import React,{useState,useEffect} from 'react'
import ShilpaFoundationMenu from './ShilpaFoundationMenu'
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../components/button'
import { DatePicker, Modal } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {FiChevronRight,FiChevronLeft} from 'react-icons/fi';
import { IconButton, Tooltip } from '@material-ui/core';
import { DeleteShilpaFoundationEventService, DownloadShilpaFoundationEventService, GetShilpaFoundationEventService } from '../../services/shilpafoundationservices/ShilpaFoundationEventListServices';
import moment from 'moment';
import { AiOutlineEdit,AiOutlineDelete,AiOutlineFileExcel } from 'react-icons/ai';
import toast from 'react-hot-toast';
import {RiUser6Line} from 'react-icons/ri';
import axios from 'axios';
import fileDownload from "js-file-download";


function ShilpaFoundationEventList() {

  const navigator = useNavigate()
  const {pathname} = useLocation()
  const roles = useSelector(state=>state.Auth.roles)

  const [modal,setmodal] = useState(false)
  const [step,setstep] = useState(1)
  const [page,setpage] = useState(1)
  const [data,setdata] = useState({})
  const [selecteddata,setselecteddata] = useState({})

  const [search,setsearch] = useState({text:'',from_date:'',to_date:'',from_date1:'',to_date1:'',activate:false})

  useEffect(()=>{
    getdata()
  },[step])

  async function getdata(){
   const response =  await GetShilpaFoundationEventService(page,search?.text,search?.from_date1,search?.to_date1,step)
   setdata(response?.data)
  }

 
  async function applyFilter(){
    setsearch({...search,activate:true})
    setpage(1)
    const response =  await GetShilpaFoundationEventService(1,search?.text,search?.from_date1,search?.to_date1,step)
    setdata(response?.data)
  }

  async function resetfilter(){
    setsearch({text:'',from_date:'',to_date:'',from_date1:'',to_date1:'',activate:false})
    setpage(1)
    const response =  await GetShilpaFoundationEventService(1,'','','',step)
    setdata(response?.data)
  }

  async function deleteData(){
    const response = await DeleteShilpaFoundationEventService(selecteddata?._id)
    if(response?.status === 200){
        toast.success("Deleted Successfully")
    }else{
        toast.success("Deleted Successfully")
    }
    setmodal(false)
    getdata()
  }

  async function downloaddata(){
    const response = await DownloadShilpaFoundationEventService(1,search?.text,search?.from_date1,search?.to_date1,)
    let path = response.data.path.replace('public/','')
    handleDownload(`${process.env.REACT_APP_BACKEND_IMAGE_URL}${path}`,path.split('/')[path.split('/')?.length - 1])

  }

  const handleDownload = (url, filename) => {
    axios.get(url, {
        responseType: 'blob',
    })
        .then((res) => {
            fileDownload(res.data, filename)
        })
  }

  
  return (
    <div className='w-[98%] h-screen overflow-hidden'>

    <Modal
       keepMounted
       open={modal}
       onClose={()=>setmodal(false)}
       width={300}
       footer={false}
       closable={false}
      
     >
       <div >
         <h6 className="font-bold text-[15px] text-center mb-2 w-full">Are you sure?</h6>
         <h6 className='bg-slate-100 text-center text-[12px] p-1.5 font-[400]'>After deleting you cannot retrieve it back before deleting check once whether you have used it in some data</h6>
         <div className='flex justify-end mt-3 '>
           <ButtonOutlinedAutoWidth btnName={'Cancel'} onClick={()=>setmodal(false)} />
           <div  className='ml-2'>
           <ButtonFilledAutoWidth btnName={'Confirm'} onClick={()=>deleteData()}  /> 
           </div>
         </div>
       </div>
     </Modal>

        <div className='flex'>
            <div className='min-w-44'>
            <ShilpaFoundationMenu />
            </div>
            <div className='w-[100%] pl-5 mt-4'>

            <div className='flex items-center -mt-1 border-b justify-between pb-1'>
                <h6 className='font-[800] text-[13px]'>Total Events  ({data?.pagination?.total})</h6>

                <div className='flex items-center text-[12px]'>

                    <AiOutlineFileExcel onClick={()=>downloaddata()} size={24}  className='mx-2 bg-gray-200 p-1.5' />

                    <h6 className='mr-2 font-[600]'>{page == 1 ? data?.datas?.length > 0 ? 1 : 0 : (page - 1) * data?.pagination?.limit } - {data?.pagination?.limit} of {data?.pagination?.total}  </h6>

                    <div>
                    <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>
                    <IconButton onClick={()=>{ page < data?.pagination?.totalPages  ? setpage(page+1) : console.log('')}}><FiChevronRight className={`${(data?.pagination?.totalPages === page || data?.datas?.length === 0) ? 'opacity-50 ' : 'opacity-100'}`}  size={16} /></IconButton>

                    </div>
                    <DatePicker  size='small' style={{fontSize:'11px'}} ampm={false} placeholder='From Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.from_date} onChange={(v,v1) => {console.log('v na',new Date(v).toLocaleDateString());setsearch({...search,from_date:v,from_date1:v1})}} /> 

                    <DatePicker size='small' style={{fontSize:'11px'}} ampm={false} placeholder='To Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.to_date} onChange={(v,v1) => {console.log('v na',new Date(v).toLocaleDateString());setsearch({...search,to_date:v,to_date1:v1})}} /> 

                    
                    <div className='mr-2'>
                        

                    <input  id="search_text" placeholder='Search text' type='text' value={search.text} onChange={(e)=>setsearch({...search,text:e.target.value})} className='border py-[3px] focus:ring-0 focus:outline-0 text-[14px]  w-[100px] px-1 rounded-md border-slate-300' />
                

                    </div>  
                    <div className='mr-2'>
                    <ButtonOutlinedAutoWidth btnName="Apply Filter" onClick={()=>applyFilter()} /> 
                    </div>
                    <div className='mr-2'>
                    <ButtonOutlinedAutoWidth btnName="Reset Filter" onClick={()=>resetfilter()} /> 
                    </div>
                    
                    <ButtonFilledAutoWidth btnName="Add New" onClick={()=>navigator('create')} />

                </div>
            </div>

            <div className='flex  items-center border-b'>
                <h6 onClick={()=>{setstep(1);setpage(1)}} className={`font-[600] ${step === 1 && 'bg-slate-600 text-white'} text-center cursor-pointer py-1 w-[80px] text-[11px]`}>Active</h6>
                <h6 onClick={()=>{setstep(2);setpage(1)}} className={`font-[600] ${step === 2 && 'bg-slate-600 text-white'} text-center cursor-pointer py-1 w-[80px] text-[11px]`}>InActive</h6>
            </div>

            <div className='overflow-x-hidden h-[90vh] mb-[50px] overflow-y-scroll'>
                            
                {data?.datas?.length === 0 &&
                <div className='grid place-items-center mt-20  items-center justify-center'>
                    <img src={'https://fidecrmfiles.s3.amazonaws.com/NoDataFound1.png'} className='w-40 h-40 object-contain' /> 
                    <h6 className='font-bold text-[14px] -mt-10'>No data found</h6>
                    <h6 className='font-[500] w-[70%] text-center text-slate-700 text-[12.5px] -mt-2'>Oops we couldn't find any data based on your current stage please add the data to see list here.</h6>
                </div>}

                <div className='h-[88vh] overflow-y-scroll'>
                    {data?.datas?.length > 0 &&
                    <div className='grid grid-cols-6 gap-1 mt-2'>
                        {data?.datas?.map((d,i)=>(
                        <div key={i}  className='border items-center relative justify-between px-2 py-1 border-b'>
                            <div className='w-full bg-slate-100 h-[80px] border-slate-100'>
                           {d?.image !== '' && <img src={`${process.env.REACT_APP_AWS_IMAGE_URL}${d?.image}`} className='border object-cover w-full h-[80px] border-slate-100' />}
                           </div>
                            <h6 className='text-[12px] mt-[1px] line-clamp-2 font-[500] capitalize '>{d?.title}</h6>
                            <h6 className='text-[10px] font-[500] bg-slate-100 mt-1 p-1'>Created At : <span className='text-[10px] font-[800]'>{moment(d.createdAt).format('LLL')}</span> </h6>
                            <div className='absolute bg-white right-0 p-1 border-l border-b border-slate-100 bg-white top-0 flex'>
                            <Tooltip title="Visitor" >
                                <span><RiUser6Line size={12} className="cursor-pointer mr-[7px]" onClick={()=>{navigator('users_list',{state:d})}}/></span>
                            </Tooltip>
                            <Tooltip title="Edit" >
                                <span><AiOutlineEdit size={12} className="cursor-pointer" onClick={()=>{navigator('edit',{state:d})}}/></span>
                            </Tooltip>
                            <Tooltip title="Delete">
                                <span><AiOutlineDelete size={12} className='ml-2 cursor-pointer'  onClick={()=>{setselecteddata(d);setmodal(true)}}/></span>
                            </Tooltip>
                            </div>
                        </div>
                        ))}
                    </div>}
                </div>

                </div> 
                
            </div>
        </div>   
    </div>
  )
}

export default ShilpaFoundationEventList