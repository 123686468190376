import { deleteRequest, get, publicPost, put } from "../helpers/apihelpers";

export const CreateCountryService=async(data)=>{
    try {
        const result = await publicPost(`api/activity/CN/create`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const CreateStateService=async(data)=>{
    try {
        const result = await publicPost(`api/activity/ST/create`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const CreateDistrictService=async(data)=>{
    try {
        const result = await publicPost(`api/activity/DT/create`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const CreateTalukService=async(data)=>{
    try {
        const result = await publicPost(`api/activity/BN/create`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateCountryService=async(data,id)=>{
    try {
        const result = await put(`api/activity/CN/update/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateStateService=async(data,id)=>{
    try {
        const result = await put(`api/activity/ST/update/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateDistrictService=async(data,id)=>{
    try {
        const result = await put(`api/activity/DT/update/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateTalukService=async(data,id)=>{
    try {
        const result = await put(`api/activity/BN/update/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetCountry=async()=>{
    try {
        const result = await get(`api/activity/CN/get`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetState=async()=>{
    try {
        const result = await get(`api/activity/ST/get`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetDistrict=async()=>{
    try {
        const result = await get(`api/activity/DT/get`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetTaluk=async()=>{
    try {
        const result = await get(`api/activity/BN/get`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteCountryService=async(id)=>{
    try {
        const result = await deleteRequest(`api/activity/CN/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteStateService=async(id)=>{
    try {
        const result = await deleteRequest(`api/activity/ST/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteDistrictService=async(id)=>{
    try {
        const result = await deleteRequest(`api/activity/DT/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteTalukService=async(id)=>{
    try {
        const result = await deleteRequest(`api/activity/BN/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}