import React,{useEffect, useState} from 'react'
import ProfileMenu from './ProfileMenu'
import {  ButtonFilled, ButtonFilledAutoWidth, ButtonOutlinedAutoWidth, ButtonOutlinedAutoWidthError } from '../../components/button'
import { Modal } from 'antd'
import { TextInput } from '../../components/input'
import {IoCloseOutline} from 'react-icons/io5'
import { UpdateUserNameService } from '../../services/AuthServices'
import { useSelector } from 'react-redux'
import { toast } from 'react-hot-toast'
import { GetOnboardDetailService, GetUserLetterList, UpdateUserApproveByEmployee } from '../../services/OnboardServices'
import CopyToClipboard from 'react-copy-to-clipboard'
import { AiOutlineCopy, AiOutlineDownload } from 'react-icons/ai'
import { IoMdShareAlt,IoIosRefresh } from "react-icons/io";
import { Tooltip } from '@mui/material'
import axios from 'axios'
import fileDownload from "js-file-download";
import { VscOpenPreview } from "react-icons/vsc";
import {BiCheckbox,BiCheckboxSquare} from 'react-icons/bi'


function ProfileDocuments() {

  const [data,setdata] = useState({})
  const [data1,setdata1] = useState({})
  const [data2,setdata2] = useState({})
  const [loader,setloader] = useState(false)

  const user = useSelector(state=>state.Auth)

  useEffect(()=>{
    getDocs()
  },[])


  async function getDocs(){
    setloader(true)
    const response = await GetUserLetterList(user?.id)
    const response1 = await GetOnboardDetailService(user?.id)
    if(response1?.status === 200){
        setloader(false)
        setdata1(response1?.data?.datas[0])
        setdata2(response1?.data?.datas[0])
    }
    setdata(response?.data?.data)
  }

  const handleDownload = async(url, filename) => {
    await axios.get(url, {
        responseType: 'blob',
    }).then((res) => {
            fileDownload(res.data, filename)
        })
  }


  // console.log("data1",data1?.agree_company_appointment_letter_terms)

  

  async function submitForm(){
    setloader(true)
      const send_Data = {
        agree_company_appointment_letter_terms:data2?.agree_company_appointment_letter_terms
      }
     
      const response = await UpdateUserApproveByEmployee(user?.id,send_Data)
      if(response.status === 200){
        toast.success("Submitted Successfully")
        const response1 = await GetOnboardDetailService(user?.id)
        if(response1?.status === 200){
            setloader(false)
            setdata1(response1?.data?.datas[0])
        }
      }
  }

//   UpdateUserApproveByEmployee


  function returnfilename(v){
    let string = v?.split("/")[v?.split("/")?.length - 1]
    return string;
  }


  return (
    <div className='px-2 mx-0 box-border'>

     

        {/* <MainprofileAppbar  /> */}

        <div className='flex'>
        <ProfileMenu />

        <div className='ml-6 w-full ml-4 mt-4 pt-5'>
        <h6 className='border-b mt-1 pb-2 mb-4 font-[700] text-[17px] border-[#d0d7de]'>Documents Added</h6>
       
        {!loader && 
            <>
            {data === null ? 
            <div>
                <h6 className='text-[12.5px] font-[700]'>No Data Added Till Now</h6>
            </div>
            :
            <div className='grid w-[100%] grid-cols-5 '>
               {(data1?.agree_company_appointment_letter_terms !== 'Approved' && data1?.agree_company_appointment_letter_terms !== undefined) ?   
               <div>
                    <h6 className='text-[11px] font-[500] leading-[14px] bg-gray-50 p-2 px-2'>We found that some letters have been generated to your account But You didn't agree the terms and condition</h6>
                    <span className='flex text-center text-[10px] mb-2'>
                    {data2?.agree_company_appointment_letter_terms === 'Approved' ?  <BiCheckboxSquare size={22} className='text-slate-600' onClick={()=>{setdata2({...data2,agree_company_appointment_letter_terms:'Pending'})}} />  : <BiCheckbox size={22} className='text-slate-300' onClick={()=>{setdata2({...data2,agree_company_appointment_letter_terms:'Approved'})}}  /> }
                    <h6 className='ml-1 mb-0 mt-1 font-[600]'>I Agree & Acknowledge</h6>
                    </span>
                    {data2?.agree_company_appointment_letter_terms === 'Approved' &&
                    <ButtonFilledAutoWidth btnName="Save" onClick={submitForm}  />}
               </div> 
                :
                <>
                {(data?.appointment !== '' && data?.appointment !== undefined) &&
                    <div className='w-[200px] border mt-2 items-center justify-between overflow-hidden cursor-pointer group'>
                        <h1 className='text-[11px] font-[600] group-hover:underline text-blue-600 p-2'>Appointment Letter</h1>
                        <span className='flex items-center justify-center border-t'>
                        
                        <CopyToClipboard text={`${process.env.REACT_APP_BACKEND_IMAGE_URL}${data?.appointment?.replace('public/','')}`} onCopy={()=>toast.success("Link Copied")}>
                        <Tooltip title="Copy link">
                        
                        <span className='flex items-center justify-center border-l px-2 py-1.5'><AiOutlineCopy className='mr-2' /></span>
                        </Tooltip>
                    
                        </CopyToClipboard>
                        <Tooltip title="Preview File">
                        <a href={`${process.env.REACT_APP_BACKEND_IMAGE_URL}${data?.appointment?.replace('public/','')}`} target='_blank'>
                        <span className='border-r flex items-center justify-center pr-0 border-l px-2 py-1.5'><VscOpenPreview className='mr-2' /></span>
                        </a>
                        </Tooltip>
                        <Tooltip title="Download">
                        <span onClick={()=>handleDownload(`${process.env.REACT_APP_BACKEND_IMAGE_URL}${data?.appointment?.replace('public/','')}`,returnfilename(data?.appointment))} className='border-r flex items-center justify-center px-2 pr-0 py-1.5'><AiOutlineDownload className='mr-2' /></span>
                        </Tooltip>
                        
                        </span>
                        
                    </div>}  

                    {(data?.exit_letter !== '' && data?.exit_letter !== undefined) &&
                    <div className='w-[200px] border mt-2 items-center justify-between overflow-hidden cursor-pointer group'>
                        <h1 className='text-[11px] font-[600] group-hover:underline text-blue-600 p-2'>Resignation/Expierence Letter</h1>
                        <span className='flex items-center justify-center border-t'>
                        
                        <CopyToClipboard text={`${process.env.REACT_APP_BACKEND_IMAGE_URL}${data?.exit_letter?.replace('public/','')}`} onCopy={()=>toast.success("Link Copied")}>
                        <Tooltip title="Copy link">
                        
                        <span className='flex items-center justify-center border-l px-2 py-1.5'><AiOutlineCopy className='mr-2' /></span>
                        </Tooltip>
                    
                        </CopyToClipboard>
                        <Tooltip title="Preview File">
                        <a href={`${process.env.REACT_APP_BACKEND_IMAGE_URL}${data?.exit_letter?.replace('public/','')}`} target='_blank'>
                        <span className='border-r flex items-center justify-center pr-0 border-l px-2 py-1.5'><VscOpenPreview className='mr-2' /></span>
                        </a>
                        </Tooltip>
                        <Tooltip title="Download">
                        <span onClick={()=>handleDownload(`${process.env.REACT_APP_BACKEND_IMAGE_URL}${data?.exit_letter?.replace('public/','')}`,returnfilename(data?.exit_letter))} className='border-r flex items-center justify-center px-2 pr-0 py-1.5'><AiOutlineDownload className='mr-2' /></span>
                        </Tooltip>
                        
                        </span>
                        
                    </div>}  
                </>} 

              
                
            </div>         
            }
            </>
        }


        </div>    

    </div>    
    </div>
  )
}

export default ProfileDocuments