import { deleteRequest, get, post, postfd, publicPost, put } from "../../../helpers/apihelpers";

export const CreateProjectWebsiteVideoService=async(data)=>{
    try {
        const result = await publicPost(`api/project_website/video/create`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateProjectWebsiteVideoService=async(data,id)=>{
    try {
        const result = await put(`api/project_website/video/update/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetProjectWebsiteVideoService=async(page,text)=>{
    try {
        const result = await get(`api/project_website/video/get?page=${page}&text=${text}`,);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteProjectWebsiteVideoService=async(id)=>{
    try {
        const result = await deleteRequest(`api/project_website/video/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UploadProjectWebsiteVideoService=async(data)=>{
    try {
        const result = await postfd(`api/project_website/video/upload_file`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}