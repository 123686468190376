import {  deleteRequest, get, post, put, } from "../../helpers/apihelpers";

export const CreateTeamService=async(data)=>{
    try {
        const result = await post(`api/team/create`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetTeamService=async(page,incentive)=>{
    try {
        const result = await get(`api/team/get?page=${page}&incentive=${incentive}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetCurrentYearTeamService=async(id,insentive,page)=>{
    try {
        const result = await get(`api/team/get_team_based_on_department/${id}/null/?page=${page}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetTeamBasedOnYearInsentiveService=async(id)=>{
    try {
        const result = await get(`api/team/get_team_based_on_department/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}



export const GetTeamTargetService=async(id)=>{
    try {
        const result = await get(`api/team/get_team_target/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetTeamServiceDetail=async(id)=>{
    try {
        const result = await get(`api/team/get/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetTeamMembersDetail=async(id)=>{
    try {
        const result = await get(`api/team/get_team_members/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}


export const GetTeamMembersList=async(id)=>{
    try {
        const result = await get(`api/team/get_team_target/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}



export const UpdateTeamService=async(data,id)=>{
    try {
        const result = await put(`api/team/update/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteTeamService=async(id)=>{
    try {
        const result = await deleteRequest(`api/team/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

