import { deleteRequest, get, post, put } from "../helpers/apihelpers";



export const CreateVerticalProfitLossService=async(data)=>{
    try {
        const result = await post(`api/vertical_profit_loss/create`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateVerticalProfitLossService=async(data,id)=>{
    try {
        const result = await put(`api/vertical_profit_loss/update/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetVerticalProfitLossService=async({from_date1,to_date1,page})=>{
    try {
        const result = await get(`api/vertical_profit_loss/get?from_date=${from_date1}&to_date=${to_date1}&page=${page}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const FilterVerticalProfitLossService=async({from_date1,to_date1,page=1})=>{
    try {
        const result = await get(`api/vertical_profit_loss/filter?from_date=${from_date1}&to_date=${to_date1}&page=${page}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteVerticalProfitLossService=async(id)=>{
    try {
        const result = await deleteRequest(`api/vertical_profit_loss/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}