import React,{useEffect, useState} from 'react'
import ExitMenu from './ExitMenu'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { IoStopwatchOutline } from "react-icons/io5";
import { Modal, Select, Tag } from 'antd';
import { TextAreaInput1, TextInput } from '../../components/input';
import {BiErrorCircle,BiCheckbox,BiCheckboxSquare} from 'react-icons/bi'
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../components/button';
import { GetExitUserFormDetails, GetOnboardDetailService, UpdateExitUserFormDetails, UpdateUserApprovalByAdmin } from '../../services/OnboardServices';
import toast from 'react-hot-toast';

function EntryMenuListEdit() {

  const user = useSelector(state=>state.Auth) 
  const [loader,setloader] = useState(false)
  const [loader1,setloader1] = useState(false)
  
//   console.log("user",user)
  const navigate = useNavigate()
  const {state} = useLocation()

  const [data,setdata] = useState({
  })

  const [error,seterror] = useState({
    approved_by_admin:''
  })

  const roles = useSelector(state=>state.Auth.roles)

  useEffect(()=>{
    getdata()
  },[])

//   console.log("error",error)


  async function getdata(){
    const response = await GetOnboardDetailService(state?._id)
    let d = response?.data?.datas

    if(d?.length !== 0){
      let datas = d[0]
      let obj = {...datas}
      obj['approved_by_admin'] = (datas?.approved_by_admin !== '' && datas?.approved_by_admin !== undefined) ? {label:datas?.approved_by_admin,value:datas?.approved_by_admin} : ''
      setdata(obj)
    }
 }

 console.log("data kp ",data)



    const option = [
        {label:'Pending',value:'Pending'},
        {label:'Approved',value:'Approved'},
        {label:'Rejected',value:'Rejected'},
        ]
      
       



     async function submitForm(){
  
        if(data?.approved_by_admin?.value === 'Rejected' && data?.admin_rejected_remarks === ''){
            seterror({...error,admin_rejected_remarks:'This Field is required'})
        }else{
            setloader(true)
            const send_data = {
                approved_by_admin:data?.approved_by_admin?.value,
                admin_rejected_remarks:data?.admin_rejected_remarks
            }

            if(send_data.approved_by_admin){
              setloader1(true)
            }
            
            

            const response = await UpdateUserApprovalByAdmin(state?._id,send_data)
            // console.log("response",response)
            if(response?.status === 200){
                toast.success("User Approval Updated Successfully")
                getdata()
                setloader1(false)
                navigate(-1)
                setloader(false)
            }
      }  

     }

  

     console.log("data?.approved_by_admin",data?.approved_by_admin)

  return (
    <div  className="w-[100%] h-screen overflow-hidden">
       <Modal open={loader1} width={300} closable={false} footer={false}>
        <div>
          <div>
            <h6 className='text-[12px] font-[800]'>We are process with Letter Generation</h6>
            <h6 className='text-[10px] bg-gray-100 p-1 font-[500] mt-2'>Please wait until the process complete don't skip or close the tab until process completed it not take much time</h6>
          </div> 
        </div>
        </Modal>  
    
        <div className='flex'>
          <div className='w-[44] max-h-screen overflow-hidden'>
            <ExitMenu />   
          </div>  
          <div className='w-80 min-h-screen max-h-screen  overflow-y-scroll p-4 px-6'>
            
            <div className='bg-green-100 flex p-2'>
                <h1 className='text-[12px] ml-2'>Hi  <b>{user?.name}</b> the employee <b className='capitalize'>{state?.name}</b> who belongs to <b>{state?.department_id[0]?.department_name}</b> and who will be serving as a <b>{state?.designation_id[0]?.designation_name}</b> has been onboarded we need your confirmation to release the offer letter. </h1>
            </div>


             


          

   <TextInput 
    label={'Name'}
    name={'name'}
    value={data?.name}
    error={error.name}
    // handlechange={handlechange}
   />

   <TextInput 
    label={'Employee Id'}
    name={'employee_id'}
    value={data?.employee_id}
    error={error.employee_id}
    // handlechange={handlechange}
    />

   <TextInput 
    label={'Department'}
    name={'department'}
    value={data?.department_id !== undefined && data?.department_id[0]?.department_name}
    // error={error.been_better_about_your_job}
    // handlechange={handlechange}
    />

<TextInput 
    label={'Designation'}
    name={'designation'}
    value={data?.designation_id !== undefined && data?.designation_id[0]?.designation_name}
    // error={error.been_better_about_your_job}
    // handlechange={handlechange}
    />

<TextInput 
    label={'CTC Yearly'}
    name={'ctc'}
    value={data?.ctc}
    // error={error.been_better_about_your_job}
    // handlechange={handlechange}
    />

<h6 className='text-[12px] font-semibold mb-1 mt-1.5'>Stage</h6>
<Select 
value={data?.approved_by_admin}
bordered={false}
onChange={(v)=>setdata({...data,approved_by_admin:{label:v,value:v}})}
className='w-full border h-[32px] border-slate-300'
options={option} />

{data?.approved_by_admin?.value === 'Rejected' 
&& 
<TextAreaInput1 
 label={'Reason For Rejection'}
 value={data?.admin_rejected_remarks}
 error={error?.admin_rejected_remarks}
 handlechange={(e)=>{setdata({...data,admin_rejected_remarks:e.target.value});seterror({...error,admin_rejected_remarks:''})}}/>

}


 

 <div className='flex justify-end border-t pt-5 my-5'>
   <ButtonOutlinedAutoWidth btnName="Cancel" onClick={()=>navigate(-1)} />
    <h6 className='w-2'></h6>
   {loader ? 
   <ButtonFilledAutoWidth btnName="Loading"  />
    :
   <ButtonFilledAutoWidth btnName="Save" onClick={submitForm} />}
   </div>
   
          </div>


   

  
          </div>
        </div>
  )
}

export default EntryMenuListEdit