import React, { useEffect, useState } from 'react'
import DashboardMenu from '../../dashboard/DashboardMenu'
import { GetAllInsentiveList } from '../../../services/AdminServicesfile/InsentiveServices'
import moment from 'moment'
import {RiBillLine} from 'react-icons/ri'
import { useNavigate } from 'react-router-dom'

function EmployeeTargets() {
  
  const [datas,setdatas] = useState([]) 
  const navigator = useNavigate()

  useEffect(()=>{
    getdata()
  },[])

  async function getdata(){
    try{
        const response = await GetAllInsentiveList()
        setdatas(response?.data?.datas)
    }catch(e){

    }
  }
  return (
    <div className='h-screen overflow-hidden min-h-screen max-h-screen'>
      <div className='flex'>
        <div className='min-w-44 max-w-44 -mt-5 w-44'>
          <div className='pt-5 border-r'>
            <DashboardMenu />
        </div>
        </div>
        <div className='mx-3 w-full'>
          <h6 className='text-[13.5px] font-[700] mt-5 border-b pb-1 mb-2'>Incentive Requirment ({datas?.length})</h6>
          <div className='grid gap-1 grid-cols-5'>
             {datas?.map((d)=>( 
              <div key={d?.id} className='border p-2 relative '>
                <RiBillLine onClick={()=>navigator('/dashboard/employee_target/list',{state:d})} className='absolute right-1 top-1 cursor-pointer' />
                 <h6 className='text-[12px]'>Year : <span className='font-[700]'>{d?.year}</span> </h6>

                 <h6 className='text-[10px] font-[700] bg-slate-100 p-1'>Created At : {moment(d?.createdAt)?.format('ll')}</h6>
              </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default EmployeeTargets