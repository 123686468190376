import React, { useState,useEffect } from 'react'
// import { GetStageService } from '../../../services/StageServices';
import {  ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../components/button';
// import {  GetLeadOptionsService } from '../../../services/LeadOptionServices';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';
import {AiOutlineCheckCircle, AiOutlineClose, AiOutlineDelete, AiOutlineEdit, AiOutlineFilter, AiOutlineReload} from 'react-icons/ai';  
import {FiChevronRight,FiChevronLeft} from 'react-icons/fi';
import { IconButton, Tooltip } from '@material-ui/core';
import TaskMenu from './TaskMenu';
import { DownloadDailyTaskService} from '../../services/DailyTaskServices';
import { DatePicker, Modal, Select } from 'antd';
import { useSelector } from 'react-redux';
import { RiFileExcel2Line } from "react-icons/ri";
import fileDownload from "js-file-download";
import axios from 'axios';
import { GetReminderService } from '../../services/ReminderServices';
import { DeleteMomItemTaskService, DeleteMomTaskService, GetMomAdminListTaskService, GetMomAdminTaskService, GetMomListTaskService, GetMomTaskService } from '../../services/MOMTaskServices';
import {BiCheckbox,BiCheckboxSquare} from 'react-icons/bi'
import { GetDepartmentService } from '../../services/DepartmentServices';
import DashboardMenu from '../dashboard/DashboardMenu';
import { GetSearchService } from '../../services/AuthServices';

function AdminMomTaskList() {

  const {pathname} = useLocation()
  const navigator = useNavigate()
  const path1 = pathname.split('/')[pathname.split('/').length - 2]
  const [search,setsearch] = useState({text:'',from_date:'',to_date:'',from_date1:'',to_date1:'',type:false,department:'',user:''})

  const roles = useSelector(state=>state.Auth.roles)

  const userId = useSelector(state=>state.Auth.id)



  
  const [step,setstep] = useState(1)
  const [substep,setsubstep] = useState(1)
  const [modal,setmodal] = useState(false)
  const [modal1,setmodal1] = useState(false)
  

  const [stages,setstages] = useState([])
  const [departmentArr,setdepartmentArr] = useState([])
  const [usersArr,setusersArr] = useState([])
  const [page,setpage] = useState(1)
  const [selectedData,setselectedData] = useState({})

  const [data,setdata] = useState({})

  const [reminderData,setreminderData] = useState([])

  useEffect(()=>{
    getfollowupStages()
  },[])

  useEffect(()=>{
    setpage(1)
    setsearch({text:'',from_date:'',to_date:'',from_date1:'',to_date1:'',type:false,department:'',user:''})
    if(substep == 1){
      getdata(1)
    }else{
      getdata1(1)
    }
  },[substep])
  


  useEffect(()=>{
    getfollowupStages()
    setstep(1)
  },[path1])

  useEffect(()=>{
    if(substep === 1){
      getdata()
    }else{
      getdata1()
    }
  },[page])
  
  const getfollowupStages = async () => {
    const response = await GetDepartmentService()
    let d = response?.data?.datas
    let arr = []
    d?.forEach((d1)=>{
      arr.push({label:d1?.department_name,value:d1?.id})
    })
    setdepartmentArr(arr)
  }


  

   async function getdata(){
    const response = await GetMomAdminTaskService(page,search?.from_date1,search?.to_date1,search?.text,search?.type,search?.user)
    setdata(response.data?.data)
   }

   async function getdata1(){
    const response = await GetMomAdminListTaskService(page,search?.from_date1,search?.to_date1,search?.text,search?.type,search?.department,search?.user)
    setdata(response.data?.data)
   }

//    console.log("data",data)

  const gotocreate = () => {
    navigator('create')
  }

  const gotoedit = (d) => {
    navigator('/tasks/mom_tasks/lists/edit',{state:{...d?.mom}})
  }

  async function downloadExcel(){
    const response = await DownloadDailyTaskService(userId,search?.from_date1,search?.to_date1)
    let d = response?.data
    if(response?.status === 200){
        handleDownload(`${process.env.REACT_APP_BACKEND_IMAGE_URL}${d?.path?.replace('public/','')}`,d?.path?.split('/')[d?.path?.split('/')?.length - 1])
    }
   }

  
   

  async function applyFilter(){
    setpage(1)
    if(substep === 1){
    const response = await GetMomAdminTaskService(1,search?.from_date1,search?.to_date1,search?.text,search?.type,search?.user)
    setdata(response.data?.data)
    }else{
      const response = await GetMomAdminListTaskService(page,search?.from_date1,search?.to_date1,search?.text,search?.type,search?.department,search?.user)
      setdata(response.data?.data)
    }
    // console.log("search",search)
    // getdata1(stages,1)
  }

  async function resetfilter(){
    setpage(1)
    if(substep === 1){
    const response = await GetMomAdminTaskService(1,'','','',false,'')
    setdata(response.data?.data)
    }else{
      const response = await GetMomAdminListTaskService(1,'','','',false,'','')
      setdata(response.data?.data)
    }
    setsearch({text:'',from_date:'',from_date1:'',to_date:'',to_date1:'',type:false,department:'',user:''})
  }

  async function downloadExcel(){
    const response = await DownloadDailyTaskService(userId,search?.from_date1,search?.to_date1)
    let d = response?.data
    if(response?.status === 200){
        handleDownload(`${process.env.REACT_APP_BACKEND_IMAGE_URL}${d?.path?.replace('public/','')}`,d?.path?.split('/')[d?.path?.split('/')?.length - 1])
    }
   }

  const handleDownload = (url, filename) => {
    axios.get(url, {
        responseType: 'blob',
    })
        .then((res) => {
            fileDownload(res.data, filename)
        })
  }

   


 

 

 


  async function deletedatafunc(){
    if(substep == 1){
      const response = await DeleteMomTaskService(selectedData?._id)
      if(response?.status === 200){
        getdata()
        setpage(1)
        setmodal(false)
        setselectedData({})
      }
    }else if(substep == 2){
      const response = await DeleteMomItemTaskService(selectedData?._id)
      if(response?.status === 200){
        getdata1()
        setpage(1)
        setmodal(false)
        setselectedData({})
      }
    }

  }

  async function searchfunction(v){
   
    const response = await GetSearchService(v,1)
    let arr = []
    response?.data?.datas?.forEach((d)=>{
    arr.push({value:d?._id,label:`${d?.name} : [${d?.department_id[0]?.department_name}]`})
    })
    setusersArr(arr)
    
  }




  return (
    <div className='w-[98%] h-screen overflow-hidden'>
        <Modal className='absolute top-0 left-[42%]' width={300} open={modal} footer={false} closable={false}>
            <div>
                <div className='flex justify-between'>
                    <h6 className='text-[13px] font-[700]'>Delete MOM</h6>
                     <div className='flex items-center'>
                    {/* <h6 onClick={()=>{resetreminderdata();setmodal1(!modal1)}} className="cursor-pointer text-[9.5px] bg-green-700  p-[4px] font-[700] rounded px-[10px] text-white  ml-1">Reminder</h6>  */}
                    <AiOutlineClose className='ml-2 bg-slate-200 p-[5px] rounded-[4px]' size={20} onClick={()=>setmodal(!modal)} />
                    </div>
                </div>
                
                <div className=' mt-2 max-h-[200px] overflow-y-scroll  no-scrollbar grid-cols-2 gap-2'>

                   <h6 className='text-[11px] bg-slate-100 p-[4px]'>Are you sure once deleted will not be retrieved back.</h6>
                   {substep == 1 ? 
                   <h1 className='text-[11px] mt-2 font-[600]'>{selectedData?.name}</h1>
                   :
                   <>
                   <h1 className='text-[11px] mt-2 font-[400]'>Task :  {selectedData?.task}</h1>
                   <h1 className='text-[11px]  font-[400]'>Department :  {selectedData?.department?.department_name}</h1>
                   </>}
                   <h1 className='text-[10px]'>{moment(selectedData?.createdAt)?.format('LLL')}</h1>
                </div>
                
                <div className='flex justify-end border-t pt-2 mt-2 '>
                <ButtonOutlinedAutoWidth onClick={()=>setmodal(false)} btnName="Cancel" />
                <h6 className='w-[10px]'></h6>
                <ButtonFilledAutoWidth onClick={()=>deletedatafunc()}  btnName="Save" />
                </div>
            </div>

        </Modal>

     
        
        <div className='flex'>
            <div className='min-w-44'>
            <DashboardMenu />
            </div>
            <div className='w-[100%] pl-5 mt-4'>
            <div className='flex items-center -mt-1 border-b justify-between pb-1'>
                <h6 className='font-[800] text-[13px]'>Total Minutes Of Meet({data?.pagination?.total})</h6>

                <div className='flex items-center text-[12px]'>

                    <h6 className='mr-2 font-[600]'>{page == 1 ? data?.datas?.length > 0 ? 1 : 0 : (page - 1) * data?.pagination?.limit } - {data?.pagination?.limit} of {data?.pagination?.total}  </h6>

                    <div>
                    <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>
                    <IconButton onClick={()=>{ page < data?.pagination?.totalPages  ? setpage(page+1) : console.log('')}}><FiChevronRight className={`${(data?.pagination?.totalPages === page || data?.datas?.length === 0) ? 'opacity-50 ' : 'opacity-100'}`}  size={16} /></IconButton>

                    </div>
                    <DatePicker  size='small' style={{fontSize:'11px'}} ampm={false} placeholder='From Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.from_date} onChange={(v,v1) => {console.log('v na',new Date(v).toLocaleDateString());setsearch({...search,from_date:v,from_date1:v1})}} /> 

                    <DatePicker size='small' style={{fontSize:'11px'}} ampm={false} placeholder='To Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.to_date} onChange={(v,v1) => {console.log('v na',new Date(v).toLocaleDateString());setsearch({...search,to_date:v,to_date1:v1})}} /> 
                    <div className='mr-2'>
                        

                        <input  id="search_text" placeholder='Search text' type='text' value={search.text} onChange={(e)=>setsearch({...search,text:e.target.value})} className='border py-[3px] focus:ring-0 focus:outline-0 text-[14px]  w-[100px] px-1 rounded-md border-slate-300' />
                    
    
                        </div>  
                    
                     <div onClick={()=>setsearch({...search,type:!search?.type})} className='flex items-center mr-2'>
                     {search?.type ? <BiCheckboxSquare size={24} className='text-slate-600' /> : <BiCheckbox size={24} className='text-slate-200' /> }
                      <h6 className='text-[10px] font-[600]' >Completed</h6>
                     </div>
                     {substep == 2 &&  
                      <div className='mr-2 border border-slate-300 rounded-[6px]  h-[28px]'> 
                      <Select
                      bordered={false}  
                      placeholder="Department"
                      value={search?.department === '' ? null : search?.department}
                      options={departmentArr}
                      onChange={(v)=>setsearch({...search,department:v})}
                      className='w-[100px]'
                      /> 
                    </div>
                    }

<div className='mr-2 border border-slate-300 rounded-[6px]  h-[28px]'> 
                      <Select
                      bordered={false}  
                      showSearch 
                      filterOption={false} 
                      placeholder="User"
                      value={search?.user === '' ? null : search?.user}
                      options={usersArr}
                      onSearch={searchfunction}
                      onChange={(v)=>setsearch({...search,user:v})}
                      className='w-[100px]'
                      /> 
                    </div>

                   
                    <div className='mr-2'>
                      <Tooltip title="Apply Filter">
                      <span>
                      <AiOutlineFilter size={28} className='bg-slate-600 cursor-pointer text-white p-[7px] rounded-[4px]'  onClick={()=>applyFilter()} />
                      </span>
                      </Tooltip>
                    {/* <ButtonOutlinedAutoWidth btnName="Apply Filter" onClick={()=>applyFilter()} />  */}
                    </div>
                    <div className='mr-2'>
                    <Tooltip title="Reset Filter">
                    <span>
                    <AiOutlineReload size={28} className='bg-slate-200 cursor-pointer text-BLACK p-[7px] rounded-[4px] '  onClick={()=>resetfilter()} />
                    </span>
                    </Tooltip>
                    {/* <ButtonOutlinedAutoWidth btnName="Reset Filter" onClick={()=>resetfilter()} />  */}
                    </div>
                   
                    {/* <ButtonFilledAutoWidth btnName="Add New" onClick={()=>gotocreate()} /> */}

                    <Tooltip title="Download Excel">
                    <span onClick={downloadExcel}>
                    <RiFileExcel2Line size={28} className='bg-slate-600 cursor-pointer text-white p-[7px] rounded-[4px] ml-2' />
                    </span>
                    </Tooltip>
                   

                </div>
            </div>


            <div className='flex  items-center border-b'>
              <h6 onClick={()=>setsubstep(1)} className={`font-[600] ${substep === 1 && 'bg-slate-600 text-white'} text-center cursor-pointer py-1 w-[80px] text-[11px]`}>MOM Wise</h6>
              <h6 onClick={()=>setsubstep(2)} className={`font-[600] ${substep === 2 && 'bg-slate-600 text-white'} text-center cursor-pointer py-1 w-[100px] text-[11px]`}>Department Wise</h6>
            </div>
          
            {/* <div className='flex  items-center border-b'>
                <h6 onClick={()=>setstep(1)} className={`font-[600] ${step === 1 && 'bg-slate-600 text-white'} text-center cursor-pointer py-1 w-[80px] text-[11px]`}>Pending</h6>
                <h6 onClick={()=>setstep(2)} className={`font-[600] ${step === 2 && 'bg-slate-600 text-white'} text-center cursor-pointer py-1 w-[80px] text-[11px]`}>Progress</h6>
                <h6 onClick={()=>setstep(3)} className={`font-[600] ${step === 3 && 'bg-slate-600 text-white'} text-center cursor-pointer py-1 w-[80px] text-[11px]`}>Completed</h6>
            </div> */}
            <div className='overflow-x-hidden h-[90vh] mb-[50px] overflow-y-scroll'>
            
            {data?.datas?.length === 0 &&
            <div className='grid place-items-center mt-20  items-center justify-center'>
                <img src={'https://fidecrmfiles.s3.amazonaws.com/NoDataFound1.png'} className='w-40 h-40 object-contain' /> 
                <h6 className='font-bold text-[14px] -mt-10'>No data found</h6>
                <h6 className='font-[500] w-[70%] text-center text-slate-700 text-[12.5px] -mt-2'>Oops we couldn't find any data based on your current stage please add the data to see list here.</h6>
            </div>}
            

            {data?.datas?.length > 0 && 
            <>
                {substep === 1 &&
                <>
                <div className='grid grid-cols-5 gap-2 mt-2'>
                  {data?.datas?.map((d)=>(
                      <div className='border p-2 relative'>
                        {d?.mass_completed &&
                        <AiOutlineCheckCircle size={14} className='text-green-500 absolute right-10 top-1' />}
                        <div className='border-l flex border-b absolute right-0 top-0'>
                          <AiOutlineEdit onClick={()=>{setselectedData(d);navigator('/tasks/mom_tasks/lists/edit',{state:d})}} className='p-[4px] border-r' size={18} />
                          <AiOutlineDelete  onClick={()=>{setselectedData(d);setmodal(true)}} className='p-[4px]' size={18} />
                        </div>
                        <h6 className='text-[12px] font-[600] mb-0'>{d?.name}</h6>
                        <span className='font-[700] text-[11px] mb-1'>Created by : {d?.created_by?.name}</span><br></br>
                        <h6 className='text-[11px] bg-slate-100 p-[4px]'>Mom Date : {moment(d?.createdAt)?.format('LLL')}</h6>
                        
                        {d?.mom_items?.length > 0 &&
                        <>
                        <h6 className='text-[11px] font-[600] mt-[5px]'>Work List</h6>

                        <div className='border mt-[5px]'>
                        <div className='text-[10px] flex bg-slate-100'>
                            <h6 className='font-[700] px-[4px] py-[2px] border-r min-w-[70%] max-w-[70%]'>Task</h6>
                            <h6 className='font-[700] px-[4px] py-[2px] min-w-[40%] max-w-[40%]'>Completed</h6>
                          </div>  
                        {d?.mom_items?.map((d1)=>(
                          <div className='text-[10px] flex border-t'>
                            <h6 className='font-[500] px-[4px] py-[2px] border-r min-w-[70%] max-w-[70%]'>{d1?.task}</h6>
                            <h6 className='font-[500] px-[4px] py-[2px] min-w-[40%] max-w-[40%]'> {d1?.completed ?  <BiCheckboxSquare size={16} className='text-slate-600' />  : <BiCheckbox size={16} className='text-slate-300' /> }</h6>
                          </div>  
                        ))}
                        </div>
                        </>}

                      </div>  
                  ))}

                </div>
                </>}

                {substep === 2 &&
                <>
                <div className='border-t max-h-[84vh] overflow-y-scroll border-l border-r mt-2'>
                  <div className='flex border-b sticky top-0 z-50 bg-white '>
                      <h6 className='text-[12px] font-[700] min-w-[50px] max-w-[50px] p-1 border-r'>SL No</h6>
                      <h6 className='text-[12px] font-[700] min-w-[15%] max-w-[15%] p-1 border-r'>Department</h6>
                      <h6 className='text-[12px] font-[700] min-w-[50%] max-w-[35%] p-1 border-r'>Task</h6>
                      <h6 className='text-[12px] font-[700]  min-w-[10%] max-w-[10%] p-1 border-r'>Status</h6>
                      <h6 className='text-[12px] font-[700]  min-w-[15%] max-w-[15%] p-1 border-r'>Created</h6>
                      <h6 className='text-[12px] font-[700] p-1'>Action</h6>
                  </div>  
                  {data?.datas?.map((d,i)=>(
                  <div className={`flex  border-b  cursor-pointer`}>
                      <h6 className='text-[12px] min-w-[50px] max-w-[50px] p-1 border-r'>{(page > 1 ? i+1+ (25 * (page - 1)) : i+1 )}</h6>
                      <h6 className='text-[12px] min-w-[15%] font-[700] max-w-[15%] p-1 border-r'>{d?.department?.department_name}</h6>
                      <h6 className='text-[12px] min-w-[50%] max-w-[50%] p-1 border-r'>{d?.task}<br></br><span className='font-[700] text-[11px] underline'>Created by : {d?.mom?.created_by?.name}</span></h6>
                      <h6 className='text-[12px] min-w-[10%] max-w-[10%] p-1 border-r'><span className='text-[10px] uppercase font-[500] bg-slate-100 p-1'> {!d?.completed  ? 'Progress' : 'Completed'}</span></h6>
                      <h6 className='text-[11px] min-w-[15%] max-w-[15%] p-1 border-r'>{moment(d?.createdAt)?.format('LL')}</h6>
                      <h6 className='flex text-[12px] p-1'>
                          <AiOutlineEdit onClick={()=>{gotoedit(d)}} className='mr-2' />
                          <AiOutlineDelete onClick={()=>{setselectedData(d);setmodal(true)}} className='mr-2' />
                          {/* <MdOutlineAlarm onClick={()=>{setmodal(true);setselectedData(d);getReminderList(d?._id)}} className='mr-2' /> */}
                      </h6>
                  </div>   
                ))} 
                </div>

                </>}
            </>}

           

           

            </div>
            </div>
        </div>
      

    </div>
  )
}

export default AdminMomTaskList