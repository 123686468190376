import { get } from "../../helpers/apihelpers";


export const GetAtsDashboardService=async()=>{
    try {
        const result = await get(`api/hr/dashboard/get`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetAtsDashboardHomePipelineService=async()=>{
    try {
        const result = await get(`api/hr/dashboard/get_datas_home_pipeline`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetAtsDashboardAdmin=async()=>{
    try {
        const result = await get(`api/hr/dashboard/get_dashboard_admin`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetAtsDashboardHomePipelineAdminService=async()=>{
    try {
        const result = await get(`api/hr/dashboard/get_datas_home_pipeline_admin`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetAtsDashboardUpcomingInterviewAdminService=async({stage,page})=>{
    try {
        const result = await get(`api/hr/dashboard/get_upcoming_interview_admin?stage=${stage}&page=${page}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetAtsDashboardTodayCandidateBankAdminService=async({from_date,to_date,page})=>{
    try {
        const result = await get(`api/hr/dashboard/get_today_candidate_bank_admin?from_date=${from_date}&to_date=${to_date}&page=${page}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetAtsAnalyticsAdminService=async({page,from_date,to_date,user})=>{
    try {
        const result = await get(`api/hr/dashboard/ats_analytics_overall_report?from_date=${from_date}&to_date=${to_date}&user=${user}&page=${page}`);
        return result;
    } catch (err) {
        return err.response;
    }
}