import React, { useEffect, useState } from 'react'
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../../components/button';
import { CreateFcaresBillService, DeleteFcaresBillService, GetFcaresBillService, UpdateFcaresBillService } from '../../../services/Fcares/FCaresBillServices';
import FcaresMenu from '../FcaresMenu';
import {FiChevronRight,FiChevronLeft} from 'react-icons/fi';
import { IconButton, } from '@mui/material'
import { BsArrowRepeat } from 'react-icons/bs'
import { Modal, Select } from 'antd';
import { TextInput } from '../../../components/input';
import { BiCheckbox,BiCheckboxSquare } from "react-icons/bi";
import { UploadDocsFcaresPropertyService } from '../../../services/Fcares/FCaresPropertyServices';
import { Tooltip } from '@mui/material'
import {IoClose} from 'react-icons/io5'
import { AiOutlineDelete, AiOutlineEdit, AiOutlinePlus } from 'react-icons/ai';
import toast from 'react-hot-toast';
import moment from 'moment/moment';
import Priceconstants from '../../../constants/imageConstants';
import { useLocation } from 'react-router-dom';

function FcaresMaintanenceList() {

  const {state} = useLocation()

//   console.log("state",state)

  const [maintenance,setmaintenance] = useState({revenue:'',type:'',bill_amount:'',fidelitus_maintenance:'',additional_maintance_charges:'',net_revenue:'',month:'',additional_maintanance_description:[],bill:'',revenue_recieved:false,fidelitus_maintance_recieved:false,amount_collected:false,send_notification:false})  
  const [additional_charge,setadditional_charge] = useState({name:'',amount:'',attachment:''})
  const [error,seterror] = useState({revenue:'',type:'',bill_amount:'',fidelitus_maintenance:'',additional_maintance_charges:'',net_revenue:'',month:'',additional_maintanance_description:[],bill:'',revenue_recieved:false,fidelitus_maintance_recieved:false,amount_collected:false,send_notification:false})  
  const [search,setsearch] = useState({from_date:'',from_date1:'',to_date:'',to_date1:''})
  const [page,setpage] = useState(1)  
  const [modal,setmodal] = useState({first:false,second:false}) 
  const [modal1,setmodal1] = useState(false) 
  const [selecteddata,setselecteddata] = useState({})
  const [data,setdata] = useState({datas:[],pagination:{total:0}})
  const [tenants,settenants]  = useState([])
 
  const [step,setstep] = useState(1)

  const type = [{label:'Landlord',value:'Landlord'},{label:'Tenant',value:'Tenant'}]
 
  
  useEffect(()=>{
     let dataTenant = state.tenant
     let arr = []
     dataTenant.forEach((d)=>{
        arr.push({label:`${d?.name} - Mobile : ${d?.mobile}`,value:d?._id})
     })
     settenants(arr)
  },[state])

  useEffect(()=>{
    getData()
  },[page,step])

 

  async function getData(){
    const response = await GetFcaresBillService(search?.from_date1,search?.to_date1,page,step === 1 ? 'Landlord' : 'Tenant',state?._id)
    setdata(response?.data?.data)
  }

  async function resetfunc(){
    setsearch({from_date:'',from_date1:'',to_date:'',to_date1:''})
    setstep(1)
    setpage(1)
    const response = await GetFcaresBillService('','',1,'Landlord',state?._id)
    setdata(response?.data?.data)
  }

  console.log("maintenance",maintenance)

  async function handlechange(e){
    setmaintenance({...maintenance,[e.target.name]:e.target.value})
  }

  async function uploadfilefunc(v,name){
    // console.log("v",name)
   
    const fd = new FormData()
    fd.append('image',v); 
    const response = await UploadDocsFcaresPropertyService(fd)
    if(response?.status === 200){
        if(name === 'attachment'){
            setadditional_charge({...additional_charge,attachment:response?.data?.data})
        }else{    
            setmaintenance({...maintenance,[name]:response?.data?.data})
        }
    }
  }

  async function addCombination(){
    let oldData = maintenance.additional_maintanance_description
    let oldObj = {...additional_charge}
    if(oldObj['id'] === undefined){
        oldObj['id'] = oldData?.length
        oldData.push(oldObj)
        
    }else{
        oldData[oldObj['id']] = additional_charge
    }



    setmaintenance({...maintenance,additional_maintanance_description:oldData})
    setadditional_charge({name:'',amount:'',attachment:''})

  }

//   console.log("maintenance",maintenance?.additional_maintanance_description)

  function removeCombination(v){
    let oldData = maintenance?.additional_maintanance_description.filter((f)=>f?.id !== v)
    console.log("oldData",oldData)
    setmaintenance({...maintenance,additional_maintanance_description:oldData})
  }

//   console.log("maintenance",maintenance)

  async function submitData(){
    if(selecteddata?._id !== undefined){
        let send_data = {...maintenance}
        // send_data['property'] = state?._id
        // send_data['owner'] = state?.client?._id
        // send_data['tenant'] = state?.tenant?._id
        const response = await UpdateFcaresBillService(selecteddata?._id,send_data)
        if(response?.status === 200){
            toast.success("Bill updated")
            setmaintenance({revenue:'',fidelitus_maintenance:'',additional_maintance_charges:'',net_revenue:'',month:'',additional_maintanance_description:[],bill:'',revenue_recieved:false,fidelitus_maintance_recieved:false,amount_collected:false})
            setadditional_charge({name:'',amount:'',attachment:''})
            getData()
            setmodal({first:false,second:false})
        }
    }else{
        let send_data = {...maintenance}
        send_data['property'] = state?._id
        if(maintenance?.type === 'Landlord'){
           send_data['owner'] = state?.client?._id
        }
        if(maintenance?.type === 'Tenant' && maintenance?.tenantdata?.value !== undefined){
           send_data['tenant'] = maintenance?.tenantdata?.value
        }
        const response = await CreateFcaresBillService(send_data)
        if(response?.status === 201){
            toast.success("Bill created")
            setmaintenance({revenue:'',fidelitus_maintenance:'',additional_maintance_charges:'',net_revenue:'',month:'',additional_maintanance_description:[],bill:'',revenue_recieved:false,fidelitus_maintance_recieved:false,amount_collected:false})
            setadditional_charge({name:'',amount:'',attachment:''})
            getData()
            setmodal({first:false,second:false})
        }
    }
  }

//   console.log("state",state)


  function addmaintenance(){
    setmaintenance({revenue:'',fidelitus_maintenance:'',additional_maintance_charges:'',net_revenue:'',month:'',additional_maintanance_description:[],bill:'',revenue_recieved:false,fidelitus_maintance_recieved:false,amount_collected:false,send_notification:false})
    setadditional_charge({name:'',amount:'',attachment:'',send_notification:''})
    setmodal({...modal,first:true,second:false})
  }

  async function deletemaintenance(){
    const response = await DeleteFcaresBillService(selecteddata?._id)
    if(response?.status === 200){
        toast.success("Maintenance Deleted Successfully")
        setselecteddata({})
        setmodal1(false)
        getData()
    }
  }

  function openfilefunc(v){
    window.open(`${process.env.REACT_APP_BACKEND_IMAGE_URL}${v?.replace('public/','')}`,'_blank')
  }

  function seteditmaintenancefunc(d){
    let sendData = {...d}
    let arr = []
    if(d?.additional_maintanance_description?.length > 0){
        d?.additional_maintanance_description?.forEach((d1,i)=>{
        arr.push({
            id:i,
            ...d1
        })
        })
    }
    sendData['additional_maintanance_description'] = arr
    setmaintenance(sendData)
    setmodal({first:true,second:false})
  }


//   console.log("data?.pagination",data?.pagination)
  return (
    <div className='h-screen max-h-screen box-border overflow-hidden'>

        <Modal open={modal1} footer={false} width={300} closable={false}>
            <div>
                <h6 className='font-[800]'>Delete the bill</h6>
                <h6 className='text-[11px] bg-slate-100 capitalize p-[4px] leading-[15px]'>Are you sure want to delete the selected bill for the property</h6>
                
                <h6 className='text-[11px] mt-[10px]'>Bill Amount : <span className='font-[700]'> {Priceconstants(parseInt(selecteddata?.revenue))}</span></h6>
                <h6 className='text-[11px]'>Bill Month : <span className='font-[700]'> {moment(selecteddata?.month)?.format('MMM YYYY')}</span></h6>

                <div className='flex mt-1'>
                    <div className='mr-1'>
                    <ButtonOutlinedAutoWidth btnName="Cancel"  onClick={()=>setmodal1(false)}/>
                    </div>
                    <ButtonFilledAutoWidth btnName="Save"  onClick={deletemaintenance}/>
                </div>
            </div>        
        </Modal> 

        <Modal open={modal?.first} footer={false} width={300} closable={false}>
            <div>
                <h6 className='font-[800]'>Create/Update the bill</h6>
                <h6 className='text-[11px] bg-slate-100 capitalize p-[4px] leading-[15px]'>use the below form to create or update the billing for the property</h6>
                
                <h6 className='text-[12px] font-semibold mb-1 mt-1'>Bill To*</h6>

                <Select 
                value={maintenance?.type}
                bordered={false}
                onChange={(e)=>{setmaintenance({...maintenance,type:e})}} 
                options={type}
                className='border-l-4 border-l-slate-600 w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none'
                />
                {maintenance?.type === 'Landlord' &&
                <>
                <TextInput 
                 mandatory={true}
                 label={'Revenue'}  
                 variant="standard"
                 name="revenue"
                 type="text"
                 value={maintenance.revenue}
                 error={error.revenue}
                 handlechange={handlechange}
                 placeholder="Enter contact name"
                 InputLabelProps={{
                     style: { color: '#fff', }, 
                 }}
                />

                <div className='flex'>
                    <div className='mr-1'>   
                        <TextInput 
                        mandatory={true}
                        label={'Fcare Maintainence'}  
                        variant="standard"
                        name="fidelitus_maintenance"
                        type="text"
                        value={maintenance.fidelitus_maintenance}
                        error={error.fidelitus_maintenance}
                        handlechange={handlechange}
                        placeholder="Enter contact name"
                        InputLabelProps={{
                            style: { color: '#fff', }, 
                        }}
                        />
                    </div>

                    <div className='ml-1'>   
                        <TextInput 
                        mandatory={true}
                        label={'Add Maintain charge'}  
                        variant="standard"
                        name="additional_maintance_charges"
                        type="text"
                        value={maintenance.additional_maintance_charges}
                        error={error.additional_maintance_charges}
                        handlechange={handlechange}
                        placeholder="Enter contact name"
                        InputLabelProps={{
                            style: { color: '#fff', }, 
                        }}
                        />
                    </div>

                </div>

                    <TextInput 
                    mandatory={true}
                    label={'Net Revenue'}  
                    variant="standard"
                    name="net_revenue"
                    type="text"
                    value={maintenance.net_revenue}
                    error={error.net_revenue}
                    handlechange={handlechange}
                    placeholder="Enter contact name"
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}
                    />
                </>}

                {maintenance?.type === 'Tenant' &&
                <>
                  <h6 className='text-[12px] font-semibold mb-1 mt-1'>Choose Tenant*</h6>
                  <Select 
                    value={maintenance?.tenant}
                    bordered={false}
                    onChange={(e)=>{setmaintenance({...maintenance,tenantdata:tenants?.find((f)=>f?.value == e)})}} 
                    options={tenants}
                    className='border-l-4 border-l-slate-600 w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none'
                />


                 <TextInput 
                 mandatory={true}
                 label={'Bill Amount'}  
                 variant="standard"
                 name="bill_amount"
                 type="text"
                 value={maintenance.bill_amount}
                 error={error.bill_amount}
                 handlechange={handlechange}
                 placeholder="Enter contact name"
                 InputLabelProps={{
                     style: { color: '#fff', }, 
                 }}
                />
                <div className='flex items-center'>
                 <TextInput 
                 mandatory={true}
                 label={'Fcare Maintenance'}  
                 variant="standard"
                 name="fidelitus_maintenance"
                 type="text"
                 value={maintenance.fidelitus_maintenance}
                 error={error.fidelitus_maintenance}
                 handlechange={handlechange}
                 placeholder="Enter contact name"
                 InputLabelProps={{
                     style: { color: '#fff', }, 
                 }}
                />
                <h6 className='w-[10px]'></h6>
                   <TextInput 
                 mandatory={true}
                 label={'Add Maintenance'}  
                 variant="standard"
                 name="additional_maintance_charges"
                 type="text"
                 value={maintenance.additional_maintance_charges}
                 error={error.additional_maintance_charges}
                 handlechange={handlechange}
                 placeholder="Enter contact name"
                 InputLabelProps={{
                     style: { color: '#fff', }, 
                 }}
                />
                </div>
                </>}
                <TextInput 
                    mandatory={true}
                    label={'Month'}  
                    variant="standard"
                    name="month"
                    type="month"
                    value={maintenance.month}
                    error={error.month}
                    handlechange={handlechange}
                    placeholder="Enter contact name"
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}
                    />

            <h6 className='text-[11px] font-[600] mb-1 mt-2' >Bill Attachment</h6>
            {(maintenance.bill === '' || maintenance.bill == null) ?
                <form onClick={()=>document.querySelector(`.input-field`).click()} className={`p-4 flex flex-col items-center justify-center cursor-pointer border border-slate-300 `}>
                    <input type='file' onChange={({target:{files}})=>{
                    files[0] && uploadfilefunc(files[0],'bill')
                    }} accept="*" className='input-field' hidden />
                </form> 
            :
                <div className='p-2 border border-slate-300 relative flex flex-col  cursor-pointer'>
                    <Tooltip title='Delete'><IoClose className='absolute top-3 right-2' onClick={()=>setmaintenance({...maintenance,bill:''})}/></Tooltip>
                    <h6 className='text-[12px] truncate w-48 ml-0'>{maintenance?.bill}</h6>
                </div>
                
            }

            <div className='flex items-center justify-between mb-1 mt-2'>
            <h6 className='text-[11px] font-[600] ' >Maintenance Charges</h6>
            <AiOutlinePlus className='bg-slate-200 p-[4px]' onClick={()=>setmodal({...modal,second:!modal.second})} size={15} />
            </div>

            {modal?.second &&
            <div>
            <TextInput 
                                mandatory={true}
                                label={'Type'}  
                                variant="standard"
                                name="name"
                                type="text"
                                value={additional_charge.name}
                                handlechange={(e)=>setadditional_charge({...additional_charge,name:e.target.value})}
                                placeholder="Enter contact name"
                                InputLabelProps={{
                                    style: { color: '#fff', }, 
                                }}
                                />


            <TextInput 
                                mandatory={true}
                                label={'Amount'}  
                                variant="standard"
                                name="amount"
                                type="text"
                                value={additional_charge.amount}
                                handlechange={(e)=>setadditional_charge({...additional_charge,amount:e.target.value})}
                                placeholder="Enter contact name"
                                InputLabelProps={{
                                    style: { color: '#fff', }, 
                                }}
                                />

            <h6 className='text-[11px] font-[600] mb-1 mt-2' >Attachment</h6>
            {(additional_charge.attachment === '' || additional_charge.attachment == null) ?
                <form onClick={()=>document.querySelector(`.input-field1`).click()} className={`p-4 flex flex-col items-center justify-center cursor-pointer border border-slate-300 `}>
                    <input type='file' onChange={({target:{files}})=>{
                    files[0] && uploadfilefunc(files[0],'attachment')
                    }} accept="*" className='input-field1' hidden />
                </form> 
            :
                <div className='p-2 border border-slate-300 relative flex flex-col  cursor-pointer'>
                    <Tooltip title='Delete'><IoClose className='absolute top-3 right-2' onClick={()=>setadditional_charge({...additional_charge,attachment:''})}/></Tooltip>
                    <h6 className='text-[12px] truncate w-48 ml-0'>{additional_charge?.attachment}</h6>
                </div>
                
            }

            <div className='mt-2'>
            <ButtonFilledAutoWidth btnName="ADD COMBO" onClick={addCombination} />
            </div>

            </div>
            }


                {maintenance?.additional_maintanance_description?.length > 0 &&
                <div className='border mt-2'>
                    <div className='text-[8px] bg-slate-100 flex'>
                        <h6 className='p-[2px] border-r w-[15%]'>SL NO</h6>
                        <h6 className='p-[2px] border-r w-[40%]'>TYPE</h6>
                        <h6 className='p-[2px] border-r w-[25%]'>AMOUNT</h6>
                        <h6 className='p-[2px] w-[18%]'>ACTION</h6>
                    </div>
                    {maintenance?.additional_maintanance_description?.map((d,i)=>(
                        <div className='text-[8px] flex border-t'>
                        <h6 className='p-[2px] border-r w-[15%]'>{i+1}</h6>
                        <h6 className='p-[2px] border-r w-[40%]'>{d?.name} {d?.attachment !== '' && <h6 onClick={()=>openfilefunc(d?.attachment)} className='text-blue-600 underline text-[8px] font-[700] cursor-pointer'>View Attachment</h6>}</h6>
                        <h6 className='p-[2px] border-r w-[25%]'>{d?.amount}</h6>
                        <h6 className='p-[2px] pt-[5px] flex w-[18%]'>
                            <AiOutlineEdit  onClick={()=>setmaintenance(d)}/>
                            <AiOutlineDelete className='ml-2' onClick={()=>removeCombination(d?.id)} />
                        </h6>
                    </div>
                    ))}
                </div>}

                <div className='flex flex-wrap items-center mt-2'>
                    <div onClick={()=>setmaintenance({...maintenance,revenue_recieved:!maintenance.revenue_recieved})}  className='flex items-center'>
                        {!maintenance?.revenue_recieved ?
                        <BiCheckbox className='text-slate-300' size={20} /> :
                        <BiCheckboxSquare className='text-slate-700' size={20}
                       />}
                        <h6 className='text-[8px] font-[600]'>Revenue Revieved</h6>
                    </div>

                    <div onClick={()=>setmaintenance({...maintenance,fidelitus_maintance_recieved:!maintenance.fidelitus_maintance_recieved})}  className='flex items-center'>
                        {!maintenance?.fidelitus_maintance_recieved ?
                        <BiCheckbox className='text-slate-300' size={20} /> :
                        <BiCheckboxSquare className='text-slate-700' size={20}
                       />}
                        <h6 className='text-[8px] font-[600]'>FCares Maintenance Revieved</h6>
                    </div>

                    <div onClick={()=>setmaintenance({...maintenance,amount_collected:!maintenance.amount_collected})}  className='flex items-center'>
                        {!maintenance?.amount_collected ?
                        <BiCheckbox className='text-slate-300' size={20} /> :
                        <BiCheckboxSquare className='text-slate-700' size={20}
                       />}
                        <h6 className='text-[8px] font-[600]'>Amount Collected</h6>
                    </div>

                    <div onClick={()=>setmaintenance({...maintenance,send_notification:!maintenance.send_notification})}  className='flex items-center'>
                        {!maintenance?.send_notification ?
                        <BiCheckbox className='text-slate-300' size={20} /> :
                        <BiCheckboxSquare className='text-slate-700' size={20}
                       />}
                        <h6 className='text-[8px] font-[600]'>Send Notification</h6>
                    </div>
                </div>


                <div className='flex mt-2'>
                    <div className='mr-2'>
                    <ButtonOutlinedAutoWidth btnName="Cancel" onClick={()=>{setmodal({...modal,first:false,second:false});setselecteddata({})}} />
                    </div>
                    <ButtonFilledAutoWidth btnName="Save" onClick={submitData} />
                </div>
            </div>

        </Modal>


         <div className='flex'>
            <div className='min-w-44'>
            <FcaresMenu />
            </div>
            <div className='w-[88%] px-5 pt-5'>




            <div className='flex items-center justify-between border-b pb-2 p'>
              <h6 className='font-[700] text-[14px] '>Total Bills ({data?.pagination?.total})</h6>
              <div className='flex items-center'>
              <div className='flex items-center text-[12px] mr-2'>

                <h6 className='mr-2 font-[600]'>{page == 1 ? data?.datas?.length > 0 ? 1 : 0 :  (page - 1) * data?.pagination?.limit } - {data?.pagination?.limit} of {data?.pagination?.total} </h6>
                <IconButton onClick={resetfunc}><BsArrowRepeat size={16} /></IconButton>

                <div>
                <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>
                <IconButton onClick={()=>{page < data?.pagination?.totalPages  ? setpage(page+1) : console.log('')}}><FiChevronRight className={`${(data?.pagination?.totalPages === page || data?.datas?.length === 0)  ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>

                </div>
                </div>
               
                <div className='ml-2'>
                <ButtonFilledAutoWidth btnName="Add Data" onClick={()=>{addmaintenance()} }/> 
                </div>
              </div>
            </div>

            <div className='flex items-center border-b'>
                <h6 onClick={()=>{setstep(1);setpage(1)}} className={`${step === 1 ? 'bg-slate-700 text-white' : ''} cursor-pointer p-[5px] w-[70px] text-center text-[10px] font-[700]`}>LandLord</h6>
                <h6 onClick={()=>{setstep(2);setpage(1)}} className={`${step === 2 ? 'bg-slate-700 text-white' : ''} cursor-pointer p-[5px] w-[70px] text-center text-[10px] font-[700]`}>Tenant</h6>
            </div>
             
            {step === 1 && 
            <div className='max-h-[82vh] overflow-x-hidden mt-2 border-t   border-l border-r overflow-y-scroll'>
                <div className='sticky top-0 z-50 flex  bg-white py-[-5px] border-b border-slate-200 relative'>
                    <h6  className='top-0 bg-white z-50 text-[12px] w-[60px]  px-2 py-1 font-[600] text-slate-600 border-r flex items-center justify-center'>SL NO</h6>
                    <h6 className='sticky top-0 z-50  text-[12px] w-[100px] px-2 py-1 font-[600] text-slate-600 border-r'>Month</h6>
                    <h6 className='sticky top-0 z-50  text-[12px] w-[100px]  px-2 py-1 font-[600] text-slate-600 border-r'>Revenue</h6>
                    <h6 className='sticky top-0 z-50  text-[12px] w-[140px] px-2 py-1 font-[600] truncate text-slate-600 border-r'>Fcares Maintainence</h6>
                    <h6 className='sticky top-0 z-50  text-[12px] w-[140px] px-2 py-1 font-[600] truncate text-slate-600 border-r'>Additional Maintainence</h6>
                    <h6 className='sticky top-0 z-50  text-[12px] w-[100px] px-2 py-1 font-[600] text-slate-600 border-r'>Net Revenue</h6>
                    <h6 className='sticky top-0 z-50  text-[12px] w-[140px] px-2 py-1 font-[600] truncate text-slate-600 border-r'>Amount Collected</h6>
                    <h6 className='sticky top-0 z-50  text-[12px] w-[140px] px-2 py-1 font-[600] truncate text-slate-600 border-r'>Fcares Amt Recieved</h6>
                    <h6 className='sticky top-0 z-50  text-[12px] w-[140px] px-2 py-1 font-[600] truncate text-slate-600 border-r'>Client Amt Recieved</h6>
                    <h6 className='sticky top-0 z-50  text-[12px] w-[90px]  px-2 py-1 font-[600] text-slate-600 sticky right-0'>Actions</h6>
                </div>
                {data?.datas?.map((d,i)=>(
                <div className='flex  bg-white py-[-5px] border-b border-slate-200 relative'>
                    <h6  className='bg-white z-50 text-[12px] w-[60px]  px-2 py-1 border-r flex items-center justify-center'>{page === 1 ? i+1 : (i+1) + ((page-1)* data?.pagination?.limit)}</h6>
                    <h6 className='text-[12px] font-[700] w-[100px] px-2 py-1 border-r'>{moment(d?.month)?.format('MMM YYYY')}</h6>
                    <h6 className='text-[12px] font-[700] w-[100px]  px-2 py-1 border-r'>{Priceconstants(!isNaN(d?.revenue) ? parseInt(d?.revenue) : 0 )}</h6>
                    <h6 className='text-[12px] font-[700] w-[140px]  px-2 py-1 border-r'>{(d?.fidelitus_maintenance !== undefined && d?.fidelitus_maintenance !== '') ? Priceconstants(!isNaN(d?.fidelitus_maintenance) ? parseInt(d?.fidelitus_maintenance) : 0 ): 0} </h6>
                    <h6 className='text-[12px] font-[700] w-[140px] px-2 py-1 border-r'>{Priceconstants(parseInt(d?.additional_maintance_charges))}</h6>
                    <h6 className='text-[12px] font-[700] w-[100px] px-2 py-1 border-r'>{Priceconstants(parseInt(d?.net_revenue))}</h6>
                    <h6 className='text-[12px] font-[700] w-[140px] px-2 py-1 border-r'><span className={`p-[4px] ${!d?.amount_collected ? 'bg-blue-300' : 'bg-green-300'} font-[600] text-[10px]`}>{d?.amount_collected ? 'Collected' : 'Pending'}</span></h6>
                    <h6 className='text-[12px] font-[700] w-[140px] px-2 py-1 border-r'><span className={`p-[4px] ${!d?.fidelitus_maintance_recieved ? 'bg-blue-300' : 'bg-green-300'} font-[600] text-[10px]`}>{d?.fidelitus_maintance_recieved ? 'Recieved' : 'Pending'}</span></h6>
                    <h6 className='text-[12px] font-[700] w-[140px] px-2 py-1 border-r'><span className={`p-[4px] ${!d?.revenue_recieved ? 'bg-blue-300' : 'bg-green-300'} font-[600] text-[10px]`}>{d?.revenue_recieved ? 'Recieved' : 'Pending'}</span></h6>
                    <h6 className='text-[12px] w-[90px]  px-2 py-1  right-0'>
                    <h6 className=' z-50 flex text-[11px] w-[90px] py-1 font-[500]'>
                    <Tooltip title="Edit">
                    <span><AiOutlineEdit size={13} className="z-10 ml-2" onClick={()=>{setselecteddata(d);setmaintenance(d);seteditmaintenancefunc(d)}}/></span>
                    </Tooltip>
                    <Tooltip title="Delete">
                    <span><AiOutlineDelete size={13} className="z-10 ml-2" onClick={()=>{setselecteddata(d);setmodal1(true)}}/></span>
                    </Tooltip>
                    </h6>

                    </h6>
                </div>))}
            </div>}   

            {step === 2 && 
            <div className='max-h-[82vh] overflow-x-hidden mt-2 border-t   border-l border-r overflow-y-scroll'>
                <div className='sticky top-0 z-50 flex  bg-white py-[-5px] border-b border-slate-200 relative'>
                    <h6  className='top-0 bg-white z-50 text-[12px] w-[60px]  px-2 py-1 font-[600] text-slate-600 border-r flex items-center justify-center'>SL NO</h6>
                    <h6 className='sticky top-0 z-50  text-[12px] w-[100px] px-2 py-1 font-[600] text-slate-600 border-r'>Month</h6>
                    <h6 className='sticky top-0 z-50  text-[12px] w-[100px] px-2 py-1 font-[600] text-slate-600 border-r'>Tenant</h6>
                    <h6 className='sticky top-0 z-50  text-[12px] w-[100px]  px-2 py-1 font-[600] text-slate-600 border-r'>Bill Amount</h6>
                    <h6 className='sticky top-0 z-50  text-[12px] w-[140px] px-2 py-1 font-[600] truncate text-slate-600 border-r'>Fcares Maintainence</h6>
                    <h6 className='sticky top-0 z-50  text-[12px] w-[140px] px-2 py-1 font-[600] truncate text-slate-600 border-r'>Additional Maintainence</h6>
                    <h6 className='sticky top-0 z-50  text-[12px] w-[140px] px-2 py-1 font-[600] truncate text-slate-600 border-r'>Amount Collected</h6>
                    <h6 className='sticky top-0 z-50  text-[12px] w-[140px] px-2 py-1 font-[600] truncate text-slate-600 border-r'>Fcares Amt Recieved</h6>
                    <h6 className='sticky top-0 z-50  text-[12px] w-[140px] px-2 py-1 font-[600] truncate text-slate-600 border-r'>Client Amt Recieved</h6>
                    <h6 className='sticky top-0 z-50  text-[12px] w-[90px]  px-2 py-1 font-[600] text-slate-600 sticky right-0'>Actions</h6>
                </div>
                {data?.datas?.map((d,i)=>(
                <div className='flex  bg-white py-[-5px] border-b border-slate-200 relative'>
                    <h6  className='bg-white z-50 text-[12px] w-[60px]  px-2 py-1 border-r flex items-center justify-center'>{page === 1 ? i+1 : (i+1) + ((page-1)* data?.pagination?.limit)}</h6>
                    <h6 className='text-[12px] font-[700] w-[100px] px-2 py-1 border-r'>{moment(d?.month)?.format('MMM YYYY')}</h6>
                    <h6 className='text-[12px] font-[700] w-[100px] px-2 py-1 border-r'>{d?.tenant?.name}</h6>
                    <h6 className='text-[12px] font-[700] w-[100px]  px-2 py-1 border-r'>{Priceconstants(!isNaN(d?.bill_amount) ? parseInt(d?.bill_amount) : 0 )}</h6>
                    <h6 className='text-[12px] font-[700] w-[140px]  px-2 py-1 border-r'>{(d?.fidelitus_maintenance !== undefined && d?.fidelitus_maintenance !== '') ? Priceconstants(!isNaN(d?.fidelitus_maintenance) ? parseInt(d?.fidelitus_maintenance) : 0 ): 0} </h6>
                    <h6 className='text-[12px] font-[700] w-[140px] px-2 py-1 border-r'>{Priceconstants(parseInt(d?.additional_maintance_charges === '' ? 0 : d?.additional_maintance_charges))}</h6>
                    <h6 className='text-[12px] font-[700] w-[140px] px-2 py-1 border-r'><span className={`p-[4px] ${!d?.amount_collected ? 'bg-blue-300' : 'bg-green-300'} font-[600] text-[10px]`}>{d?.amount_collected ? 'Collected' : 'Pending'}</span></h6>
                    <h6 className='text-[12px] font-[700] w-[140px] px-2 py-1 border-r'><span className={`p-[4px] ${!d?.fidelitus_maintance_recieved ? 'bg-blue-300' : 'bg-green-300'} font-[600] text-[10px]`}>{d?.fidelitus_maintance_recieved ? 'Recieved' : 'Pending'}</span></h6>
                    <h6 className='text-[12px] font-[700] w-[140px] px-2 py-1 border-r'><span className={`p-[4px] ${!d?.revenue_recieved ? 'bg-blue-300' : 'bg-green-300'} font-[600] text-[10px]`}>{d?.revenue_recieved ? 'Recieved' : 'Pending'}</span></h6>
                    <h6 className='text-[12px] w-[90px]  px-2 py-1  right-0'>
                    <h6 className=' z-50 flex text-[11px] w-[90px] py-1 font-[500]'>
                    <Tooltip title="Edit">
                    <span><AiOutlineEdit size={13} className="z-10 ml-2" onClick={()=>{setselecteddata(d);setmaintenance(d);seteditmaintenancefunc(d)}}/></span>
                    </Tooltip>
                    <Tooltip title="Delete">
                    <span><AiOutlineDelete size={13} className="z-10 ml-2" onClick={()=>{setselecteddata(d);setmodal1(true)}}/></span>
                    </Tooltip>
                    </h6>

                    </h6>
                </div>))}
            </div>}   

            </div>
         </div>   
    </div>
  )
}

export default FcaresMaintanenceList