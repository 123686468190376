import React, { useEffect } from 'react';
import {RiUser6Line,RiWalkLine,RiBillLine} from 'react-icons/ri';
import {BsEnvelopePaper} from 'react-icons/bs'
import {useLocation,useNavigate } from 'react-router-dom';
import {CiViewBoard} from 'react-icons/ci';
import {GrMultiple} from 'react-icons/gr';
import {FcInspection} from 'react-icons/fc';
import {BiCalendarEdit,BiFoodMenu} from 'react-icons/bi';
import {AiOutlineClockCircle} from 'react-icons/ai';
import {MdOutlineViewTimeline,MdPermIdentity,MdOutlineMoneyOffCsred} from 'react-icons/md';
import {HiOutlineViewBoards} from 'react-icons/hi';
import {VscDebugContinue} from 'react-icons/vsc';
// import {DatePicker} from 'antd';
import {BiLandscape} from 'react-icons/bi'


function TeamTaskMenu() {

  const {pathname} = useLocation();
  const path = pathname.split('/')[pathname.split('/').length - 1]

  console.log("pathname",pathname)

 

  const navigate = useNavigate();

  useEffect(()=>{
       if(path === 'tasks'){
        navigate('/tasks/daily_tasks/list')
       }
  },[])

  const menu1 = [
    {name:'My Timeline',icon:MdOutlineViewTimeline,path:`/tasks/projects/list/timeline`,id:1,color:''},
    {name:'My Board',icon:HiOutlineViewBoards,path:`/tasks/projects/list/timeline`,id:3,color:''},
  ] 

  const menu2 = [
    {name:'Project Details',icon:BiCalendarEdit,path:`/tasks/projects/list/detail`,id:1,color:''},
    {name:'Project Access',icon:MdPermIdentity,path:`/tasks/projects/list/access`,id:2,color:''},
    {name:'Project Issues',icon:VscDebugContinue,path:`/tasks/projects/list/issues`,id:3,color:''},
  ] 

  
  return (
    <div className='mr-0 min-h-screen max-h-sceen border-r min-w-44  w-44 max-w-44  px-2' >

       

        <div className={` w-full pt-4 `}>
            <h6 className=' ml-2 mb-2 font-[700] text-[12px]'>PLANNING </h6>
            {menu1.map((m)=>(
                <div key={m.path} className={`flex items-center my-1 hover:bg-[#f4f5f7] px-1.5 py-1 ml-2 rounded-md relative cursor-pointer ${pathname ===  m.path && 'bg-[#f4f5f7]'}`} onClick={()=>navigate(m?.path)}>
                  {pathname ===  m.path &&  <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3'></span>}
                  <m.icon color='#000' style={{backgroundColor:m?.color,padding:'2px'}} size={16} />
                 <span className='font-[500] ml-2 text-[12px]'>{m.name}</span>
                </div>    
            ))}
        </div>

        <div className={` w-full pt-4 `}>
            <h6 className=' ml-2 mb-2 font-[700] text-[12px]'>SETTINGS </h6>
            {menu2.map((m)=>(
                <div key={m.path} className={`flex items-center my-1 hover:bg-[#f4f5f7] px-1.5 py-1 ml-2 rounded-md relative cursor-pointer ${pathname ===  m.path && 'bg-[#f4f5f7]'}`} onClick={()=>navigate(m?.path)}>
                  {pathname ===  m.path &&  <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3'></span>}
                  <m.icon color='#000' style={{backgroundColor:m?.color,padding:'2px'}} size={16} />
                 <span className='font-[500] ml-2 text-[12px]'>{m.name}</span>
                </div>    
            ))}
        </div>
    </div>
  )
}

export default TeamTaskMenu