import { deleteRequest, get, post, put } from "../helpers/apihelpers";

export const CreateAdminReminderService=async(data)=>{
    try {
        const result = await post(`api/reminder_md/create`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateAdminReminderService=async(data,id)=>{
    try {
        const result = await put(`api/reminder_md/update/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetAdminReminderService=async({page=1,type})=>{
    try {
        const result = await get(`api/reminder_md/get?page=${page}&type=${type}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteAdminReminderService=async(id)=>{
    try {
        const result = await deleteRequest(`api/reminder_md/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}
