import { Box } from '@material-ui/core'
import React,{useState} from 'react'
import { TextInput } from '../../../../components/input'
import { useLocation,useNavigate } from 'react-router-dom';
import { ButtonFilled } from '../../../../components/button';
import {  CreateLeadReminderService } from '../../../../services/LeadServices';
import { toast } from 'react-hot-toast';
// import { DateTimePickerComponent } from '@syncfusion/ej2-react-calendars';
import { MobileBackOption } from '../../../../components/appbars/mobile/DashboardMobile';
import { DatePicker,TimePicker } from 'antd';
import moment from 'moment';

function LeadReminderCE() {

  const [data,setdata] = useState({title:'',date:'',time:'',summary:''});
  const [error,seterror] = useState({title:'',date:'',time:'',summary:''});
  const location = useLocation()
  const navigate = useNavigate()
  const {state} = location 
 
  async function submitform(){
    if(data.title === ''){
      seterror({title:'This field is required*'});
    }else if(data.date === ''){
      seterror({date:'This field is required*'});
    }else if(data.time === ''){
      seterror({time:'This field is required*'});
    }else{
      let send_data = {}
      send_data['title'] = data.title 
      send_data['summary'] = data.summary 
      send_data['type'] = 'Lead'
      send_data['type_id'] = state
      send_data['description'] = data.summary 
      send_data['repeat'] = "Once" 
      send_data['notify'] = "Email"

      if(data?.date !== ''){
        send_data['on_date_time'] = moment(data?.date).format('YYYY-MM-DD') + ' ' + moment(data?.time).format('hh:mm')
      }
      

      // console.log("send_Data",send_data)
      const response = await CreateLeadReminderService(send_data)
      // console.log("response",response)
      if(response.status === 200){
        toast.success("Reminder created successfully")
        setdata({title:'',date:'',time:'',summary:''})
        seterror({title:'',date:'',time:'',summary:''})
      }
    }
  }

  function handlechange(e){
    setdata({...data,[e.target.name] : e.target.value})
    seterror({...error,[e.target.name]:''})
  }

 

  return (
    <Box sx={{marginTop:{sm:'50px',xs:'50px',md:'50px',lg:'50px'},paddingLeft:'30px'}}>
       <Box sx={{display:{sm:'block',md:'none',lg:'none'}}} className="block md:hidden">
         <MobileBackOption goback={()=>navigate(-1)} />
       </Box> 

    <div className='sm:w-full lg:w-72 p-4'>
        <h6 className='font-bold'>{location?.pathname?.split('/')[4] !== 'create' ? 'Edit' : 'Add'}  Reminder</h6>
        <h6 className='text-[10px] bg-sky-50 p-2 font-semibold leading-snug' >Use the below form to create or update the <b> Reminder</b> for your leads followups.</h6>
         
        <TextInput 
          label={'Title'}  
          variant="standard"
          name="title"
          type="text"
          error={error.title}
          value={data.title}
          handlechange={handlechange}
          placeholder="Enter your title"
          InputLabelProps={{
              style: { color: '#fff', }, 
          }}
        />

        <TextInput 
          label={'Summary'}  
          variant="standard"
          name="summary"
          type="text"
          error={error.summary}
          value={data.summary}
          handlechange={handlechange}
          placeholder="Enter your summary"
          InputLabelProps={{
              style: { color: '#fff', }, 
          }}
        />

<h6 className='text-[12px] font-semibold mb-1 mt-1' >Date Time</h6>
        <div className='flex items-center height-24'>
        <div className="flex mb-2">
          <div>
          <DatePicker ampm={false} placeholder=''  sx={{height:'40px'}} inputFormat="MM/dd/yyyy" value={data.date} onChange={(v) => {setdata({...data,date:v});seterror({...error,date:''})}} />
          {(error?.date !== '' || error?.time !== '')  && <h6 className='text-red-600 text-[10px]'>{error?.date}</h6>}
          </div>
           <p style={{width:10}}></p>
           <div>
          <TimePicker format="hh:mm" placeholder='' ampm={false} sx={{height:'40px'}} value={data.time} onChange={(v) => {setdata({...data,time:v});seterror({...error,time:''})}} />
          {(error?.date !== '' || error?.time !== '')  && <h6 className='text-red-600 text-[10px]'>{error?.time}</h6>}

          </div>
          </div>
        </div>

        <Box className='mt-2'>
        <ButtonFilled btnName={location?.pathname?.split('/')[4] !== 'create' ? "UPDATE REMINDER" : "ADD REMINDER"}  onClick={submitform} />  
        </Box>


    </div>
    </Box>
  )
}

export default LeadReminderCE