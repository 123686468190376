import { IconButton, Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../components/button';
import ExitMenu from './ExitMenu';
import { DatePicker } from 'antd';
import {BsArrowRepeat} from 'react-icons/bs';
import {FiChevronRight,FiChevronLeft} from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { GetEmployeeAppointmentApprovalPending } from '../../services/OnboardServices';
import moment from 'moment';
import { AiOutlineEdit } from 'react-icons/ai';
import { useSelector } from 'react-redux';

function EntryMenuList() {

  const roles = useSelector(state=>state.Auth.roles)

  const [data,setdata] = useState({})  
  const [search,setsearch] = useState({from_date:'',to_date:'',search_text:'',from_date1:'',to_date1:''})
  const [page,setpage] = useState(1)
  const [step,setstep] = useState(1)

  const navigate = useNavigate()

  useEffect(()=>{
    getdata()
  },[step,page])

  async function getdata(){
    const response = await GetEmployeeAppointmentApprovalPending(step,page,search?.from_date1,search?.to_date1,search?.search_text)
    setdata(response?.data)
}

  async function applyfilterfunction(){
    setpage(1)
    const response = await GetEmployeeAppointmentApprovalPending(step,1,search?.from_date1,search?.to_date1,search?.search_text)
    setdata(response?.data)
  } 

  async function resetfunc(){
    setpage(1)
    setsearch({from_date:'',to_date:'',search_text:'',from_date1:'',to_date1:''})
    const response = await GetEmployeeAppointmentApprovalPending(step,1,'','','')
    setdata(response?.data)
  }

  function converttocurrency(v){
    return new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR'
    }).format(v)?.split('.')[0];
  }

  console.log("search",search)
  
  
  return (
    <div>
         <div  className="w-[100%]">
        <div className='flex'>
          <div className='w-[44]'>
            <ExitMenu />   
          </div>   
          <div className='p-5  w-[100%]'>
          <div className='flex items-center justify-between border-b pb-2'>
            <h6 className='font-[700] text-[14px] '>Total Entry List ({data?.pagination?.total})</h6>
            <div className='flex items-center'>
              <div className='flex items-center text-[12px] mr-2'>

                <h6 className='mr-2 font-[600]'>{page == 1 ? data?.datas?.length > 0 ? 1 : 0 : (page - 1) * data?.pagination?.limit } - {data?.pagination?.limit} of {data?.pagination?.total} </h6>
                <IconButton onClick={resetfunc}><BsArrowRepeat size={16} /></IconButton>

                <div>
                <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>
                <IconButton onClick={()=>{ page < data?.pagination?.totalPages  ? setpage(page+1) : console.log('')}}><FiChevronRight className={`${(data?.pagination?.totalPages === page || data?.datas?.length === 0)  ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>

                </div>
                <DatePicker size='small' style={{fontSize:'11px'}} ampm={false} placeholder='From Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.from_date} onChange={(v,v1) => {console.log('v na',new Date(v).toLocaleDateString());setsearch({...search,from_date:v,from_date1:v1})}} /> 

                <DatePicker size='small' style={{fontSize:'11px'}} ampm={false} placeholder='To Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.to_date} onChange={(v,v1) => {console.log('v na',new Date(v).toLocaleDateString());setsearch({...search,to_date:v,to_date1:v1})}} /> 


                <div className='mr-2'>
                  

                <input  id="search_text" placeholder='Search text' type='text' value={search.search_text} onChange={(e)=>setsearch({...search,search_text:e.target.value})} className='border py-[3px] focus:ring-0 focus:outline-0 text-[14px]  w-28 px-1 rounded-md border-slate-300' />

                </div>      

                <ButtonFilledAutoWidth btnName="Add Filter" onClick={()=>applyfilterfunction(1)} /> 
              </div>


              {/* <DatePicker size='small' style={{fontSize:'11px'}} ampm={false} placeholder='From Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.from_date} onChange={(v) => {console.log('v na',new Date(v).toLocaleDateString());setsearch({...search,from_date:v})}} /> 

              <DatePicker size='small' style={{fontSize:'11px'}} ampm={false} placeholder='To Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.to_date} onChange={(v) => {console.log('v na',new Date(v).toLocaleDateString());setsearch({...search,to_date:v})}} /> 

            
            <div className='mr-2'>
                

              <input  id="search_text" placeholder='Search text' type='text' value={search.text} onChange={(e)=>setsearch({...search,text:e.target.value})} className='border py-[3px] focus:ring-0 focus:outline-0 text-[14px]  w-28 px-1 rounded-md border-slate-300' />
          
            </div>      

          <ButtonOutlinedAutoWidth btnName="Add Filter" onClick={()=>applyfilterfunction(1)} /> 
         */}
         
            </div>
          </div>
          <div className='flex  items-center border-b'>
                <h6 onClick={()=>setstep(1)} className={`font-[600] ${step === 1 && 'bg-slate-600 text-white'} text-center cursor-pointer py-1 w-[80px] text-[11px]`}>Pending</h6>
                <h6 onClick={()=>setstep(2)} className={`font-[600] ${step === 2 && 'bg-slate-600 text-white'} text-center cursor-pointer py-1 w-[80px] text-[11px]`}>Approved</h6>
                <h6 onClick={()=>setstep(3)} className={`font-[600] ${step === 3 && 'bg-slate-600 text-white'} text-center cursor-pointer py-1 w-[80px] text-[11px]`}>Rejected</h6>
            </div>
            {data?.datas?.length == 0 &&
              <div className='grid place-items-center mt-20  items-center justify-center'>
              <img src={'https://fidecrmfiles.s3.amazonaws.com/NoDataFound1.png'} className='w-40 h-40 object-contain' /> 
              <h6 className='font-bold text-[14px] -mt-10'>No data found</h6>
              <h6 className='font-[500] w-[70%] text-center text-slate-700 text-[12.5px] -mt-2'>Oops we couldn't find any data based on your current stage please add the data to see list here.</h6>
            </div>
             }
            {data?.datas?.length > 0 &&
            <div className='max-h-[82vh] mt-4 border-t w-[100%]  border-l border-r overflow-y-scroll'>
                <div className='sticky top-0 z-50 flex  bg-white py-[-5px] border-b border-slate-200 relative'>
                    <h6  className='top-0 bg-white z-50 text-[12px] w-[60px]  px-2 py-1 font-[600] text-slate-600 border-r flex items-center justify-center'> SL NO </h6>
                    <h6 className='sticky top-0 z-50  text-[12px] w-[190px] px-2 py-1 font-[600] text-slate-600 border-r'>Employee Name</h6>
                    <h6 className='sticky top-0 z-50  text-[12px] w-[150px]  px-2 py-1 font-[600] text-slate-600 border-r'>Department</h6>
                    <h6 className='top-0 z-50  text-[12px] w-[120px]  px-2 py-1 font-[600] text-slate-600 border-r uppercase'>FCPL Id</h6>
                    <h6 className='sticky top-0 z-50  text-[12px] w-[150px]  px-2 py-1 font-[600] text-slate-600 border-r'>Designation</h6>
                    <h6 className='sticky top-0 z-50  text-[12px] w-[150px] px-2 py-1 font-[600] text-slate-600 border-r'>Joined Date</h6>
                    <h6 className='sticky top-0 z-50  text-[12px] w-[150px] px-2 py-1 font-[600] text-slate-600 border-r'>Requested Date</h6>
                    <h6 className='sticky top-0 z-50  text-[12px] w-[100px]  px-2 py-1 font-[600] text-slate-600 border-r'>CTC</h6>
                    <h6 className='sticky top-0 z-50  text-[12px] w-[80px]  px-2 py-1 font-[600] text-slate-600 sticky right-0'>Actions</h6>
                </div>
                {data?.datas?.map((d,i)=>(
                <div key={i} className=' flex  bg-white py-[-5px] border-b border-slate-200 relative'>
                    <h6  className=' bg-white  text-[12px] w-[60px]  px-2 py-1 font-[400]  border-r flex  justify-center'> {page === 1 ? i+1 : (i+1) + ((page-1)* data?.pagination?.limit)}</h6>
                    <h6 className='  text-[12px] w-[190px] px-2 py-1 font-[400]  border-r'>{d?.name}</h6>
                    <h6 className='  text-[12px] w-[150px]  px-2 py-1 font-[400]  border-r'>{d?.department_id[0]?.department_name}</h6>
                    <h6 className='  text-[12px] w-[120px]  px-2 py-1 font-[400]  border-r'>{d?.employee_id}</h6>
                    <h6 className='  text-[12px] w-[150px]  px-2 py-1 font-[400]  border-r'>{d?.designation_id[0]?.designation_name}</h6>
                    <h6 className='  text-[12px] w-[150px] px-2 py-1 font-[400]  border-r'>{moment(d?.updatedAt).format('ll')}</h6>
                    <h6 className='  text-[12px] w-[150px] px-2 py-1 font-[400]  border-r'>{moment(d?.createdAt).format('ll')}</h6>
                    <h6 className='  text-[12px] w-[100px]  px-2 py-1 font-[600] text-slate-600 border-r'>{converttocurrency(d?.ctc)}</h6>
                    <h6 className='  text-[12px] w-[80px]  px-2 py-1 font-[600] text-slate-600 sticky right-0'>
                      <AiOutlineEdit size={16} onClick={()=>{step == 1 ? navigate('update',{state:d}) : ['admin_exit_approval','admin','hr_exit_approval']?.filter((f)=>roles?.includes(f))?.length > 0 ? navigate('update',{state:d}) : console.log('')}}  />
                    </h6>
                  
                    {/* <h6 className='  text-[12px] w-[260px]  px-2 py-1 font-[400] truncate  border-r'>{d?.reason_leave_this_company?.split(":")?.map((m)=><Tag key={m} className='text-[8px] mb-1'>{m}</Tag>)}</h6> */}
                    {/* <h6 className='  text-[12px] flex w-[110px]  px-2 py-1 font-[400]'>
                     <Tooltip title={'Update Status'}>
                       <span onClick={()=>{step == 1 ? navigate('update',{state:d}) : ['admin_exit_approval','hr_exit_approval']?.filter((f)=>roles?.includes(f))?.length > 0 ? navigate('update',{state:d}) : console.log('')}} className={`${step == 1 ? 'opacity-100 cursor-allowed' : ['admin_exit_approval','hr_exit_approval']?.filter((f)=>roles?.includes(f))?.length > 0 ? 'opacity-100'  : 'opacity-50 cursor-not-allowed'}`}> <MdOutlineBrowserUpdated size={15} /></span> 
                     </Tooltip>  

                      <Tooltip title={'Delete Status'}>
                       <span onClick={()=>{setselectedData(d);setmodal(true)}} className={`${step == 1 ? 'opacity-100 cursor-allowed' : ['admin_exit_approval','hr_exit_approval']?.filter((f)=>roles?.includes(f))?.length > 0 ? 'opacity-100'  : 'opacity-50 cursor-not-allowed'} ml-2`}> <AiOutlineDelete size={15} /></span> 
                     </Tooltip>   
                    </h6> */}
                </div>))}
            </div>}
          </div>
        </div>
        </div>
    </div>
  )
}

export default EntryMenuList