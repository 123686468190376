import { deleteRequest, get, post, postfd, put } from "../helpers/apihelpers";



export const CreateAutoTaskService=async(data)=>{
    try {
        const result = await post(`api/auto_task/create`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateAutoTaskService=async(id,data)=>{
    try {
        const result = await post(`api/auto_task/update/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetAutoTaskService=async()=>{
    try {
        const result = await get(`api/auto_task/get`, );
        return result;
    } catch (err) {
        return err.response;
    }
}

export const CreateDailyTaskService=async(data)=>{
    try {
        const result = await post(`api/daily_task/create`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateDailyTaskService=async(data,id)=>{
    try {
        const result = await postfd(`api/daily_task/update/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetDailyTaskService=async({stage,step,page})=>{
    try {
        const result = await get(`api/daily_task/get?related_to=${stage}&stage=${step}&page=${page}`);
        return result;
    } catch (err) {
        return err.response;
    }
}


export const GetDailyTaskAdminService=async(page=1,from_date='',to_date='',search='',department,user)=>{
    try {
        const result = await get(`api/daily_task/get-admin?from_date=${from_date}&to_date=${to_date}&search=${search}&page=${page}&department=${department}&user=${user}`);
        return result;
    } catch (err) {
        return err.response;
    }
}


export const FilterDailyTaskService=async({stage,step,page,from_date,to_date,search})=>{
    try {
        const result = await get(`api/daily_task/filter?related_to=${stage}&stage=${step}&from_date=${from_date}&to_date=${to_date}&search=${search}&page=${page}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteDailyTaskService=async(id)=>{
    try {
        const result = await deleteRequest(`api/daily_task/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DownloadDailyTaskService=async(user,from_date,to_date)=>{
    try {
        const result = await get(`api/daily_task/download_excel?user=${user}&from_date=${from_date}&to_date=${to_date}`);
        return result;
    } catch (err) {
        return err.response;
    }
}