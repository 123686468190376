import React, { useEffect, useState } from 'react'
import DashboardMenu from '../../dashboard/DashboardMenu'
import { useLocation, useNavigate } from 'react-router-dom'
import {BsArrowRepeat} from 'react-icons/bs';
import {FiChevronRight,FiChevronLeft} from 'react-icons/fi';
import { IconButton } from '@mui/material'
import { Modal,Select } from 'antd';
import { GetEmployeeTargetBasedOnInsentivePeriodService, UpdateEmployeeShareBasedOnUserService } from '../../../services/AdminServicesfile/TargetServices';
import moment from 'moment';
import {BiCheckbox,BiCheckboxSquare} from 'react-icons/bi'
import {AiOutlineEdit,AiOutlineClose} from 'react-icons/ai';
import ReactApexChart from 'react-apexcharts';
import { useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';
import { TextAreaInput1, TextInput } from '../../../components/input';
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../../components/button';
import { GetAllInsentiveList, GetInsentiveBasicList } from '../../../services/AdminServicesfile/InsentiveServices';



function EmployeeTargetsDetailInsentivedetail() {
  const {state} = useLocation()  
  const navigator = useNavigate()
  const user = useSelector(state=>state.Auth)

  // console.log("state",state)
  const [data,setdata] = useState([])
  const [roles_arr,setroles_arr] = useState([])
  const [graph_data,setgraph_data] = useState({})
  const [modal,setmodal] = useState(false)
  const [selected_Data,setselected_Data] = useState({})
  const [selected_Data_Err,setselected_Data_Err] = useState({})
  const [page,setpage] = useState(1)
  const [pagination,setpagination] = useState({})
  const [period,setperiod] = useState([])
  const [selectedperiod,setselectedperiod] = useState({label:state?.selected_period,value:state?.selected_period})
  const [incentiveAmount,setincentiveAmount] = useState(0)
  const [targetAchieved,settargetAchieved] = useState(0)
  const [all_data,setall_data] = useState({})

  const [insentive,setinsentive] = useState([])

  const [chartOptions1,setchartOptions1] = useState({
    series: [],

  chart: {
    toolbar: {
      show: false
    }
  },
    plotOptions: {
      
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded',
        dataLabels: {
          position: 'top', // top, center, bottom
        },
        
      },
    },
    dataLabels: {
      enabled: false
    },
    grid:{
      show: true,
      borderColor: '#fafafa',
    },
  
    stroke: {
      // show: true,
      width: 1,
      
      curve: 'smooth',
      colors: ['transparent']
    },
    xaxis: {
      categories: ['Target', 'Threshold', 'Revenue', 'Incentive'],
    },
    yaxis: {
      title: {
        text: 'Count'
      }
    },
    fill: {
      opacity: 1
    },
    tooltip: {
      y: {
        formatter: function (val) {
          console.log("val",val)
          return  val
        }
      }
    },
    fill:['#027cd1']
  })

  let rupee = new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
  });
 
  useEffect(()=>{
    getInsentive()
  },[])

  useEffect(()=>{
    getdata()
  },[selectedperiod,page])


  async function getInsentive(){
    const response = await GetAllInsentiveList()
    let arr = []
    let d = response?.data?.datas
    d?.forEach((d1)=>{
      arr.push({label:d1?.year,value:d1?.id})
    })
    setinsentive(arr)
  }

  async function getdata(){
     try{
       const response = await GetEmployeeTargetBasedOnInsentivePeriodService(state?.user?.id,selectedperiod?.value,state?.incentive,page)
        if(response?.status === 200){
            setall_data(response?.data?.datas)
            setdata(response?.data?.datas?.datas)
            let d1 = response?.data?.datas?.graph_data
            setroles_arr(d1.roles_arr)
            setgraph_data(d1)
            setpagination(response?.data?.pagination)
            let arr = []

            for(let i=0;i<state?.quarter;i++){
                arr?.push({label:i+1,value:i+1})
            } 
            setperiod(arr)
            settargetAchieved(d1?.revenue)
            setincentiveAmount(d1?.incentive)

            let series = [
                {
                    name:'amount',
                    data: [
                         d1?.current_target ,
                         d1?.current_threshold,
                         d1?.revenue,
                         (d1?.roles_arr?.includes('hod') ||  d1?.roles_arr?.includes('manager')) ?  (Math.round(d1?.incentive) +  Math.round(d1?.team_member_incentive_amount)) : Math.round(d1?.incentive)
                     ]
                }
            ]
                
            setchartOptions1({...chartOptions1,series:[...series]})
        }
     
       
     }catch(e){

     }
  }

  function resetfunc(){
     setpage(1)
  }

  function handleChange(e){
    setselected_Data({...selected_Data,[e.target.name]:e.target.value})
    setselected_Data_Err({...selected_Data_Err,[e.target.name]:''})
 }

 async function updateData(){
   if((user?.roles?.includes('manager') || user?.roles?.includes('bd_user'))  && selected_Data?.incentive_percentage >  all_data?.maximum_percentage){
     setselected_Data_Err({...selected_Data_Err,incentive_percentage:`The  Percentage Should  between [0-${all_data?.maximum_percentage}] *`})
   }else if(!selected_Data.modified_remarks){
     setselected_Data_Err({...selected_Data_Err,modified_remarks:'This Field is required *'})
   }else{
     let updated_data = {
       incentive_percentage:selected_Data.incentive_percentage,
       modifed:selected_Data.modifed,
       modified_remarks:selected_Data.modified_remarks,
       modified_by:user.id
     }

     if(user?.roles?.includes('admin')){
       updated_data['incentive_amount'] = selected_Data.incentive_amount
       updated_data['incentive_requirment'] = selected_Data.incentive_requirment
       updated_data['eligible_for_incentive'] = selected_Data.eligible_for_incentive
       updated_data['quarter'] = selected_Data.quarter
     } 

     try{
       const response = await UpdateEmployeeShareBasedOnUserService(selected_Data?._id,updated_data)
       if(response.status === 200){
         toast.success(response?.data?.data)
         setselected_Data({})
         setmodal(false)
         getdata()
       }
     }catch(err){
     }
   }
 }

 console.log("selected_Data here",selected_Data)

  // console.log("all_data : ",all_data)

  return (
    <div className='h-screen min-h-screen overflow-hidden  max-h-screen'>
      <Modal open={modal} closable={false} footer={false} width={300} className='rounded-[0px] z-50' >
         
         <AiOutlineClose size={13} className='absolute right-2 top-2 cursor-pointer z-90' onClick={()=>setmodal(false)} />

         <h6 className='text-[13px] leading-tight bg-slate-100 p-2 '>Use the below form to update the percentage of the user for their incentive</h6>
           
          
           <TextInput label="Incentive Percentage" name="incentive_percentage" value={selected_Data?.incentive_percentage} error={selected_Data_Err?.incentive_percentage} handlechange={handleChange} />
          
           {user?.roles?.includes('admin') &&
           <>
           <h6 className='text-[11px] font-[600] mb-1 mt-2' >{'Incentive'}</h6>
           <Select value={selected_Data?.incentive_requirment} options={insentive} onChange={(e)=>setselected_Data({...selected_Data,incentive_requirment:e})} size='small' placeholder="Quarter" className='border border-slate-300 w-[100%] py-[4px]' bordered={false} />


           <TextInput label="Quarter" name="quarter" value={selected_Data?.quarter} error={selected_Data_Err?.quarter} handlechange={handleChange} />
           <TextInput label="Incentive Amount" name="incentive_amount" value={selected_Data?.incentive_amount} error={selected_Data_Err?.incentive_amount} handlechange={handleChange} />

           <div className='flex items-center mt-1' onClick={()=>setselected_Data({...selected_Data,eligible_for_incentive:!selected_Data
           .eligible_for_incentive})}>
            {!selected_Data?.eligible_for_incentive ?  <BiCheckbox size={18} className='text-slate-300' /> :  <BiCheckboxSquare size={18} className='text-slate-700' />}
             <h6 className='text-[11px] ml-1' >Incentive Eligible</h6>
           </div>
           </>
           }

           <div className='flex items-center mt-1' onClick={()=>setselected_Data({...selected_Data,modifed:!selected_Data
           .modifed})}>
            {!selected_Data?.modifed ?  <BiCheckbox size={18} className='text-slate-300' /> :  <BiCheckboxSquare size={18} className='text-slate-700' />}
             <h6 className='text-[11px] ml-1' >Modify</h6>
           </div>

           {selected_Data?.modifed &&
           <TextAreaInput1 label="Modify Remarks" name="modified_remarks" error={selected_Data_Err?.modified_remarks} value={selected_Data?.modified_remarks} handlechange={handleChange}  />
           }

           {selected_Data?.modifed &&
           <div className='flex mt-2'>
             <ButtonFilledAutoWidth btnName="Save" onClick={updateData} />
             <h6 className='w-[10px]'></h6>
             <ButtonOutlinedAutoWidth btnName="Cancel" onClick={()=>setmodal(false)} />
           </div>}
     </Modal>
      <div className='flex'>
        <div className='min-w-44 max-w-44 -mt-5 w-44'>
          <div className='pt-5 border-r'>
            <DashboardMenu />
          </div>
        </div>
        <div className='flex w-full'>
        <div className='border-r min-w-[70%] w-[70%] max-w-[70%] '>
        <div className='mx-3 w-full mt-5'>
        <div className='flex items-center justify-between mx-2 '>
           <h6 className='font-[700] text-[13px] '>Payment Share ({pagination?.total})</h6>
           
           <div className='flex items-center mr-5'>
                        <div className='flex items-center'>
                        <h6 className='mr-2 font-[600] text-[12px]'>{page == 1 ? data?.length > 0 ? 1 : 0 : (page - 1) * pagination?.limit } - {pagination?.limit} of {pagination?.total} </h6>
                        <IconButton onClick={resetfunc} ><BsArrowRepeat size={16} /></IconButton>

                        <div>
                        <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>
                        <IconButton onClick={()=>{ page < pagination?.totalPages  ? setpage(page+1) : console.log('')}}><FiChevronRight className={`${(pagination?.totalPages === page || data?.length === 0) ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>
                        </div>
                        <div>
                          {/* <Select options={[{label:'2023',value:'2023'},{label:'2024',value:'2024'}]} size='small' placeholder="Quarter" className='border rounded-md w-[75px] mr-2' bordered={false} /> */}
                          <Select value={selectedperiod} options={period} onChange={(e)=>setselectedperiod(period?.find((v)=>v.value === e))} size='small' placeholder="Quarter" className='border rounded-md w-[50px]' bordered={false} />
                        </div>
      
                        </div>
           </div> 
           
        </div>

        <div className='h-[93vh] w-[98%] overflow-y-scroll'>
                        <div className='flex text-[13px] mx-2 mt-2 border border-slate-200'>
                            <h6 className='px-2 py-1 border-r border-slate-200 font-[600] min-w-[27%] w-[27%] max-w-[27%]'>Lead Info</h6>
                            <h6 className='px-2 py-1 border-r border-slate-200 font-[600] min-w-[16%] w-[16%] max-w-[16%]'>Amount</h6>
                            <h6 className='px-2 py-1 border-r border-slate-200 font-[600] min-w-[16%] w-[16%] max-w-[16%]'>Incentive Amt</h6>
                            <h6 className='px-2 py-1 border-r border-slate-200 font-[600] min-w-[12%] w-[12%] max-w-[12%]'>Days</h6>
                            <h6 className='px-2 py-1 border-r border-slate-200 font-[600] min-w-[12%] w-[12%] max-w-[12%]'>Eligible</h6>
                            <h6 className='px-2 py-1 border-r border-slate-200 font-[600] min-w-[7%] w-[7%] max-w-[7%]'>%</h6>
                            <h6 className='px-2 py-1 font-[600] '>Action</h6>
                        </div>
                        
                        {data?.map((d)=>(
                        <div key={d?._id} className={`flex text-[12px] mx-2 border-b border-l border-r border-slate-200 ${d?.is_deleted ? 'bg-slate-100 opacity-100' : ''}`}>
                            <h6 className='px-2 py-1 border-r border-slate-200  min-w-[27%] w-[27%] max-w-[27%]'>{d?.client?.contact_name} / {d?.client?.phone} <br></br> <p className='text-[11px]'>Company Name : <span className='font-[700]'>{d?.client?.company_name}</span></p><span className='text-[10px] font-[700]'>{moment(d?.createdAt)?.format('LLL')}</span> <p onClick={()=>navigator('/leads/contacts/detail',{state:d?.client?._id})} className='text-[9px] cursor-pointer font-[700] text-sky-900 underline'>View Detail</p></h6>
                            <h6 className='px-2 py-1 border-r border-slate-200 font-[700]  min-w-[16%] w-[16%] max-w-[16%]'>{rupee?.format(d?.amount).split('.')[0]}</h6>
                            <h6 className='px-2 py-1 border-r border-slate-200 font-[700] min-w-[16%] w-[16%] max-w-[16%]'>{rupee?.format(d?.incentive_amount).split('.')[0]}</h6>
                            <h6 className='px-2 py-1 border-r border-slate-200  min-w-[12%] w-[12%] max-w-[12%]'>{d?.days_took_for_payment_recieve}</h6>
                            <h6 className='px-2 py-1 border-r border-slate-200  min-w-[12%] w-[12%] max-w-[12%]'>{d?.eligible_for_incentive ?  <BiCheckboxSquare size={20} className='text-slate-700' /> : <BiCheckbox className='text-slate-300' size={20}/>}</h6>
                            <h6 className='px-2 py-1 border-r border-slate-200  min-w-[7%] w-[7%] max-w-[7%]'>{!d?.is_deleted && d?.incentive_percentage}</h6>
                            <h6 className={`px-2 py-1 ${!user?.roles?.includes('admin') ? 'cursor-not-allowed' :''} `}>{(!d?.is_deleted && (parseInt(selectedperiod?.value) == parseInt(d.quarter)) && user?.roles?.includes('admin') ) && <AiOutlineEdit className='cursor-pointer' onClick={()=>{user?.roles?.includes('admin') && setmodal(true);setselected_Data(d)}}  />}</h6>
                        </div>
                        ))}
        </div>
        </div>

         </div>
         <div className='border-r min-w-[30%] h-screen overflow-y-scroll overflow-x-hidden w-[30%] max-w-[30%] '>
         <h1 className='text-[13px] font-[700] w-[96%] mt-5 ml-2 bg-slate-100 px-2 py-1'>{state?.user?.name} Report</h1>


                <ReactApexChart options={chartOptions1} size={'10'} series={chartOptions1.series} type="bar" width={'100%'}  height={240} />


                <div className='mx-2 border-t border-slate-100'>
                <div className='flex bg-slate-100 p-2 justify-between mb-2 mt-4 border-b pb-2 border-slate-100'>
                <div >
                <h6 className='text-[13px] '>Current Target</h6>
                <h6 className='text-[12px] font-[700]'>{rupee?.format((graph_data?.current_target ))?.split('.')[0]}</h6>
                </div>  

                <div >
                <h6 className='text-[13px] '>Target Achieved</h6>
                <h6 className='text-[12px] font-[700]'>{rupee?.format(targetAchieved)?.split('.')[0]}</h6>
                </div>  
                </div>

                <div className='mx-2 flex justify-between border-t border-slate-100'>


<div className='mb-2 '>
<h6 className='text-[13px] '>Your Incentive </h6>
<h6 className='text-[12px] font-[700]'>{rupee?.format(incentiveAmount)?.split('.')[0]}</h6>
</div> 

{(roles_arr?.includes('hod') || roles_arr?.includes('manager')) &&  
<div className='mb-2 '>
<h6 className='text-[13px] '>Team Incentive </h6>
<h6 className='text-[12px] font-[700]'>{rupee?.format(graph_data?.team_member_incentive_amount)?.split('.')[0]}</h6>
</div>} 
</div>

{(roles_arr?.includes('hod') || roles_arr?.includes('manager')) &&  
<>
<div className='mb-2 pl-2 mt-1 border-t pb-2 border-slate-100'>
<h6 className='text-[13px] '>Total Incentive </h6>
<h6 className='text-[12px] font-[700]'>{rupee?.format(graph_data?.team_member_incentive_amount + incentiveAmount)?.split('.')[0]}</h6>
</div> 


            {graph_data?.team_member_incentive?.length === 0 &&
            <>
            <h6 className='bg-slate-100 p-2 text-[11px] leading-tight'>We Found that your team members not achieved any incentive to share you motivate them to reach there targets to get incentive.</h6>
            </>} 

            {graph_data?.team_member_incentive?.length > 0 && 
            <div className='mb-4'>
            <div className='flex border'>
              <h6 className='text-[11px] p-1 border-r  min-w-[24%] w-[24%] max-w-[24%]'>Employee</h6>
              <h6 className='text-[11px] p-1 border-r  min-w-[19%] w-[19%] max-w-[19%]'>Target</h6>
              <h6 className='text-[11px] p-1 border-r  min-w-[19%] w-[19%] max-w-[19%]'>Threshold</h6>
              <h6 className='text-[11px] p-1 border-r  min-w-[19%] w-[19%] max-w-[19%]'>Achieved</h6>
              <h6 className='text-[11px] p-1  min-w-[19%] w-[19%] max-w-[19%]'>Incentive</h6>
            </div> 
            {graph_data?.team_member_incentive?.map((d)=>(
            <div key={d?.employee?._id} className='flex border-l border-b border-r'>
              <h6 className='text-[11px] p-1 border-r  min-w-[24%] w-[24%] max-w-[24%] truncate'>{d?.employee?.name}</h6>
              <h6 className='text-[11px] p-1 border-r  min-w-[19%] w-[19%] max-w-[19%]'>{rupee?.format(d?.vertical_target)?.split('.')[0]}</h6>
              <h6 className='text-[11px] p-1 border-r  min-w-[19%] w-[19%] max-w-[19%]'>{rupee?.format(d?.threshold_target)?.split('.')[0]}</h6>
              <h6 className='text-[11px] p-1 border-r  min-w-[19%] w-[19%] max-w-[19%]'>{rupee?.format(d?.target_achieved)?.split('.')[0]}</h6>
              <h6 className='text-[11px] p-1  min-w-[19%] w-[19%] max-w-[19%] font-[700]'>{rupee?.format(d?.incentive_amount)?.split('.')[0]}</h6>
            </div> 
            ))}
            </div>}

            </>}  
                </div>
        </div>
        </div>
        </div>
    </div>
  )
}

export default EmployeeTargetsDetailInsentivedetail