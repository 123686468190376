import React, { useEffect, useState } from 'react'
import ProfileMenu from './ProfileMenu'
import { Modal, Select } from 'antd'
import { TextAreaInput1 } from '../../components/input'
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../components/button'
import { AiOutlineDelete, AiOutlineEdit, AiOutlinePlus } from 'react-icons/ai'
import toast from 'react-hot-toast'
import { CreateAutoTaskService, GetAutoTaskService, UpdateAutoTaskService } from '../../services/DailyTaskServices'
import {BiCheckbox,BiCheckboxSquare} from 'react-icons/bi'


function AutoTaskList() {


    const [dataOld,setdataOld] = useState({})
    const [modal,setmodal] = useState(false)
    const [add_task_automate,setadd_task_automate] = useState(false)
    const [datas,setdatas] = useState([])
    const [data,setdata] = useState({work_description:'',remarks:'',slots:'',department_name:''})
    const [error,seterror] = useState({work_description:'',remarks:'',slots:'',department_name:''})
     
    const slots = [
        {label:'09.00 AM - 11.00 AM',value:'09.00 AM - 11.00 AM'},
        {label:'11.00 AM - 01.00 PM',value:'11.00 AM - 01.00 PM'},
        {label:'02.00 PM - 04.00 PM',value:'02.00 PM - 04.00 PM'},
        {label:'04.10 PM - 10.00 PM',value:'04.00 PM - 10.00 PM'},
    ]

    const departmentArr = [
        {label:'Project Team',value:'Project Team'},
        {label:'Transaction Team',value:'Transaction Team'},
        {label:'FMS Team',value:'FMS Team'},
        {label:'HR Team',value:'HR Team'},
        {label:'Fidelitus Gallery',value:'Fidelitus Gallery'},
        {label:'Corp Team',value:'Corp Team'},
        {label:'Finance Team',value:'Finance Team'},
        {label:'Shilpa Foundation',value:'Shilpa Foundation'},
        {label:'Digital Media',value:'Digital Media'},
    ]

    useEffect(()=>{
        getData()
    },[])

    async function getData() {
        const response = await GetAutoTaskService()
        if(response?.data?.data !== null){
            let datas = response?.data?.data?.datas 
            setdatas(datas)
            setadd_task_automate(response?.data?.data?.add_task_automate)
        }
        setdataOld(response?.data?.data)
    }


    async function handlechange(e) {
        setdata({...data,[e.target.name]:e.target.value})
    }

    function adddata(){
        if(!data.work_description){
          seterror({...error,work_description:'This Field is required *'})
        }else if(!data.remarks){
          seterror({...error,remarks:'This Field is required *'})
        }else if(!data.slots){
          seterror({...error,slots:'This Field is required *'})
        }else{
           if(data.id !== undefined){
            let oldData = [...datas]
            oldData[data.id] = data
            setdatas(oldData)
            setdata({work_description:'',remarks:'',slots:'',department_name:''})
            seterror({work_description:'',remarks:'',slots:'',department_name:''})
            toast.success("Updated Successfully!")
           }else{
            let  oldData = [...datas]
            oldData.push({...data,id:oldData.length})
            setdatas(oldData)
            setdata({work_description:'',remarks:'',slots:'',department_name:''})
            seterror({work_description:'',remarks:'',slots:'',department_name:''})
            toast.success("Created Successfully!")
           }
        }
    }

    function deleteData(d){
        let filterData = datas?.filter((f)=>f?.id !== d?.id)
        setdatas(filterData)
        toast.success("Deleted Successfully!")
    }

    async function savedData() {
        if(datas.length !== 0){
        
        let sendData = {
            datas:datas,
            add_task_automate:add_task_automate
        }
        if(dataOld !== null){

            const response = await UpdateAutoTaskService(dataOld._id,sendData)
            if(response?.status == 200){
                toast.success("Updated Successfully!")
            }
        }else{
            const response = await CreateAutoTaskService(sendData)
            if(response?.status == 201){
                toast.success("Created Successfully!")
            }
        }
        }else{
            toast.error("You have not added any data to submit")
        }
    }

  return (
    <div className='px-2 mx-0 box-border'>


     <Modal open={modal} width={280} footer={false} closable={false}>
      <h6 className='text-[14px] font-[700]'>Create or update the task list</h6>  
            <h6 className='bg-slate-100 text-[10px] font-[500] p-1'>Use the below form to create or update the task list for you</h6> 
           
             
            <TextAreaInput1 
             mandatory={true}
             label={'Work Description'}  
             variant="standard"
             name="work_description"
             type="text"
             value={data?.work_description}
             error={error?.work_description}
             handlechange={handlechange}
             placeholder=""
             InputLabelProps={{
                 style: { color: '#fff', }, 
             }}
            />
            <TextAreaInput1 
                mandatory={true}
                label={'Remarks'}  
                variant="standard"
                name="remarks"
                type="text"
                value={data?.remarks}
                error={error?.remarks}
                handlechange={handlechange}
                placeholder=""
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>

<h6 className='text-[12px] font-semibold mb-1 mt-1'>Slots </h6>
                <Select
                value={data.slots} 
                error={error.slots}
                bordered={false}
                placeholder="" 
                onChange={(e)=>setdata({...data,slots:e})} 
                options={slots} 
                className='w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none  focus:border-transparent focus:ring-offset-0 focus:shadow-none'
                />

<h6 className='text-[12px] font-semibold mb-1 mt-1'>Department </h6>
                <Select
                value={data.department_name} 
                error={error.department_name}
                bordered={false}
                placeholder="" 
                onChange={(e)=>setdata({...data,department_name:e})} 
                options={departmentArr} 
                className='w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none  focus:border-transparent focus:ring-offset-0 focus:shadow-none'
                />



<div className='flex mt-2 border-t pt-2 w-full items-end '>
                <div className='mr-2'>
                <ButtonOutlinedAutoWidth btnName="Cancel" onClick={()=>setmodal(false)} />
                </div>
                <ButtonFilledAutoWidth btnName="Save" onClick={()=>adddata()} />
            </div>

      </Modal>

    <div className='flex'>
        <ProfileMenu />

        <div className='ml-6  w-full ml-4 mt-4 pt-5'>
            
          <div className='w-64'>
            <h6 className='text-[14px] font-[700]'>Create The Automate Daily Task List</h6>
            <h6 className='bg-slate-100 text-[11px] font-[500] p-1'>use the below form to create or edit the task automate</h6>
          
            <div className='flex items-center justify-between mt-2 border-b pb-2'>
                 <h6 className='text-[11px]'>Total Data List ({datas?.length})</h6> 
                 <AiOutlinePlus onClick={()=>setmodal(true)} className='bg-slate-100 p-[2px]' />
            </div>  
        </div>


            
            <div className='border mt-2 w-[50%]'>
            <div className='flex text-[11px] bg-slate-100  w-[100%]'>
                <h6 className='p-1 border-r w-[10%]'>Sl No</h6>
                <h6 className='p-1 border-r w-[40%]'>Work Description</h6>
                <h6 className='p-1 border-r w-[40%]'>Remarks</h6>
                <h6 className='p-1  w-[10%]'>Action</h6>
            </div>

            {datas?.map((d,i)=>(
                <div key={i} className='flex text-[11px] border-t w-[100%]'>
                    <h6 className='p-1 border-r w-[10%]'>{d?.id}</h6>
                    <h6 className='p-1 border-r w-[40%]'>{d?.work_description} <br></br> {d?.department_name}</h6>
                    <h6 className='p-1 border-r w-[40%]'>{d?.remarks}</h6>
                    <h6 className='flex items-center w-[10%]'>
                        <AiOutlineEdit className='mx-2' onClick={()=>{setdata({...data,...d});setmodal(true)}} />
                        <AiOutlineDelete onClick={()=>deleteData(d)} />
                    </h6>
                 </div>   
            ))}  
            </div>


            <div onClick={()=>setadd_task_automate(!add_task_automate)} className='flex items-center mt-2'>
                {!add_task_automate ? <BiCheckbox size={25} className='text-slate-400' /> : <BiCheckboxSquare size={25} className='text-slate-800' /> }
                <h6 className='text-[11px] font-[600]'>AutoMate</h6>
            </div>

            <div className='w-64 mt-3'>
           <ButtonFilledAutoWidth onClick={savedData} btnName="Save Auto Task" />
             
          </div>  
            

          </div>  

          

       
    </div>     
   

    </div>
  )
}

export default AutoTaskList