import React, { useEffect, useState } from 'react'
import SettingsMenu from '../staticscreens/SettingsMenu'
import { DeleteInvoiceService, FilterInvoiceServiceAdmin, GetInvoiceServiceAll, UpdateInvoiceServiceAdmin } from '../../services/InvoiceServices'
import { AiOutlineEdit,AiOutlineDelete } from 'react-icons/ai'
import { DatePicker, Modal,Select } from 'antd'
import { TextInput } from '../../components/input'
import TextArea from 'antd/es/input/TextArea'
import { ButtonFilled, ButtonOutlinedAutoWidth } from '../../components/button'
import {BiCheckbox,BiCheckboxSquare} from 'react-icons/bi'
import moment from 'moment'
import {IoIosClose} from 'react-icons/io';
import { toast } from 'react-hot-toast';
import {BsArrowRepeat} from 'react-icons/bs';
import {FiChevronRight,FiChevronLeft} from 'react-icons/fi';
import { IconButton } from '@mui/material'
import { GetSearchService } from '../../services/AuthServices';


function InvoiceRequestModify() {

  const [modal,setmodal] = useState(false)
  const [filter,setfilter] = useState({search:'',filter_Applied:''})
  const [data,setdata] = useState([])
  const [users,setusers] = useState([])
  const [pagination,setpagination] = useState({})
  const [page,setpage] = useState(1)
  const [selected_user,setselected_user] = useState({})
  const [selectedData,setselectedData] = useState({})
  const [selectedDataErr,setselectedDataErr] = useState({})

  useEffect(()=>{
    if(!filter.filter_Applied){
      getData()
    }else{
      filterData()
    }
  },[page,filter.filter_Applied])

  async function getData(){
      const response = await GetInvoiceServiceAll(page)
      setdata(response?.data?.datas)
      setpagination(response?.data?.pagination)
  }

  async function filterData(){
    const response = await FilterInvoiceServiceAdmin(filter?.search,selected_user?.value !== undefined ? selected_user?.value : '',page)
    setfilter({...filter,filter_Applied:true})
    setdata(response?.data?.datas)
    setpagination(response?.data?.pagination)
  }

  async function searchUser(v){
    const response =  await GetSearchService(v,1)
    let arr = []
    let d = response?.data?.datas
    d.forEach((d1)=>{
      arr.push({label:d1.name,value:d1?._id})
    })
    setusers(arr)
    return arr
  }

  // console.log("selected Data",selectedData)
  let rupee = new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
  });

  function handlechange1(e){
    setselectedData({...selectedData,[e.target.name] : e.target.value})
    setselectedDataErr({...selectedDataErr,[e.target.name] : ''})
  }

  async function updatedata(){
    if(!selectedData.amount){
      setselectedDataErr({...selectedDataErr,amount:'This Field is required'})
    }else if(!selectedData.invoice_raising_date){
      setselectedDataErr({...selectedDataErr,invoice_raising_date:'This Field is required'})
    }else{
      const send_data = {
        amount:selectedData.amount,
        invoice_raising_date:selectedData.invoice_raising_date.slice(0,10),
        other_details:selectedData.other_details,
        invoice_no:selectedData.invoice_no,
        invoice_amount_with_gst:selectedData.invoice_amount_with_gst,
        invoice_amount_without_gst:selectedData.invoice_amount_without_gst,
      }

      const response = await UpdateInvoiceServiceAdmin(selectedData._id,send_data)
      if(response.status === 200){
        toast.success("Updated Successfully")
        setselectedData({})
        setselectedDataErr({})
        getData()
        setmodal(false)
      }
    }
  }

  async function resetfunc(){
    setpage(1)
    setselected_user({})
    setfilter({filter_Applied:false,search:''})
    const response = await GetInvoiceServiceAll(1)
    setdata(response?.data?.datas)
    setpagination(response?.data?.pagination)
  }

  async function deleteData(id){
    const response = await DeleteInvoiceService(id)
    if(response.status === 200){
      toast.success('Deleted Successfully')
      getData()
    }
  }

  async function handlechange(val,type){
    if(type === 'user'){
        setselected_user(users.find(d=>d.value === val))
    }
  }

  console.log("selectedData",selectedData)

  return (
    <div className='h-screen min-h-screen max-h-screen overflow-hidden'>
        <Modal open={modal}
        closable={false}
        maskClosable={false}
        onClose={()=>setmodal(false)}
        bodyStyle={{borderRadius:'0px !important'}}
        style={{maxWidth:'300px',borderRadius:'0px !important'}}
        footer={null}>
        <div style={{maxWidth:'300px',borderRadius:'0px !important'}} className="relative z-0 ">
          <h6 className="font-bold text-[15px]  mb-2 w-full">Add Info to raise Invoice For Finance</h6>
          <IoIosClose size={20} className="absolute -right-4 -top-4 z-10" onClick={()=>{setmodal(false)}} />
           

          <div className='mb-2'>
            <TextInput
              mandatory={true}
              label={'Requested Amount'}  
              variant="standard"
              name="amount"
              type="text"
              value={selectedData.amount}
              error={selectedDataErr.amount}
              handlechange={handlechange1}/>
          </div> 

         
          <div className='mb-2'>
            <TextInput
              mandatory={true}
              label={'Amount Incl Gst'}  
              variant="standard"
              name="invoice_amount_with_gst"
              type="text"
              value={selectedData.invoice_amount_with_gst}
              error={selectedDataErr.invoice_amount_with_gst}
              handlechange={handlechange1}/>
          </div> 

          <div className='mb-2'>
            <TextInput
              mandatory={true}
              label={'Amount Excl Gst'}  
              variant="standard"
              name="invoice_amount_without_gst"
              type="text"
              value={selectedData.invoice_amount_without_gst}
              error={selectedDataErr.invoice_amount_without_gst}
              handlechange={handlechange1}/>
          </div> 

          {/* 1690610440092.pdf */}

          <div className='mb-2'>
           <TextInput
              mandatory={true}
              label={'Invoice No'}  
              variant="standard"
              name="invoice_no"
              type="text"
              value={selectedData.invoice_no}
              error={selectedDataErr.invoice_no}
              handlechange={handlechange1}/>
          </div> 

          <div style={{width:'100%'}}>
            <h6 className='text-[12px] font-[600] mb-1' >{'Invoice Raise Date'}</h6>
            <DatePicker 
            placeholder='' 
            // bordered={false} 
            ampm={false} 
            style={{maxHeight:'40px',borderRadius:'3px',width:'100%'}} 
            className='border-l-4 border-l-slate-600 w-full border outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none'
            inputFormat="MM/dd/yyyy" value={moment(selectedData.invoice_raising_date)} onChange={(v) => {setselectedData({...selectedData,invoice_raising_date:v});setselectedDataErr({...selectedDataErr,invoice_raising_date:''})}} />
            {(selectedDataErr?.invoice_raising_date !== '' )  && <h6 className='text-red-600 text-[10px]'>{selectedDataErr?.invoice_raising_date}</h6>}
          </div>
          
          <div className='flex items-center mt-2'>
            <div className='flex items-center mt-0 mr-2'>
            {selectedData?.payment === '1' ?  <BiCheckboxSquare size={22} className='text-slate-600' onClick={()=>setselectedData({...selectedData,payment:''})} />  : <BiCheckbox size={22} className='text-slate-300' onClick={()=>setselectedData({...selectedData,payment:'1'})}  /> }

            {/* <input type={'checkbox'} checked={invoiceData?.payment === '1'} onChange={()=>setinvoiceData({...invoiceData,payment:'1'})} />  */}
            <h6 className="text-[13px] font-[600] ml-2">Cash</h6> 
            </div>  

            <div className='flex items-center mt-0 mr-2'>
            {selectedData?.payment === '0' ?  <BiCheckboxSquare size={22} className='text-slate-600' onClick={()=>setselectedData({...selectedData,payment:''})} />  : <BiCheckbox size={22} className='text-slate-300' onClick={()=>setselectedData({...selectedData,payment:'0'})} /> }
            {/* <input type={'checkbox'} checked={invoiceData?.payment === '0'} onChange={()=>setinvoiceData({...invoiceData,payment:'0'})} />  */}
            <h6 className="text-[13px] font-[600] ml-2">Online</h6> 
            </div>  
          </div>


          <h6 className='text-[12px] font-[600] mb-1' >{'Invoice Details'}</h6>
            <TextArea style={{borderRadius:'2px'}} value={selectedData.other_details} onChange={(e)=>setselectedData({...selectedData,other_details:e.target.value})} />
          
          {(selectedData?.amount !== '' && selectedData?.invoice_raising_date !== ''  && selectedData?.invoice_raising_date !== undefined &&  selectedData?.invoice_raising_date !== null) &&
           <div className='mt-2'>
           <ButtonFilled btnName={'Confirm'} onClick={updatedata} />
          </div>  
          }
          </div>
        </Modal>
        <div className='flex'>
            <SettingsMenu />
            <div className='mx-2  w-full'>
                <h6 className='border-b text-[14px] mt-5 font-[600] pb-1 w-full'>Invoice Request</h6>

                <div className='border-b flex  pb-1 items-center justify-between my-1'>
                  <h6 className='text-[12px] py-1 font-[400]'>Total Found ({pagination?.total})</h6>
                  <div className='flex items-center'>
                  <div className='flex items-center text-[12px] mr-2'>

                    <h6 className='mr-2 font-[600]'>{page == 1 ? data?.length > 0 ? 1 : 0 : (page - 1) * pagination?.limit } - {pagination?.limit} of {pagination?.total} </h6>
                    <IconButton onClick={resetfunc}><BsArrowRepeat size={16} /></IconButton>

                    <div>
                    <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}} ><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`} size={16} /></IconButton>
                    <IconButton onClick={()=>{ page < pagination?.totalPages  ? setpage(page+1) : console.log('')}}><FiChevronRight className={`${(pagination?.totalPages === page || data?.length === 0)  ? 'opacity-50' : 'opacity-100'}`} size={16} /></IconButton>
                  
                    </div>
                    </div>

                    <div className='border mr-1 rounded-md py-[2px]'>
                    <Select
                        placeholder='Select User'
                        bordered={false}
                        size='small'
                        filterOption={false}
                        showSearch={true}
                        allowClear={true}
                        onClear={()=>setselected_user(null)}
                        defaultValue={selected_user}
                        value={selected_user}
                        style={{ width: 110 }}
                        onSearch={(e)=>searchUser(e,'user')}
                        options={users}
                        onChange={(v)=>handlechange(v,'user')}
                    />
                    </div>

                    <input value={filter?.search} onChange={(e)=>setfilter({...filter,search:e.target.value})} className="w-[100px] text-[11px] mr-2 focus:outline-none border rounded-md py-1.5 px-2" />
                    <ButtonOutlinedAutoWidth onClick={filterData} btnName="Apply Filter" />
                  </div>
                </div>

                <div className='h-[85vh] min-h-[85vh] max-h-[85vh] overflow-y-scroll'>
                <div className=' grid grid-cols-5 gap-1 mt-2'>
                  {data?.map((d)=>( 

                    
                  <div key={d?._id} className='border p-2 relative'>
                    <AiOutlineDelete size={13} className='absolute text-slate-500 cursor-pointer right-7 top-1' onClick={()=>{deleteData(d._id)}} />
                    <AiOutlineEdit size={13} className='absolute text-slate-500 cursor-pointer right-1 top-1' onClick={()=>{setselectedData(d);setmodal(true)}} />
                   <h6 className='text-[12px]'>Amount : <span className='font-[700]'>{rupee?.format(d?.amount).split('.')[0]}</span></h6>
                   {(d?.invoice_no !== '' && d?.invoice_no !== undefined) && <h6 className='text-[12px]'>Invoice No : <span className='font-[700]'>{d?.invoice_no}</span></h6>}
                   <h6 className='text-[11px] border-b pb-1 mb-1 border-slate-100 mt-1'>Client : <b>{d?.client?.contact_name}</b> Company info : {d?.client?.company_name}</h6>
                   <h6 className='text-[12px]'>Invoice Info </h6>
                   <h6 className='text-[11px] bg-slate-100 p-1 mt-1'>User : <b>{d?.employee?.name}</b> Lead info : {d?.other_details}</h6>
                  </div>
                  ))}

                </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default InvoiceRequestModify