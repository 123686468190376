import React from 'react'

function InsentiveRequirment() {
  return (
    <div className='px-5 pt-10'>
        <h6 className='text-[15px] font-[900] border-b'>InsentiveRequirment</h6>
    </div>
  )
}

export default InsentiveRequirment