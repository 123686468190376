import { deleteRequest, get, publicPost, put } from "../helpers/apihelpers";

export const CreateStageService=async(data)=>{
    try {
        const result = await publicPost(`api/stage/create`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateStageService=async(data,id)=>{
    try {
        const result = await put(`api/stage/update/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetStageService=async(type,department=null)=>{
    try {
        const result = await get(`api/stage/get/${type}?department=${department}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteStageService=async(id,data)=>{
    // console.log('data',data)
    try {
        const result = await deleteRequest(`api/stage/delete/${id}`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}