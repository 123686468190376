import React,{useState,useEffect} from 'react'
import { Tooltip } from '@mui/material';
import {  ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../../components/button';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import SettingsMenu from '../../staticscreens/SettingsMenu';
import {AiOutlineEdit,AiOutlineDelete} from 'react-icons/ai'
import { Modal } from 'antd';
import moment from 'moment';
import { DeleteRoleService, GetRoleService } from '../../../services/RoleServices';

function RoleList() {


    const [data,setdata] = useState([])
    const [selecteddata,setselecteddata] = useState({})
    const [modal, setModal] = useState(false);
    const navigate = useNavigate()


  
    useEffect(()=>{
        getRoles()
    },[])

   

  
  async function getRoles(){
   const response = await GetRoleService()
   setdata(response.data.datas)
  }  

  async function deleteRole(){
    const response = await DeleteRoleService(selecteddata.id)
    if(response.status === 200){
        setModal(false)
        toast.success('Roles Deleted Successfully')
        getRoles()
    }
  }
  return (
    <div className='h-screen max-h-screen box-border overflow-hidden'>
   
    <Modal
       keepMounted
       open={modal}
       onClose={()=>setModal(false)}
       width={300}
       footer={false}
       closable={false}
      
     >
       <div >
         <h6 className="font-bold text-[15px] text-center mb-2 w-full">Are you sure?</h6>
         <h6 className='bg-slate-100 text-center text-[12px] p-1.5 font-[400]'>After deleting you cannot retrieve it back before deleting check once whether you have used it in some data</h6>
         <div className='flex justify-end mt-3 '>
           {/* <div className='mr-1 w-full'> */}
           <ButtonOutlinedAutoWidth btnName={'Cancel'} onClick={()=>setModal(false)} />
           {/* </div> */}
           <div  className='ml-2'>
           <ButtonFilledAutoWidth btnName={'Confirm'} onClick={()=>deleteRole()}  /> 
           </div>
         </div>
       </div>
     </Modal>
       
     <div className='block sm:flex'>
       <SettingsMenu />

       <div className='w-[88%] px-4'>
       <div >
       <div className='pt-5'>
       <div className="flex justify-between align-center items-center border-b pb-2 ">
       <span className="font-black text-[14px]">Role's ({data?.length})</span>

       
        <ButtonFilledAutoWidth  btnName="Add Roles" onClick={()=>navigate('create')} /> 
       </div>
       </div>

       {data?.length === 0 &&
        <div className='grid place-items-center mt-20  items-center justify-center'>
        <img src={'https://fidecrmfiles.s3.amazonaws.com/NoDataFound1.png'} className='w-40 h-40 object-contain' /> 
        <h6 className='font-bold text-[14px] -mt-10'>No data found</h6>
        <h6 className='font-[500] w-[70%] text-center text-slate-700 text-[12.5px] -mt-2'>Oops we couldn't find any data added based on your current page option please click on add the data button to see list here.</h6>
      </div>
       }


      <div className='overflow-x-hidden h-[90vh] overflow-y-scroll'>
       {data?.length > 0 &&
       <div className='grid grid-cols-6 gap-1 mt-2'>
           {data?.map((d,i)=>(
           <div key={i}  className='border items-center relative justify-between px-2 py-1 border-b'>
               <h6 className='text-[13px] font-[500] '>{d?.display_name} / {d?.role_name}</h6>
               <h6 className='text-[10px] font-[500] bg-slate-100 mt-1 p-1'>Created At : <span className='text-[10px] font-[800]'>{moment(d.createdAt).format('LLL')}</span> </h6>
               <div className='absolute right-1 bg-white top-1.5 flex'>
               <Tooltip title="Edit" >
                <span><AiOutlineEdit size={14} className="cursor-pointer" onClick={()=>{navigate('edit',{state:d})}}/></span>
               </Tooltip>
               <Tooltip title="Delete">
                <span><AiOutlineDelete size={14} className='ml-2 cursor-pointer'  onClick={()=>{setselecteddata({department_name:d.department_name,id:d.id});setModal(true)}}/></span>
               </Tooltip>
               </div>
           </div>
           ))}
       </div>}
         </div>
       </div>
      </div> 
     
     </div> 
   </div>
  )
}

export default RoleList