import React,{useState,useEffect} from 'react'
import { useLocation,useNavigate } from 'react-router-dom'
import { DeleteLeadReminderService, GetLeadReminderService } from '../../../../services/LeadServices'
import {AiOutlinePlus} from 'react-icons/ai'
import moment from 'moment'
import { toast } from 'react-hot-toast'
import { MobileDrawerBackAdd } from '../../../../components/appbars/mobile/DashboardMobile'
import { Box } from '@material-ui/core'

function LeadReminderlist() {
  
  const {state} = useLocation()
  const navigate = useNavigate()
  const [reminder,setreminder] = useState([])


  useEffect(()=>{
    getreminders()
  },[])


  async function getreminders(){
    const response = await GetLeadReminderService(state)
    setreminder(response.data.datas)
  }

  async function deletereminder(v){
    const response = await DeleteLeadReminderService(v)
    if(response.status === 200){
      getreminders()
      toast.success('Reminder Deleted Successfully')
    }
  }

  return (
    <Box>
         <Box sx={{display:{sm:'block',xs:'block',md:'none',lg:'none'}}} className="sm:block md:hidden">
          <MobileDrawerBackAdd name={`Reminder(${reminder?.length})`} goback={()=>navigate(-1)} />
         </Box>

       <Box sx={{padding:{xs:'0px',sm:'0px',md:'10px',lg:'10px'}}} className="p-2 hidden md:block">
          <Box sx={{display:{sm:'none',xs:'none',lg:'block',md:'block'}}}>
          <div className='flex items-center justify-between '>
          <h6 className='font-[800] text-[14px]'>Reminder({reminder?.length})</h6>
          <AiOutlinePlus size={24}  onClick={()=>navigate('create',{state:state})}/>
          </div>
          </Box>

        {reminder?.map((n)=>(
        <div key={n?._id} className='my-2'>
            <h6 className='font-[700] text-[14px]'>{n?.title}</h6>
            <h6 className='font-[500] text-[12px] -mt-1.5'>{n?.description}</h6>
            <div className='flex'>
              <h6 className='font-[400] text-[10px]'>{moment(n?.createdAt).format('ll')}</h6>
              <h6 className='font-[800] text-[10px] ml-3 cursor-pointer text-[#158a93]' onClick={()=>deletereminder(n?._id)}>Delete</h6>
            </div>  
        </div>))}
        </Box>

    </Box>
  )
}

export default LeadReminderlist