import React, { useEffect, useState } from 'react'
import ProfitLossMenu from './ProfiltLossMenu'
import { useLocation, useNavigate } from 'react-router-dom'
import { IconButton, Tooltip } from '@mui/material'
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../components/button'
import { DatePicker, Modal } from 'antd'
import { BsArrowRepeat } from 'react-icons/bs'
import { FiChevronLeft,FiChevronRight } from 'react-icons/fi'
import moment from 'moment'
import { AiOutlineEdit,AiOutlineDelete } from 'react-icons/ai'
import toast from 'react-hot-toast'
import { DeleteVerticalProfitLossService, GetVerticalProfitLossService } from '../../services/VerticalProfitLoss'
import { useSelector } from 'react-redux'
import Priceconstants from '../../constants/imageConstants'

function ProfitLossList() {

    const path = useLocation()
    const path1 = path?.pathname?.split('/')[2]
    const navigate = useNavigate()

    const user = useSelector(state=>state.Auth.user)
  
    const [modal,setmodal] = useState(false)
    const [selected_data,setselected_data] = useState({})
    const [data,setdata] = useState({})
    const [page,setpage] = useState(1)
    const [search,setsearch] = useState({text:'',from_date:'',to_date:'',from_date1:'',to_date1:'',activate:false})
   

    useEffect(()=>{
        if(search?.activate){
           applyfilterfunction(page)
        }else{
           getdata(page)
        }
    },[page,path1])
   
    async function resetfunc(){
        setsearch({text:'',from_date:'',to_date:'',from_date1:'',to_date1:'',activate:false})
        const response = await GetVerticalProfitLossService({from_date1:'',to_date1:'',page:1})
        setdata(response?.data)
      }
  
      async function applyfilterfunction(v = ''){
        setsearch({...search,activate:true})
        const response = await GetVerticalProfitLossService({from_date1:search?.from_date1,to_date1:search?.to_date1,page:v !==  '' ? v :page})
        setdata(response?.data)
      }

      async function getdata(page){
        const response = await GetVerticalProfitLossService({from_date1:search?.from_date1,to_date1:search?.to_date1,page})
        setdata(response?.data)
      }

      async function gotoedit(e){
        navigate('edit',{state:e})
      }

      async function deleteDatafunc(){
        const response = await DeleteVerticalProfitLossService(selected_data?._id)
        if(response?.status === 200){
          toast.success("Deleted Successfully")
          setmodal(false)
          getdata(page)
          setselected_data({})
        }else{
            toast.success("Error : Deleted Successfully")
            setmodal(false)
            getdata(page)
            setselected_data({})
        }

      }

      console.log("data?.datas",data?.datas?.length)

  return (
    <div className='flex max-h-screen min-h-screen overflow-hidden'>
        <ProfitLossMenu /> 
       < Modal open={modal} width={320} closable={false} footer={false}>
          <div> 

            <h6 className='text-[15px] font-[700]'>Delete Data</h6>
            <h6 className='text-[11px] leading-tight p-2' >Before Deleting the data be sure that it is required or not for further purpose.</h6>
             
             <div className='flex items-center border-t pt-2'>
                <ButtonOutlinedAutoWidth btnName="Cancel" onClick={()=>setmodal(false)} />
                <h6 className='w-[10px]'></h6>
                <ButtonFilledAutoWidth btnName="Sure" onClick={deleteDatafunc} />
             </div>
          </div>
       </Modal>
        <div className='w-[88%] px-4 mt-2'>
            <div className='flex items-center justify-between border-b pb-2'>
                <h6 className='font-[700] text-[14px] '>Total <span className='capitalize'>{path1}</span> ({data?.pagination?.total})</h6>
                <div className='flex items-center'>
                <div className='flex items-center text-[12px] mr-2'>

                    <h6 className='mr-2 font-[600]'>{page == 1 ? data?.datas?.length > 0 ? 1 : 0 : (page - 1) * data?.pagination?.limit } - {data?.pagination?.limit} of {data?.pagination?.total} </h6>
                    <IconButton onClick={resetfunc}><BsArrowRepeat size={16} /></IconButton>

                    <div>
                    <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>
                    <IconButton onClick={()=>{ page < data?.pagination?.totalPages  ? setpage(page+1) : console.log('')}}><FiChevronRight className={`${(data?.pagination?.totalPages === page || data?.datas?.length === 0)  ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>

                    </div>
                </div>


                <DatePicker size='small' style={{fontSize:'11px'}} ampm={false} placeholder='From Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.from_date} onChange={(v,v1) => {setsearch({...search,from_date:v,from_date1:v1})}} /> 

                <DatePicker size='small' style={{fontSize:'11px'}} ampm={false} placeholder='To Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.to_date} onChange={(v,v1) => {setsearch({...search,to_date:v,to_date1:v1})}} /> 

            <ButtonOutlinedAutoWidth btnName="Add Filter" onClick={()=>applyfilterfunction(1)} /> 
          
            <div className='ml-2'>
            <ButtonFilledAutoWidth btnName="Add Data" onClick={()=>navigate('create')}/> 
            </div>
                </div>
            </div>
             
            <div className='max-h-[90vh] overflow-y-scroll'>
                <div className='grid grid-cols-5 gap-1 mt-2'>
                {data?.datas?.map((d)=>(
                    <div  key={d?._id} className='border px-1.5 py-1 mt-1 relative mx-1 relative cursor-pointer' >
                                <div className='bg-sky-100 p-2'>
                                {(user?.roles?.includes('hod')||user?.roles?.include('delete_data')||user?.roles?.include('admin')) &&
                                <Tooltip title="Delete"><span className='absolute bg-white p-1 right-0  top-0'><AiOutlineDelete size={20} className='absolute bg-white p-1 right-6 top-0' onClick={()=>{setselected_data(d);setmodal(true)}} /></span></Tooltip>}
                                <Tooltip title="Edit"><span className='absolute bg-white p-1 right-0  top-0'><AiOutlineEdit size={20} className='absolute bg-white p-1 right-1 top-0' onClick={()=>{gotoedit(d)}} /></span></Tooltip>
                                <h6 className='text-[11px] ' >Profit/Loss Data for month : <span className='font-[600] text-[11px] line-clamp-2'><span className='font-[400]'>CreatedAt :-</span>  {moment(d?.createdAt)?.format('ll')}</span> </h6>
                                </div>

                                {(d?.department !== '' && d?.department !== null && d?.department !== undefined)  &&
                                <h6 className='text-[11px] flex pt-1' >Department : <span className='pl-1 font-[600] text-[11px] line-clamp-2'>{d?.department?.department_name}</span> </h6>}

                                <div className='border mt-2'> 
                                    <div className='flex text-[11px] items-center border-b'> 
                                        <h6 className='w-[50%] p-1 border-r'>Revenue</h6>
                                        <h6 className='font-[800] p-1'>{Priceconstants(d?.revenue === null ? 0 : d?.revenue)}</h6>
                                    </div> 
                                    <div className='flex text-[11px] items-center border-b'> 
                                        <h6 className='w-[50%] p-1 border-r'>Profit</h6>
                                        <h6 className='font-[800] p-1'>{Priceconstants(d?.profit === null ? 0 : d?.profit)}</h6>
                                    </div> 
                                    <div className='flex text-[11px] items-center border-b'> 
                                        <h6 className='w-[50%] p-1 border-r'>Expense</h6>
                                        <h6 className='font-[800] p-1'>{Priceconstants(d?.expense === null ? 0 : d?.expense)}</h6>
                                    </div> 
                                    <div className='flex text-[11px] border-b items-center'> 
                                        <h6 className='w-[50%] p-1 border-r'>Net Profit</h6>
                                        <h6 className='font-[800] p-1'>{Priceconstants(d?.net_profit === null ? 0 : d?.net_profit)}</h6>
                                    </div> 

                                    {d?.new_leads?.length > 0 &&
                                    <div className='flex text-[11px] border-b items-center'> 
                                        <h6 className='w-[50%] p-1 border-r'>New Leads</h6>
                                        <h6 className='font-[800] p-1'>{d?.new_leads?.length}</h6>
                                    </div>}

                                    {d?.expenses_bifurcation?.length > 0 &&
                                    <div className='flex text-[11px] border-b items-center'> 
                                        <h6 className='w-[50%] p-1 border-r'>Expenses Bifurcation</h6>
                                        <h6 className='font-[800] p-1'>{d?.expenses_bifurcation?.length}</h6>
                                    </div>}

                                    {d?.new_events?.length > 0 &&
                                    <div className='flex text-[11px] border-b items-center'> 
                                        <h6 className='w-[50%] p-1 border-r'>New Events</h6>
                                        <h6 className='font-[800] p-1'>{d?.new_events?.length}</h6>
                                    </div>}
                                </div>  
                                

                                <h6 className='text-[10px] ml-1 font-[400] mt-0.5' >Created By : <span className='pl-1 font-[600] text-[11px] -ml-1 line-clamp-2'>{d?.created_by?.name}</span></h6>
                                <h6 className='text-[10px] ml-1 font-[400] mt-0.5' >Created At : {moment(d?.createdAt).format('LLL')}</h6>

                    </div>  
                ))} 

                </div>
            </div> 
        </div>

    </div>
  )
}

export default ProfitLossList