import React, { useEffect, useState } from 'react'
import DashboardMenu from './DashboardMenu'
import { GetEmployeeShareOnMySelf } from '../../services/AdminServicesfile/TargetServices'
import { useSelector } from 'react-redux'
import ReactApexChart from 'react-apexcharts';
import {BiCheckbox,BiCheckboxSquare} from 'react-icons/bi'


function InsentiveDashboard() {

  const user = useSelector(state=>state.Auth)
  const [data1,setdata1] = useState({})
  const [incentiveAmount,setincentiveAmount] = useState(0)
  const [viewAll,setviewAll] = useState(false)

  const [chartOptions1,setchartOptions1] = useState({
    series: [],

  chart: {
    toolbar: {
      show: false
    }
  },
    plotOptions: {
      
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded',
        dataLabels: {
          position: 'top', // top, center, bottom
        },
        
      },
    },
    dataLabels: {
      enabled: false
    },
    grid:{
      show: true,
      borderColor: '#fafafa',
    },
  
    stroke: {
      // show: true,
      width: 1,
      
      curve: 'smooth',
      colors: ['transparent']
    },
    xaxis: {
      categories: ['Target', 'Threshold', 'Revenue', 'Incentive'],
    },
    yaxis: {
      title: {
        text: 'Count'
      }
    },
    fill: {
      opacity: 1
    },
    tooltip: {
      y: {
        formatter: function (val) {
          console.log("val",val)
          return  val
        }
      }
    },
    fill:['#027cd1']
  })

  useEffect(()=>{
    getData()
  },[])  

  let rupee = new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
  });

  async function getData(){
    try{
        const response = await GetEmployeeShareOnMySelf(1,user?.id)

        // console.log("response?.data",response?.data)
        // setdata(response?.data?.datas?.datas)
        // setpagination(response?.data?.pagination)

        let d1 = response?.data?.datas?.graph_data
        setdata1(d1)
        

        // settargetAchieved(d1?.revenue)
        setincentiveAmount(d1?.incentive)
        


        let series = [
            {
                name:'amount',
                data: [
                     d1?.current_target ,
                     Math?.round(d1?.current_threshold),
                     Math?.round(d1?.revenue),
                     (user?.roles?.includes('manager') || user?.roles?.includes('hod'))  ? Math?.round(d1?.incentive + d1?.team_member_incentive_amount) : Math?.round(d1?.incentive)
                 ]
            }
        ]
            
        setchartOptions1({...chartOptions1,series:[...series]})
    }catch(err){
        console.log("err",err)
    }

  } 

  
  

  return (
    <div className='min-h-screen h-screen max-h-screen'>
        <div className='flex'>
            <DashboardMenu />
        <div className='min-h-screen mx-3 w-[86%] h-screen max-h-screen overflow-hidden'>
            <h6 className='mt-5 text-[14px] font-[700] border-b pb-2'>Incentive Dashboard</h6>
            
            <div className='flex'>
            <div className='w-[80%] overflow-y-scroll min-w-[80%] max-w-[80%] border-r pr-3'>
            <div className='h-[85vh] min-h-[85vh] max-h-[85vh]'>
            <h6 className='h-4'></h6>
            <div className='p-2 border'>
                <h6 className='text-[13px] font-[700] mt-4'>Revenue Report</h6>
                <ReactApexChart options={chartOptions1} size={'10'} series={chartOptions1.series} type="bar" width={'100%'}  height={240} />
            </div>


            <div className='mt-5'>
                {(user?.roles?.includes('manager') || user?.roles?.includes('hod'))  &&
                 <div>
                    <h6 className='text-[13px] font-[400] mb-2'>Your Incentive For Period </h6>
                    <span className='flex w-[400px] border-slate-100 space-evenly items-center border'>
                        <h6 className='px-2 py-1 text-[12.5px] font-[600]  w-full border-r border-slate-100'>Employee share</h6>
                        <h6 className='px-2 py-1 text-[12.5px] font-[600]  w-full  border-r border-slate-100'>Your share</h6>
                        <h6 className='px-2 py-1 text-[12.5px] font-[600]  w-full  '>Total Insentive</h6>
                    </span>

                    <span className='flex w-[400px] border-slate-100 space-evenly items-center border-l border-r border-b'>
                        <h6 className='px-2 py-1 text-[12.5px]   w-full border-r border-slate-100'>{rupee?.format(data1?.team_member_incentive_amount)?.split('.')[0]}</h6>
                        <h6 className='px-2 py-1 text-[12.5px]   w-full  border-r border-slate-100'>{rupee?.format(incentiveAmount)?.split('.')[0]}</h6>
                        <h6 className='px-2 py-1 text-[12.5px]   w-full  '>{rupee?.format(incentiveAmount + data1?.team_member_incentive_amount)?.split('.')[0]}</h6>
                    </span>

                    {/* user?.roles?.includes('hod') */}
                   <div className='flex items-center justify-between w-[700px]'>
                        <h6 className='text-[13px] font-[400] my-2'>Your Team Incentive </h6>
                        {(user?.roles?.includes('hod') || user?.roles?.includes('admin')) &&
                        <div className='flex items-center mt-0 mr-2'>
                          {viewAll ?  <BiCheckboxSquare size={22} className='text-slate-600' onClick={()=>setviewAll(!viewAll)} />  : <BiCheckbox size={22} className='text-slate-300' onClick={()=>setviewAll(!viewAll)} /> }
                          <h6 className="text-[11.5px] font-[600] ml-1">View All</h6> 
                        </div>}
                   </div>

                    <span className='flex w-[700px] border-slate-100 space-evenly items-center border'>
                        <h6 className='px-2 py-1 text-[12.5px] font-[600] w-full border-r border-slate-100'>Employee </h6>
                        <h6 className='px-2 py-1 text-[12.5px] font-[600] w-full  border-r border-slate-100'>Vertical Target</h6>
                        <h6 className='px-2 py-1 text-[12.5px] font-[600] w-full  border-r border-slate-100'>Threshold Target</h6>
                        <h6 className='px-2 py-1 text-[12.5px] font-[600] w-full  border-r border-slate-100'>Target Achieved</h6>
                        <h6 className='px-2 py-1 text-[12.5px] font-[600] w-full  '>Insentive</h6>
                    </span>
                    
                    {viewAll ?
                    <>
                     {data1?.team_member_incentive?.map((t)=>(
                    <span className='flex w-[700px] border-slate-100 space-evenly items-center border-l border-r border-b'>
                        <h6 className='px-2 py-1 text-[12.5px] w-full border-r border-slate-100'>{t?.employee?.name}</h6>
                        <h6 className='px-2 py-1 text-[12.5px] w-full  border-r border-slate-100'>{rupee?.format(t?.vertical_target)?.split('.')[0]}</h6>
                        <h6 className='px-2 py-1 text-[12.5px] w-full  border-r border-slate-100'>{rupee?.format(t?.threshold_target)?.split('.')[0]}</h6>
                        <h6 className='px-2 py-1 text-[12.5px] w-full  border-r border-slate-100'>{rupee?.format(t?.target_achieved)?.split('.')[0]}</h6>
                        <h6 className='px-2 py-1 text-[12.5px] w-full  '>{rupee?.format(t?.incentive)?.split('.')[0]}</h6>
                    </span>))}
                    </>
                    :
                    <>
                    {data1?.team_member_incentive?.filter((f)=>f?.incentive > 0)?.map((t)=>(
                    <span className='flex w-[700px] border-slate-100 space-evenly items-center border-l border-r border-b'>
                        <h6 className='px-2 py-1 text-[12.5px] w-full border-r border-slate-100'>{t?.employee?.name}</h6>
                        <h6 className='px-2 py-1 text-[12.5px] w-full  border-r border-slate-100'>{rupee?.format(t?.vertical_target)?.split('.')[0]}</h6>
                        <h6 className='px-2 py-1 text-[12.5px] w-full  border-r border-slate-100'>{rupee?.format(t?.threshold_target)?.split('.')[0]}</h6>
                        <h6 className='px-2 py-1 text-[12.5px] w-full  border-r border-slate-100'>{rupee?.format(t?.target_achieved)?.split('.')[0]}</h6>
                        <h6 className='px-2 py-1 text-[12.5px] w-full  '>{rupee?.format(t?.incentive)?.split('.')[0]}</h6>
                    </span>))}
                    </>}

                 
                 </div>   
                }

                {user?.roles?.includes('bd_user') &&
                <div>
                    <h6 className='text-[13px] font-[400] mb-2 mt-2'>Your Stand Alone Incentive For Period  :<span className='px-2 py-1 text-[12.5px]  text-center font-[700]  border-r border-slate-100'>{rupee?.format(incentiveAmount)?.split('.')[0]}</span></h6>
                </div>}    

            </div>
            </div>
            </div>


            <div className='w-[20%] min-w-[20%] h-full max-w-[20%]  mx-3 pr-3'>
               
               <h6 className='text-[12px]  mt-5 p-2 bg-slate-100'><span className='font-[700]'>Note :- </span> {data1?.insentiverequirment?.manager_note}</h6>
            </div> 
            </div>


        </div>
        </div>
    </div>
  )
}

export default InsentiveDashboard