import React, { useEffect, useState } from 'react'
import { useLocation,useNavigate } from 'react-router-dom'
import { DeleteInsentiveBasic, GetInsentiveBasicList } from '../../services/AdminServicesfile/InsentiveServices'
import {FiPlus} from 'react-icons/fi'
import {AiOutlineEdit,AiOutlineDelete} from 'react-icons/ai'
import { Modal } from 'antd'
import {IoCloseOutline} from 'react-icons/io5';
import {BsGraphUpArrow} from 'react-icons/bs';
import {HiOutlineBuildingOffice} from 'react-icons/hi2';
import {CgListTree} from 'react-icons/cg';
import {RiListCheck,RiPlayList2Line} from 'react-icons/ri';
import {AiOutlineControl} from 'react-icons/ai';
import {GrSteps} from 'react-icons/gr';
import moment from 'moment'
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../components/button'
import { toast } from 'react-hot-toast'
import {
  Pagination,
} from "@mui/material";
import { useSelector } from 'react-redux'

function Settings() {

  const {pathname} = useLocation()

  const roles = useSelector(state=>state.Auth.roles)

  // console.log("roles",roles)
  const path = pathname.split('/')[2]
  const navigator = useNavigate()

  const menu = [
    {name:'Incentive Requ',icon:BsGraphUpArrow,path:'/settings/contacts',id:1,color:''},
  ] 

  const menu1 = [
    {name:'Our Department',icon:HiOutlineBuildingOffice,path:'/settings/department',id:1,color:''},
    {name:'Our Designation',icon:RiListCheck,path:'/settings/contacts',id:1,color:''},
    {name:'Our Branch',icon:CgListTree,path:'/settings/contacts',id:1,color:''},
    {name:'Our Roles',icon:AiOutlineControl,path:'/settings/contacts',id:1,color:''},
    {name:'Lead Stages',icon:GrSteps,path:'/settings/contacts',id:1,color:''},
    {name:'Our CSDT',icon:RiPlayList2Line,path:'/settings/contacts',id:1,color:''},
  ] 

  const [data,setdata] = useState([])
  const [pagination,setpagination] = useState({})

  const [selectedData,setselectedData] = useState({})
  const [deleteModal,setdeleteModal] = useState(false)
  const [status,setstatus] = useState('')
  const [page,setpage] = useState('1')

  useEffect(()=>{
    if(path === undefined){
      if(roles?.includes('onboard_executive')){
        navigator('/settings/onboard_list')
      }else{
        navigator('/settings/department')
        setstatus('')
        getData()
      }
    }else{
      if(path === 'insentive_list'){
        setstatus('')
        getData()
      }

    }
  },[status,page])


  async function getData(){
     const response = await GetInsentiveBasicList(page)
     setdata(response?.data?.datas)
     setpagination(response?.data?.pagination)
  }


  async function deleteData(){
    const response = await DeleteInsentiveBasic(selectedData?._id)
    if(response.status === 200){
      toast.success('Deleted Successfully')
      getData()
      setdeleteModal(false)
    }
  }

  const handlePagination = (e, pageNumber) => {
    setpage(pageNumber)
  }


  

  return (
    <div className='min-h-screen h-screen h-full'>
      <Modal open={deleteModal} closable={false} footer={null}  style={{maxWidth:'270px',borderRadius:'2px'}}>
          <div className='relative'>
               <IoCloseOutline  className='absolute -right-3 -top-2 cursor-pointer' onClick={()=>setdeleteModal(false)}/>
               <h6 className='font-[900]'>Delete Data</h6>
               <h1 className='leading-tight text-[12px] font-[400]'>Are you sure want to delete the selected data.</h1>
               <div className='flex w-full align-end items-end  mt-2'>
                <div className="mr-2">
                <ButtonOutlinedAutoWidth btnName={'Cancel'} onClick={()=>{setdeleteModal(false)}} />
                </div>
                <ButtonFilledAutoWidth btnName={'Save'} onClick={deleteData} />
            </div>
          </div>
      </Modal>
      <div className='flex h-full'>
      <div className='mr-0 min-h-screen max-h-sceen border-r  w-44 px-2'>
          
          {(roles?.includes('admin') || roles?.includes('controller')) &&
           <>
           <h1 className=' ml-2 mb-2 font-[700] text-[12px] mt-5'>Basic Options</h1>
           {menu.map((m)=>(
                <div key={m?.path} className={`flex items-center my-1 hover:bg-[#f4f5f7] px-1.5 py-1 ml-2 rounded-md relative cursor-pointer ${pathname ===  m.path && 'bg-[#f4f5f7]'}`} onClick={()=>navigator(m?.path)}>
                {pathname ===  m.path &&  <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3' ></span>}
               <m.icon color='#000' style={{backgroundColor:m?.color,padding:'2px'}} size={16}  />
               <span className='text-[#000] font-[500] ml-2 text-[12px]'>{m.name}</span>
              </div>    
            ))} 
          </>
           }

          {(roles?.includes('admin') || roles?.includes('controller') || roles?.includes('hr_admin')) &&
           <>
          <h1 className=' ml-2 mb-2 font-[700] text-[12px] mt-5'>Application Options</h1>
           {menu1.map((m)=>(
                <div key={m?.path} className={`flex items-center my-1 hover:bg-[#f4f5f7] px-1.5 py-1 ml-2 rounded-md relative cursor-pointer ${pathname ===  m.path && 'bg-[#f4f5f7]'}`} onClick={()=>navigator(m?.path)}>
                {pathname ===  m.path &&  <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3' ></span>}
               <m.icon color='#000' style={{backgroundColor:m?.color,padding:'2px'}} size={16}  />
               <span className='text-[#000] font-[500] ml-2 text-[12px]'>{m.name}</span>
              </div>    
            ))}
            </>}
      </div>

     

      <div className='px-4 w-5/6'>
        <div className='pt-12 pb-2 border-b w-full flex items-center justify-between'>
        <h6 className='text-[14px] font-[800] '>{`Total Insentive Basic (${pagination?.total})`}</h6>
        <FiPlus size={22} onClick={()=>navigator('create')} className="cursor-pointer ml-4" />
        </div>

         <div className='grid grid-cols-4 pt-4  '>
          {
          data?.map((d)=>(
            <div key={d?._id} className='border p-2'> 
            <div className='flex items-end justify-end'>
              <span><AiOutlineDelete size={14} className='mr-2 cursor-pointer' onClick={()=>{setselectedData(d);setdeleteModal(true)}}/></span>
              <span onClick={()=>{navigator('edit',{state:d})}}><AiOutlineEdit size={14} className='cursor-pointer'/></span>
            </div>
                <h6 className='text-[12px] font-[500]'>Year :<span className='font-[800]'> {d?.year}</span></h6>
                <h6 className='text-[12px] font-[500]'>Start Date - End Date : <span className='font-[800]'>{moment(d?.incentive_start).format('LL')} / {moment(d?.incentive_end).format('LL')}</span> </h6>
                <div className='bg-green-50 p-2 mt-1'>
                <h6 className='text-[11px] font-[500]'>Percentage Share : </h6>
                <h6 className='text-[10.5px] font-[500]'>HOD : <span className='font-[800]'>{d?.hod_percentage} % </span> Manager : <span className='font-[800]'>{d?.manager_percentage} % </span>  Employee : <span className='font-[800]'>{d?.employee_percentage} %</span>  </h6>
                </div>
            </div>  
          ))
          }
        </div>

        <div className="tex-center flex items-center justify-center mt-10 ">
        {(pagination?.totalPages > page && data.length > 0 &&
          <Pagination size={'small'} page={page} className="flex justify-center my-10 pb-20" count={Math.ceil(pagination?.total / pagination?.limit) } onChange={handlePagination} />
        )}
    </div>
      </div>
      </div>
   
    </div>
  )
}

export default Settings