import { deleteRequest, get, post, postfd, put } from "../../helpers/apihelpers";

export const CreateCabUsageService=async(data)=>{
    try {
        const result = await post(`api/reception/cab_usage/create`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateCabUsageService=async(data,id)=>{
    try {
        const result = await put(`api/reception/cab_usage/update/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetCabUsageService=async(page,text,from_date,to_date,step)=>{
    try {
        const result = await get(`api/reception/cab_usage/get?page=${page}&text=${text}&from_date=${from_date}&to_date=${to_date}&step=${step}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteCabUsageService=async(id)=>{
    try {
        const result = await deleteRequest(`api/reception/cab_usage/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UploadCabUsageService=async(data)=>{
    try {
        const result = await postfd(`api/reception/cab_usage/upload_file`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DownloadCabUsageService=async(page,text,from_date,to_date,step)=>{
    try {
        const result = await get(`api/reception/cab_usage/download_data?page=${page}&text=${text}&from_date=${from_date}&to_date=${to_date}&step=${step}`);
        return result;
    } catch (err) {
        return err.response;
    }
}