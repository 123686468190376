import React,{useEffect, useState} from 'react'
import { toast } from 'react-hot-toast'
import {  ButtonFilledAutoWidth } from '../../../../components/button'
import { TextAreaInput1, TextInput } from '../../../../components/input'
import { useNavigate, useLocation } from 'react-router-dom'
import GoBack from '../../../../components/back/GoBack'
import { CreateHRWebsiteStageService, SearchHRWebsiteStageService, UpdateHRWebsiteStageService } from '../../../../services/Website/HrWebsiteOption/HRStageServices'
import WebsiteMenu from '../../WebsiteMenu'
import { Select } from 'antd'
import ErrorComponent from '../../../../components/errorDesign/ErrorComponent'
import Uploader from '../../../../components/Uploader'
import { CreateHRWebsiteBlogService, UpdateHRWebsiteBlogService, UploadHRWebsiteBlogService } from '../../../../services/Website/HrWebsiteOption/HRBlogServices'
import { GetSearchService } from '../../../../services/AuthServices'
import { CreateHRWebsiteArticalService, UpdateHRWebsiteArticalService } from '../../../../services/Website/HrWebsiteOption/HRArticalServices'

function HRArticalCE() {
 
  const [data,setdata] = useState({tags:[],related_to:[],color:'',written_by:'',related_to_link:'',date:'',file:'',title:'',motive:'',min_read:'',content:'',video:'',blog_image:''});  
  const [error,seterror] = useState({tags:'',related_to:'',color:'',written_by:'',related_to_link:'',date:'',file:'',title:'',motive:'',min_read:'',content:'',video:'',blog_image:''}); 
  
  const [tags,settags] = useState([])
  const [related_to,setrelated_to] = useState([])
  const [category,setcategory] = useState([])
  const [written_by,setwritten_by] = useState([])

  const {state} = useLocation();
  const navigate = useNavigate();


 


  useEffect(()=>{
    if(state?._id !== null && state?._id !== undefined){

      let arr = [],arr1=[]

      state.tags.forEach((d)=>{
        arr.push({label:d.name,value:d._id})
      })

      state.related_to.forEach((d)=>{
        arr1.push({label:d.name,value:d._id})
      })

      let obj = {
        tags:arr,
        related_to:arr1,
        related_to_link:'',
        date:state?.date?.slice(0,10),
        file:[],
        title:state?.title,
        motive:state?.motive,
        min_read:state?.min_read,
        content:state?.content,
        video:state?.video,
        category:{label:state?.category?.name,value:state?.category?._id},
        written_by:{label:state?.written_by?.name,value:state?.written_by?._id},
        blog_image:state?.blog_image
      }

      console.log("obj",obj)

      setdata({...data,...obj})
    }

  },[state])

  function handlechange(e){
    setdata({...data,[e.target.name] : e.target.value})
    seterror({...error,[e.target.name]:''})
  }
  
  // console.log("data",data)

  async function searchData(v,type){
      if(type !== 'WrittenBy'){
        const response =  await SearchHRWebsiteStageService(v,type)
        if(type === 'Tag'){
          let arr = []
          let d = response?.data?.datas
          d.forEach((d1)=>{
            arr.push({label:d1.name,value:d1?._id})
          })
          settags(arr)
          return arr
        }
        if(type === 'RelatedTo'){
          let arr = []
          let d = response?.data?.datas
          d.forEach((d1)=>{
            arr.push({label:d1.name,value:d1?._id})
          })
          setrelated_to(arr)
          return arr
        }
        if(type === 'Category'){
          let arr = []
          let d = response?.data?.datas
          d.forEach((d1)=>{
            arr.push({label:d1.name,value:d1?._id})
          })
          setcategory(arr)
          return arr
        }
    }else{
      const response =  await GetSearchService(v,1)
          let arr = []
          let d = response?.data?.datas
          d.forEach((d1)=>{
            arr.push({label:d1.name,value:d1?._id})
          })
          setwritten_by(arr)
          return arr
    }
  }
  
  async function submitform(){
    if(!data.title){
        seterror({...error,title:'Title field is required *'})
    }else{
        let arr = [],arr1=[]
        let sendData = {...data}

        data.tags.forEach((d)=>{
          if(d.value !== undefined){
            arr.push(d.value)
          }
        })

        data.related_to.forEach((d)=>{
          if(d.value !== undefined){
            arr1.push(d.value)
          }
        })

        sendData['tags'] = arr
        sendData['related_to'] = arr1

        if(data.written_by.value !== undefined){ 
            sendData['written_by'] = data.written_by.value
        }

        if(data.category.value !== undefined){ 
          sendData['category'] = data.category.value
      }

        if(state?._id === undefined || state?._id === null){
        const response = await CreateHRWebsiteArticalService(sendData)
        if (response.status === 201) {
          setdata({tags:[],related_to:[],written_by:'',color:'',related_to_link:'',date:'',file:'',title:'',motive:'',min_read:'',content:'',video:'',blog_image:''})
          seterror({tags:'',related_to:'',written_by:'',color:'',related_to_link:'',date:'',file:'',title:'',motive:'',min_read:'',content:'',video:'',blog_image:''})
          toast.success('HR Artical Created Successfully')
        }   
        if(response.status === 422){
          if(response?.data?.errors?.name){
            seterror({...error,name:response?.data?.errors?.name})
          }
        } 
      }else{
        sendData["id"] = state?._id
        const response = await UpdateHRWebsiteArticalService(sendData,state?._id)
        if (response.status === 200) {
          setdata({tags:[],related_to:[],written_by:'',color:'',related_to_link:'',date:'',file:'',title:'',motive:'',min_read:'',content:'',video:'',blog_image:''})
          seterror({tags:'',related_to:'',written_by:'',color:'',related_to_link:'',date:'',file:'',title:'',motive:'',min_read:'',content:'',video:'',blog_image:''})
          navigate(-1)
          toast.success('HR Artical Updated Successfully')
        }   
        if(response.status === 422){
          if(response?.data?.errors?.name){
            seterror({...error,name:response?.data?.errors?.name})
          }
        } 
      }
  }
  }


  async function uploadfile(v,name){
    const fd = new FormData()
    fd.append('file',v); 
    const response = await UploadHRWebsiteBlogService(fd)
    if(response?.status === 200){
      setdata({...data,[name]:response?.data?.data})
      seterror({...error,[name]:''})
    }
  }


  async function selectedData(v,type){
    if(type == 'tags'){
      let arr = []
      v.forEach((d1)=>{
       if(tags.find((d)=>d.value == d1) !== undefined){
         arr.push(tags.find((d)=>d.value == d1))
       }else {
        if(data.tags.find((d)=>d.value == d1) !== undefined){
          arr.push(data.tags.find((d)=>d.value == d1))
        }
       }
      })
      setdata({...data,tags:arr})
    }

    if(type == 'related_to'){
      let arr = []
      v.forEach((d1)=>{
       if(related_to.find((d)=>d.value == d1) !== undefined){
         arr.push(related_to.find((d)=>d.value == d1))
       }else {
        if(data.related_to.find((d)=>d.value == d1) !== undefined){
          arr.push(data.related_to.find((d)=>d.value == d1))
        }
       }
      })
      setdata({...data,related_to:arr})
    }

    if(type == 'category'){
      let findData = category.find((f)=>f.value == v)
      if(findData !== null){
        setdata({...data,category:findData})
      }
    }
  }

  async function handleselect(v,type){
     let findData = written_by.find((f)=>f.value === v)
     setdata({...data,[type]:findData})
  }


  

  return (
    <div className='flex '>


    <WebsiteMenu />
 
  <div className='sm:w-full lg:w-72 px-4 pt-5' >


      <GoBack /> 
      
      

        <h6 className='font-bold'>{(state?._id !== null && state?._id !== undefined) ? 'Edit' : 'Add'} HR Artical</h6>
        <h6 className='text-[10px] bg-slate-100 p-2 font-[500] leading-snug' >Use the below form to create or edit the <b> HR Artical</b> for your website.</h6>
     

        <TextInput 
            label={'Title'}  
            variant="standard"
            name="title"
            type="text"
            error={error.title}
            value={data.title}
            handlechange={handlechange}
            placeholder="Enter your Role name"
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>

        <h6 className='text-[11px] mt-2 font-[600] mb-1' >Written By</h6>
        <Select 
          value={data.written_by}
          allowClear={true}
          type="written_by"
          filterOption={false}
          showSearch={true}
          onSearch={(e)=>searchData(e,'WrittenBy')}
          onChange={e=>{handleselect(e,'written_by')}}
          options={written_by}
          bordered={false}
          className='w-[100%] border border-slate-300'
        />

<TextInput 
            label={'Color'}  
            variant="standard"
            name="color"
            type="color"
            error={error.color}
            value={data.color}
            handlechange={handlechange}
            placeholder="Enter your Role name"
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>

        <TextInput 
            label={'Motive'}  
            variant="standard"
            name="motive"
            type="text"
            error={error.motive}
            value={data.motive}
            handlechange={handlechange}
            placeholder="Enter your Role name"
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>

        <TextInput 
            label={'Min Read'}  
            variant="standard"
            name="min_read"
            type="text"
            error={error.min_read}
            value={data.min_read}
            handlechange={handlechange}
            placeholder="Enter your Role name"
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>

        <TextInput 
            label={'Date'}  
            variant="standard"
            name="date"
            type="date"
            error={error.date}
            value={data.date}
            handlechange={handlechange}
            placeholder="Enter your Role name"
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>

         <TextAreaInput1 
            label={'Content'}  
            variant="standard"
            name="content"
            type="text"
            error={error.content}
            value={data.content}
            handlechange={handlechange}
            placeholder=""
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>

          <h6 className='text-[11px] mt-2 font-[600] mb-1' >Blog Image</h6>
          <Uploader image={data?.blog_image}  setimagefunc={(e)=>{uploadfile(e,'blog_image')}}  removeimageuploadfunc = {()=>setdata({...data,blog_image:''})}/>

          <h6 className='text-[11px] mt-2 font-[600] mb-1' >Video</h6>
          <Uploader image={data?.video}  setimagefunc={(e)=>{uploadfile(e,'video')}}  removeimageuploadfunc = {()=>setdata({...data,video:''})}/>


        <h6 className='text-[11px] mt-2 font-[600] mb-1' >Tags</h6>
        <Select 
          value={data.tags}
          mode = "multiple"
          showSearch={true}
          type="tags"
          filterOption={false}
          onSearch={(e)=>searchData(e,'Tag')}
          onChange={e=>{selectedData(e,'tags')}}
          options={tags}
          bordered={false}
          className='w-[100%] border border-slate-300'
        />
        <ErrorComponent error={error?.tags} />

        <h6 className='text-[11px] mt-2 font-[600] mb-1' >Related To</h6>
        <Select 
          value={data.related_to}
          mode = "multiple"
          showSearch={true}
          type="related_to"
          filterOption={false}
          onSearch={(e)=>searchData(e,'RelatedTo')}
          onChange={e=>{selectedData(e,'related_to')}}
          options={related_to}
          bordered={false}
          className='w-[100%] border border-slate-300'
        />
        <ErrorComponent error={error?.related_to} />

        <h6 className='text-[11px] mt-2 font-[600] mb-1' >Category</h6>
        <Select 
          value={data.category}
          showSearch={true}
          type="category"
          filterOption={false}
          onSearch={(e)=>searchData(e,'Category')}
          onChange={e=>{selectedData(e,'category')}}
          options={category}
          bordered={false}
          className='w-[100%] border border-slate-300'
        />
        <ErrorComponent error={error?.category} />


        
<div className='mt-5'>
        <ButtonFilledAutoWidth btnName={(state?._id !== null && state?._id !== undefined) ? "UPDATE HR Artical" : "ADD HR Artical"}  onClick={submitform} />  
        </div>


    </div>
    </div>
  )
}

export default HRArticalCE