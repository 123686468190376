import React, { useEffect, useState } from 'react'
import GalleryAppBar from '../GalleryAppBar'
import { TextAreaInput1, TextInput } from '../../../components/input'
import GoBack from '../../../components/back/GoBack'
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../../components/button'
import { useNavigate,useLocation } from 'react-router-dom'
import Uploader from '../../../components/Uploader'
import { CreateGalleryVisitorList, UpdateGalleryVisitorList } from '../../../services/Gallery/GalleryVisitorServices'
import { toast } from 'react-hot-toast'
import { BiCheckbox,BiCheckboxSquare } from "react-icons/bi";

function VisitorCE() {

  const {pathname,state} = useLocation()
  const path = pathname?.split('/')[pathname?.split('/')?.length - 1]
  
//   console.log("pathname",pathname)
  
  const navigate = useNavigate()  
  const [data,setdata] = useState({name:'',mobile:'',email:'',address:'',file:'',remarks:''})  
  const [error,seterror] = useState({name:'',mobile:'',email:'',address:'',file:''})  
  
  useEffect(()=>{
    if(path === 'edit'){
        setdata({
            ...data,
            name:state?.name,
            mobile:state?.mobile,
            email:state?.email,
            address:state?.address,
            remarks:state?.remarks,
            file:state?.file,
        })
    }else{
        resetForm()
    }
  },[])
  

  function handlechange(e){
    setdata({...data,[e.target.name] : e.target.value})
    seterror({...error,[e.target.name]:''})
  }

//   console.log("data?.file",data?.file)

  async function submitdata(){
    if(!data?.name){
        seterror({...error,name:'The name field is required'})
    }else if(!data?.mobile){
        seterror({...error,mobile:'The mobile field is required'})
    }else if(data?.mobile.length !== 10){
        seterror({...error,mobile:'Enter a valid 10 digit mobile no'})
    }else{
        const fd = new FormData()
        fd.append('name',data?.name)
        fd.append('mobile',data?.mobile)
        fd.append('email',data?.email)
        fd.append('address',data?.address)
        fd.append('remarks',data?.remarks)

        if(data?.file?.name !== undefined){
            fd.append('file',data?.file)
        }
       
        if(path === 'create'){
            const response = await CreateGalleryVisitorList(fd)
            if(response.status === 201){
                toast.success("Created successfully") 
                resetForm()
            }
        }else if(path === 'edit'){
            const response = await UpdateGalleryVisitorList(state?._id,fd)
            if(response.status === 201){
                toast.success("Updated successfully") 
                navigate(-1)
            }
        }
        

    }

  }


  function resetForm(){
    setdata({name:'',mobile:'',email:'',address:'',file:'',remarks:''})
    seterror({name:'',mobile:'',email:'',address:'',file:'',remarks:''})
  }

  return (
    <div className='h-screen max-h-screen box-border overflow-hidden'>
        <div className='block sm:flex'>
        <GalleryAppBar />

        <div className='px-5 w-full'>

          
                <div className='flex w-full h-screen overflow-y-scroll mb-10'>
                <div className='border-r w-64 pr-5 pt-5'>
                <GoBack />  

                <h6 className='font-[700] mb-1'>Create/Edit Visitor Data</h6>
                <h6 className='text-[11px] p-2  mb-2 bg-slate-100'>Use the below form to create or update the  visitor data</h6>
                     
                <h6 className='text-[11px] font-[600] mb-1' >{'Photo'}</h6>
                   <Uploader image={data?.file}  setimagefunc={(e)=>{setdata({...data,file:e});seterror({...error,file:''})}}  removeimageuploadfunc = {()=>seterror({...error,file:''})} />

                    <TextInput 
                        label={'Name'}  
                        variant="standard"
                        name="name"
                        type="text"
                        error={error.name}
                        value={data.name}
                        handlechange={handlechange}
                        placeholder="Enter your name"
                    />

                    <TextInput 
                        label={'Mobile'}  
                        variant="standard"
                        name="mobile"
                        type="number"
                        error={error.mobile}
                        value={data.mobile}
                        handlechange={handlechange}
                        placeholder="Enter your mobile no"
                    />

                <TextInput 
                        label={'Email'}  
                        variant="standard"
                        name="email"
                        type="text"
                        error={error.email}
                        value={data.email}
                        handlechange={handlechange}
                        placeholder="Enter your email address"
                    />

                <TextAreaInput1 
                        label={'Address'}  
                        variant="standard"
                        name="address"
                        type="text"
                        error={error.address}
                        value={data.address}
                        handlechange={handlechange}
                        placeholder=""
                    /> 
                     <TextAreaInput1 
                        label={'Remarks'}  
                        variant="standard"
                        name="remarks"
                        type="text"
                        error={error.remarks}
                        value={data.remarks}
                        handlechange={handlechange}
                        placeholder=""
                    /> 

                  

                     <div className=' flex mt-4 pb-5'>
                        <ButtonOutlinedAutoWidth btnName={'Back'} onClick={()=>{navigate(-1)}} />
                        <div className='ml-2'>
                        <ButtonFilledAutoWidth btnName={'Save'} onClick={submitdata} />
                     </div>
         </div>   
                </div>
                </div>

        </div>
        </div>

    </div>
  )
}

export default VisitorCE