import { deleteRequest, get, post, postfd, put } from "../../helpers/apihelpers";

export const CreateConveyanceVochuerService=async(data)=>{
    try {
        const result = await post(`api/conveyance_vocher/create`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateConveyanceVochuerService=async(data,id)=>{
    try {
        const result = await put(`api/conveyance_vocher/update/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetConveyanceVochuerService=async(page,text,from_date,to_date,step)=>{
    try {
        const result = await get(`api/conveyance_vocher/get?page=${page}&text=${text}&from_date=${from_date}&to_date=${to_date}&step=${step}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteConveyanceVochuerService=async(id)=>{
    try {
        const result = await deleteRequest(`api/conveyance_vocher/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UploadConveyanceVochuerService=async(data)=>{
    try {
        const result = await postfd(`api/conveyance_vocher/upload_file`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DownloadConveyanceVochuerService=async(page,text,from_date,to_date,step)=>{
    try {
        const result = await get(`api/conveyance_vocher/download_data?page=${page}&text=${text}&from_date=${from_date}&to_date=${to_date}&step=${step}`);
        return result;
    } catch (err) {
        return err.response;
    }
}