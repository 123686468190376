import React, { useEffect, useState } from 'react'
import SettingsMenu from '../staticscreens/SettingsMenu'
import { DeleteInsentiveAttachment, DeleteInvoiceService, GetAllInsentiveAttachment } from '../../services/InvoiceServices'
import { AiOutlineDelete,AiOutlineDownload } from 'react-icons/ai'
import { ButtonOutlinedAutoWidth } from '../../components/button'
import moment from 'moment'
import { toast } from 'react-hot-toast';
import {BsArrowRepeat} from 'react-icons/bs';
import {FiChevronRight,FiChevronLeft} from 'react-icons/fi';
import { IconButton } from '@mui/material'
import axios from 'axios';
import fileDownload from "js-file-download";



function InvoiceAttachmentModify() {

  const [filter,setfilter] = useState({search:'',filter_Applied:''})
  const [data,setdata] = useState([])
  const [pagination,setpagination] = useState({})
  const [page,setpage] = useState(1)
 
  useEffect(()=>{
    if(!filter.filter_Applied){
      getData()
    }else{
      filterData()
    }
  },[page,filter.filter_Applied])


  async function getData(){
      const response = await GetAllInsentiveAttachment('',page)
      setdata(response?.data?.datas)
      setpagination(response?.data?.pagiantion)
  }

  async function filterData(){
    if(filter?.search?.length > 2){
    const response = await GetAllInsentiveAttachment(filter?.search,page)
    setfilter({...filter,filter_Applied:true})
    setdata(response?.data?.datas)
    setpagination(response?.data?.pagiantion)
    }
  }

 

  function resetfunc(){
    setpage(1)
    setfilter({filter_Applied:false,search:''})
  }

  async function deleteData(id){
    const response = await DeleteInsentiveAttachment(id)
    if(response.status === 200){
      toast.success('Deleted Successfully')
      getData()
    }
  }

  const handleDownload = (url, filename) => {
    axios.get(url, {
        responseType: 'blob',
    })
        .then((res) => {
            fileDownload(res.data, filename)
        })
  }


  let rupee = new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
  });

  return (
    <div className='h-screen min-h-screen max-h-screen overflow-hidden'>
     
        <div className='flex'>
            <SettingsMenu />
            <div className='mx-2 h-[95vh] overflow-hidden min-h-[95vh] max-h-[95vh] w-full'>
                <h6 className='border-b text-[14px] mt-5 font-[600] pb-1 w-full'>Invoice Attachment</h6>

                <div className='border-b flex  pb-1 items-center justify-between my-1'>
                  <h6 className='text-[12px] py-1 font-[400]'>Total Found ({pagination?.total})</h6>
                  <div className='flex items-center'>
                  <div className='flex items-center text-[12px] mr-2'>

                    <h6 className='mr-2 font-[600]'>{page == 1 ? data?.length > 0 ? 1 : 0 : (page - 1) * pagination?.limit} - {pagination?.limit} of {pagination?.total} </h6>
                    <IconButton onClick={resetfunc} ><BsArrowRepeat size={16} /></IconButton>

                    <div>
                    <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}} ><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`} size={16} /></IconButton>
                    <IconButton onClick={()=>{ page < pagination?.totalPages  ? setpage(page+1) : console.log('')}} ><FiChevronRight className={`${(pagination?.totalPages === page || data?.length === 0)  ? 'opacity-50' : 'opacity-100'}`} size={16} /></IconButton>
                    </div>
                    </div>

                    <input value={filter?.search} onChange={(e)=>setfilter({...filter,search:e.target.value})} className="w-[100px] text-[11px] mr-2 focus:outline-none border rounded-md py-1.5 px-2" />
                    <ButtonOutlinedAutoWidth onClick={filterData} btnName="Apply Filter" />
                  </div>
                </div>

                <div className='h-[80vh] min-h-[80vh] max-h-[80vh] overflow-y-scroll'>
                <div className='grid grid-cols-5 gap-1 mt-2'>
                  {data?.map((d)=>( 
                  <div key={d?._id} className='border p-2 relative '>
                        <AiOutlineDelete size={13} className='absolute text-slate-500 cursor-pointer right-1 top-1' onClick={()=>{deleteData(d._id)}} />
                        {(d?.invoice_no !== '' && d?.invoice_no !== undefined) && <h6 className='text-[12px]'>Invoice No : <span className='font-[700]'>{d?.invoice_no}</span></h6>}
                        <div className='flex mt-1 '>
                          <img src="https://cdn-icons-png.flaticon.com/512/179/179483.png" className='w-5 h-5 object-contain z-0' />
                          <h6 className='text-[9px] font-[800] ml-1 w-[85%]  overflow-hidden line-clamp-2'>{d?.file?.split('/')[d?.file?.split('/')?.length - 1]}</h6>
                          <AiOutlineDownload size={13} className='cursor-pointer absolute right-1 bg-white' onClick={()=>handleDownload(`${process.env.REACT_APP_BACKEND_IMAGE_URL}${d?.file}`,d?.file?.split('/')[d?.file?.split('/')?.length - 1])} />
                        </div>
                        {d?.invoice_request !== undefined &&
                        <div className='border p-1 my-1 border-slate-100'>
                          <h6 className='text-[11px]'>Invoice Amount : <span className='font-[800]'>{rupee?.format(d?.invoice_request?.amount).split('.')[0]}</span></h6>
                          <h6 className='text-[11px]'>Employee Info : <span className='font-[800]'>{d?.invoice_request?.employee?.name}</span></h6>
                        </div>}
                        <h6 className='text-[11px] bg-slate-100 p-1 mt-1'>Created At : {moment(d?.createdAt)?.format('LL')}</h6>
                  </div>
                  ))}

                </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default InvoiceAttachmentModify