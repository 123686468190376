import { deleteRequest, get, post, put } from "../helpers/apihelpers";


export const GetWeeklyReportService=async(text,from_date,to_date,page,user,stage)=>{
    try {
        const result = await get(`api/analytics/department_customize_report_data?user=${user}&from_date=${from_date}&to_date=${to_date}&stage=${stage}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DownloadWeeklyReportService=async(text,from_date,to_date,page,user,stage)=>{
    try {
        const result = await get(`api/analytics/department_customize_report_data_excel?user=${user}&from_date=${from_date}&to_date=${to_date}&stage=${stage}`);
        return result;
    } catch (err) {
        return err.response;
    }
}