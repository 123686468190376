import React, { useEffect, useState } from 'react'
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../components/button'
import GoBack from '../../components/back/GoBack'
import { TextAreaInput1, TextInput } from '../../components/input'
import { Select,DatePicker } from 'antd'
import AdminReminderMenu from './AdminReminderMenu'
import { CreateAdminReminderService, UpdateAdminReminderService } from '../../services/AdminReminderServices'
import { useLocation, useNavigate } from 'react-router-dom'
import { GetSearchService } from '../../services/AuthServices'
import toast from 'react-hot-toast'
import {BiCheckbox,BiCheckboxSquare} from 'react-icons/bi'
import moment from 'moment'

function AdminReminderCE() {

  const [data,setdata] = useState({reminder_to:'',title:'',summary:'',date:'',date1:'',time:'',attachments:'',expired:false})
  const [error,seterror] = useState({reminder_to:'',title:'',summary:'',date:'',date1:'',time:'',attachments:'',expired:false})
  
  const [related_to,setrelated_to] = useState([])

  const {pathname,state} = useLocation()
  let path = pathname?.split("/")[pathname?.split("/")?.length - 1]
  // console.log("path",path)
  // console.log("pathanme",pathname)
  // console.log("state",state)
  const navigate = useNavigate()



  useEffect(()=>{
     if(path === 'edit'){
      setdata({
        title:state.title,
        summary:state.summary,
        expired:state.expired,
        reminder_to:{label:state?.reminder_to?.name,value:state?.reminder_to?._id},
        date:state?.date_time?.slice(0,10)        ,
        time:state?.date_time?.slice(11,16)})
     }
  },[])


  // console.log("data",data)
  async function searchemployee(e){
    // console.log("e here",e)
    const response = await GetSearchService(e,1)
    let arr = []
    response?.data?.datas?.forEach((d)=>{
      arr.push({label:d?.name,value:d?._id})
    })
    setrelated_to(arr)
    // console.log("response",response?.data)
    // setdata(response.data)
  }

  // console.log("moment(data.date + ' ' + data.time).add(5,'h').toString()",new Date(data.date + ' ' + data.time).setHours(new Date(data.date + ' ' + data.time).getHours() + 5))

  function handleSelect(e){
   let obj = related_to.find((e1)=>e1.value == e)
   setdata({...data,reminder_to:obj})
  }

  function handlechange(e){
    setdata({...data,[e.target.name]:e.target.value})
    seterror({...error,[e.target.name]:''})
  }

  async function submitData(){
    if(!data?.title){
      seterror({...error,title:'This field is required*'})
    }else if(!data?.reminder_to){
      seterror({...error,reminder_to:'This field is required*'})
    }else if(!data?.date){
      seterror({...error,date:'This field is required*'})
    }else if(!data?.date1){
      seterror({...error,date1:'This field is required*'})
    }else if(!data?.time){
      seterror({...error,time:'This field is required*'})
    }else if(!data?.summary){
      seterror({...error,summary:'This field is required*'})
    }else{
      // // console.log("data",data)
      // let newDate = new Date(data.date + ' ' + data.time)
      // newDate.setHours(newDate.getHours()+5.30)
      // newDate.setMinutes(newDate.getMinutes() + 30);
      // console.log("newDate",newDate.toLocaleTimeString())
      const send_Data = {...data}
      send_Data['reminder_to'] = data.reminder_to.value
      send_Data['date_time'] = data.date + ' ' + data.time
      send_Data['date_time1'] = data.date1 + ' ' + data.time
      //  newDate.toLocaleTimeString()
      // console.log("send_Data",send_Data)

      if(path === 'create'){
        const response = await CreateAdminReminderService(send_Data)
        if(response?.status === 201){
          toast.success("Reminder Added Successfully")
          resetform()
        }
      }else{
        const send_Data = {...data}
        send_Data['reminder_to'] = data.reminder_to.value
        send_Data['date_time'] = data.date + ' ' + data.time
        // newDate.toLocaleTimeString()
        if(data.expired){
          send_Data['expired'] = data.expired
        }
        const response = await UpdateAdminReminderService(send_Data,state._id)
        if(response?.status === 200){
          toast.success("Reminder Updated Successfully")
          resetform()
          navigate(-1)
        }
      }
     
    }
  } 

  function resetform(){
     setdata({reminder_to:'',title:'',summary:'',date:'',date1:'',time:'',attachments:'',expired:false})
     seterror({reminder_to:'',title:'',summary:'',date:'',date1:'',time:'',attachments:'',expired:false})
     setrelated_to([])
  }

  // console.log("date",data.date)

  return (
    <div className='flex'>
      <AdminReminderMenu />
      <div className='w-60 ml-5'>
          <GoBack />
              
              <div className='border-b pb-2'>
                  <h6 className='text-[13px] mb-1 font-[800]'>Add / Edit Admin Reminder</h6>
                  <h6 className='text-[12px] bg-slate-200 p-2'>Use the below form to create or update the admin reminder for further process.</h6>
            </div>


            <h6 className='text-[11px] font-[600] mb-1 mt-2' >Reminder To</h6>    
              <Select 
                  showSearch
                  filterOption={false}
                  value={data?.reminder_to}
                  bordered={false}
                  onSearch={searchemployee}
                  className='border border-slate-300 w-[100%]'
                  options={related_to}
                  onChange={handleSelect}
                  />


            <h6 className='text-[11px] font-[600] mb-1 mt-2' >From Date</h6>   
            <div className='border border-slate-300 '>
            <input type='date' value={data?.date} onChange={(v) => {setdata({...data,date:v.target.value})}}  placeholder='' bordered={false} className='text-[12px] px-1 py-1.5 focus:outline-none w-[100%]' /> 
            </div>

            <h6 className='text-[11px] font-[600] mb-1 mt-2' >To Date</h6>   
            <div className='border border-slate-300 '>
            <input type='date' value={data?.date1} onChange={(v) => {setdata({...data,date1:v.target.value})}}  placeholder='' bordered={false} className='text-[12px] px-1 py-1.5 focus:outline-none w-[100%]' /> 
            </div>

            <h6 className='text-[11px] font-[600] mb-1 mt-2' >Time</h6>   
            <div className='border border-slate-300 '>
            <input type='time' value={data?.time} onChange={(v) => {setdata({...data,time:v.target.value})}}  placeholder='' bordered={false} className='text-[12px] px-1 py-1.5 focus:outline-none w-[100%]' /> 
            </div>

            <TextInput 
             mandatory={true}
             label={'Title'}  
             variant="standard"
             name="title"
             type="text"
             value={data?.title}
             error={error?.title}
             handlechange={handlechange}
             placeholder=""
             InputLabelProps={{
                 style: { color: '#fff', }, 
             }}
            />


            <TextAreaInput1 
                mandatory={true}
                label={'Summary'}  
                variant="standard"
                name="summary"
                type="text"
                value={data?.summary}
                error={error?.summary}
                handlechange={handlechange}
                placeholder=""
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>

            {path === 'edit' &&
            <div className='flex items-center mt-0 mr-2 -ml-1 mt-1'>
                {data?.expired ?  <BiCheckboxSquare size={22} className='text-slate-600' onClick={()=>setdata({...data,expired:!data.expired})} />  : <BiCheckbox size={22} className='text-slate-300' onClick={()=>setdata({...data,expired:!data.expired})} /> }
                <h6 className="text-[11.5px] font-[500] ml-1">{data?.expired ? 'Expired' : 'Not Expired'}</h6> 
            </div>}
              


          <div className='flex items-center justify-end mt-4 border-t pt-2'>
              <ButtonOutlinedAutoWidth btnName="Cancel"  onClick={()=>navigate(-1)}/>
              <h6 className='w-[5px]'></h6>
              <ButtonFilledAutoWidth btnName="Save" onClick={submitData} />
          </div>
      </div>
    </div>
  )
}

export default AdminReminderCE