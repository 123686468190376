import {  get, post, put, } from "../helpers/apihelpers";


export const CreateLeadOptionsService=async(data)=>{
    try {
        const result = await post(`api/leadoptions/create`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateLeadOptionsService=async(data,id)=>{
    try {
        const result = await put(`api/leadoptions/update/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetLeadOptionsService=async({id,stage,page})=>{
    try {
        const result = await get(`api/leadoptions/get/${id}/${stage}/${page}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetAdminLeadOptionsService=async(stage,users,from_date,to_date,page)=>{

    try {
        const result = await get(`api/leadoptions/getadmin/${stage}?users=${users}&from_date=${from_date}&to_date=${to_date}&page=${page}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const FilterAdminLeadOptionsService=async(id,stage,page,users)=>{

    try {
        const result = await get(`api/leadoptions/filteradmin/${id}/${stage}/${page}?users=${users}`);
        return result;
    } catch (err) {
        return err.response;
    }
}