import { Box, Tooltip } from '@mui/material'
import React,{useState,useEffect} from 'react'
import { useLocation,useNavigate } from 'react-router-dom'
import {  GetLeadsService, SearchLeadService, UpdateImportantLeads } from '../../services/LeadServices';
import moment from 'moment';
import {AiOutlineEdit,AiOutlineStar,AiFillStar} from 'react-icons/ai';
import {BiFilterAlt} from 'react-icons/bi';
import {HiOutlineBellAlert} from 'react-icons/hi2';

import Avatar from '@mui/material/Avatar';
import { MobileDrawerAdd } from '../../components/appbars/mobile/DashboardMobile';
import {IoMdAdd} from 'react-icons/io';
import {IoMdClose} from 'react-icons/io';
import {
  Pagination,
} from "@mui/material";
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../components/button';
import {Drawer} from 'antd';
import { TextInput } from '../../components/input';
import { DatePicker } from 'antd';
import {RiBillLine} from 'react-icons/ri';


function Leadlist() {

  const location = useLocation();
  const navigate = useNavigate();

  let path = location?.pathname?.split('/')[1]

  const [leads,setleads]  = useState([])
  const [type,settype] = useState('')
  const [page,setpage] = useState(1)
  const [filter_drawer,setfilter_drawer] = useState(false)

  const [search,setsearch] = useState({text:'',from_date:'',to_date:'',activate:false})


 

  useEffect(()=>{
    setpage(1)
    getleads(1)
  },[location.pathname])


  useEffect(()=>{
    if(search.activate){
      applyfilterfunction(page)
    }else{
      getleads(page)
    }
  },[page])


  async function getleads(page){
      setfilter_drawer(false)
      let type = ''
      if(location.pathname === '/contacts' ) type = 'Contact'
      if(location.pathname === '/options_shared' )  type = 'Options Shared'
      if(location.pathname === '/inspections' )  type = 'Inspection'
      if(location.pathname === '/loi_agreement' )  type = 'LOI&Agreement'
      if(location.pathname === '/invoice_raised' )  type = 'Invoice Raised'
      if(location.pathname === '/hold' )  type = 'Hold'
      settype(type)
      const response =  await GetLeadsService(type,page);
      setleads(response.data)
  }

  async function updateImportance(v){
    let send_data = {
      '_id':v._id,
      'important':v?.important === true ? 0 : 1
    }
    const response = await UpdateImportantLeads(send_data)
    if(response.status === 200){
      getleads(page);
    }
  }

  const handlePagination = (e, pageNumber) => {
    setpage(pageNumber)
  }

  async function applyfilterfunction(page){
    setsearch({...search,activate:true})
    let start_date = new Date(search?.from_date).toLocaleDateString().replaceAll('/','-').split('-').reverse().join('-')
    let end_date = new Date(search?.to_date).toLocaleDateString().replaceAll('/','-').split('-').reverse().join('-')
    const response = await SearchLeadService({search_text:search.text,status:type,from_date:(search?.from_date !== ''  && search?.from_date !== null) ? start_date : '',to_date:(search?.to_date !== ''  && search?.to_date !== null) ? end_date : '',page:page})
    setleads(response.data)
    setfilter_drawer(false)
  }

  async function resetfunc(){
    setsearch({text:'',from_date:'',to_date:'',activate:false})
    getleads(1)
  }




  return (
    <div style={{width:'100%',minHeight:'100vh',maxHeight:'100vh',height:'100%',position:'relative'}}>

    <Drawer
      anchor={'right'}
      open={filter_drawer}
      closable={false}
      width={260}

      // onClose={toggleDrawer(anchor, false)}
    >
      {/* {list(anchor)} */}
      <div className=' w-52 -mt-3'>
        <span onClick={()=>setfilter_drawer(!filter_drawer)} ><IoMdClose className='absolute right-2 cursor-pointer' /></span>
        <h6 className='text-[13px]'>Use the below form to apply filter for your leads</h6>


        <TextInput 
              mandatory={false}
              label={'Search Text'}  
              variant="standard"
              name="text"
              type="text"
              value={search.text}
              // error={error.contact_name}
              handlechange={e=>setsearch({...search,text:e.target.value})}
              placeholder="Enter contact name"
              InputLabelProps={{
                  style: { color: '#fff', }, 
              }}/>


         
          
          <div>
          <h6 className='text-[12px] font-[600] mb-1 mt-2' >{'From Date'}</h6>
          <div>
         <DatePicker ampm={false} placeholder='' className='w-full'  value={search?.from_date} onChange={(v) => {console.log('v na',new Date(v).toLocaleDateString());setsearch({...search,from_date:v})}} /> 
         </div>    
         <h6 className='text-[12px] font-[600] mb-1 mt-2' >{'To Date'}</h6>
         <div>
         <DatePicker ampm={false} placeholder='' className='w-full'  value={search?.to_date} onChange={(v) => {setsearch({...search,to_date:v})}}  /> 
         </div>
         </div>




         
         <div className='flex mt-4'>
         <div  className="mr-2" >
         <ButtonOutlinedAutoWidth btnName="Reset" onClick={resetfunc}/>   
         </div>
         <ButtonFilledAutoWidth btnName="Apply" onClick={()=>applyfilterfunction(1)} />   
         </div>

      </div>
    </Drawer>

    <Box sx={{display:{sm:'block',md:'none',lg:'none'}}}>
    <MobileDrawerAdd name={type} />
    </Box> 
    
    <Box  sx={{marginTop:{sm:'0px',xs:'0px',md:'20px',lg:'20px'},padding:{xs:'0px',sm:'0px',md:'10px',lg:'10px'}}}>
       <Box sx={{display:{sm:'none',xs:'none',lg:'block',md:'block'}}}>
        <div className='flex items-center justify-between '>
         <h6 className='font-[700] text-[14px] '>{'Landlord Invoice'} ({leads?.pagination?.total})</h6>

       <div className='flex items-center'>

       {/* <ButtonFilledAutoWidth btnName="Add Filter" onClick={()=>setfilter_drawer(!filter_drawer)} />  */}
       <BiFilterAlt className='bg-gray-100 p-1.5 ml-2' size={30} onClick={()=>setfilter_drawer(!filter_drawer)}  />
       <IoMdAdd className='bg-gray-100 p-1.5 ml-2' size={30}  onClick={()=>navigate('create')}  />
       </div>
       </div>

       <div className='flex bg-[#f5f5f5] border mt-2'>
          <h6 className='text-[13px] w-[70px]  px-2 py-1 border-r font-[600]'>SL NO</h6>
          <h6 className='text-[13px] w-1/5  px-2 py-1 border-r font-[600]'>CONTACT NAME</h6>
          <h6 className='text-[13px] w-1/5  px-2 py-1 border-r font-[600]'>MOBILE</h6>
          <h6 className='text-[13px] w-1/5 px-2 py-1 border-r font-[600]'>COMPANY NAME</h6>
          <h6 className='text-[13px] w-1/5 px-2 py-1 border-r font-[600]'>LEAD STAGE</h6>
          <h6 className='text-[13px] w-1/5  px-2 py-1 border-r font-[600]'>CREATED ON</h6>
          <h6 className='text-[13px] w-1/5  px-2 py-1 font-[600]'>ACTIONS</h6>
       </div>
     

       {leads?.datas?.map((d,i)=>(
       <div key={d?._id} className='flex border-b cursor-pointer z-0 border-l border-r'  >
          <h6  onClick={()=>navigate('detail',{state:d?._id})} className='text-[12px] w-[70px]  px-2 py-1 border-r truncate'>{(page > 1 ? i+1+ (25 * (page - 1)) : i+1 )}</h6>
          <h6  onClick={()=>navigate('detail',{state:d?._id})} className='text-[12px] w-1/5  px-2 py-1 border-r truncate'>{d?.contact_name}</h6>
          <h6  onClick={()=>navigate('detail',{state:d?._id})} className='text-[12px] w-1/5  px-2 py-1 border-r truncate'>{d?.phone}</h6>
          <h6  onClick={()=>navigate('detail',{state:d?._id})} className='text-[12px] w-1/5 px-2 py-1 border-r truncate'>{d?.company_name}</h6>
          <h6  onClick={()=>navigate('detail',{state:d?._id})} className='text-[12px] w-1/5 px-2 py-1 border-r truncate'>{d?.stage?.name}</h6>
          <h6  onClick={()=>navigate('detail',{state:d?._id})} className='text-[12px] w-1/5  px-2 py-1 border-r truncate'>{moment(d?.createdAt).format('ll')}</h6>
          <h6  className='text-[12px] w-1/5  px-2 py-1 flex'>
           {d?.important === false ?
           <Tooltip title="star">
           <span> <AiOutlineStar size={20} className='mr-2 z-10' style={{color:'#c7c7c7'}} onClick={()=>updateImportance(d)}/></span>
           </Tooltip>
            :
           <Tooltip title="star">
            <span><AiFillStar size={20} className='mr-2 z-10' style={{color:'#fccb42'}} onClick={()=>updateImportance(d)} /></span>
           </Tooltip>
            }
            <Tooltip title="Reminder">
            <span><HiOutlineBellAlert size={17} onClick={()=>navigate(`detail/reminder`,{state:d?._id})} className="mr-2 z-10"/></span>
            </Tooltip>
            {path !== 'invoice_raised' &&
            <Tooltip title="Edit">
            <span><AiOutlineEdit size={17} className="z-10" onClick={()=>navigate('edit',{state:d?._id})}/></span>
            </Tooltip>}
            {path === 'invoice_raised' &&
            <Tooltip title="Invoice">
            <span><RiBillLine size={17} className="z-10 ml-1" onClick={()=>navigate('invoice',{state:d})}/></span>
            </Tooltip>}

            {/* <Tooltip title="Delete">
            <span><MdOutlineDelete size={17} className="z-10 ml-2" onClick={()=>deletelead(d?._id)}/></span>
            </Tooltip> */}
          </h6>
       </div>))}
       </Box>

       <Box sx={{display:{sm:'block',xs:'block',lg:'none',md:'none'}}}>
       {leads?.datas?.map((d,i)=>(
        <div key={d._id}  className={`px-1 py-2 flex relative ${i%2 === 0 && 'bg-[#f5fffe]'}`}>
        <Avatar onClick={()=>navigate('detail',{state:d?._id})} sx={{ width: 35, height: 35,fontSize:'17px',textTransform:'uppercase' }} style={{backgroundColor:`#${d?.color.split('0xff')[1]}`}}>{d?.contact_name?.slice(0,1)}</Avatar>
        <div className='ml-2' onClick={()=>navigate('detail',{state:d?._id})}>
          <h5 className='text-[14px] font-[800] mb-0 pb-0'>{d?.contact_name}</h5>
          <h5 className='text-[13px] font-[600] mb-0 -mt-1 pb-0'>{d?.company_name}</h5>
          <h5 className='text-[12px] -mt-1 mb-0 pb-0 '>{d?.stage?.name}</h5>
        </div>
        <h6 className='absolute right-2 top-1 text-[9px]'>{moment(d?.createdAt).format('ll')}</h6>
        {d?.important === false ?
        <AiOutlineStar size={20} className='absolute right-2 top-5' style={{color:'#c7c7c7'}} onClick={()=>updateImportance(d)}/>
        :
        <AiFillStar size={20} className='absolute right-2 top-5' style={{color:'#fccb42'}} onClick={()=>updateImportance(d)} />
        }
         
        </div>
       ))}
     

       </Box>
     


    </Box>

    
    <div className="tex-center flex items-center justify-center mt-10 ">
      {(leads?.pagination?.total > 25 &&
        <Pagination size={'small'} page={page} className="flex justify-center my-10 pb-20" count={Math.ceil(leads?.pagination?.total / 25) } onChange={handlePagination} />
      )}
    </div>
    </div>
  )
}

export default Leadlist