import React,{useState,useEffect} from 'react'
import { GetDepartmentService } from '../../services/DepartmentServices'
import { GetFinanceDataService } from '../../services/AdminServicesfile/FinanceData'
import {
  Pagination,
} from "@mui/material";
import moment from 'moment';
import {GoGraph} from 'react-icons/go';
import Chart from "react-apexcharts";
import { Drawer } from 'antd';
import {IoMdClose} from 'react-icons/io';
import SettingsMenu from '../staticscreens/SettingsMenu';
import { useLocation } from 'react-router-dom';
import FinanceMenu from '../finance/FinanceMenu';

function FinanceDashboard() {

  

  const [graph_value,setgraph_value] = useState({options:'',series:[]})
  const [department,setdepartment] = useState([])
  const [selected_department,setselected_department] = useState({})
  const [data,setdata] = useState([])
  const [singleData,setsingleData] = useState({})
  const [pagination,setpagination] = useState([])
  const [page,setpage] = useState(1)

  const [selected_Data,setselected_Data] = useState('')

  const [graph,setgraph] = useState(false)
  

  const {pathname} = useLocation()
 
  const path = pathname.split('/')[1]

  useEffect(()=>{
    getDepartment()
  },[])

  const getDepartment = async() =>{
      const response = await GetDepartmentService()
      setdepartment(response.data.datas)
  }

  useEffect(()=>{
    if(selected_department.id !== null && selected_department.id !== undefined){
      getData()
    }
  },[page,selected_department])
  
  let rupee = new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
  });

  async function getData(){
    const response = await GetFinanceDataService(selected_department?.id,page)
    if(page === 1){
      setsingleData(response.data.data[0])
    }
    setdata(response.data.data)
    setpagination(response.data.pagination)
  }

  async function setgraph_valuefun(graph_data){
    let labels = [`Invoice Amount ${rupee.format(graph_data?.invoice_amount)}`, `Amount Recieved ${rupee.format(graph_data?.amount_collected)}`,`Expenses ${rupee.format(graph_data?.expenses)}`,`Total Outstanding ${rupee.format(graph_data?.total_outstanding)}`]
    //  console.log("selec",selected_Data?.)
    let series = [graph_data?.invoice_amount/(graph_data?.invoice_amount/100) ,graph_data?.amount_collected/(graph_data?.invoice_amount/100),graph_data?.expenses/(graph_data?.invoice_amount/100),graph_data?.total_outstanding/(graph_data?.invoice_amount/100) ]
    setgraph_value({...graph_value,series,options:{
      chart: {
        type: 'pie',
      },
      legend: {
        fontWeight: 'bold',
      },
      plotOptions: {
        pie: {
          size: '50%'
        }
      },
      
      labels
    }})
  }

  const handlePagination = (e, pageNumber) => {
    setpage(pageNumber)
  }


  return (
    <div className=''>
       <div className='flex'>
       {path === 'finance' &&
      <FinanceMenu />}
      {path === 'settings' &&
      <SettingsMenu /> }

      <Drawer closable={false} maskStyle={{background:'black',opacity:'0.7'}} width={'50%'} placement="right" onClose={()=>setgraph(false)} open={graph}>
        <div className='relative'>
          <IoMdClose className='absolute right-0' onClick={()=>setgraph(false)} />
         <h6 className='text-[14px] font-[700]'>Graphical Representation</h6>
         <h6 className='leading-tight bg-gray-100 p-2 text-[13px] my-2'>See the graphical representation of revenue or loss for the period of invocie from (<span className='text-[10px] font-[900]'>{moment(selected_Data?.from).format('LL')} - {moment(selected_Data?.to).format('LL')}</span>)</h6>
         
         <div className='w-4/5'>
         <Chart
              options={graph_value?.options}
              type="donut"
              height="290"
              series={graph_value?.series}
              width={'500'}
            />
        </div>
        </div>
      </Drawer>
      
      

            
         <div className='w-[85%] px-4 mt-5'>
        <h6 className='border-b pb-2 text-[13.5px] font-[800]'>Finance Dashboard</h6>

        <div className='flex mt-2 -ml-1'>
         {department?.map((d)=>( 
         <h6 key={d?.id} className={`text-[13px] px-2 py-1 mx-1 border cursor-pointer font-[500]  ${selected_department?.id === d?.id && 'bg-slate-600 border-slate-600 text-white'} `} onClick={()=>{setpage(1);setselected_department(d)}}>{d?.department_name}</h6>
         ))}
        </div>

      
       {data.length === 0 &&
        <div className='grid place-items-center mt-20  items-center justify-center'>
          <img src={'https://fidecrmfiles.s3.amazonaws.com/NoDataFound1.png'} className='w-40 h-40 object-contain' /> 
          <h6 className='font-bold text-[14px] -mt-10'>No data found</h6>
          <h6 className='font-[500] text-slate-700 text-[12.5px] -mt-2'>Try Any Filter Option to See the "Invoice Report" For the Vertical</h6>
        </div>}

        {data.length > 0 &&
          <> 
            <h6 className='text-[13px] font-[800] mt-4  mb-1'>Recent Report</h6>
           <div>
           <div className='flex items-center border-b border-t  border-l border-r'>
               <h6 className='text-[11.5px] font-[500] p-1 min-w-[5%] font-[800] max-w-[5%] w-[5%] border-r'>{1}</h6>
               <h6 className='text-[11.5px] font-[500]  p-1 min-w-[15%] max-w-[15%] w-[15%] border-r'>{moment(singleData?.from).format('DD-MM-YYYY')} -  {moment(singleData?.to).format('DD-MM-YYYY')}</h6>
               <h6 className='text-[11.5px] font-[500] p-1 min-w-[5%] max-w-[5%] w-[5%] border-r'>{singleData?.invoice_raised_no}</h6>
               <h6 className='text-[11.5px] font-[500]  p-1 min-w-[15%] max-w-[15%] w-[15%] font-[600] border-r'>{rupee?.format(singleData?.invoice_amount)?.split('.')[0]}</h6>
               <h6 className='text-[11.5px] font-[500]  p-1 min-w-[15%] max-w-[15%] w-[15%] font-[600] border-r'>{rupee?.format(singleData?.amount_collected)?.split('.')[0]}</h6>
               <h6 className='text-[11.5px] font-[500]  p-1 min-w-[15%] max-w-[15%] w-[15%] font-[600] border-r'>{rupee?.format(singleData?.total_outstanding)?.split('.')[0]}</h6>
               <h6 className='text-[11.5px] font-[500]  p-1 min-w-[15%] max-w-[15%] w-[15%] font-[600] border-r'>{singleData?.expenses !== undefined && rupee?.format(singleData?.expenses)?.split('.')[0]}</h6>
               <h6 className='text-[11.5px] font-[500]  p-1 min-w-[10%] max-w-[10%] w-[10%] font-[600] border-r'><span className='bg-slate-100 text-slate-600 text-[11px] p-1 px-2'>{singleData?.invoice_amount - singleData?.expenses < 0 ? 'Loss' : 'Profit'}</span></h6>
               <h6 className='text-[11.5px] font-[700] p-1 min-w-[5%] max-w-[5%]  w-[5%] border-r' onClick={()=>{setselected_Data(singleData);setgraph_valuefun(singleData);setgraph(true)}}><GoGraph size={16} className='ml-2.5 mt-1' /></h6>

           </div>
           </div>
           
           <div className='mt-3'>
               <h6 className='text-[13px] font-[700] p-1 border-b'>Data Found ({pagination?.total})</h6>

           <div className='flex items-center border-b mt-1 border-l border-r border-t'>
               <h6 className='text-[11.5px] font-[700] p-1 min-w-[5%] max-w-[5%] w-[10%] border-r'>SL NO</h6>
               <h6 className='text-[11.5px] font-[700] p-1 min-w-[15%] max-w-[15%]  w-[15%] border-r'>Date</h6>
               <h6 className='text-[11.5px] font-[700] p-1 min-w-[5%] max-w-[5%]  w-[10%] border-r'>No</h6>
               <h6 className='text-[11.5px] font-[700] p-1 min-w-[15%] max-w-[15%]  w-[15%] border-r'>Invoice Raised Amount</h6>
               <h6 className='text-[11.5px] font-[700] p-1 min-w-[15%] max-w-[15%]  w-[15%] border-r'>Amount Collected</h6>
               <h6 className='text-[11.5px] font-[700] p-1 min-w-[15%] max-w-[15%]  w-[15%] border-r'>Total Outstanding</h6>
               <h6 className='text-[11.5px] font-[700] p-1 min-w-[15%] max-w-[15%]  w-[15%] border-r'>Expenses</h6>
               <h6 className='text-[11.5px] font-[700] p-1 min-w-[10%] max-w-[10%]  w-[10%] border-r'>Profit / Loss</h6>
               <h6 className='text-[11.5px] font-[700] p-1 min-w-[5%] max-w-[5%]  w-[5%] border-r'>Action</h6>
           </div>
           
           {data?.map((d,i)=>(
           <div key={d?._id} className='flex items-center border-b  border-l border-r'>
               <h6 className='text-[11.5px] font-[500] p-1 min-w-[5%] font-[800] max-w-[5%] w-[10%] border-r'>{page == 1 ? i+1 : ((page-1) * pagination.limit) + (i+1)}</h6>
               <h6 className='text-[11.5px] font-[500]  p-1 min-w-[15%] max-w-[15%] w-[15%] border-r'>{moment(d?.from).format('DD-MM-YYYY')} -  {moment(d?.to).format('DD-MM-YYYY')}</h6>
               <h6 className='text-[11.5px] font-[500] p-1 min-w-[5%] max-w-[5%] w-[5%] border-r'>{d?.invoice_raised_no}</h6>
               <h6 className='text-[11.5px] font-[500]  p-1 min-w-[15%] max-w-[15%] w-[15%] font-[600] border-r'>{rupee?.format(d?.invoice_amount)?.split('.')[0]}</h6>
               <h6 className='text-[11.5px] font-[500]  p-1 min-w-[15%] max-w-[15%] w-[15%] font-[600] border-r'>{rupee?.format(d?.amount_collected)?.split('.')[0]}</h6>
               <h6 className='text-[11.5px] font-[500]  p-1 min-w-[15%] max-w-[15%] w-[15%] font-[600] border-r'>{rupee?.format(d?.total_outstanding)?.split('.')[0]}</h6>
               <h6 className='text-[11.5px] font-[500]  p-1 min-w-[15%] max-w-[15%] w-[15%] font-[600] border-r'>{d?.expenses !== undefined && rupee?.format(d?.expenses)?.split('.')[0]}</h6>
               <h6 className='text-[11.5px] font-[500]  p-1 min-w-[10%] max-w-[10%] w-[10%] font-[600] border-r'><span className='bg-slate-100 text-slate-600 text-[11px] p-1 px-2'>{d?.invoice_amount - d?.expenses < 0 ? 'Loss' : 'Profit'}</span></h6>
               <h6 className='text-[11.5px] font-[700] p-1 min-w-[5%] max-w-[5%]  w-[5%] border-r' onClick={()=>{setselected_Data(d);setgraph_valuefun(d);setgraph(true)}}><GoGraph size={16} className='ml-2.5 mt-1' /></h6>

           </div>
           ))}

   <div className="tex-center flex items-center justify-center -mt-1 ">
     {(pagination?.total > pagination?.limit &&
       <Pagination size={'small'} page={page} className="flex justify-center my-10 pb-20" count={pagination?.totalPages} onChange={handlePagination} />
     )}
   </div>
       </div>
       </>
        }
        </div>
        </div>
        
    </div>
  )
}

export default FinanceDashboard