import React, { useEffect, useState } from 'react'
import GoBack from '../../components/back/GoBack'
import { GetAgreementDetailService, UpdateAgreementService } from '../../services/AgreementListServices'
import { useLocation, useNavigate } from 'react-router-dom'
import AgreementFollowUpMenu from './AgreementFollowUpMenu'
import { TextAreaInput1, TextInput } from '../../components/input'
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../components/button'
import { BiCheckbox,BiCheckboxSquare } from 'react-icons/bi'
import { Select } from 'antd'
import { SearchUser } from '../../services/AuthServices'
import toast from 'react-hot-toast'

function AgreementlistCE() {

  const navigator = useNavigate()

  let {state,pathname} = useLocation()

  // console.log("pathaname",pathname)

  let path = pathname?.split('/')[2]
  // console.log("path",path)

  const [data,setdata] = useState({})
  const [users,setusers] = useState([])

  const stage = [
    {label:"Pending",value:"Pending"},
    {label:"Couldn't Convert",value:"Couldn't Convert"},
    {label:"Converted",value:"Converted"}
  ]

  useEffect(()=>{
    getdetail()
  },[state])

  async function getdetail(){
    const response = await GetAgreementDetailService(state)
      
    let d = response?.data?.datas[0]
    let arr = []
    if(d?.followup_employee?.length > 0){
        d?.followup_employee?.forEach((d1)=>{
            arr.push({label:d1?.name,value:d1?._id})
        })
    }
    d['lease_years'] = d.lease_years
    d['lock_in_period_from_date'] = d.lock_in_period_from_date?.slice(0,10)
    d['lock_in_period_end_date'] = d.lock_in_period_end_date?.slice(0,10)
    d['lease_end_date'] = d.lease_end_date?.slice(0,10)

    setusers(arr)
    setdata({...d,followup_employee:arr})
  }

  async function onSearch(v){
    const response = await SearchUser(v)
    let arr = []
    response?.data?.forEach((d)=>{
     arr.push({value:d?._id,label:d?.name})
    })
    setusers(arr)
    // console.log("response kp na",response)
  }

  async function onChange(v){
    let arr = []
    v.forEach((d)=>{
        if(users.find((u)=>u.value === d) !== undefined && users.find((u)=>u.value === d) !== null){
            arr?.push(users.find((u)=>u.value === d))
        }
    })
    setdata({...data,followup_employee:arr})
 }

  async function handleChange(e){
    setdata({...data,[e.target.name]:e.target.value})
  }

  async function submitData(){

    if(path !== 'followup'){
        let send_data = {
            lock_in_period:data?.lock_in_period,
            agreement_expire:data?.agreement_expire,
            reminder_date_set:data?.reminder_date_set,
            reminder_active:data?.reminder_active,
            followup_employee:data?.followup_employee?.map((f)=>f?.value),
            followup_md_remarks:data?.followup_md_remarks,

            type:'assign'
        }
        const response = await UpdateAgreementService(data?._id,send_data)
        if(response?.status === 200){
            navigator(-1)
            toast.success("Data Updated Successfully")
        }
    }else{
      let send_data = {
        followup_employee_remarks:data?.followup_employee_remarks,
        stage:data?.stage,
        type:'update'
      }
      // console.log("send_data",send_data)
      const response = await UpdateAgreementService(data?._id,send_data)
      if(response?.status === 200){
          navigator(-1)
          toast.success("Data Updated Successfully")
      }
    }


  }

  return (
    <div className='w-[100%] h-screen flex  max-h-screen '>
        <div className='flex w-[100%]'>
            <AgreementFollowUpMenu />
            <div className='min-w-[280px] max-w-[280px] ml-4  mt-4'>
              <GoBack />
              <div className='border-b  pb-2'>
                <h6 className='font-[800] mb-1'>Edit Agreement FollowUp </h6> 
                <h6 className='text-[11px] leading-tight font-[500] p-2 bg-slate-100 '>Use the below form to edit the agreement follow up created by you .</h6> 
              </div> 

              <div>
                <div className='mb-1'>
                    <TextInput
                        mandatory={true}
                        label={'Company Name'}  
                        variant="standard"
                        name="amount"
                        type="text"
                        value={data?.lead?.company_name}
                        />
                </div> 

               

                <div className='mb-1'>
                    <TextInput
                        mandatory={true}
                        label={'Contact Name'}  
                        variant="standard"
                        name="contact_name"
                        type="text"
                        value={data?.lead?.contact_name}
                        />
                </div> 

                <div className='mb-1'>
                    <TextInput
                        mandatory={true}
                        label={'Mobile No'}  
                        variant="standard"
                        name="phone"
                        type="text"
                        value={data?.lead?.phone}
                        />
                </div> 

                <div className='mb-1'>
                    <TextInput
                        mandatory={true}
                        label={'Email'}  
                        variant="standard"
                        name="email"
                        type="text"
                        value={data?.lead?.email}
                        />
                </div> 

                <TextInput
                        mandatory={true}
                        label={'Lease Years'}  
                        variant="standard"
                        name="lease_years"
                        handlechange={path !== 'followup' && handleChange}
                        type="text"
                        value={data?.lease_years}
                        />

                <div className='flex items-center'>
                <div className='mb-1 w-[49%] mr-[1%]'>
                    <TextInput
                        mandatory={true}
                        label={'Lock In Period (From Date)' }  
                        variant="standard"
                        name="lock_in_period_from_date"
                        handlechange={path !== 'followup' && handleChange}
                        type="date"
                        value={data?.lock_in_period_from_date?.slice(0,10)}
                        />
                </div> 

                <div className='mb-1 w-[49%] ml-[1%]'>
                    <TextInput
                        mandatory={true}
                        label={'Lock In Period (End Date)'}  
                        variant="standard"
                        name="lock_in_period_end_date"
                        handlechange={path !== 'followup' && handleChange}
                        type="date"
                        value={data?.lock_in_period_end_date?.slice(0,10)}
                        />
                </div> 
                </div>

                <TextInput
                        mandatory={true}
                        label={'Lease End Date'}  
                        variant="standard"
                        name="lease_end_date"
                        handlechange={path !== 'followup' && handleChange}
                        type="date"
                        value={data?.lease_end_date}
                        />

                <div className='mb-1'>
                    <TextInput
                        mandatory={true}
                        label={'Agreement Expire'}  
                        variant="standard"
                        handlechange={path !== 'followup' && handleChange}
                        name="agreement_expire"
                        type="date"
                        value={data?.agreement_expire?.slice(0,10)}
                        />
                </div> 

                

                <h6 className='bg-slate-100 p-2 text-[11px]'>To get a detail view of the lead and the agreement just click on the <span onClick={()=>navigator('/leads/contacts/detail',{state:data?.lead?._id})} className='underline font-bold capitalize cursor-pointer'>view lead</span></h6>
                 
                

                {path !== 'followup' && <>


                <div className='mb-1'>
                    <TextInput
                        mandatory={true}
                        label={'Upcoming Reminder Date Set'}  
                        variant="standard"
                        name="reminder_date_set"
                        handlechange={path !== 'followup' && handleChange}
                        type="date"
                        value={data?.reminder_date_set?.slice(0,10)}
                        />
                </div> 

                <div className='flex items-center my-2' onClick={()=>setdata({...data,reminder_active:!data?.reminder_active})}>
                   {!data?.reminder_active ? <BiCheckbox size={20} /> : <BiCheckboxSquare size={20} />}
                   <h6 className='text-[11px] ml-1 font-[600]'>{'Reminder Active'}</h6>
                </div>

                <div className='mb-1'>
                    <TextAreaInput1
                        mandatory={false}
                        label={'Md Remark'}  
                        variant="standard"
                        name="followup_md_remarks"
                        type="text"
                        handlechange={handleChange}
                        value={data?.followup_md_remarks}
                        />
                </div> 
                </>}
                
                {path === 'followup' && <>
                <div className='mb-1'>
                    <TextAreaInput1
                        mandatory={false}
                        label={'Employee Remark'}  
                        variant="standard"
                        name="followup_employee_remarks"
                        type="text"
                        handlechange={handleChange}
                        value={data?.followup_employee_remarks}
                        />
                </div> 
                </>
                }

                {path !== 'followup' && <>
                <div className='mb-1'>
                 <h6 className='text-[11px] mb-1 font-[600]'>{'Follow Up Employee'}</h6>
                 <div className='border border-slate-300'>
                 <Select
                        showSearch
                        mode={'multiple'}
                        bordered={false}
                        className='w-full rounded-[0px] outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none' 
                        optionFilterProp="children"
                        onChange={onChange}
                        onSearch={onSearch}
                        value={data?.followup_employee === '' ? null : data?.followup_employee}
                        // placeholder={props?.placeholder}
                        filterOption={false}
                        options={users}
                    />  
                 </div>  
                </div> 
                </>}

                <div className='mb-1'>
                 <h6 className='text-[11px] mb-1 font-[600]'>{'Follow Up Employee'}</h6>
                 <div className='border border-slate-300'>
                 <Select
                        bordered={false}
                        className='w-full rounded-[0px] outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none' 
                        optionFilterProp="children"
                        onChange={(e)=>setdata({...data,stage:e})}
                        value={data?.stage === '' ? null : data?.stage}
                        // placeholder={props?.placeholder}
                        filterOption={false}
                        options={stage}
                    />  
                 </div>  
                </div> 


                <div className='flex items-center justify-end mt-4 border-t pt-2 pb-10'>
                    <div className='mr-2'>
                    <ButtonOutlinedAutoWidth btnName="Cancel" onClick={()=>navigator(-1)} />
                    </div> 
                    <ButtonFilledAutoWidth btnName="Save" onClick={()=>submitData()} />
                </div>

              </div>



            </div>
        </div>
    </div>        
  )
}

export default AgreementlistCE