import { deleteRequest, get, post, postfd, put } from "../helpers/apihelpers";

export const CreateMdFollowUpPropsoalService=async(data)=>{
    try {
        const result = await post(`api/md_followup_proposal/create`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateMdFollowUpPropsoalService=async(data,id)=>{
    try {
        const result = await put(`api/md_followup_proposal/update/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetMdFollowUpPropsoalService=async(text,from_date,to_date,page,type='',status='')=>{
    try {
        const result = await get(`api/md_followup_proposal/get?search=${text}&from_date=${from_date}&to_date=${to_date}&page=${page}&type=${type}&status=${status}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetMdFollowUpPropsoalAdminService=async(page=1,from_date='',to_date='',search='',department,user)=>{
    try {
        const result = await get(`api/md_followup_proposal/get-admin?from_date=${from_date}&to_date=${to_date}&search=${search}&page=${page}&department=${department}&user=${user}`);
        return result;
    } catch (err) {
        return err.response;
    }
}


export const FilterMdFollowUpPropsoalService=async({stage,step,page,from_date,to_date,search})=>{
    try {
        const result = await get(`api/md_followup_proposal/filter?related_to=${stage}&stage=${step}&from_date=${from_date}&to_date=${to_date}&search=${search}&page=${page}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteMdFollowUpPropsoalService=async(id)=>{
    try {
        const result = await deleteRequest(`api/md_followup_proposal/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DownloadMdFollowUpPropsoalService=async(user,from_date,to_date)=>{
    try {
        const result = await get(`api/md_followup_proposal/download_excel?user=${user}&from_date=${from_date}&to_date=${to_date}`);
        return result;
    } catch (err) {
        return err.response;
    }
}