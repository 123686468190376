import React,{useState,useEffect} from 'react'
import { TextAreaInput1, TextInput } from '../../components/input'
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../components/button'
import { toast } from 'react-hot-toast'
import GoBack from '../../components/back/GoBack'
import { useLocation, useNavigate } from 'react-router-dom'
// import LeadMenu from '../LeadMenu'
import { GetDepartmentService } from '../../services/DepartmentServices'
import { Select } from 'antd'
import Uploader from '../../components/Uploader'
import { useSelector } from 'react-redux'
import AtsMenu from './AtsMenu'
import { CreateAtsClientService, UpdateAtsClientService } from '../../services/Ats/AtsClientServices'

function AtsClientManagmentCE() {

  const {state,pathname} = useLocation() 
  const navigator = useNavigate()
  
//   console.log("state",state)

const user_id = useSelector(state=>state.Auth.id)
const roles = useSelector(state=>state.Auth.roles)
// console.log("user",user)
  
  const path = pathname?.split('/')[pathname?.split('/')?.length - 1]
  const path1 = pathname?.split('/')[pathname?.split('/')?.length - 3]
  const [singleData,setsingleData] = useState({image:'',company_name:'',client_name:'',client_logo:'',client_no:'',client_email:'',address:'',are_we_serving:true,stage:{label:'Active',value:'Active'}})
  const [error,seterror] = useState({image:'',company_name:'',client_name:'',client_logo:'',client_no:'',client_email:'',address:'',are_we_serving:true,stage:''})
  const [departmentArr,setdepartmentArr] = useState([])
  const [users,setusers] = useState([])
  

  

  const [loader,setloader] = useState(false)

//   console.log("path kp",path,state)
  useEffect(()=>{
    if(path === 'create' &&  path1 === 'assigned_work' && !roles?.includes('task_manager')){
        navigator(-1)
    }
    if(path === 'edit'){
      let d = state.data
      let edit_data = {
        ...d,
        stage:{label:d?.stage,value:d?.stage},
        image:(d?.client_logo === undefined || d?.client_logo === null) ? '' : d?.client_logo
    }
     
    // if(d?.image !== undefined && d?.remarks !== null){
    //     edit_data['remarks'] = d?.remarks
    // }  

      setsingleData({...edit_data})
    }
    getoptions()
  },[])

  async function getoptions(){
    const response = await GetDepartmentService()
    let arr = []

    response?.data?.datas?.forEach((d)=>{
        arr.push({value:d?.id,label:d?.department_name})
    })

    setdepartmentArr(arr)
  } 

  console.log("singleData?.stage?.value",singleData?.stage?.value)

  async function submitform(){
    // if(state?.type === 'daily_tasks'){
        if(!singleData.company_name){
            seterror({...error,company_name:'The company name field is required'})
        }else if(!singleData.client_name){
            seterror({...error,client_name:'The client name field is required'})
        }else if(!singleData.client_no){
            seterror({...error,client_no:'The client no field is required'})
        }else if(!singleData.client_email){
            seterror({...error,client_email:'The client email field is required'})
        }else{
             
                 const fd = new FormData()

            
                   fd.append('company_name',singleData?.company_name)
                   fd.append('client_name',singleData?.client_name)
                   fd.append('client_no',singleData?.client_no)
                   fd.append('client_email',singleData?.client_email)
                   fd.append('address',singleData?.address)
                   fd.append('are_we_serving',singleData?.stage?.value === "Active" ? '1' : '0')
                   fd.append('stage',singleData?.stage?.value)

                  

                if(singleData?.image?.name !== undefined){
                   fd.append('image',singleData?.image)
                    // send_data['image'] = singleData?.image
                }
                
                if(path !== 'edit'){
                    const response = await CreateAtsClientService(fd)
                    if(response.status === 200){
                        setloader(false)
                        toast.success('Client Created Successfully')
                        resetform() 
                    }
                }else{
                    fd.append('_id',state.data._id)
                    const response = await UpdateAtsClientService(fd,state.data._id)
                    if(response.status === 200){
                        setloader(false)
                        toast.success('Client Updated Successfully')
                        resetform() 
                    }
                }    
              
    }  
    
   }


   function resetform(){
        setsingleData({image:'',company_name:'',client_name:'',client_logo:'',client_no:'',client_email:'',address:'',are_we_serving:true})
        seterror({image:'',company_name:'',client_name:'',client_logo:'',client_no:'',client_email:'',address:'',are_we_serving:true})
        if(path === 'edit'){
            navigator(-1)
        }
       
    }

   

    function handlechange(e){
        setsingleData({...singleData,[e.target.name] : e.target.value})
        seterror({...error,[e.target.name] : ''})
    }

  



  return (
    <div className='flex min-h-screen max-h-screen h-screen '>
    <AtsMenu />
    <div className='w-[25%] pl-5 mt-5 min-h-screen max-h-screen h-screen overflow-y-screen overflow-x-hidden' >
            <GoBack />
            
            <div className='border-b pb-2'>
                <h6 className='text-[13px] mb-1 font-[800]'>Add / Edit Client Managment</h6>
                <h6 className='text-[12px] bg-slate-200 p-2'>Use the below form to create or update the client details to further process on requirment.</h6>
           </div>

            
            <div className='mt-2'>

            <h6 className='text-[11px] font-[600] mb-1' >Company Logo</h6>    
            <Uploader image={singleData?.image}  setimagefunc={(e)=>{setsingleData({...singleData,image:e});seterror({...error,image:''})}}  removeimageuploadfunc = {()=>{setsingleData({...singleData,image:''});seterror({...error,image:''})}}/>    

            <TextInput 
              mandatory={true}
              label={'Company Name'}  
              variant="standard"
              name="company_name"
              type="text"
              value={singleData?.company_name}
              handlechange={handlechange}
              placeholder="Enter contact name"
              InputLabelProps={{
                  style: { color: '#fff', }, 
              }}/>
            
            <div className='flex w-[100%] items-center space-between'>
            <div className='w-[48%] mr-[2%]'>
                <TextInput 
                mandatory={true}
                label={'Spoc Name'}  
                variant="standard"
                name="client_name"
                type="text"
                value={singleData?.client_name}
                handlechange={handlechange}
                placeholder="Enter contact name"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>
              </div>

              <div className='w-[48%] ml-[2%]'>
                <TextInput 
                mandatory={true}
                label={'Spoc No'}  
                variant="standard"
                name="client_no"
                type="text"
                value={singleData?.client_no}
                handlechange={handlechange}
                placeholder="Enter contact name"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>
              </div>
              </div>

            <TextInput 
              mandatory={true}
              label={'Spoc email'}  
              variant="standard"
              name="client_email"
              type="text"
              value={singleData?.client_email}
              handlechange={handlechange}
              placeholder="Enter contact name"
              InputLabelProps={{
                  style: { color: '#fff', }, 
              }}/>


            <TextAreaInput1 
              label={'Company Address'}  
              variant="standard"
              name="address"
              type="text"
              value={singleData?.address}
              handlechange={handlechange}
              InputLabelProps={{
                  style: { color: '#fff', }, 
              }}/>

            </div>
            
            <h6 className='text-[11px] font-[600] mb-1 mt-2' >Client Stage</h6>    
            <Select 
                value={singleData?.stage}
                defaultValue={singleData?.stage}
                bordered={false}
                className='border border-slate-300 w-[100%]'
                options={[{label:'Active',value:'Active'},{label:'InActive',value:'InActive'},{label:'Hold',value:'Hold'}]}
                onChange={(e)=>setsingleData({...singleData,stage:[{label:'Active',value:'Active'},{label:'InActive',value:'InActive'},{label:'Hold',value:'Hold'}].find((v)=>v.value == e)})}
                />




            <div className='flex mt-2 border-t pt-2 w-full items-end '>
                <div className='mr-2'>
                <ButtonOutlinedAutoWidth btnName="Cancel" onClick={()=>navigator(-1)} />
                </div>
                <ButtonFilledAutoWidth btnName="Save" onClick={submitform} />
            </div>
           </div>  

    </div>
  )
}

export default AtsClientManagmentCE