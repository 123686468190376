import { Box } from '@material-ui/core'
import React,{useState,useEffect} from 'react'
import { TextInput } from '../../../../components/input'
import { useLocation,useNavigate } from 'react-router-dom';
import { ButtonFilled } from '../../../../components/button';
import { CreateLeadNoteService, UpdateLeadNoteService } from '../../../../services/LeadServices';
import { toast } from 'react-hot-toast';
import Uploader from '../../../../components/Uploader';
import { MobileBackOption } from '../../../../components/appbars/mobile/DashboardMobile';

function LeadNotesCE() {

  const [data,setdata] = useState({note_title:'',summary:"",file:''});
  const [error,seterror] = useState({note_title:'',summary:"",file:''});
  const location = useLocation()
  const {state} = location 
  const navigate = useNavigate()

  console.log("location",location?.pathname?.split('/')[4])

  useEffect(()=>{

  },[])
  
  async function submitform(){
    if(!data.note_title){
      seterror({...error,note_title:'This field is required*'})
    }else if(!data.summary){
      seterror({...error,summary:'This field is required*'})
    }else{
      if(location?.pathname?.split('/')[4] === 'create'){

        let fd = new FormData()
        fd.append('note_title',data.note_title)  
        fd.append('summary',data.summary)  
        fd.append('type_id',state)  
        fd.append('note_type','Lead')  

        if(data.file !== ''){
          fd.append('file',data.file)
        }
        // let send_data = {...data}
        // send_data['type_id'] = state
        // send_data['note_type'] = 'Lead'
        // console.log("send_data kp",send_data)
        const response = await CreateLeadNoteService(fd)
        if(response.status === 201){
          setdata({...data,note_title:'',summary:'',file:''})
          seterror({...error,note_title:'',summary:'',file:''})
          toast.success('Notes Added Successfully')
        } 
        // console.log("response",response)    
      }else{
        const response = await UpdateLeadNoteService()  
        // console.log("response",response)
      }
    }
  }

  function handlechange(e){
    setdata({...data,[e.target.name] : e.target.value})
    seterror({...error,[e.target.name]:''})
  }

  return (
    <Box sx={{marginTop:{sm:'0px',xs:'0px',md:'50px',lg:'50px'},paddingLeft:{md:'30px',lg:'30px',sm:'0px',xs:'0px'}}}>
    <Box sx={{display:{sm:'block',md:'none !important',lg:'none !important'}}} className='sm:block lg:hidden'>
    <MobileBackOption goback={()=>navigate(-1)} />
    </Box> 
      

    <div className='sm:w-full lg:w-72 p-4'>
      

      

        <h6 className='font-bold'>{location?.pathname?.split('/')[4] !== 'create' ? 'Edit' : 'Add'}  Notes</h6>
        <h6 className='text-[10px] bg-sky-50 p-2 font-semibold leading-snug' >Use the below form to create or edit the <b> Notes</b> for your leads so you can remind the process what u hv done.</h6>
     

        <TextInput 
            label={'Title'}  
            variant="standard"
            name="note_title"
            type="text"
            error={error.note_title}
            value={data.note_title}
            handlechange={handlechange}
            placeholder="Enter your note title"
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>

        <TextInput 
            label={'Summary'}  
            variant="standard"
            name="summary"
            type="text"
            error={error.summary}
            value={data.summary}
            handlechange={handlechange}
            placeholder="Enter your summary"
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>

        <h6 className='text-[10px] py-2 font-semibold leading-snug' >Attachments</h6>
        <Uploader image={data.file} setimagefunc={(v)=>setdata({...data,file:v})} removeimageuploadfunc={()=>setdata({...data,file:''})} />

        <Box className='mt-3'>          
        <ButtonFilled btnName={location?.pathname?.split('/')[4] !== 'create' ? "UPDATE NOTE" : "ADD NOTE"}  onClick={submitform} />  
        </Box>


    </div>
    </Box>
  )
}

export default LeadNotesCE