import React, { useEffect } from 'react';
import {useLocation,useNavigate } from 'react-router-dom';
import { MdOutlineLandscape } from "react-icons/md";
import { BiCommentError } from "react-icons/bi";
import { LuInspect } from "react-icons/lu";
import { TbBrandGoogleBigQuery } from "react-icons/tb";
import { PiPlaceholder } from "react-icons/pi";
import { LuTableProperties } from "react-icons/lu";
import { FcInspection } from "react-icons/fc";
import { VscCommentDiscussion } from "react-icons/vsc";
import { LuBookmarkPlus } from "react-icons/lu";

function IlsMenu() {

  const {pathname} = useLocation();
  const path = pathname.split('/')[pathname.split('/').length - 1]

  const navigate = useNavigate();

  const menu = [
    {name:'Enquiry',icon:TbBrandGoogleBigQuery,path:'/ils/enquiry',id:1,color:''},
    {name:'Proposal',icon:LuTableProperties,path:'/ils/proposal',id:2,color:''},
    {name:'Inspection',icon:FcInspection,path:'/ils/inspection',id:3,color:''},
    {name:'Discussion',icon:VscCommentDiscussion,path:'/ils/discussion',id:4,color:''},
    {name:'Negotiation',icon:BiCommentError,path:'/ils/negotiation',id:5,color:''},
    {name:'LOI/Agreement',icon:LuBookmarkPlus,path:'/ils/loi_agreement',id:8,color:''},
    {name:'Closure',icon:LuInspect,path:'/ils/closure',id:6,color:''},
    {name:'Hold',icon:PiPlaceholder,path:'/ils/hold',id:7,color:''},
   
  ]  

  const menu1 = [
    {name:'Land Data',icon:MdOutlineLandscape,path:'/ils/land_data',id:2,color:''},
   
  ]  


  useEffect(()=>{
    if(path === 'ils'){
        navigate('land_data')
      }
  },[])

 
  return (
    <div className='mr-0 min-h-screen overflow-y-scroll no-scrollbar max-h-screen border-r min-w-44  w-44 max-w-44  px-2' >

        <div className='mb-4 mt-5'>
          <h6 className=' ml-2 mb-2 font-[700] text-[12px]'>LandData Option</h6>
          <>
          {menu1.map((m)=>(
              <div key={m.path} className={`flex items-center my-1 hover:bg-[#f4f5f7] px-1.5 py-1 ml-2 rounded-md relative cursor-pointer ${pathname ===  m.path && 'bg-[#f4f5f7]'}`} onClick={()=>navigate(m?.path)}>
                {pathname ===  m.path &&  <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3' ></span>}
                <m.icon color='#000' style={{backgroundColor:m?.color,padding:'2px'}} size={16}  />
                <span className='text-[#000] font-[500] ml-2 text-[12px]'>{m.name}</span>
              </div>    
          ))}
          </>
        
          <h6 className=' ml-2 mb-2 font-[700] text-[12px] mt-2'>Land Requirment Option</h6>
          <>
          {menu.map((m)=>(
              <div key={m.path} className={`flex items-center my-1 hover:bg-[#f4f5f7] px-1.5 py-1 ml-2 rounded-md relative cursor-pointer ${pathname ===  m.path && 'bg-[#f4f5f7]'}`} onClick={()=>navigate(m?.path)}>
                {pathname ===  m.path &&  <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3' ></span>}
                <m.icon color='#000' style={{backgroundColor:m?.color,padding:'2px'}} size={16}  />
                <span className='text-[#000] font-[500] ml-2 text-[12px]'>{m.name}</span>
              </div>    
          ))}
          </>
          
         
        </div>
    </div>
  )
}

export default IlsMenu