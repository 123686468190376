import React,{useState,useEffect} from 'react'
import { GetCurrentInsentivePayments } from '../../services/InvoiceServices'

import { Tooltip } from '@mui/material';
import { Select } from 'antd'
import moment from 'moment';
import {RiBillLine} from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import DashboardMenu from '../dashboard/DashboardMenu';
import {BsArrowRepeat} from 'react-icons/bs';
import {FiChevronRight,FiChevronLeft} from 'react-icons/fi';
import { IconButton } from '@mui/material'
import { GetAllInsentiveList } from '../../services/AdminServicesfile/InsentiveServices';


function Payment() {
 
  const [data,setdata] = useState([])
  const [pagination,setpagination] = useState({})
  const [current_tab,setcurrent_tab] = useState('1')
  const [page,setpage] = useState(1)
  const [type,settype] = useState(1)
  const navigator = useNavigate()

  const [insentive,setinsentive] = useState([])
  const [selectedInsentive,setselectedInsentive] = useState('')


  const tabs_list = [{'value':'Not Shared','key':'1'},{'value':'Amount Shared','key':'2'},{'value':'Payment Deleted','key':'2'}]

  
  useEffect(()=>{
    getInsentive()
  },[])

  async function getInsentive(){
    const response = await GetAllInsentiveList()
    let arr = []
    let d = response?.data?.datas
    d?.forEach((d1)=>{
      arr.push({label:d1?.year,value:d1?.id})
    })
    setinsentive(arr)
  }

  useEffect(()=>{
    getdata(1)
  },[type,selectedInsentive])

  useEffect(()=>{
    getdata()
  },[page])
  
  async function setactivetab(v) {
    setcurrent_tab(v)
    settype(v)
  }
  
  async function getdata(v){
    const response = await GetCurrentInsentivePayments(type,v?v:page,selectedInsentive)
    setdata(response?.data?.data)
    setpagination(response?.data?.pagination)
  }
  
  let rupee = new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
  });

  
  
    function resetfunc(){
      setselectedInsentive('')
      setpage(1)

    }
  

    console.log("data",data)
    
  return (
    <div>
       <div className='flex '>
         <DashboardMenu />
        <div className='px-4 w-[86%]'>
        <div className='pt-5 flex items-center  border-b pb-2 justify-between'>
            <h6 className='font-black text-[14px]'>Invoice Payment Recieved</h6>
            <div className='w-[100px] rounded'>
            <Select value={selectedInsentive} options={insentive} onChange={(e)=>setselectedInsentive(e)} size='small' placeholder="Quarter" className='border rounded-[10px] border-slate-300 w-[100%] py-[4px]' bordered={false} />
            </div>
        </div>
        <div className='flex border-b'>
          {tabs_list?.map((t,i)=>(
            <h6 key={i} onClick={()=>setactivetab((i+1).toString())} className={`px-2  text-[11px] py-1.5 font-[600] cursor-pointer  ${current_tab === (i+1).toString() ? 'bg-slate-600 text-white' : 'border-white' }`}>{t?.value}</h6>
          ))}
        </div>

        <div className='mt-2'>
        <div className='-mt-2 mx-2 flex items-center justify-between'>
        <h6 className='text-[12px] font-[700] mt-2'>Total {(current_tab === '1') && 'Pending' }  {(current_tab === '2') && 'Completed' } {(current_tab === '3') && 'Deleted' } ({pagination?.total})</h6>
        <div className=' w-max'>
            <div className='flex items-center text-[12px]'>

              
              <h6 className='mr-2 font-[600]'>{page == 1 ? data?.length > 0 ? 1 : 0 : (page - 1) * pagination?.limit } - {pagination?.limit} of {pagination?.total} </h6>
              <IconButton onClick={resetfunc} ><BsArrowRepeat size={16} /></IconButton>

              <div>
              <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}} ><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`} size={16} /></IconButton>
              <IconButton onClick={()=>{ page < pagination?.totalPages  ? setpage(page+1) : console.log('')}}><FiChevronRight className={`${pagination?.totalPages === page ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>
              </div>
              </div>
        </div>
        </div>
        </div> 

        <div className='h-[85vh] min-h-[85vh] max-h-[85vh] overflow-y-scroll'>
        {current_tab === '1' &&
        <div className='grid grid-cols-4'>
                    {data?.map((d)=>(
                    <div key={d?._id} className='border p-2 m-1 relative'>
                        <Tooltip title='View'><span className='absolute right-1 cursor-pointer' onClick={()=>navigator('detail',{state:d?._id})}><RiBillLine  /></span></Tooltip>
                        <h6 className='text-[11px] font-[400]'>Amt Recieved Excl GST :<span className='font-[900]'> {rupee.format(d?.amount_without_gst)?.split('.')[0]}</span> </h6>
                        <h6 className='text-[11px] font-[400]'>TDS Deduction :<span className='font-[900]'> {d?.tds_deduction !== undefined ? rupee.format(d?.tds_deduction) : rupee.format(0)}</span> </h6>
                        <div className='bg-gray-100 p-2 my-1 '>
                        <h6 className='text-[11px] font-[400]'>Client Info : <span className='font-[900]'>{d?.client?.contact_name}</span>  </h6>
                        <h6 className='text-[11px] font-[400]'>Company Name : {d?.client?.company_name}</h6>
                        {/* {d?.client?.address !== '' && <h6 className='text-[11px] font-[400]'>Address : {d?.client?.address}</h6>} */}
                        </div>

                        <h6 className='text-[11px] font-[400]'>Invoice Owner : {d?.invoice?.employee?.name}</h6>
                        <h6 className='text-[10px] font-[400]'>Payment Recieved on : <span className='font-[900] text-[9px]'> {moment(d?.recieved_date).format('LL')} </span></h6>
                    </div>
                    ))}
        </div>}

        {current_tab === '2' &&
        <div className='grid grid-cols-4'>
                    {data?.map((d)=>(
                    <div key={d?._id} className='border p-2 m-1 relative'>
                        <Tooltip title='View'><span className='absolute right-1 cursor-pointer' onClick={()=>navigator('detail',{state:d?._id})}><RiBillLine  /></span></Tooltip>
                        <h6 className='text-[11px] font-[400]'>Amt Recieved Excl GST :<span className='font-[900]'> {rupee.format(d?.amount_without_gst)}</span> </h6>
                        <h6 className='text-[11px] font-[400]'>TDS Deduction :<span className='font-[900]'> {d?.tds_deduction !== undefined ? rupee.format(d?.tds_deduction) : rupee.format(0)}</span> </h6>
                        <div className='bg-gray-100 p-2 my-1 '>
                        <h6 className='text-[11px] font-[400]'>Client Info : <span className='font-[900]'>{d?.client?.contact_name}</span>  </h6>
                        <h6 className='text-[11px] font-[400]'>Company Name : {d?.client?.company_name}</h6>
                        </div>

                        

                        <h6 className='text-[11px] font-[400]'>Invoice Owner : {d?.invoice?.employee?.name}</h6>
                        <h6 className='text-[10px] font-[400]'>Payment Recieved on : <span className='font-[900] text-[9px]'> {moment(d?.recieved_date).format('LL')} </span></h6>
                    </div>
                    ))}
        </div>}

        {current_tab === '3' &&
        <div className='grid grid-cols-4'>
                    {data?.map((d)=>(
                    <div key={d?._id} className='border p-2 m-1 relative'>
                        <Tooltip title='View'><span className='absolute right-1 cursor-pointer' onClick={()=>navigator('deleted',{state:d?._id})}><RiBillLine  /></span></Tooltip>
                        <h6 className='text-[11px] font-[400]'>Amt Recieved Excl GST :<span className='font-[900]'> {rupee.format(d?.amount_without_gst)}</span> </h6>
                        <h6 className='text-[11px] font-[400]'>TDS Deduction :<span className='font-[900]'> {d?.tds_deduction !== undefined ? rupee.format(d?.tds_deduction) : rupee.format(0)}</span> </h6>
                        <div className='bg-gray-100 p-2 my-1 '>
                        <h6 className='text-[11px] font-[400]'>Client Info : <span className='font-[900]'>{d?.client?.contact_name}</span>  </h6>
                        <h6 className='text-[11px] font-[400]'>Company Name : {d?.client?.company_name}</h6>
                        </div>
                        <div className='bg-red-100 p-2 my-1 '>
                        <h6 className='text-[11px] font-[400]'>Delete Reason : <span className='font-[700]'>{d?.deleted_reason}</span>  </h6>
                        </div>

                        <h6 className='text-[11px] font-[400]'>Invoice Owner : {d?.invoice?.employee?.name}</h6>
                        <h6 className='text-[10px] font-[400]'>Payment Recieved on : <span className='font-[900] text-[9px]'> {moment(d?.recieved_date).format('LL')} </span></h6>
                    </div>
                    ))}
        </div>}
        </div>
        </div> 
       </div>      
    </div>
  )
}

export default Payment