import React, { useEffect, useState } from 'react'
import ProfileMenu from './ProfileMenu'
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../components/button'
import {BsArrowRepeat} from 'react-icons/bs';
import {FiChevronRight,FiChevronLeft} from 'react-icons/fi';
import { IconButton } from '@material-ui/core'
import { DatePicker, Modal } from 'antd';
import { useNavigate } from 'react-router-dom';
import { DeleteReimbusmentService, GetReimbusmentService } from '../../services/ReimbusmentServices';
import moment from 'moment';
import Priceconstants from '../../constants/imageConstants';
import { AiOutlineEdit,AiOutlineDelete } from 'react-icons/ai';
import toast from 'react-hot-toast';


function Reimbusment() {

  const [data,setdata] = useState({})
  const [selectedData,setselectedData] = useState({})
  const [modal,setmodal] = useState(false)
  const [search,setsearch] = useState({from_date:'',from_date1:'',to_date:'',to_date1:''})
  const [page,setpage] = useState(1)
  const [step,setstep] = useState(1)
  const navigate = useNavigate()


  useEffect(()=>{
    getData()
  },[step,page])

  async function getData(){
    const response = await GetReimbusmentService(page,step,search?.from_date1,search?.to_date1)
    setdata(response?.data?.datas)
  }

  async function resetfunc(){
    setpage(1)
    setsearch({from_date:'',from_date1:'',to_date:'',to_date1:''})
    const response = await GetReimbusmentService(1,step,'','')
    setdata(response?.data?.datas)
  }

  async function applyfilterfunction(){
    setpage(1)
    const response = await GetReimbusmentService(1,step,search?.from_date1,search?.to_date1)
    setdata(response?.data?.datas)
  }

  async function deleteDatafunc(){
    const response =  await DeleteReimbusmentService(selectedData?._id)
    if(response.status === 200){
      toast.success("Deleted Successfully")
      setmodal(false)
      getData()
    }
  }


  return (
    <div className='px-2 flex mx-0 box-border  max-h-screen overflow-y-scroll overflow-x-hidden'>
        <Modal open={modal} className='absolute top-0 left-[41%]' width={320} closable={false} footer={false}>
          <div> 
            <h6 className='text-[15px] font-[700]'>Delete Data</h6>
            <h6 className='text-[11px] leading-tight bg-gray-100 p-2' >Before Deleting the data be sure that it is required or not for further purpose.</h6>

            <h6 className='text-[11px] mt-2'>Amount : <span className='font-[700]'>{Priceconstants(selectedData?.amount)}</span></h6>
            <h6 className='text-[11px]'>Requested Date : <span className='font-[700]'>{moment(selectedData?.createdAt)?.format('LLL')}</span></h6>
            
            <div className='flex items-center border-t pt-2'>
                <ButtonOutlinedAutoWidth btnName="Cancel" onClick={()=>setmodal(false)} />
                <h6 className='w-[10px]'></h6>
                <ButtonFilledAutoWidth btnName="Sure" onClick={deleteDatafunc} />
             </div>

          </div>
        </Modal>
        <div className='block sm:flex'>
        <ProfileMenu />
        </div>
        <div className='sm:w-[76%] md:w-full ml-4  pt-2'>
            <div className='flex flex-wrap items-center justify-between border-b pb-2'>
            <h6 className='font-[700] text-[14px] '>Total Reimbusment ({data?.pagination?.total})</h6>
            <div className='flex items-center'>
              <div className='flex items-center text-[12px] mr-2'>
             

                <h6 className='mr-2 font-[600]'>{page == 1 ? data?.datas?.length > 0 ? 1 : 0 : (page - 1) * data?.pagination?.limit } - {data?.pagination?.limit} of {data?.pagination?.total} </h6>
                <IconButton onClick={resetfunc}><BsArrowRepeat size={16} /></IconButton>

                <div>
                <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>
                <IconButton onClick={()=>{ page < data?.pagination?.totalPages  ? setpage(page+1) : console.log('')}}><FiChevronRight className={`${(data?.pagination?.totalPages === page || data?.datas?.length === 0)  ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>

                </div>
              </div>


              <DatePicker size='small' style={{fontSize:'11px'}} ampm={false} placeholder='From Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.from_date} onChange={(v,v1) => {console.log('v na',v,new Date(v).toLocaleDateString());setsearch({...search,from_date:v,from_date1:v1})}} /> 

              <DatePicker size='small' style={{fontSize:'11px'}} ampm={false} placeholder='To Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.to_date} onChange={(v,v1) => {console.log('v na',new Date(v).toLocaleDateString());setsearch({...search,to_date:v,to_date1:v1})}} /> 

            
                

          <ButtonOutlinedAutoWidth btnName="Add Filter" onClick={()=>applyfilterfunction(1)} /> 
         
          <div className='ml-2'>
          <ButtonFilledAutoWidth btnName="Add Data" onClick={()=>navigate('create')}/> 
          </div>
            </div>
            </div>

            

            <div className='flex  items-center border-b'>
            <h6 onClick={()=>{setstep(1);setpage(1)}} className={`font-[600] ${step === 1 && 'bg-slate-600 text-white'} text-center cursor-pointer py-1 w-[110px] text-[11px]`}>VP - Approval</h6>
            <h6 onClick={()=>{setstep(2);setpage(1)}} className={`font-[600] ${step === 2 && 'bg-slate-600 text-white'} text-center cursor-pointer py-1 w-[120px] text-[11px]`}>Finance - Approval</h6>
            <h6 onClick={()=>{setstep(3);setpage(1)}} className={`font-[600] ${step === 3 && 'bg-slate-600 text-white'} text-center cursor-pointer py-1 w-[80px] text-[11px]`}>Completed</h6>
            <h6 onClick={()=>{setstep(4);setpage(1)}} className={`font-[600] ${step === 4 && 'bg-slate-600 text-white'} text-center cursor-pointer py-1 w-[80px] text-[11px]`}>Rejected</h6>
          </div>
            <div className='border-t border-l border-r mt-4'>
                <div className='flex flex-wrap md:flex-nowrap border-b'>
                  <h6 className='text-[12px] py-1 px-2 border-r w-[70px] font-[600]'>Sl No</h6>
                  <h6 className='text-[12px] py-1 px-2 border-r w-[10%] font-[600]'>Request Date</h6>
                  <h6 className='text-[12px] py-1 px-2 border-r w-[10%] font-[600]'>Amount</h6>
                  <h6 className='text-[12px] py-1 px-2 border-r w-[17%] font-[600]'>Approvals</h6>
                  <h6 className='text-[12px] py-1 px-2 border-r w-[20%] font-[600]'>Summary</h6>
                  <h6 className='text-[12px] py-1 px-2 border-r w-[14%] font-[600]'>Particular Count</h6>
                  <h6 className='text-[12px] py-1 px-2 border-r w-[14%] font-[600]'>Payment Mode</h6>
                  <h6 className='text-[12px] py-1 px-2 border-r w-[15%] font-[600]'>Finance Approve Date</h6>
                  <h6 className='text-[12px] py-1 px-2 w-[10%] font-[600]'>Action</h6>
                </div>

               {data?.datas?.map((d,i)=>(
                <div className='flex flex-wrap md:flex-nowrap border-b'>
                  <h6 className='text-[11px] py-1 px-2 border-r w-[70px] font-[500]'>{(page > 1 ? i+1+ (25 * (page - 1)) : i+1 )}</h6>
                  <h6 className='text-[10px] py-1 px-2 border-r w-[10%] font-[700]'>{moment(d?.createdAt)?.format('LL')}</h6>
                  <h6 className='text-[11px] py-1 px-2 border-r w-[10%] font-[700]'>{Priceconstants(d?.amount)}</h6>
                  <h6 className='text-[11px] py-1 flex flex-wrap px-2 border-r w-[17%] font-[500]'>{d?.reimbursmentapprovals?.map((d1)=>(
                    <p className={`px-[3px] w-max py-[4px] mb-[2px] ${d1?.approvedStatus === 'Pending' && 'bg-gray-100'} ${d1?.approvedStatus === 'Approved' && 'bg-green-100'} ${d1?.approvedStatus === 'Rejected' && 'bg-red-100'} text-[8px] mr-2  font-[800]`}>{d1?.user?.name}</p>
                  ))}</h6>
                  <h6 className='text-[11px] py-1 px-2 border-r w-[20%] font-[500]'>{d?.summary}</h6>
                  <h6 className='text-[11px] py-1 px-2 border-r w-[14%] font-[700]'>{d?.reimbursmentitems?.length}</h6>
                  <h6 className='text-[11px] py-1 px-2 border-r w-[14%] font-[500]'>{d?.completed && <span className='bg-blue-400 font-[700] text-white px-[10px] py-[2px]'>{d?.payment}</span>}</h6>
                  <h6 className='text-[11px] py-1 px-2 border-r w-[15%] font-[500]'>{d?.completed && moment(d?.updatedAt)?.format('LLL')}</h6>
                  <h6 className='text-[11px] py-1 px-2 w-[10%] font-[500] flex'>
                    <AiOutlineEdit size={13} className='mr-2' onClick={()=>navigate('edit',{state:d})} /> 
                    {/* <AiOutlineDelete size={13} onClick={()=>{setselectedData(d);setmodal(true)}} />  */}
                  </h6>
                </div>
                ))}

            </div>
        </div>    
    </div>
  )
}

export default Reimbusment