import React, { useEffect, useState } from 'react'
import FcaresMenu from '../FcaresMenu'
import { useLocation } from 'react-router-dom'

import { JsonToExcel } from "react-json-to-excel";

function FCaresReportDetail() {


  const {state} = useLocation()

  const [datas,setdatas] = useState([])


  useEffect(()=>{
    let arr = []
    state?.contactsInfo?.map((c,i)=>{
      let string = ''
   
      c?.phoneNumbers?.forEach((v1)=>{
        string += `${JSON.stringify(v1?.label).toUpperCase()?.slice(1,-1)} - ${v1?.number} .`
      })

      let obj ={
         'Sl No' : i+1,
         'Display Name' : c?.displayName,
         'Contact Info' :string
      }
      arr.push(obj)
    })

    setdatas(arr)

  },[state])

  function returnphonenumber(v){
    let string = ''
    v?.forEach((v1)=>{
      string += `${JSON.stringify(v1?.label).toUpperCase()?.slice(1,-1)} - ${v1?.number} .`
    })

    return string

  }

  return (
    <div className='w-[100%] h-[100%] overflow-hidden'>
        <div className='flex'>
            <div className='min-w-44'>
            <FcaresMenu />
            </div>

          

            <div className='w-[88%] px-5 pt-5'>
          

              <div className='flex items-center justify-between pb-2'>
              <h6 className='font-[700] text-[14px] '>Total Calls List ({state?.contactsInfo?.length})</h6>
            
          </div>

          <div className='grid border mb-2 grid-cols-5'>
            <div  className='border-r p-2'>
               <h6 className='text-[12px]'>Device Info</h6>
               <h6 className='text-[11px] font-[900]'>{state?.device}</h6>
            </div>
            <div className='border-r p-2'>
               <h6 className='text-[12px]'>Lats Info</h6>
               <h6 className='text-[11px] font-[900]'>{state?.lats}</h6>
            </div>
            <div className='border-r p-2'>
               <h6 className='text-[12px]'>Long Info</h6>
               <h6 className='text-[11px] font-[900]'>{state?.long}</h6>
            </div>
            <div className='border-r p-2'>
               <h6 className='text-[12px]'>Address Info</h6>
               <h6 className='text-[11px] font-[900]'>{state?.address}</h6>
            </div>

            <div className='p-2'>
            <JsonToExcel
              title="Download as Excel"
              data={datas}
              fileName="bagvatha"
              btnClassName="custom-classname"
            />
            </div>
          
          </div>  

          {state?.contactsInfo?.length > 0 &&
              <div className='max-h-[75vh] overflow-x-hidden mt-2 mb-10 border-t   border-l border-r overflow-y-scroll'>
                <div className='sticky top-0 z-50 flex  bg-white py-[-5px] border-b border-slate-200 relative'>
                    <h6 className='sticky top-0 z-50  text-[12px] w-[10%] px-2 py-1 font-[600] text-slate-600 border-r'>Sl No</h6>
                    <h6 className='sticky top-0 z-50  text-[12px] w-[20%] px-2 py-1 font-[600] text-slate-600 border-r'>Display Name</h6>
                    <h6 className='sticky top-0 z-50  text-[12px] w-[70%] px-2 py-1 font-[600] text-slate-600 border-r'>Contact Info</h6>
                </div>
                
                {state?.contactsInfo?.map((d,i)=>(
                <div className=' z-50 flex  bg-white py-[-5px] border-b border-slate-200 relative'>
                    <h6 className=' z-50  text-[11px] w-[10%] px-2 py-1 font-[500] border-r'>{i+1}</h6>
                    <h6 className=' z-50  text-[11px] w-[20%] px-2 py-1 font-[500] border-r'>{d?.displayName}</h6>
                    <h6 className=' z-50  text-[11px] w-[70%] px-2 py-1 font-[500] break-all border-r'>{returnphonenumber(d?.phoneNumbers)}</h6>
                </div>))}

                </div>} 

              

            </div>
        </div>
    </div>
  )
}

export default FCaresReportDetail