import {  deleteRequest, get, post, postfd, publicPost, put } from "../helpers/apihelpers";

export const CreateOnboardService=async(data)=>{
    try {
        const result = await publicPost(`api/user/create_account_by_hr`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateOnboardService=async(data,id)=>{
    try {
        const result = await put(`api/user/update_appplication/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetOnboardDetailService=async(id)=>{
    try {
        const result = await get(`api/user/application_in_review/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UploadUserImageService=async(data)=>{
    try {
        const result = await postfd(`api/user/upload_file_s3_bucket`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetOnboardUserInReviewDetailService=async(id)=>{
    try {
        const result = await get(`api/user/get_in_review/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetOnboardListDetailService=async(id)=>{
    try {
        const result = await get(`api/user/get/${id}`,);
        return result;
    } catch (err) {
        return err.response;
    }
}


export const GetOnboardListService=async(page,step)=>{
    try {
        const result = await get(`api/user/application_in_review/${page}?type=${step}`,);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateRoleService = async (data) => {
    console.log("data",data)
    try {
        const result = await put(`api/user/assign-user-roles`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const SendOnboardLink = async (data) =>{
    try {
        const result = await post(`api/user/share_onboard_link`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteSelfRegistrationHr = async (id) =>{
    try {
        const result = await deleteRequest(`api/user/delete_selfregister_byhr/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetExitUserFormDetails = async (id) => {
    try {
        const result = await get(`api/exit_user/get/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const CreateExitUserFormDetails = async (data) => {
    try {
        const result = await post(`api/exit_user/create`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateExitUserFormDetails = async (id,data) => {
    
    try {
        const result = await put(`api/exit_user/update/${id}`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteExitUserFormDetails = async (id) => {
    try {
        const result = await deleteRequest(`api/exit_user/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetListBasedOnRoles = async (step,page,from_date,to_date,search) => {
    try {
        const result = await get(`api/exit_user/get_exit_list_based_on_roles?stage=${step == 1 ? 'false' : 'true'}&from_date=${from_date}&to_date=${to_date}&search=${search}&page=${page}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetEmployeeAppointmentApprovalPending = async (step,page,from_date,to_date,text) => {
    try {
        const result = await get(`api/user/get_appointment_letter/${step}?page=${page}&from_date=${from_date}&to_date=${to_date}&search_text=${text}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetUserLetterList = async (id) => {
    try {
        const result = await get(`api/user/user_letter/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GenearteUserLetterListService = async (id,data) => {
    try {
        const result = await post(`api/user/user_letter_generate/${id}`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const ShareUserLetterGeneratedService = async (data) => {
    try {
        const result = await post(`api/user/user_letter_generated_share`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}


export const UpdateUserApprovalByAdmin = async (id,data) =>{
    try {
        const result = await put(`api/user/update_appointment_letter_admin/${id}`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}


export const UpdateUserApproveByEmployee = async(id,data)=>{
     try {
        const result = await put(`api/user/update_user_approve_by_employee/${id}`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}     