import React, { useEffect } from 'react';
import {useLocation,useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { BiCategoryAlt } from "react-icons/bi";
import { MdOutlineNoteAlt } from "react-icons/md";
import { RiBillLine } from "react-icons/ri";
import { LiaVideoSolid } from "react-icons/lia";
import { PiSuitcaseSimple } from "react-icons/pi";
import { MdOutlineSupervisedUserCircle } from "react-icons/md";

function WebsiteMenu() {

  const {pathname} = useLocation();
  const path = pathname.split('/')[pathname.split('/').length - 1]
  const roles = useSelector(state=>state.Auth.roles)

 

  const navigate = useNavigate();

  const menu = [
    {name:'HR Stage',icon:BiCategoryAlt,path:'/website/hr_website_stage',id:1,color:''},
    {name:'HR Blogs',icon:MdOutlineNoteAlt,path:'/website/hr_blogs',id:2,color:''},
    {name:'HR Artical',icon:RiBillLine,path:'/website/hr_artical',id:3,color:''},
  ]  

  const menu1 = [
    {name:'Transaction Stage',icon:BiCategoryAlt,path:'/website/transaction_website_stage',id:1,color:''},
    {name:'Transaction Blogs',icon:MdOutlineNoteAlt,path:'/website/transaction_blogs',id:2,color:''},
  ]  

  const menu2 = [
    {name:'Project Stage',icon:BiCategoryAlt,path:'/website/project_website_stage',id:1,color:''},
    {name:'Project Blogs',icon:MdOutlineNoteAlt,path:'/website/project_blogs',id:2,color:''},
    {name:'Project Videos',icon:LiaVideoSolid,path:'/website/project_videos',id:3,color:''},
    {name:'Project Job Open',icon:PiSuitcaseSimple,path:'/website/project_job_opening',id:4,color:''},
    {name:'Job Applicant',icon:MdOutlineSupervisedUserCircle,path:'/website/project_job_applicant',id:5,color:''},
  ]  

  const menu3 = [
    {name:'FMS Stage',icon:BiCategoryAlt,path:'/website/fms_website_stage',id:1,color:''},
    {name:'FMS Blogs',icon:MdOutlineNoteAlt,path:'/website/fms_blogs',id:2,color:''},
  ]  

  const menu4 = [
    {name:'CORP Stage',icon:BiCategoryAlt,path:'/website/corp_website_stage',id:1,color:''},
    {name:'CORP Blogs',icon:MdOutlineNoteAlt,path:'/website/corp_blogs',id:2,color:''},
  ]  


  useEffect(()=>{
    if(path === 'website'){
      if(['admin','content_writer']?.filter((f)=>roles?.includes(f))?.length > 0){
        navigate('hr_website_stage')
      }else if(['hr_content_writer']?.filter((f)=>roles?.includes(f))?.length > 0){
        navigate('hr_website_stage')
      }else if(['project_content_writer']?.filter((f)=>roles?.includes(f))?.length > 0){
        navigate('project_website_stage')
      }else if(['fms_content_writer']?.filter((f)=>roles?.includes(f))?.length > 0){
        navigate('fms_website_stage')
      }else if(['transaction_content_writer']?.filter((f)=>roles?.includes(f))?.length > 0){
        navigate('transaction_website_stage')
      }
    }

  
  },[path])

 
  return (
    <div className='mr-0 min-h-screen max-h-sceen border-r min-w-44  w-44 max-w-44 overflow-y-scroll pb-10 px-2' >

        {/* {path !== 'daily_tasks'  && */}

        {/* ,'','','' */}
        {['admin','content_writer','hr_content_writer']?.filter((f)=>roles?.includes(f))?.length > 0 &&
        <div className='mb-4 pt-5'>
            <h6 className=' ml-2 mb-2 font-[700] text-[12px]'>HR Option</h6>
            {menu.map((m)=>(
                <div key={m.path} className={`flex items-center my-1 hover:bg-[#f4f5f7] px-1.5 py-1 ml-2 rounded-md relative cursor-pointer ${pathname ===  m.path && 'bg-[#f4f5f7]'}`} onClick={()=>navigate(m?.path)}>
                  {pathname ===  m.path &&  <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3' ></span>}
                  <m.icon color='#000' style={{backgroundColor:m?.color,padding:'2px'}} size={16}  />
                  <span className='text-[#000] font-[500] ml-2 text-[12px]'>{m.name}</span>
                </div>    
            ))}
         </div>}

         {['admin','content_writer','fms_content_writer']?.filter((f)=>roles?.includes(f))?.length > 0 &&
         <div className='mb-4 pt-2 border-t'>
            <h6 className=' ml-2 mb-2 font-[700] text-[12px]'>FMS Option</h6>
            {menu3.map((m)=>(
                <div key={m.path} className={`flex items-center my-1 hover:bg-[#f4f5f7] px-1.5 py-1 ml-2 rounded-md relative cursor-pointer ${pathname ===  m.path && 'bg-[#f4f5f7]'}`} onClick={()=>navigate(m?.path)}>
                  {pathname ===  m.path &&  <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3' ></span>}
                  <m.icon color='#000' style={{backgroundColor:m?.color,padding:'2px'}} size={16}  />
                  <span className='text-[#000] font-[500] ml-2 text-[12px]'>{m.name}</span>
                </div>    
            ))}
         </div>}

         {['admin','content_writer','project_content_writer']?.filter((f)=>roles?.includes(f))?.length > 0 &&
         <div className='mb-4 pt-2 border-t'>
          <h6 className=' ml-2 mb-2 font-[700] text-[12px]'>Project Option</h6>
          {menu2.map((m)=>(
              <div key={m.path} className={`flex items-center my-1 hover:bg-[#f4f5f7] px-1.5 py-1 ml-2 rounded-md relative cursor-pointer ${pathname ===  m.path && 'bg-[#f4f5f7]'}`} onClick={()=>navigate(m?.path)}>
                {pathname ===  m.path &&  <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3' ></span>}
                <m.icon color='#000' style={{backgroundColor:m?.color,padding:'2px'}} size={16}  />
                <span className='text-[#000] font-[500] ml-2 text-[12px]'>{m.name}</span>
              </div>    
          ))}
         </div>}

         {['admin','content_writer','transaction_content_writer']?.filter((f)=>roles?.includes(f))?.length > 0 &&
         <div className='mb-4 pt-2 border-t'>
          <h6 className=' ml-2 mb-2 font-[700] text-[12px]'>Transaction Option</h6>
          {menu1.map((m)=>(
              <div key={m.path} className={`flex items-center my-1 hover:bg-[#f4f5f7] px-1.5 py-1 ml-2 rounded-md relative cursor-pointer ${pathname ===  m.path && 'bg-[#f4f5f7]'}`} onClick={()=>navigate(m?.path)}>
                {pathname ===  m.path &&  <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3' ></span>}
                <m.icon color='#000' style={{backgroundColor:m?.color,padding:'2px'}} size={16}  />
                <span className='text-[#000] font-[500] ml-2 text-[12px]'>{m.name}</span>
              </div>    
          ))}
         </div>}

         {['admin','content_writer','corp_content_writer']?.filter((f)=>roles?.includes(f))?.length > 0 &&
         <div className='mb-4 pt-2 border-t'>
          <h6 className=' ml-2 mb-2 font-[700] text-[12px]'>Fidelitus Option</h6>
          {menu4.map((m)=>(
              <div key={m.path} className={`flex items-center my-1 hover:bg-[#f4f5f7] px-1.5 py-1 ml-2 rounded-md relative cursor-pointer ${pathname ===  m.path && 'bg-[#f4f5f7]'}`} onClick={()=>navigate(m?.path)}>
                {pathname ===  m.path &&  <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3' ></span>}
                <m.icon color='#000' style={{backgroundColor:m?.color,padding:'2px'}} size={16}  />
                <span className='text-[#000] font-[500] ml-2 text-[12px]'>{m.name}</span>
              </div>    
          ))}
         </div>}

    </div>
  )
}

export default WebsiteMenu