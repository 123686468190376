import React, { useState } from 'react'
import { TextAreaInput1, TextInput } from '../../components/input'
import { ButtonFilled, ButtonOutlined } from '../../components/button'
import { useNavigate } from 'react-router-dom'
import { DatePicker, Select } from 'antd'

function TaskProjectDetails() {
  const [data,setdata] = useState({name:'',important:''})
  const [error,seterror] = useState({name:'',important:''})

  const navigate = useNavigate()

  function handlechange(e){
    setdata({...data,[e.target.name]:e.target.value})
    seterror({...error,[e.target.name]:''})
  }

  function submitform(){

  }

  return (
         <div className=' min-w-[90%] mt-5 pl-5 min-h-screen max-h-screen h-screen overflow-y-screen overflow-x-hidden' >
            <div>
              <h6 className='text-[16px] font-[800]'>Project Detail</h6>
              <h6 className='text-[13px]'>Use the below form to update the project details for your team reference.</h6>

            
            <div className='flex w-[46%]'>
             <div className='w-64'>

              <TextInput
                label={'Name *'}  
                variant="standard"
                name="name"
                type="text"
                value={data.name}
                error={error.name}
                handlechange={handlechange}
                placeholder="Try a team name, project goal, milestone..."
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}
              />

              <TextInput
                label={'Key *'}  
                variant="standard"
                name="name"
                type="text"
                value={data.key}
                error={error.key}
                handlechange={handlechange}
                placeholder="Try a team name, project goal, milestone..."
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}
              />

              <TextAreaInput1 
                label={'Description *'}  
                variant="standard"
                name="name"
                type="text"
                value={data.description}
                error={error.description}
                handlechange={handlechange}
                placeholder=""
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}
              />
              </div>

              <div className='w-64 ml-5 mt-1'>
              <h6 className='text-[11px] font-[600] mb-1 mt-1'>Priority * </h6>
              <Select
                defaultValue={data?.important}
                value={data?.important}
                bordered={false}
                onChange={(v)=>handlechange(v,'department')}
                className='w-full border border-gray-300  h-[33px] outline-0 focus:outline-0 focus:ring-0 focus:border-none  focus:border-transparent focus:ring-offset-0 focus:shadow-none'
                options={[{label:'High',value:'High'},{label:'Medium',value:'Medium'},{label:'Low',value:'Low'}]}
              />

              <h6 className='text-[11px] font-[600] mb-1 mt-[6px]'>Project Deadline </h6>

              <div className='border  border-gray-300 h-[32px]  '>
              <DatePicker bordered={false} ampm={false} placeholder='' className='w-full border  h-[33px] outline-0 focus:outline-0 focus:ring-0 focus:border-none  focus:border-transparent focus:ring-offset-0 focus:shadow-none'    /> 
              </div>
              <TextInput
                label={'Key *'}  
                variant="standard"
                name="name"
                type="text"
                value={data.key}
                error={error.key}
                handlechange={handlechange}
                placeholder="Try a team name, project goal, milestone..."
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}
              />

              <TextAreaInput1 
                label={'Description *'}  
                variant="standard"
                name="name"
                type="text"
                value={data.description}
                error={error.description}
                handlechange={handlechange}
                placeholder=""
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}
              />
              </div>
            </div>

            <div className='flex items-center w-[46%] mt-5 mb-10  border-t pt-5'>
                <div className='mr-2'>
                <ButtonOutlined btnName={'Back'} onClick={()=>navigate(-1)} />
                </div>
                <div>
                <ButtonFilled btnName={'Save'} onClick={()=>submitform(null)} />
                </div>
            </div>

            </div>
         </div>   
  )
}

export default TaskProjectDetails