import React,{useState,useEffect} from 'react'
import { Tooltip } from '@mui/material';
import {  ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../../components/button';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { DeleteStageService, GetStageService } from '../../../services/StageServices';
import { Modal } from 'antd';
import SettingsMenu from '../../staticscreens/SettingsMenu';
import {AiOutlineEdit,AiOutlineDelete} from 'react-icons/ai'
import moment from 'moment';

function StageList() {

   
    const [data,setdata] = useState([])
    const [selecteddata,setselecteddata] = useState({})
    const [modal, setModal] = useState(false);
    const navigate = useNavigate()
    const location = useLocation()
    const {pathname} = useLocation()

    // console.log("pathname",pathname)


    // console.log('matches',matches)
    // console.log('location',location?.pathname?.split('/')[2])
  
    useEffect(()=>{
        getStages()
    },[])

   
    

  
  async function getStages(){
   const response = await GetStageService(location?.pathname?.split('/')[location?.pathname?.split('/')?.length - 1])
   setdata(response.data.data.datas)
  }  


  async function deleteStage(){
    const response = await DeleteStageService(selecteddata.id,{type:location?.pathname?.split('/')[location?.pathname?.split('/')?.length - 1]})
    if(response.status === 200){
        setModal(false)
        toast.success(`${location?.pathname?.split('/')[location?.pathname?.split('/')?.length - 1].replace('_',' ')} Deleted Succesfully`)
        getStages()
    }
  }

  return (
    <div className='h-screen max-h-screen box-border overflow-hidden'>
    
    <Modal
       keepMounted
       open={modal}
       onClose={()=>setModal(false)}
       width={300}
       footer={false}
       closable={false}
      
     >
       <div >
         <h6 className="font-bold text-[15px] text-center mb-2 w-full">Are you sure?</h6>
         <h6 className='bg-slate-100 text-center text-[12px] p-1.5 font-[400]'>After deleting you cannot retrieve it back before deleting check once whether you have used it in some data</h6>
         <div className='flex justify-end mt-3 '>
           {/* <div className='mr-1 w-full'> */}
           <ButtonOutlinedAutoWidth btnName={'Cancel'} onClick={()=>setModal(false)} />
           {/* </div> */}
           <div  className='ml-2'>
           <ButtonFilledAutoWidth btnName={'Confirm'} onClick={()=>deleteStage()}  /> 
           </div>
         </div>
       </div>
     </Modal>

      <div className='block sm:flex'>
       <SettingsMenu />

       <div className='w-[88%] px-4'>
       <div >
       <div className='pt-5'>
       <div className="flex justify-between align-center items-center border-b pb-2 ">
        <span className="font-black text-[14px]">{location?.pathname?.split('/')[location?.pathname?.split('/')?.length - 1].replace('_',' ')} ({data?.length})</span>

       
        <ButtonFilledAutoWidth onClick={()=>navigate('create')} btnName={location?.pathname?.split('/')[location?.pathname?.split('/')?.length - 1].replace('_',' ')} />
        </div>
        </div>

        <div className='overflow-y-scroll min-h-[85vh] max-h-[85vh]'> 
          <div className={`grid  grid-cols-6 gap-1 mt-2`}>
              {data?.map((d,i)=>(
              <div key={i}  className=' relative border items-center   justify-between p-2 border-b'>
                  <h6 className='text-[12px] font-[500] w-40 truncate'>Stage : {d.name}</h6>
                  {pathname === '/settings/stages/Lead_Stage' &&
                  <h6 className='text-[11px] font-[500] w-40 truncate'>Department : {d?.department?.department_name}</h6>}
                  <h6 className='text-[10px] bg-slate-100 p-1'>Created At : <span className='font-[600]'>{moment(d?.createdAt).format('lll')}</span> </h6>
                  <div className='absolute right-1 top-1 flex'>
                  <Tooltip title="Edit" >
                  <span><AiOutlineEdit size={12} className="cursor-pointer" onClick={()=>{navigate('edit',{state:{name:d?.name,id:d?._id,department:(d?.department !== null && d?.department !== undefined) ? {label:d?.department?.name,value:d?.department?._id} : '',sort:d?.sort}})}}/></span>
                  </Tooltip>
                  <Tooltip title="Delete">
                  <span><AiOutlineDelete size={12} className='ml-2 cursor-pointer'  onClick={()=>{setselecteddata({name:d.name,id:d._id});setModal(true)}}/></span>
                  </Tooltip>
                  </div>
              </div>
              ))}
          </div>
        </div>
    </div> 
    </div>
    </div>
    </div>
  )
}

export default StageList