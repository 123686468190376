import { deleteRequest, get, post, postfd, publicPost, put } from "../../../helpers/apihelpers";

export const CreateCorpWebsiteBlogService=async(data)=>{
    try {
        const result = await publicPost(`api/corp_website/blogs/create`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateCorpWebsiteBlogService=async(data,id)=>{
    try {
        const result = await put(`api/corp_website/blogs/update/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetCorpWebsiteBlogService=async(page,text)=>{
    try {
        const result = await get(`api/corp_website/blogs/get?page=${page}&text=${text}`,);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteCorpWebsiteBlogService=async(id)=>{
    try {
        const result = await deleteRequest(`api/corp_website/blogs/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UploadCorpWebsiteBlogService=async(data)=>{
    try {
        const result = await postfd(`api/corp_website/blogs/upload_file`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}