import { deleteRequest, get, postfd,post, put } from "../../helpers/apihelpers";

export const CreateNoticesService=async(data)=>{
    try {
        const result = await post(`api/govt/notices/create`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateNoticesService=async(id,data)=>{
    try {
        const result = await put(`api/govt/notices/update/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UploadNoticesFileService=async(data)=>{
    try {
        const result = await postfd(`api/govt/notices/upload_file`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetNoticesService=async(page,step,from_date,to_date,search_text)=>{
    try {
        const result = await get(`api/govt/notices/get?page=${page}&step=${step}&from_date=${from_date}&to_date=${to_date}&search_text=${search_text}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteNoticesService=async(id)=>{
    try {
        const result = await deleteRequest(`api/govt/notices/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

