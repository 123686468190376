import { IconButton, Tooltip } from '@mui/material'
import React,{useState,useEffect} from 'react'
import GalleryAppBar from '../GalleryAppBar'
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../../components/button'
import { useLocation, useNavigate } from 'react-router-dom'
import {BsArrowRepeat} from 'react-icons/bs';
import {FiChevronRight,FiChevronLeft} from 'react-icons/fi';
import { DatePicker, Modal } from 'antd';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import fileDownload from "js-file-download";
import {IoClose} from 'react-icons/io5';
import moment from 'moment';
import {AiOutlineEdit,AiOutlineDelete,AiOutlineShareAlt} from 'react-icons/ai';
import { DeleteGalleryProductCategoryService, GetGalleryProductCategoryList, SearchGalleryProductCategoryService } from '../../../services/Gallery/GalleryProductCategoryServices'
import { DeleteGalleryProductBrandService, GetGalleryProductBrandList, SearchGalleryProductBrandService } from '../../../services/Gallery/GalleryProductBrandServices'
import { DeleteGalleryProductTagsService, GetGalleryProductTagsList, SearchGalleryProductTagsService } from '../../../services/Gallery/GalleryProductTagsServices'
import { DeleteGalleryArtistService, GetGalleryArtistList, SearchGalleryArtistService } from '../../../services/Gallery/GalleryArtistServices'

function GalleryProducCategorytList() {

  const {pathname}  = useLocation()  
  const path = pathname?.split('/')[pathname?.split('/')?.length - 1]

  
  const [search,setsearch] = useState({text:'',from_date:'',to_date:'',activate:false})
  const [datas,setdatas]   = useState({})
  const [selected_data,setselected_data]   = useState({})
  const [modal,setmodal]   = useState(false)
  const [page,setpage] = useState(1)
  const [type,settype] = useState({path:'',path_display:''})
  const navigate = useNavigate()  


  console.log("type here",type)

  useEffect(()=>{
     if(path === 'product_tags'){
        settype({...type,path:path,path_display:'Tags'})
     }else if(path === 'product_category'){
        settype({...type,path:path,path_display:'Category'})
     }else if(path === 'product_brand'){
        settype({...type,path:path,path_display:'Brand'})
     }else if(path === 'our_artist'){
      settype({...type,path:path,path_display:'Artist'})
     }
     getDatas(1)
     setpage(1)
  },[pathname])

  useEffect(()=>{
    if(search.activate === false){
       getDatas(page)
    }else{
        applyfilterfunction(page)
    }
  },[page])


  async function getDatas(page){
    if(search.activate === false){
        if(path === 'product_category'){
            const response = await GetGalleryProductCategoryList(page)
            setdatas(response?.data)
        }else if(path === 'product_tags'){
            const response = await GetGalleryProductTagsList(page)
            setdatas(response?.data)
        }else if(path === 'product_brand'){
            const response = await GetGalleryProductBrandList(page)
            setdatas(response?.data)
        }else if(path === 'our_artist'){
          const response = await GetGalleryArtistList(page)
          setdatas(response?.data)
        }
    }else{
        let start_date = new Date(search?.from_date).toLocaleDateString().replaceAll('/','-').split('-').reverse().join('-')
        let end_date = new Date(search?.to_date).toLocaleDateString().replaceAll('/','-').split('-').reverse().join('-')
        
        if(type?.path === 'product_category'){
            const response = await SearchGalleryProductCategoryService({search_text:search.text,from_date:(search?.from_date !== ''  && search?.from_date !== null) ? start_date : '',to_date:(search?.to_date !== ''  && search?.to_date !== null) ? end_date : '',page:page})
            setdatas(response?.data)
        }else if(type?.path === 'product_tags'){
            const response = await SearchGalleryProductTagsService({search_text:search.text,from_date:(search?.from_date !== ''  && search?.from_date !== null) ? start_date : '',to_date:(search?.to_date !== ''  && search?.to_date !== null) ? end_date : '',page:page})
            setdatas(response?.data)
        }else if(type?.path === 'product_brand'){
            const response = await SearchGalleryProductBrandService({search_text:search.text,from_date:(search?.from_date !== ''  && search?.from_date !== null) ? start_date : '',to_date:(search?.to_date !== ''  && search?.to_date !== null) ? end_date : '',page:page})
            setdatas(response?.data)
        }else if(path === 'our_artist'){
          const response = await SearchGalleryArtistService(page)
          setdatas(response?.data)
        }
    }
  }

  async function deleteData(){
    if(type?.path === 'product_category'){
        const response = await DeleteGalleryProductCategoryService(selected_data?._id)
        if(response?.status == 200){
            setmodal(false)
            toast.success("Deleted successfully")
            getDatas(page)
        }
    }else if(type?.path === 'product_tags'){
        const response = await DeleteGalleryProductTagsService(selected_data?._id)
        if(response?.status == 200){
            setmodal(false)
            toast.success("Deleted successfully")
            getDatas(page)
        }
    }else if(type?.path === 'product_brand'){
        const response = await DeleteGalleryProductBrandService(selected_data?._id)
        if(response?.status == 200){
            setmodal(false)
            toast.success("Deleted successfully")
            getDatas(page)
        }
    }else if(type?.path === 'our_artist'){
      const response = await DeleteGalleryArtistService(selected_data?._id)
      if(response?.status == 200){
          setmodal(false)
          toast.success("Deleted successfully")
          getDatas(page)
      }
  }
  }

  async function resetfunc(){
    setsearch({text:'',from_date:'',to_date:'',activate:false})
    getDatas(1)
  }


  async function applyfilterfunction(page){
    setsearch({...search,activate:true})
    let start_date = new Date(search?.from_date).toLocaleDateString().replaceAll('/','-').split('-').reverse().join('-')
    let end_date = new Date(search?.to_date).toLocaleDateString().replaceAll('/','-').split('-').reverse().join('-')
    const response = await SearchGalleryProductCategoryService({search_text:search.text,from_date:(search?.from_date !== ''  && search?.from_date !== null) ? start_date : '',to_date:(search?.to_date !== ''  && search?.to_date !== null) ? end_date : '',page:page})
    setdatas(response.data)

  }

  const handleDownload = (url, filename) => {
    // console.log('url',url,'filename',filename)

    axios.get(url, {
        responseType: 'blob',
    })
        .then((res) => {
            fileDownload(res.data, filename)
            toast.success("Visitor Excel Downloaded") 
        })
}


  
  return (
    <div className='h-screen max-h-screen box-border overflow-hidden'>
        <div className='block sm:flex'>

        <Modal open={modal} center={true} width={300} closable={false} footer={false}>
            <div className='relative'> 
                <IoClose className='absolute right-0 top-0 cursor-pointer' onClick={()=>{setmodal(false)}} />
                <h6 className='text-[15px] font-[700]'>Delete {type?.path_display} Data</h6>
                <h6 className='leading-tight mt-2 text-[12px] bg-slate-100 p-2'>Are You sure want to delete the below selected <span className='lowercase'>{type?.path_display}</span>  data  </h6>

                <h6 className='leading-tight mt-2 text-[12px] px-2'>Name : {selected_data?.name}  </h6>

                <div className='flex mt-4'>
                    <ButtonOutlinedAutoWidth btnName="Cancel" onClick={()=>{setmodal(false)}} />
                    <div className='ml-2'>
                    <ButtonFilledAutoWidth btnName="Yes Im sure" onClick={deleteData} />
                    </div>
                </div>

          </div> 
      </Modal>

            
        <GalleryAppBar />

        <div className='w-[85%]  ml-4  mt-4'>
        <div className='flex items-center justify-between border-b pb-2'>
        <h6 className='font-[700] text-[14px] '>Total {type?.path_display} ({datas?.pagination?.total})</h6>
        <div className='flex items-center'>
          <div className='flex items-center text-[12px] mr-2'>

            <h6 className='mr-2 font-[600]'>{page == 1 ? datas?.datas?.length > 0 ? 1 : 0 : (page - 1) * datas?.pagination?.limit } - {datas?.pagination?.limit} of {datas?.pagination?.total} </h6>
            <IconButton onClick={resetfunc}><BsArrowRepeat size={16} /></IconButton>

            <div>
            <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>
            <IconButton onClick={()=>{ page < datas?.pagination?.totalPages  ? setpage(page+1) : console.log('')}}><FiChevronRight className={`${(datas?.pagination?.totalPages === page || datas?.datas?.length === 0)  ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>

            </div>
          </div>


          <DatePicker size='small' style={{fontSize:'11px'}} ampm={false} placeholder='From Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.from_date} onChange={(v) => {console.log('v na',new Date(v).toLocaleDateString());setsearch({...search,from_date:v})}} /> 

          <DatePicker size='small' style={{fontSize:'11px'}} ampm={false} placeholder='To Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.to_date} onChange={(v) => {console.log('v na',new Date(v).toLocaleDateString());setsearch({...search,to_date:v})}} /> 

        
        <div className='mr-2'>
            

          <input  id="search_text" placeholder='Search text' type='text' value={search.text} onChange={(e)=>setsearch({...search,text:e.target.value})} className='border py-[3px] focus:ring-0 focus:outline-0 text-[14px]  w-28 px-1 rounded-md border-slate-300' />
      
        </div>      

       <ButtonOutlinedAutoWidth btnName="Add Filter" onClick={()=>applyfilterfunction(1)} /> 
        {/* {leads_selected_list?.length > 0 &&
        <div className='ml-2'>
        <ButtonFilledAutoWidth onClick={createManyContactBasedOnlIST} btnName="Create Contact" /> 
        </div>} */}
         <div className='ml-2 mr-0'>
        <ButtonOutlinedAutoWidth btnName="Add Data" onClick={()=>navigate('create')} /> 
        </div>
        {/* <div className='ml-2 mr-2'>
        <ButtonFilledAutoWidth btnName="Download Excel" onClick={downloadExcelData}/> 
        </div> */}
        </div>

        </div>

         {datas?.datas?.length === 0 &&
          <div className='grid place-items-center mt-20  items-center justify-center'>
          <img src={'https://fidecrmfiles.s3.amazonaws.com/NoDataFound1.png'} className='w-40 h-40 object-contain' /> 
          <h6 className='font-bold text-[14px] -mt-10'>No data found</h6>
          <h6 className='font-[500] w-[80%] text-center text-slate-700 text-[12.5px] -mt-2'>Oops we couldn't find any product category added to the system.</h6>
        </div>
        }

        {datas?.datas?.length > 0 &&
        <div className='max-h-[85vh] overflow-y-scroll'>
      
       <div className='grid grid-cols-5 mt-2 gap-2'>
        {datas?.datas?.map((d,i)=>(
        <div  key={d?._id} className={`flex z-50 border border-slate-200 z-0`}  >
            
            <div className='w-[75%] border-r border-slate-200 my-1'>
            <h6 className='text-[12px] font-[500]   px-2 py-1    '>{d?.name}</h6>
            <h6 className='text-[10px] bg-gray-100 mx-1 font-[500]   px-2 py-1 '>Created At : {moment(d?.createdAt)?.format('LLL')}</h6>
            </div>
            <h6  className='text-[12px] w-[25%]  px-2 py-1 mt-1 flex'>
            
             
              <Tooltip title="Edit">
              <span><AiOutlineEdit size={13} className="z-10 ml-2" onClick={()=>navigate('edit',{state:d})}/></span>
              </Tooltip>

              {/* {user?.roles?.includes('delete_data') && */}
              <Tooltip title="Delete">
              <span><AiOutlineDelete size={13} className="z-10 ml-2 -mt-[1px]" onClick={()=>{setselected_data(d);setmodal(true)}}/></span>
              </Tooltip>

            
              

            
            </h6>
        </div>))}
        </div>

      </div>}
      </div>
    </div>
    </div>
  )
}

export default GalleryProducCategorytList