import { deleteRequest, get, post, put } from "../../../helpers/apihelpers";

export const CreateKathaTypeService=async(data)=>{
    try {
        const result = await post(`api/database/katha_type/create`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateKathaTypeService=async(data,id)=>{
    try {
        const result = await put(`api/database/katha_type/update/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetKathaTypeService=async(page,from_date,to_date,text,step)=>{
    try {
        const result = await get(`api/database/katha_type/get?page=${page}&from_date=${from_date}&to_date=${to_date}&text=${text}&step=${step}`,);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteKathaTypeService=async(id)=>{
    try {
        const result = await deleteRequest(`api/database/katha_type/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteAdminKathaTypeService=async(id)=>{
    try {
        const result = await deleteRequest(`api/database/katha_type/delete_admin/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const FilterKathaTypeService=async(id)=>{
    try {
        const result = await get(`api/database/katha_type/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}