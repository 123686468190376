import React, { useState } from 'react'
import {VscLock} from 'react-icons/vsc'
import { toast } from 'react-hot-toast';
import { TextInput } from '../../components/input';
import { UpdatePasswordService } from '../../services/AuthServices';
import { ButtonOutlined } from '../../components/button';
import ProfileMenu from '../profile/ProfileMenu';
import { useNavigate } from 'react-router-dom';





export default function ForgotPassword() {


  const navigator = useNavigate()

  const [data,setdata] = useState({old_password:'',new_password:'',confirm_password:''})
  const [error,seterror] = useState({old_password:'',new_password:'',confirm_password:''})


  function handlechange(e){
    setdata({...data,[e.target.name] : e.target.value})
    seterror({...error,[e.target.name] : ''})
  }

  function onKeyDownHandler(e){
    if (e.keyCode === 13) {
      handleSubmit();
    }
  }
  
  const handleSubmit = async () => {
    if(!data.old_password){
      seterror({...error,old_password:'Old Password required*'})
    }else if(data.new_password.length < 8 ){
      seterror({...error,new_password:'New Password should be atleast 8 characters*'})
    }else if(data.new_password !== data.confirm_password ){
      seterror({...error,confirm_password:' Password did not match*'})
    }else{
       let send_data = {...data}
       send_data['password_confirmation'] = data.new_password
       send_data['password'] = data.new_password
       const response = await UpdatePasswordService(send_data)
       if(response.status === 200){
        toast.success('New password updated successfully')
        setdata({...data,old_password:'',new_password:''})
       }
       if(response.status === 403){
        toast.error(response.data.msg)
       }
    } 
  };


  return (
    <div className='max-h-screen overflow-y-scroll overflow-x-hidden px-2 mx-0 box-border '>


    <div className='block sm:flex'>
    <ProfileMenu />

    <div className='w-full'>
      <div className='ml-6 w-4/6 ml-4 mt-4 pt-5'>


            <h6 className='border-b mt-1 pb-2 mb-4 font-[700] text-[17px] border-[#d0d7de]'>Change password</h6>



            {/* <h6 className='text-[18px] font-[800]'>Update Password</h6>      
            <h6 className={`text-[10px] font-[600] leading-normal bg-[${lightThemeColor}] p-2 my-2`}>Add your old password and add new password to update ur account for security purpose.</h6> */}
              <form 
              onKeyDown={onKeyDownHandler}
              onSubmit={handleSubmit}>
              <div className='w-[40%]'>

            <TextInput 
                  label={'Old Password'}  
                  variant="standard"
                  name="old_password"
                  type="password"
                  error={error.old_password}
                  value={data.old_password}
                  handlechange={handlechange}
                  placeholder="Enter your old password"
                  InputLabelProps={{
                      style: { color: '#fff', }, 
                  }}/>  


                  <TextInput 
                  label={'New Password'}  
                  variant="standard"
                  name="new_password"
                  type="password"
                  error={error.new_password}
                  value={data.new_password}
                  handlechange={handlechange}
                  placeholder="Enter your new password"
                  InputLabelProps={{
                      style: { color: '#fff', }, 
                  }}/>  

                <TextInput 
                  label={'Confirm Password'}  
                  variant="standard"
                  name="confirm_password"
                  type="password"
                  error={error.confirm_password}
                  value={data.confirm_password}
                  handlechange={handlechange}
                  placeholder="Enter your new password"
                  InputLabelProps={{
                      style: { color: '#fff', }, 
                  }}/>  


              

              <h6 className='text-[11px] pt-2 font-[400] text-gray-600 pb-0 leading-normal' >Make sure it's at least 15 characters OR at least 8 characters including a number and a lowercase letter. <span className='text-[#0a69da] cursor-pointer hover:underline'>Learn more.</span></h6>

              <div className='mt-4'>
              <ButtonOutlined btnName="Update password" onClick={handleSubmit} />
              <span className='text-[#0a69da] ml-2 text-[11px] font-[500] cursor-pointer hover:underline' onClick={()=>navigator('/forgot_password')}> I forgot my password.</span>
              </div>
              </div>
              </form>

            <h6 className='border-b mt-1 pb-2 mb-4 font-[700] text-[17px] border-[#d0d7de] mt-5'>Two Factor Authentication</h6>
            <div className='flex flex-col items-center justify-center'>
              
              <VscLock size={25} />
              <h6 className='text-[13px] pt-2 font-[400]  pb-0 leading-normal mt-2'>Two-factor authentication is not enabled yet.</h6>
              <h6 className='text-[13px] pt-2 font-[400] w-[70%] text-center  pb-0 leading-normal'>Two-factor authentication adds an additional layer of security to your account by requiring more than just a password to sign in.</h6>
              
              <div className='mt-4'>
              <button  className="border border-[#22793a] bg-[#1e883e] text-white font-[700] py-[5px] px-4 mt-0  rounded-md border-slate-600 text-[12px] hover:border-slate-600">
              Enable two-factor authentication
      </button>

              {/* <ButtonFilledAutoWidth btnName="Enable two-factor authentication" /> */}
              </div>
              <h6 className='text-[12px] pt-2 font-[400] text-sky-600'>Learn more</h6>
              
            </div>


      </div>
      </div>
    </div> 
    </div>
  );
}