import React, { useEffect, useState } from 'react'
import GoBack from '../../../../components/back/GoBack'
import { CreatePlotTypeService, UpdatePlotTypeService } from '../../../../services/database/databaseoptions/PlotTypeServices'
import toast from 'react-hot-toast'
import { useLocation, useNavigate } from 'react-router-dom'
import { TextInput } from '../../../../components/input'
import DatabaseMenu from '../../DatabaseMenu'
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../../../components/button'
import { CreatePropertyTypeService, UpdatePropertyTypeService } from '../../../../services/database/databaseoptions/PropertyTypeServices'
import { CreateApprovedTypeService, UpdateApprovedTypeService } from '../../../../services/database/databaseoptions/ApprovedTypeServices'
import { CreateLocationBasedZoneService, UpdateLocationBasedZoneService } from '../../../../services/database/databaseoptions/LocationBasedZoneServices'
import { GetPropertyZoneService } from '../../../../services/database/databaseoptions/PropertyZoneServices'
import {Select} from 'antd'
function LocationBasedZoneCE() {
  
  const [data,setdata] = useState({name:'',zone:'',sort:'',is_deleted:false})  
  const [error,seterror] = useState({name:'',zone:'',sort:'',is_deleted:''})  
  const [zone,setzone] = useState([])

  const navigate = useNavigate()
  const  {state} = useLocation()


  useEffect(()=>{
    if(state?._id !== undefined){
        setdata({...state,zone:{label:state?.propertyZone?.name,value:state?.propertyZone?._id}})
    }
    getzonefunc()
  },[state])


  async function getzonefunc(){
    const response = await GetPropertyZoneService(1,'','','',1)
    let d = response?.data?.datas
    let arr = []
    d?.forEach((d1)=>{
        arr.push({label:d1?.name,value:d1?._id})
    })
    setzone(arr)
  }

  async function submitform(){
    if(!data?.zone){
        seterror({...error,zone:'This Field is required*'})
    }else if(!data?.name){
        seterror({...error,name:'This Field is required*'})
    }else if(!data?.sort){
        seterror({...error,sort:'This Field is required*'})
    }else{
       let oldData = {...data}
       oldData['propertyZone'] = data?.zone?.value

        if(data?._id === undefined){
            const response = await CreateLocationBasedZoneService(oldData)
            if(response?.status === 201){
                toast.success("Location Based Zone Created Successfully")
                setdata({name:'',zone:'',sort:'',is_deleted:false})
                seterror({name:'',zone:'',sort:'',is_deleted:''})
            }
        }else{
            const response = await UpdateLocationBasedZoneService(data,oldData?._id)
            if(response?.status === 200){
                toast.success("Location Based Zone Updated Successfully")
                setdata({name:'',zone:'',sort:'',is_deleted:false})
                seterror({name:'',zone:'',sort:'',is_deleted:''})
                navigate(-1)
            }
        }
    }
  }

  async function handlechange(e){
        setdata({...data,[e.target.name]:e.target.value})
        seterror({...error,[e.target.name]:''})
  }

  return (
    <div className='flex'>
        <div className='min-w-[180px] w-[180px] max-w-[180px]'>
        <DatabaseMenu /> 
        </div>
        <div className='ml-4 w-64 mt-4 pr-5 min-h-screen max-h-screen h-screen overflow-y-scroll'>
        <div>
        <GoBack />

        <div className='border-b  pb-2'>
        <h6 className='font-[800] mb-1'>Create / Edit Location Based Zone Type</h6> 
        <h6 className='text-[11px] leading-tight font-[500] p-2 bg-slate-100 '>Use the below form to create or edit the Location based on zone type for the property function.</h6> 
        </div> 

        <h6 className='text-[12px] font-semibold mb-1 mt-1'>Zone *</h6>

        <Select 
        value={data?.zone}
        bordered={false}
        onChange={(e)=>{setdata({...data,zone:zone?.find((f)=>f?.value === e)})}} 
        options={zone}
        className='border-l-4 border-l-slate-600 w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none'


        />


        <TextInput 
              mandatory={true}
              label={'Name'}  
              variant="standard"
              name="name"
              type="text"
              value={data.name}
              error={error.name}
              handlechange={handlechange}
              placeholder="Enter contact name"
              InputLabelProps={{
                  style: { color: '#fff', }, 
              }}/>

        <TextInput 
              mandatory={true}
              label={'Sort'}  
              variant="standard"
              name="sort"
              type="number"
              value={data.sort}
              error={error.sort}
              handlechange={handlechange}
              placeholder="Enter contact name"
              InputLabelProps={{
                  style: { color: '#fff', }, 
              }}/>  


              <div className='flex mt-2 justify-end border-t pt-2'>
               <ButtonOutlinedAutoWidth  btnName="Cancel" onClick={()=>navigate(-1)} />  
               <h6 className='w-[10px]'></h6>
               <ButtonFilledAutoWidth  btnName="Save" onClick={submitform} />  
              </div>    

        </div> 
    </div >
    </div>

  )
}

export default LocationBasedZoneCE