import { deleteRequest, get, post, postfd, put } from "../helpers/apihelpers";

export const CreateMdFollowUpService=async(data)=>{
    try {
        const result = await post(`api/md_followup/create`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateMdFollowUpService=async(data,id)=>{
    try {
        const result = await put(`api/md_followup/update/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetMdFollowUpService=async(text,from_date,to_date,page,type='',status='')=>{
    try {
        const result = await get(`api/md_followup/get?search=${text}&from_date=${from_date}&to_date=${to_date}&page=${page}&type=${type}&status=${status}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetMdFollowUpAdminService=async(page=1,from_date='',to_date='',search='',department,user)=>{
    try {
        const result = await get(`api/md_followup/get-admin?from_date=${from_date}&to_date=${to_date}&search=${search}&page=${page}&department=${department}&user=${user}`);
        return result;
    } catch (err) {
        return err.response;
    }
}


export const FilterMdFollowUpService=async({stage,step,page,from_date,to_date,search})=>{
    try {
        const result = await get(`api/md_followup/filter?related_to=${stage}&stage=${step}&from_date=${from_date}&to_date=${to_date}&search=${search}&page=${page}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteMdFollowUpService=async(id)=>{
    try {
        const result = await deleteRequest(`api/md_followup/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DownloadMdFollowUpService=async(user,from_date,to_date)=>{
    try {
        const result = await get(`api/md_followup/download_excel?user=${user}&from_date=${from_date}&to_date=${to_date}`);
        return result;
    } catch (err) {
        return err.response;
    }
}