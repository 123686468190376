import { deleteRequest, get, post, put } from "../../../helpers/apihelpers";

export const CreateConversionTypeService=async(data)=>{
    try {
        const result = await post(`api/database/conversion_type/create`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateConversionTypeService=async(data,id)=>{
    try {
        const result = await put(`api/database/conversion_type/update/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetConversionTypeService=async(page,from_date,to_date,text,step)=>{
    try {
        const result = await get(`api/database/conversion_type/get?page=${page}&from_date=${from_date}&to_date=${to_date}&text=${text}&step=${step}`,);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteConversionTypeService=async(id)=>{
    try {
        const result = await deleteRequest(`api/database/conversion_type/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteAdminConversionTypeService=async(id)=>{
    try {
        const result = await deleteRequest(`api/database/conversion_type/delete_admin/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const FilterConversionTypeService=async(id)=>{
    try {
        const result = await get(`api/database/conversion_type/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}