import React,{useState,useEffect} from 'react'
import Grid from '@mui/material/Grid';
import { TextInput } from '../../../components/input';
import {IoClose} from 'react-icons/io5'
import { Tooltip } from '@mui/material'
import { ButtonFilled, ButtonOutlined } from '../../../components/button';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import {Select } from 'antd';
import GoBack from '../../../components/back/GoBack';
import { CreateCallService, GetCallDetailService, UpdateCallService } from '../../../services/CallsLeadServices';
// import DailyTaskCE from './dailyTask/DailyTaskCE';
import FcaresMenu from '../FcaresMenu';
import {  CreateFcaresService, GetFcaresByIdService, UpdateFcaresService, UploadDocsFcaresService } from '../../../services/Fcares/FCaresServices';


function FCaresServicesCE() {



  const [data,setdata] = useState({
    name:'',
    type:'',
    image:'',
    sort:'',
  
  })

  

  
 
  const [loader, setloader] = useState(false);



  const [error,seterror] = useState({
    name:'',
    type:'',
    image:'',
    sort:'',
  })

  const navigate = useNavigate()
  const location = useLocation()



   

  
  useEffect(()=>{

    if(location?.pathname?.split('/')[location?.pathname?.split('/').length -1] === 'edit'){
      geteditdata()
    }
  },[])

  async function geteditdata(){
    const response =  await GetFcaresByIdService(location?.state);
    let d = response?.data?.data
    setdata({...d})
  }

  // console.log("data?.operators",data?.operators)
  function handlechange(e){
    setdata({...data,[e.target.name] : e.target.value})
    seterror({...error,[e.target.name] : ''})
  }

  // console.log("data",data)

  async function submitform(){
   
    if(!data.name){
        seterror({...error,name:'This Field is required*'})
    }else if(!data.sort){
        seterror({...error,sort:'This Field is required*'})
    }else{
       
        if(location?.pathname?.split('/')[location?.pathname?.split('/').length - 1] === 'edit'){
          setloader(true)
          const response = await UpdateFcaresService(data?._id,data)
          if(response.status === 200){
            setloader(false)
            toast.success('Services Updated Successfully')
            resetform()
            navigate(-1)
          }
        }else{  
          setloader(true)
          const response = await CreateFcaresService(data)
          if(response.status === 201){
              setloader(false)
              toast.success('Services Created Successfully')
              resetform()
          }
        }
      }
  }

  function resetform(){
     setdata({
        name:'',
        type:'',
        sort:'',
      })
      seterror({
        name:'',
        type:'',
        sort:'',
      })

    
  }



  async function uploadfilefunc(v,name){
    // console.log("v",name)
   
    const fd = new FormData()
    fd.append('image',v); 
    const response = await UploadDocsFcaresService(fd)
    if(response?.status === 200){
      setdata({...data,image:response?.data?.data})
    }
  }

 
  


  return (
    <div className='h-screen max-h-screen box-border overflow-hidden'>
    <div className='block sm:flex'>
        <FcaresMenu />
    <div className='pr-5 ml-5 min-h-screen max-h-screen h-screen overflow-y-scroll' >
    

  

    <div className='w-64 '>
      <GoBack />
     <div className='border-b  pb-2'>
      <h6 className='font-[800] mb-1'>{location?.pathname?.split('/')[location?.pathname?.split('/').length -1] === 'edit' ? 'Edit' : 'Create'} Amenities </h6> 
      <h6 className='text-[11px] leading-tight font-[500] p-2 bg-slate-100 '>Use the below form to create or edit the amenities for the fcares .</h6> 
     </div> 
      <Grid container spacing={2}  >
        <Grid item xs={12}  md={12} >
          <TextInput 
              mandatory={true}
              label={'Name'}  
              variant="standard"
              name="name"
              type="text"
              value={data.name}
              error={error.name}
              handlechange={handlechange}
              placeholder="Enter contact name"
              InputLabelProps={{
                  style: { color: '#fff', }, 
              }}/>
          {/* <TextInput 
              mandatory={true}
            label={'Type'}  
            variant="standard"
            name="type"
            type="text"
            value={data.type}
            error={error.type}
            handlechange={handlechange}
            placeholder="Enter contact phone"
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/> */}

            <TextInput 
            label={'Sort'}  
            variant="standard"
            name="sort"
            type="number"
            value={data.sort}
            error={error.sort}
            handlechange={handlechange}
            placeholder="Enter contact phone"
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>

            
  <h6 className='text-[11px] font-[600] mb-1' >Image</h6>
  {(data.image === '' || data.image == null) ?
      <form onClick={()=>document.querySelector(`.input-field`).click()} className={`p-4 flex flex-col items-center justify-center cursor-pointer border border-slate-300 `}>
          <input type='file' onChange={({target:{files}})=>{
            files[0] && uploadfilefunc(files[0],'image')
          }} accept="*" className='input-field' hidden />
      </form> 
    :
        <div className='p-2 border border-slate-300 relative flex flex-col  cursor-pointer'>
            <Tooltip title='Delete'><IoClose className='absolute top-3 right-2' onClick={()=>setdata({...data,image:''})}/></Tooltip>
            <h6 className='text-[12px] truncate w-48 ml-0'>{data?.image}</h6>
        </div>
        
    }
           
       
            
        </Grid>
       
      </Grid>

   
   <div >
    <div className='flex items-center mt-5 mb-10  border-t pt-5'>
        <div className='mr-2'>
        <ButtonOutlined btnName={'Back'} onClick={()=>navigate(-1)} />
        </div>
        <div>
        {loader ?   
        <ButtonFilled btnName={loader ? 'Loading' : 'Save'} /> :
        <ButtonFilled btnName={loader ? 'Loading' : 'Save'} onClick={()=>submitform(null)} /> }
        </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
   
  )
}

export default FCaresServicesCE