import { deleteRequest, get, post, put } from "../../helpers/apihelpers";


export const GetInsentiveBasicList =async(page)=>{
    try {
        const result = await get(`api/insentive_requirment/get?page=${page}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetAllInsentiveList = async ()=>{
    try {
        const result = await get(`api/insentive_requirment/list`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetInsentiveActive = async ()=>{
    try {
        const result = await get(`api/insentive_requirment/getActive`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const CreateInsentiveBasic =async(data)=>{
    try {
        const result = await post(`api/insentive_requirment/create`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateInsentiveBasic =async(id,data)=>{
    try {
        const result = await put(`api/insentive_requirment/update/${id}`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteInsentiveBasic =async(id,data)=>{
    try {
        const result = await deleteRequest(`api/insentive_requirment/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetDetailInsentiveBasicList =async(page)=>{
    try {
        const result = await get(`api/insentive_requirment/getActive`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const CreateInsentivePeriodList =async(data)=>{
    try {
        const result = await post(`api/insentive_requirment/create_period`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetInsentivePeriodList =async(id)=>{
    try {
        const result = await post(`api/insentive_requirment/get_period/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetEmployeeTargetBasedOnIncentive = async(id,period,page)=>{
    try {
        const result = await get(`api/invoice_payment/get_employee_target_based_on_insentive?incentive=${id}&period=${period}&page=${page}`);
        return result;
    } catch (err) {
        return err.response;
    }
}
