import { get } from "../helpers/apihelpers";


export const GetFinanceDashboardService=async()=>{
    try {
        const result = await get(`api/invoice_payment/finance_dashboard`);
        return result;
    } catch (err) {
        return err.response;
    }
}