import { deleteRequest, get, post, postfd, put } from "../../helpers/apihelpers";

export const CreateAtsClientService=async(data)=>{
    try {
        const result = await postfd(`api/hr/clients/create`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateAtsClientService=async(data,id)=>{
    try {
        const result = await postfd(`api/hr/clients/update/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetAtsClientService=async({page,stage})=>{
    try {
        const result = await get(`api/hr/clients/get?page=${page}&stage=${stage}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetAtsDetailClientService=async(id)=>{
    try {
        const result = await get(`api/hr/clients/get/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const FilterAtsClientService=async({text,from_date,to_date,page})=>{
    try {
        const result = await get(`api/hr/clients/filter?search_text=${text}&from_date=${from_date}&to_date=${to_date}&page=${page}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteAtsClientService=async(id)=>{
    try {
        const result = await deleteRequest(`api/hr/clients/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}