import React,{useState,useEffect} from 'react'
import Grid from '@mui/material/Grid';
import { TextAreaInput1, TextInput } from '../../../components/input';
import { ButtonFilled, ButtonOutlined } from '../../../components/button';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import {Select } from 'antd';
import GoBack from '../../../components/back/GoBack';
// import DailyTaskCE from './dailyTask/DailyTaskCE';
import FcaresMenu from '../FcaresMenu';
import { BiCheckbox,BiCheckboxSquare } from "react-icons/bi";
import { CreateFcaresTicketService, GetFcaresTicketByIdService, UpdateFcaresTicketService } from '../../../services/Fcares/FCaresTicketService';
import { Tooltip } from '@mui/material'
import { IoClose } from 'react-icons/io5'
import { GetFcaresPropertyService, UploadDocsFcaresPropertyService } from '../../../services/Fcares/FCaresPropertyServices';
import { CreateFcaresUpcomingBillService, GetFcaresUpcomingBillService, UpdateFcaresUpcomingBillService } from '../../../services/Fcares/FCaresUpcomingBillServices';

function FCaresUpcomingBillCE() {



  const [data,setdata] = useState({
    property:'',
    type:'',
    amount:'',
    dead_line:'',
    attachment:'',
    remarks:'',
    paid:false,
    is_visible:false,
    send_notification:false
  })

  

  
 
  const [loader, setloader] = useState(false);

  const status = [{label:'Pending',value:'Pending'},{label:'Discussion',value:'Discussion'},{label:'In Progress',value:'In Progress'},{label:'Resolved',value:'Resolved'}]
  const [property,setproperty] = useState([])

  const [error,seterror] = useState({
    property:'',
    type:'',
    amount:'',
    dead_line:'',
    attachment:'',
    remarks:'',
    paid:'',
    is_visible:'',
    send_notification:''
  })

  const navigate = useNavigate()
  const location = useLocation()




  async function uploadfilefunc(v,name){

    const fd = new FormData()
    fd.append('image',v); 
    const response = await UploadDocsFcaresPropertyService(fd)
    if(response?.status === 200){
    setdata({...data,[name]:response?.data?.data})
    }
  }

  // useEffect(()=>{
  //     // getData()
  // },[])

  async function getData(){
    const response = await GetFcaresPropertyService('','','','1',1)
    let arr = []
    // console.log("response?.data?.datas",response?.data?.data)
    response?.data?.data?.datas?.forEach((d)=>{
      // console.log("D Here",d)
      let obj = { 
        label:d?.building_name,
        value:d?._id,
        client:d?.client?._id !== undefined ? {label:d?.client?.name,value:d?.client?._id} : '',
        tenant:d?.tenant?._id !== undefined ? {label:d?.tenant?.name,value:d?.tenant?._id} : '' 
      }
      arr?.push(obj)
    })
    setproperty(arr)
  }
   

  
  useEffect(()=>{
    if(location?.pathname?.split('/')[location?.pathname?.split('/').length -1] === 'edit'){
      geteditdata()
    }
  },[location?.pathname])

  async function geteditdata(){
    let d = location.state

    let sendData = {...d}
    
    sendData['property'] = {label:`${d?.property?.building_name} - client : ${d?.property?.client?.name} - mobile : ${d?.property?.client?.mobile} `,value:d?.property?._id}
    sendData['dead_line'] = d?.dead_line !== '' ? d?.dead_line?.slice(0,10) : ''
    setdata(sendData)
  }

  // console.log("data?.operators",data?.operators)
  function handlechange(e){
    setdata({...data,[e.target.name] : e.target.value})
    seterror({...error,[e.target.name] : ''})
  }


  async function submitform(){
   
        const sendData = {...data}
  
        sendData['property'] = data?.property?.value




        if(location?.pathname?.split('/')[location?.pathname?.split('/').length - 1] === 'edit'){
        

          setloader(true)
          const response = await UpdateFcaresUpcomingBillService(sendData?._id,sendData)
          if(response.status === 200){
            setloader(false)
            // console.log("Anna data?.stage?.label",data?.stage?.label,"stage?.selected_stage?.label",stage?.selected_stage?.label)
            setloader(false)
            toast.success('Upcoming Bill Updated Successfully')
            resetform()
            navigate(-1)
          }
        }else{  
          setloader(true)
          const response = await CreateFcaresUpcomingBillService(sendData)
          if(response.status === 201){
              setloader(false)
              toast.success('Upcoming Bill Created Successfully')
              resetform()
          }
        }
  }

  function resetform(){
     setdata({
        property:'',
        type:'',
        amount:'',
        dead_line:'',
        attachment:'',
        remarks:'',
        paid:false,
        is_visible:false,
        send_notification:false
      })
      seterror({
        property:'',
        type:'',
        amount:'',
        dead_line:'',
        attachment:'',
        remarks:'',
        paid:'',
        is_visible:'',
        send_notification:''
      })
  }


  async function setpropertyfunc(v){
     let propertyFind = property?.find((f)=>f?.value === v)
     if(propertyFind !== null){
       setdata({...data,property:propertyFind})
     }
  }

  async function searchfunc(v,type){
    if(type === 'property'){
      const response = await GetFcaresPropertyService(v,"","",1,1)
      let d = response?.data?.data?.datas
      let arr = []
      d?.forEach((d1)=>{
        arr.push({label:`${d1?.building_name} - client : ${d1?.client?.name} - mobile : ${d1?.client?.mobile} `,value:d1?._id})
      })
      setproperty(arr)
     }
  }

  // console.log("data.kp",data?.property)
  // console.log("property",property)
  return (
    <div className='h-screen max-h-screen box-border overflow-hidden'>
    <div className='block sm:flex'>
        <FcaresMenu />
    <div className='pr-5 ml-5 min-h-screen max-h-screen h-screen overflow-y-scroll' >
    

  

    <div className='w-64 '>
      <GoBack />
     <div className='border-b  pb-2'>
      <h6 className='font-[800] mb-1'>{location?.pathname?.split('/')[location?.pathname?.split('/').length -1] === 'edit' ? 'Edit' : 'Create'} Upcoming Bill </h6> 
      <h6 className='text-[11px] leading-tight font-[500] p-2 bg-slate-100 '>Use the below form to create or edit the upcoming bill for the property fcares .</h6> 
     </div> 
      <Grid container spacing={2}  >
        <Grid item xs={12}  md={12} >

        <h6 className='text-[12px] font-semibold mb-1 mt-1'>Property*</h6>
        <Select 
          showSearch
          value={data?.property}
          bordered={false}
          filterOption={false}
          onSearch={(v)=>searchfunc(v,"property")}
          onChange={(e)=>{setpropertyfunc(e)}} 
          options={property}
          allowClear={true}
          className='border-l-4 border-l-slate-600 w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none'
        />

      {data?.property?.label !== undefined &&
        <h6 className='bg-sky-50 mt-2 p-1 text-[11px]'>
          <span className='font-[700]'> Property Info : </span>{data?.property?.label}
        </h6>}


       <div className='flex items-center mt-2'>
        <div className='flex cusror-pointer mr-2' onClick={()=>setdata({...data,paid:!data.paid})}>
            {!data?.paid  ? <BiCheckbox className='text-slate-300' /> : <BiCheckboxSquare className='text-slate-600' />}
            <h6 className='text-[10px] ml-[3px] font-[700]'>Paid</h6>
        </div>

        <div className='flex cusror-pointer' onClick={()=>setdata({...data,is_visible:!data.is_visible})}>
            {!data?.is_visible ? <BiCheckbox className='text-slate-300' /> : <BiCheckboxSquare className='text-slate-600' />}
            <h6 className='text-[10px] ml-[3px] font-[700]'>Visible</h6>
        </div>
        </div>
       

      <h6 className='text-[11px] font-[600] mb-1 mt-2' >Query Attachemnt</h6>
        {(data.attachment === '' || data.attachment == null) ?
            <form onClick={()=>document.querySelector(`.input-field6`).click()} className={`p-4 flex flex-col items-center justify-center cursor-pointer border border-slate-300 `}>
                <input type='file' onChange={({target:{files}})=>{
                  files[0] && uploadfilefunc(files[0],'attachment')
                }} accept="*" className='input-field6' hidden />
            </form> 
          :
              <div className='p-2 border border-slate-300 relative flex flex-col  cursor-pointer'>
                  <Tooltip title='Delete'><IoClose className='absolute top-3 right-2' onClick={()=>setdata({...data,attachment:''})}/></Tooltip>
                  <h6 className='text-[12px] truncate w-48 ml-0'>{data?.attachment}</h6>
              </div>
          }

<TextInput 
              mandatory={true}
              label={'Type'}  
              variant="standard"
              name="type"
              type="text"
              value={data.type}
              error={error.type}
              handlechange={handlechange}
              placeholder=""
              InputLabelProps={{
                  style: { color: '#fff', }, 
              }}/>


<TextInput 
              mandatory={true}
              label={'Amount'}  
              variant="standard"
              name="amount"
              type="text"
              value={data.amount}
              error={error.amount}
              handlechange={handlechange}
              placeholder=""
              InputLabelProps={{
                  style: { color: '#fff', }, 
              }}/>

<TextInput 
              mandatory={true}
              label={'Dead Line'}  
              variant="standard"
              name="dead_line"
              type="date"
              value={data.dead_line}
              error={error.dead_line}
              handlechange={handlechange}
              placeholder=""
              InputLabelProps={{
                  style: { color: '#fff', }, 
              }}/>
          <TextAreaInput1 
              mandatory={true}
              label={'Remarks'}  
              variant="standard"
              name="remarks"
              type="text"
              value={data.remarks}
              error={error.remarks}
              handlechange={handlechange}
              placeholder=""
              InputLabelProps={{
                  style: { color: '#fff', }, 
              }}/>




               {/* <TextAreaInput1 
            label={'Resolution Remark'}  
            variant="standard"
            name="summary"
            type="text"
            value={data.summary}
            error={error.summary}
            handlechange={handlechange}
            placeholder=""
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>
            */}


<div className='flex cusror-pointer' onClick={()=>setdata({...data,send_notification:!data.send_notification})}>
            {!data?.send_notification ? <BiCheckbox className='text-slate-300' /> : <BiCheckboxSquare className='text-slate-600' />}
            <h6 className='text-[10px] ml-[3px] font-[700]'>Send Notification</h6>
        </div>


       
            
        </Grid>
       
      </Grid>

   
   <div >
    <div className='flex items-center mt-5 mb-10  border-t pt-5'>
        <div className='mr-2'>
        <ButtonOutlined btnName={'Back'} onClick={()=>navigate(-1)} />
        </div>
        <div>
        {loader ?   
        <ButtonFilled btnName={loader ? 'Loading' : 'Save'} /> :
        <ButtonFilled btnName={loader ? 'Loading' : 'Save'} onClick={()=>submitform(null)} /> }
        </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
   
  )
}

export default FCaresUpcomingBillCE