import React, { useEffect } from 'react';
import {useLocation,useNavigate } from 'react-router-dom';
import { MdOutlineLeaderboard } from "react-icons/md";
import { CiBank } from "react-icons/ci";
import { HiOutlineOfficeBuilding } from "react-icons/hi";
import { PiWarehouse } from "react-icons/pi";
import { MdOutlineLandscape } from "react-icons/md";

function MdFollowUpMenu() {

  const {pathname} = useLocation();
  const path = pathname.split('/')[pathname.split('/').length - 1]


 

  const navigate = useNavigate();

  useEffect(()=>{
       if(path === 'md_followup'){
        navigate('/md_followup/land/list')
       }
  },[pathname])

  const menu = [
    // {name:'MD Lead',icon:MdOutlineLeaderboard,path:`/md_followup/lead/list`,id:1,color:''},
    {name:'Land',icon:MdOutlineLandscape,path:`/md_followup/land/list`,id:1,color:''},
    {name:'Warehouse',icon:PiWarehouse,path:`/md_followup/warehouse/list`,id:1,color:''},
    {name:'Office Space',icon:HiOutlineOfficeBuilding,path:`/md_followup/office_space/list`,id:1,color:''},
    {name:'Investment',icon:CiBank,path:`/md_followup/investment/list`,id:1,color:''},
  ] 

  
  return (
    <div className='mr-0 min-h-screen max-h-sceen border-r min-w-44  w-44 max-w-44  px-2' >

       

        <div className={` w-full pt-4 `}>
            <h6 className=' ml-2 mb-2 font-[700] text-[12px]'>Followup Option</h6>
            {menu.map((m)=>(
              <>
               {/* { ['/tasks/assigned_work/list']?.includes(m?)} */}
                 
                <div key={m.path} className={`flex items-center my-1 hover:bg-[#f4f5f7] px-1.5 py-1 ml-2 rounded-md relative cursor-pointer ${pathname ===  m.path && 'bg-[#f4f5f7]'}`} onClick={()=>navigate(m?.path)}>
                  {pathname ===  m.path &&  <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3'></span>}
                  <m.icon color='#000' style={{backgroundColor:m?.color,padding:'2px'}} size={16} />
                 <span className='font-[500] ml-2 text-[12px]'>{m.name}</span>
                </div>   
              </>   
            ))}
        </div>
    </div>
  )
}

export default MdFollowUpMenu