import React,{useState,useEffect} from 'react'
import { TextAreaInput1, TextInput } from '../../components/input'
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../components/button'
import { toast } from 'react-hot-toast'
import GoBack from '../../components/back/GoBack'
import { useLocation, useNavigate } from 'react-router-dom'
// import LeadMenu from '../LeadMenu'
import { Select,Divider,} from 'antd'
import Uploader from '../../components/Uploader'
import { useSelector } from 'react-redux'
import AtsMenu from './AtsMenu'
import { AiOutlinePlus } from 'react-icons/ai'
import { BiErrorCircle } from 'react-icons/bi'
import { CreateAtsJobService, UpdateAtsJobService } from '../../services/Ats/AtsJobServices'
import { FilterAtsClientService } from '../../services/Ats/AtsClientServices'
import { GetDepartmentService, GetUsersByDepartment } from '../../services/DepartmentServices'
import {BiCheckbox,BiCheckboxSquare} from 'react-icons/bi';

function AtsJobDescriptionCE() {

  const {state,pathname} = useLocation() 
  const navigator = useNavigate()
  

  const roles = useSelector(state=>state.Auth.roles)
  
  const path = pathname?.split('/')[pathname?.split('/')?.length - 1]
  const path1 = pathname?.split('/')[pathname?.split('/')?.length - 3]
  const [singleData,setsingleData] = useState({image:'',stage:{label:'Pending',value:'Pending'},job_title:'',job_description:'',skills:'',cutoff_salary:'',ats_hr_client:'',no_of_opening:'',location:[],stages:[],experience:'',job_type:'',employment_type:'',notice_period:'',closed:false,website_visible:false,jd_handeled_by:[]})
  const [error,seterror] = useState({image:'',stage:'',job_title:'',job_description:'',skills:'',cutoff_salary:'',ats_hr_client:'',no_of_opening:'',location:'',stages:'',experience:'',job_type:'',employment_type:'',website_visible:'',notice_period:'',jd_handeled_by:''})
  const [location,setlocation] = useState([])
  const [clients,setclients] = useState([])
  const [stages,setstages] = useState([])
  const [name,setname] = useState('')
  const [name1,setname1] = useState('')
  const [employee,setemployee] = useState([])


  

  

  const [loader,setloader] = useState(false)

  useEffect(()=>{
    getdepartment()
    if(path === 'create' &&  path1 === 'assigned_work' && !roles?.includes('task_manager')){
        navigator(-1)
        resetform()
    }
    if(path === 'edit'){
      let d = state.data


      let handled_by = []

      d?.jd_handeled_by?.forEach((e)=>{
        handled_by.push({label:e?.name,value:e?._id})
      })

    //   console.log("d?.website_visible",d?.website_visible)

      let edit_data = {
        ...d,
        ats_hr_client:{label:`[${d?.ats_hr_client?.company_name} : Client : ${d?.ats_hr_client?.client_name}]`,value:d?.ats_hr_client?._id},
        location:d?.location?.split(":"),
        stages:d?.stages?.split(":"),
        website_visible:d?.website_visible === undefined ? false : d?.website_visible,
        image:(d?.job_attachment === undefined || d?.job_attachment === null) ? '' : d?.job_attachment,
        jd_handeled_by:handled_by
    }

    console.log("edit_data",edit_data)

    setlocation(d?.location?.split(":"))
    setstages(d?.stages?.split(":"))
    setsingleData({...edit_data})
    }
  },[])

  const addItem = (type)=>{
    if(type === '1'){
        if(name !== ''){
            setlocation([...location,name])
            setname('')
        }
       
    }else{
        if(name1 !== ''){
            setstages([...stages,name1])
            setname1('')
        }
    } 
  }

  console.log("singleData?.jd_handeled_by",singleData?.jd_handeled_by)

  async function submitform(){
    // if(state?.type === 'daily_tasks'){
        if(singleData?.ats_hr_client?.value === undefined){
            seterror({...error,ats_hr_client:'The Client  field is required'})
        }else if(!singleData.job_title){
            seterror({...error,job_title:'The Job title field is required'})
        }else if(!singleData.cutoff_salary){
            seterror({...error,cutoff_salary:'The cut off field is required'})
        }else if(!singleData.no_of_opening){
            seterror({...error,no_of_opening:'The No of opening field is required'})
        }else if(!singleData.experience){
            seterror({...error,experience:'The Experience field is required'})
        }else if(!singleData.notice_period){
            seterror({...error,notice_period:'The Notice period field is required'})
        }else if(singleData.location.length === 0){
            seterror({...error,location:'The job location field is required'})
        }else if(!singleData.job_type){
            seterror({...error,job_type:'The job type field is required'})
        }else if(!singleData.employment_type){
            seterror({...error,employment_type:'The employment type field is required'})
        }else{
                
            //    console.log("singleData",singleData?.stage)

                const fd = new FormData()

        
                fd.append('ats_hr_client',singleData?.ats_hr_client?.value)
                fd.append('job_title',singleData?.job_title)
                fd.append('job_description',singleData?.job_description)
                fd.append('skills',singleData?.skills)
                fd.append('cutoff_salary',singleData?.cutoff_salary)
                fd.append('no_of_opening',singleData?.no_of_opening)
                fd.append('experience',singleData?.experience)
                fd.append('notice_period',singleData?.notice_period)
                fd.append('location',singleData?.location?.join(':'))
                fd.append('stages',singleData?.stages?.join(':'))
                fd.append('job_type',singleData?.job_type)
                fd.append('employment_type',singleData?.employment_type)
                fd.append('closed',singleData?.closed ? 1 : 0)
                fd.append('website_visible',singleData?.website_visible ? 1 : 0)
                fd.append('stage',singleData?.stage?.value !== undefined ? singleData?.stage?.value : singleData?.stage)

                singleData?.jd_handeled_by?.forEach((e,i)=>{
                    fd.append(`jd_handeled_by[${i}]`,e.value)
                }) 

                if(singleData?.image?.name !== undefined){
                   fd.append('image',singleData?.image)
                }
                
                if(path !== 'edit'){
                    const response = await CreateAtsJobService(fd)
                    if(response.status === 200){
                        setloader(false)
                        toast.success('Job Created Successfully')
                        resetform() 
                    }
                }else{
                    fd.append('_id',state.data._id)
                    const response = await UpdateAtsJobService(fd,state.data._id)
                    if(response.status === 200){
                        setloader(false)
                        toast.success('Job Updated Successfully')
                        resetform() 
                    }
                }    
              
    }  
    
  }

  async function getdepartment(){
    const response = await GetDepartmentService()
    let d = response?.data?.datas.find((f)=>f.department_name === 'HR Team')
    getusersbydepartment(d?.id)
  }

  async function getusersbydepartment(id){
     const response = await GetUsersByDepartment(id)
     let d = response?.data?.datas
     let arr = []
     d.forEach((d1)=>{
         arr?.push({label:d1?.name,value:d1?._id})   
     })
     setemployee(arr)
  }

  function resetform(){
        setsingleData({image:'',stage:{label:'Pending',value:'Pending'},job_title:'',job_description:'',skills:'',cutoff_salary:'',ats_hr_client:'',no_of_opening:'',location:[],stages:[],experience:'',job_type:'',employment_type:'',notice_period:'',closed:false,website_visible:false})
        seterror({image:'',job_title:'',job_description:'',ats_hr_client:'',no_of_opening:'',location:'',stages:'',experience:'',notice_period:'',closed:'',skills:'',website_visible:''})
        if(path === 'edit'){
            navigator(-1)
        }
       
  }

  function handlechange(e){
        setsingleData({...singleData,[e.target.name] : e.target.value})
        seterror({...error,[e.target.name] : ''})
  }

  async function searchClient(e){
     const response = await FilterAtsClientService({text:e,from_date:'',to_date:'',page:1})
     let arr = []
     let d = response?.data?.datas 
     d?.forEach((d1)=>{
        arr?.push({label:`[${d1?.company_name} : Client : ${d1?.client_name}]`,value:d1?._id})
     })
     setclients(arr)
  }

//   console.log("e here",singleData?.jd_handeled_by,"employee",employee)

  async function handlechangeselect(e){
  
    let arr = []

    // console.log("e here",e,"employee",employee)
    e.forEach((d)=>{
       arr.push(employee.find((e)=>e.value === d))
    })
    setsingleData({...singleData,jd_handeled_by:arr})
  }



  



  return (
    <div className='flex min-h-screen max-h-screen overflow-hidden h-screen '>
    <AtsMenu />
    <div className='w-[45%] mt-5 pl-5 min-h-screen max-h-screen h-screen overflow-y-screen overflow-x-hidden' >
            <GoBack />
            
            <div className='border-b pb-2'>
                <h6 className='text-[13px] mb-1 font-[800]'>Add / Edit Job Description</h6>
                <h6 className='text-[12px] bg-slate-200 p-2'>Use the below form to create or update the job description added based on the client requirment.</h6>
           </div>

            
            <div className='mt-2 flex '>
            
            <div className='mr-4 w-[45%]'>
            <h6 className='text-[11px] font-[600] mb-1' >Search Client</h6>    

                <Select 
                    value={singleData?.ats_hr_client}
                    showSearch
                    filterOption={false}
                    bordered={false}
                    className='border border-l-4 border-l-slate-600 border-slate-300 w-[100%]'
                    onSearch={searchClient}
                    options={clients}
                    onChange={(e)=>{setsingleData({...singleData,ats_hr_client:clients?.find((v)=>v.value === e)});seterror({...error,ats_hr_client:''})}}
                    />

                    {error?.ats_hr_client !== '' && error?.ats_hr_client !== undefined &&
                    <div className='flex items-center mt-1'>
                    <BiErrorCircle className='text-red-500' size={14} />
                    <span className='text-[10px] text-red-500 ml-1'>{error?.ats_hr_client}</span>
                    </div>}   

            <h6 className='text-[11px] font-[600] mb-1 mt-2' >Job Stage</h6>    
            <Select 
                    value={singleData?.stage}
                    defaultValue={singleData?.stage}
                    bordered={false}
                    className='border  border-slate-300 w-[100%]'
                    options={[{label:'Pending',value:'Pending'},{label:'Closed',value:'Closed'},{label:'Lost',value:'Lost'}]}
                    onChange={(e)=>setsingleData({...singleData,stage:[{label:'Pending',value:'Pending'},{label:'Closed',value:'Closed'},{label:'Lost',value:'Lost'}].find((v)=>v.value == e)})}
                    />

            <h6 className='text-[11px] font-[600] mb-1 mt-2' >Job Attachment</h6>    
            <Uploader image={singleData?.image}  setimagefunc={(e)=>{setsingleData({...singleData,image:e});seterror({...error,image:''})}}  removeimageuploadfunc = {()=>{setsingleData({...singleData,image:''});seterror({...error,image:''})}}/>    


            {error?.image !== '' && error?.image !== undefined &&
                    <div className='flex items-center mt-1'>
                    <BiErrorCircle className='text-red-500' size={14} />
                    <span className='text-[10px] text-red-500 ml-1'>{error?.image}</span>
                    </div>}   

            <TextInput 
              mandatory={true}
              label={'Job Title'}  
              variant="standard"
              name="job_title"
              type="text"
              error={error?.job_title}
              value={singleData?.job_title}
              handlechange={handlechange}
              placeholder="Enter contact name"
              InputLabelProps={{
                  style: { color: '#fff', }, 
              }}/>

            <TextAreaInput1 
              label={'Job Description'}  
              variant="standard"
              name="job_description"
              type="text"
              value={singleData?.job_description}
              handlechange={handlechange}
              InputLabelProps={{
                  style: { color: '#fff', }, 
              }}/>

            <TextAreaInput1 
              label={'Skills'}  
              variant="standard"
              name="skills"
              type="text"
              value={singleData?.skills}
              handlechange={handlechange}
              InputLabelProps={{
                  style: { color: '#fff', }, 
              }}/>


            


            


           

            </div>
        
            

            <div className='w-[45%] -mt-2 ml-4'>

            <TextInput
              label={'Cutoff Salary '}  
              variant="standard"
              name="cutoff_salary"
              type="text"
              mandatory={true}
              error={error?.cutoff_salary}
              value={singleData?.cutoff_salary}
              handlechange={handlechange}
              InputLabelProps={{
                  style: { color: '#fff', }, 
              }}/>
            


            <div className='flex'>
            <div className='mr-4'>
            <TextInput 
              mandatory={true}
              label={'No of Opening'}  
              variant="standard"
              name="no_of_opening"
              type="text"
              error={error?.no_of_opening}
              value={singleData?.no_of_opening}
              handlechange={handlechange}
              placeholder="Enter contact name"
              InputLabelProps={{
                  style: { color: '#fff', }, 
              }}/>
            </div>
            <div className='mr-4'>
            <TextInput 
              mandatory={true}
              label={'Experience'}  
              variant="standard"
              name="experience"
              type="text"
              error={error?.experience}
              value={singleData?.experience}
              handlechange={handlechange}
              placeholder="Enter contact name"
              InputLabelProps={{
                  style: { color: '#fff', }, 
              }}/>  
            </div>
            <TextInput 
              mandatory={true}
              label={'Notice Period'}  
              variant="standard"
              name="notice_period"
              type="text"
              error={error?.notice_period}
              value={singleData?.notice_period}
              handlechange={handlechange}
              placeholder="Enter contact name"
              InputLabelProps={{
                  style: { color: '#fff', }, 
              }}/>  

            </div>  
            <h6 className='text-[11px] font-[600] mb-1 mt-2' >Job Location</h6>    

<Select
    className='border border-l-4 border-slate-300 border-l-slate-600 w-[100%]'
    mode='multiple'
    bordered={false}
    value={singleData?.location}
    onChange={e=>{setsingleData({...singleData,location:e});seterror({...error,location:''})}}
    dropdownRender={(menu) => (
        <>
        {menu}
        <Divider style={{ margin: '8px 0' }} />
        <div  className='flex items-center'>
            <div className='w-[90%] mr-2'>
            <TextInput 
            mandatory={false}
            label={''}  
            variant="standard"
            name="NAME"
            type="text"
            value={name}
            handlechange={(e)=>setname(e.target.value)}
            placeholder="Enter contact name"
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>  
            </div>
        
            <AiOutlinePlus size={24} onClick={()=>addItem("1")} className='bg-slate-600 p-[5px] rounded text-white' />
        </div>
        </>
    )}
    options={location.map((item) => ({ label: item, value: item }))}
    />

{error?.location !== '' && error?.location !== undefined &&
                    <div className='flex items-center mt-1'>
                    <BiErrorCircle className='text-red-500' size={14} />
                    <span className='text-[10px] text-red-500 ml-1'>{error?.location}</span>
                    </div>}  
{/* 
<h6 className='text-[11px] font-[600] mb-1 mt-2' >Job Stages</h6>    

<Select
    className='border border-slate-300 w-[100%]'
    mode='multiple'
    bordered={false}
    value={singleData?.stages}
    onChange={e=>setsingleData({...singleData,stages:e})}
    dropdownRender={(menu) => (
        <>
        {menu}
        <Divider style={{ margin: '8px 0' }} />
        <div  className='flex items-center'>
            <div className='w-[90%] mr-2'>
            <TextInput 
            mandatory={false}
            label={''}  
            variant="standard"
            name="NAME"
            type="text"
            value={name1}
            handlechange={(e)=>setname1(e.target.value)}
            placeholder="Enter contact name"
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>  
            </div>
        
            <AiOutlinePlus size={24} onClick={()=>addItem("2")} className='bg-slate-600 p-[5px] rounded text-white' />
        </div>
        </>
    )}
    options={stages.map((item) => ({ label: item, value: item }))}
    />   */}

    <h6 className='text-[11px] font-[600] mb-1 mt-2' >Job Type</h6>    

    <Select
        className='border border-l-4 border-slate-300 border-l-slate-600 w-[100%]'
        bordered={false}
        value={singleData?.job_type}
        onChange={e=>{setsingleData({...singleData,job_type:e});seterror({...error,job_type:''})}}           
        options={[{label:'IT',value:'IT'},{label:'NON IT',value:'NON IT'}]}
        />  

{error?.job_type !== '' && error?.job_type !== undefined &&
                    <div className='flex items-center mt-1'>
                    <BiErrorCircle className='text-red-500' size={14} />
                    <span className='text-[10px] text-red-500 ml-1'>{error?.job_type}</span>
                    </div>}  
        <h6 className='text-[11px] font-[600] mb-1 mt-2' >Employment Type</h6>    

        <Select
            className='border border-l-4 border-slate-300 border-l-slate-600 w-[100%]'
            bordered={false}
            value={singleData?.employment_type}
            onChange={e=>{setsingleData({...singleData,employment_type:e});seterror({...error,employment_type:''})}}           
            options={[{label:'FULL TIME',value:'FULL TIME'},{label:'PART TIME',value:'PART TIME'},{label:'FREELANCING',value:'FREELANCING'},]}
            />  

            {error?.employment_type !== '' && error?.employment_type !== undefined &&
            <div className='flex items-center mt-1'>
            <BiErrorCircle className='text-red-500' size={14} />
            <span className='text-[10px] text-red-500 ml-1'>{error?.employment_type}</span>
            </div>}  

            <h6 className='text-[11px] font-[600] mb-1 mt-2' >JD Handled By</h6>    

            <Select
            className='border border-l-4 border-slate-300 border-l-slate-600 w-[100%]'
            bordered={false}
            mode='multiple'
            value={singleData?.jd_handeled_by}
            onChange={handlechangeselect}           
            options={employee}
            />  

            <h6  onClick={()=>setsingleData({...singleData,website_visible:!singleData.website_visible})} className='cursor-pointer z-50 text-[12px] mt-2   font-[600] truncate border-r flex items-center '>
                {singleData?.website_visible ?
                <BiCheckboxSquare size={20} className='text-slate-700' />:
                <BiCheckbox size={20} className='text-gray-300' />} 

                <h6 className='text-[11px] ml-2 font-[600]'>Show In Website</h6>
            </h6>


            </div>

           
            </div>




            <div className='flex mt-2 border-t pt-2 w-full items-center '>
                <div className='mr-2'>
                <ButtonOutlinedAutoWidth btnName="Cancel" onClick={()=>navigator(-1)} />
                </div>
                <ButtonFilledAutoWidth btnName="Save" onClick={submitform} />
            </div>
           </div>  

    </div>
  )
}

export default AtsJobDescriptionCE