import React,{useState,useEffect} from 'react'
import DashboardMenu from './DashboardMenu'
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../components/button'
import {BsArrowRepeat} from 'react-icons/bs';
import {FiChevronRight,FiChevronLeft} from 'react-icons/fi';
import { IconButton } from '@material-ui/core'
import { DatePicker, Modal } from 'antd';
import { useNavigate } from 'react-router-dom';
import { DeleteReimbusmentService, GetFinanceReimbusmentService, GetHeadReimbusmentService, GetReimbusmentService } from '../../services/ReimbusmentServices';
import moment from 'moment';
import Priceconstants from '../../constants/imageConstants';
import { AiOutlineEdit,AiOutlineDelete } from 'react-icons/ai';
import toast from 'react-hot-toast';

function HeadReimbusmentlist() {

    const [data,setdata] = useState({})
    const [selectedData,setselectedData] = useState({})
    const [modal,setmodal] = useState(false)
    const [search,setsearch] = useState({from_date:'',from_date1:'',to_date:'',to_date1:''})
    const [page,setpage] = useState(1)
    const [step,setstep] = useState(1)
    const navigate = useNavigate()

    useEffect(()=>{
    getData()
    },[step,page])

    async function getData(){
    const response = await GetHeadReimbusmentService(page,step,search?.from_date1,search?.to_date1)
    setdata(response?.data?.datas)
    }

    async function resetfunc(){
    setpage(1)
    setsearch({from_date:'',from_date1:'',to_date:'',to_date1:''})
    const response = await GetHeadReimbusmentService(1,step,'','')
    setdata(response?.data?.datas)
    }

    async function applyfilterfunction(){
      setpage(1)
      const response = await GetHeadReimbusmentService(1,step,search?.from_date1,search?.to_date1)
      setdata(response?.data?.datas)

    }

    async function deleteDatafunc(){
    const response =  await DeleteReimbusmentService(selectedData?._id)
    if(response.status === 200){
        toast.success("Deleted Successfully")
        setmodal(false)
        getData()
    }
    }

    // console.log("data",data)


  return (
    <div className='flex max-h-screen min-h-screen'>
          <DashboardMenu />
          <div className='mx-5 w-[85%] mb-10'>
          <div className='w-[100%]   pt-2'>
            <div className='flex  items-center justify-between border-b pb-2'>
            <h6 className='font-[700] text-[14px] '>Total Reimbusment ({data?.pagination?.total})</h6>
            <div className='flex items-center'>
              <div className='flex items-center text-[12px] mr-2'>
             

                <h6 className='mr-2 font-[600]'>{page == 1 ? data?.datas?.length > 0 ? 1 : 0 : (page - 1) * data?.pagination?.limit } - {data?.pagination?.limit} of {data?.pagination?.total} </h6>
                <IconButton onClick={resetfunc}><BsArrowRepeat size={16} /></IconButton>

                <div>
                <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>
                <IconButton onClick={()=>{ page < data?.pagination?.totalPages  ? setpage(page+1) : console.log('')}}><FiChevronRight className={`${(data?.pagination?.totalPages === page || data?.datas?.length === 0)  ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>

                </div>
              </div>


              <DatePicker size='small' style={{fontSize:'11px'}} ampm={false} placeholder='From Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.from_date} onChange={(v,v1) => {console.log('v na',v,new Date(v).toLocaleDateString());setsearch({...search,from_date:v,from_date1:v1})}} /> 

              <DatePicker size='small' style={{fontSize:'11px'}} ampm={false} placeholder='To Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.to_date} onChange={(v,v1) => {console.log('v na',new Date(v).toLocaleDateString());setsearch({...search,to_date:v,to_date1:v1})}} /> 

            
                

          <ButtonOutlinedAutoWidth btnName="Add Filter" onClick={()=>applyfilterfunction(1)} /> 
         
         
            </div>
            </div>

            

            <div className='flex  items-center border-b'>
            <h6 onClick={()=>{setstep(1);setpage(1)}} className={`font-[600] ${step === 1 && 'bg-slate-600 text-white'} text-center cursor-pointer py-1 w-[90px] text-[11px]`}>Pending</h6>
            <h6 onClick={()=>{setstep(2);setpage(1)}} className={`font-[600] ${step === 2 && 'bg-slate-600 text-white'} text-center cursor-pointer py-1 w-[80px] text-[11px]`}>Approved</h6>
            <h6 onClick={()=>{setstep(3);setpage(1)}} className={`font-[600] ${step === 3 && 'bg-slate-600 text-white'} text-center cursor-pointer py-1 w-[80px] text-[11px]`}>Rejected</h6>
          </div>
            <div className='border-t border-l border-r mt-4'>
                <div className='flex border-b'>
                  <h6 className='text-[12px] py-1 px-2 border-r w-[70px] font-[600]'>Sl No</h6>
                  <h6 className='text-[12px] py-1 px-2 border-r w-[13%] font-[600]'>Request Date</h6>
                  <h6 className='text-[12px] py-1 px-2 border-r w-[10%] font-[600]'>Amount</h6>
                  <h6 className='text-[12px] py-1 px-2 border-r w-[10%] font-[600]'>User</h6>
                  <h6 className='text-[12px] py-1 px-2 border-r w-[15%] font-[600]'>Summary</h6>
                  <h6 className='text-[12px] py-1 px-2 border-r w-[14%] font-[600]'>Item Count</h6>
                  <h6 className='text-[12px] py-1 px-2 border-r w-[14%] font-[600]'>Payment Mode</h6>
                  <h6 className='text-[12px] py-1 px-2 border-r w-[15%] font-[600]'>Payment Date</h6>
                  <h6 className='text-[12px] py-1 px-2 w-[10%] font-[600]'>Action</h6>
                </div>

               {data?.datas?.map((d,i)=>(
                <div className='flex border-b'>
                  <h6 className='text-[11px] py-1 px-2 border-r w-[70px] font-[500]'>{(page > 1 ? i+1+ (25 * (page - 1)) : i+1 )}</h6>
                  <h6 className='text-[10px] py-1 px-2 border-r w-[13%] font-[700]'>{moment(d?.reimbusment?.createdAt)?.format('LL')}</h6>
                  <h6 className='text-[11px] py-1 px-2 border-r w-[10%] font-[700]'>{Priceconstants(d?.reimbusment?.amount)}</h6>
                  <h6 className='text-[11px] py-1 px-2 border-r w-[10%] font-[700]'>{d?.reimbusment?.user?.name}</h6>
                  <h6 className='text-[11px] py-1 px-2 border-r w-[15%] font-[700]'>{d?.reimbusment?.summary}</h6>
                 
                  <h6 className='text-[11px] py-1 px-2 border-r w-[14%] font-[700]'>{d?.reimbusment?.reimbursmentitems?.length}</h6>
                  <h6 className='text-[11px] py-1 px-2 border-r w-[14%] font-[500]'>{d?.reimbusment?.completed && <span className='bg-blue-400 font-[700] px-[10px] py-[2px]'>{d?.reimbusment?.payment}</span>}</h6>
                  <h6 className='text-[11px] py-1 px-2 border-r w-[15%] font-[500]'>{d?.reimbusment?.completed && moment(d?.reimbusment?.updatedAt)?.format('LLL')}</h6>
                  <h6 className='text-[11px] py-1 px-2 w-[10%] font-[500] flex'>
                    <AiOutlineEdit size={13} className='mr-2' onClick={()=>navigate('edit',{state:d})} /> 
                    {/* <AiOutlineDelete size={13} onClick={()=>{setselectedData(d);setmodal(true)}} />  */}
                  </h6>
                </div>
                ))}

            </div>
        </div>  
          </div>
    </div>
  )
}

export default HeadReimbusmentlist