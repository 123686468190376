import { IconButton, Tooltip } from '@mui/material'
import React,{useState,useEffect} from 'react'
import { useLocation,useNavigate } from 'react-router-dom'
import { GetLeadsService, SearchLeadService } from '../../services/LeadServices';
import {BsArrowRepeat} from 'react-icons/bs';
import {FiChevronRight,FiChevronLeft} from 'react-icons/fi';
import { RiTimeLine } from "react-icons/ri";
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../components/button';
import { DatePicker } from 'antd';
import DailyTask from './dailyTask/DailyTask';
import {AiOutlineEdit} from 'react-icons/ai';
import {RiBillLine} from 'react-icons/ri';
import moment from 'moment';

function Landlordlist() {

  const location = useLocation();
  const navigate = useNavigate();

  let path = location?.pathname?.split('/')[2]
  let path1 = location?.pathname?.split('/')[1]


  const [leads,setleads]  = useState([])
  const [type,settype] = useState('')
  const [page,setpage] = useState(1)
  const [modal,setmodal] = useState(false)
  // const [filter_drawer,setfilter_drawer] = useState(false)

  const [search,setsearch] = useState({text:'',from_date:'',to_date:'',activate:false})

  const list = ['landlords','landlord_invoice']
 
  
  useEffect(()=>{
    if(path1 === 'leads' && path === undefined){
      navigate('/leads/landlords')
    }
    
    if(list?.includes(path)){
      setpage(1)
      getleads(1)
    }
  },[location.pathname])


  useEffect(()=>{
    if(list?.includes(path)){
      if(search.activate){
        applyfilterfunction(page)
      }else{
        getleads(page)
      }
    }
  },[page])

  async function getleads(page){
      let type = ''
      if(path === 'landlords' ) type = 'Landlord'
      if(path === 'landlord_invoice' )  type = 'Landlord Invoice'
     
      settype(type)
      const response =  await GetLeadsService(type,page);
      setleads(response.data)
  }

 
  async function applyfilterfunction(page){
    setsearch({...search,activate:true})
    let start_date = new Date(search?.from_date).toLocaleDateString().replaceAll('/','-').split('-').reverse().join('-')
    let end_date = new Date(search?.to_date).toLocaleDateString().replaceAll('/','-').split('-').reverse().join('-')
    const response = await SearchLeadService({search_text:search.text,status:type,from_date:(search?.from_date !== ''  && search?.from_date !== null) ? start_date : '',to_date:(search?.to_date !== ''  && search?.to_date !== null) ? end_date : '',page:page})
    setleads(response.data)
  }

  async function resetfunc(){
    setsearch({text:'',from_date:'',to_date:'',activate:false})
    getleads(1)
  }


  

  
 


  return (
    <div className='h-screen max-h-screen w-[98%] '>


    

   
    
    {list?.includes(path) ? 
    <div  >
       <div  >
        <div className='flex items-center justify-between border-b pb-2'>
          <h6 className='font-[700] text-[14px] '>Total {type} ({leads?.pagination?.total})</h6>
          <div className='flex items-center'>
            <div className='flex items-center text-[12px] mr-2'>

              <h6 className='mr-2 font-[600]'>{page == 1 ? leads?.datas?.length > 0 ? 1 : 0 : (page - 1) * leads?.pagination?.limit } - {leads?.pagination?.limit} of {leads?.pagination?.total} </h6>
              <IconButton  onClick={resetfunc} ><BsArrowRepeat size={16} /></IconButton>

              <div>
              <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}} ><FiChevronLeft className={`${(page === 1 || leads?.datas?.length === 0) ? 'opacity-50' : 'opacity-100'}`} size={16} /></IconButton>
              <IconButton onClick={()=>{ page < leads?.pagination?.totalPages  ? setpage(page+1) : console.log('')}}><FiChevronRight className={`${(leads?.pagination?.totalPages === page || leads?.datas?.length === 0) ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>

              </div>
            </div>


            <DatePicker size='small' style={{fontSize:'11px'}} ampm={false} placeholder='From Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.from_date} onChange={(v) => {console.log('v na',new Date(v).toLocaleDateString());setsearch({...search,from_date:v})}} /> 

            <DatePicker size='small' style={{fontSize:'11px'}} ampm={false} placeholder='To Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.to_date} onChange={(v) => {console.log('v na',new Date(v).toLocaleDateString());setsearch({...search,to_date:v})}} /> 

          
          <div className='mr-2'>
            <input  id="search_text" placeholder='Search text' type='text' value={search.text} onChange={(e)=>setsearch({...search,text:e.target.value})} className='border py-[3.5px] focus:ring-0 focus:outline-0 text-[13px] pl-2  w-28 px-1 rounded-md border-slate-300' />
          </div>      

        <ButtonOutlinedAutoWidth btnName="Add Filter" onClick={()=>applyfilterfunction(1)} /> 
        <div className='ml-2'>
        <ButtonFilledAutoWidth btnName="Add Data" onClick={()=>navigate('/leads/landlords/create')}/> 
        </div>
          </div>
       </div>
       
       {leads?.datas?.length === 0 &&
        <div className='grid place-items-center mt-20  items-center justify-center'>
        <img src={'https://fidecrmfiles.s3.amazonaws.com/NoDataFound1.png'} className='w-40 h-40 object-contain' /> 
        <h6 className='font-bold text-[14px] -mt-10'>No data found</h6>
        <h6 className='font-[500] w-[70%] text-center text-slate-700 text-[12.5px] -mt-2'>Oops we couldn't find any data based on your current stage please add the data to see list here.</h6>
      </div>
       }

       
       {leads?.datas?.length > 0 &&
        <div className='grid grid-cols-5 gap-1 mt-4'>
            {leads?.datas?.map((l)=>(
            <div key={l?._id} className='relative border p-2'>
               {path !== 'landlord_invoice' && <AiOutlineEdit onClick={()=>navigate('/leads/landlords/edit',{state:l?._id})} size={13}  className='absolute right-7 top-1' />}
               {/* <Tooltip title="Meeting"><span className='absolute right-12 top-1'> <RiTimeLine size={13}  onClick={()=>setmodal(true)}  /></span></Tooltip> */}
               <Tooltip title="Detail"><span className='absolute right-1.5 top-1'> <RiBillLine size={13} onClick={()=>navigate('/leads/landlords/detail',{state:l?._id})}   /></span></Tooltip>
                <h6 className='font-[600] text-[12px]'>{l?.contact_name}</h6>
                <h6 className='text-[11px]'>Phone : <span className='font-[400]'>{l?.phone}</span> </h6>
                {(l?.email !== '' && l?.email !== undefined && l?.email !== null) && <h6 className='text-[11px]'>Email : {l?.email}</h6>}
                <h6 className='text-[11px]' >Land Info : <span className='font-[700]'> {l?.requirment}</span></h6>
                <h6 className='text-[11px] p-1 mt-1 bg-slate-100' >Created At : <span className='font-[700]'> {moment(l?.createdAt)?.format('LL')}</span></h6>
            </div>))}
        </div> 
        }
       </div>

       
     


    </div>
    :
    <div>
        <DailyTask />
        {/* <h1>Hello Im here</h1> */}
    </div>  

    }

    
   
    </div>
  )
}

export default Landlordlist