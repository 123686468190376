import { deleteRequest, get, post, postfd, put } from "../../helpers/apihelpers";

export const CreateAtsJobService=async(data)=>{
    try {
        const result = await postfd(`api/hr/jobs/create`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateAtsJobService=async(data,id)=>{
    try {
        const result = await postfd(`api/hr/jobs/update/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetAtsJobService=async({page,stage})=>{
    try {
        const result = await get(`api/hr/jobs/get?page=${page}&stage=${stage}`);
        return result;
    } catch (err) {
        return err.response;
    }
}




export const GetAtsActiveobService=async({page})=>{
    try {
        const result = await get(`api/hr/jobs/get_active_jobs?page=${page}`);
        return result;
    } catch (err) {
        return err.response;
    }
}



export const FilterAtsCandidateSharedService=async({page,from_date,to_date})=>{
    try {
        const result = await get(`api/hr/jobs/filter_candidate_shared?from_date=${from_date}&to_date=${to_date}&page=${page}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const FilterAtsActiveobService=async({page,text})=>{
    try {
        const result = await get(`api/hr/jobs/filter_active_jobs?page=${page}&search_text=${text}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetAtsJobBasedEmployeeService=async(id,page)=>{
    try {
        const result = await get(`api/hr/jobs/get_employee_based_client/${id}?page=${page}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DownloadAtsJobExcelbBasedEmployeeService=async(id,key)=>{
    try {
        const result = await get(`api/hr/jobs/download_excel_employee_shared_to_client/${id}?keys=${key}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetAtsJobBasedEmployeeTrackerService=async(id)=>{
    try {
        const result = await get(`api/hr/jobs/get_employee_tracker_based_job_employee/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const CreateAtsJobBasedEmployeeTrackerService=async(body)=>{
    try {
        const result = await post(`api/hr/jobs/create_tracker_update_job_employee`,body);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteAtsJobBasedEmployeeService=async(id,body)=>{
    try {
        const result = await put(`api/hr/jobs/delete_employee_based_client/${id}`,body);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetAtsJobDetailService=async(id)=>{
    try {
        const result = await get(`api/hr/jobs/get/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const FilterAtsJobService=async({text,from_date,to_date,page})=>{
    try {
        const result = await get(`api/hr/jobs/filter?search_text=${text}&from_date=${from_date}&to_date=${to_date}&page=${page}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteAtsJobService=async(id)=>{
    try {
        const result = await deleteRequest(`api/hr/jobs/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetAtsJobListBasedStageService=async({page,stage})=>{
    try {
        const result = await get(`api/hr/jobs/get_employee_based_on_stage?stage=${stage}&page=${page}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetAtsJobCandidateAssignedStageService=async({page,from_date,to_date})=>{
    try {
        const result = await get(`api/hr/dashboard/get_today_candidate_shared_admin?from_date=${from_date}&to_date=${to_date}&page=${page}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetAtsJobCandidateGroupStageService=async(id)=>{
    try {
        const result = await get(`api/hr/jobs/get_employee_based_client_stage_group/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

