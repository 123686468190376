import React from "react";
import { Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { useSelector } from "react-redux";
// import { CheckJwtExpiry } from "./CheckJwtExpiry";

const ProtectedRoute = ({ children, access,roles }) => {

  const state = useSelector(state=>state.Auth)

  
  
 

  let isValid = true;
    if(roles?.length > 0){
      isValid = state.token !== '' && state.isAuthenticated  && roles.filter(e=>state?.roles?.includes(e)).length > 0
    }else{
      isValid = state.token !== '' && state.isAuthenticated 
    }


  if (!isValid) {
    // not logged in so redirect to login page with the return url
    return <Navigate to="/login" state={{ from: "history.location" }} />;
  }

  return children;
};

const mapStateToProps = (state) => {
  const {access} = state.Auth
  return {
    access
  }
}

export default connect(mapStateToProps) (ProtectedRoute)