import { div } from '@mui/system'
import React,{useEffect, useState} from 'react'
import { toast } from 'react-hot-toast'
import { ButtonFilled, ButtonFilledAutoWidth } from '../../../components/button'
import { TextInput } from '../../../components/input'
import { CreateDepartmentService, UpdateDepartmentService } from '../../../services/DepartmentServices'
import { useNavigate, useLocation } from 'react-router-dom'
import { MobileBackOption } from '../../../components/appbars/mobile/DashboardMobile'
import SettingsMenu from '../../staticscreens/SettingsMenu'
import GoBack from '../../../components/back/GoBack'

function DepartmentCE() {
 
  const [data,setdata] = useState({department_name:''});  
  const [error,seterror] = useState({department_name:''}); 
  
  const {state} = useLocation();
  const navigate = useNavigate();


  useEffect(()=>{
    if(state?.id !== null && state?.id !== undefined){
      setdata({...data,department_name:state.department_name})
    }

  },[state])

  function handlechange(e){
    setdata({...data,[e.target.name] : e.target.value})
    seterror({...error,[e.target.name]:''})
  }

  async function submitform(){
    if(!data.department_name){
        seterror({...error,department_name:'Department Name field is required *'})
    }else{
        if(state?.id === undefined || state?.id === null){
        const response = await CreateDepartmentService(data)
        if (response.status === 201) {
          setdata({...data,department_name:''})
          seterror({...error,department_name:''})
          toast.success('Department Created Successfully')
        }   
        if(response.status === 422){
          seterror({...error,department_name:response?.data?.errors?.department_name})
        } 
      }else{
        let send_data = {...data}
        send_data["id"] = state?.id
        const response = await UpdateDepartmentService(send_data,state?.id)
        if (response.status === 200) {
          setdata({...data,department_name:''})
          seterror({...error,department_name:''})
          navigate(-1)
          toast.success('Department Updated Successfully')
        }   
        if(response.status === 422){
          seterror({...error,department_name:response?.data?.errors?.department_name})
        } 
      }
  }
  }

  

  return (
    <div className='flex '>


      <SettingsMenu />
   
    <div className='sm:w-full lg:w-72 px-4 pt-5' >


        <GoBack /> 

        <h6 className='font-[700]'>{(state?.id !== null && state?.id !== undefined) ? 'Edit' : 'Add'} Department</h6>
        <h6 className='text-[10px] bg-slate-100 p-2 font-[500] leading-snug' >Use the below form to create or edit the <b> Department</b> for your comapny employees.</h6>
     

        <TextInput 
            label={'Department Name'}  
            variant="standard"
            name="department_name"
            type="text"
            error={error.department_name}
            value={data.department_name}
            handlechange={handlechange}
            placeholder="Enter your Department name"
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>

        <div className='mt-5'>
        <ButtonFilledAutoWidth btnName={(state?.id !== null && state?.id !== undefined) ? "UPDATE DEPARTMENT" : "ADD DEPARTMENT"}  onClick={submitform} />  
        </div>


    </div>
    </div>
  )
}

export default DepartmentCE