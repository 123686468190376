import React,{useState,useEffect} from 'react'
import { Tooltip } from '@mui/material';
import {  ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../../../components/button';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import {AiOutlineEdit,AiOutlineDelete} from 'react-icons/ai'
import { Modal } from 'antd';
import moment from 'moment';
import WebsiteMenu from '../../WebsiteMenu';
import {
  IconButton,
} from "@mui/material";
import {BsArrowRepeat} from 'react-icons/bs';
import {FiChevronRight,FiChevronLeft} from 'react-icons/fi';
import { DeleteCorpWebsiteBlogService, GetCorpWebsiteBlogService } from '../../../../services/Website/CorpWebsiteOption/CorpBlogServices';


function CORPBlogList() {


    const [data,setdata] = useState([])
    const [selecteddata,setselecteddata] = useState({})
    const [modal, setModal] = useState(false);
    const [page, setpage] = useState(1);
    const navigate = useNavigate()


  
    useEffect(()=>{
        getdata()
    },[page])

   

  
  async function getdata(){
   const response = await GetCorpWebsiteBlogService(page,'',)
   setdata(response.data)
  }  


  async function deleteRole(){
    const response = await DeleteCorpWebsiteBlogService(selecteddata.id)
    if(response.status === 200){
        setModal(false)
        toast.success('Blog Deleted Successfully')
        getdata()
    }
  }

  async function resetfilter(){
    setpage(1)
  }

  
  return (
    <div className='h-screen max-h-screen box-border overflow-hidden'>
   
    <Modal
       keepMounted
       open={modal}
       onClose={()=>setModal(false)}
       width={300}
       footer={false}
       closable={false}
      
     >
       <div >
         <h6 className="font-bold text-[15px] text-center mb-2 w-full">Are you sure?</h6>
         <h6 className='bg-slate-100 text-center text-[12px] p-1.5 font-[400]'>After deleting you cannot retrieve it back before deleting check once whether you have used it in some data</h6>
         <div className='flex justify-end mt-3 '>
           {/* <div className='mr-1 w-full'> */}
           <ButtonOutlinedAutoWidth btnName={'Cancel'} onClick={()=>setModal(false)} />
           {/* </div> */}
           <div  className='ml-2'>
           <ButtonFilledAutoWidth btnName={'Confirm'} onClick={()=>deleteRole()}  /> 
           </div>
         </div>
       </div>
     </Modal>
       
     <div className='block sm:flex'>
       <WebsiteMenu />

       <div className='w-[88%] px-4'>
       <div >
       <div className='pt-5'>
       <div className="flex justify-between align-center items-center border-b pb-2 ">
       <span className="font-black text-[14px]">CORP Blogs ({data?.pagination?.total})</span>

       <div className='flex'>
        <div className='flex items-center  text-[12px] mr-2'>
            <h6 className='mr-2 font-[600]'>{page === 1 ? data?.datas?.length > 0 ? 1 : 0 : (page - 1) * data?.pagination?.limit } - {data?.pagination?.limit} of {data?.pagination?.total} </h6>
            <IconButton  onClick={resetfilter} ><BsArrowRepeat size={16} /></IconButton>
            <div>
            <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>
            <IconButton onClick={()=>{ page < data?.pagination?.totalPages  ? setpage(page+1) : console.log('')}} ><FiChevronRight className={`${(data?.pagination?.totalPages === page || data?.datas?.length === 0) ? 'opacity-50' : 'opacity-100'}`} size={16} /></IconButton>
            </div>
        </div>
       
        <ButtonFilledAutoWidth  btnName="Add Blogs" onClick={()=>navigate('create')} /> 
        </div>
       </div>
       </div>

       {data?.datas?.length === 0 &&
        <div className='grid place-items-center mt-20  items-center justify-center'>
        <img src={'https://fidecrmfiles.s3.amazonaws.com/NoDataFound1.png'} className='w-40 h-40 object-contain' /> 
        <h6 className='font-bold text-[14px] -mt-10'>No data found</h6>
        <h6 className='font-[500] w-[70%] text-center text-slate-700 text-[12.5px] -mt-2'>Oops we couldn't find any data added based on your current page option please click on add the data button to see list here.</h6>
      </div>
       }



      <div className='h-[88vh] overflow-y-scroll'>
       {data?.datas?.length > 0 &&
       <div className='grid grid-cols-6 gap-1 mt-2'>
           {data?.datas?.map((d,i)=>(
           <div key={i}  className='border items-center relative justify-between px-2 py-1 border-b'>
               <img src={`${process.env.REACT_APP_AWS_IMAGE_URL}${d?.blog_image}`} className='border object-cover w-full h-[80px] border-slate-100' />
               <h6 className='text-[12px] mt-[1px] line-clamp-2 font-[500] capitalize '>{d?.title}</h6>
               <h6 className='text-[10px] font-[500] bg-slate-100 mt-1 p-1'>Created At : <span className='text-[10px] font-[800]'>{moment(d.createdAt).format('LLL')}</span> </h6>
               <div className='absolute bg-white right-0 p-1 border-l border-b border-slate-100 bg-white top-0 flex'>
               <Tooltip title="Edit" >
                <span><AiOutlineEdit size={12} className="cursor-pointer" onClick={()=>{navigate('edit',{state:d})}}/></span>
               </Tooltip>
               <Tooltip title="Delete">
                <span><AiOutlineDelete size={12} className='ml-2 cursor-pointer'  onClick={()=>{setselecteddata({department_name:d.department_name,id:d._id});setModal(true)}}/></span>
               </Tooltip>
               </div>
           </div>
           ))}
       </div>}
       </div>
         </div>
       </div>
     </div> 
   </div>
  )
}

export default CORPBlogList