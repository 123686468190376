import React,{useState,useEffect} from 'react'
import { GetInvoiceServiceDetailBasedOnClient, ReviseInvoiceUserRequest, UpdatePaymentInvoiceFinance } from '../../services/InvoiceServices'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-hot-toast'
import GoBack from '../../components/back/GoBack'
import {BsFillBookmarkCheckFill} from 'react-icons/bs'
import fileDownload from "js-file-download";
import axios from 'axios'
import moment from 'moment'
import { DatePicker, Input, Modal } from 'antd'
import {BiCheckbox,BiCheckboxSquare,BiErrorCircle} from 'react-icons/bi'
import {IoClose} from 'react-icons/io5'
import { AiOutlineDownload } from 'react-icons/ai'
import { BiDotsVerticalRounded } from 'react-icons/bi'
import { ButtonFilledAutoWidth, ButtonOutlined, ButtonOutlinedAutoWidth } from '../../components/button'
import { TextInput } from '../../components/input'
import { Textarea } from '@material-tailwind/react'

function LeadInvoice() {

  const {state} = useLocation()  
  const [data,setdata] = useState({})
  const [selectedData1,setselectedData1] = useState(null)
  const [selectedData,setselectedData] = useState(null)
  const [selectedDataErr,setselectedDataErr] = useState(null)
  const [addPayment,setaddPayment] = useState({payment_recieved:false,complete_payment_recieved:false,amount_with_gst:'',amount_without_gst:'',recieved_date:''})
  const [reviseInvoice,setreviseInvoice] = useState({revise:false,revise_note:''})
  const [reviseInvoiceErr,setreviseInvoiceErr] = useState({revise:false,revise_note:''})
  const [addPaymentErr,setaddPaymentErr] = useState({payment_recieved:false,complete_payment_recieved:false,amount_with_gst:'',amount_without_gst:'',recieved_date:''})
  const [paymentDate,setpaymentDate] = useState(null)
 


  useEffect(()=>{
    getdata()
  },[])  
  
  const getdata = async() => {
    const response = await GetInvoiceServiceDetailBasedOnClient(state?._id)
    setdata(response?.data?.datas[0])
  }  

  let rupee = new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
  });


  const handleDownload = (url, filename) => {
        axios.get(url, {
            responseType: 'blob',
        })
            .then((res) => {
                fileDownload(res.data, filename)
            })
    }

  const reviseInvoiceRequest = async () => {
    if(!selectedData.invoice_revise_summary){
      setselectedDataErr({...selectedDataErr,invoice_revise_summary:'The Invoice Revise Summary Field is required'})
    }else{
     delete selectedData?._id
     selectedData['invoice_revise'] = true
     const response = await ReviseInvoiceUserRequest(data?._id,selectedData)
     if(response.status === 200){
      toast.success('Invoice revise request raised')
      setselectedData(null)
      getdata()
     }
    } 
  }

  const updatepayment_Status = async (v) => {
    if(!addPayment?.amount_with_gst && v === '2'){
     setaddPaymentErr({...addPaymentErr,amount_with_gst:'This Field is required*'})
    }else if(!addPayment?.amount_without_gst && v === '2'){
     setaddPaymentErr({...addPaymentErr,amount_without_gst:'This Field is required*'})
    }else if(!addPayment?.recieved_date && v === '2'){
     setaddPaymentErr({...addPaymentErr,recieved_date:'This Field is required*'})
    }else{
     
      // console.log("v here",v)
      if(v === '2'){
         let send_data_modified= {
           amount_with_gst:addPayment?.amount_with_gst,
           amount_without_gst:addPayment?.amount_without_gst,
           recieved_date:addPayment?.recieved_date,
           invoice:data?._id,
           client:data?.client?._id,
           payment_recieved:true,
         }

         console.log("send_data_modified",send_data_modified)


         if(addPayment?.complete_payment_recieved){
           send_data_modified['complete_payment_recieved'] = addPayment?.complete_payment_recieved
         }
         
         const response = await UpdatePaymentInvoiceFinance(send_data_modified,data?._id)
         if(response.status === 200){
           setaddPayment({payment_recieved:false,complete_payment_recieved:false,amount_with_gst:'',amount_without_gst:'',recieved_date:''})
           setaddPaymentErr({payment_recieved:false,complete_payment_recieved:false,amount_with_gst:'',amount_without_gst:'',recieved_date:''})
           toast.success(response?.data?.data)
           setpaymentDate('')
           getdata()
         }
      }else{
         let send_data= {
           amount_recieved_date:paymentDate,
           amount_recieved_finalized:false,
           payment_recieved:false,
           finalized:false
         }
         const response = await UpdatePaymentInvoiceFinance(send_data,state?._id)
         if(response.status === 200){
         toast.success(response?.data?.datas)
         setpaymentDate('')
         getdata()
         }
      }
    
    }
 }

  
  console.log("selectedData",selectedData)
  

  return (
    <div className='p-5'>
        <div className='mt-10'>
            <GoBack />
            <h6 className='font-black text-[14px] border-b pb-2'>Invoice Request Detail</h6>
            
            <div className='mt-2 flex'>
                <div className='w-60 pr-4 border-r'>
                    <h6 className='text-[13px] font-[600] mb-2 '>Client Info: </h6>
                    <div className='bg-gray-100 p-2'>
                    <h6 className='text-[12px] font-[500]'>{data?.client?.contact_name} / {data?.client?.phone}</h6>
                    <h6 className='text-[12px] font-[500]'>{data?.client?.email}</h6>
                    <h6 className='text-[12px] font-[500]'>Company Name : {data?.client?.company_name}</h6>
                    <h6 className='text-[12px] font-[500]'>Address : {data?.client?.address}</h6>
                    </div>
                    <h6 className='text-[12px] font-[500]  mt-2'>Requirment : {data?.client?.requirment}</h6>
                    {(data?.sqft || data?.seater || data?.rent || data?.sale) && 
                    <h6 className='text-[12px] font-[500]'>{data?.sqft && 'SQFT'} {data?.seater && 'SEATER'} , {data?.rent && 'RENT'} {data?.sale && 'SALE'} </h6>}

                    <h6 className='text-[13px] font-[600] mt-4 '>Invoice Info: </h6>
                    <h6 className='text-[12px] font-[500]'>Amount : {rupee.format(data?.amount)}</h6>
                    {(data?.other_details !== '' && data?.other_details !== undefined) && <h6 className='text-[12px] font-[500]'>Other Details : {data?.other_details}</h6>}

                    <h6 className='text-[13px] font-[600] mt-4 '>Payment Info: </h6>
                    {!data?.payment_recieved  ? <h6 className='text-[12px] font-[500]'>Amount Not recieved yet from the client</h6> :
                    <h6 className='text-[12px] font-[500]'>Payment Recieved On  : <span className='font-[700]'> {moment(data?.amount_recieved_date)?.format('LL')} </span></h6>}
                </div>

               {data?.invoicepayments?.length > 0 &&
                <div className='ml-4 w-60 border-r pr-4'>
                <h6 className='text-[13px] font-[600] mb-2 '>Payment History  ({data?.invoicepayments?.length}): </h6>
                {data?.invoicepayments?.map((d,i)=>(
                <div className={`border-b relative py-2 ${i === 0 && 'border-t'}`}>
                      <h6 className='text-[10px] ml-1 mt-1'>Amount With GST : <span className='font-[800]'>{d?.amount_with_gst}</span></h6>
                        <h6 className='text-[10px] ml-1'>Amount Without GST : <span className='font-[500]'>{d?.amount_without_gst}</span></h6>
                        <h6 className='text-[10px] ml-1'>Amount Recieved Date : <span className='font-[500]'>{moment(d?.recieved_date).format('LLL')}</span></h6>
                        <h6 className='text-[10px] ml-1'>Created On : <span className='font-[800]'>{moment(d?.createdAt).format('LLL')}</span></h6>
                        {!d.is_deleted && <h6 className={`text-[10px] ml-1 font-[800] mt-1 ${d?.finance_approved ? 'bg-green-100' : 'bg-blue-100'} p-1`}>Approval Status : {d?.finance_approved ? 'Finance Approved' : 'Waiting For Finance Approval'}</h6>}
                        {d.is_deleted && <h6 className={`text-[10px] ml-1 font-[800] mt-1  bg-red-100 p-1`}>Deleted Reason : {d?.deleted_reason}</h6>}
                </div>))} 
                </div>}

                {data?.invoice_request?.length > 0 &&
                <div className='ml-4 min-w-[240px] max-w-80 border-r pr-4'>
                <h6 className='text-[13px] font-[600] mb-2 '>Invoice History ({data?.invoice_request?.length}): </h6>
                    {/* <Uploader image={file} /> */}
 
                    {data?.invoice_request?.map((d1,i)=>(
                    <div className={`border-b w-60 relative py-2 ${i === 0 && 'border-t'}`}>

                        <div className='flex '>
                          <img src="https://cdn-icons-png.flaticon.com/512/179/179483.png" className='w-6 h-6 object-contain z-0' />
                          <h6 className='text-[9px] ml-1 w-[170px]  overflow-hidden line-clamp-2'>{d1?.file?.split('/')[d1?.file?.split('/')?.length - 1]}</h6>
                          <BiDotsVerticalRounded size={16} className='cursor-pointer absolute right-6 bg-white' onClick={()=>{setselectedData(d1);setselectedData1(d1)}} />
                          <AiOutlineDownload size={18} className='cursor-pointer absolute right-0 bg-white' onClick={()=>handleDownload(`${process.env.REACT_APP_BACKEND_IMAGE_URL}${d1?.file}`,d1?.file?.split('/')[d1?.file?.split('/')?.length - 1])} />
                        </div>  
                        {data?.invoice_revise_summary !== '' && data?.invoice_revise_summary !== undefined && <h6 className='text-[10px] ml-1 mt-1 bg-red-100 p-1 w-full'>Invoice Revise Request : <span className='font-[700] text-[11px]'>{data?.invoice_revise_summary}</span></h6>}
                        <h6 className='text-[10px] ml-1 mt-1'>Invoice No : <span className='font-[800]'>{d1?.invoice_no}</span></h6>
                        <h6 className='text-[10px] ml-1'>Description : <span className='font-[500]'>{d1?.description}</span></h6>
                        <h6 className='text-[10px] ml-1'>Created On : <span className='font-[800]'>{moment(d1?.createdAt).format('LLL')}</span></h6>
                        {d1?.final && <BsFillBookmarkCheckFill className='absolute top-0 left-0 z-100' />}
                    </div>))}
                </div>}
                {selectedData !== null &&
                <div className='border-l   px-4 -mt-5 h-screen pt-12 bg-white min-w-[300px]  max-w-[300px] absolute top-0 right-0'>
                <h1 className='text-[14px] mt-2 pb-2 font-[600] border-b'>Invoice Detail </h1>
                <IoClose className='absolute right-5 top-8 cursor-pointer' onClick={()=>setselectedData(null)} />
                <div className={` relative py-2 border-t}`}>
  
                          <div className='flex '>
                            <img src="https://cdn-icons-png.flaticon.com/512/179/179483.png" className='w-6 h-6 object-contain z-0' />
                            <h6 className='text-[10px] ml-1   overflow-hidden line-clamp-2'>{selectedData?.file?.split('/')[selectedData?.file?.split('/')?.length - 1]}</h6>
                          </div>  
                          <h6 className='text-[11px] ml-1 mt-1'>Invoice No : <span className='font-[800]'>{selectedData?.invoice_no}</span></h6>
                          <h6 className='text-[11px] ml-1'>Description : <span className='font-[500]'>{selectedData?.description}</span></h6>
                          <h6 className='text-[11px] ml-1'>Created On : <span className='font-[800]'>{moment(selectedData?.createdAt).format('LLL')}</span></h6>
                          {selectedData?.final && <BsFillBookmarkCheckFill className='absolute top-0 left-0 z-100' />}
                          
                          {!data.invoice_revise && 
                          <>
                            <div className='flex items-center cursor-pointer mt-2'>
                              {reviseInvoice?.revise ? <BiCheckboxSquare size={20} className='text-cyan-600' onClick={()=>setreviseInvoice({...reviseInvoice,revise:!reviseInvoice.revise})} />  : <BiCheckbox size={20} className='text-gray-200'  onClick={()=>setreviseInvoice({...reviseInvoice,revise:!reviseInvoice.revise})} />}
                              <h6 className='ml-1 text-[11px] font-[500]'>Revise Invoice</h6>
                            </div>

                            <div className='flex items-center'>
                            <div className='flex items-center cursor-pointer mt-2'>
                              {addPayment?.payment_recieved ? <BiCheckboxSquare size={20} className='text-cyan-600' onClick={()=>setaddPayment({...addPayment,payment_recieved:!addPayment.payment_recieved})} />  : <BiCheckbox size={20} className='text-gray-200' onClick={()=>setaddPayment({...addPayment,payment_recieved:!addPayment.payment_recieved})} />}
                              <h6 className='ml-1 text-[11px] font-[500]'>Payment Recieved</h6>
                            </div>
  
                            <div className='flex items-center cursor-pointer mt-2 ml-4'>
                              {addPayment?.complete_payment_recieved ? <BiCheckboxSquare size={20} className='text-cyan-600' onClick={()=>setaddPayment({...addPayment,complete_payment_recieved:!addPayment.complete_payment_recieved})} />  : <BiCheckbox size={20} className='text-gray-200' onClick={()=>setaddPayment({...addPayment,complete_payment_recieved:!addPayment.complete_payment_recieved})} />}
                              <h6 className='ml-1 text-[11px] font-[500]'>Complete Payment Recieved</h6>
                            </div>
                            </div>  
                          </>}   
  
                          <>
                         
                          {(addPayment?.payment_recieved || addPayment?.complete_payment_recieved) &&
                          <>
                          <div className='mt-1 ml-1.5'>
                              <TextInput 
                                mandatory={true}
                                label={'Amount With GST* '}  
                                variant="standard"
                                name="amount_with_gst"
                                type="text"
                                value={addPayment.amount_with_gst}
                                error={addPaymentErr.amount_with_gst}
                                handlechange={(e)=>{setaddPayment({...addPayment,amount_with_gst:e.target.value});setaddPaymentErr({...addPaymentErr,amount_with_gst:''})}} />
                          </div>
  
                          <div className='mt-1 ml-1.5'>
                              <TextInput 
                                mandatory={true}
                                label={'Amount Without Gst* '}  
                                variant="standard"
                                name="amount_without_gst"
                                type="text"
                                value={addPayment.amount_without_gst}
                                error={addPaymentErr.amount_without_gst}
                                handlechange={(e)=>{setaddPayment({...addPayment,amount_without_gst:e.target.value});setaddPaymentErr({...addPaymentErr,amount_without_gst:''})}} />
                          </div>
  
                          <div>
                          <h6 className='text-[11px] font-[600] ml-1.5 mb-1 mt-1' >{'Payment Recieved Date'}</h6>
                          <DatePicker placeholder='' ampm={false} style={{maxHeight:'40px',borderRadius:'3px',width:'100%'}} className='border-l-4 ml-1.5  border-l-[#158a93]' inputFormat="MM/dd/yyyy" value={addPayment?.recieved_date} onChange={(v) => {setaddPayment({...addPayment,recieved_date:v})}} />
                         </div>
  
                          <div className='mt-4 flex ml-2'>
                            <ButtonFilledAutoWidth btnName="Save Payment" onClick={()=>updatepayment_Status('2')} />
                          </div>
                          </>}

                           {reviseInvoice?.revise && !data.invoice_revise &&
                           <>
                           <div className='mt-4 ml-1.5'>
                            <h6 className='text-[12.5px] font-[800] mb-1'>Revise Request*</h6>
                            <textarea className='border w-full focus:ring-0 focus:outline-none px-2 text-[13px]' value={selectedData?.invoice_revise_summary} onChange={(e)=>{setselectedData({...selectedData,invoice_revise_summary:e.target.value});setselectedDataErr({...selectedDataErr,invoice_revise_summary:''})}}></textarea>
                              {selectedDataErr?.invoice_revise_summary !== null && selectedDataErr?.invoice_revise_summary !== '' && selectedDataErr?.invoice_revise_summary !== undefined &&
                              <div className='flex items-center mt-1'>
                              <BiErrorCircle className='text-red-500' size={14} />
                              <span className='text-[10px] text-red-500 ml-1'>{selectedDataErr?.invoice_revise_summary}</span>
                              </div>}

                              <div className='mt-2 flex justify-end'>
                               <ButtonOutlinedAutoWidth btnName="Revise Invoice" onClick={reviseInvoiceRequest} />
                              </div>
                           </div>

                           </>}
                          </>
                          {/* } */}
  
  
                      </div>
              </div>}

               
            </div>

            
        </div>

        
    </div>
  )
}

export default LeadInvoice