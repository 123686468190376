import React, { useEffect } from 'react';
import {useLocation,useNavigate } from 'react-router-dom';
import { LuCalendarPlus,LuCalendarMinus } from "react-icons/lu";


function ProfitLossMenu() {

  const {pathname} = useLocation();
  const path = pathname.split('/')[pathname.split('/').length - 2]



  const navigate = useNavigate();

  const menu = [
    {name:'Active Month',icon:LuCalendarPlus,path:'/profit_loss/active_month',id:1,color:''},
    // {name:'In Active Month',icon:LuCalendarMinus,path:'/profit_loss/inactive_month',id:2,color:''},
  ]  


  useEffect(()=>{
      if(path === ''){
        navigate('/profit_loss/active_month')
      }
  },[])

  // console.log("path",path)
  

  return (
    <div className='mr-0 min-h-screen max-h-sceen border-r  w-44 px-2' >

        <div className='mb-4 mt-5'>
        <h6 className=' ml-2 mb-2 font-[700] text-[12px]'>Profit / Loss Option</h6>

            {menu.map((m)=>(
                <div key={m?.path} className={`flex items-center my-1 hover:bg-[#f4f5f7] px-1.5 py-1 ml-2 rounded-md relative cursor-pointer ${pathname ===  m.path && 'bg-[#f4f5f7]'}`} onClick={()=>navigate(m?.path)}>
                {pathname ===  m.path &&  <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3' ></span>}
               <m.icon color='#000' style={{backgroundColor:m?.color,padding:'2px'}} size={16}  />
               <span className='text-[#000] font-[500] ml-2 text-[12px]'>{m.name}</span>
              </div>    
            ))}
        </div>
    </div>
  )
}

export default ProfitLossMenu