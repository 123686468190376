import React, { useEffect, useState } from 'react'
import IlsMenu from './IlsMenu'
import { IconButton, Tooltip } from '@mui/material'
import { DatePicker } from 'antd'
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../components/button'
import { useLocation, useNavigate } from 'react-router-dom'
import {BsArrowRepeat} from 'react-icons/bs';
import {FiChevronRight,FiChevronLeft} from 'react-icons/fi';
import moment from 'moment'
import {Modal} from 'antd'
import { AiOutlineEdit,AiOutlineDelete } from 'react-icons/ai';
import toast from 'react-hot-toast'
import { DeleteILSLandRequirmentService, GetILSLandRequirmentService } from '../../services/IlsLandRequirmentServices'
import { RiBillLine } from 'react-icons/ri'
import { useSelector } from 'react-redux'

function IlsLandRequirment() {

  const {pathname} = useLocation()
  const user = useSelector(state=>state.Auth)

  const [data,setdata] = useState({datas:[],pagination:{total:0,limit:25}})  
  const [page,setpage] = useState(1)
  const [search,setsearch] = useState({text:'',from_date:'',from_date1:'',to_date:'',to_date1:'',activate:false})
  const [selectedData,setselectedData] = useState({})
  const [modal,setmodal] = useState(false)

  const [stage,setstage] = useState('')


  const navigate = useNavigate()


  useEffect(()=>{
    if(pathname === '/ils/enquiry'){
      setpage(1)
      getData('Enquiry')
      setstage('Enquiry')
    }else if(pathname === '/ils/proposal'){
      setpage(1)
      getData('Proposal')
      setstage('Proposal')
    }else if(pathname === '/ils/discussion'){
      setpage(1)
      getData('Discussion')
      setstage('Discussion')
    }else if(pathname === '/ils/negotiation'){
      setpage(1)
      getData('Negotiation')
      setstage('Negotiation')
    }else if(pathname === '/ils/inspection'){
      setpage(1)
      getData('Inspection')
      setstage('Inspection')
    }else if(pathname === '/ils/loi_agreement'){
      setpage(1)
      getData('LOI/Agreement')
      setstage('LOI/Agreement')
    }else if(pathname === '/ils/closure'){
      setpage(1)
      getData('Closure')
      setstage('Closure')
    }else if(pathname === '/ils/hold'){
      setpage(1)
      getData('Hold')
      setstage('Hold')
    }
  },[pathname])


  useEffect(()=>{
    getData()
  },[page])

  async function getData(v){
    const response = await GetILSLandRequirmentService(page,v === undefined ? stage : v,search?.text,search?.from_date1,search?.to_date1)
    // console.log("response?.data?.kp",response?.data)
    setdata(response?.data)
  }

  async function applyfilterfunction(){
    const response = await GetILSLandRequirmentService(1,stage,search?.text,search?.from_date1,search?.to_date1)
    setdata(response?.data)
  }

  async function resetfunc(){
    setpage(1)
    setsearch({text:'',from_date:'',from_date1:'',to_date:'',to_date1:'',activate:false})
    const response = await GetILSLandRequirmentService(1,stage,'','','')
    setdata(response?.data)
  }

  async function deleteDatafunc(){
    const response = await DeleteILSLandRequirmentService(selectedData?._id)
    if(response?.status == 200){
      toast.success("Deleted Successfully")
      getData()
      setmodal(false)
    }
  }


  return (
    <div className='h-screen max-h-screen overflow-hidden'>
       <Modal open={modal} className='absolute top-0 left-[41%]' width={320} closable={false} footer={false}>
          <div> 
            <h6 className='text-[15px] font-[700]'>Delete Data</h6>
            <h6 className='text-[11px] leading-tight bg-gray-100 p-2' >Before Deleting the data be sure that it is required or not for further purpose.</h6>

            <h6 className='text-[11px] mt-1 mb-2'>Land Info : <span className='font-[700]'>{selectedData?.land_area}</span> / <span className='font-[400]'>{selectedData?.location}</span></h6>
            
            <div className='flex items-center border-t pt-2'>
                <ButtonOutlinedAutoWidth btnName="Cancel" onClick={()=>setmodal(false)} />
                <h6 className='w-[10px]'></h6>
                <ButtonFilledAutoWidth btnName="Sure" onClick={deleteDatafunc} />
             </div>

          </div>
        </Modal>
        
        <div className='flex'>
        <div className='w-44 min-w-44 max-w-44'>
        <IlsMenu />
        </div>
        <div className='w-[88%] px-4 mt-5'>
              <div>
              <div className='flex items-center justify-between border-b pb-2'>
            <h6 className='font-[700] text-[14px] '>Total ILS Requirment <span className='capitalize text-[11px] font-[400]'>[{pathname?.split('/')[pathname?.split('/').length -1]}]</span> ({data?.pagination?.total})</h6>
            <div className='flex items-center'>
              <div className='flex items-center text-[12px] mr-2'>
                   

                        <h6 className='mr-2 font-[600]'>{page == 1 ? data?.datas?.length > 0 ? 1 : 0 : (page - 1) * data?.pagination?.limit } - {data?.pagination?.limit} of {data?.pagination?.total} </h6>
                        <IconButton onClick={resetfunc}><BsArrowRepeat size={16} /></IconButton>

                        <div>
                        <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>
                        <IconButton onClick={()=>{ page < data?.pagination?.totalPages  ? setpage(page+1) : console.log('')}}><FiChevronRight className={`${(data?.pagination?.totalPages === page || data?.datas?.length === 0)  ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>

                        </div>
                    </div>


                    <DatePicker size='small' style={{fontSize:'11px'}} ampm={false} placeholder='From Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.from_date} onChange={(v) => {console.log('v na',new Date(v).toLocaleDateString());setsearch({...search,from_date:v})}} /> 

                    <DatePicker size='small' style={{fontSize:'11px'}} ampm={false} placeholder='To Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.to_date} onChange={(v) => {console.log('v na',new Date(v).toLocaleDateString());setsearch({...search,to_date:v})}} /> 

                    
                    <div className='mr-2'>
                        

                    <input  id="search_text" placeholder='Search text' type='text' value={search.text} onChange={(e)=>setsearch({...search,text:e.target.value})} className='border py-[3px] focus:ring-0 focus:outline-0 text-[14px]  w-28 px-1 rounded-md border-slate-300' />
                
                    </div>      

                <ButtonOutlinedAutoWidth btnName="Add Filter" onClick={()=>applyfilterfunction(1)} /> 
               
                <div className='ml-2'>
                <ButtonFilledAutoWidth btnName="Add Data" onClick={()=>navigate('create')}/> 
                </div>
                    </div>
                </div>
                
                {data?.datas?.length === 0 &&
                <div className='grid place-items-center mt-20  items-center justify-center'>
                <img src={'https://fidecrmfiles.s3.amazonaws.com/NoDataFound1.png'} className='w-40 h-40 object-contain' /> 
                <h6 className='font-bold text-[14px] -mt-10'>No data found</h6>
                <h6 className='font-[500] w-[70%] text-center text-slate-700 text-[12.5px] -mt-2'>Oops we couldn't find any data based on your current stage please add the data to see list here.</h6>
                </div>
                }

                {data?.datas?.length > 0 &&
                <div className='border-l overflow-y-scroll overflow-x-hidden  max-h-[90vh] border-r'>
                  <div className='flex border-b sticky top-0 bg-white'>
                    <h6 className='text-[12px] truncate py-1 px-2 border-r min-w-[50px] max-w-[50px] font-[600]'>Sl No</h6>
                    <h6 className='text-[12px] truncate py-1 px-2 border-r min-w-[10%] max-w-[10%] font-[600]'>Sector </h6>
                    <h6 className='text-[12px] truncate py-1 px-2 border-r min-w-[13%] max-w-[13%] font-[600]'>Company Name</h6>
                    <h6 className='text-[12px] truncate py-1 px-2 border-r min-w-[13%] max-w-[13%] font-[600]'>Location</h6>
                    <h6 className='text-[12px] truncate py-1 px-2 border-r min-w-[10%] max-w-[10%] font-[600]'>Micro Market</h6>
                    <h6 className='text-[12px] truncate py-1 px-2 border-r min-w-[11%] max-w-[11%] font-[600]'>Contact Name</h6>
                    <h6 className='text-[12px] truncate py-1 px-2 border-r min-w-[11%] max-w-[11%] font-[600]'>Contact Mobile</h6>
                    <h6 className='text-[12px] truncate py-1 px-2 border-r min-w-[11%] max-w-[11%] font-[600]'>Contact Email</h6>
                    <h6 className='text-[12px] truncate py-1 px-2 border-r min-w-[10%] max-w-[10%] font-[600]'>Created At</h6>
                    <h6 className='text-[12px] truncate py-1 px-2 min-w-[10%] max-w-[10%] font-[600]'>Action</h6>
                  </div>  
                 {data?.datas?.map((d,i)=>(
                  <div className='flex border-b'>
                    <h6 className='text-[11px] truncate py-1 px-2 border-r min-w-[50px] max-w-[50px] font-[500]'>{(page > 1 ? i+1+ (25 * (page - 1)) : i+1 )}</h6>
                    <h6 className='text-[11px] truncate py-1 px-2 border-r min-w-[10%] max-w-[10%] font-[500] capitalize'>{d?.sector}</h6>
                    <h6 className='text-[11px] truncate py-1 px-2 border-r min-w-[13%] max-w-[13%] font-[500]'>{d?.company_name}</h6>
                    <h6 className='text-[11px] truncate py-1 px-2 border-r min-w-[13%] max-w-[13%] font-[500]'>{d?.location}</h6>
                    <h6 className='text-[11px] truncate py-1 px-2 border-r min-w-[10%] max-w-[10%] font-[500]'>{d?.micro_market}</h6>
                    <h6 className='text-[11px] truncate py-1 px-2 border-r min-w-[11%] max-w-[11%] font-[500]'>{d?.point_of_contact_name}</h6>
                    <h6 className='text-[11px] truncate py-1 px-2 border-r min-w-[11%] max-w-[11%] font-[500]'>{d?.point_of_contact_mobile}</h6>
                    <h6 className='text-[11px] truncate py-1 px-2 border-r min-w-[11%] max-w-[11%] font-[500]'>{d?.point_of_contact_email}</h6>
                    <h6 className='text-[11px] truncate py-1 px-2 border-r min-w-[10%] max-w-[10%] border-r font-[500]'>{moment(d?.createdAt)?.format('LL')}</h6>
                    <h6 className='text-[11px] flex py-1 px-2 min-w-[10%] max-w-[10%] font-[500]'>
                    <RiBillLine size={13} className='mr-2' onClick={()=>navigate('detail',{state:d})} /> 
                    <AiOutlineEdit size={13} className='mr-2' onClick={()=>navigate('edit',{state:d})} /> 
                    {(user?.roles?.includes('delete_data') || user?.roles?.includes('ils_hod') || user?.roles?.includes('hod')) &&
                    <AiOutlineDelete size={13} onClick={()=>{setselectedData(d);setmodal(true)}} /> 
                    }
                    </h6>
                  </div>  
                  ))}

                </div>  
                }
              </div>
        </div>
        </div>

    </div>
  )
}

export default IlsLandRequirment