import { deleteRequest, get, post, postfd, put } from "../../helpers/apihelpers";

export const CreateStaffMomentService=async(data)=>{
    try {
        const result = await post(`api/reception/staff_moment/create`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateStaffMomentService=async(data,id)=>{
    try {
        const result = await put(`api/reception/staff_moment/update/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateStaffMomentApprovalService=async(id,data)=>{
    try {
        const result = await put(`api/reception/staff_moment/update_approval/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetStaffMomentService=async(page,text,from_date,to_date,step)=>{
    try {
        const result = await get(`api/reception/staff_moment/get?page=${page}&text=${text}&from_date=${from_date}&to_date=${to_date}&step=${step}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetStaffMomentApprovalService=async(page,text,from_date,to_date,step)=>{
    try {
        const result = await get(`api/reception/staff_moment/get_staff_moment_approval?page=${page}&text=${text}&from_date=${from_date}&to_date=${to_date}&step=${step}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteStaffMomentService=async(id)=>{
    try {
        const result = await deleteRequest(`api/reception/staff_moment/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UploadStaffMomentService=async(data)=>{
    try {
        const result = await postfd(`api/reception/staff_moment/upload_file`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DownloadStaffMomentService=async(page,text,from_date,to_date,step)=>{
    try {
        const result = await get(`api/reception/staff_moment/download_data?page=${page}&text=${text}&from_date=${from_date}&to_date=${to_date}&step=${step}`);
        return result;
    } catch (err) {
        return err.response;
    }
}