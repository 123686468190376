import { deleteRequest, get, post, postfd, publicPost, put } from "../../../helpers/apihelpers";

export const CreateTransactionWebsiteBlogService=async(data)=>{
    try {
        const result = await publicPost(`api/transaction_website/blogs/create`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateTransactionWebsiteBlogService=async(data,id)=>{
    try {
        const result = await put(`api/transaction_website/blogs/update/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetTransactionWebsiteBlogService=async(page,text)=>{
    try {
        const result = await get(`api/transaction_website/blogs/get?page=${page}&text=${text}`,);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteTransactionWebsiteBlogService=async(id)=>{
    try {
        const result = await deleteRequest(`api/transaction_website/blogs/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UploadTransactionWebsiteBlogService=async(data)=>{
    try {
        const result = await postfd(`api/transaction_website/blogs/upload_file`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}