import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'


function Finance() {
  
 const {pathname} = useLocation()
 const navigator = useNavigate()
 const path = pathname.split('/')[2]
//  console.log("pathname",pathname)
 
 useEffect(()=>{
  if(path === undefined){
    navigator('/finance/dashboard')
  }
 },[])

  return (
    <div>
        <h6>Finance</h6>
    </div>
  )
}

export default Finance