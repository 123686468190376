import React,{useState,useEffect} from 'react'
import { TextAreaInput1, TextInput } from '../../components/input';
import { ButtonFilled, ButtonOutlined } from '../../components/button';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { Select } from 'antd';
import GoBack from '../../components/back/GoBack';
import NMRMenu from './NMRMenu';
import { CreatePropertyDBService, GetPropertyDBDetailService, UpdatePropertyDBService } from '../../services/PropertDbWebsiteServices';

function NMRCE() {



  const [data,setdata] = useState({
    name:'',
    mobile:'',
    email:'',
    address:'',
    message:'',
    property_name:''
  })


  const propertyNameArr = [
    {label:'NMR',value:'NMR'},

  ]
  

  
  const [loader, setloader] = useState(false);



  const [error,seterror] = useState({
    name:'',
    mobile:'',
    email:'',
    address:'',
    message:'',
    property_name:''
  })

  const navigate = useNavigate()
  const location = useLocation()



  


  useEffect(()=>{

    if(location?.pathname?.split('/')[location?.pathname?.split('/').length -1] === 'edit'){
      geteditdata()
    }
  },[])

  async function geteditdata(){
    const response =  await GetPropertyDBDetailService(location?.state);
    let d = response?.data?.datas[0]


    setdata({
      id:d?._id,
      name:d?.name,
      mobile:d?.mobile,
      email:d?.email,
      address:d?.address,
      message:d?.message,
      property_name:(d?.property_name !== undefined && d?.property_name !== null && d?.property_name !== '') ? {label:d?.property_name,value:d?.property_name} : ''
    })

   

  }

  // console.log("data?.operators",data?.operators)
  function handlechange(e){
    setdata({...data,[e.target.name] : e.target.value})
    seterror({...error,[e.target.name] : ''})
  }

  async function submitform(){
    if(!data.name){
        seterror({...error,name:'This Field is required*'})
    }else if(!data.mobile){
        seterror({...error,mobile:'This Field is required*'})
    }else if(data.mobile.length !== 10){
        seterror({...error,mobile:'Enter a valid 10 digit mobile no*'})
    }else if(!data.email){
        seterror({...error,email:'This Field is required*'})
    }else if(!data.address){
      seterror({...error,address:'This Field is required*'})
    }else if(!data.message){
      seterror({...error,message:'This Field is required*'})
    }else{
        let send_data = {...data}

        if(data?.property_name?.value !== undefined){
          send_data['property_name'] = data?.property_name?.value
        }else{
          send_data['property_name'] = 'NMR'
        }
        
        if(location?.pathname?.split('/')[location?.pathname?.split('/').length - 1] === 'edit'){
        

          setloader(true)
          const response = await UpdatePropertyDBService(data?.id,send_data)
          if(response.status === 200){
            setloader(false)
            // console.log("Anna data?.stage?.label",data?.stage?.label,"stage?.selected_stage?.label",stage?.selected_stage?.label)
            setloader(false)
            toast.success('Data Updated Successfully')
            resetform()
            navigate(-1)
          }
        }else{  
          setloader(true)
          const response = await CreatePropertyDBService(send_data)
          if(response.status === 201){
              setloader(false)
              toast.success('Data Created Successfully')
              resetform()
          }
        }
      }
  }

  function resetform(){
     setdata({
      name:'',
      mobile:'',
      email:'',
      address:'',
      message:''
      })
      seterror({
        name:'',
        mobile:'',
        email:'',
        address:'',
        message:''
      })

     
  }



  const stages = [
    {label:'Not Recieved',value:'Not Recieved'},
    {label:'Invalid No',value:'Invalid No'},
    {label:'Not Interested',value:'Not Interested'},
    {label:'Lead Generated',value:'Lead Generated'},
    {label:'Not a Concern Person',value:'Not a Concern Person'},
  ]
  


  return (
    <div className='h-screen max-h-screen box-border overflow-hidden'>
    <div className='block sm:flex'>
        <NMRMenu />
    <div className='pr-5 ml-5 min-h-screen max-h-screen h-screen overflow-y-scroll' >
    

  

    <div className='w-[65%] w-44 '>
      <GoBack />
     <div className='border-b  pb-2'>
      <h6 className='font-[800] mb-1'>{location?.pathname?.split('/')[location?.pathname?.split('/').length -1] === 'edit' ? 'Edit' : 'Create'} Property Visitor Data</h6> 
      <h6 className='text-[11px] leading-tight font-[500] p-2 bg-slate-100 '>Use the below form to create or edit the Property VIsitor created by you .</h6> 
     </div> 
     <div className='w-[100%] overflow-x-hidden'>
      
          <TextInput 
              mandatory={true}
              label={'Name'}  
              variant="standard"
              name="name"
              type="text"
              value={data.name}
              error={error.name}
              handlechange={handlechange}
              placeholder="Enter contact name"
              InputLabelProps={{
                  style: { color: '#fff', }, 
              }}/>
               <TextInput 
            label={'Mobile'}  
            variant="standard"
            name="mobile"
            type="text"
            value={data.mobile}
            error={error.mobile}
            handlechange={handlechange}
            placeholder="Enter contact mobile"
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>
             <TextInput 
            label={'Email'}  
            variant="standard"
            name="email"
            type="text"
            value={data.email}
            error={error.email}
            handlechange={handlechange}
            placeholder="Enter contact email"
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>
            <TextAreaInput1 
                mandatory={false}
                label={'Address'}  
                variant="standard"
                name="address"
                type="text"
                value={data.address}
                error={error.address}
                handlechange={handlechange}
                placeholder=""
                InputLabelProps={{
                    style: { color: '#fff', }, 
            }}/>

<TextAreaInput1 
                mandatory={false}
                label={'Info'}  
                variant="standard"
                name="message"
                type="text"
                value={data.message}
                error={error.message}
                handlechange={handlechange}
                placeholder=""
                InputLabelProps={{
                    style: { color: '#fff', }, 
            }}/>

          <h6 className='text-[11px] font-[600] mb-1' >{'Property Name'}</h6>

          <Select 
              
              bordered={false}
              options={propertyNameArr} 
              placeholder="" 
              value={data?.property_name !== '' ? data?.property_name : null} 
              onChange={(e)=>setdata({...data,property_name:propertyNameArr?.find((f)=>f?.value === e)})} 
              size='small' 
              className='border w-[100%] border-slate-300 py-[2px] mr-2'
            />
     
      </div>

   
   <div >
    <div className='flex items-center mt-5 mb-10  border-t pt-5'>
        <div className='mr-2'>
        <ButtonOutlined btnName={'Back'} onClick={()=>navigate(-1)} />
        </div>
        <div>
        {loader ?   
        <ButtonFilled btnName={loader ? 'Loading' : 'Save'} /> :
        <ButtonFilled btnName={loader ? 'Loading' : 'Save'} onClick={()=>submitform(null)} /> }
        </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
   
  )
}

export default NMRCE