import React, { useEffect, useState } from 'react'
import DashboardMenu from '../dashboard/DashboardMenu'
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../components/button'
import {FiChevronRight,FiChevronLeft} from 'react-icons/fi';
import { DatePicker,Modal,Select } from 'antd';
import { IconButton } from '@mui/material'
import {BsArrowRepeat} from 'react-icons/bs';
import { GetOutstandingService, UpdateOutstandingService } from '../../services/OutstandingServices';
import { useSelector } from 'react-redux';
import moment from 'moment';
import Priceconstants from '../../constants/imageConstants';
import { useNavigate } from 'react-router-dom';
import { AiOutlineEdit } from 'react-icons/ai';
import { RiBillLine } from "react-icons/ri";
import { TextAreaInput1 } from '../../components/input';
import {BiCheckbox,BiCheckboxSquare} from 'react-icons/bi'
import toast from 'react-hot-toast';
import { GetUsersByDepartment } from '../../services/DepartmentServices';
import { GetTeamMembersDetail } from '../../services/AdminServicesfile/TeamServices';

function Outstandinglist() {

  const user = useSelector(state=>state.Auth)
  const [search,setsearch] = useState({from_date:'',from_date1:'',to_date:'',to_date1:'',selectedUser:''})  
  const [page,setpage] = useState(1)
  const [modal,setmodal] = useState(false)
  const [data,setdata] = useState({datas:[],pagination:{total:0}})
  const [users,setusers] = useState([])
  const [current_tab,setcurrent_tab] = useState(1)
  
   
  const [updateRemark,setupdateRemark] = useState({hide_in_outstanding_report:false,outstanding_report_remarks:''})
  const [updateRemarkErr,setupdateRemarkErr] = useState({hide_in_outstanding_report:'',outstanding_report_remarks:''})

  const navigate = useNavigate() 

  useEffect(()=>{
    if(user?.roles?.includes('admin') || user?.roles?.includes('hod')){
      getuserbasedDepartment()
    }else if(user?.roles?.includes('manager')){
      getTeamMembersForCurrentYear()
    }
  },[])

  useEffect(()=>{
    getdata()
  },[current_tab,page])

  async function getdata(){
     const response = await GetOutstandingService(search?.selectedUser?.value !== undefined ?  search?.selectedUser?.value : user?.id,search?.from_date1,search?.to_date1,page,current_tab)
     setdata(response?.data)
  }


  async function getuserbasedDepartment(){
    const response = await GetUsersByDepartment(user?.department_id[0]?.id)
    let arrData = []
    let arr =  response?.data?.datas
    arr.forEach((d)=>{
      arrData.push({label:d?.name,value:d?._id})
    })
    setsearch({...search,selectedUser:arrData.find((f)=>f?.value === user.id)})

    setusers(arrData)
  }

  async function getTeamMembersForCurrentYear(){
    const response = await GetTeamMembersDetail(user?.id)
    let arr = []

    response?.data?.datas[0]?.users?.forEach((d)=>{
        arr.push({label:d?.name,value:d?._id})
    })

    setsearch({...search,selectedUser:arr.find((f)=>f?.value === user.id)})

    setusers(arr)
  }

  async function applyfilterfunction(){
    setpage(1)
    const response = await GetOutstandingService(search?.selectedUser?.value !== undefined ?  search?.selectedUser?.value : user?.id,search?.from_date1,search?.to_date1,1,current_tab)
    setdata(response?.data)
  }
  

  async function resetfunc(){
    setpage(1)
    setsearch({from_date:'',from_date1:'',to_date:'',to_date1:'',selectedUser:users.find((f)=>f?.value === user.id)})
    const response = await GetOutstandingService(search?.selectedUser?.value !== undefined ?  search?.selectedUser?.value : user?.id,'','',1)
    setdata(response?.data)
  }

  async function openfilefunc(v){
    window.open(`${process.env.REACT_APP_BACKEND_IMAGE_URL}${v?.replace('public/','')}`,'_blank')
  }

  async function updateOutstandingData(d){
    let obj = {
      _id:d?._id,
      hide_in_outstanding_report:d.hide_in_outstanding_report,
      outstanding_report_remarks:d.outstanding_report_remarks
    }
    setupdateRemark(obj)
    setmodal(true)
  }

  async function handleChange(e){
    setupdateRemark({...updateRemark,[e.target.name]:e.target.value})
    setupdateRemarkErr({...updateRemarkErr,[e.target.name]:''})
  }

  async function open_lead_detail(d){
    if(d?.land_lord){
       navigate(`/leads/landlord_invoice/detail`,{state:d?.client?._id})
    }else{
       navigate(`/leads/invoice_raised/detail`,{state:d?.client?._id})
    }
  }

  async function submitoutstandingReport(){
    if(user?.roles?.includes('admin') || user?.roles?.includes('hod') || user?.roles?.includes('manager')){
        let sendData = {
          ...updateRemark
        }

        delete sendData._id
        let response = await UpdateOutstandingService(updateRemark?._id,sendData)
        if(response.status === 200){
          setmodal(false)
          getdata()
          toast.success("Outstanding Data Updated Successfully!")
        }
    }else{
      toast.error("You don't have any permission!")
    }
  }

  async function setcurrenttabfun(v){
    setcurrent_tab(v)
    setpage(1)  
  }

  function totalTdsDeduction(v){
    let arr = v 
    let amount = 0
    arr .forEach((a)=>{
      if(a.tds_deduction !== undefined && a.tds_deduction !== null && a.tds_deduction > 0){
        amount += Math.floor((a.amount_without_gst / 100) * a.tds_deduction)
      }
    })
    return amount
  }

  function totalTdsDeductionPercentage(v){
    let arr = v 
    let amount = 0
    arr .forEach((a)=>{
      if(a.tds_deduction !== undefined && a.tds_deduction !== null && a.tds_deduction > 0){
        amount += a?.tds_deduction
      }
    })
    return amount
  }



  console.log("data here",data?.datas)

  return (
    <div className='w-screen flex h-screen overflow-hidden'>
       <DashboardMenu />

       <Modal open={modal} closable={false} footer={false} width={300} className='absolute  top-0 left-[40%]'>
         <div className='relative'>

           <h6 className='font-[700] text-[14px]'>Edit Outstanding Report</h6>
           <h6 className='bg-gray-100 text-[12px] p-1'>You can use the below form to update the outstanding report data.</h6>
           

           <div onClick={()=>setupdateRemark({...updateRemark,hide_in_outstanding_report:!updateRemark.hide_in_outstanding_report})} className='flex items-center mt-2 mr-2'>
              {updateRemark?.hide_in_outstanding_report ?  <BiCheckboxSquare size={22} className='text-slate-600' />  : <BiCheckbox size={22} className='text-slate-300' /> }
              <h6 className="text-[11.5px] font-[600] ml-1">Hide In Outstanding Report</h6> 
            </div>  

           <TextAreaInput1 
             mandatory={false}
             label={'Outstanding Remarks'}  
             variant="standard"
             name="outstanding_report_remarks"
             type="text"
             handlechange={handleChange}
             value={updateRemark?.outstanding_report_remarks}
           />

           <div className='flex items-center justify-end pt-2 mt-2 border-t'>     
               <ButtonOutlinedAutoWidth onClick={()=>setmodal(false)} btnName="Cancel" />
               <h6 className='w-[10px]'></h6>
               <ButtonFilledAutoWidth onClick={()=>submitoutstandingReport()} btnName="Save" />
           </div>

         </div>
       </Modal>


       <div className='min-w-[83%] px-5 py-2 max-w-[82%] '>
          <div className='flex items-center justify-between border-b pb-2'>
              <h6 className='font-[700] text-[14px] '>Total Outstanding Data ({data?.pagination?.total})</h6>
              <div className='flex items-center'>
                <div className='flex items-center text-[12px] mr-2'>

               

                  <h6 className='mr-2 font-[600]'>{page == 1 ? data?.datas?.length > 0 ? 1 : 0 :  (page - 1) * data?.pagination?.limit } - {data?.pagination?.limit} of {data?.pagination?.total} </h6>
                  <IconButton onClick={resetfunc}><BsArrowRepeat size={16} /></IconButton>

                  <div>
                  <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>
                  <IconButton onClick={()=>{ page < data?.pagination?.totalPages  ? setpage(page+1) : console.log('')}}><FiChevronRight className={`${(data?.pagination?.totalPages === page || data?.datas?.length === 0)  ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>

                  </div>
                </div>


                <DatePicker size='small' style={{fontSize:'11px'}} ampm={false} placeholder='From Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.from_date} onChange={(v,v1) => {setsearch({...search,from_date:v,from_date1:v1})}} /> 

                <DatePicker size='small' style={{fontSize:'11px'}} ampm={false} placeholder='To Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.to_date} onChange={(v,v1) => {setsearch({...search,to_date:v,to_date1:v1})}} /> 

                 
                {(user?.roles?.includes('admin') || user?.roles?.includes('hod') || user?.roles?.includes('manager')) && 
                <div className='border border-slate-300 rounded-[8px] h-[30px] mr-2'>
                <Select
                      bordered={false}  
                      showSearch 
                      filterOption={false} 
                      placeholder="User"
                      value={search?.selectedUser === '' ? null : search?.selectedUser}
                      options={users}
                      onChange={(v)=>setsearch({...search,selectedUser:users.find((f)=>f?.value === v)})}
                      className='w-[100px]'
                      /> 
                </div>}
              <div >


                  

              
              
              </div> 
              {/* <Select 
                bordered={false}
                value={search?.zone}
                options={options}
                onChange={(e)=>setsearch({...search,zone:e})}
                placeholder={'Zone'}
                className='border rounded-[6px] border-slate-300 h-[30px] mx-2 w-[100px]' 
                
                />      */}

            <ButtonOutlinedAutoWidth btnName="Add Filter" onClick={()=>applyfilterfunction(1)} /> 
              </div>
          </div>


          <div className='flex border-b'>
            <h6 onClick={()=>setcurrenttabfun(1)} className={`px-2  text-[11px] py-1.5 font-[600] cursor-pointer  ${current_tab == 1 ? 'bg-slate-600 text-white' : 'border-white' }`}>Pending</h6>
            <h6 onClick={()=>setcurrenttabfun(2)} className={`px-2  text-[11px] py-1.5 font-[600] cursor-pointer  ${current_tab == 2 ? 'bg-slate-600 text-white' : 'border-white' }`}>Finalized</h6>
            <h6 onClick={()=>setcurrenttabfun(3)} className={`px-2  text-[11px] py-1.5 font-[600] cursor-pointer  ${current_tab == 3 ? 'bg-slate-600 text-white' : 'border-white' }`}>OverAll</h6>
          </div>

          <div className='h-[85vh] overflow-y-scroll'>
             <div className='flex sticky top-0 bg-white border mt-2 text-[11px]'>
               <h6 className='border-r py-[3px] px-[6px] w-[5%] font-[600] text-slate-600'>SL No</h6>
               <h6 className='border-r py-[3px] px-[6px] w-[15%] font-[600] text-slate-600'>Client Info</h6>
               <h6 className='border-r py-[3px] px-[6px] w-[10%] font-[600] text-slate-600'>Invoice To</h6>
               <h6 className='border-r py-[3px] px-[6px] w-[14%] font-[600] text-slate-600'>Inv Raised On</h6>
               <h6 className='border-r py-[3px] px-[6px] w-[11%] font-[600] text-slate-600'>Inv Amt Inc Gst</h6>
               <h6 className='border-r py-[3px] px-[6px] w-[11%] font-[600] text-slate-600'>Inv Amt Exc Gst</h6>
               <h6 className='border-r py-[3px] px-[6px] w-[11%] font-[600] text-slate-600'>Inv Payment Info</h6>
               <h6 className='py-[3px] px-[6px] border-r w-[13%] font-[600] text-slate-600'>Amount Collected</h6>
               <h6 className='py-[3px] px-[6px] w-[13%] border-r  font-[600] text-slate-600'>Amount Pending</h6>
               <h6 className='py-[3px] px-[6px] w-[13%] border-r  font-[600] text-slate-600'>Amount Incl TDS</h6>
               <h6 className='py-[3px] px-[6px] w-[6%] font-[600] text-slate-600'>Action</h6>
             </div>

             {data?.datas?.map((m,i)=>(
             <div key={i} className='flex border-b border-l border-r text-[11px]'>
               <h6 className='border-r py-[3px] px-[6px] w-[5%] font-[500]'>{page === 1 ? i+1 : (i+1) + ((page-1)* data?.pagination?.limit)}</h6>
               <h6 className='border-r py-[3px] px-[6px] w-[15%] font-[500]'>Name : <span className='font-[800]'>{m?.client?.contact_name}</span>   {m?.client?.land_lord === false  && `/ Company : ` + `${m?.client?.company_name}`}  <br></br> Requirment : <span>{m?.client?.requirment}</span>  <br></br> Created By : <span className='font-[800]'>{m?.employee?.name}</span>  <br></br> <span onClick={()=>open_lead_detail(m)} className='underline cursor-pointer text-slate-600 font-[700]'>View Lead</span> </h6>
               <h6 className='border-r py-[3px] px-[6px] w-[10%] font-[500] break-all'>Invoice No : <span className='font-[700]'>{m?.invoice_no}</span> <br></br> <span className='bg-slate-700 rounded-[4px] cursor-pointer text-white text-[10px] p-[4px]'> {m?.client?.land_lord ? 'Land Lord' : 'Client'}</span> </h6>
               <h6 className='border-r py-[3px] px-[6px] w-[14%] font-[800]'>{moment(m?.createdAt)?.format('LL')} <br></br> Amount Requested : {Priceconstants(m?.amount)}</h6>
               <h6 className='border-r py-[3px] px-[6px] w-[11%] font-[500]'>{Priceconstants(m?.invoice_amount_with_gst)}
               <br></br> <span onClick={()=>openfilefunc(m?.invoice_file)} className='underline cursor-pointer text-slate-600 font-[700]'>Invoice File</span> </h6>
               <h6 className='border-r py-[3px] px-[6px] w-[11%] font-[800]'>{Priceconstants(m?.invoice_amount_without_gst)}</h6>
               <h6 className='border-r py-[3px] px-[6px] w-[11%]'>Invoice Payment Count : <span className='font-[800]'>{m?.invoicepaymentList?.length}</span> Total TDS Deduction :  <span className='font-[800]'>Percentage : {(totalTdsDeductionPercentage(m?.invoicepaymentList))} % </span> <span className='font-[800]'>Amt : {Priceconstants(totalTdsDeduction(m?.invoicepaymentList))}</span></h6>
               <h6 className='border-r py-[3px] px-[6px] w-[13%] font-[500]'>{Priceconstants(m?.invoicepayment?.amount_with_gst !== undefined ? m?.invoicepayment?.amount_with_gst : 0)}</h6>
               <h6 className='py-[3px] border-r px-[6px] w-[13%] font-[900]'>{Priceconstants((m?.invoice_amount_with_gst - (m?.invoicepayment?.amount_with_gst !== undefined ? m?.invoicepayment?.amount_with_gst : 0)) > 0 ? (m?.invoice_amount_with_gst - (m?.invoicepayment?.amount_with_gst !== undefined ? m?.invoicepayment?.amount_with_gst : 0)) : 0)}</h6>
               <h6 className={`py-[3px] bg-slate-50 border-r px-[6px] w-[13%] font-[900]`}>{Priceconstants((m?.invoicepayment?.amount_with_gst !== undefined ? m?.invoicepayment?.amount_with_gst : 0) + (totalTdsDeduction(m?.invoicepaymentList)))}</h6>
               <h6 className='py-[3px] flex items-top px-[6px] w-[6%] font-[600] text-slate-600 mt-1'>
                   <AiOutlineEdit onClick={()=>updateOutstandingData(m)} size={14} className='cursor-pointer mr-2' />
                   <RiBillLine onClick={()=>navigate('/dashboard/out_standing/detail',{state:m})} className='cursor-pointer' />
               </h6>
             </div>))}

          </div>
       </div>
    </div>
  )
}

export default Outstandinglist