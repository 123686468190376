import { deleteRequest, get, post, postfd, put } from "../../helpers/apihelpers";

export const CreateFcaresPropertyService=async(data)=>{
    try {
        const result = await post(`api/fcares/property`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateFcaresPropertyService=async(id,data)=>{
    try {
        const result = await put(`api/fcares/property/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetFcaresPropertyService=async(text,from_date,to_Date,step,page)=>{
    try {
        const result = await get(`api/fcares/property?page=${page}&search=${text}&from_date=${from_date}&to_date=${to_Date}&step=${step}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetFcaresPropertyByIdService=async(id)=>{
    try {
        const result = await get(`api/fcares/property/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteFcaresPropertyService=async(id)=>{
    try {
        const result = await deleteRequest(`api/fcares/property/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UploadDocsFcaresPropertyService=async(data)=>{
    try {
        const result = await postfd(`api/fcares/property/upload_file`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}