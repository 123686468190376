import React, { useEffect, useState } from 'react'
import { GetGalleryProductDetail } from '../../../services/Gallery/GalleryProductServices'
import { useParams } from 'react-router-dom'
import GalleryAppBar from '../GalleryAppBar'
import GoBack from '../../../components/back/GoBack'
import {AiOutlineZoomIn} from 'react-icons/ai'
import { useSelector } from 'react-redux'

function ProductDetail() {

    const {id} = useParams()
    // console.log('id',id)
    const [data,setdata] = useState({})

    const auth = useSelector(state=>state.Auth)
    console.log("auth",auth)
    useEffect(()=>{
      getData()
    },[])   


    async function getData(){
        const response = await GetGalleryProductDetail(id)
        setdata(response?.data?.datas[0])
        // console.log("response",response)
    }

  //   let rupee = new Intl.NumberFormat('en-IN', {
  //     style: 'currency',
  //     currency: 'INR',
  //  });



  return (
    <div className='h-screen max-h-screen box-border overflow-y-scroll overflow-hidden'>
      <div className='block sm:flex'>    
        <GalleryAppBar />
        <div className='w-[92%] md:w-[85%] md:flex  ml-4  mt-4'>
          {auth?.login &&
          <div className='block mb-5 md:hidden '>
          <GoBack />
          </div>}
             
          {data?.name !== undefined &&<>
          <div className='md:w-[50%] relative'>
          <a target='_blank' href={`${process.env.REACT_APP_BACKEND_IMAGE_URL}${data?.image}`} > <AiOutlineZoomIn  size={24} className='absolute z-50 text-gray-300 top-1 left-1'/></a>
          <img className='md:max-w-[60%]' src={`${process.env.REACT_APP_BACKEND_IMAGE_URL}${data?.image}`} /> 
          </div>
          <div className='md:-ml-[18%] mt-10'>
          <div className='border h-fit '>
            <h6 className='text-[13px] border-b  flex'><p className='min-w-[150px] max-w-[150px] bg-gray-100 p-1 px-2  border-r'>Name  </p> <span className='p-1 px-2  font-[550]'>{data?.name}</span> </h6>
           
            <h6 className='text-[13px] border-b  flex'><p className='min-w-[150px] max-w-[150px] bg-gray-100 p-1 px-2  border-r'>Artist  </p> <span className='p-1 px-2  font-[550]'>{data?.artist?.name}</span> </h6>
        
            {(data?.measurment !== '' && data?.measurment !== null && data?.measurment !== undefined) &&
            <h6 className='text-[13px] border-b  flex'><p className='min-w-[150px] max-w-[150px] bg-gray-100 p-1 px-2  border-r'>Measurment  </p> <span className='p-1 px-2 font-[550]'>{data?.measurment}</span> </h6>}
            <h6 className='text-[13px] border-b  flex'><p className='min-w-[150px] max-w-[150px] bg-gray-100 p-1 px-2  border-r'>Quantity </p>  <span className='p-1 px-2  font-[550]'>{data?.qty}</span> </h6>
            {data?.mrp !== undefined  && data?.mrp !== '' && <h6 className='text-[13px] border-b  flex'><p className='min-w-[150px] max-w-[150px] bg-gray-100 p-1 px-2  border-r'>MRP  </p> <span className='p-1 px-2 font-[550]'>{data?.mrp}</span> </h6>}
            <h6 className='text-[13px] border-b  flex'><p className='min-w-[150px] max-w-[150px] bg-gray-100 p-1 px-2  border-r'>Selling Price  </p> <span className='p-1 px-2 font-[550]'>{data?.selling_price}</span> </h6>
            <h6 className='text-[13px] border-b  flex'><p className='min-w-[150px] max-w-[150px] bg-gray-100 p-1 px-2  border-r'>Stock Availability  </p> <span className='p-1 px-2 font-[550]'>{data?.available ? 'Available' : 'Not Availble'}</span> </h6>
            {(data?.stock_placement !== '' && data?.stock_placement !== null && data?.stock_placement !== undefined && data?.stock_placement !== 'undefined') &&
            <h6 className='text-[13px] border-b  flex'><p className='min-w-[150px] max-w-[150px] bg-gray-100 p-1 px-2  border-r'>Placement  </p> <span className='p-1 px-2  font-[550]'>{data?.stock_placement}</span> </h6>}
            {data?.discount_percentage !== undefined && data?.discount_percentage !== "undefined" &&
            <h6 className='text-[13px] flex'><p className='min-w-[150px] max-w-[150px] bg-gray-100 p-1 px-2  border-r'>Discount Percentage : </p>  <span className='p-1 px-2 font-[550]'>{data?.discount_percentage} % Flat off</span> </h6>}
            {(data?.description !== '' && data?.description !== null && data?.description !== undefined) &&
            <h6 className='text-[13px] border-b  flex'><p className='min-w-[150px] max-w-[150px] bg-gray-100 p-1 px-2  border-r'>Description  </p> <span className='p-1 px-2  font-[550]'>{data?.description}</span> </h6>}
            <h6 className='text-[13px] border-b  flex'><p className='min-w-[150px] max-w-[150px] bg-gray-100 p-1 px-2  border-r'>Specification  </p> <span className='p-1 px-2  font-[550]'>{data?.specification}</span> </h6>
           
          </div>
          {data?.available &&
           <a target='_blank' href="tel:+91 9845050109"><h6 className='mt-4 text-[12px] bg-black p-[10px] cursor-pointer transition-all duration-300 hover:bg-gray-200 hover:text-black w-[80px] text-center text-white font-[500]'>Buy Now</h6></a>}
          </div>
          </>}

          {data?.name === undefined &&<>
            <div className='md:w-[50%] relative'>
               <h6 className='text-[16px] font-[800] mt-10' >Sorry for Inconvience</h6>

              <h6 className='text-[14px] text-center mt-4 bg-slate-100 p-4 leading-normal' >Oops we could not find anything with the provided link Please check the <span className='font-[800]'>link</span> it might be <span className='font-[800]'>expired</span> of <span className='font-[800]'>invalid</span></h6>
            </div>  
          </> 
           }

        </div>  
      </div>      
    
    </div>
  )
}

export default ProductDetail