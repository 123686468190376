import React, { useEffect, useState } from 'react'
import { IconButton,Tooltip } from '@mui/material'
import AgreementFollowUpMenu from './AgreementFollowUpMenu'
import {BsArrowRepeat} from 'react-icons/bs';
import {FiChevronRight,FiChevronLeft} from 'react-icons/fi';
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../components/button';
import { DatePicker,Select,Modal } from 'antd';
import { DeleteAgreementService, GetAgreementListService } from '../../services/AgreementListServices';
import {AiOutlineDelete, AiOutlineEdit} from 'react-icons/ai';
import { FaRegFileAlt } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import { RiBillLine } from "react-icons/ri";
import moment from 'moment';
import { useSelector } from 'react-redux';
import {IoClose} from 'react-icons/io5';
import toast from 'react-hot-toast';

function Agreementlist() {
  
  const user = useSelector(state=>state.Auth)

  // console.log("user",user.roles)
  const [datas,setdatas] = useState({})
  const [page,setpage] = useState(1)
  const [search,setsearch] = useState({text:'',from_date:'',from_date1:'',to_date:'',to_date1:'',stage:''})
  const navigate = useNavigate()
  const [step,setstep] = useState(1)
  const [selectedData,setselectedData] = useState({})
  const [modal,setmodal] = useState(false)

  const stages = [{label:'Lock In Period',value:'Lock In Period'},{label:'Agreement Expire',value:'Agreement Expire'}]
  
  useEffect(()=>{
    getdatas()
  },[page,step])

  async function getdatas(){
    const response = await GetAgreementListService(page,step,search?.from_date1,search?.to_date1,search?.stage,search?.text)
    setdatas(response?.data)
  }

  async function applyfilterfunction(){
    setpage(1)
    const response = await GetAgreementListService(1,step,search?.from_date1,search?.to_date1,search?.stage,search?.text)
    setdatas(response?.data)
  }

  async function resetfunc(){
    setsearch({text:'',from_date:'',from_date1:'',to_date:'',to_date1:'',stage:''})
    const response = await GetAgreementListService(1,step,'','','','')
    setdatas(response?.data)
    setpage(1)
  }

  async function openAgreement(v){
    window.open(`${process.env.REACT_APP_BACKEND_IMAGE_URL}${v?.aggrement?.file?.replace('public/','')}`,'_blank')
  }

  async function deleteData(){
    const response = await DeleteAgreementService(selectedData?._id)
    if(response.status === 200){
      toast.success("Agreement Deleted Successfully!")
      setmodal(false)
      getdatas()
    }else{
      getdatas()
      toast.success("Agreement Deleted Successfully!")
      setmodal(false)
    }
  }



  return (
    <div className='w-[100%] h-screen flex  max-h-screen '>
      <Modal open={modal} center={true} width={300} closable={false} footer={false}>
            <div className='relative'> 
                <IoClose className='absolute right-0 top-0 cursor-pointer' onClick={()=>{setmodal(false)}} />
                <h6 className='text-[15px] font-[700]'>Delete Data</h6>
                <h6 className='leading-tight mt-2 text-[12px] bg-slate-100 p-2'>Are You sure want to delete the below selected <span className='lowercase'>{selectedData?.lead?.company_name}</span>  data  </h6>

                <h6 className='leading-tight mt-2 text-[12px] px-2'>Company : {selectedData?.lead?.company_name}  </h6>
                <h6 className='leading-tight mt-2 text-[12px] px-2'>Name : {selectedData?.lead?.contact_name}  </h6>
                <h6 className='leading-tight mt-2 text-[12px] px-2'>Requirment : {selectedData?.lead?.requirment}  </h6>

                <div className='flex mt-4'>
                    <ButtonOutlinedAutoWidth btnName="Cancel" onClick={()=>{setmodal(false)}} />
                    <div className='ml-2'>
                    <ButtonFilledAutoWidth btnName="Yes Im sure" onClick={deleteData} />
                    </div>
                </div>

          </div> 
      </Modal>
        <div className='flex w-[100%]'>
            <AgreementFollowUpMenu />
            <div className='w-[84%]  ml-4  mt-4'>
                <div className='flex items-center justify-between border-b pb-2'>
                <h6 className='font-[700] text-[14px] '>Total Agreement Data ({datas?.pagination?.total})</h6>
                <div className='flex items-center'>
                    <div className='flex items-center text-[12px] mr-2'>

                

                    <h6 className='mr-2 font-[600]'>{page == 1 ? datas?.datas?.length > 0 ? 1 : 0 :  (page - 1) * datas?.pagination?.limit } - {datas?.pagination?.limit} of {datas?.pagination?.total} </h6>
                    <IconButton onClick={resetfunc}><BsArrowRepeat size={16} /></IconButton>

                    <div>
                    <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>
                    <IconButton onClick={()=>{ page < datas?.pagination?.totalPages  ? setpage(page+1) : console.log('')}}><FiChevronRight className={`${(datas?.pagination?.totalPages === page || datas?.datas?.length === 0)  ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>

                    </div>
                    </div>


                    <DatePicker size='small' style={{fontSize:'11px'}} ampm={false} placeholder='From Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.from_date} onChange={(v,v1) => {console.log('v na',new Date(v).toLocaleDateString());setsearch({...search,from_date:v,from_date1:v1})}} /> 

                    <DatePicker size='small' style={{fontSize:'11px'}} ampm={false} placeholder='To Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.to_date} onChange={(v,v1) => {console.log('v na',new Date(v).toLocaleDateString());setsearch({...search,to_date:v,to_date1:v1})}} /> 

                
                <div >


                    {/* <Select
                    defaultValue={search?.type}
                    value={search?.type}
                    options={stages}
                    onChange={(e)=>setsearch({...search,type:stages?.find((f)=>f?.value === e)})}
                    placeholder="Stage"
                    bordered={false}
                    className='border border-slate-300 rounded-[5px] w-[100px] h-[30px] mr-[10px]'
                    /> */}
                    

                    <input  id="search_text" placeholder='Search text' type='text' value={search.text} onChange={(e)=>setsearch({...search,text:e.target.value})} className='border py-[3px] focus:ring-0 mr-2 focus:outline-0 text-[14px]  w-28 px-1 rounded-md border-slate-300' />
                
                
                </div> 
                <Select 
                    bordered={false}
                    value={search?.stage === '' ? null : search?.stage}
                    options={stages}
                    onChange={(e)=>setsearch({...search,stage:e})}
                    placeholder={'Stage'}
                    className='border rounded-[6px] border-slate-300 h-[30px] mr-2 w-[100px]' 
                    
                    />     

                <ButtonOutlinedAutoWidth btnName="Add Filter" onClick={()=>applyfilterfunction()} /> 
                

                </div>
                </div>
                <div>
                  {['admin','agreement_maintaner']?.filter((f)=>user?.roles?.includes(f)?.length > 0) && 
                  <div className='flex items-center border-b'>
                    <h6 onClick={()=>{setstep(1);setpage(1)}} className={`font-[600] ${step === 1 && 'bg-slate-600 text-white'} text-center cursor-pointer py-1 px-4 w-max text-[11px]`}>Agreement Registered</h6>
                    <h6 onClick={()=>{setstep(2);setpage(1)}} className={`font-[600] ${step === 2 && 'bg-slate-600 text-white'} text-center cursor-pointer py-1 px-4 w-max text-[11px]`}>Followup Initaited</h6>
                  </div>}

                {datas?.datas?.length === 0 &&
                    <div className='grid place-items-center mt-20  items-center justify-center'>
                    <img src={'https://fidecrmfiles.s3.amazonaws.com/NoDataFound1.png'} className='w-40 h-40 object-contain' /> 
                    <h6 className='font-bold text-[14px] -mt-10'>No data found</h6>
                    <h6 className='font-[500] w-[70%] text-center text-slate-700 text-[12.5px] -mt-2'>Oops we couldn't find any data based on your current stage please add the data to see list here.</h6>
                </div>
                }

                {datas?.datas?.length > 0 &&
                <>
                {step == 1 &&
                <div className='max-h-[82vh] mt-4 border-t   border-l border-r overflow-y-scroll'>
                        <div className='sticky top-0 z-50 flex  bg-white py-[-5px] border-b border-slate-200 relative'>
                            <h6  className='top-0 bg-white z-50 text-[12px] w-[60px]  px-2 py-1 font-[600] text-slate-600 border-r flex items-center justify-center'>SL NO</h6>
                            <h6 className='sticky top-0 z-50  text-[12px] w-[140px] px-2 py-1 font-[600] text-slate-600 border-r'>Company Name</h6>
                            <h6 className='sticky top-0 z-50  text-[12px] w-[120px]  px-2 py-1 font-[600] text-slate-600 border-r'>Contact Name</h6>
                            <h6 className='sticky top-0 z-50  text-[12px] w-[120px]  px-2 py-1 font-[600] text-slate-600 border-r'>Mobile</h6>
                            <h6 className='sticky top-0 z-50  text-[12px] w-[180px]  px-2 py-1 font-[600] text-slate-600 border-r'>Email</h6>
                            <h6 className='sticky top-0 z-50  text-[12px] w-[120px]  px-2 py-1 font-[600] text-slate-600 border-r'>Lease Years</h6>
                            <h6 className='sticky top-0 z-50  text-[12px] w-[120px]  px-2 py-1 font-[600] text-slate-600 border-r'>Lock In Period</h6>
                            <h6 className='sticky top-0 z-50  text-[12px] w-[130px] px-2 py-1 font-[600] text-slate-600 border-r'>Agreement Expire</h6>
                            <h6 className='sticky top-0 z-50  text-[12px] w-[80px] px-2 py-1 font-[600] text-slate-600 border-r'>Attachment</h6>
                            <h6 className='sticky top-0 z-50  text-[12px] w-[90px]  px-2 py-1 font-[600] text-slate-600 sticky right-0'>Actions</h6>
                        </div>
                        {datas?.datas?.map((d,i)=>(
                        <div key={d?._id} className=' z-50 flex  bg-white py-[-5px] border-b border-slate-200 relative'>
                            <h6  className='bg-white z-50 text-[12px] w-[60px]  px-2 py-1 font-[500]  border-r flex items-center justify-center'>{page === 1 ? i+1 : (i+1) + ((page-1)* datas?.pagination?.limit)}</h6>
                            <h6 onClick={()=>navigate('/leads/contacts/detail',{state:d?.lead?._id})} className=' z-50  text-[12px] w-[140px] px-2 py-1 font-[500] break-all border-r text-blue-700 underline font-[700] cursor-pointer'>{d?.lead?.company_name}</h6>
                            <h6 className=' z-50  text-[12px] truncate w-[120px]  px-2 py-1 font-[500] break-all border-r'>{d?.lead?.contact_name}</h6>
                            <h6 className=' z-50  text-[12px] truncate w-[120px]  px-2 py-1 font-[500] break-all border-r'>{d?.lead?.phone}</h6>
                            <h6 className=' z-50  text-[12px] truncate w-[180px]  px-2 py-1 font-[500] break-all border-r'>{d?.lead?.email}</h6>
                            <h6 className=' z-50  text-[12px] truncate w-[120px]  px-2 py-1 font-[500] break-all border-r'>{d?.lease_years}</h6>
                            <h6 className=' z-50  text-[12px] truncate w-[120px]  px-2 py-1 font-[500] break-all border-r'>{moment(d?.lock_in_period_end_date)?.format('LL')}</h6>
                            <h6 className=' z-50  text-[12px] truncate w-[130px] px-2 py-1 font-[500] break-all border-r'>{moment(d?.agreement_expire)?.format('LL')}</h6>
                            <h6 className=' z-50  text-[12px] truncate w-[80px] px-2 py-1 font-[600] text-slate-600 border-r'>
                                <FaRegFileAlt onClick={()=>openAgreement(d)} />
                            </h6>
                            <h6 className=' z-50 flex text-[12px] w-[90px]  py-1 font-[500]  '>
                            <Tooltip title="Edit">
                            <span><AiOutlineEdit size={13} className="z-10 ml-2" onClick={()=>navigate('/agreement/list/edit',{state:d?._id})}/></span>
                            </Tooltip>

                            <Tooltip title="Detail">
                            <span><RiBillLine size={13} className="z-10 ml-2" onClick={()=>navigate('/agreement/list/detail',{state:d?._id})}/></span>
                            </Tooltip>
                            
                            {['admin']?.filter((f)=>user?.roles?.includes(f))?.length > 0 &&
                            <Tooltip title="Delete">
                            <span><AiOutlineDelete size={13} className="z-10 ml-2" onClick={()=>{setselectedData(d);setmodal(true)}}/></span>
                            </Tooltip>}

                            </h6>
                        </div>))}
                </div>}
                {step == 2 &&
                <div className='max-h-[82vh] mt-4 border-t   border-l border-r overflow-y-scroll'>
                        <div className='sticky top-0 z-50 flex  bg-white py-[-5px] border-b border-slate-200 relative'>
                            <h6  className='top-0 bg-white z-50 text-[12px] w-[60px]  px-2 py-1 font-[600] text-slate-600 border-r flex items-center justify-center'>SL NO</h6>
                            <h6 className='sticky top-0 z-50  text-[12px] w-[180px] px-2 py-1 font-[600] text-slate-600 border-r'>Company Name</h6>
                            <h6 className='sticky top-0 z-50  text-[12px] w-[120px]  px-2 py-1 font-[600] text-slate-600 border-r'>Contact Name</h6>
                            <h6 className='sticky top-0 z-50  text-[12px] w-[120px]  px-2 py-1 font-[600] text-slate-600 border-r'>Mobile</h6>
                            <h6 className='sticky top-0 z-50  text-[12px] w-[140px]  px-2 py-1 font-[600] text-slate-600 border-r'>Email</h6>
                            <h6 className='sticky top-0 z-50  text-[12px] w-[140px]  px-2 py-1 font-[600] text-slate-600 border-r'>Lock In Period</h6>
                            <h6 className='sticky top-0 z-50  text-[12px] w-[160px] px-2 py-1 font-[600] text-slate-600 border-r'>Agreement Expire</h6>
                            <h6 className='sticky top-0 z-50  text-[12px] w-[140px] px-2 py-1 font-[600] text-slate-600 border-r'>Stage</h6>
                            <h6 className='sticky top-0 z-50  text-[12px] w-[80px] px-2 py-1 font-[600] text-slate-600 border-r'>Attachment</h6>
                            <h6 className='sticky top-0 z-50  text-[12px] w-[90px]  px-2 py-1 font-[600] text-slate-600 sticky right-0'>Actions</h6>

                          
                        </div>
                        {datas?.datas?.map((d,i)=>(
                        <div key={d?._id} className=' z-50 flex  bg-white py-[-5px] border-b border-slate-200 relative'>
                            <h6  className='bg-white z-50 text-[12px] w-[60px]  px-2 py-1 font-[500]  border-r flex items-center justify-center'>{page === 1 ? i+1 : (i+1) + ((page-1)* datas?.pagination?.limit)}</h6>
                            <h6 onClick={()=>navigate('/leads/contacts/detail',{state:d?.lead?._id})} className=' z-50  text-[12px] w-[178px] px-2 py-1 font-[500] break-all border-r text-blue-700 underline font-[700] cursor-pointer'>{d?.lead?.company_name}</h6>
                            <h6 className=' z-50  truncate text-[12px] w-[120px]  px-2 py-1 font-[500] break-all border-r'>{d?.lead?.contact_name}</h6>
                            <h6 className=' z-50  truncate text-[12px] w-[118px]  px-2 py-1 font-[500] break-all border-r'>{d?.lead?.phone}</h6>
                            <h6 className=' z-50  truncate text-[12px] w-[135px]  px-2 py-1 font-[500] break-all border-r'>{d?.lead?.email}</h6>
                            <h6 className=' z-50  truncate text-[12px] w-[135px]  px-2 py-1 font-[500] break-all border-r'>{moment(d?.lock_in_period)?.format('LL')}</h6>
                            <h6 className=' z-50  truncate text-[12px] w-[155px] px-2 py-1 font-[500] break-all border-r'>{moment(d?.agreement_expire)?.format('LL')}</h6>
                            <h6 className={` z-50  truncate text-[12px] w-[135px]  px-2 py-1 font-[500] break-all border-r  mt-1`}> <span className={` text-[8px] ${d?.stage === 'Converted' ? 'bg-green-500' : d?.stage === 'Pending' ? 'bg-blue-500' : 'bg-red-500'} px-2 py-1 font-[700] text-white uppercase `}>{d?.stage}</span> </h6>

                            <h6 className=' z-50  text-[12px] w-[82px] px-2 py-1 font-[600] text-slate-600 border-r'>
                                <FaRegFileAlt onClick={()=>openAgreement(d)} />
                            </h6>
                            <h6 className=' z-50 flex text-[12px] w-[90px]  px-2 py-1 font-[500]  '>
                            <Tooltip title="Edit">
                            <span><AiOutlineEdit size={13} className="z-10 ml-2" onClick={()=>navigate('/agreement/list/edit',{state:d?._id})}/></span>
                            </Tooltip>

                            <Tooltip title="Detail">
                            <span><RiBillLine size={13} className="z-10 ml-2" onClick={()=>navigate('/agreement/list/detail',{state:d?._id})}/></span>
                            </Tooltip>
                          
                            {['admin']?.filter((f)=>user?.roles?.includes(f))?.length > 0 &&
                            <Tooltip title="Delete">
                            <span><AiOutlineDelete size={13} className="z-10 ml-2" onClick={()=>{setselectedData(d);setmodal(true)}}/></span>
                            </Tooltip>}

                           
                            </h6>
                        </div>))}
                </div>
                }
                </>}

                </div>
            </div>
        </div>
    </div>
  )
}

export default Agreementlist