import React,{useEffect, useState} from 'react'
import { toast } from 'react-hot-toast'
import {  ButtonFilledAutoWidth } from '../../components/button'
import { TextAreaInput1, TextInput } from '../../components/input'
import { useNavigate, useLocation } from 'react-router-dom'
import GoBack from '../../components/back/GoBack'
import Uploader from '../../components/Uploader'
import { CreateShilpaFoundationEventService, UpdateShilpaFoundationEventService, UploadShilpaFoundationEventService } from '../../services/shilpafoundationservices/ShilpaFoundationEventListServices'
import { BiCheckbox,BiCheckboxSquare } from 'react-icons/bi'
import SettingsMenu from '../staticscreens/SettingsMenu'

function ShilpaFoundationEventListCE() {
 
  const [data,setdata] = useState({image:'',title:'',description:'',sponsors:'',place:'',expenses:'',remarks:'',active:true,event_date:''});  
  const [error,seterror] = useState({image:'',title:'',description:'',sponsors:'',place:'',expenses:'',remarks:'',active:'',event_date:''}); 
  
  
  const {state,pathname} = useLocation();
  const navigate = useNavigate();
  let path = pathname?.split('/')[pathname?.split('/')?.length -1]


 


  useEffect(()=>{
    if(state?._id !== null && state?._id !== undefined){

      let obj = {
        ...state,
        event_date:state?.event_date?.slice(0,10)
      }
      delete obj.created_by
      setdata({...data,...obj})
    }

  },[state])

  function handlechange(e){
    setdata({...data,[e.target.name] : e.target.value})
    seterror({...error,[e.target.name]:''})
  }
  

  
  async function submitform(){
    if(!data.title){
        seterror({...error,title:'Title field is required *'})
    }else if(!data.place){
      seterror({...error,place:'Place field is required *'})
    }else if(!data.event_date){
      seterror({...error,event_date:'Event Date field is required *'})
    }else{
        let sendData = {...data}
       
        if(state?._id === undefined || state?._id === null){
        const response = await CreateShilpaFoundationEventService(sendData)
        if (response.status === 201) {
          resetform()
          toast.success('Event Created Successfully')
        }   
        if(response.status === 422){
          if(response?.data?.errors?.title){
            seterror({...error,title:response?.data?.errors?.title})
          }
        } 
      }else{
        sendData["id"] = state?._id
        const response = await UpdateShilpaFoundationEventService(sendData,state?._id)
        if (response.status === 200) {
          resetform()
          navigate(-1)
          toast.success('Event Updated Successfully')
        }   
        if(response.status === 422){
          if(response?.data?.errors?.title){
            seterror({...error,title:response?.data?.errors?.title})
          }
        } 
      }
  }
  }

  async function resetform(){
    setdata({image:'',title:'',description:'',sponsors:'',place:'',expenses:'',remarks:'',active:true,event_date:''})
    seterror({image:'',title:'',description:'',sponsors:'',place:'',expenses:'',remarks:'',active:'',event_date:''})
  }


  async function uploadfile(v,name){
    const fd = new FormData()
    fd.append('file',v); 
    const response = await UploadShilpaFoundationEventService(fd)
    if(response?.status === 200){
      setdata({...data,[name]:response?.data?.data})
      seterror({...error,[name]:''})
    }
  }


 


  

  return (
    <div className='flex '>


    <SettingsMenu />
 
  <div className='sm:w-full lg:w-72 px-4 pt-5' >


      <GoBack /> 
      
      

        <h6 className='font-bold'>{(state?._id !== null && state?._id !== undefined) ? 'Edit' : 'Add'} Shilpa Foundation Event</h6>
        <h6 className='text-[10px] bg-slate-100 p-2 font-[500] leading-snug' >Use the below form to create or edit the <b>Shilpa Foundation Event</b> for your website.</h6>
     

        <TextInput 
            mandatory={true}
            label={'Title'}  
            variant="standard"
            name="title"
            type="text"
            error={error.title}
            value={data.title}
            handlechange={handlechange}
            placeholder=""
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>

       

          <TextAreaInput1 
            label={'Description'}  
            variant="standard"
            name="description"
            error={error.description}
            value={data.description}
            handlechange={handlechange}
            placeholder=""
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>

        <TextAreaInput1 
            label={'Sponsors'}  
            variant="standard"
            name="sponsors"
            type="text"
            error={error.sponsors}
            value={data.sponsors}
            handlechange={handlechange}
            placeholder=""
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>

        <TextInput 
            mandatory={true}
            label={'Place'}  
            variant="standard"
            name="place"
            type="text"
            error={error.place}
            value={data.place}
            handlechange={handlechange}
            placeholder="Enter your Role name"
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>

        <TextInput 
            label={'Expenses'}  
            variant="standard"
            name="expenses"
            type="text"
            error={error.expenses}
            value={data.expenses}
            handlechange={handlechange}
            placeholder="Enter your Role name"
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>

        <TextInput 
            mandatory={true}
            label={'Event Date'}  
            variant="standard"
            name="event_date"
            type="date"
            error={error.event_date}
            value={data.event_date}
            handlechange={handlechange}
            placeholder="Enter your Role name"
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>

         <TextAreaInput1 
            label={'Remarks'}  
            variant="standard"
            name="remarks"
            type="text"
            error={error.remarks}
            value={data.remarks}
            handlechange={handlechange}
            placeholder=""
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>

          <h6 className='text-[11px] mt-2 font-[600] mb-1' >Image</h6>
          <Uploader image={data?.image}  setimagefunc={(e)=>{uploadfile(e,'image')}}  removeimageuploadfunc = {()=>setdata({...data,image:''})}/>

         
          {path === 'edit' &&
          <div className='flex items-center mt-0 mr-2 -ml-1 mt-1'>
              {data?.active ?  <BiCheckboxSquare size={22} className='text-slate-600' onClick={()=>setdata({...data,active:!data.active})} />  : <BiCheckbox size={22} className='text-slate-300' onClick={()=>setdata({...data,active:!data.active})} /> }
              <h6 className="text-[11.5px] font-[500] ml-1">{data?.active ? 'Active' : 'Expired'}</h6> 
          </div>}

       

        
<div className='mt-5'>
        <ButtonFilledAutoWidth btnName={(state?._id !== null && state?._id !== undefined) ? "UPDATE EVENT" : "ADD EVENT"}  onClick={submitform} />  
        </div>


    </div>
    </div>
  )
}

export default ShilpaFoundationEventListCE