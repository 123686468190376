import React,{useState,useEffect} from 'react'
import { TextAreaInput1, TextInput } from '../../components/input'
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../components/button'
import { toast } from 'react-hot-toast'
import GoBack from '../../components/back/GoBack'
import { useLocation, useNavigate } from 'react-router-dom'
// import LeadMenu from '../LeadMenu'
import { GetDepartmentService } from '../../services/DepartmentServices'
import { Select,Divider } from 'antd'
import { useSelector } from 'react-redux'
import AtsMenu from './AtsMenu'
import { AiOutlinePlus } from 'react-icons/ai'
import { CreateAtsTrackService, UpdateAtsTrackService } from '../../services/Ats/AtsTrackServices'

function AtsTrackOptionCE() {

  const {state,pathname} = useLocation() 
  const navigator = useNavigate()
  
//   console.log("state",state)

//   const user_id = useSelector(state=>state.Auth.id)
  const roles = useSelector(state=>state.Auth.roles)
  const [name,setname] = useState('')
  const [sub_menu,setsub_menu] = useState([])
// console.log("user",user)
  
  const path = pathname?.split('/')[pathname?.split('/')?.length - 1]
  const path1 = pathname?.split('/')[pathname?.split('/')?.length - 3]
  const [singleData,setsingleData] = useState({heading:'',sub_heading:[],sort:'',color:'',is_deleted:false})
  const [error,seterror] = useState({heading:'',sub_heading:'',sort:'',color:'',is_deleted:''})
  
  

  

  const [loader,setloader] = useState(false)

//   console.log("path kp",path,state)
  useEffect(()=>{
    if(path === 'create' &&  path1 === 'assigned_work' && !roles?.includes('task_manager')){
        navigator(-1)
    }
    if(path === 'edit'){
      let d = state.data
      let stage = d?.sub_heading?.split(":")
      setsub_menu([...d?.sub_heading?.split(":")])
      let edit_data = {
        ...d,
        sub_heading:stage,
    }
     
    // if(d?.image !== undefined && d?.remarks !== null){
    //     edit_data['remarks'] = d?.remarks
    // }  

      setsingleData({...edit_data})
    }
  },[])


 

  const addItem = ()=>{
        if(name !== ''){
            setsub_menu([...sub_menu,name])
            setname('')
        }
  }


  async function submitform(){
    // if(state?.type === 'daily_tasks'){
        if(!singleData.heading){
            seterror({...error,heading:'The heading field is required'})
        }else if(singleData.sub_heading?.length === 0){
            seterror({...error,client_name:'The sub heading field is required'})
        }else if(!singleData.sort){
            seterror({...error,sort:'The sort field is required'})
        }else if(!singleData.color){
            seterror({...error,color:'The color field is required'})
        }else{
                    
                   const send_data = {...singleData}
                   send_data['sub_heading'] = singleData?.sub_heading?.join(":")
                  
                
                if(path !== 'edit'){
                    const response = await CreateAtsTrackService(send_data)
                    if(response.status === 200){
                        setloader(false)
                        toast.success('Ats Track Stage Created Successfully')
                        resetform() 
                    }
                }else{
                    send_data['_id'] = state?.data?._id
                    // fd.append('_id',state.data._id)
                    const response = await UpdateAtsTrackService(send_data,state?.data?._id)
                    if(response.status === 200){
                        setloader(false)
                        toast.success('Ats Track Stage Updated Successfully')
                        resetform() 
                    }
                }    
              
    }  
    
   }


   function resetform(){
        setsingleData({heading:'',sub_heading:[],sort:'',color:'',is_deleted:false})
        seterror({heading:'',sub_heading:'',sort:'',color:'',is_deleted:''})
        if(path === 'edit'){
            navigator(-1)
        }
       
    }

   

    function handlechange(e){
        setsingleData({...singleData,[e.target.name] : e.target.value})
        seterror({...error,[e.target.name] : ''})
    }

  



  return (
    <div className='flex min-h-screen max-h-screen h-screen '>
    <AtsMenu />
    <div className='w-[24%] pl-5 mt-5 min-h-screen max-h-screen h-screen overflow-y-screen overflow-x-hidden' >
            <GoBack />
            
            <div className='border-b pb-2'>
                <h6 className='text-[13px] mb-1 font-[800]'>Add / Edit Ats Track Option</h6>
                <h6 className='text-[12px] bg-slate-200 p-2'>Use the below form to create or update the client details to further process on requirment.</h6>
           </div>

            
            <div className='mt-2'>

           
            <TextInput 
              mandatory={true}
              label={'Track Name'}  
              variant="standard"
              name="heading"
              type="text"
              value={singleData?.heading}
              handlechange={handlechange}
              placeholder="Enter heading"
              InputLabelProps={{
                  style: { color: '#fff', }, 
              }}/>
            
            <h6 className='text-[11px] font-[600] mb-1 mt-2' >Job Stage</h6>    
            <Select 
                    mode = "multiple"
                    value={singleData?.sub_heading}
                    defaultValue={singleData?.sub_heading}
                    bordered={false}
                    className='border border-l-4 border-l-slate-600 border-slate-300 w-[100%]'
                    onChange={e=>setsingleData({...singleData,sub_heading:e})}
                    dropdownRender={(menu) => (
                    <>
                    {menu}
                    <Divider style={{ margin: '8px 0' }} />
                    <div  className='flex items-center'>
                        <div className='w-[90%] mr-2'>
                        <TextInput 
                        mandatory={false}
                        label={''}  
                        variant="standard"
                        name="NAME"
                        type="text"
                        value={name}
                        handlechange={(e)=>setname(e.target.value)}
                        placeholder="Enter contact name"
                        InputLabelProps={{
                            style: { color: '#fff', }, 
                        }}/>  
                        </div>
                    
                        <AiOutlinePlus size={24} onClick={()=>addItem("1")} className='bg-slate-600 p-[5px] rounded text-white' />
                        </div></>
                        )}
                        options={sub_menu.map((item) => ({ label: item, value: item }))}
                    />

            <TextInput 
              mandatory={true}
              label={'Sort'}  
              variant="standard"
              name="sort"
              type="text"
              value={singleData?.sort}
              handlechange={handlechange}
              placeholder="Enter heading"
              InputLabelProps={{
                  style: { color: '#fff', }, 
              }}/>
             
             <TextInput 
              mandatory={true}
              label={'Color'}  
              variant="standard"
              name="color"
              type="text"
              value={singleData?.color}
              handlechange={handlechange}
              placeholder="Enter heading"
              InputLabelProps={{
                  style: { color: '#fff', }, 
              }}/>
             

            
            </div>
            
            


            <div className='flex mt-2 border-t pt-2 w-full items-end '>
                <div className='mr-2'>
                <ButtonOutlinedAutoWidth btnName="Cancel" onClick={()=>navigator(-1)} />
                </div>
                <ButtonFilledAutoWidth btnName="Save" onClick={submitform} />
            </div>
           </div>  

    </div>
  )
}

export default AtsTrackOptionCE