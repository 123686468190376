import React,{useState,useEffect} from 'react'
import { useLocation,useNavigate } from 'react-router-dom'
import { GetLeadNoteService } from '../../../../services/LeadServices'
import {AiOutlinePlus} from 'react-icons/ai'
import { MobileDrawerBackAdd } from '../../../../components/appbars/mobile/DashboardMobile'
import { Box } from '@material-ui/core'

function LeadNotesList() {
    
    const {state} = useLocation()
    const navigate = useNavigate()
    const [notes,setnotes] = useState([])
    

    useEffect(()=>{
        getdata()
    },[])
  
    async function getdata(){
        const response = await GetLeadNoteService(state)
        setnotes(response?.data?.datas)
    }

    function returnfilename(v){
        let string = v?.split("/")[v?.split("/")?.length - 1]
        return string;
    }

    function getFileSizeString(fileSizeInBytes) {
        console.log("fileSizeInBytes",fileSizeInBytes)
        var i = -1;
        var byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
        do {
          fileSizeInBytes /= 1024;
          i++;
        } while (fileSizeInBytes > 1024);
        return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
    }

  return (
    <Box sx={{marginTop:{xs:'0px',sm:'0px',md:'10px',lg:'10px'}}}>
        <Box sx={{display:{sm:'block',md:'none',lg:'none'}}} className='sm:block md:hidden lg:hidden'>
        <MobileDrawerBackAdd goback={()=>navigate(-1)} name={`Lead Notes(${notes.length})`} />
        </Box> 

        <div className='p-4'>
            <Box sx={{display:{sm:'none',md:'block',lg:'block'}}}  className='hidden md:block lg:block'>
                <div className='flex items-center justify-between'>
                    <h6 className='font-[800] text-[14px]'>Lead Notes({notes.length})</h6>
                    <AiOutlinePlus size={24}  onClick={()=>navigate('create',{state:state})}/>
                </div>
            </Box>

        {notes?.map((n)=>(
        <div key={n?.id} className='border-l-2 my-2 pl-2 border-[#158a93]'>
            <h6 className='font-[700] text-[14px] '>{n?.note_title}</h6>
            <h6 className='font-[500] text-[12px] -mt-1 '>{n?.summary}</h6>
            {(n?.note_file !== null && n?.note_file !== undefined) &&
                <div className='mt-1 -ml-1 flex'>
                <img src='https://cdn-icons-png.flaticon.com/512/179/179483.png' style={{width:'15px',height:'20px',objectFit:'contain'}} />
                <div className='-mt-1 ml-1'>
                <h6 className='font-[400] text-[10px]'>{returnfilename(n?.note_file)}</h6>
                <h6 className='font-[600] text-[8px] -mt-0.5'>{getFileSizeString(n?.filesize)}</h6>
                </div>
                </div>}    
        </div>))}
        </div>

    </Box>
  )
}

export default LeadNotesList