import { deleteRequest, get, post, postfd, put } from "../../helpers/apihelpers";

export const CreateFcaresService=async(data)=>{
    try {
        const result = await post(`api/fcares/fcare_services`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateFcaresService=async(id,data)=>{
    try {
        const result = await put(`api/fcares/fcare_services/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetFcaresService=async(text,from_date,to_Date,page)=>{
    try {
        const result = await get(`api/fcares/fcare_services?page=${page}&search=${text}&from_date=${from_date}&to_date=${to_Date}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetFcaresAllService=async()=>{
    try {
        const result = await get(`api/fcares/fcare_services?limit_data=1000?page=1`);
        return result;
    } catch (err) {
        return err.response;
    }
}



export const GetFcaresByIdService=async(id)=>{
    try {
        const result = await get(`api/fcares/fcare_services/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteFcaresService=async(id)=>{
    try {
        const result = await deleteRequest(`api/fcares/fcare_services/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UploadDocsFcaresService=async(data)=>{
    try {
        const result = await postfd(`api/fcares/fcare_services/upload_file`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}