import { IconButton, Tooltip } from '@mui/material'
import React,{useState,useEffect} from 'react'
import { useLocation,useNavigate } from 'react-router-dom'
import { CreateLeadsListBasedonIdService, DeleteLeadService, GetLeadNoteService, GetLeadReminderService, GetLeadsService, SearchLeadService, UploadLeadContactService } from '../../services/LeadServices';
import moment from 'moment';
import {AiOutlineEdit,AiOutlineCloudDownload,AiOutlineCalendar,AiOutlineFileExcel, AiOutlineClose, AiOutlineReload} from 'react-icons/ai';
import {BsArrowRepeat} from 'react-icons/bs';
import {FiChevronRight,FiChevronLeft} from 'react-icons/fi';
import Uploader from '../../components/Uploader';

import {IoMdClose} from 'react-icons/io';
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../components/button';
import {Drawer, Modal, Select} from 'antd';
import { TextAreaInput1, TextInput } from '../../components/input';
import { DatePicker } from 'antd';
import {RiBillLine} from 'react-icons/ri';
import {VscOpenPreview} from 'react-icons/vsc';
import DailyTask from './dailyTask/DailyTask';
import {AiOutlineMail,AiOutlinePhone,AiOutlineDelete} from 'react-icons/ai';
import {HiLogout} from 'react-icons/hi';
import {IoMdArrowForward} from 'react-icons/io';
import axios from 'axios';
import fileDownload from "js-file-download";
import { toast } from 'react-hot-toast';
import {CiStickyNote} from 'react-icons/ci';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {useSelector} from 'react-redux';
import {TbScreenShare} from 'react-icons/tb';
import {BiCheckbox, BiCheckboxSquare, BiErrorCircle} from 'react-icons/bi';
import { TbAdjustmentsHorizontal } from "react-icons/tb";
import { GetDepartmentService } from '../../services/DepartmentServices';
import { CreateAssignLeadsService1 } from '../../services/AssignLeadServices';

function Leadlist() {

  const location = useLocation();
  const user = useSelector(state=>state.Auth)
  const user_department = user?.department_id[0]?.department_name
  const [excel,setexcel] = useState({file:'',error:''})
  const navigate = useNavigate();



  let path = location?.pathname?.split('/')[2]
  let path1 = location?.pathname?.split('/')[1]

  const [leads,setleads]  = useState([])
  const [leads_selected_list,setleads_selected_list] = useState([])
  const [type,settype] = useState('')
  const [page,setpage] = useState(1)
  const [modal,setmodal] = useState(false)
  const [filter_drawer,setfilter_drawer] = useState(false)
  const [preview,setpreview] = useState(false)
  const [department_share,setdepartmentShare] = useState(false)

  const [DepartmentArr,setDepartmentArr] = useState([])
  const [selected_department,setselected_department] = useState([])

  const [error,seterror] = useState({})

  const [selected_lead,setselected_lead] = useState({})
  const [attachment,setattachment] = useState([])
  const [note,setnote] = useState([])
  const [stage,setstage] = useState([])

  const [old_fidelitus_lead_assigned,setold_fidelitus_lead_assigned] = useState([])


  const [search,setsearch] = useState({text:'',from_date:'',to_date:'',activate:false})

  const list = ['contacts','options_shared','inspections','loi_signing','loi_agreement','invoice_raised','hold','non_payable_closure','design_shared','qs_stage','mep_stage','boq_stage','contact_in_future','lost_client','hold_client','execution_stage','closed_stage','follow_up','waiting_requirment','closed','lost','hold','in_progress','agreement','final_closure','negotiation','proposal_sent','assesement','meeting_discussion','contact','followup','converted']
 
  
  // console.log("path1",path1)
  // console.log("path",path)


  const [table_heading,settable_heading] = useState({contact_name:true,mobile:true,email:true,designation:true,company_name:true,secondary_contact_person:false,secondary_contact_phone:false,secondary_contact_email:false,lead_source:false,industry:true,land_lord:false,lead_status:true,zone:false,sqft:false,seater:false,rent:false,sale:false,website:false,location:false,requirment:false,address:false,operators:false,transfered:false,important:false,color:false,client:false,sqft_info:false,follow_up_remark:false,called_status:false,follow_up_date:false,contact_stage:false,created_At:true})
  const [designtable,setdesigntable] = useState(false)

  useEffect(()=>{
    setleads_selected_list([])
    if(path1 === 'leads' && path === undefined){
      if(user_department === 'HR Team'){
        navigate('/leads/follow_up')
      }else if(user_department !== 'FMS Team'){
        navigate('/leads/contacts')
      }else{
        navigate('/leads/contact')
      }
    }
    if(path1 === 'daily_tasks' && path === undefined){
      navigate('/daily_tasks/calls')
    }
    if(list?.includes(path)){
      setpage(1)
      getleads(1)
    }
    if(user_department === 'Transaction Team'){
      if(path === 'hold'){
       setstage([{name:'contacts',value:'Contact',index:0},{name:'hold',value:'Hold',index:1},{name:'options_shared',value:'Options',index:2},{name:'inspections',value:'Inspection',index:3},{name:'loi_agreement',value:'Agreement',index:4},{name:'invoice_raised',value:'Invoice',index:5}])
      }else{
       setstage([{name:'contacts',value:'Contact',index:0},{name:'options_shared',value:'Options',index:1},{name:'inspections',value:'Inspection',index:2},{name:'loi_agreement',value:'Agreement',index:3},{name:'invoice_raised',value:'Invoice',index:4}])
      }
    }else if(user_department === 'Project Team'){
      if(path === 'hold_client'){
        setstage([{name:'contacts',value:'Contact',index:0},{name:'hold_client',value:'Hold',index:1},{name:'lost_client',value:'Lost',index:2},{name:'design_shared',value:'Design',index:3},{name:'qs_stage',value:'QS',index:4},{name:'mep_Stage',value:'MEP',index:5},{name:'boq_stage',value:'BOQ',index:6},{name:'closed_stage',value:'Closed',index:7},{name:'contact_in_future',value:'InFuture',index:8}])
      }else if(path === 'lost_client'){
        setstage([{name:'contacts',value:'Contact',index:0},{name:'hold_client',value:'Hold',index:1},{name:'lost_client',value:'Lost',index:2},{name:'design_shared',value:'Design',index:3},{name:'qs_stage',value:'QS',index:4},{name:'mep_Stage',value:'MEP',index:5},{name:'boq_stage',value:'BOQ',index:6},{name:'closed_stage',value:'Closed',index:7},{name:'contact_in_future',value:'InFuture',index:8}])
      }else{
        setstage([{name:'contacts',value:'Contact',index:0},{name:'design_shared',value:'Design',index:3},{name:'qs_stage',value:'QS',index:4},{name:'mep_Stage',value:'MEP',index:5},{name:'boq_stage',value:'BOQ',index:6},{name:'closed_stage',value:'Closed',index:7},{name:'contact_in_future',value:'InFuture',index:8},{name:'hold_client',value:'Hold',index:1},{name:'lost_client',value:'Lost',index:2}])

      }
    }else if(user_department === 'HR Team'){
      if(path === 'hold'){
        setstage([{name:'contacts',value:'Contact',index:0},{name:'hold',value:'Hold',index:1},{name:'lost',value:'Lost',index:2},{name:'waiting_requirment',value:'WaitingRequirment',index:3},{name:'closed',value:'Closed',index:4}])
      }else if(path === 'lost'){
        setstage([{name:'contacts',value:'Contact',index:0},{name:'hold',value:'Hold',index:1},{name:'lost',value:'Lost',index:2},{name:'waiting_requirment',value:'WaitingRequirment',index:3},{name:'closed',value:'Closed',index:4}])
      }else{
        setstage([{name:'contacts',value:'Contact',index:0},{name:'waiting_requirment',value:'WaitingRequirment',index:3},{name:'closed',value:'Closed',index:4},{name:'hold',value:'Hold',index:1},{name:'lost',value:'Lost',index:2}])
      }
    }else if(user_department === 'FMS Team'){
      if(path === 'hold'){
        setstage([{name:'contacts',value:'Contact',index:0},{name:'hold',value:'Hold',index:1},{name:'lost',value:'Lost',index:2},{name:'meeting_discussion',value:'Meeting&Discussion',index:3},{name:'assesment',value:'Assesment',index:4},{name:'proposal_sent',value:'Proposal Sent',index:5},{name:'negotiation',value:'Negotiation',index:6},{name:'final_closure',value:'Final Closure',index:7},,{name:'agreement',value:'Agreement',index:8}])
      }else if(path === 'lost'){
        setstage([{name:'contacts',value:'Contact',index:0},{name:'lost',value:'Lost',index:2},{name:'hold',value:'Hold',index:1},{name:'meeting_discussion',value:'Meeting&Discussion',index:3},{name:'assesment',value:'Assesment',index:4},{name:'proposal_sent',value:'Proposal Sent',index:5},{name:'negotiation',value:'Negotiation',index:6},{name:'final_closure',value:'Final Closure',index:7},,{name:'agreement',value:'Agreement',index:8}])
      }else{
        setstage([{name:'contacts',value:'Contact',index:0},{name:'meeting_discussion',value:'Meeting&Discussion',index:3},{name:'assesment',value:'Assesment',index:4},{name:'proposal_sent',value:'Proposal Sent',index:5},{name:'negotiation',value:'Negotiation',index:6},{name:'final_closure',value:'Final Closure',index:7},,{name:'agreement',value:'Agreement',index:8},{name:'lost',value:'Lost',index:2},{name:'hold',value:'Hold',index:1}])
      }
    }else if(user_department === 'Fidelitus Gallery'){
        setstage([{name:'contacts',value:'Contact',index:0},{name:'followup',value:'Follow Up',index:1},{name:'converted',value:'Converted',index:2},{name:'hold',value:'Hold',index:3}])
    }else if(user_department === 'Shilpa Foundation'){
      setstage([{name:'contacts',value:'Contact',index:0},{name:'followup',value:'Follow Up',index:1},{name:'converted',value:'Converted',index:2},{name:'hold',value:'Hold',index:3}])
    }else if(user_department === 'Digital Media'){
      setstage([{name:'contacts',value:'Contact',index:0},{name:'followup',value:'Follow Up',index:1},{name:'converted',value:'Converted',index:2},{name:'hold',value:'Hold',index:3}])
  }
    
    if(path === 'contacts'){
       settable_heading({contact_name:true,mobile:true,email:true,designation:true,company_name:true,secondary_contact_person:false,secondary_contact_phone:false,secondary_contact_email:false,lead_source:false,industry:true,land_lord:false,lead_status:true,zone:false,sqft:false,seater:false,rent:false,sale:false,website:false,location:false,requirment:false,address:false,operators:false,transfered:false,important:false,color:false,client:false,sqft_info:false,follow_up_remark:false,called_status:false,follow_up_date:false,contact_stage:false,created_At:true})
    }else{
      settable_heading({contact_name:true,mobile:true,email:true,designation:true,company_name:true,secondary_contact_person:false,secondary_contact_phone:false,secondary_contact_email:false,lead_source:false,industry:true,land_lord:false,lead_status:true,zone:false,sqft:false,seater:false,rent:false,sale:false,website:false,location:false,requirment:false,address:false,operators:false,transfered:false,important:false,color:false,client:false,sqft_info:false,follow_up_remark:false,called_status:false,follow_up_date:false,contact_stage:false,created_At:true})
    }

    getdepartment()
  },[location.pathname])


  useEffect(()=>{
    if(list?.includes(path)){
      if(search.activate){
        applyfilterfunction(page)
      }else{
        getleads(page)
      }
    }
    getdepartment()
  },[page])

  async function getdepartment(){
    let arr = []
    const response = await GetDepartmentService()
    response.data.datas.forEach((d)=>{
       if(user_department !== d.department_name){
          arr.push({value:d.id,label:d.department_name})
       }  
    })
    setDepartmentArr(arr)
  }


  async function MdLeadAssignsubmitData(){
        let send_data = {}
        send_data['contact_name'] = selected_lead?.contact_name;
        send_data['phone'] = selected_lead?.mobile;
        send_data['email'] = selected_lead?.email;
        send_data['department_id'] = user.department_id[0]['id'];
        send_data['company_name'] = selected_lead?.company_name;
        send_data['designation'] = selected_lead.designation;
        send_data['lead_handled_by'] = selected_lead?.lead_handled_by?.value;
        send_data['lead_work'] = selected_lead?.lead_work;
        send_data['address'] = selected_lead?.address;
        send_data['lead_id'] = selected_lead?._id;
        
        let arr = selected_department
        arr.push({
          value:user?.department_id[0]?.id,
          summary:'Already Converted Lead',

        })
        send_data['department'] = selected_department

        const response = await CreateAssignLeadsService1(send_data)
        if(response.status === 201 || response.status === 200){
         toast.success("Lead Shared Successfully")
         setdepartmentShare(false)
         setselected_department([])
        }

  }


  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border:'1px solid rgb(227 231 234)',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      padding: '0px 6px',
      fontSize:'13px',
      fontWeight:600,
      marginBottom:'0px',
      paddingBottom:'0px'
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
    }),
  };

  async function getleads(page){
      setfilter_drawer(false)
      let type = ''

      if(user_department === 'Transaction Team'){
        if(path === 'contacts' ) type = 'Contact'
        if(path === 'options_shared' )  type = 'Options Shared'
        if(path === 'inspections' )  type = 'Inspection'
        if(path === 'loi_signing' )  type = 'LOI Signing'
        if(path === 'loi_agreement' )  type = 'LOI&Agreement'
        if(path === 'invoice_raised' )  type = 'Invoice Raised'
        if(path === 'hold' )  type = 'Hold'
        if(path === 'non_payable_closure') type = 'Non Payable Closure'
      } 

      if(user_department === 'Project Team'){
        if(path === 'contacts' ) type = 'Contact'
        if(path === 'design_shared' )  type = 'Design'
        if(path === 'qs_stage' )  type = 'QS'
        if(path === 'mep_stage' )  type = 'MEP'
        if(path === 'boq_stage' )  type = 'BOQ'
        if(path === 'contact_in_future' )  type = 'Contact In Future'
        if(path === 'execution_stage' )  type = 'Execution'
        if(path === 'closed_stage' )  type = 'Closed'
        if(path === 'lost_client') type = 'Lost'
        if(path === 'hold_client') type = 'Hold'
      } 

      if(user_department === 'HR Team'){
        if(path === 'follow_up' ) type = 'Follow Up'
        if(path === 'waiting_requirment' )  type = 'Waiting For Requirment'
        if(path === 'in_progress' )  type = 'In Progress'
        if(path === 'closed' )  type = 'Closed'
        if(path === 'lost' )  type = 'Lost'
        if(path === 'hold' )  type = 'Hold'
      }

      if(user_department === 'FMS Team'){
        if(path === 'contact' ) type = 'Contact'
        if(path === 'meeting_discussion' )  type = 'Meeting&Discussion'
        if(path === 'assesement' )  type = 'Assesment'
        if(path === 'proposal_sent' )  type = 'Proposal Sent'
        if(path === 'negotiation' )  type = 'Negotiation'
        if(path === 'final_closure' )  type = 'Final Closure'
        if(path === 'agreement' )  type = 'Agreement'
        if(path === 'lost' )  type = 'Lost'
        if(path === 'hold' )  type = 'Hold'
      }

      if(user_department === 'Fidelitus Gallery'){
        if(path === 'contacts' ) type = 'Contact'
        if(path === 'followup' )  type = 'Followup'
        if(path === 'converted' )  type = 'Converted'
        if(path === 'hold' )  type = 'Hold'
      }

      if(user_department === 'Digital Media'){
        if(path === 'contacts' ) type = 'Contact'
        if(path === 'followup' )  type = 'Followup'
        if(path === 'converted' )  type = 'Converted'
        if(path === 'hold' )  type = 'Hold'
      }

      if(user_department === 'Shilpa Foundation'){
        if(path === 'contacts' ) type = 'Contact'
        if(path === 'followup' )  type = 'Followup'
        if(path === 'converted' )  type = 'Converted'
        if(path === 'hold' )  type = 'Hold'
      }
      
      settype(type)
      const response =  await GetLeadsService(type,page);
      setleads(response.data)
  }

  async function applyfilterfunction(page){
    setsearch({...search,activate:true})
    let start_date = new Date(search?.from_date).toLocaleDateString().replaceAll('/','-').split('-').reverse().join('-')
    let end_date = new Date(search?.to_date).toLocaleDateString().replaceAll('/','-').split('-').reverse().join('-')
    const response = await SearchLeadService({search_text:search.text,status:type,from_date:(search?.from_date !== ''  && search?.from_date !== null) ? start_date : '',to_date:(search?.to_date !== ''  && search?.to_date !== null) ? end_date : '',page:page})
    setleads(response.data)
    setfilter_drawer(false)
  }

  async function resetfunc(){
    setsearch({text:'',from_date:'',to_date:'',activate:false})
    getleads(1)
  }

  async function getnote_attachment_basedonselectedlead(id){
     const response = await GetLeadNoteService(id)
     const response1 = await GetLeadReminderService(id) 
     setnote(response?.data)
     setattachment(response1?.data)  
  }

  function returnfilename(v){
    let string = v?.split("/")[v?.split("/")?.length - 1]
    return string;
  }

  const handleDownload = (url, filename) => {
    axios.get(url, {
        responseType: 'blob',
    })
        .then((res) => {
            fileDownload(res.data, filename)
            toast.success("Attachment Downloaded") 
        })
  }

  function getFileSizeString(fileSizeInBytes) {
      var i = -1;
      var byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
      do {
        fileSizeInBytes /= 1024;
        i++;
      } while (fileSizeInBytes > 1024);
      return Math.max(fileSizeInBytes, 0.1).toFixed(1).slice(0,4) + byteUnits[i];
  }

  function selected_lead_check_to_group(v){
      if(leads_selected_list?.includes(v)){
        setleads_selected_list([...leads_selected_list.filter(e => e!== v)])
      }else{
        if(leads_selected_list.length < 10){
           setleads_selected_list([...leads_selected_list,v])
        }else{
          toast.error('You can select upto max 10 items')
        }
      }      
  }

  async function createManyContactBasedOnlIST(){
    try{
     const response = await CreateLeadsListBasedonIdService({data:leads_selected_list})
     if(response?.status === 200){
        setleads_selected_list([])
        toast.success(response?.data?.data)
        getleads(page)
     }
    }catch(e){
      // console.log("err",e)
    }
  }

  async function deleteData(d){
    const response = await DeleteLeadService(d)
    if(response.status === 200){
      toast.success("Deleted Successfully")
        getleads(page)
    }
  }

  async function uploadExcel(){
    if(!excel?.file){
      setexcel({...excel,error:'This Field is required!'})
    }else{
      const response = await UploadLeadContactService(excel?.file)
      if(response?.status === 201){
        getleads(1)
        toast.success("Excel Uploaded Successfully")
        setexcel({file:'',error:''})
        setmodal(false)
      }else if(response?.status === 422){
        toast.error("Invalid Excel Format")

      }
    }
  }

  async function handleSelect(e,e1){
    // console.log("e1",e1,"e",e)

    if(e1 === 'Department'){
        if(selected_department?.length > 0){
          
          let arr = []


          if(location?.pathname?.split('/')[location?.pathname?.split('/').length -1] === 'edit'){
          
            e?.forEach((d)=>{
              // console.log("arr?.includes(a => a?.value === d)",arr?.filter(a => a?.value === d))
              // if(arr?.includes(a => a?.value === d) === false){
                if(old_fidelitus_lead_assigned?.length > 0){
                  if(old_fidelitus_lead_assigned?.filter(ol => ol?.department_id?._id === d)?.length > 0){
                    // console.log("old_fidelitus_lead_assigned?.filter(ol => ol?.department_id?._id === d)",old_fidelitus_lead_assigned?.filter(ol => ol?.department_id?._id === d))
                    arr.push({
                      label:old_fidelitus_lead_assigned?.filter(ol => ol?.department_id?._id === d)[0]?.department_id?.department_name,
                      value:old_fidelitus_lead_assigned?.filter(ol => ol?.department_id?._id === d)[0]?.department_id?._id,
                      summary:selected_department?.filter((f)=>f.value === d)?.length > 0 ? selected_department?.filter((f)=>f.value === d)[0]?.summary : old_fidelitus_lead_assigned?.filter(ol => ol?.department_id?._id === d)[0]?.department_id?.requirment,
                      id:old_fidelitus_lead_assigned?.filter(ol => ol?.department_id?._id === d)[0]?.department_id?._id})
                  }else{
                    let data = DepartmentArr?.find((f)=>f?.value === d)
                    if(data !== undefined){
                        arr.push({...data,summary:''})
                    }
                  }
                // }  
              }else{
              }
            })
           
            setselected_department(arr)

          }else{
            // console.log("ammma ammma")
            e?.forEach((d)=>{
              let data = DepartmentArr?.find((f)=>f?.value === d)
              if(data !== undefined){
                  arr.push({...data,summary:''})
              }
            })
            setselected_department(arr)
          } 

        }else{
            let arr = []
            let data = DepartmentArr?.find((f)=>f?.value === e[0])
            if(data !== undefined){
                arr.push({...data,summary:''})
            }
            setselected_department(arr)
        }
    }
  }

  async function updatesummary(i,e){
    let old_Data = [...selected_department]
    old_Data[i]['summary'] = e?.target?.value
    setselected_department([...old_Data])
  }


  return (
    <div className='h-screen max-h-screen '>
    <Modal maskClosable={true} width={370} className='absolute left-[40%] top-0' open={designtable} footer={false} closable={false}>
      <div className='relative'>
         <AiOutlineReload onClick={()=>{settable_heading({contact_name:true,mobile:true,email:true,designation:true,company_name:true,secondary_contact_person:false,secondary_contact_phone:false,secondary_contact_email:false,lead_source:false,industry:true,land_lord:false,lead_status:true,zone:false,sqft:false,seater:false,rent:false,sale:false,website:false,location:false,requirment:false,address:false,operators:false,transfered:false,important:false,color:false,client:false,sqft_info:false,follow_up_remark:false,called_status:false,follow_up_date:false,contact_stage:false,created_At:true});setdesigntable(!designtable)}} className='absolute right-7 top-0 cursor-pointer' />
         <AiOutlineClose onClick={()=>setdesigntable(!designtable)} className='absolute right-0 top-0 cursor-pointer' />
         <h6 className='text-[14px] font-[700]'>Customize Lead Table</h6>
         <h6 className='text-[11px] font-[400] bg-slate-100 p-1 my-1'>Use the below form to update the table customization as per your requirment</h6>

         <div className='flex flex-wrap  my-[5px]'>
           <div onClick={()=>settable_heading({...table_heading,contact_name:!table_heading.contact_name})} className={`flex items-center ${table_heading?.contact_name && 'bg-slate-700 text-white border-slate-700'} justify-between px-[4px] cursor-pointer py-[2px] border m-[1px]`}>
             <h6 className='text-[11px]'>Contact Name </h6>
           </div>
           <div onClick={()=>settable_heading({...table_heading,mobile:!table_heading.mobile})} className={`flex items-center  ${table_heading?.mobile && 'bg-slate-700 text-white border-slate-700'} justify-between px-[4px] cursor-pointer py-[2px] border m-[1px]`}>
             <h6 className='text-[11px]'>Mobile</h6>
           </div>
           <div onClick={()=>settable_heading({...table_heading,designation:!table_heading.designation})} className={`flex items-center  ${table_heading?.designation && 'bg-slate-700 text-white border-slate-700'} justify-between px-[4px] cursor-pointer py-[2px] border m-[1px]`}>
             <h6 className='text-[11px]'>Designation</h6>
           </div>
           <div onClick={()=>settable_heading({...table_heading,email:!table_heading.email})} className={`flex items-center  ${table_heading?.email && 'bg-slate-700 text-white border-slate-700'} justify-between px-[4px] cursor-pointer py-[2px] border m-[1px]`}>
             <h6 className='text-[11px]'>Email</h6>
           </div>
           <div onClick={()=>settable_heading({...table_heading,company_name:!table_heading.company_name})} className={`flex items-center  ${table_heading?.company_name && 'bg-slate-700 text-white border-slate-700'} justify-between px-[4px] cursor-pointer py-[2px] border m-[1px]`}>
             <h6 className='text-[11px]'>Company Name</h6>
           </div>
           <div onClick={()=>settable_heading({...table_heading,industry:!table_heading.industry})} className={`flex items-center  ${table_heading?.industry && 'bg-slate-700 text-white border-slate-700'} justify-between px-[4px] cursor-pointer py-[2px] border m-[1px]`}>
             <h6 className='text-[11px]'>Industry</h6>
           </div>
           <div onClick={()=>settable_heading({...table_heading,requirment:!table_heading.requirment})} className={`flex items-center  ${table_heading?.requirment && 'bg-slate-700 text-white border-slate-700'} justify-between px-[4px] cursor-pointer py-[2px] border m-[1px]`}>
             <h6 className='text-[11px]'>Requirment</h6>
           </div>
           <div onClick={()=>settable_heading({...table_heading,sqft_info:!table_heading.sqft_info})} className={`flex items-center  ${table_heading?.sqft_info && 'bg-slate-700 text-white border-slate-700'} justify-between px-[4px] cursor-pointer py-[2px] border m-[1px]`}>
             <h6 className='text-[11px]'>SQFT Info</h6>
           </div>
           <div onClick={()=>settable_heading({...table_heading,location:!table_heading.location})} className={`flex items-center  ${table_heading?.location && 'bg-slate-700 text-white border-slate-700'} justify-between px-[4px] cursor-pointer py-[2px] border m-[1px]`}>
             <h6 className='text-[11px]'>Location</h6>
           </div>
           <div onClick={()=>settable_heading({...table_heading,lead_status:!table_heading.lead_status})} className={`flex items-center  ${table_heading?.lead_status && 'bg-slate-700 text-white border-slate-700'} justify-between px-[4px] cursor-pointer py-[2px] border m-[1px]`}>
             <h6 className='text-[11px]'>Lead Status</h6>
           </div>
           {path === "contacts" &&
           <>
           <div onClick={()=>settable_heading({...table_heading,called_status:!table_heading.called_status})} className={`flex items-center  ${table_heading?.called_status && 'bg-slate-700 text-white border-slate-700'} justify-between px-[4px] cursor-pointer py-[2px] border m-[1px]`}>
             <h6 className='text-[11px]'>Called Status</h6>
           </div>
           <div onClick={()=>settable_heading({...table_heading,follow_up:!table_heading.follow_up})} className={`flex items-center  ${table_heading?.follow_up && 'bg-slate-700 text-white border-slate-700'} justify-between px-[4px] cursor-pointer py-[2px] border m-[1px]`}>
             <h6 className='text-[11px]'>Follow Up</h6>
           </div>
           <div onClick={()=>settable_heading({...table_heading,follow_up_date:!table_heading.follow_up_date})} className={`flex items-center  ${table_heading?.follow_up_date && 'bg-slate-700 text-white border-slate-700'} justify-between px-[4px] cursor-pointer py-[2px] border m-[1px]`}>
             <h6 className='text-[11px]'>Follow Up Date</h6>
           </div>
           <div onClick={()=>settable_heading({...table_heading,follow_up_remark:!table_heading.follow_up_remark})} className={`flex items-center  ${table_heading?.follow_up_remark && 'bg-slate-700 text-white border-slate-700'} justify-between px-[4px] cursor-pointer py-[2px] border m-[1px]`}>
             <h6 className='text-[11px]'>Follow Up Remark</h6>
           </div>
           </>}

           <div onClick={()=>settable_heading({...table_heading,lead_owner:!table_heading.lead_owner})} className={`flex items-center  ${table_heading?.lead_owner && 'bg-slate-700 text-white border-slate-700'} justify-between px-[4px] cursor-pointer py-[2px] border m-[1px]`}>
             <h6 className='text-[11px]'>Lead Owner</h6>
           </div>
           <div onClick={()=>settable_heading({...table_heading,lead_operator:!table_heading.lead_operator})} className={`flex items-center  ${table_heading?.lead_operator && 'bg-slate-700 text-white border-slate-700'} justify-between px-[4px] cursor-pointer py-[2px] border m-[1px]`}>
             <h6 className='text-[11px]'>Lead Operators</h6>
           </div>
           <div onClick={()=>settable_heading({...table_heading,created_At:!table_heading.created_At})} className={`flex items-center  ${table_heading?.created_At && 'bg-slate-700 text-white border-slate-700'} justify-between px-[4px] cursor-pointer py-[2px] border m-[1px]`}>
             <h6 className='text-[11px]'>Created At</h6>
           </div>

         </div>
      </div>
    </Modal>

    <Drawer
      anchor={'right'}
      open={filter_drawer}
      closable={false}
      width={260}
    >
      <div className=' w-52 -mt-3'>
        <span onClick={()=>setfilter_drawer(!filter_drawer)} ><IoMdClose className='absolute right-2 cursor-pointer' /></span>
        <h6 className='text-[13px]'>Use the below form to apply filter for your leads</h6>


        <TextInput 
              mandatory={false}
              label={'Search Text'}  
              variant="standard"
              name="text"
              type="text"
              value={search.text}
              handlechange={e=>setsearch({...search,text:e.target.value})}
              placeholder="Enter contact name"
              InputLabelProps={{
                  style: { color: '#fff', }, 
              }}/>


         
          
          <div>
          <h6 className='text-[12px] font-[600] mb-1 mt-2' >{'From Date'}</h6>
          <div>
         <DatePicker ampm={false} placeholder='' className='w-full'  value={search?.from_date} onChange={(v) => {console.log('v na',new Date(v).toLocaleDateString());setsearch({...search,from_date:v})}} /> 
         </div>    
         <h6 className='text-[12px] font-[600] mb-1 mt-2' >{'To Date'}</h6>
         <div>
         <DatePicker ampm={false} placeholder='' className='w-full'  value={search?.to_date} onChange={(v) => {setsearch({...search,to_date:v})}}  /> 
         </div>
         </div>




         
         <div className='flex mt-4'>
         <div  className="mr-2" >
         <ButtonOutlinedAutoWidth btnName="Reset" onClick={resetfunc}/>   
         </div>
         <ButtonFilledAutoWidth btnName="Apply" onClick={()=>applyfilterfunction(1)} />   
         </div>

      </div>
    </Drawer>

    <Drawer maskStyle={{background:'black',opacity:0.8}} onClose={()=>setpreview(false)}  maskClosable={()=>setpreview(false)} open={preview} width={600} closable={false}>
       
       <div >
         <div className='mb-3  flex items-center justify-between border-b pb-3 border-slate-100'>
          <h6  onClick={()=>setpreview(false)} className='flex cursor-pointer items-center'>
          <HiLogout size={20} className='text-slate-300' /> 
          <span className='ml-2 font-[600]'>Lead Preview</span>
          </h6>

          <h6 onClick={()=>navigate(`detail`,{state:selected_lead?._id})} className='border cursor-pointer flex items-center p-1.5 px-2 font-[500] text-[11px] border-slate-100 rounded'>View Full Detail <IoMdArrowForward size={15} className='ml-1' /></h6>
         </div>
         <div className='border relative border-slate-100 '>
          <div className='flex absolute right-2 top-1'>
              <CopyToClipboard text={selected_lead?.email}>
             <AiOutlineMail size={22} className='border cursor-pointer p-1 mr-2 rounded-full text-slate-400' />
             </CopyToClipboard>
             <CopyToClipboard text={selected_lead?.mobile}>
             <AiOutlinePhone size={22} className='border cursor-pointer p-1 mr-2 rounded-full text-slate-400'  />
             </CopyToClipboard>
             
             <AiOutlineEdit onClick={()=>navigate('edit',{state:selected_lead?._id})} size={22} className='border cursor-pointer p-1 rounded-full text-slate-400'  />
          </div>
          <div className='flex p-2 items-center border-b   border-slate-100 '>
           <h6 className='bg-purple-200 w-9 h-9 text-[12px] flex items-center justify-center uppercase rounded-full '>{selected_lead?.contact_name?.slice(0,2)}</h6>
           <div className='ml-2'>
            <h6 className='mb-0 font-[700]'>{selected_lead?.contact_name}</h6>
            <h6 className='flex text-[11px] -mt-0.5 items-center text-slate-400'><AiOutlineMail  className='mr-2'/> {selected_lead?.email} <AiOutlinePhone className='mx-2' /> {selected_lead?.mobile} </h6>
           </div>
          </div>
          <div className='flex items-center justify-evenly'>
            <div className='border-r  border-slate-100 w-full pl-3 py-2'>
              <h6 className='text-slate-400 text-[11px]'>Lead owner</h6>
              <h6 className='font-[600] text-[12px]'>{selected_lead?.lead_owner?.name}</h6>
            </div>

            <div className='border-r  border-slate-100 w-full pl-3  py-2'>
              <h6 className='text-slate-400 text-[11px]'>Company</h6>
              <h6 className='font-[600] text-[12px]'>{selected_lead?.company_name}</h6>
            </div>

            <div className='border-r  border-slate-100 w-full pl-3  py-2'>
              <h6 className='text-slate-400 text-[11px]'>Job Title</h6>
              <h6 className='font-[600] text-[12px]'>{selected_lead?.designation}</h6>
            </div>

            <div className=' w-full pl-3 py-2'>
              <h6 className='text-slate-400 text-[11px]'>Requirment</h6>
              <h6 className='font-[600] text-[11px]'>{selected_lead?.sqft && 'SQFT,'} {selected_lead?.seater && 'SEATER,'} {selected_lead?.rent && 'RENT,'} {selected_lead?.sale && 'SALE'}</h6>
            </div>

          </div>
         </div>


         <div className='mt-2 flex justify-between'>
         {stage?.map((s,i)=>(
          <h6 key={i} className={` w-full mx-0.5 font-[600] cursor-pointer text-center p-1 text-[11px] ${stage?.find(f=>f?.name === path)?.index >  i ? 'bg-green-100 text-green-600' : s?.name == path ?  'bg-green-600 text-white' : 'bg-salte-100 text-slate-600'} `}>{s?.value}</h6>
         ))}
         </div>

         <div className='flex items-center my-4 pb-4 border-slate-100 border-b justify-between'>
          <h6 className='text-[11px]'>Lead Source <span className='text-[11px] font-[700]'>{selected_lead?.lead_source?.name}</span> </h6>
          <h6 className='text-[11px]'>Last Activity : <span className='text-[11px] font-[700]'> {moment(selected_lead?.updatedAt).format('lll')}</span></h6>
         </div>


         {(attachment?.datas?.length === 0 && note?.datas?.length === 0 )&& 
              <div className='grid place-items-center mt-20  items-center justify-center'>
                <img src={'https://fidecrmfiles.s3.amazonaws.com/NoDataFound1.png'} className='w-40 h-40 object-contain' /> 
                <h6 className='font-bold text-[14px] -mt-10'>No data found</h6>
                <h6 className='font-[500] w-[80%] text-center text-slate-700 text-[12.5px] -mt-2'>Oops we couldn't find any particual notes or reminders added for this particular leads.</h6>
              </div>
         }

         {attachment?.datas?.length !== 0 && 
         <>
         <h6 className='text-[12px] font-[700]'> Reminder <span className='bg-slate-100 p-1 px-2 rounded font-[400] text-slate-800 text-[12px]'>{attachment?.datas?.length}</span></h6>
         {attachment?.datas?.map((n)=>(
         <div className='border mt-2 p-2 rounded border-slate-100'>
              <div className='border-b flex items-center justify-between pb-2 mb-2 border-slate-100'> 
                 <h6 className='text-[11px] flex items-center '><CiStickyNote size={21} className='bg-slate-100 text-blue-600 p-1 mr-2' /> <span className='font-[500] mr-1'>Reminder </span> by <span  className='font-[500] ml-1'> {n?.user_id?.name}</span></h6>
                 <h6 className='text-[10px] text-slate-400 flex items-center '><AiOutlineCalendar size={21} className='p-1 mr-1' /> <span className='font-[500] mr-1'>{moment(n?.createdAt)?.format('lll')}</span></h6>
              </div>   
              <h6 className='text-[12px] font-[500] mb-1'>{n?.title}</h6>
              <h6 className='leading-tight text-slate-400 text-[11px] font-[400]'>{n?.description} <span className='text-black'> {moment(n?.on_date_time)?.format('lll')} </span></h6>
              
         </div>
         ))}
         </>}

         {note?.datas?.length !== 0 && 
         <>
         <h6 className='text-[12px] font-[700] my-4'> Notes <span className='bg-slate-100 p-1 px-2 rounded font-[400] text-slate-800 text-[12px]'>{note?.datas?.length}</span></h6>
         
         {note?.datas?.map((n)=>(
         <div className='border mt-2 p-2 rounded border-slate-100'>
              <div className='border-b flex items-center justify-between pb-2 mb-2 border-slate-100'> 
                 <h6 className='text-[11px] flex items-center '><CiStickyNote size={21} className='bg-slate-100 text-blue-600 p-1 mr-2' /> <span className='font-[500] mr-1'>Note </span> by <span  className='font-[500] ml-1'> {n?.user_id?.name}</span></h6>
                 <h6 className='text-[10px] text-slate-400 flex items-center '><AiOutlineCalendar size={21} className='p-1 mr-1' /> <span className='font-[500] mr-1'>{moment(n?.createdAt)?.format('lll')}</span></h6>
              </div>   
              <h6 className='text-[12px] font-[500] mb-1'>{n?.note_title}</h6>
              <h6 className='leading-tight text-slate-400 text-[11px] font-[400]'>{n?.summary}</h6>
              {(n?.note_file !== null && n?.note_file !== '' && n?.note_file !== undefined) &&
                         <>
                         <div className='mt-1 border-slate-100  flex relative py-1'>
                          <img src='https://cdn-icons-png.flaticon.com/512/179/179483.png' className='w-5 h-5 object-contain' />
                          <div className='ml-1 -mt-0.5'>
                          <h6 className='font-[600] text-[11px]'>{returnfilename(n?.note_file)}</h6>
                          <h6 className='font-[600] text-[9px] -mt-0.5'>{getFileSizeString(n?.filesize)} </h6>
                          </div>
                         {(n?.note_file !== null && n?.note_file !== undefined && n?.note_file !== '') && <AiOutlineCloudDownload size={23} className='absolute right-1 top-1.5 bg-slate-100 p-1 rounded-full' onClick={()=>handleDownload(`${process.env.REACT_APP_BACKEND_IMAGE_URL}${n?.note_file}`,returnfilename(n?.note_file))}/>}

                          </div>
                          </>}
         </div>
         ))}
         </>}


       </div>
    </Drawer>

    <Modal open={department_share} width={300} className='absolute top-0 left-[42%]' closable={false} footer={false}>
       <div>

       <h6 className='text-[12px] font-semibold mb-1 mt-1'>Department</h6>
         <Select
           value={selected_department} 
           mode="multiple"
           bordered={false}
           placeholder="" 
           onChange={(e)=>handleSelect(e,'Department')} 
           styles={customStyles} 
           options={DepartmentArr}
           className='border-l-4 border-l-slate-600 w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none'
           />
         {error.stage !== '' && error.stage !== undefined &&
         <div className='flex items-center mt-1'>
         <BiErrorCircle className='text-red-500' size={14} />
         <span className='text-[10px] text-red-500 ml-1'>{error.stage}</span>
         </div>}


         {selected_department?.map((s,i)=>(
            <div>
                <TextAreaInput1 
                    label={`${s?.label} Summary`}  
                    variant="standard"
                    name="address"
                    type="text"
                    value={s.summary}
                    error={error.address}
                    handlechange={(e)=>updatesummary(i,e)}
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}/>

            </div>
         ))}


        <div className='flex justify-end border-t pt-2'>
         <ButtonOutlinedAutoWidth btnName="Cancel" onClick={()=>setdepartmentShare(false)} />
         <h6 className='w-[8px]'></h6>
         <ButtonFilledAutoWidth btnName="Save" onClick={MdLeadAssignsubmitData} />
        </div> 

       </div>
    </Modal>

    <Modal open={modal} width={350} closable={false} footer={false} className='absolute top-0 left-[40%]'>
          <div className='absolute right-6'>
           <a href="https://fidecrmfiles.s3.amazonaws.com/Contact%2BUpload%2BFormat+(1)+(1).xlsx" downlaod> <h6 className='text-[10px] cursor-pointer font-[700] flex underline items-center'><AiOutlineFileExcel className='mr-1' /> Download Sample</h6></a>
          </div>
          <h6 className="font-bold text-[13px]  mb-2 w-full">Upload Excel</h6>
          <Uploader image={excel?.file}  setimagefunc={(e)=>{setexcel({...excel,file:e,error:''})}}  removeimageuploadfunc = {()=>setexcel({...excel,file:'',error:''})} />

          <div className='mt-2 flex items-center'>
            <ButtonOutlinedAutoWidth btnName="Close" onClick={()=>setmodal(false)}/>
            <div className='ml-2'>
            <ButtonFilledAutoWidth btnName="Save" onClick={()=>uploadExcel()} />
            </div>
          </div>
    </Modal> 

   
    {list?.includes(path) ? 
      <div  className="w-[98%]">
        <div  >
          <div className='flex items-center justify-between border-b pb-2'>
            <h6 className='font-[700] text-[14px] '>Total {type} ({leads?.pagination?.total})</h6>
            <div className='flex items-center'>
              <div className='flex items-center text-[12px] mr-2'>
              {path === 'contacts' &&
                <>
                <AiOutlineFileExcel onClick={()=>setmodal(true)} size={24}  className='mr-1 bg-gray-200 p-1.5' />
                </>
                }

                <h6 className='mr-2 font-[600]'>{page == 1 ? leads?.datas?.length > 0 ? 1 : 0 : (page - 1) * leads?.pagination?.limit } - {leads?.pagination?.limit} of {leads?.pagination?.total} </h6>
                <IconButton onClick={resetfunc}><BsArrowRepeat size={16} /></IconButton>

                <div>
                <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>
                <IconButton onClick={()=>{ page < leads?.pagination?.totalPages  ? setpage(page+1) : console.log('')}}><FiChevronRight className={`${(leads?.pagination?.totalPages === page || leads?.datas?.length === 0)  ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>

                </div>
              </div>


              <DatePicker size='small' style={{fontSize:'11px'}} ampm={false} placeholder='From Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.from_date} onChange={(v) => {console.log('v na',new Date(v).toLocaleDateString());setsearch({...search,from_date:v})}} /> 

              <DatePicker size='small' style={{fontSize:'11px'}} ampm={false} placeholder='To Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.to_date} onChange={(v) => {console.log('v na',new Date(v).toLocaleDateString());setsearch({...search,to_date:v})}} /> 

            
            <div className='mr-2'>
                

              <input  id="search_text" placeholder='Search text' type='text' value={search.text} onChange={(e)=>setsearch({...search,text:e.target.value})} className='border py-[3px] focus:ring-0 focus:outline-0 text-[14px]  w-28 px-1 rounded-md border-slate-300' />
          
            </div>      

          <ButtonOutlinedAutoWidth btnName="Add Filter" onClick={()=>applyfilterfunction(1)} /> 
          {leads_selected_list?.length > 0 &&
          <div className='ml-2'>
          <ButtonFilledAutoWidth onClick={createManyContactBasedOnlIST} btnName="Create Contact" /> 
          </div>}
          <div className='mx-2'>
          <ButtonFilledAutoWidth btnName="Add Data" onClick={()=>navigate('create')}/> 
          </div>
          <TbAdjustmentsHorizontal onClick={()=>setdesigntable(!designtable)} size={28} className='bg-slate-600 text-white cursor-pointer rounded-[4px] p-[6px]' />

            </div>
         </div>
        
        {leads?.datas?.length === 0 &&
          <div className='grid place-items-center mt-20  items-center justify-center'>
          <img src={'https://fidecrmfiles.s3.amazonaws.com/NoDataFound1.png'} className='w-40 h-40 object-contain' /> 
          <h6 className='font-bold text-[14px] -mt-10'>No data found</h6>
          <h6 className='font-[500] w-[70%] text-center text-slate-700 text-[12.5px] -mt-2'>Oops we couldn't find any data based on your current stage please add the data to see list here.</h6>
        </div>
        }

        {leads?.datas?.length > 0 &&
        <div className='max-h-[85vh] mt-4 border-t   border-l border-r overflow-y-scroll'>
        <div className='sticky top-0 z-50 flex  bg-white py-[-5px] border-b border-slate-200 relative'>
            <h6  className='top-0 bg-white z-50 text-[12px] w-10  px-2 py-1 font-[600] text-slate-600 border-r flex items-center justify-center'> <p className='w-[13px] h-[13px] rounded-[2px] border p-[1.6px] flex items-center justify-center' > </p> </h6>
            {table_heading?.contact_name && <h6 className='sticky top-0 z-50  text-[12px] w-[170px]  px-2 py-1 font-[600] text-slate-600 border-r'>Contact Name</h6>}
            {table_heading?.mobile && <h6 className='sticky top-0 z-50  text-[12px] w-[130px]  px-2 py-1 font-[600] text-slate-600 border-r'>Mobile</h6>}
            {table_heading?.designation && <h6 className='sticky top-0 z-50  text-[12px] w-[130px]  px-2 py-1 font-[600] text-slate-600 border-r'>Designation</h6>}
            {table_heading?.email && <h6 className='sticky top-0 z-50  text-[12px] w-[130px]  px-2 py-1 font-[600] text-slate-600 border-r'>Email</h6>}
            {table_heading?.company_name && <h6 className='sticky top-0 z-50  text-[12px] w-[170px] px-2 py-1 font-[600] text-slate-600 border-r'>Company Name</h6>}
            {table_heading?.industry && <h6 className='sticky top-0 z-50  text-[12px] w-[130px] px-2 py-1 font-[600] text-slate-600 border-r'>Industry</h6>}
            {table_heading?.lead_status && <h6 className='sticky top-0 z-50  text-[12px] w-[100px] px-2 py-1 font-[600] text-slate-600 border-r'>Lead Status</h6>}
            {table_heading?.contact_stage && <h6 className='sticky top-0 z-50  text-[12px] w-[100px] px-2 py-1 font-[600] text-slate-600 border-r'>Contact Status</h6>}
            {table_heading?.lead_owner && <h6 className='sticky top-0 z-50  text-[12px] w-[120px] px-2 py-1 font-[600] text-slate-600 border-r'>Lead Owner</h6>}
            {table_heading?.lead_operator && <h6 className='sticky top-0 z-50  text-[12px] w-[150px] px-2 py-1 font-[600] text-slate-600 border-r'>Operators</h6>}
            {table_heading?.sqft_info && <h6 className='sticky top-0 z-50  text-[12px] w-[140px] px-2 py-1 font-[600] text-slate-600 border-r'>Sqft/Seater/Acres</h6>}
            {table_heading?.location && <h6 className='sticky top-0 z-50  text-[12px] w-[120px] px-2 py-1 font-[600] text-slate-600 border-r'>Location</h6>}
            {table_heading?.requirment && <h6 className='sticky top-0 z-50  text-[12px] w-[150px] px-2 py-1 font-[600] text-slate-600 border-r'>Requirment</h6>}
            {table_heading?.called_status && <h6 className='sticky top-0 z-50  text-[12px] w-[70px] px-2 py-1 font-[600] text-slate-600 border-r'>Called</h6>}
            {table_heading?.follow_up && <h6 className='sticky top-0 z-50  text-[12px] w-[70px] px-2 py-1 font-[600] text-slate-600 border-r'>FollowUp</h6>}
            {table_heading?.follow_up_date && <h6 className='sticky top-0 z-50  text-[12px] w-[120px] px-2 py-1 font-[600] text-slate-600 border-r'>Follow Up Date</h6>}
            {table_heading?.follow_up_remark && <h6 className='sticky top-0 z-50  text-[12px] w-[150px] px-2 py-1 font-[600] text-slate-600 border-r'>Remark</h6>}
            {table_heading?.created_At && <h6 className='sticky top-0 z-50  text-[12px] w-[100px]  px-2 py-1 font-[600] text-slate-600 border-r'>Created On</h6>}
            <h6 className='sticky top-0 z-50  text-[12px] w-[120px]  px-2 py-1 font-[600] text-slate-600 sticky right-0'>Actions</h6>
        </div>
      
        {leads?.datas?.map((d,i)=>(
        <div  key={d?._id} className={`flex z-50 border-b border-slate-200 z-0`}  >
            <h6 onClick={()=>selected_lead_check_to_group(d?._id)} className='sticky top-0 z-30 text-[12px] w-10  px-2 py-1 font-[600] text-slate-600 cursor-pointer border-r flex items-center justify-center'> <p className='w-[13px] h-[13px] rounded-[2px] border p-[1.6px] flex items-center justify-center' >{leads_selected_list?.includes(d?._id) &&  <p className='bg-slate-600 flex justify-center items-center rounded-[2px] w-[10px] h-[8px]'></p>} </p> </h6>

            {table_heading?.contact_name && <h6 className='text-[12px] font-[500] w-[170px]  px-2 py-1 truncate border-r  border-slate-200'>{d?.contact_name}</h6>}
            {table_heading?.mobile && <h6 className='text-[12px] font-[500] w-[130px]  px-2 py-1 truncate border-r  border-slate-200'>{d?.phone}</h6>}
            {table_heading?.designation && <h6 className='text-[12px] font-[500] w-[130px]  px-2 py-1 truncate border-r  border-slate-200'>{d?.designation}</h6>}
            {table_heading?.email && <h6 className='text-[12px] font-[500] w-[130px]  px-2 py-1 truncate border-r  border-slate-200'>{d?.email}</h6>}
            {table_heading?.company_name && <h6 className='text-[12px] font-[500] w-[170px] px-2 py-1 truncate border-r  border-slate-200'>{d?.company_name}</h6>}
            {table_heading?.industry && <h6 className='text-[12px] font-[500] w-[130px] px-2 py-1 truncate border-r  border-slate-200'>{d?.industry?.name}</h6>}
            {table_heading?.lead_status && <h6 className='text-[12px] font-[500] w-[100px] px-2 py-1 truncate border-r  border-slate-200'>{d?.stage?.name}</h6>}
            {table_heading?.contact_stage && <h6 className='text-[12px] font-[500] w-[100px] px-2 py-1 truncate border-r  border-slate-200'>{d?.contact_stage}</h6>}
            {table_heading?.lead_owner && <h6 className='text-[12px] font-[500] w-[120px] px-2 py-1 truncate border-r  border-slate-200'>{d?.lead_owner?.name}</h6>}
            {table_heading?.lead_operator && <h6 className='text-[12px] font-[500] w-[150px] px-2 py-1 truncate border-r  border-slate-200'>{d?.operators?.map((d)=><span className='bg-slate-100 mr-[4px] text-[8px] p-[4px] rounded font-[600]'>{d?.name?.split(' ')[0]}</span>)}</h6>}
            {table_heading?.sqft_info && <h6 className='text-[12px] w-[140px] px-2 py-1 font-[600] text-slate-600 border-r'>{d?.sqft_info}</h6>}
            {table_heading?.location && <h6 className='text-[12px] w-[120px] px-2 py-1 font-[600] text-slate-600 border-r'>{d?.location}</h6>}
            {table_heading?.requirment && <h6 className='text-[12px] w-[150px] px-2 py-1 font-[600] text-slate-600 border-r'>{d?.requirment}</h6>}
            {table_heading?.called_status && <h6 className='text-[12px] font-[500] w-[70px] px-2 py-1 truncate border-r  border-slate-200'>{!d?.called_status ? <BiCheckbox size={20} className='text-slate-400' /> : <BiCheckboxSquare size={20} className='text-slate-700' /> }</h6>}
            {table_heading?.follow_up && <h6 className='text-[12px] font-[500] w-[70px] px-2 py-1 truncate border-r  border-slate-200'>{!d?.follow_up ? <BiCheckbox size={20} className='text-slate-400' /> : <BiCheckboxSquare size={20} className='text-slate-700' /> }</h6>}
            {table_heading?.follow_up_date && <h6 className='text-[12px] font-[500] w-[120px] px-2 py-1 truncate border-r  border-slate-200'>{moment(d?.follow_up_date)?.format('LLL')}</h6>}
            {table_heading?.follow_up_remark && <h6 className='text-[12px] font-[500] w-[150px] px-2 py-1 truncate border-r  border-slate-200'>{d?.follow_up_remark}</h6>}
            {table_heading?.created_At && <h6 className='text-[12px] font-[500] w-[100px]  px-2 py-1 truncate border-r  border-slate-200 sticky right-0'>{moment(d?.createdAt).format('ll')}</h6>}
            <h6  className='text-[12px] w-[120px]  px-2 py-1 flex'>
              <Tooltip title="Detail">
              <span><RiBillLine size={13} onClick={()=>navigate(`detail`,{state:d?._id})} className=" z-10"/></span>
              </Tooltip>
              {path !== 'invoice_raised' &&
              <Tooltip title="Edit">
              <span><AiOutlineEdit size={13} className="z-10 ml-2" onClick={()=>navigate(`/leads/${path}/edit`,{state:d?._id})}/></span>
              </Tooltip>}

              <Tooltip title="Preview">
              <span><VscOpenPreview size={13} className="z-10 ml-2" onClick={()=>{getnote_attachment_basedonselectedlead(d?._id);setselected_lead(d);setpreview(true)}}/></span>
              </Tooltip>
              {user?.roles?.includes('delete_data') &&
              <Tooltip title="Delete">
              <span><AiOutlineDelete size={13} className="z-10 ml-2 -mt-[1px]" onClick={()=>{deleteData(d?._id)}}/></span>
              </Tooltip>}
              {(user?.roles?.includes('hod') || user?.roles?.includes('admin')) &&
              <Tooltip title="MD Share">
              <span><TbScreenShare size={13} className="z-10 ml-2" onClick={()=>{setselected_lead(d);setdepartmentShare(true)}}/></span>
              </Tooltip>}
              

            
            </h6>
        </div>))}
        </div>}
        </div>

        
      


      </div>
      :
      <div>
          <DailyTask />
      </div>  
    }

    
   
    </div>
  )
}

export default Leadlist