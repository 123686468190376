import React from 'react'

export function ButtonFilled(props) {
  return (
    <button onClick={props.onClick} className="bg-slate-600 text-white font-[500] py-[5px] px-4 mt-0  w-full rounded-md border-slate-600  text-[12px] hover:border-slate-600">
      {props.btnName} 
    </button>
  )
}

export function ButtonFilledAutoWidth(props) {
  return (
    <button onClick={props.onClick} className="border border-slate-600 bg-slate-600 text-white font-[500] py-[5px] px-4 mt-0  rounded-md border-slate-600 text-[12px] hover:border-slate-600">
      {props.btnName} 
    </button>
  )
}


export function ButtonOutlined(props) {
  return (
    <button onClick={props.onClick} className="border border-slate-300 bg-[#f6f8fa] text-[#000] font-[500] py-[5px] px-4 mt-0 rounded-md  text-[12px]  border-[#d4d8da] hover:bg-[#f2f4f5]">
      {props.btnName} 
    </button>
  )
}


export function ButtonOutlinedAutoWidthSmall(props) {
  return (
    <button onClick={props.onClick} className="border border-slate-300 bg-[#f6f8fa] text-[#000] font-[500] py-[5px] px-4 mt-0 rounded-md  text-[12px]  border-[#d4d8da] hover:bg-[#f2f4f5]">
      {props.btnName} 
    </button>
  )
}

export function ButtonOutlinedAutoWidth(props) {
  return (
    <button onClick={props.onClick} className="border border-slate-300 bg-[#f6f8fa] text-[#000] font-[500] py-[5px] px-4 mt-0 rounded-md  text-[12px]  border-[#d4d8da] hover:bg-[#f2f4f5]">
      {props.btnName} 
    </button>
  )
}

export function ButtonOutlinedAutoWidthError(props) {
  return (
    <button onClick={props.onClick} className="border border-slate-300 text-[#ce222e] font-[500] py-[5px] px-4 mt-0 rounded-md  text-[12px] border-[#d4d8da] hover:bg-[#912434] hover:border-[#912434] hover:text-[#fff]">
      {props.btnName} 
    </button>
  )
}