import { Box } from '@mui/material'
import React,{useState,useEffect} from 'react'
import { useLocation,useNavigate } from 'react-router-dom'
import moment from 'moment';
import {AiOutlineStar,AiFillStar} from 'react-icons/ai';
import Avatar from '@mui/material/Avatar';
import { MobileDrawerAdd } from '../../components/appbars/mobile/DashboardMobile';
import { GetCompanyLeadsService, SearchCompanyLeadService } from '../../services/AdminServicesfile/CompanyLeadServices';
import {
    Pagination,
  } from "@mui/material";
import { GetDepartmentService, GetUsersByDepartment } from '../../services/DepartmentServices';
import { Select } from 'antd';
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../components/button';
import { GetStageService } from '../../services/StageServices';
import { DatePicker } from 'antd'
import {Drawer} from 'antd';
import { TextInput } from '../../components/input';
import {IoMdClose} from 'react-icons/io';


function AdminLeads() {

    const location = useLocation();
    const navigate = useNavigate();
  
    const [leads,setleads]  = useState([])
    const [type,settype] = useState(null)

    const [selected_department,setselected_department] = useState(null)
    const [selected_user,setselected_user] = useState(null)
    const [selected_stage,setselected_stage] = useState(null)

    const [departments,setdepartments] = useState([])
    const [users,setusers] = useState([])
    const [stages,setstages] = useState([])
    const [page,setpage] = useState(1)
    const [filter_drawer,setfilter_drawer] = useState(false)

    const [search,setsearch] = useState({text:'',from_date:'',to_date:'',activate:false})
  
  
    useEffect(()=>{
      getleads(1)
    },[location.pathname])

    useEffect(()=>{
        getleads(page)
    },[page,selected_user,selected_department,selected_stage])


    useEffect(()=>{
    getdepartment()
    getstage()
    },[])



    async function getstage(){
    const response = await GetStageService('Lead_Stage')
    let arr = []
    // console.log("response?.data?.datas kp",response?.data)
    response?.data?.data?.datas?.forEach((d)=>{
      if(d?.name !== 'Calls' && d?.name !== 'Walk-Ins' && d?.name !== 'Meetings' && d?.name !== 'Property-Visit'){
        arr.push({label:d?.name,value:d?._id})
     }
    })
    setstages(arr)
    }


    async function getdepartment(){
    const response = await GetDepartmentService();
    let data = response?.data.datas.filter((d)=>d.department_name === 'Transaction Team')
    let arr = []
    setselected_department({label:data[0]?.department_name,value:data[0]?.id})
    response.data.datas.forEach((d)=>{
        arr.push({label:d?.department_name,value:d?.id})
    })
    setdepartments(arr)
    getuserbaseddepartment(data[0]?.id)
    }

    

    async function getuserbaseddepartment(id){
      const response = await GetUsersByDepartment(id) 
      let arr = []
      response.data.datas.forEach((d)=>{
          arr.push({label:d?.name,value:d?._id})
      })
      setusers(arr)
    }

    async function applyfilterfunction(page){
        // console.log("selected_department",selected_department)
        // console.log("selected_user",selected_user)
        // console.log("selected_stage",selected_stage)

        // console.log("search",search)
        setsearch({...search,activate:true})

        let start_date = new Date(search?.from_date).toLocaleDateString().replaceAll('/','-').split('-').reverse().join('-')
        let end_date = new Date(search?.to_date).toLocaleDateString().replaceAll('/','-').split('-').reverse().join('-')
        const response = await SearchCompanyLeadService({search_text:search.text,status:selected_stage?.label !== undefined  ? selected_stage?.label : '',from_date:(search.from_date !== '' && search.from_date !== null ) ? start_date : '',to_date:(search.to_date !== '' && search.to_date !== null ) ? end_date : '',page:page,department_id:selected_department.value,employee_id:selected_user?.label !== undefined ? selected_user?.label : ''})
        // console.log('response',response)
        // const response = await SearchLeadService({search_text:search.text,status:type,from_date:moment(search?.from_date).format('L'),to_date:moment(search?.to_date).format('L'),page:page})
        setleads(response.data)
        setfilter_drawer(false)
    }
    
    async function resetfunc(){
        setsearch({text:'',from_date:'',to_date:'',activate:false})
        resetfilter()
        setfilter_drawer(false)
    }
  
    async function getleads(page){
        // console.log('selected_stage',selected_stage)
        let type = ''
        if(selected_stage?.label === 'Contact' ) type = 'Contact'
        if(selected_stage?.label === 'Options Shared' )  type = 'Options Shared'
        if(selected_stage?.label === 'Inspection' )  type = 'Inspection'
        if(selected_stage?.label === 'LOI/Agreement' )  type = 'LOI&Agreement'
        if(selected_stage?.label === 'Invoice Raised' )  type = 'Invoice Raised'
        if(selected_stage?.label === 'Calls' )  type = 'Calls'
        if(selected_stage?.label === 'Walk-Ins' )  type = 'Walk-Ins'
        if(selected_stage?.label === 'Property-Visit' )  type = 'Property-Visit'
        if(selected_stage?.label === 'Meetings' )  type = 'Meetings'
        if(selected_stage?.label === 'Hold' )  type = 'Hold'
        
        // console.log("type krishna",type)
        // console.log("selected stage krishna",selected_stage)
        // if(location.pathname === 'Cl' )  type = 'Hold '
        // settype(type)
        const response =  await GetCompanyLeadsService(selected_department?.value !== undefined ? selected_department?.value : null,selected_stage?.value !== undefined  ? (selected_stage?.label !== 'LOI/Agreement' ? selected_stage?.label : type) : null,selected_user?.value !== undefined ? selected_user?.value : null,page);
        setleads(response?.data)
    }
  
    async function updateImportance(v){
  
    }

    async function handlechange(val,type){

        if(type === 'department'){
            let department = departments.find(d=>d.value == val)
            // console.log("department anna",department)
            if(selected_department.value !== department.value){
                setpage(1)
                setselected_department(department)
                getuserbaseddepartment(val)
            }
        }else if(type === 'users'){
            setpage(1)
            setselected_user(users.find(d=>d.value == val))
        }else if(type === 'stages'){
            setpage(1)
            setselected_stage(stages.find((d)=>d.value === val))
        }
    //   console.log("value",val)
    //   console.log("type",type)
    }

    const handlePagination = (e, pageNumber) => {
        setpage(pageNumber)
    }

    const resetfilter=()=>{
        setselected_user(null)
        setselected_stage(null)
        let department = departments.find(d=>d.label == 'Transaction Team')
        setselected_department(department)
        setfilter_drawer(false)
    }


  return (
    <div style={{width:'100%',minHeight:'100vh',maxHeight:'100vh',height:'100%',position:'relative'}}>


    <Drawer
      anchor={'right'}
      open={filter_drawer}
      closable={false}
      width={260}
    >
      <div className=' w-52 -mt-3'>
        <span onClick={()=>setfilter_drawer(!filter_drawer)} ><IoMdClose className='absolute right-2 cursor-pointer' /></span>
        <h6 className='text-[13px]'>Use the below form to apply filter for your leads</h6>


        <TextInput 
              mandatory={false}
              label={'Search Text'}  
              variant="standard"
              name="text"
              type="text"
              value={search.text}
              handlechange={e=>setsearch({...search,text:e.target.value})}
              placeholder="Enter contact name"
              InputLabelProps={{
                  style: { color: '#fff', }, 
              }}/>


         
          
          <div>
          <h6 className='text-[12px] font-[600] mb-1 mt-2' >{'From Date'}</h6>
          <div>
         <DatePicker ampm={false} placeholder='' className='w-full'  value={search?.from_date} onChange={(v) => {setsearch({...search,from_date:v})}} /> 
         </div>    
         <h6 className='text-[12px] font-[600] mb-1 mt-2' >{'To Date'}</h6>
         <div>
         <DatePicker ampm={false} placeholder='' className='w-full'  value={search?.to_date} onChange={(v) => {setsearch({...search,to_date:v})}}  /> 
         </div>
         </div>




         
         <div className='flex mt-4'>
         <div  className="mr-2" >
         <ButtonOutlinedAutoWidth btnName="Reset" onClick={resetfunc}/>   
         </div>
         <ButtonFilledAutoWidth btnName="Apply" onClick={()=>applyfilterfunction(1)} />   
         </div>

      </div>
    </Drawer>    
    <Box sx={{display:{sm:'block',md:'none',lg:'none'}}}>
    <MobileDrawerAdd name={type} />
    </Box> 
    
    <Box  sx={{marginTop:{sm:'0px',xs:'0px',md:'20px',lg:'20px'},padding:{xs:'0px',sm:'0px',md:'10px',lg:'10px'}}}>
       <Box sx={{display:{sm:'none',xs:'none',lg:'block',md:'block'}}}>
        <div className='flex items-center justify-between border-b pb-2 mb-5'>
       <h6 className='font-[700] text-[14px] '>Company Leads ({leads?.pagination?.total})</h6>


       
       <div className='flex items-center justify-between' style={{width:'650px'}}>
       <Select
            placeholder='Select Department'
            defaultValue={selected_department}
            value={selected_department}
            style={{ width: 150 }}
            onChange={(v)=>handlechange(v,'department')}
            options={departments}
        />

        <Select
            placeholder='Select Users'
            defaultValue={selected_user}
            value={selected_user}
            style={{ width: 150 }}
            onChange={(v)=>handlechange(v,'users')}
            options={users}
        />

        <Select
            placeholder='Select Stages'
            defaultValue={selected_stage}
            value={selected_stage}
            style={{ width: 150 }}
            onChange={(v)=>handlechange(v,'stages')}
            options={stages}
        />

        {/* <DatePicker placeholder='From Date' />
        <DatePicker placeholder='To Date' /> */}


        
       <div>
       <ButtonFilledAutoWidth btnName="Add Filter" onClick={()=>setfilter_drawer(!filter_drawer)} /> 
       </div> 

        <ButtonFilledAutoWidth btnName="Reset Filter" onClick={resetfilter} />

        </div>
       </div>


       
       <div className='flex bg-[#f5f5f5]'>
          <h6 className='text-[13px] w-20  px-2 py-1 font-[600]'>SL NO</h6>
          <h6 className='text-[13px] w-1/5  px-2 py-1 font-[600]'>CONTACT NAME</h6>
          <h6 className='text-[13px] w-1/5  px-2 py-1 font-[600]'>MOBILE</h6>
          <h6 className='text-[13px] w-1/5  px-2 py-1 font-[600]'>EMAIL</h6>
          <h6 className='text-[13px] w-1/5 px-2 py-1 font-[600]'>COMPANY NAME</h6>
          <h6 className='text-[13px] w-1/5 px-2 py-1 font-[600]'>LEAD STAGE</h6>
          <h6 className='text-[13px] w-1/5 px-2 py-1 font-[600]'>LEAD OWNER</h6>
          <h6 className='text-[13px] w-1/5  px-2 py-1 font-[600]'>CREATED ON</h6>
          {/* <h6 className='text-[13px] w-1/5  px-2 py-1 font-[600]'>ACTIONS</h6> */}
       </div>
     

       {leads?.datas?.map((d,i)=>(
       <div key={d?._id} className='flex border-b cursor-pointer z-0'  >
          <h6  onClick={()=>navigate('detail',{state:d?._id})} className='text-[12px] w-[200px]  px-2 py-1 truncate'>{(page > 1 ? i+1+ (25 * (page - 1)) : i+1 )}</h6>
          <h6  onClick={()=>navigate('detail',{state:d?._id})} className='text-[12px] w-1/5  px-2 py-1 truncate'>{d?.contact_name}</h6>
          <h6  onClick={()=>navigate('detail',{state:d?._id})} className='text-[12px] w-1/5  px-2 py-1 truncate'>{d?.phone}</h6>
          <h6  onClick={()=>navigate('detail',{state:d?._id})} className='text-[12px] w-1/5  px-2 py-1 truncate'>{d?.email}</h6>
          <h6  onClick={()=>navigate('detail',{state:d?._id})} className='text-[12px] w-1/5 px-2 py-1 truncate'>{d?.company_name}</h6>
          <h6  onClick={()=>navigate('detail',{state:d?._id})} className='text-[12px] w-1/5 px-2 py-1 truncate'>{d?.stage?.name}</h6>
          <h6  onClick={()=>navigate('detail',{state:d?._id})} className='text-[12px] w-1/5 px-2 py-1 truncate'>{d?.lead_owner?.name}</h6>
          <h6  onClick={()=>navigate('detail',{state:d?._id})} className='text-[12px] w-1/5  px-2 py-1 truncate'>{moment(d?.createdAt).format('ll')}</h6>
          {/* <h6  className='text-[12px] w-1/5  px-2 py-1 flex'>
           {d?.important === false ?
           <Tooltip title="star">
           <span> <AiOutlineStar size={20} className='mr-2 z-10' style={{color:'#c7c7c7'}} onClick={()=>updateImportance(d)}/></span>
           </Tooltip>
            :
           <Tooltip title="star">
            <span><AiFillStar size={20} className='mr-2 z-10' style={{color:'#fccb42'}} onClick={()=>updateImportance(d)} /></span>
           </Tooltip>
            }
            <Tooltip title="Reminder">
            <span><HiOutlineBellAlert size={17} onClick={()=>navigate(`detail/reminder`,{state:d?._id})} className="mr-2 z-10"/></span>
            </Tooltip>
            <Tooltip title="Edit">
            <span><AiOutlineEdit size={17} className="z-10" onClick={()=>navigate('edit',{state:d?._id})}/></span>
            </Tooltip>
          </h6> */}
       </div>))}
       </Box>

       <Box sx={{display:{sm:'block',xs:'block',lg:'none',md:'none'}}}>
       {leads?.datas?.map((d,i)=>(
        <div key={d._id}  className={`px-1 py-2 flex relative ${i%2 === 0 && 'bg-[#f5fffe]'}`}>
        <Avatar onClick={()=>navigate('detail',{state:d?._id})} sx={{ width: 35, height: 35,fontSize:'17px',textTransform:'uppercase' }} style={{backgroundColor:`#${d?.color.split('0xff')[1]}`}}>{d?.contact_name?.slice(0,1)}</Avatar>
        <div className='ml-2' onClick={()=>navigate('detail',{state:d?._id})}>
          <h5 className='text-[14px] font-[800] mb-0 pb-0'>{d?.contact_name}</h5>
          <h5 className='text-[13px] font-[600] mb-0 -mt-1 pb-0'>{d?.company_name}</h5>
          <h5 className='text-[12px] -mt-1 mb-0 pb-0 '>{d?.stage?.name}</h5>
        </div>
        <h6 className='absolute right-2 top-1 text-[9px]'>{moment(d?.createdAt).format('ll')}</h6>
        {d?.important === false ?
        <AiOutlineStar size={20} className='absolute right-2 top-5' style={{color:'#c7c7c7'}} onClick={()=>updateImportance(d)}/>
        :
        <AiFillStar size={20} className='absolute right-2 top-5' style={{color:'#fccb42'}} onClick={()=>updateImportance(d)} />
        }
        </div>
       ))}

       </Box>
     


    </Box>

    
     <div className="tex-center flex items-center justify-center mt-10 ">
      {(leads?.pagination?.total > 25 &&
        <Pagination size={'small'} page={page} className="flex justify-center my-10 pb-20" count={Math.ceil(leads?.pagination?.total / 25) } onChange={handlePagination} />
      )}
    </div>
    </div>
  )
}

export default AdminLeads