import { IconButton, Tooltip } from "@mui/material";
import React, { useState,useEffect } from "react";
import { BsArrowRepeat } from "react-icons/bs";
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import {
  ButtonFilledAutoWidth,
  ButtonOutlinedAutoWidth,
} from "../../components/button";
import { DatePicker, Modal, Select } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { deleteInspectionReview, getInspectionReview, getadminInspectionReview } from "../../services/LeadServices";
import moment from "moment";
import { RiBillLine } from "react-icons/ri";
import { AiOutlineEdit,AiOutlineClose,AiOutlineDelete } from "react-icons/ai";
import toast from "react-hot-toast";
import DashboardMenu from "../dashboard/DashboardMenu";
import { GetDepartmentService, GetUsersByDepartment } from "../../services/DepartmentServices";


function AdminInspectionReview() {


  const navigate = useNavigate();
  const {pathname} = useLocation()
  const {id} = useParams()
  const path = pathname?.split('/')[pathname?.split('/')?.length - 1]
  const [search, setsearch] = useState({
    text: "",
    from_date: "",
    from_date1: "",
    to_date1: "",
    to_date: "",
    activate: false,
  });
  const [page, setpage] = useState(1);
  const [data, setdata] = useState({});
  const [step,setstep] = useState(1)
  const [selectedData,setselectedData] = useState({})
  const [modal,setmodal] = useState(false)
  const [departments,setdepartments] = useState([])
  const [selected_department,setselected_department] = useState(null)
  const [selected_user,setselected_user] = useState(null)
  const [users,setusers] = useState([])



  useEffect(()=>{
    getdepartment()
  },[])

  useEffect(()=>{
    getData()
    
  },[step,page])

  async function getdepartment(){
    const response = await GetDepartmentService();
    let data = response?.data.datas.filter((d)=>d.department_name === 'Transaction Team')
    let arr = []
    setselected_department({label:data[0]?.department_name,value:data[0]?.id})
    response.data.datas.forEach((d)=>{
        arr.push({label:d?.department_name,value:d?.id})
    })
    setdepartments(arr)
    getuserbaseddepartment(data[0]?.id)
    }

  async function getuserbaseddepartment(id){
    const response = await GetUsersByDepartment(id) 
    let arr = []
    response.data.datas.forEach((d)=>{
        arr.push({label:d?.name,value:d?._id})
    })
    setusers(arr)
    }

  async function getData(){
    const response = await getadminInspectionReview(step,search?.text,search?.from_date1,search?.to_date1,page,selected_user?.value !== undefined ? selected_user?.value : '')
    setdata(response?.data?.data)
  }

  async function resetfunc() {
    setsearch({
      text: "",
      from_date: "",
      from_date1: "",
      to_date1: "",
      to_date: "",
      activate: false,
    });
    setselected_user(null)
    setpage(1)
    const response = await getadminInspectionReview(step,'','','',1,'')
    setdata(response?.data?.data)
  }

  async function applyfilterfunction() {
    const response = await getadminInspectionReview(step,search?.text,search?.from_date1,search?.to_date1,1,selected_user?.value !== undefined ? selected_user?.value : '')
    setdata(response?.data?.data)
  }

  async function deleteData(){
    const response = await deleteInspectionReview(selectedData?._id)
    if(response?.status == 200){
      toast.success("Lead Inspection Review Deleted Successfully")
      setmodal(false)
      getData()
    }
 
  }

  async function setstepfunc(v){
    setsearch({
      text: "",
      from_date: "",
      from_date1: "",
      to_date1: "",
      to_date: "",
      activate: false,
    });
    setselected_user(null)
    setstep(v)
    const response = await getInspectionReview(v,'','','',1)
    setdata(response?.data?.data)

  }

  async function handlechange(val,type){

    if(type === 'department'){
        let department = departments.find(d=>d.value == val)
        // console.log("department anna",department)
        if(selected_department.value !== department.value){
            setpage(1)
            setselected_department(department)
            getuserbaseddepartment(val)
        }
    }else if(type === 'users'){
        setpage(1)
        setselected_user(users.find(d=>d.value == val))
    }else if(type === 'stages'){
        setpage(1)
        // setselected_stage(stages.find((d)=>d.value === val))
    }

  }

  return (
    <div className="h-screen w-screen overflow-hidden max-h-screen">
      <Modal open={modal} closable={false} footer={false} width={300}>
        <div className="relative">
          <AiOutlineClose size={12} onClick={()=>setmodal(false)}  className="absolute cursor-pointer right-0 top-0" />
          <h6 className="text-[14px] font-[700]">Delete Data</h6>
          <h6 className="text-[11px] leading-[14px] bg-slate-100 p-1">Are you sure before deleting be sure because once the user like to open the link generated it might throw error</h6>
          <h6 className="text-[11px] mt-2">Selected Client :  {selectedData?.lead?.company_name} / {selectedData?.lead?.contact_name}</h6>

          <div className="pt-2 flex justify-end border-t mt-2">
            <div className="mr-2">
            <ButtonOutlinedAutoWidth btnName="Cancel" onClick={()=>setmodal(false)} />
            </div>
            <ButtonFilledAutoWidth btnName="Save" onClick={deleteData} />
          </div>
       
        </div>

      </Modal>
      <div className="flex">
        <DashboardMenu />
        <div className="w-[82%] p-2">
          <div className="flex items-center justify-between border-b pb-2">
            <h6 className="font-[700] text-[14px] ">Total Inspection Review ({data?.pagination?.total})</h6>
            <div className="flex items-center">
              <div className="flex items-center text-[12px] mr-2">
                <h6 className="mr-2 font-[600]">
                  {page == 1
                    ? data?.datas?.length > 0
                      ? 1
                      : 0
                    : (page - 1) * data?.pagination?.limit}{" "}
                  - {data?.pagination?.limit} of {data?.pagination?.total}{" "}
                </h6>
                <IconButton onClick={resetfunc}>
                  <BsArrowRepeat size={16} />
                </IconButton>

                <div>
                  <IconButton
                    onClick={() => {
                      page > 1 ? setpage(page - 1) : console.log("");
                    }}
                  >
                    <FiChevronLeft
                      className={`${page === 1 ? "opacity-50" : "opacity-100"}`}
                      size={16}
                    />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      page < data?.pagination?.totalPages
                        ? setpage(page + 1)
                        : console.log("");
                    }}
                  >
                    <FiChevronRight
                      className={`${
                        data?.pagination?.totalPages === page ||
                        data?.datas?.length === 0
                          ? "opacity-50"
                          : "opacity-100"
                      }`}
                      size={16}
                    />
                  </IconButton>
                </div>
              </div>

              <DatePicker
                size="small"
                style={{ fontSize: "11px" }}
                ampm={false}
                placeholder="From Date"
                className="text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2"
                value={search?.from_date}
                onChange={(v,v1) => {
                  setsearch({ ...search, from_date: v,from_date1:v1 });
                }}
              />

              <DatePicker
                size="small"
                style={{ fontSize: "11px" }}
                ampm={false}
                placeholder="To Date"
                className="text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2"
                value={search?.to_date}
                onChange={(v,v1) => {
                  setsearch({ ...search, to_date: v,to_date1:v1 });
                }}
              />

              <div className="mr-2">
                <input
                  id="search_text"
                  placeholder="Search text"
                  type="text"
                  value={search.text}
                  onChange={(e) =>
                    setsearch({ ...search, text: e.target.value })
                  }
                  className="border py-[3px] focus:ring-0 focus:outline-0 text-[14px]  w-28 px-1 rounded-md border-slate-300"
                />
              </div>

              <div className="mr-2">
              <Select
                    placeholder='Select Users'
                    defaultValue={selected_user}
                    value={selected_user}
                    style={{ width: 100 }}
                    onChange={(v)=>handlechange(v,'users')}
                    options={users}
                    bordered={false}
                    className="border rounded-[6px] border-slate-300 h-[30px]"
                />
                </div>

              <ButtonOutlinedAutoWidth
                btnName="Add Filter"
                onClick={() => applyfilterfunction(1)}
              />
            </div>
          </div>

           <div className='flex  items-center border-b'>
              <h6 onClick={()=>setstepfunc(1)} className={`font-[600] ${step === 1 && 'bg-slate-600 text-white'} text-center cursor-pointer py-1 w-[80px] text-[11px]`}>Pending</h6>
              <h6 onClick={()=>setstepfunc(2)} className={`font-[600] ${step === 2 && 'bg-slate-600 text-white'} text-center cursor-pointer py-1 w-[80px] text-[11px]`}>Completed</h6>
           </div>

           {data?.datas?.length === 0 &&
              <div className='grid place-items-center mt-20  items-center justify-center'>
              <img src={'https://fidecrmfiles.s3.amazonaws.com/NoDataFound1.png'} className='w-40 h-40 object-contain' /> 
              <h6 className='font-bold text-[14px] -mt-10'>No data found</h6>
              <h6 className='font-[500] w-[70%] text-center text-slate-700 text-[12.5px] -mt-2'>Oops we couldn't find any data based on your current stage please add the data to see list here.</h6>
            </div>
            }

            {data?.datas?.length > 0 &&
            <div className='max-h-[85vh] mt-4 border-t   border-l border-r overflow-y-scroll'>
            <div className='sticky top-0 z-50 flex  bg-white py-[-5px] border-b border-slate-200 relative'>
                <h6  className='top-0 bg-white z-50 text-[12px] min-w-[60px] max-w-[60px]  px-2 py-1 font-[600] text-slate-600 border-r flex items-center justify-center'> SL No </h6>
                <h6 className='sticky top-0 z-50  text-[12px] w-[150px]  px-2 py-1 font-[600] text-slate-600 border-r'>Client Name</h6>
                <h6 className='sticky top-0 z-50  text-[12px] w-[120px]  px-2 py-1 font-[600] text-slate-600 border-r'>Phone No</h6>
                <h6 className='sticky top-0 z-50  text-[12px] w-[160px]  px-2 py-1 font-[600] text-slate-600 border-r'>Email</h6>
                <h6 className='sticky top-0 z-50  text-[12px] w-[160px]  px-2 py-1 font-[600] text-slate-600 border-r'>Company Name</h6>
                <h6 className='sticky top-0 z-50  text-[12px] w-[130px]  px-2 py-1 font-[600] text-slate-600 border-r'>Property Name</h6>
                <h6 className='sticky top-0 z-50  text-[12px] w-[200px] px-2 py-1 font-[600] text-slate-600 border-r'>Property Address</h6>
                <h6 className='sticky top-0 z-50  text-[12px] w-[100px]  px-2 py-1 font-[600] text-slate-600 border-r'>Status</h6>
                <h6 className='sticky top-0 z-50  text-[12px] w-[130px]  px-2 py-1 font-[600] text-slate-600 border-r'>Created By</h6>
                <h6 className='sticky top-0 z-50  text-[12px] w-[110px]  px-2 py-1 font-[600] text-slate-600 border-r'>Created On</h6>
                <h6 className='sticky top-0 z-50  text-[12px] w-[80px]  px-2 py-1 font-[600] text-slate-600 sticky right-0'>Actions</h6>
            </div>
          
            {data?.datas?.map((d,i)=>(
            <div  key={d?._id} className={`flex z-50 border-b border-slate-200 z-0`}  >
                <h6 className='sticky top-0 z-30 text-[12px] min-w-[60px] max-w-[60px]  px-2 py-1 font-[600] text-slate-600 cursor-pointer border-r flex items-center justify-center'>{(page > 1 ? i+1+ (data?.pagination?.limit * (page - 1)) : i+1 )} </h6>

                <h6 onClick={()=>navigate('/leads/inspections/detail',{state:d?.lead?._id})} className='text-[12px] font-[700] cursor-pointer w-[150px]  px-2 py-1 truncate border-r  text-blue-500 underline border-slate-200'>{d?.lead?.contact_name} </h6>
                <h6 className='text-[12px] font-[500] w-[120px]  px-2 py-1 truncate border-r  border-slate-200'>{d?.send_to_phone}</h6>
                <h6 className='text-[12px] font-[500] w-[160px]  px-2 py-1 truncate border-r  border-slate-200'>{d?.send_to_email}</h6>
                <h6 className='text-[12px] font-[500] w-[160px]  px-2 py-1 truncate border-r  border-slate-200'>{d?.lead?.company_name}</h6>
                <h6 className='text-[12px] font-[500] w-[130px]  px-2 py-1 truncate border-r  border-slate-200'>{d?.property_name}</h6>
                <h6 className='text-[12px] font-[500] w-[200px] px-2 py-1 truncate border-r  border-slate-200'>{d?.property_address}</h6>
                <h6 className='text-[12px] font-[500] w-[100px]  px-2 py-1 truncate border-r  border-slate-200'>{step == 1 ? <span className="text-[8px] text-slate-700 rounded-[4px] border font-[500] p-1 uppercase bg-slate-100">Pending</span> : <span className="text-[8px] text-slate-700 rounded-[4px] border font-[500] p-1 uppercase bg-slate-100">Completed</span>}</h6>
                <h6 className='text-[12px] font-[500] w-[130px]  px-2 py-1 truncate border-r  border-slate-200'>{d?.created_by?.name}</h6>
                <h6 className='text-[11px] font-[500] w-[110px]  px-2 py-1 truncate border-r  border-slate-200 sticky right-0'>{moment(d?.createdAt).format('ll')}</h6>
                <h6  className='text-[12px] w-[80px]  px-2 py-1 flex'>
                  
                  <>
                  <Tooltip title="Detail">
                  <span><RiBillLine size={13} onClick={()=>navigate(`detail`,{state:d?._id})} className=" z-10"/></span>
                  </Tooltip>

                  </>

                
                </h6>
            </div>))}
            </div>}
        </div>
      </div>
    </div>

  );
}

export default AdminInspectionReview