import React,{useState,useEffect} from 'react'
import Grid from '@mui/material/Grid';
import { TextAreaInput1, TextInput, TextInput1 } from '../../../components/input';
import { ButtonFilled, ButtonFilledAutoWidth, ButtonOutlined, ButtonOutlinedAutoWidth } from '../../../components/button';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import {Modal, Select } from 'antd';
import GoBack from '../../../components/back/GoBack';
import FcaresMenu from '../FcaresMenu';
import { GetFcaresUserService } from '../../../services/Fcares/FCaresUserServices';
import { CreateFcaresPropertyService, GetFcaresPropertyByIdService, UpdateFcaresPropertyService, UploadDocsFcaresPropertyService } from '../../../services/Fcares/FCaresPropertyServices';
import {IoClose} from 'react-icons/io5'
import { Tooltip } from '@mui/material'
import { AiOutlineDelete, AiOutlineEdit, AiOutlinePlus } from 'react-icons/ai';


function FCaresPropertyCE() {



  const [data,setdata] = useState({
    building_name:'',
    building_address:'',
    building_city:'',
    building_area:'',
    building_zone:'',
    floor:'',
    total_floor:'',
    property_available_for:'',
    minimun_deposit:'',
    monthly_maintenance_amount:'',
    bathroom:'',
    balcony:'',
    building_type:'',
    house_type:'',
    furnishing_type:'',
    builtup_area:'',
    building_age:'',
    amenities:[],
    expecting_rent:'',
    owner_type:'',
    image:'',
    image1:'',
    image2:'',
    image3:'',
    image4:'',
    building_docs:'',
    fidelitus_verified_docs:'',
    oc_verified:'',
    katha_type:'',
    car_parking:'',
    bike_parking:'',
    status:'',
    client:'',
    tenant:'',
    lock_in_period:'',
    proptype:'',
    fidelitus_maintenance:'',
  })

  const [owners,setowners] = useState([])
  const [amenities,setamenities] = useState([])
  const [amenities_data,setamenities_data] = useState({name:'',description:''})
  const [amenities_data_err,setamenities_data_err] = useState({name:'',description:''})

  const [deletedData,setdeleteData] = useState({index:0,type:'',data:{}})
  const [deleteModal,setdeleteModal] = useState(false)

  const status  = [{label:'Verified',value:'verified'},{label:'Not Verified',value:'not_verified'},{label:'Rejected',value:'rejected'}]
  const proptype  = [{label:'Ready To Occupy',value:'Ready To Occupy'},{label:'Occupied',value:'Occupied'}]
  const building_type = [{label:'apartment',value:'apartment'},{label:'independent floor',value:'independent floor'},{label:'independent house',value:'independent house'}]
  const house_type = [{label:'1 rk',value:'1 rk'},{label:'1 bhk',value:'1 bhk'}, {label:'2 bhk',value:'2 bhk'},{label:'3 bhk',value:'3 bhk'},{label:'4 bhk',value:'4 bhk'}]
  const furnishing_type = [{label:'semi furnished',value:'semi furnished'},{label:'fully furnished',value:'fully furnished'},{label:'unfurnished',value:'unfurnished'}]
  const age_type = [{label:'under construction',value:'under construction'},{label:'less than 1 year',value:'less than 1 year'},{label:'1-3 years',value:'1-3 years'},{label:'3-5 years',value:'3-5 years'},{label:'5-10 years',value:'5-10 years'},{label:'more than 10 years',value:'more than 10 years'}]
  const owner_type = [{label:'Tenant',value:'Tenant'},{label:'Owner',value:'Owner'},{label:'Land Lord',value:'Land Lord'}]
  const city_type = [{label:'Banglore',value:'Banglore'},{label:'Mysore',value:'Mysore'}]
  const area_type = [{label:'Banshankri',value:'Banshankri'},{label:'Jayanagar',value:'Jayanagar'},{label:'White Field',value:'White Field'},{label:'Kormangala',value:'Kormangala'},{label:'Electronic City',value:'Electronic City'},]
  const zone_type = [{label:'North',value:'North'},{label:'South',value:'South'},{label:'East',value:'East'},{label:'West',value:'West'},]
  const katha_type = [{label:'a katha',value:'a katha'},{label:'b katha',value:'b katha'}]
  

  
 
  const [loader, setloader] = useState(false);
  const [modal, setmodal] = useState(false);



  const [error,seterror] = useState({
    building_name:'',
    building_address:'',
    building_city:'',
    building_area:'',
    building_zone:'',
    floor:'',
    total_floor:'',
    property_available_for:'',
    minimun_deposit:'',
    monthly_maintenance_amount:'',
    bathroom:'',
    balcony:'',
    building_type:'',
    house_type:'',
    furnishing_type:'',
    builtup_area:'',
    building_age:'',
    amenities:'',
    expecting_rent:'',
    owner_type:'',
    image:'',
    image1:'',
    image2:'',
    image3:'',
    image4:'',
    building_docs:'',
    fidelitus_verified_docs:'',
    oc_verified:'',
    katha_type:'',
    car_parking:'',
    bike_parking:'',
    status:'',
    client:'',
    tenant:'',
    lock_in_period:'',
    proptype:''
  })

  const navigate = useNavigate()
  const location = useLocation()



   

  
  useEffect(()=>{


    if(location?.pathname?.split('/')[location?.pathname?.split('/')?.length -1] === 'edit'){
      geteditdata()
    }
    getOwners()
    // getamenetis()
  },[])

  async function geteditdata(){
    const response =  await GetFcaresPropertyByIdService(location?.state);
    let d = response?.data?.data

    let obj = {...d}
    let arr = []
    
    if(d?.amenities?.filter((f)=>f !== null)?.length > 0){
      d?.amenities?.filter((f)=>f !== null)?.forEach((d1)=>{
        if(d1?._id !== undefined){
          arr.push({label:d1?.name,value:d1?._id})
        }
      })
    }
    obj['amenities'] = arr
    obj['client'] = {label:d?.client?.name,value:d?.client?._id}

    let tenantsArr = []
    d?.tenant?.forEach((t)=>{
      tenantsArr.push({label:t?.name,value:t?._id})
    })
    obj['tenant'] = tenantsArr

    // console.log("d?.tenant",tenantsArr)
    setdata(obj)
  }




  async function getOwners(){
    const response = await GetFcaresUserService('','','',1,1)
    let d = response?.data?.data
    let arr = []
    d?.datas?.forEach((d1)=>{
      arr.push({label:d1?.name,value:d1?._id})
    })

    setowners(arr)
    // console.log(Response)
  }

 

  // console.log("data?.operators",data?.operators)
  function handlechange(e){
    setdata({...data,[e.target.name] : e.target.value})
    seterror({...error,[e.target.name] : ''})
  }

  function handlechange1(e){
    setamenities_data({...amenities_data,[e.target.name] : e.target.value})
    setamenities_data_err({...amenities_data_err,[e.target.name] : ''})
  }

  console.log("data?.tenant",data?.tenant)

  async function submitform(){
        console.log("clicked here")
        let arr = []
        let sendData = {...data}


        console.log("data?.tenant !== ''",data?.tenant !== '',data?.tenant)
        // data?.amenities?.forEach((d1)=>{
        //   if(d1 !== null){
        //     arr.push(d1?.value)
        //   }
        // })

        // sendData['amenities'] = amenities
        let tenantsArr = []
        if(data?.tenant !== ''){
          data?.tenant?.forEach((t)=>{
            tenantsArr?.push(t?.value === undefined ? t : t?.value)
          })
        }
        sendData['client'] = data?.client?.value === undefined ? data?.client : data?.client?.value
        // if(tenantsArr?.length > 0){
          sendData['tenant'] = tenantsArr
        // }
        // console.log("sendData",sendData)
        


        if(location?.pathname?.split('/')[location?.pathname?.split('/').length - 1] === 'edit'){
        

          setloader(true)
          const response = await UpdateFcaresPropertyService(data?._id,sendData)
          if(response.status === 200){
            setloader(false)
            // console.log("Anna data?.stage?.label",data?.stage?.label,"stage?.selected_stage?.label",stage?.selected_stage?.label)
            setloader(false)
            toast.success('Property Updated Successfully')
            resetform()
            navigate(-1)
          }
        }else{  
          setloader(true)
          const response = await CreateFcaresPropertyService(sendData)
          if(response.status === 201){
              setloader(false)
              toast.success('Property Created Successfully')
              resetform()
          }
        }
  }

  function resetform(){
     setdata({
      building_name:'',
      building_address:'',
      building_city:'',
      building_area:'',
      building_zone:'',
      floor:'',
      total_floor:'',
      property_available_for:'',
      minimun_deposit:'',
      monthly_maintenance_amount:'',
      bathroom:'',
      balcony:'',
      building_type:'',
      house_type:'',
      furnishing_type:'',
      builtup_area:'',
      building_age:'',
      amenities:[],
      expecting_rent:'',
      owner_type:'',
      image:'',
      image1:'',
      image2:'',
      image3:'',
      image4:'',
      building_docs:'',
      fidelitus_verified_docs:'',
      oc_verified:'',
      katha_type:'',
      car_parking:'',
      bike_parking:'',
      status:'',
      client:'',
      tenant:'',
      lock_in_period:'',
      proptype:'',
      fidelitus_maintenance:'',
      })
      seterror({
        building_name:'',
        building_address:'',
        building_city:'',
        building_area:'',
        building_zone:'',
        floor:'',
        total_floor:'',
        property_available_for:'',
        minimun_deposit:'',
        monthly_maintenance_amount:'',
        bathroom:'',
        balcony:'',
        building_type:'',
        house_type:'',
        furnishing_type:'',
        builtup_area:'',
        building_age:'',
        amenities:'',
        expecting_rent:'',
        owner_type:'',
        image:'',
        image1:'',
        image2:'',
        image3:'',
        image4:'',
        building_docs:'',
        fidelitus_verified_docs:'',
        oc_verified:'',
        katha_type:'',
        car_parking:'',
        bike_parking:'',
        status:'',
        client:'',
        tenant:'',
        lock_in_period:'',
        proptype:'',
        fidelitus_maintenance:'',
      })

    
  }

  async function uploadfilefunc(v,name){

    const fd = new FormData()
    fd.append('image',v); 
    const response = await UploadDocsFcaresPropertyService(fd)
    if(response?.status === 200){
    setdata({...data,[name]:response?.data?.data})
    }
  }

  async function savefunc(){
      if(!amenities_data?.name){
      setamenities_data_err({...amenities_data_err,name:'This Field is required'})
      }else{
        if(amenities_data?.index === undefined){
          let old_data = {...amenities_data}
          amenities_data.id = amenities.length
          let all_amenities = [...data.amenities,old_data]
          setdata({...data,amenities:all_amenities})
          setamenities_data({name:'',description:''})
          setamenities_data_err({name:'',description:''})
        }else{
          let old_data = [...data.amenities]
          old_data[amenities_data?.index] = amenities_data
          // setamenities(old_data)
          // let all_amenities = [...data.amenities,old_data]
          setdata({...data,amenities:old_data})
          setmodal(!modal)
          setamenities_data({name:'',description:''})
          setamenities_data_err({name:'',description:''})
        }

      }
  }

  function deletefunc(){
    let old_data = [...data.amenities]
    old_data.splice(deletedData.index)
    setdata({...data,amenities:old_data})
    setdeleteModal(false)
  }

  async function searchuserclienttenant(v){
    const response = await GetFcaresUserService(v,'','','',1)
    console.log("response?.data",response?.data)

  }




  function setamentiesfunc(e){
    let arr = []
    e?.forEach((e1)=>{
      if(amenities?.find((f)=>f?.value === e1) !== null){
        arr.push(amenities?.find((f)=>f?.value === e1))
      }
    })
    setdata({...data,amenities:arr})
  }
 
  


  return (
    <div className='h-screen max-h-screen box-border overflow-hidden'>

    <Modal open={deleteModal} width={300} footer={false} closable={false} className='absolute top-0 left-[42%]'>
    <h6 className='text-[14px] font-[700]'>Delete Data </h6>
    <h6 className='bg-slate-100 text-[10px] font-[500] p-1'>Be sure before deleting the <span className='font-[800]'>{deletedData?.type}</span> once deleted will not be retrieved</h6> 
    
    <h6 className='text-[11px]'>Name : <span className='font-[700]'>{deletedData?.data?.name}</span> </h6>
    <h6 className='text-[11px]'>Description : {deletedData?.data?.description}</h6>



    <div className='flex border-t pt-2 mt-2'>
        <ButtonOutlinedAutoWidth btnName="Cancel" onClick={()=>setdeleteModal(false)} />
        <h6 className='mr-1'></h6>
        <ButtonFilledAutoWidth btnName="Save" onClick={deletefunc} />
      </div>
    </Modal>  

    <Modal open={modal} width={300} footer={false} closable={false} className='absolute top-0 left-[42%]'>
      <h3 className='font-[700] text-[14px]'>Add/Edit Amenities</h3>
      <h6 className='text-[11px] bg-slate-100 leading-[15px] py-[5px] px-[10px]'>Use the below form to add or edit the amenities for the selected property</h6>
 

      <TextInput 
              mandatory={true}
              label={'Name'}  
              variant="standard"
              name="name"
              type="text"
              value={amenities_data.name}
              error={amenities_data_err.name}
              handlechange={handlechange1}
              placeholder="Enter contact name"
              InputLabelProps={{
                  style: { color: '#fff', }, 
              }}/>
               <TextAreaInput1 
              mandatory={true}
            label={'Description'}  
            variant="standard"
            name="description"
            type="text"
            value={amenities_data.description}
            error={amenities_data_err.description}
            handlechange={handlechange1}
            placeholder=""
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>


      <div className='flex border-t pt-2 mt-2'>
        <ButtonOutlinedAutoWidth btnName="Cancel" onClick={()=>setmodal(false)} />
        <h6 className='mr-1'></h6>
        <ButtonFilledAutoWidth btnName="Save" onClick={savefunc} />
      </div>
    
    </Modal>  



    <div className='block sm:flex'>
        <FcaresMenu />
    <div className='pr-5 ml-5 min-h-screen max-h-screen h-screen overflow-y-scroll' >
    

  

    <div className='w-64 '>
      <GoBack />
     <div className='border-b  pb-2'>
      <h6 className='font-[800] mb-1'>{location?.pathname?.split('/')[location?.pathname?.split('/').length -1] === 'edit' ? 'Edit' : 'Create'} Property </h6> 
      <h6 className='text-[11px] leading-tight font-[500] p-2 bg-slate-100 '>Use the below form to create or edit the user for the fcares .</h6> 
     </div> 
      <Grid container spacing={2}  >
        <Grid item xs={12}  md={12} >
          <TextInput 
              mandatory={true}
              label={'Building Name'}  
              variant="standard"
              name="building_name"
              type="text"
              value={data.building_name}
              error={error.building_name}
              handlechange={handlechange}
              placeholder="Enter contact name"
              InputLabelProps={{
                  style: { color: '#fff', }, 
              }}/>
               <TextAreaInput1 
              mandatory={true}
            label={'Building Address'}  
            variant="standard"
            name="building_address"
            type="text"
            value={data.building_address}
            error={error.building_address}
            handlechange={handlechange}
            placeholder=""
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>
             {/* <TextInput 
              mandatory={true}
            label={'Building City'}  
            variant="standard"
            name="building_city"
            type="text"
            value={data.building_city}
            error={error.building_city}
            handlechange={handlechange}
            placeholder="Enter contact email"
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/> */}

                       
<h6 className='text-[12px] font-semibold mb-1 mt-1'>Building City*</h6>

<Select 
 value={data?.building_city}
 bordered={false}
 onChange={(e)=>{setdata({...data,building_city:e})}} 
 options={city_type}
 className='border-l-4 border-l-slate-600 w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none'


/>

<h6 className='text-[12px] font-semibold mb-1 mt-1'>Building Area*</h6>

<Select 
 value={data?.building_area}
 bordered={false}
 onChange={(e)=>{setdata({...data,building_area:e})}} 
 options={area_type}
 className='border-l-4 border-l-slate-600 w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none'


/>

{/* 
<TextInput 
            label={'Building Area'}  
            variant="standard"
            name="building_area"
            type="text"
            value={data.building_area}
            error={error.building_area}
            handlechange={handlechange}
            placeholder="Enter designation"
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>

         
      */}
<h6 className='text-[12px] font-semibold mb-1 mt-1'>Facing*</h6>

<Select 
 value={data?.building_zone}
 bordered={false}
 onChange={(e)=>{setdata({...data,building_zone:e})}} 
 options={zone_type}
 className='border-l-4 border-l-slate-600 w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none'

/>

<h6 className='text-[12px] font-semibold mb-1 mt-1'>Type of building*</h6>

<Select 
 value={data?.building_type}
 bordered={false}
 onChange={(e)=>{setdata({...data,building_type:e})}} 
 options={building_type}
 className='border-l-4 border-l-slate-600 w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none'

/>
{/* 
<h6 className='text-[12px] font-semibold mb-1 mt-1'>Type of House*</h6>

<Select 
 value={data?.house_type}
 bordered={false}
 onChange={(e)=>{setdata({...data,house_type:e})}} 
 options={house_type}
 className='border-l-4 border-l-slate-600 w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none'

/> */}

<h6 className='text-[12px] font-semibold mb-1 mt-1'>Level of Furnishing*</h6>

<Select 
 value={data?.furnishing_type}
 bordered={false}
 onChange={(e)=>{setdata({...data,furnishing_type:e})}} 
 options={furnishing_type}
 className='border-l-4 border-l-slate-600 w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none'

/>

<div className='flex'>
<div className='mr-2'>
<TextInput1 
            label={'My Floor'}  
            variant="standard"
            name="floor"
            type="text"
            value={data.floor}
            error={error.floor}
            handlechange={handlechange}
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>
</div>  
<TextInput1 
            label={'Total Floor'}  
            variant="standard"
            name="total_floor"
            type="text"
            value={data.total_floor}
            error={error.total_floor}
            handlechange={handlechange}
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>

</div>

{/* <TextInput1 
            label={'Property Available Floor'}  
            variant="standard"
            name="property_available_for"
            type="text"
            value={data.property_available_for}
            error={error.property_available_for}
            handlechange={handlechange}
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/> */}

<TextInput1 
            label={'Minimum Deposit'}  
            variant="standard"
            name="minimun_deposit"
            type="text"
            value={data.minimun_deposit}
            error={error.minimun_deposit}
            handlechange={handlechange}
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>

{/* <TextInput1 
            label={'Monthly Maintenance Deposit'}  
            variant="standard"
            name="monthly_maintenance_amount"
            type="text"
            value={data.monthly_maintenance_amount}
            error={error.monthly_maintenance_amount}
            handlechange={handlechange}
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/> */}

{/* <div className='flex'>
<div className='mr-2'>
<TextInput1 
            label={'Bathroom'}  
            variant="standard"
            name="bathroom"
            type="text"
            value={data.bathroom}
            error={error.bathroom}
            handlechange={handlechange}
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>
</div>
<TextInput1 
            label={'Balcony'}  
            variant="standard"
            name="balcony"
            type="text"
            value={data.balcony}
            error={error.balcony}
            handlechange={handlechange}
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>
</div> */}

            
            <TextInput1 
            label={'Size of your area(sq.ft)'}  
            variant="standard"
            name="builtup_area"
            type="text"
            value={data.builtup_area}
            error={error.builtup_area}
            handlechange={handlechange}
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>


<h6 className='text-[12px] font-semibold mb-1 mt-1'>Age of building*</h6>

<Select 
 value={data?.building_age}
 bordered={false}
 onChange={(e)=>{setdata({...data,building_age:e})}} 
 options={age_type}
 className='border-l-4 border-l-slate-600 w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none'

/>


{/* <TextAreaInput1 
            label={'Amenities'}  
            variant="standard"
            name="amenities"
            type="text"
            value={data.amenities}
            error={error.amenities}
            handlechange={handlechange}
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/> */}


<h6 className='text-[12px] font-semibold mb-1 mt-1'>Katha Type*</h6>

<Select 
 value={data?.katha_type}
 bordered={false}
 onChange={(e)=>{setdata({...data,katha_type:e})}} 
 options={katha_type}
 className='border-l-4 border-l-slate-600 w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none'


/>

     
<div className='flex'>
  <div className='mr-2'>
      <TextInput1 
            label={'Car Parking'}  
            variant="standard"
            name="car_parking"
            type="text"
            value={data.car_parking}
            error={error.car_parking}
            handlechange={handlechange}
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>  
  </div>
      <TextInput1 
            label={'Bike Parking'}  
            variant="standard"
            name="bike_parking"
            type="text"
            value={data.bike_parking}
            error={error.bike_parking}
            handlechange={handlechange}
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>    
</div>
         

{/* <h6 className='text-[12px] font-semibold mb-1 mt-1'>Amenities*</h6>
<Select 
 value={data?.amenities}
 bordered={false}
 mode="multiple"
 onChange={(e)=>{setamentiesfunc(e)}} 
 options={amenities}
 className='border-l-4 border-l-slate-600 w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none'

/> */}

<div className='flex mb-1 mt-1 items-center justify-between'>
  <h6 className='text-[12px] font-semibold '>Amenities*</h6>
  <AiOutlinePlus onClick={()=>setmodal(!modal)} className='cursor-pointer bg-slate-300 p-[2px]' />
</div> 

<div className='border'>
  <div className='bg-slate-100 text-[11px] flex border-b'>
    <h6 className='w-[40%] border-r p-[5px]'>Name</h6>
    <h6 className='w-[60%] border-r p-[5px]'>Description</h6>
    <h6 className='w-[20%] p-[5px]'>Action</h6>
  </div>
   
  {data?.amenities?.map((a,i)=>( 
  <div className='text-[11px] flex border-b'>
    <h6 className='w-[40%] border-r p-[5px]'>{a?.name}</h6>
    <h6 className='w-[60%] border-r p-[5px]'>{a?.description}</h6>
    <h6 className='w-[20%] flex p-[5px]'>
      <AiOutlineEdit size={12} className='mr-2' onClick={()=>{setamenities_data({...a,index:i});setmodal(true)}} />
      <AiOutlineDelete  size={12} onClick={()=>{setdeleteData({type:'amenities',index:i,data:a});setdeleteModal(true)}} />
    </h6>
  </div>
  ))}

</div>

<h6 className='text-[12px] font-semibold mb-1 mt-1'>You are an*</h6>

<Select 
 value={data?.owner_type}
 bordered={false}
 onChange={(e)=>{setdata({...data,owner_type:e})}} 
 options={owner_type}
 className='border-l-4 border-l-slate-600 w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none'


/>

<TextInput1 
            label={'Expecting Rent'}  
            variant="standard"
            name="expecting_rent"
            type="text"
            value={data.expecting_rent}
            error={error.expecting_rent}
            handlechange={handlechange}
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>

      <TextInput
            label={'Fidelitus Maintenance'}  
            variant="standard"
            name="fidelitus_maintenance"
            type="number"
            value={data.fidelitus_maintenance}
            error={error.fidelitus_maintenance}
            handlechange={handlechange}
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/> 


<h6 className='text-[11px] font-[600] mb-1' >Image</h6>
{(data.image === '' || data.image == null) ?
    <form onClick={()=>document.querySelector(`.input-field`).click()} className={`p-4 flex flex-col items-center justify-center cursor-pointer border border-slate-300 `}>
        <input type='file' onChange={({target:{files}})=>{
          files[0] && uploadfilefunc(files[0],'image')
        }} accept="*" className='input-field' hidden />
    </form> 
   :
      <div className='p-2 border border-slate-300 relative flex flex-col  cursor-pointer'>
          <Tooltip title='Delete'><IoClose className='absolute top-3 right-2' onClick={()=>setdata({...data,image:''})}/></Tooltip>
          <h6 className='text-[12px] truncate w-48 ml-0'>{data?.image}</h6>
      </div>
      
  }


<h6 className='text-[11px] font-[600] mb-1' >Image 1</h6>
{(data.image1 === '' || data.image1 == null) ?
    <form onClick={()=>document.querySelector(`.input-field1`).click()} className={`p-4 flex flex-col items-center justify-center cursor-pointer border border-slate-300 `}>
        <input type='file' onChange={({target:{files}})=>{
          files[0] && uploadfilefunc(files[0],'image1')
        }} accept="*" className='input-field1' hidden />
    </form> 
   :
      <div className='p-2 border border-slate-300 relative flex flex-col  cursor-pointer'>
          <Tooltip title='Delete'><IoClose className='absolute top-3 right-2' onClick={()=>setdata({...data,image1:''})}/></Tooltip>
          <h6 className='text-[12px] truncate w-48 ml-0'>{data?.image1}</h6>
      </div>
      
  }

<h6 className='text-[11px] font-[600] mb-1' >Image 2</h6>
{(data.image2 === '' || data.image2 == null) ?
    <form onClick={()=>document.querySelector(`.input-field2`).click()} className={`p-4 flex flex-col items-center justify-center cursor-pointer border border-slate-300 `}>
        <input type='file' onChange={({target:{files}})=>{
          files[0] && uploadfilefunc(files[0],'image2')
        }} accept="*" className='input-field2' hidden />
    </form> 
   :
      <div className='p-2 border border-slate-300 relative flex flex-col  cursor-pointer'>
          <Tooltip title='Delete'><IoClose className='absolute top-3 right-2' onClick={()=>setdata({...data,image2:''})}/></Tooltip>
          <h6 className='text-[12px] truncate w-48 ml-0'>{data?.image2}</h6>
      </div>
  }

<h6 className='text-[11px] font-[600] mb-1' >Image 3</h6>
{(data.image3 === '' || data.image3 == null) ?
    <form onClick={()=>document.querySelector(`.input-field3`).click()} className={`p-4 flex flex-col items-center justify-center cursor-pointer border border-slate-300 `}>
        <input type='file' onChange={({target:{files}})=>{
          files[0] && uploadfilefunc(files[0],'image3')
        }} accept="*" className='input-field3' hidden />
    </form> 
   :
      <div className='p-2 border border-slate-300 relative flex flex-col  cursor-pointer'>
          <Tooltip title='Delete'><IoClose className='absolute top-3 right-2' onClick={()=>setdata({...data,image3:''})}/></Tooltip>
          <h6 className='text-[12px] truncate w-48 ml-0'>{data?.image3}</h6>
      </div>
  }

<h6 className='text-[11px] font-[600] mb-1' >Image 4</h6>
{(data.image4 === '' || data.image4 == null) ?
    <form onClick={()=>document.querySelector(`.input-field4`).click()} className={`p-4 flex flex-col items-center justify-center cursor-pointer border border-slate-300 `}>
        <input type='file' onChange={({target:{files}})=>{
          files[0] && uploadfilefunc(files[0],'image4')
        }} accept="*" className='input-field4' hidden />
    </form> 
   :
      <div className='p-2 border border-slate-300 relative flex flex-col  cursor-pointer'>
          <Tooltip title='Delete'><IoClose className='absolute top-3 right-2' onClick={()=>setdata({...data,image4:''})}/></Tooltip>
          <h6 className='text-[12px] truncate w-48 ml-0'>{data?.image4}</h6>
      </div>
  }


<h6 className='text-[11px] font-[600] mb-1' >Building Docs</h6>
{(data.building_docs === '' || data.building_docs == null) ?
    <form onClick={()=>document.querySelector(`.input-field5`).click()} className={`p-4 flex flex-col items-center justify-center cursor-pointer border border-slate-300 `}>
        <input type='file' onChange={({target:{files}})=>{
          files[0] && uploadfilefunc(files[0],'building_docs')
        }} accept="*" className='input-field5' hidden />
    </form> 
   :
      <div className='p-2 border border-slate-300 relative flex flex-col  cursor-pointer'>
          <Tooltip title='Delete'><IoClose className='absolute top-3 right-2' onClick={()=>setdata({...data,building_docs:''})}/></Tooltip>
          <h6 className='text-[12px] truncate w-48 ml-0'>{data?.building_docs}</h6>
      </div>
  }

<h6 className='text-[11px] font-[600] mb-1' >Fidelitus Verified Docs</h6>
{(data.fidelitus_verified_docs === '' || data.fidelitus_verified_docs == null) ?
    <form onClick={()=>document.querySelector(`.input-field6`).click()} className={`p-4 flex flex-col items-center justify-center cursor-pointer border border-slate-300 `}>
        <input type='file' onChange={({target:{files}})=>{
          files[0] && uploadfilefunc(files[0],'fidelitus_verified_docs')
        }} accept="*" className='input-field6' hidden />
    </form> 
   :
      <div className='p-2 border border-slate-300 relative flex flex-col  cursor-pointer'>
          <Tooltip title='Delete'><IoClose className='absolute top-3 right-2' onClick={()=>setdata({...data,fidelitus_verified_docs:''})}/></Tooltip>
          <h6 className='text-[12px] truncate w-48 ml-0'>{data?.fidelitus_verified_docs}</h6>
      </div>
  }

<h6 className='text-[11px] font-[600] mb-1' >OC Verified Docs</h6>
{(data.oc_verified === '' || data.oc_verified == null) ?
    <form onClick={()=>document.querySelector(`.input-field7`).click()} className={`p-4 flex flex-col items-center justify-center cursor-pointer border border-slate-300 `}>
        <input type='file' onChange={({target:{files}})=>{
          files[0] && uploadfilefunc(files[0],'oc_verified')
        }} accept="*" className='input-field7' hidden />
    </form> 
   :
      <div className='p-2 border border-slate-300 relative flex flex-col  cursor-pointer'>
          <Tooltip title='Delete'><IoClose className='absolute top-3 right-2' onClick={()=>setdata({...data,oc_verified:''})}/></Tooltip>
          <h6 className='text-[12px] truncate w-48 ml-0'>{data?.oc_verified}</h6>
      </div>
  }

  
  
<h6 className='text-[12px] font-semibold mb-1 mt-1'>Status*</h6>

<Select 
 value={data?.status}
 bordered={false}
 onChange={(e)=>{setdata({...data,status:e})}} 
 options={status}
 className='border-l-4 border-l-slate-600 w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none'


/>
  

       {/* <TextAreaInput1 
            label={'Client'}  
            variant="standard"
            name="client"
            type="text"
            value={data.client}
            error={error.client}
            handlechange={handlechange}
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/> 

             */}
<h6 className='text-[12px] font-semibold mb-1 mt-1'>Proptype*</h6>

<Select 
 value={data?.proptype}
 bordered={false}
 onChange={(e)=>{setdata({...data,proptype:e})}} 
 options={proptype}
 className='border-l-4 border-l-slate-600 w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none'


/>
     

                                  
 






<h6 className='text-[12px] font-semibold mb-1 mt-1'>Owner*</h6>

<Select 
 value={data.client}
 bordered={false}
 onChange={(e)=>{setdata({...data,client:e})}} 
 options={owners}
 
 className='border-l-4 border-l-slate-600 w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none'
/>

<h6 className='text-[12px] font-semibold mb-1 mt-1'>Tenant*</h6>

{/* {JSON.stringify(data.tenant)} */}
<Select 
 value={data.tenant === '' ? []  : data.tenant}
 bordered={false}
 allowClear
 mode="multiple"
 onChange={(e)=>{setdata({...data,tenant:e})}} 
 options={owners}
//  disabled
 className='w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none'
/>

<TextInput1 
            label={'Lock in period'}  
            variant="standard"
            name="lock_in_period"
            type="text"
            value={data.lock_in_period}
            error={error.lock_in_period}
            handlechange={handlechange}
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>
      



       
            
        </Grid>
       
      </Grid>

   
   <div >
    <div className='flex items-center mt-5 mb-10  border-t pt-5'>
        <div className='mr-2'>
        <ButtonOutlined btnName={'Back'} onClick={()=>navigate(-1)} />
        </div>
        <div>
        {loader ?   
        <ButtonFilled btnName={loader ? 'Loading' : 'Save'} /> :
        <ButtonFilled btnName={loader ? 'Loading' : 'Save'} onClick={()=>submitform(null)} /> }
        </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
   
  )
}

export default FCaresPropertyCE