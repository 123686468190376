import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

function LeadPushtoDetail() {
  const {id} = useParams() 
  const navigator = useNavigate()

  useEffect(()=>{
     if(id !== ''){
        navigator('/leads/invoice_raised/detail',{state:id})
     }
  },[])
  
}

export default LeadPushtoDetail