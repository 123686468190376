import { deleteRequest, get, post, put } from "../../../helpers/apihelpers";

export const CreateLocationBasedZoneService=async(data)=>{
    try {
        const result = await post(`api/database/location_based_zone/create`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateLocationBasedZoneService=async(data,id)=>{
    try {
        const result = await put(`api/database/location_based_zone/update/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetLocationBasedZoneService=async(page,from_date,to_date,text,step)=>{
    try {
        const result = await get(`api/database/location_based_zone/get?page=${page}&from_date=${from_date}&to_date=${to_date}&text=${text}&step=${step}`,);
        return result;
    } catch (err) {
        return err.response;
    }
}


export const DeleteLocationBasedZoneService=async(id)=>{
    try {
        const result = await deleteRequest(`api/database/location_based_zone/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteAdminLocationBasedZoneService=async(id)=>{
    try {
        const result = await deleteRequest(`api/database/location_based_zone/delete_admin/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const FilterLocationBasedZoneService=async(id)=>{
    try {
        const result = await get(`api/database/location_based_zone/zone_based_location/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}