import React, { useEffect, useState } from 'react'
import DashboardMenu from '../../dashboard/DashboardMenu'
import { useLocation, useNavigate } from 'react-router-dom'
import { GetEmployeeTargetBasedOnIncentive } from '../../../services/AdminServicesfile/InsentiveServices'
import {TbListDetails} from 'react-icons/tb';
import { Select } from 'antd';
import {BsArrowRepeat} from 'react-icons/bs';
import {FiChevronRight,FiChevronLeft} from 'react-icons/fi';
import { IconButton } from '@material-ui/core';

function EmployeeTargetsDetailInsentive() {
 
  const {state} = useLocation()
  const [datas,setdatas] = useState([])
  const navigator = useNavigate()
  const [page,setpage] = useState(1)
  const [pagination,setpagination] = useState({})
  const [periods,setperiods] = useState([])
  const [selected_period,setselected_period] = useState({})



  let rupee = new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
  });


  useEffect(()=>{
    getdata()
  },[selected_period,page])

  async function setselectedperiodfun(e){
    setpage(1)
    let period_selected = periods?.find((v)=>v.value === e)
    // console.log("period_selected kp anna",period_selected)
    setselected_period({...period_selected})
 } 

  async function resetfunc(){
    setpage(1)
    setselected_period(periods[0])
  }

  async function getdata(){
     try{
        const response = await GetEmployeeTargetBasedOnIncentive(state?.id,selected_period?.value === undefined ? 1 : selected_period?.value,page)
        let d = state
        let arr = []
        for(let i=0;i<d?.period;i++){
          arr.push({label:i+1,value:i+1})
        }  

        setperiods(arr)
        if(selected_period?.value === undefined){
          setselected_period({label:1,value:1})
        }
        setdatas(response?.data?.datas)
        setpagination(response?.data?.pagination)
     }catch(e){

     }
  }

  // console.log("datas",datas)


 

   function achevetimes(v){
      let achieve_times = ''
        if(Array.isArray(v)){
          if(selected_period?.value === 1){
            achieve_times = v?.slice(0,3).join(":")
          }else if(selected_period?.value === 2){
            achieve_times = v?.slice(3,6).join(":")
          }else if(selected_period?.value === 3){
            achieve_times = v?.slice(6,9).join(":")
          }else if(selected_period?.value === 4){
            achieve_times = v?.slice(9).join(":")
          }
      }
      return achieve_times
  }

  function salary(v){
    if(Array.isArray(v)){
       return Math.ceil(v.reduce((a,b)=>a+b,0))
    }else{
      return 0
    }
  }  

  function target(v){
   if(Array.isArray(v)){
      return Math.ceil(v.reduce((a,b)=>a+b,0))
   }else{
     return v
   }
  }  

  function currenttarget(v){
    if(Array.isArray(v)){
      return v[selected_period?.value - 1]
    }else{
      return 0
    }
  }


  // console.log("selected_period?.value",selected_period?.value)

  function totaltarget(v){
    // if(selected_period?.value === 1){
    //   if(Array.isArray(v?.target)){
    //     return v?.target[selected_period?.value - 1]
    //   }else{
    //     return v?.target
    //   }
    // }else{
    //   if(Array.isArray(v?.target)){
       
    //      const target =  (v?.target?.slice(0,selected_period?.value).reduce((a,b)=>a+b,0)) 
    //      let achieved =  0
    //      for(let i = 0; i < selected_period?.value ; i++){
    //        achieved += ( v.target_achieved_based_period !== undefined  && v.target_achieved_based_period !== null && v.target_achieved_based_period[i] !== null) ? v.target_achieved_based_period[i] : 0
    //      }

    //      let totalTarget =  target - achieved > 0 ? target - achieved : 0
    //      return totalTarget
    //   }else{
    //     return v?.target
    //   }
    // }
    if(selected_period?.value === 1){
      if(Array.isArray(v?.target)){
        return v?.target[selected_period?.value - 1]
      }else{
        return v?.target
      }
    }else{
      if(Array.isArray(v?.target)){

         const last_year_shortfall = (v?.last_year_shortfall !== null && v?.last_year_shortfall !== undefined) ?  v?.last_year_shortfall : 0
         const target =  (v?.target?.slice(0,selected_period?.value).reduce((a,b)=>a+b,0)) + last_year_shortfall
         let achieved =  0
         for(let i = 0; i < selected_period?.value ; i++){
           achieved += ( v.target_achieved_based_period !== undefined  && v.target_achieved_based_period !== null && v.target_achieved_based_period[i] !== null) ? v.target_achieved_based_period[i] : 0
         }

         let totalTarget =  target - achieved > 0 ? target - achieved : v?.target[selected_period?.value - 1]
         return totalTarget
      }else{
        return v?.target
      }
    }
  }

  function pendingtarget(v){
    // if(selected_period?.label !== 1){
    //   let pendingtarget = 0
    //   if(Array.isArray(v.target)){
    //     let target = 0
    //     let achieved = 0

    //     for(let i = 0;i < selected_period?.label-1 ;i++){
    //       target += v.target[i]
    //       achieved += ( v.target_achieved_based_period !== undefined  && v.target_achieved_based_period !== null && v.target_achieved_based_period[i] !== null) ? v.target_achieved_based_period[i] : 0
    //     } 

    //     if(target > achieved){
    //       pendingtarget = target - achieved
    //     }
         
    //     return pendingtarget
           
    //   }

    // }else{
    //   return 0
    // }

    const last_year_shortfall = (v?.last_year_shortfall !== null && v?.last_year_shortfall !== undefined) ?  v?.last_year_shortfall : 0

    if(selected_period?.label !== 1){
      let pendingtarget = 0 + last_year_shortfall
      if(Array.isArray(v.target)){
        let target = 0 + last_year_shortfall
        let achieved = 0

        for(let i = 0;i < selected_period?.label-1 ;i++){
          target += v.target[i]
          achieved += ( v.target_achieved_based_period !== undefined  && v.target_achieved_based_period !== null && v.target_achieved_based_period[i] !== null) ? v.target_achieved_based_period[i] : 0
        } 


        if(target > achieved){
          pendingtarget = target - achieved
        }
         
        return pendingtarget
           
      }

    }else{
      return 0
    }
  }

  function totalrevenue(v){
    let total = 0
    if(selected_period?.value == 1){
     if(v?.target_achieved_based_period?.length > 0 && ![0,'',null,undefined,'null','undefined']?.includes(v?.target_achieved_based_period[0])){
       total += v?.target_achieved_based_period[0]
     }else{
       total += v?.target_achieved !== undefined ?  v?.target_achieved : 0
     }
    }else{
     total += v?.target_achieved_based_period?.length > 0 ?  v?.target_achieved_based_period?.slice(0,selected_period?.value)?.reduce((a,b)=>a+b,0) : 0
    }
    return total
 }



  return (
    
    <div className='h-screen min-h-screen overflow-hidden  max-h-screen'>
      <div className='flex'>
        <div className='min-w-44 max-w-44 -mt-5 w-44'>
          <div className='pt-5 border-r'>
            <DashboardMenu />
          </div>
        </div>
        <div className='mx-3 w-full mt-5'>
          <div className='flex items-center justify-between border-b pb-1 mb-2'>
          <h6 className='text-[13.5px] font-[700] '>Employee Target For year ({state?.year})</h6>

          <div className='flex tems-center justify-center'>
          <div className='flex items-center text-[12px] mr-2'>
            <h6 className='mr-2 font-[600]'>{page == 1 ? datas?.length > 0 ? 1 : 0 : (page - 1) * pagination?.limit } - {pagination?.limit} of {pagination?.total} </h6>
            <IconButton onClick={resetfunc}><BsArrowRepeat size={16} /></IconButton>

            <div>
            <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>
            <IconButton onClick={()=>{ page < pagination?.totalPages  ? setpage(page+1) : console.log('')}}><FiChevronRight className={`${(pagination?.totalPages === page || datas?.length === 0)  ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>

            </div>
            <Select value={selected_period} options={periods} onChange={(e)=>setselectedperiodfun(e)} size='small' placeholder="Quarter" className='border py-[2px] rounded-md w-[50px] ml-2' bordered={false}  />

            </div>
          </div>
          </div>
          <div className='max-h-[85vh] overflow-y-scroll border'>
          <div className='sticky top-0 z-50 flex  bg-white py-[-5px] border-b border-slate-200 relative '>
            <h6 className='text-[11px] w-[3%] min-w-[3%] max-w-[3%]  px-[4px] py-1 border-r font-[600]'></h6>
            <h6 className='text-[11px] w-[9%] min-w-[9%] max-w-[9%]  px-[4px] py-1 border-r font-[600]'>Employee</h6>
            <h6 className='text-[11px] w-[7%] min-w-[7%] max-w-[7%]  px-[4px] py-1 border-r font-[600]'>Yearly Salary</h6>
            <h6 className='text-[11px] w-[6%] min-w-[6%] max-w-[6%]  px-[4px] py-1 border-r font-[600]'>Times </h6>
            <h6 className='text-[11px] w-[5%] min-w-[5%] max-w-[5%] px-[4px] py-1 border-r font-[600]'>Quarter</h6>
            <h6 className='text-[11px] w-[8%] min-w-[8%] max-w-[8%] px-[4px] py-1 border-r font-[600]'>Yearly Target</h6>
            <h6 className='text-[11px] w-[8%] min-w-[8%] max-w-[8%] px-[4px] py-1 border-r font-[600]'>Vertical Target</h6>
            <h6 className='text-[11px] w-[8%] min-w-[8%] max-w-[8%] px-[4px] py-1 border-r font-[600]'>Current Quarter Target</h6>
            <h6 className='text-[11px] w-[9%] min-w-[9%] max-w-[9%] px-[4px] py-1 border-r font-[600]'>Total Pending Target</h6>
            <h6 className='text-[11px] w-[9%] min-w-[9%] max-w-[9%] px-[4px] py-1 border-r font-[600]'>Quarter Total Target</h6>
            <h6 className='text-[11px] w-[8%] min-w-[8%] max-w-[8%] px-[4px] py-1 border-r font-[600]'>Target Achieved</h6>
            <h6 className='text-[11px] w-[8%] min-w-[8%] max-w-[8%] px-[4px] py-1 border-r font-[600]'>Total Achieved</h6>
            <h6 className='text-[11px] w-[6%] min-w-[6%] max-w-[6%] px-[4px] py-1 border-r font-[600]'>Payment</h6>
            <h6 className='text-[11px] w-[4%] min-w-[4%] max-w-[4%] px-[4px] py-1  font-[600]'>Action</h6>
          </div>
     

       {datas?.map((d,i)=>(
       <div key={d?._id} className={`flex ${i !== datas?.length-1 ? 'border-b' : ''} cursor-pointer z-0`}  >
          <h6 className='text-[12px] w-[3%] min-w-[3%] max-w-[3%]  px-2 py-1 border-r truncate'>{i+1}</h6>
          <h6 className='text-[12px] w-[9%] min-w-[9%] max-w-[9%]  px-2 py-1 border-r truncate'>{d?.user?.name}</h6>
          <h6 className='text-[12px] w-[7%] min-w-[7%] max-w-[7%]  px-2 py-1 border-r truncate'>{rupee?.format(salary(d?.salary))?.split('.')[0]}</h6>
          <h6 className='text-[11px] w-[6%] min-w-[6%] max-w-[6%]  px-2 py-1 border-r truncate'>{achevetimes(d?.achieve_times)}</h6>
          <h6 className='text-[11px] w-[5%] min-w-[5%] max-w-[5%] px-2 py-1 border-r'>{d?.quarter}</h6>
          <h6 className='text-[12px] w-[8%] min-w-[8%] max-w-[8%] px-2 py-1 border-r font-[600] truncate'>{rupee?.format(target(d?.target))?.split('.')[0]}</h6>
          <h6 className='text-[12px] w-[8%] min-w-[8%] max-w-[8%] px-2 py-1 border-r font-[600] truncate'>{rupee?.format(d?.vertical_target)?.split('.')[0]}</h6>
          <h6 className='text-[12px] w-[8%] min-w-[8%] max-w-[8%] px-2 py-1 border-r font-[600] truncate'>{rupee?.format(currenttarget(d?.target))?.split('.')[0]}</h6>
          <h6 className='text-[12px] w-[9%] min-w-[9%] max-w-[9%] px-2 py-1 border-r font-[600] truncate'>{rupee?.format(pendingtarget(d))?.split('.')[0]} </h6>
          <h6 className='text-[12px] w-[9%] min-w-[9%] max-w-[9%] px-2 py-1 border-r font-[600] truncate'>{rupee.format(totaltarget(d))?.split('.')[0]} </h6>
          <h6 className='text-[12px] w-[8%] min-w-[8%] max-w-[8%] px-2 py-1 border-r font-[600] truncate'>{d?.target_achieved === undefined ? rupee?.format(0)?.split('.')[0] : rupee?.format(d?.target_achieved)?.split('.')[0]}</h6>
          <h6 className='text-[12px] w-[8%] min-w-[8%] max-w-[8%] px-2 py-1 border-r font-[600] truncate'>{rupee?.format(totalrevenue(d))?.split('.')[0]}</h6>
          <h6 className='text-[12px] w-[6%] min-w-[6%] max-w-[6%] px-2 py-1 border-r font-[600]'>{d?.payments === undefined ? rupee?.format(0)?.split('.')[0]?.slice(1) : JSON.stringify(rupee?.format(d?.payments)?.split('.')[0])?.slice(2,-1) } </h6>
          <h6 className='text-[13px] w-[5%] min-w-[5%] max-w-[5%] px-2 py-1 font-[600] pl-3'><TbListDetails onClick={()=>navigator('/dashboard/employee_target/list/detail',{state:{...d,incentive:state?.id,selected_period:selected_period?.value}})}/></h6>
        
       </div>))}
       </div> 
        </div>
      </div>
    </div>
  )
}

export default EmployeeTargetsDetailInsentive