import React, { useEffect, useState } from 'react'
import AdminReminderMenu from './AdminReminderMenu'
import { useLocation, useNavigate } from 'react-router-dom'
import { IconButton, Tooltip } from '@mui/material'
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../components/button'
import { DatePicker, Modal } from 'antd'
import { BsArrowRepeat } from 'react-icons/bs';
import { FiChevronLeft,FiChevronRight } from 'react-icons/fi';
import { DeleteAdminReminderService, GetAdminReminderService } from '../../services/AdminReminderServices'
import moment from 'moment'
import { AiOutlineEdit,AiOutlineDelete } from 'react-icons/ai'
import toast from 'react-hot-toast'

function AdminReminderRoot() {

    const path = useLocation()
    const path1 = path?.pathname?.split('/')[2]
    const navigate = useNavigate()
  
    const [modal,setmodal] = useState(false)
    const [selected_data,setselected_data] = useState({})
    const [data,setdata] = useState({})
    const [page,setpage] = useState(1)
    const [search,setsearch] = useState({text:'',from_date:'',to_date:'',activate:false})
   
    useEffect(()=>{
        getdata(page)
    },[page,path1])
   
    async function resetfunc(){
        setsearch({text:'',from_date:'',to_date:'',activate:false})
        // getleads(1)
      }
  
      async function applyfilterfunction(page){
        setsearch({...search,activate:true})
        let start_date = new Date(search?.from_date).toLocaleDateString().replaceAll('/','-').split('-').reverse().join('-')
        let end_date = new Date(search?.to_date).toLocaleDateString().replaceAll('/','-').split('-').reverse().join('-')
        // const response = await SearchLeadService({search_text:search.text,status:type,from_date:(search?.from_date !== ''  && search?.from_date !== null) ? start_date : '',to_date:(search?.to_date !== ''  && search?.to_date !== null) ? end_date : '',page:page})
        // setleads(response.data)
        // setfilter_drawer(false)
      }

      async function getdata(page){
        const response = await GetAdminReminderService({page,type:path1 === 'active' ? 0 :1})
        setdata(response?.data)
        // console.log("response.data kp",response.data)
      }

      async function gotoedit(e){
        navigate('edit',{state:e})
      }

      async function deleteDatafunc(){
        const response = await DeleteAdminReminderService(selected_data?._id)
        if(response?.status === 200){
          toast.success("Deleted Successfully")
          setmodal(false)
          setselected_data({})
          getdata(page)
        }
        // if(response?.status)

      }

  return (
    <div className='flex'>
        <AdminReminderMenu /> 
       < Modal open={modal} width={320} closable={false} footer={false}>
          <div> 

            <h6 className='text-[15px] font-[700]'>Delete Data</h6>
            <h6 className='text-[11px] leading-tight p-2' >Before Deleting the data be sure that it is required or not for further purpose.</h6>
             
             <div className='flex items-center border-t pt-2'>
                <ButtonOutlinedAutoWidth btnName="Cancel" onClick={()=>setmodal(false)} />
                <h6 className='w-[10px]'></h6>
                <ButtonFilledAutoWidth btnName="Sure" onClick={deleteDatafunc} />
             </div>
          </div>
       </Modal>
        <div className='w-[88%] px-2 md:px-4 mt-2'>
            <div className='w-[85%] md:w-full flex items-center justify-between border-b pb-2'>
                <h6 className='font-[700] text-[14px] '>Total <span className='capitalize'>{path1}</span> ({data?.pagination?.total})</h6>
                <div className='hidden md:flex items-center'>
              
                <div className='hidden md:flex items-center text-[12px] mr-2'>

                    <h6 className='mr-2 font-[600]'>{page == 1 ? data?.datas?.length > 0 ? 1 : 0 : (page - 1) * data?.pagination?.limit } - {data?.pagination?.limit} of {data?.pagination?.total} </h6>
                    <IconButton onClick={resetfunc}><BsArrowRepeat size={16} /></IconButton>

                    <div>
                    <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>
                    <IconButton onClick={()=>{ page < data?.pagination?.totalPages  ? setpage(page+1) : console.log('')}}><FiChevronRight className={`${(data?.pagination?.totalPages === page || data?.datas?.length === 0)  ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>

                    </div>
                </div>
         
               
                <DatePicker size='small' style={{fontSize:'11px'}} ampm={false} placeholder='From Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.from_date} onChange={(v) => {console.log('v na',new Date(v).toLocaleDateString());setsearch({...search,from_date:v})}} /> 

                <DatePicker size='small' style={{fontSize:'11px'}} ampm={false} placeholder='To Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.to_date} onChange={(v) => {console.log('v na',new Date(v).toLocaleDateString());setsearch({...search,to_date:v})}} /> 

                
                <div className='hidden md:flex mr-2'>
                    

                <input  id="search_text" placeholder='Search text' type='text' value={search.text} onChange={(e)=>setsearch({...search,text:e.target.value})} className='border py-[3px] focus:ring-0 focus:outline-0 text-[14px]  w-28 px-1 rounded-md border-slate-300' />
            
                </div>      

            <ButtonOutlinedAutoWidth btnName="Add Filter" onClick={()=>applyfilterfunction(1)} /> 
          
            <div className='ml-2'>
            <ButtonFilledAutoWidth btnName="Add Data" onClick={()=>navigate('create')}/> 
            </div>
                </div>

                <div className='block md:hidden '>
                <ButtonFilledAutoWidth btnName="Add Data" onClick={()=>navigate('create')}/> 
                </div>
            </div>

            <div className='grid w-[84%] md:w-full md:grid-cols-5 gap-1 mt-2'>
               {data?.datas?.map((d)=>(
                <div  key={d?._id} className='border px-1.5 py-1 mt-1 relative mx-1 relative cursor-pointer' >
                            <div className='bg-yellow-100 p-2'>
                            <Tooltip title="Delete"><span className='absolute bg-white p-1 right-0  top-0'><AiOutlineDelete size={20} className='absolute bg-white p-1 right-6 top-0' onClick={()=>{setselected_data(d);setmodal(true)}} /></span></Tooltip>
                            <Tooltip title="Edit"><span className='absolute bg-white p-1 right-0  top-0'><AiOutlineEdit size={20} className='absolute bg-white p-1 right-1 top-0' onClick={()=>{gotoedit(d)}} /></span></Tooltip>
                            <h6 className='text-[11px] ' >Message : <span className='font-[600] text-[11px] line-clamp-2'>{d?.summary}</span> </h6>
                            </div>

                            {(d?.department !== '' && d?.department !== null && d?.department !== undefined)  &&
                            <h6 className='text-[11px] flex px-2 pt-1' >Department : <span className='pl-1 font-[600] text-[11px] line-clamp-2'>{d?.department?.department_name}</span> </h6>}

                            <h6 className='text-[11px] flex font-[800] pt-1' ><span className='pl-1 text-[11px] line-clamp-2'>Reminder To : {d?.reminder_to?.name}</span> </h6>
                            <h6 className='text-[11px] flex font-[800] pt-1' ><span className='pl-1 text-[11px] line-clamp-2'>Reminder Date : {moment(d?.reminder_to?.date)?.format('LL')}</span> </h6>

                            <h6 className='text-[10px] ml-1 font-[400] mt-0.5' >Created At : {moment(d?.createdAt).format('LLL')}</h6>

                  </div>  
               ))} 

            </div>
        </div>

    </div>
  )
}

export default AdminReminderRoot