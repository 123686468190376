import flogo from '../assets/images/flogo.jpeg'
import applogo from '../assets/images/fidelitus.png'
import glogo from '../assets/images/globe.svg'

export const globelogo = glogo
export const fidelogo = flogo
export const appbarlogo = applogo

export default  function Priceconstants(v) {
    let rupeeIndian = Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    });

    return v !== undefined  ? rupeeIndian.format(JSON.stringify(v))?.split('.')[0] : rupeeIndian.format(JSON.stringify(0))?.split('.')[0]
 
}
