import React,{useState,useEffect} from 'react'
import { Tooltip,IconButton } from '@mui/material';
import {  ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../../../components/button';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import {AiOutlineEdit,AiOutlineDelete} from 'react-icons/ai'
import { Modal,Select } from 'antd';
import moment from 'moment';
import WebsiteMenu from '../../WebsiteMenu';
import {BsArrowRepeat} from 'react-icons/bs';
import {FiChevronRight,FiChevronLeft} from 'react-icons/fi';
import { AiOutlineFilter } from "react-icons/ai";
import { GetProjectWebsiteStageService } from '../../../../services/Website/ProjectWebsiteOption/ProjectStageServices';
import { DeleteProjectWebsiteStageService } from '../../../../services/Website/ProjectWebsiteOption/ProjectStageServices';

function ProjectStageList() {


    const [data,setdata] = useState([])
    const [selecteddata,setselecteddata] = useState({})
    const [modal, setModal] = useState(false);
    const [page, setpage] = useState(1);
    const navigate = useNavigate()

    const [search,setsearch] = useState({text:'',type:''})

    const option = [
      {label:'Tag',value:'Tag'},
      {label:'RelatedTo',value:'RelatedTo'}
    ]

  
    useEffect(()=>{
        getdata()
    },[page])

   

  
  async function getdata(){
   const response = await GetProjectWebsiteStageService(page,search?.text,search?.type)
   setdata(response.data)
  }  

  async function filterData(){
    setpage(1)
    const response = await GetProjectWebsiteStageService(1,search?.text,search?.type)
   setdata(response.data)
  }



  async function deleteRole(){
    const response = await DeleteProjectWebsiteStageService(selecteddata.id)
    if(response.status === 200){
        setModal(false)
        toast.success('Project Stage Deleted Successfully')
        getdata()
    }
  }

  async function resetfunc(){
    setpage(1)
    setsearch({text:'',type:''})
    const response = await GetProjectWebsiteStageService(page,'','')
    setdata(response.data)
  }

  return (
    <div className='h-screen max-h-screen box-border overflow-hidden'>
   
    <Modal
       keepMounted
       open={modal}
       onClose={()=>setModal(false)}
       width={300}
       footer={false}
       closable={false}
      
     >
       <div >
         <h6 className="font-bold text-[15px] text-center mb-2 w-full">Are you sure?</h6>
         <h6 className='bg-slate-100 text-center text-[12px] p-1.5 font-[400]'>After deleting you cannot retrieve it back before deleting check once whether you have used it in some data</h6>
         <div className='flex justify-end mt-3 '>
           {/* <div className='mr-1 w-full'> */}
           <ButtonOutlinedAutoWidth btnName={'Cancel'} onClick={()=>setModal(false)} />
           {/* </div> */}
           <div  className='ml-2'>
           <ButtonFilledAutoWidth btnName={'Confirm'} onClick={()=>deleteRole()}  /> 
           </div>
         </div>
       </div>
     </Modal>
       
     <div className='block sm:flex'>
       <WebsiteMenu />

       <div className='w-[88%] px-4'>
       <div >
       <div className='pt-5'>
       <div className="flex justify-between align-center items-center border-b pb-2 ">
       <span className="font-black text-[14px]">Project Stage ({data?.pagination?.total})</span>
       


       <div className='flex items-center'>


       <h6 className='mr-2 text-[12px] font-[600]'>{page == 1 ? data?.datas?.length > 0 ? 1 : 0 :  (page - 1) * data?.pagination?.limit } - {data?.pagination?.limit} of {data?.pagination?.total} </h6>
                  <IconButton onClick={resetfunc}><BsArrowRepeat size={16} /></IconButton>

                  <div>
                  <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>
                  <IconButton onClick={()=>{ page < data?.pagination?.totalPages  ? setpage(page+1) : console.log('')}}><FiChevronRight className={`${(data?.pagination?.totalPages === page || data?.datas?.length === 0)  ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>

                  </div>

       <input type="text"  className='w-[120px] focus:outline-none pl-2 text-[13px] mr-2 border border-slate-300 h-[30px] rounded-[7px]'  /> 
       <div className='w-[100px] mr-2'>
        <Select allowClear={true} onChange={(e)=>setsearch({...search,type:e})} value={search?.type == '' ? '' : search?.type}  bordered={false}  className='w-[100%] border border-slate-300 h-[30px] rounded-[7px]' options={option} />
        </div>
        <AiOutlineFilter onClick={filterData} size={28}  className='text-slate-700 bg-slate-200 p-[5px] rounded mr-[5px]'/>
        <ButtonFilledAutoWidth  btnName="Add Project Stage" onClick={()=>navigate('create')} /> 
       </div>
       </div> 
       </div>

       {data?.length === 0 &&
        <div className='grid place-items-center mt-20  items-center justify-center'>
        <img src={'https://fidecrmfiles.s3.amazonaws.com/NoDataFound1.png'} className='w-40 h-40 object-contain' /> 
        <h6 className='font-bold text-[14px] -mt-10'>No data found</h6>
        <h6 className='font-[500] w-[70%] text-center text-slate-700 text-[12.5px] -mt-2'>Oops we couldn't find any data added based on your current page option please click on add the data button to see list here.</h6>
      </div>
       }


      <div className='h-[88vh] overflow-y-scroll'>
       {data?.datas?.length > 0 &&
       <div className='grid grid-cols-6 gap-1 mt-2'>
           {data?.datas?.map((d,i)=>(
           <div key={i}  className='border items-center relative justify-between px-2 py-1 border-b'>
               <h6 className='text-[13px] font-[800] capitalize '>{d?.name}</h6>
               <h6 className='text-[11px] font-[500] py-1 capitalize '>Category : <span className='font-[800] bg-gray-100 p-1'>{d?.type}</span> </h6>
               <h6 className='text-[10px] font-[500] bg-slate-100 mt-1 p-1'>Created At : <span className='text-[10px] font-[800]'>{moment(d.createdAt).format('LLL')}</span> </h6>
               <div className='absolute right-1 bg-white top-1.5 flex'>
               <Tooltip title="Edit" >
                <span><AiOutlineEdit size={14} className="cursor-pointer" onClick={()=>{navigate('edit',{state:d})}}/></span>
               </Tooltip>
               <Tooltip title="Delete">
                <span><AiOutlineDelete size={14} className='ml-2 cursor-pointer'  onClick={()=>{setselecteddata({department_name:d.department_name,id:d._id});setModal(true)}}/></span>
               </Tooltip>
               </div>
           </div>
           ))}
       </div>}
       </div>
         </div>
       </div>
     </div> 
   </div>
  )
}

export default ProjectStageList