import React from 'react';
import {useLocation,useNavigate } from 'react-router-dom';
import {TbRadioactive,TbRadioactiveOff} from 'react-icons/tb';
// import {DatePicker} from 'antd';




function AdminReminderMenu() {

  const {pathname} = useLocation();
  const path = pathname.split('/')[pathname.split('/').length - 2]



  const navigate = useNavigate();

  const menu = [
    {name:'Active Reminder',icon:TbRadioactive,path:'/reminder/active',id:1,color:''},
    {name:'InActive Reminder',icon:TbRadioactiveOff,path:'/reminder/inactive',id:1,color:''},
  ]  
  

  return (
    <div className='hidden md:block mr-0 min-h-screen max-h-sceen border-r  w-44 px-2' >

        <div className='mb-4 mt-5'>
        <h6 className=' ml-2 mb-2 font-[700] text-[12px]'>Reminder Option</h6>

            {menu.map((m)=>(
                <div key={m?.path} className={`flex items-center my-1 hover:bg-[#f4f5f7] px-1.5 py-1 ml-2 rounded-md relative cursor-pointer ${pathname ===  m.path && 'bg-[#f4f5f7]'}`} onClick={()=>navigate(m?.path)}>
                {pathname ===  m.path &&  <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3' ></span>}
               <m.icon color='#000' style={{backgroundColor:m?.color,padding:'2px'}} size={16}  />
               <span className='text-[#000] font-[500] ml-2 text-[12px]'>{m.name}</span>
              </div>    
            ))}
        </div>
    </div>
  )
}

export default AdminReminderMenu