import React, { useState,useEffect } from 'react'
import SettingsMenu from '../staticscreens/SettingsMenu'
import { GetOnboardUserInReviewDetailService } from '../../services/OnboardServices'
import { useLocation, useNavigate } from 'react-router-dom'
import {BiErrorCircle,BiCheckbox,BiCheckboxSquare} from 'react-icons/bi'
import {TextAreaInput1, TextInput} from '../../components/input';
import { AiOutlineEdit } from 'react-icons/ai';
import { Select,Tooltip } from 'antd'
import {IoClose} from 'react-icons/io5'
import { updateOnboardDetailsByHr, uploadFile } from '../../services/AuthServices'
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../components/button'
import GoBack from '../../components/back/GoBack'
import toast from 'react-hot-toast'

function OnboardDetail() {
 
   const {state} = useLocation()
   const navigate = useNavigate()
   const [step,setstep] = useState(1)


   const [docs,setdocs] = useState({addhar:'',pan_card:'',tenth_marks_card:'',puc_marks_card:'',degree_marks_card:'',master_marks_card:'',signature:'',experience_relieving_letter:'',last_employer_payslips:'',terms_and_signature:false,signature_policy:false,form11revised:'',esi:'',formf:'',nda:'',nominee:''})
   const [docs_err,setdocs_err] = useState({addhar:'',pan_card:'',tenth_marks_card:'',puc_marks_card:'',degree_marks_card:'',master_marks_card:'',signature:'',experience_relieving_letter:'',last_employer_payslips:'',terms_and_signature:false,signature_policy:false,form11revised:'',esi:'',formf:'',nda:'',nominee:''})
   const [other_info,setother_info] = useState({present_address:'',permanent_address:'',total_experience:'',addhar_no:'',pan_no:'',previous_uan_pf_no:'',previous_esi_no:'',emergency_contact:'',father_husband_name:'',gender:'',blood_group:'',martial_status:'',have_superpower:'',fun_fact:'',describe_yourself:'',favourite_hobby:''}) 
   const [other_info_err,setother_info_err] = useState({present_address:'',permanent_address:'',total_experience:'',addhar_no:'',pan_no:'',previous_uan_pf_no:'',previous_esi_no:'',emergency_contact:'',father_husband_name:'',gender:'',blood_group:'',martial_status:'',have_superpower:'',fun_fact:'',describe_yourself:'',favourite_hobby:''}) 

   const [languages,setlanguages] = useState([])
   const [families,setfamilies] = useState([])
   const [families_err,setfamilies_err] = useState('')
   const [bank,setbank] = useState({bank_name:'',account_no:'',ifsc_code:'',bank_address:'',bank_photo:null,visible:false})
   const [bank_err,setbank_err] = useState({bank_name:'',account_no:'',ifsc_code:'',bank_address:'',bank_photo:null})
   const [language,setlanguage] = useState({id:'',language:'',read:false,write:false,speak:false})
   const [language_err,setlanguage_err] = useState({language_err1:''})
   const [family,setfamily] = useState({id:'',name:'',relationship:'',education:'',occupation:'',age:'',file:'',visible:false})
   const [family_err,setfamily_err] = useState({name_err:'',relationship_err:'',education_err:'',occupation_err:'',age_err:'',file:''})
   
   // console.log("state",state)

   const [data,setdata] = useState({
      name:'',
      employee_id:'',
      department:'',
      designation_id:'',
      official_phone:'',
      official_email:'',
      dob:'',
      doj:'',
      doe:'',
      email:'',
      phone:'',
      roles:[],
      is_active:false,
      branch:'',
      is_verified:false,
      is_partially_verified:false,
      sent_for_review:false,
      app_access:false,
      crendtials_shared:false,
      user_in_review:false,
      hr_reject_summary:'',
      notification:false
   })

  useEffect(()=>{
    getdata()
  },[])


   
  const [departmentArr,setdepartmentArr] = useState([])
  const [designationArr,setdesignationArr] = useState([])
  const [branchArr,setbranchArr] = useState([])
  const [rolesArr,setrolesArr] = useState([])
 
  const [error,seterror] = useState({
  name:'',
  employee_id:'',
  department:'',
  designation_id:'',
  official_phone:'',
  official_email:'',
  dob:'',
  doj:'',
  doe:'',
  email:'',
  phone:'',
  is_active:false,
  branch:'',
  is_verified:false,
  is_partially_verified:false,
  sent_for_review:false,
  app_access:false,
  crendtials_shared:false,
  user_in_review:false,
  hr_reject_summary:''
  })

  const roles = ['hr_admin','admin','controller']

useEffect(()=>{
  getdata()
},[])


const languages_Arr = [
   {label:'Kannada',value:'Kannada'},
   {label:'English',value:'English'},
   {label:'Hindi',value:'Hindi'},
   {label:'Telugu',value:'Telugu'},
   {label:'Urdu',value:'Urdu'},
   {label:'Bengali',value:'Bengali'},
   {label:'Tulu',value:'Tulu'},
   {label:'Tamil',value:'Tamil'},
   {label:'Malayalam',value:'Malayalam'},
   {label:'Konkani',value:'Konkani'},
   {label:'Marati',value:'Marati'},
]

const relationship_Arr = [
 {label:'Mother',value:'Mother'},
 {label:'Father',value:'Father'},
 {label:'Cousin',value:'Cousin'},
 {label:'Brother',value:'Brother'},
 {label:'Sister',value:'Sister'},
 {label:'Child',value:'Child'},
 {label:'Spouse',value:'Spouse'},
 {label:'Neighbour',value:'Neighbour'},
 {label:'Other',value:'Other'},
 
]

const blood_group_Arr = [
 {label:'A+',value:'A+'},
 {label:'A-',value:'A-'},
 {label:'B+',value:'B+'},
 {label:'B-',value:'B-'},
 {label:'O+',value:'O+'},
 {label:'O-',value:'O-'},
 {label:'AB+',value:'AB+'},
 {label:'AB-',value:'AB-'}
]



//  console.log("data",data) 



function handlechange(e){
setdata({...data,[e.target.name] : e.target.value})
seterror({...error,[e.target.name]:''})
}

function handlechange1(e){
  setfamily({...family,[e.target.name] : e.target.value})
  setfamily_err({...family_err,[e.target.name]:''})
}

function handlechange2(e){
  setother_info({...other_info,[e.target.name] : e.target.value})
  setother_info_err({...other_info_err,[e.target.name]:''})
}

function handlechange3(e){
 setbank({...bank,[e.target.name] : e.target.value})
 setbank_err({...bank_err,[e.target.name]:''})
}

function ErrorComponent({error}){
    return (error !== '' && error !== undefined && error !== null) &&
    <div className='flex items-center mt-1'>
     <BiErrorCircle className='text-red-500' size={14} />
     <span className='text-[10px] text-red-500 ml-1'>{error}</span>
     </div>
  }

  const steps =['Basic Info','Additional Info','Documents','Bank Details','Other Docs','Terms and Condition',] 


function addFamily(){
  let obj = {...family}
  console.log("obj",obj)
  if(obj.id === ''){
      obj['id'] = families?.length
      setfamilies([...families,obj])
  }else{
     let families_data = [...families] 
     let index = families_data.findIndex((f)=>f.id === obj.id)
     if(index >= 0){
      families_data[index] = family
      setfamilies(families_data)
     }
  }
  setfamilies_err('')
  setfamily({id:'',name:'',relationship:'',education:'',occupation:'',age:'',file:'',visible:false})

}

function addLanguage(){
  if(!language?.language){
    setlanguage_err({...language_err,language:'This field is required*'})
  }else{
    let obj = {...language}
    if(obj.id === ''){
       obj['id'] = languages.length
       setlanguages([...languages,obj])
       
    }else{
       let languages_data = [...languages] 
       let index = languages_data.findIndex((f)=>f.id === obj.id)
       if(index >= 0){
       languages_data[index] = family
       setlanguages(languages_data)
       }
    }  
    setlanguage({id:'',language:'',read:false,write:false,speak:false})
    setlanguage_err({id:'',language:'',read:false,write:false,speak:false})
  }

}

async function handlefileupload(file,name,type){
    const response = await uploadFile({file:file})
    if(response?.status === 200){
      if(type === 'docs'){
         setdocs({...docs,[name]:response?.data?.data})
      }else if(type === 'bank'){
         setbank({...bank,[name]:response?.data?.data})
      }
    }
   //  console.log("response",response?.data?.data)
 }
  async function getdata(){
    const response = await GetOnboardUserInReviewDetailService(state)
    if(response?.data?.datas?.length > 0 ){
        let d = response?.data?.datas[0]
        let roles = []
        d?.user_roles?.forEach((r)=>{
        roles.push({label:r?.roles?.display_name,value:r?.roles?._id})
        })

        setdata({...data,
        name:d?.name,
        employee_id:d?.employee_id,
        department:{value:d?.department_id[0]?._id,label:d?.department_id[0]?.department_name},
        designation_id:{value:d?.designation_id[0]?._id,label:d?.designation_id[0]?.designation_name},
        official_phone:d?.official_phone,
        official_email:d?.official_email,
        dob:d?.dob,
        doj:d?.doj,
        doe:'',
        roles:roles,
        email:d?.email,
        phone:d?.phone,
        is_active:d?.is_active,
        branch:{value:d?.branch[0]?._id,label:d?.branch[0]?.branch_name},
        is_verified:d?.is_verified,
        is_partially_verified:d?.is_partially_verified,
        sent_for_review:d?.sent_for_review,
        app_access:d?.app_access,
        crendtials_shared:d?.crendtials_shared,
        user_in_review:d?.user_in_review,
        notification:false
        })

        setother_info({
           present_address:d?.other_info[0]?.present_address,
           permanent_address:d?.other_info[0]?.permanent_address,
           total_experience:d?.other_info[0]?.total_experience,
           addhar_no:d?.other_info[0]?.addhar_no,
           pan_no:d?.other_info[0]?.pan_no,
           previous_uan_pf_no:d?.other_info[0]?.previous_uan_pf_no,
           previous_esi_no:d?.other_info[0]?.previous_esi_no,
           emergency_contact:d?.other_info[0]?.emergency_contact,
           father_husband_name:d?.other_info[0]?.father_husband_name,
           gender:{label:d?.other_info[0]?.gender,value:d?.other_info[0]?.gender},
           blood_group:{label:d?.other_info[0]?.blood_group,value:d?.other_info[0]?.blood_group},
           martial_status:{label:d?.other_info[0]?.martial_status,value:d?.other_info[0]?.martial_status},
           have_superpower:d?.other_info[0]?.have_superpower !== undefined ? d?.other_info[0]?.have_superpower : '',
           fun_fact:d?.other_info[0]?.fun_fact !== undefined ? d?.other_info[0]?.fun_fact : '',
           describe_yourself:d?.other_info[0]?.describe_yourself !== undefined ? d?.other_info[0]?.describe_yourself : '',
           favourite_hobby:d?.other_info[0]?.favourite_hobby !== undefined ? d?.other_info[0]?.favourite_hobby : '',
        })


        setdocs({
           addhar:d?.other_info[0]?.addhar === undefined ? '' : d?.other_info[0]?.addhar,
           pan_card:d?.other_info[0]?.pan_card  === undefined ? '' : d?.other_info[0]?.pan_card,
           tenth_marks_card:d?.other_info[0]?.tenth_marks_card === undefined ? '' : d?.other_info[0]?.tenth_marks_card,
           puc_marks_card:d?.other_info[0]?.puc_marks_card === undefined ? '' : d?.other_info[0]?.puc_marks_card,
           degree_marks_card:d?.other_info[0]?.degree_marks_card === undefined ? '' : d?.other_info[0]?.degree_marks_card,
           master_marks_card:d?.other_info[0]?.master_marks_card === undefined ? '' : d?.other_info[0]?.master_marks_card,
           experience_relieving_letter:d?.other_info[0]?.experience_relieving_letter === undefined ? '' : d?.other_info[0]?.experience_relieving_letter,
           last_employer_payslips:d?.other_info[0]?.last_employer_payslips === undefined ? '' : d?.other_info[0]?.last_employer_payslips,
           signature:d?.other_info[0]?.signature === undefined ? '' : d?.other_info[0]?.signature,
           terms_and_signature:d?.other_info[0]?.terms_and_signature === undefined ? '' : d?.other_info[0]?.terms_and_signature,
           signature_policy:d?.other_info[0]?.signature_policy === undefined ? '' : d?.other_info[0]?.signature_policy,
           form11revised:d?.other_info[0]?.form11revised !== undefined ? d?.other_info[0]?.form11revised : '',
           esi:d?.other_info[0]?.esi !== undefined ? d?.other_info[0]?.esi : '',
           formf:d?.other_info[0]?.formf !== undefined ? d?.other_info[0]?.formf : '',
           nda:d?.other_info[0]?.nda !== undefined ? d?.other_info[0]?.nda : '',
           nominee:d?.other_info[0]?.nominee !== undefined ? d?.other_info[0]?.nominee : ''
        }
        )

        setbank({
           bank_name:d?.other_info[0]?.bank_name,
           account_no:d?.other_info[0]?.account_no,
           bank_photo:d?.other_info[0]?.bank_photo === undefined ? '' : d?.other_info[0]?.bank_photo,
           ifsc_code:d?.other_info[0]?.ifsc_code,
           bank_address:d?.other_info[0]?.bank_address,
        })

      //   if(d?.other_info[0]?.languages !== undefined){
      //      setlanguages(d?.other_info[0]?.languages)
      //   }
      //   if(d?.other_info[0]?.families !== undefined){
      //      setfamilies(d?.other_info[0]?.families)
      //   }
      let languagesArr = []
      let familiesArr = []
      
      if(d?.other_info[0]?.languages !== undefined){
         d?.other_info[0]?.languages.forEach((l,i)=>{
            languagesArr.push({...l,id:i})
         })
         // setlanguages(d?.other_info[0]?.languages)
      }
      if(d?.other_info[0]?.families !== undefined){
         d?.other_info[0]?.families.forEach((l,i)=>{
            familiesArr.push({...l,id:i})
         })
         // setfamilies(d?.other_info[0]?.families)
      }
      setlanguages(languagesArr)
      setfamilies(familiesArr)
  }
  }


  async function handleSelect(e,e1){
    if(e1 === 'department'){
    setdata({...data,department:departmentArr?.find((d)=>d.value === e)})
    seterror({...error,department:''})
    }else if(e1 === 'designation_id'){
    setdata({...data,designation_id:designationArr?.find((d)=>d.value === e)})
    seterror({...error,designation_id:''})
    }else if(e1 === 'branch'){
    setdata({...data,branch:branchArr?.find((d)=>d.value === e)})
    seterror({...error,branch:''})
    }else if(e1 === 'roles'){
    let arr = []
    e?.forEach((ele)=>{
    arr?.push(rolesArr?.find((r)=>r?.value === ele))
    })
    setdata({...data,roles:arr})
    seterror({...error,roles:''})
    }
    }

    async function prevStep(){
        if(step === 1){
           navigator(-1)
        }else{
            setstep(step - 1)
        }
    
    }

   async function nextStep(){
     if(step == 6){
        submitForm()
     }else{
        setstep(step + 1)
     }
   }  

   async function submitForm(){
    const send_data = {
        data:{
           id:state,
           name:data?.name,
           department:data?.department?.value,
           designation_id:data?.designation_id?.value,
           branch:data?.designation_id?.value === undefined ? '' : data?.branch?.value,
           dob:data?.dob,
           doj:data?.doj,
           doe:data?.doe,
           email:data?.email,
           phone:data?.phone,
           is_active:data?.is_active,
           is_verified:data?.is_verified,
           is_partially_verified:data?.is_partially_verified,
           app_access:data?.app_access,
           crendtials_shared:data?.crendtials_shared,
           user_in_review:data?.user_in_review,
           sent_for_review:data?.sent_for_review,
           approved:data?.approved === true ? true : data.approved === undefined ? false : data.approved,
           notification:data?.notification

        },
        other_docs:{
            
           user:state?.id,
           addhar:other_info?.addhar,
           pan_card:other_info?.pan_card,
           tenth_marks_card:other_info?.tenth_marks_card,
           puc_marks_card:other_info?.puc_marks_card,
           degree_marks_card:other_info?.degree_marks_card,
           master_marks_card:other_info?.master_marks_card,
           signature:other_info?.signature,
           terms_and_signature:other_info?.terms_and_signature,
           signature_policy:other_info?.signature_policy,
           present_address:other_info?.present_address,
           permanent_address:other_info?.permanent_address,
           total_experience:other_info?.total_experience,
           addhar_no:other_info?.addhar_no,
           pan_no:other_info?.pan_no,
           previous_uan_pf_no:other_info?.previous_uan_pf_no,
           previous_esi_no:other_info?.previous_esi_no,
           emergency_contact:other_info?.emergency_contact,
           father_husband_name:other_info?.father_husband_name,
           gender:other_info?.gender?.value,
           blood_group:other_info?.blood_group?.value,
           martial_status:other_info?.martial_status?.value,
           bank_name:bank?.bank_name,
           account_no:bank?.account_no,
           ifsc_code:bank?.ifsc_code,
           bank_address:bank?.bank_address,
           bank_photo:bank?.bank_photo,
           families:families,
           languages:languages,
           addhar:docs?.addhar,
           pan_card:docs?.pan_card,
           tenth_marks_card:docs?.tenth_marks_card,
           puc_marks_card:docs?.puc_marks_card,
           degree_marks_card:docs?.degree_marks_card,
           master_marks_card:docs?.master_marks_card,
           signature:docs?.signature,
           terms_and_signature:docs?.terms_and_signature,
           signature_policy:docs?.signature_policy,
           experience_relieving_letter:docs?.experience_relieving_letter,
           last_employer_payslips:docs?.last_employer_payslips,
           have_superpower:other_info.have_superpower,
            fun_fact:other_info?.fun_fact,
            describe_yourself:other_info?.describe_yourself,
            favourite_hobby:other_info?.favourite_hobby,
            form11revised:docs?.form11revised,
            esi:docs?.esi,
            formf:docs?.formf,
            nda:docs?.nda,
            nominee:docs?.nominee,
        }  
        }

     const response = await updateOnboardDetailsByHr(send_data)
     if(response?.status === 200){
        toast.success("Form Submitted successfully")
        navigate(-1)
     }
   }
   
   
  return (
    <div className='h-screen max-h-screen min-h-screen overflow-hidden'>
       <div className='flex'> 

         <SettingsMenu />
         <div className='px-5 max-h-screen min-h-screen w-full overflow-y-scroll'>
 
         <div className=' pt-5'>
            <GoBack />  

            <div className='flex no-scrollbar my-5 w-[40%] overflow-y-scroll border-b items-center'>
            {steps?.map((d,i)=>(
                <h6 onClick={()=>setstep(i+1)} className={`p-1 min-w-fit md:w-[100%] text-center cursor-pointer ${step == i+1 ? 'bg-slate-700 text-white' : ''} font-[500] text-[11px]`}>{d}</h6>
            ))}
            </div> 


            {step === 1 &&
            <div className='md:w-64'>

<TextInput 
label={'Name*'} 
mandatory={true} 
variant="standard"
name="name"
type="text"
error={error.name}
value={data.name}
handlechange={handlechange}
placeholder="Enter your Department name"
/>

{roles?.includes((r)=>data?.roles?.includes(r)) &&
<TextInput 
mandatory={true} 
label={'Employee Id'} 
variant="standard"
name="employee_id"
type="text"
error={error.employee_id}
value={data.employee_id}
handlechange={handlechange}
placeholder="Enter your Employee Id"
/>}

<div className='flex justify-between'>

<div className="w-full mr-1"> 
<TextInput 
mandatory={true} 
label={'Phone*'} 
variant="standard"
name="phone"
type="text"
error={error.phone}
value={data.phone}
handlechange={handlechange}
placeholder="Enter your Phone"
/>
</div> 

<div className="w-full ml-1 mt-0.5"> 
<h6 className='text-[12px] font-semibold mb-1 mt-1'>Date of Birth* </h6>
<input className='border p-1.5 w-full text-[11px] border-gray-300 border-l-4 border-l-slate-600' type='Date' value={data?.dob?.slice(0,10)} onChange={(e)=>{setdata({...data,dob:e.target.value});seterror({...error,dob:''})}} />

{/* <DatePicker ampm={false} placeholder='' style={{maxHeight:'40px',borderWidth:'2px solid #ddd',borderRadius:'4px',minWidth:'100%'}} inputFormat="MM/dd/yyyy" value={data?.dob === '' ? null : moment(data?.dob)} onChange={(v) => {setdata({...data,dob:v});seterror({...error,dob:''})}} /> */}
{(error?.dob !== '' || error?.dob !== '') && <h6 className='text-red-600 text-[10px]'>{error?.dob}</h6>}
</div>
</div>

<div className='flex justify-between mt-1 w-full'>

<div className="w-full mr-1">
<h6 className='text-[12px] font-semibold mb-1 mt-1'>Date of Joining *</h6>
<input className='border p-1.5 text-[11px] w-full text-[11px] border-gray-300 border-l-4 border-l-slate-600' type='Date' value={data?.doj?.slice(0,10)} onChange={(e)=>{setdata({...data,doj:e.target.value});seterror({...error,doj:''})}} />
{/* <DatePicker placeholder='' style={{maxHeight:'40px',borderWidth:'2px solid #ddd',borderRadius:'4px',minWidth:'100%'}} inputFormat="MM/dd/yyyy" value={data?.doj === '' ? null : moment(data?.doj)} onChange={(v) => {setdata({...data,doj:v});seterror({...error,doj:''})}} /> */}
{(error?.doj !== '' || error?.doj !== '') && <h6 className='text-red-600 text-[10px]'>{error?.doj}</h6>}
</div>


{roles?.includes((r)=>data?.roles?.includes(r)) &&
<div className="w-full ml-1">
<h6 className='text-[12px] font-semibold mb-1 mt-1'>Date of Exit </h6>
<input className='border p-1.5 text-[11px] w-full text-[11px] border-gray-300 ' type='Date' value={data?.doe?.slice(0,10)} onChange={(e)=>{setdata({...data,doe:e.target.value});seterror({...error,doe:''})}} />
{/* <DatePicker placeholder='' style={{maxHeight:'40px',borderWidth:'2px solid #ddd',borderRadius:'4px',minWidth:'100%'}} inputFormat="MM/dd/yyyy" value={data?.doe === '' ? null : moment(data.doe)} onChange={(v) => {setdata({...data,doe:v});seterror({...error,doe:''})}} /> */}
{(error?.doe !== '' || error?.doe !== '') && <h6 className='text-red-600 text-[10px]'>{error?.doe}</h6>}
</div>}
</div>


<TextInput 
label={'Email*'} 
mandatory={true}
variant="standard"
name="email"
type="text"
error={error.email}
value={data.email}
handlechange={handlechange}
placeholder="Enter your email address"
/>




<h6 className='text-[12px] font-semibold mb-1 mt-1.5'>Department* </h6>
<Select

value={data.department} 
error={error.department}
bordered={false}
placeholder="" 
onChange={(e)=>handleSelect(e,'department')} 
options={departmentArr}  
className='w-full border bg-white border-slate-300 h-[35px] outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-transparent focus:ring-offset-0 focus:shadow-none border-l-4 border-l-slate-600'
/>
{error.department !== '' && error.department !== undefined &&
<div className='flex items-center mt-1'>
<BiErrorCircle className='text-red-500' size={14} />
<span className='text-[10px] text-red-500 ml-1'>{error.department}</span>
</div>}



<h6 className='text-[12px] font-semibold mb-1 mt-1.5'>Designation </h6>
<Select
value={data.designation_id} 
error={error.designation_id}
bordered={false}
placeholder="" 
onChange={(e)=>handleSelect(e,'designation_id')} 
options={designationArr} 
className='w-full border bg-white border-slate-300 h-[35px] outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-transparent focus:ring-offset-0 focus:shadow-none border-l-4 border-l-slate-600'

/>
{error.designation_id !== '' && error.designation_id !== undefined &&
<div className='flex items-center mt-1'>
<BiErrorCircle className='text-red-500' size={14} />
<span className='text-[10px] text-red-500 ml-1'>{error.designation_id}</span>
</div>}

<h6 className='text-[12px] font-semibold mb-1 mt-1.5'>Branch </h6>
<Select
bordered={false}
value={data.branch} 
error={error.branch}
placeholder="" 
onChange={(e)=>handleSelect(e,'branch')} 
options={branchArr} 
className='w-full border bg-white border-slate-300 h-[35px] outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-transparent focus:ring-offset-0 focus:shadow-none border-l-4 border-l-slate-600'
/>
{error.branch !== '' && error.branch !== undefined &&
<div className='flex items-center mt-1'>
<BiErrorCircle className='text-red-500' size={14} />
<span className='text-[10px] text-red-500 ml-1'>{error.branch}</span>
</div>}

{roles?.includes((r)=>data?.roles?.includes(r)) &&
<div>
<TextInput 
label={'Official Email'} 
variant="standard"
name="official_email"
type="text"
error={error.official_email}
value={data.official_email}
handlechange={handlechange}
placeholder="Enter your official email"
/>


<TextInput 
label={'Official Phone'} 
variant="standard"
name="official_phone"
type="text"
error={error.official_phone}
value={data.official_phone}
handlechange={handlechange}
placeholder="Enter your official phone"
/>
</div>}







{/* {data?.user_in_review && roles?.filter(f=>users?.includes(f))?.length > 0  && */}

<TextAreaInput1 
label={'Summary For User'} 
variant="standard"
name="hr_reject_summary"
type="text"
error={error.hr_reject_summary}
value={data.hr_reject_summary}
handlechange={handlechange}
placeholder=""
/>
{/* } */}



            </div>}
    
   
            {step === 2 &&
            <div>
            <div className='md:w-72'>
        {/* <h6 className='text-[14px] font-[800]'>Additional Info</h6>
        <h6 className='text-[11px] p-2  mb-2 bg-slate-100'>Use the below additional Information added by user during onboarding</h6>
         */}
        <TextInput
                label={'Addhar Card No*'} 
                mandatory={true}
                variant="standard"
                name="addhar_no"
                type="text"
                error={other_info_err?.addhar_no}
                value={other_info?.addhar_no}
                handlechange={handlechange2}
                placeholder="Enter your Phone"
            />
    
        <TextInput
                label={'Pan Card No*'} 
                mandatory={true}
                variant="standard"
                name="pan_no"
                type="text"
                error={other_info_err?.pan_no}
                value={other_info?.pan_no}
                handlechange={handlechange2}
                placeholder="Enter your Phone"
            />
    
    
        <TextInput
                label={'Previous UAN/PF Number'} 
                variant="standard"
                name="previous_uan_pf_no"
                type="text"
                error={other_info_err.previous_uan_pf_no}
                value={other_info?.previous_uan_pf_no}
                handlechange={handlechange2}
                placeholder="Enter your Phone"
            />
    
            <TextInput
                label={'Previous ESI Number'} 
                variant="standard"
                name="previous_esi_no"
                type="text"
                error={other_info_err.previous_esi_no}
                value={other_info?.previous_esi_no}
                handlechange={handlechange2}
                placeholder="Enter your Phone"
            />
    
        <TextInput
                label={'Father/Husband Name*'} 
                mandatory={true}
                variant="standard"
                name="father_husband_name"
                type="text"
                error={other_info_err.father_husband_name}
                value={other_info?.father_husband_name}
                handlechange={handlechange2}
                placeholder="Enter your Phone"
            />
    
        <TextInput
                label={'Emergency Contact*'}
                mandatory={true}
                variant="standard"
                name="emergency_contact"
                type="text"
                error={other_info_err.emergency_contact}
                value={other_info?.emergency_contact}
                handlechange={handlechange2}
                placeholder="Enter your Phone"
            />
    
    
    
    
        <h6 className='text-[12px] font-semibold mb-1 mt-1.5'>Gender* </h6>
    
        <Select 
                mandatory={true}
        className='w-full border bg-white border-slate-300 h-[30px] outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-transparent focus:ring-offset-0 focus:shadow-none border-l-4 border-l-slate-600'
        bordered={false}
        value={other_info?.gender}
        onChange={(e)=>{setother_info({...other_info,gender:{label:e,value:e}});setother_info_err({...other_info_err,gender:''})}}
        options={[{label:'Male',value:'Male'},{label:'Female',value:'Female'},{label:'Others',value:'Others'}]}/> 
        
        <ErrorComponent error={other_info_err?.gender} />
        
        <h6 className='text-[12px] font-semibold mb-1 mt-1.5'>Blood Group* </h6>
    
        <Select 
        className='w-full border bg-white border-slate-300 h-[30px] outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-transparent focus:ring-offset-0 focus:shadow-none border-l-4 border-l-slate-600'
        bordered={false}
        value={other_info?.blood_group}
        onChange={(e)=>{setother_info({...other_info,blood_group:{label:e,value:e}});setother_info_err({...other_info_err,blood_group:''})}}
        options={
            blood_group_Arr
        }/> 
    
    <ErrorComponent error={other_info_err?.blood_group} />
    
        <h6 className='text-[12px] font-semibold mb-1 mt-1.5'>Martial Status* </h6>
    
        <Select 
        className='w-full border bg-white border-slate-300 h-[30px] outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-transparent focus:ring-offset-0 focus:shadow-none border-l-4 border-l-slate-600'
        bordered={false}
        value={other_info?.martial_status}
        onChange={(e)=>{setother_info({...other_info,martial_status:{label:e,value:e}});setother_info_err({...other_info_err,martial_status:''})}}
        options={[{label:'Single',value:'Single'},{label:'Married',value:'Married'}]}/> 
    
    <ErrorComponent error={other_info_err?.martial_status} />
    
    
        <TextInput
                label={'Total Experience*'} 
                mandatory={true}
                variant="standard"
                name="total_experience"
                type="text"
                error={other_info_err.total_experience}
                value={other_info.total_experience}
                handlechange={handlechange2}
                placeholder="Enter your Phone"
            />
    
    
    
    <TextAreaInput1 
            label={'Present Address*'}
            mandatory={true} 
            variant="standard"
            name="present_address"
            type="number"
            error={other_info_err.present_address}
            value={other_info?.present_address}
            handlechange={handlechange2}
            placeholder=""
            />
    
    
    
        <TextAreaInput1 
            mandatory={true} 
            label={'Permanent Address*'} 
            variant="standard"
            name="permanent_address"
            type="number"
            error={other_info_err.permanent_address}
            value={other_info?.permanent_address}
            handlechange={handlechange2}
            placeholder=""
            />

<TextAreaInput1 
         mandatory={true} 
         label={'If you could have any superpower, what would it be and why?*'} 
         variant="standard"
         name="have_superpower"
         type="text"
         error={other_info_err.have_superpower}
         value={other_info?.have_superpower}
         handlechange={handlechange2}
         placeholder=""
         />

<TextAreaInput1 
         mandatory={true} 
         label={"What's a fun fact about you that most people at work might not know?*"} 
         variant="standard"
         name="fun_fact"
         type="text"
         error={other_info_err.fun_fact}
         value={other_info?.fun_fact}
         handlechange={handlechange2}
         placeholder=""
         />


         <TextAreaInput1 
         mandatory={true} 
         label={'If you could describe yourself using three words, what would they be?*'} 
         variant="standard"
         name="describe_yourself"
         type="text"
         error={other_info_err.describe_yourself}
         value={other_info?.describe_yourself}
         handlechange={handlechange2}
         placeholder=""
         />

         <TextAreaInput1 
         mandatory={true} 
         label={"What's your favorite hobby or pastime outside of work?*"} 
         variant="standard"
         name="favourite_hobby"
         type="text"
         error={other_info_err.favourite_hobby}
         value={other_info?.favourite_hobby}
         handlechange={handlechange2}
         placeholder=""
         />   
            </div>
   
   
   
         <h6 className='font-[700] mb-2  mt-4 border-t pt-4'>Family Information</h6>
         <h6 className='text-[11px] mb-10'>We need atleast 2 family members details even guardian is also fine!</h6>
          
          <div className='-mt-8 mb-4'>
          <ErrorComponent error={families_err} />
          </div>
         <div className='flex w-full  justify-content-end'>
            <h6 onClick={()=>setfamily({...family,visible:!family.visible})} className='w-[80px] cursor-pointer text-center bg-slate-700 p-2 text-[11px] text-white font-[700]'>View Form</h6>
         </div>
   
         {family?.visible &&
         <div>
   
            <TextInput label="Name"  name={'name'} error={family_err?.name} value={family?.name} handlechange={handlechange1} />
            <TextInput label="Mobile"  name={'mobile'} error={family_err?.mobile} value={family?.mobile} handlechange={handlechange1} />
            <TextInput label="Age"  name={'age'} error={family_err?.age} value={family?.age} handlechange={handlechange1} />
            <TextInput label="Education"  name={'education'} error={family_err?.education} value={family?.education} handlechange={handlechange1}  />
            <h6 className='text-[12px] font-semibold mb-1 mt-1.5'>Relationship* </h6>
   
            <Select 
               className='w-full border bg-white border-slate-300 h-[30px] outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-transparent focus:ring-offset-0 focus:shadow-none'
               bordered={false}
               onChange={(e)=>{setfamily({...family,relationship:e});setfamily_err({...family_err,relationship_err:''})}}
               options={relationship_Arr}/> 
            <TextInput label="Occupation" name={'occupation'} error={family_err?.occupation} value={family?.occupation} handlechange={handlechange1}   />
   
            <div className='flex items-center justify-end mt-2'>
                  <h6 onClick={addFamily} className='bg-slate-300 text-[13px] w-[70px] text-center font-[700] cursor-pointer text-black p-1'>Save</h6>
               </div>  
   
         </div>}  
   
         <div className='overflow-x-scroll mt-5 no-scrollbar'>
   
            <div className='flex w-fit border-l border-t border-r border-b bg-gray-300'>
               <h6 className='text-[11px]  border-r p-1.5 font-[600] min-w-[50px] bg-gray-300 max-w-[50px]'>Sl No</h6>
               <h6 className='text-[11px]  border-r p-1.5 font-[600] min-w-[100px] bg-gray-300 max-w-[100px]'>Name</h6>
               <h6 className='text-[11px]  border-r p-1.5 font-[600] min-w-[120px] bg-gray-300 max-w-[512px]'>Relationship</h6>
               <h6 className='text-[11px]  border-r p-1.5 font-[600] min-w-[100px] bg-gray-300  max-w-[100x]'>Education</h6>
               <h6 className='text-[11px]  border-r p-1.5 font-[600] min-w-[100px] bg-gray-300 max-w-[100px]'>Occupation</h6>
               <h6 className='text-[11px]  border-r  p-1.5 font-[600] min-w-[50px] bg-gray-300 max-w-[50px]'>Age</h6>
               <h6 className='text-[11px]  border-r  p-1.5 font-[600] min-w-[50px] bg-gray-300 max-w-[50px]'>Action</h6>
            </div>
         
            {families?.map((f,i)=>(
            <div className='flex border-l w-fit border-r border-b'>
               <h6 className='text-[11px]  p-1.5 bg-white border-r font-[600] min-w-[50px] bg-white max-w-[50px]'>{i+1}</h6>
               <h6 className='text-[11px]  p-1.5 bg-white border-r font-[600] min-w-[100px] max-w-[100px]'>{f?.name}</h6>
               <h6 className='text-[11px]  p-1.5 bg-white border-r font-[600] min-w-[120px] max-w-[512px]'>{f?.relationship}</h6>
               <h6 className='text-[11px]  p-1.5 bg-white border-r border-b font-[600] min-w-[100px] max-w-[100x]'>{f?.education}</h6>
               <h6 className='text-[11px]  p-1.5 bg-white border-r border-b font-[600] min-w-[100px] max-w-[100px]'>{f?.occupation}</h6>
               <h6 className='text-[11px]  p-1.5 bg-white border-r border-b font-[600] min-w-[50px] max-w-[50px]'>{f?.age}</h6>
               <h6 className='text-[11px] flex items-center justify-center bg-white border-b font-[600] min-w-[50px] max-w-[50px]'>
                  <AiOutlineEdit onClick={()=>{setfamily({...f,visible:true});}} size={15} />
               </h6>
            </div>))} 
   
         </div>  
   
         <h6 className='font-[700] mb-2  mt-4 border-t pt-4'>Language Information</h6>
         <h6 className='text-[11px] mb-10'>Add the languages info which is weel know for you</h6>
   
         <div className='flex w-full -mt-6 justify-content-end'>
            <h6 onClick={()=>setlanguage({...language,visible:!language.visible})} className='w-[80px] cursor-pointer text-center bg-slate-700 p-2 text-[11px] text-white font-[700]'>View Form</h6>
         </div>    
         <div>
         
         {language?.visible &&
         <div>
         <h6 className='text-[12px] font-semibold mb-2 mt-2 '>Language* </h6>
   
         <Select 
            className='w-full border bg-white border-slate-300 h-[30px] outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-transparent focus:ring-offset-0 focus:shadow-none'
            bordered={false}
            value={language?.language}
            onChange={(e)=>{setlanguage({...language,language:e});setlanguage_err({...language_err,language:''})}}
            options={languages_Arr}
         /> 
   
         <ErrorComponent error={language_err?.language} />
   
   
         <div className='flex items-center mt-5'>
            <div className='flex items-center mr-4' onClick={()=>{setlanguage({...language,read:!language.read})}}>
               {language?.read ? <BiCheckboxSquare  size={20} className='text-slate-700'  /> : 
               <BiCheckbox size={20} color="#bbb" /> }
               <h6 className='text-[12px] font-[500]'>Read</h6> 
            </div> 
   
            <div className='flex items-center mr-4' onClick={()=>{setlanguage({...language,write:!language.write})}}>
               {language?.write ? <BiCheckboxSquare  size={20} className='text-slate-700'  /> :  
                           <BiCheckbox size={20} color="#bbb" /> }
               <h6 className='text-[12px] font-[500]'>Write</h6> 
            </div> 
   
            <div className='flex items-center mr-4' onClick={()=>{setlanguage({...language,speak:!language.speak})}}>
            {language?.speak ? <BiCheckboxSquare  size={20} className='text-slate-700'  /> :  
                           <BiCheckbox size={20} color="#bbb" /> }
               <h6 className='text-[12px] font-[500]'>Speak</h6> 
            </div> 
   
         </div>  
   
   
         <div className='flex items-center justify-end mt-2'>
            <h6 onClick={addLanguage} className='bg-slate-300 text-[13px] w-[70px] text-center font-[700] cursor-pointer  text-black p-1'>Save</h6>
         </div>  
         </div>} 
         
   
         <div className='overflow-x-scroll mt-5 no-scrollbar'>
   
      <div className='flex w-fit bg-gray-300'>
         <h6 className='text-[11px] p-1.5 border-r font-[600] min-w-[50px]  bg-gray-300 max-w-[50px]'>Sl No</h6>
         <h6 className='text-[11px] p-1.5 border-r font-[600] min-w-[100px]  bg-gray-300 max-w-[100px]'>Language</h6>
         <h6 className='text-[11px] p-1.5 border-r font-[600] min-w-[80px] max-w-[80px]'>Read</h6>
         <h6 className='text-[11px] p-1.5 border-r font-[600] min-w-[80px]  bg-gray-300 max-w-[80px]'>Write</h6>
         <h6 className='text-[11px] p-1.5 border-r font-[600] min-w-[80px]  bg-gray-300 max-w-[80px]'>Speak</h6>
         <h6 className='text-[11px] p-1.5 border-r font-[600] min-w-[50px]  bg-gray-300 max-w-[50px]'>Action</h6>
      </div>
   
      {languages?.map((f,i)=>(
      <div className='flex w-fit border-l border-r border-b '>
         <h6 className='text-[11px] bg-white border-r p-1.5 font-[600] min-w-[50px] max-w-[50px]'>{i+1}</h6>
         <h6 className='text-[11px] bg-white border-r p-1.5 font-[600] min-w-[100px] max-w-[100px]'>{f?.language}</h6>
         <h6 className='text-[11px] bg-white border-r p-1.5 font-[600] min-w-[80px] max-w-[80px]'>{f?.read ? <BiCheckboxSquare  size={20} className='text-slate-700'  /> : 
               <BiCheckbox size={20} color="#bbb" />  }</h6>
         <h6 className='text-[11px] bg-white border-r p-1.5 font-[600] min-w-[80px] max-w-[80px]'>{f?.write ? <BiCheckboxSquare  size={20} className='text-slate-700'  /> : 
               <BiCheckbox size={20} color="#bbb" />  }</h6>
         <h6 className='text-[11px] bg-white border-r border-b p-1.5 font-[600] min-w-[80px] max-w-[80px]'>{f?.speak ? <BiCheckboxSquare  size={20} className='text-slate-700'  /> : 
               <BiCheckbox size={20} color="#bbb" />  }</h6>
         <h6 className='text-[11px] flex items-center justify-center bg-white border-b font-[600] min-w-[48px] max-w-[48px]'>
            <AiOutlineEdit onClick={()=>{setlanguage({...f,language:{label:f?.language,value:f?.language},visible:true})}} size={15} />
         </h6>
      </div>))} 
   
        </div>  
        </div>
        </div>}

        {step === 3 &&
        <div className='md:w-72'>
            <div className='relative'>
            <h6 className='text-[12px] font-semibold mb-1 mt-1.5'>Addhar Card *</h6>
            {docs?.addhar !== '' && <h6 onClick={()=> window.open(`${process.env.REACT_APP_BACKEND_IMAGE_URL}${docs?.addhar}`, "_blank")} className='text-[10px] hover:underline cursor-pointer font-[700] absolute right-0 top-1'>View File</h6>}
            {docs?.addhar === '' ? 
            <form onClick={()=>document.querySelector('.input-field').click()} className={`p-4 flex flex-col items-center justify-center cursor-pointer border  border-l-4 border-l-slate-600`}>
                    <input type='file' name={'addhar'} onChange={({target:{files}})=>{
                        files[0] && handlefileupload(files[0],'addhar','docs');setdocs_err({...docs_err,addhar:''})
                    }} accept="*" className='input-field' hidden />
            </form>  
            :
            <div className='p-2 border relative flex flex-col  cursor-pointer   border-l-4 border-l-slate-600'>
                    <Tooltip title='Delete'><IoClose className='absolute top-1.5 right-2' onClick={()=>setdocs({...docs,addhar:''})}/></Tooltip>
                    <h6 className='text-[12px] truncate ml-0'>{docs?.addhar?.name !== undefined ? docs?.addhar?.name : docs?.addhar?.split('/')[docs?.addhar?.split('/')?.length - 1]}</h6>
            </div>
            }       

            <ErrorComponent error={docs_err?.addhar} />
            </div>

            <div className='relative'>
            <h6 className='text-[12px] font-semibold mb-1 mt-2'>Pan Card *</h6>
            {docs?.pan_card !== '' && <h6 onClick={()=> window.open(`${process.env.REACT_APP_BACKEND_IMAGE_URL}${docs?.pan_card}`, "_blank")} className='text-[10px] hover:underline cursor-pointer font-[700] absolute right-0 top-1'>View File</h6>}
            {docs?.pan_card === '' ? 
            <form onClick={()=>document.querySelector('.input-field1').click()} className={`p-4 flex flex-col items-center justify-center cursor-pointer border   border-l-4 border-l-slate-600`}>
                    <input type='file' name={'pan_card'} onChange={({target:{files}})=>{
                        files[0] && handlefileupload(files[0],'pan_card','docs');setdocs_err({...docs_err,pan_card:''})
                    }} accept="*" className='input-field1' hidden />
            </form>  
            :
            <div className='p-2 border relative flex flex-col  cursor-pointer   border-l-4 border-l-slate-600'>
                    <Tooltip title='Delete'><IoClose className='absolute top-1.5 right-2' onClick={()=>setdocs({...docs,pan_card:''})}/></Tooltip>
                    <h6 className='text-[12px] truncate w-48 ml-0'>{docs?.pan_card?.name !== undefined ? docs?.pan_card?.name : docs?.pan_card?.split('/')[docs?.pan_card?.split('/')?.length - 1]}</h6>
            </div>
            }    

            <ErrorComponent error={docs_err?.pan_card} />
            </div>

            <div className='relative'>
            <h6 className='text-[12px] font-semibold mb-1 mt-2'>10 th Marks Card</h6>
            {docs?.tenth_marks_card !== '' &&  <h6 onClick={()=> window.open(`${process.env.REACT_APP_BACKEND_IMAGE_URL}${docs?.tenth_marks_card}`, "_blank")} className='text-[10px] hover:underline cursor-pointer font-[700] absolute right-0 top-1'>View File</h6>}
            {docs?.tenth_marks_card === '' ? 
            <form onClick={()=>document.querySelector('.input-field2').click()} className={`p-4 flex flex-col items-center justify-center cursor-pointer border `}>
                    <input type='file' name={'tenth_marks_card'} onChange={({target:{files}})=>{
                        files[0] && handlefileupload(files[0],'tenth_marks_card','docs')
                    }} accept="*" className='input-field2' hidden />
            </form>  
            :
            <div className='p-2 border relative flex flex-col  cursor-pointer'>
                    <Tooltip title='Delete'><IoClose className='absolute top-1.5 right-2' onClick={()=>setdocs({...docs,tenth_marks_card:''})}/></Tooltip>
                    <h6 className='text-[12px] truncate w-48 ml-0'>{docs?.tenth_marks_card?.name !== undefined ? docs?.tenth_marks_card?.name : docs?.tenth_marks_card?.split('/')[docs?.tenth_marks_card?.split('/')?.length - 1]}</h6>
            </div>
            }   
            </div>
            
            <div className='relative'>

            <h6 className='text-[12px] font-semibold mb-1 mt-2'>PUC Marks Card </h6>
            {docs?.puc_marks_card !== '' &&   <h6 onClick={()=> window.open(`${process.env.REACT_APP_BACKEND_IMAGE_URL}${docs?.puc_marks_card}`, "_blank")} className='text-[10px] hover:underline cursor-pointer font-[700] absolute right-0 top-1'>View File</h6>}

            {docs?.puc_marks_card === '' ? 
            <form onClick={()=>document.querySelector('.input-field3').click()} className={`p-4 flex flex-col items-center justify-center cursor-pointer border `}>
                <input type='file' name={'puc_marks_card'} onChange={({target:{files}})=>{
                        files[0] && handlefileupload(files[0],'puc_marks_card','docs')
                    }} accept="*" className='input-field3' hidden />
            </form>  
            :
            <div className='p-2 border relative flex flex-col  cursor-pointer'>
                <Tooltip title='Delete'><IoClose className='absolute top-1.5 right-2' onClick={()=>setdocs({...docs,puc_marks_card:''})}/></Tooltip>
                <h6 className='text-[12px] truncate w-48 ml-0'>{docs?.puc_marks_card?.name !== undefined ? docs?.puc_marks_card?.name : docs?.puc_marks_card?.split('/')[docs?.puc_marks_card?.split('/')?.length - 1]}</h6>
            </div>
            }
            </div>
            <div className='relative'>
            <h6 className='text-[12px] font-semibold mb-1 mt-2'>Degree Marks Card </h6>
            {docs?.degree_marks_card !== '' &&  <h6 onClick={()=> window.open(`${process.env.REACT_APP_BACKEND_IMAGE_URL}${docs?.degree_marks_card}`, "_blank")} className='text-[10px] hover:underline cursor-pointer font-[700] absolute right-0 top-1'>View File</h6>}

            {docs?.degree_marks_card === '' ? 
            <form onClick={()=>document.querySelector('.input-field4').click()} className={`p-4 flex flex-col items-center justify-center cursor-pointer border `}>
                <input type='file' name={'degree_marks_card'} onChange={({target:{files}})=>{
                        files[0] && handlefileupload(files[0],'degree_marks_card','docs')
                    
                    }} accept="*" className='input-field4' hidden />
            </form>  
            :
            <div className='p-2 border relative flex flex-col  cursor-pointer'>
                <Tooltip title='Delete'><IoClose className='absolute top-1.5 right-2' onClick={()=>setdocs({...docs,degree_marks_card:''})}/></Tooltip>
                <h6 className='text-[12px] truncate w-48 ml-0'>{docs?.degree_marks_card?.name !== undefined ? docs?.degree_marks_card?.name : docs?.degree_marks_card?.split('/')[docs?.degree_marks_card?.split('/')?.length - 1]}</h6>
            </div>
            }
            </div>

            <div className='relative'>
            <h6 className='text-[12px] font-semibold mb-1 mt-2'>Master Degree Marks Card </h6>
            {docs?.master_marks_card !== '' &&  <h6 onClick={()=> window.open(`${process.env.REACT_APP_BACKEND_IMAGE_URL}${docs?.master_marks_card}`, "_blank")} className='text-[10px] hover:underline cursor-pointer font-[700] absolute right-0 top-1'>View File</h6>}
            {docs?.master_marks_card === '' ? 
            <form onClick={()=>document.querySelector('.input-field5').click()} className={`p-4 flex flex-col items-center justify-center cursor-pointer border `}>
                <input type='file' name={'master_marks_card'} onChange={({target:{files}})=>{
                        files[0] && handlefileupload(files[0],'master_marks_card','docs');setdocs_err({...docs_err,master_marks_card:''})
                    }} accept="*" className='input-field5' hidden />
            </form>  
            :
            <div className='p-2 border relative flex flex-col  cursor-pointer'>
                <Tooltip title='Delete'><IoClose className='absolute top-1.5 right-2' onClick={()=>setdocs({...docs,master_marks_card:''})}/></Tooltip>
                <h6 className='text-[12px] truncate w-48 ml-0'>{docs?.master_marks_card?.name !== undefined ? docs?.master_marks_card?.name : docs?.master_marks_card?.split('/')[docs?.master_marks_card?.split('/')?.length - 1]}</h6>
            </div>
            }
            </div>

            <div className='relative'>
            <h6 className='text-[12px] font-semibold mb-1 mt-2'>Experience/Relieving Letter </h6>
            {docs?.experience_relieving_letter !== '' &&  <h6 onClick={()=> window.open(`${process.env.REACT_APP_BACKEND_IMAGE_URL}${docs?.experience_relieving_letter}`, "_blank")} className='text-[10px] hover:underline cursor-pointer font-[700] absolute right-0 top-1'>View File</h6>}
            {docs?.experience_relieving_letter === '' ? 
            <form onClick={()=>document.querySelector('.input-field7').click()} className={`p-4 flex flex-col items-center justify-center cursor-pointer border `}>
                <input type='file' name={'experience_relieving_letter'} onChange={({target:{files}})=>{
                        files[0] && handlefileupload(files[0],'experience_relieving_letter','docs');setdocs_err({...docs_err,experience_relieving_letter:''})
                    }} accept="*" className='input-field7' hidden />
            </form>  
            :
            <div className='p-2 border relative flex flex-col  cursor-pointer'>
                <Tooltip title='Delete'><IoClose className='absolute top-1.5 right-2' onClick={()=>setdocs({...docs,experience_relieving_letter:''})}/></Tooltip>
                <h6 className='text-[12px] truncate w-48 ml-0'>{docs?.experience_relieving_letter?.name !== undefined ? docs?.experience_relieving_letter?.name : docs?.experience_relieving_letter?.split('/')[docs?.experience_relieving_letter?.split('/')?.length - 1]}</h6>
            </div>
            }
            </div>

            <div className='relative'>
            <h6 className='text-[12px] font-semibold mb-1 mt-2'>Last Employer Payslips </h6>
            {docs?.last_employer_payslips !== '' &&  <h6 onClick={()=> window.open(`${process.env.REACT_APP_BACKEND_IMAGE_URL}${docs?.last_employer_payslips}`, "_blank")} className='text-[10px] hover:underline cursor-pointer font-[700] absolute right-0 top-1'>View File</h6>}
            {docs?.last_employer_payslips === '' ? 
            <form onClick={()=>document.querySelector('.input-field8').click()} className={`p-4 flex flex-col items-center justify-center cursor-pointer border `}>
                <input type='file' name={'last_employer_payslips'} onChange={({target:{files}})=>{
                        files[0] && handlefileupload(files[0],'last_employer_payslips','docs');setdocs_err({...docs_err,last_employer_payslips:''})
                    }} accept="*" className='input-field8' hidden />
            </form>  
            :
            <div className='p-2 border relative flex flex-col  cursor-pointer'>
                <Tooltip title='Delete'><IoClose className='absolute top-1.5 right-2' onClick={()=>setdocs({...docs,last_employer_payslips:''})}/></Tooltip>
                <h6 className='text-[12px] truncate w-48 ml-0'>{docs?.last_employer_payslips?.name !== undefined ? docs?.last_employer_payslips?.name : docs?.last_employer_payslips?.split('/')[docs?.last_employer_payslips?.split('/')?.length - 1]}</h6>
            </div>
            }
            </div>

            <div className='relative'>
            <h6 className='text-[12px] font-semibold mb-1 mt-2'>Signature *</h6>
            {docs?.signature !== '' &&  <h6 onClick={()=> window.open(`${process.env.REACT_APP_BACKEND_IMAGE_URL}${docs?.signature}`, "_blank")} className='text-[10px] hover:underline cursor-pointer font-[700] absolute right-0 top-1'>View File</h6>}
            <h6 className='font-[400] leading-[15px] mb-2 text-[10px]'>(Please upload the png format which is cropped correctly because the following signature is attached for the upcoming documents provided by company based on your approval)</h6> 

            {docs?.signature === '' ? 
            <form onClick={()=>document.querySelector('.input-field6').click()} className={`p-4 flex flex-col items-center justify-center cursor-pointer border   border-l-4 border-l-slate-600`}>
                <input type='file'  name={'signature'} onChange={({target:{files}})=>{
                        files[0] && handlefileupload(files[0],'signature','docs');setdocs_err({...docs_err,signature:''})
                    }} accept="png" className='input-field6' hidden />
            </form>  
            :
            <div className='p-2 border relative flex flex-col  cursor-pointer   border-l-4 border-l-slate-600'>
                <Tooltip title='Delete'><IoClose className='absolute top-1.5 right-2' onClick={()=>setdocs({...docs,signature:''})}/></Tooltip>
                <h6 className='text-[12px] truncate w-48 ml-0'>{docs?.signature?.name !== undefined ? docs?.signature?.name : docs?.signature?.split('/')[docs?.signature?.split('/')?.length - 1]}</h6>
            </div>
            }

            <ErrorComponent error={docs_err?.signature} />
            </div>

            


        </div>}

        {step === 4 &&
        <div className='md:w-72'>
      <TextInput
         label={'Bank Name*'} 
         mandatory={true}
         variant="standard"
         name="bank_name"
         type="text"
         error={bank_err?.bank_name}
         value={bank?.bank_name}
         handlechange={handlechange3}
         placeholder="Enter your Phone"
      />

      <TextInput
         label={'Account No*'} 
         mandatory={true}
         variant="standard"
         name="account_no"
         type="text"
         value={bank?.account_no}
         error={bank_err?.account_no}
         handlechange={handlechange3}
         placeholder="Enter your Phone"
      />

      <TextInput
         label={'Ifsc Code *'} 
         mandatory={true}
         variant="standard"
         name="ifsc_code"
         type="text"
         value={bank?.ifsc_code}
         error={bank_err?.ifsc_code}
         handlechange={handlechange3}
         placeholder="Enter your Phone"
      />

      <TextAreaInput1
         label={'Bank Address*'} 
         variant="standard"
         name="bank_address"
         type="text"
         value={bank?.bank_address}
         error={bank_err?.bank_address}
         handlechange={handlechange3}
         placeholder=""
            />

<div className='relative'>
<h6 className='text-[12px] font-semibold mb-1 mt-1.5'>Bank PassBook * </h6>
{(bank?.bank_photo !== null && bank?.bank_photo !== '') &&  <h6 onClick={()=> window.open(`${process.env.REACT_APP_BACKEND_IMAGE_URL}${bank?.bank_photo}`, "_blank")} className='text-[10px] hover:underline cursor-pointer font-[700] absolute right-0 top-1'>View File</h6>}

   {(bank?.bank_photo === null || bank?.bank_photo === '') ? 
      <form onClick={()=>document.querySelector('.input-field').click()} className={`p-4 flex flex-col items-center justify-center cursor-pointer border  border-l-4 border-l-slate-600`}>
            <input type='file' name={'bank_photo'} onChange={({target:{files}})=>{
               files[0] && handlefileupload(files[0],'bank_photo','bank');setbank_err({...bank_err,bank_photo:null})
            }} accept="*" className='input-field' hidden />
      </form>  
      :
      <div className='p-2 border relative flex flex-col  cursor-pointer   border-l-4 border-l-slate-600'>
            <Tooltip title='Delete'><IoClose className='absolute top-1.5 right-2' onClick={()=>setbank({...bank,bank_photo:null})}/></Tooltip>
            <h6 className='text-[12px] truncate w-48 ml-0'>{bank?.bank_photo?.split('/')[bank?.bank_photo?.split('/')?.length-1]}</h6>
      </div>
      }       

      <ErrorComponent error={bank_err?.bank_photo} />


      </div>
        </div>}

        {step === 5 &&
          <div className='md:w-[30%]  pt-5'>
          <h6 className='text-[12px] font-semibold mb-1 mt-1.5'>Form 11 Raised * </h6>

          {docs?.form11revised === '' ? 
          <div className='relative'>
               <div className='flex absolute right-0 -top-[20px]'>
                      <a href="https://fidecrmfiles.s3.amazonaws.com/Form11Revised.pdf" target='_blank' download><h6 className='text-[10px] font-[600] text-sky-700 underline'>Download Form</h6></a>
                  </div> 
          <form onClick={()=>document.querySelector('.input-field1').click()} className={`p-4 flex flex-col items-center justify-center cursor-pointer  border `}>
            
              <input type='file' name={'form11revised'} onChange={({target:{files}})=>{
              files[0] && handlefileupload(files[0],'form11revised','docs');setdocs_err({...docs_err,form11revised:''})
              }} accept="*" className='input-field1' hidden />
          </form>  
          </div>
          :
          <div className='p-2 border relative flex flex-col  cursor-pointer  '>
                 
                  <div className='flex absolute right-0 -top-[20px]'>
                    {docs?.form11revised !== '' && <a href={`${process.env.REACT_APP_BACKEND_IMAGE_URL}${docs?.form11revised}`} target='_blank' download><h6 className='text-[10px] font-[600] text-sky-700 underline mr-2'>Preview Form</h6></a>}
                      <a href="https://fidecrmfiles.s3.amazonaws.com/Form11Revised.pdf" target='_blank' download><h6 className='text-[10px] font-[600] text-sky-700 underline'>Download Form</h6></a>
                  </div>    
                  <Tooltip title='Delete'><IoClose className='absolute top-1.5 right-2' onClick={()=>setdocs({...docs,form11revised:''})}/></Tooltip>
                  <h6 className='text-[12px] truncate w-48 ml-0'>{docs?.form11revised?.name !== undefined ? docs?.form11revised?.name : docs?.form11revised?.split('/')[docs?.form11revised?.split('/')?.length - 1]}</h6>
          </div>
          }    

          <ErrorComponent error={docs_err?.form11revised} />

          <h6 className='text-[12px] font-semibold mb-1 mt-1.5'>ESI * </h6>

          {docs?.esi === '' ? 
          <div className='relative'>
             <div className='flex absolute right-0 -top-[20px]'>
                      <a href="https://fidecrmfiles.s3.amazonaws.com/esic_form.pdf" target='_blank' download><h6 className='text-[10px] font-[600] text-sky-700 underline'>Download Form</h6></a>
                  </div>
          <form onClick={()=>document.querySelector('.input-field1').click()} className={`p-4 flex flex-col items-center justify-center cursor-pointer border `}>
                
                  <input type='file' name={'esi'} onChange={({target:{files}})=>{
                  files[0] && handlefileupload(files[0],'esi','docs');setdocs_err({...docs_err,esi:''})
                  }} accept="*" className='input-field1' hidden />
          </form>  
          </div>
          :
          <div className='p-2 border relative flex flex-col  cursor-pointer '>
                  <div className='flex absolute right-0 -top-[20px]'>
                    {docs?.esi !== '' && <a href={`${process.env.REACT_APP_BACKEND_IMAGE_URL}${docs?.esi}`} target='_blank' download><h6 className='text-[10px] font-[600] text-sky-700 underline mr-2'>Preview Form</h6></a>}
                      <a href="https://fidecrmfiles.s3.amazonaws.com/esic_form.pdf" target='_blank' download><h6 className='text-[10px] font-[600] text-sky-700 underline'>Download Form</h6></a>
                  </div> 
                  <Tooltip title='Delete'><IoClose className='absolute top-1.5 right-2' onClick={()=>setdocs({...docs,esi:''})}/></Tooltip>
                  <h6 className='text-[12px] truncate w-48 ml-0'>{docs?.esi?.name !== undefined ? docs?.esi?.name : docs?.esi?.split('/')[docs?.esi?.split('/')?.length - 1]}</h6>
          </div>
          }    

          <ErrorComponent error={docs_err?.esi} />

          <h6 className='text-[12px] font-semibold mb-1 mt-1.5'>Form F * </h6>

          {docs?.formf === '' ? 
          <div className='relative'>
               <div className='flex absolute right-0 -top-[20px]'>
                      <a href="https://fidecrmfiles.s3.amazonaws.com/New+Form-F+(for+Gratuity).pdf" target='_blank' download><h6 className='text-[10px] font-[600] text-sky-700 underline'>Download Form</h6></a>
                  </div> 
          <form onClick={()=>document.querySelector('.input-field1').click()} className={`p-4 flex flex-col items-center justify-center cursor-pointer border `}>
               
                  <input type='file' name={'formf'} onChange={({target:{files}})=>{
                  files[0] && handlefileupload(files[0],'formf','docs');setdocs_err({...docs_err,formf:''})
                  }} accept="*" className='input-field1' hidden />
          </form>  
          </div>
          :
          <div className='p-2 border relative flex flex-col  cursor-pointer '>
               <div className='flex absolute right-0 -top-[20px]'>
                    {docs?.formf !== '' && <a href={`${process.env.REACT_APP_BACKEND_IMAGE_URL}${docs?.formf}`} target='_blank' download><h6 className='text-[10px] font-[600] text-sky-700 underline mr-2'>Preview Form</h6></a>}
                      <a href="https://fidecrmfiles.s3.amazonaws.com/New+Form-F+(for+Gratuity).pdf" target='_blank' download><h6 className='text-[10px] font-[600] text-sky-700 underline'>Download Form</h6></a>
                  </div> 
                  <Tooltip title='Delete'><IoClose className='absolute top-1.5 right-2' onClick={()=>setdocs({...docs,formf:''})}/></Tooltip>
                  <h6 className='text-[12px] truncate w-48 ml-0'>{docs?.formf?.name !== undefined ? docs?.formf?.name : docs?.formf?.split('/')[docs?.formf?.split('/')?.length - 1]}</h6>
          </div>
          }    

          <ErrorComponent error={docs_err?.formf} />

          <h6 className='text-[12px] font-semibold mb-1 mt-1.5'>NDA * </h6>

          {docs?.nda === '' ? 
          <div className='relative'>
              <div className='flex absolute right-0 -top-[20px]'>
                     <a href="https://fidecrmfiles.s3.amazonaws.com/NDA.docx" target='_blank' download><h6 className='text-[10px] font-[600] text-sky-700 underline'>Download Form</h6></a>
                  </div> 
          <form onClick={()=>document.querySelector('.input-field1').click()} className={`p-4 flex flex-col items-center justify-center cursor-pointer border `}>
                
                  <input type='file' name={'nda'} onChange={({target:{files}})=>{
                  files[0] && handlefileupload(files[0],'nda','docs');setdocs_err({...docs_err,nda:''})
                  }} accept="*" className='input-field1' hidden />
          </form>  
          </div>
          :
          <div className='p-2 border relative flex flex-col  cursor-pointer '>
               <div className='flex absolute right-0 -top-[20px]'>
                    {docs?.nda !== '' && <a href={`${process.env.REACT_APP_BACKEND_IMAGE_URL}${docs?.nda}`} target='_blank' download><h6 className='text-[10px] font-[600] text-sky-700 underline mr-2'>Preview Form</h6></a>}
                      <a href="https://fidecrmfiles.s3.amazonaws.com/NDA.docx" target='_blank' download><h6 className='text-[10px] font-[600] text-sky-700 underline'>Download Form</h6></a>
                  </div> 
                  <Tooltip title='Delete'><IoClose className='absolute top-1.5 right-2' onClick={()=>setdocs({...docs,nda:''})}/></Tooltip>
                  <h6 className='text-[12px] truncate w-48 ml-0'>{docs?.nda?.name !== undefined ? docs?.nda?.name : docs?.nda?.split('/')[docs?.nda?.split('/')?.length - 1]}</h6>
          </div>
          }    

          <ErrorComponent error={docs_err?.nda} />

          <h6 className='text-[12px] font-semibold mb-1 mt-1.5'>Nominee * </h6>

          {docs?.nominee === '' ? 
          <div className='relative'>
            <div className='flex absolute right-0 -top-[20px]'>
                     <a href="https://fidecrmfiles.s3.amazonaws.com/form2_nomination_declaration_form.pdf" target='_blank' download><h6 className='text-[10px] font-[600] text-sky-700 underline'>Download Form</h6></a>
          </div> 
          <form onClick={()=>document.querySelector('.input-field1').click()} className={`p-4 flex flex-col items-center justify-center cursor-pointer border `}>
                  
                  <input type='file' name={'nominee'} onChange={({target:{files}})=>{
                  files[0] && handlefileupload(files[0],'nominee','docs');setdocs_err({...docs_err,nominee:''})
                  }} accept="*" className='input-field1' hidden />
          </form>  
          </div>
          :
          <div className='p-2 border relative flex flex-col  cursor-pointer '>
               <div className='flex absolute right-0 -top-[20px]'>
                    {docs?.nominee !== '' && <a href={`${process.env.REACT_APP_BACKEND_IMAGE_URL}${docs?.nominee}`} target='_blank' download><h6 className='text-[10px] font-[600] text-sky-700 underline mr-2'>Preview Form</h6></a>}
                      <a href="https://fidecrmfiles.s3.amazonaws.com/form2_nomination_declaration_form.pdf" target='_blank' download><h6 className='text-[10px] font-[600] text-sky-700 underline'>Download Form</h6></a>
                  </div> 
                  <Tooltip title='Delete'><IoClose className='absolute top-1.5 right-2' onClick={()=>setdocs({...docs,nominee:''})}/></Tooltip>
                  <h6 className='text-[12px] truncate w-48 ml-0'>{docs?.nominee?.name !== undefined ? docs?.nominee?.name : docs?.nominee?.split('/')[docs?.nominee?.split('/')?.length - 1]}</h6>
          </div>
          }    

          <ErrorComponent error={docs_err?.nominee} />


      </div>}   

        {step === 6 &&
        <div>
        <div className='flex items-start' onClick={()=>{setdocs_err({...docs_err,terms_and_signature:''});setdocs({...docs,terms_and_signature:!docs.terms_and_signature})}}>  
         {docs?.terms_and_signature ? 
         <BiCheckboxSquare size={25} className='-mt-1 text-slate-700' />
          :
         <BiCheckbox size={25} className='-mt-1 text-[#bbb]' />}
         <h6 className='text-[12px] pl-2'>I agree the terms and condition mentioned by the fidelitus company 
         </h6>
      </div>

      <div className='flex items-start mt-2' onClick={()=>{setdocs_err({...docs_err,signature_policy:''});setdocs({...docs,signature_policy:!docs.signature_policy})}}>  
        {docs?.signature_policy ? 
         <BiCheckboxSquare  className='-mt-1 text-[40px] md:text-[25px]  text-slate-700' />
          :
         <BiCheckbox  className='-mt-1 text-[40px] md:text-[25px] text-[#bbb]' />}
         <h6 className='text-[12px] pl-2'>I allow the fidelitus company to use my signature on the necessary documents based on my approvals.
         </h6>
      </div>

      {/* <div className='flex items-center mb-2'>
         <div className='flex items-center mt-0 mr-2'>
         {data?.sent_for_review ? <BiCheckboxSquare size={25} className='text-slate-600' onClick={()=>setdata({...data,sent_for_review:!data.sent_for_review})} /> : <BiCheckbox size={25} className='text-[#bbb]' onClick={()=>setdata({...data,sent_for_review:!data.sent_for_review})} /> }
         <h6 className="text-[12px] ml-2">Send For Review</h6> 
         </div> 
      </div> */}

        <div className='flex mt-2'>
            <div className='flex items-center mb-2'>
                <div className='flex items-center mt-0 mr-2'>
                {data?.approved ? <BiCheckboxSquare size={25} className='text-slate-600' onClick={()=>setdata({...data,approved:!data.approved})} /> : <BiCheckbox size={25} className='text-[#bbb]' onClick={()=>setdata({...data,approved:!data.approved})} /> }
                <h6 className="text-[12px] ml-2">Approve</h6> 
                </div> 
            </div>

            <div className='flex items-center mb-2'>
                <div className='flex items-center mt-0 mr-2'>
                {data?.rejected ? <BiCheckboxSquare size={25} className='text-slate-600' onClick={()=>setdata({...data,rejected:!data.rejected})} /> : <BiCheckbox size={25} className='text-[#bbb]' onClick={()=>setdata({...data,rejected:!data.rejected})} /> }
                <h6 className="text-[12px] ml-2">Require Changes</h6> 
                </div> 
            </div>

            <div className='flex items-center mb-2'>
                <div className='flex items-center mt-0 mr-2'>
                {data?.notification ? <BiCheckboxSquare size={25} className='text-slate-600' onClick={()=>setdata({...data,notification:!data.notification})} /> : <BiCheckbox size={25} className='text-[#bbb]' onClick={()=>setdata({...data,notification:!data.notification})} /> }
                <h6 className="text-[12px] ml-2">Don't send Notification</h6> 
                </div> 
            </div>
       </div>
      
      <div>
         <h6 className='text-[12px] bg-slate-100 p-1 leading-[16px]'><span className='font-[800]'>Note :</span><br></br>
         
         1. Before we use your signature we will send an confirmation request with the documents were the signature is being used once you confirm then the signature will be used.
         <br></br>
         2. If you have submitted all the fields and ready to send for further purpose click on the send for review button. </h6>
      </div>  

      

        </div>}    

        <div className='flex py-5'>
            <ButtonOutlinedAutoWidth btnName="Cancel" onClick={prevStep} />
            <h6 className='w-2'></h6>
            <ButtonFilledAutoWidth btnName="Save" onClick={nextStep} />
        </div>
   
            
         </div>  
   
         </div>

       </div>  
    </div>
  )
}

export default OnboardDetail