import React,{useEffect, useState} from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import GoBack from '../../../components/back/GoBack';
import { TextAreaInput1, TextInput, TextInput1 } from '../../../components/input';
import { ButtonFilledAutoWidth } from '../../../components/button';
import { toast } from 'react-hot-toast'
import { GetDepartmentService } from '../../../services/DepartmentServices';
import { Select } from 'antd';
import { useSelector } from 'react-redux';
import ReceptionMenu from '../ReceptionMenu';
import { CreateStaffMomentService, UpdateStaffMomentService } from '../../../services/receptionservices/StaffMomentServices';
import { GetSearchService } from '../../../services/AuthServices';
import {BiCheckbox,BiCheckboxSquare} from 'react-icons/bi'

function StaffMomentCE() {

  const user = useSelector(state=>state.Auth)
  const {state,pathname} = useLocation();
  const navigate = useNavigate();

  
  const path = pathname.split('/')[1]
  const path_type = pathname.split('/')[pathname.split('/').length - 1]



  const [data,setdata] = useState({employee:'',purpose:'',work_for:'',status:'Pending',date:'',in_time:'',out_time:'',remarks:'',approval_required:false,approval_user:''});  
  const [error,seterror] = useState({employee:'',purpose:'',work_for:'',status:'',date:'',in_time:'',out_time:'',remarks:'',approval_required:'',approval_user:''}); 

  const statusOption = [
      {label:'Pending',value:'Pending'},
      {label:'Completed',value:'Completed'},
  ]

  const [userArr,setuserArr] = useState([])

  function handlechange(e){
    setdata({...data,[e.target.name] : e.target.value})
    seterror({...error,[e.target.name]:''})
  }

  async function handleselect(val,type){
    if(type === 'user'){
      setdata({...data,employee:userArr.find((d)=>d.value === val)})
    }
    if(type === 'approval_user'){
      setdata({...data,approval_user:userArr.find((d)=>d.value === val)})
    }
  }

  useEffect(()=>{
    if(state?._id !== undefined){

      let d = {...state}

      console.log("d here",d)
        setdata({
            ...data,
            ...d,
            in_time:d?.in_time?.slice(0,16),
            out_time:d?.out_time?.slice(0,16),
            employee:{label:state?.employee?.name,value:state?.employee?._id}
        })
    }
  },[state])

  async function getuserlist(v){
    const response = await GetSearchService(v,1)
    let arr = []
    let d = response?.data?.datas
    d?.forEach((d1)=>{
      arr?.push({label:`${d1?.name} - [${d1?.employee_id}]`,value:d1?._id})
    })
    setuserArr(arr)
  }



  console.log("data",data)

  async function submitform(){
    if(!data.employee){
        seterror({...error,employee:'This field is required *'})
    }else if(!data.purpose){
      seterror({...error,purpose:'This field is required *'})
    }else if(!data.work_for){
      seterror({...error,work_for:'This field is required *'})
    }else if(!data.date){
      seterror({...error,date:'This field is required *'})
    }else if(!data.out_time){
      seterror({...error,out_time:'This field is required *'})
    }else if(data.approval_required && !data?.approval_user){
      seterror({...error,approval_user:'This field is required *'})
    }else{
        let sendData = {...data}

        if(state?._id === undefined || state?._id === null){
            sendData['employee'] = data?.employee.value
            if(data?.approval_required && data?.approval_user !== ''){
              sendData['approval_user'] = data?.approval_user.value
            }else{
              sendData['approval_user_status'] = "Approved"
              delete sendData.approval_user
            }

            const response = await CreateStaffMomentService(sendData)
            if (response.status === 201) {
              resetform()
              toast.success('Staff Moment Created Successfully')
            }   
      }else{
        if(data.status === 'Completed' && !data.in_time){
          seterror({...error,in_time:'This field is required *'})
        }else{
          sendData["id"] = state?._id
          sendData['employee'] = data?.employee.value
          if(data?.approval_required && data?.approval_user !== ''){
            sendData['approval_user'] = data?.approval_user.value
          }else{
            delete sendData.approval_user
          }
          const response = await UpdateStaffMomentService(sendData,state?._id)
          if (response.status === 200) {
            resetform()
            navigate(-1)
            toast.success('Staff Moment Updated Successfully')
          }   
          if(response.status === 422){
            if(response?.data?.errors?.title){
              seterror({...error,title:response?.data?.errors?.title})
            }
          } 
        }
      }
    }
  }


  function resetform(){
    setdata({employee:'',purpose:'',work_for:'',status:'Pending',date:'',in_time:'',out_time:'',remarks:'',approval_required:false,approval_user:''})
    seterror({employee:'',purpose:'',work_for:'',status:'',in_time:'',date:'',out_time:'',remarks:'',approval_required:'',approval_user:''})
  }

  return (
    <div className='flex '>
        {/* {pathname !== '/conveyance_vochuer_create' &&
        <FinanceMenu />} */}
         <div className={`min-w-44 ${path == 'profile' && 'min-w-[180px] pl-2'}`}>
              <ReceptionMenu />
            </div>

        <div className={`w-full md:w-72 lg:w-72 px-4 pt-5`} >

            <GoBack />
            
            

            <h6 className='font-bold'>{(state?._id !== null && state?._id !== undefined) ? 'Edit' : 'Add'} Staff Moment</h6>
            <h6 className='text-[10px] bg-slate-100 p-2 font-[500] leading-snug' >Use the below form to create or edit the <b> Staff Moment</b> for your issues.</h6>
        
               <h6 className='text-[11px] font-[600] mb-1 mt-2' >Search Outgoing user</h6>
                <div className='h-[35px] border'>
                  <Select
                      bordered={false}
                      className='w-[100%] border-l-4 border-slate-700'
                      filterOption={false}
                      showSearch
                      disabled={path_type == 'edit'}
                      placeholder=''
                      allowClear={true}
                      onSearch={getuserlist}
                      optionFilterProp="children"
                      value={data?.employee}
                      options={userArr}
                      onChange={(v)=>handleselect(v,'user')}

                     
                  />
                </div>


            <TextAreaInput1 
                label={'Purpose'}  
                variant="standard"
                name="purpose"
                type="date"
                mandatory={true}
                error={error.purpose}
                readOnly={path_type == 'edit'}
                value={data.purpose}
                handlechange={handlechange}
                placeholder=""
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>

          <TextInput
                label={'Work Related / Sent By'}  
                variant="standard"
                name="work_for"
                mandatory={true}
                type="text"
                error={error.work_for}
                value={data.work_for}
                readOnly={path_type == 'edit'}
                handlechange={handlechange}
                placeholder=""
                InputLabelProps={{
                    style: { color: '#fff', }, 
              }}/>

          <TextInput
                label={'Date'}  
                variant="standard"
                name="date"
                mandatory={true}
                type="date"
                error={error.date}
                value={data.date}
                readOnly={path_type == 'edit'}
                handlechange={handlechange}
                placeholder=""
                InputLabelProps={{
                    style: { color: '#fff', }, 
              }}/>

            <TextInput
                label={'Out Time'}  
                variant="standard"
                name="out_time"
                mandatory={true}
                readOnly={path_type == 'edit'}
                type="time"
                error={error.out_time}
                value={data.out_time}
                handlechange={handlechange}
                placeholder=""
                InputLabelProps={{
                    style: { color: '#fff', }, 
              }}/>

            <TextInput
                label={'In Time'}  
                variant="standard"
                name="in_time"
                mandatory={path_type == 'edit'}
                type="time"
                error={error.in_time}
                value={data.in_time}
                handlechange={handlechange}
                placeholder=""
                InputLabelProps={{
                    style: { color: '#fff', }, 
              }}/>

            <div onClick={()=>path_type != 'edit' && setdata({...data,approval_required:!data.approval_required})} className='flex items-center mt-0 mr-2 -ml-1 mt-1'>
                {data?.approval_required ?  <BiCheckboxSquare size={22} className='text-slate-600'  />  : <BiCheckbox size={22} className='text-slate-300'  /> }
                <h6 className="text-[11.5px] font-[500] ml-1">{data?.expired ? 'Approval Required' : 'No Approval Required'}</h6> 
            </div>
            
            {data?.approval_required && <>
            <h6 className='text-[11px] font-[600] mb-1 mt-2' >Approval user</h6>
                <div className='h-[35px] border'>
                  <Select
                      bordered={false}
                      className='w-[100%] border-l-4 border-slate-700'
                      filterOption={false}
                      showSearch
                      placeholder=''
                      disabled={path_type == 'edit'}
                      allowClear={true}
                      onSearch={getuserlist}
                      optionFilterProp="children"
                      value={data?.approval_user}
                      options={userArr}
                      onChange={(v)=>handleselect(v,'approval_user')}

                     
                  />
                </div>
                </>}
  
            {state?._id !== undefined && path_type === 'edit' &&
             
             <>
            <TextAreaInput1 
                label={'Remarks'}  
                variant="standard"
                name="remarks"
                mandatory={true}
                type="text"
                error={error.remarks}
                value={data.remarks}
                handlechange={handlechange}
                placeholder=""
                InputLabelProps={{
                    style: { color: '#fff', }, 
              }}/>


            <div className='mt-1'>
                
                <h6 className='text-[11px] font-[600] mb-1 mt-2' >Status</h6>
                <div className='h-[35px] border border-slate-300'>
                  <Select
                      placeholder=''
                      value={data?.status}
                      onChange={(v)=>setdata({...data,status:v})}
                      options={statusOption}
                      bordered={false}
                      className='w-[100%]'
                  />
                </div>
              
            </div>
            </>}


            <div className='mt-5'>
            <ButtonFilledAutoWidth btnName={(state?._id !== null && state?._id !== undefined) ? "UPDATE STAFF MOMENT" : "ADD STAFF MOMENT"}  onClick={submitform} />  
            </div>
            
        </div>
   </div> 
  )
}

export default StaffMomentCE