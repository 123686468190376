import React, { useEffect } from 'react';
import {useLocation,useNavigate } from 'react-router-dom';
import {AiOutlineFileSearch,AiOutlineTeam} from 'react-icons/ai';
import {TbPresentationAnalytics} from 'react-icons/tb';
import {MdOutlineManageAccounts} from 'react-icons/md';
import {CgListTree} from 'react-icons/cg';
import {BsOpticalAudio} from 'react-icons/bs';
import {RiGovernmentLine} from 'react-icons/ri';
import { LuScreenShare } from "react-icons/lu";
import { CgWebsite } from "react-icons/cg";
import { useSelector } from 'react-redux';
// import {DatePicker} from 'antd';




function AtsMenu() {

  const {pathname} = useLocation();
  const user = useSelector(state=>state.Auth)
  const path = pathname.split('/')[pathname.split('/').length - 2]

  // console.log("path",path)

  
  
  // const roles = useSelector(state=>state.Auth.roles)

  // console.log("auth",auth)

  const navigate = useNavigate();

  const menu = [
    {name:'Home Page',icon:TbPresentationAnalytics,path:'/ats/dashboard',id:1,color:'',roles:['admin','ats_head','ats_manager','ats_user']},
    {name:'Our Clients',icon:CgListTree,path:'/ats/client_managment',id:2,color:'',roles:['admin','ats_head','ats_manager','ats_user']},
    {name:'Job Openings',icon:AiOutlineFileSearch,path:'/ats/job_description',id:3,color:'',roles:['admin','ats_head','ats_manager','ats_user']},
    {name:'Candidate Shared',icon:LuScreenShare,path:'/ats/employee_status_update',id:4,color:'',roles:['admin','ats_head','ats_manager','ats_user']},
    {name:'Candidate Manage',icon:MdOutlineManageAccounts,path:'/ats/employee_managment',id:4,color:'',roles:['admin','ats_head','ats_manager','ats_user']},
    {name:'Track Option',icon:BsOpticalAudio,path:'/ats/track_option',id:4,color:'',roles:['admin','ats_head']},
  ]  

  const menu2 = [
    {name:'Website Candidate',icon:CgWebsite,path:'/ats/website_candidate',id:1,color:'',roles:['admin','ats_head','ats_manager',]},
  ]  
  
  const menu1 = [
    {name:'Team Report',icon:AiOutlineTeam,path:'/ats/team_report',id:1,color:'',roles:['admin','ats_head']},
    {name:'Overall Report',icon:RiGovernmentLine,path:'/ats/overall_report',id:1,color:'',roles:['admin','ats_head']},
  ]  

  useEffect(()=>{
    if(path === ''){
      navigate('dashboard')
    }
  },[path])
 
  return (
    <div className='mr-0 min-h-screen overflow-hidden max-h-sceen border-r min-w-44 max-w-44 min-w-[180px] max-w-[180px]  w-44 px-2' >

        <div className='mb-4 pt-5'>
        <h6 className=' ml-2 mb-2 font-[700] text-[12px]'>Hiring Option</h6>
              
            {menu.map((m)=>(
                user?.roles?.filter((f)=>m.roles?.includes(f))?.length > 0 && 
                <div key={m?.path} className={`flex items-center my-1 hover:bg-[#f4f5f7] px-1.5 py-1 ml-2 rounded-md relative cursor-pointer ${pathname ===  m.path && 'bg-[#f4f5f7]'}`} onClick={()=>navigate(m?.path)}>
                  {pathname ===  m.path &&  <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3' ></span>}
                  <m.icon color='#000' style={{backgroundColor:m?.color,padding:'2px'}} size={16}  />
                  <span className='text-[#000] font-[500] ml-2 text-[12px]'>{m.name}</span>
                </div>    
            ))}

        <h6 className=' ml-2 mb-2 font-[700] text-[12px] mt-5'>Website Option</h6>
        {menu2.map((m)=>(
                user?.roles?.filter((f)=>m.roles?.includes(f))?.length > 0 && 
                <div key={m?.path} className={`flex items-center my-1 hover:bg-[#f4f5f7] px-1.5 py-1 ml-2 rounded-md relative cursor-pointer ${pathname ===  m.path && 'bg-[#f4f5f7]'}`} onClick={()=>navigate(m?.path)}>
                  {pathname ===  m.path &&  <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3' ></span>}
                  <m.icon color='#000' style={{backgroundColor:m?.color,padding:'2px'}} size={16}  />
                  <span className='text-[#000] font-[500] ml-2 text-[12px]'>{m.name}</span>
                </div>    
            ))}

      
        {['admin','ats_head']?.filter(f=>user?.roles?.includes(f))?.length > 0 &&
        <>
        <h6 className=' ml-2 mb-2 font-[700] text-[12px] mt-5'>HOD Option</h6>

        {menu1.map((m)=>(
                user?.roles?.filter((f)=>m.roles?.includes(f))?.length > 0 && 
                <div key={m?.path} className={`flex items-center my-1 hover:bg-[#f4f5f7] px-1.5 py-1 ml-2 rounded-md relative cursor-pointer ${pathname ===  m.path && 'bg-[#f4f5f7]'}`} onClick={()=>navigate(m?.path)}>
                  {pathname ===  m.path &&  <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3' ></span>}
                  <m.icon color='#000' style={{backgroundColor:m?.color,padding:'2px'}} size={16}  />
                  <span className='text-[#000] font-[500] ml-2 text-[12px]'>{m.name}</span>
                </div>    
            ))}
          </>} 

        </div>

       
    </div>
  )
}

export default AtsMenu