import React, { useEffect, useState } from 'react'
import ProfileMenu from './ProfileMenu'
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../components/button'

import { CreateReimbusmentService, GetReimbusmentDetailService, GetReimbusmentService } from '../../services/ReimbusmentServices';
import GoBack from '../../components/back/GoBack';
import { useSelector } from 'react-redux';
import { TextAreaInput1, TextInput } from '../../components/input';
import Uploader1 from '../../components/Uploader1';
import { SearchUser, uploadFile } from '../../services/AuthServices';
import Priceconstants from '../../constants/imageConstants';
import { FiFile } from "react-icons/fi";
import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai';
import toast from 'react-hot-toast';
import { Modal,Select } from 'antd';
import { IoMdAdd } from "react-icons/io";
import {BiCheckbox,BiCheckboxSquare} from 'react-icons/bi'
import { useLocation } from 'react-router-dom';
import { GetDepartmentService } from '../../services/DepartmentServices';


function ReimbusmentCE() {

  const {pathname,state} = useLocation()

  let path = pathname?.split('/')[pathname?.split('/')?.length - 1]

  // console.log("create",path)
  const user = useSelector(state=>state.Auth)  
  const [data,setdata] = useState({particular:'',other_particular:'',amount:'',file:''})
  const [error,seterror] = useState({particular:'',other_particular:'',amount:'',file:''})
  const [datas,setdatas] = useState([])
  const [modal,setmodal] = useState(false)

  const [expense_department,setexpense_department] = useState(null)

  const [department,setdepartment] = useState([])


  const [data1,setdata1] = useState({required_approval:false,approvals:[],summary:''})
  const [searchEmployee,setsearchEmployee] = useState([])

  const particulars = [
    {label:'Petrol',value:'Petrol'},
    {label:'Trip',value:'Trip'},
    {label:'Lunch With Client',value:'Lunch With Client'},
    {label:'Team Lunch',value:'Team Lunch'},
    {label:'OLA / Travel Bill',value:'OLA / Travel Bill'},
    {label:'Other',value:'Other'},
  ]

  useEffect(()=>{
    getData()
    getdepartment()
  },[])

  async function getData(){
    if(path === 'edit'){
      const response = await GetReimbusmentDetailService(state?._id)

      let d = response?.data?.datas?.datas[0]

      if(d?.expense_department?._id !== undefined && d?.expense_department?._id !== null && d?.expense_department?._id !== ''){
        setexpense_department({label:d?.expense_department?.department_name,value:d?.expense_department?._id})
      }
      setdatas(d?.reimbursmentitems)
      setdata1({...data1,summary:d?.summary,approvals:d?.reimbursmentapprovals})
      setdata(response?.data?.datas?.datas[0])
    }
    // console.log("response?.data",response?.data)
  }

  async function getdepartment(){
    const response = await GetDepartmentService();
    let arr = []
    response.data.datas.forEach((d)=>{
        arr.push({label:d?.department_name,value:d?.id})
    })
    setdepartment(arr)
  }

  async function handlefileupload(e){
     const response = await uploadFile({file:e})
    //  console.log("response",response?.data)
     setdata({...data,file:response?.data?.data})
     
  }

  async function resetform(){
       setdata({particular:'',amount:'',file:''})
       setmodal(false)
  }

  async function submiform(){

      if(data?.particular === ''){
        seterror({...error,particular:'This Field is required'})
      }else if(data?.particular === 'Other' && data?.other_particular === ''){
        seterror({...error,other_particular:'This Field is required'})
      }else if(data.amount === ''){
        seterror({...error,amount:'This Field is required'})
      }else{
          if(data.id === undefined){
            if(datas?.length > 0){
                const arr = [...datas]
                arr.push({...data,id:datas.length})
                setdatas(arr)
                setdata({particular:'',amount:'',file:''})
            }else{
                const arr = []
                let obj = {
                    ...data,
                    id:0
                }
                delete obj.datas
                delete obj.pagination

                arr.push(obj)
                setdatas(arr)
                setdata({particular:'',amount:'',file:''})
            }
          }else{
            const arr = [...datas]
            arr[data.id] = data
            setdatas(arr)
            setdata({particular:'',amount:'',file:''})
          }
      }
      
  }

  async function handleChange(e){
    setdata({...data,[e.target.name]:e.target.value})
    seterror({...error,[e.target.name]:''})
  }

  async function removeData(i){
    setdatas(datas.filter((f)=>f.id !== i))
  }

  async function submitData(){
    if(datas.length > 0){

      let amount =  0;
      let approvals = [];
      datas.forEach((d)=>{
        amount += parseInt(d?.amount)
      })

      if(data1?.approvals?.length !== 0){
        data1?.approvals?.forEach((a)=>{
          approvals?.push(a?.value)
        })
      }
      

      let sendData = {
        items:datas,
        approvals:approvals,
        summary:data1?.summary,
        amount:amount
      }

      if(expense_department?.value !== undefined && expense_department !== null && expense_department !== undefined){
        sendData['expense_department'] = expense_department?.value
      }
     
      const response = await CreateReimbusmentService(sendData)

      if(response?.status === 201){
        toast.success("Reimbusment request created")
        setmodal(false)
        setdatas([])
        setexpense_department(null)
        setdata({particular:'',other_particular:'',amount:'',file:''})
        setdata1({...data1,required_approval:false,approvals:[],summary:''})
      }
      // console.log("response?.data",response?.data)
    }else{
      toast.error("You must add minimum 1 data for to raise reimbusment request!")
    }
  }

  async function onSearch(v){
    const response = await SearchUser(v)
    let arr = []
    response?.data?.forEach((d)=>{
     arr.push({value:d?._id,label:d?.name})
    })
    setsearchEmployee(arr)
    // console.log("response kp na",response)
  }

  async function handleSelect(v){
    let arr = []
     v?.forEach((v1)=>{
      let findEmployee =  searchEmployee.find((f)=>f?.value == v1)
      let findEmployee1 =  data1?.approvals.find((f)=>f?.value == v1)

      if(findEmployee !== null && findEmployee !== undefined && findEmployee !== ''){
        arr.push(findEmployee)
      }else{
      if(findEmployee1 !== null && findEmployee1 !== undefined && findEmployee1 !== ''){
        arr.push(findEmployee1)
      }}
     })
     setdata1({...data1,approvals:arr})
  }


  return (
    <div className='px-2 flex mx-0 box-border  max-h-screen overflow-y-scroll overflow-x-hidden'>
        <div className='block sm:flex'>
        <ProfileMenu />
        </div>
        <div className='w-full ml-4  pt-2'>
          <Modal open={modal} width={300} closable={false} footer={false} className='absolute top-0 left-[40%]' >
              <h6 className="font-bold text-[13px]  mb-1 w-full">Create / Edit Reimbusment</h6>
              <h6 className="text-[11px] bg-slate-100  font-[600] p-[5px] mb-2 w-full">Use the below form to create or edit the reimbusment of your money</h6>
             
             {/* < */}

             <h6 className='text-[10px] border-t border-slate-100 pt-1 font-[600] mb-1 mt-1'>Particular </h6>
              <div className='border border-slate-200'>
              
              <Select
                bordered={false}
                value={data?.particular} 
                filterOption={false}
                placeholder="" 
                onChange={(e)=>{setdata({...data,particular:e});seterror({...error,particular:''})}} 
                className='w-full rounded-[0px]' 
                options={particulars} 
                />

              
              </div>

              {data?.particular === 'Other' &&
              <TextInput 
                    label="Other Particulars"
                    name="other_particular"
                    type="text"
                    value={data?.other_particular}
                    error={error?.other_particular}
                    handlechange={handleChange}
                />}

              <TextInput 
                    label="Amount"
                    name="amount"
                    type="number"
                    min={0}
                    value={data?.amount}
                    error={error?.amount}
                    handlechange={handleChange}

                />

        

              <h6 className='text-[11px] font-[600] mb-1 mt-2' >Attachment</h6>
              <Uploader1 
                image={data?.file}
                removeimageuploadfunc ={()=>setdata({...data,file:''})}
                setimagefunc={(e)=>{handlefileupload(e)}}
              
              />

              <div className='flex justify-end pt-2 mt-2 border-t border-slate-100'>
                <ButtonOutlinedAutoWidth onClick={resetform} btnName="Clear" />
                <h6 className='w-[10px]'></h6>
                <ButtonFilledAutoWidth onClick={submiform} btnName="Save" />
              </div>


           </Modal>
            
            <div className='flex'>
            <div className='w-64 pr-2 mr-2'>
            <GoBack />

            <h6 className='text-[13px] font-[700]'>Create or Update the Reimbusment Request</h6>
            <h6 className='text-[11px] p-1 font-[500] leading-[14px] my-2 bg-slate-100'>Use the below form to create or update the reimbusment request for the finance team</h6>

            <div className='flex'>
                <div className='w-[49%] mr-[1%]'>
                <TextInput 
                    readOnly={true}
                    label="Request By"
                    name="name"
                    value={user?.name}
                />
                </div>

                <div className='w-[49%] ml-[1%]'>
                <TextInput 
                    readOnly={true}
                    label="Request Date"
                    name="name"
                    type="date"
                    value={new Date()?.toISOString()?.slice(0,10)}
                />
                </div>
            </div>

            <h6 className='text-[11px] font-[600] mb-1 mt-2' >Expense Department</h6>
                <div className='h-[35px] border'>
                  <Select
                      disabled={path === 'edit'}
                      placeholder=''
                      value={expense_department}
                      onChange={(v)=>setexpense_department(department?.find((f)=>f?.value === v))}
                      options={department}
                      bordered={false}
                      className='w-[100%]'
                  />
                </div>




            {path === 'edit' &&
            <h6 className='text-[11px] font-[700] mt-2'>Total Reimbusment Amount : {Priceconstants(data?.amount)}.</h6>}


            <TextAreaInput1 
                label={'Summary'}
                readOnly={path==='edit'}
                name={'summary'}
                value={data1?.summary}
                handlechange={(e)=>setdata1({...data1,summary:e.target.value})}
                />


            {path !== 'edit' && 
            <h6 className='text-[11px] bg-slate-100 mt-2 p-[4px]'>Enable the below checkbox if your reimbusment request should be accepted any higher authorities in thee organization</h6>
            }

            <div onClick={()=> path !=='edit' && setdata1({...data1,required_approval:!data1.required_approval})} className='flex cursor-pointer items-center mt-2 mr-2'>
             {data1?.required_approval ?  <BiCheckboxSquare size={20} className='text-slate-600' />  : <BiCheckbox size={20} className='text-slate-300' /> }
             <h6 className="text-[10px] font-[600] ml-[0px]">Approval Required</h6> 
            </div>  

            {data1?.required_approval &&
            <>
             <h6 className='text-[10px] border-t border-slate-100 pt-1 font-[600] mb-1 mt-1'>Approval Members </h6>
              <div className='border border-slate-200'>
              
              <Select
              showSearch
                mode='multiple'
                bordered={false}
                value={data1.approvals} 
                filterOption={false}
                // error={error.users}
                placeholder="" 
                onChange={(e)=>handleSelect(e,'users')} 
                onSearch={onSearch}
                className='w-full rounded-[0px]' 
                options={searchEmployee} 
                />
              </div>  
            </>}

           


           


            </div> 
            <div className='border-l mt-10 pl-2'>
                <div className='flex items-center justify-between'>
                <h6 className='text-[12px] font-[600]'>Reimbusment Listed ({datas?.length})</h6>
                {path === 'create' && 
                <IoMdAdd onClick={()=>setmodal(true)} className='bg-slate-100 p-[3px] rounded-[4px] cursor-pointer' />
                }
                </div>
                
                <div className='border mt-5'>
                <div className='flex bg-slate-100 text-[11px]'>
                    <h6 className='w-[40px] p-1 border-r'>Sl No </h6>
                    <h6 className='w-[150px] p-1 border-r'>Particulars </h6>
                    <h6 className='w-[60px] p-1 border-r'>Amount </h6>
                    <h6 className='w-[60px] p-1 border-r'>File </h6>
                    <h6 className='w-[60px] p-1'>Action </h6>
                </div>
                {datas?.map((d,i)=>(
                <div className='flex items-center border-t text-[11px]'>
                    <h6 className='w-[40px] p-1 border-r'>{i+1}</h6>
                    <h6 className='w-[150px] p-1 border-r'>{d?.particular?.label === 'Other' ? d?.other_particular : d?.particular} </h6>
                    <h6 className='w-[60px] p-1 font-[800] border-r'>{Priceconstants(parseInt(d?.amount))} </h6>
                    <h6 className='w-[60px] p-1 border-r'>{(d?.file !== '' && d?.file !== null && d?.file !== undefined) &&  <a href={`${process.env.REACT_APP_BACKEND_IMAGE_URL}${d?.file}`} target='_blank'><FiFile /></a>} </h6>
                    <h6 className='w-[60px] p-1 flex'>
                      {path === 'create' && <>
                      <AiOutlineEdit onClick={()=>{setdata(d);setmodal(true)}} className='mr-2'/> <AiOutlineDelete onClick={()=>removeData(d?.id)} /> 
                      </>}</h6>

                </div>
                ))}

                
                </div>

                {path === 'edit' &&
                <>
                <div className='flex items-center mt-5 justify-between'>
                <h6 className='text-[12px] font-[600]'>Approvals Listed ({data?.reimbursmentapprovals?.length})</h6>
                </div>

                <div className='border mt-2'>
                <div className='flex bg-slate-100 text-[11px]'>
                    <h6 className='w-[40px] p-1 border-r'>Sl No </h6>
                    <h6 className='w-[150px] p-1 border-r'>Name </h6>
                    <h6 className='w-[120px] p-1 border-r'>Approval Status </h6>
                    <h6 className='w-[170px] p-1 '>Remarks </h6>
                </div>

                {data?.reimbursmentapprovals?.map((d,i)=>(
                <div className='flex border-t text-[11px]'>
                    <h6 className='w-[40px] p-1 border-r'>{i+1}</h6>
                    <h6 className='w-[150px] p-1 border-r'>{d?.user?.name} </h6>
                    <h6 className='w-[120px] font-[700]  p-1 border-r'>{d?.approvedStatus} </h6>
                    <h6 className='w-[170px] p-1 '>{d?.remarks} </h6>
                </div>))}
                </div>
                </>}
            </div>
            </div>
            {path === 'create' && 
            <div className='pt-5 mt-5 border-t border-slate-100'>
            <ButtonFilledAutoWidth onClick={submitData} btnName="Send Reimbusment" />
            </div>}
        </div>    
    </div>
  )
}

export default ReimbusmentCE