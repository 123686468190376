import { deleteRequest, get, post, postfd, put } from "../helpers/apihelpers";


export const GetInvoiceService=async(type,page,user='')=>{
    try {
        const result = await get(`api/invoice_request/get?type=${type}&page=${page}&user=${user}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetInvoiceServiceAll=async(page)=>{
    try {
        const result = await get(`api/invoice_request/get_all?page=${page}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateInvoiceService=async(id)=>{
    try {
        const result = await deleteRequest(`api/invoice_request/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateInvoiceServiceAdmin=async(id,data)=>{
    try {
        const result = await put(`api/invoice_request/update_admin/${id}`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const FilterInvoiceServiceAdmin=async(search,user,page)=>{
    try {
        const result = await get(`api/invoice_request/filter_admin?search=${search}&user=${user}&page=${page}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteInvoiceService=async(id)=>{
    try {
        const result = await deleteRequest(`api/invoice_request/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}
export const GetInvoiceFilterService=async(type,page,search,user='')=>{
    try {
        const result = await get(`api/invoice_request/filter?type=${type}&page=${page}&search=${search}&user=${user}`);
        return result;
    } catch (err) {
        return err.response;
    }
}



export const GetInvoiceServiceDetail=async(id)=>{
    try {
        const result = await get(`api/invoice_request/getDetail/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetInvoiceServiceDetailBasedOnClient=async(id)=>{
    try {
        const result = await get(`api/invoice_request/getDetailBasedOnClient/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}


export const CreateInvoiceFinance=async(data,id)=>{
    try {
        const result = await postfd(`api/invoice_request/create_invoice/${id}`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateInvoiceFinance=async(data,id)=>{
    try {
        const result = await postfd(`api/invoice_request/revise_invoice_based_on_user_request/${id}`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdatePaymentInvoiceFinance=async(data,id)=>{
    try {
        const result = await post(`api/invoice_request/invoice_payment_recieved/${id}`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}


export const ReviseInvoiceFinance=async(data,id)=>{
    try {
        const result = await postfd(`api/invoice_request/revise_invoice_based_on_user_request/${id}`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const ReviseInvoiceUserRequest=async(id,data)=>{
    try {
        const result = await put(`api/invoice_request/revise_invoice_user/${id}`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetAllInsentivePayments = async (type,page)=>{
    try {
        const result = await get(`api/invoice_payment/get_all_insentive_payments?type=${type}&page=${page}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetAllInsentiveAttachment = async (search,page)=>{
    try {
        const result = await get(`api/invoice_attachment/get?search=${search}&page=${page}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteInsentiveAttachment = async (id)=>{
    try {
        const result = await deleteRequest(`api/invoice_attachment/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}


export const GetCurrentInsentivePayments = async (type,page,incentive)=>{
    try {
        const result = await get(`api/invoice_payment/get_current_insentive_payments?type=${type}&page=${page}&incentive=${incentive}`);
        return result;
    } catch (err) {
        return err.response;
    }
}



export const GetInsentivePaymentsDetail = async (id)=>{
    try {
        const result = await get(`api/invoice_payment/get_current_insentive_payments/${id}?type=`);
        return result;
    } catch (err) {
        return err.response;
    }
}


export const UpdatePaymentServiceRequest = async (id,data)=>{
    try {
        const result = await put(`api/invoice_payment/update/${id}`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeletePaymentServiceRequest = async (id,data)=>{
    try {
        const result = await put(`api/invoice_payment/delete_invoice_payment/${id}`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}


export const RestorePaymentServiceRequest = async (id,data)=>{
    try {
        const result = await put(`api/invoice_payment/restore/${id}`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const CreateInsentivePaymentsShare = async (id,data)=>{
    try {
        const result = await post(`api/invoice_payment/create_employee_share/${id}`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}


export const GetInsentivePaymentsShareBasedOnLeadAndUser = async (leadid,userId)=>{
    try {
        const result = await get(`api/invoice_payment/check_my_payment_share/${leadid}/${userId}`);
        return result;
    } catch (err) {
        return err.response;
    }
}


export const GetEmployeeShareCurrentInsentive = async (selectedYear) => {
    try {
        const result = await get(`api/invoice_payment/get_employee_share?incentive=${selectedYear}`);
        return result;
    } catch (err) {
        return err.response;
    }
}


export const GetAllEmployeeShare = async (page) =>{
    try {
        const result = await get(`api/invoice_payment/get_all_insentive_payments?page=${page}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteAllEmployeeShare = async (id) =>{
    try {
        const result = await deleteRequest(`api/invoice_payment/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteEmployeeShare = async (id) =>{
    try {
        const result = await deleteRequest(`api/invoice_payment/delete_employee_share/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}


export const GetInvoiceRaisedBasedOnIncentive = async (from_date,to_date,incentive) =>{
    try {
        const result = await get(`api/invoice_request/get_invoice_raised_incentive_period?from_date=${from_date}&to_date=${to_date}&incentive_requirment=${incentive}`);
        return result;
    } catch (err) {
        return err.response;
    }
}
