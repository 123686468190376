import React,{useEffect, useState} from 'react'
import { DatePicker,Modal } from 'antd';
import { IconButton } from '@material-ui/core';
import {FiChevronRight,FiChevronLeft} from 'react-icons/fi';
import {  ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../components/button';
import AtsMenu from './AtsMenu';
import {AiOutlineFilter,AiOutlineDelete,AiOutlineEdit} from 'react-icons/ai';
import {BiReset} from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { DeleteAtsTrackService, FilterAtsTrackService, GetAtsTrackService } from '../../services/Ats/AtsTrackServices';
import moment from 'moment';


function AtsTrackOption() {

  const [search,setsearch] = useState({text:'',from_date:'',to_date:'',activate:false})
  const [selected_data,setselected_data] = useState({})
  const [modal,setmodal] = useState(false)
  const [index,setindex] = useState(1)

  const stage = ['Active','InActive','Hold']

  const [page,setpage] = useState(1)
  const navigate = useNavigate()

  const [datas,setdatas] = useState({})
  const [error,seterror] = useState({})

  const [data,setdata] = useState([])

  const navigator = useNavigate()


  useEffect(()=>{
    if(search?.activate){
      filterdata(page)
    }else{
      getdata(page)
    }
  },[page,index])

  async function getdata(page){
    const response = await GetAtsTrackService({page:page,stage:stage[index-1]})
    setdata(response.data)
  }

  async function filterdata(page=1){
    setsearch({...search,activate:true})
    const response = await FilterAtsTrackService({...search,page:page})
    setdata(response.data)
  }

  async function resetdata(){
    setsearch({text:'',from_date:'',to_date:'',activate:false})
    getdata(1)
  }

  async function deleteDatafunc(){
    const response = await DeleteAtsTrackService(selected_data?._id)
    if(response?.status === 200){
      toast.success("Deleted Successfully")
      getdata(page)
      setmodal(false)
    }
  }


  return (
    <div className='w-[98%]'>
       
       <Modal open={modal} width={320} closable={false} footer={false}>
          <div> 

            <h6 className='text-[15px] font-[700]'>Delete Data</h6>
            <h6 className='text-[11px] leading-tight bg-gray-100 p-2' >Before Deleting the data be sure that it is required or not for further purpose.</h6>
             
             <h6 className='mb-2 mt-2 text-[11px]'>Stage Name : <span className='font-[800]'>{selected_data?.heading}</span>  Sub  Stages : <span className='font-[800]'>{selected_data?.sub_heading?.split(":").join(',')}</span></h6>
             <div className='flex items-center border-t pt-2'>
                <ButtonOutlinedAutoWidth btnName="Cancel" onClick={()=>setmodal(false)} />
                <h6 className='w-[10px]'></h6>
                <ButtonFilledAutoWidth btnName="Sure" onClick={deleteDatafunc} />
             </div>
          </div>
       </Modal>
    
       <div className='flex'>
            <div className='min-w-44'>
            <AtsMenu />
            </div>
            <div className='w-[100%] pl-5 mt-4'>
              <div className='flex items-center -mt-1 border-b justify-between pb-1'>

              <h6 className='font-[800] text-[13px]'>Total Ats Stage  ({data?.pagination?.total})</h6>
                      
                      <div className='flex items-center text-[12px]'>
                      <div className='flex items-center'>
                      <h6 className='mr-2 font-[600]'>{page == 1 ? data?.datas?.length > 0 ? 1 : 0 : (page - 1) * data?.pagination?.limit } - {data?.pagination?.limit} of {data?.pagination?.total} </h6>
                      <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>
                      <IconButton onClick={()=>{ page < data?.pagination?.totalPages  ? setpage(page+1) : console.log('')}}><FiChevronRight className={`${(data?.pagination?.totalPages === page || data?.datas?.length === 0) ? 'opacity-50 ' : 'opacity-100'}`}  size={16} /></IconButton>

                      </div>
                      {/* <DatePicker  size='small' style={{fontSize:'11px'}} ampm={false} placeholder='From Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.from_date} onChange={(v) => {console.log('v na',new Date(v).toLocaleDateString());setsearch({...search,from_date:v})}} /> 

                      <DatePicker size='small' style={{fontSize:'11px'}} ampm={false} placeholder='To Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.to_date} onChange={(v) => {console.log('v na',new Date(v).toLocaleDateString());setsearch({...search,to_date:v})}} /> 

                      
                      <div className='mr-2'>
                          

                      <input  id="search_text" placeholder='Search text' type='text' value={search.text} onChange={(e)=>setsearch({...search,text:e.target.value})} className='border py-[3px] focus:ring-0 focus:outline-0 text-[14px]  w-[100px] px-1 rounded-md border-slate-300' />
                  

                      </div>  
                      <div className='mr-2'>
                        <AiOutlineFilter onClick={()=>filterdata(1)} size={24} className='border border-slate-600 bg-slate-600 text-[#dedede] p-1 rounded' />
                      </div>
                      <div className='mr-2'>
                         <BiReset size={24} onClick={resetdata} className='border border-slate-300 bg-[#f6f8fa] text-[#5c5c5c] p-1 rounded' />
                      </div> */}
                      <ButtonFilledAutoWidth btnName="Add New" onClick={()=>navigator('create')} />
                      </div>
              </div>
               
              {data?.datas?.length > 0 &&
              <div className='grid mt-2 grid-cols-5  gap-1'>
                {data?.datas?.map((d)=>(                
                 <div key={d?._id} className='border  p-2 relative'>
                     <div className='flex absolute bg-white z-100 top-1 right-1'>
                     <AiOutlineEdit size={14}  className='mr-2' onClick={()=>{navigator('edit',{state:{data:d}})}} />
                     <AiOutlineDelete size={14}  onClick={()=>{setselected_data(d);setmodal(true)}} />
                     </div>
                     <h6 className='text-[12px] bg-blue-100 p-1'>Stage : <span className='font-[700]'>{d?.heading}</span></h6>
                     <h6 className='text-[12px] mb-1'>Stage Option :</h6>
                     <div className='flex flex-wrap mt-1'>
                     {d?.sub_heading?.split(":")?.map((d,i)=>(
                        <span key={i} className='text-[10px] bg-slate-100 p-1 px-2 mr-1 font-[700] rounded mb-1'>{d}</span>
                        ))}
                     </div> 
                     <h6 className='text-[10px]'>Created At : <span className='font-[700]'>{moment(d?.createdAt)?.format('LLL')}</span> </h6>  
                 </div>   
                ))}
             </div>}
            </div>
        </div>    
    </div>
  )
}

export default AtsTrackOption