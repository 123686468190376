import React from 'react'
import IlsMenu from './IlsMenu'
import { useLocation } from 'react-router-dom'

function IlsLandDataDetail() {
  
  const {state} = useLocation()
  
  console.log("state",state)
  return (
    <div className='h-screen max-h-screen overflow-hidden'>
        <div className='flex'>
            <IlsMenu />
            <div className='w-[88%] px-4 mt-5'>

            </div>
        </div>    
    </div>
  )
}

export default IlsLandDataDetail