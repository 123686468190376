import { IconButton, Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import GalleryAppBar from '../GalleryAppBar'
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../../components/button'
import {BsArrowRepeat} from 'react-icons/bs';
import {FiChevronRight,FiChevronLeft} from 'react-icons/fi';
import { DatePicker, Modal } from 'antd';
import { useNavigate } from 'react-router-dom';
import { DeleteGalleyVisitorService, DownloadExcelGalleyVisitorService, GetGalleryVisitorList, SearchGalleryVisitorService } from '../../../services/Gallery/GalleryVisitorServices';
import moment from 'moment';
import {AiOutlineEdit,AiOutlineDelete,AiOutlineFileExcel} from 'react-icons/ai';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import fileDownload from "js-file-download";
import {IoClose} from 'react-icons/io5';

function VisitorList() {
 
  const [search,setsearch] = useState({text:'',from_date:'',to_date:'',activate:false})
  const [datas,setdatas]   = useState({})
  const [selected_data,setselected_data]   = useState({})
  const [modal,setmodal]   = useState(false)
  const [page,setpage] = useState(1)
  const navigate = useNavigate();
  
  useEffect(()=>{
    if(search.activate === false){
       getDatas(page)
    }else{
        applyfilterfunction(page)
    }
  },[page])


  async function getDatas(page){
    if(search.activate === false){
        const response = await GetGalleryVisitorList(page)
        setdatas(response?.data)
    }else{
        let start_date = new Date(search?.from_date).toLocaleDateString().replaceAll('/','-').split('-').reverse().join('-')
        let end_date = new Date(search?.to_date).toLocaleDateString().replaceAll('/','-').split('-').reverse().join('-')
        const response = await SearchGalleryVisitorService({search_text:search.text,from_date:(search?.from_date !== ''  && search?.from_date !== null) ? start_date : '',to_date:(search?.to_date !== ''  && search?.to_date !== null) ? end_date : '',page:page})
        setdatas(response.data)
    }
  }

  async function deleteData(){
    const response = await DeleteGalleyVisitorService(selected_data?._id)
    if(response?.status == 200){
        setmodal(false)
        toast.success("Deleted successfully")
        getDatas(page)
    }
  }

  async function resetfunc(){
    setsearch({text:'',from_date:'',to_date:'',activate:false})
    getDatas(1)
  }


  async function applyfilterfunction(page){
    setsearch({...search,activate:true})
    setpage(1)
    let start_date = new Date(search?.from_date).toLocaleDateString().replaceAll('/','-').split('-').reverse().join('-')
    let end_date = new Date(search?.to_date).toLocaleDateString().replaceAll('/','-').split('-').reverse().join('-')
    const response = await SearchGalleryVisitorService({search_text:search.text,from_date:(search?.from_date !== ''  && search?.from_date !== null) ? start_date : '',to_date:(search?.to_date !== ''  && search?.to_date !== null) ? end_date : '',page:page})
    setdatas(response.data)

  }

  const handleDownload = (url, filename) => {
    // console.log('url',url,'filename',filename)

    axios.get(url, {
        responseType: 'blob',
    })
        .then((res) => {
            fileDownload(res.data, filename)
            toast.success("Visitor Excel Downloaded") 
        })
}

  async function downloadExcelData(){
        const response = await DownloadExcelGalleyVisitorService()
        let path =  response.data.path.replace('public/','')
        handleDownload(`${process.env.REACT_APP_BACKEND_URL}${path}`,path.split('/')[path.split('/')?.length - 1])
  }



   
  return (
    <div className='h-screen max-h-screen box-border overflow-hidden'>
    <div className='block sm:flex'>
    <GalleryAppBar />

    <Modal open={modal} center={true} width={300} closable={false} footer={false}>
      <div className='relative'> 
              <IoClose className='absolute right-0 top-0 cursor-pointer' onClick={()=>{setmodal(false)}} />
              <h6 className='text-[15px] font-[700]'>Delete Visitor Data</h6>
              <h6 className='leading-tight mt-2 text-[12px] bg-slate-100 p-2'>Are You sure want to delete the below selected visitor data  </h6>

              <h6 className='leading-tight mt-2 text-[12px] px-2'>Name : {selected_data?.name}  </h6>
              <h6 className='leading-tight text-[12px] px-2'>Mobile : {selected_data?.mobile} / {(selected_data?.email !== undefined && selected_data?.email !== null) && `Email : ${selected_data?.email}`}  </h6>
              {(selected_data?.address !== undefined && selected_data?.address !== null) &&  <h6 className='leading-tight text-[12px] px-2'>Address : {selected_data?.address}  </h6>}

              <div className='flex mt-4'>
                <ButtonOutlinedAutoWidth btnName="Cancel" onClick={()=>{setmodal(false)}} />
                <div className='ml-2'>
                <ButtonFilledAutoWidth btnName="Yes Im sure" onClick={deleteData} />
                </div>
              </div>

          </div> 
      </Modal>

    <div className='w-[85%]  ml-4  mt-4'>

     <div className='flex items-center justify-between border-b pb-2'>
            <h6 className='font-[700] text-[14px] '>Total Visitor Data Found ({datas?.pagination?.total})</h6>
            <div className='flex items-center'>
              <div className='flex items-center text-[12px] mr-2'>

                <h6 className='mr-2 font-[600]'>{page == 1 ? datas?.datas?.length > 0 ? 1 : 0 : (page - 1) * datas?.pagination?.limit } - {datas?.pagination?.limit} of {datas?.pagination?.total} </h6>
                <IconButton onClick={resetfunc}><BsArrowRepeat size={16} /></IconButton>

                <div>
                <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>
                <IconButton onClick={()=>{ page < datas?.pagination?.totalPages  ? setpage(page+1) : console.log('')}}><FiChevronRight className={`${(datas?.pagination?.totalPages === page || datas?.datas?.length === 0)  ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>

                </div>
              </div>


              <DatePicker size='small' style={{fontSize:'11px'}} ampm={false} placeholder='From Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.from_date} onChange={(v) => {console.log('v na',new Date(v).toLocaleDateString());setsearch({...search,from_date:v})}} /> 

              <DatePicker size='small' style={{fontSize:'11px'}} ampm={false} placeholder='To Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.to_date} onChange={(v) => {console.log('v na',new Date(v).toLocaleDateString());setsearch({...search,to_date:v})}} /> 

            
            <div className='mr-2'>
                

              <input  id="search_text" placeholder='Search text' type='text' value={search.text} onChange={(e)=>setsearch({...search,text:e.target.value})} className='border py-[3px] focus:ring-0 focus:outline-0 text-[14px]  w-28 px-1 rounded-md border-slate-300' />
          
            </div>      

          <ButtonOutlinedAutoWidth btnName="Add Filter" onClick={()=>applyfilterfunction(1)} /> 
            {/* {leads_selected_list?.length > 0 &&
            <div className='ml-2'>
            <ButtonFilledAutoWidth onClick={createManyContactBasedOnlIST} btnName="Create Contact" /> 
            </div>} */}
             <div className='ml-2 mr-2'>
            <ButtonOutlinedAutoWidth btnName="Add Data" onClick={()=>navigate('create')} /> 
            </div>
            <AiOutlineFileExcel onClick={()=>downloadExcelData()} size={24}  className='bg-gray-200 p-1.5 border rounded border-gray-300' />

            {/* <ButtonFilledAutoWidth btnName="Download Excel" onClick={downloadExcelData}/>  */}
            </div>
        </div>


        {datas?.datas?.length === 0 &&
          <div className='grid place-items-center mt-20  items-center justify-center'>
          <img src={'https://fidecrmfiles.s3.amazonaws.com/NoDataFound1.png'} className='w-40 h-40 object-contain' /> 
          <h6 className='font-bold text-[14px] -mt-10'>No data found</h6>
          <h6 className='font-[500] w-[80%] text-center text-slate-700 text-[12.5px] -mt-2'>Oops we couldn't find any visitor added to the sustem based on your stage.</h6>
        </div>
        }

        {datas?.datas?.length > 0 &&
        <div className='max-h-[85vh] mt-4 border-t   border-l border-r overflow-y-scroll'>
        <div className='sticky top-0 z-50 flex  bg-white py-[-5px] border-b border-slate-200 relative'>
            <h6  className='top-0 bg-white z-50 text-[12px] w-14  px-2 py-1 font-[600] text-slate-600 border-r flex items-center justify-center'>Sl No</h6>
            <h6 className='sticky top-0 z-50  text-[12px] w-[50px]  px-2 py-1 font-[600] text-slate-600 border-r'>Photo</h6>
            <h6 className='sticky top-0 z-50  text-[12px] w-[130px]  px-2 py-1 font-[600] text-slate-600 border-r'>Name</h6>
            <h6 className='sticky top-0 z-50  text-[12px] w-[130px]  px-2 py-1 font-[600] text-slate-600 border-r'>Mobile</h6>
            <h6 className='sticky top-0 z-50  text-[12px] w-[170px]  px-2 py-1 font-[600] text-slate-600 border-r'>Email</h6>
            <h6 className='sticky top-0 z-50  text-[12px] w-[200px]  px-2 py-1 font-[600] text-slate-600 border-r'>Address</h6>
            <h6 className='sticky top-0 z-50  text-[12px] w-[200px]  px-2 py-1 font-[600] text-slate-600 border-r'>Remark</h6>
            <h6 className='sticky top-0 z-50  text-[12px] w-[100px] px-2 py-1 font-[600] text-slate-600 border-r'>Visited Date</h6>
          
            <h6 className='sticky top-0 z-50  text-[12px] w-[50px]  px-2 py-1 font-[600] text-slate-600 sticky right-0'>Actions</h6>
        </div>
      
        {datas?.datas?.map((d,i)=>(
        <div  key={d?._id} className={`flex z-50 border-b border-slate-200 z-0`}  >
            <h6 className='sticky top-0 z-30 text-[12px] w-14  px-2 py-1 font-[600] text-slate-600 cursor-pointer border-r flex items-center justify-center'>{page == 1 ? i+1 : ((i+1) + (datas?.pagination?.limit * (page-1)))} </h6>

            <h6 className='text-[12px] font-[500] w-[50px]  px-2 py-1 truncate border-r  border-slate-200'>{(d?.file !== undefined && d?.file !== null) ? <img className='w-8 h-4 object-contain' src={`${process.env.REACT_APP_BACKEND_IMAGE_URL}${d?.file}`} /> : <span className='bg-gray-100 w-5 h-5 flex items-center justify-center text-[10px] rounded uppercase font-[700]'>{d?.name?.slice(0,2)}</span>}</h6>
            <h6 className='text-[12px] font-[500] w-[130px]  px-2 py-1 truncate border-r  border-slate-200'>{d?.name}</h6>
            <h6 className='text-[12px] font-[500] w-[130px]  px-2 py-1 truncate border-r  border-slate-200'>{d?.mobile}</h6>
            <h6 className='text-[12px] font-[500] w-[170px] px-2 py-1 truncate border-r  border-slate-200'>{d?.email}</h6>
            <h6 className='text-[12px] font-[500] w-[200px] px-2 py-1 truncate border-r  border-slate-200'>{d?.address}</h6>
            <h6 className='text-[12px] font-[500] w-[200px] px-2 py-1 truncate border-r  border-slate-200'>{d?.remarks}</h6>
            <h6 className='text-[12px] font-[500] w-[100px]  px-2 py-1 truncate border-r  border-slate-200 sticky right-0'>{moment(d?.createdAt).format('ll')}</h6>
            <h6  className='text-[12px] w-[50px]  px-2 py-1 mt-1 flex'>
            
             
              <Tooltip title="Edit">
              <span><AiOutlineEdit size={13} className="z-10 ml-2" onClick={()=>navigate('edit',{state:d})}/></span>
              </Tooltip>

              {/* {user?.roles?.includes('delete_data') && */}
              <Tooltip title="Delete">
              <span><AiOutlineDelete size={13} className="z-10 ml-2 -mt-[1px]" onClick={()=>{setselected_data(d);setmodal(true)}}/></span>
              </Tooltip>
              

            
            </h6>
        </div>))}
        </div>}
    </div>
    </div>
</div>
  )
}

export default VisitorList