import React, { useEffect } from 'react';
import {useLocation,useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { VscGraphLine } from 'react-icons/vsc';
import { LuBarChartBig } from "react-icons/lu";
import { MdOutlineFeaturedPlayList,MdCleaningServices,MdOutlineApproval,MdOutlineSettingsInputComposite } from "react-icons/md";
import { SiTestcafe } from "react-icons/si";
import { TbUserSquareRounded } from "react-icons/tb";
import { GrStatusInfo } from "react-icons/gr";
import { GrMapLocation } from "react-icons/gr";
import { HiOutlineBuildingOffice } from "react-icons/hi2";
import { MdOutlineLandslide } from "react-icons/md";
import { RiHomeOfficeLine } from "react-icons/ri";
import { BiCategoryAlt } from "react-icons/bi";

function DatabaseMenu() {

  const {pathname} = useLocation();
  const path = pathname.split('/')[2]
  const user = useSelector(state=>state.Auth)

  const navigate = useNavigate();

  useEffect(()=>{
    if(path === undefined){
      navigate('/database/property_type')
    }
  },[path])


 
  
  const menu = [
    {name:'Office Space Option',icon:RiHomeOfficeLine,path:`/database/office_space`,id:1,color:'',roles:['manager','hod','admin']},
    {name:'Managed Office',icon:HiOutlineBuildingOffice,path:`/database/managed_office`,id:2,color:'',roles:['manager','hod','admin']},
    // {name:'Team Revenue',icon:TbMoneybag,path:`/dashboard/team_target_achieved`,id:1,color:'',roles:['manager','hod','admin']},
    // {name:'Weekly Report',icon:FaRegCalendarAlt,path:`/dashboard/weekly_report`,id:1,color:'',roles:['manager','hod','admin']},
    // {name:'Team Calls',icon:HiDevicePhoneMobile,path:'/dashboard/team_calls',id:2,color:'',roles:['manager','hod','admin']},
    // {name:'Daily Tasks',icon:GoTasklist,path:'/dashboard/daily_tasks',id:2,color:'',roles:['manager','hod','admin']},
    // {name:'Team Collection',icon:PiHandbagSimpleBold,path:'/dashboard/team_collection',id:2,color:'',roles:['manager','hod','admin']},
  ] 

  

  const menu1 = [
    {name:'Property Category',icon:BiCategoryAlt,path:`/database/property_category`,id:1,color:'',roles:['manager','hod','admin']},
    {name:'Property Type',icon:MdOutlineLandslide,path:`/database/property_type`,id:1,color:'',roles:['manager','hod','admin']},
    {name:'Property Status',icon:GrStatusInfo,path:`/database/property_status`,id:11,color:'',roles:['manager','hod','admin']},
    {name:'Zone Type',icon:VscGraphLine,path:`/database/property_zone`,id:2,color:'',roles:['manager','hod','admin']},
    {name:'Location vs Zone',icon:GrMapLocation,path:`/database/location_based_zone`,id:3,color:'',roles:['admin']},
    {name:'Conversion Type',icon:LuBarChartBig,path:`/database/conversion_type`,id:4,color:'',roles:['admin']},
    {name:'Furnish Type',icon:MdCleaningServices,path:`/database/furnish_type`,id:5,color:'',roles:['admin']},
    {name:'Amenities Type',icon:MdOutlineFeaturedPlayList,path:`/database/property_amenities`,id:6,color:'',roles:['admin']},
    {name:'Plot Type',icon:SiTestcafe,path:`/database/plot_type`,id:7,color:'',roles:['admin']},
    {name:'Approved Type',icon:MdOutlineApproval,path:`/database/approved_type`,id:8,color:'',roles:['admin']},
    {name:'Katha Type',icon:MdOutlineSettingsInputComposite,path:`/database/katha_type`,id:9,color:'',roles:['admin']},
    {name:'Ownership Type',icon:TbUserSquareRounded,path:`/database/owner_type`,id:10,color:'',roles:['admin']},
  ] 

  
  

  return (
    <div className='mr-0 min-h-screen overflow-y-scroll no-scrollbar max-h-screen border-r min-w-44  max-w-44 min-w-44 px-2' >

        <div className='mb-4 mt-5'>
        <h6 className=' ml-2 mb-2 font-[700] text-[12px]'>Commercial Info</h6>

            {menu.map((m)=>(
              <div key={m?.path}>
              {(m?.path === '/dashboard/my_revenue' || m?.path === '/dashboard/weekly_report1') && (user?.roles?.includes('admin') || user?.department_id[0]?.department_name === 'Transaction Team') ?
               <React.Fragment key={m?.path}>
                {m?.roles?.filter((f)=> user?.roles?.includes(f))?.length > 0 &&
                <div key={m?.path} className={`flex items-center my-1 hover:bg-[#f4f5f7] px-1.5 py-1 ml-2 rounded-md relative cursor-pointer ${pathname ===  m.path && 'bg-[#f4f5f7]'}`} onClick={()=>navigate(m?.path)}>
                  {pathname ===  m.path &&  <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3' ></span>}
                  <m.icon color='#000' style={{backgroundColor:m?.color,padding:'2px'}} size={16}  />
                  <span className='text-[#000] font-[500] ml-2 text-[12px]'>{m.name}</span>
              </div>} 
              </React.Fragment>
              :
              <div key={m?.path}>
              {m?.path !== '/dashboard/my_revenue' &&
              <React.Fragment key={m?.path}>
               {m?.roles?.filter((f)=> user?.roles?.includes(f))?.length > 0 &&
                <div key={m?.path} className={`flex items-center my-1 hover:bg-[#f4f5f7] px-1.5 py-1 ml-2 rounded-md relative cursor-pointer ${pathname ===  m.path && 'bg-[#f4f5f7]'}`} onClick={()=>navigate(m?.path)}>
                  {pathname ===  m.path &&  <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3' ></span>}
                  <m.icon color='#000' style={{backgroundColor:m?.color,padding:'2px'}} size={16}  />
                  <span className='text-[#000] font-[500] ml-2 text-[12px]'>{m.name}</span>
              </div>} 
              </React.Fragment>}
              </div>
              }


              </div>
            ))}
        </div>


        {(user?.roles?.includes('admin') || user?.department_id[0]?.department_name === 'Transaction Team') &&
        <div >
        {['admin','hod','manager']?.filter((f)=> user?.roles?.includes(f))?.length > 0 &&
        <div className={` ${path === 'daily_tasks' ? 'mt-4 pt-4 border-t border-b' : 'border-t border-b pt-4 ' }`}>
            <h6 className=' ml-2 mb-2 font-[700] text-[12px]'>Property Option</h6>
            {menu1.map((m)=>(
                <React.Fragment key={m?.path}>
                {m?.roles?.filter((f)=> user?.roles?.includes(f))?.length > 0 &&
                <div key={m?.path} className={`flex items-center my-1 hover:bg-[#f4f5f7] px-1.5 py-1 ml-2 rounded-md relative cursor-pointer ${pathname ===  m.path && 'bg-[#f4f5f7]'}`} onClick={()=>navigate(m?.path)}>
                  {pathname ===  m.path &&  <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3'></span>}
                  <m.icon color='#000' style={{backgroundColor:m?.color,padding:'2px'}} size={16} />
                 <span className='font-[500] ml-2 text-[12px]'>{m.name}</span>
                </div>}
                </React.Fragment>   
            ))} 
        </div>
        }
        </div>}

       
    </div>
  )
}

export default DatabaseMenu