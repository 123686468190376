import { deleteRequest, get, postfd, put } from "../../helpers/apihelpers";


export const CreateGalleryProductList=async(data)=>{
    try {
        const result = await postfd(`api/gallery_product/create`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateGalleryQrCodeProductMassList= async()=>{
    try {
        const result = await put(`api/gallery_product/update_all`,{});
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateGalleryProductList=async(id,data)=>{
    try {
        const result = await postfd(`api/gallery_product/update/${id}`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetGalleryProductList=async(page)=>{
    try {
        const result = await get(`api/gallery_product/get/null/${page}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetGalleryProductDetail=async(id)=>{
    try {
        const result = await get(`api/gallery_product/get/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const SearchGalleryProductService = async({search_text,author,from_date,to_date,page})=>{
    try {
        const result = await get(`api/gallery_product/filter?search_text=${search_text}&author=${author}&from_date=${from_date}&to_date=${to_date}&page=${page}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteGalleyProductService = async(id) => {
    try {
        const result = await deleteRequest(`api/gallery_product/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DownloadExcelGalleyProductService = async() => {
    try {
        const result = await get(`api/gallery_product/downloadExcel`);
        return result;
    } catch (err) {
        return err.response;
    }
}