import React, { useEffect, useState } from 'react'
import {IoMdAdd} from 'react-icons/io'
import { DeleteFinanceDataService, GetFinanceDataService } from '../../services/AdminServicesfile/FinanceData'
import moment from 'moment'
import { useLocation, useNavigate } from 'react-router-dom'
import {AiOutlineEdit,AiOutlineDelete} from 'react-icons/ai'
import { toast } from 'react-hot-toast'
import { GetDepartmentService } from '../../services/DepartmentServices'
import {  Select } from 'antd'
import {
  Pagination,
} from "@mui/material";
import {GrPowerReset} from 'react-icons/gr';
import SettingsMenu from '../staticscreens/SettingsMenu'
import FinanceMenu from '../finance/FinanceMenu'

function FiananceList() {

  const  navigator = useNavigate()
  const [data,setdata] = useState([])
  const [pagination,setpagination] = useState({})
  const [page,setpage] = useState(1)

  const [department,setdepartment] = useState('')
  const [departmentArr,setdepartmentArr] = useState('')

  const {pathname} = useLocation()
 
  const path = pathname.split('/')[1]



  
  useEffect(()=>{
    getData()
    getDepartment()
  },[])

  useEffect(()=>{
    getData()
  },[department,page])

  async function getData(){
    const response = await GetFinanceDataService(department,page)
    setdata(response.data.data)
    setpagination(response.data.pagination)
  }

  
  let rupee = new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
  });

  const getDepartment = async() =>{
    const response = await GetDepartmentService()
    let arr = []
    response?.data?.datas?.forEach((d)=>{
       arr.push({label:d?.department_name,value:d?.id})
    })
    setdepartmentArr(arr)
  }


  async function deleteData(id){
    const response = await DeleteFinanceDataService(id)
    if(response.status === 200){
        getData()
        toast.success('Deleted Successfully')
    }
  }

  const handlePagination = (e, pageNumber) => {
    setpage(pageNumber)
  }

  return (
    <div className='flex'>
      {path === 'finance' &&
      <FinanceMenu />}
      {path === 'settings' &&
      <SettingsMenu /> }
    <div className='mx-5 w-[85%]'>
        <div className='flex items-center justify-between border-b pb-1  pt-4'>
        <h6 className='text-[14px] font-[800]'>Finance Data ({pagination?.total})</h6>
        <div className='flex items-center'>
            <div className='border rounded-md border-slate-300 mr-2'>
            <Select 
             value={department !== '' ? department : null} 
             style={{ width: 130 }}
             options={departmentArr} 
             size='small' 
             bordered={false} 
             className='py-0.5' 
             placeholder='Select Department' 
             onChange={(v)=>{setpage(1);setdepartment(v)}} />
            </div>
            <IoMdAdd className='bg-gray-100 p-1.5 z-50' size={30} onClick={()=>navigator(`/${path}/finance_dashboard_data/create`)} />
            <GrPowerReset className='bg-gray-100 p-1.5 ml-2 z-50' size={30} onClick={()=>setdepartment('')} />
        </div>
        </div>

        {data.length === 0 &&
        <div className='grid place-items-center mt-20  items-center justify-center'>
          <img src={'https://fidecrmfiles.s3.amazonaws.com/NoDataFound1.png'} className='w-40 h-40 object-contain' /> 
          <h6 className='font-bold text-[14px] -mt-10'>No data found</h6>
          <h6 className='font-[500] text-slate-700 text-[12.5px] -mt-2'>Try Any Filter Option to See the "Invoice Report" For the Vertical</h6>
        </div>}
       
        {data?.length > 0 &&
        <div>
            <div className='flex items-center border-b mt-1 border-l border-r border-t'>
                <h6 className='text-[12px] font-[700] p-1 min-w-[4.5%] max-w-[4.5%] w-[4.5%] border-r'>SL NO</h6>
                <h6 className='text-[12px] font-[700] p-1 min-w-[10%] max-w-[10%]  w-[10%] border-r'>Department</h6>
                <h6 className='text-[12px] font-[700] p-1 min-w-[15%] max-w-[15%]  w-[15%] border-r'>Date</h6>
                <h6 className='text-[12px] font-[700] p-1 min-w-[4.5%] max-w-[4.5%]  w-[4.5%] border-r'>No</h6>
                <h6 className='text-[12px] font-[700] p-1 min-w-[15%] max-w-[15%]  w-[15%] border-r'>Invoice Raised Amount</h6>
                <h6 className='text-[12px] font-[700] p-1 min-w-[13%] max-w-[13%]  w-[13%] border-r'>Amount Collected</h6>
                <h6 className='text-[12px] font-[700] p-1 min-w-[13%] max-w-[13%]  w-[13%] border-r'>Total Outstanding</h6>
                <h6 className='text-[12px] font-[700] p-1 min-w-[10%] max-w-[10%]  w-[10%] border-r'>Expenses</h6>
                <h6 className='text-[12px] font-[700] p-1 min-w-[10%] max-w-[10%]  w-[10%] border-r'>Profit / Loss</h6>
                <h6 className='text-[12px] font-[700] p-1 min-w-[10%] max-w-[10%]  w-[10%] border-r'>Action</h6>
            </div>
            
            {data?.map((d,i)=>(
            <div className='flex items-center border-b  border-l border-r'>
                <h6 className='text-[11.5px] font-[500] p-1 min-w-[4.5%] font-[800] max-w-[4.5%] w-[4.5%] border-r'>{page === 1 ? i+1 : ((page-1) * pagination.limit) + (i+1)}</h6>
                <h6 className='text-[11.5px] font-[500] p-1 min-w-[10%] max-w-[10%] w-[10%] border-r'>{d?.department?.department_name}</h6>
                <h6 className='text-[11.5px] font-[500]  p-1 min-w-[15%] max-w-[15%] w-[15%] border-r'>{moment(d?.from).format('DD-MM-YYYY')} -  {moment(d?.to).format('DD-MM-YYYY')}</h6>
                <h6 className='text-[11.5px] font-[500] p-1 min-w-[4.5%] max-w-[4.5%] w-[4.5%] border-r'>{d?.invoice_raised_no}</h6>
                <h6 className='text-[11.5px] font-[500]  p-1 min-w-[15%] max-w-[15%] w-[15%] font-[600] border-r'>{rupee?.format(d?.invoice_amount)?.split('.')[0]}</h6>
                <h6 className='text-[11.5px] font-[500]  p-1 min-w-[13%] max-w-[13%] w-[13%] font-[600] border-r'>{rupee?.format(d?.amount_collected)?.split('.')[0]}</h6>
                <h6 className='text-[11.5px] font-[500]  p-1 min-w-[13%] max-w-[13%] w-[13%] font-[600] border-r'>{rupee?.format(d?.total_outstanding)?.split('.')[0]}</h6>
                <h6 className='text-[11.5px] font-[700] p-1 min-w-[10%] max-w-[10%]  w-[10%] border-r'>{d?.expenses !== undefined && rupee?.format(d?.expenses)?.split('.')[0]}</h6>
                <h6 className='text-[11.5px] font-[700] p-1 min-w-[10%] max-w-[10%]  w-[10%] border-r'> <span className='bg-slate-100 p-1 text-[10px] text-slate-600 px-2'>{(d?.amount_collected - d?.expenses) < 0 ? 'Loss' : 'Profit'}</span> </h6>
                <h6 className='text-[11.5px] font-[500] p-1 min-w-[10%] max-w-[10%]  w-[10%]  flex'><AiOutlineEdit size={13} onClick={()=>navigator(`/${path}/finance_dashboard_data/edit`,{state:d})} /> <AiOutlineDelete onClick={()=>deleteData(d?._id)} className='ml-2' size={13} /></h6>

            </div>
            ))}

    <div className="tex-center flex items-center justify-center mt-10 ">
    {(pagination?.total > pagination?.limit &&
        <Pagination size={'small'} page={page} className="flex justify-center my-10 pb-20" count={pagination?.totalPages} onChange={handlePagination} />
    )}
    </div>
        </div>}
    </div>
    </div> 
  )
}

export default FiananceList