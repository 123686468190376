import React,{useEffect, useState} from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import GoBack from '../../../components/back/GoBack';
import { Select } from 'antd';
import { TextInput } from '../../../components/input';
import Uploader from '../../../components/Uploader';
import { ButtonFilledAutoWidth } from '../../../components/button';
import { CreateConveyanceVochuerService, UpdateConveyanceVochuerService, UploadConveyanceVochuerService } from '../../../services/FinanceFormServices/ConveyanceVochuerServices';
import { toast } from 'react-hot-toast'
import FinanceMenu from '../FinanceMenu';
import { BiCheckbox,BiCheckboxSquare } from 'react-icons/bi'
import ProfileMenu from '../../profile/ProfileMenu';
import { GetSearchService } from '../../../services/AuthServices';
import { useSelector } from 'react-redux';


function ConveyanceVochuerCE() {
  const {state,pathname} = useLocation();
  const navigate = useNavigate();

  const roles = useSelector(state=>state.Auth.roles)

  
  const path = pathname.split('/')[1]

  const [data,setdata] = useState({bill_date:'',vehicle_name:'',starting_km:'',ending_km:'',bill_no:'',amount:'',is_verified:false,file:''});  
  const [error,seterror] = useState({bill_date:'',vehicle_name:'',starting_km:'',ending_km:'',bill_no:'',amount:'',is_verified:false,file:''}); 
  const [user,setuser] = useState([])
  
  function handlechange(e){
    setdata({...data,[e.target.name] : e.target.value})
    seterror({...error,[e.target.name]:''})
  }

  useEffect(()=>{
    if(state?._id !== undefined){

        let d = state 
        delete d.approved_by
        setdata({
            ...data,
            ...state,
            bill_date:state?.bill_date?.slice(0,10),
            created_by:{label:state?.created_by?.name,value:state?.created_by?._id}
        })
    }
  },[state])

  async function uploadfile(v,name){
    const fd = new FormData()
    fd.append('file',v); 
    const response = await UploadConveyanceVochuerService(fd)
    if(response?.status === 200){
      setdata({...data,[name]:response?.data?.data})
      seterror({...error,[name]:''})
    }
  }

  async function searchUser(v){
    const response =  await GetSearchService(v,1)
          let arr = []
          let d = response?.data?.datas
          d.forEach((d1)=>{
            arr.push({label:d1.name,value:d1?._id})
          })
          setuser(arr)
          return arr
  }

  async function submitform(){
    if(!data.bill_date){
        seterror({...error,bill_date:'This field is required *'})
    }else if(!data.vehicle_name){
      seterror({...error,mobile:'This field is required *'})
    }else if(!data.starting_km){
      seterror({...error,starting_km:'This field is required *'})
    }else if(!data.ending_km){
        seterror({...error,ending_km:'This field is required *'})
    }else if(!data.amount){
        seterror({...error,amount:'This field is required *'})
    }else{
        let sendData = {...data}

        if(data?.created_by !== ''){
           sendData['created_by'] = data?.created_by?.value !== undefined ? data?.created_by?.value : data?.created_by
        }
        if(state?._id === undefined || state?._id === null){
            const response = await CreateConveyanceVochuerService(sendData)
            if (response.status === 201) {
            resetform()
            toast.success('Conveyance Voucher Created Successfully')
            }   
            if(response.status === 422){
            if(response?.data?.errors?.title){
                seterror({...error,title:response?.data?.errors?.title})
            }
            } 
      }else{
        sendData["id"] = state?._id
        const response = await UpdateConveyanceVochuerService(sendData,state?._id)
        if (response.status === 200) {
          resetform()
          navigate(-1)
          toast.success('Conveyance Voucher Update Successfully')
        }   
        if(response.status === 422){
          if(response?.data?.errors?.title){
            seterror({...error,title:response?.data?.errors?.title})
          }
        } 
      }
    }
  }


  function resetform(){
    setdata({bill_date:'',vehicle_name:'',starting_km:'',ending_km:'',bill_no:'',amount:'',is_verified:false,file:''})
    seterror({bill_date:'',vehicle_name:'',starting_km:'',ending_km:'',bill_no:'',amount:'',is_verified:'',file:''})
  }

  return (
    <div className='flex '>
        {/* {pathname !== '/conveyance_vochuer_create' &&
        <FinanceMenu />} */}
         <div className={`min-w-44 ${path == 'profile' && 'min-w-[180px] pl-2'}`}>
              {path == 'profile' && <ProfileMenu />}
              {path == 'finance' && <FinanceMenu />}
            </div>

        <div className={`w-full md:w-72 lg:w-72 px-4 pt-5 ${pathname === '/conveyance_vochuer_create' && 'mx-auto'} `} >

           {pathname !== '/conveyance_vochuer_create' &&
            <GoBack /> }
            
            

            <h6 className='font-bold'>{(state?._id !== null && state?._id !== undefined) ? 'Edit' : 'Add'} Conveyance Voucher</h6>
            <h6 className='text-[10px] bg-slate-100 p-2 font-[500] leading-snug' >Use the below form to create or edit the <b> Conveyance Voucher</b> for your reference.</h6>
        



            {(state?._id !== null && state?._id !== undefined && roles?.includes('admin')) &&
            <>
            <h6 className='text-[11px] font-[600] mb-1 mt-2' >{"User"}</h6>

            <div className='border border-gray-300 py-[2px]'>
            <Select
                bordered={false}
                size='small'
                filterOption={false}
                showSearch={true}
                allowClear={true}
                onClear={()=>setdata({...data,created_by:''})}
                defaultValue={data?.created_by}
                value={data?.created_by}
                className='w-[100%]'
                onSearch={(e)=>searchUser(e,'user')}
                options={user}
                onChange={(v)=>setdata({...data,created_by:v})}
            />
            </div>
            </>}


            <TextInput 
                label={'Bill Date'}  
                variant="standard"
                name="bill_date"
                type="date"
                mandatory={true}
                error={error.bill_date}
                value={data.bill_date}
                handlechange={handlechange}
                placeholder="Enter your Role name"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>

            {/* <TextInput 
                label={'Added By'}  
                variant="standard"
                name="created_by"
                type="text"
                mandatory={true}
                error={error.created_by}
                value={data.created_by}
                handlechange={handlechange}
                placeholder="Enter your Role name"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/> */}

            <TextInput 
                label={'Vehicle Name'}  
                variant="standard"
                name="vehicle_name"
                mandatory={true}
                type="text"
                error={error.vehicle_name}
                value={data.vehicle_name}
                handlechange={handlechange}
                placeholder="Enter your Role name"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>

            <TextInput 
                label={'Starting KM'}  
                variant="standard"
                mandatory={true}
                name="starting_km"
                type="text"
                error={error.starting_km}
                value={data.starting_km}
                handlechange={handlechange}
                placeholder="Enter your Role name"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>

            <TextInput 
                label={'Ending KM'}  
                variant="standard"
                mandatory={true}
                name="ending_km"
                type="text"
                error={error.ending_km}
                value={data.ending_km}
                handlechange={handlechange}
                placeholder="Enter your Role name"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>

            <TextInput 
                label={'Bill No'}  
                variant="standard"
                name="bill_no"
                type="text"
                error={error.bill_no}
                value={data.bill_no}
                handlechange={handlechange}
                placeholder="Enter your Role name"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>

            <TextInput 
                label={'Amount'}  
                mandatory={true}
                variant="standard"
                name="amount"
                type="text"
                error={error.amount}
                value={data.amount}
                handlechange={handlechange}
                placeholder=""
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>

            <h6 className='text-[11px] mt-2 font-[600] mb-1' >Bill Image</h6>
            <Uploader image={data?.file}  setimagefunc={(e)=>{uploadfile(e,'file')}}  removeimageuploadfunc = {()=>setdata({...data,file:''})}/>

  
            {state?._id !== undefined && path === 'finance' &&
            <div className='flex items-center mt-0 mr-2 -ml-1 mt-1'>
                {data?.is_verified ?  <BiCheckboxSquare size={22} className='text-slate-600' onClick={()=>setdata({...data,is_verified:!data.is_verified})} />  : <BiCheckbox size={22} className='text-slate-300' onClick={()=>setdata({...data,is_verified:!data.is_verified})} /> }
                <h6 className="text-[11.5px] font-[500] ml-1">{data?.is_verified ? 'Verified' : 'Not Veified'}</h6> 
            </div>}


            <div className='mt-5'>
            <ButtonFilledAutoWidth btnName={(state?._id !== null && state?._id !== undefined) ? "UPDATE CONVEYANCE VOUCHER" : "ADD CONVEYANCE VOUCHER"}  onClick={submitform} />  
            </div>
            
        </div>
   </div> 
  )
}

export default ConveyanceVochuerCE