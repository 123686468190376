import React, { useState,useEffect } from 'react'
import { TextInput } from '../../../components/input'
import {BiErrorCircle} from 'react-icons/bi';
// import Select from 'react-select'
import { Select } from 'antd';
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../../components/button';
import { GetDepartmentService, GetUsersByDepartment } from '../../../services/DepartmentServices';
import { CreateTeamService, GetTeamServiceDetail, UpdateTeamService } from '../../../services/AdminServicesfile/TeamServices';
import { toast } from 'react-hot-toast';
import { useLocation,useNavigate } from 'react-router-dom';
import GoBack from '../../../components/back/GoBack';
import SettingsMenu from '../../staticscreens/SettingsMenu';
import { GetInsentiveActive } from '../../../services/AdminServicesfile/InsentiveServices';

function TeamAdd() {

  const {state,pathname} = useLocation() 
  const navigator = useNavigate()

  const [data,setdata] = useState({name:'',department:'',team_lead:'',users:[],team_based_period:[]})   
  const [error,seterror] = useState({name:'',department:'',team_lead:'',users:'',team_based_period:[]})   
  const [departmentArr,setdepartmentArr] = useState([])
  const [departmentEmployee,setdepartmentEmployee] = useState([])
  const [insentive,setinsentive] = useState({}) 

  useEffect(()=>{
    if(pathname.split('/')[pathname.split('/').length - 1] === 'edit' && state !== null){
      getOldDetailData()
    }else{
      getInsentiveActivefunc()
    }
    getdata()
    // console.log("okkkk amsmasmakkandkand")
  },[])

  async function getInsentiveActivefunc(){
    const response = await GetInsentiveActive()
    let d = response?.data?.datas[0]
    setinsentive(d)
    let arr = []
    let arr1 = []
    for(let i = 0;i< d?.incentive_period;i++){
      arr?.push([])
      arr1?.push('')
    }
    setdata({...data,team_based_period:arr})
    seterror({...error,team_based_period:arr1})
  }

  async function getOldDetailData(){
    const response =  await GetTeamServiceDetail(state)
    let d = response?.data?.datas[0]

    let users = []
    d?.users?.forEach((u)=>{
      users.push({label:u?.name,value:u?._id})
    })
    
    let arr = []
    let arr1 = []
    setinsentive(d?.incentive)
    if(d?.teambasedperiods !== undefined && d?.teambasedperiods?.length > 0){
      d?.teambasedperiods?.forEach((d1)=>{
        let arr1 = []
        d1?.teamBasedPeriod?.forEach((d2)=>{
            arr1.push({label:d2?.name,value:d2?._id})
        })
        arr.push(arr1)
      })
    }else{
      for(let i = 0;i< d?.incentive?.incentive_period;i++){
        arr?.push([])
        arr1?.push('')
      }
    }
    getemployeebasedondepartment(d?.department?._id)
    setdata({...data,name:d?.name,id:d?._id,team_lead:{label:d?.team_lead?.name,value:d?.team_lead?._id},users:users,department:{label:d?.department?.department_name,value:d?.department?._id},year:d?.year,team_based_period:arr})
  }


  async function getdata(){
    let department_Arr = []
    const response = await GetDepartmentService()

    response?.data?.datas?.forEach(d => {
        department_Arr.push({label:d.department_name,value:d.id})

    });
    // getemployeebasedondepartment(department_Arr[0]?.value)
    setdepartmentArr(department_Arr)
  }

  async function getemployeebasedondepartment(id){
    let department_Employee = []
    const response = await GetUsersByDepartment(id)
    response?.data?.datas?.forEach(d => {
        department_Employee.push({label:d.name,value:d._id})
    });
    setdepartmentEmployee(department_Employee)
  }

  function handlechange(e){
    setdata({...data,[e.target.name] : e.target.value})
    seterror({...error,[e.target.name]:''})
  }
  
  async function handleSelect(e,e1,e2,index){
    if(e2 === 'team_based_period'){
      let arr = []
      let oldData = [...data.team_based_period]
      let oldDataErr = [...error.team_based_period]
      e?.forEach((d)=>{
        arr.push(departmentEmployee?.find(d1 => d1?.value === d))
      })
      oldData[index] = arr
      oldDataErr[index] = ''
      setdata({...data,team_based_period:oldData})
      seterror({...error,team_based_period:oldDataErr})

    }else if(e1 === 'department'){
     setdata({...data,department:departmentArr?.find(d1 => d1?.value === e)})
     getemployeebasedondepartment(e)
     seterror({...error,department:''})
    }else  if(e1 === 'team_lead'){
     setdata({...data,team_lead:departmentEmployee?.find(d1 => d1?.value === e)})
     seterror({...error,team_lead:''})
    }else  if(e1 === 'users'){
      let arr = []
      e?.forEach((d)=>{
         arr.push(departmentEmployee?.find(d1 => d1?.value === d))
      })
      setdata({...data,users:arr})
      seterror({...error,users:''})
    }
  }

  

  async function submitdata(){
    if(!data.name){
        seterror({...error,name:'Name field is required*'})
    }else if(!data.department){
        seterror({...error,department:'Department field is required'})
    }else if(!data.team_lead){
        seterror({...error,team_lead:'Team Lead field is required'})
    }else if(!data.users){
        seterror({...error,users:'Users field is required'})
    }else{

      if(pathname.split('/')[pathname.split('/').length - 1] === 'edit' && state !== null){
        let send_data = {...data}
        send_data['department'] = data?.department?.value
        send_data['team_lead'] = data?.team_lead?.value
        send_data['year'] = data?.year
        let team_users_based_department = []
        let users = []
        data?.users?.forEach((u)=>{
            users.push(u.value)
        })
        data?.team_based_period?.forEach((d)=>{
          if(d?.length > 0){
            let arr1 = []
             d?.forEach((d1)=>{
            //  team_users_based_department.push({period:i,user:d1.value})
              arr1.push(d1.value)
             })
             team_users_based_department.push(arr1)
          }else{
            team_users_based_department.push([])
          }
        })
        send_data['users'] = users
        send_data['teamBasedPeriod'] = team_users_based_department

        // console.log("send_data",send_data)

        const response = await UpdateTeamService(send_data,data?.id)
        if(response.status === 200){
            toast.success("Team Updated Successfully")
            navigator(-1)
            resetform()
        }

        if(response.status === 422){
          if(response?.data?.message){
            toast.error(response?.data?.message)
          }

            if(response?.data?.errors !== undefined && response?.data?.errors['fidelitus.teams index'] !== undefined){
                seterror({...error,team_lead:response?.data?.errors['fidelitus.teams index']})
            }

           
        }
      }else{
        let send_data = {...data}
        send_data['department'] = data?.department?.value
        send_data['team_lead'] = data?.team_lead?.value
        send_data['year'] = JSON.stringify(new Date().getFullYear())+"-"+JSON.stringify(new Date().getFullYear()+1)
        let team_users_based_department = []
        let users = []
        data?.users?.forEach((u)=>{
            users.push(u.value)
        })
        data?.team_based_period?.forEach((d,i)=>{
          if(d?.length > 0){
            let arr1 = []
             d?.forEach((d1)=>{
            //  team_users_based_department.push({period:i,user:d1.value})
              arr1.push(d1.value)
             })
            team_users_based_department.push(arr1)

          }else{
            team_users_based_department.push([])
          }
        })
        send_data['users'] = users
        send_data['teamBasedPeriod'] = team_users_based_department

        console.log("send_data",send_data)
        const response = await CreateTeamService(send_data)
        if(response.status === 201){
            toast.success("Team Created Successfully")
            resetform()
        }

        if(response.status === 422){
            if(response?.data?.message){
              toast.error(response?.data?.message)
            }

            if(response?.data?.errors !== undefined &&  response?.data?.errors['fidelitus.teams index'] !== undefined){
                seterror({...error,team_lead:response?.data?.errors['fidelitus.teams index']})
            }
        }
      }
    }
  }

  function resetform(){
    let arr = []
    let arr1 = []
    for(let i =0;i< insentive?.incentive_period;i++){
      arr?.push([])
      arr1.push('')
    }
    setdata({...data,team_based_period:arr})
    setdata({name:'',department:'',team_lead:'',users:[],team_based_period:arr})
    seterror({name:'',department:'',team_lead:'',users:'',team_based_period:arr1})
  }

  return (
    <div>
       <div className='flex '>
        <SettingsMenu />  
        <div>
        <div className='w-60 ml-4 mt-5'>
         <GoBack /> 
        <h6 className='font-[700] mb-1'>Create/Edit Team</h6>
        <h6 className='text-[12px] bg-slate-100 p-1 font-[500]'>Use the below form to create a team inside the vertical</h6>

        <TextInput 
         label={'Name'}  
         variant="standard"
         name="name"
         type="text"
         error={error.name}
         value={data.name}
         handlechange={handlechange}
         placeholder="Enter your Team name"
         />

        <TextInput 
         label={'Year'}  
         variant="standard"
         name="year"
         type="text"
         error={error.year}
         value={data.year}
         handlechange={handlechange}
         placeholder="Enter your Year"
         />

        <h6 className='text-[12px] font-semibold mb-1 mt-1'>Department </h6>
        <div className='border border-slate-300'>
        <Select
           value={data.department} 
           error={error.department}
           bordered={false}
           placeholder="" 
           onChange={(e)=>handleSelect(e,'department')} 
           className='w-full rounded-[0px]'
           options={departmentArr} 
           
           />
          </div> 
         {error.department !== '' && error.department !== undefined &&
         <div className='flex items-center mt-1'>
         <BiErrorCircle className='text-red-500' size={14} />
         <span className='text-[10px] text-red-500 ml-1'>{error.department}</span>
         </div>}


         <h6 className='text-[12px] font-semibold mb-1 mt-1'>Team Lead </h6>
        <div className='border border-slate-300'>
         <Select
           value={data.team_lead} 
           error={error.team_lead}
           bordered={false}
           placeholder="" 
           onChange={(e)=>handleSelect(e,'team_lead')} 
           className='w-full rounded-[0px]' 
           options={departmentEmployee} 
           />
          </div> 
         {error.team_lead !== '' && error.team_lead !== undefined &&
         <div className='flex items-center mt-1'>
         <BiErrorCircle className='text-red-500' size={14} />
         <span className='text-[10px] text-red-500 ml-1'>{error.team_lead}</span>
         </div>}

         <h6 className='text-[12px] font-semibold mb-1 mt-1'>Team Members </h6>
            <div className='border border-slate-300'>
            
            <Select
              mode='multiple'
              bordered={false}
              value={data.users} 
              error={error.users}
              placeholder="" 
              onChange={(e)=>handleSelect(e,'users')} 
              className='w-full rounded-[0px]' 
              options={departmentEmployee} 
              />
            </div>  
         {error.users !== '' && error.users !== undefined &&
         <div className='flex items-center mt-1'>
         <BiErrorCircle className='text-red-500' size={14} />
         <span className='text-[10px] text-red-500 ml-1'>{error.users}</span>
         </div>}
        </div>

        <div className='ml-4'>
            <h6 className='text-[12px] font-semibold mb-1 mt-1'>Team Members Based Period {data?.team_based_period?.length}</h6>
            <div className='flex'>
            {data?.team_based_period?.map((d,i)=>(
              <div className='border mr-2 w-[150px] border-slate-300'>
              <Select
                mode='multiple'
                bordered={false}
                value={data.team_based_period[i]} 
                error={error.team_based_period[i]}
                placeholder="" 
                onChange={(e)=>handleSelect(e,'users',"team_based_period",i)} 
                className='w-full rounded-[0px]' 
                options={departmentEmployee} 
                />
              </div> 
                ))}
              </div>
        </div>
        
        <div>
         <div className='flex ml-4 my-2'>
            <ButtonOutlinedAutoWidth btnName={'Back'} onClick={()=>{navigator(-1)}} />
            <h6 style={{width:'8px'}}></h6>
            <ButtonFilledAutoWidth btnName={'Save'} onClick={submitdata} />
         </div>

        </div> 
        </div>
        </div>
    </div>
  )
}

export default TeamAdd