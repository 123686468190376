import React, { useEffect, useState } from 'react'
import FcaresMenu from '../FcaresMenu'
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../../components/button'
import { Modal } from 'antd'
import {IoClose} from 'react-icons/io5'
import { Tooltip } from '@mui/material'
import { TextInput } from '../../../components/input'
import { UploadDocsFcaresPropertyService } from '../../../services/Fcares/FCaresPropertyServices'
import { CreateFcaresPropertyAttachmentService, DeleteFcaresPropertyAttachmentService, GetFcaresPropertyAttachmentService, UpdateFcaresPropertyAttachmentService } from '../../../services/Fcares/FCaresPropertyAttachmentServices'
import toast from 'react-hot-toast'
import { BiErrorCircle } from 'react-icons/bi'
import { AiOutlineEdit,AiOutlineDelete } from 'react-icons/ai'
import moment from 'moment'
import { useLocation } from 'react-router-dom'
import {BiCheckbox,BiCheckboxSquare} from 'react-icons/bi'

function FCaresPropertyListAttachment() {

  const [modal,setmodal] = useState(false)  
  const [deletemodal,setdeletemodal] = useState(false)  
  const [singleData,setsingleData] = useState({name:'',image:'',visible:false,sort:0})  
  const [error,seterror] = useState({name:'',image:'',sort:''})  
  const [data,setdata] = useState({pagination:{total:0}})
  const [page,setpage] = useState(1)

  const {state} = useLocation()

//   console.log("state",state)

  function handlechange(e){
    setsingleData({...singleData,[e.target.name]:e.target.value})
    seterror({...error,[e.target.name]:''})
  }

  useEffect(()=>{
    getData()
  },[])

  async function getData(){
    const response = await GetFcaresPropertyAttachmentService(state?._id,page)
    setdata(response?.data?.data)
  }

  async function addattachment(){
    if(!singleData?.name){
        seterror({...error,name:'This Field is required *'})
    }else if(!singleData?.image){
        seterror({...error,image:'This Field is required *'})
    }else if(!singleData?.sort){
        seterror({...error,sort:'This Field is required *'})
    }else{
        if(singleData?._id !== undefined){
            singleData['property'] = state?._id
            const response = await UpdateFcaresPropertyAttachmentService(singleData?._id,singleData)
            if(response?.status === 200){
                toast.success("Attachment Updated Successfully")
                setsingleData({name:'',image:'',visible:false,sort:0})
                seterror({name:'',image:'',sort:''})
                setmodal(!modal)
                getData()
            }

        }else{
            singleData['property'] = state?._id
            const response = await CreateFcaresPropertyAttachmentService(singleData)
            if(response?.status === 201){
                toast.success("Attachment Created Successfully")
                setsingleData({name:'',image:'',visible:false,sort:0})
                seterror({name:'',image:'',sort:''})
                setmodal(!modal)
                getData()
            }
        }
    }
  }

  async function deleteimagefunc(){
    const response = await DeleteFcaresPropertyAttachmentService(singleData?._id)
    if(response?.status === 200){
        toast.success("Attachment Deleted Successfully")
        getData()
        setdeletemodal(!deletemodal)
    }
  }

  async function uploadfilefunc(v,name){

    const fd = new FormData()
    fd.append('image',v); 
    const response = await UploadDocsFcaresPropertyService(fd)
    if(response?.status === 200){
     setsingleData({...singleData,[name]:response?.data?.data})
     seterror({...error,image:''})
     
    }
  }

  return (
    <div className='h-screen max-h-screen box-border overflow-hidden'>
        <Modal open={modal} footer={false} width={300} closable={false}>
            <div>
                <h6 className='font-[800]'>Add/Edit Attachment</h6>
                <h6 className='text-[11px] bg-slate-100 capitalize p-[4px] leading-[15px]'>Use the below form to create or update the attachments for the property</h6>
                <TextInput 
                 mandatory={true}
                 label={'Name'}  
                 variant="standard"
                 name="name"
                 type="text"
                 value={singleData.name}
                 error={error.name}
                 handlechange={handlechange}
                 placeholder="Enter contact name"
                 InputLabelProps={{
                     style: { color: '#fff', }, 
                 }}
                />

                <h6 className='text-[11px] font-[600] mb-1' >Image </h6>
                {(singleData.image === '' || singleData.image == null) ?
                    <form onClick={()=>document.querySelector(`.input-field`).click()} className={`p-4 flex flex-col items-center justify-center cursor-pointer border border-slate-300 `}>
                        <input type='file' onChange={({target:{files}})=>{
                        files[0] && uploadfilefunc(files[0],'image')
                        }} accept="*" className='input-field' hidden />
                    </form> 
                :
                    <div className='p-2 border border-slate-300 relative flex flex-col  cursor-pointer'>
                        <Tooltip title='Delete'><IoClose className='absolute top-3 right-2' onClick={()=>setsingleData({...singleData,image:''})}/></Tooltip>
                        <h6 className='text-[12px] truncate w-48 ml-0'>{singleData.image}</h6>
                    </div>
                } 
                

                {error?.image !== '' &&
                <div className='flex items-center mt-1'>
                    <BiErrorCircle className='text-red-500' size={14} />
                    <span className='text-[10px] text-red-500 ml-1'>{error?.image}</span>
                </div>}

                <TextInput 
                    mandatory={true}
                    label={'Sort'}  
                    variant="standard"
                    name="sort"
                    type="number"
                    value={singleData.sort}
                    error={error.sort}
                    handlechange={handlechange}
                    placeholder="Enter contact name"
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}
                    />


<div className='flex items-center mt-0 mr-2 -ml-1 mt-1'>
                {singleData?.visible ?  <BiCheckboxSquare size={22} className='text-slate-600' onClick={()=>setsingleData({...singleData,visible:!singleData.visible})} />  : <BiCheckbox size={22} className='text-slate-300' onClick={()=>setsingleData({...singleData,visible:!singleData.visible})} /> }
                <h6 className="text-[11.5px] font-[500] ml-1">Visible</h6> 
            </div>


                  <div className='flex mt-4 border-t pt-2'>
                    <div className='mr-1'>
                    <ButtonOutlinedAutoWidth btnName="Cancel"  onClick={()=>setmodal(false)}/>
                    </div>
                    <ButtonFilledAutoWidth btnName="Save"  onClick={addattachment}/>
                </div>     
            </div>        
        </Modal>

         <Modal open={deletemodal} footer={false} width={300} closable={false}>
            <div>
                <h6 className='font-[800]'>Delete Attachment</h6>
                <h6 className='text-[11px] bg-slate-100 capitalize p-[4px] leading-[15px]'>Are you sure want to delete the selected attachments for the property</h6>
                
                <h6 className='text-[11px]'>Name : <span className='font-[700]'>{singleData?.name}</span> </h6>
                <h6 className='text-[11px]'>Attachment : {singleData?.image}</h6>



                  <div className='flex mt-4 border-t pt-2'>
                    <div className='mr-1'>
                    <ButtonOutlinedAutoWidth btnName="Cancel"  onClick={()=>setdeletemodal(false)}/>
                    </div>
                    <ButtonFilledAutoWidth btnName="Save"  onClick={deleteimagefunc}/>
                </div>     
            </div>        
        </Modal> 

        <div className='flex'>
            <div className='min-w-44'>
            <FcaresMenu />
            </div>
            <div className='w-[88%] px-5 pt-5'>

            <div className='flex items-center justify-between border-b pb-2 p'>
              <h6 className='font-[700] text-[14px] '>Total Attachment ({data?.pagination?.total})</h6>
              <div className='flex items-center'>
                {/* <div className='flex items-center text-[12px] mr-2'>

                <h6 className='mr-2 font-[600]'>{page == 1 ? data?.datas?.length > 0 ? 1 : 0 :  (page - 1) * data?.pagination?.limit } - {data?.pagination?.limit} of {data?.pagination?.total} </h6>
                <IconButton onClick={resetfunc}><BsArrowRepeat size={16} /></IconButton>

                <div>
                <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>
                <IconButton onClick={()=>{page < data?.pagination?.totalPages  ? setpage(page+1) : console.log('')}}><FiChevronRight className={`${(data?.pagination?.totalPages === page || data?.datas?.length === 0)  ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>

                </div>
                </div> */}
               
                <div className='ml-2'>
                <ButtonFilledAutoWidth btnName="Add Data" onClick={()=>{setmodal(true)} }/> 
                </div>
              </div>
            </div>

            <div>
                <div className='grid grid-cols-5 gap-2'>
                    {data?.datas?.map((d)=>(
                    <div className='border relative mt-2 p-[10px]'>
                        <div className='flex absolute cursor-pointer top-0 right-0 border-l border-b'>
                        <AiOutlineDelete onClick={()=>{setsingleData(d);setdeletemodal(!deletemodal)}} className='border-r p-[2px]' />
                        <AiOutlineEdit onClick={()=>{setsingleData(d);setmodal(!modal)}}  className='p-[2px]' />
                        </div>
                        <h6 className='text-[11px]'>Name: <span className='font-[700]'> {d?.name}</span> <span className='bg-slate-700 text-[8px] px-[10px] py-[5px] rounded text-white font-[700]'>{d?.visible ? 'Visible' : 'Not VVisible'}</span></h6>
                        <h6 className='-mt-1'><a  href={`${process.env.REACT_APP_BACKEND_IMAGE_URL}${d?.image}`} target='_blank' className='text-[11px] underline -mt-2 font-[700] text-slate-800 cursor_pointer'>View Attachment  <span className='hidden text-[8px] font-[700] leading-[10px] text-blue-400 underline'> {d?.image?.split('/')[d?.image?.split('/')?.length - 1]}</span></a></h6>

                        <h6 className='bg-slate-100 mt-1 text-[11px] p-[5px]'>Created At : {moment(d?.createdAt)?.format('LLL')}</h6>  

                    </div>))}

                </div>
            </div>

            </div>
        </div>
    </div>
  )
}

export default FCaresPropertyListAttachment