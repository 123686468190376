import React,{useState,useEffect} from 'react'
import Grid from '@mui/material/Grid';
import { TextAreaInput1, TextInput } from '../../../components/input';
import { ButtonFilled, ButtonOutlined } from '../../../components/button';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import {Select } from 'antd';
import GoBack from '../../../components/back/GoBack';
// import DailyTaskCE from './dailyTask/DailyTaskCE';
import FcaresMenu from '../FcaresMenu';
import { BiCheckbox,BiCheckboxSquare } from "react-icons/bi";
import { CreateFcaresTicketService, GetFcaresTicketByIdService, UpdateFcaresTicketService } from '../../../services/Fcares/FCaresTicketService';
import { Tooltip } from '@mui/material'
import { IoClose } from 'react-icons/io5'
import { GetFcaresPropertyService, UploadDocsFcaresPropertyService } from '../../../services/Fcares/FCaresPropertyServices';
import { GetFcaresService } from '../../../services/Fcares/FCaresServices';
import { GetFcaresQueryByIdService, UpdateFcaresQueryService, UploadDocsFcaresQueryService } from '../../../services/Fcares/FCaresQueryService';

function FCaresQueryCE() {



  const [data,setdata] = useState({
    service:'',
    other:'',
    user:'',
    remarks:'',
    attachment:'',
    summary:'',
    attachment1:'',
    send_notification:false,
    resolved:false
  })

  

  
 
  const [loader, setloader] = useState(false);

  const status = [{label:'Pending',value:'Pending'},{label:'Discussion',value:'Discussion'},{label:'In Progress',value:'In Progress'},{label:'Resolved',value:'Resolved'}]
  const [services,setservices] = useState([])

  const [error,seterror] = useState({
    service:'',
    other:'',
    user:'',
    remarks:'',
    attachment:'',
    summary:'',
    attachment1:'',
    resolved:''
  })

  const navigate = useNavigate()
  const location = useLocation()




  async function uploadfilefunc(v,name){

    const fd = new FormData()
    fd.append('image',v); 
    const response = await UploadDocsFcaresPropertyService(fd)
    if(response?.status === 200){
    setdata({...data,[name]:response?.data?.data})
    }
  }

  useEffect(()=>{
      getservices()
  },[])

  async function getservices(){
    const response = await GetFcaresService('','','',1)
    console.log(response?.data)
    let arr = []
    response?.data?.data?.datas?.forEach((d)=>{
      arr.push({label:d?.name,value:d?._id})
    })
    arr.push({label:'Other',value:'Other'})
    setservices(arr)
  }

  useEffect(()=>{
    if(location?.pathname?.split('/')[location?.pathname?.split('/').length -1] === 'edit'){
      geteditdata()
    }
  },[location?.pathname])

  async function geteditdata(){
    const response =  await GetFcaresQueryByIdService(location?.state);
    let d = response?.data?.data

    let sendData = {
      service:(d?.service === null || d?.service === undefined) ? null : {label:d?.service?.name,value:d?.service?._id},
      other:d?.other,
      remarks:d?.remarks,
      attachment:d?.attachment,
      summary:d?.summary,
      attachment1:d?.attachment1 !== undefined ? d?.attachment1 : ''  ,
      resolved:d?.resolved,
      _id:d?._id
    }
  
    setdata(sendData)
  }

  // console.log("data?.operators",data?.operators)
  function handlechange(e){
    setdata({...data,[e.target.name] : e.target.value})
    seterror({...error,[e.target.name] : ''})
  }


  async function submitform(){
   
        const sendData = {...data}
        if(data?.service?.value !== 'Other'){
          sendData['service'] = data?.service?.value
        }else{
          delete sendData.service
          sendData['other'] = data?.other
        }
      


        

          setloader(true)
          const response = await UpdateFcaresQueryService(sendData?._id,sendData)
          if(response.status === 200){
            setloader(false)
            // console.log("Anna data?.stage?.label",data?.stage?.label,"stage?.selected_stage?.label",stage?.selected_stage?.label)
            setloader(false)
            toast.success('Query Updated Successfully')
            resetform()
            navigate(-1)
          }
  }

  function resetform(){
     setdata({
      service:'',
      other:'',
      user:'',
      remarks:'',
      attachment:'',
      summary:'',
      attachment1:'',
      send_notification:false,
      resolved:false
      })
      seterror({
        service:'',
        other:'',
        user:'',
        remarks:'',
        attachment:'',
        summary:'',
        attachment1:'',
        send_notification:'',
        resolved:''
      })
  }


  async function setservicefunc(v){
     let serviceFind = services?.find((f)=>f?.value === v)
     if(serviceFind !== null){
       setdata({...data,service:serviceFind})
     }
  }

  async function uploadfilefunc(v,name){

    const fd = new FormData()
    fd.append('image',v); 
    const response = await UploadDocsFcaresQueryService(fd)
    if(response?.status === 200){
    setdata({...data,[name]:response?.data?.data})
    }
  }

  return (
    <div className='h-screen max-h-screen box-border overflow-hidden'>
    <div className='block sm:flex'>
        <FcaresMenu />
    <div className='pr-5 ml-5 min-h-screen max-h-screen h-screen overflow-y-scroll' >
    

  

    <div className='w-64 '>
      <GoBack />
     <div className='border-b  pb-2'>
      <h6 className='font-[800] mb-1'>{location?.pathname?.split('/')[location?.pathname?.split('/').length -1] === 'edit' ? 'Update' : 'Update'} Query </h6> 
      <h6 className='text-[11px] leading-tight font-[500] p-2 bg-slate-100 '>Use the below form to update the query status for the fcares services.</h6> 
     </div> 
      <Grid container spacing={2}  >
        <Grid item xs={12}  md={12} >

        <h6 className='text-[12px] font-semibold mb-1 mt-1'>Services*</h6>
        <Select 
        value={data?.service}
        bordered={false}
        onChange={(e)=>{setservicefunc(e)}} 
        options={services}
        className=' w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none'
        />


          {/* {data?.service?.value === 'Other' && */}
          <TextInput 
              label={'Other'}  
              variant="standard"
              name="other"
              type="text"
              value={data.other}
              error={error.other}
              handlechange={handlechange}
              placeholder=""
              InputLabelProps={{
                  style: { color: '#fff', }, 
              }}/>
              {/* } */}

      
       


        <TextAreaInput1 
            label={'Summary'}  
            variant="standard"
            name="remarks"
            type="text"
            value={data.remarks}
            error={error.remarks}
            handlechange={handlechange}
            placeholder=""
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>
       


      <h6 className='text-[11px] font-[600] mb-1 mt-2' >Query Attachement</h6>
        {(data.attachment === '' || data.attachment == null) ?
            <form onClick={()=>document.querySelector(`.input-field6`).click()} className={`p-4 flex flex-col items-center justify-center cursor-pointer border border-slate-300 `}>
                <input type='file' onChange={({target:{files}})=>{
                  files[0] && uploadfilefunc(files[0],'attachment')
                }} accept="*" className='input-field6' hidden />
            </form> 
          :
              <div className='p-2 border border-slate-300 relative flex flex-col  cursor-pointer'>
                  <Tooltip title='Delete'><IoClose className='absolute top-3 right-2' onClick={()=>setdata({...data,attachment:''})}/></Tooltip>
                  <h6 className='text-[12px] truncate w-48 ml-0'>{data?.attachment}</h6>
              </div>
          }

      
           

          <TextAreaInput1 
              mandatory={true}
              label={'Summary'}  
              variant="standard"
              name="summary"
              type="text"
              value={data.summary}
              error={error.summary}
              handlechange={handlechange}
              placeholder=""
              InputLabelProps={{
                  style: { color: '#fff', }, 
              }}/>


      <h6 className='text-[11px] font-[600] mb-1' >Resolve Attachment</h6>
        {(data.attachment1 === '' || data.attachment1 == null) ?
            <form onClick={()=>document.querySelector(`.input-field6`).click()} className={`p-4 flex flex-col items-center justify-center cursor-pointer border border-slate-300 `}>
                <input type='file' onChange={({target:{files}})=>{
                  files[0] && uploadfilefunc(files[0],'attachment1')
                }} accept="*" className='input-field6' hidden />
            </form> 
          :
              <div className='p-2 border border-slate-300 relative flex flex-col  cursor-pointer'>
                  <Tooltip title='Delete'><IoClose className='absolute top-3 right-2' onClick={()=>setdata({...data,attachment1:''})}/></Tooltip>
                  <h6 className='text-[12px] truncate w-48 ml-0'>{data?.attachment1}</h6>
              </div>
          }

          

        <h6 className='text-[12px] font-semibold mb-1 mt-2'>Status*</h6>

        <div className='flex items-center'>
        <div className='flex cusror-pointer mr-2' onClick={()=>setdata({...data,resolved:!data?.resolved})}>
            {!data?.resolved  ? <BiCheckbox className='text-slate-300' /> : <BiCheckboxSquare className='text-slate-600' />}
            <h6 className='text-[10px] ml-[3px] font-[700]'>{data?.resolved ? 'Completed' : 'Pending'}</h6>
        </div>


        </div>

        <h6 className='text-[12px] font-semibold mb-1 mt-2'>Send Notification*</h6>

      <div className='flex items-center'>
      <div className='flex cusror-pointer mr-2' onClick={()=>setdata({...data,send_notification:!data?.send_notification})}>
          {!data?.send_notification  ? <BiCheckbox className='text-slate-300' /> : <BiCheckboxSquare className='text-slate-600' />}
      </div>


      </div>

       
            
        </Grid>
       
      </Grid>

   
   <div >
    <div className='flex items-center mt-5 mb-10  border-t pt-5'>
        <div className='mr-2'>
        <ButtonOutlined btnName={'Back'} onClick={()=>navigate(-1)} />
        </div>
        <div>
        {loader ?   
        <ButtonFilled btnName={loader ? 'Loading' : 'Save'} /> :
        <ButtonFilled btnName={loader ? 'Loading' : 'Save'} onClick={()=>submitform(null)} /> }
        </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
   
  )
}

export default FCaresQueryCE