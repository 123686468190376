import React, { useEffect } from 'react';
import {RiUser6Line,RiWalkLine} from 'react-icons/ri';
import {useLocation,useNavigate } from 'react-router-dom';
import {CiViewBoard} from 'react-icons/ci';
import {BiFoodMenu} from 'react-icons/bi';
import {TbScreenShare} from 'react-icons/tb';
import { MdOutlineDoorFront } from "react-icons/md";
import { useSelector } from 'react-redux';
function TaskMenu() {

  const roles = useSelector(state=>state.Auth.roles)

  const {pathname} = useLocation();
  const path = pathname.split('/')[pathname.split('/').length - 1]


 

  const navigate = useNavigate();

  useEffect(()=>{
       if(path === 'tasks'){
        navigate('/tasks/daily_tasks/list')
       }
  },[])

  const menu1 = [
    {name:'Daily Tasks',icon:BiFoodMenu,path:`/tasks/daily_tasks/list`,id:1,color:''},
    {name:'Project Work',icon:RiUser6Line,path:`/tasks/project_work/list`,id:2,color:''},
    {name:'External Work',icon:RiWalkLine,path:`/tasks/external_work/list`,id:3,color:''},
    {name:'Assign Work',icon:TbScreenShare,path:`/tasks/assigned_work/list`,id:4,color:''},
    // {name:'My Projects',icon:CiViewBoard,path:`/tasks/projects/list`,id:5,color:''},
  ] 

  const menu2 = [
    {name:'M-O-M Work',icon:TbScreenShare,path:`/tasks/mom_tasks/lists`,id:1,color:''},
  ]  

  const menu3 = [
    {name:'MD Meet',icon:MdOutlineDoorFront,path:`/tasks/md_meet/lists`,id:1,color:''},
  ]  

  
  
  return (
    <div className='mr-0 min-h-screen max-h-sceen border-r min-w-44  w-44 max-w-44  px-2' >

       

        <div className={` w-full pt-4 `}>
            <h6 className=' ml-2 mb-2 font-[700] text-[12px]'>Today Plans </h6>
            {menu1.map((m)=>(
              <>                 
                <div key={m.path} className={`flex items-center my-1 hover:bg-[#f4f5f7] px-1.5 py-1 ml-2 rounded-md relative cursor-pointer ${pathname ===  m.path && 'bg-[#f4f5f7]'}`} onClick={()=>navigate(m?.path)}>
                  {pathname ===  m.path &&  <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3'></span>}
                  <m.icon color='#000' style={{backgroundColor:m?.color,padding:'2px'}} size={16} />
                 <span className='font-[500] ml-2 text-[12px]'>{m.name}</span>
                </div>   
              </>   
            ))}

            <div className='border-t pt-4 mt-4'>
            <h6 className=' ml-2 mb-2 font-[700] text-[12px]'>MOM Info </h6>
            {menu2.map((m)=>(
              <>                 
                <div key={m.path} className={`flex items-center my-1 hover:bg-[#f4f5f7] px-1.5 py-1 ml-2 rounded-md relative cursor-pointer ${pathname ===  m.path && 'bg-[#f4f5f7]'}`} onClick={()=>navigate(m?.path)}>
                  {pathname ===  m.path &&  <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3'></span>}
                  <m.icon color='#000' style={{backgroundColor:m?.color,padding:'2px'}} size={16} />
                 <span className='font-[500] ml-2 text-[12px]'>{m.name}</span>
                </div>   
              </>   
            ))}
            </div>

          {(roles?.includes('admin') || roles?.includes('md_task')) &&
            <div className='border-t pt-4 mt-4'>
            <h6 className=' ml-2 mb-2 font-[700] text-[12px]'>Corp Team </h6>
            {menu3.map((m)=>(
              <>                 
                <div key={m.path} className={`flex items-center my-1 hover:bg-[#f4f5f7] px-1.5 py-1 ml-2 rounded-md relative cursor-pointer ${pathname ===  m.path && 'bg-[#f4f5f7]'}`} onClick={()=>navigate(m?.path)}>
                  {pathname ===  m.path &&  <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3'></span>}
                  <m.icon color='#000' style={{backgroundColor:m?.color,padding:'2px'}} size={16} />
                 <span className='font-[500] ml-2 text-[12px]'>{m.name}</span>
                </div>   
              </>   
            ))}
            </div>}

        </div>
    </div>
  )
}

export default TaskMenu