import React,{useEffect, useState} from 'react';
import {  TextAreaInput1, TextInput1 } from '../../components/input';
import { ButtonFilledAutoWidth } from '../../components/button';
import ProfileMenu from './ProfileMenu';
import { Avatar } from '@mui/material';
import {SlPencil} from 'react-icons/sl';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {  UploadProfiledata, getMyProfile } from '../../services/AuthServices';
import { toast } from 'react-hot-toast';
import {BiCheckbox,BiCheckboxSquare} from 'react-icons/bi'
import { UploadUserImageService } from '../../services/OnboardServices';

function Mainprofile() {


   const [data,setdata] = useState({name:'',phone:'',email:'',dob:'',doj:'',official_email:'',official_phone:'',bio:'',profile_photo:''})  
   const [error,seterror] = useState({name:'',phone:'',email:'',dob:'',doj:'',official_email:'',official_phone:'',bio:''})  

   const auth = useSelector(state=>state.Auth)
   const {pathname} = useLocation()
   const navigator = useNavigate()

   const path = pathname?.split('/')[2]


   useEffect(()=>{
   
        if(path === undefined){
            navigator('/profile/basic')
        }
        getprofile()
   },[])

   const handlechange = (e) =>{
        setdata({...data,[e.target.name]:e.target.value})
        seterror({...error,[e.target.name]:''})
   }

   async function getprofile(){
    const response = await getMyProfile(auth.id)
    let d = response?.data?.data[0]
    setdata({name:d?.name,phone:d?.phone,email:d?.email,dob:d?.dob,doj:d?.doj,official_email:d?.official_email,official_phone:d?.official_phone,bio:d?.bio === undefined ? '' : d?.bio,employee_id:d?.employee_id,app_access:d?.app_access,profile_photo:d?.profile_photo})
   }

   async function submitForm(){
       if(!data?.name){
           seterror({...error,name:'The name field is required'})
       }else if(!data?.email){
           seterror({...error,email:'The email field is required'})
       }else if(!data?.phone && data?.phone?.length !== 10){
          seterror({...error,phone:'Enter a valid 10 digit mobile no'})
       }else{
        const fd  = new FormData()
        fd.append('name',data.name)
        fd.append('email',data.email)
        fd.append('phone',data.phone)
        fd.append('bio',data.bio)
        fd.append('profile_photo',data?.profile_photo)

        if(data?.profile_photo?.name !== undefined){    
            fd.append('image',data?.profile_photo)
        }
        const response = await UploadProfiledata(fd,auth?.id)
        if(response.status === 200){
            toast.success('Profile updated successfully')
        }
     }
   }

   async function uploadfile(v,name){
    const fd = new FormData()
    fd.append('file',v); 
    const response = await UploadUserImageService(fd)
    if(response?.status === 200){
      setdata({...data,[name]:response?.data?.data})
      seterror({...error,[name]:''})
    }
  }


  console.log("data",data)

  return (
    <div className='px-2 mx-0 box-border max-h-screen min-h-screen h-screen overflow-hidden   '>

        <div className='block sm:flex max-h-screen min-h-screen h-screen overflow-hidden'>
        <ProfileMenu />         
        <div className='w-full max-h-screen min-h-screen h-screen overflow-y-scroll'>
            <div className='ml-6  mb-4  sm:w-8/12 ml-4 mt-4 pt-5'>
            <h6 className='border-b mt-1 pb-2 mb-4 font-[700] text-[17px] border-[#d0d7de]'>Public Profile</h6>


            <div className='md:flex'>    
                <div className='mr-0  md:w-2/5 mr-16'>
                <div className='block sm:flex'>

                <div className='mr-0 sm:mr-4'>
                <TextInput1 
                    label={'Name'}  
                    variant="standard"
                    name="name"
                    type="name"
                    readOnly={false}
                    error={error.name}
                    value={data.name}
                    handlechange={handlechange}
                    placeholder="Enter your password"
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}/> 
                <h6 className='text-[11.5px] mt-1 font-[400] text-[#646d76] pb-0 leading-tight' >Your name may appear around Fidelitus where you contribute or are mentioned. You can rename it at any time.</h6> 
                </div> 

                <div>
                <TextInput1 
                    label={'Employee Id'}  
                    variant="standard"
                    name="employee_id"
                    type="employee_id"
                    readOnly={true}
                    error={error.employee_id}
                    value={data.employee_id}
                    handlechange={handlechange}
                    placeholder="Enter your employee_id"
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}/> 
                <h6 className='text-[11.5px] mt-1 font-[400] text-[#646d76] pb-0 leading-tight' >Your employee id has been created during onboarding you don't have any access to update it.</h6> 
                </div> 


                </div>

                <TextInput1 
                label={'Official Email'}  
                variant="standard"
                name="official_email"
                type="official_email"
                readOnly={true}
                error={error.official_email}
                value={data.official_email}
                handlechange={handlechange}
                placeholder="Enter your official_email"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/> 
                <span className='text-[11.5px] font-[400] text-[#646d76] pb-0 leading-none'>Your official email id created by it team for your work purpose.</span> 

                <TextInput1 
                label={'Official Phone'}  
                name="official_phone"
                type="official_phone"
                readOnly={true}
                error={error.official_phone}
                value={data.official_phone}
                handlechange={handlechange}
                placeholder="Enter your official_phone"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/> 


                <h6 className='border-b pb-2 mb-4 mt-4 font-[400] text-[14px]'>More Info</h6>




                <TextInput1 
                label={'Personal Email'}  
                variant="standard"
                name="email"
                type="email"
                error={error.email}
                value={data.email}
                
                handlechange={handlechange}
                placeholder="Enter your email"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/> 



                <TextInput1 
                    label={'Personal Phone'}  
                    variant="standard"
                    name="phone"
                    type="phone"
                    error={error.phone}
                    value={data.phone}
                    handlechange={handlechange}
                    placeholder="Enter your phone"
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}/> 

                <TextInput1 
                    label={'Joining Date'}  
                    variant="standard"
                    name="doj"
                    type="doj"
                    readOnly={true}
                    error={error.doj}
                    value={data.doj}
                    handlechange={handlechange}
                    placeholder="Enter your doj"
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}/> 

                <TextInput1 
                label={'Date of Birth'}  
                variant="standard"
                name="dob"
                type="text"
                readOnly={true}
                error={error.dob}
                value={data.dob}
                handlechange={handlechange}
                placeholder="Enter your dob"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/> 

                <TextAreaInput1 
                    label={'Bio'}  
                    variant="standard"
                    name="bio"
                    type="bio"
                    error={error?.bio}
                    value={data?.bio === undefined ? '' : data?.bio}
                    handlechange={handlechange}
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                }}/> 
                <span className='text-[11.5px] font-[400] text-[#646d76] pb-0 leading-none'>Your can <span className='font-[500]'>@mention</span>  other users and organization to link to them.</span> 


                <div className='mt-2 flex '>
                {!data?.app_access ? <BiCheckbox className='text-slate-300' size={40} /> :
                    <BiCheckboxSquare className='text-slate-600' size={40} />}
                {/* <input type="checkbox" readOnly={true} checked={data?.app_access} className='-mt-5' />  */}
                <div className='ml-2 mt-2'>
                <h6 className='font-[600]'>App Access</h6>
                <h6 className='text-[11.5px] font-[400] text-[#646d76] pb-0 leading-tight'>The above switch declare whether you have access to the application or not you dont have any access to update it.</h6>
                </div>

                </div>

                
                <div className='mt-5'>
                    <ButtonFilledAutoWidth btnName="Update profile" onClick={submitForm} /> 
                </div>
                </div>

                <div className='ml-0 w-full mt-4 sm:ml-5 md:w-1/5 mt-0 mr-2 relative'>
                <h6 className='text-[12px] font-[700] mb-4' >{'Profile picture'}</h6>
                <Avatar   sx={{ width: 180, height: 180 }} src={(data?.profile_photo !== null || data?.profile_photo !== undefined || data?.profile_photo !== '') ?  data?.profile_photo?.name !== undefined ? `${process.env.REACT_APP_AWS_IMAGE_URL}${data?.profile_photo}` : `${process.env.REACT_APP_AWS_IMAGE_URL}${data?.profile_photo}` :  'https://fidecrmfiles.s3.amazonaws.com/noprofile.png'} className='border relative' />
                <form onClick={()=>document.querySelector('.input-field').click()} className='flex items-center border cursor-pointer py-1 px-2 rounded-md w-[51px] border-[#d1d7df] absolute top-[160px] bg-white -left-[10px]'>
                <SlPencil  size={15} />
                <input type='file' onChange={({target:{files}})=>{
                files[0] &&  uploadfile(files[0],'profile_photo')
                }} accept="image/*" className='input-field' hidden />
                <span className='ml-1.5 text-[11px] '>Edit</span>
                </form>
                </div>
            </div>  
            </div>
        </div>
        </div>
    </div>
  )
}

export default Mainprofile