import React,{useEffect, useState} from 'react'
import DashboardMenu from '../dashboard/DashboardMenu'

import {AiOutlineContacts} from 'react-icons/ai';
import {VscInspect} from 'react-icons/vsc';
import {RiBillLine,RiFilePaperLine} from 'react-icons/ri';
import { AdminHomeData } from '../../services/StickyNotesServices';

import ReactApexChart from 'react-apexcharts';

function AdminDashboard() {

  const [homeData,sethomeData] = useState([])

  const chartOptions = {
    plotOptions: {
      color:['#000','#000'],
      size:'40%',
      radialBar: {
          inverseOrder: false,
          startAngle: 0,
          endAngle: 360,
          offsetX: 0,
          offsetY: 0,
          hollow: {
              margin: 5,
              size: '50%',
              color: '#f2f2f2',
              image: undefined,
              imageWidth: 150,
              imageHeight: 150,
              imageOffsetX: 0,
              imageOffsetY: 0,
              imageClipped: true,
              position: 'front',
             
          },
          track: {
              show: true,
              startAngle: undefined,
              endAngle: undefined,
              background: '#f2f2f2',
              strokeWidth: '50%',
              opacity: 1,
              margin: 5, 
              dropShadow: {
                  enabled: false,
                  top: 0,
                  left: 0,
                  opacity: 1
              }
          },
          dataLabels: {
              show: true,
              name: {
                  show: true,
                  fontSize: '12px',
                  fontFamily: undefined,
                  fontWeight: 400,
                  color: undefined,
                  color:'#000',
                },
                value: {
                  show: true,
                  fontSize: '14px',
                  fontFamily: undefined,
                  fontWeight: 900,
                  color: undefined,
                  offsetY: 1,
                  formatter: function (val) {
                    return val + '%'
                  }
                },
                
          },
          
      },
    },
    labels: ['Payment Pending'],
    fill:{
      colors: ['#008ef2'],
    }
  }

  const [chartOptions1,setchartOptions1] = useState({
    series: [],

  chart: {
    toolbar: {
      show: false
    }
  },
    plotOptions: {
      
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded',
        dataLabels: {
          position: 'top', // top, center, bottom
        },
        
      },
    },
    dataLabels: {
      enabled: false
    },
    grid:{
      show: true,
      borderColor: '#fafafa',
    },
  
    stroke: {
      // show: true,
      width: 1,
      
      curve: 'smooth',
      colors: ['transparent']
    },
    xaxis: {
      categories: ['Contact', 'Options', 'Inspection', 'Agreement', 'Invoice', 'Hold'],
    },
    yaxis: {
      title: {
        text: 'Count'
      }
    },
    fill: {
      opacity: 1
    },
    tooltip: {
      y: {
        formatter: function (val) {
          console.log("val",val)
          return  val
        }
      }
    },
    fill:['#027cd1']
  })
  
  let rupeeIndian = Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  });

  useEffect(()=>{
    getData()
  },[])


  async function getData(){
    const response =  await AdminHomeData()
    sethomeData(response?.data?.data)
    let d = response?.data?.data
    let series1 = [
        {
          name:'count',
          data: [ 
            d?.contacts,
            d?.options,
            d?.inspection,
            d?.loi,
            d?.invoice_raised,
            d?.hold,
          ]
        }
      ] 

      setchartOptions1({...chartOptions1,series:[...series1]})
    // console.log("response data kpp",response?.data)
  }


  return (
    <div>
        <div className='flex'>
            <DashboardMenu />
            <div className='w-[86%] flex h-screen min-h-screen max-h-screen overflow-y-scroll'>
                <div className='w-[100%]'>
                    <div className='px-3 pt-5'>
                        <div className='grid border-l border-r grid-cols-6 items-center justify-evenly border-t border-b border-slate-100'>
                            <div className='border-r  border-slate-100 px-2 py-2 w-full'>
                            <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><AiOutlineContacts size={23} className='mr-2 bg-slate-200 p-1 text-slate-500' /> Total Contacts</h6>
                            <h6 className='text-[14px] mt-1'>{rupeeIndian?.format(JSON.stringify(homeData?.contacts))?.split('.')[0].slice(1)}</h6>
                            </div>

                            <div className='border-r  border-slate-100 px-2 py-2 w-full'>
                            <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><AiOutlineContacts size={23} className='mr-2 bg-slate-200 p-1 text-slate-500' />Total Options Shared</h6>
                            <h6 className='text-[14px] mt-1'>{rupeeIndian?.format(JSON.stringify(homeData?.options))?.split('.')[0].slice(1)}</h6>
                            </div>

                            <div className='border-r  border-slate-100 px-2 py-2 w-full'>
                            <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><VscInspect size={23} className='mr-2 bg-slate200 p-1 text-slate-500' />Total Inspection</h6>
                            <h6 className='text-[14px] mt-1'>{rupeeIndian?.format(JSON.stringify(homeData?.inspection))?.split('.')[0].slice(1)}</h6>
                            </div>

                            <div className='border-r  border-slate-100 px-2 py-2 w-full'>
                            <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><RiBillLine size={23} className='mr-2 bg-slate200 p-1 text-slate-500' /> Total LOI /Agreement</h6>
                            <h6 className='text-[14px] mt-1'>{rupeeIndian?.format(JSON.stringify(homeData?.loi))?.split('.')[0].slice(1)}</h6>
                            </div>

                            <div className='border-r  border-slate-100 px-2 py-2 w-full'>
                            <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><RiFilePaperLine size={23} className='mr-2 bg-slate-200 p-1 text-slate-500' />Total Invoice Raised </h6>
                            <h6 className='text-[14px] mt-1'>{rupeeIndian?.format(JSON.stringify(homeData?.invoice_raised))?.split('.')[0].slice(1)}</h6>
                            </div>

                            <div className='px-2 py-2 w-full'>
                            <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><RiFilePaperLine size={23} className='mr-2 bg-slate-200 p-1 text-slate-500' />Total Hold Clients </h6>
                            <h6 className='text-[14px] mt-1'>{rupeeIndian?.format(JSON.stringify(homeData?.hold))?.split('.')[0].slice(1)}</h6>
                            </div>

                        
                        </div>
                        <div className='grid border-l border-r grid-cols-6 items-center justify-evenly  border-b border-slate-100'>
                            <div className='border-r  border-slate-100 px-2 py-2 w-full'>
                            <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><AiOutlineContacts size={23} className='mr-2 bg-slate-200 p-1 text-slate-500' /> Total Invoice Raised</h6>
                            <h6 className='text-[14px] mt-1'>{rupeeIndian?.format(JSON.stringify(homeData?.invoice_raised))?.split('.')[0].slice(1)}</h6>
                            </div>

                            <div className='border-r  border-slate-100 px-2 py-2 w-full'>
                            <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><AiOutlineContacts size={23} className='mr-2 bg-slate-200 p-1 text-slate-500' />Invoice Amt Incl GST</h6>
                            <h6 className='text-[14px] mt-1'>{rupeeIndian?.format(JSON.stringify(homeData?.invoice_amount_generated_with_gst))?.split('.')[0]}</h6>
                            </div>

                            <div className='border-r  border-slate-100 px-2 py-2 w-full'>
                            <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><VscInspect size={23} className='mr-2 bg-slate200 p-1 text-slate-500' />Invoice Amt Excl GST</h6>
                            <h6 className='text-[14px] mt-1'>{rupeeIndian?.format(JSON.stringify(homeData?.invoice_amount_generated_without_gst))?.split('.')[0]}</h6>
                            </div>

                            <div className='border-r  border-slate-100 px-2 py-2 w-full'>
                            <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><RiBillLine size={23} className='mr-2 bg-slate200 p-1 text-slate-500' /> Payment Incl GST</h6>
                            <h6 className='text-[14px] mt-1'>{rupeeIndian?.format(JSON.stringify(homeData?.revenue_recieved_with_gst))?.split('.')[0]}</h6>
                            </div>

                            <div className='border-r  border-slate-100 px-2 py-2 w-full'>
                            <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><RiFilePaperLine size={23} className='mr-2 bg-slate-200 p-1 text-slate-500' />Payment Excl Gst </h6>
                            <h6 className='text-[14px] mt-1'>{rupeeIndian?.format(JSON.stringify(homeData?.revenue_recieved_without_gst))?.split('.')[0]}</h6>
                            </div>

                            

                        
                        </div>

                        <div className='flex h-[45%]'>
           <div className='border-b border-t border-l mr-2 border-r pr-2  w-[40%] flex-0.4  mt-2 border-slate-100 px-3 py-2 relative'>

            <h6 className='text-[13px] font-[700]'>Revenue Forecast</h6>
             {/* {(revenue?.target_achieved - revenue?.employee_target_current_period) > 0 && <h6 className='bg-green-50 text-[10px] p-1 w-60 absolute right-2'>Congrats you have become eligible for incentive check your <span className='font-[800] cursor-pointer'>Incentive</span> </h6>} */}
              <div className='flex '>
              <div className='-ml-10'>
              <ReactApexChart options={chartOptions} size={'10'} series={[homeData?.invoice_amount_generated_without_gst > 0 ? 100 - Math.round((homeData?.revenue_recieved_without_gst/homeData?.invoice_amount_generated_without_gst)*100) : 0]} type="radialBar" width={240} height={240} />
              </div>
              <div className='w-full '>

               <div className='ml-0 mt-6 w-full relative'>
                <h6 className='text-[11px] font-[400] min-w-[70%] max-w-[70%]'>Invoice Amount With Gst</h6>
                  <div className="w-full bg-gray-200 mt-1 rounded-full h-2.5 mb-2 dark:bg-gray-700">
                    <div className={`bg-gray-700  rounded-full h-2.5 flex items-center justify-center dark:bg-gray-300 font-[900  text-[8px] text-white text-center leading-none`}  style={{width:homeData?.invoice_amount_generated_with_gst > 0 ? '100%' : '0%'}} >{homeData?.invoice_amount_generated_with_gst > 0 ? 100 : 0} %</div>
                  </div>
                  <h6 className='text-[11px] font-[700] absolute top-0 right-0'>{rupeeIndian?.format(JSON.stringify(homeData?.invoice_amount_generated_with_gst))?.split('.')[0]}</h6>

              </div>

              <div className='ml-0 mt-2 w-full relative'>
                <h6 className='text-[11px] font-[400] min-w-[70%] max-w-[70%]'>Invoice Amount without Gst</h6>
                  <div className="w-full bg-gray-200 mt-1 rounded-full h-2.5 mb-2 dark:bg-gray-700">
                    <div className={`bg-gray-700  rounded-full h-2.5 flex items-center justify-center dark:bg-gray-300 font-[900  text-[8px] text-white text-center leading-none`}  style={{width:homeData?.invoice_amount_generated_without_gst > 0 ? '100%' : '0%'}}   > {homeData?.invoice_amount_generated_without_gst > 0 ? 100 : 0}%</div>
                  </div>
                  <h6 className='text-[11px] font-[700] absolute top-0 right-0'>{rupeeIndian?.format(JSON.stringify(homeData?.invoice_amount_generated_without_gst))?.split('.')[0]}</h6>

              </div>

                <div className='ml-0 mt-2 w-full relative'>
                    <h6 className='text-[11px] font-[400] min-w-[70%] max-w-[70%]'>Revenue Recieved With Gst</h6>
                    <div className="w-full bg-gray-200 mt-1 rounded-full h-2.5 mb-4 dark:bg-gray-700">
                        <div className={`bg-gray-700  rounded-full h-2.5 flex items-center justify-center dark:bg-gray-300 font-[900  text-[8px] text-white text-center leading-none `} style={{width:homeData?.revenue_recieved_with_gst > 0 ? (homeData?.revenue_recieved_with_gst/homeData?.invoice_amount_generated_with_gst)*100 > 100 ? `100%` : `${Math.round((homeData?.revenue_recieved_with_gst/homeData?.invoice_amount_generated_with_gst)*100)}%` : '0%'}} >{homeData?.revenue_recieved_with_gst > 0 ? Math.round((homeData?.revenue_recieved_with_gst/homeData?.invoice_amount_generated_with_gst)*100) : 0}%</div>
                    </div>
                    <h6 className='text-[11px] font-[700] absolute top-0 right-0'>{rupeeIndian?.format(JSON.stringify(homeData?.revenue_recieved_with_gst))?.split('.')[0]}</h6>
                </div>

                <div className='ml-0 mt-2 w-full relative'>
                    <h6 className='text-[11px] font-[400] min-w-[70%] max-w-[70%]'>Revenue Recieved Without Gst</h6>
                    <div className="w-full bg-gray-200 mt-1 rounded-full h-2.5 mb-4 dark:bg-gray-700">
                        <div className={`bg-gray-700  rounded-full h-2.5 flex items-center justify-center dark:bg-gray-300 font-[900  text-[8px] text-white text-center leading-none `} style={{width:homeData?.revenue_recieved_without_gst > 0 ? (homeData?.revenue_recieved_without_gst/homeData?.invoice_amount_generated_without_gst)*100 > 100 ? '100%' : `${(homeData?.revenue_recieved_without_gst/homeData?.invoice_amount_generated_without_gst)*100}%` : '0%'}} >{homeData?.revenue_recieved_without_gst > 0 ? Math.round((homeData?.revenue_recieved_without_gst/homeData?.invoice_amount_generated_without_gst)*100) : 0}%</div>
                    </div>
                    <h6 className='text-[11px] font-[700] absolute top-0 right-0'>{rupeeIndian?.format(JSON.stringify(homeData?.revenue_recieved_without_gst))?.split('.')[0]}</h6>
                </div>

                {/* {(revenue?.target_achieved - revenue?.employee_target_current_period) > 0 && 
                <div className='ml-0 w-full relative -mt-1'>
                    <h6 className='text-[11px] font-[400]'>Incentive Amount</h6>
                    <div className="w-full bg-gray-200 mt-1 rounded-full h-2.5 mb-4 dark:bg-gray-700">
                        <div className="bg-gray-700 rounded-full h-2.5 flex items-center justify-center dark:bg-gray-300 font-[900]  text-[8px] text-white text-center leading-none " >100%</div>
                    </div>
                    <h6 className='text-[11px] font-[700] absolute top-0 right-0'>{rupeeIndian?.format(JSON.stringify(17000))?.split('.')[0]}</h6>

                </div>} */}
                </div>
                </div>

            </div>
            <div className='border  w-[60%] flex-0.6  mt-2 border-slate-100 px-3 py-2 relative'>
                    <h1 className='text-[13px] font-[700]'>Total Leads</h1>
                    <ReactApexChart options={chartOptions1} size={'10'} series={chartOptions1.series} type="bar" width={'100%'}  height={240} />

            </div>

            
            </div>

                    </div>
            </div>
            </div>
        </div>
    </div>
  )
}

export default AdminDashboard