import { deleteRequest, get, post, postfd, put } from "../../helpers/apihelpers";

export const CreateFcaresUpcomingBillService=async(data)=>{
    try {
        const result = await post(`api/fcares/upcoming_bill`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateFcaresUpcomingBillService=async(id,data)=>{
    try {
        const result = await put(`api/fcares/upcoming_bill/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteFcaresUpcomingBillService=async(id,data)=>{
    try {
        const result = await deleteRequest(`api/fcares/upcoming_bill/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetFcaresUpcomingBillService=async(from_date,to_Date,page)=>{
    try {
        const result = await get(`api/fcares/upcoming_bill?page=${page}&from_date=${from_date}&to_date=${to_Date}`);
        return result;
    } catch (err) {
        return err.response;
    }
}