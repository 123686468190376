import React from 'react'
import TaskMenu from './TaskMenu'

function TaskDashboard() {
  return (
    <div className='h-screen max-h-screen box-border overflow-hidden'>
     <div className='block sm:flex'>
       <TaskMenu />
     </div>  
    </div>
  )
}

export default TaskDashboard