import React,{useState,useEffect} from 'react'
import { useLocation,useNavigate } from 'react-router-dom'
import {
    IconButton,
  } from "@mui/material";

import { Select } from 'antd';
import { ButtonFilledAutoWidth } from '../../components/button';
import { DatePicker } from 'antd'
import { useSelector } from 'react-redux';
import DashboardMenu from '../dashboard/DashboardMenu';
import { DownloadWeeklyReportService, GetWeeklyReportService } from '../../services/WeeklyReport';
import { CgArrowsExpandUpRight } from "react-icons/cg";
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { fullScreenAction } from '../../redux/actions/staticAction';
import toast from 'react-hot-toast';
import { GetTeamBasedOnYearInsentiveService, GetTeamMembersDetail } from '../../services/AdminServicesfile/TeamServices';
import { GetDepartmentService, GetUsersByDepartment } from '../../services/DepartmentServices';
import { GetReportingMembersDetail } from '../../services/AdminServicesfile/ReportingServices';
import fileDownload from "js-file-download";

import { AiOutlineFileExcel } from "react-icons/ai";
import axios from 'axios';
import { GetStageService } from '../../services/StageServices';

function WeeklyReport() {

    const zoom_enabled = useSelector(state=>state.Static.fullScreen)
    const {pathname} = useLocation();
    const user = useSelector(state=>state.Auth)
    const user_department = user.department_id[0]


    // console.log("user_department",user_department)

    let path = pathname?.split('/')[pathname?.split('/')?.length -1]


    let year = new Date().getFullYear().toString()
    const dispatch = useDispatch()

    const [datas,setdatas]  = useState([])

    const [selected_department,setselected_department] = useState(null)
    const [selected_team,setselected_team] = useState(null)
    const [selected_user,setselected_user] = useState(null)
    const [selected_stage,setselected_stage] = useState(null)
    const [teams,setteams] = useState([]) 


    const [departments,setdepartments] = useState([])
    const [users,setusers] = useState([])
    const [stages,setstages] = useState([])
    const [page,setpage] = useState(1)
    const [filter_drawer,setfilter_drawer] = useState(false)

     const [zoom,setzoom] = useState(false) 


     const [meetings_count,setmeetings_count] = useState(0)
     const [walkins_count,setwalkins_count] = useState(0)
     const [inspection_count,setinspection_count] = useState(0)
     const [property_visit_count,setproperty_visit_count] = useState(0)
     const [scouting_count,setscouting_count] = useState(0)
     const [landlord_meetings_count,setlandlord_meetings_count] = useState(0)
     const [loi_signing_count,setloi_signing_count] = useState(0)
     const [agreement_signing_count,setagreement_signing_count] = useState(0)

     const [payment_collection_count,setpayment_collection_count] = useState(0)
     const [lead_generated_count,setlead_generated_count] = useState(0)
     const [loi_discussion_count,setloi_discussion_count] = useState(0)
     const [aggrement_discussion_count,setaggrement_discussion_count] = useState(0)
     const [calls_count,setcalls_count] = useState(0)
     const [dailytask_count,setdailytask_count] = useState(0)
   

    const [search,setsearch] = useState({text:'',from_date:'',from_date1:'',to_date:'',to_date1:'',activate:false,stage:''})
  
   
    useEffect(()=>{
      setsearch({text:'',from_date:'',from_date1:'',to_date:'',to_date1:'',activate:false,stage:''})
      setdatas({})
      if(path !== 'weekly_report1'){
        if(user.roles.includes('manager')){
          getTeamMembersForCurrentYear()
        }else if(user?.roles?.includes('reporting_manager')){
          getReportingMemberDetails(user?.id)
         }else if(user.roles.includes('bd_user') && !user.roles.includes('manager') && !user.roles.includes('admin') && !user.roles.includes('hod')){
          setselected_user({label:user?.name,value:user?.id})
        }
      }else{
        // console.log("kpp na")
      }
    },[])



    useEffect(()=>{
        getweeklyreport()
        getdepartment()
     
    },[page])


    // console.log("path1",path)

    async function getstages(v){
      // console.log("im gettting called here")
      const response1 = await GetStageService('Lead_Stage',v)
      const response = await GetStageService('FollowUp_Options')
      let arr = []
      response1?.data?.data?.datas?.forEach((d)=>{
        if(d?.name !== 'Calls' && d?.name !== 'Walk-Ins' && d?.name !== 'Meetings'  && d?.name !== 'Landlord Meeting' && d?.name !== 'Invoice Raised' && d?.name !== 'Property-Visit' && d?.name !== 'Payment Collection'){
          arr.push({label:d?.name,value:d?._id})
        }
        // arr.push({value:d?._id,label:d?.name})
      })
      response?.data?.data?.datas?.forEach((d)=>{
          arr.push({value:d?._id,label:d?.name})
      })
      setstages(arr)
    
    }

    // console.log("stages",stages)

    async function getuserbaseddepartment(id){
      if(user?.roles?.includes('admin') || user?.roles?.includes('hod')){
        if(id !== undefined){
         const response = await GetUsersByDepartment(id) 
          let arr = []
          response?.data?.datas?.forEach((d)=>{
              arr.push({label:d?.name,value:d?._id})
          })
        setusers(arr)
        }
      }
    }

    async function getReportingMemberDetails(id){
      const response = await GetReportingMembersDetail(id)
      let arr = []
      response?.data?.datas[0]?.users?.forEach((d)=>{
          arr.push({label:d?.name,value:d?._id})
      })
      setusers(arr)
     }

    // console.log("user.roles",user.roles)


    async function getdepartment(){
        const response = await GetDepartmentService();
        let data = response?.data?.datas?.find((d)=>d?.id === user_department.id)
        let data1 = response?.data?.datas?.find((d)=>d?.department_name === 'Transaction Team')
        getstages(data1?.id)


        let arr = []
        response?.data?.datas.forEach((d)=>{
          if(d?.department_name !== 'Finance Team' && d?.department_name !== 'Gallery Team' && d?.department_name !== 'Corp Team'){
             arr.push({label:d?.department_name,value:d?.id})
          }
        })
        setdepartments(arr)

        if(user.roles.includes('hod') || user.roles.includes('admin')){
          getuserbaseddepartment(data?.id) 
          if(data?.department_name === 'Transaction Team' || user.roles.includes('admin')){
            let teams_arr = []
             let data = response?.data?.datas?.find((d)=>d?.department_name === 'Transaction Team')

             const response1 = await GetTeamBasedOnYearInsentiveService(data.id)
             
             response1?.data?.datas?.forEach((d)=>{
              let users_arr = []
              let users_arr_id = []
              let team_arr_based_period = []

               d?.teamBasedPeriod?.forEach((d2)=>{
                d2?.forEach((d3)=>{
                  team_arr_based_period.push({label:d3?.name,value:d3?._id})
                })
               })

               d?.users?.forEach((d1)=>{
                users_arr?.push({label:d1?.name,value:d1?._id})
                users_arr_id.push(d1?._id)
               })

              teams_arr?.push({value:d._id,label:d?.name,users:users_arr,users_id:users_arr_id,team_arr_based_period})
            })
            setteams(teams_arr)
          }
        }else if(user?.roles?.includes('manager')){
          getTeamMembersForCurrentYear()
        }

        if(!user.roles.includes('admin')){
          setselected_department({label:user?.department_id[0]?.department_name,value:user?.department_id[0]?.id})
          getuserbaseddepartment(user?.department_id[0]?.id)

        }else{
          // console.log("amma")
        }
        
    }

    // console.log("teams kp here",teams)


    async function getTeamMembersForCurrentYear(){
      const response = await GetTeamMembersDetail(user?.id,year-1)
      // console.log("response?.data",response?.data)
      let arr = []
      response?.data?.datas[0]?.users?.forEach((d)=>{
          arr.push({label:d?.name,value:d?._id})
      })
      setusers(arr)
    }


    function getdifferentdays(){

      var date1 = new Date(search?.from_date1); 
      var date2 = new Date(search?.to_date1); 
  
      var Difference_In_Time = date2.getTime() - date1.getTime(); 
      var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
  
      return Difference_In_Days
  
    }

    // console.log("user?.roles",user?.roles)
    async function applyfilterfunction(page){
        let days = getdifferentdays()
        if(days < 31){
            setsearch({...search,activate:true})   
            if(user?.roles?.includes('manager')){
              let user_id =  ''
              users?.forEach((u)=>{
                user_id  += u?.value + ','
              })

              if(path === 'weekly_report1'){
                const response = await  GetWeeklyReportService(search?.text,search?.from_date1,search?.to_date1,page, user?.id,search?.stage)
                setdatas(response?.data)
                  let options = response?.data?.data?.leadoptions
                  let follow_up_options = response?.data?.data?.leadfollowup_options


                  let walkins = 0 
                  let meetings = 0
                  let property_visit = 0
                  let daily_task = 0
                  let loi_discussion = 0
                  let agreement_discussion = 0
                  let scouting = 0
                  let calls = 0


                  let loi_agreement = 0 
                  let inspection = 0
                  let options_shared = 0
                  let loi_signing = 0
                  let landlord_meeting = 0
                  let payment_collection = 0
                
                  
                  options?.forEach((d)=>{
                    if(d?.datas?.stage?.findIndex((f)=>f === "LOI Signing") >= 0){
                      loi_signing += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === "LOI Signing")]
                    }
                    if(d?.datas?.stage?.findIndex((f)=>f === 'Meetings') >= 0){
                      meetings += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === 'Meetings')]
                    }
                    if(d?.datas?.stage?.findIndex((f)=>f === 'Property Visit') >= 0){
                      property_visit += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === 'Property Visit')]
                    }
                    if(d?.datas?.stage?.findIndex((f)=>f === 'Daily Tasks') >= 0){
                      daily_task += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === 'Daily Tasks')]
                    }
                    if(d?.datas?.stage?.findIndex((f)=>f === "Calls") >= 0){
                      calls += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === "Calls")]
                    }
                    if(d?.datas?.stage?.findIndex((f)=>f === 'LOI Discussion') >= 0){
                      loi_discussion += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === 'LOI Discussion')]
                    }
                    if(d?.datas?.stage?.findIndex((f)=>f === 'Agreement Discussion') >= 0){
                      agreement_discussion += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === 'Agreement Discussion')]
                    }
                    if(d?.datas?.stage?.findIndex((f)=>f === "Scouting") >= 0){
                      scouting += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === "Scouting")]
                    }
                    if(d?.datas?.stage?.findIndex((f)=>f === "Walk Ins") >= 0){
                      walkins += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === "Walk Ins")]
                    }
                    if(d?.datas?.stage?.findIndex((f)=>f === "Landlord Meeting") >= 0){
                      landlord_meeting += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === "Landlord Meeting")]
                    }
                    if(d?.datas?.stage?.findIndex((f)=>f === "Client Meeting") >= 0){
                      meetings += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === "Client Meeting")]
                    }
                    if(d?.datas?.stage?.findIndex((f)=>f === "LOI/Agreement") >= 0){
                      loi_agreement += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === "LOI/Agreement")]
                    }
                    if(d?.datas?.stage?.findIndex((f)=>f === "Inspection") >= 0){
                      inspection += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === "Inspection")]
                    }
                    if(d?.datas?.stage?.findIndex((f)=>f === "Options Shared") >= 0){
                      options_shared += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === "Options Shared")]
                    }
                    if(d?.datas?.stage?.findIndex((f)=>f === "Payment Collection") >= 0){
                      payment_collection += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === "Payment Collection")]
                    }

                  })

                  // console.log("follow_up_options",follow_up_options)

                  follow_up_options?.forEach((d)=>{
                    if(d?.datas?.stage?.findIndex((f)=>f === "LOI Signing") >= 0){
                      loi_signing += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === "LOI Signing")]
                    }
                    if(d?.datas?.stage?.findIndex((f)=>f === 'Meetings') >= 0){
                      meetings += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === 'Meetings')]
                    }
                    if(d?.datas?.stage?.findIndex((f)=>f === 'Property Visit') >= 0){
                      property_visit += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === 'Property Visit')]
                    }
                    if(d?.datas?.stage?.findIndex((f)=>f === 'Daily Tasks') >= 0){
                      daily_task += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === 'Daily Tasks')]
                    }
                    if(d?.datas?.stage?.findIndex((f)=>f === "Calls") >= 0){
                      calls += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === "Calls")]
                    }
                    if(d?.datas?.stage?.findIndex((f)=>f === 'LOI Discussion') >= 0){
                      loi_discussion += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === 'LOI Discussion')]
                    }
                    if(d?.datas?.stage?.findIndex((f)=>f === 'Agreement Discussion') >= 0){
                      agreement_discussion += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === 'Agreement Discussion')]
                    }
                    if(d?.datas?.stage?.findIndex((f)=>f === "Scouting") >= 0){
                      scouting += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === "Scouting")]
                    }
                    if(d?.datas?.stage?.findIndex((f)=>f === "Walk Ins") >= 0){
                      walkins += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === "Walk Ins")]
                    }
                    if(d?.datas?.stage?.findIndex((f)=>f === "Landlord Meeting") >= 0){
                      landlord_meeting += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === "Landlord Meeting")]
                    }
                    if(d?.datas?.stage?.findIndex((f)=>f === "Client Meeting") >= 0){
                      meetings += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === "Client Meeting")]
                    }
                    if(d?.datas?.stage?.findIndex((f)=>f === "LOI/Agreement") >= 0){
                      loi_agreement += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === "LOI/Agreement")]
                    }
                    if(d?.datas?.stage?.findIndex((f)=>f === "Inspection") >= 0){
                      inspection += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === "Inspection")]
                    }
                    if(d?.datas?.stage?.findIndex((f)=>f === "Options Shared") >= 0){
                      options_shared += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === "Options Shared")]
                    }
                    if(d?.datas?.stage?.findIndex((f)=>f === "Payment Collection") >= 0){
                      payment_collection += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === "Payment Collection")]
                    }

                    
                    
                  })

                  setwalkins_count(walkins)
                  setmeetings_count(meetings)
                  setproperty_visit_count(property_visit)
                  setloi_discussion_count(loi_discussion)
                  setcalls_count(calls)
                  setaggrement_discussion_count(agreement_discussion)
                  setscouting_count(scouting)
                  setdailytask_count(daily_task)

                  setagreement_signing_count(loi_agreement)
                  setinspection_count(inspection)
                  setlead_generated_count(options_shared)
                  setpayment_collection_count(payment_collection)
                  setlandlord_meetings_count(landlord_meeting)
                  setloi_signing_count(loi_signing)
              }else{
                const response = await  GetWeeklyReportService(search?.text,search?.from_date1,search?.to_date1,page, selected_user?.value === undefined ? user_id : selected_user?.value,search?.stage)

                
                let options = response?.data?.data?.leadoptions
               let follow_up_options = response?.data?.data?.leadfollowup_options


              // console.log("follow_up_options",follow_up_options)
              let walkins = 0 
              let meetings = 0
              let property_visit = 0
              let daily_task = 0
              let loi_discussion = 0
              let agreement_discussion = 0
              let scouting = 0
              let calls = 0


              let loi_agreement = 0 
              let inspection = 0
              let options_shared = 0
              let loi_signing = 0
              let landlord_meeting = 0
              let payment_collection = 0
             
               
              options?.forEach((d)=>{
                if(d?.datas?.stage?.findIndex((f)=>f === 'Meetings') >= 0){
                  meetings += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === 'Meetings')]
                }
                if(d?.datas?.stage?.findIndex((f)=>f === 'Property Visit') >= 0){
                  property_visit += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === 'Property Visit')]
                }
                if(d?.datas?.stage?.findIndex((f)=>f === 'Daily Tasks') >= 0){
                  daily_task += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === 'Daily Tasks')]
                }
                if(d?.datas?.stage?.findIndex((f)=>f === "Calls") >= 0){
                  calls += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === "Calls")]
                }
                if(d?.datas?.stage?.findIndex((f)=>f === 'LOI Discussion') >= 0){
                  loi_discussion += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === 'LOI Discussion')]
                }
                if(d?.datas?.stage?.findIndex((f)=>f === 'Agreement Discussion') >= 0){
                  agreement_discussion += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === 'Agreement Discussion')]
                }
                if(d?.datas?.stage?.findIndex((f)=>f === "Scouting") >= 0){
                  scouting += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === "Scouting")]
                }
                if(d?.datas?.stage?.findIndex((f)=>f === "Walk Ins") >= 0){
                  walkins += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === "Walk Ins")]
                }
              })

              // console.log("follow_up_options",follow_up_options)

              follow_up_options?.forEach((d)=>{
                if(d?.datas?.stage?.findIndex((f)=>f === "LOI Signing") >= 0){
                  loi_signing += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === "LOI Signing")]
                }
                if(d?.datas?.stage?.findIndex((f)=>f === "Landlord Meeting") >= 0){
                  landlord_meeting += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === "Landlord Meeting")]
                }
                if(d?.datas?.stage?.findIndex((f)=>f === "Client Meeting") >= 0){
                  meetings += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === "Client Meeting")]
                }
                if(d?.datas?.stage?.findIndex((f)=>f === "LOI/Agreement") >= 0){
                  loi_agreement += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === "LOI/Agreement")]
                }
                if(d?.datas?.stage?.findIndex((f)=>f === "Inspection") >= 0){
                  inspection += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === "Inspection")]
                }
                if(d?.datas?.stage?.findIndex((f)=>f === "Options Shared") >= 0){
                  options_shared += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === "Options Shared")]
                }
                if(d?.datas?.stage?.findIndex((f)=>f === "Payment Collection") >= 0){
                  payment_collection += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === "Payment Collection")]
                }
                
              })

              setwalkins_count(walkins)
              setmeetings_count(meetings)
              setproperty_visit_count(property_visit)
              setloi_discussion_count(loi_discussion)
              setcalls_count(calls)
              setaggrement_discussion_count(agreement_discussion)
              setscouting_count(scouting)
              setdailytask_count(daily_task)

              setagreement_signing_count(loi_agreement)
              setinspection_count(inspection)
              setlead_generated_count(options_shared)
              setpayment_collection_count(payment_collection)
              setlandlord_meetings_count(landlord_meeting)
              setloi_signing_count(loi_signing)
              setdatas(response?.data)
              }
            }else{

              // console.log("ammmma")
              if(path === 'weekly_report1'){
                
                const response = await  GetWeeklyReportService(search?.text,search?.from_date1,search?.to_date1,page, user?.id,search?.stage)

                let options = response?.data?.data?.leadoptions
                let follow_up_options = response?.data?.data?.leadfollowup_options


                // console.log("follow_up_options",follow_up_options)
                let walkins = 0 
                let meetings = 0
                let property_visit = 0
                let daily_task = 0
                let loi_discussion = 0
                let agreement_discussion = 0
                let scouting = 0
                let calls = 0


                let loi_agreement = 0 
                let inspection = 0
                let options_shared = 0
                let loi_signing = 0
                let landlord_meeting = 0
                let payment_collection = 0
               
                 
                options?.forEach((d)=>{
                  if(d?.datas?.stage?.findIndex((f)=>f === "LOI Signing") >= 0){
                    loi_signing += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === "LOI Signing")]
                  }
                  if(d?.datas?.stage?.findIndex((f)=>f === "Landlord Meeting") >= 0){
                    landlord_meeting += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === "Landlord Meeting")]
                  }
                  if(d?.datas?.stage?.findIndex((f)=>f === "LOI/Agreement") >= 0){
                    loi_agreement += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === "LOI/Agreement")]
                  }
                  if(d?.datas?.stage?.findIndex((f)=>f === "Inspection") >= 0){
                    inspection += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === "Inspection")]
                  }
                  if(d?.datas?.stage?.findIndex((f)=>f === "Options Shared") >= 0){
                    options_shared += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === "Options Shared")]
                  }
                  if(d?.datas?.stage?.findIndex((f)=>f === "Payment Collection") >= 0){
                    payment_collection += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === "Payment Collection")]
                  }
                  if(d?.datas?.stage?.findIndex((f)=>f === 'Meetings') >= 0){
                    meetings += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === 'Meetings')]
                  }
                  if(d?.datas?.stage?.findIndex((f)=>f === 'Property Visit') >= 0){
                    property_visit += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === 'Property Visit')]
                  }
                  if(d?.datas?.stage?.findIndex((f)=>f === 'Daily Tasks') >= 0){
                    daily_task += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === 'Daily Tasks')]
                  }
                  if(d?.datas?.stage?.findIndex((f)=>f === "Calls") >= 0){
                    calls += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === "Calls")]
                  }
                  if(d?.datas?.stage?.findIndex((f)=>f === 'LOI Discussion') >= 0){
                    loi_discussion += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === 'LOI Discussion')]
                  }
                  if(d?.datas?.stage?.findIndex((f)=>f === 'Agreement Discussion') >= 0){
                    agreement_discussion += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === 'Agreement Discussion')]
                  }
                  if(d?.datas?.stage?.findIndex((f)=>f === "Scouting") >= 0){
                    scouting += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === "Scouting")]
                  }
                  if(d?.datas?.stage?.findIndex((f)=>f === "Walk Ins") >= 0){
                    walkins += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === "Walk Ins")]
                  }
                })

                follow_up_options?.forEach((d)=>{
                  if(d?.datas?.stage?.findIndex((f)=>f === "LOI Signing") >= 0){
                    loi_signing += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === "LOI Signing")]
                  }
                  if(d?.datas?.stage?.findIndex((f)=>f === "Landlord Meeting") >= 0){
                    landlord_meeting += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === "Landlord Meeting")]
                  }
                  if(d?.datas?.stage?.findIndex((f)=>f === "LOI/Agreement") >= 0){
                    loi_agreement += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === "LOI/Agreement")]
                  }
                  if(d?.datas?.stage?.findIndex((f)=>f === "Inspection") >= 0){
                    inspection += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === "Inspection")]
                  }
                  if(d?.datas?.stage?.findIndex((f)=>f === "Options Shared") >= 0){
                    options_shared += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === "Options Shared")]
                  }
                  if(d?.datas?.stage?.findIndex((f)=>f === "Payment Collection") >= 0){
                    payment_collection += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === "Payment Collection")]
                  }
                  if(d?.datas?.stage?.findIndex((f)=>f === 'Meetings') >= 0){
                    meetings += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === 'Meetings')]
                  }
                  if(d?.datas?.stage?.findIndex((f)=>f === 'Property Visit') >= 0){
                    property_visit += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === 'Property Visit')]
                  }
                  if(d?.datas?.stage?.findIndex((f)=>f === 'Daily Tasks') >= 0){
                    daily_task += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === 'Daily Tasks')]
                  }
                  if(d?.datas?.stage?.findIndex((f)=>f === "Calls") >= 0){
                    calls += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === "Calls")]
                  }
                  if(d?.datas?.stage?.findIndex((f)=>f === 'LOI Discussion') >= 0){
                    loi_discussion += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === 'LOI Discussion')]
                  }
                  if(d?.datas?.stage?.findIndex((f)=>f === 'Agreement Discussion') >= 0){
                    agreement_discussion += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === 'Agreement Discussion')]
                  }
                  if(d?.datas?.stage?.findIndex((f)=>f === "Scouting") >= 0){
                    scouting += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === "Scouting")]
                  }
                  if(d?.datas?.stage?.findIndex((f)=>f === "Walk Ins") >= 0){
                    walkins += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === "Walk Ins")]
                  }
                })

                setwalkins_count(walkins)
                setmeetings_count(meetings)
                setproperty_visit_count(property_visit)
                setloi_discussion_count(loi_discussion)
                setcalls_count(calls)
                setaggrement_discussion_count(agreement_discussion)
                setscouting_count(scouting)
                setdailytask_count(daily_task)

                setagreement_signing_count(loi_agreement)
                setinspection_count(inspection)
                setlead_generated_count(options_shared)
                setpayment_collection_count(payment_collection)
                setlandlord_meetings_count(landlord_meeting)
                setloi_signing_count(loi_signing)

                // console.log("walkins",walkins)
                // console.log("meetings",meetings)
                // console.log("property_visit",property_visit)
                // console.log("daily_task",daily_task)
                // console.log("loi_discussion",loi_discussion)
                // console.log("agreement_discussion",agreement_discussion)
                // console.log("scouting",scouting)
                // console.log("calls",calls)
                
                
                
                
                
                
                
             

                // console.log("response?.data",response?.data)
                setdatas(response?.data)
              }else{
              const response = await  GetWeeklyReportService(search?.text,search?.from_date1,search?.to_date1,page,selected_user?.value !== undefined  ? selected_user?.value : selected_team === '' ? '' : selected_team?.users_id?.join(','),search?.stage)
              let options = response?.data?.data?.leadoptions
              let follow_up_options = response?.data?.data?.leadfollowup_options


              // console.log("follow_up_options",follow_up_options)
              let walkins = 0 
              let meetings = 0
              let property_visit = 0
              let daily_task = 0
              let loi_discussion = 0
              let agreement_discussion = 0
              let scouting = 0
              let calls = 0


              let loi_agreement = 0 
              let inspection = 0
              let options_shared = 0
              let loi_signing = 0
              let landlord_meeting = 0
              let payment_collection = 0
             
               
              options?.forEach((d)=>{
                if(d?.datas?.stage?.findIndex((f)=>f === "LOI Signing") >= 0){
                  loi_signing += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === "LOI Signing")]
                }
                if(d?.datas?.stage?.findIndex((f)=>f === "Landlord Meeting") >= 0){
                  landlord_meeting += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === "Landlord Meeting")]
                }
                if(d?.datas?.stage?.findIndex((f)=>f === "Client Meeting") >= 0){
                  meetings += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === "Client Meeting")]
                }
                if(d?.datas?.stage?.findIndex((f)=>f === "LOI/Agreement") >= 0){
                  loi_agreement += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === "LOI/Agreement")]
                }
                if(d?.datas?.stage?.findIndex((f)=>f === "Inspection") >= 0){
                  inspection += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === "Inspection")]
                }
                if(d?.datas?.stage?.findIndex((f)=>f === "Options Shared") >= 0){
                  options_shared += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === "Options Shared")]
                }
                if(d?.datas?.stage?.findIndex((f)=>f === "Payment Collection") >= 0){
                  payment_collection += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === "Payment Collection")]
                }
                if(d?.datas?.stage?.findIndex((f)=>f === 'Meetings') >= 0){
                  meetings += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === 'Meetings')]
                }
                if(d?.datas?.stage?.findIndex((f)=>f === 'Property Visit') >= 0){
                  property_visit += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === 'Property Visit')]
                }
                if(d?.datas?.stage?.findIndex((f)=>f === 'Daily Tasks') >= 0){
                  daily_task += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === 'Daily Tasks')]
                }
                if(d?.datas?.stage?.findIndex((f)=>f === "Calls") >= 0){
                  calls += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === "Calls")]
                }
                if(d?.datas?.stage?.findIndex((f)=>f === 'LOI Discussion') >= 0){
                  loi_discussion += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === 'LOI Discussion')]
                }
                if(d?.datas?.stage?.findIndex((f)=>f === 'Agreement Discussion') >= 0){
                  agreement_discussion += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === 'Agreement Discussion')]
                }
                if(d?.datas?.stage?.findIndex((f)=>f === "Scouting") >= 0){
                  scouting += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === "Scouting")]
                }
                if(d?.datas?.stage?.findIndex((f)=>f === "Walk Ins") >= 0){
                  walkins += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === "Walk Ins")]
                }
              })

              // console.log("follow_up_options",follow_up_options)

              follow_up_options?.forEach((d)=>{
                if(d?.datas?.stage?.findIndex((f)=>f === "LOI Signing") >= 0){
                  loi_signing += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === "LOI Signing")]
                }
                if(d?.datas?.stage?.findIndex((f)=>f === "Landlord Meeting") >= 0){
                  landlord_meeting += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === "Landlord Meeting")]
                }
                if(d?.datas?.stage?.findIndex((f)=>f === "Client Meeting") >= 0){
                  meetings += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === "Client Meeting")]
                }
                if(d?.datas?.stage?.findIndex((f)=>f === "LOI/Agreement") >= 0){
                  loi_agreement += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === "LOI/Agreement")]
                }
                if(d?.datas?.stage?.findIndex((f)=>f === "Inspection") >= 0){
                  inspection += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === "Inspection")]
                }
                if(d?.datas?.stage?.findIndex((f)=>f === "Options Shared") >= 0){
                  options_shared += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === "Options Shared")]
                }
                if(d?.datas?.stage?.findIndex((f)=>f === "Payment Collection") >= 0){
                  payment_collection += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === "Payment Collection")]
                }
                if(d?.datas?.stage?.findIndex((f)=>f === 'Meetings') >= 0){
                  meetings += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === 'Meetings')]
                }
                if(d?.datas?.stage?.findIndex((f)=>f === 'Property Visit') >= 0){
                  property_visit += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === 'Property Visit')]
                }
                if(d?.datas?.stage?.findIndex((f)=>f === 'Daily Tasks') >= 0){
                  daily_task += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === 'Daily Tasks')]
                }
                if(d?.datas?.stage?.findIndex((f)=>f === "Calls") >= 0){
                  calls += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === "Calls")]
                }
                if(d?.datas?.stage?.findIndex((f)=>f === 'LOI Discussion') >= 0){
                  loi_discussion += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === 'LOI Discussion')]
                }
                if(d?.datas?.stage?.findIndex((f)=>f === 'Agreement Discussion') >= 0){
                  agreement_discussion += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === 'Agreement Discussion')]
                }
                if(d?.datas?.stage?.findIndex((f)=>f === "Scouting") >= 0){
                  scouting += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === "Scouting")]
                }
                if(d?.datas?.stage?.findIndex((f)=>f === "Walk Ins") >= 0){
                  walkins += d?.datas?.data?.count[d?.datas?.stage?.findIndex((f)=>f === "Walk Ins")]
                }
                
              })

              setwalkins_count(walkins)
              setmeetings_count(meetings)
              setproperty_visit_count(property_visit)
              setloi_discussion_count(loi_discussion)
              setcalls_count(calls)
              setaggrement_discussion_count(agreement_discussion)
              setscouting_count(scouting)
              setdailytask_count(daily_task)

              setagreement_signing_count(loi_agreement)
              setinspection_count(inspection)
              setlead_generated_count(options_shared)
              setpayment_collection_count(payment_collection)
              setlandlord_meetings_count(landlord_meeting)
              setloi_signing_count(loi_signing)
              setdatas(response?.data)
              }
            }
        }else{
          toast.error("Days Difference should be least than 1 month")
        }   
    }

    // console.log("selected_team : ",users)

    async function getweeklyreport(){
      if(search?.from_date1 !== '' || search?.to_date1 !== ''){
        if(user?.roles?.includes('manager')){
          let user_id =  ''
          users?.forEach((u)=>{
            user_id  += u?.value + ','
          })
          if(path === 'weekly_report1'){
            const response = await  GetWeeklyReportService(search?.text,search?.from_date1,search?.to_date1,page, user?.id,search?.stage)
            setdatas(response?.data)
          }else{
            const response = await  GetWeeklyReportService(search?.text,search?.from_date1,search?.to_date1,page, selected_user?.value === undefined ? user_id : selected_user?.value,search?.stage)
            setdatas(response?.data)
          }
        }else if(user?.roles?.includes('reporting_manager')){
          let user_id =  ''
          users?.forEach((u)=>{
            user_id  += u?.value + ','
          })
          if(path === 'weekly_report1'){
            const response = await  GetWeeklyReportService(search?.text,search?.from_date1,search?.to_date1,page, user?.id,search?.stage)
            setdatas(response?.data)
          }else{
            const response = await  GetWeeklyReportService(search?.text,search?.from_date1,search?.to_date1,page, selected_user?.value === undefined ? user_id : selected_user?.value,search?.stage)
            setdatas(response?.data)
          }
        }else{
          if(path === 'weekly_report1'){
            const response = await  GetWeeklyReportService(search?.text,search?.from_date1,search?.to_date1,page, user?.id,search?.stage)
            setdatas(response?.data)
          }else{
            const response = await  GetWeeklyReportService(search?.text,search?.from_date1,search?.to_date1,page,selected_user?.value !== undefined ? selected_user?.value : selected_team === '' ? '' : selected_team?.users_id?.join(','),search?.stage)
            setdatas(response?.data)
          }
        }
      } 
    }

    async function handlechange(v,type){
      if(type === 'team'){
        let team = teams.find((f)=>f?.value === v)
        setselected_team(team)
        setusers(team?.users)
        setselected_user('')
      }else if(type == 'user'){
        let user = users.find((f)=>f?.value === v)
        setselected_user(user)
      } else if(type == 'stage'){
        setsearch({...search,stage:v})
      }  
    }

    const resetfilter=()=>{
        if(user.roles.includes('bd_user') && !user.roles.includes('manager') && !user.roles.includes('admin') && !user.roles.includes('hod')){
          setselected_user({label:user.name,value:user.id})
        }else{
          setselected_user(null)
        }  
        setselected_stage(null)
        setselected_department(null)
        setselected_team(null)
        setsearch({text:'',from_date:'',from_date1:'',to_date:'',to_date1:'',activate:false,stage:''})
        setfilter_drawer(false)
        setdatas({})
        setpage(1) 
    }

    async function zoomfunc(){
      setzoom(!zoom)
      dispatch(fullScreenAction(!zoom))
    }

    async function downloadexcel(){
     if(search?.from_date1 &&  search?.to_date1){
      if(user?.roles?.includes('manager')){
        let user_id =  ''
        users?.forEach((u)=>{
          user_id  += u?.value + ','
        })
        if(path === 'weekly_report1'){
          const response = await  DownloadWeeklyReportService(search?.text,search?.from_date1,search?.to_date1,page, user?.id,search?.stage)
          let d = response?.data
          if(response?.status === 200){
              handleDownload(`${process.env.REACT_APP_BACKEND_IMAGE_URL}${d?.path?.replace('public/','')}`,d?.path?.split('/')[d?.path?.split('/')?.length - 1])
          }
        }else{
          const response = await  DownloadWeeklyReportService(search?.text,search?.from_date1,search?.to_date1,page, selected_user?.value === undefined ? user_id : selected_user?.value,search?.stage)
          let d = response?.data
          if(response?.status === 200){
              handleDownload(`${process.env.REACT_APP_BACKEND_IMAGE_URL}${d?.path?.replace('public/','')}`,d?.path?.split('/')[d?.path?.split('/')?.length - 1])
          }
        }
      }else if(user?.roles?.includes('reporting_manager')){
        let user_id =  ''
        users?.forEach((u)=>{
          user_id  += u?.value + ','
        })
        if(path === 'weekly_report1'){
          const response = await  DownloadWeeklyReportService(search?.text,search?.from_date1,search?.to_date1,page, user?.id,search?.stage)
          let d = response?.data
          if(response?.status === 200){
              handleDownload(`${process.env.REACT_APP_BACKEND_IMAGE_URL}${d?.path?.replace('public/','')}`,d?.path?.split('/')[d?.path?.split('/')?.length - 1])
          }
        }else{
          const response = await  DownloadWeeklyReportService(search?.text,search?.from_date1,search?.to_date1,page, selected_user?.value === undefined ? user_id : selected_user?.value,search?.stage)
          let d = response?.data
          if(response?.status === 200){
              handleDownload(`${process.env.REACT_APP_BACKEND_IMAGE_URL}${d?.path?.replace('public/','')}`,d?.path?.split('/')[d?.path?.split('/')?.length - 1])
          }
        }
      }else{
        if(path === 'weekly_report1'){
          const response = await  DownloadWeeklyReportService(search?.text,search?.from_date1,search?.to_date1,page, user?.id,search?.stage)
          let d = response?.data
          if(response?.status === 200){
              handleDownload(`${process.env.REACT_APP_BACKEND_IMAGE_URL}${d?.path?.replace('public/','')}`,d?.path?.split('/')[d?.path?.split('/')?.length - 1])
          }
        }else{
          const response = await  DownloadWeeklyReportService(search?.text,search?.from_date1,search?.to_date1,page,selected_user?.value !== undefined ? selected_user?.value : selected_team === '' ? '' : selected_team?.users_id?.join(','),search?.stage)
          let d = response?.data
          if(response?.status === 200){
              handleDownload(`${process.env.REACT_APP_BACKEND_IMAGE_URL}${d?.path?.replace('public/','')}`,d?.path?.split('/')[d?.path?.split('/')?.length - 1])
          }
        }
      }  
     }else{
      toast.error("Select From Date and to Date to download Report")
     }
    }

    const handleDownload = (url, filename) => {
      axios.get(url, {
          responseType: 'blob',
      })
          .then((res) => {
              fileDownload(res.data, filename)
          })
    }
 
   
  return (
    <div className='flex max-h-screen min-h-screen overflow-hidden'>
        {zoom_enabled === false && <>
        <div className='w-44 border-r'>
        <DashboardMenu />
        </div>  
        </>}
        <div className={`px-4 ${!zoom_enabled ? 'w-[87%]' : 'w-[100%]'} pt-5`}>
        <div  >
          <div >
            <div className={`border-b flex justify-between pb-2 mb-5`}>
              <div className={`flex items-center mb-1 ${user?.roles?.includes('admin') && 'justify-between'}`}>
              {/* ({datas?.data?.leadoptions.length !== undefined ? datas?.data?.leadoptions.length : 0}) */}
                <h6 className='font-[700] text-[14px] '> Weekly Report  </h6>
              </div>

          
            <div className='flex items-center ' >

                <span className='mr-2'>
                <IconButton onClick={downloadexcel} className='pr-2' ><AiOutlineFileExcel className={'opacity-100 '} size={16} /></IconButton>
                </span>
             
                <span className='mr-5'>
                <IconButton onClick={zoomfunc} className='pr-2' ><CgArrowsExpandUpRight className={'opacity-100 '} size={16} /></IconButton>
                </span>

              

                  <DatePicker size='small'  ampm={false} placeholder='From Date' className='text-[11px] py-[4px]   w-28 border-slate-300 mr-1'  value={search?.from_date} onChange={(v,v1) => {setsearch({...search,from_date:v,from_date1:v1})}} /> 

                  <DatePicker size='small'  ampm={false} placeholder='To Date' className='text-[11px] py-[4px]  w-28 border-slate-300 mr-1'  value={search?.to_date} onChange={(v,v1) => {setsearch({...search,to_date:v,to_date1:v1})}} /> 
                

                  <div className='border border-slate-300 rounded-md py-[2px] mr-[5px]'>
                <Select
                      placeholder='Select Stage'
                      bordered={false}
                      size='small'
                      defaultValue={search.stage == '' ? null : search.stage}
                      value={search.stage == '' ? null : search.stage}
                      style={{ width: 110 }}
                      onChange={(v)=>handlechange(v,'stage')}
                      options={stages}
                  />
                 </div>
                 
                {path !== 'weekly_report1'  &&
                <>

               {(user?.roles?.includes('admin') || user?.roles?.includes('hod')) &&
                <div className='border border-slate-300 rounded-md py-[2px] mr-[5px]'>
                <Select
                      placeholder='Select Team'
                      bordered={false}
                      size='small'
                      defaultValue={selected_team !== '' ? selected_team : null}
                      value={selected_team !== '' ? selected_team : null}
                      style={{ width: 110 }}
                      onChange={(v)=>handlechange(v,'team')}
                      options={teams}
                  />
                 </div>}


                 {(user?.roles?.includes('admin') || user?.roles?.includes('hod') || user?.roles?.includes('manager') || user?.roles?.includes('reporting_manager')) &&
                 <div className='border border-slate-300 h-[30px] rounded-md py-[2px] mr-[5px]'>
                <Select
                      placeholder='Select User'
                      bordered={false}
                      size='small'
                      defaultValue={selected_user !== '' ? selected_user : null}
                      value={selected_user !== '' ? selected_user : null}
                      style={{ width: 110 }}
                      onChange={(v)=>handlechange(v,'user')}
                      options={users}
                  />
                 </div>}
                 </>
                 }
            
            

              {/* <div className='ml-1 rounded-md border border-slate-300 py-[2px]'>
              <Select
                  placeholder='Select Stage'
                  bordered={false}
                  size='small'
                  defaultValue={selected_stage}
                  value={selected_stage}
                  style={{ width: 110 }}
                  onChange={(v)=>handlechange(v,'stages')}
                  options={stages}
              />
              </div> */}

            
              <div className='ml-1 mr-1'>
              <ButtonFilledAutoWidth btnName="Add Filter" onClick={()=>applyfilterfunction(1)} /> 
              </div> 

              <ButtonFilledAutoWidth btnName="Reset Filter" onClick={resetfilter} />

            </div>

          </div>


         
         {/* <h6>{datas?.data?.leads?.length}</h6> */}
         {(search?.from_date1 !== '' && search?.to_date1 !== '' ) &&

          <div className='flex'> 
          <div className={`${(selected_team !== null && selected_stage?.label === "Invoice Raised") ? 'max-h-[60vh]' : 'max-h-[80vh]' } border-slate-300 border-t w-[75%] border-l h-fit border-r overflow-y-scroll`}>
          <div className='sticky top-0 z-50 flex  py-[-5px] border-b bg-gray-100 border-slate-300 relative'>
              {/* <h6 className='sticky top-0 z-50 text-[12px] w-[60px]  px-2 py-1 font-[600] border-slate-300 border-r '>SL No</h6> */}
              <h6 className='sticky top-0 z-50  text-[12px] w-[130px]  px-2 py-1 font-[600] border-slate-300 border-r'>Week</h6>
              <h6 className='sticky top-0 z-50  text-[12px] w-[120px]  px-2 py-1 font-[600] border-slate-300 border-r'>BD Person</h6>
              <h6 className='sticky top-0 z-50  text-[12px] w-[110px]  px-2 py-1 font-[600] border-slate-300 border-r'>New/Old</h6>
              <h6 className='sticky top-0 z-50  text-[12px] w-[110px]  px-2 py-1 font-[600] border-slate-300 border-r'>Activity</h6>
              <h6 className='sticky top-0 z-50  text-[12px] w-[110px] px-2 py-1 font-[600] border-slate-300 border-r'>Date</h6>
              <h6 className='sticky top-0 z-50  text-[12px] w-[230px] px-2 py-1 font-[600] border-slate-300 '>Company Name/Property Name</h6>
          </div>
        
          {/* {datas?.data?.leads?.map((d,i)=>(
          <div key={i}>
          {d?.datas?.data.data?.map((d1,i1)=>( 
          <>
          {d1?.map((d2,i2)=>( 
          <div key={d?._id} className='flex z-50 border-b border-slate-300 '  >
              <h6 className='text-[11px] font-[700] w-[130px]  px-2 py-1 border-r border-slate-300'>{(i == 0 && i1 == 0 & i2 == 0) ? moment(search?.from_date1)?.format('DD-MM-YY') + ' - ' + moment(search?.to_date1)?.format('DD-MM-YY') : ''}</h6>
              <h6 className='text-[11px] font-[700] w-[120px]  px-2 py-1 border-r border-slate-300'>{d?.employee_name}</h6>
              <h6 className='text-[11px] font-[700] w-[110px]  px-2 py-1 border-r border-slate-300'>{'New'}</h6>
              <h6 className='text-[11px] font-[700] w-[110px]  px-2 py-1 border-r border-slate-300'>{d2?.stage?.name}</h6>
              <h6 className='text-[11px] font-[700] w-[110px] px-2 py-1 border-r border-slate-300'>{moment(d2?.createdAt)?.format('DD/MM/YYYY')}</h6>
              <h6 className='text-[11px] font-[700] w-[230px] px-2 py-1 border-slate-300'>{d1[0]?.company_name}</h6>
          </div>
          ))}
          </>
          ))}
          </div>
          ))} */}


          {datas?.data?.leadoptions?.map((d,i)=>(
          <div key={i}>
            {d?.datas?.data?.data?.map((d1,i1)=>( 
            <div key={i1}>
            {d1?.map((d2,i2)=>( 
            <div key={i2} className='flex z-50 border-b border-slate-300 '  >
                {/* <h6 className='text-[11px] font-[700] w-[60px]  px-2 py-1  border-r border-slate-300 '>{ ((i1 == 0 && i == 0 && i2 == 0) ?  i2+1    :   oldarraylength1(i2,i1,i) + i2+1 )}</h6> */}
                <h6 className='text-[11px] font-[700] w-[130px]  px-2 py-1 border-r border-slate-300'>{(i == 0 && i1 == 0 & i2 == 0) ? moment(search?.from_date1)?.format('DD-MM-YY') + ' - ' + moment(search?.to_date1)?.format('DD-MM-YY') : ''}</h6>
                <h6 className='text-[11px] font-[700] w-[120px]  px-2 py-1 border-r border-slate-300'>{d?.employee_name}</h6>
                <h6 className='text-[11px] font-[700] w-[110px]  px-2 py-1 border-r border-slate-300'>{d2?.type}</h6>
                <h6 className='text-[11px] font-[700] w-[110px]  px-2 py-1 border-r border-slate-300'>{d2?.stage?.name === undefined ? 'Meetings' : d2?.stage?.name}</h6>
                <h6 className='text-[11px] font-[700] w-[110px] px-2 py-1 border-r border-slate-300'>{moment(d2?.createdAt)?.format('DD/MM/YYYY')}</h6>
                <h6 className='text-[11px] font-[700] w-[230px] px-2 py-1 border-slate-300'> 
                {(d2?.company_name !== '' && d2?.company_name !== undefined && d2?.company_name !== null) && `Company Name : ${d2?.company_name}`} 
                {(d2?.message !== '' && d2?.message !== undefined && d2?.message !== null) && `Message :  ${d2?.message}`} 
                {(d2?.contact_name !== '' && d2?.contact_name !== undefined && d2?.contact_name !== null) && `Contact Name : ${d2?.contact_name}`} 
                {(d2?.phone !== '' && d2?.phone !== undefined && d2?.phone !== null) && `Phone : ${d2?.phone}`}  
                {(d2?.requirment !== '' && d2?.requirment !== undefined && d2?.requirment !== null) && `Summary : ${d2?.requirment}`}
                
                 </h6>
            </div>
            ))}
            </div>
            ))}
            </div>
            ))}

          {/* {console.log("datas?.data?.leadfollowup_options",datas?.data?.leadfollowup_options)}   */}

          {datas?.data?.leadfollowup_options?.map((d,i)=>(
          <div key={i}>
            {d?.datas?.data?.data?.map((d1,i1)=>( 
            <div key={i1}>
            {d1?.map((d2,i2)=>( 
            <div key={i2} className='flex z-50 border-b border-slate-300 '  >
                {/* <h6 className='text-[11px] font-[700] w-[60px]  px-2 py-1  border-r border-slate-300 '>{ ((i1 == 0 && i == 0 && i2 == 0) ?  i2+1    :   oldarraylength1(i2,i1,i) + i2+1 )}</h6> */}
                <h6 className='text-[11px] font-[700] w-[130px]  px-2 py-1  border-r border-slate-300'>{(datas?.data?.leads?.length === 0 && datas?.data?.leadoptions?.length === 0 && (i == 0 && i1 == 0 & i2 == 0)) ? moment(search?.from_date1)?.format('DD-MM-YY') + ' - ' + moment(search?.to_date1)?.format('DD-MM-YY') : ''}</h6>
                <h6 className='text-[11px] font-[700] w-[120px]  px-2 py-1  border-r border-slate-300'>{d2?.user_id?.name}</h6>
                <h6 className='text-[11px] font-[700] w-[110px]  px-2 py-1  border-r border-slate-300'>{'New'}</h6>
                <h6 className='text-[11px] font-[700] w-[110px]  px-2 py-1  border-r border-slate-300'>{d2?.stage?.name}</h6>
                <h6 className='text-[11px] font-[700] w-[110px] px-2 py-1  border-r border-slate-300'>{moment(d2?.createdAt)?.format('DD/MM/YYYY')}</h6>
                <h6 className='text-[11px] font-[700] w-[230px] px-2 py-1  border-slate-300'>
                 {(d2?.type_id?.company_name !== '' && d2?.type_id?.company_name !== null && d2?.type_id?.company_name !== undefined) && `Company Name : ${d2?.type_id?.company_name} ,`} 
                 {(d2?.type_id?.contact_name !== '' && d2?.type_id?.contact_name !== null && d2?.type_id?.contact_name !== undefined) &&  `Contact Name : ${d2?.type_id?.contact_name},`} 
                 {(d2?.type_id?.requirment !== '' && d2?.type_id?.requirment !== null && d2?.type_id?.requirment !== undefined) &&  `Requirment : ${d2?.type_id?.requirment},`} 
                 {(d2?.type_id?.summary !== '' && d2?.type_id?.summary !== null && d2?.type_id?.summary !== undefined) &&  `Summary : ${d2?.type_id?.summary}`}
                 {(d2?.slots !== '' && d2?.slots !== undefined && d2?.slots !== null) && `Slot : ${d2?.slots}`}
                  </h6>
            </div>
            ))}
            </div>
            ))}
            </div>
            ))}
        </div>



          {/* <div className='ml-10 border-b flex flex-col h-fit border-slate-300 border-t w-[25%] border-l border-r '>
            <div className='sticky top-0 z-50 flex  py-[-5px] border-b bg-gray-100 border-slate-300 relative'>
                <h6 className='sticky top-0 z-50 text-[11px] w-[50%]  px-2 py-1 font-[600] border-slate-300 border-r '>Activity</h6>
                <h6 className='sticky top-0 z-50  text-[11px] w-[50%]  px-2 py-1 font-[600] border-slate-300'>Count</h6>
            </div>

             <div className='z-50 flex  py-[-5px] border-b border-slate-300 relative'>
                <h6 className='z-50 text-[11px] w-[50%]  px-2 py-1 font-[600] border-slate-300 border-r '>Meeting</h6>
                <h6 className='z-50  text-[11px] w-[50%]  px-2 py-1 font-[600] border-slate-300'>{returnCount('Meetings')}</h6>
            </div> 

             <div className='z-50 flex  py-[-5px] border-b border-slate-300 relative'>
                <h6 className='z-50 text-[11px] w-[50%]  px-2 py-1 font-[600] border-slate-300 border-r '>WalkIn</h6>
                <h6 className='z-50  text-[11px] w-[50%]  px-2 py-1 font-[600] border-slate-300'>{returnCount('Walk Ins')}</h6>
            </div> 

            <div className='z-50 flex  py-[-5px] border-b border-slate-300 relative'>
                <h6 className='z-50 text-[11px] w-[50%]  px-2 py-1 font-[600] border-slate-300 border-r '>Inspection</h6>
                <h6 className='z-50  text-[11px] w-[50%]  px-2 py-1 font-[600] border-slate-300'>{returnCount('Inspection')}</h6>
            </div> 

             <div className='z-50 flex  py-[-5px] border-b border-slate-300 relative'>
                <h6 className='z-50 text-[11px] w-[50%]  px-2 py-1 font-[600] border-slate-300 border-r '>Property Visit</h6>
                <h6 className='z-50  text-[11px] w-[50%]  px-2 py-1 font-[600] border-slate-300'>{returnCount('Property Visit')}</h6>
            </div> 

             <div className='z-50 flex  py-[-5px] border-b border-slate-300 relative'>
                <h6 className='z-50 text-[11px] w-[50%]  px-2 py-1 font-[600] border-slate-300 border-r '>Scouting</h6>
                <h6 className='z-50  text-[11px] w-[50%]  px-2 py-1 font-[600] border-slate-300'>{returnCount('Scouting')}</h6>
            </div> 

             <div className='z-50 flex  py-[-5px] border-b border-slate-300 relative'>
                <h6 className='z-50 text-[11px] w-[50%]  px-2 py-1 font-[600] border-slate-300 border-r '>Landlord Meeting</h6>
                <h6 className='z-50  text-[11px] w-[50%]  px-2 py-1 font-[600] border-slate-300'>{returnCount('Landlord Meeting')}</h6>
            </div> 

            <div className='z-50 flex  py-[-5px] border-b border-slate-300 relative'>
                <h6 className='z-50 text-[11px] w-[50%]  px-2 py-1 font-[600] border-slate-300 border-r '>LOI Signing</h6>
                <h6 className='z-50  text-[11px] w-[50%]  px-2 py-1 font-[600] border-slate-300'>{returnCount('LOI Signing')}</h6>
            </div> 

            <div className='z-50 flex  py-[-5px] border-b border-slate-300 relative'>
                <h6 className='z-50 text-[11px] w-[50%]  px-2 py-1 font-[600] border-slate-300 border-r '>Agreement Signing</h6>
                <h6 className='z-50  text-[11px] w-[50%]  px-2 py-1 font-[600] border-slate-300'>{returnCount('LOI/Agreement')}</h6>
            </div> 

            <div className='z-50 flex  py-[-5px] border-b border-slate-300 relative'>
                <h6 className='z-50 text-[11px] w-[50%]  px-2 py-1 font-[600] border-slate-300 border-r '>Payment collection</h6>
                <h6 className='z-50  text-[11px] w-[50%]  px-2 py-1 font-[600] border-slate-300'>{returnCount('Payment Collection')}</h6>
            </div> 

            <div className='z-50 flex  py-[-5px] border-b border-slate-300 relative'>
                <h6 className='z-50 text-[11px] w-[50%]  px-2 py-1 font-[600] border-slate-300 border-r '>Lead Generated</h6>
                <h6 className='z-50  text-[11px] w-[50%]  px-2 py-1 font-[600] border-slate-300'>{returnCount('Options Shared')}</h6>
            </div> 

            <div className='z-50 flex  py-[-5px] border-b border-slate-300 relative'>
                <h6 className='z-50 text-[11px] w-[50%]  px-2 py-1 font-[600] border-slate-300 border-r '>LOI Discussion</h6>
                <h6 className='z-50  text-[11px] w-[50%]  px-2 py-1 font-[600] border-slate-300'>{returnCount('LOI Discussion')}</h6>
            </div> 

            <div className='z-50 flex  py-[-5px]  relative'>
                <h6 className='z-50 text-[11px] w-[50%]  px-2 py-1 font-[600] border-slate-300 border-r '>Agreement Discussion</h6>
                <h6 className='z-50  text-[11px] w-[50%]  px-2 py-1 font-[600] border-slate-300'>{returnCount('Agreement Discussion')}</h6>
            </div> 

            

          </div> */}

<div className='ml-10 border-b flex flex-col h-fit border-slate-300 border-t w-[25%] border-l border-r '>
            <div className='sticky top-0 z-50 flex  py-[-5px] border-b bg-gray-100 border-slate-300 relative'>
                <h6 className='sticky top-0 z-50 text-[11px] w-[50%]  px-2 py-1 font-[600] border-slate-300 border-r '>Activity</h6>
                <h6 className='sticky top-0 z-50  text-[11px] w-[50%]  px-2 py-1 font-[600] border-slate-300'>Count</h6>
            </div>

             <div className='z-50 flex  py-[-5px] border-b border-slate-300 relative'>
                <h6 className='z-50 text-[11px] w-[50%]  px-2 py-1 font-[600] border-slate-300 border-r '>Meeting</h6>
                <h6 className='z-50  text-[11px] w-[50%]  px-2 py-1 font-[600] border-slate-300'>{meetings_count}</h6>
            </div> 

             <div className='z-50 flex  py-[-5px] border-b border-slate-300 relative'>
                <h6 className='z-50 text-[11px] w-[50%]  px-2 py-1 font-[600] border-slate-300 border-r '>WalkIn</h6>
                <h6 className='z-50  text-[11px] w-[50%]  px-2 py-1 font-[600] border-slate-300'>{walkins_count}</h6>
            </div> 

            <div className='z-50 flex  py-[-5px] border-b border-slate-300 relative'>
                <h6 className='z-50 text-[11px] w-[50%]  px-2 py-1 font-[600] border-slate-300 border-r '>Inspection</h6>
                <h6 className='z-50  text-[11px] w-[50%]  px-2 py-1 font-[600] border-slate-300'>{inspection_count}</h6>
            </div> 

             <div className='z-50 flex  py-[-5px] border-b border-slate-300 relative'>
                <h6 className='z-50 text-[11px] w-[50%]  px-2 py-1 font-[600] border-slate-300 border-r '>Property Visit</h6>
                <h6 className='z-50  text-[11px] w-[50%]  px-2 py-1 font-[600] border-slate-300'>{property_visit_count}</h6>
            </div> 

             <div className='z-50 flex  py-[-5px] border-b border-slate-300 relative'>
                <h6 className='z-50 text-[11px] w-[50%]  px-2 py-1 font-[600] border-slate-300 border-r '>Scouting</h6>
                <h6 className='z-50  text-[11px] w-[50%]  px-2 py-1 font-[600] border-slate-300'>{scouting_count}</h6>
            </div> 

             <div className='z-50 flex  py-[-5px] border-b border-slate-300 relative'>
                <h6 className='z-50 text-[11px] w-[50%]  px-2 py-1 font-[600] border-slate-300 border-r '>Landlord Meeting</h6>
                <h6 className='z-50  text-[11px] w-[50%]  px-2 py-1 font-[600] border-slate-300'>{landlord_meetings_count}</h6>
            </div> 

            <div className='z-50 flex  py-[-5px] border-b border-slate-300 relative'>
                <h6 className='z-50 text-[11px] w-[50%]  px-2 py-1 font-[600] border-slate-300 border-r '>LOI Signing</h6>
                <h6 className='z-50  text-[11px] w-[50%]  px-2 py-1 font-[600] border-slate-300'>{loi_signing_count}</h6>
            </div> 

            <div className='z-50 flex  py-[-5px] border-b border-slate-300 relative'>
                <h6 className='z-50 text-[11px] w-[50%]  px-2 py-1 font-[600] border-slate-300 border-r '>Agreement Signing</h6>
                <h6 className='z-50  text-[11px] w-[50%]  px-2 py-1 font-[600] border-slate-300'>{agreement_signing_count}</h6>
            </div> 

            <div className='z-50 flex  py-[-5px] border-b border-slate-300 relative'>
                <h6 className='z-50 text-[11px] w-[50%]  px-2 py-1 font-[600] border-slate-300 border-r '>Payment collection</h6>
                <h6 className='z-50  text-[11px] w-[50%]  px-2 py-1 font-[600] border-slate-300'>{payment_collection_count}</h6>
            </div> 

            <div className='z-50 flex  py-[-5px] border-b border-slate-300 relative'>
                <h6 className='z-50 text-[11px] w-[50%]  px-2 py-1 font-[600] border-slate-300 border-r '>Lead Generated</h6>
                <h6 className='z-50  text-[11px] w-[50%]  px-2 py-1 font-[600] border-slate-300'>{lead_generated_count}</h6>
            </div> 

            <div className='z-50 flex  py-[-5px] border-b border-slate-300 relative'>
                <h6 className='z-50 text-[11px] w-[50%]  px-2 py-1 font-[600] border-slate-300 border-r '>LOI Discussion</h6>
                <h6 className='z-50  text-[11px] w-[50%]  px-2 py-1 font-[600] border-slate-300'>{loi_discussion_count}</h6>
            </div> 

            <div className='z-50 flex  py-[-5px]  relative'>
                <h6 className='z-50 text-[11px] w-[50%]  px-2 py-1 font-[600] border-slate-300 border-r '>Agreement Discussion</h6>
                <h6 className='z-50  text-[11px] w-[50%]  px-2 py-1 font-[600] border-slate-300'>{aggrement_discussion_count}</h6>
            </div> 

            

          </div>

          </div>}

          
          {datas?.datas?.length === 0 &&
            <div className='grid place-items-center mt-20  items-center justify-center'>
            <img src={'https://fidecrmfiles.s3.amazonaws.com/NoDataFound1.png'} alt="no" className='w-40 h-40 object-contain' /> 
            <h6 className='font-bold text-[14px] -mt-10'>No data found</h6>
            <h6 className='font-[500] w-[70%] text-center text-slate-700 text-[12.5px] -mt-2'>Oops we couldn't find any data based on your current stage please add the data to see list here.</h6>
          </div>
          }


          </div>

        


        </div>

        </div>

    </div>
  )
}

export default WeeklyReport