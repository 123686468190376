import React, { useEffect, useState } from 'react'
import FcaresMenu from '../FcaresMenu'
import { DeleteFcaresUserService, GetFcaresUserService } from '../../../services/Fcares/FCaresUserServices'
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../../components/button'
import { DatePicker, Modal } from 'antd'
import { BsArrowRepeat } from 'react-icons/bs'
import {FiChevronRight,FiChevronLeft} from 'react-icons/fi';
import { IconButton, Tooltip, setRef } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai'
import { BiCheckbox,BiCheckboxSquare } from "react-icons/bi";
import moment from 'moment'
import toast from 'react-hot-toast'
import { DeleteFcaresService, GetFcaresService } from '../../../services/Fcares/FCaresServices'


function FCaresServicesList() {

  const navigate = useNavigate()

  const [data,setdata] = useState({datas:[],pagination:{total:0}})
  const [selecteddata,setselecteddata] = useState({})
  const [step,setstep] = useState(1)
  const [modal,setmodal] = useState(false)
  const [page,setpage] = useState(1)
  const [search,setsearch] = useState({text:'',from_date:'',from_date1:'',to_date:'',to_date1:'',zone:'',country:'',step:1})



  useEffect(()=>{
    getData()
  },[step,page])


  async function getData(){
    const response = await GetFcaresService(search?.text,search?.from_date1,search?.to_date1,page)
    setdata(response?.data?.data)
  }


  async function applyfilterfunction(){
    const response = await GetFcaresService(search?.text,search?.from_date1,search?.to_date1,1)
    setdata(response?.data?.data)
  }


  async function resetfunc(){
    setsearch({text:'',from_date:'',from_date1:'',to_date:'',to_date1:''})
    setpage(1)
    const response = await GetFcaresService('','','',1)
    setdata(response?.data?.data)
  }

  async function deleteFunc(){
   const response = await DeleteFcaresService(selecteddata?._id)
   if(response?.status === 200){
      getData()
      toast.success("Services Deleted Successfully")
      setmodal(false)
   }else{
    getData()
      toast.success("Amenities Deleted Successfully")
      setmodal(false)
   }
  }

  return (
    <div className='w-[100%] h-[100%] overflow-hidden'>
        <div className='flex'>
            <div className='min-w-44'>
            <FcaresMenu />
            </div>

            <Modal open={modal} width={350} closable={false} footer={false} className='absolute top-0 left-[40%]'>
               
                <h6 className="font-bold text-[13px]  mb-2 w-full">Delete Data</h6>

                <h6 className='text-[10px] font-[500] p-[5px] bg-slate-100 mb-1'>Are you sure want to delete the selected data once deleted will not be retrieved!</h6>
                <h6 className='font-[700] text-[12px]'>Selected Fcares</h6>

                <div className='text-[11px] mb-4'>
                  <h6>Name :<span className='font-[700]'>{selecteddata?.name}</span>  Type : <span className='font-[700]'>{selecteddata?.type}</span> </h6>
                </div>
                <div className='mt-2 flex items-center'>
                  <ButtonOutlinedAutoWidth btnName="Close" onClick={()=>setmodal(false)}/>
                  <div className='ml-2'>
                  <ButtonFilledAutoWidth btnName="Save" onClick={()=>deleteFunc()} />
                  </div>
                </div>
           </Modal> 

            <div className='w-[88%] px-5 pt-5'>
              {/* <div className='flex items-center justify-center'>
              
              </div> */}

              <div className='flex items-center justify-between border-b pb-2 mb-2'>
              <h6 className='font-[700] text-[14px] '>Total Services Data ({data?.pagination?.total})</h6>
              <div className='flex items-center'>
                <div className='flex items-center text-[12px] mr-2'>

                

                  <h6 className='mr-2 font-[600]'>{page == 1 ? data?.datas?.length > 0 ? 1 : 0 :  (page - 1) * data?.pagination?.limit } - {data?.pagination?.limit} of {data?.pagination?.total} </h6>
                  <IconButton onClick={resetfunc}><BsArrowRepeat size={16} /></IconButton>

                  <div>
                  <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>
                  <IconButton onClick={()=>{ page < data?.pagination?.totalPages  ? setpage(page+1) : console.log('')}}><FiChevronRight className={`${(data?.pagination?.totalPages === page || data?.datas?.length === 0)  ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>

                  </div>
                </div>


                <DatePicker size='small' style={{fontSize:'11px'}} ampm={false} placeholder='From Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.from_date} onChange={(v,v1) => {console.log('v na',new Date(v).toLocaleDateString());setsearch({...search,from_date:v,from_date1:v1})}} /> 

                <DatePicker size='small' style={{fontSize:'11px'}} ampm={false} placeholder='To Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.to_date} onChange={(v,v1) => {console.log('v na',new Date(v).toLocaleDateString());setsearch({...search,to_date:v,to_date1:v1})}} /> 

              
              <div >
                  

                <input  id="search_text" placeholder='Search text' type='text' value={search.text} onChange={(e)=>setsearch({...search,text:e.target.value})} className='border py-[3px] focus:ring-0 mr-2 focus:outline-0 text-[14px]  w-28 px-1 rounded-md border-slate-300' />
              
              
              </div> 
              {/* <Select 
                bordered={false}
                value={search?.zone}
                options={options}
                onChange={(e)=>setsearch({...search,zone:e})}
                placeholder={'Zone'}
                className='border rounded-[6px] border-slate-300 h-[30px] mx-2 w-[100px]' 
                
                />      */}

            <ButtonOutlinedAutoWidth btnName="Add Filter" onClick={()=>applyfilterfunction(1)} /> 


            <div className='ml-2'>
            <ButtonFilledAutoWidth btnName="Add Data" onClick={()=>navigate('create')}/> 
            </div>
              </div>
          </div>

             
              {data?.datas?.length > 0 &&
              <div className='grid grid-cols-6 gap-2'>
                {data?.datas?.map((d)=>(
                    <div className='border relative p-[10px]'> 
                        <div className='flex absolute top-0 right-0  bg-slate-100 items-center'>
                        <AiOutlineDelete size={16} className='p-[3px]' onClick={()=>{setselecteddata(d);setmodal(true)}} />
                        <AiOutlineEdit className='p-[3px] border-l' size={16} onClick={()=>navigate('/fcares/services_list/edit',{state:d?._id})} />
                        </div>
                     <img src={`${process.env.REACT_APP_BACKEND_IMAGE_URL}${d?.image}`} className='h-[90px] w-[100%] object-cover' />   
                     <h6 className='text-[12px] font-[700] capitalize'>{d?.name}</h6>
                     <h6 className='text-[10px]'>Type : {d?.type} / Sort : {d?.sort}</h6>
                     <h6 className='text-[10px] bg-slate-100 p-[4px] mt-[4px]'>Created AT : {moment(d?.createdAt)?.format('ll')}</h6>
                    </div>    
                ))}

              </div>  
             } 

                <div className='flex items-center justify-center'>
                  {data?.datas?.length === 0 &&
                  <div className='flex flex-col mt-20 w-[40%] items-center justify-center'>
                  <img src={'https://fidecrmfiles.s3.amazonaws.com/NoDataFound1.png'} className='w-40 h-40 object-contain' /> 
                  <h6 className='font-bold text-[14px] -mt-10'>No data found</h6>
                  <h6 className='font-[500] text-center w-[70%] text-slate-700 text-[12.5px] '>Oops we couldn't find any "Employee" onboarded yet click on the add option to create a new user for your fcares app access.</h6>
                   </div>
                  }
              </div>

            </div>
        </div>
    </div>
  )
}

export default FCaresServicesList