import { deleteRequest, get, post, put } from "../../helpers/apihelpers";

export const CreateAtsTrackService=async(data)=>{
    try {
        const result = await post(`api/hr/track/create`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateAtsTrackService=async(data,id)=>{
    try {
        const result = await put(`api/hr/track/update/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetAtsTrackService=async({page,stage})=>{
    try {
        const result = await get(`api/hr/track/get?page=${page}&stage=${stage}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteAtsTrackBasedEmployeeService=async(id,body)=>{
    try {
        const result = await put(`api/hr/track/delete_employee_based_client/${id}`,body);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetAtsJobDetailService=async(id)=>{
    try {
        const result = await get(`api/hr/track/get/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const FilterAtsTrackService=async({text,from_date,to_date,page})=>{
    try {
        const result = await get(`api/hr/track/filter?search_text=${text}&from_date=${from_date}&to_date=${to_date}&page=${page}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteAtsTrackService=async(id)=>{
    try {
        const result = await deleteRequest(`api/hr/track/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}