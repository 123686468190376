import { deleteRequest, get, post, postfd, put } from "../../helpers/apihelpers";

export const CreateFidelitusCorpEventService=async(data)=>{
    try {
        const result = await post(`api/corp/events/create`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateFidelitusCorpEventService=async(data,id)=>{
    try {
        const result = await put(`api/corp/events/update/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetFidelitusCorpEventService=async(page,text,from_date,to_date,step)=>{
    try {
        const result = await get(`api/corp/events/get?page=${page}&text=${text}&from_date=${from_date}&to_date=${to_date}&step=${step}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteFidelitusCorpEventService=async(id)=>{
    try {
        const result = await deleteRequest(`api/corp/events/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DownloadFidelitusCorpEventService=async(page,text,from_date,to_date,)=>{
    try {
        const result = await get(`api/corp/events/download_data?page=${page}&text=${text}&from_date=${from_date}&to_date=${to_date}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UploadFidelitusCorpEventService=async(data)=>{
    try {
        const result = await postfd(`api/corp/events/upload_file`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}