import React from 'react';
import {RiUser6Line,RiSettingsLine,RiNotification3Line,RiBrush4Line} from 'react-icons/ri';
import {IoAccessibilityOutline} from 'react-icons/io5';
import {BsShieldLock} from 'react-icons/bs'
import { useLocation,useNavigate } from 'react-router-dom';
import { FaRegFile } from "react-icons/fa";
import { HiOutlineRectangleGroup } from "react-icons/hi2";
import { FcMoneyTransfer } from "react-icons/fc";
import { LiaAddressBookSolid } from "react-icons/lia";
import { useSelector } from 'react-redux';
// import {DatePicker} from 'antd';
import { BsFuelPumpDiesel } from "react-icons/bs";



function ProfileMenu() {

  const {pathname} = useLocation();
  const user = useSelector(state=>state.Auth)

  const navigate = useNavigate();

  const menu = [
    {name:'Public profile',icon:RiUser6Line,path:'/profile/basic',id:1,},
    {name:'Account',icon:RiSettingsLine,path:'/profile/account',id:2},
    {name:'More Info',icon:FaRegFile,path:'/profile/documents',id:7},
    {name:"Edit Profile",icon:LiaAddressBookSolid,path:'/profile/edit_profile',id:7},
    {name:'Collegues',icon:HiOutlineRectangleGroup,path:'/profile/collegues',id:8},
    {name:'Reimbusment',icon:FcMoneyTransfer,path:'/profile/reimbusment',id:6},
    {name:'Conveyance Form',icon:BsFuelPumpDiesel,path:'/profile/conveyance_vochuer',id:6},
   
    {name:'Appearance',icon:RiBrush4Line,path:'/profile/appearance',id:3},
    {name:'Accessibility',icon:IoAccessibilityOutline,path:'/profile/accessibility',id:4},
    {name:'Notifications',icon:RiNotification3Line,path:'/profile/notifications',id:5}
  ]  
  
  const menu1 = [
    {name:'Password Update',icon:BsShieldLock,path:'/profile/update_password',id:1},
    {name:'Organization',icon:RiSettingsLine,path:'/profile/organiztion',id:2},
  ] 

  const menu2 = [
    {name:'Auto Task List',icon:BsShieldLock,path:'/profile/auto_task_list',id:1},
  ] 


  return (
    <div className='mr-0 min-h-screen max-h-sceen border-r overflow-hidden min-w-[170px] max-w-[170px] w-[170px] px-2 pt-5'>

        <div className=' mb-4'>
        <h6 className='mb-2 font-[700] text-[12px]'>Menu</h6>
            {menu.map((m)=>(
              <>
              {(m?.path === '/profile/edit_profile' && user?.edit_info) ?
                <div key={m?.path} className={`flex items-center  my-1 hover:bg-[#f4f5f7] px-1.5 py-1 rounded-md relative cursor-pointer ${pathname ===  m.path && 'bg-[#f4f5f7]'}`} onClick={()=>navigate(m?.path)}>
                 {pathname ===  m.path &&  <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3' ></span>}
                 <m.icon color='#000' style={{backgroundColor:m?.color,padding:'2px'}} size={16}  />
                 <span className='text-[#000] font-[500] ml-2 text-[12px]'>{m.name}</span>
                </div>   
                :
                <>
                {(m?.path === '/profile/edit_profile' && !user?.edit_info) ? <></> :
                <div key={m?.path} className={`flex items-center  my-1 hover:bg-[#f4f5f7] px-1.5 py-1 rounded-md relative cursor-pointer ${pathname ===  m.path && 'bg-[#f4f5f7]'}`} onClick={()=>navigate(m?.path)}>
                {pathname ===  m.path &&  <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3' ></span>}
                <m.icon color='#000' style={{backgroundColor:m?.color,padding:'2px'}} size={16}  />
                <span className='text-[#000] font-[500] ml-2 text-[12px]'>{m.name}</span>
                </div>}
                </>
              }
              </>  
            ))}
        </div>

        <div className='border-t pt-4'>
            <h6 className='mb-2 font-[700] text-[12px]'>Access</h6>
            {menu1.map((m)=>(
                <div key={m?.path} className={`flex items-center my-1 hover:bg-[#f4f5f7] px-1.5 py-1  rounded-md relative cursor-pointer ${pathname ===  m.path && 'bg-[#f4f5f7]'}`} onClick={()=>navigate(m?.path)}>
                {pathname ===  m.path &&  <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3' ></span>}
               <m.icon color='#000' style={{backgroundColor:m?.color,padding:'2px'}} size={16}  />
               <span className='text-[#000] font-[500] ml-2 text-[12px]'>{m.name}</span>
              </div>    
            ))}
        </div>

       {user?.roles?.includes('it_tricks') &&
        <div className='border-t pt-4'>
            <h6 className='mb-2 font-[700] text-[12px]'>It Tricks</h6>
            {menu2.map((m)=>(
                <div key={m?.path} className={`flex items-center my-1 hover:bg-[#f4f5f7] px-1.5 py-1  rounded-md relative cursor-pointer ${pathname ===  m.path && 'bg-[#f4f5f7]'}`} onClick={()=>navigate(m?.path)}>
                {pathname ===  m.path &&  <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3' ></span>}
               <m.icon color='#000' style={{backgroundColor:m?.color,padding:'2px'}} size={16}  />
               <span className='text-[#000] font-[500] ml-2 text-[12px]'>{m.name}</span>
              </div>    
            ))}
        </div>}
    </div>
  )
}

export default ProfileMenu