import React from 'react'
import { useLocation } from 'react-router-dom'

import AssignLeadMenu from './AssignLeadMenu'
import AssignLeadlist from './AssignLeadlist'
import ManageLeadlist from './ManageLeadList'

function AssignLeadBase() {
  const {pathname} = useLocation()

  const path1 = pathname.split('/')[2]

  return (
    <div className='h-screen max-h-screen box-border overflow-hidden'>


        <div className='block sm:flex'>
        <AssignLeadMenu />

        <div className='w-[86%]  ml-4  mt-4'>
         {path1 !== 'manage_leads' ?  
          <AssignLeadlist />  
          :
          <ManageLeadlist />}
        </div>    

    </div>    
    </div>
  )
}

export default AssignLeadBase