import { deleteRequest, get, post } from "../../helpers/apihelpers";


export const CreateGalleryArtistList=async(data)=>{
    try {
        const result = await post(`api/gallery_artist/create`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateGalleryArtistList=async(id,data)=>{
    try {
        const result = await post(`api/gallery_artist/update/${id}`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetGalleryArtistList=async(page)=>{
    try {
        const result = await get(`api/gallery_artist/get/null/${page}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const SearchGalleryArtistService = async({search_text,from_date,to_date,page})=>{
    try {
        const result = await get(`api/gallery_artist/filter?search_text=${search_text}&from_date=${from_date}&to_date=${to_date}&page=${page}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteGalleryArtistService = async(id) => {
    try {
        const result = await deleteRequest(`api/gallery_artist/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

