import React,{useState,useEffect} from 'react'
import { useLocation,useNavigate } from 'react-router-dom'

import {
    IconButton,Tooltip
  } from "@mui/material";
import { Select,Modal } from 'antd';
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../components/button';
import { DatePicker } from 'antd'
import { useSelector } from 'react-redux';
import DashboardMenu from '../dashboard/DashboardMenu';
import {BsArrowRepeat} from 'react-icons/bs';
import {FiChevronRight,FiChevronLeft} from 'react-icons/fi';
import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai';
import fileDownload from "js-file-download";
import { toast } from 'react-hot-toast';
import axios from 'axios';

import { DeleteCallService, GetAdminCallService } from '../../services/CallsLeadServices';
import { GetDepartmentService, GetUsersByDepartment } from '../../services/DepartmentServices';
import { GetTeamBasedOnYearInsentiveService, GetTeamMembersDetail } from '../../services/AdminServicesfile/TeamServices';


function AdminCalls() {

    const location = useLocation();
    const user = useSelector(state=>state.Auth)
    const [delete_modal,setdelete_modal] = useState(false)


    let year = new Date().getFullYear().toString()
    const navigate = useNavigate();

    const [data,setdata]  = useState([])

    const [selected_department,setselected_department] = useState(null)
    const [selected_team,setselected_team] = useState(null)
    const [selected_user,setselected_user] = useState(null)
    const [selected_stage,setselected_stage] = useState(null)
    const [selected_lead,setselected_lead] = useState({})


    const [departments,setdepartments] = useState([])
    const [teams,setteams] = useState([])
    const [users,setusers] = useState([])
    const [page,setpage] = useState(1)


    const [search,setsearch] = useState({text:'',stage:'',type:'2',from_date:'',from_date1:'',to_date:'',to_date1:'',activate:false,zone:'',selected_user:''})
  


    useEffect(()=>{
      getdepartment()
      getTeamMembersForCurrentYear()
    },[])
  
    useEffect(()=>{
        if(users.length > 0){
          getcalls(1)
        }
    },[location.pathname,users])

    useEffect(()=>{
      if(search?.activate){
        applyfilterfunction(page)
      }else{
        getcalls(page) 
      }  
    },[page,selected_user,selected_department,selected_stage])



    // console.log("users kp",user?.department_id[0])

    async function getdepartment(){
      const response = await GetDepartmentService();
      let arr = []
      
      response.data.datas.forEach((d)=>{
        // && d?.department_name !== 'Corp Team'
        if(d?.department_name !== 'Finance Team' && d?.department_name !== 'Gallery Team'  && d?.department_name !== 'Shilpa Foundation'){
          arr.push({label:d?.department_name,value:d?.id})
        }  
      })

      if(user?.roles?.includes('hod')){
        getuserbaseddepartment(user?.department_id[0]?.id)
        //  getTea
      }
      setdepartments(arr)
      }

      async function getuserbaseddepartment(id){
         const response = await GetUsersByDepartment(id) 
          let arr = []
          response.data.datas.forEach((d)=>{
              arr.push({label:d?.name,value:d?._id})
          })

           const response1 = await GetTeamBasedOnYearInsentiveService(id)
             let teams_arr = []
             response1?.data?.datas?.forEach((d)=>{
              let users_arr = []
              let users_arr_id = []
               d?.users?.forEach((d1)=>{
                users_arr?.push({label:d1?.name,value:d1?._id})
                users_arr_id.push(d1?._id)
               })
              teams_arr?.push({value:d._id,label:d?.name,users:users_arr,users_id:users_arr_id})
            })
            setteams(teams_arr)
        setusers(arr)
      }



    
   


    async function applyfilterfunction(page){
      setsearch({...search,activate:true})
      let users_list = ''
      if(users?.length > 0){
        users?.forEach((u)=>{
          if(users.length > 0){
          if(users_list === ''){
            users_list = u?.value
            }else{
                users_list = users_list + ',' + u?.value
            }  
        }
        })                  
      }else{
          users_list = search?.user?.value !== undefined ? search?.user?.value  : '' 
      }
      const response =  await GetAdminCallService(1,search?.stage?.label !== undefined ? search?.stage?.label : '',search?.type,search?.from_date1,search?.to_date1,search?.text,users_list);
      setdata(response?.data)
      setpage(1)
    }

    async function getTeamMembersForCurrentYear(){
      if(user.roles.includes('manager')){
        const response = await GetTeamMembersDetail(user?.id,year-1)
        let arr = []
        response?.data?.datas[0]?.users?.forEach((d)=>{
            arr.push({label:d?.name,value:d?._id})
        })
        setusers(arr)
      }
    }  

    
  
  
    async function getcalls(page){
        let users_list = ''
        if(users?.length > 0){
          users?.forEach((u)=>{
           if(users.length > 0){
           if(users_list === ''){
             users_list = u?.value
             }else{
                 users_list = users_list + ',' + u?.value
             }  
          }
          })                  
        }else{
            users_list = selected_user?.value
        }
        const response =  await GetAdminCallService(page,search?.stage?.label !== undefined ? search?.stage?.label : '',search?.type,search?.from_date1,search?.to_date1,search?.text,users_list);
        setdata(response?.data)
    }

   
  
    

    async function handlechange(val,type){
      if(type === 'user'){
        setpage(1)
        setsearch({...search,user:users.find((d)=>d.value === val)})
      }else if(type === 'stage'){
        setsearch({...search,stage:call_stages.find((d)=>d.value === val)})
      }else if(type === 'department'){
        setselected_team(null)
        getuserbaseddepartment(departments.find((d)=>d.value === val)?.value)
        setselected_department(departments.find((d)=>d.value === val))
      }else if(type === 'team'){
        const team = teams.find((d)=>d.value === val)
        setselected_team(team)
        setusers(team?.users)
      }
    }

    console.log("users",users)


    const resetfilter=async()=>{
        setselected_team(null)
        setsearch({text:'',stage:'',type:'2',from_date:'',from_date1:'',to_date:'',to_date1:'',activate:false,zone:'',selected_user:''})
        setpage(1)
        let users_list = ''

        
        if(user?.roles?.includes('hod')){
          getuserbaseddepartment(user?.department_id[0]?.id)
        }else{
          if(users?.length > 0){
            users?.forEach((u)=>{
            if(users.length > 0){
            if(users_list === ''){
              users_list = u?.value
              }else{
                  users_list = users_list + ',' + u?.value
              }  
            }
            })                  
          }else{
              users_list = selected_user?.value
          }
        }
        

        const response =  await GetAdminCallService(page,'','','','','',users_list);
        setdata(response?.data)
        
    }


  async function deleteData(d){
    const response = await DeleteCallService(d)
    if(response.status === 200){
      toast.success("Deleted Successfully")
      // setdelete_modal(false)
      getcalls(page)
    }
  }

  const call_stages = [
    {label:'Not Recieved',value:'Not Recieved'},
    {label:'Invalid No',value:'Invalid No'},
    {label:'Not Interested',value:'Not Interested'},
    {label:'Lead Generated',value:'Lead Generated'},
    {label:'Not a Concern Person',value:'Not a Concern Person'}
  ]

  console.log("selected_department",selected_department)

  return (
    <div style={{width:'100%',minHeight:'100vh',maxHeight:'100vh',height:'100%',overflow:'hidden',position:'relative'}}>
    
    <Modal open={delete_modal} width={350} closable={false} footer={false} className='absolute top-0 left-[40%]'>
          <h6 className="font-bold text-[13px]  mb-2 w-full">Delete Data</h6>
          <h6 className="text-[11px]  mb-2 w-full">Are you sure want to delete the selected data which has company name : <span className='font-[800]'>{selected_lead?.company_name}</span></h6>

          <div className='mt-2 flex items-center'>
            <ButtonOutlinedAutoWidth btnName="Close" onClick={()=>setdelete_modal(false)}/>
            <div className='ml-2'>
            <ButtonFilledAutoWidth btnName="Save" onClick={()=>deleteData(selected_lead?._id)} />
            </div>
          </div>
      </Modal> 


    <div > 
        <div className='flex'>
        <div className='w-44 border-r'>
        <DashboardMenu />
        </div>  
        <div className='px-3 w-[87%] pt-3'>
        <div  >
          <div >
            <div className={`flex ${user?.roles?.includes('admin') ? 'flex-col mb-[10px]' : 'flex-row items-center'}  justify-between border-b pb-2 mb-5`}>

            <div className={`flex ${user?.roles?.includes('admin') ? 'flex-row mb-[10px]' : 'flex-row items-center'}  justify-between`}>
             <h6 className='font-[700] text-[14px] '>Team Calls ({data?.pagination?.total})</h6>


          
             <div className={`flex items-center ${user?.roles?.includes('admin') ? 'justify-between' : 'justify-end'}`} >
            <div className='flex items-center  text-[12px] mr-2'>
                <h6 className='mr-2 font-[600]'>{page === 1 ? data?.datas?.length > 0 ? 1 : 0 : (page - 1) * data?.pagination?.limit } - {data?.pagination?.limit} of {data?.pagination?.total} </h6>
                <IconButton  onClick={resetfilter} ><BsArrowRepeat size={16} /></IconButton>

                <div>
                <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>
                <IconButton onClick={()=>{ page < data?.pagination?.totalPages  ? setpage(page+1) : console.log('')}} ><FiChevronRight className={`${(data?.pagination?.totalPages === page || data?.datas?.length === 0) ? 'opacity-50' : 'opacity-100'}`} size={16} /></IconButton>
                </div>
            </div>
            </div> 
           </div> 

            <div className='flex'>

            


                <DatePicker size='small'  ampm={false} placeholder='From Date' className='text-[11px] py-[4px]   w-28 border-slate-300 mr-1'  value={search?.from_date} onChange={(v,v1) => {setsearch({...search,from_date:v,from_date1:v1})}} /> 

                <DatePicker size='small'  ampm={false} placeholder='To Date' className='text-[11px] py-[4px]  w-28 border-slate-300 mr-1'  value={search?.to_date} onChange={(v,v1) => {setsearch({...search,to_date:v,to_date1:v1})}} /> 
                <input id="search_text" type='text' value={search.text} placeholder='Search text' onChange={(e)=>setsearch({...search,text:e.target.value})} className='border py-[4px] focus:ring-0 mr-1 focus:outline-0 text-[14px]  w-28 px-1 rounded-md border-slate-300' />

          
          {(user?.roles?.includes('admin') || user?.roles?.includes('hod'))  &&
          <>
          {user?.roles?.includes('admin') &&
          <div className='border border-slate-300 rounded-md mr-[4px] py-[2px]'>
            <Select
                placeholder='Select Department'
                bordered={false}
                size='small'
                defaultValue={selected_department}
                value={selected_department}
                style={{ width: 110 }}
                onChange={(v)=>handlechange(v,'department')}
                options={departments}
            />
          </div>}

          {(user?.department_id[0]?.department_name === 'Transaction Team' || selected_department?.label === 'Transaction Team') &&
          <div className='border border-slate-300 rounded-md mr-[4px] py-[2px]'>
            <Select
                placeholder='Select Team'
                bordered={false}
                size='small'
                defaultValue={selected_team}
                value={selected_team}
                style={{ width: 110 }}
                onChange={(v)=>handlechange(v,'team')}
                options={teams}
            />
          </div>}
          </>}
          <div className='border border-slate-300  rounded-md py-[2px]'>
            <Select
                placeholder='Select Stage'
                bordered={false}
                size='small'
                defaultValue={search?.stage === '' ? null : search?.stage}
                value={search?.stage === '' ? null : search?.stage}
                style={{ width: 110 }}
                onChange={(v)=>handlechange(v,'stage')}
                options={call_stages}
            />
          </div>

          <div className='ml-1 rounded-md border border-slate-300 py-[2px]'>
            <Select
                placeholder='Select User'
                bordered={false}
                size='small'
                defaultValue={search?.user}
                value={search?.user}
                style={{ width: 110 }}
                onChange={(v)=>handlechange(v,'user')}
                options={users}
            />
            </div>

          <div onClick={()=>setsearch({...search,type:search?.type === '1' ? '2' : '1'})} className='text-[11px] cursor-pointer flex border border-slate-300 rounded-[5px] mx-[5px] overflow-hidden'>
            <h6 className={`${search?.type === '1' ? '' : 'bg-slate-700 text-white'}  font-[600] text-[10px] py-[7px] p-[4px]`}>Pending</h6>
            <h6 className={`${search?.type === '2' ? '' : 'bg-slate-700  text-white'} font-[600] text-[10px] py-[7px] p-[4px]`}>Called</h6>
          </div>

            {/* <div className='ml-1 rounded-md border border-slate-300 py-[2px]'>
            <Select
                placeholder='Select Stage'
                bordered={false}
                size='small'
                defaultValue={selected_stage}
                value={selected_stage}
                style={{ width: 110 }}
                onChange={(v)=>handlechange(v,'stages')}
                options={stages}
            />
            </div> */}

           

          
            <div className='ml-1'>
            <ButtonFilledAutoWidth btnName="Add Filter" onClick={()=>applyfilterfunction(1)} /> 
            </div> 
            </div>
            {/* <ButtonFilledAutoWidth btnName="Reset Filter" onClick={resetfilter} /> */}

          </div>

        
           {data?.datas?.length === 0 &&
            <div className='grid place-items-center mt-20  items-center justify-center'>
            <img src={'https://fidecrmfiles.s3.amazonaws.com/NoDataFound1.png'} className='w-40 h-40 object-contain' /> 
            <h6 className='font-bold text-[14px] -mt-10'>No data found</h6>
            <h6 className='font-[500] w-[70%] text-center text-slate-700 text-[12.5px] -mt-2'>Oops we couldn't find any data based on your current stage please add the data to see list here.</h6>
          </div>
          }


          {data?.datas?.length > 0 &&
          <div className='max-h-[82vh] mt-4 border-t   border-l border-r overflow-y-scroll'>
          <div className='sticky top-0 z-50 flex  bg-white py-[-5px] border-b border-slate-200 relative'>
              <h6  className='top-0 bg-white z-50 text-[12px] w-[60px]  px-2 py-1 font-[600] text-slate-600 border-r flex items-center justify-center'>SL NO</h6>
              <h6 className='sticky top-0 z-50  text-[12px] w-[138px] px-2 py-1 font-[600] text-slate-600 border-r'>Company Name</h6>
              <h6 className='sticky top-0 z-50  text-[12px] w-[138px] px-2 py-1 font-[600] text-slate-600 border-r'>Address</h6>
              <h6 className='sticky top-0 z-50  text-[12px] w-[100px]  px-2 py-1 font-[600] text-slate-600 border-r'>Contact Name</h6>
              <h6 className='sticky top-0 z-50  text-[12px] w-[90px]  px-2 py-1 font-[600] text-slate-600 border-r'>Mobile</h6>
              <h6 className='sticky top-0 z-50  text-[12px] w-[130px]  px-2 py-1 font-[600] text-slate-600 border-r'>Email</h6>
              <h6 className='sticky top-0 z-50  text-[12px] w-[120px]  px-2 py-1 font-[600] text-slate-600 border-r'>Stage</h6>
              {/* <h6 className='sticky top-0 z-50  text-[12px] w-[120px] px-2 py-1 font-[600] text-slate-600 border-r'>Department</h6> */}
              <h6 className='sticky top-0 z-50  text-[12px] w-[140px] px-2 py-1 font-[600] text-slate-600 border-r'>Requirment</h6>
              <h6 className='sticky top-0 z-50  text-[12px] w-[140px] px-2 py-1 font-[600] text-slate-600 border-r'>Summary</h6>
              {/* <h6 className='sticky top-0 z-50  text-[12px] w-[100px]  px-2 py-1 font-[600] text-slate-600 border-r'>Created On</h6> */}
              <h6 className='sticky top-0 z-50  text-[12px] w-[90px]  px-2 py-1 font-[600] text-slate-600 sticky right-0'>Actions</h6>
          </div>
        
          {data?.datas?.map((d,i)=>(
          <div  key={d?._id} className={`flex items-center z-50 h-full border-b border-slate-200 z-0`}  >
              <h6 className='text-[12px] w-[60px]  px-2 py-1 font-[600] text-slate-600 cursor-pointer border-r flex items-center justify-center'>{page === 1 ? i+1 : (i+1) + ((page-1)* data?.pagination?.limit)}</h6>
              <h6 className='text-[12px] font-[500] w-[138px] px-2 py-1 truncate border-r  border-slate-200'>{d?.company_name}</h6>
              <h6 className='text-[12px] font-[500] w-[138px] px-2 py-1 truncate border-r  border-slate-200'>{d?.address}</h6>
              <h6 className='text-[12px] font-[500] w-[100px]  px-2 py-1 truncate border-r  border-slate-200'>{d?.contact_name}</h6>
              <h6 className='text-[12px] font-[500] w-[90px]  px-2 py-1 truncate border-r  border-slate-200'>{d?.phone}</h6>
              <h6 className='text-[12px] font-[500] w-[130px]  px-2 py-1 truncate border-r  border-slate-200'>{d?.email}</h6>
              <h6 className='text-[12px] font-[500] w-[120px]  px-2 py-1 truncate border-r  border-slate-200'>{d?.stage}</h6>
              {/* <h6 className='text-[12px] font-[500] flex w-[120px] px-2 py-1 truncate border-r border-slate-200'>
                {d?.department_id?.department_name !== undefined &&  <p className='border-2 border-white bg-gray-100 px-[4px] py-[2px] text-[8px] dark:border-gray-800 font-[800]'>{d?.department_id?.department_name !== undefined && d?.department_id?.department_name}</p>}
              </h6> */}

              <h6 className={`text-[12px] font-[500] w-[140px] px-2 py-1  truncate  border-r  border-slate-200 ${d?.requirment ? 'block' : 'text-[#fff]'}`}>{d?.requirment ? d?.requirment : 'Not Added'}</h6>
              <h6 className={`text-[12px] font-[500] w-[140px] px-2 py-1 truncate border-r  border-slate-200 ${d?.remarks ? 'block' : 'text-[#fff]'}`}>{d?.remarks ? d?.remarks : 'Not Added'}</h6>
              
              {/* <h6 className='text-[12px] font-[500] w-[100px]  px-2 py-1 truncate border-r  border-slate-200 sticky right-0'>{moment(d?.createdAt).format('ll')}</h6> */}
              <h6  className='text-[12px] w-[90px]  px-2 py-1 flex'>
             

                <Tooltip title="Edit">
                <span><AiOutlineEdit size={13} className="z-10 ml-2" onClick={()=>navigate('/calls/list/edit',{state:d?._id})}/></span>
                </Tooltip>

                {user?.roles?.includes('admin') &&
                <Tooltip title="Delete">
                <span><AiOutlineDelete size={13} className="z-10 ml-2 -mt-[1px]" onClick={()=>{deleteData(d?._id)}}/></span>
                </Tooltip>
                }
                

              
              </h6>
          </div>))}


          </div>}
   
        


        </div>
        </div>

        
      
        </div>
        </div>
    </div>
    </div>
  )
}

export default AdminCalls