import React, { useEffect, useState } from 'react'
import SettingsMenu from '../staticscreens/SettingsMenu'
import { DeleteAllEmployeeShare, DeleteInvoiceService, GetAllEmployeeShare, GetAllInsentiveAttachment } from '../../services/InvoiceServices'
import { AiOutlineDelete } from 'react-icons/ai'
import {  ButtonOutlinedAutoWidth } from '../../components/button'
import moment from 'moment'
import { toast } from 'react-hot-toast';
import {BsArrowRepeat} from 'react-icons/bs';
import {FiChevronRight,FiChevronLeft} from 'react-icons/fi';
import { IconButton } from '@mui/material'
import { Tag } from 'antd'



function PaymentShare() {

  const [filter,setfilter] = useState({search:'',filter_Applied:''})
  const [data,setdata] = useState([])
  const [pagination,setpagination] = useState({})
  const [page,setpage] = useState(1)
 
  useEffect(()=>{
    if(!filter.filter_Applied){
      getData()
    }else{
      filterData()
    }
  },[page,filter.filter_Applied])


  async function getData(){
      const response = await GetAllEmployeeShare(page)
      setdata(response?.data?.data)
      setpagination(response?.data?.pagination)
  }

  async function filterData(){
    if(filter?.search?.length > 2){
    const response = await GetAllInsentiveAttachment(filter?.search,page)
    setfilter({...filter,filter_Applied:true})
    setdata(response?.data?.datas)
    setpagination(response?.data?.pagiantion)
    }
  }

 

  function resetfunc(){
    setpage(1)
    setfilter({filter_Applied:false,search:''})
  }

  async function deleteData(id){
    const response = await DeleteAllEmployeeShare(id)
    if(response.status === 200){
      toast.success('Deleted Successfully')
      getData()
    }
  }

  let rupee = new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
  });




  return (
    <div className='h-screen min-h-screen max-h-screen overflow-hidden'>
     
        <div className='flex'>
            <SettingsMenu />
            <div className='mx-2 h-[95vh] overflow-hidden min-h-[95vh] max-h-[95vh] w-full'>
                <h6 className='border-b text-[14px] mt-5 font-[600] pb-1 w-full'>Payment Share</h6>

                <div className='border-b flex  pb-1 items-center justify-between my-1'>
                  <h6 className='text-[12px] py-1 font-[400]'>Total Found ({pagination?.total})</h6>
                  <div className='flex items-center'>
                  <div className='flex items-center text-[12px] mr-2'>

                    <h6 className='mr-2 font-[600]'>{page == 1 ? data?.length > 0 ? 1 : 0 : (page - 1) * pagination?.limit} - {pagination?.limit} of {pagination?.total} </h6>
                    <IconButton onClick={resetfunc} ><BsArrowRepeat size={16} /></IconButton>

                    <div>
                    <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>
                    <IconButton onClick={()=>{ page < pagination?.totalPages  ? setpage(page+1) : console.log('')}}><FiChevronRight className={`${(pagination?.totalPages === page || data?.length === 0)  ? 'opacity-50' : 'opacity-100'}`} size={16} /></IconButton>
                  
                    </div>
                    </div>

                    <input value={filter?.search} onChange={(e)=>setfilter({...filter,search:e.target.value})} className="w-[100px] text-[11px] mr-2 focus:outline-none border rounded-md py-1.5 px-2" />
                    <ButtonOutlinedAutoWidth onClick={filterData} btnName="Apply Filter" />
                  </div>
                </div>
                <div className='h-[85vh] pb-10 min-h-[85vh] max-h-[85vh] overflow-y-scroll'>
                <div className='grid grid-cols-5 gap-1 mt-2'>

                  {data?.map((d)=>( 
                  <div key={d?._id} className='border p-2 relative '>
                    <AiOutlineDelete size={13} className='absolute text-slate-500 cursor-pointer right-1 top-1' onClick={()=>{deleteData(d._id)}} />
                        <h6 className='text-[13px] border-b pb-1 mb-1 border-slate-100'>Client : <span className='text-[12px] w-[80%]'>{d?.client?.contact_name}</span></h6>
                        <h6 className='text-[11px] font-[700]'> {d?.client?.company_name}</h6>
                        <h6 className='text-[11px]'> {d?.client?.address}</h6>
                        <h6 className='text-[11px]'>Amount with Gst : <span className='font-[800]'>{rupee?.format(d?.amount_with_gst)?.split('.')[0]} / {rupee?.format(d?.amount_without_gst)?.split('.')[0]}</span></h6>
                        <h6 className='text-[11px] font-[700]'>Shared with </h6>
                        {d?.invoice?.shares?.map((s,i)=>(
                          <Tag className='text-[10px]'>{s?.user}</Tag>
                        ))}
                        <h6 className='text-[10px] bg-slate-100 p-1 mt-1'>Created At : <span className='font-[800]'>{moment(d?.createdAt)?.format('LL')}</span> </h6>
                  </div>
                  ))}

                </div>
                </div> 
            </div>
        </div>
    </div>
  )
}

export default PaymentShare