import React, { useEffect, useState } from 'react'
import FcaresMenu from '../FcaresMenu'
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../../components/button'
import { Modal } from 'antd'
import { BsArrowRepeat } from 'react-icons/bs'
import {FiChevronRight,FiChevronLeft} from 'react-icons/fi';
import { IconButton, Tooltip } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { RiBillLine } from 'react-icons/ri'
import { DeleteFcaresReportService, GetFcaresReportService } from '../../../services/Fcares/FCaresReportServices'
import moment from 'moment'


function FCaresReportList() {

  const navigate = useNavigate()

  const [data,setdata] = useState({datas:[],pagination:{total:0}})
  const [selecteddata,setselecteddata] = useState({})
  const [step,setstep] = useState(1)
  const [modal,setmodal] = useState(false)
  const [page,setpage] = useState(1)



  useEffect(()=>{
    getData()
  },[step,page])


  async function getData(){
    const response = await GetFcaresReportService(page)
    setdata(response?.data)
  }


  async function applyfilterfunction(){
    const response = await GetFcaresReportService(1)
    setdata(response?.data)
  }


  async function resetfunc(){
    setpage(1)
    const response = await GetFcaresReportService(1)
    setdata(response?.data)
  }

  async function deleteFunc(){
   const response = await DeleteFcaresReportService(selecteddata?._id)
   if(response?.status === 200){
      getData()
      setmodal(false)
   }
  }

  return (
    <div className='w-[100%] h-[100%] overflow-hidden'>
        <div className='flex'>
            <div className='min-w-44'>
            <FcaresMenu />
            </div>

            <Modal open={modal} width={350} closable={false} footer={false} className='absolute top-0 left-[40%]'>
               
                <h6 className="font-bold text-[13px]  mb-2 w-full">Delete Data</h6>

                <h6 className='text-[10px] font-[500] p-[5px] bg-slate-100 mb-1'>Are you sure want to delete the selected data once deleted will not be retrieved!</h6>
                <h6 className='font-[700] text-[12px]'>Selected Data</h6>

                <div className='text-[11px] mb-4'>
                  <h6 className='mt-1'>Device :<span className='font-[700]'>{selecteddata?.device}</span>  Lat : <span className='font-[700]'>{selecteddata?.lats}</span> / Long <span className='font-[700]'>{selecteddata?.long}</span> </h6>
                  <h6 className='mt-1'>Address :<span className='font-[700]'>{selecteddata?.address}</span> </h6>
                  <h6 className='mt-1'>Contact List :<span className='font-[700]'>{selecteddata?.contactsInfo?.length}</span> </h6>
                </div>
                <div className='mt-2 flex items-center'>
                  <ButtonOutlinedAutoWidth btnName="Close" onClick={()=>setmodal(false)}/>
                  <div className='ml-2'>
                  <ButtonFilledAutoWidth btnName="Save" onClick={()=>deleteFunc()} />
                  </div>
                </div>
           </Modal> 

            <div className='w-[88%] px-5 pt-5'>
              {/* <div className='flex items-center justify-center'>
              
              </div> */}

              <div className='flex items-center justify-between border-b pb-2 p'>
              <h6 className='font-[700] text-[14px] '>Total Report List ({data?.pagination?.total})</h6>
              <div className='flex items-center'>
                <div className='flex items-center text-[12px] mr-2'>

                

                  <h6 className='mr-2 font-[600]'>{page == 1 ? data?.datas?.length > 0 ? 1 : 0 :  (page - 1) * data?.pagination?.limit } - {data?.pagination?.limit} of {data?.pagination?.total} </h6>
                  <IconButton onClick={resetfunc}><BsArrowRepeat size={16} /></IconButton>

                  <div>
                  <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>
                  <IconButton onClick={()=>{ page < data?.pagination?.totalPages  ? setpage(page+1) : console.log('')}}><FiChevronRight className={`${(data?.pagination?.totalPages === page || data?.datas?.length === 0)  ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>

                  </div>
                </div>


            <div className='ml-2'>
            <ButtonFilledAutoWidth btnName="Add Data" onClick={()=>navigate('create')}/> 
            </div>
              </div>
          </div>

            
          {data?.datas?.length > 0 &&
              <div className='max-h-[82vh] overflow-x-hidden mt-4 border-t   border-l border-r overflow-y-scroll'>
                <div className='sticky top-0 z-50 flex  bg-white py-[-5px] border-b border-slate-200 relative'>
                    <h6  className='top-0 bg-white z-50 text-[12px] w-[5%]  px-2 py-1 font-[600] text-slate-600 border-r flex items-center justify-center'></h6>
                    <h6 className='sticky top-0 z-50  text-[12px] w-[10%] px-2 py-1 font-[600] text-slate-600 border-r'>Device</h6>
                    <h6 className='sticky top-0 z-50  text-[12px] w-[7.5%] px-2 py-1 font-[600] text-slate-600 border-r'>Lats</h6>
                    <h6 className='sticky top-0 z-50  text-[12px] w-[7.5%]  px-2 py-1 font-[600] text-slate-600 border-r'>Long</h6>
                    <h6 className='sticky top-0 z-50  text-[12px] w-[20%] px-2 py-1 font-[600] text-slate-600 border-r'>Address</h6>
                    <h6 className='sticky top-0 z-50  text-[12px] w-[40%] px-2 py-1 font-[600] text-slate-600 border-r'>Contacts Info</h6>
                    <h6 className='sticky top-0 z-50  text-[12px] w-[10%] px-2 py-1 font-[600] text-slate-600 border-r'>Created At</h6>
                    <h6 className='sticky top-0 z-50  text-[12px] w-[6%]  px-2 py-1 font-[600] text-slate-600 sticky right-0'>Actions</h6>
                </div>
                
                {data?.datas?.map((d,i)=>(
                <div className=' z-50 flex  bg-white py-[-5px] border-b border-slate-200 relative'>
                    <h6  className='bg-white z-50 text-[11px] w-[5%]  px-2 py-1 font-[500]  border-r'>{page === 1 ? i+1 : (i+1) + ((page-1)* data?.pagination?.limit)}</h6>
                    <h6 className=' z-50  text-[11px] w-[10%] px-2 py-1 font-[500] border-r'>{d?.device}</h6>
                    <h6 className=' z-50  text-[11px] w-[7.5%] px-2 py-1 font-[500] break-all border-r'>{d?.lats}</h6>
                    <h6 className=' z-50  text-[11px] w-[7.5%]  px-2 py-1 font-[500] break-all border-r'>{d?.long}</h6>
                    <h6 className=' z-50  text-[11px] w-[20%] px-2 py-1 font-[500] break-all border-r'>{d?.address}</h6>
                    <h6 className=' z-50  text-[11px] w-[40%] px-2 py-1 font-[500] break-all line-clamp-4 border-r'>{JSON.stringify(d?.contactsInfo)}</h6>
                    <h6 className=' z-50  text-[11px] w-[10%] px-2 py-1 font-[500] border-r'>{moment(d?.createdAt)?.format('LL')}</h6>
                    <h6 className=' z-50 flex text-[11px] w-[6%] py-1 font-[500]'>
                    <Tooltip title="Detail">
                    <span><RiBillLine size={13} className="z-10 ml-2" onClick={()=>navigate('/fcares/report_list/detail',{state:d})}/></span>
                    </Tooltip>
                    {/* <Tooltip title="Delete">
                    <span><AiOutlineDelete size={13} className="z-10 ml-2" onClick={()=>{setselecteddata(d);setmodal(true)}}/></span>
                    </Tooltip> */}
                    </h6>
                </div>))}

                </div>} 

                <div className='flex items-center justify-center'>
                  {data?.datas?.length === 0 &&
                  <div className='flex flex-col mt-20 w-[40%] items-center justify-center'>
                  <img src={'https://fidecrmfiles.s3.amazonaws.com/NoDataFound1.png'} className='w-40 h-40 object-contain' /> 
                  <h6 className='font-bold text-[14px] -mt-10'>No data found</h6>
                  <h6 className='font-[500] text-center w-[70%] text-slate-700 text-[12.5px] '>Oops we couldn't find any "Employee" onboarded yet click on the add option to create a new user for your fcares app access.</h6>
                   </div>
                  }
              </div>

            </div>
        </div>
    </div>
  )
}

export default FCaresReportList