import { deleteRequest, get, post, put } from "../../../helpers/apihelpers";

export const CreatePropertyCategoryService=async(data)=>{
    try {
        const result = await post(`api/database/property_category/create`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdatePropertyCategoryService=async(data,id)=>{
    try {
        const result = await put(`api/database/property_category/update/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetPropertyCategoryService=async(page,from_date,to_date,text,step)=>{
    try {
        const result = await get(`api/database/property_category/get?page=${page}&from_date=${from_date}&to_date=${to_date}&text=${text}&step=${step}`,);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeletePropertyCategoryService=async(id)=>{
    try {
        const result = await deleteRequest(`api/database/property_category/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteAdminPropertyCategoryService=async(id)=>{
    try {
        const result = await deleteRequest(`api/database/property_category/delete_admin/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const FilterPropertyCategoryService=async(id)=>{
    try {
        const result = await get(`api/database/property_category/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}