import React,{useState,useEffect} from 'react'
import { TextInput } from '../../components/input'
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../components/button'
import { Select } from 'antd'
import { GetDepartmentService } from '../../services/DepartmentServices'
import {BiErrorCircle} from 'react-icons/bi'
import { useNavigate,useLocation, useFetcher } from 'react-router-dom'
import { CreateFinanceDataService, updateFinanceDataService } from '../../services/AdminServicesfile/FinanceData'
import { toast } from 'react-hot-toast'
import SettingsMenu from '../staticscreens/SettingsMenu'
import GoBack from '../../components/back/GoBack'
import FinanceMenu from '../finance/FinanceMenu'

function FinanceDashboarddata() {
  
  const navigator = useNavigate()
  const {pathname,state} = useLocation()

  let path = pathname.split('/')[pathname.split('/').length - 1]
  let path1 = pathname.split('/')[1]

  const [data,setdata] = useState({department:'',from:'',to:'',invoice_amount:'',invoice_raised_no:'',amount_collected:'',total_outstanding:'',expenses:''})  
  const [error,seterror] = useState({department:'',from:'',to:'',invoice_amount:'',invoice_raised_no:'',amount_collected:'',total_outstanding:'',expenses:''})  
  const [department,setdepartment] = useState([])

  


   

  useEffect(()=>{
    if(path === 'edit'){
        setdata({
            ...state,department:state.department._id
        })
    }
    getDepartment()
  },[])

  

  const getDepartment = async() =>{
    const response = await GetDepartmentService()
    let arr = []
    response?.data?.datas?.forEach((d)=>{
       arr.push({label:d?.department_name,value:d?.id})
    })
    setdepartment(arr)
  }

  function handleChange(e){
    setdata({...data,[e.target.name]:e.target.value})
    seterror({...error,[e.target.name]:''})
  } 


  async function submitData(){
    if(!data.department){
        seterror({...error,department:'This Field is required*'})
    }else if(!data.from){
        seterror({...error,from:'This Field is required*'})
    }else if(!data.to){
        seterror({...error,to:'This Field is required*'})
    }else if(!data.invoice_raised_no){
        seterror({...error,invoice_raised_no:'This Field is required*'})
    }else if(!data.invoice_amount){
        seterror({...error,invoice_amount:'This Field is required*'})
    }else if(!data.amount_collected){
        seterror({...error,amount_collected:'This Field is required*'})
    }else if(!data.total_outstanding){
        seterror({...error,total_outstanding:'This Field is required*'})
    }else if(!data.expenses){
        seterror({...error,expenses:'This Field is required*'})
    }else{
        if(path === 'edit'){
            const response = await updateFinanceDataService(state?._id,data)
            if(response.status === 200){
                toast.success('Updated Successfully')
                setdata({department:'',from:'',to:'',invoice_amount:'',invoice_raised_no:'',amount_collected:'',total_outstanding:'',expenses:''})
                seterror({department:'',from:'',to:'',invoice_amount:'',invoice_raised_no:'',amount_collected:'',total_outstanding:'',expenses:''})
                navigator(-1)
            }    
        }else{
            const response = await CreateFinanceDataService(data)
            if(response.status === 201){
                toast.success('Created Successfully')
                setdata({department:'',from:'',to:'',invoice_amount:'',invoice_raised_no:'',amount_collected:'',total_outstanding:'',expenses:''})
                seterror({department:'',from:'',to:'',invoice_amount:'',invoice_raised_no:'',amount_collected:'',total_outstanding:'',expenses:''})
            }
        }
    }
  }

//   console.log("path1",path1)
   
  return (
    <div>
    <div className='flex'>
     {path1 === 'finance' &&
      <FinanceMenu />}
      {path1 === 'settings' &&
      <SettingsMenu /> }
    <div className='mx-5  pt-5 w-60'>
         <GoBack />
        <h1 className='text-[13px] font-[700]'>Add/Edit Fianance Dashboard Data</h1>
        <h6 className='text-[11px] my-1 font-[500] p-1 bg-slate-100'>Use the below form to Create or update the Invoice Raise Data</h6>
        

        <h6 className='text-[11px] my-1 font-[700]'>Select Department</h6>
        <Select options={department} bordered={false} className='w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none' value={data.department} onChange={(v)=>{setdata({...data,department:v});seterror({...error,department:''})}} />
        {error?.department !== null && error?.department !== undefined && error?.department !== ''  &&
        <div className='flex items-center mt-1'>
        <BiErrorCircle className='text-red-500' size={14} />
        <span className='text-[10px] text-red-500 ml-1'>{error?.department}</span>
        </div>}

        <div className='flex items-center justify-between'>
        <div>
            <h6 className='text-[11px] my-1 font-[700]'>From Date</h6>
            <input className='border p-1 text-[11px]' type='Date' value={data?.from?.slice(0,10)} onChange={(e)=>{setdata({...data,from:e.target.value});seterror({...error,from:''})}} />
        </div>

        <div>
            <h6 className='text-[11px] my-1 font-[700]'>To Date</h6>
            <input className='border p-1 text-[11px]' type='Date' value={data?.to?.slice(0,10)} onChange={(e)=>{setdata({...data,to:e.target.value});seterror({...error,to:''})}} />
        </div>

       
        </div>

        {error?.from !== null && error?.from !== undefined && error?.from !== ''  &&
        <div className='flex items-center mt-1'>
        <BiErrorCircle className='text-red-500' size={14} />
        <span className='text-[10px] text-red-500 ml-1'>{error?.from}</span>
        </div>}
        {error?.to !== null && error?.to !== undefined && error?.to !== ''  &&
        <div className='flex items-center mt-1'>
        <BiErrorCircle className='text-red-500' size={14} />
        <span className='text-[10px] text-red-500 ml-1'>{error?.to}</span>
        </div>}

        <TextInput value={data?.invoice_raised_no} error={error?.invoice_raised_no} name="invoice_raised_no" label={'Invoice Raised No'} handlechange={handleChange} />
        <TextInput value={data?.invoice_amount} error={error?.invoice_amount} name="invoice_amount" label={'Invoice Amount'} handlechange={handleChange} />
        <TextInput value={data?.amount_collected} error={error?.amount_collected} name="amount_collected" label={'Amount Collected'} handlechange={handleChange} />
        <TextInput value={data?.total_outstanding} error={error?.total_outstanding} name="total_outstanding" label={'Total Outstanding'} handlechange={handleChange} />
        <TextInput value={data?.expenses} error={error?.expenses} name="expenses" label={'Total Expenses'} handlechange={handleChange} />


        <div className='mt-4 flex items-center'>
            <ButtonOutlinedAutoWidth btnName="CANCEL" onClick={()=>navigator(-1)} />
            <div className='ml-2'>
            <ButtonFilledAutoWidth btnName="SAVE" onClick={submitData} />
            </div>
        </div>
    </div>
    </div> 
    </div>
  )
}

export default FinanceDashboarddata