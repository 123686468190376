import { deleteRequest, get, post, postfd, publicPost, put } from "../../../helpers/apihelpers";

export const CreateProjectWebsiteJobApplyService=async(data)=>{
    try {
        const result = await publicPost(`api/project_website/job_apply/create`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateProjectWebsiteJobApplyService=async(data,id)=>{
    try {
        const result = await put(`api/project_website/job_apply/update/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetProjectWebsiteJobApplyService=async(page,text,job_description='')=>{
    try {
        const result = await get(`api/project_website/job_apply/get?page=${page}&text=${text}&job_description=${job_description}`,);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteProjectWebsiteJobApplyService=async(id)=>{
    try {
        const result = await deleteRequest(`api/project_website/job_apply/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UploadProjectWebsiteJobApplyService=async(data)=>{
    try {
        const result = await postfd(`api/project_website/job_apply/upload_file`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DownloadProjectWebsiteJobApplyService=async(page,text,job_description='')=>{
    try {
        const result = await get(`api/project_website/job_apply/download_data?page=${page}&text=${text}&job_description=${job_description}`);
        return result;
    } catch (err) {
        return err.response;
    }
}