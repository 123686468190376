import React, { useEffect } from 'react';
import {RiUser6Line} from 'react-icons/ri';
import {useLocation,useNavigate } from 'react-router-dom';
import {BiFoodMenu} from 'react-icons/bi';

function ShilpaFoundationMenu() {

  const {pathname} = useLocation();
  const path = pathname.split('/')[pathname.split('/').length - 1]


 

  const navigate = useNavigate();

  useEffect(()=>{
       if(path === 'shilpa_foundation'){
        navigate('/shilpa_foundation/events')
       }
  },[])

  const menu = [
    {name:'Event List',icon:BiFoodMenu,path:`/shilpa_foundation/events`,id:1,color:''},
    {name:'User List',icon:RiUser6Line,path:`/shilpa_foundation/users`,id:2,color:''},
  ] 

  

 

  
  
  return (
    <div className='mr-0 min-h-screen max-h-sceen border-r min-w-44  w-44 max-w-44  px-2' >

        <div className={` w-full pt-4 `}>
            <h6 className=' ml-2 mb-2 font-[700] text-[12px]'>Basic Option </h6>
            {menu.map((m)=>(
              <>                 
                <div key={m.path} className={`flex items-center my-1 hover:bg-[#f4f5f7] px-1.5 py-1 ml-2 rounded-md relative cursor-pointer ${pathname ===  m.path && 'bg-[#f4f5f7]'}`} onClick={()=>navigate(m?.path)}>
                  {pathname ===  m.path &&  <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3'></span>}
                  <m.icon color='#000' style={{backgroundColor:m?.color,padding:'2px'}} size={16} />
                 <span className='font-[500] ml-2 text-[12px]'>{m.name}</span>
                </div>   
              </>   
            ))}

            

        </div>
    </div>
  )
}

export default ShilpaFoundationMenu