import React,{useEffect, useState} from 'react'
import { toast } from 'react-hot-toast'
import {  ButtonFilledAutoWidth } from '../../../../components/button'
import { TextAreaInput1, TextInput } from '../../../../components/input'
import { useNavigate, useLocation } from 'react-router-dom'
import GoBack from '../../../../components/back/GoBack'
import WebsiteMenu from '../../WebsiteMenu'
import Uploader from '../../../../components/Uploader'
import { CreateProjectWebsiteVideoService, UpdateProjectWebsiteVideoService, UploadProjectWebsiteVideoService } from '../../../../services/Website/ProjectWebsiteOption/ProjectVideoServices'
import { CiSquarePlus } from "react-icons/ci";
import { TiDocumentDelete } from "react-icons/ti";
import DOMPurify from "dompurify";
import moment from 'moment'

function ProjectVideoCE() {
 
  const [data,setdata] = useState({color:'',client:'',work:'',project_name:'',total_sqft:'',nature_of_the_project:'',scope:'',banner:'',video:'',youtube_link:'',image_type:'',images:[],sort:''});  
  const [error,seterror] = useState({color:'',client:'',work:'',project_name:'',total_sqft:'',nature_of_the_project:'',scope:'',banner:'',video:'',youtube_link:'',image_type:'',images:'',sort:''}); 
  
  const [image,setimage] = useState('')
  const [written_by,setwritten_by] = useState([])

  const {state} = useLocation();
  const navigate = useNavigate();


 


  useEffect(()=>{
    if(state?._id !== null && state?._id !== undefined){

      
      let obj = {
        color:state?.color,
        client:state?.client,
        work:state?.work,
        project_name:state?.project_name,
        total_sqft:state?.total_sqft,
        nature_of_the_project:state?.nature_of_the_project,
        scope:state?.scope,
        banner:state?.banner,
        video:state?.video,
        sort:state?.sort,
        youtube_link:state?.youtube_link,
        images:state?.images === undefined ? [] :state.images
      }

      setdata({...data,...obj})
    }

  },[state])

  function handlechange(e){
    setdata({...data,[e.target.name] : e.target.value})
    seterror({...error,[e.target.name]:''})
  }
  
  
  
  async function submitform(){
    if(!data.client){
        seterror({...error,client:'Client field is required *'})
    }else{
        let arr = [],arr1=[]
        let sendData = {...data}

       

        if(state?._id === undefined || state?._id === null){
        const response = await CreateProjectWebsiteVideoService(sendData)
        if (response.status === 201) {
          setdata({color:'',client:'',work:'',project_name:'',total_sqft:'',nature_of_the_project:'',scope:'',banner:'',video:'',youtube_link:''})
          seterror({color:'',client:'',work:'',project_name:'',total_sqft:'',nature_of_the_project:'',scope:'',banner:'',video:'',youtube_link:''})
          toast.success('Project Video Created Successfully')
        }   
        if(response.status === 422){
          if(response?.data?.errors?.name){
            seterror({...error,name:response?.data?.errors?.name})
          }
        } 
      }else{
        sendData["id"] = state?._id
        const response = await UpdateProjectWebsiteVideoService(sendData,state?._id)
        if (response.status === 200) {
          setdata({color:'',client:'',work:'',project_name:'',total_sqft:'',nature_of_the_project:'',scope:'',banner:'',video:'',youtube_link:''})
          seterror({color:'',client:'',work:'',project_name:'',total_sqft:'',nature_of_the_project:'',scope:'',banner:'',video:'',youtube_link:''})
          navigate(-1)
          toast.success('Project Video Updated Successfully')
        }   
        if(response.status === 422){
          if(response?.data?.errors?.name){
            seterror({...error,name:response?.data?.errors?.name})
          }
        } 
      }
  }
  }


  async function uploadfile(v,name){
    const fd = new FormData()
    fd.append('file',v); 
    const response = await UploadProjectWebsiteVideoService(fd)
    if(response?.status === 200){
      if(name !== 'image'){
        setdata({...data,[name]:response?.data?.data})
        seterror({...error,[name]:''})
      }else{
        // setimage(response?.data?.data)
        let imagesArr = [...data.images]
        imagesArr.push(response?.data?.data)
        setdata({...data,images:imagesArr})
      }
    }
  }


  console.log("data.images",data.images)




  async function handleselect(v,type){
     let findData = written_by.find((f)=>f.value === v)
     setdata({...data,[type]:findData})
  }

  async function removefile(i){
    let images = [...data.images]
    images.splice(i, 1); 
    setdata({...data,images:images})
  }


  

  return (
    <div className='flex '>


   
    <div className='w-44'> 
      <WebsiteMenu />
    </div>

    <div className='flex w-[83%]'>
      <div className='sm:w-full lg:border-r lg:min-w-[25%] lg:max-w-[25%] px-4 pt-5' >


        <GoBack /> 
        
        

          <h6 className='font-bold'>{(state?._id !== null && state?._id !== undefined) ? 'Edit' : 'Add'} Project Video</h6>
          <h6 className='text-[10px] bg-slate-100 p-2 font-[500] leading-snug' >Use the below form to create or edit the <b> Project Video</b> for your website video.</h6>
      

          <TextInput 
              label={'Client'}  
              variant="standard"
              name="client"
              type="text"
              error={error.client}
              value={data.client}
              handlechange={handlechange}
              placeholder="Enter your Role name"
              InputLabelProps={{
                  style: { color: '#fff', }, 
              }}/>

          

  <TextInput 
              label={'Work'}  
              variant="standard"
              name="work"
              type="text"
              error={error.work}
              value={data.work}
              handlechange={handlechange}
              placeholder="Enter your Role name"
              InputLabelProps={{
                  style: { color: '#fff', }, 
              }}/>

          <TextInput 
              label={'Project Name'}  
              variant="standard"
              name="project_name"
              type="text"
              error={error.project_name}
              value={data.project_name}
              handlechange={handlechange}
              placeholder="Enter your Role name"
              InputLabelProps={{
                  style: { color: '#fff', }, 
              }}/>

          <TextInput 
              label={'Total SQFT'}  
              variant="standard"
              name="total_sqft"
              type="text"
              error={error.total_sqft}
              value={data.total_sqft}
              handlechange={handlechange}
              placeholder="Enter your Role name"
              InputLabelProps={{
                  style: { color: '#fff', }, 
              }}/>

          <TextInput 
              label={'Nature of the Project'}  
              variant="standard"
              name="nature_of_the_project"
              type="text"
              error={error.nature_of_the_project}
              value={data.nature_of_the_project}
              handlechange={handlechange}
              placeholder="Enter your Role name"
              InputLabelProps={{
                  style: { color: '#fff', }, 
              }}/>

          <TextAreaInput1 
              label={'Scope'}  
              variant="standard"
              name="scope"
              type="text"
              error={error.scope}
              value={data.scope}
              handlechange={handlechange}
              placeholder=""
              InputLabelProps={{
                  style: { color: '#fff', }, 
              }}/>

            <h6 className='text-[11px] mt-2 font-[600] mb-1' >Banner</h6>
            <Uploader image={data?.banner}  setimagefunc={(e)=>{uploadfile(e,'banner')}}  removeimageuploadfunc = {()=>setdata({...data,banner:''})}/>

            <h6 className='text-[11px] mt-2 font-[600] mb-1' >Video</h6>
            <Uploader image={data?.video}  setimagefunc={(e)=>{uploadfile(e,'video')}}  removeimageuploadfunc = {()=>setdata({...data,video:''})}/>

            <h6 className='text-[11px] flex items-center w-full justify-between mt-2 font-[600] mb-1' >Upload Multi File </h6>
            <Uploader image={image}  setimagefunc={(e)=>{uploadfile(e,'image')}}  removeimageuploadfunc = {()=>setimage('')}/>
            
            <div className='grid mt-2 grid-cols-3 gap-1'>
            {data?.images?.map((d,i)=>(
            <div key={i} className='relative'>
                <img src={`${process.env.REACT_APP_AWS_IMAGE_URL}${d}`} className='border p-1 h-[80px] object-cover w-full' />
                <TiDocumentDelete size={18}  onClick={()=>removefile(i)} className='absolute top-0 border-l border-b right-0 p-[2px] bg-white' />
            </div>))}
            </div> 

            <TextInput 
              label={'YouTube Link'}  
              variant="standard"
              name="youtube_link"
              type="text"
              error={error.youtube_link}
              value={data.youtube_link}
              handlechange={handlechange}
              placeholder="Enter your Role name"
              InputLabelProps={{
                  style: { color: '#fff', }, 
              }}/>

            <TextInput 
              label={'Banner Type'}  
              variant="standard"
              name="image_type"
              type="text"
              error={error.image_type}
              value={data.image_type}
              handlechange={handlechange}
              placeholder="Enter your Role name"
              InputLabelProps={{
                  style: { color: '#fff', }, 
              }}/>

            <TextInput 
              label={'Sort'}  
              variant="standard"
              name="sort"
              type="number"
              error={error.sort}
              value={data.sort}
              handlechange={handlechange}
              placeholder="Enter your Role name"
              InputLabelProps={{
                  style: { color: '#fff', }, 
              }}/>

            <div className='mt-5'>
            <ButtonFilledAutoWidth btnName={(state?._id !== null && state?._id !== undefined) ? "UPDATE Project Video" : "ADD Project Video"}  onClick={submitform} />  
            </div>


      </div>
      <div className='sm:w-full mt-10 lg:min-w-[75%] mx-[3%] lg:max-w-[75%]'>
          <>
          <img src={`${process.env.REACT_APP_AWS_IMAGE_URL}${data?.banner}`} className={`w-screen  h-[90vh] ${(data?.image_type !== undefined && data?.image_type !== null && data?.image_type !== '')? 'object-contain' : 'object-cover'}`} />

          <div className='w-[90%] md:w-[80%] mx-auto my-[5%] mb-[10%]'>
            <h6 className='font_bold text-[25px]'>{data?.client}</h6>
            {(data?.work !== '' && data?.work !== undefined) && <h6 className='font_normal text-[14px] mb-4'>{data?.work}</h6>}
            {(data?.project_name !== '' && data?.project_name !== undefined) && <h6 className='font_normal text-[14px] mb-4'>PROJECT NAME : <span className='font_bold'>{data?.project_name}</span> </h6>}
            {(data?.total_sqft !== '' && data?.total_sqft !== undefined) && <h6 className='font_normal text-[14px] mb-4'>TOTAL SFT : <span className='font_bold'>{data?.total_sqft}</span> </h6>}
            {(data?.nature !== '' && data?.nature !== undefined) && <h6 className='font_normal text-[14px] mb-4'>NATURE OF THE PROJECT : <span className='font_bold'>{data?.nature}</span> </h6>}
            {(data?.scope !== '' && data?.scope !== undefined) && <h6 className='font_normal text-[14px] mb-10'>SCOPE : {data?.scope}</h6>}

            {(data?.video !== '' && data?.video !== null && data?.video !== undefined && (data?.youtube_link == '' || data?.youtube_link == null || data?.youtube_link == undefined) ) &&
            <video className='w-full' src={`${process.env.REACT_APP_AWS_IMAGE_URL}${data?.video}`} controls />}
            
            {(data?.youtube_link !== '' && data?.youtube_link !== null && data?.youtube_link !== undefined ) &&
            <div className='w-[100%] h-[100%] bg-red-100'>
                  <iframe src={data?.youtube_link} width={'100%'} allowFullScreen />
            </div>
              }
            

            <div className='grid grid-cols-2 md:grid-cols-4 gap-5'>
            {(data?.images !== null && data?.images !== undefined && data?.images !== '') &&
            data?.images?.map((s)=>(
              <img src={`${process.env.REACT_APP_AWS_IMAGE_URL}${s}`} className='w-[100%] h-[150px] object-cover' />
            ))
            }
            </div>
          </div>
          </>
      </div>
    </div>  

    </div>
  )
}

export default ProjectVideoCE