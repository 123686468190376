import React,{useState,useEffect} from 'react'
import { useLocation,useNavigate } from 'react-router-dom'
import { GetLeadAttachmentFollowUpService } from '../../../../services/LeadServices'
import {AiOutlinePlus,} from 'react-icons/ai'
// AiOutlineEdit
import { Box } from '@material-ui/core'
import { Typography } from '@mui/material'
import { btnColor } from '../../../../constants/themeConstants'
import {IoMdAdd,} from 'react-icons/io';
import {IoChevronBack} from 'react-icons/io5';
import moment from 'moment'
import axios from 'axios'
import fileDownload from "js-file-download";


function Leadfollowupslist() {
    
    const {state} = useLocation()
    const navigate = useNavigate()
    const [followUps,setfollowUps] = useState([])
    
    
    useEffect(()=>{
        getdata()
    },[])

  
  
    async function getdata(){
        const response = await GetLeadAttachmentFollowUpService(state?._id)
        setfollowUps(response?.data?.datas)
    }

    function returnfilename(v){
        let string = v?.split("/")[v?.split("/")?.length - 1]
        return string;
    }

    function getFileSizeString(fileSizeInBytes) {
        var i = -1;
        var byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
        do {
          fileSizeInBytes /= 1024;
          i++;
        } while (fileSizeInBytes > 1024);
        return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
    }

    const handleDownload = (url, filename) => {
        axios.get(url, {
            responseType: 'blob',
        })
            .then((res) => {
                fileDownload(res.data, filename)
            })
    }


  return (
    <Box sx={{paddingTop:{xs:'0px',sm:'0px',md:'10px',lg:'10px'}}}>
          
            <Box sx={{display:{sm:'block',md:'none',lg:'none'}}} className="block md:hidden">
            <Box style={{display:'flex',background:btnColor,alignItems:'center',padding:6}} sx={{display:{xs:'block',sm:'none',md:'none',lg:'none'}}}>
                <Box>
                    <Box  style={{display:'flex',background:btnColor,width:'100%',minWidth:'100vw',alignItems:'center',justifyContent:'space-between',padding:2,}}>
                    <Box style={{display:'flex',alignItems:'center',marginLeft:-5}}>
                    <IoChevronBack size={24} color="#000" onClick={()=>navigate(-1)} />
                    <Typography variant='span' ml={1} fontWeight={'600'} fontSize={'14px'}>{`Lead Follow ups(${followUps?.length})`}</Typography>
                    </Box> 
                    <IoMdAdd size={24} color="#000" onClick={()=>navigate('create',{state:state})} />
                    </Box>
                </Box>
            </Box>
            </Box> 

        <Box sx={{display: { xs: 'none', sm: 'none',md:'block',lg:'block' }}} >
            <Box className='hidden flex items-center justify-between mb-3 p-2 md:flex' >
            <h6 className='font-[800] text-[14px]'>Lead Follow ups({followUps?.length})</h6>
            <AiOutlinePlus size={24}  onClick={()=>navigate('create',{state:state})}/>
            </Box>

        {followUps?.map((n)=>(
            
        <div key={n?.id} className='border-[#158a93] bg-[#e6fdff] m-2 p-2 relative z-10 w-72 sm:w-full md:w-72'>
            {/* <span  className="z-100">
            <AiOutlineEdit size={18}  className="absolute right-2 bottom-2 cursor-pointer "  />
            </span> */}
            {n?.finalized && <img src="https://cdn-icons-png.flaticon.com/512/5289/5289675.png" className='absolute w-4 -top-1 -right-1' />}

            <h6 className='font-[700] text-[13px] -mt-1'>{n?.stage_type}</h6>
            <h6 className='font-[500] text-[9px] '>DateTime : {moment(n?.datetime).format('llll')}</h6>
            {(n?.summary !== null && n?.summary !== undefined && n?.summary !== '') &&
            
            <h6 className='font-[500] text-[12px] -mt-0.5 '>Summary : {n?.summary}</h6>}
            {(n?.file !== null && n?.file !== undefined && n?.file !== '') &&
            <div className='mt-1 -ml-1 flex relative'>
            <img src='https://cdn-icons-png.flaticon.com/512/179/179483.png' style={{width:'20px',height:'15px',objectFit:'contain'}} />
            <div className='-mt-1 ml-1 '>
            <h6 className='font-[400] text-[10px]'>{returnfilename(n?.file)}</h6>
            <h6 className='font-[600] text-[8px] -mt-0.5'>{getFileSizeString(n?.fileSize)}</h6>
            </div>
            </div>}    
            <div className='flex'>
            <h6 className='text-[10px] font-[600] text-[#158a93] cursor-pointer mr-2' onClick={()=>navigate('edit',{state:n})}>Edit Followup</h6>
            {(n?.file !== null && n?.file !== undefined && n?.file !== '') && <h6 className='text-[10px] font-[600] text-[#158a93] cursor-pointer' onClick={()=>handleDownload(`${process.env.REACT_APP_BACKEND_IMAGE_URL}${n?.file}`,returnfilename(n?.file))}>Download Attachment</h6>}
            </div>
        </div>))}
        </Box>
    </Box>
  )
}

export default Leadfollowupslist