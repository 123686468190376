import React, { useEffect } from 'react'
import { useLocation,useNavigate } from 'react-router-dom';
import {MdOutlineDoorFront} from 'react-icons/md';
import {AiOutlineBoxPlot,AiOutlineTags} from 'react-icons/ai';
import {BiCategoryAlt} from  'react-icons/bi';
import {MdOutlineBrandingWatermark} from 'react-icons/md';
import {BsPaintBucket} from 'react-icons/bs';
import { useSelector } from 'react-redux';
import {RiUser6Line} from 'react-icons/ri';
import {BiFoodMenu} from 'react-icons/bi';

function GalleryAppBar() {

    const {pathname} = useLocation();
    const navigate = useNavigate();
    const auth = useSelector(state=>state.Auth)


    useEffect(()=>{
        if(pathname === '/gallery'){
            navigate('visitor_list')
        }
    },[])
  
    const menu = [
        {name:'Visitor List',icon:MdOutlineDoorFront,path:'/gallery/visitor_list',id:1,},
    ]   
    
    const menu1 = [
        {name:'Product List',icon:AiOutlineBoxPlot,path:'/gallery/product_list',id:1,},
        {name:'Our Artist',icon:BsPaintBucket,path:'/gallery/our_artist',id:5,},
        {name:'Product Category',icon:BiCategoryAlt,path:'/gallery/product_category',id:2,},
        {name:'Product Tags',icon:AiOutlineTags,path:'/gallery/product_tags',id:3,},
        {name:'Product Brand',icon:MdOutlineBrandingWatermark,path:'/gallery/product_brand',id:4,}
    ]   

    const menu2 = [
        {name:'Event List',icon:BiFoodMenu,path:`/gallery/events`,id:1,color:''},
        {name:'User List',icon:RiUser6Line,path:`/gallery/users`,id:2,color:''},
    ] 



    return (
        auth.isAuthenticated &&
        <div className='hidden md:block mr-0 min-h-screen max-h-sceen border-r min-w-[180px]  w-44 max-w-44  px-2' >
            <div className='mb-4 mt-5'>
                <h6 className=' ml-2 mb-2 font-[700] text-[12px]'>Visitor Data</h6>

                {menu.map((m)=>(
                    <div key={m.path} className={`flex items-center my-1 hover:bg-[#f4f5f7] px-1.5 py-1 ml-2 rounded-md relative cursor-pointer ${pathname ===  m.path && 'bg-[#f4f5f7]'}`} onClick={()=>navigate(m?.path)}>
                        {pathname ===  m.path &&  <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3' ></span>}
                        <m.icon color='#000' style={{backgroundColor:m?.color,padding:'2px'}} size={16}  />
                        <span className='text-[#000] font-[500] ml-2 text-[12px]'>{m.name}</span>
                    </div>    
                ))}

                <h6 className=' ml-2 mb-2 font-[700] mt-4 text-[12px]'>Inventory Data</h6>

                {menu1.map((m)=>(
                    <div key={m.path} className={`flex items-center my-1 hover:bg-[#f4f5f7] px-1.5 py-1 ml-2 rounded-md relative cursor-pointer ${pathname ===  m.path && 'bg-[#f4f5f7]'}`} onClick={()=>navigate(m?.path)}>
                        {pathname ===  m.path &&  <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3' ></span>}
                        <m.icon color='#000' style={{backgroundColor:m?.color,padding:'2px'}} size={16}  />
                        <span className='text-[#000] font-[500] ml-2 text-[12px]'>{m.name}</span>
                    </div>    
                ))}

                <h6 className=' ml-2 mb-2 font-[700] mt-4 text-[12px]'>More Data</h6>

                {menu2.map((m)=>(
                <div key={m.path} className={`flex items-center my-1 hover:bg-[#f4f5f7] px-1.5 py-1 ml-2 rounded-md relative cursor-pointer ${pathname ===  m.path && 'bg-[#f4f5f7]'}`} onClick={()=>navigate(m?.path)}>
                    {pathname ===  m.path &&  <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3' ></span>}
                    <m.icon color='#000' style={{backgroundColor:m?.color,padding:'2px'}} size={16}  />
                    <span className='text-[#000] font-[500] ml-2 text-[12px]'>{m.name}</span>
                </div>    
                ))}
            </div>
        </div>
    )
}

export default GalleryAppBar