import React,{useEffect, useState} from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import GoBack from '../../../components/back/GoBack';
import { TextAreaInput1, TextInput, TextInput1 } from '../../../components/input';
import { ButtonFilledAutoWidth } from '../../../components/button';
import { toast } from 'react-hot-toast'
import { Select } from 'antd';
import { useSelector } from 'react-redux';
import ReceptionMenu from '../ReceptionMenu';
import { GetSearchService } from '../../../services/AuthServices';
import { CreateCabUsageService, UpdateCabUsageService } from '../../../services/receptionservices/CabUsageServices';

function CabUsageCE() {

  const user = useSelector(state=>state.Auth)
  const {state,pathname} = useLocation();
  const navigate = useNavigate();

  
  const path = pathname.split('/')[1]
  const path_type = pathname.split('/')[pathname.split('/').length - 1]



  const [data,setdata] = useState({employee:'',location:'',status:'Pending',date:'',in_time:'',out_time:'',vendor_info:'',distance_in_km:''});  
  const [error,seterror] = useState({employee:'',location:'',status:'',date:'',in_time:'',out_time:'',vendor_info:'',distance_in_km:''}); 
  
  const statusOption = [
      {label:'Pending',value:'Pending'},
      {label:'Completed',value:'Completed'},
  ]

  const [userArr,setuserArr] = useState([])

  function handlechange(e){
    setdata({...data,[e.target.name] : e.target.value})
    seterror({...error,[e.target.name]:''})
  }

  async function handleselect(val,type){
    if(type === 'user'){
      setdata({...data,employee:userArr.find((d)=>d.value === val)})
    }
  }

  useEffect(()=>{
    if(state?._id !== undefined){

      let d = {...state}

      console.log("d here",d)
        setdata({
            ...data,
            ...d,
            in_time:d?.in_time?.slice(0,16),
            out_time:d?.out_time?.slice(0,16),
            employee:{label:state?.employee?.name,value:state?.employee?._id}
        })
    }
  },[state])

  async function getuserlist(v){
    const response = await GetSearchService(v,1)
    let arr = []
    let d = response?.data?.datas
    d?.forEach((d1)=>{
      arr?.push({label:`${d1?.name} - [${d1?.employee_id}]`,value:d1?._id})
    })
    setuserArr(arr)
  }




  async function submitform(){
    if(!data.employee){
        seterror({...error,employee:'This field is required *'})
    }else if(!data.location){
      seterror({...error,location:'This field is required *'})
    }else if(!data.date){
        seterror({...error,date:'This field is required *'})
    }else if(!data.out_time){
      seterror({...error,out_time:'This field is required *'})
    }else{
        let sendData = {...data}

        if(state?._id === undefined || state?._id === null){
            sendData['employee'] = data?.employee.value

            const response = await CreateCabUsageService(sendData)
            if (response.status === 201) {
              resetform()
              toast.success('Cab Usage Created Successfully')
            }   
      }else{
       
        sendData["id"] = state?._id
        sendData['employee'] = data?.employee.value
        const response = await UpdateCabUsageService(sendData,state?._id)
        if (response.status === 200) {
          resetform()
          navigate(-1)
          toast.success('Cab Usage Updated Successfully')
        }   
        if(response.status === 422){
          if(response?.data?.errors?.title){
            seterror({...error,title:response?.data?.errors?.title})
          }
        } 
      }
    }
  }


  function resetform(){
    setdata({employee:'',location:'',status:'Pending',date:'',in_time:'',out_time:'',vendor_info:'',distance_in_km:''})
    seterror({employee:'',location:'',status:'',date:'',in_time:'',out_time:'',vendor_info:'',distance_in_km:''})
  }

  return (
    <div className='flex '>
        {/* {pathname !== '/conveyance_vochuer_create' &&
        <FinanceMenu />} */}
         <div className={`min-w-44 ${path == 'profile' && 'min-w-[180px] pl-2'}`}>
              <ReceptionMenu />
            </div>

        <div className={`w-full md:w-72 lg:w-72 px-4 pt-5`} >

            <GoBack />
            
            

            <h6 className='font-bold'>{(state?._id !== null && state?._id !== undefined) ? 'Edit' : 'Add'} Cab Usage</h6>
            <h6 className='text-[10px] bg-slate-100 p-2 font-[500] leading-snug' >Use the below form to create or edit the <b> Cab Usage</b> for your issues.</h6>
        
            <h6 className='text-[11px] font-[600] mb-1 mt-2' >Employee</h6>
                <div className='h-[35px] border'>
                  <Select
                      bordered={false}
                      className='w-[100%] border-l-4 border-slate-700'
                      filterOption={false}
                      showSearch
                      placeholder=''
                      allowClear={true}
                      onSearch={getuserlist}
                      optionFilterProp="children"
                      value={data?.employee}
                      options={userArr}
                      onChange={(v)=>handleselect(v,'user')}

                     
                  />
                </div>


            <TextAreaInput1 
                label={'Location'}  
                variant="standard"
                name="location"
                type="date"
                mandatory={true}
                error={error.location}
                value={data.location}
                handlechange={handlechange}
                placeholder=""
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>

         

            <TextInput
                label={'Date'}  
                variant="standard"
                name="date"
                mandatory={true}
                type="date"
                error={error.date}
                value={data.date}
                handlechange={handlechange}
                placeholder=""
                InputLabelProps={{
                    style: { color: '#fff', }, 
              }}/>

            <TextInput
                label={'Out Time'}  
                variant="standard"
                name="out_time"
                mandatory={true}
                type="time"
                error={error.out_time}
                value={data.out_time}
                handlechange={handlechange}
                placeholder=""
                InputLabelProps={{
                    style: { color: '#fff', }, 
              }}/>

            <TextInput
                label={'In Time'}  
                variant="standard"
                name="in_time"
                mandatory={false}
                type="time"
                error={error.in_time}
                value={data.in_time}
                handlechange={handlechange}
                placeholder=""
                InputLabelProps={{
                    style: { color: '#fff', }, 
              }}/>


            <TextInput
                label={'Vendor Info'}  
                variant="standard"
                name="vendor_info"
                mandatory={false}
                type="text"
                error={error.vendor_info}
                value={data.vendor_info}
                handlechange={handlechange}
                placeholder=""
                InputLabelProps={{
                    style: { color: '#fff', }, 
              }}/>

            <TextInput
                label={'Distance In KM'}  
                variant="standard"
                name="distance_in_km"
                mandatory={false}
                type="text"
                error={error.distance_in_km}
                value={data.distance_in_km}
                handlechange={handlechange}
                placeholder=""
                InputLabelProps={{
                    style: { color: '#fff', }, 
              }}/>

         
         
            
  
            {state?._id !== undefined && path_type === 'edit' &&
             
             <>
            <div className='mt-1'>
                
                <h6 className='text-[11px] font-[600] mb-1 mt-2' >Status</h6>
                <div className='h-[35px] border border-slate-300'>
                  <Select
                      placeholder=''
                      value={data?.status}
                      onChange={(v)=>setdata({...data,status:v})}
                      options={statusOption}
                      bordered={false}
                      className='w-[100%]'
                  />
                </div>
              
            </div>
            </>}


            <div className='mt-5'>
            <ButtonFilledAutoWidth btnName={(state?._id !== null && state?._id !== undefined) ? "UPDATE CAB USAGE" : "ADD CAB USAGE"}  onClick={submitform} />  
            </div>
            
        </div>
   </div> 
  )
}

export default CabUsageCE