import { deleteRequest, get, post, postfd, publicPost, put } from "../../helpers/apihelpers";

export const CreateFidelitusCorpUserService=async(data)=>{
    try {
        const result = await post(`api/corp/users/create`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateFidelitusCorpUserService=async(data,id)=>{
    try {
        const result = await put(`api/corp/users/update/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetFidelitusCorpUserService=async(page,text,from_date,to_date,event_id='')=>{
    try {
        const result = await get(`api/corp/users/get?page=${page}&text=${text}&from_date=${from_date}&to_date=${to_date}&event_id=${event_id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteFidelitusCorpUserService=async(id)=>{
    try {
        const result = await deleteRequest(`api/corp/users/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DownloadFidelitusCorpUserService=async(page,text,from_date,to_date,event_id='')=>{
    try {
        const result = await get(`api/corp/users/download_data?page=${page}&text=${text}&from_date=${from_date}&to_date=${to_date}&event_id=${event_id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UploadFidelitusCorpUserService=async(data)=>{
    try {
        const result = await postfd(`api/corp/users/upload_file`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}