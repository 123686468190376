import { deleteRequest, get, postfd} from "../../helpers/apihelpers";


export const CreateGalleryVisitorList=async(data)=>{
    try {
        const result = await postfd(`api/gallery_visitor/create`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateGalleryVisitorList=async(id,data)=>{
    try {
        const result = await postfd(`api/gallery_visitor/update/${id}`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetGalleryVisitorList=async(page)=>{
    try {
        const result = await get(`api/gallery_visitor/get/null/${page}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const SearchGalleryVisitorService = async({search_text,from_date,to_date,page})=>{
    try {
        const result = await get(`api/gallery_visitor/filter?search_text=${search_text}&from_date=${from_date}&to_date=${to_date}&page=${page}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteGalleyVisitorService = async(id) => {
    try {
        const result = await deleteRequest(`api/gallery_visitor/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DownloadExcelGalleyVisitorService = async() => {
    try {
        const result = await get(`api/gallery_visitor/downloadExcel`);
        return result;
    } catch (err) {
        return err.response;
    }
}