import { deleteRequest, get, post, postfd, put } from "../../helpers/apihelpers";

export const CreateGalleryUserService=async(data)=>{
    try {
        const result = await post(`api/gallery_events_visitor/create`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateGalleryUserService=async(data,id)=>{
    try {
        const result = await put(`api/gallery_events_visitor/update/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetGalleryUserService=async(page,text,from_date,to_date,event_id='')=>{
    try {
        const result = await get(`api/gallery_events_visitor/get?page=${page}&text=${text}&from_date=${from_date}&to_date=${to_date}&event_id=${event_id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteGalleryUserService=async(id)=>{
    try {
        const result = await deleteRequest(`api/gallery_events_visitor/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DownloadGalleryUserService=async(page,text,from_date,to_date,event_id='')=>{
    try {
        const result = await get(`api/gallery_events_visitor/download_data?page=${page}&text=${text}&from_date=${from_date}&to_date=${to_date}&event_id=${event_id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UploadGalleryUserService=async(data)=>{
    try {
        const result = await postfd(`api/gallery_events_visitor/upload_file`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}