import { Box } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { MobileDrawer } from '../../../components/appbars/mobile/DashboardMobile'
import SettingsMenu from '../../staticscreens/SettingsMenu'
import {AiOutlineEdit} from 'react-icons/ai';

function StageRoot() {
  const navigate = useNavigate()

  const datas = [
    {path:"Lead_Stage",value:'Lead Stage',color:'bg-slate-100'},
    {path:"FollowUp_Options",value:'FollowUp Options',color:'bg-slate-100'},
    {path:"Zones",value:'Zones',color:'bg-slate-100'},
    {path:"Contact_Source",value:'Contact Source',color:'bg-slate-100'},
    {path:"Industry",value:'Industry',color:'bg-slate-100'},
    {path:"Task_Stages",value:'Task_Stages',color:'bg-slate-100'},
    {path:"ILS_Stages",value:'ILS_Stages',color:'bg-slate-100'},
    {path:"MD_LandStages",value:'MD Land Stages',color:'bg-slate-100'},
    {path:"MD_WareHouseStages",value:'MD WareHouse Stages',color:'bg-slate-100'},
    {path:"MD_CommercialOfficeSpaceStages",value:'MD CommercialOfficeSpace Stages',color:'bg-slate-100'},
    {path:"MD_InvestmentStages",value:'MD Investment Stages',color:'bg-slate-100'}
  ]

  return (
    <div  className='flex'>
      <SettingsMenu />
      <div className='w-[86%] px-4 pt-5'>
        <h6 className='text-[14px]  font-[800] border-b pb-2 cursor-pointer' onClick={()=>{navigate('Lead_Stage')}} >Stage Option</h6>
        <div className='grid grid-cols-6 gap-1 mt-2'> 
        
        {datas?.map((d)=>(
        <div key={d?.value} className='border p-1 relative'>
          <AiOutlineEdit size={12} onClick={()=>{navigate(d?.path)}} className='absolute right-1 top-1 bg-white' />
          <h6 className={`text-[12px]  truncate w-[94%] font-[500] p-1 cursor-pointer`}  >Type : <span className='text-[11.5px] font-[700]'>{d?.value}</span> </h6>
        </div>
        ))}
        </div>
       
        </div>
    </div>
  )
}

export default StageRoot