import React, { useState } from 'react'
import { useNavigate,useLocation, useSearchParams } from 'react-router-dom';

// import { app_logo } from '../../components/constants/ImageConstants';
import { toast } from 'react-hot-toast';
import { TextInput } from '../../components/input';
import { ForgotPasswordService } from '../../services/AuthServices';
import { lightThemeColor } from '../../constants/themeConstants';



export default function ForgotPassword() {

  const { state } = useLocation()
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token')
  // let state;
  console.log("token",token)

  console.log("amma please")


  const navigate = useNavigate()

  const [data,setdata] = useState({password:'',confirm_password:''})
  const [error,seterror] = useState({password:'',confirm_password:''})


  function handlechange(e){
    setdata({...data,[e.target.name] : e.target.value})
    seterror({...error,[e.target.name] : ''})
  }

  function onKeyDownHandler(e){
    if (e.keyCode === 13) {
      handleSubmit();
    }
  }
  

  const handleSubmit = async () => {
    if(data.password.length < 8){
      seterror({...error,password:'New Password should be atleast 8 characters*'})
    }else if(data.password !== data.confirm_password){
      seterror({...error,confirm_password:'New Password and Conform Password did not match*'})
    }else{
      let send_data = {...data,...state?.datas}

      // console.log("send_data",send_data)
       const response = await ForgotPasswordService(send_data,token)
    //    await ForgotPasswordService(send_data)
    // console.log("response",response.status)
       if(response.status === 200){

        // navigate('/login',{state:'New password updated successfully'})
        toast.success('New password updated successfully')
        navigate('/login')
        setdata({...data,password1:'',password2:''})
       }

       if(response.status === 404){
        toast.error('Token Expired')
       }
    } 
  };


  return (
      

    <div className='flex min-h-screen max-h-screen justify-between'>

    <div className='min-w-[70vw] w-[70vw] max-w-[70vw] bg-slate-800'>
    <img src="https://fidecrmfiles.s3.amazonaws.com/crmposter.avif" className='w-full h-full object-cover opacity-20' />
   </div>   

   {/* <div className='flex justify-center pb-5 items-center box-border'> 
       <img alt="logo" src={fidelogo} style={{width:'30px',height:'30px',objectFit:'contain'}} />
       <h6 className='text-[16px] font-[600] text-center ml-2  font-family:Roboto-300'>Fidelitus</h6>
   </div>    */}


  

   {/* {(error?.user_id !== '' || error?.password !== '')  &&
   <div className='bg-[#ffebe8] p-4 flex items-center border border-[#ffc1c1] rounded-md mb-5 justify-between'>
       <h6 className='text-[13.5px] font-[400]'>{error?.user_id}{error?.password}</h6>
       <IoClose size={18} className="text-[#cf212e] cursor-pointer" onClick={()=>seterror({user_id:'',password:''})} />
   </div>}

   {stateMsg !== ''  &&
   <div className='bg-[#ddf4ff] p-4 flex items-center border border-[#a8d8ff] rounded-md mb-5 justify-between'>
       <h6 className='text-[13.5px] font-[400]'>{stateMsg}</h6>
       <IoClose size={18} className="text-[#5ebcff] cursor-pointer" onClick={()=>setstateMsg('')} />
   </div>} */}


   <div className='min-w-[30vw] w-[30vw] max-w-[30vw] flex items-center justify-center' >

   
       <div className='p-1 w-72'  >  

        <h6 className='text-[18px] font-[800]'>Forgot Password</h6>      
          <h6 className={`text-[10px] font-[500] leading-normal bg-slate-100 p-2 my-2`}>Add your new password to update ur account for security purpose.</h6>


          
          <form 
           onKeyDown={onKeyDownHandler}
           onSubmit={handleSubmit}>
          <TextInput 
                label={'Password'}  
                variant="standard"
                name="password"
                type="password"
                error={error.password}
                value={data.password}
                handlechange={handlechange}
                placeholder="Enter your new password"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>  


                <TextInput 
                label={'Confirm Password'}  
                variant="standard"
                name="confirm_password"
                type="password"
                error={error.confirm_password}
                value={data.confirm_password}
                handlechange={handlechange}
                placeholder="Enter your new password"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>  


            {/* <button onClick={handleSubmit} className="bg-[#158a93] text-white font-bold py-1.5 px-4 mt-5 rounded w-full text-[13px]">
              UPDATE PASSWORD 
            </button> */}

            <h6 className='text-[9px] pt-2 font-[400] text-[#646d76] pb-0 leading-normal' >Make sure it's at least 15 characters OR at least 8 characters including a number and a lowercase letter. <span className='text-[#0a69da] cursor-pointer hover:underline'>Learn more.</span></h6>

            {/* <div className='mt-4'>
            <ButtonFilled btnName="Change password" onClick={handleSubmit} />
            </div> */}
            <h6 onClick={handleSubmit} className="bg-slate-600 cursor-pointer text-center mt-4 text-white font-[500] py-[8px] px-4 mt-0  w-full rounded-md border-slate-600  text-[14px] hover:border-slate-600">Change password</h6>

            </form>
       {/* <Box mt={3} >

       <ButtonFilled btnName="SIGN IN"  onClick={submitform} />  
       </Box> */}

       <h6 className='text-[10px] mt-4 text-800 leading-normal'>By continuing, you agree to Fidelitus<span className='text-black font-bold text-[#0a69da]'> Conditions of Use</span>  and <span className='text-black font-bold text-[#0a69da]'> Privacy Notice </span> .</h6>



       </div>  
   </div> 

   {/* <Card variant='outlined' sx={{textAlign:'center',padding:2,marginTop:2}} className="border-[#d7dee3] border">
       <h6 className='text-[13px] font-[500]'>New to Fidelitus? <span className='text-[#0a69da] cursor-pointer'> Create an account.</span></h6>
   </Card> */}
   {/* <Copyright sx={{ mt: 8, mb: 4 }} className="center w-full" /> */}
   </div>  
  );
}