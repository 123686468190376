import { deleteRequest, get, post, postfd, put } from "../helpers/apihelpers";


export const GetILSLandRequirmentService=async(page,stage,search,from_date,to_date)=>{
    try {
        const result = await get(`api/ils_land_requirment/get?page=${page}&stage=${stage}&from_date=${from_date}&to_date=${to_date}&search=${search}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetILSLandRequirmentAdminService=async(page,search,from_date,to_date,stage,user)=>{
    try {
        const result = await get(`api/ils_land_requirment/get_admin?page=${page}&stage=${stage}&from_date=${from_date}&to_date=${to_date}&search=${search}&user=${user}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetILSLandRequirmentDetailService=async(id)=>{
    try {
        const result = await get(`api/ils_land_requirment/get/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const CreateILSLandRequirmentService=async(data)=>{
    try {
        const result = await post(`api/ils_land_requirment/create`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateILSLandRequirmentService=async(id,data)=>{
    try {
        const result = await put(`api/ils_land_requirment/update/${id}`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}


export const UploadFileILSLandRequirmentService=async(data)=>{
    try {
        const result = await postfd(`api/ils_land_requirment/upload_file`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteILSLandRequirmentService=async(id)=>{
    try {
        const result = await deleteRequest(`api/ils_land_requirment/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}