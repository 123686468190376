import React, { useState,useEffect } from 'react'
import { TextInput } from '../../../components/input'
import {BiErrorCircle} from 'react-icons/bi';
// import Select from 'react-select'
import { Select } from 'antd';
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../../components/button';
import { GetDepartmentService, GetUsersByDepartment } from '../../../services/DepartmentServices';
import { CreateTeamService, GetTeamServiceDetail, UpdateTeamService } from '../../../services/AdminServicesfile/TeamServices';
import { toast } from 'react-hot-toast';
import { useLocation,useNavigate } from 'react-router-dom';
import GoBack from '../../../components/back/GoBack';
import SettingsMenu from '../../staticscreens/SettingsMenu';
import { CreateReportingService, GetReportingServiceDetail, UpdateReportingService } from '../../../services/AdminServicesfile/ReportingServices';

function ReportingCE() {

  const {state,pathname} = useLocation() 
  const navigator = useNavigate()

  const [data,setdata] = useState({name:'',department:'',reporting_hod:'',team_lead:'',reporting_manager:'',users:[]})   
  const [error,seterror] = useState({name:'',department:'',reporting_hod:'',team_lead:'',reporting_manager:'',team_lead:'',users:''})   
  const [departmentArr,setdepartmentArr] = useState([])
  const [departmentEmployee,setdepartmentEmployee] = useState([])
  

  useEffect(()=>{
    if(pathname.split('/')[pathname.split('/').length - 1] === 'edit' && state !== null){
      getOldDetailData()
    }
    getdata()
  },[])

  async function getOldDetailData(){
    const response =  await GetReportingServiceDetail(state)
    let d = response?.data?.datas[0]
    
    console.log("d here okkk",d)

    let users = []
    d?.users?.forEach((u)=>{
      users.push({label:u?.name,value:u?._id})
    })
    getemployeebasedondepartment(d?.department?._id)
    setdata({...data,name:d?.name,id:d?._id,reporting_hod:{label:d?.reporting_hod?.name,value:d?.reporting_hod?._id},reporting_manager:{label:d?.reporting_manager?.name,value:d?.reporting_manager?._id},team_lead:{label:d?.team_lead?.name,value:d?.team_lead?._id},users:users,department:{label:d?.department?.department_name,value:d?.department?._id},year:d?.year})
  }


  async function getdata(){
    let department_Arr = []
    const response = await GetDepartmentService()

    response?.data?.datas?.forEach(d => {
        department_Arr.push({label:d.department_name,value:d.id})

    });
    // getemployeebasedondepartment(department_Arr[0]?.value)
    setdepartmentArr(department_Arr)
  }

  async function getemployeebasedondepartment(id){
    let department_Employee = []
    const response = await GetUsersByDepartment(id)
    response?.data?.datas?.forEach(d => {
        department_Employee.push({label:d.name,value:d._id})
    });
    setdepartmentEmployee(department_Employee)
  }

  function handlechange(e){
    setdata({...data,[e.target.name] : e.target.value})
    seterror({...error,[e.target.name]:''})
  }
  
  async function handleSelect(e,e1){
    if(e1 === 'department'){
     setdata({...data,department:departmentArr?.find(d1 => d1?.value === e)})
     getemployeebasedondepartment(e)
     seterror({...error,department:''})
    }else  if(e1 === 'team_lead'){
     setdata({...data,team_lead:departmentEmployee?.find(d1 => d1?.value === e)})
     seterror({...error,team_lead:''})
    }else  if(e1 === 'reporting_hod'){
      setdata({...data,reporting_hod:departmentEmployee?.find(d1 => d1?.value === e)})
      seterror({...error,reporting_hod:''})
    }else  if(e1 === 'reporting_manager'){
      setdata({...data,reporting_manager:departmentEmployee?.find(d1 => d1?.value === e)})
      seterror({...error,reporting_manager:''})
    }else  if(e1 === 'users'){
      let arr = []
      e?.forEach((d)=>{
         arr.push(departmentEmployee?.find(d1 => d1?.value === d))
      })
      setdata({...data,users:arr})
      seterror({...error,users:''})
    }
  }

  

  async function submitdata(){
    if(!data.department){
        seterror({...error,department:'Department field is required'})
    }else if(!data.reporting_hod){
      seterror({...error,reporting_hod:'Reporting HOD field is required'})
    }else if(!data.team_lead){
        seterror({...error,team_lead:'Team Lead field is required'})
    }else if(!data.reporting_manager){
      seterror({...error,reporting_manager:'Team Reporting Manager field is required'})
    }else if(data.users.length === 0){
        seterror({...error,users:'Users field is required'})
    }else{

      if(pathname.split('/')[pathname.split('/').length - 1] === 'edit' && state !== null){
        let send_data = {...data}
        send_data['department'] = data?.department?.value
        send_data['team_lead'] = data?.team_lead?.value
        send_data['reporting_hod'] = data?.reporting_hod?.value
        send_data['reporting_manager'] = data?.reporting_manager?.value
        send_data['year'] = data?.year
        let users = []
        data?.users?.forEach((u)=>{
            users.push(u.value)
        })
        send_data['users'] = users
        const response = await UpdateReportingService(send_data,data?.id)
        if(response.status === 200){
            toast.success("Reporting Structure Updated Successfully")
            navigator(-1)
            resetform()
        }

        if(response.status === 422){
          if(response?.data?.message){
            toast.error(response?.data?.message)
          }

            if(response?.data?.errors !== undefined && response?.data?.errors['fidelitus.teams index'] !== undefined){
                seterror({...error,team_lead:response?.data?.errors['fidelitus.teams index']})
            }

           
        }
      }else{
        let send_data = {...data}
        send_data['department'] = data?.department?.value
        send_data['team_lead'] = data?.team_lead?.value
        send_data['reporting_hod'] = data?.reporting_hod?.value
        send_data['reporting_manager'] = data?.reporting_manager?.value
        send_data['year'] = JSON.stringify(new Date().getFullYear())+"-"+JSON.stringify(new Date().getFullYear()+1)
        let users = []
        data?.users?.forEach((u)=>{
            users.push(u.value)
        })
        send_data['users'] = users
        const response = await CreateReportingService(send_data)
        if(response.status === 201){
            toast.success("Reporting Structure Created Successfully")
            resetform()
        }

        if(response.status === 422){
            if(response?.data?.message){
              toast.error(response?.data?.message)
            }

            if(response?.data?.errors !== undefined &&  response?.data?.errors['fidelitus.teams index'] !== undefined){
                seterror({...error,team_lead:response?.data?.errors['fidelitus.teams index']})
            }
        }
      }
    }
  }

  function resetform(){
    setdata({name:'',department:'',team_lead:'',users:[]})
    seterror({name:'',department:'',team_lead:'',users:''})
  }

  return (
    <div>
       <div className='flex '>
        <SettingsMenu />  
        <div className='w-60 ml-4 mt-5'>
         <GoBack /> 
        <h6 className='font-[700] mb-1'>Create/Edit Reporting Team</h6>
        <h6 className='text-[12px] bg-slate-100 p-1 font-[500]'>Use the below form to create a team inside the vertical</h6>

       

        <h6 className='text-[12px] font-semibold mb-1 mt-1'>Department </h6>
        <div className='border border-slate-300'>
        <Select
           value={data.department} 
           error={error.department}
           bordered={false}
           placeholder="" 
           onChange={(e)=>handleSelect(e,'department')} 
           className='w-full rounded-[0px]'
           options={departmentArr} 
           
           />
          </div> 
         {error.department !== '' && error.department !== undefined &&
         <div className='flex items-center mt-1'>
         <BiErrorCircle className='text-red-500' size={14} />
         <span className='text-[10px] text-red-500 ml-1'>{error.department}</span>
         </div>}

         <h6 className='text-[12px] font-semibold mb-1 mt-1'>Department Head </h6>
        <div className='border border-slate-300'>
         <Select
           value={data.reporting_hod} 
           error={error.reporting_hod}
           bordered={false}
           placeholder="" 
           onChange={(e)=>handleSelect(e,'reporting_hod')} 
           className='w-full rounded-[0px]' 
           options={departmentEmployee} 
           />
          </div> 
         {error.reporting_hod !== '' && error.reporting_hod !== undefined &&
         <div className='flex items-center mt-1'>
         <BiErrorCircle className='text-red-500' size={14} />
         <span className='text-[10px] text-red-500 ml-1'>{error.reporting_hod}</span>
         </div>}


        <h6 className='text-[12px] font-semibold mb-1 mt-1'>Team Lead </h6>
        <div className='border border-slate-300'>
         <Select
           value={data.team_lead} 
           error={error.team_lead}
           bordered={false}
           placeholder="" 
           onChange={(e)=>handleSelect(e,'team_lead')} 
           className='w-full rounded-[0px]' 
           options={departmentEmployee} 
           />
          </div> 
         {error.team_lead !== '' && error.team_lead !== undefined &&
         <div className='flex items-center mt-1'>
         <BiErrorCircle className='text-red-500' size={14} />
         <span className='text-[10px] text-red-500 ml-1'>{error.team_lead}</span>
         </div>}

         <h6 className='text-[12px] font-semibold mb-1 mt-1'>Reporting Manager</h6>
        <div className='border border-slate-300'>
         <Select
           value={data.reporting_manager} 
           error={error.reporting_manager}
           bordered={false}
           placeholder="" 
           onChange={(e)=>handleSelect(e,'reporting_manager')} 
           className='w-full rounded-[0px]' 
           options={departmentEmployee} 
           />
          </div> 
         {error.reporting_manager !== '' && error.reporting_manager !== undefined &&
         <div className='flex items-center mt-1'>
         <BiErrorCircle className='text-red-500' size={14} />
         <span className='text-[10px] text-red-500 ml-1'>{error.reporting_manager}</span>
         </div>}

         <h6 className='text-[12px] font-semibold mb-1 mt-1'>Team Members </h6>
        <div className='border border-slate-300'>
         
         <Select
           mode='multiple'
           bordered={false}
           value={data.users} 
           error={error.users}
           placeholder="" 
           onChange={(e)=>handleSelect(e,'users')} 
           className='w-full rounded-[0px]' 
           options={departmentEmployee} 
           />
         </div>  
         {error.users !== '' && error.users !== undefined &&
         <div className='flex items-center mt-1'>
         <BiErrorCircle className='text-red-500' size={14} />
         <span className='text-[10px] text-red-500 ml-1'>{error.users}</span>
         </div>}

         <div className='flex mt-5'>
            <ButtonOutlinedAutoWidth btnName={'Back'} onClick={()=>{navigator(-1)}} />
            <h6 style={{width:'8px'}}></h6>
            <ButtonFilledAutoWidth btnName={'Save'} onClick={submitdata} />
         </div>

        </div> 
        </div>
    </div>
  )
}

export default ReportingCE