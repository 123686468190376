import React,{useEffect, useState} from 'react'
import { toast } from 'react-hot-toast'
import {  ButtonFilledAutoWidth } from '../../../components/button'
import { TextAreaInput1, TextInput } from '../../../components/input'
import { Select } from 'antd'
import { useNavigate, useLocation } from 'react-router-dom'
import GoBack from '../../../components/back/GoBack'
import Uploader from '../../../components/Uploader'
import ErrorComponent from '../../../components/errorDesign/ErrorComponent'
import GalleryAppBar from '../GalleryAppBar'
import { CreateGalleryUserService, UpdateGalleryUserService, UploadGalleryUserService } from '../../../services/Gallery/GalleryUserListServices'
import { GetGalleryEventService } from '../../../services/Gallery/GalleryEventListServices'

function FidelitusGalleryUserListCE() {
 
  const [data,setdata] = useState({image:'',name:'',mobile:'',email:'',address:'',designation:'',remarks:'',company:'',gallery_event:''});  
  const [error,seterror] = useState({image:'',name:'',mobile:'',email:'',address:'',designation:'',remarks:'',company:'',gallery_event:''}); 
  
  
  const {state} = useLocation();
  const [events,setevents] = useState([])
  const navigate = useNavigate();


 


  useEffect(()=>{
    if(state?._id !== null && state?._id !== undefined){

      let obj = {
        ...state,
        gallery_event:{label:state?.gallery_event?.title,value:state?.gallery_event?._id}
      }
      delete obj.created_by
      setdata({...data,...obj})
    }

  },[state])

  function handlechange(e){
    setdata({...data,[e.target.name] : e.target.value})
    seterror({...error,[e.target.name]:''})
  }
  

  
  async function submitform(){
    if(!data.name){
        seterror({...error,name:'Name field is required *'})
    }else if(!data.mobile){
      seterror({...error,mobile:'Mobile field is required *'})
    }else if(!data.gallery_event){
      seterror({...error,gallery_event:'Event field is required *'})
    }else{
        let sendData = {...data}

        sendData['gallery_event'] = data?.gallery_event?.value
       
        if(state?._id === undefined || state?._id === null){
        const response = await CreateGalleryUserService(sendData)
        if (response.status === 201) {
          resetform()
          toast.success('User Created Successfully')
        }   
        if(response.status === 422){
          if(response?.data?.errors?.title){
            seterror({...error,title:response?.data?.errors?.title})
          }
        } 
      }else{
        sendData["id"] = state?._id
        const response = await UpdateGalleryUserService(sendData,state?._id)
        if (response.status === 200) {
          resetform()
          navigate(-1)
          toast.success('User Updated Successfully')
        }   
        if(response.status === 422){
          if(response?.data?.errors?.title){
            seterror({...error,title:response?.data?.errors?.title})
          }
        } 
      }
  }
  }

  async function resetform(){
    setdata({image:'',name:'',mobile:'',email:'',address:'',designation:'',remarks:'',company:'',foundation_event:''})
    seterror({image:'',name:'',mobile:'',email:'',address:'',designation:'',remarks:'',company:'',foundation_event:''})
  }


  async function uploadfile(v,name){
    const fd = new FormData()
    fd.append('file',v); 
    const response = await UploadGalleryUserService(fd)
    if(response?.status === 200){
      setdata({...data,[name]:response?.data?.data})
      seterror({...error,[name]:''})
    }
  }

  async function searchData(v,type){

    const response = await GetGalleryEventService(1,v,'','','null')

    let d = response?.data?.datas
    console.log("response?.data",response?.data)
    let arr = []


    d.forEach((d1)=>{
      arr.push({label:d1?.title,value:d1?._id})
    })
    setevents(arr)
  }  

 

  async function handleselect(v,type){
    let findData = events.find((f)=>f.value === v)
    setdata({...data,[type]:findData})
 }
  

  return (
    <div className='flex '>


    <GalleryAppBar />
 
  <div className='sm:w-full lg:w-72 px-4 pt-5' >


      <GoBack /> 
      
      

        <h6 className='font-bold'>{(state?._id !== null && state?._id !== undefined) ? 'Edit' : 'Add'} Event Visitor</h6>
        <h6 className='text-[10px] bg-slate-100 p-2 font-[500] leading-snug' >Use the below form to create or edit the <b>Visitor details</b> for your event.</h6>
     

        <TextInput 
            mandatory={true}
            label={'Name'}  
            variant="standard"
            name="name"
            type="text"
            error={error.name}
            value={data.name}
            handlechange={handlechange}
            placeholder=""
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>

       

          <TextInput 
            mandatory={true}
            label={'Mobile'}  
            variant="standard"
            name="mobile"
            error={error.mobile}
            value={data.mobile}
            handlechange={handlechange}
            placeholder=""
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>

        <TextInput 
            label={'Email'}  
            variant="standard"
            name="email"
            type="text"
            error={error.email}
            value={data.email}
            handlechange={handlechange}
            placeholder=""
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>

        <TextInput 
            label={'Designation'}  
            variant="standard"
            name="designation"
            type="text"
            error={error.designation}
            value={data.designation}
            handlechange={handlechange}
            placeholder=""
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>


            

        <TextAreaInput1 
            label={'Address'}  
            variant="standard"
            name="address"
            type="text"
            error={error.address}
            value={data.address}
            handlechange={handlechange}
            placeholder=""
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>

        <TextInput 
            label={'Company'}  
            variant="standard"
            name="company"
            type="text"
            error={error.company}
            value={data.company}
            handlechange={handlechange}
            placeholder=""
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>

       
         <TextAreaInput1 
            label={'Remarks'}  
            variant="standard"
            name="remarks"
            type="text"
            error={error.remarks}
            value={data.remarks}
            handlechange={handlechange}
            placeholder=""
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>

          <h6 className='text-[11px] mt-2 font-[600] mb-1' >Visitor Card Image</h6>
          <Uploader image={data?.image}  setimagefunc={(e)=>{uploadfile(e,'image')}}  removeimageuploadfunc = {()=>setdata({...data,image:''})}/>

         
          <h6 className='text-[11px] mt-2 font-[600] mb-1' >Event Info</h6>
        <Select 
          value={data?.gallery_event}
          allowClear={true}
          type="gallery_event"
          filterOption={false}
          showSearch={true}
          onSearch={(e)=>searchData(e,'gallery_event')}
          onChange={e=>{handleselect(e,'gallery_event')}}
          options={events}
          bordered={false}
          className='w-[100%] border-l-4 border-l-slate-700 border border-slate-300'
        />

        <ErrorComponent error={error?.gallery_event} />

       

        
<div className='mt-5'>
        <ButtonFilledAutoWidth btnName={(state?._id !== null && state?._id !== undefined) ? "UPDATE VISITOR DATA" : "ADD VISITOR DATA"}  onClick={submitform} />  
        </div>


    </div>
    </div>
  )
}

export default FidelitusGalleryUserListCE