import { deleteRequest, get, post, postfd, put } from "../../helpers/apihelpers";

export const CreateFcaresTicketService=async(data)=>{
    try {
        const result = await post(`api/fcares/ticket`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateFcaresTicketService=async(id,data)=>{
    try {
        const result = await put(`api/fcares/ticket/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetFcaresTicketService=async(text,from_date,to_Date,step,page)=>{
    try {
        const result = await get(`api/fcares/ticket?page=${page}&search=${text}&from_date=${from_date}&to_date=${to_Date}&step=${step}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetFcaresTicketByIdService=async(id)=>{
    try {
        const result = await get(`api/fcares/ticket/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteFcaresTicketService=async(id)=>{
    try {
        const result = await deleteRequest(`api/fcares/ticket/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UploadDocsFcaresTicketService=async(data)=>{
    try {
        const result = await postfd(`api/fcares/property/ticket`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}