import { Box } from '@material-ui/core'
import React,{useState,useEffect} from 'react'
import { TextInput } from '../../../../components/input'
import { useLocation,useNavigate } from 'react-router-dom';
import { ButtonFilled } from '../../../../components/button';
import { CreateLeadAttachmentFollowUpService, UpdateLeadAttachmentFollowUpService, UpdateLeadNoteService } from '../../../../services/LeadServices';
import { toast } from 'react-hot-toast';
import Uploader from '../../../../components/Uploader';
import { MobileBackOption } from '../../../../components/appbars/mobile/DashboardMobile';
import { DatePicker, TimePicker } from 'antd';
import moment from 'moment';

function LeadfollowupsCE() {

  const [data,setdata] = useState({file:'',date:'',time:'',summary:"",finalized:false});
  const [error,seterror] = useState({file:'',date:'',time:'',summary:"",finalized:false});
  const location = useLocation()
  const {state} = location 
  const navigate = useNavigate()

  // console.log("location",state)

  useEffect(()=>{
    if(location?.pathname?.split('/')[location?.pathname?.split('/').length - 1] !== 'create'){
      setdata({...data,summary:state.summary,finalized:state.finalized})
    }
  },[])

  
  async function submitform(){
    if(location?.pathname?.split('/')[location?.pathname?.split('/').length - 1] === 'create'){
      if((state.stage.name === 'Inspection' || state?.stage?.name === 'Landlord Meeting')){
        if(!data.date){
          seterror({...error,date:'Date Field is required*'})
        }else if(!data.time){
          seterror({...error,time:'Time Field is required*'})
        }else{
              let send_data1 = {}
              send_data1['attachment_type'] = 'Lead'
              send_data1['stage_type'] = state?.stage?.name
              send_data1['stage'] = state?.stage?._id
              send_data1['summary'] = ''
              send_data1['finalized'] = 0
              send_data1['type_id'] = state?._id
              if(data?.date !== ''){
                send_data1['datetime'] = moment(data?.date).format('YYYY-MM-DD') + ' ' + moment(data?.time).format('hh:mm')
              }
              if(data?.file !== ''){
                send_data1['file'] =data?.file
                send_data1['fileSize'] = data?.file?.size 
              }
              const response = await CreateLeadAttachmentFollowUpService(send_data1)
              if(response.status === 201){
                resetform()
                toast.success("Attachment added successfully")
              }
        }
      }else{
        if(!data.file){
          seterror({...error,file:'File Field is required*'})
        }else{
          let send_data1 = {}
          send_data1['leadId'] = state?._id
          send_data1['attachment_type'] = 'Lead'
          send_data1['stage_type'] = state?.stage?.name
          send_data1['stage'] = state?.stage?._id
          send_data1['summary'] = ''
          send_data1['finalized'] = 0
          send_data1['type_id'] = state?._id
          if(data?.date !== ''){
            send_data1['datetime'] = moment(data?.date).format('YYYY-MM-DD') + ' ' + moment(data?.time).format('hh:mm')
          }
          if(data?.file !== ''){
          send_data1['file'] =data?.file
          send_data1['fileSize'] = data?.file?.size
          }
          const response = await CreateLeadAttachmentFollowUpService(send_data1)
          if(response.status === 201){
            resetform()
            toast.success("Attachment added successfully")
          }
        }
      }
    }else{
      if(!data.summary){
        seterror({...error,summary:'This Field is required *'})
      }else{
        let send_data1 = {}
        send_data1['_id'] = state?.id
        send_data1['attachment_type'] = 'Lead'
        send_data1['stage_type'] = state?.stage_type
        send_data1['stage'] = state?.stage
        send_data1['summary'] = data?.summary
        send_data1['finalized'] = data?.finalized === true ? 1 : 0
        send_data1['type_id'] = state?.type_id
        const response = await UpdateLeadAttachmentFollowUpService(send_data1,state?.id)
        if(response.status === 200){
          resetform();
          navigate(-1)
          toast.success('Attachment updated successfully*')
        }

      }
    }
    
  }

  // console.log("data date",data.date)

  function handlechange(e){
    setdata({...data,[e.target.name] : e.target.value})
    seterror({...error,[e.target.name]:''})
  }

  function resetform(){
    setdata({file:'',date:'',time:'',summary:"",finalized:false})
    seterror({file:'',date:'',time:'',summary:"",finalized:false})
  }

  return (
    <Box sx={{marginTop:{sm:'50px',xs:'50px',md:'50px',lg:'50px'},paddingLeft:'30px'}}>
    <Box sx={{display:{sm:'block',md:'none',lg:'none'}}} className="block md:hidden">
    <MobileBackOption goback={()=>navigate(-1)} />
    </Box> 
    <div className='sm:w-full lg:w-72 p-4'>

       

        <h6 className='font-bold'>{location?.pathname?.split('/')[4] !== 'create' ? 'Edit Follow up For' : 'Add Follow up For '} {'Options Shared'} Stage</h6>
        <h6 className='text-[10px] bg-sky-50 p-2 font-semibold leading-snug' >Use the below form to create the <b> Tracking Flow</b> for your generated leads.</h6>
     
        {location?.pathname?.split('/')[location?.pathname?.split('/')?.length - 1] === 'edit' && 
        <>
       <TextInput 
            label={'Summary'}  
            variant="standard"
            name="summary"
            type="text"
            error={error.summary}
            value={data.summary}
            handlechange={handlechange}
            placeholder="Enter your summary"
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>

        <div className='flex items-center mt-5'>
        <input type={'checkbox'} checked={data?.finalized} onChange={()=>setdata({...data,finalized:!data.finalized})} /> 
        <h6 className="text-[13px] font-[600] ml-2">Finalized</h6> 
        </div>  
        </>}

        {location?.pathname?.split('/')[location?.pathname?.split('/')?.length - 1] !== 'edit' && 

        <>
        {(state.stage.name === 'Inspection' || state?.stage?.name === 'Landlord Meeting') ? 
         <>
        <h6 className='text-[10px] py-2 font-semibold leading-snug mt-5' >Date Time</h6>
        <div className='flex items-center height-24'>
          {/* <LocalizationProvider dateAdapter={AdapterDayjs} > */}
          <div>
          <DatePicker ampm={false} placeholder=''  sx={{height:'40px'}} inputFormat="MM/dd/yyyy" value={data.date} onChange={(v) => {setdata({...data,date:v});seterror({...error,date:''})}} />
          {(error?.date !== '' || error?.time !== '')  && <h6 className='text-red-600 text-[10px]'>{error?.date}</h6>}
          </div>
           <p style={{width:10}}></p>
           <div>
          <TimePicker format="hh:mm" placeholder=''  ampm={false} sx={{height:'40px'}} value={data.time} onChange={(v) => {setdata({...data,time:v});seterror({...error,time:''})}} />
          {(error?.date !== '' || error?.time !== '')  && <h6 className='text-red-600 text-[10px]'>{error?.time}</h6>}

          </div>
          {/* {(error?.date !== '' || error?.time !== '')  && <h6 className='text-red-600 text-[10px]'>{error?.time}</h6>} */}

          </div>
         </>
         :
         <>
        <h6 className='text-[10px] py-2 font-semibold leading-snug mt-5' >Attachments</h6>
        <Uploader image={data.file} setimagefunc={(v)=>setdata({...data,file:v})} removeimageuploadfunc={()=>setdata({...data,file:''})} />
        </>
        }
        </>}
        <Box className='mt-3'>          
        <ButtonFilled btnName={location?.pathname?.split('/')[4] !== 'create' ? "UPDATE NOTE" : "ADD NOTE"}  onClick={submitform} />  
        </Box>


    </div>
    </Box>
  )
}

export default LeadfollowupsCE