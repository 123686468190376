import { deleteRequest, get, post, postfd, put } from "../../helpers/apihelpers";

export const CreateShilpaFoundationEventService=async(data)=>{
    try {
        const result = await post(`api/shilpa_foundation/events/create`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateShilpaFoundationEventService=async(data,id)=>{
    try {
        const result = await put(`api/shilpa_foundation/events/update/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetShilpaFoundationEventService=async(page,text,from_date,to_date,step)=>{
    try {
        const result = await get(`api/shilpa_foundation/events/get?page=${page}&text=${text}&from_date=${from_date}&to_date=${to_date}&step=${step}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteShilpaFoundationEventService=async(id)=>{
    try {
        const result = await deleteRequest(`api/shilpa_foundation/events/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DownloadShilpaFoundationEventService=async(page,text,from_date,to_date,)=>{
    try {
        const result = await get(`api/shilpa_foundation/events/download_data?page=${page}&text=${text}&from_date=${from_date}&to_date=${to_date}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UploadShilpaFoundationEventService=async(data)=>{
    try {
        const result = await postfd(`api/shilpa_foundation/events/upload_file`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}