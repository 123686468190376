import React,{useState} from "react"
import { Box } from "@material-ui/core"
import Drawer from '@mui/material/Drawer';
import { btnColor } from "../../../constants/themeConstants";
import { useDispatch,useSelector } from 'react-redux';
import { LogOutAction } from "../../../redux/actions/authAction";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import {AiTwotoneSetting} from 'react-icons/ai';
import {IoOptionsSharp,IoMenu,IoChevronBack} from 'react-icons/io5';
import {IoMdAdd,} from 'react-icons/io';
import {GrDocumentText,GrStepsOption} from 'react-icons/gr';
import {TbFileInvoice} from 'react-icons/tb';
import {SlUser} from 'react-icons/sl';
import {FiUserPlus} from 'react-icons/fi';
import {HiLogout} from 'react-icons/hi';
import {AiOutlineApartment,AiOutlineAntDesign} from 'react-icons/ai';
import {CgOptions} from 'react-icons/cg';
import {AiOutlineContacts,AiOutlineSetting,AiOutlinePauseCircle,AiOutlineControl,AiOutlineUserAdd} from 'react-icons/ai';
import {IoMdOptions} from 'react-icons/io';
import {VscInspect,VscGroupByRefType} from 'react-icons/vsc';
import {HiOutlineDocument,HiOutlineTemplate,HiOutlineMenuAlt2,HiOutlineMenu} from 'react-icons/hi';
import {FiUser} from 'react-icons/fi';
import {MdPassword,MdOutlineContactSupport,MdOutlineSpaceDashboard} from 'react-icons/md';
import {RiMastercardLine} from 'react-icons/ri';
import {BiBuilding} from 'react-icons/bi';


const drawerWidth = 200;


export const MobileDrawer = ({name})=>{
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [mobileOpen, setMobileOpen] = useState(false);
    const user = useSelector(state=>state.Auth)

    const handleDrawerToggle = () => {
      setMobileOpen(!mobileOpen);
    };

    const logoutfunction = () =>{
      dispatch(LogOutAction());
      navigate("/");
      setMobileOpen(false)
    }

    const userRoute = [ 
      { name: "Dashboard", to: "/dashboard", icon: MdOutlineSpaceDashboard ,size:15,employee_id:""},
      { name: "My Contact", to: "/contacts", icon: AiOutlineContacts,size:15,employee_id:""},
      { name: "Options Shared", to: "/options_shared", icon: IoOptionsSharp,size:15,employee_id:""},
      { name: "Inspection", to: "/inspections", icon: VscInspect,size:15,employee_id:""},
      { name: "LOI/Agreement", to: "/loi_agreement", icon: GrDocumentText,size:14,employee_id:""},
      { name: "Invoice Raised", to: "/invoice_raised", icon: TbFileInvoice,size:16,employee_id:""},
      { name: "Profile", to: "/profile", icon: SlUser,size:14,employee_id:""},
      { name: "Update Password", to: "/update_password", icon: MdPassword,size:13,employee_id:""},
      { name: "Settings", to: "/settings", icon: AiTwotoneSetting,size:15,employee_id:""},
      { name: "Support", to: "/support", icon: MdOutlineContactSupport,size:15,employee_id:"" },
      { name: "OnBoard", to: "/onboard_list", icon: FiUserPlus,size:15,employee_id:"fcpl_239"},
      { name: "Department", to: "/department", icon: AiOutlineApartment,size:15,employee_id:"fcpl_239"},
      { name: "Designation", to: "/designation", icon: AiOutlineAntDesign,size:15,employee_id:"fcpl_239"},
      { name: "CSDT", to: "/csdt_list", icon: CgOptions,size:15,employee_id:"fcpl_239"},
      { name: "Roles", to: "/roles", icon: AiOutlineControl,size:15,employee_id:"fcpl_239"},
      { name: "Stages", to: "/stages", icon: GrStepsOption,size:15,employee_id:"fcpl_239"},
    ];
  
    const drawer = (
      <Box mt={2} className="mt-4">
      {userRoute.map((item,index) => (
        <div>
      {item.employee_id === '' ? 
        <Box  key={index} display={'flex'} flexDirection={'row'} py={0.2} px={1} mb={1} ml={1} alignItems={'center'} onClick={()=>{console.log("okk");navigate(item.to);setMobileOpen(false)}} className="flex py-1.5 px-2 items-center" style={{cursor:'pointer'}}>
          {item.icon && <item.icon size={item.size}  /> }
          <Typography ml={1.5} variant={'p'} fontSize={'12px'} fontWeight={'500'} >
            {item.name}
          </Typography>
        </Box> 
        :
        <>
        {(item?.employee_id === user?.employee_id ) &&
        <Box  key={index} display={'flex'} py={0.2} px={1} mb={1} ml={1} alignItems={'center'} onClick={()=>{console.log("okk");navigate(item.to);setMobileOpen(false)}} style={{cursor:'pointer'}}>
        {item.icon && <item.icon size={item.size}  /> }
        <Typography ml={1.5} variant={'p'} fontSize={'12px'} fontWeight={'500'} >
          {item.name}
        </Typography>
       </Box>}
       </>
       }      
       </div> 
      ))}
      <Box position={'absolute'}  display={'flex'} alignItems={'center'} bottom={10} left={13} style={{cursor:'pointer'}} onClick={logoutfunction} className="flex py-1.5 px-2 items-center absolute bottom-2">
          <HiLogout size={18} /> 
          <Typography ml={1.5} variant={'span'} fontSize={'12px'} fontWeight={'500'} >
            Log Out
          </Typography>
      </Box> 
    </Box>
    );
  

    return (
      
    <Box style={{display:'flex',background:btnColor,alignItems:'center',padding:6,maxHeight:'100vh'}} sx={{display:{xs:'block',sm:'none',md:'none',lg:'none'}}}>
    <Drawer 
          variant="temporary" 
          sx={{
            display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            },
          }} 
          open={mobileOpen}
          onClose={handleDrawerToggle}>


          <Box  display={'flex'} alignItems={'center'} px={2} py={1} borderBottom={'1px solid #eee'} style={{backgroundColor:btnColor}} > 
          <Typography ml={1} style={{fontSize:'14px',fontWeight:'900',height:'44px',display:'flex',alignItems:'center',paddingLeft:'18px'}}>Hi {user?.name}</Typography>
          </Box>         
            {drawer}
    </Drawer>
      <Box  onClick={()=>setMobileOpen(!mobileOpen)} >
        <Box  style={{display:'flex',background:btnColor,alignItems:'center',padding:4}}>
        <IoMenu size={24} color="#000" />
        <Typography variant='span' ml={1} fontWeight={'600'} fontSize={'14px'} className="pl-2">{name}</Typography>
        </Box>
     </Box>
    </Box>
)} 

export const MobileDrawerAdd = ({name})=>{
  const dispatch = useDispatch()
  const navigator = useNavigate()
  const [mobileOpen, setMobileOpen] = useState(false);
  const auth = useSelector(state=>state.Auth)


  

  const handleLogout = () =>{
    dispatch(LogOutAction());
    navigator("/");
    setMobileOpen(false)
  }

  const userRoute = [
    { name: "Dashboard", to: "/dashboard", icon: MdOutlineSpaceDashboard ,size:18,users:[] },
    { name: "Daily Tasks", to: "/daily_tasks", icon: HiOutlineMenuAlt2 ,size:18,users:[]},
    { name: "My Contact", to: "/contacts", icon: AiOutlineContacts,size:16,users:[] },
    { name: "Options Shared", to: "/options_shared", icon: IoMdOptions,size:16,users:[]},
    { name: "Inspection", to: "/inspections", icon: VscInspect,size:16,users:[]},
    { name: "LOI/Agreement", to: "/loi_agreement", icon: HiOutlineDocument,size:16,users:[]},
    { name: "Invoice Raised", to: "/invoice_raised", icon: HiOutlineDocument,size:16,users:[]},
    { name: "Hold", to: "/hold", icon: AiOutlinePauseCircle,size:16,users:[]},
    { name: "Profile", to: "/profile", icon: FiUser,size:19,users:[]},
    { name: "Update Password", to: "/update_password", icon: MdPassword,size:16,users:[]},
    { name: "Settings", to: "/settings", icon: AiOutlineSetting,size:16,users:[]},
    { name: "Support", to: "/support", icon: MdOutlineContactSupport,size:16,users:[]},
    { name: "Admin", to: "/company_leads", icon: RiMastercardLine,size:16,users:['fcpl_240','fcpl_239'] },
    { name: "Onboard", to: "/onboard_list", icon: AiOutlineUserAdd,size:16,users:['fcpl_240','fcpl_239']},
    { name: "Department", to: "/department", icon: BiBuilding,size:16,users:['fcpl_240','fcpl_239']},
    { name: "Designation", to: "/designation", icon: VscGroupByRefType,size:16,users:['fcpl_240','fcpl_239']},
    { name: "Roles", to: "/roles", icon: AiOutlineControl,size:16,users:['fcpl_240','fcpl_239']},
    { name: "Stages", to: "/stages", icon: HiOutlineTemplate,size:16,users:['fcpl_240','fcpl_239']},
    { name: "CSDT", to: "/csdt_list", icon: IoMdOptions,size:16,users:['fcpl_240','fcpl_239']},
  ];

  const drawer = (
    <div className={` relative `} style={{ height: "100%",minHeight:'100%',maxHeight:'100vh',overflow:'hidden', color: "#fff" }}>
     
     

      {/* {applive == true && */}
      <>
      <>
      <h6 className={`text-[#000] bg-[${btnColor}] font-[600] p-2 text-[14px] h-16 mb-3 text-center`}>Hi {auth?.name}</h6>
      </>
      {userRoute?.map((item) => (
         <>
         {(item?.users !== undefined  && item?.users?.includes(auth.employee_id)) ?
          <div key={item?.name}>
            <div className="flex items-center mb-1 cursor-pointer relative ml-5 py-1 mb-1" onClick={()=>{navigator(item.to);setMobileOpen(false)}}>
              {item.icon && <item.icon size={item.size} color="#000" /> }
              <span  className="text-[#000] ml-2 text-[12px] font-[500]" style={{color:'black'}}>
                {item.name}
              </span>
            </div>
           
          </div>
          
         :
         <>
         {item?.users?.length === 0 &&
         <>
         <div key={item?.name}>
            <div className="flex items-center mb-1 cursor-pointer relative ml-5 py-1 mb-1" onClick={()=>{navigator(item.to);setMobileOpen(false)}}>
              {item.icon && <item.icon size={item.size} color="#000" /> }
              <span className="text-[#000] ml-2 text-[12px] font-[500]">
                {item.name}
              </span>
            </div>
           
          </div>
          </>}
          </>
         
         
         }
          </>
            
      ))}
      </>
      {/* } */}

     

      <div className="flex ml-5 mb-5 cursor-pointer w-full opacity-60" onClick={handleLogout}>
          <HiLogout size={18} color="#fff" /> 
          <span className="text-white  ml-2 text-[12px] text-black font-semibold">
            {'Log out'}
          </span>
      </div> 
       
    </div>
  );


  return (
  <>
    <Drawer
              variant="temporary"
              open={mobileOpen}
              onClose={()=>setMobileOpen(false)}
              ModalProps={{
                keepMounted: true,
              }}
              sx={{
                display: { xs: "block", sm: "none" },
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: drawerWidth,
                },
              }}
            >
              {drawer}
            </Drawer>
  <Box  onClick={()=>setMobileOpen(!mobileOpen)} >
      <Box  style={{display:'flex',background:btnColor,minWidth:'100vw',alignItems:'center',justifyContent:'space-between',padding:8,paddingRight:15}}>
      <Box style={{display:'flex',alignItems:'center'}}>
      <IoMenu size={24} color="#000" />
      <Typography variant='span' ml={1} fontWeight={'600'} fontSize={'14px'}>{name}</Typography>
      </Box> 
      <IoMdAdd size={24} color="#000" onClick={()=>navigator('create')} />
      </Box>
  </Box>
  </>  

)} 


export const MobileDrawerBackAdd = ({name,goback})=>{
  const navigate = useNavigate()

  return (   
  <Box style={{display:'flex',background:btnColor,alignItems:'center',padding:6}} sx={{display:{xs:'block',sm:'none',md:'none',lg:'none'}}}>
  <Box  >
      <Box  style={{display:'flex',background:btnColor,minWidth:'100vw',alignItems:'center',justifyContent:'space-between',padding:4,paddingRight:15}}>
      <Box style={{display:'flex',alignItems:'center',marginLeft:-5}}>
      <IoChevronBack size={24} color="#000" onClick={goback} />
      <Typography variant='span' ml={1} fontWeight={'600'} fontSize={'14px'}>{name}</Typography>
      </Box> 
      <IoMdAdd size={24} color="#000" onClick={()=>navigate('create')} />
      </Box>
  </Box>
  </Box>
)} 


export const MobileBackOption = ({goback,name})=>{
 
  return (
    
  <Box style={{display:'flex',background:btnColor,alignItems:'center',padding:6}} sx={{display:{xs:'block',sm:'none',md:'none',lg:'none'}}}>
    <Box>
      <Box  style={{display:'flex',background:btnColor,minWidth:'100vw',alignItems:'center',justifyContent:'space-between',padding:4,paddingRight:15}}>
      <Box style={{display:'flex',alignItems:'center',marginLeft:-5}}>
      <IoChevronBack size={24} color="#000" onClick={goback} />
      <Typography variant='span' ml={1} fontWeight={'600'} fontSize={'14px'}>{name}</Typography>
      </Box> 
      </Box>
  </Box>
  </Box>
)} 
