import React,{useEffect, useState} from 'react'
import { toast } from 'react-hot-toast'
import { ButtonFilledAutoWidth } from '../../../components/button'
import { TextInput } from '../../../components/input'
import { useNavigate, useLocation } from 'react-router-dom'
import { CreateStageService, UpdateStageService } from '../../../services/StageServices'
import SettingsMenu from '../../staticscreens/SettingsMenu'
import GoBack from '../../../components/back/GoBack'
import { Select } from 'antd';
import { GetDepartmentService } from '../../../services/DepartmentServices'
import {BiErrorCircle} from 'react-icons/bi'

function StageCE() {

  const location = useLocation();
  
 
  const [data,setdata] = useState({name:'',sort:'',department:''});  
  const [error,seterror] = useState({name:'',sort:'',department:''}); 
  const [departmentArr,setdepartmentArr] = useState([])
  
  const path = location.pathname.split('/')[location.pathname.split('/')?.length - 2]
  const {state} = useLocation();

  const navigate = useNavigate();

  // console.log("state",state)


  useEffect(()=>{
    getDepartment()
    if(state?.id !== null && state?.id !== undefined){
      setdata({...data,name:state.name,sort:state.sort,department:state.department})
    }

  },[state])


  async function getDepartment(){
    const response = await GetDepartmentService()
    let arr = []
    response?.data?.datas?.forEach((d)=>{
        arr?.push({label:d?.department_name,value:d.id})
    })
    setdepartmentArr(arr)
    // console.log("response",response)

  }

  function handlechange(e){
    setdata({...data,[e.target.name] : e.target.value})
    seterror({...error,[e.target.name]:''})
  }

  async function submitform(){
    if(!data.name){
        seterror({...error,name:'Stage Name is required *'})
    }else if(path === 'Lead_Stage' && data?.department?.value === undefined){  
        seterror({...error,department:'Department Name is required *'})
    }else if(!data.sort){
      seterror({...error,sort:'Sort Field is required *'})
    }else{
        if(state?.id === undefined || state?.id === null){
        let send_data = {...data}
        send_data['type'] = location.pathname.split('/')[3]
        if(path === 'Lead_Stage'){ 
          if(data?.department?.value !== undefined){
            send_data["department"] = data?.department?.value
          }
        }

        if(send_data?.department == ''){
          delete send_data?.department
        }

        const response = await CreateStageService(send_data)
        if (response.status === 201) {
          setdata({...data,name:'',sort:'',department:''})
          seterror({...error,name:'',sort:'',department:''})
          toast.success(`${location.pathname.split('/')[location.pathname.split('/')?.length - 2]} Created Successfully`)
        }   
        if(response.status === 422){
          if(response?.data?.errors?.name){
            seterror({...error,name:response?.data?.errors?.name})
          }else{
            seterror({...error,sort:response?.data?.errors?.sort})
          }
        } 
      }else{
        let send_data = {...data}
        send_data["id"] = state?.id
        send_data['type'] = location.pathname.split('/')[location.pathname.split('/')?.length - 2]

        if(path === 'Lead_Stage'){ 
          if(data?.department?.value !== undefined){
            send_data["department"] = data?.department?.value
          }
        }

        if(send_data?.department == ''){
          delete send_data?.department
        }

        const response = await UpdateStageService(send_data,state?.id)
        if (response.status === 200) {
          setdata({...data,name:'',sort:'',department:''})
          seterror({...error,name:'',sort:'',department:''})
          navigate(-1)
          toast.success(`${location.pathname.split('/')[location.pathname.split('/')?.length - 2]} Updated Successfully`)
        }   
        if(response.status === 422){
          if(response?.data?.errors?.name){
            seterror({...error,name:response?.data?.errors?.name})
          }else{
            seterror({...error,sort:response?.data?.errors?.sort})
          }
        } 
      }
  }
  }

  async function handleSelect(e,e1){
    if(e1 === 'Department'){
     setdata({...data,department:departmentArr?.find((i)=>i.value === e)})
     seterror({...error,department:''})
    }
  }  

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border:'1px solid rgb(227 231 234)',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      padding: '0px 6px',
      fontSize:'13px',
      fontWeight:600,
      marginBottom:'0px',
      paddingBottom:'0px'
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
    }),
  };


  

  return (
    <div className='flex '>


    <SettingsMenu />
 
  <div className='sm:w-full lg:w-72 px-4 pt-5' >


      <GoBack /> 
      
      

        <h6 className='font-bold'>{(state?.id !== null && state?.id !== undefined) ? 'Edit' : 'Add'} {location.pathname.split('/')[location.pathname.split('/')?.length - 2]?.replace('_',' ')}</h6>
        <h6 className='text-[10px] bg-slate-100 p-2 leading-snug' >Use the below form to create or edit the <b> Stages</b> for your form options.</h6>
     

        <TextInput 
            label={'Name'}  
            variant="standard"
            name="name"
            type="text"
            error={error.name}
            value={data.name}
            handlechange={handlechange}
            placeholder="Enter your stage name"
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>

          {path === 'Lead_Stage' &&
            <>
            <h6 className='text-[12px] font-semibold mb-1 mt-1'>Department </h6>
              <Select
                value={data.department} 
                error={error.department}
                placeholder="" 
                bordered={false}
                onChange={(e)=>handleSelect(e,'Department')} 
                styles={customStyles} 
                options={departmentArr} 
                className='w-full border border-gray-300 outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none'
                
                />

              {error.department !== '' && error.department !== undefined &&
              <div className='flex items-center mt-1'>
              <BiErrorCircle className='text-red-500' size={14} />
              <span className='text-[10px] text-red-500 ml-1'>{error.department}</span>
              </div>}

           </>}


          <TextInput 
            label={'Sort'}  
            variant="standard"
            name="sort"
            type="text"
            error={error.sort}
            value={data.sort}
            handlechange={handlechange}
            placeholder="Enter your stage sort"
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>
<div className='mt-5'>
        <ButtonFilledAutoWidth btnName={(state?.id !== null && state?.id !== undefined) ? "UPDATE STAGE" : "ADD STAGE"}  onClick={submitform} />  
        </div>


    </div>
    </div>
  )
}

export default StageCE