import { deleteRequest, get, publicPost, put } from "../../../helpers/apihelpers";

export const CreateHRWebsiteStageService=async(data)=>{
    try {
        const result = await publicPost(`api/hr_website/stages/create`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const SearchHRWebsiteStageService=async(text,type)=>{
    try {
        const result = await get(`api/hr_website/stages/search?type=${type}&text=${text}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateHRWebsiteStageService=async(data,id)=>{
    try {
        const result = await put(`api/hr_website/stages/update/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetHRWebsiteStageService=async(page,text,type)=>{
    try {
        const result = await get(`api/hr_website/stages/get?page=${page}&text=${text}&type=${type}`,);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteHRWebsiteStageService=async(id)=>{
    try {
        const result = await deleteRequest(`api/hr_website/stages/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}