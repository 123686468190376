import React,{useEffect, useState} from 'react'
import { toast } from 'react-hot-toast'
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../../components/button'
import { TextAreaInput1, TextInput, TextInput1 } from '../../../components/input'
import { useNavigate, useLocation } from 'react-router-dom'
import GoBack from '../../../components/back/GoBack'
import { CreateFeatureOptionService, UpdateFeatureOptionService, UploadFeatureOptionService } from '../../../services/ITServices/FeatureOption/FeatureOptionServices'
import ItMenu from '../ItMenu'
import { GetDepartmentService } from '../../../services/DepartmentServices'
import { Modal, Select } from 'antd'
import { AiOutlinePlus,AiOutlineEdit,AiOutlineDelete } from 'react-icons/ai'
import { CreateUpcomingPurchaseOptionOptionService, UpdateUpcomingPurchaseOptionOptionService } from '../../../services/ITServices/PuchaseOption/UpcomingPurchaseOptionServices'
import { CreateInventoryPurchaseOptionService, UpdateInventoryPurchaseOptionService } from '../../../services/ITServices/PuchaseOption/InventoryPurchaseOptionServices'

function InventoryPurchaseOptionCE() {
 
  const [data,setdata] = useState({department:'',purchase_summary:[],purchased_date:'',remarks:''});  
  const [error,seterror] = useState({department:'',purchase_summary:'',purchased_date:'',remarks:''});
  const [type,settype] = useState("");
  const [modal,setmodal] = useState(false);
  
  const status = [{label:'New',value:'New'},{label:'Refurbished',value:'Refurbished'}]
  const [departmentArr,setdepartmentArr] = useState([])


  const [purchase_summary,setpurchase_summary] = useState({item_info:'',cost:'',code:'',purchase_type:'',quantity:''})
  const [error1,seterror1] = useState({item_info:'',cost:'',code:'',purchase_type:'',quantity:''});

  const {state} = useLocation();
  const navigate = useNavigate();

  useEffect(()=>{
    if(state?._id !== null && state?._id !== undefined){

      let arr  = []

      state?.purchase_summary?.forEach((d,i)=>{
        arr.push({id:i,...d})
      })
      setdata({
                ...data,
                ...state,
                purchase_summary:arr,
                purchased_date:state?.purchased_date?.slice(0,10),
                department:{label:state?.department?.department_name,value:state?.department?._id},
                created_by:{label:state?.created_by?.name,value:state?.created_by?._id},
              })
    }
    getdata()
  },[state])

  function handlechange(e){
    setpurchase_summary({...purchase_summary,[e.target.name] : e.target.value})
    seterror1({...error1,[e.target.name]:''})
  }

  async function getdata(){
    let department_Arr = []
    const response = await GetDepartmentService()

    response?.data?.datas?.forEach(d => {
        department_Arr.push({label:d.department_name,value:d.id})

    });
    // getemployeebasedondepartment(department_Arr[0]?.value)
    setdepartmentArr(department_Arr)
  }

  async function handleSelect(e,e1){
    if(e1 === 'department'){
     setdata({...data,department:departmentArr?.find(d1 => d1?.value === e)})
     seterror({...error,department:''})
    }
  }

  async function submitform(){
    if(!data.department){
        seterror({...error,department:'Department field is required *'})
    }if(data.purchase_summary.length === 0){
      seterror({...error,purchase_summary:'Purchase Summary field is required *'})
    }else{
        if(state?._id === undefined || state?._id === null){
        let send_data = {...data}
        send_data['department'] = data.department.value
        const response = await CreateInventoryPurchaseOptionService(send_data)
        if (response.status === 201) {
          setdata({department:'',purchase_summary:[],purchased_date:'',remarks:''})
          seterror({department:'',purchase_summary:'',purchased_date:'',remarks:''})
          toast.success(`Created Successfully`)
        }   
        if(response.status === 422){
          seterror({...error,name:response?.data?.errors?.name})
        } 
      }else{
        let send_data = {...data}
        send_data['department'] = data.department.value
        send_data['created_by'] = data.created_by.value
        send_data["_id"] = state?._id
        const response = await UpdateInventoryPurchaseOptionService(send_data,state?._id)
        if (response.status === 200) {
          setdata({department:'',purchase_summary:[],purchased_date:'',remarks:''})
          seterror({department:'',purchase_summary:'',purchased_date:'',remarks:''})
          navigate(-1)
          toast.success(`Updated Successfully`)
        }   
        if(response.status === 422){
          seterror({...error,name:response?.data?.errors?.name})
        } 
      }
  }
  }

  function adddata(){
    if(!purchase_summary.item_info){
      seterror({...error1,item_info:'This Field is required *'})
    }else if(!purchase_summary.quantity){
      seterror({...error1,quantity:'This Field is required *'})
    }else{
       if(purchase_summary.id !== undefined){
        let oldData = [...data?.purchase_summary]
        oldData[data.id] = data
        setdata({...data,purchase_summary:oldData})
        setpurchase_summary({item_info:'',cost:'',code:'',purchase_type:'',quantity:''})
        seterror1({item_info:'',cost:'',code:'',purchase_type:'',quantity:''})
        toast.success("Updated Successfully!")
       }else{
        let  oldData = [...data?.purchase_summary]
        oldData.push({...purchase_summary,id:oldData.length})
        setdata({...data,purchase_summary:oldData})
        setpurchase_summary({item_info:'',cost:'',code:'',purchase_type:'',quantity:''})
        seterror1({item_info:'',cost:'',code:'',purchase_type:'',quantity:''})
        toast.success("Created Successfully!")
       }
    }
  }

  function deleteData(d){
    let filterData = data?.purchase_summary?.filter((f)=>f?.id !== d?.id)
    setdata({...data,purchase_summary:filterData})
    toast.success("Deleted Successfully!")
  }

  console.log("data",data)


  return (
    <div className='flex '>
      <Modal open={modal} width={280} footer={false} closable={false}>
      <h6 className='text-[14px] font-[700]'>Create or update the task list</h6>  
            <h6 className='bg-slate-100 text-[10px] font-[500] p-1'>Use the below form to create or update the task list for you</h6> 
           
             
            <TextAreaInput1 
             mandatory={true}
             label={'Item Description'}  
             variant="standard"
             name="item_info"
             type="text"
             value={purchase_summary?.item_info}
             error={error1?.item_info}
             handlechange={handlechange}
             placeholder=""
             InputLabelProps={{
                 style: { color: '#fff', }, 
             }}
            />

          <TextAreaInput1 
             mandatory={true}
             label={'Code'}  
             variant="standard"
             name="code"
             type="text"
             value={purchase_summary?.code}
             error={error1?.code}
             handlechange={handlechange}
             placeholder=""
             InputLabelProps={{
                 style: { color: '#fff', }, 
             }}
            />

              <TextInput1 
                mandatory={true}
                label={'Cost'}  
                variant="standard"
                name="cost"
                type="text"
                value={purchase_summary?.cost}
                error={error1?.cost}
                handlechange={handlechange}
                placeholder=""
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>


            <TextInput1 
                mandatory={true}
                label={'Quantity'}  
                variant="standard"
                name="quantity"
                type="text"
                value={purchase_summary?.quantity}
                error={error1?.quantity}
                handlechange={handlechange}
                placeholder=""
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>

        <h6 className='text-[12px] font-semibold mb-1 mt-1'>Purchase Type </h6>
        <div className='border border-slate-300'>
        <Select
           value={purchase_summary?.purchase_type} 
           error={error1?.purchase_type}
           bordered={false}
           placeholder="" 
           onChange={(e)=>setpurchase_summary({...purchase_summary,purchase_type:e})} 
           className='w-full border-l-4 border-l-slate-700 rounded-[0px]'
           options={status} 
           
           />
          </div> 

            <div className='flex mt-2 border-t pt-2 w-full items-end '>
                <div className='mr-2'>
                <ButtonOutlinedAutoWidth btnName="Cancel" onClick={()=>setmodal(false)} />
                </div>
                <ButtonFilledAutoWidth btnName="Save" onClick={()=>adddata()} />
            </div>

      </Modal>
      

    <div className='ml-2 min-w-44 max-w-44'>
      <ItMenu />
    </div>
    <div className='sm:w-full lg:w-72 px-4 pt-5' >


        <GoBack /> 

        <h6 className='font-[700]'>{(state?._id !== null && state?._id !== undefined) ? 'Edit' : 'Add'} Inventory puchase</h6>
        <h6 className='text-[10px] bg-slate-100 p-2 font-[500] leading-snug' >Use the below form to create or edit the <b>inventory puchase</b> for your company and employees.</h6>
     


        <h6 className='text-[12px] font-semibold mb-1 mt-1'>Department </h6>
        <div className='border border-slate-300'>
        <Select
           value={data.department} 
           error={error.department}
           bordered={false}
           placeholder="" 
           onChange={(e)=>handleSelect(e,'department')} 
           className='w-full border-l-4 border-l-slate-700 rounded-[0px]'
           options={departmentArr} 
           
           />
          </div> 


          <TextInput 
             mandatory={true}
             label={'Purchased Date'}  
             variant="standard"
             name="purchased_date"
             type="date"
             value={data?.purchased_date}
             error={error?.purchased_date}
             handlechange={(e)=>{setdata({...data,purchased_date:e.target.value});seterror({...error,purchased_date:''})}}
             placeholder=""
             InputLabelProps={{
                 style: { color: '#fff', }, 
             }}
            />
       
          <TextAreaInput1 
             mandatory={true}
             label={'Remarks'}  
             variant="standard"
             name="remarks"
             type="text"
             value={data?.remarks}
             error={error?.remarks}
             handlechange={(e)=>{setdata({...data,remarks:e.target.value});seterror({...error,remarks:''})}}
             placeholder=""
             InputLabelProps={{
                 style: { color: '#fff', }, 
             }}
            />

          <div className='flex items-center justify-between mt-2 border-b pb-2'>
            <h6 className='text-[11px]'>Total Data List ({data.purchase_summary?.length})</h6> 
            <AiOutlinePlus onClick={()=>setmodal(true)} className='bg-slate-100 p-[2px]' />
          </div>  

          <div className='border mt-2 w-[180%]'>
          <div className='flex text-[11px] bg-slate-100  w-[100%]'>
              <h6 className='p-1 border-r w-[10%]'>Sl No</h6>
              <h6 className='p-1 border-r w-[40%]'>Item Description</h6>
              <h6 className='p-1 border-r w-[20%]'>Quantity</h6>
              <h6 className='p-1 border-r w-[20%]'>Cost</h6>
              <h6 className='p-1  w-[10%]'>Action</h6>
          </div>

          {data?.purchase_summary?.map((d,i)=>(
              <div key={i} className='flex text-[11px] border-t w-[100%]'>
                  <h6 className='p-1 border-r w-[10%]'>{d?.id}</h6>
                  <h6 className='p-1 border-r w-[40%]'>{d?.item_info}</h6>
                  <h6 className='p-1 border-r w-[20%]'>{d?.quantity}</h6>
                  <h6 className='p-1 border-r w-[20%]'>{d?.cost}</h6>
                  <h6 className='flex items-center w-[10%]'>
                      <AiOutlineEdit className='mx-2' onClick={()=>{setpurchase_summary({...purchase_summary,...d});setmodal(true)}} />
                      <AiOutlineDelete onClick={()=>deleteData(d)} />
                  </h6>
                </div>   
          ))}  
          </div>
       
        <div className='mt-5'>
        <ButtonFilledAutoWidth btnName={(state?._id !== null && state?._id !== undefined) ? `Update ${type}` : `Add ${type}`}  onClick={submitform} />  
        </div>


    </div>
    </div>
  )
}

export default InventoryPurchaseOptionCE