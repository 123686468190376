import { FULL_SCREEN, GALLERY_STATE, NOTES, PAGINATION_STATE } from "../actionTypes/staticActions";


export function staticAction(result){
    return{
     type:NOTES,
     payload:result
    }
 }

 export function galleryPagiantionAction(result){
    return{
     type:GALLERY_STATE,
     payload:result
    }
 }

 export function paginationAction(result){
   return{
      type:PAGINATION_STATE,
      payload:result
     }
 }

 export function fullScreenAction(result){
   return{
    type:FULL_SCREEN,
    payload:result
   }
}