import {  deleteRequest, get, post, put, } from "../../helpers/apihelpers";

export const CreateTargetServices =async(data)=>{
    try {
        const result = await post(`api/target/create`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateTargetServices =async(id,data)=>{
    try {
        const result = await put(`api/target/update/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetTargetService=async(page,incentive)=>{
    try {
        const result = await get(`api/target/get?page=${page}&incentive=${incentive}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteTargetService=async(id)=>{
    try {
        const result = await deleteRequest(`api/target/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}


export const GetTargetForCurrentInsentivePatternListService=async(period,page)=>{
    try {
        const result = await get(`api/target/get_all_employee_target_achieved?period=${period}&page=${page}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetEmployeeShareBasedOnUserService=async(page,user,period)=>{
    try {
        const result = await get(`api/invoice_payment/get_employee_share_based_on_user?page=${page}&period=${period}&user=${user}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetEmployeeShareOnMySelf=async(page)=>{
    try {
        const result = await get(`api/invoice_payment/get_employee_share_myself?page=${page}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateEmployeeShareBasedOnUserService=async(id,data)=>{
    try {
        const result = await put(`api/invoice_payment/update_employee_share_based_on_user/${id}`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetEmployeeTargetBasedOnInsentivePeriodService=async(user,period,incentive,page)=>{
    try {
        const result = await get(`api/invoice_payment/get_employee_share_based_on_user_insentive_period_year?incentive=${incentive}&period=${period}&user=${user}&page=${page}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetEmployeeTargetBasedOnInsentiveActive=async()=>{
    try {
        const result = await get(`api/target/get_all_employee_target_achieved_based_on_incentive`);
        return result;
    } catch (err) {
        return err.response;
    }
}