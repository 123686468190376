import React,{useState,useEffect} from 'react'
import { useNavigate } from 'react-router-dom';
import { DeleteTeamService, GetTeamService } from '../../../services/AdminServicesfile/TeamServices';
import { Tag,Modal,Pagination,Select } from 'antd';
import {AiOutlineEdit,AiOutlineDelete} from 'react-icons/ai';
import moment from 'moment';
import {IoIosClose} from 'react-icons/io';
import {SlTarget} from 'react-icons/sl';
import {  ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../../components/button';
import { toast } from 'react-hot-toast';
import SettingsMenu from '../../staticscreens/SettingsMenu';
import { GetAllInsentiveList } from '../../../services/AdminServicesfile/InsentiveServices';
import {BsArrowRepeat} from 'react-icons/bs';
import {FiChevronRight,FiChevronLeft} from 'react-icons/fi';
import { IconButton } from '@mui/material'
import { DeleteReportingService, GetReportingService } from '../../../services/AdminServicesfile/ReportingServices';


function Reporting() {
  const navigator = useNavigate()
  const [data,setdata] = useState([])
  const [modal, setModal] = useState(false);
  const [page,setpage] = useState(1)
  const [selectedData,setselectedData] = useState({})




  useEffect(()=>{
    getdata()
  },[page])

  async function getdata(){
    const response = await GetReportingService(page)
    setdata(response.data)
  }


  async function deleteTeamData(){
    const response = await DeleteReportingService(selectedData?._id)
    if(response?.status === 200){
      toast.success('Reporting Stage Deleted Successfully')
      setModal(false)
      setselectedData({})
      getdata()
    }
  }

  

  const handlePagination = (e, pageNumber) => {
    setpage(pageNumber)
  }


  async function resetfunc(){
    setpage(1)
  }
 

  return (
    <div >
        
        <div className='flex'>
          <SettingsMenu />
        <div className='mx-4 w-full'>
        <div className='flex  mt-5 border-b items-center  pb-2 justify-between  border-b'>
        <h6 className='font-[900] text-[14px] '>Reporting Team ({data?.pagination?.total})</h6>
         
        <div className='flex items-center'>
        <div className='flex items-center text-[12px] mr-2'>
            <h6 className='mr-2 font-[600]'>{page == 1 ? data?.datas?.length > 0 ? 1 : 0 : (page - 1) * data?.pagination?.limit } - {data?.pagination?.limit} of {data?.pagination?.total} </h6>
            <IconButton  onClick={resetfunc}><BsArrowRepeat size={16} /></IconButton>
            <div>
            <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}} ><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`} size={16} /></IconButton>
            <IconButton onClick={()=>{ page < data?.pagination?.totalPages  ? setpage(page+1) : console.log('')}}><FiChevronRight className={`${data?.pagination?.totalPages === page ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>
          </div>
        </div> 

        <ButtonFilledAutoWidth btnName="Add Team" onClick={()=>navigator('create')} />
        </div>
        </div>

        <Modal
        open={modal}
        closable={false}
        maskClosable={false}
        onClose={()=>setModal(false)}
        bodyStyle={{borderRadius:'0px !important'}}
        style={{maxWidth:'300px',borderRadius:'0px !important'}}
        footer={null}
      >
      <div style={{maxWidth:'300px',borderRadius:'0px !important'}} className="relative z-0 ">
           <IoIosClose size={20} className="absolute -right-4 -top-4 z-10" onClick={()=>{setModal(false)}} />
           <h6 className='text-[15px] font-[700]'>Are you sure ?</h6>
           <h6 className='text-[11px] leading-tight'>Please be sure before deleting the team because it might affect the team target and other process.</h6>
          

             
           <div className='flex mt-2'>
            <div >
            <ButtonOutlinedAutoWidth btnName ="Cancel" onClick={()=>{setModal(false);setselectedData({})}}  />
            </div>
            <div className='w-full ml-2' onClick={deleteTeamData} >

            <ButtonFilledAutoWidth btnName ="Confirm" />
            </div>
           </div>
      </div>
       </Modal>

       {data?.datas?.length === 0 &&
        <div className='grid place-items-center mt-20  items-center justify-center'>
          <img src={'https://fidecrmfiles.s3.amazonaws.com/NoDataFound1.png'} className='w-40 h-40 object-contain' /> 
          <h6 className='font-bold text-[14px] -mt-10'>No data found</h6>
          <h6 className='font-[500] w-[70%] text-center text-slate-700 text-[12.5px] -mt-2'>Oops we couldn't find any data added based on your current page option please click on add the data button to see list here.</h6>
        </div>
       }
        
        {data?.datas?.length > 0 &&
        <div className="max-h-[85vh] overflow-y-scroll  grid grid-cols-2 md:grid-cols-4 mt-1 -mx-1">
                {data?.datas?.map((d)=>(
                <div key={d?._id} className='border px-3 py-1 mt-1 -pt-2 mx-1 relative cursor-pointer' >
                    <div className='mt-1 relative'>
                        <span className='absolute right-1'  onClick={()=>{navigator('/settings/reporting/edit',{state:d?._id})}}><AiOutlineEdit size={14}/></span>
                        <span className='absolute right-7' onClick={()=>{setselectedData(d);setModal(true)}}  ><AiOutlineDelete size={14}/></span>
                        <h6 className='text-[12px] font-[800]' >{d?.name}</h6>
                        <h6 className='text-[11px] font-[500]' >Team Lead : {d?.team_lead?.name}</h6>
                        <h6 className='text-[11px] font-[500]' >Reporting Head : {d?.reporting_manager?.name}</h6>
                        <h6 className='text-[11px] font-semibold mt-0 mb-0' >Members : {d?.users?.length}</h6>
                        <h6 className='text-[11px] font-semibold mt-0 mb-2 -ml-0.5' > {d?.users?.map(e => <Tag key={e?._id} className='text-[9px] m-0.5'>{e?.name}</Tag>  )}</h6>
                          
                        <h6 className='text-[9px] font-semibold mb-1 -mt-1' >Created At : {moment(d?.createdAt).format('LLL')}</h6>
                    </div>
                </div>))}
        </div>}

        <div className="tex-center flex items-center justify-center mt-10 ">
          {(data?.pagination?.total > data?.pagination?.limit &&
            <Pagination size={'small'} page={page} className="flex justify-center my-10 pb-20" count={Math.ceil(data?.pagination?.total / 25) } onChange={handlePagination} />
          )}
    </div>
    </div>
    </div>


    </div>
  )
}

export default Reporting