import React, { useEffect, useState } from 'react'
import AtsMenu from './AtsMenu'
import { DatePicker, Select } from 'antd';
import {AiOutlineFilter} from 'react-icons/ai';
import { FiChevronRight,FiChevronLeft } from 'react-icons/fi';
import {BiReset} from 'react-icons/bi';
import { IconButton } from '@material-ui/core';
import { GetDepartmentService, GetUsersByDepartment } from '../../services/DepartmentServices';
import { GetAtsAnalyticsAdminService } from '../../services/Ats/AtsDashboardServices';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

function AtsTeamReport() {

  const [page,setpage] = useState(1)
  const [users,setusers] = useState([])
  const [search,setsearch] = useState({from_date:'',to_date:'',from_date1:'',to_date1:'',employee:'',stage:'',client:'',job_requirment:'',filter_applied:false})
  const [data,setdata] = useState({})
  const navigate = useNavigate()
  
  useEffect(()=>{
    getdepartment()
    getanalyticsreport()
  },[])

  useEffect(()=>{
    getanalyticsreport()
  },[page])

  async function getdepartment(){
    const response = await GetDepartmentService()
    let d = response?.data?.datas.find((f)=>f.department_name === 'HR Team')
    getusersbydepartment(d?.id)
  }

  async function getusersbydepartment(id){
     const response = await GetUsersByDepartment(id)
     let d = response?.data?.datas
     let arr = []
     d.forEach((d1)=>{
         arr?.push({label:d1?.name,value:d1?._id})   
     })
     setusers(arr)
  }

  async function getanalyticsreport(page1){
     const response = await GetAtsAnalyticsAdminService({page:page1!==undefined ? page1 : page,from_date:search?.from_date !== '' ? search?.from_date1 : '',to_date:search?.to_date !== '' ? search?.to_date1 : '',user:search?.employee!== '' ? search?.employee?.value : ''})
     setdata(response?.data)
  }

 
  
  function resetdata(){
      setsearch({from_date:'',to_date:'',employee:'',stage:'',client:'',job_requirment:'',filter_applied:false})
      getanalyticsreport(1)
  }

  // console.log("search",search)

  return (
    <div ClassName='w-[98%] h-screen overflow-y-hidden'>
         <div className='flex'>
            <div className='min-w-44'>
            <AtsMenu />
            </div>
            <div className='w-[86%] overflow-hidden  pl-3 mt-4'>
              
              <div className='flex items-center justify-between mb-2 border-b'>
                <h6 className='text-[13px] font-[800] '>Team Performance Report</h6>
                <div className='flex items-center text-[12px]'>
                        <div className='flex items-center'>
                        <h6 className='mr-2 font-[600]'>{page == 1 ? data?.datas?.length > 0 ? 1 : 0 : (page - 1) * data?.pagination?.limit } - {data?.pagination?.limit} of {data?.pagination?.total} </h6>
                        <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>
                        <IconButton onClick={()=>{ page < data?.pagination?.totalPages  ? setpage(page+1) : console.log('')}}><FiChevronRight className={`${(data?.pagination?.totalPages === page || data?.datas?.length === 0) ? 'opacity-50 ' : 'opacity-100'}`}  size={16} /></IconButton>

                        </div>
                        <DatePicker  size='small' style={{fontSize:'11px'}} placeholder='From Date' className='text-[11px] py-[2.6px]  w-28 border-black-500 mr-2'  value={search?.from_date} onChange={(v,v1) => {console.log('v na',new Date(v).toLocaleDateString());setsearch({...search,from_date:v,from_date1:v1})}} /> 

                        <DatePicker size='small' style={{fontSize:'11px'}} placeholder='To Date' className='text-[11px] py-[2.6px]  w-28 border-black-500 mr-2'  value={search?.to_date} onChange={(v,v1) => {console.log('v na',new Date(v).toLocaleDateString());setsearch({...search,to_date:v,to_date1:v1})}} /> 

                        
                        <div className='mr-2 border border-gray-300 rounded-[5px]'>

                          
                        <Select size='small' placeholder = "Select Company" onChange={(e)=>setsearch({...search,employee:users.find((e1)=>e1.value === e)})} value={search?.employee !== '' ? search?.employee : ''} bordered={false} className='w-[120px] p-[1.5px]' options={users} />    
                        </div>  
                        <div className='mr-2'>
                          <AiOutlineFilter onClick={()=>getanalyticsreport(1)} size={24} className='border border-slate-600 bg-slate-600 text-[#dedede] p-1 rounded' />
                        </div>
                        <div className='mr-2'>
                          <BiReset size={24} onClick={resetdata} className='border border-black-500 bg-[#f6f8fa] text-[#5c5c5c] p-1 rounded' />
                        </div>
                </div>
              </div>

              <div className='max-h-[85vh] mt-4 border-t no-scrollbar  border-l  border-black-500 overflow-y-scroll'>
                <div className='sticky bg-slate-100 uppercase font-[600] text-black top-0 z-50 flex py-[-5px] border-b border-black-500 relative'>
                    <h6 className='sticky top-0 z-50 font-[600] text-[12px] w-[120px]  px-2 py-1 font-[500] border-r border-slate-200'>Client</h6>
                    <h6 className='sticky top-0 z-50 font-[600] text-[12px] w-[240px]  px-2 py-1 font-[500] border-r border-slate-200'>Job Requirment </h6>
                    {users?.map((e,i)=>(
                    <h6  key={i} className={`text-center font-[600] uppercase text-[12px] w-[100px]  px-2 py-1  ${i < users?.length && 'border-r border-black-500'}  truncate`}> {e?.label?.split(' ')[0]}</h6>
                    ))}

                    
                </div>
                {data?.datas?.map((d)=>(
                <div className='flex  bg-white py-[-5px] border-b border-black-500 relative'>
                    <h6 className=' text-[13px] font-[600] w-[120px]  px-2 py-1  border-r break-all border-black-500 text-slate-600  font-[500]'>{d?.ats_hr_client?.company_name}</h6>
                    <h6 onClick={()=>navigate('/ats/job_description/detail',{state:{data:d._id}})} className=' text-[12px] w-[240px]  px-2 py-1  bg-violet-100 border-r border-black-500 text-sky-700 cursor-pointer font-[600] '><span className='underline'> {d?.job_title}</span> <p className='text-black text-[10px]'>Description ({d?.job_description})</p>  <p className='text-black text-[10px]'>No of opening ({d?.no_of_opening})</p> <span className='font-[600] font-[11px] text-black'>Created At : {moment(d?.createdAt)?.format('LL')}</span> </h6>
                    {users?.map((e,i)=>(
                    <h6 key={i} className={`text-center font-[900] text-[12px] flex items-center justify-center w-[100px]  px-2 py-1  ${i < users?.length && 'border-r border-black-500'}  truncate`}> {d?.ats_hr_client_job?.length > 0 && d?.ats_hr_client_job?.find((f)=>f?.id === e?.value) !== undefined &&  d?.ats_hr_client_job?.find((f)=>f?.id === e?.value)?.count } </h6>
                    ))}                   
                </div>
                ))}
              </div>  
         
            </div>
          </div>   
    </div>
  )
}

export default AtsTeamReport