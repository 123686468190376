import React,{useState,useEffect} from 'react';
import { DeleteSelfRegistrationHr, GetOnboardListService, SendOnboardLink } from '../../services/OnboardServices';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import {AiOutlineEdit,AiOutlineDelete, AiOutlineFileExcel} from 'react-icons/ai';
import {BiErrorCircle} from 'react-icons/bi';
import SettingsMenu from '../staticscreens/SettingsMenu';
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../components/button';
import {MdPassword} from 'react-icons/md';
import { Modal,Select } from 'antd';
import { TextInput, TextInput1 } from '../../components/input';
import { GetSearchService, UpdateUserPasswordService, downloaduserExcel } from '../../services/AuthServices';
import { toast } from 'react-hot-toast';
import {FiChevronRight,FiChevronLeft} from 'react-icons/fi';
import { IconButton } from '@mui/material'
import {BsArrowRepeat} from 'react-icons/bs';
import { GetDepartmentService } from '../../services/DepartmentServices';
import { GetDesignationService } from '../../services/DesignationService';
import { GetTaluk } from '../../services/CSDTServices';
import fileDownload from "js-file-download";
import axios from 'axios';

function OnboardList() {
 
 const [page,setpage] = useState(1)
 const [data,setdata] = useState([]) 
 const [selectedData,setselectedData] = useState({})
 const [modal,setmodal] = useState(false)
 const [delete_modal,setdelete_modal] = useState(false)
 const [on_boardmodal,seton_boardmodal] = useState(false)
 const [filterApplied,setfilterApplied] = useState(false)
 const [departmentArr,setdepartmentArr] = useState([])
 const [designationArr,setdesignationArr] = useState([])
 const [branchArr,setbranchArr] = useState([])
 const [send_Data,setsend_Data] = useState({})
 const [send_Data_err,setsend_Data_err] = useState({})
 const [search,setsearch] = useState('')
 const navigate = useNavigate() 

 const [step,setstep] = useState(1)

 const stages = ['Active','InActive','HR Review','Link Shared']

 const [password,setpassword] = useState('')
 const [error,seterror] = useState('')

 
 useEffect(()=>{
 getoptions()
 },[])

 useEffect(()=>{
 if(filterApplied){
 searchfunction()
 }else{
 getOnboardList()
 }
 },[page,step]) 


 async function getoptions(){
 const response = await GetDepartmentService()
 const response1 = await GetDesignationService()
 const response2 = await GetTaluk()

 let arr = []
 let arr1 = []
 let arr2 = []
 
 response?.data?.datas?.forEach((d)=>{
 arr.push({value:d?.id,label:d?.department_name})
 })
 response1?.data?.datas?.forEach((d)=>{
 arr1.push({value:d?.id,label:d?.designation_name})
 })

 response2?.data?.data?.datas?.forEach((d)=>{
  arr2.push({value:d?._id,label:d?.display_name})
 })
 
 setdepartmentArr(arr)
 setdesignationArr(arr1)
 setbranchArr(arr2)
 }

//  console.log("branchArr",branchArr)
 
 async function getOnboardList(){
 const response = await GetOnboardListService(page,step);
 setdata(response.data)
 }

 async function updatePassword(){
 if(password.length < 8){
 seterror('Password Shoild be atleast 8 characters')
 }else{
 let send_Data = {
 'user_id':selectedData?._id,
 'password':password
 }
 const response = await UpdateUserPasswordService(send_Data)
 if(response.status === 200){
 setpassword('')
 seterror('')
 setmodal(false)
 toast.success(response?.data?.message)
 }
 }
 }

 async function searchfunction(){
 if(search.length < 3){

 }else{
 const response = await GetSearchService(search,page)
 setdata(response.data)
 setfilterApplied(true)
 }
 
 }

 function setpasswordfunc(e){
 setpassword(e.target.value)
 seterror('')
 }

 async function resetfunc(){
 setpage(1)
 setfilterApplied(false)
 getOnboardList()
 setsearch('')
 }

 async function handleSelect(e,e1){
 if(e1 === 'department'){
  setsend_Data({...send_Data,department:departmentArr?.find((d)=>d.value === e)})
  setsend_Data_err({...error,department:''})
 }else if(e1 === 'designation_id'){
  setsend_Data({...send_Data,designation_id:designationArr?.find((d)=>d.value === e)})
  setsend_Data_err({...error,designation_id:''})
 }else if(e1 === 'branch'){
  setsend_Data({...send_Data,branch:branchArr?.find((d)=>d.value === e)})
  setsend_Data_err({...error,branch:''})
  }
 }


 async function sendOnboardLink(){
    if(!send_Data.name){
    setsend_Data_err({...send_Data_err,name:"Name Field is required*"})
    }else if(!send_Data.email){
    setsend_Data_err({...send_Data_err,email:"Email Field is required*"})
    }else if(!send_Data.doj){
    setsend_Data_err({...send_Data_err,doj:"Doj Field is required*"})
    }else if(send_Data?.department?.value === undefined){
    setsend_Data_err({...send_Data_err,department:"Department Field is required*"})
    }else if(send_Data?.designation_id?.value === undefined){
    setsend_Data_err({...send_Data_err,designation_id:"Designation Field is required*"})
    }else if(send_Data?.branch?.value === undefined){
      setsend_Data_err({...send_Data_err,branch:"Branch Field is required*"})
    }else if(!send_Data.ctc){
      setsend_Data_err({...send_Data_err,ctc:"CTC Field is required*"})
    }else{
      let obj = Object.assign({...send_Data})
      delete obj.department
      delete obj.designation_id
      obj.department_id = [send_Data?.department?.value]
      obj.designation_id = send_Data?.designation_id?.value
      obj.branch = send_Data.branch?.value
      const response = await SendOnboardLink(obj)
      if(response?.status === 201){
        getOnboardList()
      toast.success(response?.data?.message)
      setsend_Data_err({})
      setsend_Data({department:'',designation_id:''})
      seton_boardmodal(false)
      }
      if(response?.status === 403){
        if(response?.data?.err?.errors?.phone !== undefined){
            setsend_Data_err({...send_Data_err,phone:"Mobile No already exist*"})
        }else if(response?.data?.err?.errors?.email !== undefined){
          setsend_Data_err({...send_Data_err,email:"Email Id already exist*"})
        }else if(response?.data?.err !== undefined){
        setsend_Data_err({...send_Data_err,email:"Email Id already exist*"})
       }
      }
    }
 }

 // console.log("send_Data",send_Data)


 async function onchange(e){
 setsend_Data({...send_Data,[e.target.name]:e.target.value})
 setsend_Data_err({...send_Data_err,[e.target.name]:''})
 }

 async function deleteData(){
  const response = await DeleteSelfRegistrationHr(selectedData?._id)
  if(response?.status === 200){
    getOnboardList()
    setdelete_modal(false)
    toast?.success("Deleted Successfully")
    setselectedData({})
  }
 }

 async function downloadExcel(){
   const response = await downloaduserExcel(step)
  //  console.log("response?.data",response?.data)
   let d1 = response?.data?.path
   let d = d1?.replace('public/','')

   handleDownload(`${process.env.REACT_APP_BACKEND_IMAGE_URL}${d}`,d?.split('/')[d?.split('/')?.length - 1])
 }

 const handleDownload = (url, filename) => {
  axios.get(url, {
      responseType: 'blob',
  })
      .then((res) => {
          fileDownload(res.data, filename)
      })
}

 return (
 <div> 

 <Modal 
 open={modal}
 closable={false}
 footer={false}
 width={300}>
 <div className='relative'>
 <h6 className="font-bold text-[15px] mb-2 w-full">Update Password</h6>
 <h6 className='bg-slate-100 text-[12px] p-1.5 font-[400]'>Are you sure to update the password for the employee who is having a name of <span className='font-[700]'>[{selectedData?.name}]</span> who is having a employee_id of <span className='font-[700]'>[{selectedData?.employee_id}]</span> </h6>

 <TextInput error={error} name="password" value={password} handlechange={setpasswordfunc} /> 
 <div className='flex justify-end mt-3 '>
 {/* <div className='mr-1 w-full'> */}
 <ButtonOutlinedAutoWidth btnName={'Cancel'} onClick={()=>setmodal(false)} />
 {/* </div> */}
 <div className='ml-2'>
 <ButtonFilledAutoWidth btnName={'Confirm'} onClick={updatePassword} /> 
 </div>
 </div>
 </div>
 </Modal>

 <Modal 
 open={delete_modal}
 closable={false}
 footer={false}
 width={300}>
 <div className='relative'>
 <h6 className="font-bold text-[15px] mb-2 w-full">Delete Data</h6>
 <h6 className='bg-slate-100 text-[12px] p-1.5 font-[400]'>Are you sure to want to delete the data of the <span className='font-[700]'>[{selectedData?.name}]</span> once deleted it will be not retrieved! </h6>

 <div className='flex justify-end mt-3 '>
 {/* <div className='mr-1 w-full'> */}
 <ButtonOutlinedAutoWidth btnName={'Cancel'} onClick={()=>setdelete_modal(false)} />
 {/* </div> */}
 <div className='ml-2'>
 <ButtonFilledAutoWidth btnName={'Confirm'} onClick={deleteData} /> 
 </div>
 </div>
 </div>
 </Modal>


 <Modal 
 open={on_boardmodal}
 closable={false}
 footer={false}
 width={300}>
 <div className='relative '>
 <h6 className="font-bold text-[15px] mb-2 w-full">Send Link</h6>
 <h6 className='bg-slate-100 text-[12px] p-1.5 font-[400]'>Are you sure to send the self registration link for the selected user for onboarding! </h6>
 <TextInput1 label="Name*" name="name" error={send_Data_err?.name} value={send_Data?.name} handlechange={onchange} />
 <TextInput1 label="Mobile*" name="phone" error={send_Data_err?.phone} value={send_Data?.phone} handlechange={onchange} />
 <TextInput1 label="Email*" name="email" error={send_Data_err?.email} value={send_Data?.email} handlechange={onchange} />
 <div className="w-full">
 <h6 className='text-[12px] font-semibold mb-1 mt-1'>Date of Joining </h6>
 <input className='border p-1.5 text-[11px] w-full text-[11px] border-gray-300 focus:outline-none focus:ring-0' type='Date' value={send_Data?.doj?.slice(0,10)} onChange={(e)=>{setsend_Data({...send_Data,doj:e.target.value});setsend_Data_err({...send_Data_err,doj:''})}} />
 </div>
 {send_Data_err?.doj !== '' && send_Data_err?.doj !== undefined &&
 <div className='flex items-center mt-1'>
 <BiErrorCircle className='text-red-500' size={14} />
 <span className='text-[10px] text-red-500 ml-1'>{send_Data_err?.doj}</span>
 </div>}

 <h6 className='text-[12px] font-semibold mb-1 mt-1'>Department </h6>
 <Select
 value={send_Data?.department} 
 error={send_Data_err?.department}
 bordered={false}
 placeholder="" 
 allowClear={true}
 onChange={(e)=>handleSelect(e,'department')} 
 options={departmentArr} 
 className='w-full border text-[11px] outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-transparent focus:ring-offset-0 focus:shadow-none'
 />
 {send_Data_err?.department !== '' && send_Data_err?.department !== undefined &&
 <div className='flex items-center mt-1'>
 <BiErrorCircle className='text-red-500' size={14} />
 <span className='text-[10px] text-red-500 ml-1'>{send_Data_err?.department}</span>
 </div>}



 <h6 className='text-[12px] font-semibold mb-1 mt-1'>Designation </h6>
 <Select
 value={send_Data?.designation_id} 
 error={send_Data_err?.designation_id}
 bordered={false}
 placeholder="" 
 onChange={(e)=>handleSelect(e,'designation_id')} 
 options={designationArr} 
 className='w-full border outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-transparent focus:ring-offset-0 focus:shadow-none'
 />
 {send_Data_err?.designation_id !== '' && send_Data_err?.designation_id !== undefined &&
 <div className='flex items-center mt-1'>
 <BiErrorCircle className='text-red-500' size={14} />
 <span className='text-[10px] text-red-500 ml-1'>{send_Data_err?.designation_id}</span>
 </div>}


 <h6 className='text-[12px] font-semibold mb-1 mt-1'>Branch </h6>
 <Select
 value={send_Data?.branch} 
 error={send_Data_err?.branch}
 bordered={false}
 placeholder="" 
 onChange={(e)=>{console.log("e here",e);handleSelect(e,'branch')}} 
 options={branchArr} 
 className='w-full border outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-transparent focus:ring-offset-0 focus:shadow-none'
 />

 {send_Data_err?.branch !== '' && send_Data_err?.branch !== undefined &&
 <div className='flex items-center mt-1'>
 <BiErrorCircle className='text-red-500' size={14} />
 <span className='text-[10px] text-red-500 ml-1'>{send_Data_err?.branch}</span>
 </div>}

 <TextInput1 label="CTC*" name="ctc" error={send_Data_err?.ctc} value={send_Data?.ctc} handlechange={onchange} />


 <div className='flex justify-end mt-3 '>
 {/* <div className='mr-1 w-full'> */}
 <ButtonOutlinedAutoWidth btnName={'Cancel'} onClick={()=>seton_boardmodal(false)} />
 {/* </div> */}
 <div className='ml-2'>
 <ButtonFilledAutoWidth btnName={'Confirm'} onClick={sendOnboardLink} /> 
 </div>
 </div>

 </div>

 </Modal>

 <div className='flex'>
 <SettingsMenu />
 <div className='w-[85%] px-4 pt-5'>
 <div sx={{display:{sm:'none',xs:'none',lg:'block',md:'block'}}}>
 <div className='flex items-center justify-between border-b pb-2'>
 <h6 className='font-[700] text-[14px] '>{'Onboard Request'} ({data?.pagination?.total})</h6>

 <div className='flex'>

 <div className='flex items-center text-[12px] mr-2'>

 <h6 className='mr-2 font-[600]'>{page == 1 ? data?.datas?.length > 0 ? 1 : 0 : (page - 1) * data?.pagination?.limit } - {data?.pagination?.limit} of {data?.pagination?.total} </h6>
 <AiOutlineFileExcel onClick={downloadExcel}  className='cursor-pointer bg-slate-200 p-[4px] rounded mx-1' size={23} />
 <IconButton onClick={resetfunc}><BsArrowRepeat size={16} /></IconButton>

 <div>
 <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}} ><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`} size={16} /></IconButton>
 <IconButton onClick={()=>{ page < data?.pagination?.totalPages ? setpage(page+1) : console.log('')}}><FiChevronRight className={`${data?.pagination?.totalPages === page ? 'opacity-50' : 'opacity-100'}`} size={16} /></IconButton>

 </div>
 </div>

 <div className='flex items-center'>
 <div>
 <input id="search_text" placeholder='Search Employee' type='text' value={search} onChange={(e)=>setsearch(e.target.value)} className='border py-[4px] focus:ring-0 focus:outline-0 text-[13px] w-28 px-1 rounded-md border-slate-300' />
 </div>
 <div className="ml-2 flex">
 <ButtonOutlinedAutoWidth onClick={()=>{setpage(1);searchfunction()}} btnName="Add Filter" />

 <div className="ml-2">
 <ButtonFilledAutoWidth btnName="Add New" onClick={()=>navigate('create')} />
 </div> 

 <div className="ml-2">
 <ButtonFilledAutoWidth btnName="Invite Link" onClick={()=>seton_boardmodal(true)} />
 </div> 
 </div> 
 </div>
 </div>

 </div> 


 <div className='flex border-b items-center'>
 {stages?.map((s,i)=>(
 <h6 key={i} onClick={()=>setstep(i+1)} className={`font-[600] ${step === i+1 && 'bg-slate-600 text-white'} text-center cursor-pointer py-1 min-w-[80px] w-fit px-2 text-[11px]`}>{s}</h6>
 ))}
 </div>

 {data?.datas?.length === 0 &&
 <div className='grid place-items-center mt-20 items-center justify-center'>
 <img src={'https://fidecrmfiles.s3.amazonaws.com/NoDataFound1.png'} className='w-40 h-40 object-contain' /> 
 <h6 className='font-bold text-[14px] -mt-10'>No data found</h6>
 <h6 className='font-[500] text-center w-[70%] text-slate-700 text-[12.5px] -mt-2'>Oops we couldn't find any "Employee" onboarded yet click on the add option to create a new employee for your organization.</h6>
 </div>
 }
 {data?.datas?.length !== 0 &&
 <div className='max-h-[85vh] overflow-y-scroll border mt-2'>
 <div className='sticky top-0 z-50 flex bg-white py-[-5px] border-b border-slate-200 relative'>
 <h6 className='text-[13px] w-10 px-2 py-1 font-[500] text-slate-600 border-r'></h6>
 <h6 className='text-[12px] w-[50px] px-2 py-1 font-[600] border-r capitalize'>Photo</h6>
 <h6 className='text-[12px] w-[90px] px-2 py-1 font-[600] border-r capitalize'>Emp Id</h6>
 <h6 className='text-[12px] w-[150px] px-2 py-1 font-[600] border-r capitalize'>Name</h6>
 <h6 className='text-[12px] w-[100px] px-2 py-1 font-[600] border-r capitalize'>Phone</h6>
 <h6 className='text-[12px] w-[180px] px-2 py-1 font-[600] border-r capitalize'>Email</h6>
 <h6 className='text-[12px] w-[140px] px-2 py-1 font-[600] border-r capitalize'>Department</h6>
 <h6 className='text-[12px] w-[140px] px-2 py-1 font-[600] border-r capitalize'>Roles</h6>
 <h6 className='text-[12px] w-[90px] px-2 py-1 font-[600] border-r capitalize'>Joined Date </h6>
 <h6 className='text-[12px] w-[90px] px-2 py-1 font-[600] border-r capitalize'>Created On </h6>
 <h6 className={`text-[12px] ${(step === 3 || step === 4) ? 'w-[60px]' : 'w-[60px]' } px-1 py-1 font-[600] capitalize`}>Action</h6>
 </div>
 

 {data?.datas?.map((d,i)=>(
 <div key={d?._id} className='flex border-b cursor-pointer z-0' >
 <h6 onClick={()=>navigate('edit',{state:d?._id})} className='text-[12px] w-10 text-center px-2 py-1 truncate border-r border-[#f7f7f7]'>{ page == 1 ? i+1 : ((page - 1) * data?.pagination?.limit) + i+1 }</h6>
 <h6 onClick={()=>navigate('edit',{state:d?._id})} className='text-[12px] flex items-center justify-center w-[50px] py-1 truncate border-r border-[#f7f7f7]'>{(d?.profile_photo !== null && d?.profile_photo !== undefined) ? <img className=" w-5 h-5 rounded object-contain" src={`${process.env.REACT_APP_AWS_IMAGE_URL}${d?.profile_photo}`} alt="Default avatar"></img> : <span className='bg-gray-100 w-5 h-5 flex items-center justify-center text-[10px] rounded uppercase font-[700]'>{d?.name?.slice(0,2)}</span>}</h6>
 <h6 onClick={()=>navigate('edit',{state:d?._id})} className='text-[12px] w-[90px] font-[700] px-2 py-1 truncate border-r border-[#f7f7f7]'>{d?.employee_id}</h6>
 <h6 onClick={()=>navigate('edit',{state:d?._id})} className='text-[12px] w-[150px] font-[700] px-2 py-1 truncate border-r border-[#f7f7f7]'>{d?.name}</h6>
 <h6 onClick={()=>navigate('edit',{state:d?._id})} className='text-[12px] w-[100px] px-2 py-1 truncate border-r border-[#f7f7f7]'>{d?.phone}</h6>
 <h6 onClick={()=>navigate('edit',{state:d?._id})} className='text-[12px] w-[180px] font-[700] px-2 py-1 truncate border-r border-[#f7f7f7]'>{d?.email}</h6>
 <h6 onClick={()=>navigate('edit',{state:d?._id})} className='text-[12px] w-[140px] px-2 py-1 truncate border-r border-[#f7f7f7]'>{d?.department_id[0]?.department_name !== undefined && <span className='text-[9px] font-semibold inline-block py-1 px-2 uppercase rounded text-slate-600 bg-slate-100 uppercase last:mr-0 mr-1'>{d?.department_id[0]?.department_name}</span>}</h6>
 <h6 className='text-[13px] w-[140px] px-2 py-1 font-[500] text-slate-600 border-r capitalize truncate'>{d?.user_roles?.map((r)=>(<span key={r?.roles?._id} className="text-[9px] font-semibold inline-block py-1 px-2 uppercase rounded text-slate-600 bg-slate-100 uppercase last:mr-0 mr-1">{r?.roles?.display_name}</span>))}</h6>
 <h6 onClick={()=>navigate('edit',{state:d?._id})} className='text-[12px] w-[90px] font-[700] px-2 py-1 truncate border-r border-[#f7f7f7]'>{moment(d?.doj).format('ll')}</h6>
 <h6 onClick={()=>navigate('edit',{state:d?._id})} className='text-[12px] w-[90px] px-2 py-1 truncate border-r border-[#f7f7f7]'>{moment(d?.createdAt).format('ll')}</h6>
 <h6 className={`text-[12px] ${(step === 3 || step === 4) ? 'w-[60px]' : 'w-[60px]' } flex items-center justify-center `}>
 
 <AiOutlineEdit size={12} className={`z-10 ${!(step === 3 || step === 4) ? '-ml-0' : 'ml-0'} cursor-pointer`} onClick={()=>navigate('edit',{state:d?._id})}/>
 <MdPassword className='ml-2 cursor-pointer' size={12} onClick={()=>{setselectedData(d);setmodal(true)}} />
 {(step === 3 || step === 4) && <AiOutlineDelete size={12} className="ml-2 z-10 cursor-pointer" onClick={()=>{setselectedData(d);setdelete_modal(true)}}/>}
 </h6>
 </div>))}
 </div>}
 </div>

 



 </div>
 </div> 
 </div>
 )
}

export default OnboardList