import React, { useEffect, useState } from 'react'
import DashboardMenu from './DashboardMenu'
import { Select } from 'antd'
import { IconButton } from "@mui/material";
import {BsArrowRepeat} from 'react-icons/bs';
import {FiChevronRight,FiChevronLeft} from 'react-icons/fi';
import { BiFilterAlt } from "react-icons/bi";
import { RxReset } from "react-icons/rx";
import { instituteArr } from '../../constants/dataConstants';
import { GetInstituteEmployeeProgressService, GetInstituteEmployeeService, GetInstituteProgressService, LoginInstituteService } from '../../services/InductionServices';


function InductionList() {

  const [selected_department,setselected_department] = useState(null)
  const [selected_user,setselected_user] = useState(null)

  const [token,settoken] = useState('')
  const [page,setpage] = useState(1)
  const [datas,setdatas] = useState([])
  const [users,setusers] = useState([])


 
  async function getToken(e) {
     const response = await LoginInstituteService(e)
     settoken(response?.data?.token)
     getUser(response?.data?.token)
     getInstituteprogress(response?.data?.token)
  }

  async function getUser(token) {
    const header = `Bearer ${token}`;
    let headers = { "headers": {Authorization: header }}

    const response = await GetInstituteEmployeeService(headers)
    let arr = []
    response?.data?.forEach((d)=>{
      arr.push({label:d?.employeeName,value:d?.id})
    })
    setusers(arr)
  }

  async function getInstituteprogress(token){
    const header = `Bearer ${token}`;
    let headers = { "headers": {Authorization: header }}
    const response = await GetInstituteProgressService(page,headers)
    setdatas(response?.data)
  }

  async function handlechange(e,name) {
    if(name === 'department'){
      getToken(e)
      setselected_department(instituteArr?.find((f)=>f?.value == e))
    }
    if(name === 'user'){
      getUserIndivisualprogress(e)
      setselected_user(users?.find((f)=>f?.value == e))
    }
  }


  async function getUserIndivisualprogress(id) {
    const header = `Bearer ${token}`;
    let headers = { "headers": {Authorization: header }}
    const response = await GetInstituteEmployeeProgressService(id,headers)

    let data_received = {
      ...response?.data,
    } 
    setdatas([data_received])
    
  }
  

  async function applyfilterfunction() {
    
  }

  async function resetfilter() {
    setselected_department(null)
    setselected_user(null)
    
  }   

  return (
    <div className='flex max-h-screen min-h-screen  '>
     


      <DashboardMenu />
      <div className='mx-5 mt-5 w-[85%] mb-10'>

        <div>
            <div className='flex items-center justify-between border-b pb-2 mb-5'>
              <h6 className='font-[700] text-[14px] '>Induction Report</h6>

              <div className='flex items-center  justify-end' >
                    <div className='flex items-center  text-[12px] mr-2'>
                    <IconButton  onClick={resetfilter} ><BsArrowRepeat size={16} /></IconButton>
                    <div>
                    <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>
                    <IconButton onClick={()=>{ page < datas?.pagination?.totalPages  ? setpage(page+1) : console.log('')}} ><FiChevronRight className={`${(datas?.pagination?.totalPages === page || datas?.datas?.length === 0) ? 'opacity-50' : 'opacity-100'}`} size={16} /></IconButton>
                    </div>
                </div>

                <div className='border border-slate-300 rounded-md py-[2px] mr-1'>
                    <Select
                        placeholder='Assigned Department'
                        bordered={false}
                        allowClear={true}
                        onClear={()=>setselected_department(null)}
                        size='small'
                        defaultValue={selected_department}
                        value={selected_department}
                        style={{ width: 110 }}
                        onChange={(v)=>handlechange(v,'department')}
                        options={instituteArr}
                    />
                </div>

                <div className='border border-slate-300 rounded-md py-[2px]'>
                    <Select
                        placeholder='Select User'
                        bordered={false}
                        size='small'
                        allowClear={true}
                        onClear={()=>setselected_user(null)}
                        defaultValue={selected_user}
                        value={selected_user}
                        style={{ width: 110 }}
                        options={users}
                        onChange={(v)=>handlechange(v,'user')}
                    />
                </div>

                <div className='ml-1 mr-1'>
                <BiFilterAlt  onClick={()=>applyfilterfunction(1)} size={25} className='bg-slate-600 p-[5px] text-white cursor-pointer rounded ml-1' />
                </div> 

                <RxReset  onClick={resetfilter} size={25} className='bg-slate-200 p-[5px] text-salte-800 cursor-pointer rounded ' />

              </div>
            </div>
        </div>  

       
        {(datas?.length > 0 && selected_department !== null) &&
        <div className='border-t border-l border-r'>
            <div className='sticky top-0 z-50 flex  py-[-5px] border-b bg-white border-slate-200 relative'>
              <h6 className='sticky top-0 z-50 text-[12px] w-[5%]  px-2 py-1 font-[600] text-slate-600 border-r '>Sl No</h6>
              <h6 className='sticky top-0 z-50  text-[12px] w-[15%] px-2 py-1 font-[600] text-slate-600 border-r'>Employee Name</h6>
              <h6 className='sticky top-0 z-50  text-[12px] w-[80%]  px-2 py-1 bg-gray-50 font-[600] text-slate-600'>Progress Info</h6>
            </div>
            
            {datas?.map((d,i)=>(
            <div className='sticky top-0 z-50 flex  py-[-5px] border-b bg-white border-slate-200 relative'>
              <h6 className='sticky top-0 z-50 text-[11px] w-[5%]  px-2 py-1  border-r '>{i+1}</h6>
              <h6 className='sticky top-0 z-50  text-[11px] w-[15%] px-2 py-1  border-r'>{d?.employeeName}</h6>
              <h6 className='sticky top-0 z-50  text-[11px] w-[80%]  px-2 py-1 bg-gray-50 '>{d?.courseProgress?.map((c)=>`Course Name : ${c?.courseName} / Course Points : ${c?.courseCreditPoints} / Course Status : ${c?.courseStatus} \n` )}</h6>
            </div>))}
        </div>}
         
      
         
      </div>

    </div>
  )
}

export default InductionList