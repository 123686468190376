import React, { useState,useEffect } from 'react'
import { CreateInsentivePaymentsShare, GetInsentivePaymentsDetail } from '../../services/InvoiceServices'
import { useLocation } from 'react-router-dom'
import GoBack from '../../components/back/GoBack'
import moment from 'moment'
import {AiFillPlusSquare} from 'react-icons/ai'
import { ToWords } from 'to-words';
import { Drawer } from '@mui/material'
import {IoMdClose} from 'react-icons/io'
import { TextAreaInput1, TextInput } from '../../components/input'
import {Select,Modal} from 'antd'
import { GetOperatorsService, UpdateLeadInvoiceRaisedOutstandingService } from '../../services/LeadServices'
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../components/button'
import {AiOutlineDelete,AiOutlineEdit} from 'react-icons/ai'
import {BiErrorCircle} from 'react-icons/bi'
import { toast } from 'react-hot-toast'
import DashboardMenu from '../dashboard/DashboardMenu'
import {BiCheckbox,BiCheckboxSquare} from 'react-icons/bi'
import { useSelector } from 'react-redux'

function PaymentDetail() {

  const toWords = new ToWords({
        localeCode: 'en-IN',
        converterOptions: {
          currency: true,
          ignoreDecimal: false,
          ignoreZeroCurrency: false,
          doNotAddOnly: false,
          currencyOptions: { // can be used to override defaults for the selected locale
            name: 'Rupee',
            plural: 'Rupees',
            symbol: '₹',
            fractionalUnit: {
              name: 'Paisa',
              plural: 'Paise',
              symbol: '',
            },
          }
        }
  });
  
  const user = useSelector(state=>state.Auth)
  const [amountShared,setamountShared] = useState([])

  const {pathname} = useLocation()

  let path = pathname.split('/')[2]


  const [data,setdata] = useState({})
  const {state} = useLocation() 


  const [share,setshare] = useState({amount:'',percentage:'',user:'',client:'',payment:'',invoice:'',recieved_date:'',out_of_organization:false,lead_share:'',lead_share_more_info:'',index:'',payment:true})
  const [error,seterror] = useState({amount:'',percentage:'',user:'',client:'',payment:'',invoice:'',recieved_date:''})
  const [users,setusers] = useState([])
  const [modal,setmodal] = useState(false)
  const [invoce_data_modify,setinvoce_data_modify] = useState({full_payment_recieved:false,hide_in_outstanding_report:false,outstanding_report_remarks:''})
  const [invoce_data_modify_err,setinvoce_data_modify_err] = useState({full_payment_recieved:false,hide_in_outstanding_report:false,outstanding_report_remarks:''})
  const [modal1,setmodal1] = useState(false)

  useEffect(()=>{
    getdata()
  },[])

  let rupee = new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
  });


  
  async function getdata(){
    const response = await GetInsentivePaymentsDetail(state)
    let shares = response?.data?.data[0]?.shares 
    let arr = []
    if(shares.length > 0 ){
        shares?.forEach((s,i)=>(
            arr.push({amount:s?.amount,percentage:s?.percentage,user:{label:s?.user[0]?.name,value:s?.user[0]?._id},client:s?.client,payment:s?.payment,invoice:s?.invoice,recieved_date:s?.recieved_date,lead_share:s?.lead_share,lead_share_more_info:s?.lead_share_more_info,out_of_organization:s?.out_of_organization,index:i})
        ))
    }
    setamountShared(arr)

    if(response?.data?.data?.length > 0 ){
        let d = response?.data?.data[0]
        // console.log("d here",d)
        let sendData = {
          full_payment_recieved:(d?.invoice?.full_payment_recieved !== null && d?.invoice?.full_payment_recieved !== undefined) ? d?.invoice?.full_payment_recieved : false,
          hide_in_outstanding_report:(d?.invoice?.hide_in_outstanding_report !== null && d?.invoice?.hide_in_outstanding_report !== undefined) ? d?.invoice?.hide_in_outstanding_report : false,
          outstanding_report_remarks:(d?.invoice?.outstanding_report_remarks !== null && d?.invoice?.outstanding_report_remarks !== undefined) ? d?.invoice?.outstanding_report_remarks : '',
        }

        // console.log("sendData",sendData)
        setinvoce_data_modify({...sendData})
    }

    setdata(response?.data?.data[0])
  } 
  
  async function  onSearch(value){
   const response = await GetOperatorsService(value)
   let arr = []
   response?.data?.forEach((d)=>{
    arr.push({label:d?.name,value:d?._id})
   })
   setusers(arr)
   return arr
  }



  async function  onChange(v){
    setshare({...share,user:v})
    seterror({...error,user:''})
  }   

  // console.log("share.percentage",share.payment)
  async function handleChange(e){
    if(e.target.name === 'percentage'){
        if(share.payment){
          let amount = e.target.value 
          let percentage =  Math.round(amount / data?.amount_without_gst) * 100
          setshare({...share,[e.target.name]:percentage,amount})
          seterror({...error,[e.target.name]:''})
        }else{
        let share_percentage_remainig =  amountShared?.length > 0 ?  100 - amountShared?.reduce((r,a)=>{return  Number(r) + Number(a?.percentage)},0) : 100
        if(e.target.value <= share_percentage_remainig){
          let amount = (data?.amount_without_gst / 100 ) * e.target.value
          setshare({...share,[e.target.name]:e.target.value,amount})
          seterror({...error,[e.target.name]:''})
        }else{
          seterror({...error,[e.target.name]:'The share Percentage Exceeded'})
        }
        }
    }else{
        if(e.target.name === 'amount'){
          // if(amount )
          let amount = e.target.value 

          let total_amount =  0

          amountShared?.forEach((a)=>{
            total_amount += a?.amount 
          })
          // console.log("amountShared?.reduce((m,x=0)=>m.amount + x) ",amountShared?.length > 0  && amountShared?.reduce((m,x=0)=>m.amount + x) )
          // console.log("total_amount",total_amount)
          // console.log("amount + total_amount",parseInt(amount) + parseInt(total_amount))
          

          if(data?.amount_without_gst < (parseInt(amount) + parseInt(total_amount))){
            seterror({...error,[e.target.name]:'The Amount Exceeded Based on Amount With Gst *'})
          }else{
            let percentage =  Math.round((amount / data?.amount_without_gst) * 100) 
            setshare({...share,[e.target.name]:amount,percentage:JSON.stringify(percentage)})
            seterror({...error,[e.target.name]:''})
          }
        }else{
          setshare({...share,[e.target.name]:e.target.value})
          seterror({...error,[e.target.name]:''})
        }
    }
  } 

  const setamountSharedFunc=()=>{
    if(share.out_of_organization ==='0' && !share.user){
        seterror({...error,user:'The user field is required'})
    }else if(share.out_of_organization ==='1' && !share.lead_share){
      seterror({...error,lead_share:'The Person Name field is required'})
    }else if(share.out_of_organization ==='1' && !share.lead_share_more_info){
      seterror({...error,lead_share_more_info:'The Person Details field is required'})
    }else if(!share.percentage){
        seterror({...error,percentage:'The percentage field is required'})
    }else{
        let exist =  amountShared?.find((f)=>f?.user?.value === share?.user)
        if(share?.edit === true && !share?.out_of_organization){
        let exist =  amountShared?.findIndex((f)=>f?.user?.value === share?.user?.value)
        let old_Data = [...amountShared]
        let additional_data = {...share}
        additional_data['recieved_date'] = data?.recieved_date
        additional_data['invoice'] = data?.invoice?._id
        additional_data['payment'] = data?._id
        additional_data['client'] = data?.client?._id
        old_Data[exist] = additional_data
        setamountShared(old_Data)
        setshare({amount:'',percentage:'',user:'',client:'',payment:'',invoice:'',recieved_date:'',out_of_organization:false,lead_share:'',lead_share_more_info:''})
        seterror({amount:'',percentage:'',user:'',client:'',payment:'',invoice:'',recieved_date:'',out_of_organization:false,lead_share:'',lead_share_more_info:''})
        setmodal(false)
        

        }else if(share?.edit === true && share?.out_of_organization){
        let exist =  amountShared?.findIndex((f)=>f?.index === share?.index)
        let old_Data = [...amountShared]
        let additional_data = {...share}
        additional_data['recieved_date'] = data?.recieved_date
        additional_data['invoice'] = data?.invoice?._id
        additional_data['payment'] = data?._id
        additional_data['client'] = data?.client?._id
        old_Data[exist] = additional_data
        setamountShared(old_Data)
        setshare({amount:'',percentage:'',user:'',client:'',payment:'',invoice:'',recieved_date:'',out_of_organization:false,lead_share:'',lead_share_more_info:''})
        seterror({amount:'',percentage:'',user:'',client:'',payment:'',invoice:'',recieved_date:'',out_of_organization:false,lead_share:'',lead_share_more_info:''})
        setmodal(false)
        }else{ 
            if(exist){
                seterror({...error,user:'The share for the user already exist'})
            }else{
                let additional_data = {...share}
                if(amountShared?.length === 0){
                  additional_data['index'] = 0
                }else{
                  additional_data['index'] = amountShared[amountShared?.length - 1]?.index + 1
                }
                additional_data['recieved_date'] = data?.recieved_date
                additional_data['invoice'] = data?.invoice?._id
                additional_data['payment'] = data?._id
                additional_data['client'] = data?.client?._id
                additional_data['user'] = users?.find((u)=>u?.value === share?.user)
                setamountShared([...amountShared,additional_data])
                setshare({amount:'',percentage:'',user:'',client:'',payment:'',invoice:'',recieved_date:'',out_of_organization:false,lead_share:'',lead_share_more_info:''})
                seterror({amount:'',percentage:'',user:'',client:'',payment:'',invoice:'',recieved_date:'',out_of_organization:false,lead_share:'',lead_share_more_info:''})
            }
        }
    }
  }

  async function createEmployeeShare(){
    let payment_share = []
    amountShared.forEach((e)=>{
        let datas = {...e,amount:Math.ceil(e?.amount)}
        if(e?.user !== ''){
          datas['user'] = e?.user?.value
        }
        datas['incentive_requirment'] = data?.incentive_requirment
        payment_share.push(datas)
    })
    const response = await CreateInsentivePaymentsShare(data?._id,{payment:data?.id,payment_share:payment_share})
    if(response.status === 200){
        toast.success(response?.data?.data)
        getdata()
    }
  }

  async function removeEmployeeShare(){
    let payment_share = []
    const response = await CreateInsentivePaymentsShare(data?._id,{payment:data?.id,payment_share:payment_share})
    if(response.status === 200){
        toast.success(response?.data?.data)
        getdata()
    }
  }

  async function deleteItem(i){
    let old_Arr = [...amountShared]
    old_Arr.filter(a => a.index !== i)
    setamountShared(old_Arr.filter(a => a.index !== i))
    toast.success('User share removed')
  }


  // console.log("invoce_data_modify",invoce_data_modify)

  async function submitinvoiceData(){
    if(invoce_data_modify.full_payment_recieved === false && invoce_data_modify.outstanding_report_remarks === ''){
      setinvoce_data_modify_err({...invoce_data_modify_err,outstanding_report_remarks:'This Field is required'})
    }else{
      let sendData = {...invoce_data_modify}
      sendData['outstanding_report_modify'] = user.id
      const response = await UpdateLeadInvoiceRaisedOutstandingService(data?.invoice?._id,sendData)
      if(response?.status === 200){
        getdata()
        toast.success("Invoice Outstanding Status Updated")
        setmodal1(false)
        setinvoce_data_modify({full_payment_recieved:false,hide_in_outstanding_report:false,outstanding_report_remarks:''})
        setinvoce_data_modify_err({full_payment_recieved:false,hide_in_outstanding_report:false,outstanding_report_remarks:''})
      }
    }

  }



  console.log("data",data)

  return (
    <div>
       
        <div className='flex'>
          <DashboardMenu />
        <div className='w-[86%]'>
        <Drawer open={modal} anchor={'right'} style={{minWidth:'350px',zIndex:10}} >
              <div className='max-w-[300px] p-3'>
              <div className='flex justify-end mb-2 cursor-pointer' onClick={()=>setmodal(!modal)}>
              <IoMdClose  />  
              </div>  
              <h6 className='text-[11px] bg-slate-100 p-2'>Use the below form to create or update the share of the lead amount recieved</h6>
              
              <div className='flex items-center mt-2'>
              <div className='flex items-center mr-2' onClick={()=>setshare({...share,out_of_organization:!share.out_of_organization})} >
              {share.out_of_organization ? <BiCheckbox size={20} className='text-slate-300' /> : <BiCheckboxSquare size={20} className='text-slate-700'  /> }  
              <span  className ="block text-[12px] font-[600] text-gray-900  ml-1">{'Our Employee'}</span>
              </div>

              <div className='flex items-center' onClick={()=>setshare({...share,out_of_organization:!share.out_of_organization})} >
              {!share.out_of_organization  ? <BiCheckbox size={20} className='text-slate-300' /> : <BiCheckboxSquare size={20} className='text-slate-700' /> }  
              <span  className ="block text-[12px] font-[600] text-gray-900  ml-1">{'Not Employee'}</span>
              </div>
              </div>
               
               <div className='mx-1'>
              
              {!share.out_of_organization && <>
              <label  className ="block mb-1 text-[12px] font-[600] text-gray-900 mt-2 ">{'Employee*'}</label>
              <Select
                    showSearch
                    bordered={false}
                    className='w-full border rounded-[0px] outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 border-l-4 border-slate-300 border-l-slate-700 focus:shadow-none z-100' 
                    onChange={onChange}
                    onSearch={onSearch}
                    value={share?.user === '' ? null : share?.user}
                    filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={users}
                />
                </>}
              
               {error?.user &&
                <div className='flex items-center mt-1'>
                    <BiErrorCircle className='text-red-500' size={14} />
                    <span className='text-[10px] text-red-500 ml-1'>{error?.user}</span>
                </div>}


                {share.out_of_organization
                 && <>
                <TextInput
                    mandatory={true}
                    label={'Person Name*'}  
                    variant="standard"
                    name="lead_share"
                    type="text"
                    value={share.lead_share}
                    error={error.lead_share}
                    handlechange={handleChange}/>

                <TextAreaInput1
                    mandatory={true}
                    label={'Person Details*'}  
                    variant="standard"
                    name="lead_share_more_info"
                    type="text"
                    value={share.lead_share_more_info}
                    error={error.lead_share_more_info}
                    handlechange={handleChange}/>    
                </>}

                
                {/* <div className='flex items-center mt-2'>
                  <div className='flex items-center mr-2' onClick={()=>setshare({...share,payment:!share.payment})} >
                    {!share?.payment ? <BiCheckboxSquare size={20} className='text-slate-700' /> : <BiCheckbox size={20} className='text-slate-700'  /> }  
                    <span  className ="block text-[12px] font-[600] text-gray-900  ml-1">{'Percentage'}</span>
                  </div>

                  <div className='flex items-center' onClick={()=>setshare({...share,payment:!share.payment})} >
                    {share?.payment  ? <BiCheckboxSquare size={20} className='text-slate-700' /> : <BiCheckbox size={20} className='text-slate-700' /> }  
                    <span className ="block text-[12px] font-[600] text-gray-900  ml-1">{'Amount'}</span>
                  </div>
                </div> */}

               

                    <TextInput
                       
                        mandatory={true}
                        label={'Amount*'}  
                        variant="standard"
                        name="amount"
                        type="text"
                        value={share.amount}
                        error={error.amount}
                        handlechange={handleChange} 
                    />


                    <TextInput
                    mandatory={true}
                    label={'Percentage*'}  
                    variant="standard"
                    name="percentage"
                    type="text"
                    value={share.percentage}
                    error={error.percentage}
                    />

{/* handlechange={handleChange} */}

                    </div>
   
                   {(100 - amountShared?.reduce((r,a)=>{return  Number(r) + Number(a?.percentage)},0)) !== 0 &&
                   <div className='mt-4'>
                   <ButtonFilledAutoWidth btnName="Save" onClick={setamountSharedFunc} />
                   </div>}
                   <h6 className='bg-slate-100 text-black p-1 leading-tight mt-5 font-[500] text-[12px]' > Note * The share Percentage Available <span className='font-[900]'> {amountShared?.length > 0 ?  100 - amountShared?.reduce((r,a)=>{return  Number(r) + Number(a?.percentage)},0) : 100}</span> % Percentage to transfer the members of our lead workers  </h6>

              </div>  
        </Drawer>

        <Modal open={modal1} closable={false} footer={false} width={300} className='absolute top-0 left-[40%]'>
                
               <div>
                 
                 <h6 className='font-[800]'>Update the Invoice Status</h6>
                 <h6 className='text-[11px] leading-[14px] p-2 bg-blue-50'>If You would like to modify the outstanding report of the payment status of this selected lead </h6>
                 
                 <div onClick={()=>setinvoce_data_modify({...invoce_data_modify,full_payment_recieved:!invoce_data_modify.full_payment_recieved})} className='flex items-center my-1'>
                    {!invoce_data_modify.full_payment_recieved ? <BiCheckbox size={20} className='text-slate-300' /> : <BiCheckboxSquare size={20} className='text-slate-700'  /> }  
                    <h6 className='text-[11px] ml-1 font-[700]'>Full Payment Recieved</h6>
                 </div>

                 <div onClick={()=>setinvoce_data_modify({...invoce_data_modify,hide_in_outstanding_report:!invoce_data_modify.hide_in_outstanding_report})} className='flex items-center my-1'>
                    {!invoce_data_modify.hide_in_outstanding_report ? <BiCheckbox size={20} className='text-slate-300' /> : <BiCheckboxSquare size={20} className='text-slate-700'  /> }  
                    <h6 className='text-[11px] ml-1 font-[700]'>Hide In Outstanding Report</h6>
                 </div>

                 <TextAreaInput1
                    mandatory={!invoce_data_modify.full_payment_recieved}
                    label={'Remarks'}  
                    variant="standard"
                    name="outstanding_report_remarks"
                    type="text"
                    value={invoce_data_modify.outstanding_report_remarks}
                    error={invoce_data_modify_err.outstanding_report_remarks}
                    handlechange={(e)=>{setinvoce_data_modify({...invoce_data_modify,outstanding_report_remarks:e.target.value});setinvoce_data_modify_err({...invoce_data_modify_err,outstanding_report_remarks:''})}}/>  
                 
                  <div className='mt-4 flex items-center justify-end border-t pt-2'>
                    <ButtonOutlinedAutoWidth btnName="Cancel" onClick={()=>setmodal1(false)} />
                      <h6 className='w-[5px]'></h6>
                    <ButtonFilledAutoWidth btnName="Save" onClick={submitinvoiceData} />
                  </div>
                </div> 
        </Modal>
        
        <div className='px-4 flex '>
        <div className='pt-4  pr-5 w-full'>
          <GoBack />
            <h6 className='font-black text-[14px] border-b pb-2 w-full'>Invoice Request Detail</h6>
            
            <div className='mt-2 flex'>
                <div className='w-56 pr-4 border-r'>
                    <h6 className='text-[13px] font-[600] mb-2 '>Client Info: </h6>
                    <div className='bg-gray-100 p-2'>
                    <h6 className='text-[12px] font-[500]'>{data?.client?.contact_name} / {data?.client?.phone}</h6>
                    <h6 className='text-[12px] font-[500]'>{data?.client?.email}</h6>
                    <h6 className='text-[12px] font-[500]'>Company Name : {data?.client?.company_name}</h6>
                    <h6 className='text-[12px] font-[500]'>Address : {data?.client?.address}</h6>
                    </div>
                    <h6 className='text-[12px] font-[500]  mt-2'>Requirment : {data?.client?.requirment}</h6>
                    {(data?.sqft || data?.seater || data?.rent || data?.sale) && 
                    <h6 className='text-[12px] font-[500]'>{data?.sqft && 'SQFT'} {data?.seater && 'SEATER'} , {data?.rent && 'RENT'} {data?.sale && 'SALE'} </h6>}

                    <h6 className='text-[13px] font-[600] mt-4 '>Invoice Info: </h6>
                    <h6 className='text-[11px] leading-[14px] p-2 bg-blue-50'>If You would like to modify the outstanding report of the payment status of this particular lead you can <span onClick={()=>setmodal1(!modal1)} className='underline text-blue-600 font-[800] capitalize cursor-pointer'>click here</span></h6>
                    <h6 className='text-[12px] font-[500]'>Invoice No : <span className='font-[800]'> {data?.invoice?.invoice_no}</span></h6>
                    <h6 className='text-[12px] font-[500]'>Inv Amt Incl Gst : <span className='font-[800]'> {rupee.format(data?.invoice?.invoice_amount_with_gst)?.split('.')[0]}</span></h6>
                    <h6 className='text-[12px] font-[500]'>Inv Amt Excl Gst : <span className='font-[800]'> {rupee.format(data?.invoice?.invoice_amount_without_gst)?.split('.')[0]}</span></h6>
                    <h6 className='text-[12px] font-[500]'>Request To Raise : {moment(data?.invoice?.invoice_raising_date).format('LL')}</h6>
                    <h6 className='text-[12px] font-[500]'>Invoice Raised On : {moment(data?.invoice?.invoice_raised_date).format('LL')}</h6>
                    {(data?.other_details !== '' && data?.other_details !== undefined) && <h6 className='text-[12px] font-[500]'>Other Details : {data?.other_details}</h6>}


                    <h6 className='text-[13px] font-[600] mt-4 '>Payment Info: </h6>
                    <h6 className='text-[12px] font-[500]'> Payment Recieved   : <span className='font-[700]'> {rupee.format(data?.amount_without_gst)?.split('.')[0]} </span></h6>
                    <h6 className='text-[12px] font-[500]'>TDS Deduction : <span className='font-[700]'>{data?.tds_deduction !== undefined ? rupee.format(data?.tds_deduction)?.split('.')[0] :  rupee.format(0)?.split('.')[0]}</span> </h6>
                    <h6 className='text-[12px] font-[500] bg-gray-50 p-1 my-1'>Amount in words : <span className='font-[700] text-[11px]'>{data?.amount_without_gst !== undefined && toWords.convert(data?.amount_without_gst)}</span></h6>
                    <h6 className='text-[12px] font-[500]'> Payment Recieved On  : <span className='font-[700]'> {moment(data?.recieved_date)?.format('LL')} </span></h6>

                    
                   
                </div>

                {data?.client?.operators?.length > 0 &&
                <div className='ml-4 w-60 border-r pr-4'>
                <h6 className='text-[13px] font-[600] mb-2 '>Lead Operators ({data?.client?.operators?.length}): </h6>
                {data?.client?.operators?.map((d,i)=>(
                <div className={`border-b relative py-2 ${i === 0 && 'border-t'}`}>
                        <h6 className='text-[10px] ml-1 mt-1'><span className='font-[800]'>{d?.name}</span></h6>
                        <h6 className='text-[10px] ml-1'>Employee Id : <span className='font-[500]'>{d?.employee_id}</span></h6>
                        {/* <h6 className='text-[10px] ml-1'>Designation : <span className='font-[500]'>{(d?.designation_id)}</span></h6> */}
                </div>))} 

                </div>}

                {(path === 'deleted' && data?.is_deleted) && 
                   <div className='ml-4  w-[58%] border-r pr-4'>
                      <h6 className='text-[14px] font-[600] mb-2'>Deleted Reason </h6>
                      <h6 className='text-[13px] font-[400] mb-2'>{data?.deleted_reason}</h6>
                   </div> 
                }
                 
                {path !== 'deleted' && !data?.is_deleted && 
                <div className='ml-4  w-[58%] border-r pr-4'>
                    <div className='flex border-b items-center justify-between'>
                    <h6 className='text-[13px] font-[600] mb-2'>Amount Shared With ({amountShared?.length})</h6>
                    <AiFillPlusSquare size={20} className='text-gray-400 cursor-pointer' onClick={()=>{setmodal(true);setshare({amount:'',percentage:'',user:'',client:'',payment:'',invoice:'',recieved_date:'',out_of_organization:false,lead_share:'',lead_share_more_info:'',index:''})}} />    
                    </div>

                    <div className='border border-gray-100 border-b mt-2'>
                    <div className='bg-gray-50  flex '>
                        <h6 className='text-[12px] font-[600] border-r pl-4 p-1 min-w-2/5 w-2/5 max-w-2/5'>Employee Name</h6>
                        <h6 className='text-[12px] font-[600] border-r pl-4 p-1 min-w-1/5 w-1/5 max-w-1/5'>Percentage</h6>
                        <h6 className='text-[12px] font-[600] border-r pl-4 p-1 min-w-1/5 w-1/5 max-w-1/5'>Amount</h6>
                        <h6 className='text-[12px] font-[600]  pl-4 p-1 min-w-1/5 w-1/5 max-w-1/5'>Actions</h6>
                    </div>
                    {amountShared?.map((e,i)=>(
                    <div key={i} className={`flex border-t `}>
                        <h6 className='text-[12px] font-[500] border-r pl-4 p-1 min-w-2/5 w-2/5 max-w-2/5'>{e?.out_of_organization === false ? e?.user?.label : `${e?.lead_share} [External Support]`}</h6>
                        <h6 className='text-[12px] font-[700] border-r pl-4 p-1 min-w-1/5 w-1/5 max-w-1/5'>{e?.percentage}</h6>
                        <h6 className='text-[12px] font-[700] border-r pl-4 p-1 min-w-1/5 w-1/5 max-w-1/5'>{rupee?.format(Math.ceil(e?.amount))?.split('.')[0]}</h6>
                        <h6 className='text-[12px] font-[500] pl-4 p-1 min-w-1/5 w-1/5 max-w-1/5 flex items-center cursor-pointer'><AiOutlineDelete className='mr-3' onClick={()=>deleteItem(e?.index)} size={14} /> <AiOutlineEdit className='mr-2' onClick={()=>{setshare({...e,edit:true,out_of_organization:e?.out_of_organization});setmodal(true)}} size={14} /></h6>
                    </div>
                    ))}
                    
                  
                    </div>

                    {amountShared?.length > 0 &&
                    <div className='flex items-center'>
                    <div className='mt-5'>
                    <ButtonFilledAutoWidth btnName="Send Employee Share" onClick={createEmployeeShare} />
                    </div>

                    <div className='mt-5 ml-2'>
                    <ButtonOutlinedAutoWidth btnName="Remove Employee Share" onClick={removeEmployeeShare} />
                    </div>


                    </div>




                    
                    }



                </div>}

               
 
              

               
            </div>


        </div>
          
        </div>    
        </div>
        </div>
    </div>
  )
}

export default PaymentDetail