import React,{useState,useEffect} from 'react'
import { Tooltip } from '@mui/material';
import {  ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../../../components/button';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import {AiOutlineEdit,AiOutlineDelete,AiOutlineFileExcel} from 'react-icons/ai'
import { Modal } from 'antd';
import moment from 'moment';
import WebsiteMenu from '../../WebsiteMenu';
import {
  IconButton,
} from "@mui/material";
import {BsArrowRepeat} from 'react-icons/bs';
import {FiChevronRight,FiChevronLeft} from 'react-icons/fi';
import { DeleteProjectWebsiteJobApplyService, DownloadProjectWebsiteJobApplyService, GetProjectWebsiteJobApplyService } from '../../../../services/Website/ProjectWebsiteOption/ProjectJobApplicantServices';
import fileDownload from "js-file-download";
import axios from 'axios';

function ProjectJobApplyList() {


    const [data,setdata] = useState([])
    const [selecteddata,setselecteddata] = useState({})
    const [modal, setModal] = useState(false);
    const [page, setpage] = useState(1);
    const navigate = useNavigate()


  
    useEffect(()=>{
        getdata()
    },[page])

   

  
  async function getdata(){
   const response = await GetProjectWebsiteJobApplyService(page,'',)
   setdata(response.data)
  }  

  async function deleteRole(){
    const response = await DeleteProjectWebsiteJobApplyService(selecteddata?._id)
    if(response.status === 200){
        setModal(false)
        toast.success('Job Apply Deleted Successfully')
        getdata()
    }else{
        setModal(false)
        toast.success('Job Apply Deleted Successfully')
    }
  }

  async function resetfilter(){
    setpage(1)
  }

  async function downloaddata(){
    const response = await DownloadProjectWebsiteJobApplyService(page,'','')
    let path = response.data.path.replace('public/','')
    handleDownload(`${process.env.REACT_APP_BACKEND_IMAGE_URL}${path}`,path.split('/')[path.split('/')?.length - 1])
  }

  const handleDownload = (url, filename) => {
    axios.get(url, {
        responseType: 'blob',
    })
        .then((res) => {
            fileDownload(res.data, filename)
        })
  }




  return (
    <div className='h-screen max-h-screen box-border overflow-hidden'>
   
    <Modal
       keepMounted
       open={modal}
       onClose={()=>setModal(false)}
       width={300}
       footer={false}
       closable={false}
      
     >
       <div >
         <h6 className="font-bold text-[15px] text-center mb-2 w-full">Are you sure?</h6>
         <h6 className='bg-slate-100 text-center text-[12px] p-1.5 font-[400]'>After deleting you cannot retrieve it back before deleting check once whether you have used it in some data</h6>
         <div className='flex justify-end mt-3 '>
           {/* <div className='mr-1 w-full'> */}
           <ButtonOutlinedAutoWidth btnName={'Cancel'} onClick={()=>setModal(false)} />
           {/* </div> */}
           <div  className='ml-2'>
           <ButtonFilledAutoWidth btnName={'Confirm'} onClick={()=>deleteRole()}  /> 
           </div>
         </div>
       </div>
     </Modal>
       
     <div className='block sm:flex'>
       <WebsiteMenu />

       <div className='w-[88%] px-4'>
       <div >
       <div className='pt-5'>
       <div className="flex justify-between align-center items-center border-b pb-2 ">
       <span className="font-black text-[14px]">Project Job Applicant ({data?.pagination?.total})</span>

       <div className='flex items-center'>
        <AiOutlineFileExcel onClick={()=>downloaddata()} size={24}  className='mx-2 bg-gray-200 p-1.5' />
        <div className='flex items-center  text-[12px] mr-2'>
            <h6 className='mr-2 font-[600]'>{page === 1 ? data?.datas?.length > 0 ? 1 : 0 : (page - 1) * data?.pagination?.limit } - {data?.pagination?.limit} of {data?.pagination?.total} </h6>
            <IconButton  onClick={resetfilter} ><BsArrowRepeat size={16} /></IconButton>
            <div>
            <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>
            <IconButton onClick={()=>{ page < data?.pagination?.totalPages  ? setpage(page+1) : console.log('')}} ><FiChevronRight className={`${(data?.pagination?.totalPages === page || data?.datas?.length === 0) ? 'opacity-50' : 'opacity-100'}`} size={16} /></IconButton>
            </div>
        </div>
          <ButtonFilledAutoWidth  btnName="Add Job Apply" onClick={()=>navigate('create')} /> 
          </div>
       </div>
       </div>

       {data?.datas?.length === 0 &&
        <div className='grid place-items-center mt-20  items-center justify-center'>
        <img src={'https://fidecrmfiles.s3.amazonaws.com/NoDataFound1.png'} className='w-40 h-40 object-contain' /> 
        <h6 className='font-bold text-[14px] -mt-10'>No data found</h6>
        <h6 className='font-[500] w-[70%] text-center text-slate-700 text-[12.5px] -mt-2'>Oops we couldn't find any data added based on your current page option please click on add the data button to see list here.</h6>
      </div>
       }


        <div className='h-[88vh] w-[100%] overflow-x-hidden overflow-y-scroll'>
          {data?.datas?.length > 0 &&
            <div className='max-h-[85vh] w-[100%] mt-4 border-t  overflow-x-hidden  border-l border-r overflow-y-scroll'>
                <div className='sticky top-0 z-50 flex  bg-white py-[-5px] border-b border-slate-200 relative'>
                    <h6  className='top-0 bg-white z-50 text-[12px] min-w-[5%]  max-w-[5%]  px-2 py-1 font-[600] text-slate-600 border-r flex items-center justify-center'>Sl No</h6>
                    <h6 className='sticky top-0 z-50  text-[12px]  min-w-[10%] max-w-[10%]  px-2 py-1 font-[600] text-slate-600 border-r'>Name</h6>
                    <h6 className='sticky top-0 z-50  text-[12px]  min-w-[10%] max-w-[10%]  px-2 py-1 font-[600] text-slate-600 border-r'>Mobile</h6>
                    <h6 className='sticky top-0 z-50  text-[12px] min-w-[15%] max-w-[15%]  px-2 py-1 font-[600] text-slate-600 border-r'>Email</h6>
                    <h6 className='sticky top-0 z-50  text-[12px] min-w-[10%] max-w-[15%]  px-2 py-1 font-[600] text-slate-600 border-r'>Experience</h6>
                    <h6 className='sticky top-0 z-50  text-[12px] min-w-[10%] max-w-[10%]  px-2 py-1 font-[600] text-slate-600 border-r'>Company</h6>
                    <h6 className='sticky top-0 z-50  text-[12px] min-w-[15%] max-w-[10%]  px-2 py-1 font-[600] text-slate-600 border-r'>Notice Period</h6>
                    <h6 className='sticky top-0 z-50  text-[12px] min-w-[15%] max-w-[15%]  px-2 py-1 font-[600] text-slate-600 border-r'>Job Applied For</h6>
                    <h6 className='sticky top-0 z-50  text-[12px] min-w-[10%] max-w-[10%]  px-2 py-1 font-[600] text-slate-600 '>Actions</h6>
                </div>
                {data?.datas?.map((d,i)=>(
                <div className='flex  bg-white py-[-5px] border-b border-slate-200 relative'>
                    <h6  className='bg-white text-[12px] min-w-[5%]  max-w-[5%]   px-2 py-1 font-[500] border-r flex items-center justify-center'>{(page > 1 ? i+1+ (data?.pagination?.limit * (page - 1)) : i+1 )}</h6>
                    <h6 className=' text-[12px] truncate min-w-[10%] max-w-[10%] px-2 py-1 font-[500] border-r'>{d?.name}</h6>
                    <h6 className=' text-[12px] truncate min-w-[10%] max-w-[10%] px-2 py-1 font-[500] border-r'>{d?.mobile}</h6>
                    <h6 className=' text-[12px] truncate min-w-[15%] max-w-[15%]  px-2 py-1 font-[500] border-r'>{d?.email}</h6>
                    <h6 className=' text-[12px] truncate  min-w-[10%] max-w-[15%]  px-2 py-1 font-[500] border-r'>{d?.experience} / <span onClick={()=>handleDownload(`${process.env.REACT_APP_AWS_IMAGE_URL}${d?.resume}`,d?.resume?.split('/')[d?.resume?.split('/')?.length - 1])}  className='font-[700] text-[10px] cursor-pointer underline'> Resume</span></h6>
                    <h6 className=' text-[12px] truncate  min-w-[10%] max-w-[10%] px-2 py-1 font-[500] border-r'>{d?.current_company}</h6>
                    <h6 className=' text-[12px] truncate min-w-[15%] max-w-[10%]  px-2 py-1 font-[500] border-r'>{d?.notice_period}</h6>
                    <h6 className=' text-[12px] truncate  min-w-[15%] max-w-[15%]  px-2 py-1 font-[500] border-r'>{d?.job_opening?.designation}</h6>
                    <h6 className='flex items-center text-[12px]  min-w-[10%] max-w-[10%]  px-2 py-1 font-[500] '>
                      <AiOutlineEdit onClick={()=>navigate('edit',{state:d})} className='mr-2' />
                      <AiOutlineDelete onClick={()=>{setselecteddata(d);setModal(true)}} />
                    </h6>
                </div>))}
            </div>}
        </div>


       
         </div>
       </div>
     </div> 
   </div>
  )
}

export default ProjectJobApplyList