import { Tooltip } from '@mui/material'
import React from 'react'
import {FiUpload} from 'react-icons/fi'
import {IoClose} from 'react-icons/io5'
// import { theme_line, theme_line_dark } from '../constants/ThemeConstants'
// import { image_base_url } from '../constants/UrlConstants'

function Uploader2({image,setimagefunc,name,removeimageuploadfunc}) {

  // const [data,setdata] = useState('')

 

 
  function setimage(v,name){
    // console.log("v here",v ,"name here",name)
    setimagefunc(v,name)
  }


 if(image === '' || image == null){
  return (
    <form onClick={()=>document.querySelector(`.input-field1`).click()} className={`p-4 flex flex-col items-center justify-center cursor-pointer border border-slate-300 `}>
        <input type='file' name={name} onChange={({target:{files}})=>{
          files[0] && setimage(files[0],name)
        }} accept="*" className='input-field1' hidden />
    </form> 
  )}
  else{
    return (
      <div className='p-2 border border-slate-300 relative flex flex-col  cursor-pointer'>
          <Tooltip title='Delete'><IoClose className='absolute top-3 right-2' onClick={removeimageuploadfunc}/></Tooltip>
          <h6 className='text-[12px] truncate w-48 ml-0'>{image?.name !== undefined ? image?.name : image}</h6>
      </div>
      
    )
  }
}

export default Uploader2