import { get} from "../../helpers/apihelpers";

export const GetFcaresDashboardService=async()=>{
    try {
        const result = await get(`api/fcares/dashboard`);
        return result;
    } catch (err) {
        return err.response;
    }
}
