import React from 'react'
import TeamTaskMenu from './TeamTaskMenu'
import { useLocation } from 'react-router-dom'
import TaskProjectDetails from './TaskProjectDetails'

function TeamTaskRoot() {
  const {pathname} = useLocation()

  return (
    <div className='flex min-h-screen max-h-screen h-screen '>
        <TeamTaskMenu />
        <div className='w-[90%] min-h-screen max-h-screen h-screen overflow-y-screen overflow-x-hidden' >
        {pathname === '/tasks/projects/list/detail' &&  
         <TaskProjectDetails />
        }
        </div>   
    </div>
  )
}

export default TeamTaskRoot