import React, { useEffect, useState } from 'react'
import DashboardMenu from '../../dashboard/DashboardMenu'
import { GetTeamMembersDetail } from '../../../services/AdminServicesfile/TeamServices'
import { useSelector } from 'react-redux'
import { GetInsentiveActive } from '../../../services/AdminServicesfile/InsentiveServices'
import { Select } from 'antd'
import { GetInvoiceRaisedBasedOnIncentive } from '../../../services/InvoiceServices'
import moment from 'moment'
import Priceconstants from '../../../constants/imageConstants'
import parse from 'html-react-parser';
import { GetEmployeeTargetBasedOnInsentiveActive } from '../../../services/AdminServicesfile/TargetServices'
import { TbAdjustmentsHorizontal } from "react-icons/tb";
import {Modal} from 'antd'
import { AiOutlineClose, AiOutlineReload} from 'react-icons/ai';

function RevenueCollection() {

   const user = useSelector(state=>state.Auth)
   let year = new Date().getFullYear().toString()

   const [teamMember,setteamMember] = useState([])
   const [incentive,setincentive] = useState({})
   const [selected_period,setselected_period] = useState('')
   const [period,setperiod] = useState([])
   const [datas,setdatas] = useState([])
   const [target,settarget] = useState([])

   const [step,setstep] = useState(1)
   const [report_display,setreport_display] = useState(false)

   const [table_visible,settable_visible] = useState({sl_no:true,month:true,operator:true,client_name:true,distribution:true,area:true,invoive_amt:true,amt_collected:true,amt_pending:true,users:true})

   const [currentperiod,setcurrentperiod] = useState('')
   const [designtable,setdesigntable] = useState(false)



   async function getTargetAchieve(){
    const response = await GetEmployeeTargetBasedOnInsentiveActive()
    settarget(response?.data?.data?.data)
   }

   useEffect(()=>{
    getTeamMembers()
    getCurrentInsentive()
   },[])

   useEffect(()=>{
      getData()
   },[currentperiod,report_display])

   async function handlechange(e){
      setselected_period(period?.find((f)=>f?.value === e))
      setcurrentperiod(period?.findIndex((f)=>f?.value === e) + 1)
   }

  async function getTeamMembers(){
        const response = await GetTeamMembersDetail(user?.id, new Date().getFullYear() - 1)
        let arr = []
        response?.data?.datas[0]?.users?.forEach((d)=>{
            arr?.push({label:d?.name,value:d?._id})
        })
        setteamMember(arr)
  }  


  async function getData(){

      
       if(!report_display){
            let invoice_payment_recieved_form;
            let invoice_payment_recieved_till;


            invoice_payment_recieved_form = incentive.incentive_date_time_period[currentperiod - 1]
            if(currentperiod === 4){
                invoice_payment_recieved_till = incentive.incentive_end
            }else{
               invoice_payment_recieved_till =  incentive.incentive_date_time_period[currentperiod]
            }

            // console.log("currentperiod",currentperiod)
            // console.log("invoice_payment_recieved_form",invoice_payment_recieved_form)
            // console.log("invoice_payment_recieved_till",invoice_payment_recieved_till)

            if(invoice_payment_recieved_form !== undefined && invoice_payment_recieved_till !== undefined){
                GetInvoiceRaisedBasedOnIncentivePeriod(invoice_payment_recieved_form?.slice(0,10),invoice_payment_recieved_till?.slice(0,10),incentive?._id)
            }
        }else{
            let invoice_payment_recieved_form = incentive.incentive_start;
            let invoice_payment_recieved_till = incentive.incentive_end;
            if(invoice_payment_recieved_form !== undefined && invoice_payment_recieved_till !== undefined){
                GetInvoiceRaisedBasedOnIncentivePeriod(invoice_payment_recieved_form?.slice(0,10),invoice_payment_recieved_till?.slice(0,10),incentive?._id)
            }
        }
  }

  async function getCurrentInsentive(){
        const response = await GetInsentiveActive()
        let d = response?.data?.datas[0]
        let current_period = 1

        let arr = []

        if(d?.incentive_period !== undefined){
            new Array(d?.incentive_period)?.fill('')?.forEach((d,i)=>{
                arr.push({label:`Q${i+1} Revenue`,value:i+1})
            })
            setperiod(arr)
        }
        setincentive(d)

        const current_date_time = new Date().getTime()
        const q_start = new Date(d.incentive_start).getTime()
        const q1_start = new Date(d.incentive_date_time_period[0]).getTime()
        const q2_start = new Date(d.incentive_date_time_period[1]).getTime()
        const q3_start = new Date(d.incentive_date_time_period[2]).getTime()
        const q4_start = new Date(d.incentive_date_time_period[3]).getTime()
        const q_end = new Date(d.incentive_end).getTime()

        if(current_date_time <= q2_start && current_date_time >= q1_start && current_date_time >= q_start){
            current_period = 1
        }else if(current_date_time <= q3_start && current_date_time >= q2_start){
            current_period = 2
        }else if(current_date_time <= q4_start && current_date_time >= q3_start){
            current_period = 3
        }else if(current_date_time <= q_end && current_date_time >= q4_start){
            current_period = 4
        }


        let invoice_payment_recieved_form;
        let invoice_payment_recieved_till;

        if(current_period !== 1){
            invoice_payment_recieved_form =  d.incentive_date_time_period[current_period - 1]
            if(current_period === 4){
                invoice_payment_recieved_till =  d.incentive_end
            }else{
               invoice_payment_recieved_till =  d.incentive_date_time_period[current_period]
            }
        }else{
            invoice_payment_recieved_form = q1_start
            invoice_payment_recieved_till = q2_start
        }
        setcurrentperiod(current_period)
        setselected_period(arr[current_period-1])
       

        GetInvoiceRaisedBasedOnIncentivePeriod(invoice_payment_recieved_form?.slice(0,10),invoice_payment_recieved_till?.slice(0,10),d?._id)
  }

  async function GetInvoiceRaisedBasedOnIncentivePeriod(from_date,to_date,incentive){
    const response = await GetInvoiceRaisedBasedOnIncentive(from_date,to_date,incentive)
    setdatas(response?.data?.datas)
  }

  function totalAmount(d){
    let total = 0

    d?.invoicepayment?.forEach((d1)=>{
        d1?.payment_share?.forEach((d2)=>{
            total += d2?.total
        })
    })

    return total
  }

  function invoicepaymentshare(d,id){
    let total = 0

    d?.invoicepayment?.forEach((d1)=>{
        d1?.payment_share?.forEach((d2)=>{
            if(d2?._id === id){
                total += d2?.total
            }
        })
    })

    return total
  } 

  function distributions(d){
    let users_percentage = []
    let string = ''

    let total = 0

    d?.invoicepayment?.forEach((d1)=>{
        d1?.payment_share?.forEach((d2)=>{
            total += d2?.total
        })
    })


    d?.invoicepayment?.forEach((d1)=>{
        d1?.payment_share?.forEach((d2)=>{
            let member =  teamMember.find((f)=>f?.value === d2?._id)
            let dataExist = users_percentage.find((f)=>f?.user?.value === d2?._id)
            let dataExistfindIndex = users_percentage.findIndex((f)=>f?.user?.value === d2?._id)

            if(member !== undefined){ 
                if(dataExist !== null && member !== null){
                    if(dataExistfindIndex >= 0){
                        users_percentage[dataExistfindIndex] = {
                            user:member,
                            total:dataExist.total + d2.total,
                            percentage:`${(Math.abs((dataExist.total + d2.total)/total)*100)} %`
                        }
                    }else{
                        if(member !== null){
                            users_percentage.push({
                                user:member,
                                total:d2?.total,
                                percentage:`${(Math.abs(d2?.total/total)*100)} %`
                            })
                        }
                    }    
                }else{
                    if(member !== null){
                        users_percentage.push({
                            user:member,
                            total:d2?.total,
                            percentage:`${(Math.abs(d2?.total/total)*100)} %`
                        })
                    }
                }
            }

            

        })
    })

    users_percentage?.forEach((d,i)=>{
        string += i == 0 ? `${d?.user?.label} - <b>${d?.percentage}</b>` : ' ' + `${d?.user?.label} - <b>${d?.percentage}</b>`   
    })



     

    return string

  }


  function returntotalValue1(type,user){
    let total = 0 
    if(type === 'InvoiceAmount'){
        datas?.forEach((d)=>{
          total +=  (d?.invoice_amount_without_gst === undefined ? d?.amount : d?.invoice_amount_without_gst)
        })
    }

    if(type === 'AmountCollected'){
        datas?.forEach((d)=>{
            d?.invoicepayment?.forEach((d1)=>{
                d1?.payment_share?.forEach((d2)=>{
                    total += d2?.total
                })
            })
          })
    }

    if(type === 'AmountCollectedBasedUser'){
        datas?.forEach((d)=>{
            d?.invoicepayment?.forEach((d1)=>{
                d1?.payment_share?.forEach((d2)=>{
                    if(d2?._id === user){
                       total += d2?.total
                    }
                })
            })
          })

    }  
    
    if(type === 'AmountPending'){

        let invoiceAmt = 0 
        let amountRec = 0
        datas?.forEach((d)=>{
            invoiceAmt += (d?.invoice_amount_without_gst === undefined ? d?.amount : d?.invoice_amount_without_gst)
            d?.invoicepayment?.forEach((d1)=>{
                d1?.payment_share?.forEach((d2)=>{
                    amountRec += d2?.total
                })
            })
          })
       
          total = (invoiceAmt - amountRec) > 0 ? (invoiceAmt - amountRec)  : 0

    }

    return total
  }

  function returntotalValue(type){
    let total = 0
    if(type === 'target'){
        target?.forEach((t)=>{
            total += t?.vertical_target
        })
    }

    if(type === 'achieved'){
        target?.forEach((t)=>{
            total += t?.target_achieved_till_now !== undefined ? t?.target_achieved_till_now : 0
        })
    }

    // if(type === 'pending'){
    //     console.log("target",target)
    //     target?.forEach((t)=>{
    //         total += (t?.vertical_target - t?.target_achieved_till_now !== undefined ? t?.target_achieved_till_now : 0) > 0 ? (t?.vertical_target - t?.target_achieved_till_now !== undefined ? t?.target_achieved_till_now : 0) : 0
    //     })
    // }

    return total
    
  }

  function totalAmountPending(d){
    let total = 0;
    let amount = d?.invoice_amount_without_gst === undefined ? d?.amount : d?.invoice_amount_without_gst

    d?.invoicepayment?.forEach((d1)=>{
        d1?.payment_share?.forEach((d2)=>{
            total += d2?.total
        })
    })

    return (amount - total) > 0 ? (amount-total) : 0

  }


  return (
    <div className='w-[100%] overflow-hidden'>
         <Modal maskClosable={true} width={370} className='absolute left-[40%] top-0' open={designtable} footer={false} closable={false}>
      <div className='relative'>
         <AiOutlineReload onClick={()=>{settable_visible({sl_no:true,month:true,operator:true,client_name:true,distribution:true,area:true,invoive_amt:true,amt_collected:true,users:true});setdesigntable(!designtable)}} className='absolute right-7 top-0 cursor-pointer' />
         <AiOutlineClose onClick={()=>setdesigntable(!designtable)} className='absolute right-0 top-0 cursor-pointer' />
         <h6 className='text-[14px] font-[700]'>Customize Lead Table</h6>
         <h6 className='text-[11px] font-[400] bg-slate-100 p-1 my-1'>Use the below form to update the table customization as per your requirment</h6>

         <div className='flex flex-wrap  my-[5px]'>
           <div onClick={()=>settable_visible({...table_visible,sl_no:!table_visible.sl_no})} className={`flex items-center ${table_visible?.sl_no && 'bg-slate-700 text-white border-slate-700'} justify-between px-[4px] cursor-pointer py-[2px] border m-[1px]`}>
             <h6 className='text-[11px]'>Sl No</h6>
           </div>
           <div onClick={()=>settable_visible({...table_visible,month:!table_visible.month})} className={`flex items-center  ${table_visible?.month && 'bg-slate-700 text-white border-slate-700'} justify-between px-[4px] cursor-pointer py-[2px] border m-[1px]`}>
             <h6 className='text-[11px]'>Month</h6>
           </div>
           <div onClick={()=>settable_visible({...table_visible,operator:!table_visible.operator})} className={`flex items-center  ${table_visible?.operator && 'bg-slate-700 text-white border-slate-700'} justify-between px-[4px] cursor-pointer py-[2px] border m-[1px]`}>
             <h6 className='text-[11px]'>Operator</h6>
           </div>
           <div onClick={()=>settable_visible({...table_visible,client_name:!table_visible.client_name})} className={`flex items-center  ${table_visible?.client_name && 'bg-slate-700 text-white border-slate-700'} justify-between px-[4px] cursor-pointer py-[2px] border m-[1px]`}>
             <h6 className='text-[11px]'>Client</h6>
           </div>
           <div onClick={()=>settable_visible({...table_visible,distribution:!table_visible.distribution})} className={`flex items-center  ${table_visible?.distribution && 'bg-slate-700 text-white border-slate-700'} justify-between px-[4px] cursor-pointer py-[2px] border m-[1px]`}>
             <h6 className='text-[11px]'>Distribution</h6>
           </div>
           <div onClick={()=>settable_visible({...table_visible,area:!table_visible.area})} className={`flex items-center  ${table_visible?.area && 'bg-slate-700 text-white border-slate-700'} justify-between px-[4px] cursor-pointer py-[2px] border m-[1px]`}>
             <h6 className='text-[11px]'>Area</h6>
           </div>
           <div onClick={()=>settable_visible({...table_visible,invoive_amt:!table_visible.invoive_amt})} className={`flex items-center  ${table_visible?.invoive_amt && 'bg-slate-700 text-white border-slate-700'} justify-between px-[4px] cursor-pointer py-[2px] border m-[1px]`}>
             <h6 className='text-[11px]'>Invoice Amount</h6>
           </div>
           <div onClick={()=>settable_visible({...table_visible,amt_collected:!table_visible.amt_collected})} className={`flex items-center  ${table_visible?.amt_collected && 'bg-slate-700 text-white border-slate-700'} justify-between px-[4px] cursor-pointer py-[2px] border m-[1px]`}>
             <h6 className='text-[11px]'>Amount Collected</h6>
           </div>
           <div onClick={()=>settable_visible({...table_visible,amt_pending:!table_visible.amt_pending})} className={`flex items-center  ${table_visible?.amt_pending && 'bg-slate-700 text-white border-slate-700'} justify-between px-[4px] cursor-pointer py-[2px] border m-[1px]`}>
             <h6 className='text-[11px]'>Amount Pending</h6>
           </div>
           <div onClick={()=>settable_visible({...table_visible,users:!table_visible.users})} className={`flex items-center  ${table_visible?.users && 'bg-slate-700 text-white border-slate-700'} justify-between px-[4px] cursor-pointer py-[2px] border m-[1px]`}>
             <h6 className='text-[11px]'>Users</h6>
           </div>
          

         </div>
      </div>
    </Modal>
        <div className='flex'>
        <div className='w-44'>
        <DashboardMenu />
        </div>    
        <div className='px-4 w-[88%]'>

        <h6 className='font-[900] text-[14px] mt-4'>Revenue Collection Chart</h6>
            <div className='flex border-b mt-2 border-t '>
                <h6 onClick={()=>setstep(1)} className={`font-[600] ${step === 1 && 'bg-slate-600 text-white'} text-center cursor-pointer py-1 min-w-[120px] w-fit px-2 text-[11px]`}>Client Based</h6>
                <h6 onClick={()=>{setstep(2);getTargetAchieve()}} className={`font-[600] ${step === 2 && 'bg-slate-600 text-white'} text-center cursor-pointer py-1 min-w-[120px] w-fit px-2 text-[11px]`}>Target Based</h6>
            </div>

           {step === 1 &&
            <>
            <div className='flex items-center mt-1 border-b pb-1 justify-between  border-b'>
            <h6 className='font-[900] text-[13px] '>Revenue Chart</h6>
            <div className='flex items-center'>

            <div onClick={()=>setreport_display(!report_display)} className='text-[11px] cursor-pointer flex border h-[26px] overflow-hidden rounded-[5px] items-center mr-2'>
               <h6 className={`p-[4px] font-[600] ${report_display ? 'bg-slate-700 text-white' : 'text-blqack'} `} >Overall</h6>
               <h6 className={`p-[4px] font-[600] ${!report_display ? 'bg-slate-700 text-white' : 'text-blqack'} `}>Quarter</h6>
            </div>    
            <div className='border flex items-center border-slate-300 h-[30px] rounded-md py-[2px] mr-[5px]'>
                <Select
                      placeholder='Select Period'
                      bordered={false}
                      size='small'
                      defaultValue={selected_period}
                      value={selected_period}
                      style={{ width: 80 }}
                      onChange={(v)=>handlechange(v,'period')}
                      options={period}
                  />
                 </div>

              <TbAdjustmentsHorizontal onClick={()=>setdesigntable(!designtable)} size={28} className='bg-slate-600 text-white cursor-pointer rounded-[4px] ml-[2px] p-[6px]' />
              </div>
            </div>

            <div className='border max-h-[80vh]   min-w-[100%] overflow-scroll no-scrollbar mt-4'>
                <div className='sticky w-max top-0 z-50 flex bg-slate-50 py-[-5px] border-b border-slate-200 relative'>
                {table_visible?.sl_no && <h6 className='top-0  z-50 text-[12px] min-w-[60px] max-w-[60px]  px-2 py-1 font-[600] text-slate-600 border-r flex '>SL NO</h6>}
                {table_visible?.month &&  <h6 className='sticky top-0 z-50  text-[12px] min-w-[90px]  max-w-[90px]  px-2 py-1 font-[600] text-slate-600 border-r'>Month</h6>}
                {table_visible?.operator &&  <h6 className='sticky top-0 z-50  text-[12px] min-w-[120px] max-w-[120px]  px-2 py-1 font-[600] text-slate-600 border-r'>Operator</h6>}
                {table_visible?.client_name &&  <h6 className='sticky top-0 z-50  text-[12px] min-w-[150px] max-w-[150px]   px-2 py-1 font-[600] text-slate-600 border-r'>Client Name</h6>}
                {table_visible?.distribution &&  <h6 className='sticky top-0 z-50  text-[12px] min-w-[180px] max-w-[180px]  px-2 py-1 font-[600] text-slate-600 border-r'>% Distribution</h6>}
                {table_visible?.area &&  <h6 className='sticky top-0 z-50  text-[12px] min-w-[180px] max-w-[180px]  px-2 py-1 font-[600] text-slate-600 border-r'>Area in Sqft</h6>}
                {table_visible?.invoive_amt &&  <h6 className='sticky top-0 z-50  text-[12px] min-w-[110px] max-w-[110px]   px-2 py-1 font-[600] text-slate-600 border-r'>Amt Invoice</h6>}
                {table_visible?.amt_collected &&  <h6 className='sticky top-0 z-50  text-[12px] min-w-[110px] max-w-[110px]   px-2 py-1 font-[600] text-slate-600 border-r'>Amt Collected</h6>}
                {table_visible?.amt_pending &&  <h6 className='sticky top-0 z-50  text-[12px] min-w-[110px] max-w-[110px]   px-2 py-1 font-[600] text-slate-600 border-r'>Amt Pending</h6>}
                {table_visible?.users && <>
                    {teamMember?.map((u)=>(
                    <h6  key={u?.value} className='sticky top-0 z-50  text-[12px] min-w-[90px] max-w-[90px]  px-2 py-1 font-[600] text-slate-600 border-r'>{u?.label?.split(' ')[0]}</h6>
                    ))}
                    </>}


                    

                    

                
                </div>

                {datas?.map((d,i)=>(            
                    <div className='flex  w-max bg-white py-[-5px] border-b border-slate-200 relative'>
                    {table_visible?.sl_no &&  <h6 className='text-[11px] min-w-[60px]  max-w-[60px]  px-2 py-1 font-[500] border-r flex text-center '>{i+1}</h6>}
                    {table_visible?.month &&  <h6 className='text-[11px] min-w-[90px]  max-w-[90px] px-2 py-1 font-[700] border-r'>{new moment(d?.createdAt)?.format('MMMM')}</h6>}
                    {table_visible?.operator &&  <h6 className='text-[11px] min-w-[120px] max-w-[120px] px-2 py-1 font-[500] border-r'>{d?.employee?.name}</h6>}
                    {table_visible?.client_name &&  <h6 className='text-[11px] min-w-[150px] max-w-[150px] px-2 py-1 font-[500] border-r'>{(d?.client?.company_name !== '' && !d?.land_lord) && d?.client?.company_name}  {(d?.client?.contact_name !== '' && d?.land_lord) && `` + d?.client?.contact_name}</h6>}
                    {table_visible?.distribution &&  <h6 className='text-[11px] min-w-[180px] max-w-[180px] px-2 py-1 font-[500] border-r'>{parse(distributions(d))}</h6>}
                    {table_visible?.area &&  <h6 className='text-[11px] min-w-[180px] max-w-[180px] px-2 py-1 font-[500] border-r'>Area : <span className='font-[700]'>{d?.client?.area}</span> / Requirment : <span className='font-[700]'>{d?.client?.requirment}</span></h6>}
                    {table_visible?.invoive_amt &&  <h6 className='text-[11px] min-w-[110px] max-w-[110px]  px-2 py-1 font-[700] border-r'>{Priceconstants(d?.invoice_amount_without_gst === undefined ? d?.amount : d?.invoice_amount_without_gst)}</h6>}
                    {table_visible?.amt_collected &&  <h6 className='text-[11px] min-w-[110px] max-w-[110px]  px-2 py-1 font-[700] border-r'>{Priceconstants(totalAmount(d))}</h6>}
                    {table_visible?.amt_pending &&  <h6 className='text-[11px] min-w-[110px] max-w-[110px]  px-2 py-1 font-[700] border-r'>{Priceconstants(totalAmountPending(d))}</h6>}
                    {table_visible?.users &&  <>
                        {teamMember?.map((u)=>(
                        <h6  key={u?.value} className='bg-white text-[12px] min-w-[90px] max-w-[90px]  px-2 py-1 font-[600] text-black border-r'>
                        {invoicepaymentshare(d,u?.value) !== 0 && Priceconstants(invoicepaymentshare(d,u?.value)) }
                        </h6>
                        ))}
                        </>}
                    </div>
                ))}

                    <div className='flex  w-max bg-white py-[-5px] border-b border-slate-200 relative'>
                    {table_visible?.sl_no &&  <h6 className='text-[11px] min-w-[60px]  max-w-[60px]  px-2 py-1 font-[800]  flex text-center '>Total</h6>}
                    {table_visible?.month &&  <h6 className='text-[11px] min-w-[90px]  max-w-[90px] px-2 py-1 font-[700] '></h6>}
                    {table_visible?.operator &&  <h6 className='text-[11px] min-w-[120px] max-w-[120px] px-2 py-1 font-[500] '></h6>}
                    {table_visible?.client_name &&  <h6 className='text-[11px] min-w-[150px] max-w-[150px] px-2 py-1 font-[500] '></h6>}
                    {table_visible?.distribution &&  <h6 className='text-[11px] min-w-[180px] max-w-[180px] px-2 py-1 font-[500] '></h6>}
                    {table_visible?.area &&  <h6 className='text-[11px] min-w-[180px] max-w-[180px] px-2 py-1 font-[500] border-r'></h6>}
                    {table_visible?.invoive_amt &&  <h6 className='text-[11px] min-w-[110px] max-w-[110px]  px-2 py-1 font-[700] border-r'>{Priceconstants(returntotalValue1('InvoiceAmount'))}</h6>}
                    {table_visible?.amt_collected &&  <h6 className='text-[11px] min-w-[110px] max-w-[110px]  px-2 py-1 font-[700] border-r'>{Priceconstants(returntotalValue1('AmountCollected'))}</h6>}
                    {table_visible?.amt_pending &&  <h6 className='text-[11px] min-w-[110px] max-w-[110px]  px-2 py-1 font-[700] border-r'>{Priceconstants(returntotalValue1('AmountPending'))}</h6>}
                    {table_visible?.users &&  <>
                        {teamMember?.map((u)=>(
                        <h6  key={u?.value} className='text-[12px] min-w-[90px] max-w-[90px]  px-2 py-1 font-[600] text-slate-600 border-r'>
                            {Priceconstants(returntotalValue1('AmountCollectedBasedUser',u?.value))}
                        </h6>
                        ))}
                        </>}
                    </div>

            </div>
            </>}

            {step === 2 &&
            <>
            <div className='mt-4'>
            <h6 className='font-[900] text-[13px] '>Target Chart</h6>

            <div className='w-fit justify-between  border mt-2'>

                <div className='text-[12px] flex bg-slate-50 border-b'>
                    <h6 className='w-[60px] border-r p-[5px] font-[600] text-slate-600'>SL No</h6>
                    <h6 className='w-[120px] border-r p-[5px] font-[600] text-slate-600'>Employee</h6>
                    <h6 className='w-[120px] border-r p-[5px] font-[600] text-slate-600'>Target</h6>
                    <h6 className='w-[120px] border-r p-[5px] font-[600] text-slate-600'>Achieved</h6>
                    <h6 className='w-[120px] p-[5px] font-[600] text-slate-600'>Pending</h6>
                </div>
                {target?.map((t,i)=>(
                <div className={`text-[12px] flex ${(i !== target?.length - 1) && 'border-b'} `}>
                    <h6 className='w-[60px] border-r p-[5px] text-[11px] font-[500]'>{i+1}</h6>
                    <h6 className='w-[120px] font-[800] border-r p-[5px] text-[11px] font-[500]'>{t?.user?.name}</h6>
                    <h6 className='w-[120px] font-[800] border-r p-[5px] text-[11px] font-[500]'>{Priceconstants(t?.vertical_target)}</h6>
                    <h6 className='w-[120px] font-[800] border-r p-[5px] text-[11px] font-[500]'>{Priceconstants(t?.target_achieved_till_now)}</h6>
                    <h6 className='w-[120px] font-[800] p-[5px] text-[11px] font-[500]'>{Priceconstants((t?.vertical_target - (t?.target_achieved_till_now !== undefined ? t?.target_achieved_till_now : 0)) > 0 ? (t?.vertical_target - (t?.target_achieved_till_now !== undefined ? t?.target_achieved_till_now : 0)) : 0)}</h6>
                </div>))}

                <div className={`text-[12px] flex border-b border-t`}>
                    <h6 className='w-[180px]  p-[5px] text-[11px] font-[700] border-r'>Total</h6>
                    <h6 className='w-[120px] font-[800] border-r p-[5px] text-[11px] font-[500]'>{Priceconstants(returntotalValue('target'))}</h6>
                    <h6 className='w-[120px] font-[800] border-r p-[5px] text-[11px] font-[500]'>{Priceconstants(returntotalValue('achieved'))}</h6>
                    <h6 className='w-[120px] font-[800] p-[5px] text-[11px] font-[500]'>{Priceconstants(returntotalValue('target') - returntotalValue('achieved'))}</h6>
                </div>

            </div>
            </div>
            </>}
        </div>
      </div>
    </div>
  )
}

export default RevenueCollection