import React,{useState,useEffect} from 'react'
import { DatePicker,Modal } from 'antd';
import { IconButton, Tooltip, } from '@material-ui/core';
import {FiChevronRight,FiChevronLeft} from 'react-icons/fi';
import {  ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../components/button';
import AtsMenu from './AtsMenu';
import { useNavigate } from 'react-router-dom';
import { CreateAtsEmployeeToClientJobService, DeleteAtsEmployeeService, FilterAllAtsEmployeeSharedBasedOnJd, FilterAtsEmployeeService, GetAllAtsEmployeeSharedBasedOnJd, GetAtsEmployeeService } from '../../services/Ats/AtsEmployeeServices';
import toast from 'react-hot-toast';
import {AiOutlineFilter,AiOutlineDelete,AiOutlineEdit,AiOutlineFilePdf, AiOutlineDownload, AiOutlineClose} from 'react-icons/ai';
import { RiBillLine } from 'react-icons/ri';
import {BiReset,BiCheckbox,BiCheckboxSquare} from 'react-icons/bi';
import { FilterAtsActiveobService, GetAtsActiveobService } from '../../services/Ats/AtsJobServices';
import { TextAreaInput1 } from '../../components/input';
import { useSelector } from 'react-redux';

function AtsCandidateManage() {

  const [search,setsearch] = useState({text:'',from_date:'',to_date:'',from_date1:'',to_date1:'',activate:false})
  const [page,setpage] = useState(1)
 


  const navigate = useNavigate()

  const [data,setdata] = useState([])
  const [datas,setdatas] = useState({})

  const roles = useSelector(state=>state.Auth.roles)

  console.log("roles",roles)

  // console.log("search_text kp : ",search_text)


  useEffect(()=>{
    if(search?.activate){
      filterdata(page)
    }else{
      getdata(page)
    }
  },[page])

  async function getdata(page){
    const response = await GetAllAtsEmployeeSharedBasedOnJd({page:page})
    // console.log("response?.data here",response?.data)
    setdata(response.data.data)
  }

  async function filterdata(page=1){
      setsearch({...search,activate:true})
      const response = await FilterAllAtsEmployeeSharedBasedOnJd({page:page,...search})
      setdata(response?.data?.data)
    }

  // console.log("data kp",data)
  async function resetdata(){
    setsearch({text:'',from_date:'',to_date:'',activate:false})
    getdata(1)
  }

 


  return (
    <div className='w-[98%]'>
      
       <div className='flex'>
            <div className='min-w-44'>
            <AtsMenu />
            </div>
            <div className='w-[100%] pl-5 mt-4'>
              <div className='flex items-center -mt-1 border-b justify-between pb-1'>

              <h6 className='font-[800] text-[13px]'>Total Profile Shortlisted   ({data?.pagination?.total})</h6>
                      
                      <div className='flex items-center text-[12px]'>
                      <div className='flex items-center'>
                      <h6 className='mr-2 font-[600]'>{page == 1 ? data?.datas?.length > 0 ? 1 : 0 : (page - 1) * data?.pagination?.limit } - {data?.pagination?.limit} of {data?.pagination?.total} </h6>

                      <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>
                      <IconButton onClick={()=>{ page < data?.pagination?.totalPages  ? setpage(page+1) : console.log('')}}><FiChevronRight className={`${(data?.pagination?.totalPages === page || data?.datas?.length === 0) ? 'opacity-50 ' : 'opacity-100'}`}  size={16} /></IconButton>

                      </div>
                      <DatePicker  size='small' style={{fontSize:'11px'}} ampm={false} placeholder='From Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.from_date} onChange={(v,v1) => {console.log('v na',new Date(v).toLocaleDateString());setsearch({...search,from_date:v,from_date1:v1})}} /> 

                      <DatePicker size='small' style={{fontSize:'11px'}} ampm={false} placeholder='To Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.to_date} onChange={(v,v1) => {console.log('v na',new Date(v).toLocaleDateString());setsearch({...search,to_date:v,to_date1:v1})}} /> 

                      
                      <div className='mr-2'>
                          

                      <input  id="search_text" placeholder='Search text' type='text' value={search?.text} onChange={(e)=>setsearch({...search,text:e.target.value})} className='border py-[3px]  focus:ring-0 !outline-none  focus:ring-offset-0 focus:shadow-none text-[14px]  w-[100px] px-1 rounded-md border-slate-300' />
                  

                      </div>  
                      <div className='flex'>
                      <div className='mr-2'>
                        <AiOutlineFilter onClick={()=>filterdata(1)} size={24} className='border border-slate-600 bg-slate-600 text-[#dedede] p-1 rounded' />
                      </div>
                      <div>
                      <BiReset size={24} onClick={resetdata} className='border border-slate-300 bg-[#f6f8fa] text-[#5c5c5c] p-1 rounded' />
                      </div>
                      </div>
                      {/* <ButtonFilledAutoWidth btnName="Add New" onClick={()=>{navigate('create')}} />
                      {selected_employee?._id !== undefined &&
                      <div className='ml-2'>
                      <ButtonFilledAutoWidth btnName="Assign" onClick={()=>{setassignmodal(!assignmodal)}} />
                      </div>} */}
                      </div>
              </div>


              {/* {data?.datas?.map} */}

              {data?.datas?.length > 0 &&


            
            <div className='max-h-[85vh] mt-4 border-t  border-l border-r overflow-y-scroll'>
                <div className='flex sticky top-0 bg-white z-50 border-b'>
                    <div className='p-1 border-r w-[4%] h-full'>
                       <h6 className='font-[700] text-[11px]'>SL No</h6>
                     </div>  
                    <div className='pl-2 border-r p-1 w-[20%] pr-2'>
                       <h6 className='font-[700] text-[11px]'>Job Description / Client Info</h6>
                    </div>   

                    <div className='p-1 w-[76%]'>
                       <h6 className='font-[700] text-[11px]'>Candidate Shared</h6>
                    </div>   
                </div>    
                  {data?.datas?.map((d,i)=>(
                 <div>
                    <div className='h-max border-b flex h-full items-start'>
                     <div className='p-1 w-[4%] h-full'>
                       <h6 className='font-[700] text-[11px] text-center'>{i+1}</h6>
                     </div>  
                    
                     <div className='p-1 min-h-[28vh] max-h-[28vh] border-r border-l pl-2 w-[20%] pr-2'>
                     <h6 className='text-[12px] mb-0'>Job Title : <span className='font-[800]'>{d?._id?.ats_hr_client_job?.job_title}</span> </h6>
                     <h6 className='text-[11px] mb-0 line-clamp-2'>Job Description : <span className='font-[800]'>{d?._id?.ats_hr_client_job?.job_description}</span></h6>
                     <h6 className='text-[11.5px] mb-0'>Company/ Client : <span className='font-[800]'>{d?._id?.ats_hr_client_job?.ats_hr_client?.company_name} / {d?._id?.ats_hr_client_job?.ats_hr_client?.client_name}</span> </h6>
                     <span className='bg-gray-100 text-[11px] font-[600] p-1 rounded '>{d?._id?.ats_hr_client_job?.ats_hr_client?.stage}</span>
                     <div className='border w-[100%] flex mt-2   border-gray-100'>
                           <div className='border-r w-[100%]  border-gray-200 '>
                              <h6 className='text-[11px] bg-slate-50 text-center font-[400]'>Opening</h6>
                              <h6 className='text-[10px] text-center font-[800]'>{d?._id?.ats_hr_client_job?.no_of_opening}</h6>
                           </div> 

                           <div className='border-r w-[100%]  border-gray-200 '>
                              <h6 className='text-[11px] bg-slate-50 text-center font-[400]'>Experience</h6>
                              <h6 className='text-[10px] text-center font-[800]'>{d?._id?.ats_hr_client_job?.experience}</h6>
                           </div> 

                           <div className='w-[100%]'>
                              <h6 className='text-[11px] bg-slate-50 text-center font-[400]'>Notice</h6>
                              <h6 className='text-[10px] text-center font-[800]'>{d?._id?.ats_hr_client_job?.notice_period}</h6>
                           </div> 

                     </div>  
                     <h6 className='text-[11px] mt-2 mb-1'>Client Spoc person : <span className='font-[800]'>{d?._id?.ats_hr_client_job?.created_by?.name}</span></h6>

                     </div> 

                     <div className='p-1 px-2 w-[76%]'>
                      <h6 className='text-[11px]'>Total Candidate Shared <span className='font-[700]'>({d?.count})</span></h6>
                      <div className='h-[30%]  max-h-[20vh] min-h-[30%] mt-2 border-t   border-l border-r overflow-y-scroll'>
                        <div className='sticky top-0 z-20 flex  bg-white py-[-5px] border-b border-slate-200 relative'>
                            <h6 className='sticky top-0 z-20  text-[12px] w-[60px]  px-2 py-1 font-[600] text-slate-600 border-r'>Photo</h6>
                            <h6 className='sticky top-0 z-20  text-[12px] w-[130px]  px-2 py-1 font-[600] text-slate-600 border-r'> Name</h6>
                            <h6 className='sticky top-0 z-20  text-[12px] w-[80px]  px-2 py-1 font-[600] text-slate-600 border-r'> Experience</h6>
                            <h6 className='sticky top-0 z-20  text-[12px] w-[120px]  px-2 py-1 font-[600] text-slate-600 border-r'> Mobile</h6>
                            <h6 className='sticky top-0 z-20  text-[12px] w-[170px]  px-2 py-1 font-[600] text-slate-600 border-r'> Email</h6>
                            <h6 className='sticky top-0 z-20  text-[12px] w-[200px] px-2 py-1 font-[600] text-slate-600 border-r'>Hiring Stage</h6>
                            <h6 className='sticky top-0 z-20  text-[12px] w-[30px]  px-2 py-1 font-[600] text-slate-600 sticky right-0'>Actions</h6>
                        </div>
              
                        {d?.records?.map((d1,i)=>(
                        <div  key={d1?._id} className={`${d1.is_deleted ? 'opacity-50 bg-red-50 border-l-2 border-l-red-500':'oapcity-100 border-l-2 border-l-white'} flex z-50 border-b border-slate-200 z-0`}  >
                            <h6 className='text-[11.5px] relative font-[500] w-[58px]  px-2 py-1 truncate border-r  border-slate-200'>{(d1?.ats_hr_employee?.user_photo !== undefined && d1?.ats_hr_employee?.user_photo !== null) ? <img className='w-8 h-4 rounded overflow-hidden object-contain' onError={({currentTarget})=> {currentTarget.src = 'https://fidecrmfiles.s3.amazonaws.com/noprofile.png'}}  src={`${process.env.REACT_APP_BACKEND_IMAGE_URL}${d1?.ats_hr_employee?.user_photo}`} /> : <span className='bg-gray-100 w-5 h-5 ml-[6px] flex items-center justify-center  text-[10px] rounded overflow-hidden uppercase font-[700]'>{d1?.ats_hr_employee?.employee_name?.slice(0,2)}</span>}  </h6>
                            <h6 className='text-[11.5px] font-[500] w-[130px]  px-2 py-1 truncate border-r  border-slate-200'>{d1?.ats_hr_employee?.employee_name}</h6>
                            <h6 className='text-[11.5px] font-[500] w-[80px]  px-2 py-1 truncate border-r  border-slate-200'>{d1?.ats_hr_employee?.experience}</h6>
                            <h6 className='text-[11.5px] font-[500] w-[120px] px-2 py-1 truncate border-r  border-slate-200'>{d1?.ats_hr_employee?.mobile_no}</h6>  
                            {['admin','ats_head'].filter(f => roles?.includes(f))?.length > 0 ?
                            <h6 className='text-[11.5px] font-[500] w-[170px] px-2 py-1 truncate border-r  border-slate-200'>{d1?.ats_hr_employee?.email_id}</h6> :   <h6 className='text-[11.5px] font-[500] w-[170px] px-2 py-1 truncate border-r  border-slate-200'>{d1?.created_by?.name}</h6>}
                            <h6 className='text-[11.5px] font-[500] w-[200px]  px-2 py-1 truncate border-r  border-slate-200 sticky right-0'>{d1?.heading_Stage} : <span className='font-[700]'>[{d1?.sub_heading_Stage}]</span></h6>
                            
                            <h6  className='text-[12px] w-[30px]  px-2 py-1 mt-1 flex'>
                            
                            
                              <Tooltip title="Detail">
                              <span><RiBillLine size={13} className="z-10 ml-0" onClick={()=>{navigate('/ats/job_description/detail',{state:{data:d?._id?.ats_hr_client_job?._id}})}}/></span>
                              </Tooltip>

                            </h6>
                        </div>))}
                        </div>
                     </div> 
                   
                    </div>
                 </div>
                 
                 
                 ))}
            </div>
             
             
          
              
              
              }  

            </div>
        </div>    
    </div>
  )
}

export default AtsCandidateManage