import { deleteRequest, get, post, put } from "../helpers/apihelpers";


// create lead getleads ,getdetaillead,updatelead
export const GetReimbusmentService=async(page,step,from_date,to_date)=>{
    try {
        const result = await get(`api/reimbusment/get?page=${page}&step=${step}&from_date=${from_date}&to_date=${to_date}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetReimbusmentDetailService=async(id)=>{
    try {
        const result = await get(`api/reimbusment/get/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetFinanceReimbusmentService=async(page,step,from_date,to_date,department,user,particulars)=>{
    try {
        const result = await get(`api/reimbusment/get_finance?page=${page}&step=${step}&from_date=${from_date}&to_date=${to_date}&department=${department}&user=${user}&particulars=${particulars}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetFinanceLineItemReimbusmentService=async(page,step,from_date,to_date,department,user,particulars)=>{
    try {
        const result = await get(`api/reimbusment/get_finance_line_item?page=${page}&step=${step}&from_date=${from_date}&to_date=${to_date}&department=${department}&user=${user}&particulars=${particulars}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetHeadReimbusmentService=async(page,step,from_date,to_date)=>{
    try {
        const result = await get(`api/reimbusment/get_head?page=${page}&step=${step}&from_date=${from_date}&to_date=${to_date}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetDetailHeadReimbusmentService=async(id)=>{
    try {
        const result = await get(`api/reimbusment/get_head/${id}?page=${1}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const CreateReimbusmentService=async(data)=>{
    try {
        const result = await post(`api/reimbusment/create`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateReimbusmentItemService=async(id,data)=>{
    try {
        const result = await put(`api/reimbusment/update_reimbusment_item/${id}`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateReimbusmentApprovalService=async(id,data)=>{
    try {
        const result = await put(`api/reimbusment/update_reimbusment_approval/${id}`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}


export const UpdateReimbusmentService=async(id,data)=>{
    try {
        const result = await put(`api/reimbusment/update/${id}`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}


export const DeleteReimbusmentService=async(id)=>{
    try {
        const result = await deleteRequest(`api/reimbusment/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UploadExcelReimbusmentService=async(data)=>{
    try {
        const result = await post(`api/reimbusment/upload_excel_data_user`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DownloadExcelReimbusmentService=async(step)=>{
    try {
        const result = await get(`api/reimbusment/download_excel?step=${step}`);
        return result;
    } catch (err) {
        return err.response;
    }
}