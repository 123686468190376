import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { TbArrowAutofitRight,TbArrowAutofitLeft } from "react-icons/tb";
import { useSelector } from 'react-redux';


function ExitMenu(){

  const {pathname} = useLocation();
  const path = pathname.split('/')[pathname.split('/').length - 1]
  const roles = useSelector(state=>state.Auth.roles)
  const user = useSelector(state=>state.Auth)

//   console.log("roles",roles)
//   console.log("user",user)

//   console.log("path")
//   CONST [ROLES]

   const navigate = useNavigate();

   useEffect(()=>{
      if(path == 'in_out'){
            if(roles?.includes('admin')){
                navigate('/in_out/entry_approval/list')
            }else{
                navigate('/in_out/exit_approval/list')
            }
       }
   },[])

    const menu = [
       {name:'Employee Joining',icon:TbArrowAutofitRight,path:'/in_out/entry_approval/list',id:1,color:'',roles:['admin','admin_entry_approval']},
       {name:'Employee Exit',icon:TbArrowAutofitLeft,path:'/in_out/exit_approval/list',id:1,color:'',roles:['admin','hod_exit_approval','hr_exit_approval','it_head','it_exit_approval']},
    ]  
    
    return (
        <div className='mr-0 min-h-screen max-h-sceen overflow-hidden border-r min-w-44  w-44 max-w-44  px-2'>
            <h6 className='pt-5 ml-2 mb-2 font-[700] text-[12px]'>Basic Option</h6>

            {menu.map((m)=>(
                roles?.filter((f)=>m?.roles?.includes(f))?.length > 0 && <>
                <div key={m.path} className={`flex items-center my-1 hover:bg-[#f4f5f7] px-1.5 py-1 ml-2 rounded-md relative cursor-pointer ${pathname ===  m.path && 'bg-[#f4f5f7]'}`} onClick={()=>navigate(m?.path)}>
                {pathname ===  m.path &&  <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3' ></span>}
                <m.icon color='#000' style={{backgroundColor:m?.color,padding:'2px'}} size={16}  />
                <span className='text-[#000] font-[500] ml-2 text-[12px]'>{m.name}</span>
                </div>    
                </>
            ))}


       </div>
    )
}

export default ExitMenu