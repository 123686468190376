import React,{useState,useEffect} from 'react'
import { useLocation,useNavigate } from 'react-router-dom'
import moment from 'moment';
import {
    IconButton,
    Tooltip
  } from "@mui/material";

import { GetDepartmentService, GetUsersByDepartment } from '../../services/DepartmentServices';
import { Select } from 'antd';
import { ButtonFilledAutoWidth } from '../../components/button';
import { GetStageService } from '../../services/StageServices';
import { DatePicker } from 'antd'
import { GetTeamBasedOnYearInsentiveService, GetTeamMembersDetail } from '../../services/AdminServicesfile/TeamServices';
import { useSelector } from 'react-redux';
import DashboardMenu from '../dashboard/DashboardMenu';
import {BsArrowRepeat} from 'react-icons/bs';
import {FiChevronRight,FiChevronLeft} from 'react-icons/fi';
import { AiOutlineEdit } from 'react-icons/ai';
import { GetAdminLeadOptionsService } from '../../services/LeadOptionServices';
import { GetReportingMembersDetail } from '../../services/AdminServicesfile/ReportingServices';


function TeamDailyTask() {

    const location = useLocation();
    const user = useSelector(state=>state.Auth)
    const user_department = user.department_id[0]

    let year = new Date().getFullYear().toString()
    const navigate = useNavigate();

    const [data,setdata]  = useState({})

    const [selected_department,setselected_department] = useState('')
    const [selected_team,setselected_team] = useState('')
    const [selected_user,setselected_user] = useState('')
    const [selected_stage,setselected_stage] = useState('')
    const [teams,setteams] = useState([]) 


    const [departments,setdepartments] = useState([])
    const [users,setusers] = useState([])
    const [stages,setstages] = useState([])
    const [page,setpage] = useState(1)

    const [search,setsearch] = useState({text:'',from_date:'',to_date:'',from_date1:'',to_date1:'',activate:false})
  
   
   
    useEffect(()=>{
       if(user?.roles?.includes('admin')){
         getdepartment()
       }else if(user?.roles?.includes('hod')){
        if(user?.department_id[0]?.department_name === 'Transaction Team'){
            getTeamMembers(user?.department_id[0]?.id)
            getuserbaseddepartment(user?.department_id[0]?.id)
        }
        setselected_department({label:user?.department_id[0]?.department_name,value:user?.department_id[0]?.id})
       }else if(user?.roles?.includes('manager')){
          getTeamMembersForCurrentYear(user?.id)
       }else if(user?.roles?.includes('reporting_manager')){
          getReportingMemberDetails(user?.id)
       }
       getstage()
       getdailytask()
    },[])

    useEffect(()=>{
        getdailytask()
    },[page,selected_stage,selected_user,selected_department])

    



    async function getdepartment(){
        const response = await GetDepartmentService();
        let data = response?.data?.datas?.find((d)=>d?.id === user_department.id)
        let arr = []
        response?.data?.datas.forEach((d)=>{
        if(d?.department_name !== 'Finance Team' && d?.department_name !== 'Gallery Team' && d?.department_name !== 'Corp Team'){
            arr.push({label:d?.department_name,value:d?.id})
        }
        })

        if(data?.department_name === 'Transaction Team'){
          if(user?.roles?.includes('admin') || user?.roles?.includes('hod')){
            getTeamMembers(data?.id)
          }else{
            getTeamMembersForCurrentYear(user?.id) 
          }   
        }
        setselected_department(arr?.find((f)=>f?.value === data.id))
        setdepartments(arr)
   }    
   
   async function getTeamMembers(id){
    let teams_arr = []
    const response1 = await GetTeamBasedOnYearInsentiveService(id)
    response1?.data?.datas?.forEach((d)=>{
     let users_arr = []
     let users_arr_id = []
      d?.users?.forEach((d1)=>{
       users_arr?.push({label:d1?.name,value:d1?._id})
       users_arr_id.push(d1?._id)
      })
     teams_arr?.push({value:d._id,label:d?.name,users:users_arr,users_id:users_arr_id})
   })
   setteams(teams_arr)
   }

//    console.log("teams",teams)

   async function getTeamMembersForCurrentYear(id){
    const response = await GetTeamMembersDetail(id,year-1)
    let arr = []
    response?.data?.datas[0]?.users?.forEach((d)=>{
        arr.push({label:d?.name,value:d?._id})
    })
    setusers(arr)
    getdailytask()
   }

   async function getReportingMemberDetails(id){
    const response = await GetReportingMembersDetail(id)
    let arr = []
    response?.data?.datas[0]?.users?.forEach((d)=>{
        arr.push({label:d?.name,value:d?._id})
    })
    setusers(arr)
    getdailytask()
   }

//  console.log("users",users)

   async function getstage(){
    const response = await GetStageService('FollowUp_Options')
    let arr = []
    response?.data?.data?.datas?.forEach((d)=>{
      if(d?.name !== 'LOI Discussion' && d?.name !== 'Agreement Discussion'){
        arr.push({label:d?.name,value:d?._id})
      }  
    })
    setselected_stage(arr[0])
    setstages(arr)

   }

    async function getdailytask(){
        if(selected_stage?.value !== undefined){

            if(user?.roles?.includes('manager') || user?.roles?.includes('reporting_manager')){
                let user_id =  ''
                users?.forEach((u)=>{
                  user_id  += u?.value + ','
                })

                console.log("user_id",user_id)
                const response =  await GetAdminLeadOptionsService(
                    selected_stage?.value === undefined ? '' : selected_stage?.value,
                    selected_user?.value === undefined ? user_id : selected_user?.value,
                    search?.from_date1,
                    search?.to_date1,
                    page);
                setdata(response?.data)
              }else{
                const response =  await GetAdminLeadOptionsService(
                    selected_stage?.value === undefined ? '' : selected_stage?.value,
                    selected_team?.value !== undefined ? selected_user?.value === undefined ? selected_team?.users_id?.join(',') : selected_user?.value : selected_user?.value === undefined ? '' : selected_user?.value,
                    search?.from_date1,
                    search?.to_date1,
                    page);
                setdata(response?.data)
              }

          
        }
    }

    async function handlechange(val,type){
        if(type === 'department'){
            let department = departments.find(d=>d.value === val)
            if(selected_department !== null && selected_department.value !== undefined && selected_department.value !== department.value){
                setpage(1)
                setselected_department(department)
                getuserbaseddepartment(department.value)
                getstage(department.value) 
                setselected_user('')
                setselected_team('')
                if(department.label === 'Transaction Team'){
                    getTeamMembers(department.value)
                }
            }else{
              setpage(1)
              setselected_department(department)
              getuserbaseddepartment(department.value)
              getstage(department.value)
              setselected_user('')
              setselected_team('')
              if(department.label === 'Transaction Team'){
                getTeamMembers(department.value)
              }
            }

          
        }else if(type === 'users'){
            setpage(1)
            setselected_user(users.find(d=>d.value === val))
        }else if(type === 'stages'){
            setpage(1)
            setselected_stage(stages.find((d)=>d.value === val))
        }else if(type === 'team'){
          let team_select = teams?.find((t)=>t?.value === val)
          setselected_team(team_select)
          setusers(team_select.users)
          setselected_user(null)
        }

    }


    async function getdepartment(){
        // console.log("amma")
          const response = await GetDepartmentService();
          // console.log("response",response)
          let data = response?.data?.datas?.find((d)=>d?.id === user_department.id)
          console.log('data',data)
  
          let arr = []
          response?.data?.datas.forEach((d)=>{
            if(d?.department_name !== 'Finance Team' && d?.department_name !== 'Gallery Team' && d?.department_name !== 'Corp Team'){
               arr.push({label:d?.department_name,value:d?.id})
            }
          })
          setdepartments(arr)
          if(user.roles.includes('hod')){
            getuserbaseddepartment(data?.id) 
            getstage(data?.id)
  
  
            if(data?.department_name === 'Transaction Team' || user.roles.includes('admin')){
              let teams_arr = []
               let data = response?.data?.datas?.find((d)=>d?.department_name === 'Transaction Team')
               const response1 = await GetTeamBasedOnYearInsentiveService(data.id)
               response1?.data?.datas?.forEach((d)=>{
                let users_arr = []
                let users_arr_id = []
                 d?.users?.forEach((d1)=>{
                  users_arr?.push({label:d1?.name,value:d1?._id})
                  users_arr_id.push(d1?._id)
                 })
                teams_arr?.push({value:d._id,label:d?.name,users:users_arr,users_id:users_arr_id})
              })
              setteams(teams_arr)
            }
          }
  
          if(!user.roles.includes('admin')){
            setselected_department({label:user?.department_id[0]?.department_name,value:user?.department_id[0]?.id})
            getuserbaseddepartment(user?.department_id[0]?.id)
  
          }else{
            // console.log("amma")
          }
          
      }
  
      async function getTeamMembersForCurrentYear(){
          const response = await GetTeamMembersDetail(user?.id,year-1)
          let arr = []
          response?.data?.datas[0]?.users?.forEach((d)=>{
              arr.push({label:d?.name,value:d?._id})
          })
          setusers(arr)
      }
  
      async function getuserbaseddepartment(id){
        if(id !== undefined){
        const response = await GetUsersByDepartment(id) 
          let arr = []
          response?.data?.datas?.forEach((d)=>{
              arr.push({label:d?.name,value:d?._id})
          })
        setusers(arr)
        }
      }

    async function applyfilterfunction(){
        if(selected_stage?.value !== undefined){
        const response =  await GetAdminLeadOptionsService(
            selected_stage?.value === undefined ? '' : selected_stage?.value,
            selected_team?.value !== undefined 
            ? 
            selected_user?.value === undefined ? selected_team?.users_id?.join(',') : selected_user?.value : '',
            search?.from_date1,
            search?.to_date1,
            page);
        setdata(response?.data)
        }
    }

    async function resetfilter(){
        setsearch({text:'',from_date:'',to_date:'',from_date1:'',to_date1:'',activate:false})
        setselected_stage(stages[0])
        setselected_user('')
       if(user?.roles?.includes('admin')){
         setselected_department('')
       }else if(user?.roles?.includes('hod')){
         setselected_team('')
         setselected_user('')
         if(user?.department_id[0]?.department_name === 'Transaction Team'){
            getTeamMembers(user?.department_id[0]?.id)
            getuserbaseddepartment(user?.department_id[0]?.id)
        }
       } 
      

        if(user?.roles?.includes('manager') || user?.roles?.includes('reporting_manager')){
            let user_id =  ''
            users?.forEach((u)=>{
              user_id  += u?.value + ','
            })
            const response =  await GetAdminLeadOptionsService(
                selected_stage?.value === undefined ? '' : selected_stage?.value,
                selected_user?.value === undefined ? user_id : selected_user?.value === undefined ? '' : selected_user?.value,
                search?.from_date1,
                search?.to_date1,
                page);
            setdata(response?.data)
          }else{
            const response =  await GetAdminLeadOptionsService(
                selected_stage?.value === undefined ? '' : selected_stage?.value,
                '',
                '',
                '',
                page);
            setdata(response?.data)
          }

       setpage(1)

      
      
    }

    async function gotoedit(v){
        // console.log("v",v)
        // console.log("selected_stage?.label",selected_stage?.label)
        if(selected_stage?.label === 'Calls'){
            navigate('/leads/daily_tasks/calls/edit',{state:{type:'calls',stage:selected_stage,data:v}})
        }
        if(selected_stage?.label === 'Walk Ins'){
            navigate('/leads/daily_tasks/walk_ins/edit',{state:{type:'walk_ins',stage:selected_stage,data:v}})
        }
        if(selected_stage?.label === 'Meetings'){
            navigate('/leads/daily_tasks/meeting/edit',{state:{type:'meeting',stage:selected_stage,data:v}})
        }
        if(selected_stage?.label === 'Property Visit'){
            navigate('/leads/daily_tasks/property_visit/edit',{state:{type:'property_visit',stage:selected_stage,data:v}})
        }
        if(selected_stage?.label === 'Scouting'){
            navigate('/leads/daily_tasks/scouting/edit',{state:{type:'scouting',stage:selected_stage,data:v}})
        }
 
    }



   
 
    


  return (
    <div style={{width:'100%',minHeight:'100vh',maxHeight:'100vh',height:'100%',overflow:'hidden',position:'relative'}}>
   
    <div > 
        <div className='flex'>
        <div className='w-44 border-r'>
        <DashboardMenu />
        </div>  
        <div className='px-4 min-w-[87%]  max-w-[87%] overflow-hidden max-w-[87%] pt-5'>
      
      
        
        <div   className='w-[100%]'>
          <div >
          <div className={`border-b ${user?.roles?.includes('admin') ? 'block' : 'flex justify-between'} pb-2  mb-2`}>
              <div className={`flex items-center mb-1 ${user?.roles?.includes('admin') && 'justify-between'}`}>
              <h6 className='font-[700] text-[14px] '>{!(user?.roles?.includes('hod') || user?.roles?.includes('manager')) ?  selected_department?.label?.split(' ')[0]  :  selected_department !== null ?  selected_department?.label?.split(' ')[0] : 'Fidelitus'} Daily Task  ({data?.pagination?.total})</h6>

                {user?.roles?.includes('admin') &&
                <div className='flex items-center  text-[12px] mr-2'>
                    <h6 className='mr-2 font-[600]'>{page === 1 ? data?.datas?.length > 0 ? 1 : 0 : (page - 1) * data?.pagination?.limit } - {data?.pagination?.limit} of {data?.pagination?.total} </h6>
                    <IconButton  onClick={resetfilter} ><BsArrowRepeat size={16} /></IconButton>
                    <div>
                    <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>
                    <IconButton onClick={()=>{ page < data?.pagination?.totalPages  ? setpage(page+1) : console.log('')}} ><FiChevronRight className={`${(data?.pagination?.totalPages === page || data?.datas?.length === 0) ? 'opacity-50' : 'opacity-100'}`} size={16} /></IconButton>
                    </div>
                </div>}
               </div>

          
               <div className='flex items-center ' >
                {!user?.roles?.includes('admin') &&
                    <div className='flex items-center  text-[12px] mr-2'>
                    <h6 className='mr-2 font-[600]'>{page === 1 ? data?.datas?.length > 0 ? 1 : 0 : (page - 1) * data?.pagination?.limit } - {data?.pagination?.limit} of {data?.pagination?.total} </h6>
                    <IconButton  onClick={resetfilter} ><BsArrowRepeat size={16} /></IconButton>

                    <div>
                    <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>
                    <IconButton onClick={()=>{ page < data?.pagination?.totalPages  ? setpage(page+1) : console.log('')}} ><FiChevronRight className={`${(data?.pagination?.totalPages === page || data?.datas?.length === 0) ? 'opacity-50' : 'opacity-100'}`} size={16} /></IconButton>
                    </div>
                </div>}
                

                

                    <DatePicker size='small'  ampm={false} placeholder='From Date' className='text-[11px] py-[4px]   w-28 border-slate-300 mr-1'  value={search?.from_date} onChange={(v,v1) => {console.log('v na',new Date(v).toLocaleDateString());setsearch({...search,from_date:v,from_date1:v1})}} /> 

                    <DatePicker size='small'  ampm={false} placeholder='To Date' className='text-[11px] py-[4px]  w-28 border-slate-300 mr-1'  value={search?.to_date} onChange={(v,v1) => {console.log('v na',new Date(v).toLocaleDateString());setsearch({...search,to_date:v,to_date1:v1})}} /> 

                
                    {user?.roles?.includes('admin') &&
                    <div className='border border-slate-300 rounded-md py-[2px] mr-[5px]'>
                    <Select
                        placeholder='Select Department'
                        bordered={false}
                        size='small'
                        defaultValue={selected_department !== '' ? selected_department : null}
                        value={selected_department !== '' ? selected_department : null}
                        style={{ width: 110 }}
                        onChange={(v)=>handlechange(v,'department')}
                        options={departments}
                    />
                    </div>}

                {selected_department?.label === 'Transaction Team' && (user?.roles?.includes('admin') || user?.roles?.includes('hod')) &&
                    <div className='border border-slate-300 rounded-md py-[2px] mr-[5px]'>
                    <Select
                        placeholder='Select Team'
                        bordered={false}
                        size='small'
                        defaultValue={selected_team !== '' ? selected_team : null}
                        value={selected_team !== '' ? selected_team : null}
                        style={{ width: 110 }}
                        onChange={(v)=>handlechange(v,'team')}
                        options={teams}
                    />
                    </div>}


                
                <div className='border border-slate-300 rounded-md py-[2px]'>
                <Select
                    placeholder='Select User'
                    bordered={false}
                    size='small'
                    defaultValue={selected_user !== '' ? selected_user : null}
                    value={selected_user !== '' ? selected_user : null}
                    style={{ width: 110 }}
                    onChange={(v)=>handlechange(v,'users')}
                    options={users}
                />
                </div>

                <div className='ml-1 rounded-md border border-slate-300 py-[2px]'>
                <Select
                    placeholder='Select Stage'
                    bordered={false}
                    size='small'
                    defaultValue={selected_stage !== '' ? selected_stage : null}
                    value={selected_stage !== '' ? selected_stage : null}
                    style={{ width: 110 }}
                    onChange={(v)=>handlechange(v,'stages')}
                    options={stages}
                />
                </div>

                
                <div className='ml-1 mr-1'>
                <ButtonFilledAutoWidth btnName="Add Filter" onClick={()=>applyfilterfunction(1)} /> 
                </div> 

                <ButtonFilledAutoWidth btnName="Reset Filter" onClick={resetfilter} />

               </div>

          </div>


        
          {data?.datas?.length > 0 && 
          <div className='max-h-[82vh] max-w-[99%] overflow-x-hidden w-[100%] overflow-y-scroll'> 
            <h6 className='font-[800] text-[13px]'>Total {selected_stage?.label}  ({data?.pagination?.total})</h6>
             
            {selected_stage?.label === 'Calls' && 
            <div className="grid  grid-cols-5 mb-2">
                    {data?.datas?.map((d)=>(
                    <div  key={d?._id} className='border px-1.5 py-1 mt-1 relative mx-1 relative cursor-pointer' >
                        <div className='bg-sky-100 p-2'>
                        {/* <Tooltip title="Convert"><span className='absolute bg-white p-1 right-6 border-r border-slate-100 top-0'><AiOutlineContacts size={12}  onClick={()=>{navigator('/leads/contacts/create',{state:{type:'convert', data:d}})}} /></span></Tooltip> */}
                        <Tooltip title="Edit"><span className='absolute bg-white p-1 right-0  top-0'><AiOutlineEdit size={20} className='absolute bg-white p-1 right-1 top-0' onClick={()=>{gotoedit(d)}} /></span></Tooltip>
                        {(d?.contact_name !== null && d?.contact_name !== undefined) && <h6 className='text-[11px]' >Name : <span className='font-[600] text-[12px]'>{d?.contact_name} / {d?.phone}</span> </h6>}
                        {d?.email !== null && <h6 className='text-[11px]' >Email : <span className='font-[600] text-[12px]'>{d?.email}</span> </h6>}

                        <h6 className='text-[11px] font-[600]'>Company Info : </h6>

                        {d?.company_name !== null &&  <h6 className='text-[11px] ' ><span className='font-[500] text-[12px]'>{d?.company_name}</span> </h6>}
                        <h6 className='text-[11px] ' >Message : <span className='font-[600] text-[11px] line-clamp-2'>{d?.message}</span> </h6>
                        </div>
                        <h6 className='text-[10px] ml-1 font-[400] mt-0.5' >Created At : {moment(d?.createdAt).format('LLL')}</h6>

                </div>))}

            </div>}

            {selected_stage?.label === 'Walk Ins' &&
            <div className="grid  grid-cols-5">
                    {data?.datas?.map((d)=>(
                    <div key={d?._id} className='border px-1.5 py-1 mt-1 relative mx-1 relative cursor-pointer' >
                        <Tooltip title="Edit"><span className='absolute bg-white p-1 right-0  top-0'><AiOutlineEdit size={12} onClick={()=>{gotoedit(d)}} /></span></Tooltip>
                        <div className='mt-1'>
                        <div className='bg-teal-100 p-2'>
                        {d?.contact_name !== '' && <h6 className='text-[11px]' >Name : <span className='font-[600] text-[12px]'>{d?.contact_name} / {d?.phone}</span> </h6>}
                        {d?.email !== '' && <h6 className='text-[11px]' >Email : <span className='font-[600] text-[12px]'>{d?.email}</span> </h6>}

                        <h6 className='text-[11px] font-[600]'>Company Info : </h6>
                            {d?.company_name !=='' &&  <h6 className='text-[11px]' ><span className='font-[500] text-[12px]'>{d?.company_name}</span> </h6>}
                            {d?.address !=='' && <h6 className='text-[11px]' ><span className='font-[500] text-[12px]'>{d?.address}</span> </h6>}
                            {d?.requirment !=='' &&  <h6 className='text-[11px] font-[600]'>Remarks : </h6>}
                            {d?.requirment !=='' && <h6 className='text-[11px]' ><span className='font-[500] text-[12px]'>{d?.requirment}</span> </h6>}

                        </div> 
                        <h6 className='text-[10px] ml-1 font-[400] mt-0.5' >Created At : {moment(d?.createdAt).format('LLL')}</h6>
                        </div>
                </div>))}
            </div>}

            {selected_stage?.label === 'Property Visit' &&
            <div className="grid  grid-cols-5">
                    {data?.datas?.map((d)=>(
                    <div key={d?._id} className='border px-1.5 py-1 mt-1 relative mx-1 relative cursor-pointer' >
                        <Tooltip title="Edit"><span className='absolute bg-white p-1 right-0  top-0'><AiOutlineEdit size={12} onClick={()=>{gotoedit(d)}} /></span></Tooltip>
                        <div className='mt-1'>
                        <div className='bg-teal-100 p-2'>
                        {d?.contact_name !== '' && <h6 className='text-[11px]' >Name : <span className='font-[600] text-[12px]'>{d?.contact_name} / {d?.phone}</span> </h6>}
                        {d?.email !== '' && <h6 className='text-[11px]' >Email : <span className='font-[600] text-[12px]'>{d?.email}</span> </h6>}

                        <h6 className='text-[11px] font-[600]'>Company Info : </h6>
                            {d?.company_name !=='' &&  <h6 className='text-[11px]' ><span className='font-[500] text-[12px]'>{d?.company_name}</span> </h6>}
                            {d?.address !=='' && <h6 className='text-[11px]' ><span className='font-[500] text-[12px]'>{d?.address}</span> </h6>}
                            {d?.requirment !=='' &&  <h6 className='text-[11px] font-[600]'>Remarks : </h6>}
                            {d?.requirment !=='' && <h6 className='text-[11px]' ><span className='font-[500] text-[12px]'>{d?.requirment}</span> </h6>}

                        </div> 
                        <h6 className='text-[10px] ml-1 font-[400] mt-0.5' >Created At : {moment(d?.createdAt).format('LLL')}</h6>
                        </div>
                </div>))}
            </div>}

            {selected_stage?.label === 'Meetings' &&
            <div className="grid  grid-cols-5">
                    {data?.datas?.map((d)=>(
                    <div key={d?._id} className='border px-1.5 py-1 mt-1 relative mx-1 relative cursor-pointer'>
                        <Tooltip title="Edit"><span className='absolute bg-white p-1 right-0 top-0'><AiOutlineEdit size={12}  onClick={()=>{gotoedit(d)}} /></span></Tooltip>
                        <div className='mt-1'>
                        <div className='bg-rose-100 p-2'>
                        {d?.contact_name !=='' &&  <h6 className='text-[11px]' >Name : <span className='font-[600] text-[12px]'>{d?.contact_name} / {d?.phone}</span> </h6>}
                        {d?.email !=='' &&  <h6 className='text-[11px]' >Email : <span className='font-[600] text-[12px]'>{d?.email}</span> </h6>}

                        <h6 className='text-[11px] font-[600]'>Location : </h6>
                        {d?.address !=='' &&  <h6 className='text-[11px]' ><span className='font-[500] text-[12px]'>{d?.address}</span> </h6>}
                        {d?.requirment !=='' &&  <h6 className='text-[11px] font-[600]'>Remarks : </h6>}
                        {d?.requirment !=='' &&  <h6 className='text-[11px]' ><span className='font-[500] text-[12px]'>{d?.requirment}</span> </h6>}

                        </div> 
                        <h6 className='text-[10px] ml-1 font-[400] mt-0.5' >Stage : <span className='font-[700]'>{d?.stage?.name}</span></h6>
                        <h6 className='text-[10px] ml-1 font-[400] mt-0.5' >Created At : {moment(d?.createdAt).format('LLL')}</h6>
                        </div>
                </div>))}
            </div>}

            {selected_stage?.label === 'Scouting' &&
            <div className="grid  grid-cols-5">
                    {data?.datas?.map((d)=>(
                    <div key={d?._id} className='border px-1.5 py-1 mt-1 relative mx-1 relative cursor-pointer'>
                        <Tooltip title="Edit"><span className='absolute bg-white p-1 right-0 top-0'><AiOutlineEdit size={12}  onClick={()=>{gotoedit(d)}} /></span></Tooltip>
                        <div className='mt-1'>
                        <div className='bg-rose-100 p-2'>
                        {d?.contact_name !=='' &&  <h6 className='text-[11px]' >Name : <span className='font-[600] text-[12px]'>{d?.contact_name} / {d?.phone}</span> </h6>}

                        {d?.requirment !=='' &&  <h6 className='text-[11px] font-[600]'>Remarks : </h6>}
                        {d?.requirment !=='' &&  <h6 className='text-[11px]' ><span className='font-[500] text-[12px]'>{d?.requirment}</span> </h6>}

                        </div> 
                        <h6 className='text-[10px] ml-1 font-[400] mt-0.5' >Created At : {moment(d?.createdAt).format('LLL')}</h6>
                        </div>
                </div>))}
            </div>}

            {selected_stage?.label === 'Daily Tasks' &&
            <div className="grid  grid-cols-5 mt-3">
                {data?.datas?.map((d)=>(
                    <div  key={d?._id} className='border px-1.5 py-1 mt-1 relative mx-1 relative cursor-pointer' >
                        <div className='bg-yellow-100 p-2'>
                        <Tooltip title="Edit"><span className='absolute bg-white p-1 right-0  top-0'><AiOutlineEdit size={20} className='absolute bg-white p-1 right-1 top-0' onClick={()=>{gotoedit(d)}} /></span></Tooltip>
                        <h6 className='text-[11px] break-all' >Message : <span className='font-[600] text-[11px] line-clamp-2'>{d?.message}</span> </h6>
                        </div>
                        <h6 className='text-[10px] ml-1 font-[400] mt-0.5' >Created At : {moment(d?.createdAt).format('LLL')}</h6>

                </div>))}
            </div>
            }

          </div>}

          

          
          {data?.datas?.length === 0 &&
            <div className='grid place-items-center mt-20  items-center justify-center'>
            <img src={'https://fidecrmfiles.s3.amazonaws.com/NoDataFound1.png'} alt="no" className='w-40 h-40 object-contain' /> 
            <h6 className='font-bold text-[14px] -mt-10'>No data found</h6>
            <h6 className='font-[500] w-[70%] text-center text-slate-700 text-[12.5px] -mt-2'>Oops we couldn't find any data based on your current stage please add the data to see list here.</h6>
          </div>
          }



        


          </div>

        
      </div>
        </div>
        </div>
    </div>
    </div>
  )
}

export default TeamDailyTask