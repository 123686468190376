import React,{useState,useEffect} from 'react'
import { DatePicker,Modal } from 'antd';
import { IconButton, Tooltip, } from '@material-ui/core';
import {FiChevronRight,FiChevronLeft} from 'react-icons/fi';
import {  ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../components/button';
import AtsMenu from './AtsMenu';
import { useNavigate } from 'react-router-dom';
import { CreateAtsEmployeeToClientJobService, DeleteAtsEmployeeService, FilterAtsEmployeeService, GetAtsEmployeeService } from '../../services/Ats/AtsEmployeeServices';
import toast from 'react-hot-toast';
import {AiOutlineFilter,AiOutlineDelete,AiOutlineEdit,AiOutlineFilePdf, AiOutlineDownload, AiOutlineClose} from 'react-icons/ai';
import { RiBillLine } from 'react-icons/ri';
import {BiReset,BiCheckbox,BiCheckboxSquare} from 'react-icons/bi';
import { FilterAtsActiveobService, GetAtsActiveobService } from '../../services/Ats/AtsJobServices';
import { TextAreaInput1 } from '../../components/input';

function AtsEmployeeManagment() {

  const [search,setsearch] = useState({text:'',from_date:'',to_date:'',activate:false})
  const [page,setpage] = useState(1)
  const [page1,setpage1] = useState(1)
  const [search_text,setsearch_text] = useState('')
  const [selected_data,setselected_data] = useState({})
  const [selected_employee,setselected_employee] = useState('')
  const [search_job_data,setsearch_job_data] = useState('')
  const [error,seterror] = useState('')
  const [modal,setmodal] = useState(false)
  const [assignmodal,setassignmodal] = useState(false)
  const [detail_modal,setdetail_modal] = useState(false)
  const [search_modal,setsearch_modal] = useState(false)
  const [jobs,setjobs] = useState([])
  const [pagination_search,setpagination_search] = useState({})


  const navigate = useNavigate()

  const [data,setdata] = useState([])
  const [datas,setdatas] = useState({})

  // console.log("search_text kp : ",search_text)


  useEffect(()=>{
    if(search?.activate){
      filterdata(page)
    }else{
      getdata(page)
    }
  },[page])

  async function getdata(page){
    const response = await GetAtsEmployeeService({page:page})
    setdata(response.data)
  }

  async function filterdata(page=1){
    setsearch({...search,activate:true})
    const response = await FilterAtsEmployeeService({...search,page:page})
    setdata(response.data)
  }

  // console.log("data kp",data)
  async function resetdata(){
    setsearch({text:'',from_date:'',to_date:'',activate:false})
    getdata(1)
  }

  async function deleteDatafunc(){
    const response = await DeleteAtsEmployeeService(selected_data?._id)
    if(response?.status === 200){
      toast.success("Deleted Successfully")
      getdata(page)
      setmodal(false)
    }
  }

  async function getactivejobs(v){
    const response = await GetAtsActiveobService({page:v !== undefined ? v : page1})
    setjobs(response?.data)
    // setpagination_search(response)

  }

  // console.log("jobs",jobs)

  async function filteractivejobs(){
    const response = await FilterAtsActiveobService({text:search_text,from_date:'',to_date:'',page:1})
    setjobs(response?.data)
  }

//   async function searchClientJobs(e){
//     const response = await FilterAtsJobService({text:e,from_date:'',to_date:'',page:1})
//     let arr = []
//     let d = response?.data?.datas 
//     d?.forEach((d1)=>{
//        arr?.push({label:`[${d1?.job_title} : Company Name  : [${d1?.ats_hr_client?.company_name}] : Client Name : [${d1?.ats_hr_client?.client_name}]]`,value:d1?._id})
//     })
//     setjobs(arr)
//  }

  async function assigncandidatetojob(){
    // if(search_job_data === ''){
    //   seterror("Job field is required")
    // }else{
      let obj={
        ats_hr_client_job:selected_data?._id,
        ats_hr_employee:selected_employee?._id
      }
      const response = await CreateAtsEmployeeToClientJobService(obj)
      if(response?.status === 200){
        toast.success("Candidate Allocated Successfully")
        setassignmodal(false)
        setselected_data({})
        getdata(page)

      }
    // }
  }


  return (
    <div className='w-[98%]'>
      <Modal open={modal} width={320} closable={false} footer={false}>
          <div> 

            <h6 className='text-[15px] font-[700]'>Delete Data</h6>
            <h6 className='text-[11px] leading-tight bg-gray-100 p-2' >Before Deleting the data be sure that this candidate details is not required for further purpose.</h6>
             
             <h6 className='mb-2 mt-2 text-[11px]'>Candidate Name : <span className='font-[800]'>{selected_data?.employee_name}</span> Designation : <span className='font-[800]'>{selected_data?.designation}</span> With Expierence : <span className='font-[800]'>{selected_data?.experience} Years</span> </h6>
             <div className='flex items-center border-t pt-2'>
                <ButtonOutlinedAutoWidth btnName="Cancel" onClick={()=>setmodal(false)} />
                <h6 className='w-[10px]'></h6>
                <ButtonFilledAutoWidth btnName="Sure" onClick={deleteDatafunc} />
             </div>
          </div>
      </Modal>

      <Modal open={detail_modal} width={'32%'} height={300} closable={false} footer={false}>
        <div className='relative h-[400px]'>

        <AiOutlineClose className='absolute right-0 top-0 cursor-pointer' onClick={()=>setdetail_modal(false)} />

          <div className='flex absolute top-0'>
          {(selected_data?.user_photo !== undefined && selected_data?.user_photo !== null) ? <img className='w-[40px] h-[40px] rounded-full border-2 border-slate-100 overflow-hidden object-contain' src={`${process.env.REACT_APP_BACKEND_IMAGE_URL}${selected_data?.user_photo}`} /> : <span className='bg-gray-100 w-5 h-5 ml-[6px] flex items-center justify-center  text-[10px] rounded overflow-hidden uppercase font-[700]'>{selected_data?.employee_name?.slice(0,2)}</span>}
            <div className='ml-2'>
            <h6 className='font-[700] flex items-center'>{selected_data?.employee_name}  <a href={`${process.env.REACT_APP_BACKEND_IMAGE_URL}${selected_data?.resume}`} target='_blank'>   <span className='flex items-center ml-2 text-[12px] font-[400]  cursor-pointer hover:underline'> <AiOutlineFilePdf className='mr-1 text-slate-600'/> Resume</span> </a></h6>
            <div className='flex items-center mt-[-1px]'>
            <span onClick={()=>navigate('edit',{state:{data:selected_data}})} className='flex items-center  cursor-pointer hover:underline'><AiOutlineEdit className='text-slate-600' /> <span className='ml-2 text-[11px]'> Edit Profile</span></span>
            <a href={`${process.env.REACT_APP_BACKEND_IMAGE_URL}${selected_data?.resume}`} download>  <span className='flex ml-4 items-center cursor-pointer hover:underline'><AiOutlineDownload className='text-slate-600' /><span className='ml-2 text-[11px]'> Download Resume</span></span> </a>
            </div>
            </div>
          </div>   

          <div className='pt-[50px]'>
            {/* <h6 className='font-[700] text-[14px]'>Basic Info</h6> */}

            <div className='flex border'>
               <div className='w-[33%] p-2 border-r border-black-200'>
                 <h6 className='uppercase text-[11px] text-gray-600 mb-1'>Mobile number</h6>
                 <h6 className='text-[11.5px] font-[600]'>+91 {selected_data?.mobile_no}</h6>
               </div>
               <div className='w-[33%] p-2 border-r border-black-200'>
                 <h6 className='uppercase text-[11px] text-gray-600 mb-1'>Email</h6>
                 <h6 className='text-[11.5px] font-[600]'>{selected_data?.email_id}</h6>
               </div>
               <div className='w-[33%] p-2 '>
                 <h6 className='uppercase text-[11px] text-gray-600 mb-1'>Experience</h6>
                 <h6 className='text-[11.5px] font-[600]'>{selected_data?.experience} YEARS</h6>
               </div>
            </div>

            <div className='pt-4 border-slate-100'>
              
                 <h6 className='uppercase text-[11px] text-gray-600'>Skills</h6>
                 <h6 className='text-[11.5px] font-[600]'>{selected_data?.skills}</h6>
              
                 <h6 className='uppercase text-[11px] mt-2 text-gray-600'>Designation</h6>
                 <h6 className='text-[11.5px] font-[600]'>{selected_data?.designation}</h6>

                 <h6 className='uppercase text-[11px] mt-2 text-gray-600'>Preferred Location</h6>
                 <h6 className='text-[11.5px] font-[600]'>{selected_data?.preferred_location}</h6>

                 <h6 className='uppercase text-[11px] mt-2 text-gray-600'>Agency Comment</h6>
                 <h6 className='text-[11.5px] font-[600]'>{selected_data?.agency_comment}</h6>

              
            </div>

            <div className='flex border mt-4'>
               <div className='w-[33%] border-r border-black-200'>
                 <h6 className='uppercase p-2 text-[11px] bg-slate-100 '>current ctc </h6>
                 <h6 className='text-[11.5px] p-2 font-[600]'>{selected_data?.current_ctc} LPA</h6>
               </div>
               <div className='w-[33%] border-r border-black-200'>
                 <h6 className='uppercase  p-2 text-[11px] bg-slate-100 '>expected ctc</h6>
                 <h6 className='text-[11.5px] p-2 font-[600]'>{selected_data?.expected_ctc} LPA</h6>
               </div>
               <div className='w-[33%]  border-r border-black-200'>
                 <h6 className='uppercase  p-2 text-[11px] bg-slate-100 '>cURRENT coMPANY</h6>
                 <h6 className='text-[11.5px]  p-2 font-[600]'>{selected_data?.company_name}</h6>
               </div>
               <div className='w-[33%]  border-r border-black-200'>
                 <h6 className='uppercase  p-2 text-[11px] bg-slate-100 '>Notice Period</h6>
                 <h6 className='text-[11.5px]  p-2 font-[600]'>{selected_data?.notice_period} DAYS</h6>
               </div>
               <div className='w-[33%] '>
                 <h6 className='uppercase  p-2 text-[11px] bg-slate-100 '>TOTAL Expierence</h6>
                 <h6 className='text-[11.5px]  p-2 font-[600]'>{selected_data?.experience} YEARS</h6>
               </div>
            </div>
          </div>

        </div>

      </Modal>

      <Modal open={search_modal} width={'75%'} className='absolute top-0 left-[12.5%]' closable={false} footer={false}>
        <div> 
            <div className='flex justify-between'>
            <h6 className='text-[13px] font-[700]'>Select Job Description <span className='font-[400] text-[12px]'>( Based on the client )</span> </h6>

             <div className='flex items-center'>
              <input type='text' placeholder='search' value={search_text} onChange={(e)=>setsearch_text(e.target.value)} className='border-[#d0d7de] text-[11px] focus:ring-0 outline-none border-b  px-2 w-full  text-[400] text-[12px] focus:bg-white mr-2' />
             <div className='mr-2'>
                  <AiOutlineFilter onClick={()=>filteractivejobs(1)} size={20} className='border border-slate-600 bg-slate-600 text-[#dedede] p-1 rounded' />
              </div>
              <div className='mr-2'>
                  <BiReset size={20} onClick={()=>{getactivejobs(1);setsearch_text('');setselected_data({})}} className='border border-slate-300 bg-[#f6f8fa] text-[#5c5c5c] p-1 rounded' />
              </div>
              <div>
                  <AiOutlineClose size={20} onClick={()=>setsearch_modal(!search_modal)} className='border border-slate-300 bg-[#f6f8fa] text-[#5c5c5c] p-1 rounded' />
              </div>
             </div> 
            </div>

            <div className='max-h-[85vh]  mt-1.5 border-t   border-l border-r overflow-y-scroll'>
                <div className='sticky top-0 z-50 flex bg-gray-50  py-[-5px] border-b border-slate-200 relative'>
                    <h6  className='top-0  z-50 text-[12px] w-[33px]  px-2 py-1 font-[600] text-slate-600 border-r flex items-center justify-center'></h6>
                    <h6 className='sticky top-0 z-50  text-[12px] w-[180px]  px-2 py-1 font-[600] text-slate-600 border-r'>Job Title</h6>
                    <h6 className='sticky top-0 z-50  text-[12px] w-[180px]  px-2 py-1 font-[600] text-slate-600 border-r'> Client Name</h6>
                    <h6 className='sticky top-0 z-50  text-[12px] w-[75px]  px-2 py-1 font-[600] text-slate-600 border-r'> Openings</h6>
                    <h6 className='sticky top-0 z-50  text-[12px] w-[180px]  px-2 py-1 font-[600] text-slate-600 border-r'> Location</h6>
                    <h6 className='sticky top-0 z-50  text-[12px] w-[170px]  px-2 py-1 font-[600] text-slate-600 border-r'> Experience</h6>
                    <h6 className='sticky top-0 z-50  text-[12px] w-[170px]  px-2 py-1 font-[600] text-slate-600 border-r'> Package </h6>
                    <h6 className='sticky top-0 z-50  text-[12px] w-[130px]  px-2 py-1 font-[600] text-slate-600 sticky right-0'>Priority</h6>
                </div>

                {jobs?.datas?.map((d,i)=>(
                <div key={i} className='z-50 flex hover:bg-gray-50 cursor-pointer bg-white py-[-5px] border-b border-slate-200 relative'>
                    <h6  className='z-50 text-[12px] w-[33px]  px-2  font-[600] truncate border-r flex items-center justify-center'>
                    {selected_data?._id === d?._id ?
                         <BiCheckboxSquare onClick={()=>setselected_data({})} size={24} className='text-slate-700' />:
                         <BiCheckbox onClick={()=>{setselected_data(d);setsearch_modal(!search_modal)}} size={24} className='text-gray-300' />} </h6>
                    <h6 className='z-50  text-[10px] w-[180px]  px-2 py-1 font-[600] truncate border-r'>{d?.job_title}</h6>
                    <h6 className='z-50  text-[10px] w-[180px]  px-2 py-1 font-[600] truncate border-r'>{d?.ats_hr_client?.client_name}</h6>
                    <h6 className='z-50  text-[10px] w-[75px]  px-2 py-1 font-[600] truncate border-r'> {d?.no_of_opening}</h6>
                    <h6 className='z-50  text-[10px] w-[180px]  px-2 py-1 font-[600] truncate border-r'> {d?.location?.split(":")?.map((l,i)=><span key={i} className='bg-slate-100 p-[2px] text-[10px] mr-1'>{l}</span>)}</h6>
                    <h6 className='z-50  text-[10px] w-[170px]  px-2 py-1 font-[600] truncate border-r'> {d?.experience}</h6>
                    <h6 className='z-50  text-[10px] w-[170px]  px-2 py-1 font-[600] truncate border-r'> {d?.cutoff_salary} </h6>
                    <h6 className='z-50  text-[10px] w-[130px]  px-2 py-1 font-[600] truncate  right-0'>Priority</h6>
                </div>))}

            </div>    
             
             
        </div>    
      </Modal>

      <Modal open={assignmodal} width={340} className='absolute top-0 left-[40%]' closable={false} footer={false}>
          <div> 
          <h6 className='text-[15px] font-[700]'>Assign Candidate</h6>
          <h6 className='text-[11px] leading-tight bg-gray-100 p-2' >Before Assign the candidate be sure that this candidate has all the skills for the job role.</h6>
           
          <h6 className='mb-2 mt-2 text-[11px]'>Candidate Name : <span className='font-[800]'>{selected_employee?.employee_name}</span> Designation : <span className='font-[800]'>{selected_employee?.designation}</span> With Expierence : <span className='font-[800]'>{selected_employee?.experience} Years</span> </h6>

          <div className='mr-4 w-[100%]'>
              <h6 className='text-[11px] font-[600] mb-1' >Search Job Opening *</h6>
              {selected_data?._id === undefined ? 
              <h6 onClick={()=>{setsearch_modal(!search_modal);getactivejobs()}} className='w-[100%]  h-[35px] border border-slate-300 w-[100%]'></h6>:
              <h6 onClick={()=>{setsearch_modal(!search_modal);getactivejobs()}} className='w-[100%]  min-h-[35px] text-[12px] p-[4px] px-[10px] leading-tight border border-slate-300 w-[100%]'>Company : {selected_data?.ats_hr_client?.company_name} / {selected_data?.job_title} </h6>}
            


                <TextAreaInput1 label={'Summary *'} />
          </div>      


          <div className='flex items-center border-t pt-2 mt-3'>
              <ButtonOutlinedAutoWidth btnName="Cancel" onClick={()=>setassignmodal(false)} />
              <h6 className='w-[10px]'></h6>
              <ButtonFilledAutoWidth btnName="Assign" onClick={assigncandidatetojob} />
          </div>
          </div>
      </Modal>

       <div className='flex'>
            <div className='min-w-44'>
            <AtsMenu />
            </div>
            <div className='w-[100%] pl-5 mt-4'>
              <div className='flex items-center -mt-1 border-b justify-between pb-1'>

              <h6 className='font-[800] text-[13px]'>Total Candidate Managment   ({data?.pagination?.total})</h6>
                      
                      <div className='flex items-center text-[12px]'>
                      <div className='flex items-center'>
                      <h6 className='mr-2 font-[600]'>{page == 1 ? data?.datas?.length > 0 ? 1 : 0 : (page - 1) * data?.pagination?.limit } - {data?.pagination?.limit} of {data?.pagination?.total} </h6>

                      <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>
                      <IconButton onClick={()=>{ page < data?.pagination?.totalPages  ? setpage(page+1) : console.log('')}}><FiChevronRight className={`${(data?.pagination?.totalPages === page || data?.datas?.length === 0) ? 'opacity-50 ' : 'opacity-100'}`}  size={16} /></IconButton>

                      </div>
                      <DatePicker  size='small' style={{fontSize:'11px'}} ampm={false} placeholder='From Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.from_date} onChange={(v) => {console.log('v na',new Date(v).toLocaleDateString());setsearch({...search,from_date:v})}} /> 

                      <DatePicker size='small' style={{fontSize:'11px'}} ampm={false} placeholder='To Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.to_date} onChange={(v) => {console.log('v na',new Date(v).toLocaleDateString());setsearch({...search,to_date:v})}} /> 

                      
                      <div className='mr-2'>
                          

                      <input  id="search_text" placeholder='Search text' type='text' value={search?.text} onChange={(e)=>setsearch({...search,text:e.target.value})} className='border py-[3px]  focus:ring-0 !outline-none  focus:ring-offset-0 focus:shadow-none text-[14px]  w-[100px] px-1 rounded-md border-slate-300' />
                  

                      </div>  
                      <div className='mr-2 flex'>
                      <div className='mr-2'>
                        <AiOutlineFilter onClick={()=>filterdata(1)} size={24} className='border border-slate-600 bg-slate-600 text-[#dedede] p-1 rounded' />
                      </div>
                      <div>
                      <BiReset size={24} onClick={resetdata} className='border border-slate-300 bg-[#f6f8fa] text-[#5c5c5c] p-1 rounded' />
                      </div>
                      </div>
                      <ButtonFilledAutoWidth btnName="Add New" onClick={()=>{navigate('create')}} />
                      {selected_employee?._id !== undefined &&
                      <div className='ml-2'>
                      <ButtonFilledAutoWidth btnName="Assign" onClick={()=>{setassignmodal(!assignmodal)}} />
                      </div>}
                      </div>
              </div>

              {data?.datas?.length > 0 &&
              <div className='max-h-[85vh] mt-4 border-t   border-l border-r overflow-y-scroll'>
                <div className='sticky top-0 z-50 flex  bg-white py-[-5px] border-b border-slate-200 relative'>
                    <h6  className='top-0 bg-white z-50 text-[12px] w-[40px]  px-2 py-1 font-[600] text-slate-600 border-r flex items-center justify-center'></h6>
                    <h6 className='sticky top-0 z-50  text-[12px] w-[60px]  px-2 py-1 font-[600] text-slate-600 border-r'>Photo</h6>
                    <h6 className='sticky top-0 z-50  text-[12px] w-[130px]  px-2 py-1 font-[600] text-slate-600 border-r'> Name</h6>
                    <h6 className='sticky top-0 z-50  text-[12px] w-[80px]  px-2 py-1 font-[600] text-slate-600 border-r'> Experience</h6>
                    <h6 className='sticky top-0 z-50  text-[12px] w-[120px]  px-2 py-1 font-[600] text-slate-600 border-r'> Mobile</h6>
                    <h6 className='sticky top-0 z-50  text-[12px] w-[170px]  px-2 py-1 font-[600] text-slate-600 border-r'> Email</h6>
                    <h6 className='sticky top-0 z-50  text-[12px] w-[100px] px-2 py-1 font-[600] text-slate-600 border-r'>Current CTC</h6>
                    <h6 className='sticky top-0 z-50  text-[12px] w-[110px] px-2 py-1 font-[600] text-slate-600 border-r'>Expected CTC </h6>
                    <h6 className='sticky top-0 z-50  text-[12px] w-[240px] px-2 py-1 font-[600] text-slate-600 border-r'>Assigned To</h6>
                    <h6 className='sticky top-0 z-50  text-[12px] w-[30px]  px-2 py-1 font-[600] text-slate-600 sticky right-0'>Actions</h6>
                </div>
              
                {data?.datas?.map((d,i)=>(
                <div  key={d?._id} className={`flex z-50 border-b border-slate-200 z-0`}  >
                    <h6 onClick={()=>selected_employee?._id === d?._id ? setselected_employee('') : setselected_employee(d)} className='z-30 text-[12px] w-[40px]  px-2 pt-0.5 font-[600] text-slate-600 cursor-pointer border-r text-center'>{(selected_employee?._id !== d?._id) ? <BiCheckbox size={20} className='text-gray-300' /> : <BiCheckboxSquare size={20} className='text-slate-700' />} </h6>
                    <h6 className='text-[11.5px] relative font-[500] w-[60px]  px-2 py-1 truncate border-r  border-slate-200'>{(d?.user_photo !== undefined && d?.user_photo !== null) ? <img className='w-8 h-4 rounded overflow-hidden object-contain' onError={({currentTarget})=> {currentTarget.src = 'https://fidecrmfiles.s3.amazonaws.com/noprofile.png'}}  src={`${process.env.REACT_APP_BACKEND_IMAGE_URL}${d?.user_photo}`} /> : <span className='bg-gray-100 w-5 h-5 ml-[6px] flex items-center justify-center  text-[10px] rounded overflow-hidden uppercase font-[700]'>{d?.employee_name?.slice(0,2)}</span>}  </h6>
                    <h6 className='text-[11.5px] font-[500] w-[130px]  px-2 py-1 truncate border-r  border-slate-200'>{d?.employee_name}</h6>
                    <h6 className='text-[11.5px] font-[500] w-[80px]  px-2 py-1 truncate border-r  border-slate-200'>{d?.experience}</h6>
                    <h6 className='text-[11.5px] font-[500] w-[120px] px-2 py-1 truncate border-r  border-slate-200'>{d?.mobile_no}</h6>   
                    <h6 className='text-[11.5px] font-[500] w-[170px] px-2 py-1 truncate border-r  border-slate-200'>{d?.email_id}</h6>
                    <h6 className='text-[11.5px] font-[500] w-[100px]  px-2 py-1 truncate border-r  border-slate-200 sticky right-0'>{d?.current_ctc} LPA</h6>
                    <h6 className='text-[11.5px] font-[500] w-[110px]  px-2 py-1 truncate border-r  border-slate-200 sticky right-0'>{d?.expected_ctc} LPA</h6>
                    <h6 className='text-[11.5px] font-[500]  w-[240px] flex flex-wrap  px-2 py-1  border-r  border-slate-200'>
                      {d?.atshrjobsassignemployees?.map((d1,i)=>(
                      <span key={i} className='text-center mb-1 mr-1 items-center text-[8px] bg-slate-100 cursor-pointer rounded-[4px] border px-[5px] p-[2px] font-[700] uppercase'>{d1?.ats_hr_client_job?.ats_hr_client?.company_name} </span>
                      ))}

                    </h6>
                    <h6  className='text-[12px] w-[30px]  px-2 py-1 mt-1 flex'>
                    
                     
                      <Tooltip title="Detail">
                      <span><RiBillLine size={13} className="z-10 ml-0" onClick={()=>{setselected_data(d);setdetail_modal(true)}}/></span>
                      </Tooltip>


                      <Tooltip title="Edit">
                      <span><AiOutlineEdit size={13} className="z-10 ml-2" onClick={()=>{navigate('edit',{state:{data:d}})}}/></span>
                      </Tooltip>

                      <Tooltip title="Delete">
                      <span><AiOutlineDelete size={13} className="z-10 ml-2 -mt-[1px]" onClick={()=>{setselected_data(d);setmodal(true)}}/></span>
                      </Tooltip>

                      {/* <Tooltip title="Assign">
                      <span><MdOutlineAssignmentReturn size={13} className="z-10 ml-2 -mt-[1px]" onClick={()=>{setselected_employee(d);setassignmodal(true)}}/></span>
                      </Tooltip> */}
        

                      

                    
                    </h6>
                </div>))}
              </div>}  

            </div>
        </div>    
    </div>
  )
}

export default AtsEmployeeManagment