import axios from "axios";
import { institueUrl } from "../constants/dataConstants";

export const LoginInstituteService=async(key)=>{
    try {
        const result = await axios.post(`${institueUrl}api/external/auth`,{},{auth:{username:'key',password:key}});
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetInstituteEmployeeService=async(headers)=>{
    try {
        const result = await axios.get(`${institueUrl}api/external/employee`,headers);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetInstituteProgressService=async(page,headers)=>{
    try {
        const result = await axios.get(`${institueUrl}api/external/institute/stats/${page-1}`,headers);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetInstituteEmployeeProgressService=async(id,headers)=>{
    try {
        const result = await axios.get(`${institueUrl}api/external/employee/stats?employeeId=${id}`,headers);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetCourseListService=async(headers)=>{
    try {
        const result = await axios.get(`${institueUrl}api/external/courses`,headers);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetSuperVisorListService=async(headers)=>{
    try {
        const result = await axios.get(`${institueUrl}api/external/supervisor`,headers);
        return result;
    } catch (err) {
        return err.response;
    }
}


export const CreateInductionUserListService=async(data,headers)=>{
    try {
        const result = await axios.post(`${institueUrl}api/external/employee`,data,headers);
        return result;
    } catch (err) {
        return err.response;
    }
}
