import React, { useState } from 'react'
import DashboardMenu from './DashboardMenu'
import { Select } from 'antd'
import { IconButton } from "@mui/material";
import {BsArrowRepeat} from 'react-icons/bs';
import {FiChevronRight,FiChevronLeft} from 'react-icons/fi';
import { instituteArr } from '../../constants/dataConstants';
import  {GetCourseListService, LoginInstituteService } from '../../services/InductionServices';


function CoursesList() {

  const [selected_department,setselected_department] = useState(null)

  const [token,settoken] = useState('')
  const [page,setpage] = useState(1)
  const [datas,setdatas] = useState([])
 
  async function getToken(e) {
     const response = await LoginInstituteService(e)
     settoken(response?.data?.token)
     getCoursesprogress(response?.data?.token)
  }

  async function handlechange(e,name) {
    if(name === 'department'){
      getToken(e)
      setselected_department(instituteArr?.find((f)=>f?.value == e))
    }
  }

  async function getCoursesprogress(token){
    const header = `Bearer ${token}`;
    let headers = { "headers": {Authorization: header }}
    const response = await GetCourseListService(headers)
    setdatas(response?.data)
  }

  async function resetfilter() {
    setselected_department(null)
  }   

  return (
    <div className='flex max-h-screen min-h-screen  '>
     


      <DashboardMenu />
      <div className='mx-5 mt-5 w-[85%] mb-10'>

        <div>
            <div className='flex items-center justify-between border-b pb-2 mb-5'>
              <h6 className='font-[700] text-[14px] '>Courses List</h6>

              <div className='flex items-center  justify-end' >
                    <div className='flex items-center  text-[12px] mr-2'>
                      <IconButton  onClick={resetfilter} ><BsArrowRepeat size={16} /></IconButton>
                      <div>
                      <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>
                      <IconButton onClick={()=>{ page < datas?.pagination?.totalPages  ? setpage(page+1) : console.log('')}} ><FiChevronRight className={`${(datas?.pagination?.totalPages === page || datas?.datas?.length === 0) ? 'opacity-50' : 'opacity-100'}`} size={16} /></IconButton>
                      </div>
                   </div>
                   <div className='border border-slate-300 rounded-md py-[2px] mr-1'>
                    <Select
                        placeholder='Assigned Department'
                        bordered={false}
                        allowClear={true}
                        onClear={()=>setselected_department(null)}
                        size='small'
                        defaultValue={selected_department}
                        value={selected_department}
                        style={{ width: 110 }}
                        onChange={(v)=>handlechange(v,'department')}
                        options={instituteArr}
                    />
                </div>
              </div>
            </div>
        </div>  

       
        {(datas?.length > 0 && selected_department !== null) &&
        <div className='grid grid-cols-5 gap-5'>
          
            
            {datas?.map((d,i)=>(
            <div className=' py-[-5px] border bg-white border-slate-200 relative'>
              <h6 className='text-[12.5px] font-[700] px-2 py-1'>{d?.courseName}</h6>
              <h6 className='text-[11px] px-2 border-t border-slate-100 py-1'>{d?.courseCertificate}</h6>
            </div>))}
        </div>}
         
      
         
      </div>

    </div>
  )
}

export default CoursesList