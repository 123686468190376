import React, { useEffect, useState } from 'react'
import { getInspectionReviewDetail, updateInspectionReview } from '../../services/LeadServices'
import { useNavigate, useParams } from 'react-router-dom'
import { TextAreaInput1, TextInput } from '../../components/input'
import {Rate} from 'antd'
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../components/button'
import toast from 'react-hot-toast'

function LeadInspectionReviewParamsDetail() {

  const {id} = useParams()  
  const navigate = useNavigate()
  const [data,setdata] = useState({})
  const [error,seterror] = useState({})

  useEffect(()=>{
    getData(id)
  },[])

  async function getData(state){
    const response = await getInspectionReviewDetail(state)
    setdata(response?.data?.data[0])
  }


  function handlechange(e){
    setdata({...data,[e.target.name] : e.target.value})
    seterror({...error,[e.target.name] : ''})
  }


  async function submitform(){
    if(!data.question1){
        seterror({...error,question1:'This Field is required*'})
    }else if(!data.question2){
        seterror({...error,question2:'This Field is required*'})
    }else if(!data.question3){
        seterror({...error,question3:'This Field is required*'})
    }else if(!data.ratings){
        seterror({...error,ratings:'This Field is required*'})
    }else{
        let send_data = {...data}
        send_data['form_submitted'] = true
        delete send_data?.lead
        delete send_data?.leadFollowup
       const response = await updateInspectionReview(data?._id,send_data)
       if(response?.status === 200){
        toast.success("Inspection Review Submitted Successfully")
        navigate(-1)
        getData()
       }
    }
  }

  
  
  return (
    <div className="h-screen max-h-screen w-screen overflow-hidden max-h-screen">
        <div className='flex mt-10 justify-center'>
            <div className='px-4 h-screen no-scrollbar overflow-y-scroll w-[100%] h-[80vh] md:w-[300px] mt-5'>
              <h6 className='text-[14px] font-[700]'>Lead InspectionReview Detail</h6>
              <h6 className='text-[11px] leading-[14px] bg-slate-100 p-1 mt-2'>Use the below form to complete the lead inspection review detail</h6>


              <div>
                <TextInput 
                    readOnly={true}
                    label={'Property Name'}
                    value={data?.property_name}
                />

                <TextAreaInput1 
                    readOnly={true}
                    label={'Property Address'}
                    value={data?.property_address}
                />

                <TextInput 
                    readOnly={true}
                    label={'Client Name'}
                    value={data?.lead?.contact_name}
                />

                <TextInput 
                    readOnly={true}
                    label={'Contact Name'}
                    value={data?.lead?.company_name}
                />

                <TextInput 
                    label={'How well have I been following through on the things I’ve promised?'}
                    name="question1"
                    readOnly={data?.form_submitted}
                    value={data?.question1}
                    error={error?.question1}
                    handlechange={handlechange}
                />

                <TextInput 
                    label={'How is the quality of my work output?'}
                    name="question2"
                    readOnly={data?.form_submitted}
                    value={data?.question2}
                    error={error?.question2}
                    handlechange={handlechange}
                />

                <TextInput 
                    label={'What are the things I’ve done that were particularly helpful or unhelpful?'}
                    name="question3"
                    readOnly={data?.form_submitted}
                    value={data?.question3}
                    error={error?.question3}
                    handlechange={handlechange}
                />
              
                <h6 className='text-[11px] mt-2 font-[600] mb-1' >Rating</h6>
                {data?.form_submitted ? 
                <Rate value={data?.ratings} className='-mt-2' />
                :
                <Rate value={data?.ratings} className='-mt-2' onChange={(e)=>{setdata({...data,ratings:e});seterror({...error,ratings:''})}} />
                 } 

                {data?.form_submitted &&
                  <h6 className='text-[11.5px] p-1 bg-green-200 my-4 leading-[16px]'>Thanks for completing the feedback survey form . we will try to improve ourself with the feedback provided by you. </h6> 
                 }
                {!data?.form_submitted &&
                <div className='mt-2 pt-2 border-t flex  mb-10 justify-end'>
                <div className='mr-2'>
                <ButtonOutlinedAutoWidth  btnName="Cancel" onClick={()=>navigate(-1)}/>
                </div>
                <ButtonFilledAutoWidth  btnName="Save" onClick={submitform}/>
                </div> }           


              </div>
            </div>
        </div>
    </div>
  )
}

export default LeadInspectionReviewParamsDetail