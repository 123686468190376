import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { GetTeamTargetService } from '../../../services/AdminServicesfile/TeamServices'
import GoBack from '../../../components/back/GoBack'
import SettingsMenu from '../../staticscreens/SettingsMenu'

function TeamTarget() {
  
  const {state} = useLocation()
  const [data,setdata] = useState({})
  const [target,settarget] = useState(0)
  const [achieved,setachieved] = useState(0)

  useEffect(()=>{
    getTeamTarget()
  },[])

  async function getTeamTarget(){
   const response = await GetTeamTargetService(state)
   setdata(response?.data?.datas[0])
//    console.log("response",response)
  }

//   console.log("state",state)
  return (
    <div className='min-h-screen max-h-screen overflow-hidden'>

        <div className='flex'>
          <SettingsMenu />
        <div className='px-2 pt-5'>
        <GoBack />
        <div className='flex mt-5 border-b pb-2 justify-between  border-b'>
        <h6 className='font-[900] '>Team Target</h6>
        </div>


       <div className='flex flex-row mt-5'>

        <div className='  p-2'>
            <h6 className='text-[13px] pl-1'>Team Name : {data?.name} </h6>
            <h6 className='text-[13px]  pl-1'>Department  : {data?.department?.department_name} </h6>
            <h6 className='text-[13px] font-[400] pl-1'>Team Lead  : {data?.team_lead?.name} </h6>
            <h6  className='text-[13px] font-[600]  pl-1'>Target  : {target} / Achieved  : {achieved} </h6>

        </div>

        <div className='ml-1 border-l pl-5 -mt-3'>
        <h6 className=' mt-4 text-[13px] '>Team Members</h6>   

        {data?.users?.map((d)=>(
        <div>
          <h6 className='text-[11px] font-[600]'>{d?.name} </h6>
          <h6  className='text-[13px] font-[400]'>Target  : {(data?.user_target?.find((t)=>t?.user === d?._id) !== null && data?.user_target?.find((t)=>t.user === d?._id) !== undefined) ?  data?.user_target?.find((t)=>t.user === d?._id)?.target : 'Not Added' } / Achieved  :  {(data?.user_target?.find((t)=>t.user === d?._id) !== null && data?.user_target?.find((t)=>t?.user === d?._id) !== undefined) ?  data?.user_target?.find((t)=>t?.user === d?._id)?.target_achieved : 'Not Added' }  </h6>
        </div> 
        ))}
        </div>

        </div> 
        </div> 
        </div>

    </div>    
  )
}

export default TeamTarget