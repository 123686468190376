import { deleteRequest, get, post, put } from "../helpers/apihelpers";

export const CreateReminderService=async(data)=>{
    try {
        const result = await post(`api/reminder/create`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateReminderService=async(data,id)=>{
    try {
        const result = await put(`api/reminder/update/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetReminderService=async(type,type_id,page)=>{
    try {
        const result = await get(`api/reminder/get?page=${page}&type=${type}&type_id=${type_id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteReminderService=async(id)=>{
    try {
        const result = await deleteRequest(`api/reminder/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}
