import {  deleteRequest, get, post, put, } from "../../helpers/apihelpers";

export const CreateReportingService=async(data)=>{
    try {
        const result = await post(`api/reporting/create`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetReportingService=async(page)=>{
    try {
        const result = await get(`api/reporting/get?page=${page}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetCurrentYearReportingService=async(id,insentive,page)=>{
    try {
        const result = await get(`api/reporting/get_team_based_on_department/${id}/null/?page=${page}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetReportingBasedOnYearInsentiveService=async(id)=>{
    try {
        const result = await get(`api/reporting/get_team_based_on_department/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}



export const GetReportingTargetService=async(id)=>{
    try {
        const result = await get(`api/reporting/get_team_target/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetReportingServiceDetail=async(id)=>{
    try {
        const result = await get(`api/reporting/get/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetReportingMembersDetail=async(id,year)=>{
    try {
        const result = await get(`api/reporting/get_team_members/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}


export const GetReportingMembersList=async(id)=>{
    try {
        const result = await get(`api/reporting/get_team_target/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}



export const UpdateReportingService=async(data,id)=>{
    try {
        const result = await put(`api/reporting/update/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteReportingService=async(id)=>{
    try {
        const result = await deleteRequest(`api/reporting/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

