import React,{useState,useEffect} from 'react'
import {Tooltip} from '@material-ui/core';
import { DeleteCountryService, DeleteDistrictService, DeleteStateService, DeleteTalukService, GetCountry, GetDistrict, GetState, GetTaluk } from '../../../services/CSDTServices';
import { useNavigate } from 'react-router-dom';
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../../components/button';
import { toast } from 'react-hot-toast';
import SettingsMenu from '../../staticscreens/SettingsMenu';
import {AiOutlineEdit,AiOutlineDelete} from 'react-icons/ai'
import { Modal } from 'antd';
import moment from 'moment';

function CSDTList() {

  const navigate = useNavigate()
  const [data,setdata] = useState([])
  const [selecteddata,setselecteddata] = useState({})
  const [modal, setModal] = useState(false);
  const [value,setvalue] = useState(0)

  const type = ['Country','State','District','Taluk']


  useEffect(()=>{
    getdata()
  },[value])

  function handleChange(e,v){
    setvalue(v)
  }
 
  async function getdata(){
      if(value === 0){
        const response = await GetCountry()
        setdata(response.data.data)
      }else if(value === 1){
        const response = await GetState()
        setdata(response.data.data)
      }else if(value === 2){
        const response = await GetDistrict()
        setdata(response.data.data)
      }else if(value === 3){
        const response = await GetTaluk()
        setdata(response.data.data)
      }
  }


  async function deletedata(){
       if(value === 0){
        const response  = await DeleteCountryService(selecteddata?._id)
        if(response.status === 201){
          setModal(false)
          toast.success(response?.data?.message)
          getdata()
        }
       }else if(value === 1){
        const response = await DeleteStateService(selecteddata?._id)
        if(response.status === 201){
          setModal(false)
          toast.success(response?.data?.message)
          getdata()
        }
       }else if(value === 2){
        const response = await DeleteDistrictService(selecteddata?._id)
        if(response.status === 201){
          setModal(false)
          toast.success(response?.data?.message)
          getdata()
        }
       }else if(value === 3){
        const response = await DeleteTalukService(selecteddata?._id)
        if(response.status === 201){
          setModal(false)
          toast.success(response?.data?.message)
          getdata()
        }
       }
  }

   async function gotocreate(){
      if(value === 0){
        navigate('create',{state:{type:'Country'}})
      }else if(value === 1){
        navigate('create',{state:{type:'State'}})
      }else if(value === 2){
        navigate('create',{state:{type:'District'}})
      }else if(value === 3){
        navigate('create',{state:{type:'Taluk'}})
      }

   }
//   console.log("data",data)

  

  return (
    <div className='h-screen max-h-screen box-border overflow-hidden'>
    
    <Modal
       keepMounted
       open={modal}
       onClose={()=>setModal(false)}
       width={300}
       footer={false}
       closable={false}
      
     >
       <div >
         <h6 className="font-bold text-[15px] text-center mb-2 w-full">Are you sure?</h6>
         <h6 className='bg-slate-100 text-center text-[12px] p-1.5 font-[400]'>After deleting you cannot retrieve it back before deleting check once whether you have used it in some data</h6>
         <div className='flex justify-end mt-3 '>
           {/* <div className='mr-1 w-full'> */}
           <ButtonOutlinedAutoWidth btnName={'Cancel'} onClick={()=>setModal(false)} />
           {/* </div> */}
           <div  className='ml-2'>
           <ButtonFilledAutoWidth btnName={'Confirm'} onClick={()=>deletedata()}  /> 
           </div>
         </div>
       </div>
     </Modal>

      <div className='block sm:flex'>
       <SettingsMenu />

       <div className='w-[88%] px-4'>
       <div >
       <div className='pt-5'>
       <div className="w-full border-b flex justify-between align-center items-center  pb-1 ">
          <div className='flex '>
            <h6 onClick={()=>setvalue(0)} className={`px-2  text-[11px] py-1.5 font-[700]  cursor-pointer  ${value === 0 ? 'bg-slate-600 text-white' : 'border-white' }`}>Country</h6>
            <h6 onClick={()=>setvalue(1)} className={`px-2  text-[11px] py-1.5 font-[700]  cursor-pointer  ${value === 1 ? 'bg-slate-600 text-white' : 'border-white'}`}> State</h6>
            <h6 onClick={()=>setvalue(2)} className={`px-2  text-[11px] py-1.5 font-[700]  cursor-pointer  ${value === 2 ? 'bg-slate-600 text-white' : 'border-white'}`}>District</h6>
            <h6 onClick={()=>setvalue(3)} className={`px-2  text-[11px] py-1.5 font-[700]  cursor-pointer  ${value === 3 ? 'bg-slate-600 text-white' : 'border-white'}`}>Taluk</h6>
          </div>

          <ButtonFilledAutoWidth onClick={gotocreate} btnName={`Add ${value === 0 ? 'Country' : ''} ${value === 1 ? 'State' : ''} ${value === 2 ? 'District' : ''} ${value === 3 ? 'Taluk' : ''}`} />
        </div>

        {data?.datas?.length === 0 &&
        <div className='grid place-items-center mt-20  items-center justify-center'>
          <img src={'https://fidecrmfiles.s3.amazonaws.com/NoDataFound1.png'} className='w-40 h-40 object-contain' /> 
          <h6 className='font-bold text-[14px] -mt-10'>No data found</h6>
          <h6 className='font-[500] w-[70%] text-center text-slate-700 text-[12.5px] -mt-2'>Oops we couldn't find any data added based on your current page option please click on add the data button to see list here.</h6>
        </div>
        }

        {data?.datas?.length > 0 && 
        <div className='grid grid-cols-5 gap-1 mt-2'>
          
        
          {data?.datas?.map((d,i)=>(
              <div key={i}  className='relative  items-center  border  justify-between px-2 py-1.5 border-b'>
                  <h6 className='text-[13px] font-[500] truncate'>{type[value] === 'Country' && d.country_name} {type[value] === 'State' && d.state_name} {type[value] === 'District' && d.district_name} {type[value] === 'Taluk' && d.branch_name}</h6>
                  <h6 className='text-[10px] font-[500] bg-slate-100 mt-1 p-1'>Created At : <span className='text-[10px] font-[800]'>{moment(d.createdAt).format('LLL')}</span> </h6>
                  <div className='flex absolute top-2 right-1'>
                  <Tooltip title="Edit" >
                    <span> <AiOutlineEdit size={14} className="cursor-pointer" onClick={()=>{navigate('edit',{state:{data:d,type:type[value]}})}}/></span>
                  </Tooltip>
                  <Tooltip title="Delete">
                   <span> <AiOutlineDelete size={14} className='ml-2 cursor-pointer' onClick={()=>{setselecteddata(d);setModal(true)}}/></span>
                  </Tooltip>
                  </div>
              </div>
              ))}
        </div>}  
        </div>  


    </div>
    </div>
    </div>
    </div>
  )
}





export default CSDTList