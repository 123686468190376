import { deleteRequest, get, post, postfd, put } from "../../helpers/apihelpers";

export const CreateFcaresQueryService=async(data)=>{
    try {
        const result = await post(`api/fcares/fcare_query`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateFcaresQueryService=async(id,data)=>{
    try {
        const result = await put(`api/fcares/fcare_query/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetFcaresQueryService=async(text,from_date,to_Date,step,page)=>{
    try {
        const result = await get(`api/fcares/fcare_query?page=${page}&search=${text}&from_date=${from_date}&to_date=${to_Date}&step=${step}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetFcaresQueryByIdService=async(id)=>{
    try {
        const result = await get(`api/fcares/fcare_query/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteFcaresQueryService=async(id)=>{
    try {
        const result = await deleteRequest(`api/fcares/fcare_query/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UploadDocsFcaresQueryService=async(data)=>{
    try {
        const result = await postfd(`api/fcares/fcare_query/upload_file`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}