import React, { useEffect, useState } from 'react'
import AtsMenu from './AtsMenu'
import { useLocation, useNavigate } from 'react-router-dom'
import { GetAtsTrackService } from '../../services/Ats/AtsTrackServices'
import { Select,Date, DatePicker } from 'antd'
import { AiOutlineReload } from 'react-icons/ai'
import { GetAllAtsEmployeeSharedBasedOnHiringStage } from '../../services/Ats/AtsEmployeeServices'
import {FiChevronRight,FiChevronLeft} from 'react-icons/fi';
import moment from 'moment'

function AtsEmployeeListBasedOnHiringStage() {

  const {state} = useLocation()
  const navigate = useNavigate()
  const [page,setpage] = useState(1)
  const [selected,setselected] = useState({stage:'',sub_stage:'',from_date:'',from_date1:'',to_date:'',to_date1:''})
  const [stage,setstage] = useState([])
  const [sub_stage,setsub_stage] = useState([])
  const [datas,setdatas] = useState({})
  console.log("state",state)

  useEffect(()=>{
    setselected({...selected,stage:{label:state,value:state}})
    getData(state)
  },[])


  useEffect(()=>{
     getDataReport()
  },[selected?.stage,selected?.sub_stage,selected?.from_date,selected?.to_date,page])


  async function getDataReport(){
    const response1 = await GetAllAtsEmployeeSharedBasedOnHiringStage(page,selected?.stage?.label !== undefined ? selected?.stage?.label : '',selected?.sub_stage?.label !== undefined ? selected?.sub_stage?.label :'',selected?.from_date1,selected?.to_date1)
    setdatas(response1?.data?.data)
  }



  async function getData(state){
    const response = await GetAtsTrackService({page:1})
    const response1 = await GetAllAtsEmployeeSharedBasedOnHiringStage(page,state !== undefined ? state : selected?.stage,selected?.sub_stage,selected?.from_date1,selected?.to_date1)
    setdatas(response1?.data?.data)
    let arr = []
    let arr1 = []
    let d = response?.data?.datas
    let d2 = d?.find((f)=>f.heading === state)
    if(d2 !== null){
        d2?.sub_heading?.split(":")?.forEach((d1)=>{
          arr1.push({label:d1,value:d1})
        })
    }
    d?.forEach((d1)=>{
        arr.push({label:d1?.heading,value:d1?._id,option:d1?.sub_heading})
    })
    setstage(arr)
    setsub_stage(arr1)
  }

  async function resetfilter(){
    setpage(1)
    setselected({stage:{label:state,value:state},sub_stage:'',from_date:'',from_date1:'',to_date:'',to_date1:''})
  }

  async function handlechange(v,type){
    if(type == 1){
        if(stage.find((f)=>f?.value === v)?.value !== selected?.stage?.value){
            let s = stage.find((f)=>f?.value === v)
            let arr = []
            let options = s?.option?.split(":")
            options?.forEach((e)=>{
               arr.push({label:e,value:e})
            })
            setselected({stage:s,sub_stage:''})
            setsub_stage(arr)
        }
    }else if(type == 2){
        setselected({...selected,sub_stage:sub_stage?.find((f)=>f?.value === v) !== undefined ? sub_stage?.find((f)=>f?.value === v) : ''})
    }
  }

  return (
    <div className='w-[98%] max-h-screen overflow-hidden'>
    <div className='flex'>
       <div className='min-w-44 overflow-hidden'>
       <AtsMenu />
       </div>
        <div className='w-[86.5%] maxh-h-screen pl-5 pr-3 mt-4 h-screen no-scrollbar pb-10 overflow-y-hidden'> 
         
          <div className='flex items-center justify-between border-b pb-2'>

          <h6 className='text-[13px] font-[800] mb-2'>Employee's Data ({datas?.pagination?.total !== undefined ? datas?.pagination?.total : 0})</h6>
            <div className='flex items-center'>

            <div className='flex text-[11px] items-center'>
                <h6 className='mr-2 font-[600]'>{page == 1 ? datas?.datas?.length > 0 ? 1 : 0 : (page - 1) * datas?.pagination?.limit } - {datas?.pagination?.limit} of {datas?.pagination?.total} </h6>
                <FiChevronLeft onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}} className={`${page === 1 ? 'opacity-50' : 'opacity-100'} mx-4`}  size={14} />
                <FiChevronRight onClick={()=>{ page < datas?.pagination?.totalPages  ? setpage(page+1) : console.log('')}} className={`${(datas?.pagination?.totalPages === page || datas?.datas?.length === 0) ? 'opacity-50 ' : 'opacity-100'} mr-4`}  size={14} />
                </div>
                <AiOutlineReload onClick={resetfilter}  className='mr-4' size={13} />

                <DatePicker value={selected?.from_date} placeholder='From Date' onChange={(e,e1)=>{setselected({...selected,from_date:e,from_date1:e1})}} className='w-[120px] mr-2 rounded-[4px] h-[30px]  border border-slate-200 rounded-[2px]' /> 
                <DatePicker value={selected?.to_date} placeholder='To Date' onChange={(e,e1)=>{setselected({...selected,to_date:e,to_date1:e1})}} className='w-[120px] mr-2 rounded-[4px] h-[30px]  border border-slate-200 rounded-[2px]' /> 

                <Select 
                    bordered={false}
                    value={selected?.stage}
                    className='border h-[30px] mr-2 rounded w-[100px]'
                    options={stage}
                    onChange={(e)=>handlechange(e,'1')}
                />

                <Select 
                    bordered={false}
                    value={selected?.sub_stage}
                    className='border h-[30px] mr-2 rounded w-[100px]'
                    options={sub_stage}
                    onChange={(e)=>handlechange(e,'2')}
                />

            </div>
          </div>

          <div className='relative mt-2 no-scrollbar max-h-[90vh] overflow-y-scroll'>
          <div className='border-l border-r'>

            <div className='sticky bg-white  w-[100%] top-0 z-30 flex  py-[-5px] border-t  border-b border-slate-200 relative'>
                <h6 className='sticky top-0 z-30  text-[11px] min-w-[200px] max-w-[200px] px-2 py-1 font-[600] border-r'>Client Name / Requirment</h6>
                <h6 className='sticky top-0 z-30  text-[11px] min-w-[200px] max-w-[200px] px-2 py-1 font-[600] border-r'> Employee Info</h6>
                <h6 className='sticky top-0 z-30  text-[11px] min-w-[130px] max-w-[130px] px-2 py-1 font-[600] border-r'> Stage</h6>
                <h6 className='sticky top-0 z-30  text-[11px] min-w-[140px] max-w-[140px] px-2 py-1 font-[600] border-r'> Sub Stage</h6>
                <h6 className='sticky top-0 z-30  text-[11px] min-w-[140px] max-w-[140px] px-2 py-1 font-[600] border-r'> Created By</h6>
                <h6 className='sticky top-0 z-30  text-[11px] min-w-[140px] max-w-[140px] px-2 py-1 font-[600] '> Created On</h6>
            </div>

            {datas?.datas?.map((d)=>(
            <div className=' bg-white w-[100%] flex   border-b border-slate-200 relative'>
                <h6 onClick={()=>navigate('/ats/job_description/detail',{state:{data:d?.ats_hr_client_job?._id}})} className='text-[11px] min-w-[200px] max-w-[200px] px-2 py-1 border-r'>
                     <h6 className='text-[12px] mb-0'>Job Title : <span className='font-[800]'>{d?.ats_hr_client_job?.job_title}</span> </h6>
                     <h6 className='text-[11px] mb-0 line-clamp-2'>Job Description : <span className='font-[800]'>{d?.ats_hr_client_job?.job_description}</span></h6>
                     <h6 className='text-[11.5px] mb-0'>Company/ Client : <span className='font-[800]'>{d?.ats_hr_client_job?.ats_hr_client?.company_name} / {d?.ats_hr_client_job?.ats_hr_client?.client_name}</span> </h6>
                     <span className='bg-gray-100 text-[11px] font-[600] p-1 rounded '>{d?.ats_hr_client_job?.ats_hr_client?.stage}</span>
                     <div className='border w-[100%] flex mt-2   border-gray-100'>
                           <div className='border-r w-[100%]  border-gray-200 '>
                              <h6 className='text-[11px] bg-slate-50 text-center font-[400]'>Opening</h6>
                              <h6 className='text-[10px] text-center font-[800]'>{d?.ats_hr_client_job?.no_of_opening}</h6>
                           </div> 

                           <div className='border-r w-[100%]  border-gray-200 '>
                              <h6 className='text-[11px] bg-slate-50 text-center font-[400]'>Experience</h6>
                              <h6 className='text-[10px] text-center font-[800]'>{d?.ats_hr_client_job?.experience}</h6>
                           </div> 

                           <div className='w-[100%]'>
                              <h6 className='text-[11px] bg-slate-50 text-center font-[400]'>Notice</h6>
                              <h6 className='text-[10px] text-center font-[800]'>{d?.ats_hr_client_job?.notice_period}</h6>
                           </div> 

                     </div>  
                     <h6 className='text-[11px] mt-2 mb-1'>Client Spoc person : <span className='font-[800]'>{d?.ats_hr_client_job?.created_by?.name}</span></h6>

                </h6>
                <h6 className='text-[11px] min-w-[200px] max-w-[200px] px-2 py-1 border-r'>
                <h6 className='text-[12px] mb-0'>Employee : <span className='font-[800]'>{d?.ats_hr_employee?.employee_name}</span> </h6>
                <h6 className='text-[11px] mb-0 line-clamp-2'>Mobile : <span className='font-[800]'>{d?.ats_hr_employee?.mobile_no}</span></h6>
                     <h6 className='text-[11.5px] mb-0'>Email/ Current Company : <span className='font-[800]'>{d?.ats_hr_employee?.email_id} / {d?.ats_hr_employee?.current_company}</span> </h6>
                     <div className='border w-[100%] flex mt-2   border-gray-100'>
                           <div className='border-r w-[100%]  border-gray-200 '>
                              <h6 className='text-[11px] bg-slate-50 text-center font-[400]'>Expierence</h6>
                              <h6 className='text-[10px] text-center font-[800]'>{d?.ats_hr_employee?.experience}</h6>
                           </div> 

                           <div className='border-r w-[100%]  border-gray-200 '>
                              <h6 className='text-[11px] bg-slate-50 text-center font-[400]'>CTC</h6>
                              <h6 className='text-[10px] text-center font-[800]'>{d?.ats_hr_employee?.current_ctc}</h6>
                           </div> 

                           <div className='w-[100%]'>
                              <h6 className='text-[11px] bg-slate-50 text-center font-[400]'>Notice</h6>
                              <h6 className='text-[10px] text-center font-[800]'>{d?.ats_hr_employee?.notice_period}</h6>
                           </div> 

                     </div>  

                </h6>
                <h6 className='text-[11px] min-w-[130px] max-w-[130px] px-2 py-1 pt-2 border-r'> <span className='p-1.5 font-[700] text-[10px] bg-slate-500 rounded text-white'>{d?.heading_Stage}</span></h6>
                <h6 className='text-[11px] min-w-[140px] max-w-[140px] px-2 py-1 pt-2 border-r'> <span className='p-1.5 font-[700] text-[10px] bg-slate-100 rounded text-black'>{d?.sub_heading_Stage}</span> </h6>
                <h6 className='text-[11px] min-w-[140px] max-w-[140px] px-2 font-[700] py-1 border-r'> {d?.created_by?.name}</h6>
                <h6 className='text-[10px] min-w-[140px] max-w-[140px] font-[700] px-2 py-1 '>{moment(d?.createdAt)?.format('LL')}</h6>
            </div>))}

            </div>
          </div>
        </div>


      
    </div>
    </div>      
  )
}

export default AtsEmployeeListBasedOnHiringStage