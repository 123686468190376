import { deleteRequest, get, put } from "../helpers/apihelpers";


export const GetAgreementListService=async(page=1,step=1,from_date,to_date,stage,text)=>{
    try {
        const result = await get(`api/lead/get_aggrement_followup?page=${page}&step=${step}&from_date=${from_date}&to_date=${to_date}&stage=${stage}&text=${text}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetAgreementListBasedUserService=async(page=1,step=1)=>{
    try {
        const result = await get(`api/lead/get_aggrement_followup_based_user?page=${page}&step=${step}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetAgreementDetailService=async(id)=>{
    try {
        const result = await get(`api/lead/get_aggrement_followup/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateAgreementService=async(id,data)=>{
    try {
        const result = await put(`api/lead/update_aggrement_followup/${id}`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteAgreementService=async(id)=>{
    try {
        const result = await deleteRequest(`api/lead/delete_aggrement_followup/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}