import {get, put} from "../helpers/apihelpers";

export const GetOutstandingService=async(id,from_date,to_date,page,current_tab)=>{
    try {
        const result = await get(`api/lead/get_outstanding/${id}?from_date=${from_date}&to_date=${to_date}&page=${page}&current_tab=${current_tab}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateOutstandingService=async(id,data)=>{
    try {
        const result = await put(`api/lead/update_outstanding/${id}`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}