import React from 'react'
import DashboardMenu from '../dashboard/DashboardMenu'
import { useLocation } from 'react-router-dom'
import GoBack from '../../components/back/GoBack'
import moment from 'moment'
import {MdPayment} from 'react-icons/md'
import { AiOutlineCloudDownload } from 'react-icons/ai'
import {BsFillBookmarkCheckFill} from 'react-icons/bs'
import axios from 'axios'
import fileDownload from "js-file-download";
import {BiCheckbox,BiCheckboxSquare} from 'react-icons/bi'


function OutstandingDetail() {
  
  const {state} = useLocation()

  let rupee = new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
  });

  const handleDownload = (url, filename) => {
    axios.get(url, {
        responseType: 'blob',
    })
        .then((res) => {
            fileDownload(res.data, filename)
        })
}
  
  console.log("state",state)

  function totalTdsDeduction(v){
    let arr = v 
    let amount = 0
    arr .forEach((a)=>{
      if(a.tds_deduction !== undefined && a.tds_deduction !== null && a.tds_deduction > 0){
        amount += Math.round((a.amount_without_gst / 100) * a.tds_deduction)
      }
    })
    return amount
  }

  function returnTdsAmount(v){
    let amount = 0
    amount = Math.round((v.amount_without_gst /100) * v?.tds_deduction)

    return amount
  }

  return (
    <div className='w-screen flex h-screen overflow-hidden'>
       <DashboardMenu />

       <div className='flex'>
            <div className='min-w-[25%] w-[25%] max-w-[25%]  border-r relative min-h-screen max-h-screen '>

                    <div className='px-2'>
                    <GoBack />

                    {state?.invoice_request?.length > 0 &&<>
                    <h6 className='font-[600] text-[14px] p-1 mt-4'>Invoice Info</h6>
                    <h6 className='text-[11px] p-1 bg-slate-100'>Use the below details For the Invoice request raised by the user</h6>

                    {state?.invoice_request?.map((d1,i)=>(
                    <div key={i} className={`border-b mb-2 relative py-2`}>
                        
                        {(d1?.file !== '' && d1?.file !== undefined && d1?.file !== null) &&
                        <div className='flex '>
                        <img src="https://cdn-icons-png.flaticon.com/512/179/179483.png" className='w-6 h-6 object-contain z-0' />
                        <h6 className='text-[9px] ml-1 w-[170px] font-[800]  overflow-hidden line-clamp-2'>{d1?.file?.split('/')[d1?.file?.split('/')?.length - 1]}</h6>
                        <AiOutlineCloudDownload size={18} className='cursor-pointer absolute right-0  bg-white' onClick={()=>handleDownload(`${process.env.REACT_APP_BACKEND_IMAGE_URL}${d1?.file}`,d1?.file?.split('/')[d1?.file?.split('/')?.length - 1])} />
                        </div>}  
                    
                        <h6 className='text-[10px] ml-1 mt-1'>Invoice No : <span className='font-[800]'>{d1?.invoice_no}</span></h6>
                        {d1?.description !== '' && <h6 className='text-[10px] ml-1'>Description : <span className='font-[500]'>{d1?.description}</span></h6>}
                        <h6 className='text-[10px] ml-1'>Created On : <span className='font-[800]'>{moment(d1?.createdAt).format('LLL')}</span></h6>
                        {d1?.final && <BsFillBookmarkCheckFill className='absolute top-0 left-0 z-100' />}
                    </div>))}
                    </>}

                    <h6 className={`font-[600] text-[14px] ${state?.invoice_request?.length === 0 && 'border-t mt-[20px] '} p-1`}>Client Info</h6>

                    <h6 className='text-[11px] p-1 bg-slate-100'>Use the below details For the Invoice owner who raised  invoice request from you. </h6>

                    <div className='pt-2 flex '>

                    <h6 className='bg-slate-200 w-8 h-8 p-1 text-[11px] flex items-center justify-center uppercase rounded-full'>{state?.client?.contact_name?.slice(0,2)}</h6>
                    <div className='ml-2'>
                    <h6 className='text-[14px] mb-0 font-[900]'>{state?.client?.contact_name}</h6>
                    <h6 className='text-[9.5px] -mt-0.5 font-[400] '>{state?.client?.company_name}</h6>
                    </div>
                    </div>

                    <div className='border-b mb-2 pb-2'>

                    <span className='text-[11px] flex items-center text-gray-800 mt-3 ml-1 '>Name : <span className='font-[600] ml-1'>   {state?.client?.contact_name}</span></span>
                    <span className='text-[11px] flex items-center text-gray-800 mt-0.5 ml-1 '>Email : <span className='font-[600] ml-1'>   {state?.client?.email}</span></span>
                    <span className='text-[11px] flex items-center text-gray-800 mt-0.5 ml-1 '>Phone : <span className='font-[600] ml-1'>  {state?.client?.phone}</span></span>
                    <span className='text-[11px] flex items-center text-gray-800 mt-0.5 ml-1 '>Company Name : <span className='font-[600] ml-1'>  {state?.client?.company_name}</span></span>
                    {state?.client?.address !== '' && <span className='text-[11px] flex items-center text-gray-800 mt-0.5 ml-1 '>Company Address : <span className='font-[600] ml-1'>  {state?.client?.address}</span></span>}
                    </div>
                            
                    <h6 className='font-[600] text-[14px]  p-1'>Invoice Owner</h6>

                    <span className='text-[11px] flex items-center text-gray-800 mt-1 ml-1 mb-2 border-b pb-2'>Name : <span className='font-[600] ml-1'>   {state?.employee?.name} [{state?.employee?.employee_id}]</span></span>

                    <h6 className='font-[600] text-[14px] p-1'>Invoice Detail</h6>

                    <span className='text-[11px] flex items-center text-gray-800 ml-1 '>To : <span className='font-[600] ml-1'>   {state?.client?.land_lord ? 'Land Lord' : 'Client'} </span></span>
                    <span className='text-[11px] flex items-center text-gray-800 ml-1 '>Amount : <span className='font-[600] ml-1'>   {rupee?.format(state?.amount)} </span></span>
                    <span className='text-[11px] flex items-center text-gray-800 ml-1 '>Raise Date : <span className='font-[600] ml-1'>   {moment(state?.invoice_raising_date).format('LL')} </span></span>
                    {(state?.gst_no !== null && state?.gst_no !== undefined) &&
                    <span className='text-[11px] flex items-center text-gray-800 ml-1 '>Gst No  : <span className='font-[600] ml-1'>   {state?.gst_no} </span></span>}
                    {(state?.pan_no !== null && state?.pan_no !== undefined) &&
                    <span className='text-[11px] flex items-center text-gray-800 ml-1 '>Pan No  : <span className='font-[600] ml-1'>   {state?.pan_no} </span></span>}
                    {(state?.office_space_closed !== null && state?.office_space_closed !== undefined) &&
                    <h6 className='text-[11px]  items-center text-gray-800 ml-1 '>Office Space Closed  : <span className='font-[600] ml-1'>   {state?.office_space_closed} </span></h6>}
                    <h6 className='text-[11px] bg-slate-100 p-2  text-gray-800 mt-1 '>Description : <span className='font-[600] ml-1'>   {state?.other_details} </span></h6>
                    

                    </div>
                    
                


                    
                    
                
            </div>
            <div className='min-w-[69%] w-[69%] max-w-[69%] p-5  pt-8 relative min-h-screen max-h-screen '>

                <div>
                <h6 className={`font-[600] text-[14px] border-b pb-1 border-slate-200`}>Invoice Payment Info</h6>

                <div className='flex justify-evenly border mt-2'>

                    <div className='border-r p-2 w-full'>
                        <h6 className='text-[12px] font-[700]'>Invoice Amount Incl Gst</h6>
                        <h6 className='text-[18px] text-slate-700'>{rupee?.format(state?.invoice_amount_with_gst)}</h6>
                    </div>

                    <div className='border-r p-2 w-full'>
                        <h6 className='text-[12px] font-[700]'>Invoice Amount Excl Gst</h6>
                        <h6 className='text-[18px] text-slate-700'>{rupee?.format(state?.invoice_amount_without_gst)}</h6>
                    </div>

                    <div className='border-r p-2 w-full'>
                        <h6 className='text-[12px] font-[700]'>Payment Collected</h6>
                        <h6 className='text-[18px] text-slate-700'>{rupee?.format(state?.amount)}</h6>
                    </div>

                    <div className=' p-2 w-full'>
                        <h6 className='text-[12px] font-[700]'>TDS Deduction</h6>
                        <h6 className='text-[18px] text-slate-700'>{rupee?.format(totalTdsDeduction(state?.invoicepaymentList))}</h6>
                    </div>
                </div>


                <div className='border mt-2'>
                    <div className='flex items-center'>
                          <h1 className='text-[11px] p-1 w-[5%] font-[700] border-r'>Sl No</h1>
                          <h1 className='text-[11px] p-1 w-[13%] font-[700] border-r'>Amount (Incl GST)</h1>
                          <h1 className='text-[11px] p-1 w-[14%] font-[700] border-r'>Amount (Excl GST)</h1>
                          <h1 className='text-[11px] p-1 w-[12%] font-[700] border-r'>Recieved Date</h1>
                          <h1 className='text-[11px] p-1 w-[15%] font-[700] border-r'>TDS Deducation</h1>
                          <h1 className='text-[11px] p-1 w-[15%] font-[700] border-r'>Finance Approved</h1>
                          <h1 className='text-[11px] p-1 w-[10%] font-[700] border-r'>Is Deleted</h1>
                          <h1 className='text-[11px] p-1 w-[16%] font-[700] '>Remarks</h1>
                    </div>

                    {state?.invoicepaymentList?.map((d,i)=>(
                    <div className='flex  border-t'>
                          <h1 className='text-[11px] p-1 w-[5%]  border-r'>{i+1}</h1>
                          <h1 className='text-[11px] p-1 w-[13%]  border-r'>{rupee?.format(d?.amount_with_gst)}</h1>
                          <h1 className='text-[11px] p-1 w-[14%]  border-r'>{rupee?.format(d?.amount_without_gst)}</h1>
                          <h1 className='text-[11px] p-1 w-[12%]  border-r'>{moment(d?.recieved_date)?.format('LL')}</h1>
                          <h1 className='text-[11px] p-1 w-[15%]  border-r'>{(d?.tds_deduction !== undefined && d?.tds_deduction !== null && d?.tds_deduction !== '') ? `${d?.tds_deduction} % TDS  has been deducted the  Amount will be ${rupee?.format(returnTdsAmount(d))}` : '0'}</h1>
                          <h1 className='text-[11px] p-1 w-[15%]  border-r'>{d?.finance_approved ? <BiCheckboxSquare size={20} className='text-cyan-600'  />  : <BiCheckbox size={20} className='text-gray-200'  />}</h1>
                          <h1 className='text-[11px] p-1 w-[10%]  border-r'>{d?.is_deleted ? <BiCheckboxSquare size={20} className='text-cyan-600'  />  : <BiCheckbox size={20} className='text-gray-200'  />}</h1>
                          <h1 className='text-[11px] p-1 w-[16%]  '>{d?.deleted_reason}</h1>
                    </div>
                    ))}

                </div>    


                </div>

            </div>
      </div>

    </div>   
  )
}

export default OutstandingDetail