import React,{useState,useEffect} from 'react'
import Grid from '@mui/material/Grid';
import { TextAreaInput1, TextInput } from '../../../components/input';
import { ButtonFilled, ButtonOutlined } from '../../../components/button';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import {Select } from 'antd';
import GoBack from '../../../components/back/GoBack';
// import DailyTaskCE from './dailyTask/DailyTaskCE';
import FcaresMenu from '../FcaresMenu';
import { BiCheckbox,BiCheckboxSquare } from "react-icons/bi";
import { CreateFcaresUserService, GetFcaresUserByIdService, UpdateFcaresUserService } from '../../../services/Fcares/FCaresUserServices';


function FCaresUserCE() {



  const [data,setdata] = useState({
    name:'',
    mobile:'',
    email:'',
    password:'',
    address:'',
    summary:'',
    type:'',
    photo:'',
    is_customer:false,
    is_client:false,
    is_active:false,
  })

  

  
 
  const [loader, setloader] = useState(false);



  const [error,seterror] = useState({
        name:'',
        mobile:'',
        email:'',
        password:'',
        address:'',
        summary:'',
        type:'',
        photo:'',
        is_customer:false,
        is_client:false,
        is_active:false,
  })

  const navigate = useNavigate()
  const location = useLocation()



   

  
  useEffect(()=>{

    if(location?.pathname?.split('/')[location?.pathname?.split('/').length -1] === 'edit'){
      geteditdata()
    }
  },[])

  async function geteditdata(){
    const response =  await GetFcaresUserByIdService(location?.state);
    let d = response?.data?.data
    setdata({...d})
  }

  // console.log("data?.operators",data?.operators)
  function handlechange(e){
    setdata({...data,[e.target.name] : e.target.value})
    seterror({...error,[e.target.name] : ''})
  }

  // console.log("data",data)

  async function submitform(){
   
    if(!data.name){
        seterror({...error,name:'This Field is required*'})
    }else if(!data.mobile){
        seterror({...error,mobile:'This Field is required*'})
    }else if(!data.email){
        seterror({...error,email:'This Field is required*'})
    }else if(!data.password){
      seterror({...error,password:'This Field is required*'})
    }else if(!data.type){
      seterror({...error,type:'This Field is required*'})
    }else{
        let fd = new FormData()
        fd.append('name',data?.name)
        fd.append('mobile',data?.mobile)
        fd.append('email',data?.email)
        fd.append('password',data?.password)
        fd.append('type',data?.type)
        fd.append('address',data?.address)
        fd.append('summary',data?.summary)
        fd.append('is_customer',data?.is_customer)
        fd.append('is_client',data?.is_client)
        fd.append('is_active',data?.is_active)
      
       
       

        if(location?.pathname?.split('/')[location?.pathname?.split('/').length - 1] === 'edit'){
        

          setloader(true)
          const response = await UpdateFcaresUserService(data?._id,fd)
          if(response.status === 200){
            setloader(false)
            // console.log("Anna data?.stage?.label",data?.stage?.label,"stage?.selected_stage?.label",stage?.selected_stage?.label)
            setloader(false)
            toast.success('User Updated Successfully')
            resetform()
            navigate(-1)
          }
        }else{  
          setloader(true)
          const response = await CreateFcaresUserService(fd)
          if(response.status === 201){
              setloader(false)
              toast.success('User Created Successfully')
              resetform()
          }
        }
      }
  }

  function resetform(){
     setdata({
        name:'',
        mobile:'',
        email:'',
        password:'',
        address:'',
        summary:'',
        type:'',
        photo:'',
        is_customer:false,
        is_client:false,
        is_active:false,
      })
      seterror({
        name:'',
        mobile:'',
        email:'',
        password:'',
        address:'',  
        summary:'',
        type:'',
        photo:'',
        is_customer:false,
        is_client:false,
        is_active:false,
      })

    
  }



 
  


  return (
    <div className='h-screen max-h-screen box-border overflow-hidden'>
    <div className='block sm:flex'>
        <FcaresMenu />
    <div className='pr-5 ml-5 min-h-screen max-h-screen h-screen overflow-y-scroll' >
    

  

    <div className='w-64 '>
      <GoBack />
     <div className='border-b  pb-2'>
      <h6 className='font-[800] mb-1'>{location?.pathname?.split('/')[location?.pathname?.split('/').length -1] === 'edit' ? 'Edit' : 'Create'} User </h6> 
      <h6 className='text-[11px] leading-tight font-[500] p-2 bg-slate-100 '>Use the below form to create or edit the user for the fcares .</h6> 
     </div> 
      <Grid container spacing={2}  >
        <Grid item xs={12}  md={12} >
          <TextInput 
              mandatory={true}
              label={'Name'}  
              variant="standard"
              name="name"
              type="text"
              value={data.name}
              error={error.name}
              handlechange={handlechange}
              placeholder="Enter contact name"
              InputLabelProps={{
                  style: { color: '#fff', }, 
              }}/>
               <TextInput 
              mandatory={true}
            label={'Phone'}  
            variant="standard"
            name="mobile"
            type="text"
            value={data.mobile}
            error={error.mobile}
            handlechange={handlechange}
            placeholder="Enter contact phone"
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>
             <TextInput 
              mandatory={true}
            label={'Email'}  
            variant="standard"
            name="email"
            type="text"
            value={data.email}
            error={error.email}
            handlechange={handlechange}
            placeholder="Enter contact email"
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>

<TextInput 
            label={'Password'}  
            variant="standard"
            name="password"
            type="text"
            value={data.password}
            error={error.password}
            handlechange={handlechange}
            placeholder="Enter designation"
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>

          <TextInput 
            mandatory={true}
            label={'User Type'}  
            variant="standard"
            name="type"
            type="text"
            value={data.type}
            error={error.type}
            handlechange={handlechange}
            placeholder="Enter company name"
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>
             <TextAreaInput1 
            label={'Address'}  
            variant="standard"
            name="address"
            type="text"
            value={data.address}
            error={error.address}
            handlechange={handlechange}
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>

<TextAreaInput1 
            label={'Summary'}  
            variant="standard"
            name="summary"
            type="text"
            value={data.summary}
            error={error.summary}
            handlechange={handlechange}
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>

            <div className='grid gap-1 grid-cols-3 mt-2'>

            <div className='flex cusror-pointer' onClick={()=>setdata({...data,is_customer:!data.is_customer})}>
              {!data?.is_customer ? <BiCheckbox className='text-slate-300' /> : <BiCheckboxSquare className='text-slate-600' />}
              <h6 className='text-[10px] ml-[3px] font-[700]'>Is Teannt</h6>
            </div>

            <div className='flex cusror-pointer' onClick={()=>setdata({...data,is_client:!data.is_client})}>
              {!data?.is_client ? <BiCheckbox className='text-slate-300' /> : <BiCheckboxSquare className='text-slate-600' />}
              <h6 className='text-[10px] ml-[3px] font-[700]'>Is LandLord</h6>
            </div>

            <div className='flex cusror-pointer' onClick={()=>setdata({...data,is_active:!data.is_active})}>
              {!data?.is_active ? <BiCheckbox className='text-slate-300' /> : <BiCheckboxSquare className='text-slate-600' />}
              <h6 className='text-[10px] ml-[3px] font-[700]'>Is Active</h6>
            </div>
            </div>




       
            
        </Grid>
       
      </Grid>

   
   <div >
    <div className='flex items-center mt-5 mb-10  border-t pt-5'>
        <div className='mr-2'>
        <ButtonOutlined btnName={'Back'} onClick={()=>navigate(-1)} />
        </div>
        <div>
        {loader ?   
        <ButtonFilled btnName={loader ? 'Loading' : 'Save'} /> :
        <ButtonFilled btnName={loader ? 'Loading' : 'Save'} onClick={()=>submitform(null)} /> }
        </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
   
  )
}

export default FCaresUserCE