import { deleteRequest, get, post } from "../../helpers/apihelpers";


export const CreateGalleryProductBrandList=async(data)=>{
    try {
        const result = await post(`api/gallery_product_brand/create`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateGalleryProductBrandList=async(id,data)=>{
    try {
        const result = await post(`api/gallery_product_brand/update/${id}`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetGalleryProductBrandList=async(page)=>{
    try {
        const result = await get(`api/gallery_product_brand/get/null/${page}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const SearchGalleryProductBrandService = async({search_text,from_date,to_date,page})=>{
    try {
        const result = await get(`api/gallery_product_brand/filter?search_text=${search_text}&from_date=${from_date}&to_date=${to_date}&page=${page}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteGalleryProductBrandService = async(id) => {
    try {
        const result = await deleteRequest(`api/gallery_product_brand/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

