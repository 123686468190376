import React,{useState, useEffect} from 'react'
import AtsMenu from './AtsMenu'
import { GetAtsDashboardAdmin, GetAtsDashboardHomePipelineAdminService, GetAtsDashboardTodayCandidateBankAdminService, GetAtsDashboardUpcomingInterviewAdminService } from '../../services/Ats/AtsDashboardServices';
import {BiSelectMultiple,BiArchive} from 'react-icons/bi'
import { GetAtsDashboardHomePipelineService } from '../../services/Ats/AtsDashboardServices';
import {LuScreenShare} from 'react-icons/lu';
import {MdOutlineSubtitles,MdOutlineLocalOffer} from 'react-icons/md';
import {AiOutlineInteraction,AiOutlineReload,AiOutlineUserAdd} from 'react-icons/ai';
import {TbPlayerEject} from 'react-icons/tb';
import { GetAtsActiveobService, GetAtsJobCandidateAssignedStageService } from '../../services/Ats/AtsJobServices';
import { useNavigate } from 'react-router-dom';
import {FiChevronRight,FiChevronLeft} from 'react-icons/fi';
import { IconButton, Tooltip } from '@material-ui/core';
import { GetAtsTrackService } from '../../services/Ats/AtsTrackServices';
import { FilterAllAtsEmployeeSharedBasedOnJd } from '../../services/Ats/AtsEmployeeServices';

function AtsOverallReport() {

  const navigate = useNavigate()  
  const [homeData,sethomeData] = useState({})
  const [hr_stages,sethr_stages] = useState([])
  const [ats_dashboard_home_pipeline_service,setats_dashboard_home_pipeline_service] = useState({})
  const [ats_interview_employee,setats_interview_employee] = useState({})
  const [ats_employee_bank,setats_employee_bank] = useState({})
  const [ats_candidate_shared,setats_candidate_shared] = useState({})
  const [ats_active_job,setats_active_job] = useState({})


  const [page_ats_interview,setpage_ats_interview] = useState(1)
  const [page_ats_employee,setpage_ats_employee] = useState(1)
  const [page_ats_candidate_share,setpage_ats_candidate_share] = useState(1)
  const [page_ats_active_job,setpage_ats_active_job] = useState(1)



  let rupeeIndian = Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  });

  useEffect(()=>{
    getDashboarddata()
    getStages()
    getActiveJobsData()
    getAtsDashboardHomePipelineService()
    getTodayEmployeeBankData()
    getActiveInterviewCandidateAdminData()
    getTodayCandidateShared()
  },[])  


  useEffect(()=>{
    getTodayEmployeeBankData()
  },[page_ats_employee])

  useEffect(()=>{
    getActiveJobsData()
  },[page_ats_active_job])
  
  useEffect(()=>{
    getActiveInterviewCandidateAdminData()
  },[page_ats_interview])

  useEffect(()=>{
    getTodayCandidateShared()
  },[page_ats_candidate_share])

  async function getDashboarddata(){
    const response = await GetAtsDashboardAdmin()
    sethomeData(response?.data)
  }
  
  async function getStages(){
    const response = await GetAtsTrackService({page:1})
    sethr_stages(response?.data?.datas)
  }

  async function getTodayEmployeeBankData(){
    const response = await GetAtsDashboardTodayCandidateBankAdminService({from_date:new Date().toISOString()?.slice(0,10),to_date:new Date().toISOString()?.slice(0,10),page:page_ats_employee})
    setats_employee_bank(response?.data)
  }

  async function getActiveInterviewCandidateAdminData(){ 
    const response = await GetAtsDashboardUpcomingInterviewAdminService({page:page_ats_interview,stage:'Interview'})
    setats_interview_employee(response?.data)
  }

  async function getAtsDashboardHomePipelineService(){
    const response = await GetAtsDashboardHomePipelineAdminService()
    setats_dashboard_home_pipeline_service(response?.data)
  }

  async function getActiveJobsData(){
    const response = await GetAtsActiveobService({page:page_ats_active_job})
    setats_active_job(response?.data)
  }

  

  async function getTodayCandidateShared(){
    const response = await GetAtsJobCandidateAssignedStageService({page:page_ats_candidate_share,from_date:new Date().toISOString()?.slice(0,10),to_date:new Date().toISOString()?.slice(0,10),})
    setats_candidate_shared(response?.data)
  }

  // console.log("ats_active_job : ",ats_active_job)

  return (
    <div className='w-[98%] max-h-screen overflow-hidden'>
         <div className='flex'>
            <div className='min-w-44 overflow-hidden'>
            <AtsMenu />
            </div>
            <div className='w-[86.5%] pl-5 pr-3 mt-4 h-screen no-scrollbar pb-10 overflow-y-scroll'> 
               <h6 className='text-[13px] font-[800] mb-2'>Find the Over all Report Of ATS</h6>
               <div className='grid border-l border-r grid-cols-6 items-center justify-evenly border-t border-b border-slate-200'>
                 <div className='border-r bg-white  border-slate-200 px-2 py-2 w-full' onClick={()=>navigate('employee_based_on_hiring_stage',{state:'Spocs Screening'})}>
                      <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><LuScreenShare size={23} className='mr-2 text-[#4070cf] p-1 bg-slate-100' />Spocs Screening</h6>
                      <h6 className='text-[14px] mt-1'>{rupeeIndian?.format(JSON.stringify(homeData?.data?.data?.find((f)=>f._id.heading === 'Spocs Screening') !== undefined ? homeData?.data?.data?.find((f)=>f?._id?.heading === 'Spocs Screening')?.count : 0))?.split('.')[0].slice(1)}</h6>
                  </div>

                  <div className='border-r bg-white  border-slate-200 px-2 py-2 w-full' onClick={()=>navigate('employee_based_on_hiring_stage',{state:'Interview'})}>
                      <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><MdOutlineSubtitles size={23} className='mr-2 text-[#8a50b3] p-1 bg-slate-100' /> Interview</h6>
                      <h6 className='text-[14px] mt-1'>{rupeeIndian?.format(JSON.stringify(homeData?.data?.data?.find((f)=>f._id.heading === "Interview") !== undefined ? homeData?.data?.data?.find((f)=>f?._id?.heading === "Interview")?.count : 0))?.split('.')[0].slice(1)}</h6>
                  </div>

                  <div className='border-r bg-white  border-slate-200 px-2 py-2 w-full' onClick={()=>navigate('employee_based_on_hiring_stage',{state:'Offered'})}>
                      <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><AiOutlineInteraction size={23} className='mr-2 text-[#38b8d1] p-1 bg-slate-100' /> Offered</h6>
                      <h6 className='text-[14px] mt-1'>{rupeeIndian?.format(JSON.stringify(homeData?.data?.data?.find((f)=>f._id.heading === 'Offered') !== undefined ? homeData?.data?.data?.find((f)=>f?._id?.heading === 'Offered')?.count : 0))?.split('.')[0].slice(1)}</h6>
                  </div>

                  <div className='border-r bg-white  border-slate-200 px-2 py-2 w-full' onClick={()=>navigate('employee_based_on_hiring_stage',{state:'Onboard'})}>
                      <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><MdOutlineLocalOffer size={23} className='mr-2 text-[#47c98a] p-1 bg-slate-100' /> Onboard</h6>
                      <h6 className='text-[14px] mt-1'>{rupeeIndian?.format(JSON.stringify(homeData?.data?.data?.find((f)=>f._id.heading === 'Onboard') !== undefined ? homeData?.data?.data?.find((f)=>f?._id?.heading === 'Onboard')?.count : 0))?.split('.')[0].slice(1)}</h6>
                  </div>

                  <div className='border-r bg-white  border-slate-200 px-2 py-2 w-full' onClick={()=>navigate('employee_based_on_hiring_stage',{state:'Invoice'})}>
                      <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><BiSelectMultiple size={23} className='mr-2 text-[#de9835] p-1 bg-slate-100' /> Invoice</h6>
                      <h6 className='text-[14px] mt-1'>{rupeeIndian?.format(JSON.stringify(homeData?.data?.data?.find((f)=>f._id.heading === 'Invoice') !== undefined ? homeData?.data?.data?.find((f)=>f?._id?.heading === 'Invoice')?.count : 0))?.split('.')[0].slice(1)}</h6>
                  </div>

                  <div className='border-r bg-white  border-slate-200 px-2 py-2 w-full' >
                      <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><TbPlayerEject size={23} className='mr-2 text-[#d9573d] p-1 bg-slate-100' /> Employee Bank</h6>
                      <h6 className='text-[14px] mt-1'>{rupeeIndian?.format(JSON.stringify(homeData?.data?.ats_employee_bank_total))?.split('.')[0].slice(1)}</h6>
                  </div>
                 
              </div> 

                <div className='border border-slate-200 p-2  bg-white h-[50vh] justify-between my-4'>
              <h6 className='text-[14px] my-2 font-[600]'>Pipeline View of Active Clients</h6>

              <div className='flex items-center justify-between -pt-5 border-t border-b'>
                <h6 className='text-[12px] font-[500]  w-[20%] border-r  py-[5px]'>Requirment / Client Information</h6>
                <div className='w-[80%] flex items-center justify-evenly'>
                {hr_stages?.map((s)=>(
                <h6 key={s._id} className='text-[12px] border-t-2 w-[100%] font-[600]  py-[5px] text-gray-700 text-center py-1 mx-[2px]' style={{borderTopColor:`${s?.color}`}}>{s?.heading}</h6>
                ))}
                </div>
              </div>
               
              <div  className='max-h-[37vh] overflow-y-scroll no-scrollbar '>
              {ats_dashboard_home_pipeline_service?.datas?.map((d,i)=>(
              <div key={i} className={`flex items-center group justify-between py-2  ${i == 0 && 'mt-1 border-t'} border-b`}>
                <h6 onClick={()=>navigate('/ats/job_description/detail',{state:{data:d?._id}})} className='text-[11px] cursor-pointer decoration-blue-500 font-[600] relative w-[20%] group-hover:underline '><p className='max-w-[80%]' ><span className='text-blue-500'>{d?.job_title}</span> ({d?.ats_hr_client?.company_name}) ({d?.no_of_opening})</p> <Tooltip title='Assign Candidate'><span onClick={()=>navigate('/ats/employee_managment')} className='absolute right-1 top-1 text-gray-600 bg-gray-200 p-1' > <AiOutlineUserAdd size={10} /></span></Tooltip>  </h6>
                <div className='w-[80%] flex items-center justify-evenly'>
                {hr_stages?.map((s,i)=>(
                  <div key={i} className='w-[100%] flex items-center justify-center'>
                  {(d?.ats_hr_client_candidate?.find((f)=>f?._id?.heading === s?.heading)) !== undefined &&
                  <div className='w-[100%] flex items-center justify-center'>  
                      <div className='relative w-[90px] h-[31.5px] ml-5 opacity-70 hover:opacity-100'>  
                      <h6 className='text-[12px] w-[90px] h-[31.5px] -ml-5 font-[800] overflow-hidden relative  text-center  mx-[2px] py-2 pl-4 ' style={{background:`${s?.color}`,}}> 
                      {(d?.ats_hr_client_candidate?.find((f)=>f?._id?.heading === s?.heading)) !== undefined &&  (d?.ats_hr_client_candidate?.find((f)=>f?._id?.heading === s?.heading))?.count} 
                      <p className='w-[32px] rotate-[43deg] -left-3 absolute top-0 h-[100%] bg-white text-white'>1</p> 
                      </h6>
                      <h6 className='w-[22.4px] rotate-[45deg] right-[9px] absolute top-[4.5px] h-[22.2px] z-40 bg-red-100 text-white' style={{background:`${s?.color}`,}}></h6> 
                      </div>
                  </div>}
                  </div>
                ))}
                </div>
              </div>))}
              </div>

            

               </div>  

                <div className='flex items-center justify-between my-4'>
                <div className='min-w-[49.5%] relative bg-white h-[40vh] max-w-[49.5%] p-2 mr-[0.5%] border  border-slate-200 overflow-hidden'>
                  <h6 className='text-[14px] font-[600] relative'>Upcoming Interviews ({ats_interview_employee?.pagination?.total}) <IconButton size={'small'} onClick={()=>setpage_ats_employee(1)}> <AiOutlineReload size={13} className='ml-2 cursor-pointer ' /></IconButton></h6>
                  <div className='overflow-x-scroll no-scrollbar'>
                  {ats_interview_employee?.datas?.length >  0 &&
                  <div className={`w-max   ${ats_interview_employee?.datas?.length > 0 ? 'overflow-y-scroll' : 'overflow-hidden'} no-scrollbar  `}>
                      <div className='sticky  bg-white w-[100%]  top-0 z-30 flex  py-[-5px] border-t mt-2 border-b border-slate-200 relative'>
                        <h6 className='sticky top-0 z-30  text-[11px] min-w-[140px] max-w-[140px] px-2 py-1 font-semibold text-gray-400 uppercase border-r border-slate-200'>Employee Name</h6>
                        <h6 className='sticky top-0 z-30  text-[11px] min-w-[150px] max-w-[150px] px-2 py-1 font-semibold text-gray-400 uppercase border-r border-slate-200'> Company Name</h6>
                        <h6 className='sticky top-0 z-30  text-[11px] min-w-[150px] max-w-[150px] px-2 py-1 font-semibold text-gray-400 uppercase border-r border-slate-200'> Job Title</h6>
                        <h6 className='sticky top-0 z-30  text-[11px] min-w-[150px] max-w-[150px] px-2 py-1 font-semibold text-gray-400 uppercase border-r border-slate-200'> Interview Stage</h6>
                        <h6 className='sticky top-0 z-30  text-[11px] min-w-[140px] max-w-[140px] px-2 py-1 font-semibold text-gray-400 uppercase border-r border-slate-200'> Expierence (years)</h6>
                        <h6 className='sticky top-0 z-30  text-[11px] min-w-[130px] max-w-[130px] px-2 py-1 font-semibold text-gray-400 uppercase '> Package (LPA)</h6>
                      </div>
                      <div className='max-h-[25vh]'>
                      {ats_interview_employee?.datas?.map((d,i)=>(
                      <div onClick={()=>navigate('/ats/job_description/detail',{state:{data:d.ats_hr_client_job}})}  key={i} className={`w-[100%] flex group hover:bg-gray-50  ${i=== 0 && 'border-t mt-1'} cursor-pointer border-b border-slate-200 z-0 relative`}>
                        <h6 className='text-[12px] min-w-[140px] max-w-[140px] group-hover:underline text-blue-500 px-2 py-1 font-[600] capitalize border-r border-slate-200'>{d?.ats_hr_employee?.employee_name}</h6>
                        <h6 className='text-[11px] min-w-[150px] max-w-[150px] px-2 py-1 font-[600]  border-r border-slate-200'> {d?.ats_hr_client_job?.ats_hr_client?.company_name}</h6>
                        <h6 className='text-[11px] min-w-[150px] max-w-[150px] px-2 py-1 font-[600]  border-r border-slate-200'> {d?.ats_hr_client_job?.job_title}</h6>
                        <h6 className='text-[11px] min-w-[150px] max-w-[150px] px-2 py-1 font-[600]  border-r border-slate-200'> {d?.heading_Stage} ({d?.sub_heading_Stage})</h6>
                        <h6 className='text-[11px] min-w-[140px] max-w-[140px] px-2 py-1 font-[600]  border-r border-slate-200'> {d?.ats_hr_employee?.experience}</h6>
                        <h6 className='text-[11px] min-w-[130px] max-w-[130px] px-2 py-1 font-[600]  '> {d?.ats_hr_employee?.expected_ctc} </h6>
                      </div>))}
                      </div>


                   
                     

                      <div className='flex absolute bottom-2 right-2 items-center bg-white'>
                        <FiChevronLeft onClick={()=>( page_ats_interview !== 1) && setpage_ats_interview(page_ats_interview - 1) } className={!(page_ats_interview > 1 && ats_interview_employee?.pagination?.totalPages > 1) ? 'text-gray-400' : ''} /> 
                           <span className='text-[11px] mx-2 bg-gray-100 p-1 w-[20px] text-center'>{page_ats_interview}</span>
                        <FiChevronRight onClick={()=>(ats_interview_employee?.pagination?.totalPages !== page_ats_interview && page_ats_interview <= ats_interview_employee?.pagination?.totalPages) && setpage_ats_interview(page_ats_interview + 1) } className={(ats_interview_employee?.pagination?.totalPages == page_ats_interview) ? 'cursor-pointer text-gray-400' : 'cursor-pointer'} /> 
                      </div>
                      
                  </div>}
                  
                  {ats_interview_employee?.datas?.length === 0 &&
                       <div className='flex flex-col items-center justify-center'>
                          <img src={'https://fidecrmfiles.s3.amazonaws.com/no_data_found.png'} className='w-40 h-40 object-contain mt-6' /> 
                          <h6 className='font-bold text-[14px] -mt-2'>No data found</h6>
                        </div>
                      }
                  </div>
                </div>

                <div className='min-w-[49.5%] relative bg-white h-[40vh] max-w-[49.5%] p-2 ml-[0.5%] border  border-slate-200 overflow-hidden'>
                  <h6 className='text-[13px] font-[600] relative'>Candidate Bank ({ats_employee_bank?.pagination?.total}) <IconButton size={'small'} onClick={()=>setpage_ats_employee(1)}> <AiOutlineReload size={13} className='ml-2 cursor-pointer ' /></IconButton></h6>
                  {ats_employee_bank?.datas?.length > 0 &&
                  <div className='overflow-x-scroll no-scrollbar'>
                  <div className={`w-max  ${ats_employee_bank?.datas?.length > 0 ? 'overflow-y-scroll' : 'overflow-hidden'} no-scrollbar  `}>
                      <div className='sticky bg-white w-[100%] top-0 z-30 flex  py-[-5px] border-t mt-2 border-b border-slate-200 relative'>
                        {/* <h6 className='sticky top-0 uppercase text-gray-400 z-30  text-[11px] min-w-[60px] max-w-[60px] px-2 py-1 font-[600] border-r'>Sl No</h6> */}
                        <h6 className='sticky top-0 uppercase text-gray-400 z-30  text-[11px] min-w-[140px] max-w-[140px] px-2 py-1 font-[600] border-r'>Employee Name</h6>
                        <h6 className='sticky top-0 uppercase text-gray-400 z-30  text-[11px] min-w-[150px] max-w-[150px] px-2 py-1 font-[600] border-r'> Designation</h6>
                        <h6 className='sticky top-0 uppercase text-gray-400 z-30  text-[11px] min-w-[150px] max-w-[150px] px-2 py-1 font-[600] border-r'> Current Location</h6>
                        <h6 className='sticky top-0 uppercase text-gray-400 z-30  text-[11px] min-w-[170px] max-w-[170px] px-2 py-1 font-[600] border-r'> Prefeered Location</h6>
                        <h6 className='sticky top-0 uppercase text-gray-400 z-30  text-[11px] min-w-[130px] max-w-[130px] px-2 py-1 font-[600] border-r'> Package (LPA)</h6>
                        <h6 className='sticky top-0 uppercase text-gray-400 z-30  text-[11px] min-w-[140px] max-w-[140px] px-2 py-1 font-[600] '> Experience (YEARS)</h6>
                        <h6 className='sticky top-0 uppercase text-gray-400 z-30  text-[11px] min-w-[140px] max-w-[140px] px-2 py-1 font-[600] '> Created By</h6>
                      </div>
                      <div className='max-h-[25vh]'>
                      {ats_employee_bank?.datas?.map((d,i)=>(
                      <div onClick={()=>navigate('/ats/employee_managment',)} key={i} className={`w-[100%]  flex py-[-5px] ${i === 0 && 'border-t mt-1'} group cursor-pointer hover:bg-gray-50  border-b border-slate-200 relative`}>
                        {/* <h6 className='text-[11px] min-w-[60px] max-w-[60px] px-2 py-1 font-[600] border-r'>{page_ats_employee === 1 ? i+1 : ((i+1) + (ats_employee_bank?.datas?.pagination?.limit * (page_ats_employee-1)))} </h6> */}
                        <h6 className='text-[11px] min-w-[140px] max-w-[140px] px-2 py-1 text-blue-500 px-2 py-1 font-[600] capitalize border-r border-slate-200 group-hover:underline '>{d?.employee_name}</h6>
                        <h6 className='text-[11px] min-w-[150px] max-w-[150px] px-2 py-1  border-r'> {d?.designation}</h6>
                        <h6 className='text-[11px] min-w-[150px] max-w-[150px] px-2 py-1 font-medium border-r'> {d?.current_location}</h6>
                        <h6 className='text-[11px] min-w-[170px] max-w-[170px] px-2 py-1 font-medium border-r'> {d?.preferred_location}</h6>
                        <h6 className='text-[11px] min-w-[130px] max-w-[130px] px-2 py-1 font-[600] border-r'> {d?.experience}</h6>
                        <h6 className='text-[11px] min-w-[140px] max-w-[140px] px-2 py-1 font-[600] '> {d?.expected_ctc} </h6>
                        <h6 className='text-[11px] min-w-[140px] max-w-[140px] px-2 py-1 font-[600] '> {d?.created_by?.name} </h6>
                      </div>))}



                      {/* <div className='flex absolute bottom-2 right-2 items-center bg-white'>
                        <FiChevronLeft onClick={()=>( page_ats_interview !== 1) && setpage_ats_interview(page_ats_interview - 1) } className={!(page_ats_interview > 1 && ats_interview_employee?.pagination?.totalPages > 1) ? 'text-gray-400' : ''} /> 
                           <span className='text-[11px] mx-2 bg-gray-100 p-1 w-[20px] text-center'>{page_ats_interview}</span>
                        <FiChevronRight onClick={()=>(ats_interview_employee?.pagination?.totalPages !== page_ats_interview && page_ats_interview <= ats_interview_employee?.pagination?.totalPages) && setpage_ats_interview(page_ats_interview + 1) } className={(ats_interview_employee?.pagination?.totalPages == page_ats_interview) ? 'cursor-pointer text-gray-400' : 'cursor-pointer'} /> 
                      </div> */}
                      
                     
                      <div className='flex absolute bottom-2 right-2 items-center bg-white'>
                        <FiChevronLeft onClick={()=>( page_ats_employee !== 1) && setpage_ats_employee(page_ats_employee - 1) } className={!(page_ats_employee > 1 && ats_employee_bank?.pagination?.totalPages > 1) ? 'text-gray-400' : ''} /> 
                           <span className='text-[11px] mx-2 bg-gray-100 p-1 w-[20px] text-center'>{page_ats_employee}</span>
                        <FiChevronRight onClick={()=>(ats_employee_bank?.pagination?.totalPages !== page_ats_employee && page_ats_employee <= ats_employee_bank?.pagination?.totalPages) && setpage_ats_employee(page_ats_employee + 1) } className={(ats_employee_bank?.pagination?.totalPages === page_ats_employee) ? 'cursor-pointer text-gray-400' : 'cursor-pointer'} /> 
                      </div>
                      </div>
                      
                  </div>
                  </div>}

                  {ats_employee_bank?.datas?.length === 0 &&
                       <div className='flex flex-col items-center justify-center'>
                          <img src={'https://fidecrmfiles.s3.amazonaws.com/no_data_found.png'} className='w-40 h-40 object-contain mt-6' /> 
                          <h6 className='font-bold text-[14px] -mt-2'>No data found</h6>
                        </div>
                      }
                </div>
                </div>

                 <div className='flex items-center  justify-between my-4'>
                <div className='min-w-[49.5%] relative bg-white h-[40vh] max-w-[49.5%] p-2 mr-[0.5%] border  border-slate-200 overflow-hidden'>
                  <h6 className='text-[13px] font-[600] relative'>Active Jobs ({ats_active_job?.pagination?.total})  <IconButton size={'small'} onClick={()=>setpage_ats_active_job(1)}> <AiOutlineReload size={13} className='ml-2 cursor-pointer ' /></IconButton></h6>
                  <div className='overflow-x-scroll  no-scrollbar mt-2'>
                  {ats_active_job?.datas?.length > 0 &&
                  <div className={`w-max  ${ats_active_job?.datas?.length > 0 ? 'overflow-y-scroll' : 'overflow-hidden'} no-scrollbar  `}>
                      <div className='w-[100%]  top-0 z-30 flex py-[-5px] border-t border-b border-slate-200 relative'>
                        <h6 className='text-[11px] uppercase text-gray-400 min-w-[150px] max-w-[150px] px-2 py-1 font-semibold border-r border-slate-200'> Company Name</h6>
                        <h6 className='text-[11px] uppercase text-gray-400 min-w-[150px] max-w-[150px] px-2 py-1 font-semibold border-r border-slate-200'> Client Name</h6>
                        <h6 className='text-[11px] uppercase text-gray-400 min-w-[150px] max-w-[150px] px-2 py-1 font-semibold border-r border-slate-200'> Handeled By</h6>
                        <h6 className='text-[11px] uppercase text-gray-400 min-w-[150px] max-w-[150px] px-2 py-1 font-semibold border-r border-slate-200'> Job Title</h6>
                        <h6 className='text-[11px] uppercase text-gray-400 min-w-[150px] max-w-[150px] px-2 py-1 font-semibold border-r border-slate-200'> Interview Stage</h6>
                        <h6 className='text-[11px] uppercase text-gray-400 min-w-[140px] max-w-[140px] px-2 py-1 font-semibold border-r border-slate-200'> Expierence (YEARS)</h6>
                        <h6 className='text-[11px] uppercase text-gray-400 min-w-[120px] max-w-[120px] px-2 py-1 font-semibold border-r border-slate-200'> NO OF Opening</h6>
                        <h6 className='text-[11px] uppercase text-gray-400 min-w-[100px] max-w-[100px] px-2 py-1 font-semibold border-r border-slate-200'> JOB TYPE</h6>
                        <h6 className='text-[11px] uppercase text-gray-400 min-w-[120px] max-w-[120px] px-2 py-1 font-semibold border-r border-slate-200'> NOTICE PERIOD</h6>
                        <h6 className='text-[11px] uppercase text-gray-400 min-w-[100px] max-w-[100px] px-2 py-1 font-semibold border-r border-slate-200'> Location</h6>
                        <h6 className='text-[11px] uppercase text-gray-400 min-w-[140px] max-w-[140px] px-2 py-1 font-semibold'> cutt off package </h6>
                      </div>

                      <div className='max-h-[23vh]'>

                      {ats_active_job?.datas?.map((d,i)=>(
                      <div key={i} onClick={()=>navigate('/ats/job_description/detail',{state:{data:d?._id}})} className={`w-[100%] group cursor-pointer hover:bg-gray-50 top-0 z-30 flex py-[-5px] ${i=== 0 && 'border-t mt-1'}  border-b border-slate-200 relative`}>
                        <h6 className='text-[12px]  min-w-[150px] max-w-[150px] px-2 py-1 text-blue-500 font-[600] border-r border-slate-200 group-hover:underline'> {d?.ats_hr_client?.company_name}</h6>
                        <h6 className='text-[11px]  min-w-[150px] max-w-[150px] px-2 py-1 font-[500] border-r border-slate-200'>{d?.ats_hr_client?.client_name}</h6>
                        <h6 className='text-[11px]  min-w-[150px] max-w-[150px] px-2 py-1 font-[500] border-r border-slate-200'>{d?.jd_handeled_by?.map((e)=><span key={e} className='bg-gray-100 mr-1 text-[10px] font-[600] px-[5px] py-[2px]'>{e?.name}</span>)}</h6>
                        <h6 className='text-[11px]  min-w-[150px] max-w-[150px] px-2 py-1 font-[500] border-r border-slate-200'> {d?.job_title}</h6>
                        <h6 className='text-[11px]  min-w-[150px] max-w-[150px] px-2 py-1 font-[500] border-r border-slate-200'> <span className='bg-slate-100 text-[10px] font-[600] px-[5px] py-[2px]'>{d?.stage}</span></h6>
                        <h6 className='text-[11px]  min-w-[140px] max-w-[140px] px-2 py-1 font-[600] border-r border-slate-200'> {d?.experience}</h6>
                        <h6 className='text-[11px]  min-w-[120px] max-w-[120px] px-2 py-1 font-[600] border-r border-slate-200'> {d?.no_of_opening}</h6>
                        <h6 className='text-[11px]  min-w-[100px] max-w-[100px] px-2 py-1 font-[500] border-r border-slate-200'> {d?.job_type}</h6>
                        <h6 className='text-[11px]  min-w-[120px] max-w-[120px] px-2 py-1 font-[600] border-r border-slate-200'> {d?.notice_period}</h6>
                        <h6 className='text-[11px]  min-w-[100px] max-w-[100px] px-2 py-1  border-r border-slate-200  truncate'>  {d?.location?.split(":")?.map((e)=><span key={e} className='bg-gray-100 mr-1 text-[10px] font-[600] px-[5px] py-[2px]'>{e}</span>)}</h6>
                        <h6 className='text-[11px]  min-w-[140px] max-w-[140px] px-2 py-1 font-[500]'> {d?.cutoff_salary} </h6>
                      </div>
                     ))}
                     </div>

                     <div className='flex absolute bottom-2 right-2 items-center bg-white'>
                        <FiChevronLeft onClick={()=>(page_ats_active_job !== 1) && setpage_ats_active_job(page_ats_active_job - 1) } className={!(page_ats_active_job > 1 && ats_active_job?.pagination?.totalPages > 1) ? 'text-gray-400' : ''} /> 
                           <span className='text-[11px] mx-2 bg-gray-100 p-1 w-[20px] text-center'>{page_ats_active_job}</span>
                        <FiChevronRight onClick={()=>(ats_active_job?.pagination?.totalPages !== page_ats_active_job && page_ats_active_job <= ats_active_job?.pagination?.totalPages) && setpage_ats_active_job(page_ats_active_job + 1) } className={ats_active_job?.pagination?.totalPages === page_ats_active_job ? 'cursor-pointer text-gray-400' : 'cursor-pointer'} /> 
                      </div>
                  </div>}

                  {ats_active_job?.datas?.length === 0 &&
                       <div className='flex flex-col items-center justify-center'>
                          <img src={'https://fidecrmfiles.s3.amazonaws.com/no_data_found.png'} className='w-40 h-40 object-contain mt-6' /> 
                          <h6 className='font-bold text-[14px] -mt-2'>No data found</h6>
                        </div>
                      }

                  </div>


                
                </div>

                <div className='min-w-[49.5%] relative  bg-white h-[40vh] max-w-[49.5%] p-2 ml-[0.5%] border  border-slate-200 overflow-hidden'>
                  <h6 className='text-[13px] font-[600] flex items-center'>Candidate Shared ({ats_candidate_shared?.pagination?.total}) <IconButton size={'small'} onClick={()=>setpage_ats_employee(1)}> <AiOutlineReload size={13} className='ml-2 cursor-pointer ' /></IconButton> </h6>
                  <div className='overflow-x-scroll no-scrollbar'>
                  {ats_candidate_shared?.datas?.length > 0 &&
                  <div className={`w-max  ${ats_candidate_shared?.datas?.length > 0 ? 'overflow-y-scroll' : 'overflow-hidden'} no-scrollbar  `}>
                      <div className='sticky bg-white z-30 w-[100%] top-0 z-30 flex  py-[-5px] border-t mt-2 border-b border-slate-200 relative'>
                        {/* <h6 className='sticky top-0 uppercase text-gray-400 z-30  text-[11px] min-w-[60px] max-w-[60px] px-2 py-1 font-[600] border-r'>Sl No</h6> */}
                        <h6 className='sticky top-0 uppercase text-gray-400 z-30  text-[11px] min-w-[140px] max-w-[140px] px-2 py-1 font-[600] border-r'>Employee Name</h6>
                        <h6 className='sticky top-0 uppercase text-gray-400 z-30  text-[11px] min-w-[150px] max-w-[150px] px-2 py-1 font-[600] border-r'> Designation</h6>
                        <h6 className='sticky top-0 uppercase text-gray-400 z-30  text-[11px] min-w-[150px] max-w-[150px] px-2 py-1 font-[600] border-r'> Company Name</h6>
                        <h6 className='sticky top-0 uppercase text-gray-400 z-30  text-[11px] min-w-[150px] max-w-[150px] px-2 py-1 font-[600] border-r'> Job Description</h6>
                        <h6 className='sticky top-0 uppercase text-gray-400 z-30  text-[11px] min-w-[150px] max-w-[150px] px-2 py-1 font-[600] border-r'> Current Location</h6>
                        <h6 className='sticky top-0 uppercase text-gray-400 z-30  text-[11px] min-w-[170px] max-w-[170px] px-2 py-1 font-[600] border-r'> Prefeered Location</h6>
                        <h6 className='sticky top-0 uppercase text-gray-400 z-30  text-[11px] min-w-[130px] max-w-[130px] px-2 py-1 font-[600] border-r'> Package (LPA)</h6>
                        <h6 className='sticky top-0 uppercase text-gray-400 z-30  text-[11px] min-w-[140px] max-w-[140px] px-2 py-1 font-[600] border-r'> Experience (YEARS)</h6>
                        <h6 className='sticky top-0 uppercase text-gray-400 z-30  text-[11px] min-w-[140px] max-w-[140px] px-2 py-1 font-[600] '> Sourced By</h6>
                      </div>
                      <div className='max-h-[23vh]'>
                      {ats_candidate_shared?.datas?.map((d,i)=>(
                      <div onClick={()=>navigate('/ats/job_description/detail',{state:{data:d.ats_hr_client_job}})} key={i} className={`w-[100%]  flex py-[-5px] ${i=== 0 && 'border-t mt-1'} group  cursor-pointer hover:bg-gray-50  border-b border-slate-200 relative`}>
                        {/* <h6 className='text-[11px] min-w-[60px] max-w-[60px] px-2 py-1 font-[600] border-r'>{page_ats_employee === 1 ? i+1 : ((i+1) + (ats_employee_bank?.datas?.pagination?.limit * (page_ats_candidate_share-1)))} </h6> */}
                        <h6 className='text-[12px] min-w-[140px] max-w-[140px] px-2 py-1 text-blue-500 font-[600] border-r border-slate-200 group-hover:underline'>{d?.ats_hr_employee?.employee_name}</h6>
                        <h6 className='text-[11px] min-w-[150px] max-w-[150px] px-2 py-1  border-r'> {d?.ats_hr_employee?.designation}</h6>
                        <h6 className='text-[11px] min-w-[150px] max-w-[150px] px-2 py-1  border-r'> {d?.ats_hr_client_job?.ats_hr_client?.company_name}</h6>
                        <h6 className='text-[11px] min-w-[150px] max-w-[150px] px-2 py-1  border-r'> {d?.ats_hr_client_job?.job_title}</h6>
                        <h6 className='text-[11px] min-w-[150px] max-w-[150px] px-2 py-1 font-medium border-r'> {d?.ats_hr_employee?.current_location}</h6>
                        <h6 className='text-[11px] min-w-[170px] max-w-[170px] px-2 py-1 font-medium border-r'> {d?.ats_hr_employee?.preferred_location}</h6>
                        <h6 className='text-[11px] min-w-[130px] max-w-[130px] px-2 py-1 font-[600] border-r'> {d?.ats_hr_employee?.experience}</h6>
                        <h6 className='text-[11px] min-w-[140px] max-w-[140px] px-2 py-1 font-[600] border-r'> {d?.ats_hr_employee?.expected_ctc} </h6>
                        <h6 className='sticky top-0 uppercase text-gray-400 z-30  text-[11px] min-w-[140px] max-w-[140px] px-2 py-1 font-[600] '>{d?.created_by?.name}</h6>
                      </div>))}
                      </div>

                      <div className='flex absolute bottom-2 right-2 items-center bg-white'>
                        <FiChevronLeft onClick={()=>(page_ats_candidate_share !== 1) && setpage_ats_active_job(page_ats_active_job - 1) } className={!(page_ats_candidate_share > 1 && ats_candidate_shared?.pagination?.totalPages > 1) ? 'text-gray-400' : ''} /> 
                           <span className='text-[11px] mx-2 bg-gray-100 p-1 w-[20px] text-center'>{page_ats_candidate_share}</span>
                        <FiChevronRight onClick={()=>(ats_candidate_shared?.pagination?.totalPages !== page_ats_active_job && page_ats_candidate_share <= ats_candidate_shared?.pagination?.totalPages) && setpage_ats_candidate_share(page_ats_candidate_share + 1) } className={ats_candidate_shared?.pagination?.totalPages === page_ats_candidate_share ? 'cursor-pointer text-gray-400' : 'cursor-pointer'} /> 
                      </div>
                      
                  </div>}
                  {ats_candidate_shared?.datas?.length === 0 &&
                       <div className='flex flex-col items-center justify-center'>
                          <img src={'https://fidecrmfiles.s3.amazonaws.com/no_data_found.png'} className='w-40 h-40 object-contain mt-6' /> 
                          <h6 className='font-bold text-[14px] -mt-2'>No data found</h6>
                        </div>
                      }

                  </div>
                </div>
              </div> 


            </div>
          </div>   
    </div>
  )
}

export default AtsOverallReport