import React,{useState,useEffect} from 'react'
import TaskMenu from './TaskMenu'
import { DatePicker,Modal } from 'antd'
import { IconButton, Tooltip, } from '@material-ui/core';
import {  ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../components/button';
import {FiChevronRight,FiChevronLeft} from 'react-icons/fi';
import { useLocation, useNavigate } from 'react-router-dom';
import {AiOutlineFilter} from 'react-icons/ai';
import {MdOutlineResetTv} from 'react-icons/md';
import { DeleteTeamTaskService, GetTeamTaskService } from '../../services/TeamTaskServices';
import moment from 'moment';
import { AiOutlineEdit,AiOutlineDelete,AiFillWarning } from 'react-icons/ai';
import toast from 'react-hot-toast';
import { RiBillLine } from 'react-icons/ri';
// import {GoAlertFill} from 'react-icons/gi';

function TeamTaskList() {

  const {pathname} = useLocation()
  const navigator = useNavigate()
  const path = pathname.split('/')[pathname.split('/').length - 1]
  const path1 = pathname.split('/')[pathname.split('/').length - 2]
  const [search,setsearch] = useState({text:'',from_date:'',to_date:'',activate:false})

  const [page,setpage] = useState(1)
  const [data,setdata] = useState([])
  const [modal,setmodal] = useState(false)
  const [selected_data,setselected_data] = useState('')

  useEffect(()=>{
    getdata(1)
  },[page])

  async function getdata(page){
      const response = await GetTeamTaskService({page:page})
      setdata(response.data)
  }
  
  async function deletedProject(){
    const response = await DeleteTeamTaskService(selected_data?._id)
    if(response?.status == 200){
      toast.success("Deleted Successfully")
      setmodal(false)
      getdata(page)
    }else{
      setmodal(false)
      toast.error("Something Went Wrong")
    }
  }


  return (
    <div className='w-[100%]'>
        <Modal width={350} open={modal} closable={false} footer={false}>
           <div>
             <h6 className='text-[15px] font-[800] flex items-center'><AiFillWarning className="text-red-600 mr-1" /> Move to trash?</h6>
             <h6 className='text-[12px] mt-2 bg-gray-100 p-2'>The project along with its issues, components, attachments, and versions will be available in the trash for 60 days after which it will be permanently deleted.</h6>

             <h6 className='text-[10px] mt-2'>Only Project admins can restore the project from the trash.</h6>
             
             <div className='flex mt-2 border-t pt-2 justify-content-end'>
              <div className='mr-2'>
              <ButtonOutlinedAutoWidth onClick={()=>setmodal(false)} btnName="Cancel" />
              </div>
              <ButtonFilledAutoWidth btnName="Confirm" onClick={deletedProject} />
             </div>
           </div>
        </Modal>
        <div className='flex'>
            <div className='min-w-44'>
            <TaskMenu />
            </div>
            <div className='w-[87%] pl-5 mt-4'>
            <div className='flex items-center -mt-1 border-b justify-between pb-1'>
                <h6 className='font-[800] text-[13px]'>Total Projects  ({data?.pagination?.total})</h6>

                <div className='flex items-center text-[12px]'>

                    <h6 className='mr-2 font-[600]'>{page == 1 ? data?.data?.length > 0 ? 1 : 0 : (page - 1) * data?.pagination?.limit } - {data?.pagination?.limit} of {data?.pagination?.total}  </h6>

                    <div>
                    <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>
                    <IconButton onClick={()=>{ page < data?.pagination?.totalPages  ? setpage(page+1) : console.log('')}}><FiChevronRight className={`${(data?.pagination?.totalPages === page || data?.data?.length === 0) ? 'opacity-50 ' : 'opacity-100'}`}  size={16} /></IconButton>

                    </div>
                    <DatePicker  size='small' style={{fontSize:'11px'}} ampm={false} placeholder='From Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.from_date} onChange={(v) => {console.log('v na',new Date(v).toLocaleDateString());setsearch({...search,from_date:v})}} /> 

                    <DatePicker size='small' style={{fontSize:'11px'}} ampm={false} placeholder='To Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.to_date} onChange={(v) => {console.log('v na',new Date(v).toLocaleDateString());setsearch({...search,to_date:v})}} /> 

                    
                    <div className='mr-2'>
                        

                    <input  id="search_text" placeholder='Search text' type='text' value={search.text} onChange={(e)=>setsearch({...search,text:e.target.value})} className='border py-[3px] focus:ring-0 focus:outline-0 text-[14px]  w-[100px] px-1 rounded-md border-slate-300' />
                

                    </div>  
                    <div className='mr-2'>
                     <Tooltip title="Apply Filter" >
                     <AiOutlineFilter size={29} className='cursor-pointer rounded border border-slate-300 bg-slate-200 p-[5px]' />   
                     </Tooltip>
                    </div>
                    <div className='mr-2'>
                     <Tooltip title="Reset Filter" >
                     <MdOutlineResetTv size={29} className='cursor-pointer rounded border border-slate-300 bg-slate-200 p-[5px]' />
                     </Tooltip>
                    </div>
                    <ButtonFilledAutoWidth btnName="Create Project" onClick={()=>navigator('/tasks/projects/list/create')} />
                </div>
            </div>


        {data?.data?.length === 0 &&
          <div className='grid place-items-center mt-20  items-center justify-center'>
          <img src={'https://fidecrmfiles.s3.amazonaws.com/NoDataFound1.png'} className='w-40 h-40 object-contain' /> 
          <h6 className='font-bold text-[14px] -mt-10'>No data found</h6>
          <h6 className='font-[500] w-[70%] text-center text-slate-700 text-[12.5px] -mt-2'>Oops we couldn't find any project based on your current stage please add the data to see list here.</h6>
        </div>
        }


        {data?.data?.length > 0 &&
        <div className='max-h-[85vh] mt-4 border-t   border-l border-r overflow-y-scroll'>
          <div className='sticky top-0 z-50 flex  bg-white py-[-5px] border-b border-slate-200 relative'>
              <h6  className='top-0 bg-white z-50 text-[12px] w-16  px-2 py-1 font-[600] text-slate-600 border-r flex items-center justify-center'> SL NO</h6>
              <h6 className='sticky top-0 z-50  text-[12px] w-[170px]  px-2 py-1 font-[600] text-slate-600 border-r'>Project Name</h6>
              <h6 className='sticky top-0 z-50  text-[12px] w-[40px]  px-2 py-1 font-[600] text-slate-600 border-r'>Key</h6>
              <h6 className='sticky top-0 z-50  text-[12px] w-[250px]  px-2 py-1 font-[600] text-slate-600 border-r'>Description</h6>
              <h6 className='sticky top-0 z-50  text-[12px] w-[110px]  px-2 py-1 font-[600] text-slate-600 border-r'>Owner</h6>
              <h6 className='sticky top-0 z-50  text-[12px] w-[170px] px-2 py-1 font-[600] text-slate-600 border-r'>Team Members</h6>
              <h6 className='sticky top-0 z-50  text-[12px] w-[100px] px-2 py-1 font-[600] text-slate-600 border-r'>Status</h6>
              <h6 className='sticky top-0 z-50  text-[12px] w-[80px] px-2 py-1 font-[600] text-slate-600 border-r'>Important</h6>
              <h6 className='sticky top-0 z-50  text-[12px] w-[150px]  px-2 py-1 font-[600] text-slate-600 border-r'>Created On</h6>
              <h6 className='sticky top-0 z-50  text-[12px] w-[90px]  px-2 py-1 font-[600] text-slate-600 sticky right-0'>Actions</h6>
          </div>

          {data?.data?.map((d,i)=>(
          <div key={d?._id} className='flex  bg-white py-[-5px] border-b border-slate-200 relative'>
              <h6  className='bg-white z-50 text-[12px] w-16  px-2 py-1 font-[400]  border-r flex items-center justify-center'> {page === 1 ? i+1 : ((i+1) + (data?.pagination?.limit * (page-1)))}</h6>
              <h6 className='text-[12px] w-[170px]  px-2 py-1 font-[400]  border-r'>{d?.name}</h6>
              <h6 className='text-[12px] w-[42px]  px-2 py-1 font-[400]  border-r uppercase'> <span className='bg-gray-100 p-[4px] text-[10px] font-[800]'>{d?.key}</span> </h6>
              <h6 className='text-[12px] w-[250px]  px-2 py-1 font-[400]  border-r'>{d?.description}</h6>
              <h6 className='text-[12px] w-[110px]  px-2 py-1 font-[400] truncate border-r'>{d?.created_by?.name}</h6>
              <h6 className='text-[12px] w-[170px] px-2 py-1 font-[400]  border-r'>{d?.team_members?.length > 0 && 'Team Members'}</h6>
              <h6 className='text-[12px] w-[100px] px-2 py-1 font-[400]  border-r'> 
              {d?.status === '1' && <span className='bg-blue-100 p-[4px] text-[10px] font-[600]'>{'Pending'}</span>}
              {d?.status === '2' && <span className='bg-yellow-100 p-[4px] text-[10px] font-[600]'>{'In Progress'}</span>} {d?.status === '3' && <span className='bg-green-100 p-[4px] text-[10px] font-[600]'>{'Completed'}</span>} {d?.status === '4' && <span className='bg-red-100 p-[4px] text-[10px] font-[600]'>{'Completed'}</span>}</h6>
              <h6 className='text-[12px] w-[80px] px-2 py-1 font-[400]  border-r text-center'><p className='w-[13px] h-[13px] rounded-[2px] border p-[1.6px] flex items-center justify-center' > </p></h6>
              <h6 className='text-[12px] w-[150px] text-[9.5px] font-[800]  px-2 py-1 font-[400] truncate border-r'>{moment(d?.createdAt)?.format('lll')}</h6>
              <h6 className='text-[12px] flex items-center w-[90px]  px-2 py-1 font-[400]  sticky right-0'>
                <Tooltip title="Detail" >
                <span><RiBillLine onClick={()=>{navigator(`/tasks/projects/list/detail`,{state:d})}} className='mr-2' /></span>
                </Tooltip>

                <Tooltip title="Edit">
                <span><AiOutlineEdit className='mr-2' /></span>
                </Tooltip>
                
                <Tooltip title="Delete">
                <span><AiOutlineDelete onClick={()=>{setselected_data(d);setmodal(true)}} /></span>
                </Tooltip>
                </h6>

               
          </div>
          ))}

        </div>}
        </div>

        </div>    

    </div>
  )
}

export default TeamTaskList