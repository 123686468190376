import { deleteRequest, get, post, postfd, put } from "../helpers/apihelpers";

export const CreateMDMeetTaskService=async(data)=>{
    try {
        const result = await post(`api/md_meet/create`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateMDMeetTaskService=async(data,id)=>{
    try {
        const result = await post(`api/md_meet/update/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}


export const DeleteMDMeetTaskService=async(id)=>{
    try {
        const result = await deleteRequest(`api/md_meet/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}


export const GetMDMeetTaskService=async(page,from_date,to_date,search_text,type)=>{
    try {
        const result = await get(`api/md_meet/get?from_date=${from_date}&to_date=${to_date}&search_text=${search_text}&type=${type}&page=${page}`);
        return result;
    } catch (err) {
        return err.response;
    }
}


export const DownloadMDMeetTaskService=async(user,from_date,to_date)=>{
    try {
        const result = await get(`api/md_meet/download_excel?user=${user}&from_date=${from_date}&to_date=${to_date}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetMDMeetFollowUpTaskService=async(id)=>{
    try {
        const result = await get(`api/md_meet/get_followup/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const CreateMDMeetFollowUpTaskService=async(data)=>{
    try {
        const result = await post(`api/md_meet/create_followup`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateMDMeetFollowUpTaskService=async(id,data)=>{
    try {
        const result = await post(`api/md_meet/update_followup/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}


export const DeleteMDMeetFollowUpTaskService=async(id)=>{
    try {
        const result = await deleteRequest(`api/md_meet/delete_followup/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}