import React, { useState } from 'react'
import TaskMenu from './TaskMenu'
import { TextInput, TextInputWithPh } from '../../components/input'
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../components/button'
import {IoArrowBack} from 'react-icons/io5';
import {RiBillLine,RiStackshareLine,} from 'react-icons/ri';
import {GrDocumentUpdate} from 'react-icons/gr';
import {MdSchedule} from 'react-icons/md';
import {AiOutlineFileDone} from 'react-icons/ai';
import { CreateTeamTaskService } from '../../services/TeamTaskServices';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

function TeamTaskCE() {

  const navigate = useNavigate()
  
  const [data,setdata] = useState({name:'',key:'',description:'',image:''})  
  const [error,seterror] = useState({name:'',key:'',description:'',image:''})  
   
  function handlechange(e){
    if(e.target.name === 'name'){
      let key
      if(e?.target?.value?.split(' ')?.length > 1){
        key = e?.target?.value?.split(' ')[0][0] + e?.target?.value?.split(' ')[1][0]
      }else{
        key = e?.target?.value?.slice(0,2)
      }
      setdata({...data,[e.target.name]:e.target.value,key:key})
    }else{
      if(e.target.name === 'key' && e.target.value < 3){
        setdata({...data,key:e.target.value})
      }else{
        seterror({...error,[e.target.name]:'The key should be less than 3 characters'})
      }
    }
    seterror({...error,[e.target.name]:''})
  }

  async function submitform(){
    if(!data?.name){
      seterror({...error,name:'This field is required*'})
    }else if(!data?.key){
      seterror({...error,key:'This field is required*'})
    }else{
      const response = await CreateTeamTaskService(data)
      if(response?.status === 201){
        resetform()
        toast.success("Project Created Successfully")
      }
    }
  }

  function resetform(){
    setdata({name:'',key:'',description:'',image:''})
    seterror({name:'',key:'',description:'',image:''})
  }

  return (
    <div className='flex min-h-screen max-h-screen h-screen  overflow-hidden'>
         <TaskMenu />
         <div>

             <span onClick={()=>navigate(-1)} className='cursor-pointer ml-4 mt-10 w-[130px] cursor-pointer transition-all duration-300 rounded hover:bg-slate-200 text-gray-500 font-[600] flex items-center p-1 py-1.5 text-[13px]'><IoArrowBack className='mr-1' /> Back to project </span> 
              <div className='pl-5  w-[100%] flex pt-6 pb-10 min-h-screen max-h-screen h-screen overflow-y-screen overflow-x-hidden'>
                <div className='border-r w-[40%]'>
                  <div className='w-[90%]'>

                    <h6 className='font-[800] text-[20px]'>Add Project details</h6>
                    <h6 className='font-[400] text-gray-500 text-[12px]'>Explore what's possible when you collaborate with your team. Edit project details anytime in project settings.</h6>

                    <div className='w-[100%]'>
                      <TextInputWithPh 
                        mandatory={false}
                        label={'Name *'}  
                        variant="standard"
                        name="name"
                        type="text"
                        value={data.name}
                        error={error.name}
                        handlechange={handlechange}
                        placeholder="Try a team name, project goal, milestone..."
                        InputLabelProps={{
                            style: { color: '#fff', }, 
                        }}
                        />
                        <h6 className='text-[11px] mt-3 text-[#9c9c9c]'> <span className='font-[900]'>Access</span> Anyone with access to testnewkp can access and administer this project.</h6>
                      </div>



                    <div className='w-32'>
                    <TextInputWithPh
                      
                      mandatory={false}
                      label={'Key *'}  
                      variant="standard"
                      name="key"
                      type="text"
                      value={data.key.toUpperCase()}
                      error={error.key}
                      handlechange={handlechange}
                      InputLabelProps={{
                          style: { color: '#fff', }, 
                      }}
                      />
                      </div>


                      <h6 className='mt-6 w-[100%] p-2 bg-gray-50 font-[800] text-[14px]'>Connect team, share ideas, and more <h6 className='text-[11px] font-[400]'>Sync your team's work from other tools with this project for better visibility, access, and automation.</h6></h6>


                      <div className='mt-5 w-[100%] justify-self-end  border-t pt-5'>
                        <div className='flex justify-end'>
                          <div className='mr-2'>
                          <ButtonOutlinedAutoWidth onClick={()=>navigate(-1)}  btnName="Cancel"/>
                          </div> 
                          <ButtonFilledAutoWidth onClick={submitform}  btnName="Create project"/>
                          </div>
                      </div>
                  </div>
                  
              </div>
              <div className='pl-[2%] w-[35%]'>
                    <h1 className='font-[800] text-[20px]'>Application Use Flow</h1>
                    <h6 className='font-[400] text-gray-500 text-[12px]'>Application steps to follow for the greater performance and results use .</h6>

                    <div className='p-5 rounded'>

                      <div className='w-[80%]'>
                        <div className='flex items-center'>
                        <RiBillLine size={50} className='bg-blue-100 p-2' />
                          <div className='ml-4 w-[90%]'>
                            <h6 className='font-[800] text-[14px]'>Step 1</h6>
                            <h6 className='font-[400] text-[11px]'>Create a project which might be a task and has a deadline which aa purpose of it .</h6>
                          </div>
                        </div>

                        <div className='flex items-center mt-5'>
                          <div className='mr-4 w-[90%]'>
                            <h6 className='font-[800] text-[14px]'>Step 2</h6>
                            <h6 className='font-[400] text-[11px]'>Create a task list to complete these project with deadline assign task to experts who can really help you.</h6>
                          </div>
                          <RiStackshareLine size={50} className='bg-red-100 p-2'  />
                        </div>

                        <div className='flex items-center mt-5'>
                        <GrDocumentUpdate size={50} className='bg-yellow-100 p-2'  />
                          <div className='ml-4 w-[90%]'>
                            <h6 className='font-[800] text-[14px]'>Step 3</h6>
                            <h6 className='font-[400] text-[11px]'>Monitor Continous Update done by your experts and resolve there query to achieve your goals.</h6>
                          </div>
                        </div>

                        <div className='flex items-center mt-5'>

                          <div className='mr-4 w-[90%]'>
                            <h6 className='font-[800] text-[14px]'>Step 4</h6>
                            <h6 className='font-[400] text-[11px]'>Keep continous track of the deadline for each task in the projects to check the performance by your experts.</h6>
                          </div>
                        <MdSchedule size={50} className='bg-orange-100 p-2'  />

                        </div>

                        <div className='flex items-center mt-5'>

                          <AiOutlineFileDone size={50} className='bg-green-100 p-2'  />
                          <div className='ml-4 w-[90%]'>
                            <h6 className='font-[800] text-[14px]'>Step 5</h6>
                            <h6 className='font-[400] text-[11px]'>Check each project completion period and work efficiency of your team based on there performance  .</h6>
                          </div>

                        </div>

                      </div>
                    </div>
                  </div>  
              </div>
         </div>

    </div>
  )
}

export default TeamTaskCE