import React,{useState,useEffect} from 'react';
import { useNavigate,useLocation } from 'react-router-dom';
import {TextAreaInput1, TextInput} from '../../components/input';
// import Select from 'react-select'
import {BiErrorCircle,BiCheckbox,BiCheckboxSquare} from 'react-icons/bi'
import { ButtonFilledAutoWidth } from '../../components/button';
import { CreateExitUserFormDetails, GetExitUserFormDetails, UpdateExitUserFormDetails,} from '../../services/OnboardServices';
import { appbarlogo, globelogo } from '../../constants/imageConstants';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { uploadFile } from '../../services/AuthServices';
import { HiLogout } from "react-icons/hi";
import { useDispatch } from 'react-redux';
import { LogOutAction } from '../../redux/actions/authAction';
import { AiOutlineReload } from "react-icons/ai";

function ExitForm() {

      const navigate = useNavigate() 
      const dispatch = useDispatch()
      // const location = useLocation()
      const {state} = useLocation()

      const roles = useSelector(state=>state.Auth.roles)


      const [step,setstep] = useState(1)



      const [docs,setdocs] = useState({addhar:'',pan_card:'',tenth_marks_card:'',puc_marks_card:'',degree_marks_card:'',master_marks_card:'',signature:'',terms_and_signature:false,signature_policy:false})
     
      const [bank,setbank] = useState({bank_name:'',account_no:'',ifsc_code:'',bank_address:'',bank_photo:null,visible:false})
   
      const [data,setdata] = useState({
         user:'',
         department:'',
         exit_type:'',
         reason_leave_this_company:[],
         paragraph_format_leave_this_company:'',
         prevented_your_resignation:'',
         interested_part_of_your_job:'',
         been_better_about_your_job:'',
         during_your_tenure:'',
         timely_feedback_on_performance:'',
         working_relationship_collegues:'',
         relationship_with_supervisor:'',
         answered_by_hd_dep:false,
         nicely_by_acc_dep:'',
         how_long_in_com:'',
         feeback_company:'',
         department_process_completed:'Pending',
         department_process_verified_by:'',
         department_process_verified_by_summary:'',
         it_process_completed:'Pending',
         it_process_verified_by:'',
         it_summary:'',
         hr_process_completed:'Pending',
         hr_process_verified_by:'',
         hr_summary:'',
         it_process_verified_by:'',
         kt_shared:false,
         edit_mode:true,
         doe:''
      })
   
    const [error,seterror] = useState({
    name:'',
    employee_id:'',
    department:'',
    designation_id:'',
    official_phone:'',
    official_email:'',
    dob:'',
    doj:'',
    doe:'',
    email:'',
    phone:'',
    is_active:false,
    branch:'',
    is_verified:false,
    is_partially_verified:false,
    sent_for_review:false,
    app_access:false,
    edit_mode:false,
    crendtials_shared:false,
    user_in_review:false,
    hr_reject_summary:''
    })


 useEffect(()=>{
    getdata()
 },[])




 // clg 

 

 function handlechange(e){
    setdata({...data,[e.target.name] : e.target.value})
    seterror({...error,[e.target.name]:''})
 }

 async function getdata(){
    const response = await GetExitUserFormDetails(state?.id)
    let d = response?.data?.datas
    if(d?.length !== 0){
      let datas = d[0]
      setdata({...datas})
    }
 }

 async function handleSelect(e,e1){
 if(e1 === '1'){
    let oldData = data.reason_leave_this_company
    if(oldData.filter((f)=>f === e?.label) != 0){
      setdata({...data,reason_leave_this_company:oldData.filter((f)=>f !== e?.label)})
    }else{
      oldData.push(e?.label)
      setdata({...data,reason_leave_this_company:oldData})
    }
   seterror({...error,reason_leave_this_company:''})
 }else if(e1 === '2'){
    setdata({...data,during_your_tenure:e?.label})
    seterror({...error,during_your_tenure:''})
 }else if(e1 === '3'){
    setdata({...data,timely_feedback_on_performance:e?.label})
    seterror({...error,timely_feedback_on_performance:''})
 }else if(e1 === '4'){
    setdata({...data,relationship_with_supervisor:e?.label})
    seterror({...error,relationship_with_supervisor:''})
 }else if(e1 === '6'){
  setdata({...data,answered_by_hd_dep:e?.label})
  seterror({...error,answered_by_hd_dep:''})
 }else if(e1 === '7'){
  setdata({...data,nicely_by_acc_dep:e?.label})
  seterror({...error,nicely_by_acc_dep:''})
 }else if(e1 === '8'){
  setdata({...data,working_relationship_collegues:e?.label})
  seterror({...error,working_relationship_collegues:''})
 }else if(e1 === '9'){
  setdata({...data,kt_shared:e?.label})
  seterror({...error,kt_shared:''})
 }else if(e1 === '10'){
  setdata({...data,hr_process_completed:e?.label})
  seterror({...error,hr_process_completed:''})
 }else if(e1 === '11'){
  setdata({...data,it_process_completed:e?.label})
  seterror({...error,it_process_completed:''})
 }else if(e1 === '12'){
  setdata({...data,department_process_completed:e?.label})
  seterror({...error,department_process_completed:''})
 }
 }

  const option = [
  {label:'Better Offer',value:'Better Offer'},
  {label:'Benefits',value:'Benefits'},
  {label:'Illness',value:'Illness'},
  {label:'Different Role/Industry',value:'Different Role/Industry'},
  {label:'Others',value:'Others'},
  ]

  const option1 = [
  {label:'Very Satisfied',value:'Very Satisfied'},
  {label:'Satisfied',value:'Satisfied'},
  {label:'Somewhat Satisfied',value:'Somewhat Satisfied'},
  {label:'Not Satisfied',value:'Not Satisfied'}
  ]

  const option2 = [
  {label:'Very Satisfied',value:'Very Satisfied'},
  {label:'Satisfied',value:'Satisfied'},
  {label:'Somewhat Satisfied',value:'Somewhat Satisfied'},
  {label:'Not Satisfied',value:'Not Satisfied'}
  ]

  const option3 = [
  {label:'Very Satisfied',value:'Very Satisfied'},
  {label:'Satisfied',value:'Satisfied'},
  {label:'Somewhat Satisfied',value:'Somewhat Satisfied'},
  {label:'Not Satisfied',value:'Not Satisfied'}
  ]

  const option4 = [
  {label:'Very Satisfied',value:'Very Satisfied'},
  {label:'Satisfied',value:'Satisfied'},
  {label:'Somewhat Satisfied',value:'Somewhat Satisfied'},
  {label:'Not Satisfied',value:'Not Satisfied'}
  ]

  const option5 = [
  {label:'Yes',value:'Yes'},
  {label:'No',value:'No'},
  {label:'Maybe',value:'Maybe'} 
  ]

  const option6 = [
  {label:'Yes',value:'Yes'},
  {label:'No',value:'No'},
  {label:'Maybe',value:'Maybe'} 
  ]

  const option7 = [
  {label:'Approved',value:'Approved'},
  {label:'Pending',value:'Pending'},
  {label:'Rejected',value:'Rejected'},
  ]

  const option8 = [
  {label:'Yes',value:'Yes'},
  {label:'No',value:'No'},
  ]

  const option9 = [
    {label:'Yes',value:'Yes'},
    {label:'No',value:'No'},
    {label:'Occasionally',value:'Occasionally'},
  ]



 function ErrorComponent({error}){
   return (error !== '' && error !== undefined && error !== null) &&
   <div className='flex items-center mt-1'>
    <BiErrorCircle className='text-red-500' size={14} />
    <span className='text-[10px] text-red-500 ml-1'>{error}</span>
    </div>
 }

 async function submitForm(){
   if(data?.reason_leave_this_company.length === 0){
      seterror({...error,reason_leave_this_company:'This Field is required*'})
   }else if(!data?.paragraph_format_leave_this_company){
      seterror({...error,paragraph_format_leave_this_company:'The Field is required*'})
   }else if(!data?.prevented_your_resignation){
      seterror({...error,prevented_your_resignation:'The Field is required*'})
   }else if(!data?.interested_part_of_your_job){
      seterror({...error,interested_part_of_your_job:'The DOJ Field is required*'})
   }else if(!data?.been_better_about_your_job){
      seterror({...error,been_better_about_your_job:'The Field is required*'})
   }else if(!data?.during_your_tenure){
      seterror({...error,during_your_tenure:'The Field is required*'})
    }else if(!data?.timely_feedback_on_performance){
        seterror({...error,timely_feedback_on_performance:'The Field is required*'})
    }else if(!data?.relationship_with_supervisor){
      seterror({...error,relationship_with_supervisor:'The addhar no field is required*'})
    }else if(!data?.working_relationship_collegues){
      seterror({...error,working_relationship_collegues:'The pan no field is required*'})
    }else if(!data?.answered_by_hd_dep){
      seterror({...error,answered_by_hd_dep:'The father/husband field is required*'})
    }else if(!data?.nicely_by_acc_dep){
      seterror({...error,nicely_by_acc_dep:'The emergency contact field is required*'})
    }else if(!data?.how_long_in_com){
      seterror({...error,how_long_in_com:'The gender field is required*'})
    }else if(!data?.feeback_company){
      seterror({...error,feeback_company:'The blood group field is required*'})
    }else{
       if(data?.id !== undefined){
        let send_data = {...data}
        send_data['edit_mode'] = false
        const response = await  UpdateExitUserFormDetails(data?.id,send_data)
        if(response?.status == 200){
          getdata()
          toast.success("Exit Form Update Successfully Please wait for approval proces to complete")
        }
       }else{
        let send_data = {...data}
        let option = data?.reason_leave_this_company?.join(':')
        send_data['reason_leave_this_company'] = option

        delete send_data.department_process_verified_by
        delete send_data.hr_process_verified_by
        delete send_data.it_process_verified_by
        // delete send_data.department_process_verified_by
        // delete send_data.department_process_verified_by

        const response = await CreateExitUserFormDetails(send_data)
        if(response?.status == 201){
          toast.success("Exit Form Submitted Please wait for approval proces to complete")
          getdata()
        }
       }

    }
 }


 async function handlefileupload(file,name,type){
   console.log("kp")
    const response = await uploadFile({file:file})
    console.log("type",type,'name',name)
    if(response?.status === 200){
      if(type === 'docs'){
         setdocs({...docs,[name]:response?.data?.data})
      }else if(type === 'bank'){
         setbank({...bank,[name]:response?.data?.data})
      }
    }
   //  console.log("response",response?.data?.data)
 }

 async function logoutfunc(){
  dispatch(LogOutAction())
  navigate("/",{ replace: true });
 }

//  console.log("bank",bank)


 return (
 <div className='w-[100%] leading-[18px] no-scrollbar   border-box font-sans md:flex md:h-[100vh] min-h-screen md:max-h-screen'>

 <div>
 </div>  

 <div className='md:min-w-[25%] h-[170px] md:h-full md:max-w-[25%] md:min-h-screen md:max-h-screen overflow-hidden  md:flex px-10 relative flex-col items-center justify-center bg-slate-800'>
 
 <div  onClick={logoutfunc} className='absolute z-50 cursor-pointer flex items-center top-4 left-4'>
 <HiLogout size={15} className='text-white' />
 <h6 className='ml-2 text-white text-[10px] font-[700]'>Log Out</h6>
 </div>
 <img src={appbarlogo} className='absolute w-10 h-10 top-2 right-2 object-contain' />
 <img src={globelogo} className='w-[210px] md:w-[100%] absolute -top-[20%] -left-[10%]' />
 <h6 className='text-[11px] pt-10 md:pt-0 font-normal text-white leading-normal'><span className='opacity-60'> Hello</span> <b className='capitalize opacity-100'>{state?.name}</b> <span className='opacity-60'> We are sad that you're leaving the company so please fill the form to complete the exit formalties for our refernce and to genearate the exit docs what you have required! </span></h6> 

 <h6 className='text-[10px] font-[600] text-white text-center left-[35%] absolute bottom-5'>Powered By Fidelitus</h6>
 </div> 

 <div className='w-screen py-10 relative  px-5 md:px-10 md:h-[95vh] overflow-y-scroll mb-10'>
    
 <div onClick={()=>getdata()} className='cursor-pointer bg-slate-700 absolute top-0 right-0 md:right-0 w-[40px] h-[40px] md:h-[70px] flex flex-col items-center justify-center text-white p-1'>
   <AiOutlineReload />
   <h6 className='text-[9px] my-1'>Reload</h6>
 </div>
  
   <h6 className='font-[700]'>Exit Form</h6>
   <h6 className='text-[11px] mt-2'>Use the below form to complete your exit formalties from the company </h6>
  

   {!data?.edit_mode && data?.id !== undefined && 
   <>

   <div className='bg-green-100 p-1 md:w-[40%] mt-4'>
     <h6 className='text-[12px] leading-[15px]'>Thanks For Submitted the Exit Form <span>wait until your heads process the approval</span></h6>
   </div>
  
   <div className='flex justify-between items-center my-5'>
      <h6 className='text-[13px]  font-[700]'>Approval Stage </h6>
    
      <div className='flex'>
      <div className='flex  mr-2 items-center text-[12px] font-[600]'>
        <h6 className='w-[10px] h-[10px] bg-blue-200'></h6>
        <h6 className='ml-2'>Pending</h6>
      </div>

      <div className='flex  mr-2 items-center text-[12px] font-[600]'>
        <h6 className='w-[10px] h-[10px] bg-green-200'></h6>
        <h6 className='ml-2'>Approved</h6>
      </div>

      <div className='flex  items-center text-[12px] font-[600]'>
        <h6 className='w-[10px] h-[10px] bg-red-200'></h6>
        <h6 className='ml-2'>Rejected</h6>
      </div>
      </div>
   </div>



   <div className='flex mt-2'>
     <h6 className={`text-[11px] w-[80px] cursor-pointer mr-2 ${data?.department_process_completed === 'Approved' ? 'bg-green-200' : data?.department_process_completed === 'Pending' ? 'bg-blue-200' : 'bg-red-200'}  font-[500] text-center p-[3px] `}>Department</h6>
     <h6 className={`text-[11px] w-[80px] cursor-pointer mr-2 ${data?.it_process_completed === 'Approved' ? 'bg-green-200' : data?.it_process_completed === 'Pending' ? 'bg-blue-200' : 'bg-red-200'} font-[500] text-center p-[3px] `}>IT/ Admin</h6>
     <h6 className={`text-[11px] w-[80px] cursor-pointer mr-2 ${data?.hr_process_completed === 'Approved' ? 'bg-green-200' : data?.hr_process_completed === 'Pending' ? 'bg-blue-200' : 'bg-red-200'} font-[500] text-center p-[3px] `}>HR Approval</h6>
   </div>

   </>}

   <h6 className='text-[12px] font-semibold mb-1 mt-5'>What is the reason for leaving the company*</h6>


   <div className='flex md:w-[30%] mt-2 flex-wrap'>
   {option?.map((o)=>(
    <div onClick={()=>handleSelect(o,'1')} className='flex items-center mr-2 mb-2'>
        {data?.reason_leave_this_company?.includes(o?.label) ?  <BiCheckboxSquare size={18} color="text-slate-700"/> :
        <BiCheckbox size={18} color="#bbb"/>} 
        <h6 className='text-[11px]'>{o?.value}</h6>
    </div>
   ))} 
   </div>

   <ErrorComponent error={error?.reason_leave_this_company} />


   <h6 className='text-[12px] font-semibold mb-1 mt-1.5'>Please explain more in paragraph format why do you want to leave company.*</h6>
   <TextAreaInput1
      name={'paragraph_format_leave_this_company'}
      value={data.paragraph_format_leave_this_company}
      error={error.paragraph_format_leave_this_company}
      handlechange={handlechange}
    />



<h6 className='text-[12px] font-semibold mb-1 mt-1.5'>What circumstances would have prevented your resignation?*</h6>
   <TextInput 
    name={'prevented_your_resignation'}
    value={data.prevented_your_resignation}
    error={error.prevented_your_resignation}
    handlechange={handlechange}
   />



   <h6 className='text-[12px] font-semibold mb-1 mt-1.5'>Whatis the most intresting part of your job?*</h6>
   <TextInput 
    name={'interested_part_of_your_job'}
    value={data.interested_part_of_your_job}
    error={error.interested_part_of_your_job}
    handlechange={handlechange}/>


   <h6 className='text-[12px] font-semibold mb-1 mt-1.5'>What could have been better about your job?*</h6>
   <TextInput 
    name={'been_better_about_your_job'}
    value={data.been_better_about_your_job}
    error={error.been_better_about_your_job}
    handlechange={handlechange}/>



   <h6 className='text-[12px]  font-semibold mb-1 mt-1.5'>During your tenure did you feel appreciated for good work you did?*</h6>
   {option9?.map((o)=>(
    <div onClick={()=>handleSelect(o,'2')} className='flex items-center mr-2 mb-2'>
        {data?.during_your_tenure === o?.value ?  <BiCheckboxSquare size={18} color="text-slate-700"/> :
        <BiCheckbox size={18} color="#bbb"/>} 
        <h6 className='text-[11px]'>{o?.value}</h6>
    </div>
   ))} 

<ErrorComponent error={error?.during_your_tenure} />


   <h6 className='text-[12px] font-semibold mb-1 mt-1.5'>Did you recieve timely feedback on your performance?*</h6>
   {option9?.map((o)=>(
    <div onClick={()=>handleSelect(o,'3')} className='flex items-center mr-2 mb-2'>
       {data?.timely_feedback_on_performance !== o?.value ? <BiCheckbox size={18} color="#bbb" />  : <BiCheckboxSquare size={18} color="text-slate-700" />}
        <h6 className='text-[11px]'>{o?.value}</h6>
    </div>
   ))} 

<ErrorComponent error={error?.timely_feedback_on_performance} />


   <h6 className='text-[12px] font-semibold mb-1 mt-1.5'>How do you describe working relationship with supervisors?*</h6>
   {option3?.map((o)=>(
    <div onClick={()=>handleSelect(o,'4')} className='flex items-center mr-2 mb-2'>
        {data?.relationship_with_supervisor !== o?.value ? <BiCheckbox size={18} color="#bbb" />  : <BiCheckboxSquare size={18} color="text-slate-700" />}
        <h6 className='text-[11px]'>{o?.value}</h6>
    </div>
   ))} 

<ErrorComponent error={error?.relationship_with_supervisor} />

   <h6 className='text-[12px] font-semibold mb-1 mt-1.5'>How do you describe working relationship with colleagues?*</h6>
   {option4?.map((o)=>(
    <div onClick={()=>handleSelect(o,'8')} className='flex items-center mr-2 mb-2'>
        {data?.working_relationship_collegues !== o?.value ? <BiCheckbox size={18} color="#bbb" />  : <BiCheckboxSquare size={18} color="text-slate-700" />} 
        <h6 className='text-[11px]'>{o?.value}</h6>
    </div>
   ))} 

<ErrorComponent error={error?.working_relationship_collegues} />

   <h6 className='text-[12px] font-semibold mb-1 mt-1.5'>Were your questions answered by the Human Resource Department?*</h6>
   {option5?.map((o)=>(
    <div onClick={()=>handleSelect(o,'6')} className='flex items-center mr-2 mb-2'>
        {data?.answered_by_hd_dep !== o?.value ? <BiCheckbox size={18} color="#bbb" />  : <BiCheckboxSquare size={18} color="text-slate-700" />} 
        <h6 className='text-[11px]'>{o?.value}</h6>
    </div>
   ))} 

<ErrorComponent error={error?.answered_by_hd_dep} />

   <h6 className='text-[12px] font-semibold mb-1 mt-1.5'>Were you assisted nicely by the accounting department?*</h6>
   {option6?.map((o)=>(
    <div onClick={()=>handleSelect(o,'7')} className='flex items-center mr-2 mb-2'>
        {data?.nicely_by_acc_dep !== o?.value ? <BiCheckbox size={18} color="#bbb" />  : <BiCheckboxSquare size={18} color="text-slate-700" />} 
        <h6 className='text-[11px]'>{o?.value}</h6>
    </div>
   ))} 

<ErrorComponent error={error?.nicely_by_acc_dep} />

   <h6 className='text-[12px] font-semibold mb-1 mt-1.5'>How long have you been in the company?*</h6>
   <TextInput 
    name={'how_long_in_com'}
    value={data.how_long_in_com}
    error={error.how_long_in_com}
    handlechange={handlechange}/>


   <h6 className='text-[12px] font-semibold mb-1 mt-1.5'>Any comments, suggestions, feedback for this company?*</h6>
  
   

<TextAreaInput1 
    name={'feeback_company'}
    value={data.feeback_company}
    error={error.feeback_company}
    handlechange={handlechange}/>



   {roles?.includes('hod_exit_approval') || roles?.includes('admin_exit_approval')  &&
    <>
    <h6 className='text-[12px] font-semibold mb-1 mt-1.5'>Department Head Approval*</h6>
      {option7?.map((o)=>(
        <div  onClick={()=>handleSelect(o,'12')} className='flex items-center mr-2 mb-2'>
            {data?.department_process_completed !== o?.value ? <BiCheckbox size={18} color="#bbb" />  : <BiCheckboxSquare size={18} color="text-slate-700" />} 
            <h6 className='text-[11px]'>{o?.value}</h6>
        </div>
      ))} 

    <TextAreaInput1
    label="Remarks*"
    name={'department_process_verified_by_summary'}
    value={data.department_process_verified_by_summary}
    error={error.department_process_verified_by_summary}
    handlechange={handlechange}
    />

    <h6 className='text-[12px] font-semibold mb-1 mt-1.5'>KT Shared*</h6>
    {option8?.map((o)=>(
        <div onClick={()=>handleSelect(o,'9')} className='flex items-center mr-2 mb-2'>
            {data?.kt_shared !== o?.value ? <BiCheckbox size={18} color="#bbb" />  : <BiCheckboxSquare size={18} color="text-slate-700" />}  
            <h6 className='text-[11px]'>{o?.value}</h6>
        </div>
      ))} 

    </>}


    {roles?.includes('it_exit_approval') || roles?.includes('admin_exit_approval')  &&
  
  <>
    <h6 className='text-[12px] font-semibold mb-1 mt-1.5'>IT & Admin Approvals*</h6>
    {option7?.map((o)=>(
        <div onClick={()=>handleSelect(o,'11')} className='flex items-center mr-2 mb-2'>
            {data?.it_process_completed !== o?.value ? <BiCheckbox size={18} color="#bbb" />  : <BiCheckboxSquare size={18} color="text-slate-700" />}  
            <h6 className='text-[11px]'>{o?.value}</h6>
        </div>
      ))} 

      <TextAreaInput1
      label="Remarks*"
      name={'it_summary'}
      value={data.it_summary}
      error={error.it_summary}
      handlechange={handlechange}
      />
      </>}


    {roles?.includes('hr_exit_approval') || roles?.includes('admin_exit_approval')  &&
     <>
      <h6 className='text-[12px] font-semibold mb-1 mt-1.5'>Hr Approval*</h6>
      {option7?.map((o)=>(
        <div  onClick={()=>handleSelect(o,'10')} className='flex items-center mr-2 mb-2'>
            {data?.hr_process_completed !== o?.value ? <BiCheckbox size={18} color="#bbb" />  : <BiCheckboxSquare size={18} color="text-slate-700" />} 
            <h6 className='text-[11px]'>{o?.value}</h6>
        </div>
      ))} 

    <TextAreaInput1
    name={'hr_summary'}
    label="Remarks*"
    value={data.hr_summary}
    error={error.hr_summary}
    handlechange={handlechange}
    />

 </>}


 

 {(data?.edit_mode) && 
   <div className='border-t w-[100%] pt-4 items-center justify-end flex mt-4 pb-5'>
      <div className='ml-2'>
      <ButtonFilledAutoWidth btnName={'Save'} onClick={submitForm} />
      </div>
   </div>}

   
   {/* <Select
   mode='multiple'
   
   bordered={false}
   disabled={!roles?.includes((r)=>data?.roles?.includes(r))}
   placeholder="" 
   onChange={(e)=>handleSelect(e,'designation_id')} 
   options={designationArr} 
   className='w-full border bg-white border-slate-300 h-[30px] outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-transparent focus:ring-offset-0 focus:shadow-none border-l-4 border-l-slate-600'

   /> */}

  


 
  

   

 

 </div> 

 </div>
 )
}

export default ExitForm