import { deleteRequest, get, post } from "../../helpers/apihelpers";


export const CreateGalleryProductCategoryList=async(data)=>{
    try {
        const result = await post(`api/gallery_product_category/create`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateGalleryProductCategoryList=async(id,data)=>{
    try {
        const result = await post(`api/gallery_product_category/update/${id}`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetGalleryProductCategoryList=async(page)=>{
    try {
        const result = await get(`api/gallery_product_category/get/null/${page}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const SearchGalleryProductCategoryService = async({search_text,from_date,to_date,page})=>{
    try {
        const result = await get(`api/gallery_product_category/filter?search_text=${search_text}&from_date=${from_date}&to_date=${to_date}&page=${page}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteGalleryProductCategoryService = async(id) => {
    try {
        const result = await deleteRequest(`api/gallery_product_category/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DownloadExcelGalleryProductCategoryService = async() => {
    try {
        const result = await get(`api/gallery_product_category/downloadExcel`);
        return result;
    } catch (err) {
        return err.response;
    }
}