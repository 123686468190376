import { deleteRequest, get, post, postfd, put } from "../helpers/apihelpers";


// create lead getleads ,getdetaillead,updatelead
export const GetOperatorsService=async(type)=>{
    try {
        const result = await get(`api/user/get_operator_based_filter?search=${type}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const CreateLeadsService=async(data)=>{
    try {
        const result = await post(`api/lead/create`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const CreateLeadsListBasedonIdService=async(data)=>{
    try {
        const result = await post(`api/lead/convert_data_to_contact`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetLeadsService=async(type,page)=>{
    try {
        const result = await get(`api/lead/get/null/${type}/${page}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetLeadsDetailService=async(id)=>{
    try {
        const result = await get(`api/lead/get/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateLeadsService=async(data,id)=>{
    try {
        const result = await put(`api/lead/update/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateImportantLeads=async(data)=>{
    try {
        const result = await put(`api/lead/update_importance/${data._id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}


export const SearchLeadService = async({search_text,status,from_date,to_date,page})=>{
    try {
        const result = await get(`api/lead/search?search_text=${search_text}&status=${status}&from_date=${from_date}&to_date=${to_date}&page=${page}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteLeadService = async(id)=>{
    try {
        const result = await deleteRequest(`api/lead/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}


//create attachment followups 

export const CreateLeadAttachmentFollowUpService=async(data)=>{
    try {
        const result = await postfd(`api/activity/create-attachment`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetLeadAttachmentFollowUpService=async(id)=>{
    try {
        const result = await get(`api/activity/get-attachement?type=Lead&type_id=${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateLeadAttachmentFollowUpService=async(data,id)=>{
    try {
        const result = await put(`api/activity/update-attachment/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteLeadAttachmentFollowUpService=async(id)=>{
    try {
        const result = await deleteRequest(`api/activity/delete-attachment/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}


// create notes get notes update notes 

export const CreateLeadNoteService=async(data)=>{
    try {
        const result = await postfd(`api/activity/create-note`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetLeadNoteService=async(id)=>{
    try {
        const result = await get(`api/activity/get-note?type=Lead&type_id=${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetLeadNoteService1=async(id)=>{
    try {
        const result = await get(`api/activity/get-note?type=ILS&type_id=${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateLeadNoteService=async(data,id)=>{
    try {
        const result = await put(`api/activity/update-note/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

// create attachment get attachment update attachment

export const CreateLeadAttachmentService=async(data)=>{
    try {
        const result = await postfd(`api/activity/create-note`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetLeadAttachmentService=async(id)=>{
    try {
        const result = await get(`api/activity/get-note?type=Lead&type_id=${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateLeadAttachmentService=async(data,id)=>{
    try {
        const result = await put(`api/activity/update-note/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

// create reminder get reminder update reminder

export const CreateLeadReminderService=async(data)=>{
    try {
        const result = await post(`api/reminder/create`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetLeadReminderService=async(id)=>{
    try {
        const result = await get(`api/reminder/get?type=Lead&type_id=${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetLeadReminderService1=async(id)=>{
    try {
        const result = await get(`api/reminder/get?type=ILS&type_id=${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetTodayLeadReminderService=async()=>{
    try {
        const result = await get(`api/reminder/get_today`);
        return result;
    } catch (err) {
        return err.response;
    }
}


export const UpdateLeadReminderService=async(data,id)=>{
    try {
        const result = await put(`api/reminder/update/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteLeadReminderService=async(id)=>{
    try {
        const result = await deleteRequest(`api/reminder/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}


//create invoice request 

export const CreateInvoiceRequest = async(data)=>{
    try {
        const result = await post(`api/invoice_request/create`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}


export const getTargetAchieved = async()=>{
    try {
        const result = await get(`api/lead/target_achieved`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const getPaymentCollectionPending = async()=>{
    try {
        const result = await get(`api/lead/payment_collection_pending`);
        return result;
    } catch (err) {
        return err.response;
    }
}


export const getInsentiveTargetAchieved = async()=>{
    try {
        const result = await get(`api/lead/incentive_target_achieved`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const getMyInsentiveTargetAchieved = async()=>{
    try {
        const result = await get(`api/lead/get_my_incentive_target_achieved`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const createInspectionReview = async(data)=>{
    try{
        const result = await post(`api/lead/create_inspection_review`,data);
        return result;
    }catch(err){
        return err.response;
    }
}

export const getInspectionReviewDetail = async(id)=>{
    try{
        const result = await get(`api/lead/get_inspection_review_detail/${id}`);
        return result;
    }catch(err){
        return err.response;
    }
}

export const getInspectionReview = async(step,search,from_date,to_date,page)=>{
    try{
        const result = await get(`api/lead/get_inspection_review?status=${step}&search_text=${search}&from_date=${from_date}&to_date=${to_date}&page=${page}`);
        return result;
    }catch(err){
        return err.response;
    }
}

export const getadminInspectionReview = async(step,search,from_date,to_date,page,user)=>{
    try{
        const result = await get(`api/lead/admin_get_inspection_review?status=${step}&search_text=${search}&from_date=${from_date}&to_date=${to_date}&page=${page}&user=${user}`);
        return result;
    }catch(err){
        return err.response;
    }
}


export const deleteInspectionReview = async(id)=>{
    try{
        const result = await deleteRequest(`api/lead/delete_inspection_review/${id}`);
        return result;
    }catch(err){
        return err.response;
    }
}

export const updateInspectionReview = async(id,data)=>{
    try{
        const result = await put(`api/lead/update_inspection_review/${id}`,data);
        return result;
    }catch(err){
        return err.response;
    }
}

export const UploadLeadContactService=async(file)=>{
    try {
        const result = await postfd(`api/lead/upload_excel`,{file:file});
        return result;
    } catch (err) {
        return err.response;
    }
}


export const UpdateLeadInvoiceRaisedOutstandingService = async (id,data) =>{
    try {
        const result = await put(`api/lead/updateinvoice_outstanding_modify/${id}`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UploadVisitingCardFile =async(data)=>{
    try {
        const result = await postfd(`api/lead/upload_file`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}