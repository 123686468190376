import React,{useEffect, useState} from 'react'
import ExitMenu from './ExitMenu'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { IoStopwatchOutline } from "react-icons/io5";
import { Tag } from 'antd';
import { TextAreaInput1, TextInput } from '../../components/input';
import {BiCheckbox,BiCheckboxSquare} from 'react-icons/bi'
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../components/button';
import { GetExitUserFormDetails, UpdateExitUserFormDetails } from '../../services/OnboardServices';
import toast from 'react-hot-toast';
import {Modal,Select} from 'antd';

function ExitMenuListEdit() {

  const user = useSelector(state=>state.Auth)  
  const navigate = useNavigate()
  const {state} = useLocation()
  const [loader,setloader] = useState(false)

  const [data,setdata] = useState({
    ...state,
  })

  const [error,seterror] = useState({
    department_process_completed:'',
    department_process_verified_by:'',
    department_process_verified_by_summary:'',
    it_process_completed:'',
    it_process_verified_by:'',
    it_summary:'',
    hr_process_completed:'',
    hr_process_verified_by:'',
    hr_summary:'',
    it_process_verified_by:'',
    kt_shared:'',
  })

  const roles = useSelector(state=>state.Auth.roles)

  useEffect(()=>{
    getdata()
  },[])


  async function getdata(){
    const response = await GetExitUserFormDetails(state?._id)
    let d = response?.data?.datas
    if(d?.length !== 0){
      let datas = d[0]
      if(datas.exit_type !== ''){
        datas['exit_type'] = {label:datas.exit_type,value:datas.exit_type}
      }
      setdata({...datas})
    }
 }

  function handlechange(e){
    setdata({...data,[e.target.name] : e.target.value})
    seterror({...error,[e.target.name]:''})
  }



 async function handleSelect(e,e1){
    if(e1 === '1'){
       let oldData = data.reason_leave_this_company
       if(oldData.filter((f)=>f === e?.label) != 0){
         setdata({...data,reason_leave_this_company:oldData.filter((f)=>f !== e?.label)})
       }else{
         oldData.push(e?.label)
         setdata({...data,reason_leave_this_company:oldData})
       }
      seterror({...error,reason_leave_this_company:''})
    }else if(e1 === '2'){
       setdata({...data,during_your_tenure:e?.label})
       seterror({...error,during_your_tenure:''})
    }else if(e1 === '3'){
       setdata({...data,timely_feedback_on_performance:e?.label})
       seterror({...error,timely_feedback_on_performance:''})
    }else if(e1 === '4'){
       setdata({...data,relationship_with_supervisor:e?.label})
       seterror({...error,relationship_with_supervisor:''})
    }else if(e1 === '6'){
     setdata({...data,answered_by_hd_dep:e?.label})
     seterror({...error,answered_by_hd_dep:''})
    }else if(e1 === '7'){
     setdata({...data,nicely_by_acc_dep:e?.label})
     seterror({...error,nicely_by_acc_dep:''})
    }else if(e1 === '8'){
     setdata({...data,working_relationship_collegues:e?.label})
     seterror({...error,working_relationship_collegues:''})
    }else if(e1 === '9'){
     setdata({...data,kt_shared:e?.label})
     seterror({...error,kt_shared:''})
    }else if(e1 === '10'){
     setdata({...data,hr_process_completed:e?.label})
     seterror({...error,hr_process_completed:''})
    }else if(e1 === '11'){
     setdata({...data,it_process_completed:e?.label})
     seterror({...error,it_process_completed:''})
    }else if(e1 === '12'){
     setdata({...data,department_process_completed:e?.label})
     seterror({...error,department_process_completed:''})
    }
    }

    const option = [
        {label:'Better Offer',value:'Better Offer'},
        {label:'Benefits',value:'Benefits'},
        {label:'Illness',value:'Illness'},
        {label:'Different Role/Industry',value:'Different Role/Industry'},
        {label:'Others',value:'Others'},
        ]
      
        const option1 = [
        {label:'Very Satisfied',value:'Very Satisfied'},
        {label:'Satisfied',value:'Satisfied'},
        {label:'Somewhat Satisfied',value:'Somewhat Satisfied'},
        {label:'Not Satisfied',value:'Not Satisfied'}
        ]
      
        const option2 = [
        {label:'Very Satisfied',value:'Very Satisfied'},
        {label:'Satisfied',value:'Satisfied'},
        {label:'Somewhat Satisfied',value:'Somewhat Satisfied'},
        {label:'Not Satisfied',value:'Not Satisfied'}
        ]
      
        const option3 = [
        {label:'Very Satisfied',value:'Very Satisfied'},
        {label:'Satisfied',value:'Satisfied'},
        {label:'Somewhat Satisfied',value:'Somewhat Satisfied'},
        {label:'Not Satisfied',value:'Not Satisfied'}
        ]
      
        const option4 = [
        {label:'Very Satisfied',value:'Very Satisfied'},
        {label:'Satisfied',value:'Satisfied'},
        {label:'Somewhat Satisfied',value:'Somewhat Satisfied'},
        {label:'Not Satisfied',value:'Not Satisfied'}
        ]
      
        const option5 = [
        {label:'Yes',value:'Yes'},
        {label:'No',value:'No'},
        {label:'Maybe',value:'Maybe'} 
        ]
      
        const option6 = [
        {label:'Yes',value:'Yes'},
        {label:'No',value:'No'},
        {label:'Maybe',value:'Maybe'} 
        ]
   
   
     const option7 = [
     {label:'Approved',value:'Approved'},
     {label:'Pending',value:'Pending'},
     {label:'Rejected',value:'Rejected'},
     ]
   
     const option8 = [
     {label:'Yes',value:'Yes'},
     {label:'No',value:'No'},
     ]

     const exit_option = [
      {label:'Abscond',value:'Abscond'},
      {label:'Self Relieving',value:'Self Relieving'},
      {label:'Organization Relieving',value:'Organization Relieving'},
     ]


    //  console.log("user?.roles",user?.roles)

     async function submitForm(){

        const send_data = {...data}
        delete send_data.user
        delete send_data.department
        
        if(user.roles.includes('it_exit_approval')){
            if(state.it_process_completed !== 'Approved' && (send_data.it_process_completed !== 'Approved' || send_data.it_process_completed !== 'Rejected')){
                  send_data['it_process_verified_by'] = user.id
            }
        }else if(user.roles.includes('hr_exit_approval')){
            if(state.hr_process_completed !== 'Approved' && (send_data.hr_process_completed !== 'Approved' || send_data.hr_process_completed !== 'Rejected')){
                send_data['hr_process_verified_by'] = user.id
            }
        }else if(user.roles.includes('hod_exit_approval')){
            if(state.department_process_verified_by !== 'Approved' && (send_data.department_process_verified_by !== 'Approved' || send_data.department_process_verified_by !== 'Rejected')){
                send_data['department_process_verified_by'] = user.id
            }
        }else if(user.roles.includes('admin_exit_approval')){
            if(state.it_process_completed !== 'Approved' && (send_data.it_process_completed !== 'Approved' || send_data.it_process_completed !== 'Rejected')){
                send_data['it_process_verified_by'] = user.id
            }
            if(state.hr_process_completed !== 'Approved' && (send_data.hr_process_completed !== 'Approved' || send_data.hr_process_completed !== 'Rejected')){
                send_data['hr_process_verified_by'] = user.id
            }
            if(state.department_process_verified_by !== 'Approved' && (send_data.department_process_verified_by !== 'Approved' || send_data.department_process_verified_by !== 'Rejected')){
                send_data['department_process_verified_by'] = user.id
            }
        }

        if(send_data?.exit_type?.value !== undefined){
            send_data['exit_type'] = send_data?.exit_type?.value
        }

        setloader(true)
        const response = await UpdateExitUserFormDetails(state?._id,send_data)
        console.log("response",response)
        if(response?.status === 200){
            setloader(false)
            toast.success("Exit Formalities Updated Successfully")
            getdata()
            navigate(-1)
        }


     }

  

  return (
    <div  className="w-[100%] h-screen overflow-hidden">
      <Modal open={loader} width={300} closable={false} footer={false}>
        <div>
         <div >
            <h6 className='text-[12px] font-[800]'>We are process with Exit Letter Generation</h6>
            <h6 className='text-[10px] bg-gray-100 p-1 font-[500] mt-2'>Please wait until the process complete don't skip or close the tab until process completed it not take much time</h6>
         </div> 
        </div>
      </Modal>
        <div className='flex'>
          <div className='w-[44] max-h-screen overflow-hidden'>
            <ExitMenu />   
          </div>  
          <div className='w-[35%] min-h-screen max-h-screen  overflow-y-scroll p-4 px-6'>
            
            <div className='bg-green-100 flex p-2'>
                <IoStopwatchOutline size={50} />
                <h1 className='text-[12px] ml-2'>Hi  <b>{user?.name}</b> the employee <b>{state?.user?.name}</b> who belongs to <b>{state?.department?.department_name}</b> and served as a <b>{state?.user?.designation_id?.designation_name}</b> would like to resign from this organiztion. And we need your approval to complete the exit formalities of the employee. </h1>
            </div>


            <h6 className='text-[12px] mt-4'>Reason For Leaving the company</h6>
            <h6>{state?.reason_leave_this_company?.split(":")?.map((m)=><Tag key={m} className='text-[8px] mb-1'>{m}</Tag>)}</h6>

            <h6 className='text-[12px] mt-2'>Explain Brief :  {state?.paragraph_format_leave_this_company}</h6>

            {(roles?.includes('hod_exit_approval')  || roles?.includes('admin')) &&
            <>
            <h6 className='text-[12px] font-semibold mb-1 mt-1.5'>Department Head Approval*</h6>
              {option7?.map((o)=>(
                <div  onClick={()=>handleSelect(o,'12')} className='flex items-center mr-2 mb-2'>
                    {data?.department_process_completed !== o?.value ? <BiCheckbox size={18} color="#bbb" />  : <BiCheckboxSquare size={18} color="text-slate-700" />} 
                    <h6 className='text-[11px]'>{o?.value}</h6>
                </div>
              ))} 

        {!data?.edit_mode && data?.id !== undefined && 
          <>

          <div className='bg-green-100 p-1 md:w-[40%] mt-4'>
            <h6 className='text-[12px] leading-[15px]'>Thanks For Submitted the Exit Form <span>wait until your heads process the approval</span></h6>
          </div>
          
          <div className='flex justify-between items-center my-5'>
              <h6 className='text-[13px]  font-[700]'>Approval Stage </h6>
            
              <div className='flex'>
              <div className='flex  mr-2 items-center text-[12px] font-[600]'>
                <h6 className='w-[10px] h-[10px] bg-blue-200'></h6>
                <h6 className='ml-2'>Pending</h6>
              </div>

              <div className='flex  mr-2 items-center text-[12px] font-[600]'>
                <h6 className='w-[10px] h-[10px] bg-green-200'></h6>
                <h6 className='ml-2'>Approved</h6>
              </div>

              <div className='flex  items-center text-[12px] font-[600]'>
                <h6 className='w-[10px] h-[10px] bg-red-200'></h6>
                <h6 className='ml-2'>Rejected</h6>
              </div>
              </div>
          </div>



          <div className='flex mt-2'>
            <h6 className={`text-[11px] w-[80px] cursor-pointer mr-2 ${data?.department_process_completed === 'Approved' ? 'bg-green-200' : data?.department_process_completed === 'Pending' ? 'bg-blue-200' : 'bg-red-200'}  font-[500] text-center p-[3px] `}>Department</h6>
            <h6 className={`text-[11px] w-[80px] cursor-pointer mr-2 ${data?.it_process_completed === 'Approved' ? 'bg-green-200' : data?.it_process_completed === 'Pending' ? 'bg-blue-200' : 'bg-red-200'} font-[500] text-center p-[3px] `}>IT/ Admin</h6>
            <h6 className={`text-[11px] w-[80px] cursor-pointer mr-2 ${data?.hr_process_completed === 'Approved' ? 'bg-green-200' : data?.hr_process_completed === 'Pending' ? 'bg-blue-200' : 'bg-red-200'} font-[500] text-center p-[3px] `}>HR Approval</h6>
          </div>

          </>}

          <h6 className='text-[12px] font-semibold mb-1 mt-5'>What is the reason for leaving the company*</h6>


          <div className='flex md:w-[40%] mt-2 flex-wrap'>
          {option?.map((o)=>(
            <div onClick={()=>handleSelect(o,'1')} className='flex items-center mr-2 mb-2'>
                {data?.reason_leave_this_company?.includes(o?.label) ?  <BiCheckboxSquare size={18} color="text-slate-700"/> :
                <BiCheckbox size={18} color="#bbb"/>} 
                <h6 className='text-[11px]'>{o?.value}</h6>
            </div>
          ))} 
          </div>


          <h6 className='text-[12px] font-semibold mb-1 mt-1.5'>Please explain more in paragraph format why do you want to leave company.*</h6>
          <TextAreaInput1
              name={'paragraph_format_leave_this_company'}
              value={data.paragraph_format_leave_this_company}
              error={error.paragraph_format_leave_this_company}
              handlechange={handlechange}
            />

        <h6 className='text-[12px] font-semibold mb-1 mt-1.5'>What circumstances would have prevented your resignation?*</h6>
          <TextInput 
            name={'prevented_your_resignation'}
            value={data.prevented_your_resignation}
            error={error.prevented_your_resignation}
            handlechange={handlechange}
          />

          <h6 className='text-[12px] font-semibold mb-1 mt-1.5'>Whatis the most intresting part of your job?*</h6>
          <TextInput 
            name={'interested_part_of_your_job'}
            value={data.interested_part_of_your_job}
            error={error.interested_part_of_your_job}
            handlechange={handlechange}/>

          <h6 className='text-[12px] font-semibold mb-1 mt-1.5'>What could have been better about your job?*</h6>
          <TextInput 
            name={'been_better_about_your_job'}
            value={data.been_better_about_your_job}
            error={error.been_better_about_your_job}
            handlechange={handlechange}/>

          <h6 className='text-[12px]  font-semibold mb-1 mt-1.5'>During your tenure did you feel appreciated for good work you did?*</h6>
          {option1?.map((o)=>(
            <div onClick={()=>handleSelect(o,'2')} className='flex items-center mr-2 mb-2'>
                {data?.during_your_tenure === o?.value ?  <BiCheckboxSquare size={18} color="text-slate-700"/> :
                <BiCheckbox size={18} color="#bbb"/>} 
                <h6 className='text-[11px]'>{o?.value}</h6>
            </div>
          ))} 
          <h6 className='text-[12px] font-semibold mb-1 mt-1.5'>Did you recieve timely feedback on your performance?*</h6>
          {option2?.map((o)=>(
            <div onClick={()=>handleSelect(o,'3')} className='flex items-center mr-2 mb-2'>
              {data?.timely_feedback_on_performance !== o?.value ? <BiCheckbox size={18} color="#bbb" />  : <BiCheckboxSquare size={18} color="text-slate-700" />}
                <h6 className='text-[11px]'>{o?.value}</h6>
            </div>
          ))} 
          <h6 className='text-[12px] font-semibold mb-1 mt-1.5'>How do you describe working relationship with supervisors?*</h6>
          {option3?.map((o)=>(
            <div onClick={()=>handleSelect(o,'4')} className='flex items-center mr-2 mb-2'>
                {data?.relationship_with_supervisor !== o?.value ? <BiCheckbox size={18} color="#bbb" />  : <BiCheckboxSquare size={18} color="text-slate-700" />}
                <h6 className='text-[11px]'>{o?.value}</h6>
            </div>
          ))} 
          <h6 className='text-[12px] font-semibold mb-1 mt-1.5'>How do you describe working relationship with colleagues?*</h6>
          {option4?.map((o)=>(
            <div onClick={()=>handleSelect(o,'8')} className='flex items-center mr-2 mb-2'>
                {data?.working_relationship_collegues !== o?.value ? <BiCheckbox size={18} color="#bbb" />  : <BiCheckboxSquare size={18} color="text-slate-700" />} 
                <h6 className='text-[11px]'>{o?.value}</h6>
            </div>
          ))} 
          <h6 className='text-[12px] font-semibold mb-1 mt-1.5'>Were your questions answered by the Human Resource Department?*</h6>
          {option5?.map((o)=>(
            <div onClick={()=>handleSelect(o,'6')} className='flex items-center mr-2 mb-2'>
                {data?.answered_by_hd_dep !== o?.value ? <BiCheckbox size={18} color="#bbb" />  : <BiCheckboxSquare size={18} color="text-slate-700" />} 
                <h6 className='text-[11px]'>{o?.value}</h6>
            </div>
          ))} 
          <h6 className='text-[12px] font-semibold mb-1 mt-1.5'>Were you assisted nicely by the accounting department?*</h6>
          {option6?.map((o)=>(
            <div onClick={()=>handleSelect(o,'7')} className='flex items-center mr-2 mb-2'>
                {data?.nicely_by_acc_dep !== o?.value ? <BiCheckbox size={18} color="#bbb" />  : <BiCheckboxSquare size={18} color="text-slate-700" />} 
                <h6 className='text-[11px]'>{o?.value}</h6>
            </div>
          ))} 
          <h6 className='text-[12px] font-semibold mb-1 mt-1.5'>How long have you been in the company?*</h6>
          <TextInput 
            name={'how_long_in_com'}
            value={data.how_long_in_com}
            error={error.how_long_in_com}
            handlechange={handlechange}/>
          <h6 className='text-[12px] font-semibold mb-1 mt-1.5'>Any comments, suggestions, feedback for this company?*</h6>
          
          

        <TextAreaInput1 
            name={'feeback_company'}
            value={data.feeback_company}
            error={error.feeback_company}
            handlechange={handlechange}/>




        {(roles?.includes('hod_exit_approval') || roles?.includes('admin_exit_approval') || roles?.includes('admin'))  &&
            <>
            <h6 className='text-[12px] font-semibold mb-1 mt-1.5'>Department Head Approval*</h6>
              {option7?.map((o)=>(
                <div  onClick={()=>handleSelect(o,'12')} className='flex items-center mr-2 mb-2'>
                    {data?.department_process_completed !== o?.value ? <BiCheckbox size={18} color="#bbb" />  : <BiCheckboxSquare size={18} color="text-slate-700" />} 
                    <h6 className='text-[11px]'>{o?.value}</h6>
                </div>
              ))} 


            <TextAreaInput1
            label="Remarks*"
            name={'department_process_verified_by_summary'}
            value={data.department_process_verified_by_summary}
            error={error.department_process_verified_by_summary}
            handlechange={handlechange}
            />

            <h6 className='text-[12px] font-semibold mb-1 mt-1.5'>KT Shared*</h6>
            {option8?.map((o)=>(
                <div onClick={()=>handleSelect(o,'9')} className='flex items-center mr-2 mb-2'>
                    {data?.kt_shared !== o?.value ? <BiCheckbox size={18} color="#bbb" />  : <BiCheckboxSquare size={18} color="text-slate-700" />}  
                    <h6 className='text-[11px]'>{o?.value}</h6>
                </div>
              ))} 

            </>}
            </>}


    {(roles?.includes('it_exit_approval') || roles?.includes('admin_exit_approval') || roles?.includes('admin'))  &&
  
  <>
    <h6 className='text-[12px] font-semibold mb-1 mt-1.5'>IT & Admin Approvals*</h6>
    {option7?.map((o)=>(
        <div onClick={()=>handleSelect(o,'11')} className='flex items-center mr-2 mb-2'>
            {data?.it_process_completed !== o?.value ? <BiCheckbox size={18} color="#bbb" />  : <BiCheckboxSquare size={18} color="text-slate-700" />}  
            <h6 className='text-[11px]'>{o?.value}</h6>
        </div>
      ))} 

      <TextAreaInput1
      label="Remarks*"
      name={'it_summary'}
      value={data.it_summary}
      error={error.it_summary}
      handlechange={handlechange}
      />
      </>}


    {(roles?.includes('hr_exit_approval') || roles?.includes('admin_exit_approval')  || roles?.includes('admin')) &&
     <>

     <h6 className='text-[12px] font-semibold mb-1 mt-1.5'>Exit Type*</h6>
     <Select 
      value={data?.exit_type}
      bordered={false}
      onChange={(v)=>setdata({...data,exit_type:{label:v,value:v}})}
      className='w-full border h-[32px] border-slate-300'
      options={exit_option} />

      <h6 className='text-[12px] font-semibold mb-1 mt-1.5'>Hr Approval*</h6>
      {option7?.map((o)=>(
        <div  onClick={()=>handleSelect(o,'10')} className='flex items-center mr-2 mb-2'>
            {data?.hr_process_completed !== o?.value ? <BiCheckbox size={18} color="#bbb" />  : <BiCheckboxSquare size={18} color="text-slate-700" />} 
            <h6 className='text-[11px]'>{o?.value}</h6>
        </div>
      ))} 
      <div>
      <h6 className='text-[12px] font-semibold mb-1 mt-1'>Date of Exit </h6>
      <input className='border p-1.5 w-full  text-[11px] border-gray-300 ' type='Date' value={data?.doe?.slice(0,10)} onChange={(e)=>{setdata({...data,doe:e.target.value});seterror({...error,doe:''})}} />
      {(error?.doe !== '' || error?.doe !== '')  && <h6 className='text-red-600 text-[10px]'>{error?.dob}</h6>}
        </div>



    <TextAreaInput1
    name={'hr_summary'}
    label="Remarks*"
    value={data.hr_summary}
    error={error.hr_summary}
    handlechange={handlechange}
    />


<h6 className='text-[12px] font-semibold flex' onClick={()=>setdata({...data,edit:!data.edit})}>{!data?.edit  ? <BiCheckbox size={18} color="#bbb" />  : <BiCheckboxSquare size={18} color="text-slate-700" />}   Edit Mode </h6>


 </>}



   <div className='flex my-5'>
   <ButtonOutlinedAutoWidth btnName="Cancel" onClick={()=>navigate(-1)} />
    <h6 className='w-2'></h6>
   {loader ?  
    <ButtonFilledAutoWidth btnName="Loading" />
   :
   <ButtonFilledAutoWidth btnName="Save" onClick={submitForm} />}
   </div>
          </div>
        </div>
    </div>      
  )
}

export default ExitMenuListEdit