import React, { useEffect } from 'react';
import {useLocation,useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RiBillLine } from "react-icons/ri";
import { RiChatFollowUpLine } from "react-icons/ri";

function AgreementFollowUpMenu() {

  const {pathname} = useLocation();
  const path = pathname.split('/')[pathname.split('/').length - 1]
  const roles = useSelector(state=>state.Auth.roles)

 

  const navigate = useNavigate();

  const menu = [
    {name:'Agreement List',icon:RiBillLine,path:'/agreement/list',id:1,color:'',roles:['admin','agreement_maintaner']},
    {name:'Agreement Followup',icon:RiChatFollowUpLine,path:'/agreement/followup',id:2,color:'',roles:[]},
  ]  

  useEffect(()=>{
        // if((roles?.includes('admin')  || roles?.includes('tele_caller') || roles?.includes('md_lead')) === false){
        //     navigate(-1)
        // }
       if(path === 'agreement'){
         navigate('list')
       }
  },[path])

 
  return (
    <div className='mr-0 min-h-screen max-h-sceen border-r min-w-44  w-44 max-w-44  px-2' >

        {/* {path !== 'daily_tasks'  && */}
        <div className='mb-4 pt-5'>
          <h6 className=' ml-2 mb-2 font-[700] text-[12px]'>Basic Option</h6>

          {menu.map((m)=>(
              <>
              {m?.roles?.filter((f)=>roles?.includes(f))?.length > 0 ?
              <div key={m.path} className={`flex items-center my-1 hover:bg-[#f4f5f7] px-1.5 py-1 ml-2 rounded-md relative cursor-pointer ${pathname ===  m.path && 'bg-[#f4f5f7]'}`} onClick={()=>navigate(m?.path)}>
                {pathname ===  m.path &&  <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3' ></span>}
                <m.icon color='#000' style={{backgroundColor:m?.color,padding:'2px'}} size={16}  />
                <span className='text-[#000] font-[500] ml-2 text-[12px]'>{m.name}</span>
              </div>
              :
              <>
              {m?.roles?.length === 0 && 
              <div key={m.path} className={`flex items-center my-1 hover:bg-[#f4f5f7] px-1.5 py-1 ml-2 rounded-md relative cursor-pointer ${pathname ===  m.path && 'bg-[#f4f5f7]'}`} onClick={()=>navigate(m?.path)}>
                {pathname ===  m.path &&  <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3' ></span>}
                <m.icon color='#000' style={{backgroundColor:m?.color,padding:'2px'}} size={16}  />
                <span className='text-[#000] font-[500] ml-2 text-[12px]'>{m.name}</span>
              </div>}
              </>
              }
              </>
          ))}


         </div>
    </div>
  )
}

export default AgreementFollowUpMenu