import React,{useState,useEffect} from 'react'
import GalleryAppBar from '../GalleryAppBar'
import {  TextInput } from '../../../components/input'
import GoBack from '../../../components/back/GoBack'
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../../components/button'
import { useNavigate,useLocation } from 'react-router-dom'
import { toast } from 'react-hot-toast'
import { CreateGalleryProductCategoryList, UpdateGalleryProductCategoryList } from '../../../services/Gallery/GalleryProductCategoryServices'
import { CreateGalleryProductBrandList, UpdateGalleryProductBrandList } from '../../../services/Gallery/GalleryProductBrandServices'
import { CreateGalleryProductTagsList, UpdateGalleryProductTagsList } from '../../../services/Gallery/GalleryProductTagsServices'
import { CreateGalleryArtistList, UpdateGalleryArtistList } from '../../../services/Gallery/GalleryArtistServices'

function GalleryProductCategoryCE() {

  const {pathname,state} = useLocation()
  const path = pathname?.split('/')[pathname?.split('/')?.length - 1]
  const path1 = pathname?.split('/')[pathname?.split('/')?.length - 2]
  
  console.log("path1",path1)
  
  const navigate = useNavigate()  
  const [type,settype] = useState({path:'',path_display:''})
  const [data,setdata] = useState({name:'',})  
  const [error,seterror] = useState({name:'',})  

  useEffect(()=>{
    if(path1 === 'product_tags'){
        settype({...type,path:path1,path_display:'Tags'})
     }else if(path1 === 'product_category'){
        settype({...type,path:path1,path_display:'Category'})
     }else if(path1 === 'product_brand'){
        settype({...type,path:path1,path_display:'Brand'})
     }else if(path1 === 'our_artist'){
        settype({...type,path:path1,path_display:'Artist'})
     }

    if(path === 'edit'){
        setdata({
            ...data,
            name:state?.name,
        })
    }else{
        resetForm()
    }
  },[])
  
  
  function handlechange(e){
    setdata({...data,[e.target.name] : e.target.value})
    seterror({...error,[e.target.name]:''})
  }

  async function submitdata(){
    if(!data?.name){
        seterror({...error,name:'The name field is required'})
    }else{
      

        if(path1 === 'product_category'){
            if(path === 'create'){
                const response = await CreateGalleryProductCategoryList(data)
                if(response.status === 201){
                    toast.success("Created successfully") 
                    resetForm()
                }
            }else if(path === 'edit'){
                const response = await UpdateGalleryProductCategoryList(state?._id,data)
                if(response.status === 201){
                    toast.success("Updated successfully") 
                    navigate(-1)
                }
            }
        }else if(path1 === 'product_brand'){
            if(path === 'create'){
                const response = await CreateGalleryProductBrandList(data)
                if(response.status === 201){
                    toast.success("Created successfully") 
                    resetForm()
                }
            }else if(path === 'edit'){
                const response = await UpdateGalleryProductBrandList(state?._id,data)
                if(response.status === 201){
                    toast.success("Updated successfully") 
                    navigate(-1)
                }
            }
        }else if(path1 === 'product_tags'){
            if(path === 'create'){
                const response = await CreateGalleryProductTagsList(data)
                if(response.status === 201){
                    toast.success("Created successfully") 
                    resetForm()
                }
            }else if(path === 'edit'){
                const response = await UpdateGalleryProductTagsList(state?._id,data)
                if(response.status === 201){
                    toast.success("Updated successfully") 
                    navigate(-1)
                }
            }
        }else if(path1 === 'our_artist'){
            if(path === 'create'){
                const response = await CreateGalleryArtistList(data)
                if(response.status === 201){
                    toast.success("Created successfully") 
                    resetForm()
                }
            }else if(path === 'edit'){
                const response = await UpdateGalleryArtistList(state?._id,data)
                if(response.status === 201){
                    toast.success("Updated successfully") 
                    navigate(-1)
                }
            }
        }
        

    }

  }



  function resetForm(){
    setdata({name:'',measurment:'',available:'',qty:'',description:'',specification:'',image:'',mrp:'',selling_price:''})
    seterror({name:'',measurment:'',available:'',qty:'',description:'',specification:'',image:'',mrp:'',selling_price:''})
  }


  return (
    <div className='h-screen max-h-screen box-border overflow-hidden'>
        <div className='block sm:flex'>
            <GalleryAppBar />
            <div className='w-[85%]  ml-4  '>
            <div className='w-full '>

          
              <div className='flex w-full h-screen overflow-y-scroll mb-10'>
              <div className='border-r w-64 pr-5 pt-5'>
              <GoBack />  

              <h6 className='font-[700] mb-1'>Create/Edit Product <span className='capitalize'>{type?.path_display}</span>  Data</h6>
              <h6 className='text-[11px] p-2  mb-2 bg-slate-100'>Use the below form to create or update the  product <span className='lowercase'>{type?.path_display}</span> data</h6>
                  

                  <TextInput 
                      label={'Name'}  
                      variant="standard"
                      name="name"
                      type="text"
                      error={error.name}
                      value={data.name}
                      handlechange={handlechange}
                      placeholder="Enter your name"
                  />

                  

                  <div className=' flex mt-4 pb-5'>
                      <ButtonOutlinedAutoWidth btnName={'Back'} onClick={()=>{navigate(-1)}} />
                      <div className='ml-2'>
                      <ButtonFilledAutoWidth btnName={'Save'} onClick={submitdata} />
                  </div>
              </div>   
              </div>
              </div>

              </div>
            </div>
        </div>
    </div>
  )
}

export default GalleryProductCategoryCE