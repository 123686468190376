import { deleteRequest, get, post, postfd, put } from "../../helpers/apihelpers";

export const CreateFcaresPropertyAttachmentService=async(data)=>{
    try {
        const result = await post(`api/fcares/property_attachment`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateFcaresPropertyAttachmentService=async(id,data)=>{
    try {
        const result = await put(`api/fcares/property_attachment/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetFcaresPropertyAttachmentService=async(property,page)=>{
    try {
        const result = await get(`api/fcares/property_attachment?page=${page}&property=${property}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetFcaresPropertyAttachmentByIdService=async(id)=>{
    try {
        const result = await get(`api/fcares/property_attachment/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteFcaresPropertyAttachmentService=async(id)=>{
    try {
        const result = await deleteRequest(`api/fcares/property_attachment/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UploadDocsFcaresPropertyAttachmentService=async(data)=>{
    try {
        const result = await postfd(`api/fcares/property_attachment/upload_file`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}