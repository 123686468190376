import React, { useEffect, useState } from 'react'
import DashboardMenu from '../dashboard/DashboardMenu'
import { Select } from 'antd'
import { GetEmployeeShareCurrentInsentive } from '../../services/InvoiceServices'
import { RiBillLine } from 'react-icons/ri'
import { IconButton } from '@mui/material'
import {BsArrowRepeat} from 'react-icons/bs';
import {FiChevronRight,FiChevronLeft} from 'react-icons/fi';
import { useNavigate } from 'react-router-dom'
import { GetAllInsentiveList } from '../../services/AdminServicesfile/InsentiveServices'
// import { GetAllInsentiveList } from '../../services/AdminServicesfile/InsentiveServices'

function PaymentShareRecieved() {

  const [revenue,setrevenue] = useState(0)
  const [yearData,setyearData] = useState([])
  const [selectedYear,setselectedYear] = useState('')

  const navigate = useNavigate()
  const [data,setdata] = useState([])
  const [page,setpage] = useState(1)
  const [pagination,setpagination] = useState({})

  useEffect(()=>{
    getdata()
    getAllInsentiveList()
  },[])

  useEffect(()=>{
    getdata()
  },[selectedYear])

  async function getdata(){
    const response = await GetEmployeeShareCurrentInsentive(selectedYear)
    setdata(response?.data?.datas?.datas)
    setrevenue(response?.data?.datas?.revenue)
    setpagination(response?.data?.pagination)
  } 

  async function getAllInsentiveList(){
    const response = await GetAllInsentiveList()
    let arr = []
    response?.data?.datas?.forEach((d)=>{
      arr.push({label:d?.year,value:d?.id})
    })
    setyearData(arr)
  }
  
  async function resetfunc(){

  }

  let rupee = new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
  });

  return (
    <div >
        <div className='flex'>
        <DashboardMenu />
        <div className='mx-4 w-[84%] mt-4'>
          <div className='border-b pb-1 flex items-center justify-between'>
          <h6 className='text-[14px] font-[600]'>Revenue Generated ({pagination?.total})</h6>

              <div className='flex items-center'>
             <IconButton onClick={resetfunc}  ><BsArrowRepeat size={16} /></IconButton>
              <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`} size={16} /></IconButton>
              <IconButton onClick={()=>{ page < pagination?.totalPages  ? setpage(page+1) : console.log('')}}><FiChevronRight className={`${pagination?.totalPages === page ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>
              <Select options={yearData} placeholder="Year" bordered={false} value={selectedYear !== '' ? selectedYear : null} onChange={(e)=>setselectedYear(e)} size='small' className='border rounded-md border-slate-300 w-[80px] py-[2px] mr-2'/>

              </div>
          </div>  

           {data?.length === 0 &&
           <div className='grid place-items-center mt-20  items-center justify-center'>
            <img src={'https://fidecrmfiles.s3.amazonaws.com/NoDataFound1.png'} className='w-40 h-40 object-contain' /> 
            <h6 className='font-bold text-[14px] -mt-10'>No data found</h6>
            <h6 className='font-[500] w-[70%] text-center text-slate-700 text-[12.5px] -mt-2'>Oops we couldn't find any data based on your list please check whether any payment share has been recieved or not.</h6>
          </div>}

          {data?.length > 0 &&
          <div className='max-h-[85vh]  mt-2 overflow-y-scroll'>
            <h6 className='text-[12px] font-[600]'>Total Revenue: {rupee?.format(revenue)}</h6>

            <div className='max-h-[80vh] overflow-y-scroll grid grid-cols-4 gap-1 mt-2'>
            {data?.map(l=>
              <div key={l?._id} className='relative border p-2'>
                <RiBillLine size={13} onClick={()=>l?.client?.land_lord ? navigate('/leads/landlords/detail',{state:l?.client?._id}) : navigate('/leads/invoice_raised/detail',{state:l?.client?._id})}  className='absolute right-1.5 top-1' />
                <h6 className='font-[600] text-[12px]'>{l?.client?.contact_name}</h6>
                <h6 className='text-[11px]'>Phone : {l?.client?.phone}</h6>
                <h6 className='text-[11px]'>Payment By : {l?.client?.land_lord ? 'Land Lord' : 'Client'}</h6>
                <h6 className='text-[11px] bg-slate-100 p-1 mt-1'>Amount : <span className='font-[700]'>{rupee?.format(l?.amount)}</span></h6>
            </div>)}
            </div>  
          </div>}
        </div> 
        </div>
    </div>
  )
}

export default PaymentShareRecieved