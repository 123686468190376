import { deleteRequest, get, post, postfd, publicPost, put } from "../../helpers/apihelpers";

export const CreateShilpaFoundationUserService=async(data)=>{
    try {
        const result = await post(`api/shilpa_foundation/users/create`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateShilpaFoundationUserService=async(data,id)=>{
    try {
        const result = await put(`api/shilpa_foundation/users/update/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetShilpaFoundationUserService=async(page,text,from_date,to_date,event_id='')=>{
    try {
        const result = await get(`api/shilpa_foundation/users/get?page=${page}&text=${text}&from_date=${from_date}&to_date=${to_date}&event_id=${event_id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteShilpaFoundationUserService=async(id)=>{
    try {
        const result = await deleteRequest(`api/shilpa_foundation/users/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DownloadShilpaFoundationUserService=async(page,text,from_date,to_date,event_id='')=>{
    try {
        const result = await get(`api/shilpa_foundation/users/download_data?page=${page}&text=${text}&from_date=${from_date}&to_date=${to_date}&event_id=${event_id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UploadShilpaFoundationUserService=async(data)=>{
    try {
        const result = await postfd(`api/shilpa_foundation/users/upload_file`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}