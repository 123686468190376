import { deleteRequest, get, post, postfd, publicPost, put } from "../../helpers/apihelpers";

export const CreateFidelitusCorpVisitorService=async(data)=>{
    try {
        const result = await post(`api/corp/visitor/create`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateFidelitusCorpVisitorService=async(data,id)=>{
    try {
        const result = await put(`api/corp/visitor/update/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetFidelitusCorpVisitorService=async(page,text,from_date,to_date,)=>{
    try {
        const result = await get(`api/corp/visitor/get?page=${page}&text=${text}&from_date=${from_date}&to_date=${to_date}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteFidelitusCorpVisitorService=async(id)=>{
    try {
        const result = await deleteRequest(`api/corp/visitor/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DownloadFidelitusCorpVisitorService=async(page,text,from_date,to_date,)=>{
    try {
        const result = await get(`api/corp/visitor/download_data?page=${page}&text=${text}&from_date=${from_date}&to_date=${to_date}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UploadFidelitusCorpVisitorService=async(data)=>{
    try {
        const result = await postfd(`api/corp/visitor/upload_file`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}