import { deleteRequest, get, post, put } from "../../../helpers/apihelpers";

export const CreateFurnishTypeService=async(data)=>{
    try {
        const result = await post(`api/database/furnish_type/create`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateFurnishTypeService=async(data,id)=>{
    try {
        const result = await put(`api/database/furnish_type/update/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetFurnishTypeService=async(page,from_date,to_date,text,step)=>{
    try {
        const result = await get(`api/database/furnish_type/get?page=${page}&from_date=${from_date}&to_date=${to_date}&text=${text}&step=${step}`,);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteFurnishTypeService=async(id)=>{
    try {
        const result = await deleteRequest(`api/database/furnish_type/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteAdminFurnishTypeService=async(id)=>{
    try {
        const result = await deleteRequest(`api/database/furnish_type/delete_admin/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const FilterFurnishTypeService=async(id)=>{
    try {
        const result = await get(`api/database/furnish_type/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}