import { deleteRequest, get, post, postfd, put } from "../../helpers/apihelpers";

export const CreateSupportTicketService=async(data)=>{
    try {
        const result = await post(`api/support_ticket/create`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateSupportTicketService=async(data,id)=>{
    try {
        const result = await put(`api/support_ticket/update/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetSupportTicketService=async(page,text,from_date,to_date,step)=>{
    try {
        const result = await get(`api/support_ticket/get?page=${page}&text=${text}&from_date=${from_date}&to_date=${to_date}&step=${step}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetSupportTicketBasedDepartmentService=async(page,text,from_date,to_date,step)=>{
    try {
        const result = await get(`api/support_ticket/get_based_department?page=${page}&text=${text}&from_date=${from_date}&to_date=${to_date}&step=${step}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteSupportTicketService=async(id)=>{
    try {
        const result = await deleteRequest(`api/support_ticket/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UploadSupportTicketService=async(data)=>{
    try {
        const result = await postfd(`api/support_ticket/upload_file`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DownloadSupportTicketService=async(page,text,from_date,to_date,step)=>{
    try {
        const result = await get(`api/support_ticket/download_data?page=${page}&text=${text}&from_date=${from_date}&to_date=${to_date}&step=${step}`);
        return result;
    } catch (err) {
        return err.response;
    }
}