import { deleteRequest, get, post, postfd, put } from "../../helpers/apihelpers";

export const CreateGalleryEventService=async(data)=>{
    try {
        const result = await post(`api/gallery_events/create`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateGalleryEventService=async(data,id)=>{
    try {
        const result = await put(`api/gallery_events/update/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetGalleryEventService=async(page,text,from_date,to_date,step)=>{
    try {
        const result = await get(`api/gallery_events/get?page=${page}&text=${text}&from_date=${from_date}&to_date=${to_date}&step=${step}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteGalleryEventService=async(id)=>{
    try {
        const result = await deleteRequest(`api/gallery_events/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DownloadGalleryEventService=async(page,text,from_date,to_date,)=>{
    try {
        const result = await get(`api/gallery_events/download_data?page=${page}&text=${text}&from_date=${from_date}&to_date=${to_date}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UploadGalleryEventService=async(data)=>{
    try {
        const result = await postfd(`api/gallery_events/upload_file`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}