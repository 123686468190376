import { deleteRequest, get, post, postfd, put } from "../helpers/apihelpers";


export const GetILSLandDataService=async(page,text,from_date,to_date,zone,type_of_transaction)=>{
    try {
        const result = await get(`api/ils_land_data/get?page=${page}&search=${text}&from_date=${from_date}&to_date=${to_date}&zone=${zone}&type_of_transaction=${type_of_transaction}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetILSEmployeeService=async()=>{
    try {
        const result = await get(`api/ils_land_data/get_ils_user`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetILSAdminLandDataService=async(page,text,from_date,to_date,zone,type_of_transaction,user)=>{
    try {
        const result = await get(`api/ils_land_data/get_admin?page=${page}&search=${text}&from_date=${from_date}&to_date=${to_date}&zone=${zone}&type_of_transaction=${type_of_transaction}&user=${user}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const CreateILSLandDataService=async(data)=>{
    try {
        const result = await post(`api/ils_land_data/create`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateILSLandDataService=async(id,data)=>{
    try {
        const result = await put(`api/ils_land_data/update/${id}`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}


export const UploadFileILSLandDataService=async(data)=>{
    try {
        const result = await postfd(`api/ils_land_data/upload_file`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteILSLandDataService=async(id)=>{
    try {
        const result = await deleteRequest(`api/ils_land_data/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}