import React,{useState,useEffect} from 'react'
import { TextAreaInput1, TextInput } from '../../components/input';
import {BiCheckbox,BiCheckboxSquare,BiErrorCircle} from 'react-icons/bi'
import { GetStageService } from '../../services/StageServices';
import { ButtonFilled, ButtonOutlined } from '../../components/button';
import { useLocation, useNavigate } from 'react-router-dom';
import { CreateLeadsService, GetLeadsDetailService, UpdateLeadsService,CreateLeadAttachmentFollowUpService, CreateInvoiceRequest, GetOperatorsService, UploadVisitingCardFile } from '../../services/LeadServices';
import {  toast } from 'react-hot-toast';
import moment from 'moment'
import {IoIosClose} from 'react-icons/io';
import { useSelector } from 'react-redux';
import { DatePicker,Modal,Select } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import GoBack from '../../components/back/GoBack';
import LeadMenu from './LeadMenu';
import Uploader1 from '../../components/Uploader1';


function LandLordCE() {


  const list = []
  const {pathname,state} = useLocation();

  const path = pathname.split('/')[pathname?.split('/')?.length - 1]

  // console.log("path",path)

  const [stage,setstage] = useState({})
  const [stagesArr,setstagesArr] = useState([])
  const [data,setdata] = useState({
    department_id:'',
    contact_name:'',
    phone:'',
    email:'',
    requirment:'',
    address:'',
    operators:[],
    color:'',
    land_lord:true,
    raise_invoice:false,
    visiting_card:'',
  })

  const [invoiceData,setinvoiceData] = useState({amount:'',other_details:'',invoice_raising_date:'',invoice_attachment_id:'',payment:'0',gst_no:'',pan_no:'',office_space_closed:''})
  const [invoiceDataErr,setinvoiceDataErr] = useState({amount:'',other_details:'',invoice_raising_date:'',invoice_attachment_id:'',payment:'0',gst_no:'',pan_no:'',office_space_closed:''})
  const [OperatorsArr,setOperatorsArr] = useState([])
  const [modal, setModal] = useState(false);


  // console.log("state",state)
  
  useEffect(()=>{
   if(path === 'create' && state?.type !== undefined && state?.type === 'convert'){
    setdata({...state.data})
   }
  },[path])
  
  useEffect(()=>{
    if(data?.raise_invoice){
      if(!data.contact_name){
        setdata({...data,raise_invoice:false})
        seterror({...error,contact_name:'This Field is required*'})
      }else if(!data.phone){
          setdata({...data,raise_invoice:false})
          seterror({...error,phone:'This Field is required*'})
      }else if(data.phone.length !== 10){
          setdata({...data,raise_invoice:false})
          seterror({...error,phone:'Enter a valid 10 digit mobile no*'})
      }else{
        setModal(true)
      }
    }
  },[data?.raise_invoice])

  const [error,seterror] = useState({
    department_id:'',
    contact_name:'',
    phone:'',
    email:'',
    requirment:'',
    address:'',
    operators:[],
    color:'',
    land_lord:'',
    raise_invoice:'',
    operators:'',
    visiting_card:''
  })

  const navigate = useNavigate()
  const location = useLocation()

  const user = useSelector(state=>state.Auth)

  const user_department = user?.department_id[0]?.id
  const user_department_name = user?.department_id[0]?.department_name



 

 

 
 

  useEffect(()=>{
    if(location?.pathname?.split('/')[location?.pathname?.split('/').length -1] === 'edit'){
      geteditdata()
    }
    getStage()
  },[])


  async function getStage(){
    const response = await GetStageService('Lead_Stage',user_department)

    let arr = []
  
    response.data.data.datas.forEach((d)=>{
      if(d?.name === 'Invoice Raised'){
        setstage({label:d?.name,value:d?._id})
      }
      arr.push({label:d?.name,value:d?._id})
    })
    setstagesArr(arr)
  }
  
  // console.log("stagesArr kp here",stagesArr)

  async function geteditdata(){
    const response =  await GetLeadsDetailService(location?.state);
    let d = response?.data?.datas[0]

    // console.log("d here",d?.operators)

    let arr = []

    d?.operators?.forEach((d1)=>{
      arr.push({label:d1?.name,value:d1?._id})
    })
   

    setdata({
      id:d?._id,
      department_id:d?.department_id,
      contact_name:d?.contact_name,
      phone:d?.phone,
      email:d?.email,
      company_name:d?.company_name,
      requirment:d?.requirment,
      address:d?.address,
      color:d?.color,
      operators:arr,
      visiting_card:[undefined,null,'undefined','null','']?.includes(d?.visiting_card) ? '': d?.visiting_card,
    })

  }

  // console.log("data?.operators",data?.operators)
  function handlechange(e){
    setdata({...data,[e.target.name] : e.target.value})
    seterror({...error,[e.target.name] : ''})
  }

  function handlechange1(e){
    setinvoiceData({...invoiceData,[e.target.name] : e.target.value})
    setinvoiceDataErr({...invoiceDataErr,[e.target.name] : ''})
  }

  async function getoperators(v){
    const response = await GetOperatorsService(v)
    let arr = []
    response.data.forEach((d)=>{
     arr.push({value:d._id,label:d.name})
    })
    setOperatorsArr([...arr])
    return arr;
   }

  // console.log("data",data)

  async function submitform(){
   
    if(!data.contact_name){
        seterror({...error,contact_name:'This Field is required*'})
    }else if(!data.phone){
        seterror({...error,phone:'This Field is required*'})
    }else if(data.phone.length !== 10){
        seterror({...error,phone:'Enter a valid 10 digit mobile no*'})
    }else{

      let send_data = {}
        send_data['contact_name'] = data.contact_name;
        send_data['phone'] = data.phone;
        send_data['email'] = data.email;
        send_data['department_id'] = location?.pathname?.split('/')[2] === 'edit' ? data.department_id : user.department_id[0]['id'];
        send_data['address'] = data.address;
        send_data['requirment'] = data.requirment;
        send_data['land_lord'] = true
        send_data['visiting_card'] = data?.visiting_card

        let arr=[]
        if(data.operators.length > 0){
            for(let i=0;i<data.operators.length;i++){
                arr.push(data?.operators[i]?.value)
            }
        }
        send_data['operators'] = arr;

       

        if(location?.pathname?.split('/')[location?.pathname?.split('/')?.length - 1] === 'edit'){
          if(data?.raise_invoice){
            send_data['stage'] = stage?.value
          }
          const response = await UpdateLeadsService(send_data,data?.id)
          if(response.status === 200){
            resetform()
            navigate(-1)
            toast.success('LandLord info updated successfully')
          }
        }else{ 

          let stageFind = stagesArr?.find((f)=>f?.label === 'Contact')
          send_data['stage'] = stageFind?.value
          const response = await CreateLeadsService(send_data)
          console.log("response?.data?.kp",response?.data)
          if(response.status === 201){
            resetform()
            createContactFollowUp(response?.data?.datas?.id)
            // toast.success('LandLord info created successfully')
          }
        }
      } 
    }

  function resetform(){
     setdata({
      department_id:'',
      contact_name:'',
      phone:'',
      email:'',
      requirment:'',
      address:'',
      operators:[],
      color:'',
      land_lord:true,
      raise_invoice:false,
      visiting_card:'',
      })
      seterror({
        department_id:'',
      contact_name:'',
      phone:'',
      email:'',
      requirment:'',
      address:'',
      operators:'',
      color:'',
      land_lord:true,
      raise_invoice:false,
      visiting_card:'',

      })

  }


  async function handleSelect(e,e1){
    if(e1 === 'Operator'){
        let arr = []
        e?.forEach((ele)=>{
          arr?.push(OperatorsArr?.find((i)=>i.value === ele))
        })
        setdata({...data,operators:arr})
        seterror({...error,operators:''})
    }
  }  

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border:'1px solid rgb(227 231 234)',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      padding: '0px 6px',
      fontSize:'13px',
      fontWeight:600,
      marginBottom:'0px',
      paddingBottom:'0px'
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
    }),
  };

  async function createContactFollowUp(type_id){
    let contact_id = stagesArr?.find((f)=>f?.label === 'Contact')
    let send_data = {}
    send_data['leadId'] = type_id
    send_data['attachment_type'] = 'Lead'
    send_data['stage_type'] = contact_id?.label
    send_data['stage'] = contact_id?.value
    send_data['summary'] = ''
    send_data['type_id'] = type_id
    send_data['finalized'] = 0
    const response   =   await CreateLeadAttachmentFollowUpService(send_data)
      // console.log("response.status",response.status)
    if(response.status === 201){
      toast.success('LandLord info created successfully')
    }

  }

  async function createInvoiceRequest(){

    if(stage?.label === 'Invoice Raised'){

      let send_data = {}
      send_data['leadId'] = data?.id
      send_data['attachment_type'] = 'Lead'
      send_data['stage_type'] = (stage !== undefined && stage !== '' && stage !== null) ?  stage?.label : data?.stage?.label
      send_data['stage'] = (stage !== undefined && stage !== '' && stage !== null) ?  stage?.value : data?.stage?.value
      send_data['summary'] = ''
      send_data['type_id'] = data?.id

      send_data['finalized'] = 0
      send_data['datetime'] = moment(invoiceData?.invoice_raising_date).format('YYYY-MM-DD') 
      send_data['amount'] = parseInt(invoiceData?.amount?.split(',')?.join(''))
      send_data['payment'] = invoiceData?.payment 


      // console.log("send_data",send_data)
      

           
      const response   =   await CreateLeadAttachmentFollowUpService(send_data)
      // console.log("response.status",response.status)
      if(response.status === 201){
            let send_Data = {...invoiceData}
            send_Data['client'] = data?.id
            send_Data['invoice_attachment_id'] = response?.data?.datas?.id
            send_Data['employee'] = user?.id
            send_Data['amount'] = parseInt(invoiceData?.amount?.split(',')?.join(''))
             
            const response1 = await CreateInvoiceRequest(send_Data) 
              if(response1.status === 201){
                setModal(false)
                setdata({...data,stage:stage})
                seterror({...error,stage:''})
                submitform(stage)
              } 
      }
    }
   
  }

  async function uploadfile(v,name){
    const fd = new FormData()
    fd.append('file',v); 
    const response = await UploadVisitingCardFile(fd)
    if(response?.status === 200){
      setdata({...data,[name]:response?.data?.data})
      seterror({...error,[name]:''})
    }
  }
    


  return (
    <div className='flex'>
      <Modal
      open={modal}
      closable={false}
      maskClosable={false}
      onClose={()=>setModal(false)}
      bodyStyle={{borderRadius:'0px !important'}}
      style={{maxWidth:'300px',borderRadius:'0px !important'}}
      footer={null}
      >
           <IoIosClose size={22} className='absolute right-1 top-1' onClick={()=>{setModal(false);setdata({...data,raise_invoice:false})}} />

          <h6 className="font-bold text-[15px]  mb-2 w-full">Add Info to raise Invoice For Finance</h6>

          <div className='mb-2'>
          <TextInput
            mandatory={true}
            label={'Amount'}  
            variant="standard"
            name="amount"
            type="text"
            value={invoiceData.amount}
            error={invoiceDataErr.amount}
            handlechange={handlechange1}/>
          </div> 

          <div style={{width:'100%'}}>
            <h6 className='text-[12px] font-[600] mb-1' >{'Invoice Raise Date'}</h6>
            <DatePicker 
            placeholder='' 
            // bordered={false} 
            ampm={false} 
            style={{maxHeight:'40px',borderRadius:'3px',width:'100%'}} 
            className='border-l-4 border-l-slate-600 w-full border outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none'
            inputFormat="MM/dd/yyyy" value={invoiceData.invoice_raising_date} onChange={(v) => {setinvoiceData({...invoiceData,invoice_raising_date:v});setinvoiceDataErr({...invoiceDataErr,invoice_raising_date:''})}} />
            {(invoiceDataErr?.invoice_raising_date !== '' )  && <h6 className='text-red-600 text-[10px]'>{invoiceDataErr?.invoice_raising_date}</h6>}
          </div>

          <TextInput
             label={'Gst No'}  
             variant="standard"
             name="gst_no"
             type="text"
             value={invoiceData?.gst_no}
             error={invoiceDataErr.gst_no}
             handlechange={handlechange1}/>


            <TextInput
             label={'Pan No'}  
             variant="standard"
             name="pan_no"
             type="text"
             value={invoiceData?.pan_no}
             error={invoiceDataErr.pan_no}
             handlechange={handlechange1}/>

             <h6 className='text-[12px] font-[600] mb-1' >{'Office Space Closed'}</h6>
             <TextArea style={{borderRadius:'2px'}} value={invoiceData.office_space_closed} onChange={(e)=>setinvoiceData({...invoiceData,office_space_closed:e.target.value})} />
           
          
          <div className='flex items-center mt-2'>
            <div className='flex items-center mt-0 mr-2'>
            {invoiceData?.payment === '1' ?  <BiCheckboxSquare size={22} className='text-slate-600' onClick={()=>setinvoiceData({...invoiceData,payment:''})} />  : <BiCheckbox size={22} className='text-slate-300' onClick={()=>setinvoiceData({...invoiceData,payment:'1'})}  /> }

            {/* <input type={'checkbox'} checked={invoiceData?.payment === '1'} onChange={()=>setinvoiceData({...invoiceData,payment:'1'})} />  */}
            <h6 className="text-[13px] font-[600] ml-2">Cash</h6> 
            </div>  

            <div className='flex items-center mt-0 mr-2'>
            {invoiceData?.payment === '0' ?  <BiCheckboxSquare size={22} className='text-slate-600' onClick={()=>setinvoiceData({...invoiceData,payment:''})} />  : <BiCheckbox size={22} className='text-slate-300' onClick={()=>setinvoiceData({...invoiceData,payment:'0'})} /> }
            {/* <input type={'checkbox'} checked={invoiceData?.payment === '0'} onChange={()=>setinvoiceData({...invoiceData,payment:'0'})} />  */}
            <h6 className="text-[13px] font-[600] ml-2">Online</h6> 
            </div>  
          </div>


          <h6 className='text-[12px] font-[600] mb-1' >{'Invoice Details'}</h6>
            <TextArea style={{borderRadius:'2px'}} value={invoiceData.other_details} onChange={(e)=>setinvoiceData({...invoiceData,other_details:e.target.value})} />
          
          {(invoiceData?.amount !== '' && invoiceData?.invoice_raising_date !== ''  && invoiceData?.invoice_raising_date !== undefined &&  invoiceData?.invoice_raising_date !== null) &&
           <div className='mt-2'>
           <ButtonFilled btnName={'Confirm'} onClick={createInvoiceRequest} />
          </div>  
          }
      </Modal>
      <LeadMenu />
    <div className={`ml-4 w-72  pr-5 min-h-screen max-h-screen h-screen overflow-y-scroll`} >

 
     

     <div >
      <GoBack />
     <div className='border-b  pb-2'>
      <h6 className='font-[800] mb-1'>{location?.pathname?.split('/')[location?.pathname?.split('/').length -1] === 'edit' ? 'Edit' : 'Create'}  Landlord Info</h6> 
      <h6 className='text-[11px] leading-tight font-[500] p-2 bg-slate-100 '>Use the below form to create or edit the Landlord Info for your reference to get the continous task progress made by you all the time.</h6> 
     </div> 
          <TextInput 
              mandatory={true}
              label={'Name'}  
              variant="standard"
              name="contact_name"
              type="text"
              value={data.contact_name}
              error={error.contact_name}
              handlechange={handlechange}
              placeholder="Enter contact name"
              InputLabelProps={{
                  style: { color: '#fff', }, 
              }}/>
               <TextInput 
              mandatory={true}
            label={'Phone'}  
            variant="standard"
            name="phone"
            type="text"
            value={data.phone}
            error={error.phone}
            handlechange={handlechange}
            placeholder="Enter contact phone"
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>
             <TextInput 
            label={'Email'}  
            variant="standard"
            name="email"
            type="text"
            value={data.email}
            error={error.email}
            handlechange={handlechange}
            placeholder="Enter contact email"
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>

<TextAreaInput1 
            label={'Address'}  
            variant="standard"
            name="address"
            type="text"
            value={data.address}
            error={error.address}
            handlechange={handlechange}
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>

{user_department_name === 'Transaction Team' &&
        <>
        <h6 className='text-[12px] font-semibold mb-1 mt-1'>Operators</h6>
         <Select
           showSearch
           mode="multiple"
           optionFilterProp="children"
           bordered={false}
           value={data.operators} 
           error={error.operators}
           placeholder="" 
           onSearch={getoperators}
           onChange={(e)=>handleSelect(e,'Operator')} 
          //  loadOptions={getoperators}
           styles={customStyles} 
           options={OperatorsArr} 
           filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
           }
           className='w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none'
           />
         {error.operators !== '' && error.operators !== undefined &&
         <div className='flex items-center mt-1'>
         <BiErrorCircle className='text-red-500' size={14} />
         <span className='text-[10px] text-red-500 ml-1'>{error.operators}</span>
         </div>}
         </>}


            <TextAreaInput1 

            mandatory={true}
            label={'Site Info'}  
            variant="standard"
            name="requirment"
            type="text"
            value={data.requirment}
            error={error.requirment}
            handlechange={handlechange}
            placeholder=""
            className='border-l-2 border-l-slate-600 rounded-md'
            InputLabelProps={{
                style: { color: '#fff', }, 
                
            }}/>

        <h6 className="font-bold text-[11px]  my-2 w-full">Visiting Card</h6>
        <Uploader1 image={data?.visiting_card}  setimagefunc={(e)=>uploadfile(e,'visiting_card')}  removeimageuploadfunc = {()=>setdata({...data,visiting_card:''})} />


           {path === 'edit' && 
           <span onClick={()=>{setdata({...data,raise_invoice:!data.raise_invoice});setinvoiceData({amount:'',other_details:'',invoice_raising_date:'',invoice_attachment_id:'',payment:'0'})}} className='flex text-center items-center -ml-1 text-[12px]'>{data?.raise_invoice ?<BiCheckboxSquare size={22} className='text-slate-600'  /> :  <BiCheckbox size={22} className='text-slate-300'   />} Raise Invocie</span>}
            
            

          
            
   

   
   <div >
    <div className='flex items-center mt-5 mb-10  border-t pt-5'>
        <div className='mr-2'>
        <ButtonOutlined btnName={'Back'} onClick={()=>navigate(-1)} />
        </div>
        <div>
        <ButtonFilled btnName={'Save'} onClick={()=>submitform(null)} />
        </div>
    </div>
    </div>
    </div>
    </div>
   
    </div>
  )
}

export default LandLordCE