import React from 'react'

function PrivacyPolicy() {
  return (
    <div className='bg-[#fff] font-sans'>
        <div className='py-10'>
            <div className='w-[90%] md:w-[80%] mx-auto'>
                <h6 className='font-black capitalize text-[32px]'>privacy policy</h6>
                <h6 className='text-slate-500 font-[400] text-[13px] my-5 mb-14'>Last updated On March 18, 2024</h6>
                <h6 className='my-5 text-[14px] leading-[20px] font-[400]'>We i.e. "Fidelitus Corp Private Limited" (formerly known as India Corperate Private Limited) (“Company”), are committed to protecting the privacy and security of your personal information. Your privacy is important to us and maintaining your trust is paramount.</h6>
                <h6 className='my-5 text-[14px] leading-[20px] font-[400]'>This privacy policy explains how we collect, use, process and disclose information about you. By using our website/ app/ platform and affiliated services, you consent to the terms of our privacy policy (“Privacy Policy”) in addition to our ‘Terms of Use.’ We encourage you to read this privacy policy to understand the collection, use, and disclosure of your information from time to time, to keep yourself updated with the changes and updates that we make to this policy.</h6>
                <h6 className='my-5 text-[14px] leading-[20px] font-[400]'>This privacy policy describes our privacy practices for all websites, products and services that are linked to it. However this policy does not apply to those affiliates and partners that have their own privacy policy. In such situations, we recommend that you read the privacy policy on the applicable site.</h6>
                <h6 className='my-5 text-[14px] leading-[20px]'>Should you have any clarifications regarding this privacy policy, please write to us at <b>privacy@fidelituscorp.com</b></h6>
                <h6 className='my-4 font-bold  leading-[20px]text-[20px]'>The collection, storage and use of information related to you</h6>
                <h6 className='my-5 text-[14px] leading-[20px] font-[400]'>Collection of Personal Information : We may collect personal information such as name, email address, phone number, and shipping address when customers place orders on our business platform. We use this information solely for the purpose of fulfilling orders and providing customer service.</h6>
                <h6 className='my-5 text-[14px] leading-[20px] font-[400]'>Payment Information : We do not store credit card information on our servers. Payment information is securely processed by third-party payment gateways that comply with industry security standards.</h6>
                <h6 className='my-5 text-[14px] leading-[20px] font-[400]'>Use of Personal Information : We use personal information to fulfill orders, process payments, communicate with customers, and provide customer service. We may also use personal information to send promotional emails or newsletters, but customers can opt-out of receiving these communications at any time.</h6>
                <ul className='list-disc ml-5'>
                    <li className='mb-2 text-[14px] leading-[20px] font-[400]'>To enable the provision of services opted by you;</li>
                    <li className='mb-2 text-[14px] leading-[20px] font-[400]'>To communicate necessary account and product/service related information from time to time;</li>
                    <li className='mb-2 text-[14px] leading-[20px] font-[400]'>To allow you to receive quality customer care services;</li>
                    <li className='mb-2 text-[14px] leading-[20px] font-[400]'>To undertake necessary fraud and money laundering prevention checks, and comply with the highest security standards;</li>
                    <li className='mb-2 text-[14px] leading-[20px] font-[400]'>To comply with applicable laws, rules and regulations; and</li>
                    <li className='mb-2 text-[14px] leading-[20px] font-[400]'>To provide you with information and offers on products and services, on updates, on promotions, on related, affiliated or associated service providers and partners, that we believe would be of interest to you</li>
                </ul>
                <h6 className='my-5 text-[14px] leading-[20px] font-[400]'>Where any service requested by you involves a third party, such information as is reasonably necessary by the Company to carry out your service request, may be shared with such third party.</h6>
                <h6 className='my-5 text-[14px] leading-[20px] font-[400]'>Disclosure of Personal Information : We do not sell, rent, or disclose personal information to third parties, except as necessary to fulfill orders or comply with legal obligations. We may share personal information with third-party service providers, such as shipping carriers or payment processors, to facilitate order fulfillment.</h6>
                <h6 className='my-5 text-[14px] leading-[20px] font-[400]'>Cookies and Tracking Technologies : Our Business platform may use cookies and other tracking technologies to improve the user experience and track user behavior. Customers can disable cookies in their web browser, but this may affect the functionality of our business platform.</h6>
                <h6 className='my-5 text-[14px] leading-[20px] font-[400]'>Security : We take the security of personal information seriously and implement appropriate measures to protect it from unauthorized access, alteration, or disclosure. We use industry-standard security protocols and technologies to protect personal information during transmission and storage.</h6>
                <h6 className='my-5 text-[14px] leading-[20px] font-[400]'>Children's Privacy : Our Business platform is not intended for use by children under the age of 13. We do not knowingly collect personal information from children under the age of 13. If we become aware that we have collected personal information from a child under the age of 13, we will promptly delete it.</h6>
                <h6 className='my-5 text-[14px] leading-[20px] font-[400]'>Changes to Privacy Policy : We reserve the right to modify this privacy policy at any time without prior notice. Any changes will be effective immediately upon posting on our business platform.</h6>
            </div>
        </div>

        <div className='w-[90%] md:w-[80%] mx-auto pb-10'>
            <div className='my-4'>
                <h6 className='text-[13px] font-[900] leading-[20px] mb-2'>#1 instant reminder and performance</h6>
                <h6 className='text-[13px] text-[#757575] font-[400] leading-[20px]'>Use this application to improve the daily process for your organization flows for the section master and to create there daily progressive task.</h6>
            </div>

            <div className='my-4'>
                <h6 className='text-[13px] font-[900] mb-2 leading-[20px]'>single app for all your daily needs</h6>
                <h6 className='text-[13px] text-[#757575] font-[400] leading-[20px]'>Use this application to improve your productivity day by day .</h6>
            </div>

            <div className='my-4'>
                <h6 className='text-[13px] font-[900] mb-2 leading-[20px]'>Fidelitus App to enjoy daily task</h6>
                <h6 className='text-[13px] text-[#757575] font-[400] leading-[20px]'>Cities we currently serve: Chennai, Bengaluru,Mumbai,Kolkata.</h6>
            </div>
        </div>
    </div>
  )
}

export default PrivacyPolicy