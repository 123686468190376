import React,{useEffect, useState} from 'react'
import { toast } from 'react-hot-toast'
import {  ButtonFilledAutoWidth } from '../../../../components/button'
import { TextAreaInput1, TextInput } from '../../../../components/input'
import { useNavigate, useLocation } from 'react-router-dom'
import GoBack from '../../../../components/back/GoBack'
import WebsiteMenu from '../../WebsiteMenu'
import Uploader from '../../../../components/Uploader'
import { UploadProjectWebsiteBlogService } from '../../../../services/Website/ProjectWebsiteOption/ProjectBlogServices'
import { CreateProjectWebsiteJobOpeningService, GetProjectWebsiteJobOpeningService, UpdateProjectWebsiteJobOpeningService } from '../../../../services/Website/ProjectWebsiteOption/ProjectJobOpeningServices'
import { Select } from 'antd'
import { CreateProjectWebsiteJobApplyService, UpdateProjectWebsiteJobApplyService } from '../../../../services/Website/ProjectWebsiteOption/ProjectJobApplicantServices'

function ProjectJobApplyCE() {
 
  const [data,setdata] = useState({name:'',current_company:'',experience:'',email:'',mobile:'',address:'',current_address:'',current_ctc:'',expected_ctc:'',notice_period:'',resume:''});  
  const [error,seterror] = useState({name:'',current_company:'',experience:'',email:'',mobile:'',address:'',current_address:'',current_ctc:'',expected_ctc:'',notice_period:'',resume:''}); 
  
  const {state} = useLocation();
  const navigate = useNavigate();

  const [job_opening_arr,setjob_opening_arr] = useState([])


 


  useEffect(()=>{
    if(state?._id !== null && state?._id !== undefined){
      let obj = {
       ...state
      }

      obj['job_opening'] = {label:state?.job_opening?.designation,value:state?.job_opening?._id}
      setdata({...data,...obj})
    }
  },[state])

  function handlechange(e){
    setdata({...data,[e.target.name] : e.target.value})
    seterror({...error,[e.target.name]:''})
  }

  
  async function submitform(){
    if(!data.name){
        seterror({...error,name:'Designation field is required *'})
    }else{
        let sendData = {...data}

        if(data?.job_opening?.value !== undefined){
          sendData['job_opening'] = data?.job_opening?.value
        }

      
        if(state?._id === undefined || state?._id === null){
        const response = await CreateProjectWebsiteJobApplyService(sendData)
        if (response.status === 201) {
          setdata({name:'',current_company:'',experience:'',email:'',mobile:'',address:'',current_address:'',current_ctc:'',expected_ctc:'',notice_period:'',resume:''})
          seterror({name:'',current_company:'',experience:'',email:'',mobile:'',address:'',current_address:'',current_ctc:'',expected_ctc:'',notice_period:'',resume:''})
          toast.success('Project Job Apply Created Successfully')
        }   
        if(response.status === 422){
          if(response?.data?.errors?.name){
            seterror({...error,name:response?.data?.errors?.name})
          }
        } 
      }else{
        sendData["id"] = state?._id
        const response = await UpdateProjectWebsiteJobApplyService(sendData,state?._id)
        if (response.status === 200) {
          setdata({name:'',current_company:'',experience:'',email:'',mobile:'',address:'',current_address:'',current_ctc:'',expected_ctc:'',notice_period:'',resume:''})
          seterror({name:'',current_company:'',experience:'',email:'',mobile:'',address:'',current_address:'',current_ctc:'',expected_ctc:'',notice_period:'',resume:''})
          navigate(-1)
          toast.success('Project Job Apply Updated Successfully')
        }   
        if(response.status === 422){
          if(response?.data?.errors?.name){
            seterror({...error,name:response?.data?.errors?.name})
          }
        } 
      }
  }
  }


  async function uploadfile(v,name){
    const fd = new FormData()
    fd.append('file',v); 
    const response = await UploadProjectWebsiteBlogService(fd)
    if(response?.status === 200){
      setdata({...data,[name]:response?.data?.data})
      seterror({...error,[name]:''})
    }
  }


  async function searchData(v){
    const response =  await GetProjectWebsiteJobOpeningService(1,v)
      let arr = []
      let d = response?.data?.datas
      d.forEach((d1)=>{
        arr.push({label:d1.designation,value:d1?._id})
      })
      setjob_opening_arr(arr)
      return arr
  }  
  
  async function handleselect(v,type){
    let findData = job_opening_arr.find((f)=>f.value === v)
    setdata({...data,[type]:findData})
 }

 async function handleDownload(v){
  window.open(`${process.env.REACT_APP_AWS_IMAGE_URL}${v}`, "_blank");
 }
  

  return (
    <div className='flex '>


    <WebsiteMenu />
 
  <div className='sm:w-full lg:w-72 px-4 pt-5' >


      <GoBack /> 
      
      

        <h6 className='font-bold'>{(state?._id !== null && state?._id !== undefined) ? 'Edit' : 'Add'} Project Job Apply</h6>
        <h6 className='text-[10px] bg-slate-100 p-2 font-[500] leading-snug' >Use the below form to create or edit the <b> Project Job Apply for your Job Opening</b> for your website.</h6>
     

        <TextInput 
            label={'Name'}  
            variant="standard"
            name="name"
            type="text"
            error={error.name}
            value={data.name}
            handlechange={handlechange}
            placeholder="Enter your Role name"
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>

<h6 className='text-[11px] mt-2 font-[600] mb-1' >Job Opening</h6>
        <Select 
          value={data?.job_opening}
          allowClear={true}
          type="job_opening"
          filterOption={false}
          showSearch={true}
          onSearch={(e)=>searchData(e,'Job Opening')}
          onChange={e=>{handleselect(e,'job_opening')}}
          options={job_opening_arr}
          bordered={false}
          className='w-[100%] border border-slate-300'
        />


          <TextInput 
            label={'Mobile'}  
            variant="standard"
            name="mobile"
            type="text"
            error={error.mobile}
            value={data.mobile}
            handlechange={handlechange}
            placeholder=""
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>

        <TextInput 
            label={'Email'}  
            variant="standard"
            name="email"
            type="text"
            error={error.email}
            value={data.email}
            handlechange={handlechange}
            placeholder="Enter your Role name"
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>

        <TextAreaInput1 
            label={'Address'}  
            variant="standard"
            name="address"
            type="text"
            error={error.address}
            value={data.address}
            handlechange={handlechange}
            placeholder="Enter your Role name"
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>

        <TextAreaInput1 
            label={'Current Address'}  
            variant="standard"
            name="current_address"
            type="text"
            error={error.current_address}
            value={data.current_address}
            handlechange={handlechange}
            placeholder="Enter your Role name"
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>


        <TextInput 
            label={'Total Years of Experience'}  
            variant="standard"
            name="experience"
            type="text"
            error={error.experience}
            value={data.experience}
            handlechange={handlechange}
            placeholder="Enter your Role name"
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>

        <TextInput 
            label={'Current Company'}  
            variant="standard"
            name="current_company"
            type="text"
            error={error.current_company}
            value={data.current_company}
            handlechange={handlechange}
            placeholder="Enter your Role name"
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>    


        <TextInput 
            label={'Notice Period'}  
            variant="standard"
            name="notice_period"
            type="text"
            error={error.notice_period}
            value={data.notice_period}
            handlechange={handlechange}
            placeholder="Enter your Role name"
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>

        <TextInput 
            label={'Current CTC'}  
            variant="standard"
            name="current_ctc"
            type="text"
            error={error.current_ctc}
            value={data.current_ctc}
            handlechange={handlechange}
            placeholder="Enter your Role name"
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>


        <TextInput 
            label={'Expected CTC'}  
            variant="standard"
            name="expected_ctc"
            type="text"
            error={error.expected_ctc}
            value={data.expected_ctc}
            handlechange={handlechange}
            placeholder="Enter your Role name"
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>    

       
          <div className='relative  mb-1'>
          <h6 className='text-[11px] mt-2 font-[600]' >Resume</h6>
          {!['',null,undefined]?.includes(data?.resume) &&
          <h6 onClick={()=>handleDownload(data?.resume)} className='text-[10px] cursor-pointer absolute right-0 top-0 underline font-[600] ' >Download Resume</h6>}
          <Uploader image={data?.resume}  setimagefunc={(e)=>{uploadfile(e,'resume')}}  removeimageuploadfunc = {()=>setdata({...data,resume:''})}/>
          </div>  
         
        
<div className='mt-5'>
        <ButtonFilledAutoWidth btnName={(state?._id !== null && state?._id !== undefined) ? "UPDATE Project Job Apply" : "ADD Project Job Apply"}  onClick={submitform} />  
        </div>


    </div>
    </div>
  )
}

export default ProjectJobApplyCE