import React,{useEffect, useState} from 'react'
import { DatePicker,Modal,Drawer } from 'antd';
import { IconButton } from '@material-ui/core';
import {FiChevronRight,FiChevronLeft} from 'react-icons/fi';
import {  ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../components/button';
import AtsMenu from './AtsMenu';
import {AiOutlineFilter,AiOutlineDelete,AiOutlineEdit,AiOutlineFilePdf,AiOutlineUserAdd} from 'react-icons/ai';
import {BiReset} from 'react-icons/bi';
import { RiBillLine } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { DeleteAtsJobService, FilterAtsJobService, GetAtsJobService } from '../../services/Ats/AtsJobServices';
import AtsEmployeeManagmentCE from './AtsEmployeeManagmentCE';
import { useSelector } from 'react-redux';
import GoBack from '../../components/back/GoBack';
import {IoChevronBackCircleOutline} from 'react-icons/io5'


function AtsClientManagment() {

  const roles = useSelector(state=>state.Auth.roles)
  const user = useSelector(state=>state.Auth.id)
  const [search,setsearch] = useState({text:'',from_date:'',to_date:'',activate:false})
  const [selected_data,setselected_data] = useState({})
  const [modal,setmodal] = useState(false)
  const [addmodal,setaddmodal] = useState(false)

  const [page,setpage] = useState(1)
  const [index,setindex] = useState(1)

  const [step,setstep] = useState(1)
  const [requirments,setrequirments] = useState([])

  const stage = ['Pending','Closed','Lost']

  const [data,setdata] = useState([])

  const navigator = useNavigate()


  useEffect(()=>{
    setstep(1)
    if(search?.activate){
      filterdata(page)
    }else{
      getdata(page)
    }
  },[page,index])

  async function getdata(page){
    const response = await GetAtsJobService({page:page,stage:stage[index-1]})
    setdata(response.data)
  }

  async function filterdata(page=1){
    setsearch({...search,activate:true})
    const response = await FilterAtsJobService({...search,page:page})
    setdata(response.data)
  }

  async function resetdata(){
    setsearch({text:'',from_date:'',to_date:'',activate:false})
    getdata(1)
  }

  async function deleteDatafunc(){
    const response = await DeleteAtsJobService(selected_data?._id)
    if(response?.status === 200){
      toast.success("Deleted Successfully")
      getdata(page)
      setmodal(false)
    }
  }


  return (
    <div className='w-[98%]'>
       
       <Modal open={modal} className='absolute top-0 left-[41%]' width={320} closable={false} footer={false}>
          <div> 

            <h6 className='text-[15px] font-[700]'>Delete Data</h6>
            <h6 className='text-[11px] leading-tight bg-gray-100 p-2' >Before Deleting the data be sure that it is required or not for further purpose.</h6>
             
             <h6 className='mb-2 mt-2 text-[11px]'>Position Name : <span className='font-[800]'>{selected_data?.job_title}</span> For the Company Name : <span className='font-[800]'>{selected_data?.client_name}</span> Address : <span className='font-[800]'>{selected_data?.address}</span> </h6>
             <div className='flex items-center border-t pt-2'>
                <ButtonOutlinedAutoWidth btnName="Cancel" onClick={()=>setmodal(false)} />
                <h6 className='w-[10px]'></h6>
                <ButtonFilledAutoWidth btnName="Sure" onClick={deleteDatafunc} />
             </div>
          </div>
       </Modal>

       <Drawer bodyStyle={{ padding:'0px 20px' }} mask={true} maskClosable={true} open={addmodal} width={'50%'} closable={false} footer={false} >
         <div className='bg-white-100'>
            <AtsEmployeeManagmentCE selected_data={selected_data} closemodal={()=>setaddmodal(false)} step={0} />
         </div>
       </Drawer>
    
       <div className='flex'>
            <div className='min-w-44'>
            <AtsMenu />
            </div>
            <div className='w-[100%] pl-5 mt-4'>
              <div className='flex items-center -mt-1 border-b justify-between pb-1'>

              <h6 className='font-[800] text-[13px]'>Total Jobs  ({data?.pagination?.total})</h6>
                      
                      <div className='flex items-center text-[12px]'>
                      <div className='flex items-center'>
                      <h6 className='mr-2 font-[600]'>{page == 1 ? data?.datas?.length > 0 ? 1 : 0 : (page - 1) * data?.pagination?.limit } - {data?.pagination?.limit} of {data?.pagination?.total} </h6>
                      <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>
                      <IconButton onClick={()=>{ page < data?.pagination?.totalPages  ? setpage(page+1) : console.log('')}}><FiChevronRight className={`${(data?.pagination?.totalPages === page || data?.datas?.length === 0) ? 'opacity-50 ' : 'opacity-100'}`}  size={16} /></IconButton>

                      </div>
                      <DatePicker  size='small' style={{fontSize:'11px'}} ampm={false} placeholder='From Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.from_date} onChange={(v) => {console.log('v na',new Date(v).toLocaleDateString());setsearch({...search,from_date:v})}} /> 

                      <DatePicker size='small' style={{fontSize:'11px'}} ampm={false} placeholder='To Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.to_date} onChange={(v) => {console.log('v na',new Date(v).toLocaleDateString());setsearch({...search,to_date:v})}} /> 

                      
                      <div className='mr-2'>
                          

                      <input  id="search_text" placeholder='Search text' type='text' value={search.text} onChange={(e)=>setsearch({...search,text:e.target.value})} className='border py-[3px] focus:ring-0 focus:outline-0 text-[14px]  w-[100px] px-1 rounded-md border-slate-300' />
                  

                      </div>  
                      <div className='mr-2'>
                        <AiOutlineFilter onClick={()=>filterdata(1)} size={24} className='border border-slate-600 bg-slate-600 text-[#dedede] p-1 rounded' />
                      </div>
                      <div className='mr-2'>
                      <BiReset size={24} onClick={resetdata} className='border border-slate-300 bg-[#f6f8fa] text-[#5c5c5c] p-1 rounded' />
                      </div>
                      <ButtonFilledAutoWidth btnName="Add New" onClick={()=>navigator('create')} />
                      </div>
              </div>
              <div className='flex border-b'>
                <h6 onClick={()=>setindex(1)} className={`px-2  text-[11px] py-1.5 font-[600] cursor-pointer  ${index == 1 ? 'bg-slate-600 text-white' : 'border-white' }`}>Active Jobs</h6>
                <h6 onClick={()=>setindex(2)} className={`px-2  text-[11px] py-1.5 font-[600] cursor-pointer  ${index == 2 ? 'bg-slate-600 text-white' : 'border-white' }`}>Closed Jobs</h6>
                <h6 onClick={()=>setindex(3)} className={`px-2  text-[11px] py-1.5 font-[600] cursor-pointer  ${index == 3 ? 'bg-slate-600 text-white' : 'border-white' }`}>Lost Jobs</h6>
              </div>
               
               
              {step == 2 && 
              <div onClick={()=>setstep(1)} className='flex text-gray-800 mb-0 items-center mt-4 cursor-pointer'>
                <IoChevronBackCircleOutline />
                <span className='ml-1 text-[11px] font-[400]'>Go Back</span>
             </div>}

              {data?.datas?.length > 0 &&
              <div className='max-h-[85vh] mt-4 overflow-y-scroll'>
                <div className='grid grid-cols-5 gap-1'>
                   
                  {step === 1 && 
                    <>
                    {data?.datas?.map((d)=>(
                      <div onClick={()=>{setrequirments(d?.requirments);setstep(2)}} key={d?._id} className='border p-2  cursor-pointer'>
                         <h1 className='text-[12px]'>Requirments : <span className='font-[800]  text-[12px]'> {d?.requirments?.length}</span></h1>
                         <h1 className='text-[12px]'>Company / Client : <span className='font-[800]  text-[12px]'> {d?.ats_hr_client?.client_name}</span> / <span className='font-[800]  text-[12px]'> {d?.ats_hr_client?.company_name}</span></h1>
                        
                        

                       

                      </div> 
                      ))  
                    }
                    </>}

                  {step === 2 && 
                  <>
                  {requirments?.map((d)=>(
                    <div  key={d?._id} className='border p-2 cursor-pointer'>
                        <h1 className='text-[12px]'>Position : <span className='font-[800]  text-[12px]'> {d?.job_title}</span></h1>
                        <h1 className='text-[12px]'>Company / Client : <span className='font-[800]  text-[12px]'> {d?.ats_hr_client?.client_name}</span> / <span className='font-[800]  text-[12px]'> {d?.ats_hr_client?.company_name}</span></h1>
                        <div className='border flex mt-2 border-gray-200'>
                          <div className='border-r w-[33%]  border-gray-200 '>
                            <h6 className='text-[11px] bg-slate-50 text-center font-[400]'>Opening</h6>
                            <h6 className='text-[12px] text-center font-[800]'>{d?.no_of_opening}</h6>
                          </div> 

                          <div className='border-r w-[33%]  border-gray-200 '>
                            <h6 className='text-[11px] bg-slate-50 text-center font-[400]'>Experience</h6>
                            <h6 className='text-[12px] text-center font-[800]'>{d?.experience}</h6>
                          </div> 

                          <div className='w-[34%]'>
                            <h6 className='text-[11px] bg-slate-50 text-center font-[400]'>Notice</h6>
                            <h6 className='text-[11px] text-center font-[800]'>{d?.notice_period}</h6>
                          </div> 
                      </div>  
                      
                      {['admin','ats_head']?.filter((i)=>roles?.includes(i))?.length > 0 &&
                      <h1 className='text-[11px] mt-1'>Created By : <span className='font-[600]  text-[10px]'> {d?.created_by?.name}</span> </h1>}

                    
                      <h6 className='text-[12px] font-[600] mt-2'>Location : </h6>
                      <div className='flex flex-wrap'>  
                        {d?.location?.split(':')?.map((e,i) => (
                        <span key={i} className='text-[10px] font-[400] mt-1 uppercase bg-slate-100 p-1 mr-1'>{e}</span>
                        ))}
                      </div>

                      <div className='flex border-t mt-2 pt-1 justify-end'>
                          <AiOutlineUserAdd size={14}  className='mr-2'  onClick={()=>{setselected_data(d);setaddmodal(true)}} />
                          {d?.job_attachment !== undefined &&
                          <a href={`${process.env.REACT_APP_BACKEND_IMAGE_URL}${d?.job_attachment}`} target='_blank'><AiOutlineFilePdf size={14}  className='mr-2' /></a>}
                          <RiBillLine size={14}  className='mr-2'  onClick={()=>{navigator('detail',{state:{data:d._id}})}} />
                          {((user === d?.created_by?._id) || ['admin','ats_head'].filter(f => roles?.includes(f))?.length > 0) &&
                          <AiOutlineEdit size={14}  className='mr-2' onClick={()=>{navigator('edit',{state:{data:d}})}} />
                          }
                        {['admin','ats_head','ats_manager'].filter(f => roles?.includes(f))?.length > 0 &&
                          <AiOutlineDelete size={14}  onClick={()=>{setselected_data(d);setmodal(true)}} />}
                      </div>  

                    </div> 
                    ))  
                  }
                  </>}  
                </div>  
              </div>}
            </div>
        </div>    
    </div>
  )
}

export default AtsClientManagment