import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

function InvoicePushtoDetail() {
  const {id} = useParams() 
  const navigator = useNavigate()

  useEffect(()=>{
     if(id !== ''){
        navigator('/finance/invoice/detail',{state:{_id:id}})
     }
  },[])
 
}

export default InvoicePushtoDetail