import { deleteRequest, get, publicPost, put } from "../../../helpers/apihelpers";

export const CreateTransactionWebsiteStageService=async(data)=>{
    try {
        const result = await publicPost(`api/transaction_website/stages/create`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const SearchTransactionWebsiteStageService=async(text,type)=>{
    try {
        const result = await get(`api/transaction_website/stages/search?type=${type}&text=${text}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateTransactionWebsiteStageService=async(data,id)=>{
    try {
        const result = await put(`api/transaction_website/stages/update/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetTransactionWebsiteStageService=async(page,text,type)=>{
    try {
        const result = await get(`api/transaction_website/stages/get?page=${page}&text=${text}&type=${type}`,);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteTransactionWebsiteStageService=async(id)=>{
    try {
        const result = await deleteRequest(`api/transaction_website/stages/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}