import { deleteRequest, get, post, put } from "../helpers/apihelpers";

export const CreateTeamTaskService=async(data)=>{
    try {
        const result = await post(`api/team_tasks_project/create`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateTeamTaskService=async(data,id)=>{
    try {
        const result = await put(`api/team_tasks_project/update/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetTeamTaskService=async({page})=>{
    try {
        const result = await get(`api/team_tasks_project/get?page=${page}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const FilterDailyTaskService=async({stage,step,page})=>{
    try {
        const result = await get(`api/team_tasks_project/filter?related_to=${stage}&stage=${step}&page=${page}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteTeamTaskService=async(id)=>{
    try {
        const result = await deleteRequest(`api/team_tasks_project/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

