import React from 'react'
import GalleryAppBar from './GalleryAppBar'

function GalleryMainPage() {
  return (
    <div className='h-screen max-h-screen box-border overflow-hidden'>
        <div className='block sm:flex'>
        <GalleryAppBar />

        <div className='w-[86%]  ml-4  mt-4'>
        </div>
        </div>
    </div>
  )
}

export default GalleryMainPage