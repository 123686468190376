import React,{useState,useEffect} from 'react'
import { TextAreaInput1, TextInput, TextInput1 } from '../../components/input'
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../components/button'
import { toast } from 'react-hot-toast'
import GoBack from '../../components/back/GoBack'
import { useLocation, useNavigate } from 'react-router-dom'
import TaskMenu from './TaskMenu'
import { CreateDailyTaskService, UpdateDailyTaskService } from '../../services/DailyTaskServices'
// import LeadMenu from '../LeadMenu'
import {BiCheckbox,BiCheckboxSquare} from 'react-icons/bi'
import { GetDepartmentService } from '../../services/DepartmentServices'
import { Modal, Select } from 'antd'
import { GetSearchService } from '../../services/AuthServices'
import {BiErrorCircle} from 'react-icons/bi'
import Uploader from '../../components/Uploader'
import { useSelector } from 'react-redux'
import { AiOutlinePlus,AiOutlineDelete } from 'react-icons/ai'
import { CreateReminderService } from '../../services/ReminderServices'
import * as XLSX from 'xlsx';
import {FiUpload} from 'react-icons/fi'
import {IoClose} from 'react-icons/io5'
import { Tooltip } from '@mui/material'
import { CreateMDMeetTaskService, UpdateMDMeetTaskService } from '../../services/MdMeetServices'
import moment from 'moment'

function MDMeetCE() {

  const {state,pathname} = useLocation() 
  const navigator = useNavigate()
  

  
  const path = pathname?.split('/')[pathname?.split('/')?.length - 1]
  const path1 = pathname?.split('/')[pathname?.split('/')?.length - 3]



  const [singleData,setsingleData] = useState({name:'',mobile:'',email:'',purpose_of_meeting:'',meeting_summary:'',remarks:'',date_time:'',type:'1'})
  const [error,seterror] = useState({name:'',mobile:'',email:'',purpose_of_meeting:'',meeting_summary:'',remarks:'',date_time:'',type:''})
  const [departmentArr,setdepartmentArr] = useState([])
  const [modal,setmodal] = useState(false)
  const [users,setusers] = useState([])

  const [data,setdata] = useState({title:'',description:'',date:'',date1:'',time:'',added:false,expired:false,timestamp:''})
  const [error1,seterror1] = useState({title:'',description:'',date:'',date1:'',time:'',added:false,expired:false,timestamp:''})
  

  const slots = [
    {label:'09.00 AM - 11.00 AM',value:'09.00 AM - 11.00 AM'},
    {label:'11.00 AM - 01.00 PM',value:'11.00 AM - 01.00 PM'},
    {label:'02.00 PM - 04.00 PM',value:'02.00 PM - 04.00 PM'},
    {label:'04.10 PM - 10.00 PM',value:'04.00 PM - 10.00 PM'},
  ]

  const stages = [
    {label:'Follow Up',value:'1'},
    {label:'Completed',value:'2'},
    {label:'No Response',value:'3'},
  ]
  



  

  const [loader,setloader] = useState(false)

  useEffect(()=>{
   
    if(path === 'edit'){
      let d = state

      const date = new Date(d?.date_time);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      
      // Set the formatted date string to state
      const localDateString = `${year}-${month}-${day}T${hours}:${minutes}`;

     
      let edit_data = {
        ...d,
        name:d?.name,
        mobile:d?.mobile,
        email:d?.email,
        purpose_of_meeting:d?.purpose_of_meeting,
        meeting_summary:d?.meeting_summary,
        remarks:d?.remarks,
        date_time:localDateString,
        type:d?.type
    }

      setsingleData({...edit_data})
    }
    getoptions()
  },[])


  async function getoptions(){
    const response = await GetDepartmentService()
    let arr = []

    response?.data?.datas?.forEach((d)=>{
        arr.push({value:d?.id,label:d?.department_name})
    })

    setdepartmentArr(arr)
  } 


  // console.log("data",singleData)

  async function submitform(){
    setloader(true)
        if(!singleData.name){
            seterror({...error,name:'The name field is required'})
        }else if(!singleData.date_time){
            seterror({...error,date_time:'The date field is required'})
        }else if(!singleData.purpose_of_meeting){
            seterror({...error,purpose_of_meeting:'The purpose of meeting field is required'})
        }else if(!singleData?.meeting_summary){
            seterror({...error,meeting_summary:'The meeting summary field is required'})
        } else{
           
            let send_data = {
               ...singleData
            }

          
            if(path === 'edit'){
              delete send_data.created_by
              const response = await UpdateMDMeetTaskService(send_data,state?._id)
              if(response.status === 200){
                  setloader(false)
                  toast.success('MD Meet Updated Successfully')
                  resetform() 
              }
            }else{
              const response = await CreateMDMeetTaskService(send_data)
              if(response.status === 201){
                  setloader(false)
                  toast.success('MD Meet Created Successfully')
                  resetform() 
              }
            }
        }
    
  }


  function resetform(){
        setsingleData({name:'',mobile:'',email:'',purpose_of_meeting:'',meeting_summary:'',remarks:'',date_time:'',type:''})
        seterror({name:'',mobile:'',email:'',purpose_of_meeting:'',meeting_summary:'',remarks:'',date_time:'',type:''})
        if(path === 'edit'){
            navigator(-1)
        }
       
  }

  async function handleSelect(e,e1){
        if(e1 === 'department'){
         setsingleData({...singleData,department:departmentArr?.find((d)=>d.value === e)})
         seterror({...error,department:''})
        }
        if(e1 === 'assigned_to'){
         setsingleData({...singleData,assigned_to:users?.find((d)=>d.value === e)})
         seterror({...error,assigned_to:''})
        }
        if(e1 === 'slots'){
         setsingleData({...singleData,slots:slots?.find((d)=>d.value === e)})
         seterror({...error,slots:''})
        }
  }    

  function handlechange(e){
        setsingleData({...singleData,[e.target.name] : e.target.value})
        seterror({...error,[e.target.name] : ''})
  }


 
  async function handlechange2(e){
    setdata({...data,[e.target.name] : e.target.value})
    seterror1({...error1,[e.target.name] : ''})
  }


  async function addreminder(){
    if(!data.date){
      // console.log("1")
      seterror1({...error1,date:'This Field is required'})
      setdata({...data,added:false})
    }else  if(!data.time){
      // console.log("2")
      seterror1({...error1,time:'This Field is required'})
      setdata({...data,added:false})
    }else  if(!data.title){
      // console.log("3")
      seterror1({...error1,title:'This Field is required'})
      setdata({...data,added:false})
    }else  if(!data.description){
      // console.log("4")
      seterror1({...error1,description:'This Field is required'})
      setdata({...data,added:false})
    }else{
      // console.log("5")
        setdata({...data,added:true})
        if(path === 'edit'){
          addreminderfunc(state?.data?._id)
        }else{
          setmodal(false)
        }
    }

  }

  async function addreminderfunc(v){
    let oldData = {...data}
    oldData['on_date_time'] = data.date + ' ' + data.time
    oldData['type'] = 'TaskSchema'
    oldData['type_id'] = v
    oldData['repeat'] = 'Once'
    oldData['notify'] = 'Email'
    delete oldData.date
    delete oldData.time
    delete oldData.added
    delete oldData.expired

    const response = await CreateReminderService(oldData)
    if(response?.status === 200){
      toast.success("Reminder Created Successfully")
      setmodal(false)
      setdata({title:'',description:'',date:'',date1:'',time:'',added:false,expired:false})
      seterror1({title:'',description:'',date:'',date1:'',time:'',added:false,expired:false})
    }

  }


  return (
    <div className='flex min-h-screen max-h-screen h-screen '>

      <Modal open={modal} width={280} footer={false} closable={false}>
      <h6 className='text-[14px] font-[700]'>Create Reminder for the task</h6>  
            <h6 className='bg-slate-100 text-[10px] font-[500] p-1'>Use the below form to create the reminder to trigger for you</h6> 
           
              <div className='flex justify-between'>
              <div className='w-[48%]'>
              <h6 className='text-[11px] font-[600] mb-1 mt-2' >Date</h6>   
              <div className='border border-slate-300 '>
              <input type='date' value={data?.date} onChange={(v) => {setdata({...data,date:v.target.value});seterror1({...error1,date:''})}}  placeholder='' bordered={false} className='text-[12px] px-1 py-1.5 focus:outline-none w-[100%]' /> 
             
              </div>
              {error1?.date !== '' &&
              <div className='flex items-center mt-1'>
                  <BiErrorCircle className='text-red-500' size={14} />
                  <span className='text-[10px] text-red-500 ml-1'>{error1?.date}</span>
              </div>}
              </div>

              <div className='w-[48%]'>
              <h6 className='text-[11px] font-[600] mb-1 mt-2' >Time</h6>   
              <div className='border border-slate-300 '>
              <input type='time' value={data?.time} onChange={(v) => {setdata({...data,time:v.target.value});seterror1({...error1,time:''})}}  placeholder='' bordered={false} className='text-[12px] px-1 py-1.5 focus:outline-none w-[100%]' /> 
              
              </div>
              {error1?.time !== '' &&
              <div className='flex items-center mt-1'>
                  <BiErrorCircle className='text-red-500' size={14} />
                  <span className='text-[10px] text-red-500 ml-1'>{error1?.time}</span>
              </div>}
            </div>
            </div>

            <TextInput1 
             mandatory={true}
             label={'Title'}  
             variant="standard"
             name="title"
             type="text"
             value={data?.title}
             error={error1?.title}
             handlechange={handlechange2}
             placeholder=""
             InputLabelProps={{
                 style: { color: '#fff', }, 
             }}
            />


            <TextAreaInput1 
                mandatory={true}
                label={'Description'}  
                variant="standard"
                name="description"
                type="text"
                value={data?.description}
                error={error1?.description}
                handlechange={handlechange2}
                placeholder=""
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>


<div className='flex mt-2 border-t pt-2 w-full items-end '>
                <div className='mr-2'>
                <ButtonOutlinedAutoWidth btnName="Cancel" onClick={()=>setmodal(false)} />
                </div>
                <ButtonFilledAutoWidth btnName="Save" onClick={()=>addreminder()} />
            </div>

      </Modal>
       <TaskMenu />
           
           <div className='flex min-w-[300px] max-w-[300px]'>
           <div className=' border-r pr-5 border-slate-200 pl-5 min-h-screen max-h-screen h-screen overflow-y-scroll overflow-x-hidden' >
            <GoBack />
            
            <div className='border-b pb-2'>
                <h6 className='text-[13px] mb-1 font-[800]'>Create/Edit the MD Meet Task Done By You</h6>
                <h6 className='text-[12px] bg-slate-200 p-2'>Use the Below form to add the md meet daily task report </h6>
                
            </div>

           

              <TextInput  value={singleData?.name} mandatory={true} error={error?.name} handlechange={handlechange} name="name" label="Name" />
              <TextInput  value={singleData?.mobile} mandatory={false} error={error?.mobile} handlechange={handlechange} name="mobile" label="Mobile" />
              <TextInput  value={singleData?.email} mandatory={false} error={error?.email} handlechange={handlechange} name="email" label="Email" />
              <TextInput type="datetime-local" value={singleData?.date_time} mandatory={true} error={error?.date_time} handlechange={handlechange} name="date_time" label="Date & Time" />
              <TextInput  value={singleData?.purpose_of_meeting} mandatory={true} error={error?.purpose_of_meeting} handlechange={handlechange} name="purpose_of_meeting" label="Purpose of Meet" />
              <TextAreaInput1  value={singleData?.meeting_summary} mandatory={true} error={error?.meeting_summary} handlechange={handlechange} name="meeting_summary" label="Meeting Summary" />
              <TextAreaInput1  value={singleData?.remarks} mandatory={false} error={error?.remarks} handlechange={handlechange} name="remarks" label="Remarks" />


              
              {path !== 'create' && <>
              <h6 className='text-[12px] font-semibold mb-1 mt-1'>Task Stage </h6>
                <Select
                value={singleData.type} 
                error={error.type}
                bordered={false}
                placeholder="" 
                onChange={(v)=>setsingleData({...singleData,type:v})} 
                options={stages} 
                className='w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none  focus:border-transparent focus:ring-offset-0 focus:shadow-none'
                />
                </>}

              <div className='flex mt-2 border-t pt-2 w-full items-end '>
                  <div className='mr-2'>
                  <ButtonOutlinedAutoWidth btnName="Cancel" onClick={()=>navigator(-1)} />
                  </div>
                  <ButtonFilledAutoWidth btnName="Save" onClick={submitform} />
              </div>

              
           </div>
           </div>


    </div>
  )
}

export default MDMeetCE