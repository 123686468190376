import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { TbCategory, TbBrandGoogleBigQuery } from "react-icons/tb";
import { BiUser } from "react-icons/bi";
import { MdOutlineLandscape } from "react-icons/md";
import { RiBillLine } from 'react-icons/ri';
import { MdOutlineNoteAlt } from "react-icons/md";
import { AiOutlineDashboard } from "react-icons/ai";
import { TbReportAnalytics } from "react-icons/tb";

function FcaresMenu(){

  const {pathname} = useLocation();
  const path = pathname.split('/')[pathname.split('/').length - 1]
//   const roles = useSelector(state=>state.Auth.roles)
//   const user = useSelector(state=>state.Auth)


   const navigate = useNavigate();

   useEffect(()=>{
       if(path === 'fcares'){
        navigate('dashboard')
       }
   },[])

    const menu = [
       {name:'Dashboard',icon:AiOutlineDashboard,path:'/fcares/dashboard',id:8,color:'',roles:[]},
       {name:'User List',icon:BiUser,path:'/fcares/user_list',id:1,color:'',roles:[]},
       {name:'Tenant List',icon:BiUser,path:'/fcares/tenant_list',id:6,color:'',roles:[]},
       {name:'Property List',icon:MdOutlineLandscape,path:'/fcares/property_list',id:2,color:'',roles:[]},
       {name:'Services List',icon:TbCategory,path:'/fcares/services_list',id:3,color:'',roles:[]},
       {name:'Tickets List',icon:RiBillLine,path:'/fcares/ticket_list',id:4,color:'',roles:[]},
       {name:'Upcoming Bill',icon:MdOutlineNoteAlt,path:'/fcares/upcoming_bill_list',id:5,color:'',roles:[]},
       {name:'Query List',icon:TbBrandGoogleBigQuery,path:'/fcares/query_list',id:6,color:'',roles:[]},
    //    {name:'Report List',icon:TbReportAnalytics,path:'/fcares/report_list',id:6,color:'',roles:[]},
    ]  
    
    return (
        <div className='mr-0 min-h-screen max-h-sceen overflow-hidden border-r min-w-44  w-44 max-w-44  px-2'>
            <h6 className='pt-5 ml-2 mb-2 font-[700] text-[12px]'>Basic Option</h6>

            {menu.map((m)=>(
                // roles?.filter((f)=>m?.roles?.includes(f))?.length > 0 && <>
                <div key={m.path} className={`flex items-center my-1 hover:bg-[#f4f5f7] px-1.5 py-1 ml-2 rounded-md relative cursor-pointer ${pathname ===  m.path && 'bg-[#f4f5f7]'}`} onClick={()=>navigate(m?.path)}>
                {pathname ===  m.path &&  <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3' ></span>}
                <m.icon color='#000' style={{backgroundColor:m?.color,padding:'2px'}} size={16}  />
                <span className='text-[#000] font-[500] ml-2 text-[12px]'>{m.name}</span>
                </div>    
                // </>
            ))}


       </div>
    )
}

export default FcaresMenu