import React,{useState} from 'react'
import ProfileMenu from './ProfileMenu'
import {  ButtonFilled, ButtonOutlinedAutoWidth, ButtonOutlinedAutoWidthError } from '../../components/button'
import { Modal } from 'antd'
import { TextInput } from '../../components/input'
import {IoCloseOutline} from 'react-icons/io5'
import { UpdateUserNameService } from '../../services/AuthServices'
import { useSelector } from 'react-redux'
import { toast } from 'react-hot-toast'



function Account() {

  const [visible,setvisible] = useState(false)
  const [name,setname] = useState('')
  const user = useSelector(state=>state.Auth)

  

  async function submitForm(){
      const fd = new FormData()
      fd.append('name',name)
      const response = await UpdateUserNameService(fd,user?.id)
      if(response.status === 200){
        toast.success("Account Name Updated")
        setvisible(false)
        setname('')
      }
  }

  return (
    <div className='px-2 mx-0 box-border'>

      <Modal open={visible} className='absolute top-5 left-[40%]'  footer={false} closable={false} width={350}>
        <div className='relative'>
          <h6 className='font-[700]'>Enter a new username</h6>
          <IoCloseOutline className="absolute cursor-pointer -right-2 -top-2" onClick={()=>setvisible(false)} />
          <TextInput readOnly={false} value={name} handlechange={e =>setname(e.target.value)} />

          
          <div className={`mt-4 ${name?.length < 3 ? 'opacity-50' : 'opacity-100'}`}>
           
          <ButtonFilled btnName="Update My Name" onClick={submitForm} />
          </div>


        </div>
      </Modal>

        {/* <MainprofileAppbar  /> */}

        <div className='flex'>
        <ProfileMenu />

        <div className='ml-6 w-full ml-4 mt-4 pt-5'>
        <h6 className='border-b mt-1 pb-2 mb-4 font-[700] text-[17px] border-[#d0d7de]'>Change Username</h6>


<div className='md:flex'>    
    <div className='mr-0 w-full md:w-3/5 mr-16'>
    <div className='block sm:flex'>

    <div className='mr-0 sm:mr-4'>
       
        <span className='text-[12px] font-[400] text-gray-700 pb-0 leading-none' >Changing your username can have <span className='text-[#0a69da] cursor-pointer'>unintended side effects</span>.</span> 

        <div className='my-3'>
        <ButtonOutlinedAutoWidth onClick={()=>setvisible(true)} btnName="Change username" />
        </div>

        <span className='text-[12px] font-[400] text-gray-700 pb-0 leading-tight' >Looking to manage account security settings? You can find them in the <span className='text-[#0a69da] cursor-pointer hover:underline'>Password and authentication</span> page..</span> 


        <h6 className='text-[#cf212e] text-[16px] font-[600] mt-5 border-b pb-2 border-[#d0d7de]'>Delete account</h6> 

        <h6 className='text-[12px] font-[400] text-gray-700 pb-0 leading-none pt-4'>Once you delete your account, there is no going back. Please be certain.</h6>

        <div className='my-3'>
        <ButtonOutlinedAutoWidthError btnName="Delete your account" />
        </div>

        </div> 
        </div>
        </div>

        </div>
        </div>    

    </div>    
    </div>
  )
}

export default Account