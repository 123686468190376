import React,{useEffect, useState} from 'react'
import { DatePicker,Modal } from 'antd';
import { IconButton, Tooltip } from '@material-ui/core';
import {FiChevronRight,FiChevronLeft} from 'react-icons/fi';
import {  ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../components/button';
import AtsMenu from './AtsMenu';
import { DeleteAtsClientService, FilterAtsClientService, GetAtsClientService } from '../../services/Ats/AtsClientServices';
import {AiOutlineFilter,AiOutlineDelete,AiOutlineEdit} from 'react-icons/ai';
import {BiReset} from 'react-icons/bi';
import { RiBillLine } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import {BiCheckboxSquare,BiCheckbox} from 'react-icons/bi';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';


function AtsClientManagment() {

  const roles = useSelector(state=>state.Auth.roles)
  const [search,setsearch] = useState({text:'',from_date:'',to_date:'',activate:false})
  const [selected_data,setselected_data] = useState({})
  const [modal,setmodal] = useState(false)
  const [index,setindex] = useState(1)

  const stage = ['Active','InActive','Hold']

  const [page,setpage] = useState(1)
  const navigate = useNavigate()

  const [datas,setdatas] = useState({})

  const [data,setdata] = useState([])

  const navigator = useNavigate()


  
  useEffect(()=>{
    if(search?.activate){
      filterdata(page)
    }else{
      getdata(page)
    }
  },[page,index])

  async function getdata(page){
    const response = await GetAtsClientService({page:page,stage:stage[index-1]})
    setdata(response.data)
  }

  async function filterdata(page=1){
    setsearch({...search,activate:true})
    console.log("page",page)
    const response = await FilterAtsClientService({...search,page:page})
    setdata(response.data)
  }

  async function resetdata(){
    setsearch({text:'',from_date:'',to_date:'',activate:false})
    getdata(1)
  }

  async function deleteDatafunc(){
    const response = await DeleteAtsClientService(selected_data?._id)
    if(response?.status === 200){
      toast.success("Deleted Successfully")
      getdata(page)
      setmodal(false)
    }
  }

  return (
    <div className='w-[98%]'>
       
       <Modal open={modal} width={320} closable={false} footer={false}>
          <div> 

            <h6 className='text-[15px] font-[700]'>Delete Data</h6>
            <h6 className='text-[11px] leading-tight bg-gray-100 p-2' >Before Deleting the data be sure that it is required or not for further purpose.</h6>
             
             <h6 className='mb-2 mt-2 text-[11px]'>Company Name : <span className='font-[800]'>{selected_data?.company_name}</span> Client Name : <span className='font-[800]'>{selected_data?.client_name}</span> Address : <span className='font-[800]'>{selected_data?.address}</span> </h6>
             <div className='flex items-center border-t pt-2'>
                <ButtonOutlinedAutoWidth btnName="Cancel" onClick={()=>setmodal(false)} />
                <h6 className='w-[10px]'></h6>
                <ButtonFilledAutoWidth btnName="Sure" onClick={deleteDatafunc} />
             </div>
          </div>
       </Modal>
    
       <div className='flex'>
            <div className='min-w-44'>
            <AtsMenu />
            </div>
            <div className='w-[100%] pl-5 mt-4'>
              <div className='flex items-center -mt-1 border-b justify-between pb-1'>

              <h6 className='font-[800] text-[13px]'>Total Clients  ({data?.pagination?.total})</h6>
                      
                      <div className='flex items-center text-[12px]'>
                      <div className='flex items-center'>
                      <h6 className='mr-2 font-[600]'>{page == 1 ? data?.datas?.length > 0 ? 1 : 0 : (page - 1) * data?.pagination?.limit } - {data?.pagination?.limit} of {data?.pagination?.total} </h6>
                      <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>
                      <IconButton onClick={()=>{ page < data?.pagination?.totalPages  ? setpage(page+1) : console.log('')}}><FiChevronRight className={`${(data?.pagination?.totalPages === page || data?.datas?.length === 0) ? 'opacity-50 ' : 'opacity-100'}`}  size={16} /></IconButton>

                      </div>
                      <DatePicker  size='small' style={{fontSize:'11px'}} ampm={false} placeholder='From Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.from_date} onChange={(v) => {console.log('v na',new Date(v).toLocaleDateString());setsearch({...search,from_date:v})}} /> 

                      <DatePicker size='small' style={{fontSize:'11px'}} ampm={false} placeholder='To Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.to_date} onChange={(v) => {console.log('v na',new Date(v).toLocaleDateString());setsearch({...search,to_date:v})}} /> 

                      
                      <div className='mr-2'>
                          

                      <input  id="search_text" placeholder='Search text' type='text' value={search.text} onChange={(e)=>setsearch({...search,text:e.target.value})} className='border py-[3px] focus:ring-0 focus:outline-0 text-[14px]  w-[100px] px-1 rounded-md border-slate-300' />
                  

                      </div>  
                      <div className='mr-2'>
                        <AiOutlineFilter onClick={()=>filterdata(1)} size={24} className='border border-slate-600 bg-slate-600 text-[#dedede] p-1 rounded' />
                      </div>
                      <div className='mr-2'>
                         <BiReset size={24} onClick={resetdata} className='border border-slate-300 bg-[#f6f8fa] text-[#5c5c5c] p-1 rounded' />
                      </div>
                      <ButtonFilledAutoWidth btnName="Add New" onClick={()=>navigator('create')} />
                      </div>
              </div>
              <div className='flex border-b'>
                <h6 onClick={()=>setindex(1)} className={`px-2  text-[11px] py-1.5 font-[600] cursor-pointer  ${index == 1 ? 'bg-slate-600 text-white' : 'border-white' }`}>Active Client</h6>
                <h6 onClick={()=>setindex(2)} className={`px-2  text-[11px] py-1.5 font-[600] cursor-pointer  ${index == 2 ? 'bg-slate-600 text-white' : 'border-white' }`}>InActive Client</h6>
                <h6 onClick={()=>setindex(3)} className={`px-2  text-[11px] py-1.5 font-[600] cursor-pointer  ${index == 3 ? 'bg-slate-600 text-white' : 'border-white' }`}>Hold Client</h6>
              </div>

              {/* <h6>{['admin','ats_head','ats_manager'].filter(f => roles?.includes((f1)=>f1===f))?.length } {JSON.stringify(roles)}</h6> */}
               
              {data?.datas?.length > 0 &&
              <div className='max-h-[85vh] mt-4 border-t   border-l border-r overflow-y-scroll'>
                <div className='sticky top-0 z-50 flex  bg-white py-[-5px] border-b border-slate-200 relative'>
                    <h6  className='top-0 bg-white z-50 text-[12px] w-[60px]  px-2 py-1 font-[600] text-slate-600 border-r flex items-center justify-center'>Sl No</h6>
                    <h6 className='sticky top-0 z-50  text-[12px] w-[60px]  px-2 py-1 font-[600] text-slate-600 border-r'>Logo</h6>
                    <h6 className='sticky top-0 z-50  text-[12px] w-[130px]  px-2 py-1 font-[600] text-slate-600 border-r'>Company Name</h6>
                    <h6 className='sticky top-0 z-50  text-[12px] w-[140px]  px-2 py-1 font-[600] text-slate-600 border-r'>Client Name</h6>
                    <h6 className='sticky top-0 z-50  text-[12px] w-[140px]  px-2 py-1 font-[600] text-slate-600 border-r'>Client No</h6>
                    <h6 className='sticky top-0 z-50  text-[12px] w-[170px]  px-2 py-1 font-[600] text-slate-600 border-r'>Client Email</h6>
                    <h6 className='sticky top-0 z-50  text-[12px] w-[200px] px-2 py-1 font-[600] text-slate-600 border-r'>Address</h6>
                    <h6 className='sticky top-0 z-50  text-[12px] w-[90px] px-2 py-1 font-[600] text-slate-600 border-r'>Requirment </h6>
                    <h6 className='sticky top-0 z-50  text-[12px] w-[80px] px-2 py-1 font-[600] text-slate-600 border-r'>Active </h6>
                    <h6 className='sticky top-0 z-50  text-[12px] w-[30px]  px-2 py-1 font-[600] text-slate-600 sticky right-0'>Actions</h6>
                </div>
              
                {data?.datas?.map((d,i)=>(
                <div  key={d?._id} className={`flex z-50 border-b border-slate-200 z-0`}  >
                    <h6 className='sticky top-0 z-30 text-[12px] w-[60px]  px-2 py-1 font-[600] text-slate-600 cursor-pointer border-r flex items-center justify-center'>{page === 1 ? i+1 : ((i+1) + (datas?.pagination?.limit * (page-1)))} </h6>

                    <h6 className='text-[12px] relative font-[500] w-[60px]  px-2 py-1 truncate border-r  border-slate-200'>{(d?.client_logo !== undefined && d?.client_logo !== null) ? <img className='w-8 h-4 rounded overflow-hidden object-contain' onError={({currentTarget})=> {currentTarget.src = 'https://fidecrmfiles.s3.amazonaws.com/noprofile.png'}} src={`${process.env.REACT_APP_BACKEND_IMAGE_URL}${d?.client_logo}`} /> : <span className='bg-gray-100 w-5 h-5 ml-[6px] flex items-center justify-center  text-[10px] rounded overflow-hidden uppercase font-[700]'>{d?.client_name?.slice(0,2)}</span>}  </h6>
                    <h6 className='text-[12px] font-[500] w-[130px]  px-2 py-1 truncate border-r  border-slate-200'>{d?.company_name}</h6>
                    <h6 className='text-[12px] font-[500] w-[140px]  px-2 py-1 truncate border-r  border-slate-200'>{d?.client_name}</h6>
                    <h6 className='text-[12px] font-[500] w-[140px] px-2 py-1 truncate border-r  border-slate-200'>{d?.client_no}</h6>   
                    <h6 className='text-[12px] font-[500]  w-[170px] px-2 py-1 truncate border-r  border-slate-200'>{d?.client_email}</h6>
                    <h6 className='text-[12px] font-[500]  w-[200px]  px-2 py-1 truncate border-r  border-slate-200 sticky right-0'>{d?.address}</h6>
                    <h6 className='text-[12px] font-[500] w-[90px]  px-2 py-1 truncate border-r  border-slate-200 sticky right-0'>{d?.atshrclentjobs?.count === undefined ? 0 : d?.atshrclentjobs?.count}</h6>
                    <h6 className='text-[12px] font-[500] w-[80px]  px-2 py-1 truncate border-r  border-slate-200 sticky right-0'>
                      <span className='flex text-center items-center -ml-1 -mt-1 text-[12px]'>{d?.are_we_serving ?<BiCheckboxSquare size={22} className='text-slate-600'  /> :  <BiCheckbox size={22} className='text-slate-300'   />}</span>

                    </h6>
                    <h6  className='text-[12px] w-[30px]  px-2 py-1 mt-1 flex'>
                    
                     
                      <Tooltip title="Detail">
                      <span><RiBillLine size={13} className="z-10 ml-0" onClick={()=>navigate(`detail`,{state:{data:d?._id}})}/></span>
                      </Tooltip>


                      <Tooltip title="Edit">
                      <span><AiOutlineEdit size={13} className="z-10 ml-2" onClick={()=>{navigate('edit',{state:{data:d}})}}/></span>
                      </Tooltip>

                      {['admin','ats_head','ats_manager'].filter(f => roles?.includes((f1)=>f1===f))?.length > 0 &&
                      <Tooltip title="Delete">
                      <span><AiOutlineDelete size={13} className="z-10 ml-2 -mt-[1px]" onClick={()=>{setselected_data(d);setmodal(true)}}/></span>
                      </Tooltip>}


                      

                    
                    </h6>
                </div>))}
              </div>}
            </div>
        </div>    
    </div>
  )
}

export default AtsClientManagment