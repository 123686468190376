import { deleteRequest, get, post, postfd, put } from "../../helpers/apihelpers";

export const CreateFcaresBillService=async(data)=>{
    try {
        const result = await post(`api/fcares/maintenance`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateFcaresBillService=async(id,data)=>{
    try {
        const result = await put(`api/fcares/maintenance/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteFcaresBillService=async(id,data)=>{
    try {
        const result = await deleteRequest(`api/fcares/maintenance/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetFcaresBillService=async(from_date,to_Date,page,type,id)=>{
    try {
        const result = await get(`api/fcares/maintenance?page=${page}&from_date=${from_date}&to_date=${to_Date}&type=${type}&property_id=${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}