import React,{useEffect, useState} from 'react'
import ProfileMenu from './ProfileMenu'
import {  ButtonFilled, ButtonOutlinedAutoWidth, ButtonOutlinedAutoWidthError } from '../../components/button'
import { TextInput } from '../../components/input'
import {IoCloseOutline} from 'react-icons/io5'
import { UpdateUserNameService, getactiveUser } from '../../services/AuthServices'
import { useSelector } from 'react-redux'
import { GetDepartmentService } from '../../services/DepartmentServices'
import {Select} from 'antd'
import { RiUser6Line } from "react-icons/ri";
import {BsArrowRepeat} from 'react-icons/bs';
import {FiChevronRight,FiChevronLeft} from 'react-icons/fi';
import { AiOutlineMail,AiOutlinePhone } from "react-icons/ai";
import { Tooltip,IconButton } from '@material-ui/core'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import toast from 'react-hot-toast'

function Collegues() {

  const [visible,setvisible] = useState(false)
  const [name,setname] = useState('')
  const [data,setdata] = useState({})
  const user = useSelector(state=>state.Auth)
  const [page,setpage] = useState(1)
  const [department,setdepartment] = useState([])
  const [search,setsearch] = useState({text:'',department:''})

  useEffect(()=>{
    getData()
    getdepartment()
  },[])

  useEffect(()=>{
    getData()
  },[page])

  async function getData(){
    const response = await getactiveUser(search?.department?.value !== undefined ? search?.department?.value : '',page,search?.text)
    setdata(response?.data?.data)
  } 

  async function handlechange(v,type){
    if(type === 'department'){
        setsearch({...search,department:department.find((f)=>f.value === v)})
    }
  }


  async function getdepartment(){
    const response = await GetDepartmentService();
    let arr = []
    response?.data?.datas.forEach((d)=>{
        arr.push({label:d?.department_name,value:d?.id})
    })
    setdepartment(arr)
    }   

   async function applyfilter(){
    const response = await getactiveUser(search?.department?.value !== undefined ? search?.department?.value : '',1,search?.text)
    setdata(response?.data?.data)
   }   

   async function resetfilter(){
    setpage(1)
    setsearch({text:'',department:''})
    const response = await getactiveUser('',1,'')
    setdata(response?.data?.data)

   }

   console.log("data?.pagination?.totalPages",data?.pagination?.totalPages)
   console.log("page",page)

  return (
    <div className='px-2 mx-0 box-border'>
       <div className='flex '>
        <ProfileMenu />
        <div className='w-full ml-2 pt-2'>
        <div className='border-b pb-[2px] mb-2  flex items-center justify-between border-slate-200'>
        <h6 className='mt-1  font-[700] text-[13px] '>Active Users ({data?.pagination?.total})</h6>


       <div className='flex items-center'>
       <div className='flex items-center  text-[12px]'>
                <h6 className='mr-2 font-[600]'>{page === 1 ? data?.data?.length > 0 ? 1 : 0 : (page - 1) * data?.pagination?.limit } - {data?.pagination?.limit} of {data?.pagination?.total} </h6>
                <IconButton onClick={resetfilter} ><BsArrowRepeat size={16} /></IconButton>

                <div>
                <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>
                <IconButton onClick={()=>{ page < data?.pagination?.totalPages  ? setpage(page+1) : console.log('')}} ><FiChevronRight className={`${(data?.pagination?.totalPages === page || data?.data?.length === 0) ? 'opacity-50' : 'opacity-100'}`} size={16} /></IconButton>
                </div>
            </div>

        <input id="search_text" type='text' value={search.text} placeholder='Search text' onChange={(e)=>setsearch({...search,text:e.target.value})} className='border py-[4px] focus:ring-0 mr-2 focus:outline-0 text-[12px]  w-28 px-1 rounded-md border-slate-300' />

        <div className='border border-slate-300 w-[110px] rounded-md py-[1px] mr-[5px]'>
                
            <Select
                placeholder='Select Department'
                bordered={false}
                size='small'
                defaultValue={search.department}
                value={search.department}
                style={{ width: 110 }}
                onChange={(v)=>handlechange(v,'department')}
                options={department}
            />
        </div>

        <ButtonOutlinedAutoWidth onClick={applyfilter} btnName="Apply Filter" />
                
        </div>   
        </div> 
        <div className='grid grid-cols-5 gap-2'>  
        {data?.data?.map((d)=>(
            <div className='border border-slate-100 p-2'>
                <div className='flex -mt-3 border-b border-slate-100 pb-2 items-center'>
                {d?.profile_photo === null  ?  <h6 className='w-[30px] h-[30px] text-[12px] font-[700] uppercase flex items-center justify-center bg-slate-100'>{d?.name?.slice(0,2)}</h6> : 
                <img className='w-[30px] h-[30px]' src={`${process.env.REACT_APP_BACKEND_IMAGE_URL}${d?.profile_photo}`} />}
                <div className='ml-2 mt-2'>
                <h6 className='text-[11px] font-[700] capitalize'>{d?.name}</h6>
                <h6 className='text-[9px] -mt-1 font-[700] capitalize'>[{d?.employee_id}]</h6>
                </div>
                </div>
                <h6 className='text-[11px]'>Department : {d?.department_id[0]?.department_name}</h6>
                <h6 className='text-[11px]'>Designation : {d?.designation_id[0]?.designation_name}</h6>
                
                <div className='flex mt-1'>
                <Tooltip title={d?.official_email}>
                <CopyToClipboard onCopy={()=>toast.success("Copied Successfully")} text={d?.official_email}>
                <span><AiOutlineMail size={18} className='cursor-pointer bg-slate-100 p-1 mr-2' /></span>
                </CopyToClipboard></Tooltip>    
                <Tooltip title={d?.official_phone}>
                <CopyToClipboard onCopy={()=>toast.success("Copied Successfully")} text={d?.official_phone}>
                <span><AiOutlinePhone size={18} className='cursor-pointer bg-slate-100 p-1' />
                </span>
                </CopyToClipboard></Tooltip>
                </div>
                
            </div>    
        ))}
        </div> 
        </div>
       </div>  
    </div>
  )
}

export default Collegues