import { deleteRequest, get, post } from "../../helpers/apihelpers";


export const CreateGalleryProductTagsList=async(data)=>{
    try {
        const result = await post(`api/gallery_product_tags/create`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateGalleryProductTagsList=async(id,data)=>{
    try {
        const result = await post(`api/gallery_product_tags/update/${id}`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetGalleryProductTagsList=async(page)=>{
    try {
        const result    = await get(`api/gallery_product_tags/get/null/${page}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const SearchGalleryProductTagsService = async({search_text,from_date,to_date,page})=>{
    try {
        const result = await get(`api/gallery_product_tags/filter?search_text=${search_text}&from_date=${from_date}&to_date=${to_date}&page=${page}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteGalleryProductTagsService = async(id) => {
    try {
        const result = await deleteRequest(`api/gallery_product_tags/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

