import { deleteRequest, get, post, postfd, publicPost, put } from "../../../helpers/apihelpers";

export const CreateInventoryPurchaseOptionService=async(data)=>{
    try {
        const result = await post(`api/it/inventory_purchase_option/create`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateInventoryPurchaseOptionService=async(data,id)=>{
    try {
        const result = await put(`api/it/inventory_purchase_option/update/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetInventoryPurchaseOptionService=async(page,text,from_date,to_date)=>{
    try {
        const result = await get(`api/it/inventory_purchase_option/get?page=${page}&text=${text}&from_date=${from_date}&to_date=${to_date}`,);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteInventoryPurchaseOptionService=async(id)=>{
    try {
        const result = await deleteRequest(`api/it/inventory_purchase_option/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UploadInventoryPurchaseOptionService=async(data)=>{
    try {
        const result = await postfd(`api/it/inventory_purchase_option/upload_file`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}