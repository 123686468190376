import React,{useState,useEffect} from 'react'
import { TextAreaInput1, TextInput } from '../../components/input'
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../components/button'
import { toast } from 'react-hot-toast'
import GoBack from '../../components/back/GoBack'
import { useLocation, useNavigate } from 'react-router-dom'
import { CreateDailyTaskService, UpdateDailyTaskService } from '../../services/DailyTaskServices'
// import LeadMenu from '../LeadMenu'
import {BiCheckbox,BiCheckboxSquare} from 'react-icons/bi'
import { GetDepartmentService } from '../../services/DepartmentServices'
import { Select } from 'antd'
import { GetSearchService } from '../../services/AuthServices'
import {BiErrorCircle} from 'react-icons/bi'
import Uploader from '../../components/Uploader'
import { useSelector } from 'react-redux'
import { AiOutlinePlus,AiOutlineDelete } from 'react-icons/ai'
import MdFollowUpMenu from './MdFollowUpMenu'
import { GetStageService } from '../../services/StageServices'
import { CreateMdFollowUpService, UpdateMdFollowUpService } from '../../services/MdFollowUpServices'

function MdFollowUpCE() {

  const {state,pathname} = useLocation() 
  const navigator = useNavigate()
  

  const user_id = useSelector(state=>state.Auth.id)
  const roles = useSelector(state=>state.Auth.roles)
// console.log("user",user)
  
  const path = pathname?.split('/')[pathname?.split('/')?.length - 1]

  const path1 = pathname?.split('/')[pathname?.split('/')?.length - 3]


  // console.log("path1",path1)
  const [singleData,setsingleData] = useState({name:'',detail:'',location:'',area:'',status:'Pending',stage:'',department:'',assigned_to:'',remarks:''})
  const [error,seterror] = useState({name:'',detail:'',location:'',area:'',status:'',stage:'',department:'',assigned_to:'',remarks:''})
  const [summary,setsummary] = useState([])
  const [users,setusers] = useState([])
  const [stages,setstages] = useState([])

  const statusArr = [
    {label:'Pending',value:'Pending'},
    {label:'Processing',value:'Processing'},
    {label:'Closed',value:'Closed'},
    {label:'Not Closed',value:'Not Closed'},
  ]

  const typeArr = [
    {label:'Land',value:'MD_LandStages'},
    {label:'WareHouse',value:'MD_WareHouseStages'},
    {label:'Commercial Office Space',value:'MD_CommercialOfficeSpaceStages'},
    {label:'Investment',value:'MD_InvestmentStages'},
  ]
  

  

  const [loader,setloader] = useState(false)

  useEffect(()=>{
    if(path1 === 'land'){
      let type = typeArr?.find((f)=>f?.label === 'Land')
      getstagesbasedtype(type?.value)
  }else if(path1 === 'warehouse'){
      let type = typeArr?.find((f)=>f?.label === 'WareHouse')
      getstagesbasedtype(type?.value)
  }else if(path1 === 'office_space'){
      let type = typeArr?.find((f)=>f?.label === 'Commercial Office Space')
      getstagesbasedtype(type?.value)
  }else if(path1 === 'investment'){
      let type = typeArr?.find((f)=>f?.label === 'Investment')
      getstagesbasedtype(type?.value)
  }
    
    if(path === 'edit'){
      let d = state
     
      let edit_data = {
        ...d,
        name:d?.name,
        detail:d?.detail,
        location:d?.location,
        area:d?.area,
        type:d?.type !== '' ? typeArr?.find((f)=>f?.label === d?.type) : null,
        status:{label:d?.status,value:d?.status},
        stage:{label:d?.stage?.name,value:d?.stage?._id},
        department:'',
        assigned_to:'',
        remarks:d?.remarks
    }

    let type = d?.type !== '' ? typeArr?.find((f)=>f?.label === d?.type) : null
    
    if(type !== null){
        getstagesbasedtype(type?.value)
    }


    // if(d?.image !== undefined && d?.remarks !== null){
    //     edit_data['remarks'] = d?.remarks
    // }  

      setsingleData({...edit_data})
    }
  },[pathname])

  async function searchfunction(v){
   
    const response = await GetSearchService(v,1)
    let arr = []
    response?.data?.datas?.forEach((d)=>{
    arr.push({value:d?._id,label:`${d?.name} : [${d?.department_id[0]?.department_name}]`})
    })
    setusers(arr)
    
  }

  async function getstagesbasedtype(v,v1){
    if(v !== singleData?.type){
        const response = await GetStageService(v)
        let arr = []
        response?.data?.data?.datas?.map((m)=>(
            arr.push({label:m?.name,value:m?._id})
        ))
        
        setstages(arr)
    }
}

//   console.log("data",singleData)


        

  async function submitform(){
    setloader(true)
        // if(!singleData.work_description){
        //     seterror({...error,work_description:'The work description field is required'})
        // }else if(state?.type === 'assigned_work' && !singleData.assigned_to){
        //     seterror({...error,assigned_to:'The assigned to field is required'})
        // }else if(state?.type === 'assigned_work' && !singleData?.dead_line){
        //     seterror({...error,dead_line:'The dead line field is required'})
        // }else if(path === 'edit' && !singleData?.remarks){
        //     seterror({...error,remarks:'The remarks field is required'})
        // } else{
        
            let send_data = {
                name:singleData.name,
                detail:singleData?.detail,
                location:singleData?.location,
                remarks:singleData?.remarks,
                area:singleData?.area,
                status:singleData?.status?.value === undefined ? '' : singleData?.status?.value,
                type:singleData?.type?.value === undefined ? '' : singleData?.type?.label,
                stage:singleData?.stage?.value === undefined ? '' : singleData?.stage?.value,
            }


            if(path === 'create'){
                const response = await CreateMdFollowUpService(send_data)
                if(response?.status === 201){
                    toast.success("Md Lead FollowUp Created Successfully")
                    resetform()
                }
            }else{
                const response = await UpdateMdFollowUpService(send_data,state?._id)
                if(response?.status === 200){
                    toast.success("Md Lead FollowUp Updated Successfully")
                    resetform()
                }
            }

            // if(singleData?.department?.value !== undefined){
            //     send_data['department'] = singleData?.department?.value
            // }

        // }    
    
   }


   function resetform(){
        setsingleData({name:'',detail:'',location:'',area:'',status:'Pending',stage:'',department:'',assigned_to:'',remarks:''})
        seterror({name:'',detail:'',location:'',area:'',status:'',stage:'',department:'',assigned_to:'',remarks:''})
        if(path === 'edit'){
            navigator(-1)
        }
       
    }

    async function handleSelect(e,e1){
       console.log("e here",e)
       console.log("e1 here",e1)
        if(e1 === 'status'){
            setsingleData({...singleData,status:statusArr?.find((d)=>d.value === e)})
            seterror({...error,status:''})
        }

        if(e1 === 'type'){
            setsingleData({...singleData,type:typeArr?.find((d)=>d.value === e)})
            seterror({...error,type:''})
        }

        if(e1 === 'stage'){
            setsingleData({...singleData,stage:stages?.find((d)=>d.value === e)})
            seterror({...error,stage:''})
        }
    }    

    function handlechange(e){
        setsingleData({...singleData,[e.target.name] : e.target.value})
        seterror({...error,[e.target.name] : ''})
    }


    function handlechange1(e,i){
        // let summaryList = [...summary]
        // summaryList[i]['summary'] = e.target.value
        // setsummary(summaryList)
        setsingleData({...singleData,[e.target.name]:e.target.value})
    }

    async function setaddfollwup(){
        let summaryList = [...summary]
        summaryList.push({summary:''})
        setsummary(summaryList)
    }

    async function deletefollowup(i){
        let summaryList = [...summary]
        summaryList.splice(i,1)
        setsummary(summaryList)
    }
  


    console.log("singleData.summary",stages)


  return (
    <div className='flex min-h-screen max-h-screen h-screen '>
    <MdFollowUpMenu />
           
           <div className='flex'>
           <div className='min-w-[20%] border-r pr-5 border-slate-200 pl-5 min-h-screen max-h-screen h-screen overflow-y-screen overflow-x-hidden' >
            <GoBack />
            
            <div className='border-b pb-2 w-64'>
                <h6 className='text-[13px] mb-1 font-[800]'>Create/Edit the Md Follow Up Lead</h6>
                <h6 className='text-[12px] bg-slate-200 p-2'>Use the Below form to add the md follow up lead report </h6>
           </div>

            {/* {selectedData === '' ? */}
            <>
           
            <>
                <TextInput
                mandatory={true}
                label={'Project Name'}  
                variant="standard"
                name="name"
                type="text"
                value={singleData.name}
                error={error.name}
                handlechange={handlechange1}/>

             <TextAreaInput1  value={singleData?.detail} mandatory={true} error={error?.detail} handlechange={handlechange} name="detail" label="Detail" />
             <TextAreaInput1  value={singleData?.location} mandatory={true} error={error?.location} handlechange={handlechange} name="location" label="Location" />

            {path1 === 'lead' &&
             <>
             <h6 className='text-[12px] font-semibold mb-1 mt-1'>Type </h6>
                <Select
                value={singleData.type} 
                error={error.type}
                bordered={false}
                placeholder="" 
                onChange={(e)=>{handleSelect(e,'type');getstagesbasedtype(e)}} 
                options={typeArr} 
                className='w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none  focus:border-transparent focus:ring-offset-0 focus:shadow-none'
                />
              </>}  

               

            

<h6 className='text-[12px] font-semibold mb-1 mt-1'>Status Type </h6>
                <Select
                value={singleData.stage} 
                error={error.stage}
                bordered={false}
                placeholder="" 
                onChange={(e)=>{handleSelect(e,'stage');}} 
                options={stages} 
                className='w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none  focus:border-transparent focus:ring-offset-0 focus:shadow-none'
                />

             <TextInput
                mandatory={true}
                label={'Area / Acres'}  
                variant="standard"
                name="area"
                type="text"
                value={singleData.area}
                error={error.area}
                handlechange={handlechange1}/>


<h6 className='text-[12px] font-semibold mb-1 mt-1'>Requirment Status </h6>
                <Select
                value={singleData.status} 
                error={error.status}
                bordered={false}
                placeholder="" 
                onChange={(e)=>handleSelect(e,'status')} 
                options={statusArr} 
                className='w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none  focus:border-transparent focus:ring-offset-0 focus:shadow-none'
                />
          
                <TextAreaInput1  value={singleData?.remarks} mandatory={true} error={error?.remarks} handlechange={handlechange} name="remarks" label="Remarks" />

            
           
             </>

            </>
           




             {/* {selectedData === '' && */}
            <div className='flex mt-2 border-t pt-2 w-full items-end '>
                <div className='mr-2'>
                <ButtonOutlinedAutoWidth btnName="Cancel" onClick={()=>navigator(-1)} />
                </div>
                <ButtonFilledAutoWidth btnName="Save" onClick={submitform} />
            </div>
            {/* } */}
           </div>  
           <div className='w-80 pl-5 mt-5 min-h-screen max-h-screen h-screen overflow-y-screen overflow-x-hidden'>
           {state?.type === 'project_work' &&
             <div className='w-[100%]'>
              <div className='flex w-[100%] items-center justify-between'>
                <h6 className='text-[11px] font-[600] mb-1 mt-[6px]'>Add Sub Task Follow Up </h6>
                <AiOutlinePlus size={20} onClick={setaddfollwup} className='cursor-pointer bg-slate-100 p-1' />
              </div>
              {summary?.map((s,i)=>(
                <div className='relative'>
                <div className='flex items-center justify-between mb-1 mt-[6px]'>    
                <h6 className='text-[11px] font-[600] '>Follow Up {i+1} </h6>
                <AiOutlineDelete size={14} onClick={()=>deletefollowup(i)} className='cursor-pointer' />
                </div>
                <TextAreaInput1 name={`Summary ${i+1}`} value={s?.summary} handlechange={(e)=>handlechange1(e,i)} />
                </div>
              ))}
              {/* <Uploader image={singleData?.image}  setimagefunc={(e)=>{setsingleData({...singleData,image:e});seterror({...error,singleData:''})}}  removeimageuploadfunc = {()=>{setsingleData({...singleData,image:''});seterror({...error,singleData:''})}} /> */}
              </div>
             }
           </div>
           </div>

    </div>
  )
}

export default MdFollowUpCE