import { deleteRequest, get, post, put } from "../../helpers/apihelpers";

export const CreateFcaresUserService=async(data)=>{
    try {
        const result = await post(`api/fcares/client`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateFcaresUserService=async(id,data)=>{
    try {
        const result = await post(`api/fcares/client/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetFcaresUserService=async(text,from_date,to_Date,step,page)=>{
    try {
        const result = await get(`api/fcares/client?page=${page}&search=${text}&from_date=${from_date}&to_date=${to_Date}&step=${step}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetFcaresUserByIdService=async(id)=>{
    try {
        const result = await get(`api/fcares/client/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteFcaresUserService=async(id)=>{
    try {
        const result = await deleteRequest(`api/fcares/client/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}