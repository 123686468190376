import { deleteRequest, get, post, postfd, publicPost, put } from "../../../helpers/apihelpers";

export const CreateProjectWebsiteJobOpeningService=async(data)=>{
    try {
        const result = await publicPost(`api/project_website/job_opening/create`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateProjectWebsiteJobOpeningService=async(data,id)=>{
    try {
        const result = await put(`api/project_website/job_opening/update/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetProjectWebsiteJobOpeningService=async(page,text,step=1)=>{
    try {
        const result = await get(`api/project_website/job_opening/get?page=${page}&text=${text}&step=${step}`,);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteProjectWebsiteJobOpeningService=async(id)=>{
    try {
        const result = await deleteRequest(`api/project_website/job_opening/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UploadProjectWebsiteJobOpeningService=async(data)=>{
    try {
        const result = await postfd(`api/project_website/job_opening/upload_file`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}