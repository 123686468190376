import React,{useEffect, useState} from 'react'
import { toast } from 'react-hot-toast'
import { ButtonFilledAutoWidth } from '../../../components/button'
import { TextAreaInput1, TextInput } from '../../../components/input'
import { useNavigate, useLocation } from 'react-router-dom'
import GoBack from '../../../components/back/GoBack'
import ItMenu from '../ItMenu'
import { CreateInventoryOptionService, UpdateInventoryOptionService, UploadInventoryOptionService } from '../../../services/ITServices/InventoryOption/InventoryOptionServices'
import Uploader from '../../../components/Uploader'
import { GetFeatureOptionService } from '../../../services/ITServices/FeatureOption/FeatureOptionServices'
import { Select } from 'antd'


function InventoryOptionCE() {
 
  const [data,setdata] = useState({device_id:'',asset_id:'',barcode:'',name:'',storage:'',ram:'',configuration:'',brand:'',processor:'',purchase_type:'',device_type:'',device_stage:'',image:''});  
  const [error,seterror] = useState({device_id:'',asset_id:'',barcode:'',name:'',storage:'',ram:'',configuration:'',brand:'',processor:'',purchase_type:'',device_type:'',device_stage:'',image:''});
  const [type,settype] = useState("");


  const [brand,setbrand] = useState([])
  const [processor,setprocessor] = useState([])
  const [purchaseType,setpurchaseType] = useState([])
  const [deviceType,setdeviceType] = useState([])
  const [deviceStage,setdeviceStage] = useState([])
  
  const {pathname}  = useLocation()
  const path = pathname.split('/')[pathname.split('/').length - 2]

  const {state} = useLocation();
  const navigate = useNavigate();

  useEffect(()=>{
    if(path == 'laptop'){
      settype("Laptop")
    }
    if(path == 'desktop'){
      settype("Desktop")
    }
    if(path == 'cpu'){
      settype("CPU")
    }
    if(path == 'mobile'){
      settype("Mobile")
    }
    if(path == 'mouse'){
      settype("Mouse")
    }
    if(path == 'keyboard'){
      settype("Keyboard")
    }
    if(path == 'hard_disk'){
      settype("HardDisk")
    }
    if(path == 'cable'){
      settype("Cable")
    }
    if(path == 'laptop_charger'){
      settype("Laptop Charger")
    }
    if(path == 'mobile_charger'){
      settype("Mobile Charger")
    }
    if(path == 'pendrive'){
      settype("Pendrive")
    }
   },[path])

  useEffect(()=>{
    if(state?._id !== null && state?._id !== undefined){
      setdata({...data,...state})
    }
  },[state])

  function handlechange(e){
    setdata({...data,[e.target.name] : e.target.value})
    seterror({...error,[e.target.name]:''})
  }

  async function submitform(){
    if(!data.name){
        seterror({...error,name:'Name field is required *'})
    }else{
        if(state?._id === undefined || state?._id === null){
        let send_data = {...data}

        delete send_data.brand
        delete send_data.processor
        delete send_data.purchase_type
        delete send_data.device_type
        delete send_data.device_stage

        if(data?.brand !== ''){
          send_data['brand'] = data?.brand?.value
        }
        if(data?.processor !== ''){
          send_data['processor'] = data?.processor?.value
        }
        if(data?.purchase_type !== ''){
          send_data['purchase_type'] = data?.purchase_type?.value
        }
        if(data?.device_type !== ''){
          send_data['device_type'] = data?.device_type?.value
        }
        if(data?.device_stage !== ''){
          send_data['device_stage'] = data?.device_stage?.value
        }

        send_data['type'] = type
        const response = await CreateInventoryOptionService(send_data)
        if (response.status === 201) {
          resetform()
          toast.success(`${type} Created Successfully`)
        }   
        if(response.status === 422){
          seterror({...error,name:response?.data?.errors?.name})
        } 
      }else{
        let send_data = {...data}
        send_data["_id"] = state?._id

        delete send_data.brand
        delete send_data.processor
        delete send_data.purchase_type
        delete send_data.device_type
        delete send_data.device_stage

        if(data?.brand !== ''){
          send_data['brand'] = data?.brand?.value
        }
        if(data?.processor !== ''){
          send_data['processor'] = data?.processor?.value
        }
        if(data?.purchase_type !== ''){
          send_data['purchase_type'] = data?.purchase_type?.value
        }
        if(data?.device_type !== ''){
          send_data['device_type'] = data?.device_type?.value
        }
        if(data?.device_stage !== ''){
          send_data['device_stage'] = data?.device_stage?.value
        }


        const response = await UpdateInventoryOptionService(send_data,state?._id)
        if (response.status === 200) {
          resetform()
          navigate(-1)
          toast.success(`${type} Updated Successfully`)
        }   
        if(response.status === 422){
          seterror({...error,name:response?.data?.errors?.name})
        } 
      }
  }
  }

  function resetform(){
    setdata({device_id:'',asset_id:'',barcode:'',name:'',storage:'',ram:'',configuration:'',brand:'',processor:'',purchase_type:'',device_type:'',device_stage:'',image:''})
    seterror({device_id:'',asset_id:'',barcode:'',name:'',storage:'',ram:'',configuration:'',brand:'',processor:'',purchase_type:'',device_type:'',device_stage:'',image:''})
  }

  async function uploadfilefunc(v,name){
    const fd = new FormData()
    fd.append('file',v); 
    const response = await UploadInventoryOptionService(fd)
    if(response?.status === 200){
    setdata({...data,[name]:response?.data?.data})
    }
  }


  async function handleselect(v,type){
    if(type == 'processor'){
      let findData = processor.find((f)=>f.value === v)
      setdata({...data,[type]:findData})
    }
    if(type == 'brand'){
      let findData = brand.find((f)=>f.value === v)
      setdata({...data,[type]:findData})
    }
    if(type == 'purchase_type'){
      let findData = purchaseType.find((f)=>f.value === v)
      setdata({...data,[type]:findData})
    }
    if(type == 'device_type'){
      let findData = deviceType.find((f)=>f.value === v)
      setdata({...data,[type]:findData})
    }
    if(type == 'device_stage'){
      let findData = deviceStage.find((f)=>f.value === v)
      setdata({...data,[type]:findData})
    }
 }

 async function searchData(v,type){
  if(type == 'Processor'){
    const response = await GetFeatureOptionService(1,v,type)
    console.log("response?.data?.data",response?.data?.datas)
    let d = response?.data?.datas
    let arr = []
    d.forEach((d1)=>{
       arr.push({label:d1?.name,value:d1?._id})
    })
    setprocessor(arr)
  }

  if(type == 'Brand'){
    const response = await GetFeatureOptionService(1,v,type)
    let d = response?.data?.datas
    let arr = []
    d.forEach((d1)=>{
       arr.push({label:d1?.name,value:d1?._id})
    })
    setbrand(arr)
  }

  if(type == 'Purchase Type'){
    const response = await GetFeatureOptionService(1,v,type)
    let d = response?.data?.datas
    let arr = []
    d.forEach((d1)=>{
       arr.push({label:d1?.name,value:d1?._id})
    })
    setpurchaseType(arr)
  }

  if(type == 'Device Type'){
    const response = await GetFeatureOptionService(1,v,type)
    let d = response?.data?.datas
    let arr = []
    d.forEach((d1)=>{
       arr.push({label:d1?.name,value:d1?._id})
    })
    setdeviceType(arr)
  }

  if(type == 'Device Stage'){
    const response = await GetFeatureOptionService(1,v,type)
    let d = response?.data?.datas
    let arr = []
    d.forEach((d1)=>{
       arr.push({label:d1?.name,value:d1?._id})
    })
    setdeviceStage(arr)
  }
 }

  

  return (
    <div className='flex '>


    <div className='ml-2'>
      <ItMenu />
    </div> 
   
    <div className=' px-4 pt-5' >


        <div className='sm:w-full lg:w-72'>
        <GoBack /> 

        <h6 className='font-[700]'>{(state?._id !== null && state?._id !== undefined) ? 'Edit' : 'Add'} {type}</h6>
        <h6 className='text-[10px] bg-slate-100 p-2 font-[500] leading-snug' >Use the below form to create or edit the <b> {type}</b> for your comapny employees.</h6>
        </div>


        <div className='flex w-[450px]'>
            <div className='w-[100%] mr-5'>
            <TextInput 
                label={'Name'}  
                variant="standard"
                name="name"
                type="text"
                mandatory={true}
                error={error.name}
                value={data.name}
                handlechange={handlechange}
                placeholder="Enter your name"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>


            <TextInput 
                label={'Device Id'}  
                variant="standard"
                name="device_id"
                type="text"
                error={error.device_id}
                value={data.device_id}
                mandatory={true}
                handlechange={handlechange}
                placeholder="Enter your name"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>

            <TextInput 
                label={'Asset Id (Unique)'}  
                variant="standard"
                name="asset_id"
                type="text"
                error={error.asset_id}
                value={data.asset_id}
                mandatory={true}
                handlechange={handlechange}
                placeholder="Enter your name"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/> 

              {['Laptop','CPU','Mobile','Desktop','HardDisk']?.includes(type) && 
              <>
              <TextInput 
                label={'RAM'}  
                variant="standard"
                name="ram"
                type="text"
                mandatory={true}
                error={error.ram}
                value={data.ram}
                handlechange={handlechange}
                placeholder=""
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>

              <TextInput 
                label={'Storage'}  
                variant="standard"
                name="storage"
                type="text"
                mandatory={true}
                error={error.storage}
                value={data.storage}
                handlechange={handlechange}
                placeholder=""
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>  

              <TextAreaInput1 
                label={'Configuration'}  
                variant="standard"
                name="configuration"
                type="text"
                mandatory={true}
                error={error.configuration}
                value={data.configuration}
                handlechange={handlechange}
                placeholder=""
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>
              </>}
            <h6 className='text-[11px] font-[600] mb-1 mt-2' >Image</h6>    
            <Uploader image={data?.image}  setimagefunc={(e)=>{uploadfilefunc(e,'image');seterror({...error,image:''})}}  removeimageuploadfunc = {()=>{setdata({...data,image:''});seterror({...error,image:''})}}/>    

            </div>
            <div className='w-[100%]'>


            {['Laptop','CPU','Mobile','Desktop']?.includes(type) && 
            <>
            <h6 className='text-[11px] mt-2 font-[600] mb-1' >Processor</h6>
              <div className='w-[100%]'>
                <Select 
                  value={data.processor}
                  allowClear={true}
                  type="processor"
                  filterOption={false}
                  showSearch={true}
                  onSearch={(e)=>searchData(e,'Processor')}
                  onChange={e=>{handleselect(e,'processor')}}
                  options={processor}
                  bordered={false}
                  className='w-[100%] border-l-4 border-l-slate-700 border border-slate-300'
                />
            </div>
            </>}

            <h6 className='text-[11px] mt-2 font-[600] mb-1' >Brand</h6>
            <div className='w-[100%]'>
                <Select 
                  value={data.brand}
                  allowClear={true}
                  type="brand"
                  filterOption={false}
                  showSearch={true}
                  onSearch={(e)=>searchData(e,'Brand')}
                  onChange={e=>{handleselect(e,'brand')}}
                  options={brand}
                  bordered={false}
                  className='w-[100%] border-l-4 border-l-slate-700 border border-slate-300'
                />
            </div>

            <h6 className='text-[11px] mt-2 font-[600] mb-1' >Purchase Type</h6>
            <div className='w-[100%]'>
                <Select 
                  value={data.purchase_type}
                  allowClear={true}
                  type="purchase_type"
                  filterOption={false}
                  showSearch={true}
                  onSearch={(e)=>searchData(e,'Purchase Type')}
                  onChange={e=>{handleselect(e,'purchase_type')}}
                  options={purchaseType}
                  bordered={false}
                  className='w-[100%] border-l-4 border-l-slate-700 border border-slate-300'
                />
            </div>

            <h6 className='text-[11px] mt-2 font-[600] mb-1' >Device Type</h6>
            <div className='w-[100%]'>
                <Select 
                  value={data.device_type}
                  allowClear={true}
                  type="device_type"
                  filterOption={false}
                  showSearch={true}
                  onSearch={(e)=>searchData(e,'Device Type')}
                  onChange={e=>{handleselect(e,'device_type')}}
                  options={deviceType}
                  bordered={false}
                  className='w-[100%] border-l-4 border-l-slate-700 border border-slate-300'
                />
            </div>

            <h6 className='text-[11px] mt-2 font-[600] mb-1' >Device Stage</h6>
            <div className='w-[100%]'>
                <Select 
                  value={data.device_stage}
                  allowClear={true}
                  type="device_stage"
                  filterOption={false}
                  showSearch={true}
                  onSearch={(e)=>searchData(e,'Device Stage')}
                  onChange={e=>{handleselect(e,'device_stage')}}
                  options={deviceStage}
                  bordered={false}
                  className='w-[100%] border-l-4 border-l-slate-700 border border-slate-300'
                />
            </div>

            </div>
            </div>

         
        <div className='mt-5'>
        <ButtonFilledAutoWidth btnName={(state?._id !== null && state?._id !== undefined) ? `Update ${type}` : `Add ${type}`}  onClick={submitform} />  
        </div>


    </div>
    </div>
  )
}

export default InventoryOptionCE