import { IconButton, Tooltip } from '@mui/material'
import React,{useState,useEffect} from 'react'
import GalleryAppBar from '../GalleryAppBar'
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../../components/button'
import { useLocation, useNavigate } from 'react-router-dom'
import {BsArrowRepeat} from 'react-icons/bs';
import {FiChevronRight,FiChevronLeft} from 'react-icons/fi';
import { DatePicker, Modal,Select } from 'antd';
import { toast } from 'react-hot-toast';
import { DeleteGalleyProductService, GetGalleryProductList, SearchGalleryProductService, UpdateGalleryQrCodeProductMassList } from '../../../services/Gallery/GalleryProductServices'
import {AiOutlineEdit,AiOutlineDelete,AiOutlineShareAlt,AiOutlineZoomIn,AiOutlineQrcode} from 'react-icons/ai';
import { RiBillLine } from 'react-icons/ri'
import {IoIosClose} from 'react-icons/io';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { GetGalleryArtistList } from '../../../services/Gallery/GalleryArtistServices'
import { useDispatch, useSelector } from 'react-redux'
import { galleryPagiantionAction } from '../../../redux/actions/staticAction'


function GalleryProductList() {

  const roles = useSelector(state=>state.Auth.roles) 
  
  const [search,setsearch] = useState({text:'',from_date:'',to_date:'',artist:'',activate:false})
  const [datas,setdatas]   = useState({})
  const [artist,setartist] = useState([])
  const [selected_data,setselected_data]   = useState({})
  const [modal,setmodal]   = useState(false)
  const [page,setpage] = useState(1)
  const navigate = useNavigate()  
  const dispatch = useDispatch()

  const gallery_pagination = useSelector(state=>state.Static)



  useEffect(()=>{
    if(gallery_pagination?.page === null){
      if(search.activate === false){
        getDatas(page)
      }else{
        applyfilterfunction(page)
      }
    }else{
      // console.log("")
      if(gallery_pagination?.search?.activate !== undefined && gallery_pagination?.search?.activate === true){
        setsearch(gallery_pagination?.search)
        filterData(gallery_pagination?.search,gallery_pagination?.page)
        setpage(gallery_pagination?.page)
      }else{
        getDatas(gallery_pagination?.page)
        setpage(gallery_pagination?.page)
      } 
    }
    getDropdown()
  },[page])

  async function getDropdown(){
    const response = await GetGalleryArtistList(1)
    let arr = []
    response?.data?.datas?.forEach(d => {
        arr.push({label:`${d?.name}`,value:d?._id})
    });
    setartist(arr)
  }

  async function filterData(search,page){
    let start_date = new Date(search?.from_date).toLocaleDateString().replaceAll('/','-').split('-').reverse().join('-')
    let end_date = new Date(search?.to_date).toLocaleDateString().replaceAll('/','-').split('-').reverse().join('-')
    const response = await SearchGalleryProductService({search_text:search.text,author:search?.author,from_date:(search?.from_date !== ''  && search?.from_date !== null) ? start_date : '',to_date:(search?.to_date !== ''  && search?.to_date !== null) ? end_date : '',page:page})
    setdatas(response.data)
    dispatch(galleryPagiantionAction({search:{},page:null}))
  }


  async function getDatas(page){
    if(search.activate === false){
        const response = await GetGalleryProductList(page)
        setdatas(response?.data)
    }else{
        let start_date = new Date(search?.from_date).toLocaleDateString().replaceAll('/','-').split('-').reverse().join('-')
        let end_date = new Date(search?.to_date).toLocaleDateString().replaceAll('/','-').split('-').reverse().join('-')
        const response = await SearchGalleryProductService({search_text:search.text,author:search?.author,from_date:(search?.from_date !== ''  && search?.from_date !== null) ? start_date : '',to_date:(search?.to_date !== ''  && search?.to_date !== null) ? end_date : '',page:page})
        setdatas(response.data)
    }
    dispatch(galleryPagiantionAction({search:{},page:null}))

  }

  async function deleteData(){
    const response = await DeleteGalleyProductService(selected_data?._id)
    if(response?.status == 200){
        setmodal(false)
        toast.success("Deleted successfully")
        getDatas(page)
    }
  }

  async function resetfunc(){
    dispatch(galleryPagiantionAction({search:{},page:null}))
    setsearch({text:'',from_date:'',to_date:'',activate:false})
    // getDatas(1)
    const response = await GetGalleryProductList(page)
    setdatas(response?.data)
    setpage(1)
  }


  async function applyfilterfunction(page){
    setsearch({...search,activate:true})
    let start_date = new Date(search?.from_date).toLocaleDateString().replaceAll('/','-').split('-').reverse().join('-')
    let end_date = new Date(search?.to_date).toLocaleDateString().replaceAll('/','-').split('-').reverse().join('-')
    const response = await SearchGalleryProductService({search_text:search.text,author:search?.author,from_date:(search?.from_date !== ''  && search?.from_date !== null) ? start_date : '',to_date:(search?.to_date !== ''  && search?.to_date !== null) ? end_date : '',page:page})
    setdatas(response.data)

  }

 

  async function deleteData(){

    const response = await DeleteGalleyProductService(selected_data?._id)
    if(response?.status === 200){
      toast.success("Deleted successfully")
      setmodal(false)
      getDatas(page)
    }

  }

  async function updateProductMass(){
     const response = await UpdateGalleryQrCodeProductMassList()
     console.log("response",response?.data)
     if(response?.status === 200){
      toast.success(response?.data?.datas)
     }
  }
  
  
  return (
    <div className='h-screen max-h-screen box-border overflow-hidden'>
        <div className='block sm:flex'>

        <Modal
              open={modal}  
              closable={false}
              maskClosable={false}
              onClose={()=>setmodal(false)}
              bodyStyle={{borderRadius:'0px !important'}}
              style={{maxWidth:'300px',borderRadius:'0px !important'}}
              footer={null}
            >
            <div style={{maxWidth:'300px',borderRadius:'0px !important'}} className="relative z-0 ">
                <IoIosClose size={20} className="absolute -right-4 -top-4 z-10" onClick={()=>{setmodal(false)}} />
                <h6 className='text-[15px] font-[700]'>Are you sure ?</h6>
                <h6 className='text-[11px] leading-tight my-2'>Product Name : {selected_data?.name}</h6>
                <h6 className='text-[11px] leading-tight'>Please be sure before deleting the data because once deleted cannot retreved.</h6>
                

                  
                <div className='flex mt-2'>
                  <div className='mr-1'>
                  <ButtonOutlinedAutoWidth btnName ="Cancel" onClick={()=>{setmodal(false);setselected_data({})}}  />
                  </div>
                  <div className='ml-1'  >

                  <ButtonFilledAutoWidth btnName ="Confirm"  onClick={deleteData}/>
                  </div>
                </div>
            </div>
          </Modal>

            
        <GalleryAppBar />

        <div className='w-[85%]  ml-4  mt-4'>
        <div className='flex items-center justify-between border-b pb-2'>
        <h6 className='font-[700] text-[14px] '>Total Products({datas?.pagination?.total})</h6>
        <div className='flex items-center'>
          <div className='flex items-center text-[12px] mr-2'>

            <h6 className='mr-2 font-[600]'>{page == 1 ? datas?.datas?.length > 0 ? 1 : 0 : (page - 1) * datas?.pagination?.limit } - {datas?.pagination?.limit} of {datas?.pagination?.total} </h6>
            <IconButton onClick={resetfunc}><BsArrowRepeat size={16} /></IconButton>

            <div>
            <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>
            <IconButton onClick={()=>{ page < datas?.pagination?.totalPages  ? setpage(page+1) : console.log('')}}><FiChevronRight className={`${(datas?.pagination?.totalPages === page || datas?.datas?.length === 0)  ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>

            </div>
          </div>


          <DatePicker size='small' style={{fontSize:'11px'}} ampm={false} placeholder='From Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.from_date} onChange={(v) => {console.log('v na',new Date(v).toLocaleDateString());setsearch({...search,from_date:v})}} /> 

          <DatePicker size='small' style={{fontSize:'11px'}} ampm={false} placeholder='To Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.to_date} onChange={(v) => {console.log('v na',new Date(v).toLocaleDateString());setsearch({...search,to_date:v})}} /> 

        
        <div className='mr-2'>
            

          <input  id="search_text" placeholder='Search text' type='text' value={search.text} onChange={(e)=>setsearch({...search,text:e.target.value})} className='border py-[3px] focus:ring-0 focus:outline-0 text-[14px]  w-28 px-1 rounded-md border-slate-300' />
           
        </div>      

        <Select value={search?.author} options={artist} className='w-[100px] mr-2' placeholder="Author" onChange={(v)=>setsearch({...search,author:v})} />
        {roles?.includes('admin') &&
        <div className='mr-2'>
        <ButtonOutlinedAutoWidth btnName="Update QR Code" onClick={updateProductMass} /> 
        </div>}
       <ButtonOutlinedAutoWidth btnName="Add Filter" onClick={()=>applyfilterfunction(1)} /> 
        {/* {leads_selected_list?.length > 0 &&
        <div className='ml-2'>
        <ButtonFilledAutoWidth onClick={createManyContactBasedOnlIST} btnName="Create Contact" /> 
        </div>} */}
         <div className='ml-2 mr-0'>
        <ButtonFilledAutoWidth btnName="Add Data" onClick={()=>navigate('create')} /> 
        </div>
        {/* <div className='ml-2 mr-2'>
        <ButtonFilledAutoWidth btnName="Download Excel" onClick={downloadExcelData}/> 
        </div> */}
        </div>

        </div>

         {datas?.datas?.length === 0 &&
          <div className='grid place-items-center mt-20  items-center justify-center'>
          <img src={'https://fidecrmfiles.s3.amazonaws.com/NoDataFound1.png'} className='w-40 h-40 object-contain' /> 
          <h6 className='font-bold text-[14px] -mt-10'>No data found</h6>
          <h6 className='font-[500] w-[80%] text-center text-slate-700 text-[12.5px] -mt-2'>Oops we couldn't find any visitor added to the sustem based on your stage.</h6>
        </div>
        }

        {datas?.datas?.length > 0 &&
        <div className='max-h-[85vh] mt-4 border-t   border-l border-r overflow-y-scroll'>
        <div className='sticky top-0 z-50 flex  bg-white py-[-5px] border-b border-slate-200 relative'>
            <h6  className='top-0 bg-white z-50 text-[12px] w-[60px]  px-2 py-1 font-[600] text-slate-600 border-r flex items-center justify-center'>Sl No</h6>
            <h6 className='sticky top-0 z-50  text-[12px] w-[60px]  px-2 py-1 font-[600] text-slate-600 border-r'>Photo</h6>
            <h6 className='sticky top-0 z-50  text-[12px] w-[130px]  px-2 py-1 font-[600] text-slate-600 border-r'>Name</h6>
            <h6 className='sticky top-0 z-50  text-[12px] w-[100px]  px-2 py-1 font-[600] text-slate-600 border-r'>Measurment</h6>
            <h6 className='sticky top-0 z-50  text-[12px] w-[140px]  px-2 py-1 font-[600] text-slate-600 border-r'>Artist</h6>
            <h6 className='sticky top-0 z-50  text-[12px] w-[70px]  px-2 py-1 font-[600] text-slate-600 border-r'>Quantity</h6>
            <h6 className='sticky top-0 z-50  text-[12px] w-[150px] px-2 py-1 font-[600] text-slate-600 border-r'>Description</h6>
            <h6 className='sticky top-0 z-50  text-[12px] w-[150px] px-2 py-1 font-[600] text-slate-600 border-r'>Specification</h6>
            <h6 className='sticky top-0 z-50  text-[12px] w-[90px] px-2 py-1 font-[600] text-slate-600 border-r'>MRP</h6>
            <h6 className='sticky top-0 z-50  text-[12px] w-[95px] px-2 py-1 font-[600] text-slate-600 border-r'>Selling Price</h6>
            <h6 className='sticky top-0 z-50  text-[12px] w-[70px]  px-2 py-1 font-[600] text-slate-600 sticky right-0'>Actions</h6>
        </div>
      
        {datas?.datas?.map((d,i)=>(
        <div  key={d?._id} className={`flex z-50 border-b border-slate-200 z-0`}  >
            <h6 className='sticky top-0 z-30 text-[12px] w-[60px]  px-2 py-1 font-[600] text-slate-600 cursor-pointer border-r flex items-center justify-center'>{page === 1 ? i+1 : ((i+1) + (datas?.pagination?.limit * (page-1)))} </h6>

            <h6 className='text-[12px] relative font-[500] w-[60px]  px-2 py-1 truncate border-r  border-slate-200'>{(d?.image !== undefined && d?.image !== null) ? <img className='w-8 h-4 object-contain' src={`${process.env.REACT_APP_BACKEND_IMAGE_URL}${d?.image}`} /> : <span className='bg-gray-100 w-5 h-5 flex items-center justify-center text-[10px] rounded uppercase font-[700]'>{d?.name?.slice(0,2)}</span>} <a target='_blank' href={`${process.env.REACT_APP_BACKEND_IMAGE_URL}${d?.image}`} > <AiOutlineZoomIn  size={14} className='absolute text-gray-300 top-1 right-1'/></a> </h6>
            <h6 className='text-[12px] font-[500] w-[130px]  px-2 py-1 truncate border-r  border-slate-200'>{d?.name}</h6>
            <h6 className='text-[12px] font-[500] w-[100px]  px-2 py-1 truncate border-r  border-slate-200'>{d?.measurment}</h6>
            <h6 className='text-[12px] font-[500] w-[140px] px-2 py-1 truncate border-r  border-slate-200'>{d?.artist?.name}</h6>   
            <h6 className='text-[12px] font-[500] w-[70px] px-2 py-1 truncate border-r  border-slate-200'>{d?.qty}</h6>
            <h6 className='text-[12px] font-[500] w-[150px]  px-2 py-1 truncate border-r  border-slate-200 sticky right-0'>{d?.description}</h6>
            <h6 className='text-[12px] font-[500] w-[150px]  px-2 py-1 truncate border-r  border-slate-200 sticky right-0'>{d?.specification}</h6>
            <h6 className='text-[12px] font-[500] w-[90px]  px-2 py-1 truncate border-r  border-slate-200 sticky right-0'>{d?.mrp}</h6>
            <h6 className='text-[12px] font-[500] w-[95px]  px-2 py-1 truncate border-r  border-slate-200 sticky right-0'>{d?.selling_price}</h6>
            <h6  className='text-[12px] w-[70px]  px-2 py-1 mt-1 flex'>
            
             
              <Tooltip title="Detail">
              <span><RiBillLine size={13} className="z-10 ml-0" onClick={()=>navigate(`detail/${d?._id}`)}/></span>
              </Tooltip>


              <Tooltip title="Edit">
              <span><AiOutlineEdit size={13} className="z-10 ml-2" onClick={()=>{navigate('edit',{state:{data:d}});dispatch(galleryPagiantionAction({search:search,page:page}))}}/></span>
              </Tooltip>

              <Tooltip title="Delete">
              <span><AiOutlineDelete size={13} className="z-10 ml-2 -mt-[1px]" onClick={()=>{setselected_data(d);setmodal(true)}}/></span>
              </Tooltip>

              <Tooltip title="QR Code Download">
                <a download={d?.name} target='_blank' href={d?.qr_code}>
              <span><AiOutlineQrcode size={13} className="z-10 ml-2 -mt-[1px]" /></span></a>
              </Tooltip>

              <CopyToClipboard text={`${process.env.REACT_APP_FRONTEND_URL}gallery/product_list/detail/${d?._id}`} onCopy={()=>toast.success('Link Copied Successfully')}>

              <Tooltip title="Share">
              <span><AiOutlineShareAlt size={13} className="z-10 ml-2 -mt-[1px]" /></span>
              </Tooltip>
              </CopyToClipboard>

              

            
            </h6>
        </div>))}
      </div>}
      </div>
    </div>
    </div>
  )
}

export default GalleryProductList