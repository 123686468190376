import React,{useState,useEffect} from 'react'
import { TextAreaInput1, TextInput1 } from '../../components/input'
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../components/button'
import { toast } from 'react-hot-toast'
import GoBack from '../../components/back/GoBack'
import { useLocation, useNavigate } from 'react-router-dom'
import TaskMenu from './TaskMenu'
import { CreateDailyTaskService, UpdateDailyTaskService } from '../../services/DailyTaskServices'
// import LeadMenu from '../LeadMenu'
import {BiCheckbox,BiCheckboxSquare} from 'react-icons/bi'
import { GetDepartmentService, GetUsersByDepartment } from '../../services/DepartmentServices'
import { Modal, Select } from 'antd'
import { GetSearchService } from '../../services/AuthServices'
import {BiErrorCircle} from 'react-icons/bi'
import Uploader from '../../components/Uploader'
import { useSelector } from 'react-redux'
import { AiOutlinePlus,AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai'
import { CreateReminderService } from '../../services/ReminderServices'
import { CreateMomTaskService, DeleteMomItemTaskService, GetMomDetailTaskService, UpdateMomTaskService, UploadMomTaskAttachmentService } from '../../services/MOMTaskServices'

function MomCE() {

  const {state,pathname} = useLocation() 
  const navigator = useNavigate()
  
  // console.log("state",state)

  const user_id = useSelector(state=>state.Auth.id)
  const roles = useSelector(state=>state.Auth.roles)
  
  const path = pathname?.split('/')[pathname?.split('/')?.length - 1]
  const path1 = pathname?.split('/')[pathname?.split('/')?.length - 3]



  const [singleData,setsingleData] = useState({name:'',mass_completed:false})
  const [error,seterror] = useState({name:'',mass_completed:''})
  const [departmentArr,setdepartmentArr] = useState([])
  const [taskItem,settaskItem] = useState([])
  const [summary,setsummary] = useState([])
  const [modal,setmodal] = useState(false)
  const [modal1,setmodal1] = useState(false)
  const [users,setusers] = useState([])

  // const [data,setdata] = useState({department:'',task:'',remarks:'',dead_line:'',assigned_to:'',completed:false,attachment:'',id:''})
  // const [error1,seterror1] = useState({department:'',task:'',remarks:'',dead_line:'',assigned_to:'',completed:'',attachment:'',id:''})
  
  const [data,setdata] = useState({department:'',task:'',remarks:'',completed:false,id:''})
  const [error1,seterror1] = useState({department:'',task:'',remarks:'',completed:'',id:''})
  

  const [loader,setloader] = useState(false)

  useEffect(()=>{
    if(path === 'create' &&  path1 === 'assigned_work' && !roles?.includes('task_manager')){
        navigator(-1)
    }
    if(path === 'edit'){
      let d = state
      geteditdata(d?._id)
    }
    getoptions()
  },[])


  async function geteditdata(id){
     const response = await GetMomDetailTaskService(id)
     let data = response?.data?.data?.datas
     if(data?.length > 0){
        let d = data[0]
        let taskList = []
        d?.mom_items?.forEach((d1)=>{
          let obj = {
            department:d1?.department?._id === undefined ? '' : {label:d1?.department?.department_name,value:d1?.department?._id},
            task:d1?.task,
            // remarks:d1?.remarks,
            remarks:'',
            // dead_line:d1?.dead_line === '' ? '' : d1?.dead_line?.slice(0,10),
            // assigned_to:d1?.assigned_to?._id === undefined ? '' : {label:d1?.assigned_to?.name,value:d1?.assigned_to?._id},
            completed:d1?.completed,
            // attachment:d1?.attachment,
            id:d1?._id
          }
          taskList.push(obj)
        })
        settaskItem(taskList)
      
        let edit_data = {
          ...d,
          name:d?.name,
          mass_completed:d?.mass_completed,
          id:d?._id
        }
        setsingleData({...edit_data})
     }
  }

  async function getoptions(){
    const response = await GetDepartmentService()
    let arr = []

    response?.data?.datas?.forEach((d)=>{
        arr.push({value:d?.id,label:d?.department_name})
    })

    setdepartmentArr(arr)
  } 

  async function searchfunction(v){
   
    const response = await GetSearchService(v,1)
    let arr = []
    response?.data?.datas?.forEach((d)=>{
    arr.push({value:d?._id,label:`${d?.name} : [${d?.department_id[0]?.department_name}]`})
    })
    setusers(arr)
    
  }

  // console.log("path",path)

  async function submitform(){
    setloader(true)
    if(!singleData?.name){
      seterror({...error,name:"This Field is required"})
    }else{
      const send_data = {
        ...singleData
      }

      let arr = []
      taskItem?.forEach((t)=>{
        let obj = {...t}
        if(t?.department?.value !== undefined){
          obj['department'] = t?.department?.value
        }
        if(t?.assigned_to?.value !== undefined){
          obj['assigned_to'] = t?.assigned_to?.value
        }
        arr?.push(obj)
      })
      send_data['mom_items'] = arr

      if(path === 'create'){
        const response = await CreateMomTaskService(send_data)
        if(response?.status === 201){
          toast.success("MOM Task Created successfully")
          resetform()
        }
      }else{
        const response = await UpdateMomTaskService(send_data,singleData?.id)
        if(response?.status === 200){
          toast.success("MOM Task Updated successfully")
          resetform()
        }
      }
    }     
    
  }


  function resetform(){
        setsingleData({name:'',mass_completed:false})
        seterror({name:'',mass_completed:''})
        settaskItem([])
        setdata({department:'',task:'',remarks:'',completed:false,id:''})
        seterror1({department:'',task:'',remarks:'',completed:'',id:''})
        if(path === 'edit'){
            navigator(-1)
        }
       
  }

  async function handleSelect(e,e1){
        if(e1 === 'department'){
         setdata({...data,department:departmentArr?.find((d)=>d.value === e)})
         seterror({...error,department:''})
        }
        if(e1 === 'assigned_to'){
          setdata({...data,assigned_to:users?.find((d)=>d.value === e)})
          seterror({...error,assigned_to:''})
        }
  }    

  async function handlechange2(e){
    setdata({...data,[e.target.name] : e.target.value})
    seterror1({...error1,[e.target.name] : ''})
  }


  async function addreminder(){
    // if(!data.dead_line){
    //   seterror1({...error1,dead_line:'This Field is required'})
    //   setdata({...data,added:false})
    // }else
    if(!data.task){
      seterror1({...error1,task:'This Field is required'})
      setdata({...data,added:false})
    }
    // else  if(!data.remarks){
    //   seterror1({...error1,remarks:'This Field is required'})
    //   setdata({...data,added:false})
    // }
    else  if(!data.department){
      seterror1({...error1,department:'This Field is required'})
      setdata({...data,added:false})
    }
    // else  if(!data.assigned_to){
    //   seterror1({...error1,assigned_to:'This Field is required'})
    //   setdata({...data,added:false})
    // }
    else{
        if(data.id !== undefined && data.id !== '' && data.id !== null){
          let old_data = [...taskItem]
          let findIndex = old_data.findIndex((f)=>f?.id === data?.id)

          if(findIndex >= 0){
            console.log("findIndex",findIndex)
            old_data[findIndex] = data
          }
          settaskItem(old_data)
          resetformdata()
          setmodal(!modal)
        }else{
          let old_data = [...taskItem]
          let addData = {...data}
          addData['id'] = old_data.length 
          old_data.push(addData)
          settaskItem(old_data)
          resetformdata()
        }
    }

  }

  function resetformdata(){
    // setdata({department:'',task:'',remarks:'',dead_line:'',assigned_to:'',completed:false,attachment:'',id:''})
    // seterror1({department:'',task:'',remarks:'',dead_line:'',assigned_to:'',completed:'',attachment:'',id:''})
    setdata({department:'',task:'',remarks:'',completed:false,id:''})
    seterror1({department:'',task:'',remarks:'',completed:'',id:''})
  }

  async function addreminderfunc(v){
    let oldData = {...data}
    oldData['on_date_time'] = data.date + ' ' + data.time
    oldData['type'] = 'TaskSchema'
    oldData['type_id'] = v
    oldData['repeat'] = 'Once'
    oldData['notify'] = 'Email'
    delete oldData.date
    delete oldData.time
    delete oldData.added
    delete oldData.expired

    const response = await CreateReminderService(oldData)
    if(response?.status === 200){
      toast.success("Reminder Created Successfully")
      setmodal(false)
      setdata({title:'',description:'',date:'',date1:'',time:'',added:false,expired:false})
      seterror1({title:'',description:'',date:'',date1:'',time:'',added:false,expired:false})
    }

  }



  async function deletereminderfunc(v){
    if(path == 'create'){
        settaskItem(taskItem.filter((f)=>f?.id !== v?.id))
    }else{
      if(isNaN(v?.id)){
         const response = await DeleteMomItemTaskService(v?.id)
         if(response?.status === 200){
          geteditdata(state?._id)
          toast.success("MOM Task Deleted Successfully")
         }
      }else{
        settaskItem(taskItem.filter((f)=>f?.id !== v?.id))
      }
    }
  }

  async function editreminderfunc(v){
    if(path == 'create'){
      setdata({...v})
      setmodal(!modal)
    }else if(path == 'edit'){
      setdata({...v})
      setmodal(!modal)
    }
  }

  async function uploadfile(v){
    // console.log("v here ok na",v)
    const fd = new FormData()
    fd.append('image',v); 
    const response = await UploadMomTaskAttachmentService(fd)
    if(response?.status === 200){
      setdata({...data,attachment:response?.data?.data})
      seterror1({...error1,attachment:''})
    }
  }


  return (
    <div className='flex min-h-screen max-h-screen h-screen '>
      <Modal open={modal} width={280} footer={false} closable={false}>
      <h6 className='text-[14px] font-[700]'>Create Task for the mom</h6>  
            <h6 className='bg-slate-100 text-[10px] font-[500] p-1'>Use the below form to create the mom task</h6> 
          
              {/* <div className='w-[100%]'>
              <h6 className='text-[11px] font-[600] mb-1 mt-2' >Deadline</h6>   
              <div className='border border-slate-300 '>
              <input type='date' value={data?.dead_line} onChange={(v) => {setdata({...data,dead_line:v.target.value});seterror1({...error1,dead_line:''})}}  placeholder='' bordered={false} className='text-[12px] px-1 py-1.5 focus:outline-none w-[100%]' /> 
            
              </div>
              {error1?.dead_line !== '' &&
              <div className='flex items-center mt-1'>
                  <BiErrorCircle className='text-red-500' size={14} />
                  <span className='text-[10px] text-red-500 ml-1'>{error1?.dead_line}</span>
              </div>}
              </div> */}

            

            <TextAreaInput1 
              mandatory={true}
              label={'Work'}  
              variant="standard"
              name="task"
              type="text"
              value={data?.task}
              error={error1?.task}
              handlechange={handlechange2}
              placeholder=""
              InputLabelProps={{
                  style: { color: '#fff', }, 
              }}
            />

             
              <h6 className='text-[12px] font-semibold mb-1 mt-1'>Department </h6>
              <Select
                value={data.department} 
                error={error1.department}
                bordered={false}
                // disabled={path === 'create' ? false : (path === 'edit' && singleData?.created_by === user_id) ? false : true}
                placeholder="" 
                onChange={(e)=>handleSelect(e,'department')} 
                options={departmentArr} 
                className='w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none  focus:border-transparent focus:ring-offset-0 focus:shadow-none'
                />

{/* {path === 'edit' && */}
                <TextAreaInput1 
                    mandatory={false}
                    label={'Remarks'}  
                    variant="standard"
                    name="remarks"
                    type="text"
                    value={data?.remarks}
                    error={error1?.remarks}
                    handlechange={handlechange2}
                    placeholder=""
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}/>
              {/* } */}

                <div className='flex items-center mt-0 mr-2 -ml-1 mt-1'>
                {data?.completed ?  <BiCheckboxSquare size={22} className='text-slate-600' onClick={()=>setdata({...data,completed:!data.completed})} />  : <BiCheckbox size={22} className='text-slate-300' onClick={()=>setdata({...data,completed:!data.completed})} /> }
                <h6 className="text-[11.5px] font-[500] ml-1">COMPLETED</h6> 
                </div>


                {/* <h6 className='text-[12px] font-semibold mb-1 mt-1'>User </h6>
                <Select
                showSearch 
                filterOption={false} 
                value={data.assigned_to} 
                error={error1.assigned_to}
                bordered={false}
                closable={true}
                allowClear={true}
                width={270}
                // disabled={path === 'create' ? false : (path === 'edit' && singleData?.created_by === user_id) ? false : true}
                placeholder="" 
                onChange={(e)=>handleSelect(e,'assigned_to')} 
                options={users}
                onSearch={searchfunction}
                className='w-full border   outline-0 focus:outline-0 focus:ring-0 focus:border-none  focus:border-transparent focus:ring-offset-0 focus:shadow-none'
                />

              <>
              <h6 className='text-[11px] font-[600] mb-1 mt-[6px]'>Work Attachment </h6>
              <Uploader image={data?.attachment}  setimagefunc={(e)=>{uploadfile(e)}}  removeimageuploadfunc = {()=>{setdata({...data,attachment:''});seterror1({...error1,attachment:''})}} />
              </> */}






              <div className='flex mt-2 border-t pt-2 w-full items-end '>
                <div className='mr-2'>
                <ButtonOutlinedAutoWidth btnName="Cancel" onClick={()=>setmodal(false)} />
                </div>
                <ButtonFilledAutoWidth btnName="Save" onClick={()=>addreminder()} />
            </div>

      </Modal>

      

      <TaskMenu />
        
        <div className='flex'>
        <div className='border-r min-w-64 max-w-64 pr-5 border-slate-200 pl-5 min-h-screen max-h-screen h-screen overflow-y-screen overflow-x-hidden' >
        <GoBack />
        
          <div className='border-b pb-2'>
            <h6 className='text-[13px] mb-1 font-[800]'>Create/Edit the Mom Task Done By You</h6>
            <h6 className='text-[12px] bg-slate-200 p-2'>Use the Below form to add the daily task report </h6>
          </div>

        
          

          <h6 className='text-[11px] font-[600] mb-1 mt-[6px]'>Title</h6>
          <input className='border outline-none focus:none focus:ring-0 p-1.5 text-[11px] w-full  text-[11px] border-gray-300 '  value={singleData?.name} onChange={(e)=>{setsingleData({...singleData,name:e.target.value});seterror({...error,name:''})}} />

          <div className='flex items-center mt-0 mr-2 -ml-1 mt-2'>
            <h6 onClick={()=>{resetformdata();setmodal(!modal)}} className="cursor-pointer text-[9.5px] bg-green-700  p-[4px] font-[700] rounded px-[10px] text-white  ml-1">Add MOM Task List</h6> 
          </div>
        
            <div className='flex items-center mt-0 mr-2 -ml-1 mt-1'>
            {singleData?.mass_completed ?  <BiCheckboxSquare size={22} className='text-slate-600' onClick={()=>setsingleData({...singleData,mass_completed:!singleData.mass_completed})} />  : <BiCheckbox size={22} className='text-slate-300' onClick={()=>setsingleData({...singleData,mass_completed:!singleData.mass_completed})} /> }
            <h6 className="text-[11.5px] font-[500] ml-1">Mass Completed</h6> 
            </div>
            
            
        

      



        




        <div className='flex mt-2 border-t pt-2 w-full items-end '>
            <div className='mr-2'>
            <ButtonOutlinedAutoWidth btnName="Cancel" onClick={()=>navigator(-1)} />
            </div>
            <ButtonFilledAutoWidth btnName="Save" onClick={submitform} />
        </div>
        </div>  
        <div className='w-[600px] px-4 py-8'>
            <div className='flex border-b pb-2 items-center justify-between text-[12px]'>
              <h6 className='font-[700]'>TaskList ({taskItem?.length})</h6>
              <h6 onClick={()=>{resetformdata();setmodal(!modal)}} className='bg-slate-100 hover:bg-slate-200 cursor-pointer text-[11px] font-[700] py-[3px] rounded-[4px]  px-[6px]'>Add item</h6>
            </div>

            <div className='text-[11px] bg-gray-50 mt-2 border flex items-start'>
              <h6 className='min-w-[7%]  max-w-[7%]  px-[4px] font-[600] py-[2px] border-r'>Sl No</h6>
              <h6 className='min-w-[75%] max-w-[75%] px-[4px] font-[600] py-[2px]  border-r'>Task</h6>
              {/* <h6 className='min-w-[15%] max-w-[15%] px-[4px] font-[600] py-[2px]  border-r'>Deadline</h6> */}
              {/* <h6 className='min-w-[15%] max-w-[15%] px-[4px] font-[600] py-[2px]  border-r'>Assigned To</h6> */}
              <h6 className='min-w-[9%]  max-w-[9%]  px-[4px] font-[600] py-[2px] border-r'>Status</h6>
              <h6 className='min-w-[10%] max-w-[10%] px-[4px] font-[600] py-[2px] '>Action</h6>
            </div>
           
            {taskItem?.map((t,i)=>(
            <div className='text-[11px] border-l border-b border-r flex '>
              <h6 className='min-w-[7%]  max-w-[7%]  px-[4px] py-[2px] border-r'>{i+1}</h6>
              <h6 className='min-w-[75%] max-w-[75%] px-[4px] py-[2px]  border-r'>Task : {t?.task} <br></br> {t?.remarks !== '' && <span> Remarks : {t?.remarks} <br></br> </span> } {t?.department?.label !== undefined &&<span className='font-[600] underline'>{t?.department?.label}</span>} / {t?.attachment !== '' && <span className='text-[11px] underline font-[700] cursror-pointer'>{t?.attachment?.split('/')[t?.attachment?.split('/')?.length - 1]}</span>}</h6>
              {/* <h6 className='min-w-[15%] max-w-[15%] px-[4px] py-[2px]  border-r'>{t?.dead_line}</h6> */}
              {/* <h6 className='min-w-[15%] max-w-[15%] truncate px-[4px] py-[2px]  border-r'>{t?.assigned_to?.label}</h6> */}
              <h6 className='min-w-[9%]  max-w-[9%]  px-[4px] py-[2px] border-r'>
               {!t?.completed ? <BiCheckbox className='text-gray-200' size={20} /> : <BiCheckboxSquare className='text-slate-700' size={20} />}
              </h6>
              <h6 className='min-w-[10%] flex mt-1 max-w-[10%] px-[4px] py-[2px] '>
                <AiOutlineEdit className='mr-2' onClick={()=>editreminderfunc(t)} />
                <AiOutlineDelete onClick={()=>deletereminderfunc(t)} />
              </h6>
            </div>))}
        </div>
    
        </div>
    </div>
  )
}

export default MomCE