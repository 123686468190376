import React from 'react'
import { useLocation } from 'react-router-dom'

import CallsMenu from './CallsMenu'
import CallsLeadlist from './CallsLeadlist'

function CallsLeadBase() {
  const {pathname} = useLocation()


  return (
    <div className='h-screen max-h-screen box-border overflow-hidden'>


        <div className='block sm:flex'>
        <CallsMenu />

        <div className='w-[86%]  ml-4  mt-4'>
         <CallsLeadlist />
        </div>    

    </div>    
    </div>
  )
}

export default CallsLeadBase