import { deleteRequest, get, post, postfd, publicPost, put } from "../helpers/apihelpers";

export const CreatePropertyDBService=async(data)=>{
    try {
        const result = await post(`api/propertyDB/create`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const FilterPropertyDBService=async(page,from_date,to_date,search_text)=>{
    try {
        const result = await get(`api/propertyDB/filter?page=${page}&from_date=${from_date}&to_date=${to_date}&search_text=${search_text}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const FilterPropertyDBAdminService=async(page,from_date,to_date,search_text,user)=>{
    try {
        const result = await get(`api/propertyDB/filter_admin?page=${page}&from_date=${from_date}&to_date=${to_date}&search_text=${search_text}&user=${user}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DownloadPropertyDBAdminService=async(from_date,to_date,search_text,user)=>{
    try {
        const result = await get(`api/propertyDB/download_data?from_date=${from_date}&to_date=${to_date}&search_text=${search_text}&user=${user}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeletePropertyDBService=async(id)=>{
    try {
        const result = await deleteRequest(`api/propertyDB/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}


export const UploadPropertyDBService=async(file)=>{
    try {
        const result = await postfd(`api/propertyDB/upload_excel`,{file:file});
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DownloadPropertyDBService =async(step)=>{
    try {
        const result = await get(`api/propertyDB/download_excel?step=${step}`);
        return result;
    } catch (err) {
        return err.response;
    }
}


export const GetPropertyDBService =async(page)=>{
    try {
        const result = await get(`api/propertyDB/get?page=${page}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetPropertyDBAdminService =async(page,user)=>{
    // console.log("page",page)
    // console.log("user",user)
    try {
        const result = await get(`api/propertyDB/get_admin?page=${page}&user=${user}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetPropertyDBDetailService=async(id)=>{
    try {
        const result = await get(`api/propertyDB/get?id=${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetPropertyDBUserRoleWithAccessService=async()=>{
    try {
        const result = await get(`api/propertyDB/get_user_with_access`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdatePropertyDBService=async(id,data)=>{
    try {
        const result = await put(`api/propertyDB/update/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}


