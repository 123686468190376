import React,{useState,useEffect} from 'react'
import { GetDetailInsentiveBasicList } from '../../services/AdminServicesfile/InsentiveServices'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { getMyTarget } from '../../services/AuthServices'
import { getTargetAchieved } from '../../services/LeadServices'

function Incentive() {


  const auth = useSelector(state=>state.Auth)  
  const [incentiveData,setincentiveData] = useState({})  

  const [target,settarget] = useState({})
  const [target_achieved,settarget_achieved] = useState(0)
  const [incentive_amount,setincentive_amount] = useState(0)

  useEffect(()=>{
    getdata()
  },[])

  const getdata = async()=>{
    const response = await getMyTarget()
    const response1 = await getTargetAchieved()

    
    let d = response.data.data[0]
    // console.log("d here",d)
    settarget_achieved(response1?.data?.data[0]?.amount !== undefined ? response1?.data?.data[0]?.amount : 0)

    if(response1?.data?.data[0]?.amount !== 0){
      if(auth?.roles?.includes('hod')){
        setincentive_amount((response1?.data?.data[0]?.amount /100) * d?.incentive?.hod_percentage)
      }else if(auth?.roles?.includes('manager')){
        setincentive_amount((response1?.data?.data[0]?.amount /100) * d?.incentive?.manager_percentage)
      }else if(auth?.roles?.includes('bd_user')){
        setincentive_amount((response1?.data?.data[0]?.amount /100) * d?.incentive?.employee_percentage)
      }
    }

    settarget(d)

  }
  
  useEffect(()=>{
    getData()
  },[])
  
  const getData = async () => {
    const response = await GetDetailInsentiveBasicList()
    setincentiveData(response?.data?.datas[0])
  }




  return (
    <div className='px-5 pt-10'>
        <div>
            <h6 className='text-[15px] font-[900] border-b'>Incentive</h6>


            <h1 className='mt-4 bg-cyan-100 p-1 text-[14px]'>Hello Congrats Now your incentive feature has been enabled for the period <span className='text-[12.5px] font-[800]'>[ {moment(incentiveData?.incentive_start).format('LL')} - {moment(incentiveData?.incentive_end).format('LL')}]</span></h1>

            <h6 className='mt-4 text-[13px]'>Note : </h6>
            {auth?.roles?.includes('hod') &&
            <h6 className='text-[12px]'>{incentiveData?.hod_note}</h6>}
            {auth?.roles?.includes('manager') &&
            <h6 className='text-[12px]'>{incentiveData?.manager_note}</h6>}
            {auth?.roles?.includes('bd_user') &&
            <h6 className='text-[12px]'>{incentiveData?.employee_note}</h6>}


            {/* <h6>Target : {target?.target}</h6> */}
            <h6>Target_Achieved : {target_achieved}</h6>

            <span>Flat incentive you will be recieving based on the amount achieved will be {incentive_amount} </span>

        </div>
    </div>
  )
}

export default Incentive