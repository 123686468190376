import React from 'react'
import LeadMenu from './LeadMenu'
import Leadlist from './Leadlist'
import LeadCE from './LeadCE'
import { useLocation } from 'react-router-dom'
import DailyTask from './dailyTask/DailyTask'
import Landlordlist from './LandlordList'
import LandLordCE from './LandLordCE'

function LeadBase() {
  const {pathname} = useLocation()

  const path = pathname.split('/')[pathname.split('/').length - 1]
  const path1 = pathname.split('/')[2]

  // console.log("path",path)
  // console.log("path1 kp",path1)
  // console.log("path1 === 'landlords'",path1 === 'landlords')

  // const options = []
  // console.log('kpppp ',path)
  // console.log('path1',path1)


  return (
    <div className='h-screen max-h-screen box-border overflow-hidden'>


        <div className='block sm:flex'>
        <LeadMenu />

        <div className='w-[86%]  ml-4  mt-4'>

         {(path === 'create' || path === 'edit') ? 
          <>
          {path1 === 'landlords' ? 
            <>
            <LandLordCE />
            </>
            :
            <>
            <LeadCE />  
            </>
          }
          </>
          :
          <>
            {path !== '' &&
             <> 
             {/* <h6>{JSON.stringify(path1 === 'landlords')}</h6> */}
             {(path1 !== 'landlords' && path1 !== 'landlord_invoice') ? 
              <>
              {path1 !== 'daily_tasks' ?
                <Leadlist />  
              :
                <DailyTask/>}
              </>
              :
              <>
               {path1 === 'landlords' && <Landlordlist />}
               {path1 === 'landlord_invoice' && <Landlordlist />}
              </>}
              </>
            }  
          </>
         }

       

        </div>    

    </div>    
    </div>
  )
}

export default LeadBase