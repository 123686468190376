import { Tooltip } from '@mui/material'
import React from 'react'
import {FiUpload} from 'react-icons/fi'
import {IoClose} from 'react-icons/io5'
// import { theme_line, theme_line_dark } from '../constants/ThemeConstants'
// import { image_base_url } from '../constants/UrlConstants'

function Uploader({image,setimagefunc,name,removeimageuploadfunc,ext}) {

  // const [data,setdata] = useState('')

 

 
  function setimage(v){
    setimagefunc(v,name)
  }


 if(image === '' || image == null){
  return (
    <form onClick={()=>document.querySelector('.input-field').click()} className={`p-4 flex flex-col items-center justify-center cursor-pointer border-dashed border border-slate-400 `}>
        <FiUpload size={24} />
        <span className='font-bold text-[10px] mt-1 text-center'>Click To Upload Photo</span>
        <span className='font-bold text-[7px] mt-0 text-center'>Supported Type JPEG,PNG,SVG,WEBP,PDF,XLSX,PPT</span>
        <input type='file' onChange={({target:{files}})=>{
          files[0] && setimage(files[0])
        }} accept="*" className='input-field' hidden />
    </form> 
  )}
  else{
    return (
      <div className='p-2 border relative flex flex-col  cursor-pointer'>
          <Tooltip title='Delete'><IoClose className='absolute top-3 right-2' onClick={removeimageuploadfunc}/></Tooltip>
          <h6 className='text-[12px] truncate w-48 ml-0'>{image?.name !== undefined ? image?.name : image}</h6>
      </div>
      
    )
  }
}

export default Uploader