import React, { useEffect, useState } from 'react'
import { IconButton,Tooltip } from '@mui/material'
import AgreementFollowUpMenu from './AgreementFollowUpMenu'
import {BsArrowRepeat} from 'react-icons/bs';
import {FiChevronRight,FiChevronLeft} from 'react-icons/fi';
import { ButtonOutlinedAutoWidth } from '../../components/button';
import { DatePicker,Select } from 'antd';
import { GetAgreementListBasedUserService, GetAgreementListService } from '../../services/AgreementListServices';
import {AiOutlineEdit} from 'react-icons/ai';
import { FaRegFileAlt } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import { RiBillLine } from "react-icons/ri";
import moment from 'moment';
import { useSelector } from 'react-redux';

function AgreementFollowuplistuser() {
  
  const user = useSelector(state=>state.Auth)
  console.log("user",user)
  const [datas,setdatas] = useState({})
  const [page,setpage] = useState(1)
  const [search,setsearch] = useState({text:'',from_date:'',from_date1:'',to_date:'',to_date1:''})
  const navigate = useNavigate()
  const [step,setstep] = useState(1)


  useEffect(()=>{
    getdatas()
  },[page,step])

  async function getdatas(){
     const response = await GetAgreementListBasedUserService(page,step,search?.from_date1,search?.to_date1,search?.text)
     setdatas(response?.data)
  }

  // agreement_maintaner

  async function applyfilterfunction(){
    setpage(1)
    const response = await GetAgreementListBasedUserService(page,step,search?.from_date1,search?.to_date1,search?.text)
    setdatas(response?.data)
  }



  async function resetfunc(){
    setsearch({text:'',from_date:'',from_date1:'',to_date:'',to_date1:''})
    const response = await GetAgreementListBasedUserService(page,step,'','','')
    setdatas(response?.data)
    setpage(1)
  }

  async function openAgreement(v){
    window.open(`${process.env.REACT_APP_BACKEND_IMAGE_URL}${v?.aggrement?.file?.replace('public/','')}`,'_blank')
  }


  return (
    <div className='w-[100%] h-screen flex  max-h-screen '>
        <div className='flex w-[100%]'>
            <AgreementFollowUpMenu />
            <div className='w-[84%]  ml-4  mt-4'>
                <div className='flex items-center justify-between border-b pb-2'>
                <h6 className='font-[700] text-[14px] '>Total Agreement Data ({datas?.pagination?.total})</h6>
                <div className='flex items-center'>
                    <div className='flex items-center text-[12px] mr-2'>

                

                    <h6 className='mr-2 font-[600]'>{page == 1 ? datas?.datas?.length > 0 ? 1 : 0 :  (page - 1) * datas?.pagination?.limit } - {datas?.pagination?.limit} of {datas?.pagination?.total} </h6>
                    <IconButton onClick={resetfunc}><BsArrowRepeat size={16} /></IconButton>

                    <div>
                    <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>
                    <IconButton onClick={()=>{ page < datas?.pagination?.totalPages  ? setpage(page+1) : console.log('')}}><FiChevronRight className={`${(datas?.pagination?.totalPages === page || datas?.datas?.length === 0)  ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>

                    </div>
                    </div>


                    <DatePicker size='small' style={{fontSize:'11px'}} ampm={false} placeholder='From Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.from_date} onChange={(v,v1) => {console.log('v na',new Date(v).toLocaleDateString());setsearch({...search,from_date:v,from_date1:v1})}} /> 

                    <DatePicker size='small' style={{fontSize:'11px'}} ampm={false} placeholder='To Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.to_date} onChange={(v,v1) => {console.log('v na',new Date(v).toLocaleDateString());setsearch({...search,to_date:v,to_date1:v1})}} /> 

                
                <div >


                    {/* <Select
                    defaultValue={search?.type}
                    value={search?.type}
                    options={stages}
                    onChange={(e)=>setsearch({...search,type:stages?.find((f)=>f?.value === e)})}
                    placeholder="Stage"
                    bordered={false}
                    className='border border-slate-300 rounded-[5px] w-[100px] h-[30px] mr-[10px]'
                    /> */}
                    

                    <input  id="search_text" placeholder='Search text' type='text' value={search.text} onChange={(e)=>setsearch({...search,text:e.target.value})} className='border py-[3px] focus:ring-0 mr-2 focus:outline-0 text-[14px]  w-28 px-1 rounded-md border-slate-300' />
                
                
                </div> 
                {/* <Select 
                    bordered={false}
                    value={search?.zone}
                    options={options}
                    onChange={(e)=>setsearch({...search,zone:e})}
                    placeholder={'Zone'}
                    className='border rounded-[6px] border-slate-300 h-[30px] mx-2 w-[100px]' 
                    
                    />      */}

                <ButtonOutlinedAutoWidth btnName="Add Filter" onClick={()=>applyfilterfunction()} /> 
                

                </div>
                </div>
                <div>
                  {['admin','agreement_maintaner']?.filter((f)=>user?.roles?.includes(f)?.length > 0) && 
                  <div className='flex items-center border-b'>
                    <h6 onClick={()=>{setstep(1);setpage(1)}} className={`font-[600] ${step === 1 && 'bg-slate-600 text-white'} text-center cursor-pointer py-1 px-4 w-max text-[11px]`}>Pending</h6>
                    <h6 onClick={()=>{setstep(2);setpage(1)}} className={`font-[600] ${step === 2 && 'bg-slate-600 text-white'} text-center cursor-pointer py-1 px-4 w-max text-[11px]`}>Converted</h6>
                    <h6 onClick={()=>{setstep(3);setpage(1)}} className={`font-[600] ${step === 3 && 'bg-slate-600 text-white'} text-center cursor-pointer py-1 px-4 w-max text-[11px]`}>Couldn't Convert</h6>
                  </div>}

                {datas?.datas?.length === 0 &&
                    <div className='grid place-items-center mt-20  items-center justify-center'>
                    <img src={'https://fidecrmfiles.s3.amazonaws.com/NoDataFound1.png'} className='w-40 h-40 object-contain' /> 
                    <h6 className='font-bold text-[14px] -mt-10'>No data found</h6>
                    <h6 className='font-[500] w-[70%] text-center text-slate-700 text-[12.5px] -mt-2'>Oops we couldn't find any data based on your current stage please add the data to see list here.</h6>
                </div>
                }

                {datas?.datas?.length > 0 &&
                <div className='max-h-[82vh] mt-4 border-t   border-l border-r overflow-y-scroll'>
                        <div className='sticky top-0 z-50 flex  bg-white py-[-5px] border-b border-slate-200 relative'>
                            <h6  className='top-0 bg-white z-50 text-[12px] w-[60px]  px-2 py-1 font-[600] text-slate-600 border-r flex items-center justify-center'>SL NO</h6>
                            <h6 className='sticky top-0 z-50  text-[12px] w-[210px] px-2 py-1 font-[600] text-slate-600 border-r'>Company Name</h6>
                            <h6 className='sticky top-0 z-50  text-[12px] w-[140px]  px-2 py-1 font-[600] text-slate-600 border-r'>Contact Name</h6>
                            <h6 className='sticky top-0 z-50  text-[12px] w-[120px]  px-2 py-1 font-[600] text-slate-600 border-r'>Mobile</h6>
                            <h6 className='sticky top-0 z-50  text-[12px] w-[180px]  px-2 py-1 font-[600] text-slate-600 border-r'>Email</h6>
                            <h6 className='sticky top-0 z-50  text-[12px] w-[140px]  px-2 py-1 font-[600] text-slate-600 border-r'>Lock In Period</h6>
                            <h6 className='sticky top-0 z-50  text-[12px] w-[140px] px-2 py-1 font-[600] text-slate-600 border-r'>Agreement End Date</h6>
                            <h6 className='sticky top-0 z-50  text-[12px] w-[80px] px-2 py-1 font-[600] text-slate-600 border-r'>Attachment</h6>
                            <h6 className='sticky top-0 z-50  text-[12px] w-[90px]  px-2 py-1 font-[600] text-slate-600 sticky right-0'>Actions</h6>
                        </div>
                        {datas?.datas?.map((d,i)=>(
                        <div key={d?._id} className=' z-50 flex  bg-white py-[-5px] border-b border-slate-200 relative'>
                            <h6  className='bg-white z-50 text-[12px] w-[60px]  px-2 py-1 font-[500]  border-r flex items-center justify-center'>{page === 1 ? i+1 : (i+1) + ((page-1)* datas?.pagination?.limit)}</h6>
                            <h6 onClick={()=>navigate('/leads/contacts/detail',{state:d?.lead?._id})} className=' z-50  text-[12px] w-[210px] px-2 py-1 font-[500] break-all border-r text-blue-700 underline font-[700] cursor-pointer'>{d?.lead?.company_name}</h6>
                            <h6 className=' z-50  text-[12px] w-[140px]  px-2 py-1 font-[500] break-all border-r'>{d?.lead?.contact_name}</h6>
                            <h6 className=' z-50  text-[12px] w-[120px]  px-2 py-1 font-[500] break-all border-r'>{d?.lead?.phone}</h6>
                            <h6 className=' z-50  text-[12px] w-[180px]  px-2 py-1 font-[500] break-all border-r'>{d?.lead?.email}</h6>
                            <h6 className=' z-50  text-[12px] w-[140px]  px-2 py-1 font-[500] break-all border-r'>{moment(d?.lock_in_period)?.format('LL')}</h6>
                            <h6 className=' z-50  text-[12px] w-[140px] px-2 py-1 font-[500] break-all border-r'>{moment(d?.agreement_expire)?.format('LL')}</h6>
                            <h6 className=' z-50  text-[12px] w-[80px] px-2 py-1 font-[600] text-slate-600 border-r'>
                                <FaRegFileAlt onClick={()=>openAgreement(d)} />
                            </h6>
                            <h6 className=' z-50 flex text-[12px] w-[90px]  px-2 py-1 font-[500]  '>
                            <Tooltip title="Edit">
                            <span><AiOutlineEdit size={13} className="z-10 ml-2" onClick={()=>navigate('/agreement/followup/edit',{state:d?._id})}/></span>
                            </Tooltip>

                            <Tooltip title="Detail">
                            <span><RiBillLine size={13} className="z-10 ml-2" onClick={()=>navigate('/agreement/followup/detail',{state:d?._id})}/></span>
                            </Tooltip>
                            </h6>
                        </div>))}
                </div>}

                </div>
            </div>
        </div>
    </div>
  )
}

export default AgreementFollowuplistuser