import { deleteRequest, get, post, postfd, put } from "../../helpers/apihelpers";

export const CreateCasesService=async(data)=>{
    try {
        const result = await post(`api/govt/cases/create`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateCasesService=async(id,data)=>{
    try {
        const result = await put(`api/govt/cases/update/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UploadCasesFileService=async(data)=>{
    try {
        const result = await postfd(`api/govt/cases/upload_file`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetCasesService=async(page,step,from_date,to_date,search_text)=>{
    try {
        const result = await get(`api/govt/cases/get?page=${page}&step=${step}&from_date=${from_date}&to_date=${to_date}&search_text=${search_text}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteCasesService=async(id)=>{
    try {
        const result = await deleteRequest(`api/govt/cases/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}



export const CreateCasesFollowupService=async(data)=>{
    try {
        const result = await post(`api/govt/govt_followup/create`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetCasesFollowupService=async(type,id)=>{
    try {
        const result = await get(`api/govt/govt_followup/get?type=${type}&id=${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}


export const DeleteCasesFollowupService=async(id)=>{
    try {
        const result = await deleteRequest(`api/govt/govt_followup/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateCasesFollowupService=async(id,data)=>{
    try {
        const result = await put(`api/govt/govt_followup/update/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}
