import { deleteRequest, get, post, postfd, publicPost, put } from "../../../helpers/apihelpers";

export const CreateFmsWebsiteBlogService=async(data)=>{
    try {
        const result = await publicPost(`api/fms_website/blogs/create`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateFmsWebsiteBlogService=async(data,id)=>{
    try {
        const result = await put(`api/fms_website/blogs/update/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetFmsWebsiteBlogService=async(page,text)=>{
    try {
        const result = await get(`api/fms_website/blogs/get?page=${page}&text=${text}`,);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteFmsWebsiteBlogService=async(id)=>{
    try {
        const result = await deleteRequest(`api/fms_website/blogs/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UploadFmsWebsiteBlogService=async(data)=>{
    try {
        const result = await postfd(`api/fms_website/blogs/upload_file`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}