import React,{useState,useEffect} from 'react'
import { CreateInvoiceFinance, DeletePaymentServiceRequest, GetInsentivePaymentsDetail, GetInvoiceServiceDetail, RestorePaymentServiceRequest, UpdateInvoiceFinance, UpdatePaymentInvoiceFinance, UpdatePaymentServiceRequest } from '../../services/InvoiceServices'
import { json, useLocation,useNavigate } from 'react-router-dom'
import Uploader from '../../components/Uploader'
import { DatePicker, Modal } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../components/button'
import { TextInput } from '../../components/input'
import { toast } from 'react-hot-toast'
import { AiOutlineDownload,AiOutlineCloudDownload } from 'react-icons/ai'
import { BiDotsVerticalRounded, BiErrorCircle } from 'react-icons/bi'
import {MdPayment} from 'react-icons/md'
import GoBack from '../../components/back/GoBack'
import {BsFillBookmarkCheckFill} from 'react-icons/bs'
import {BiCheckbox,BiCheckboxSquare} from 'react-icons/bi'
import {IoClose} from 'react-icons/io5'
import fileDownload from "js-file-download";
import axios from 'axios'
import moment from 'moment'
import FinanceMenu from '../finance/FinanceMenu'
import {AiOutlineEdit,AiOutlineDelete} from 'react-icons/ai';


function InvoiceRequestDetail() {


  const navigator = useNavigate()
  const {state,pathname} = useLocation()  


  const path = pathname.split('/')[2]


  const [data,setdata] = useState({})

  const [raiseData,setraiseData] = useState({file:'',date:'',description:'',invoice_no:'',invoice_amount_with_gst:'',invoice_amount_without_gst:''})
  const [raiseDataErr,setraiseDataErr] = useState({file:'',date:'',description:'',invoice_no:'',invoice_amount_with_gst:'',invoice_amount_without_gst:''})

  const [selectedData,setselectedData] = useState(null)
  const [paymentModal,setpaymentModal] = useState(false)

  const [reviseInvoice,setreviseInvoice] = useState(null)

  const [addPayment,setaddPayment] = useState({payment_recieved:false,complete_payment_recieved:false,amount_with_gst:'',amount_without_gst:'',amount_with_gst_incl_tds_value:'',recieved_date:'',finance_approved:'',tds_deduction:0,payment:'0'})
  const [addPaymentErr,setaddPaymentErr] = useState({payment_recieved:false,complete_payment_recieved:false,amount_with_gst:'',amount_without_gst:'',amount_with_gst_incl_tds_value:'',recieved_date:'',payment:'0'})

  const [paymentDate,setpaymentDate] = useState(null)

  const [paymentDataSelected,setpaymentDataSelected] = useState({})
  const [reviseInvoiceBool,setreviseInvoiceBool] = useState(false)
  const [paymentStatusMode,setpaymentStatusMode] = useState('')

  const [deleteReason,setdeleteReason] = useState({reason:'',error:''})

  const [restorePaymentModal,setrestorePaymentModal] = useState(false)

  useEffect(()=>{
    getdata()
  },[])  
  
  const getdata = async() => {
    if(path === 'delete'){
      const response = await GetInsentivePaymentsDetail(state?._id)
      setdata(response?.data?.data[0])
    }else{
      const response = await GetInvoiceServiceDetail(state?._id)
      setdata(response?.data?.datas[0])
    }
  }  

  let rupee = new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
  });

  const raiseInvoiceData = async () => {
    if(data?.payment === '1' && !raiseData.file){
      setraiseDataErr({...raiseDataErr,file:'The Invoice File is required'})
    }else if(!raiseData.invoice_no){
      setraiseDataErr({...raiseDataErr,invoice_no:'The Invoice No is required'})
    }else if(!raiseData.date){
      setraiseDataErr({...raiseDataErr,data:'The Invoice Date is required'})
    }else if(!raiseData.invoice_amount_with_gst){
      setraiseDataErr({...raiseDataErr,invoice_amount_with_gst:'The Invoice Amount with gst is required'})
    }else if(!raiseData.invoice_amount_without_gst){
      setraiseDataErr({...raiseDataErr,invoice_amount_without_gst:'The Invoice Amount without gst is required'})
    }else{
        if(reviseInvoice === null){
            const fd = new FormData()
            fd.append('_id',data?._id)
            fd.append('invoice_no',raiseData?.invoice_no)
            fd.append('invoice_amount_with_gst',parseInt(raiseData?.invoice_amount_with_gst?.split(',')?.join('')))
            fd.append('invoice_amount_without_gst',parseInt(raiseData?.invoice_amount_without_gst?.split(',')?.join('')))
            fd.append('invoice_file',raiseData?.file)
            fd.append('description',raiseData?.description)
          
            const response =  await CreateInvoiceFinance(fd,data?._id)
            if(response.status === 200){
              toast.success(response?.data?.datas)
              setraiseData({file:'',date:'',description:'',invoice_no:'',invoice_amount_with_gst:'',invoice_amount_without_gst:''})
              setraiseDataErr({file:'',date:'',description:'',invoice_no:'',invoice_amount_with_gst:'',invoice_amount_without_gst:''})
              getdata()
              setreviseInvoiceBool(false)
              setreviseInvoice(false)
            }
            if(response.status === 403){
              setraiseDataErr({...raiseDataErr,invoice_no:response.data.message})
            }
            if(response.status === 422){
              setraiseDataErr({...raiseDataErr,file:response.data.errors})
            }
       }else{
            const fd = new FormData()
            fd.append('invoice_no',raiseData?.invoice_no)
            fd.append('invoice_amount_with_gst', JSON.stringify(raiseData?.invoice_amount_with_gst)?.split(',')?.join(''))
            fd.append('invoice_amount_without_gst',JSON.stringify(raiseData?.invoice_amount_without_gst)?.split(',')?.join(''))
            if(raiseData?.file?.name !== undefined){
              fd.append('invoice_file',raiseData?.file)
            }
            fd.append('description',raiseData?.description)
            fd.append('invoice_attachment',reviseInvoice?._id)
            const response =  await UpdateInvoiceFinance(fd,data?._id)
            if(response.status === 200){
              toast.success(response?.data?.datas)
              setraiseData({file:'',date:'',description:'',invoice_no:'',invoice_amount_with_gst:'',invoice_amount_without_gst:''})
              setraiseDataErr({file:'',date:'',description:'',invoice_no:'',invoice_amount_with_gst:'',invoice_amount_without_gst:''})
              setreviseInvoiceBool(false)
              setreviseInvoice(false)
              getdata()
            }
       }
    } 
  }


  const handleDownload = (url, filename) => {
        axios.get(url, {
            responseType: 'blob',
        })
            .then((res) => {
                fileDownload(res.data, filename)
            })
  }

  const updatepayment_Status = async (v) => {
       if(!addPayment?.amount_with_gst && v === '2'){
        setaddPaymentErr({...addPaymentErr,amount_with_gst:'This Field is required*'})
       }else if(!addPayment?.amount_without_gst && v === '2'){
        setaddPaymentErr({...addPaymentErr,amount_without_gst:'This Field is required*'})
       }else if(!addPayment?.recieved_date && v === '2'){
        setaddPaymentErr({...addPaymentErr,recieved_date:'This Field is required*'})
       }else{
        if(v === '2'){
            let send_data_modified= {
              amount_with_gst:addPayment?.amount_with_gst?.split(',')?.join(''),
              amount_without_gst:addPayment?.amount_without_gst?.split(',')?.join(''),
              tds_deduction:(addPayment?.tds_deduction === '' || addPayment?.tds_deduction === null || addPayment?.tds_deduction === undefined) ? 0 : parseInt(addPayment?.tds_deduction),
              recieved_date:addPayment?.recieved_date,
              payment:addPayment?.payment,
              invoice:state?._id,
              client:data?.client?._id,
              payment_recieved:true,
              // finance_approved:addPayment?.finance_approved
            }

            console.log("state",state)
            // console.log("send_data_modified",send_data_modified)

            if(addPayment?.complete_payment_recieved){
              send_data_modified['complete_payment_recieved'] = addPayment?.complete_payment_recieved
            }
            
            const response = await UpdatePaymentInvoiceFinance(send_data_modified,data?._id)
            if(response.status === 200){
              setpaymentModal(false)
              setaddPayment({payment_recieved:false,complete_payment_recieved:false,amount_with_gst:'',amount_without_gst:'',recieved_date:'',payment:'0',tds_deduction:0})
              setaddPaymentErr({payment_recieved:false,complete_payment_recieved:false,amount_with_gst:'',amount_without_gst:'',recieved_date:'',payment:'0'})
              toast.success(response?.data?.data)
              setpaymentDate('')
              getdata()
            } 
        }else{
            let send_data= {
              amount_recieved_date:paymentDate,
              amount_recieved_finalized:false,
              payment_recieved:false,
              finalized:false
            }
            const response = await UpdatePaymentInvoiceFinance(send_data,data?._id)
            if(response.status === 200){
            toast.success(response?.data?.datas)
            setaddPayment({payment_recieved:false,complete_payment_recieved:false,amount_with_gst:'',amount_without_gst:'',recieved_date:'',payment:'0'})
            setaddPaymentErr({payment_recieved:false,complete_payment_recieved:false,amount_with_gst:'',amount_without_gst:'',recieved_date:'',payment:'0'})
            setpaymentDate('')
            getdata()
            }
          }
       
       }
  }

  const completedPymentStatusUpdate = async () =>{
      if(paymentStatusMode === '1'){
        if(!deleteReason.reason){
          setdeleteReason({...deleteReason,error:'The Message Field is required*'})
        }else{
          const response = await DeletePaymentServiceRequest(paymentDataSelected?._id,{is_deleted:true,deleted_reason:deleteReason?.reason})
          if(response.status === 200){
            setpaymentStatusMode('')
            setdeleteReason({reason:'',error:''})
            getdata()
            toast.success(response?.data?.data)
          }
         }
      }else if(paymentStatusMode === '2'){
        let send_data = {
          amount_with_gst:JSON.stringify(addPayment?.amount_with_gst)?.split('')?.includes(',') ? parseInt(addPayment?.amount_with_gst?.split(',')?.join(''))  : addPayment?.amount_with_gst,
          amount_without_gst:JSON.stringify(addPayment?.amount_with_gst)?.split('')?.includes(',') ? parseInt(addPayment?.amount_without_gst?.split(',')?.join('')) : addPayment?.amount_without_gst ,
          amount_with_gst_incl_tds_value:JSON.stringify(addPayment?.amount_with_gst_incl_tds_value)?.split('')?.includes(',') ? parseInt(addPayment?.amount_with_gst_incl_tds_value?.split(',')?.join('')) : addPayment?.amount_with_gst_incl_tds_value ,
          tds_deduction:JSON.stringify(addPayment?.tds_deduction)?.split('')?.includes(',') ? parseInt(addPayment?.tds_deduction?.split(',')?.join('')) : addPayment?.tds_deduction ,
          recieved_date:addPayment?.recieved_date,
          finance_approved:addPayment?.finance_approved,
          deleted_reason:addPayment?.deleted_reason,
          payment:addPayment?.payment
        }


        const response = await UpdatePaymentServiceRequest(paymentDataSelected?._id,send_data)
        if(response.status === 200){
         setpaymentStatusMode('')
         setaddPayment({payment_recieved:false,complete_payment_recieved:false,amount_with_gst:'',amount_without_gst:'',amount_with_gst_incl_tds_value:'',recieved_date:'',finance_approved:'',tds_deduction:0,payment:'0'})
         setdeleteReason({reason:'',error:''})
         getdata()
         toast.success(response?.data?.data)
        }
      }
  }

  async function restorePayment(){
      const response = await RestorePaymentServiceRequest(state?._id,{is_deleted:false,deleted_reason:''})
      if(response.status === 200){
        setrestorePaymentModal(false)
         toast.success(response?.data?.data)
         setTimeout(() => {
             navigator(-1)
         }, 1000);
      }  
  }

  async function handleChangepayment(e){


    if((e.target.name === 'tds_deduction' && e.target.name !== 'amount_without_gst') && addPayment?.tds_deduction !== 0 && addPayment?.amount_without_gst !== ''){
      let gst_calculation = (addPayment?.amount_without_gst / 100) * 18
      let tds_amount = 0
      let amount_without_gst = addPayment?.amount_without_gst


    

      let total_calculation = 0 
      // total_calculation =  Math.round((amount_without_gst - tds_amount) + gst_calculation)  
      
      if(e.target.name === 'tds_deduction' && e.target?.value == 0){
        total_calculation = addPayment?.amount_without_gst
      }else{
        tds_amount = e.target.value === 0 ? 0 : ((addPayment?.amount_without_gst  / 100) * e.target?.value)
        // console.log("tds_amount : ",tds_amount)
        // console.log("gst_calculation : ",gst_calculation)
        // console.log("amount_without_gst : ",amount_without_gst)
        // console.log("amount_without_gst + tds_amount + gst",amount_without_gst + tds_amount + gst_calculation)
        total_calculation =  Math.round((parseInt(addPayment?.amount_with_gst) + Math.round(tds_amount)))
      }

      // console.log("amount_without_gst",amount_without_gst)
      // console.log("tds_amount",tds_amount)
      // console.log("gst_calculation",gst_calculation)
      // console.log("total_calculation",total_calculation)

      setaddPayment({...addPayment,[e.target.name]:e.target.value,amount_with_gst_incl_tds_value:total_calculation})
      setaddPaymentErr({...addPaymentErr,[e.target.name]:''})
    }else{
      setaddPayment({...addPayment,[e.target.name]:e.target.value})
      setaddPaymentErr({...addPaymentErr,[e.target.name]:''})
    }
    


  }




  return (
    <div className='min-h-screen h-screen overflow-hidden  '>
         
      <div className='flex'>
        <FinanceMenu />
      <div className=' w-[87%] min-h-screen '>
      
      <Modal open={restorePaymentModal} center={true} width={300} closable={false} footer={false}>
      <div className='relative'> 
              <IoClose className='absolute right-0 top-0 cursor-pointer' onClick={()=>{setrestorePaymentModal(false)}} />
              <h6 className='text-[15px] font-[700]'>Restore Payment Transaction</h6>
              <h6 className='leading-tight mt-2 text-[12px] bg-slate-100 p-2'>Are You sure want to restore the selected payment of Amount  </h6>
              <div className='flex mt-4'>
                <ButtonOutlinedAutoWidth btnName="Cancel" onClick={()=>{setrestorePaymentModal(false)}} />
                <div className='ml-2'>
                <ButtonFilledAutoWidth btnName="Yes Im sure" onClick={restorePayment} />
                </div>
              </div>

          </div> 
      </Modal>

      <Modal open={reviseInvoice}  width={300} className='-mt-20' closable={false} footer={false}>
      <div className='relative '> 
      <IoClose className='absolute right-0 top-0 cursor-pointer' onClick={()=>{setreviseInvoice(false)}} />

      <h6 className='text-[14px] font-[900] ml-1'>Revise Invoice</h6>
                        <h6 className='text-[11px] bg-slate-100 p-2 my-2 leading-tight'>Use the below form to create or update the invoice raised for the client which has been created.</h6>
                     
                     <h6 className='text-[11px] font-[600] mb-1 mt-1' >{'Invoice File'}</h6>
                     <Uploader image={raiseData?.file}  setimagefunc={(e)=>{setraiseData({...raiseData,file:e});setraiseDataErr({...raiseDataErr,file:''})}}  removeimageuploadfunc = {()=>setraiseData({...raiseData,file:''})}/>
                       
 
                     <div className='mb-2'>
                         <TextInput
                             mandatory={true}
                             label={'Invoice No'}  
                             variant="standard"
                             name="invoice_no"
                             type="text"
                             value={raiseData.invoice_no}
                             error={raiseDataErr.invoice_no}
                             handlechange={(e)=>{setraiseData({...raiseData,invoice_no:e.target.value});setraiseDataErr({...raiseDataErr,invoice_no:''})}}/>
                     </div>
 
                     <div className='mb-2'>
                         <TextInput
                             mandatory={true}
                             label={'Invoice Amount Included GST*'}  
                             variant="standard"
                             name="invoice_amount_with_gst"
                             type="text"
                             value={raiseData.invoice_amount_with_gst}
                             error={raiseDataErr.invoice_amount_with_gst}
                             handlechange={(e)=>{setraiseData({...raiseData,invoice_amount_with_gst:e.target.value});setraiseDataErr({...raiseDataErr,invoice_no:''})}}/>
                     </div>
 
 
                     <div className='mb-2'>
                         <TextInput
                             mandatory={true}
                             label={'Invoice Amount Excluded GST*'}  
                             variant="standard"
                             name="invoice_amount_without_gst"
                             type="text"
                             value={raiseData.invoice_amount_without_gst}
                             error={raiseDataErr.invoice_amount_without_gst}
                             handlechange={(e)=>{setraiseData({...raiseData,invoice_amount_without_gst:e.target.value});setraiseDataErr({...raiseDataErr,invoice_no:''})}}/>
                     </div>
 
 
                     <div style={{width:'100%',}}>
                         <h6 className='text-[11px] font-[600] mb-1 mt-1' >{'Invoice Raise Date '}</h6>
                         <div className='border border-slate-300 border-l-4 border  border-l-slate-600 '>
                         <DatePicker placeholder='' bordered={false} ampm={false} style={{maxHeight:'40px',borderRadius:'3px',width:'100%'}} className='rounded-[0px]' inputFormat="MM/dd/yyyy" value={raiseData?.date} onChange={(v) => {setraiseData({...raiseData,date:v});setraiseDataErr({...raiseDataErr,date:''})}} />
                         </div>
                         {/* {(invoiceDataErr?.invoice_raising_date !== '' )  && <h6 className='text-red-600 text-[10px]'>{invoiceDataErr?.invoice_raising_date}</h6>} */}
                     </div>
 
                     <div style={{width:'100%',}}>
                         <h6 className='text-[11px] font-[600] mb-1 mt-1' >{'Description'}</h6>
                         <TextArea value={raiseData?.description} className='rounded-sm' onChange={(e)=>{setraiseData({...raiseData,description:e.target.value});setraiseDataErr({...raiseDataErr,description:''})}} />
                         {/* {(invoiceDataErr?.invoice_raising_date !== '' )  && <h6 className='text-red-600 text-[10px]'>{invoiceDataErr?.invoice_raising_date}</h6>} */}
                     </div>
 
 
                     <div className='mt-4 flex justify-end'>
                     <ButtonFilledAutoWidth btnName="Save" onClick={raiseInvoiceData} />
                     </div>

          </div> 
      </Modal>

      <Modal open={paymentModal} center={true} width={300} closable={false} footer={false}>
          <div className='relative'> 
          <IoClose className='absolute right-0 top-0 cursor-pointer' onClick={()=>{setpaymentModal('');setaddPayment({payment_recieved:false,complete_payment_recieved:false,amount_with_gst:'',amount_without_gst:'',recieved_date:''})}} />
              <>       
                        <h6 className='text-[14px] font-[900] ml-1'>Create Payment</h6>
                        <h6 className='text-[11px] bg-slate-100 p-2 my-2 leading-tight'>Use the below form to create the payment recieved for the Invoice which has been created.</h6>
                        <div className='mt-1 ml-1.5'>
                            <TextInput 
                              mandatory={true}
                              label={'Amount With GST* '}  
                              variant="standard"
                              name="amount_with_gst"
                              type="text"
                              value={addPayment?.amount_with_gst}
                              error={addPaymentErr?.amount_with_gst}
                              handlechange={handleChangepayment} />
                        </div>

                        <div className='mt-1 ml-1.5'>
                            <TextInput 
                              mandatory={true}
                              label={'Amount Without Gst* '}  
                              variant="standard"
                              name="amount_without_gst"
                              type="text"
                              value={addPayment?.amount_without_gst}
                              error={addPaymentErr?.amount_without_gst}
                              handlechange={handleChangepayment} />
                        </div>

                        <div className='mt-1 ml-1.5'>
                            <TextInput 
                              mandatory={false}
                              label={'TDS Deduction '}  
                              variant="standard"
                              name="tds_deduction"
                              type="number"
                              value={addPayment?.tds_deduction}
                              error={addPaymentErr?.tds_deduction}
                              handlechange={handleChangepayment} />
                        </div>

                        <div className='mt-1 '>
                            <TextInput 
                              mandatory={true}
                              label={'Amount Without Gst Incl TDS Amount* '}  
                              variant="standard"
                              name="amount_with_gst_incl_tds_value"
                              type="text"
                              value={addPayment.amount_with_gst_incl_tds_value}
                              error={addPaymentErr.amount_with_gst_incl_tds_value}
                              handlechange={handleChangepayment} />
                        </div>

                        <div>
                        <h6 className='text-[11px] font-[600] ml-1.5 mb-1 mt-1' >{'Payment Recieved Date'}</h6>
                        <input type='date' value={addPayment?.recieved_date?.slice(0,10)} className='border w-[98%] p-1 text-[14px] ml-1.5  border-l-4 border-l-slate-600' onChange={(e)=>{setaddPayment({...addPayment,recieved_date:e?.target.value})}} />
                        {/* <DatePicker placeholder='' style={{maxHeight:'40px',borderRadius:'3px',width:'100%'}} className='border-l-4 ml-1.5  border-l-[#158a93]' inputFormat="MM/dd/yyyy" value={(addPayment?.recieved_date === '' || addPayment?.recieved_date === null) ? null : moment(addPayment?.recieved_date)} onChange={(v) => {setaddPayment({...addPayment,recieved_date:v})}} /> */}
                       </div>
                        
                        <div className='flex items-center mt-2'>
                        {addPayment?.payment == '1' ?   <BiCheckboxSquare size={20} onClick={()=>{setaddPayment({...addPayment,payment:1})}} className='text-slate-600' /> : <BiCheckbox size={20} onClick={()=>{setaddPayment({...addPayment,payment:'1'})}} className='text-slate-300'  />} <span className='text-[11px] ml-1 font-[600]'>Cash</span>
                        <h6 className='w-2'></h6>
                        {addPayment?.payment == '0' ?   <BiCheckboxSquare size={20} onClick={()=>{setaddPayment({...addPayment,payment:0})}} className='text-slate-600' /> : <BiCheckbox size={20} onClick={()=>{setaddPayment({...addPayment,payment:'0'})}} className='text-slate-300'  />} <span className='text-[11px] ml-1 font-[600]'>Online</span>
                       </div>
                        <div className='mt-4 flex ml-1'>
                          <ButtonFilledAutoWidth btnName="Save Payment" onClick={()=>updatepayment_Status('2')} />
                        </div>
                        </>

          </div> 
      </Modal>

      <Modal open={paymentStatusMode !== ''} center={true} width={300} closable={false} footer={false}>
          {paymentStatusMode === '1' ? 
          <div className='relative'> 
              <IoClose className='absolute right-0 top-0 cursor-pointer' onClick={()=>{setpaymentStatusMode('');setaddPayment({payment_recieved:false,complete_payment_recieved:false,amount_with_gst:'',amount_without_gst:'',recieved_date:''})}} />
              <h6 className='text-[15px] font-[700]'>Delete Payment Transaction</h6>
              <h6 className='leading-tight mt-2 text-[12px] bg-slate-100 p-2'>Are You sure want to delete the selected payment of Amount :<span className='font-[700] text-[11px]'> {rupee.format(paymentDataSelected?.amount_with_gst)}</span> which is recieved on : <span className='font-[700] text-[12px]'>{moment(paymentDataSelected?.recieved_date).format('LLL')}</span>  </h6>


              <h6 className='text-[12px] font-[600] mb-1 mt-1' >{'Reason'}</h6>
              <TextArea className='border rounded-[0px] ' value={deleteReason?.reason} onChange={(e)=>setdeleteReason({...deleteReason,reason:e.target.value,error:''})} />
              {deleteReason.error !== '' &&
              <div className='flex items-center mt-1'>
                <BiErrorCircle className='text-red-500' size={14} />
                <span className='text-[10px] text-red-500 ml-1'>{deleteReason?.error}</span>
              </div>}

              <div className='flex mt-4'>
                <ButtonOutlinedAutoWidth btnName="Cancel" onClick={()=>{setpaymentStatusMode('');setaddPayment({payment_recieved:false,complete_payment_recieved:false,amount_with_gst:'',amount_without_gst:'',recieved_date:''})}} />
                <div className='ml-2'>
                <ButtonFilledAutoWidth btnName="Yes Im sure" onClick={completedPymentStatusUpdate} />
                </div>
              </div>

          </div> 
          :
          <div className='relative'>
              <IoClose className='absolute right-0 top-0 cursor-pointer' onClick={()=>{setpaymentStatusMode('');setaddPayment({payment_recieved:false,complete_payment_recieved:false,amount_with_gst:'',amount_without_gst:'',recieved_date:''})}} />
              <h6 className='text-[15px] font-[700]'>Update Payment Transaction</h6>
              <h6 className='leading-tight mt-2 text-[11px] bg-slate-100 p-2'>Are You sure want to update the selected payment transaction of Amount :<span className='font-[700] text-[10px]'> {rupee?.format(paymentDataSelected?.amount_with_gst)}</span> which is recieved on : <span className='font-[700] text-[10px]'>{moment(paymentDataSelected?.recieved_date).format('LLL')}</span>  </h6>
               
              <div className='mt-1 '>
                            <TextInput 
                              mandatory={true}
                              label={'Amount With GST* '}  
                              variant="standard"
                              name="amount_with_gst"
                              type="text"
                              value={addPayment.amount_with_gst}
                              error={addPaymentErr.amount_with_gst}
                              handlechange={handleChangepayment} />
                        </div>

                        <div className='mt-1 '>
                            <TextInput 
                              mandatory={true}
                              label={'Amount Without Gst* '}  
                              variant="standard"
                              name="amount_without_gst"
                              type="text"
                              value={addPayment.amount_without_gst}
                              error={addPaymentErr.amount_without_gst}
                              handlechange={handleChangepayment} />
                        </div>

                        <div className='mt-1 '>
                            <TextInput 
                              mandatory={false}
                              label={'TDS Deducation'}  
                              variant="standard"
                              name="tds_deduction"
                              type="number"
                              value={addPayment.tds_deduction}
                              error={addPaymentErr.tds_deduction}
                              handlechange={handleChangepayment} />
                        </div>


                        <div className='mt-1 '>
                            <TextInput 
                              mandatory={true}
                              // readOnly={true}
                              label={'Amount With Gst Incl TDS Amount* '}  
                              variant="standard"
                              name="amount_with_gst_incl_tds_value"
                              type="text"
                              value={addPayment.amount_with_gst_incl_tds_value}
                              error={addPaymentErr.amount_with_gst_incl_tds_value}
                              handlechange={handleChangepayment} />
                        </div>

                        <div>
                        <h6 className='text-[11px] font-[600]  mb-1 mt-1' >{'Payment Recieved Date'} {addPayment?.recieved_date}</h6>
                        {/* <DatePicker mode={'date'} bordered={false} placeholder='' ampm={false} style={{maxHeight:'40px',borderRadius:'3px',width:'100%'}} className='border-l-4 border-l-slate-700' inputFormat="MM/dd/yyyy" defaultValue={addPayment?.recieved_date === '' ? null : moment(addPayment?.recieved_date)} onChange={(e)=>{setaddPayment({...addPayment,recieved_date:e?.target.value})}} /> */}

                        <input type='date' value={addPayment?.recieved_date} className='border w-full p-1 border-l-4 border-l-slate-600' onChange={(e)=>{setaddPayment({...addPayment,recieved_date:e?.target.value})}} />
                        </div>


                        <div className='flex items-center mt-2'>
                        {addPayment?.payment == 1 ?   <BiCheckboxSquare size={20} onClick={()=>{setaddPayment({...addPayment,payment:1})}} className='text-slate-600' /> : <BiCheckbox size={20} onClick={()=>{setaddPayment({...addPayment,payment:1})}} className='text-slate-300'  />} <span className='text-[11px] ml-1 font-[600]'>Cash</span>
                        <h6 className='w-2'></h6>
                        {addPayment?.payment == 0 ?   <BiCheckboxSquare size={20} onClick={()=>{setaddPayment({...addPayment,payment:0})}} className='text-slate-600' /> : <BiCheckbox size={20} onClick={()=>{setaddPayment({...addPayment,payment:0})}} className='text-slate-300'  />} <span className='text-[11px] ml-1 font-[600]'>Online</span>
                       </div>

                        <div className='flex items-center  mt-2'>
                        {addPayment?.finance_approved ?  <BiCheckboxSquare size={20} onClick={()=>{setaddPayment({...addPayment,finance_approved:!addPayment.finance_approved})}} className='text-slate-600' /> : <BiCheckbox size={20} onClick={()=>{setaddPayment({...addPayment,finance_approved:!addPayment.finance_approved})}} className='text-slate-300'  />}
                        {addPayment?.finance_approved ? <span className='text-[11px] font-[600]'>Finance Approved</span> : <span className='text-[11px] font-[600]' >Finance Not Approved</span>}
                       </div>

                       <div style={{width:'100%',}}>
                        <h6 className='text-[11px] font-[600] mb-1 mt-1' >{'Description'}</h6>
                        <TextArea value={addPayment?.deleted_reason} className='rounded-sm' onChange={(e)=>{setaddPayment({...addPayment,deleted_reason:e.target.value});setraiseDataErr({...raiseDataErr,deleted_reason:''})}} />
                        {/* {(invoiceDataErr?.invoice_raising_date !== '' )  && <h6 className='text-red-600 text-[10px]'>{invoiceDataErr?.invoice_raising_date}</h6>} */}
                    </div>

                       

                       {/* <h6 className='text-[11px] font-[600]  mb-1 mt-1' >{'Finance Approved'}</h6> */}
                       {/* <input type='checkbox' checked={addPayment?.finance_approved} onChange={()=>setaddPayment({...addPayment,finance_approved:!addPayment.finance_approved})} /> */}



              <div className='flex mt-4'>
                <ButtonOutlinedAutoWidth btnName="Cancel" onClick={()=>{setpaymentStatusMode('');setaddPayment({payment_recieved:false,complete_payment_recieved:false,amount_with_gst:'',amount_without_gst:'',recieved_date:''})}} />
                <div className='ml-2'>
                <ButtonFilledAutoWidth btnName="Yes Im sure" onClick={completedPymentStatusUpdate} />
                </div>
              </div>

          </div>}
      </Modal>
      
      <div className='flex'>
      <div className='min-w-[25%] w-[25%] max-w-[25%] mb-20 border-r relative overflow-y-scroll'>

            <div className='px-2'>
            <GoBack />

            {data?.invoice_request?.length > 0 &&<>
            <h6 className='font-[600] text-[14px] p-1 mt-4'>Invoice Info</h6>
            <h6 className='text-[11px] p-1 bg-slate-100'>Use the below details For the Invoice request raised by the user</h6>

            {data?.invoice_request?.map((d1,i)=>(
            <div key={i} className={`border-b mb-2 relative py-2`}>
                
                {(d1?.file !== '' && d1?.file !== undefined && d1?.file !== null) &&
                <div className='flex '>
                  <img src="https://cdn-icons-png.flaticon.com/512/179/179483.png" className='w-6 h-6 object-contain z-0' />
                  <h6 className='text-[9px] ml-1 w-[170px] font-[800]  overflow-hidden line-clamp-2'>{d1?.file?.split('/')[d1?.file?.split('/')?.length - 1]}</h6>
                  <MdPayment size={16} className='cursor-pointer absolute right-8  bg-white' onClick={()=>{setpaymentModal(true);setselectedData(d1)}} />
                  <AiOutlineCloudDownload size={18} className='cursor-pointer absolute right-0  bg-white' onClick={()=>handleDownload(`${process.env.REACT_APP_BACKEND_IMAGE_URL}${d1?.file}`,d1?.file?.split('/')[d1?.file?.split('/')?.length - 1])} />
                </div>}  
                {data?.invoice_revise &&
                <h6 className='text-[10px] ml-1 mt-1 bg-red-100 p-1'>Invoice Revise Request : <span className='font-[700] text-[11px]'>{data?.invoice_revise_summary}</span><span className='text-red-600 cursor-pointer font-[600] underline' 
                    onClick={()=>{
                    setreviseInvoiceBool(true)
                    setraiseData({...raiseData,
                      invoice_no:d1?.invoice_no,
                    // file:d1?.file,
                      invoice_amount_with_gst:data?.invoice_amount_with_gst,
                      invoice_amount_without_gst:data?.invoice_amount_without_gst,
                      description:data?.description,
                      date:moment(data?.invoice_raising_date?.slice(0,10))});setreviseInvoice(d1)}}> Revise Invoice</span></h6>
                  }
                <h6 className='text-[10px] ml-1 mt-1'>Invoice No : <span className='font-[800]'>{d1?.invoice_no}</span></h6>
                {d1?.description !== '' && <h6 className='text-[10px] ml-1'>Description : <span className='font-[500]'>{d1?.description}</span></h6>}
                <h6 className='text-[10px] ml-1'>Created On : <span className='font-[800]'>{moment(d1?.createdAt).format('LLL')}</span></h6>
                {d1?.final && <BsFillBookmarkCheckFill className='absolute top-0 left-0 z-100' />}
            </div>))}
            </>}

            <h6 className={`font-[600] text-[14px] ${data?.invoice_request?.length === 0 && 'border-t mt-[20px] '} p-1`}>Client Info</h6>

            <h6 className='text-[11px] p-1 bg-slate-100'>Use the below details For the Invoice owner who raised  invoice request from you. </h6>

            <div className='pt-2 flex '>

              <h6 className='bg-slate-200 w-8 h-8 p-1 text-[11px] flex items-center justify-center uppercase rounded-full'>{data?.client?.contact_name?.slice(0,2)}</h6>
              <div className='ml-2'>
              <h6 className='text-[14px] mb-0 font-[900]'>{data?.client?.contact_name}</h6>
              <h6 className='text-[9.5px] -mt-0.5 font-[400] '>{data?.client?.company_name}</h6>
              </div>
            </div>

            <div className='border-b mb-2 pb-2'>

              <span className='text-[11px] flex items-center text-gray-800 mt-3 ml-1 '>Name : <span className='font-[600] ml-1'>   {data?.client?.contact_name}</span></span>
              <span className='text-[11px] flex items-center text-gray-800 mt-0.5 ml-1 '>Email : <span className='font-[600] ml-1'>   {data?.client?.email}</span></span>
              <span className='text-[11px] flex items-center text-gray-800 mt-0.5 ml-1 '>Phone : <span className='font-[600] ml-1'>  {data?.client?.phone}</span></span>
              <span className='text-[11px] flex items-center text-gray-800 mt-0.5 ml-1 '>Company Name : <span className='font-[600] ml-1'>  {data?.client?.company_name}</span></span>
             {data?.client?.address !== '' && <span className='text-[11px] flex items-center text-gray-800 mt-0.5 ml-1 '>Company Address : <span className='font-[600] ml-1'>  {data?.client?.address}</span></span>}
             </div>

            <h6 className='font-[600] text-[14px]  p-1'>Invoice Owner</h6>

            <span className='text-[11px] flex items-center text-gray-800 mt-1 ml-1 '>Name : <span className='font-[600] ml-1'>   {data?.employee?.name} [{data?.employee?.employee_id}]</span></span>
            <span className='text-[11px] flex items-center text-gray-800 mt-0.5 mb-2 ml-1 border-b pb-2'>Department : <span className='font-[600] ml-1'>   {data?.employee?.department_id[0]?.department_name}</span></span>

            <h6 className='font-[600] text-[14px] p-1'>Invoice Detail</h6>

            <span className='text-[11px] flex items-center text-gray-800 ml-1 '>To : <span className='font-[600] ml-1'>   {data?.client?.land_lord ? 'Land Lord' : 'Client'} </span></span>
            <span className='text-[11px] flex items-center text-gray-800 ml-1 '>Amount : <span className='font-[600] ml-1'>   {rupee?.format(data?.amount)} </span> </span>
            <span className='text-[11px] flex items-center text-gray-800 ml-1 '>Invocie Amount Incl GST : <span className='font-[600] ml-1'>   {rupee?.format(!['',null,undefined,'null','undefined']?.includes(data?.invoice_amount_with_gst) ? data?.invoice_amount_with_gst : 0 )} </span></span>
            <span className='text-[11px] flex items-center text-gray-800 ml-1 '>Invocie Amount Excl Gst : <span className='font-[600] ml-1'>   {rupee?.format(!['',null,undefined,'null','undefined']?.includes(data?.invoice_amount_without_gst) ? data?.invoice_amount_without_gst : 0)} </span></span>
            <span className='text-[11px] flex items-center text-gray-800 ml-1 '>Raise Date : <span className='font-[600] ml-1'>   {moment(data?.invoice_raising_date).format('LL')} </span></span>
            {(data?.gst_no !== null && data?.gst_no !== undefined) &&
            <span className='text-[11px] flex items-center text-gray-800 ml-1 '>Gst No  : <span className='font-[600] ml-1'>   {data?.gst_no} </span></span>}
            {(data?.pan_no !== null && data?.pan_no !== undefined) &&
            <span className='text-[11px] flex items-center text-gray-800 ml-1 '>Pan No  : <span className='font-[600] ml-1'>   {data?.pan_no} </span></span>}
            {(data?.office_space_closed !== null && data?.office_space_closed !== undefined) &&
            <h6 className='text-[11px]  items-center text-gray-800 ml-1 '>Office Space Closed  : <span className='font-[600] ml-1'>   {data?.office_space_closed} </span></h6>}
            <h6 className='text-[11px] bg-slate-100 p-2  text-gray-800 mt-1 '>Description : <span className='font-[600] ml-1'>   {data?.other_details} </span></h6>
             

            </div>
            
           


            
            
           
      </div>

      <div className={`px-4 w-[75%] overflow-y-scroll ${data?.invoice_request?.length === 0 && 'border-r'} relative min-h-screen max-h-screen `}>
       {data?.invoice_request?.length  === 0 && 
        <div className='w-60'>
         <h1 className='text-[14px] font-[700] mt-11 mb-2.5'>Create Invoice</h1>
         <h6 className='bg-slate-100 p-1 text-[11px]'>Use the below form to create the invoice for the request reaised by the employee</h6>
         <h6 className='text-[11px] font-[600] mb-1 mt-1' >{'Invoice File'}</h6>
                    <Uploader image={raiseData?.file}  setimagefunc={(e)=>{setraiseData({...raiseData,file:e});setraiseDataErr({...raiseDataErr,file:''})}}  removeimageuploadfunc = {()=>setraiseData({...raiseData,file:''})}/>
                    {raiseDataErr.file !== '' && raiseDataErr.file !== undefined && raiseDataErr.file !== null &&
                    <div className='flex items-center mt-1'>
                    <BiErrorCircle className='text-red-500' size={14} />
                    <span className='text-[10px] text-red-500 ml-1'>{raiseDataErr.file}</span>
                    </div>}

                    <div className='mb-2'>
                        <TextInput
                            mandatory={true}
                            label={'Invoice No'}  
                            variant="standard"
                            name="invoice_no"
                            type="text"
                            value={raiseData.invoice_no}
                            error={raiseDataErr.invoice_no}
                            handlechange={(e)=>{setraiseData({...raiseData,invoice_no:e.target.value});setraiseDataErr({...raiseDataErr,invoice_no:''})}}/>
                    </div>

                    <div className='mb-2'>
                        <TextInput
                            mandatory={true}
                            label={'Invoice Amount Included GST*'}  
                            variant="standard"
                            name="invoice_amount_with_gst"
                            type="text"
                            value={raiseData.invoice_amount_with_gst}
                            error={raiseDataErr.invoice_amount_with_gst}
                            handlechange={(e)=>{setraiseData({...raiseData,invoice_amount_with_gst:e.target.value});setraiseDataErr({...raiseDataErr,invoice_no:''})}}/>
                    </div>


                    <div className='mb-2'>
                        <TextInput
                            mandatory={true}
                            label={'Invoice Amount Excluded GST*'}  
                            variant="standard"
                            name="invoice_amount_without_gst"
                            type="text"
                            value={raiseData.invoice_amount_without_gst}
                            error={raiseDataErr.invoice_amount_without_gst}
                            handlechange={(e)=>{setraiseData({...raiseData,invoice_amount_without_gst:e.target.value});setraiseDataErr({...raiseDataErr,invoice_no:''})}}/>
                    </div>


                    <div style={{width:'100%',}}>
                        <h6 className='text-[11px] font-[600] mb-1 mt-1' >{'Invoice Raise Date'}</h6>
                        <div className='border border-slate-300  border-l-4 border-l-slate-700'>
                        <DatePicker bordered={false} placeholder='' ampm={false} style={{maxHeight:'40px',borderRadius:'3px',width:'100%',fontSize:'13px',marginLeft:-5}} inputFormat="MM/dd/yyyy" value={raiseData?.date} onChange={(v) => {setraiseData({...raiseData,date:v});setraiseDataErr({...raiseDataErr,date:''})}} />
                        </div>
                        {/* {(invoiceDataErr?.invoice_raising_date !== '' )  && <h6 className='text-red-600 text-[10px]'>{invoiceDataErr?.invoice_raising_date}</h6>} */}
                    </div>

                    <div style={{width:'100%',}}>
                        <h6 className='text-[11px] font-[600] mb-1 mt-1' >{'Description'}</h6>
                        <TextArea value={raiseData?.description} className='rounded-sm' onChange={(e)=>{setraiseData({...raiseData,description:e.target.value});setraiseDataErr({...raiseDataErr,description:''})}} />
                        {/* {(invoiceDataErr?.invoice_raising_date !== '' )  && <h6 className='text-red-600 text-[10px]'>{invoiceDataErr?.invoice_raising_date}</h6>} */}
                    </div>


                    <div className='mt-4 flex justify-end'>
                    <ButtonFilledAutoWidth btnName="Save" onClick={raiseInvoiceData} />
                    </div>
        </div>}
        {data?.invoice_request?.length > 0 &&
         <>
         <h1 className='text-[13px] font-[700] mb-2 mt-2 border-b pb-3'>Payment History ({data?.invoicepayments?.length})</h1>
         
         <div className='grid grid-cols-3 gap-1'>
         {data?.invoicepayments?.map((d,i)=>(
                <div key={i} className={` border relative py-2 `}>
                    {!d?.is_deleted && <>
                    <AiOutlineDelete size={14} className='cursor-pointer absolute right-8 bg-white' onClick={()=>{setpaymentDataSelected(d);setpaymentStatusMode('1')}} />
                    <AiOutlineEdit size={14} className='cursor-pointer absolute right-2 bg-white' onClick={()=>{setpaymentDataSelected(d);setaddPayment({amount_with_gst:d?.amount_with_gst,amount_with_gst_incl_tds_value:d?.amount_with_gst_incl_tds_value,amount_without_gst:d?.amount_without_gst,finance_approved:d?.finance_approved,recieved_date:d?.recieved_date?.slice(0,10),payment:d?.payment,tds_deduction:d?.tds_deduction === undefined ? 0 :d?.tds_deduction});setpaymentStatusMode('2')}} />
                    </>}
                      <h6 className='text-[10px] ml-1 mt-1'>Amount With GST : <span className='font-[800]'>{rupee?.format(d?.amount_with_gst)?.split('.')[0]}</span></h6>
                        <h6 className='text-[10px] ml-1'>Amount Without GST : <span className='font-[800]'>{rupee?.format(d?.amount_without_gst)?.split('.')[0]}</span></h6>
                        <h6 className='text-[10px] ml-1'>TDS Deduction : <span className='font-[800]'>{d?.tds_deduction !== undefined ? `${d?.tds_deduction}%`  : `0%`}</span></h6>
                        <h6 className='text-[10px] ml-1'>Amount Recieved Date : <span className='font-[500]'>{moment(d?.recieved_date).format('LL')}</span></h6>
                        <h6 className='text-[10px] ml-1'>Payment Mode : <span className='font-[500]'>{d?.payment == '0' ? 'Online' : 'Cash'}</span></h6>
                        <h6 className='text-[10px] ml-1'>Created On : <span className='font-[800]'>{moment(d?.createdAt).format('LLL')}</span></h6>
                        {!d.is_deleted && <h6 className={`text-[10px] ml-1 font-[800] mt-1 ${d?.finance_approved ? 'bg-slate-100' : 'bg-slate-100'} p-1`}>Approval Status : {d?.finance_approved ? 'Finance Approved' : 'Waiting For Your Approval'}</h6>}
                        {d.is_deleted && <h6 className={`text-[10px] ml-1 font-[800] bg-red-100 font-[400] mt-1  p-1`}>Deleted Reason : {d?.deleted_reason}</h6>}
                </div>))} 
          </div>
         </>} 
      </div>
      </div>


        <div className='flex w-full'>
        <div className='pt-2 pr-5 w-full'>
          <GoBack />
            <h6 className='font-black text-[14px] border-b pb-2 w-full'>{path === 'delete' ?  'Payment Info'  : 'Invoice Request Detail'}</h6>
            
            <div className='mt-2 flex'>
                <div className='w-72 pr-4 border-r'>
                    <h6 className='text-[13px] font-[600] mb-2 '>Client Info: </h6>
                    <div className='bg-gray-100 p-2'>
                    <h6 className='text-[12px] font-[500]'>{data?.client?.contact_name} / {data?.client?.phone}</h6>
                    <h6 className='text-[12px] font-[500]'>{data?.client?.email}</h6>
                    <h6 className='text-[12px] font-[500]'>Company Name : {data?.client?.company_name}</h6>
                    <h6 className='text-[12px] font-[500]'>Address : {data?.client?.address}</h6>
                    </div>
                   
                   {path !== 'delete' &&
                   <>
                    <h6 className='text-[12px] font-[500]  mt-2'>Requirment : {data?.client?.requirment}</h6>
                    {(data?.sqft || data?.seater || data?.rent || data?.sale) && 
                    <h6 className='text-[12px] font-[500]'>{data?.sqft && 'SQFT'} {data?.seater && 'SEATER'} , {data?.rent && 'RENT'} {data?.sale && 'SALE'} </h6>}


                    <h6 className='text-[13px] font-[600] mt-4 '>Invoice Info: </h6>
                    <h6 className='text-[12px] font-[500]'>Amount : {rupee.format(data?.amount)}</h6>
                    {(data?.other_details !== '' && data?.other_details !== undefined) && <h6 className='text-[12px] font-[500]'>Other Details : {data?.other_details}</h6>}


                    <h6 className='text-[13px] font-[600] mt-4 '>Payment Info: </h6>
                    <h6 className='text-[12.5px] font-[600]'>Payment Mode : <span>{data?.payment === '0' ? 'Online' : 'Cash'}</span></h6>
                    {!data?.payment_recieved  ? <h6 className='text-[12px] font-[500]'>Amount Not recieved yet from the client</h6> :
                    <h6 className='text-[12px] font-[500]'> Final Payment Recieved On  : <span className='font-[700]'> {moment(data?.amount_recieved_date)?.format('LL')} </span></h6>}
                    </>
                   }

                   {path === 'delete' &&
                   <>
                   {data?.client?.requirment !== '' &&  <h6 className='text-[12px] font-[500]  mt-2'>Requirment : {data?.client?.requirment}</h6>}
                    {(data?.client?.sqft || data?.client?.seater || data?.client?.rent || data?.client?.sale) && 
                    <h6 className='text-[12px] font-[500]'>{data?.client?.sqft && 'SQFT'} {data?.client?.seater && 'SEATER'} , {data?.client?.rent && 'RENT'} {data?.client?.sale && 'SALE'} </h6>}


                    <h6 className='text-[13px] font-[600] mt-4 '>Payment Info: </h6>
                    <h6 className='text-[13px] font-[400]  '>Invoice No: <span className='font-[700]'>{data?.invoice?.invoice_no}</span> </h6>
                    <h6 className='text-[13px] font-[400]  '>Invoice Owner: <span className='font-[700]'>{data?.invoice?.employee?.name}</span> </h6>
                    <h6 className='text-[12px] font-[500]'>Amount : {rupee.format(data?.amount_with_gst)}</h6>
                    <h6 className='text-[12px] font-[500]'>Recieved Date : <span className='font-[700]'>{moment(data?.recieved_date).format('ll')}</span></h6>

                    {/* <h6 className='text-[13px] font-[600] mt-4 '>iNVOICE Info: </h6> */}

                  
                    </>
                   }
                    
                    {/* {data?.invoicepayments?.map((d)=>(
                    <div className='my-3 border-l-4 border-l-gray-500  p-1 pl-2'>
                        <h6 className='text-[11.5px]'>Amount With GST : <span className='font-[800]'> {d?.amount_with_gst} </span></h6>
                        <h6 className='text-[11.5px]'>Amount WithOut GST :<span className='font-[800]'>  {d?.amount_without_gst}</span></h6>
                        <h6 className='text-[11.5px]'>Amount Recived On :<span className='font-[800]'>  {moment(d?.recieved_date).format('ll')}</span></h6>
                        <div className='flex items-center mt-1 cursor-pointer'>
                        <AiOutlineEdit className='' />
                        <AiOutlineDelete className='ml-5' />
                        </div>
                    </div>
                    ))} */}
                </div>

                {path === 'delete' &&
                    <div className='ml-4  w-[58%] border-r pr-4'>
                    <h6 className='text-[14px] font-[600] mb-2'>Deleted Reason </h6>
                    <h6 className='text-[13px] font-[400] mb-2'>{data?.deleted_reason}</h6>

                    <h6  className='cursor-pointer text-[12px] font-[600] text-cyan-600 hover:underline' onClick={()=>setrestorePaymentModal(true)}>Restore Payment</h6>
                    </div> 
                }

                {path !== 'delete' && data?.invoicepayments?.length > 0 &&
                <div className='ml-4 w-60 border-r pr-4'>
                <h6 className='text-[13px] font-[600] mb-2 '>Payment History ({data?.invoicepayments?.length}): </h6>
                {data?.invoicepayments?.map((d,i)=>(
                <div key={i} className={`border-b relative py-2 ${i === 0 && 'border-t'}`}>
                    {!d?.is_deleted && <>
                    <AiOutlineDelete size={16} className='cursor-pointer absolute right-6 bg-white' onClick={()=>{setpaymentDataSelected(d);setpaymentStatusMode('1')}} />
                    <AiOutlineEdit size={18} className='cursor-pointer absolute right-0 bg-white' onClick={()=>{setpaymentDataSelected(d);setaddPayment({amount_with_gst:d?.amount_with_gst,amount_without_gst:d?.amount_without_gst,finance_approved:d?.finance_approved,recieved_date:d?.recieved_date?.slice(0,10),tds_deduction:d?.tds_deduction === undefined ? 0 :d?.tds_deduction});setpaymentStatusMode('2')}} />
                    </>}
                      <h6 className='text-[10px] ml-1 mt-1'>Amount With GST : <span className='font-[800]'>{d?.amount_with_gst}</span></h6>
                        <h6 className='text-[10px] ml-1'>Amount Without GST : <span className='font-[500]'>{d?.amount_without_gst}</span></h6>
                        <h6 className='text-[10px] ml-1'>Amount Recieved Date : <span className='font-[500]'>{moment(d?.recieved_date).format('LLL')}</span></h6>
                        <h6 className='text-[10px] ml-1'>Created On : <span className='font-[800]'>{moment(d?.createdAt).format('LLL')}</span></h6>
                        {!d.is_deleted && <h6 className={`text-[10px] ml-1 font-[800] mt-1 ${d?.finance_approved ? 'bg-green-100' : 'bg-blue-100'} p-1`}>Approval Status : {d?.finance_approved ? 'Finance Approved' : 'Waiting For Your Approval'}</h6>}
                        {d.is_deleted && <h6 className={`text-[10px] ml-1 font-[800] bg-red-100 font-[400] mt-1  p-1`}>Deleted Reason : {d?.deleted_reason}</h6>}
                </div>))} 
                </div>}

                {data?.invoice_request?.length > 0 &&
                <div className='ml-4 w-60 border-r pr-4'>
                <h6 className='text-[13px] font-[600] mb-2 '>Invoice History ({data?.invoice_request?.length}): </h6>
                    {/* <Uploader image={file} /> */}
 
                    {data?.invoice_request?.map((d1,i)=>(
                    <div className={`border-b relative py-2 ${i === 0 && 'border-t'}`}>
                       
                       {(d1?.file !== '' && d1?.file !== undefined && d1?.file !== null) &&
                        <div className='flex '>
                          <img src="https://cdn-icons-png.flaticon.com/512/179/179483.png" className='w-6 h-6 object-contain z-0' />
                          <h6 className='text-[9px] ml-1 w-[170px]  overflow-hidden line-clamp-2'>{d1?.file?.split('/')[d1?.file?.split('/')?.length - 1]}</h6>
                          <BiDotsVerticalRounded size={16} className='cursor-pointer absolute right-6 bg-white' onClick={()=>{setselectedData(d1)}} />
                          <AiOutlineDownload size={18} className='cursor-pointer absolute right-0 bg-white' onClick={()=>handleDownload(`${process.env.REACT_APP_BACKEND_IMAGE_URL}${d1?.file}`,d1?.file?.split('/')[d1?.file?.split('/')?.length - 1])} />
                        </div>}  
                        {data?.invoice_revise &&
                        <h6 className='text-[10px] ml-1 mt-1 bg-red-100 p-1'>Invoice Revise Request : <span className='font-[700] text-[11px]'>{data?.invoice_revise_summary}</span><span className='text-red-600 cursor-pointer font-[600] underline' 
                            onClick={()=>{
                            setreviseInvoiceBool(true)
                            setraiseData({...raiseData,
                              invoice_no:d1?.invoice_no,
                            // file:d1?.file,
                              invoice_amount_with_gst:data?.invoice_amount_with_gst,
                              invoice_amount_without_gst:data?.invoice_amount_without_gst,
                              description:data?.description,
                              date:moment(data?.invoice_raising_date?.slice(0,10))});setreviseInvoice(d1)}}> Revise Invoice</span></h6>
                          }
                        <h6 className='text-[10px] ml-1 mt-1'>Invoice No : <span className='font-[800]'>{d1?.invoice_no}</span></h6>
                        <h6 className='text-[10px] ml-1'>Description : <span className='font-[500]'>{d1?.description}</span></h6>
                        <h6 className='text-[10px] ml-1'>Created On : <span className='font-[800]'>{moment(d1?.createdAt).format('LLL')}</span></h6>
                        {d1?.final && <BsFillBookmarkCheckFill className='absolute top-0 left-0 z-100' />}
                    </div>))}
                </div>}
 
                {!data?.complete_payment_recieved  && data?.invoice_request?.length === 0 &&
                <div className='ml-4 w-56'>
                <h6 className='text-[13px] font-[600] mb-2 '>Create Invoice: </h6>
                     
                    <h6 className='text-[11px] font-[600] mb-1 mt-1' >{'Invoice File'}</h6>
                    {data?.payment === '1' &&
                    <Uploader image={raiseData?.file}  setimagefunc={(e)=>{setraiseData({...raiseData,file:e});setraiseDataErr({...raiseDataErr,file:''})}}  removeimageuploadfunc = {()=>setraiseData({...raiseData,file:''})}/>
                    }
                    {raiseDataErr.file !== '' && raiseDataErr.file !== undefined && raiseDataErr.file !== null &&
                    <div className='flex items-center mt-1'>
                    <BiErrorCircle className='text-red-500' size={14} />
                    <span className='text-[10px] text-red-500 ml-1'>{raiseDataErr.file}</span>
                    </div>}

                    <div className='mb-2'>
                        <TextInput
                            mandatory={true}
                            label={'Invoice No'}  
                            variant="standard"
                            name="invoice_no"
                            type="text"
                            value={raiseData.invoice_no}
                            error={raiseDataErr.invoice_no}
                            handlechange={(e)=>{setraiseData({...raiseData,invoice_no:e.target.value});setraiseDataErr({...raiseDataErr,invoice_no:''})}}/>
                    </div>

                    <div className='mb-2'>
                        <TextInput
                            mandatory={true}
                            label={'Invoice Amount Included GST*'}  
                            variant="standard"
                            name="invoice_amount_with_gst"
                            type="text"
                            value={raiseData.invoice_amount_with_gst}
                            error={raiseDataErr.invoice_amount_with_gst}
                            handlechange={(e)=>{setraiseData({...raiseData,invoice_amount_with_gst:e.target.value});setraiseDataErr({...raiseDataErr,invoice_no:''})}}/>
                    </div>


                    <div className='mb-2'>
                        <TextInput
                            mandatory={true}
                            label={'Invoice Amount Excluded GST*'}  
                            variant="standard"
                            name="invoice_amount_without_gst"
                            type="text"
                            value={raiseData.invoice_amount_without_gst}
                            error={raiseDataErr.invoice_amount_without_gst}
                            handlechange={(e)=>{setraiseData({...raiseData,invoice_amount_without_gst:e.target.value});setraiseDataErr({...raiseDataErr,invoice_no:''})}}/>
                    </div>


                    <div style={{width:'100%',}}>
                        <h6 className='text-[11px] font-[600] mb-1 mt-1' >{'Invoice Raise Date'}</h6>
                        <DatePicker placeholder='' ampm={false} style={{maxHeight:'40px',borderRadius:'3px',width:'100%'}} className='border-l-4 border-l-[#158a93]' inputFormat="MM/dd/yyyy" value={raiseData?.date} onChange={(v) => {setraiseData({...raiseData,date:v});setraiseDataErr({...raiseDataErr,date:''})}} />
                        {/* {(invoiceDataErr?.invoice_raising_date !== '' )  && <h6 className='text-red-600 text-[10px]'>{invoiceDataErr?.invoice_raising_date}</h6>} */}
                    </div>

                    <div style={{width:'100%',}}>
                        <h6 className='text-[11px] font-[600] mb-1 mt-1' >{'Description'}</h6>
                        <TextArea value={raiseData?.description} className='rounded-sm' onChange={(e)=>{setraiseData({...raiseData,description:e.target.value});setraiseDataErr({...raiseDataErr,description:''})}} />
                        {/* {(invoiceDataErr?.invoice_raising_date !== '' )  && <h6 className='text-red-600 text-[10px]'>{invoiceDataErr?.invoice_raising_date}</h6>} */}
                    </div>


                    <div className='mt-4 flex justify-end'>
                    <ButtonFilledAutoWidth btnName="Save" onClick={raiseInvoiceData} />
                    </div>


                </div>}

                {!data?.complete_payment_recieved  && reviseInvoiceBool &&
                <div className='ml-4 w-56'>
                <h6 className='text-[13px] font-[600] mb-2 '>Revise Invoice: </h6>
                     
                    <h6 className='text-[11px] font-[600] mb-1 mt-1' >{'Invoice File'}</h6>
                    <Uploader image={raiseData?.file}  setimagefunc={(e)=>{setraiseData({...raiseData,file:e});setraiseDataErr({...raiseDataErr,file:''})}}  removeimageuploadfunc = {()=>setraiseData({...raiseData,file:''})}/>
                      

                    <div className='mb-2'>
                        <TextInput
                            mandatory={true}
                            label={'Invoice No'}  
                            variant="standard"
                            name="invoice_no"
                            type="text"
                            value={raiseData.invoice_no}
                            error={raiseDataErr.invoice_no}
                            handlechange={(e)=>{setraiseData({...raiseData,invoice_no:e.target.value});setraiseDataErr({...raiseDataErr,invoice_no:''})}}/>
                    </div>

                    <div className='mb-2'>
                        <TextInput
                            mandatory={true}
                            label={'Invoice Amount Included GST*'}  
                            variant="standard"
                            name="invoice_amount_with_gst"
                            type="text"
                            value={raiseData.invoice_amount_with_gst}
                            error={raiseDataErr.invoice_amount_with_gst}
                            handlechange={(e)=>{setraiseData({...raiseData,invoice_amount_with_gst:e.target.value});setraiseDataErr({...raiseDataErr,invoice_no:''})}}/>
                    </div>


                    <div className='mb-2'>
                        <TextInput
                            mandatory={true}
                            label={'Invoice Amount Excluded GST*'}  
                            variant="standard"
                            name="invoice_amount_without_gst"
                            type="text"
                            value={raiseData.invoice_amount_without_gst}
                            error={raiseDataErr.invoice_amount_without_gst}
                            handlechange={(e)=>{setraiseData({...raiseData,invoice_amount_without_gst:e.target.value});setraiseDataErr({...raiseDataErr,invoice_no:''})}}/>
                    </div>


                    <div style={{width:'100%',}}>
                        <h6 className='text-[11px] font-[600] mb-1 mt-1' >{'Invoice Raise Date'}</h6>
                        <DatePicker placeholder='' ampm={false} style={{maxHeight:'40px',borderRadius:'3px',width:'100%'}} className='border-l-4 border-l-[#158a93]' inputFormat="MM/dd/yyyy" value={raiseData?.date} onChange={(v) => {setraiseData({...raiseData,date:v});setraiseDataErr({...raiseDataErr,date:''})}} />
                        {/* {(invoiceDataErr?.invoice_raising_date !== '' )  && <h6 className='text-red-600 text-[10px]'>{invoiceDataErr?.invoice_raising_date}</h6>} */}
                    </div>

                    <div style={{width:'100%',}}>
                        <h6 className='text-[11px] font-[600] mb-1 mt-1' >{'Description'}</h6>
                        <TextArea value={raiseData?.description} className='rounded-sm' onChange={(e)=>{setraiseData({...raiseData,description:e.target.value});setraiseDataErr({...raiseDataErr,description:''})}} />
                        {/* {(invoiceDataErr?.invoice_raising_date !== '' )  && <h6 className='text-red-600 text-[10px]'>{invoiceDataErr?.invoice_raising_date}</h6>} */}
                    </div>


                    <div className='mt-4 flex justify-end'>
                    <ButtonFilledAutoWidth btnName="Save" onClick={raiseInvoiceData} />
                    </div>


                </div>}
            </div>


        </div>
          {selectedData !== null &&
            <div className='border-l   px-4 -mt-5 h-screen pt-12  w-[500px] relative'>
              <h1 className='text-[14px] mt-2 pb-2 font-[600] border-b'>Invoice Detail </h1>
              <IoClose className='absolute right-5 top-8 cursor-pointer' onClick={()=>setselectedData(null)} />
              <div className={` relative py-2 border-t}`}>

                        <div className='flex '>
                          <img src="https://cdn-icons-png.flaticon.com/512/179/179483.png" className='w-6 h-6 object-contain z-0' />
                          <h6 className='text-[10px] ml-1   overflow-hidden line-clamp-2'>{selectedData?.file?.split('/')[selectedData?.file?.split('/')?.length - 1]}</h6>
                        </div>  
                        <h6 className='text-[11px] ml-1 mt-1'>Invoice No : <span className='font-[800]'>{selectedData?.invoice_no}</span></h6>
                        <h6 className='text-[11px] ml-1'>Description : <span className='font-[500]'>{selectedData?.description}</span></h6>
                        <h6 className='text-[11px] ml-1'>Created On : <span className='font-[800]'>{moment(selectedData?.createdAt).format('LLL')}</span></h6>
                        {selectedData?.final && <BsFillBookmarkCheckFill className='absolute top-0 left-0 z-100' />}
                        
                        
                          
                          <div className='flex items-center'>
                          <div className='flex items-center cursor-pointer mt-2'>
                            {addPayment?.payment_recieved ? <BiCheckboxSquare size={20} className='text-cyan-600' onClick={()=>setaddPayment({...addPayment,payment_recieved:!addPayment.payment_recieved})} />  : <BiCheckbox size={20} className='text-gray-200' onClick={()=>setaddPayment({...addPayment,payment_recieved:!addPayment.payment_recieved})} />}
                            <h6 className='ml-1 text-[11px] font-[500]'>Payment Recieved</h6>
                          </div>

                          <div className='flex items-center cursor-pointer mt-2 ml-4'>
                            {addPayment?.complete_payment_recieved ? <BiCheckboxSquare size={20} className='text-cyan-600' onClick={()=>setaddPayment({...addPayment,complete_payment_recieved:!addPayment.complete_payment_recieved})} />  : <BiCheckbox size={20} className='text-gray-200' onClick={()=>setaddPayment({...addPayment,complete_payment_recieved:!addPayment.complete_payment_recieved})} />}
                            <h6 className='ml-1 text-[11px] font-[500]'>Complete Payment Recieved</h6>
                          </div>
                        </div>  

                        <>
                       
                        {(addPayment?.payment_recieved || addPayment?.complete_payment_recieved) &&
                        <>
                        <div className='mt-1 ml-1.5'>
                            <TextInput 
                              mandatory={true}
                              label={'Amount With GST* '}  
                              variant="standard"
                              name="amount_with_gst"
                              type="text"
                              value={addPayment?.amount_with_gst}
                              error={addPaymentErr?.amount_with_gst}
                              handlechange={(e)=>{setaddPayment({...addPayment,amount_with_gst:e.target.value});setaddPaymentErr({...addPaymentErr,amount_with_gst:''})}} />
                        </div>

                        <div className='mt-1 ml-1.5'>
                            <TextInput 
                              mandatory={true}
                              label={'Amount Without Gst* '}  
                              variant="standard"
                              name="amount_without_gst"
                              type="text"
                              value={addPayment?.amount_without_gst}
                              error={addPaymentErr?.amount_without_gst}
                              handlechange={(e)=>{setaddPayment({...addPayment,amount_without_gst:e.target.value});setaddPaymentErr({...addPaymentErr,amount_without_gst:''})}} />
                        </div>

                        <div>
                        <h6 className='text-[11px] font-[600] ml-1.5 mb-1 mt-1' >{'Payment Recieved Date'}</h6>
                        <input type='date' value={addPayment?.recieved_date?.slice(0,10)} className='border w-[98%] p-1 text-[14px] ml-1.5  border-l-4 border-l-slate-600' onChange={(e)=>{setaddPayment({...addPayment,recieved_date:e?.target.value})}} />
                        {/* <DatePicker placeholder='' style={{maxHeight:'40px',borderRadius:'3px',width:'100%'}} className='border-l-4 ml-1.5  border-l-[#158a93]' inputFormat="MM/dd/yyyy" value={(addPayment?.recieved_date === '' || addPayment?.recieved_date === null) ? null : moment(addPayment?.recieved_date)} onChange={(v) => {setaddPayment({...addPayment,recieved_date:v})}} /> */}
                       </div>

                        <div className='mt-4 flex ml-2'>
                          <ButtonFilledAutoWidth btnName="Save Payment" onClick={()=>updatepayment_Status('2')} />
                        </div>
                        </>}
                        </>
                        {/* } */}


                    </div>
            </div>}
        </div> 
        </div>  
        </div> 


    </div>
  )
}

export default InvoiceRequestDetail