import React, { useState } from 'react'
import SettingsMenu from './SettingsMenu'
import { ButtonOutlinedAutoWidth } from '../../components/button'
import { DownloadDataCountService, DownloadDataService } from '../../services/AdminServicesfile/CompanyLeadServices'
import fileDownload from "js-file-download";
import axios from 'axios';
import { toast } from 'react-hot-toast';
import {BiCheckbox,BiCheckboxSquare} from 'react-icons/bi'

function DownloadReport() {

    const [type,settype] =  useState(1)

    async function downloadDataReport(){
       if(type === 1){ 
        try{
            const response = await DownloadDataService()
            if(response.status === 200){
                let path = response.data.path.replace('public/','')
                handleDownload(`${process.env.REACT_APP_BACKEND_IMAGE_URL}${path}`,path.split('/')[path.split('/')?.length - 1])
            }
        }catch(e){

        }
       }else{
        try{
            const response = await DownloadDataCountService()
            if(response.status === 200){
                let path = response.data.path.replace('public/','')
                handleDownload(`${process.env.REACT_APP_BACKEND_IMAGE_URL}${path}`,path.split('/')[path.split('/')?.length - 1])
            }
        }catch(e){

        }
       }
    }

    const handleDownload = (url, filename) => {
        // console.log('url',url,'filename',filename)

        axios.get(url, {
            responseType: 'blob',
        })
            .then((res) => {
                fileDownload(res.data, filename)
                toast.success("Employee Excel Downloaded") 
            })
    }

  return (
    <div className='min-h-screen max-h-screen overflow-hidden'>
        <div className='flex'>
        <SettingsMenu />
        <div className='mx-3 mt-5 w-full'>
            <div>
            <h6 className='font-[800] text-[16px] border-b  pb-2'>Download Report </h6>    
            <h6 className='text-[11.5px] leading-tight w-72 mt-2'>Hello welcome to the report download section of the crm application Report Download</h6>

            <div className='-ml-1 mb-3 mt-2 flex items-center'>
              <div className='flex items-center cursor-pointer mr-2' onClick={()=>settype(1)}>
              {type !== 1 ? <BiCheckbox size={18} className='text-slate-300' /> :  <BiCheckboxSquare size={18} className='text-slate-800' />}
              <h6 className='ml-1 text-[12px] font-[600]'>Data</h6>
              </div>  
              <div className='flex items-center cursor-pointer ml-2' onClick={()=>settype(2)}>
              {type !== 2 ? <BiCheckbox size={18} className='text-slate-300' /> :  <BiCheckboxSquare size={18} className='text-slate-800' />}

              {/* <BiCheckbox size={18} className='text-slate-300' /> */}
              <h6 className='ml-1 text-[12px] font-[600]'>Count</h6>
              </div>  
            </div> 

            <ButtonOutlinedAutoWidth btnName="Download Report" onClick={downloadDataReport} />
            </div>
        </div>
        </div>
    </div>
  )
}

export default DownloadReport