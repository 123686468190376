import { get, post } from "../helpers/apihelpers";


export const GetHomeData = async()=>{
    try {
        const result = await get(`api/analytics/home_data`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const AdminHomeData = async()=>{
    try {
        const result = await get(`api/analytics/admin_home_data`);
        return result;
    } catch (err) {
        return err.response;
    }
}


export const GetStickyNotes = async(id)=>{
    try {
        const result = await get(`api/activity/sticky-note`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const CreateUpdateStickyNotes = async(data)=>{
    try {
        const result = await post(`api/activity/create-update-note`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}