import React,{useEffect, useState} from 'react';
import { ButtonFilledAutoWidth } from '../../../components/button';
import { TextInput } from '../../../components/input';
import { useNavigate,useLocation } from 'react-router-dom';
import { CreateCountryService, CreateDistrictService, CreateStateService, CreateTalukService, GetCountry, GetDistrict, GetState, UpdateCountryService, UpdateDistrictService, UpdateStateService, UpdateTalukService } from '../../../services/CSDTServices';
import { toast } from 'react-hot-toast';
// import Select from 'react-select'
import { Select } from 'antd';
import {BiErrorCircle} from 'react-icons/bi'
import SettingsMenu from '../../staticscreens/SettingsMenu';
import GoBack from '../../../components/back/GoBack';

function CSDTCE() {

  const [data,setdata] = useState({country_name:'',country_id:'',state_name:'',state_id:'',district_name:'',district_id:'',branch_name:'',display_name:''});  
  const [error,seterror] = useState({country_name:'',country_id:'',state_name:'',state_id:'',district_name:'',district_id:'',branch_name:'',display_name:''});  

  const [countryArr,setcountryArr] = useState([])
  const [stateArr,setstateArr] = useState([])
  const [districtArr,setdistrictArr] = useState([])

  const {state} = useLocation();
  const navigate = useNavigate();


  function handlechange(e){
    setdata({...data,[e.target.name] : e.target.value})
    seterror({...error,[e.target.name]:''})
  }

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border:'1px solid rgb(227 231 234)',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      padding: '0px 6px',
      fontSize:'13px',
      fontWeight:600,
      marginBottom:'0px',
      paddingBottom:'0px'
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
    }),
  };

  useEffect(()=>{
    setStatedata()
    dropdown()
  },[])

  async function handleSelect(e,e1){
     if(e1 === 'Country'){
      setdata({...data,country_id:countryArr?.find((v)=>v?.value === e)})
      seterror({...error,country_id:''})
     }else  if(e1 === 'State'){
      setdata({...data,state_id:stateArr?.find((v)=>v?.value === e)})
      seterror({...error,state_id:''})
     }else  if(e1 === 'District'){
      setdata({...data,district_id:districtArr?.find((v)=>v?.value === e)})
      seterror({...error,district_id:''})
     }
  }

  function setStatedata(){
    if(state?.data?._id !== null && state?.data?._id !== undefined){
      if(state['type'] === 'Country'){
        setdata({...data,country_name:state.data.country_name,display_name:state.data.display_name})
      }else if(state['type'] === 'State'){
        setdata({...data,state_name:state.data.state_name,display_name:state.data.display_name,country_id:{label:state.data.country_id.country_name,value:state.data.country_id._id}})
      }else if(state['type'] === 'District'){
        setdata({...data,district_name:state.data.district_name,display_name:state.data.display_name,state_id:{label:state.data.state_id.state_name,value:state.data.state_id._id}})
      }else if(state['type'] === 'Taluk'){
        setdata({...data,branch_name:state.data.branch_name,display_name:state.data.display_name,district_id:{label:state.data.district_id.district_name,value:state.data.district_id._id}})
      }
    }
  }

  async function dropdown(){
    if(state['type'] === 'State'){
      const response = await GetCountry()
      let arr = []
      response.data.data.datas.forEach((d)=>{
          arr.push({value:d._id,label:d.country_name})
      })
      setcountryArr(arr)
    }else if(state['type'] === 'District'){
      const response = await GetState()
      let arr = []
      response.data.data.datas.forEach((d)=>{
          arr.push({value:d._id,label:d.state_name})
      })
      setstateArr(arr)
    }else if(state['type'] === 'Taluk'){
      const response = await GetDistrict()
      let arr = []
      response.data.data.datas.forEach((d)=>{
          arr.push({value:d._id,label:d.district_name})
      })
      setdistrictArr(arr)
    }
  }

  async function submitform(){
    if(state?.data?._id !== null && state?.data?._id !== undefined){
        if(state['type'] === 'Country'){
          if(!data.country_name){
            seterror({...error,country_name:'This Field id required*'})
          }else if(!data.display_name){
            seterror({...error,display_name:'This Field is required*'})
          }else{
            const send_data = {}
            send_data['country_name'] = data.country_name
            send_data['display_name'] = data.display_name
            send_data['_id'] = state?.data?._id
            const response = await UpdateCountryService(send_data,state?.data?._id)
            if(response.status === 201){
              toast.success(`${state['type']} Updated Successfully`)
              navigate(-1)
              setdata({country_name:'',country_id:'',state_name:'',state_id:'',district_name:'',district_id:'',branch_name:'',display_name:''})
              seterror({country_name:'',country_id:'',state_name:'',state_id:'',district_name:'',district_id:'',branch_name:'',display_name:''})
            }
            if(response.status === 403){
              seterror({...error,country_name:'Country Name should be unique'})
            }
          }
        }else if(state['type'] === 'State'){
          if(data.country_id === ''){
            seterror({...error,country_id:'This Field id required*'})
          }else if(!data.state_name){
            seterror({...error,state_name:'This Field id required*'})
          }else if(!data.display_name){
            seterror({...error,display_name:'This Field is required*'})
          }else{

            
            const send_data = {}
            send_data['_id'] = data?.state?._id
            send_data['country_id'] = data?.country_id?.value
            send_data['state_name'] = data?.state_name
            send_data['display_name'] = data.display_name
            const response = await UpdateStateService(send_data,state?.data?._id)
            if(response.status === 201){
              toast.success(`${state['type']} Updated Successfully`)
              setdata({country_name:'',country_id:'',state_name:'',state_id:'',district_name:'',district_id:'',branch_name:'',display_name:''})
              seterror({country_name:'',country_id:'',state_name:'',state_id:'',district_name:'',district_id:'',branch_name:'',display_name:''})
              navigate(-1)
            }
            if(response.status === 403){
              seterror({...error,state_name:'State Name should be unique'})
            }
          }
        }else if(state['type'] === 'District'){
          if(data.state_id === ''){
            seterror({...error,state_id:'This Field id required*'})
          }else if(!data.district_name){
            seterror({...error,district_name:'This Field id required*'})
          }else if(!data.display_name){
            seterror({...error,display_name:'This Field is required*'})
          }else{
            const send_data = {}
            send_data['_id'] = data?.state?._id
            send_data['state_id'] = data?.state_id?.value
            send_data['district_name'] = data?.district_name
            send_data['display_name'] = data.display_name
            const response = await UpdateDistrictService(send_data,state?.data?._id)
            if(response.status === 201){
              toast.success(`${state['type']} Updated Successfully`)
              setdata({country_name:'',country_id:'',state_name:'',state_id:'',district_name:'',district_id:'',branch_name:'',display_name:''})
              seterror({country_name:'',country_id:'',state_name:'',state_id:'',district_name:'',district_id:'',branch_name:'',display_name:''})
              navigate(-1)
            }
            if(response.status === 403){
              seterror({...error,district_name:'District Name should be unique'})
            }
          }
        }else if(state['type'] === 'Taluk'){
          if(data.district_id === ''){
            seterror({...error,district_id:'This Field id required*'})
          }else if(!data.branch_name){
            seterror({...error,branch_name:'This Field id required*'})
          }else if(!data.display_name){
            seterror({...error,display_name:'This Field is required*'})
          }else{
            const send_data = {}
            send_data['_id'] = data?.state?._id
            send_data['district_id'] = data?.district_id?.value
            send_data['branch_name'] = data?.branch_name
            send_data['display_name'] = data.display_name
            const response = await UpdateTalukService(send_data,state?.data?._id)
            if(response.status === 201){
              toast.success(`${state['type']} Updated Successfully`)
              setdata({country_name:'',country_id:'',state_name:'',state_id:'',district_name:'',district_id:'',branch_name:'',display_name:''})
              seterror({country_name:'',country_id:'',state_name:'',state_id:'',district_name:'',district_id:'',branch_name:'',display_name:''})
              navigate(-1)
            }
            if(response.status === 403){
              seterror({...error,branch_name:'Branch Name should be unique'})
            }
          }
        }
    }else{
        if(state['type'] === 'Country'){
          if(!data.country_name){
            seterror({...error,country_name:'This Field id required*'})
          }else if(!data.display_name){
            seterror({...error,display_name:'This Field is required*'})
          }else{
            const send_data = {}
            send_data['country_name'] = data.country_name
            send_data['display_name'] = data.display_name
            const response = await CreateCountryService(send_data)
            if(response.status === 201){
              toast.success(`${state['type']} Created Successfully`)
              setdata({country_name:'',country_id:'',state_name:'',state_id:'',district_name:'',district_id:'',branch_name:'',display_name:''})
              seterror({country_name:'',country_id:'',state_name:'',state_id:'',district_name:'',district_id:'',branch_name:'',display_name:''})
            }
            if(response.status === 403){
              seterror({...error,country_name:'Country Name should be unique'})
            }
          }
        }else if(state['type'] === 'State'){
          if(data.country_id === ''){
            seterror({...error,country_id:'This Field id required*'})
          }else if(!data.state_name){
            seterror({...error,state_name:'This Field id required*'})
          }else if(!data.display_name){
            seterror({...error,display_name:'This Field is required*'})
          }else{
            const send_data = {}
            send_data['country_id'] = data?.country_id?.value
            send_data['state_name'] = data?.state_name
            send_data['display_name'] = data.display_name
            const response = await CreateStateService(send_data)
            if(response.status === 201){
              toast.success(`${state['type']} Created Successfully`)
              setdata({country_name:'',country_id:'',state_name:'',state_id:'',district_name:'',district_id:'',branch_name:'',display_name:''})
              seterror({country_name:'',country_id:'',state_name:'',state_id:'',district_name:'',district_id:'',branch_name:'',display_name:''})
            }
            if(response.status === 403){
              seterror({...error,state_name:'State Name should be unique'})
            }
          }
        }else if(state['type'] === 'District'){
          if(data.state_id === ''){
            seterror({...error,state_id:'This Field id required*'})
          }else if(!data.district_name){
            seterror({...error,district_name:'This Field id required*'})
          }else if(!data.display_name){
            seterror({...error,display_name:'This Field is required*'})
          }else{
            const send_data = {}
            send_data['state_id'] = data?.state_id?.value
            send_data['district_name'] = data?.district_name
            send_data['display_name'] = data.display_name
            const response = await  CreateDistrictService(send_data)
            if(response.status === 201){
              toast.success(`${state['type']} Created Successfully`)
              setdata({country_name:'',country_id:'',state_name:'',state_id:'',district_name:'',district_id:'',branch_name:'',display_name:''})
              seterror({country_name:'',country_id:'',state_name:'',state_id:'',district_name:'',district_id:'',branch_name:'',display_name:''})
            }
            if(response.status === 403){
              seterror({...error,district_name:'District Name should be unique'})
            }
          }
        }else if(state['type'] === 'Taluk'){
          if(data.district_id === ''){
            seterror({...error,district_id:'This Field id required*'})
          }else if(!data.branch_name){
            seterror({...error,branch_name:'This Field id required*'})
          }else if(!data.display_name){
            seterror({...error,display_name:'This Field is required*'})
          }else{
            const send_data = {}
            send_data['district_id'] = data?.district_id?.value
            send_data['branch_name'] = data?.branch_name
            send_data['display_name'] = data.display_name
            const response = await  CreateTalukService(send_data)
            if(response.status === 201){
              toast.success(`${state['type']} Created Successfully`)
              setdata({country_name:'',country_id:'',state_name:'',state_id:'',district_name:'',district_id:'',branch_name:'',display_name:''})
              seterror({country_name:'',country_id:'',state_name:'',state_id:'',district_name:'',district_id:'',branch_name:'',display_name:''})
            }
            if(response.status === 403){
              seterror({...error,branch_name:'Branch Name should be unique'})
            }
          }
        }
    }
  }


  return (
    <div className='flex '>


    <SettingsMenu />
 
  <div className='sm:w-full lg:w-72 ' >

       
        <div className='sm:w-full lg:w-72 p-4'>

        <GoBack />   
        <h6 className='font-bold'>{!(state?.data?._id !== null && state?.data?._id !== undefined) ? 'Create' : 'Edit'} New {state['type']}</h6>
        <h6 className='text-[10px] bg-slate-100 p-2  leading-snug' >Use the below form to create or edit the <b> csdt</b> for the application.</h6>
        

        


        {state['type'] === 'Country' &&
        <TextInput 
            label={'Country Name'}  
            variant="standard"
            name="country_name"
            type="text"
            error={error.country_name}
            value={data.country_name}
            handlechange={handlechange}
            placeholder="Enter your Country name"
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>}

        {state['type'] === 'State' &&
        <>
        <h6 className='text-[12px] font-semibold mb-1 mt-1'>Select Country</h6>
        <Select
          value={data.country_id} 
          placeholder="" 
          bordered={false}
          onChange={(e)=>handleSelect(e,'Country')} 
          styles={customStyles} 
          options={countryArr} 
          className='w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none'
          />
        {error.country_id !== '' && error.country_id !== undefined &&
        <div className='flex items-center mt-1'>
        <BiErrorCircle className='text-red-500' size={14} />
        <span className='text-[10px] text-red-500 ml-1'>{error.country_id}</span>
        </div>}
        <TextInput 
            label={'State Name'}  
            variant="standard"
            name="state_name"
            type="text"
            error={error.state_name}
            value={data.state_name}
            handlechange={handlechange}
            placeholder="Enter your State name"
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>
        </>}

        {state['type'] === 'District' &&
         <>
         <h6 className='text-[12px] font-semibold mb-1 mt-1'>Select State</h6>
         <Select
           value={data.state_id}
           bordered={false}
           placeholder="" 
           onChange={(e)=>handleSelect(e,'State')} 
           styles={customStyles} 
           options={stateArr} 
           className='w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none'
           />
         {error.state_id !== '' && error.state_id !== undefined &&
         <div className='flex items-center mt-1'>
         <BiErrorCircle className='text-red-500' size={14} />
         <span className='text-[10px] text-red-500 ml-1'>{error.state_id}</span>
         </div>}
        <TextInput 
            label={'District Name'}  
            variant="standard"
            name="district_name"
            type="text"
            error={error.district_name}
            value={data.district_name}
            handlechange={handlechange}
            placeholder="Enter your District name"
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>
        </>}

        {state['type'] === 'Taluk' &&
         <>
         <h6 className='text-[12px] font-semibold mb-1 mt-1'>Select District</h6>
         <Select
           value={data.district_id} 
           placeholder="" 
           bordered={false}
           onChange={(e)=>handleSelect(e,'District')} 
           styles={customStyles} 
           options={districtArr} 
           className='w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none'

           />
         {error.district_id !== '' && error.district_id !== undefined &&
         <div className='flex items-center mt-1'>
         <BiErrorCircle className='text-red-500' size={14} />
         <span className='text-[10px] text-red-500 ml-1'>{error.district_id}</span>
         </div>}
        <TextInput 
            label={'Branch Name'}  
            variant="standard"
            name="branch_name"
            type="text"
            error={error.branch_name}
            value={data.branch_name}
            handlechange={handlechange}
            placeholder="Enter your Branch name"
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>
         </>}            

        <TextInput 
            label={'Display Name'}  
            variant="standard"
            name="display_name"
            type="text"
            error={error.display_name}
            value={data.display_name}
            handlechange={handlechange}
            placeholder="Enter your Display name"
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>

        {/* <Box mt={2}>
        <ButtonFilled btnName={(state?.data?._id !== null && state?.data?._id !== undefined) ? `Update ${state['type']}` : `Add ${state['type']}`}  onClick={submitform} />  
        </Box> */}
        <div className='mt-5'>
        <ButtonFilledAutoWidth btnName={(state?.data?._id !== null && state?.data?._id !== undefined) ? `Update ${state['type']}` : `Add ${state['type']}`}  onClick={submitform} />  
        </div>
        </div>
        </div>

    </div>
  )
}

export default CSDTCE