import { deleteRequest, get, post, postfd, publicPost, put } from "../../../helpers/apihelpers";

export const CreateProjectWebsiteBlogService=async(data)=>{
    try {
        const result = await publicPost(`api/project_website/blogs/create`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UpdateProjectWebsiteBlogService=async(data,id)=>{
    try {
        const result = await put(`api/project_website/blogs/update/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetProjectWebsiteBlogService=async(page,text)=>{
    try {
        const result = await get(`api/project_website/blogs/get?page=${page}&text=${text}`,);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteProjectWebsiteBlogService=async(id)=>{
    try {
        const result = await deleteRequest(`api/project_website/blogs/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const UploadProjectWebsiteBlogService=async(data)=>{
    try {
        const result = await postfd(`api/project_website/blogs/upload_file`,data);
        return result;
    } catch (err) {
        return err.response;
    }
}