import React, { useEffect } from 'react'
import { useLocation,useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FaWalking } from "react-icons/fa";
import { IoCarSportOutline } from "react-icons/io5";
import { MdOutlineApproval } from "react-icons/md";

function ReceptionMenu() {

    const {pathname} = useLocation();
    const navigate = useNavigate();
    const auth = useSelector(state=>state.Auth)
    const user = useSelector(state=>state.Auth)
    
    useEffect(()=>{
        if(pathname === '/reception'){
            navigate('staff_moment')
        }
    },[])
  
    const menu = [
        {name:'Staff Moment',icon:FaWalking,path:'/reception/staff_moment',roles:[],id:1,},
        {name:'Cab Usage',icon:IoCarSportOutline,path:'/reception/cab_usage',roles:[],id:2,},
        {name:'Staff Approval',icon:MdOutlineApproval,path:'/reception/staff_moment_approval',roles:['staff_moment_approval','admin'],id:3,},
    ]   
    
   


    return (
        auth.isAuthenticated &&
        <div className='hidden md:block mr-0 min-h-screen max-h-sceen border-r min-w-[180px]  w-44 max-w-44  px-2' >
            <div className='mb-4 mt-5'>
                <h6 className=' ml-2 mb-2 font-[700] text-[12px]'>Basic Option</h6>

                {menu.map((m)=>(
                     <>
                     {(m?.roles?.length === 0 || m?.roles?.filter((f)=> user?.roles?.includes(f))?.length > 0) &&
                    <div key={m.path} className={`flex items-center my-1 hover:bg-[#f4f5f7] px-1.5 py-1 ml-2 rounded-md relative cursor-pointer ${pathname ===  m.path && 'bg-[#f4f5f7]'}`} onClick={()=>navigate(m?.path)}>
                        {pathname ===  m.path &&  <span className='border-slate-800 border-2 h-6 rounded absolute -ml-3' ></span>}
                        <m.icon color='#000' style={{backgroundColor:m?.color,padding:'2px'}} size={16}  />
                        <span className='text-[#000] font-[500] ml-2 text-[12px]'>{m.name}</span>
                    </div>}
                    </>   
                ))}

               
            </div>
        </div>
    )
}

export default ReceptionMenu