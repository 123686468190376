import React,{useEffect, useState} from 'react'
import { toast } from 'react-hot-toast'
import {  ButtonFilledAutoWidth } from '../../../../components/button'
import { TextAreaInput1, TextInput } from '../../../../components/input'
import { useNavigate, useLocation } from 'react-router-dom'
import GoBack from '../../../../components/back/GoBack'
import WebsiteMenu from '../../WebsiteMenu'
import Uploader from '../../../../components/Uploader'
import { UploadProjectWebsiteBlogService } from '../../../../services/Website/ProjectWebsiteOption/ProjectBlogServices'
import { CreateProjectWebsiteJobOpeningService, UpdateProjectWebsiteJobOpeningService } from '../../../../services/Website/ProjectWebsiteOption/ProjectJobOpeningServices'
import { BiCheckbox,BiCheckboxSquare } from 'react-icons/bi'

function ProjectJobOpeningCE() {
 
  const [data,setdata] = useState({date:'',designation:'',job_description:'',no_of_position:'',location:'',contact_person:'',is_active:true});  
  const [error,seterror] = useState({date:'',designation:'',job_description:'',no_of_position:'',location:'',contact_person:'',is_active:''}); 
  
  const {state} = useLocation();
  const navigate = useNavigate();


 


  useEffect(()=>{
    if(state?._id !== null && state?._id !== undefined){
      let obj = {
       ...state
      }
      setdata({...data,...obj})
    }
  },[state])

  function handlechange(e){
    setdata({...data,[e.target.name] : e.target.value})
    seterror({...error,[e.target.name]:''})
  }

  
  async function submitform(){
    if(!data.designation){
        seterror({...error,designation:'Designation field is required *'})
    }else{
        let sendData = {...data}

      
        if(state?._id === undefined || state?._id === null){
        const response = await CreateProjectWebsiteJobOpeningService(sendData)
        if (response.status === 201) {
          setdata({date:'',designation:'',job_description:'',no_of_position:'',location:'',contact_person:'',is_active:true})
          seterror({date:'',designation:'',job_description:'',no_of_position:'',location:'',contact_person:'',is_active:''})
          toast.success('Project Job Opening Created Successfully')
        }   
        if(response.status === 422){
          if(response?.data?.errors?.name){
            seterror({...error,name:response?.data?.errors?.name})
          }
        } 
      }else{
        sendData["id"] = state?._id
        const response = await UpdateProjectWebsiteJobOpeningService(sendData,state?._id)
        if (response.status === 200) {
          setdata({date:'',designation:'',job_description:'',no_of_position:'',location:'',contact_person:'',is_active:true})
          seterror({date:'',designation:'',job_description:'',no_of_position:'',location:'',contact_person:'',is_active:''})
          navigate(-1)
          toast.success('Project Job Opening Updated Successfully')
        }   
        if(response.status === 422){
          if(response?.data?.errors?.name){
            seterror({...error,name:response?.data?.errors?.name})
          }
        } 
      }
  }
  }


  async function uploadfile(v,name){
    const fd = new FormData()
    fd.append('file',v); 
    const response = await UploadProjectWebsiteBlogService(fd)
    if(response?.status === 200){
      setdata({...data,[name]:response?.data?.data})
      seterror({...error,[name]:''})
    }
  }


  

  

  return (
    <div className='flex '>


    <WebsiteMenu />
 
  <div className='sm:w-full lg:w-72 px-4 pt-5' >


      <GoBack /> 
      
      

        <h6 className='font-bold'>{(state?._id !== null && state?._id !== undefined) ? 'Edit' : 'Add'} Project Job Opening</h6>
        <h6 className='text-[10px] bg-slate-100 p-2 font-[500] leading-snug' >Use the below form to create or edit the <b> Project Job Opening</b> for your website.</h6>
     

        <TextInput 
            label={'Job Designation'}  
            variant="standard"
            name="designation"
            type="text"
            error={error.designation}
            value={data.designation}
            handlechange={handlechange}
            placeholder="Enter your Role name"
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>


          <TextAreaInput1 
            label={'Job Description'}  
            variant="standard"
            name="job_description"
            type="text"
            error={error.job_description}
            value={data.job_description}
            handlechange={handlechange}
            placeholder=""
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>

        <TextInput 
            label={'No Of Position'}  
            variant="standard"
            name="no_of_position"
            type="text"
            error={error.no_of_position}
            value={data.no_of_position}
            handlechange={handlechange}
            placeholder="Enter your Role name"
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>

        <TextInput 
            label={'Location'}  
            variant="standard"
            name="location"
            type="text"
            error={error.location}
            value={data.location}
            handlechange={handlechange}
            placeholder="Enter your Role name"
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>

        <TextInput 
            label={'Contact Person'}  
            variant="standard"
            name="contact_person"
            type="text"
            error={error.contact_person}
            value={data.contact_person}
            handlechange={handlechange}
            placeholder="Enter your Role name"
            InputLabelProps={{
                style: { color: '#fff', }, 
            }}/>

       

          <h6 className='text-[11px] mt-2 font-[600] mb-1' >JD Info</h6>
          <Uploader image={data?.file}  setimagefunc={(e)=>{uploadfile(e,'file')}}  removeimageuploadfunc = {()=>setdata({...data,file:''})}/>

          <div className='flex items-center mt-0 mr-2 -ml-1 mt-1'>
              {data?.is_active ?  <BiCheckboxSquare size={22} className='text-slate-600' onClick={()=>setdata({...data,is_active:!data.is_active})} />  : <BiCheckbox size={22} className='text-slate-300' onClick={()=>setdata({...data,is_active:!data.is_active})} /> }
              <h6 className="text-[11.5px] font-[500] ml-1">{data?.is_active ? 'Active' : 'Expired'}</h6> 
          </div>
         
        
        <div className='mt-5'>
        <ButtonFilledAutoWidth btnName={(state?._id !== null && state?._id !== undefined) ? "UPDATE Project Job Opening" : "ADD Project Job Opening"}  onClick={submitform} />  
        </div>


    </div>
    </div>
  )
}

export default ProjectJobOpeningCE