import React,{useState,useEffect} from 'react'
import GoBack from '../../components/back/GoBack'
import { useLocation, useNavigate } from 'react-router-dom'
import AtsMenu from './AtsMenu'
import { AiOutlineEdit } from 'react-icons/ai'
import { GetAtsDetailClientService } from '../../services/Ats/AtsClientServices'

function AtsClientManagmentDetail() {

  const {state} = useLocation() 
  const navigator = useNavigate()
  

    
  const [singleData,setsingleData] = useState({image:'',company_name:'',client_name:'',client_logo:'',client_no:'',client_email:'',address:'',are_we_serving:true})

  useEffect(()=>{
  
      let d = state.data
      let edit_data = {
        ...d,
        image:(d?.client_logo === undefined || d?.client_logo === null) ? '' : d?.client_logo
      }
      setsingleData({...edit_data})
      getData()
  },[])

  async function getData(){
     const response = await GetAtsDetailClientService(state.data._id)
     setsingleData({...response?.data?.datas[0]})

  }

 

  // console.log("singleData",singleData)

  return (
    <div className='flex min-h-screen max-h-screen h-screen '>
    <AtsMenu />
    <div className='w-[25%] pl-5 min-h-screen max-h-screen h-screen overflow-y-screen overflow-x-hidden' >
            <GoBack />
            
            <div className='border-b pb-2'>
                <h6 className='text-[13px] mb-1 font-[800]'>Client Detail</h6>
                <h6 className='text-[12px] bg-slate-200 p-2'>See the below the clients detail added for now for further process.</h6>
           </div>

            
            <div className='mt-2'>

              <div className='relative flex mb-2 border-b border-slate-100 pb-2'>
                <AiOutlineEdit size={14} className='absolute right-0 cursor-pointer' onClick={()=>navigator('/ats/client_managment/edit',{state:{data:singleData}})} />
              <img className='w-[100px]' src={`${process.env.REACT_APP_BACKEND_IMAGE_URL}${singleData?.client_logo}`}  />
              <div className='ml-4'>
              <h6 className='text-[14px] font-[700]'>{singleData?.company_name}</h6>
              <h6 className='text-[12px]'>Client Name : <span className='font-[700]'>{singleData?.client_name}</span></h6>
              <h6 className='text-[12px]'>Client No : <span className='font-[700]'>{singleData?.client_no}</span></h6>
              <h6 className='text-[12px]'>Client Email : <span className='font-[700]'>{singleData?.client_email}</span></h6>
              <span className='font-[600] text-[11px] bg-slate-300 p-1 px-2'>{singleData?.are_we_serving ? 'Active' : 'In Active'}</span>
              </div>
              </div> 

              {(singleData?.address !== undefined && singleData?.address !== null) && 
              <h6 className='text-[13px]'>Address : <span className='font-[700]'>{singleData?.address}</span></h6>}
           
            
           </div>  
           </div>

    </div>
  )
}

export default AtsClientManagmentDetail