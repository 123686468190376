import React, { useEffect, useState,useRef } from 'react'
import {MdOutlineRadioButtonUnchecked,MdOutlineRadioButtonChecked,MdOutlineCheckCircleOutline} from 'react-icons/md';
import {Modal} from 'antd'
import {BiUserPin} from 'react-icons/bi'
import { TextAreaInput1 } from '../../components/input';
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../components/button';
import {FaCaretDown} from 'react-icons/fa';
import { IoCloseOutline } from 'react-icons/io5';
import { CreateAtsJobBasedEmployeeTrackerService, GetAtsJobBasedEmployeeTrackerService } from '../../services/Ats/AtsJobServices';
import toast from 'react-hot-toast';
import moment from 'moment';
import { Tooltip } from '@material-ui/core';

function HiringTracker({tracker,onclose,selected_data}) {

  const [selected_stage,setselected_stage] = useState({summary:'',heading:'',sub_heading:''}) 
  const [stagemodal,setstagemodal] = useState(false) 
  const [stagemenumodal,setstagemenumodal] = useState(false) 
  const [tracker_array,settracker_array] = useState([])
  const [tracker_count,settracker_count] = useState({})
   
  const ref = useRef()

  function handleChange(e){
     setselected_stage({...selected_stage,[e.target.name]:e.target.value})
  } 

  useEffect(()=>{
    getData()
  },[selected_data])

  async function getData(){
    const response =  await GetAtsJobBasedEmployeeTrackerService(selected_data._id)
    // console.log("response?.data",response?.data)
    settracker_array(response?.data?.data?.data)
    settracker_count(response?.data?.data?.count)
  }

  async function updateStagefunc(){
     let body = {
        ...selected_stage,
        related_to:selected_data._id
     }
     const response = await CreateAtsJobBasedEmployeeTrackerService(body)
     if(response?.status === 200){
        getData()
        toast.success(response?.data?.data)
        setstagemodal(false)
        setstagemenumodal(false)
        setselected_stage({summary:'',heading:'',sub_heading:''})
        ref.current.focus()
     }
  }

  return (
    <div className='relative max-h-screen pb-[50px]'>
        
        <Tooltip title={'Close'}>
        <span className='absolute z-100 right-0 cursor-pointer' ><IoCloseOutline onClick={()=>{onclose();console.log("click here")}} /></span> 
        </Tooltip>
        <Modal open={stagemodal} footer={false} closable={false} width={300} style={{margin: 'unset'}} className='absolute top-0 left-[40%] before:content-unset;'>
            <div>
            <h6 className='text-[14px] font-[700]'>Change Status</h6>
            <h6 className='text-[10px] mt-1 leading-tight'>Use the below options to update the hiring status of the selected candidate to get track for your reminder.</h6>
             
            
            <div className='relative'>
            <h6 className='text-[11px] font-[600] mb-1 mt-4' >{'Select Applicant Status'}</h6>
            <div onClick={()=>setstagemenumodal(!stagemenumodal)}>
                <h6 className='border z-10 flex items-center justify-between p-1.5 border-slate-300'> <span className='text-[12px] font-[600]'> {selected_stage?.sub_heading === '' ?  'Current Status' : selected_stage?.sub_heading} </span> <FaCaretDown className='text-slate-500'/> </h6>
            </div>

            {stagemenumodal &&
            <div className='border max-h-[300px] top-[21px] border border-blue-300 rounded-[2px] absolute overflow-y-scroll p-2  z-50 bg-white'>
                {tracker?.map((t,i)=>(
                    <div key={i}>
                        <h6 className='font-[700] text-[13px] ml-1' >{t?.heading}</h6>
                         {t?.sub_heading?.split(":")?.map((t1,i1)=>(
                            <h6 key={i1} onClick={()=>{setselected_stage({...selected_stage,heading:t?.heading,sub_heading:t1});setstagemenumodal(!stagemenumodal)}} className={`text-[12px] ml-2 py-1 ${selected_stage?.sub_heading == '' && tracker_array[tracker_array?.length - 1]?.sub_heading === t1 ?  'bg-slate-100 ' : '' } hover:bg-slate-100 cursor-pointer px-1`}>{t1}</h6>
                         ))}
                    </div>    
                ))}
                
            </div>}
            </div>


            

            <TextAreaInput1 
                label={'Summary'}
                name={'summary'}
                handlechange={handleChange}
                value={selected_stage?.summary}
            />

            <div className='flex mt-4 border-t  pt-2 justify-end'>
                <ButtonOutlinedAutoWidth btnName={"Cancel"} onClick={()=>setstagemodal(!stagemodal)} />
                <h6 className='w-[10px]'></h6>
                <ButtonFilledAutoWidth btnName={"Save"} onClick={()=>updateStagefunc()}/>
            </div>
            </div>

        </Modal>


        <h1 className='font-[700]'>HiringTracker</h1>
        <h6 className='text-[11.5px] '>Use the below status to update the candidate hiring managment.</h6>

         <h6 className='mt-5 font-[600] flex items-center border-t pt-4'> <span className='border-r border-r-slate-400 pr-2'>Employee Hiring Stage</span> <span className='border-r border-r-slate-400 capitalize font-[400] text-slate-600 flex items-center text-[12px] pl-2 pr-2'> <BiUserPin size={18} className='mr-2' /> {selected_data?.ats_hr_employee?.employee_name} /  <span className='ml-1 font-[700] text-[10px]'> {selected_data?.ats_hr_employee?.designation}</span>  </span>  <span className='bg-orange-600 ml-2  uppercase text-[8px] p-[3px] px-[10px] rounded-[20px] text-white font-[700]' style={{backgroundColor:tracker?.find((f)=>f?.heading === tracker_array[tracker_array?.length - 1]?.heading)?.color}}>{tracker_array[tracker_array?.length - 1]?.heading}</span></h6>    
            {(tracker?.length > 0 && tracker_array?.length > 0) &&
            <div className='grid grid-cols-5 mt-5 border rounded-[5px] mb-5'>
                {tracker?.map((t,i)=>(
                    <div className={` ${ i < tracker?.length - 1 && 'border-r' } ${tracker_count?.filter((f)=>f?.heading === t?.heading)?.length > 0 ? 'opacity-100' : 'opacity-40'} p-2`}>
                    {tracker_array[tracker_array?.length -1]?.heading === t?.heading ?  <MdOutlineRadioButtonChecked size={20} color={t?.color} /> : 
                    tracker_count?.filter((f)=>f?.heading === t?.heading)?.length > 0 ? <MdOutlineCheckCircleOutline size={20} color={t?.color} /> : <MdOutlineRadioButtonUnchecked  size={20} color={'#ddd'}/> }
                    <h6 className='text-[12px] mt-1 font-[600]'>{t?.heading}</h6>
                    <h6 className='text-[11px] font-[700] text-gray-400'>{tracker_count?.filter((f)=>f?.heading === t?.heading)?.length > 0 ? tracker_count?.filter((f)=>f?.heading === t?.heading)[0]?.count +  ' Data Found' : '---' }</h6>
                    </div>
                ))}
            </div>}

            <div className=' overflow-y-scroll h-[70vh] pb-[100px]'>
            {tracker_array?.map((t,i)=>(
            <div className='flex'>
                <div className=''> 
                {i === 0  &&
                <div className='bg-slate-100 border-l-2 flex w-[200px] h-min  flex flex-col justify-items-end p-2 ' style={{borderLeftColor:tracker?.find((t1)=>t1?.heading == t?.heading)?.color}}>
                    <h6 className='text-[12px] font-[700] text-right'>{t?.heading}</h6>
                    <h6 className='text-[9px] font-[400] text-right'>{moment(t?.createdAt)?.format('L')}</h6>
                </div>
                }
                {(i > 0 && t?.heading != tracker_array[i-1]?.heading) &&
                <div className={`bg-slate-100 border-l-2 border-l-blue-500 flex w-[200px] h-min  flex flex-col justify-items-end p-2 mt-5 mt-10`} style={{borderLeftColor:tracker?.find((t1)=>t1?.heading == t?.heading)?.color}}>
                    <h6 className='text-[12px] font-[700] text-right'>{t?.heading}</h6>
                    <h6 className='text-[9px] font-[400] text-right'>{moment(t?.createdAt)?.format('L')}</h6>
                </div>
                }
                </div>
                <div className={`border-l  pl-2 ${(i > 0 && t?.heading == tracker_array[i-1]?.heading) ?`pt-5 ml-[200px]`  :  (i > 0 && t?.heading != tracker_array[i-1]?.heading) ? `pt-[80px]` :  'pt-16' } `}>
                    <div className=' flex items-center justify-self-start -ml-[83.5px]'>
                        <div className='flex flex-col justify-items-end'>
                        <h6 className='text-[10px] mx-2'>{moment(t?.createdAt)?.format('L')}</h6>  
                        <h6 className='text-[8px] mx-2'>({moment(t?.createdAt)?.format('LT')})</h6>  
                        </div>  
                       
                        <h6 className='w-[7px] h-[7px] bg-orange-500 rounded-[5px]' style={{backgroundColor:tracker?.find((t1)=>t1?.heading == t?.heading)?.color}}></h6>  

                        <div className='ml-3 '>
                        <span className='bg-orange-600  text-[10px] p-[4px] px-[12px] rounded-[20px] text-white font-[700]' style={{backgroundColor:tracker?.find((t1)=>t1?.heading == t?.heading)?.color}} >{t?.sub_heading}</span>
                        {t?.summary !== '' && <h6 className='text-[10px] w-[70%]  py-[4px] rounded-[20px] font-[500]'>Summary : {t?.summary}</h6>}
                        <h6 className='text-[9px] pl-0.5 mt-0.5'>Changes was Made by <span className='font-[700] capitalize'>{t?.created_by?.name}</span> </h6>
                        </div> 
                    </div>


                   

                </div>
            </div>))}
           {!selected_data?.is_deleted ?
            <div ref={ref} className='ml-[199px] -mt-5 z-80 mb-10'>
                    <h1 className='h-[80px] max-w-[0.75px] border border-dashed border-blue-200'></h1>
                    <div className='flex items-center -mt-3 '>
                         <h1 className='min-w-[40px] max-w-[40px] border border-dashed border-blue-200'></h1>
                         <h6 onClick={()=>setstagemodal(true)} className='w-[115px] p-1 bg-slate-100 hover:bg-sky-100 cursor-pointer border border-slate-400 text-center text-[10px]'>Move to Next Status</h6>
                    </div>
            </div>
            :
            <div ref={ref} className='ml-[199px] -mt-5 z-80 '>
            <h1 className='h-[80px] max-w-[0.75px] border border-dashed border-blue-200'></h1>
            <div className='flex items-center -mt-3 '>
                 <h1 className='min-w-[40px] max-w-[40px] border border-dashed border-blue-200'></h1>
                 <h6  className='w-[120px] p-1 bg-red-100 hover:bg-red-100 cursor-pointer border border-red-400 text-center text-[10px]'>Cannot Update Status</h6>
            </div>
            <h6 className='text-[9px] ml-10 mt-1 w-[240px]'>Candidate has been deleted, update candidate status from deleted stage to modify the status.</h6>
                 </div>
            }
            </div>        

    </div>
  )
}

export default HiringTracker