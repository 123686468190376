import React,{useState,useEffect} from 'react'
import { DeleteInsentiveBasic, GetInsentiveBasicList } from '../../services/AdminServicesfile/InsentiveServices'
import { toast } from 'react-hot-toast'
import {AiOutlineEdit,AiOutlineDelete} from 'react-icons/ai'
import moment from 'moment'
import SettingsMenu from '../staticscreens/SettingsMenu'
import { Modal } from 'antd'
import {IoCloseOutline} from 'react-icons/io5';
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../components/button'
import { useNavigate } from 'react-router-dom'
import { IconButton } from '@material-ui/core'
import {BsArrowRepeat} from 'react-icons/bs';
import {FiChevronRight,FiChevronLeft} from 'react-icons/fi';

function IncentiveRequirmentList() {

    const navigator = useNavigate()
    const [data,setdata] = useState([])
    const [pagination,setpagination] = useState({})

    const [selectedData,setselectedData] = useState({})
    const [deleteModal,setdeleteModal] = useState(false)
    const [page,setpage] = useState(1)

    useEffect(()=>{
        getData()
    },[])


    async function getData(){
        const response = await GetInsentiveBasicList(page)
        setdata(response?.data?.datas)
        setpagination(response?.data?.pagination)
    }

    // console.log("pagination",pagination)

    async function deleteData(){
        const response = await DeleteInsentiveBasic(selectedData?._id)
        if(response.status === 200){
          toast.success('Deleted Successfully')
          getData()
          setdeleteModal(false)
        }
    }

    async function resetfunc(){

    }

  return (
    <div className='h-screen max-h-screen box-border overflow-hidden'>
        <Modal open={deleteModal} closable={false} footer={null}  style={{maxWidth:'270px',borderRadius:'2px'}}>
          <div className='relative'>
               <IoCloseOutline  className='absolute -right-3 -top-2 cursor-pointer' onClick={()=>setdeleteModal(false)}/>
               <h6 className='font-[900]'>Delete Data</h6>
               <h1 className='leading-tight text-[12px] font-[400]'>Are you sure want to delete the selected data.</h1>
               <div className='flex w-full align-end items-end  mt-2'>
                <div className="mr-2">
                <ButtonOutlinedAutoWidth btnName={'Cancel'} onClick={()=>{setdeleteModal(false)}} />
                </div>
                <ButtonFilledAutoWidth btnName={'Save'} onClick={deleteData} />
            </div>
          </div>
      </Modal>

       
       <div className='block sm:flex'>
          <SettingsMenu />
<       div className='px-4 w-[90%]'>
        <div className='pt-5  border-b w-full flex items-center justify-between'>
        <h6 className='text-[14px] font-[800] '>{`Total Insentive Basic (${pagination?.total})`}</h6>


        <div  className='flex text-[12px] mr-2 items-center'>
        <h6 className='mr-2  font-[600]'>{page === 1 ? data?.length > 0 ? 1 : 0 : (page - 1) * pagination?.limit } - {pagination?.limit} of {pagination?.total} </h6>
        <IconButton onClick={resetfunc} ><BsArrowRepeat size={16} /></IconButton>
        <div>
        <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>
        <IconButton onClick={()=>{(page < pagination?.totalPages && data?.length > 0)  ? setpage(page+1) : console.log('')}}><FiChevronRight className={`${(pagination?.totalPages === page || data?.length === 0)  ? 'opacity-50' : 'opacity-100'}`} size={16} /></IconButton>
        </div>
        <ButtonFilledAutoWidth btnName="Add New" onClick={()=>navigator('create')} />
        </div>
        {/* <FiPlus size={22}  className="cursor-pointer ml-4" /> */}
        </div>

        {data?.length === 0 &&    
        <div className='grid place-items-center mt-20  items-center justify-center'>
          <img src={'https://fidecrmfiles.s3.amazonaws.com/NoDataFound1.png'} className='w-40 h-40 object-contain' /> 
          <h6 className='font-bold text-[14px] -mt-10'>No data found</h6>
          <h6 className='font-[500] text-slate-700 text-[12.5px] -mt-2'>Try Any Filter Option to See the "Invoice Report" For the Vertical</h6>
        </div>}

         <div className='max-h-[85vh] overflow-y-scroll  grid grid-cols-4 pt-2 gap-1.5  '>
          {
          data?.map((d)=>(
            <div key={d?._id} className='border relative p-2'> 
            {d?.is_active && 
            <span className='text-[9px] absolute top-[-8px] bg-slate-700 px-2 py-1 font-[600] text-white'>Active</span>}
            <div className='flex items-end justify-end'>
              <span><AiOutlineDelete size={14} className='mr-2 cursor-pointer' onClick={()=>{setselectedData(d);setdeleteModal(true)}}/></span>
              <span onClick={()=>{navigator('edit',{state:d})}}><AiOutlineEdit size={14} className='cursor-pointer'/></span>
            </div>
                <h6 className='text-[12px] -mt-2 font-[500]'>Year :<span className='font-[800]'> {d?.year}</span></h6>
                <h6 className='text-[12px] font-[500]'>Start Date - End Date : <span className='font-[800]'>{moment(d?.incentive_start).format('LL')} / {moment(d?.incentive_end).format('LL')}</span> </h6>
                <div className='bg-slate-100 p-2 mt-1'>
                <h6 className='text-[11px] font-[500]'>Percentage Share : </h6>
                <h6 className='text-[10.5px] font-[500]'>HOD : <span className='font-[800]'>{d?.hod_percentage} % </span> Manager : <span className='font-[800]'>{d?.manager_percentage} % </span>  Employee : <span className='font-[800]'>{d?.employee_percentage} %</span>  </h6>
                </div>
            </div>  
          ))
        }
        </div>

       
      </div>
      </div>

    </div>
  )
}

export default IncentiveRequirmentList