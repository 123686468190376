import React,{useState,useEffect} from 'react'
import { TextAreaInput1, TextInput } from '../../components/input'
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../components/button'
import { toast } from 'react-hot-toast'
import GoBack from '../../components/back/GoBack'
import { useLocation, useNavigate } from 'react-router-dom'
// import LeadMenu from '../LeadMenu'
import Uploader from '../../components/Uploader'
import AtsMenu from './AtsMenu'
import {BiErrorCircle} from 'react-icons/bi'
// import { CreateAtsJobService, UpdateAtsJobService } from '../../services/Ats/AtsJobServices'
import { FilterAtsClientService } from '../../services/Ats/AtsClientServices'
import { CreateAtsEmployeeService, UpdateAtsEmployeeService } from '../../services/Ats/AtsEmployeeServices'

function AtsEmployeeManagmentCE({step = undefined,employee_data = undefined,closemodal,selected_data}) {

  const {state,pathname} = useLocation() 
  const navigator = useNavigate()

  // console.log("state",state)
  

  
  const path = pathname?.split('/')[pathname?.split('/')?.length - 1]
  const [singleData,setsingleData] = useState({employee_name:'',mobile_no:'',email_id:'',current_location:'',skype_id:'',referred_by:'',user_photo:'',resume:'',notice_period:'',experience:'',preferred_location:'',current_ctc:'',expected_ctc:'',current_company:'',agency_comment:'',skills:'',designation:'',ratings:0})
  const [error,seterror] = useState({employee_name:'',mobile_no:'',email_id:'',current_location:'',skype_id:'',referred_by:'',user_photo:'',resume:'',notice_period:'',experience:'',preferred_location:'',current_ctc:'',expected_ctc:'',current_company:'',agency_comment:'',skills:'',designation:'',ratings:''})
  const [location,setlocation] = useState([])
  const [clients,setclients] = useState([])
  const [stages,setstages] = useState([])

  

  

  const [loader,setloader] = useState(false)


  useEffect(()=>{

     
    
      if(path === 'edit'){
        let d = state.data

        let edit_data = {
          ...d,
          ats_hr_client:{label:`[${d?.ats_hr_client?.company_name} : Client : ${d?.ats_hr_client?.client_name}]`,value:d?.ats_hr_client?._id},
          location:d?.location?.split(":"),
          stages:d?.stages?.split(":"),
          image:(d?.job_attachment === undefined || d?.job_attachment === null) ? '' : d?.job_attachment
      }

        setlocation(d?.location?.split(":"))
        setstages(d?.stages?.split(":"))
  
        setsingleData({...edit_data})
      }else{
       
        if(employee_data?._id !== undefined){
          setsingleData({
            ...employee_data,
            skype_id:employee_data?.skype_id !== "undefined" ? employee_data?.skype_id : '',
            referred_by:employee_data?.referred_by !== "undefined" ? employee_data?.referred_by : '',
            agency_comment:employee_data?.agency_comment !== "undefined" ? employee_data?.agency_comment : '',
            skills:employee_data?.skills !== "undefined" ? employee_data?.skills : '',
            designation:employee_data?.skype_id !== "undefined" ? employee_data?.designation : ''
          })
        }else{
          setsingleData({employee_name:'',mobile_no:'',email_id:'',current_location:'',skype_id:'',referred_by:'',user_photo:'',resume:'',notice_period:'',experience:'',preferred_location:'',current_ctc:'',expected_ctc:'',current_company:'',agency_comment:'',skills:'',designation:''})
          seterror({employee_name:'',mobile_no:'',email_id:'',current_location:'',skype_id:'',referred_by:'',user_photo:'',resume:'',notice_period:'',experience:'',preferred_location:'',current_ctc:'',expected_ctc:'',current_company:'',agency_comment:'',skills:'',designation:''})
        }
    
      }
  },[employee_data,path])
   
  // console.log("error",error)
  // console.log("singleData",singleData)



  async function submitform(){
    // if(state?.type === 'daily_tasks'){
        if(!singleData?.employee_name){
            seterror({...error,employee_name:'The Name  field is required'})
        }else if(!singleData.mobile_no){
            seterror({...error,mobile_no:'The Mobile No field is required'})
        }else if(!singleData.email_id){
            seterror({...error,email_id:'The Email field is required'})
        }else if(!singleData.current_location){
            seterror({...error,current_location:'The Current Location field is required'})
        }else if(!singleData.current_ctc){
            seterror({...error,current_ctc:'The Current CTC field is required'})
        }else if(!singleData.expected_ctc){
            seterror({...error,expected_ctc:'The Expected CTC field is required'})
        }else if(!singleData.notice_period){
            seterror({...error,notice_period:'The Notice period field is required'})
        }else if(!singleData.experience){
            seterror({...error,experience:'The Experience field is required'})
        }else if(!singleData.preferred_location){
            seterror({...error,preferred_location:'The Preferred Location field is required'})
        }else if(!singleData.resume){
            seterror({...error,resume:'The Resume field is required'})
        }else{
             
                const fd = new FormData()
                
                if(selected_data?._id !== undefined){
                  fd.append('client',selected_data?._id)
                }
        
                fd.append('employee_name',singleData?.employee_name)
                fd.append('mobile_no',singleData?.mobile_no)
                fd.append('email_id',singleData?.email_id)
                fd.append('current_location',singleData?.current_location)
                fd.append('skype_id',singleData?.skype_id === undefined ? '' : singleData?.skype_id)
                fd.append('referred_by',singleData?.referred_by === undefined ? '' : singleData?.referred_by)
                fd.append('notice_period',singleData?.notice_period === undefined ? '' : singleData?.notice_period)
                fd.append('experience',singleData?.experience === undefined ? '' : singleData?.experience)
                fd.append('preferred_location',singleData?.preferred_location === undefined ? '' : singleData?.preferred_location)
                fd.append('current_ctc',singleData?.current_ctc === undefined ? '' : singleData?.current_ctc)
                fd.append('expected_ctc',singleData?.expected_ctc === undefined ? '' : singleData?.expected_ctc)
                fd.append('current_company',singleData?.current_company === undefined ? '' : singleData?.current_company)
                fd.append('agency_comment',singleData?.agency_comment === undefined ? '' : singleData?.agency_comment)
                fd.append('designation',singleData?.designation === undefined ? '' : singleData?.designation )
                fd.append('skills',singleData?.skills  === undefined ? '' : singleData?.skills)

                if(singleData?.user_photo?.name !== undefined){
                   fd.append('profile_pic',singleData?.user_photo)
                }

                if(singleData?.resume?.name !== undefined){
                    fd.append('resume',singleData?.resume)
                 }
                
                if(path !== 'edit' && employee_data === undefined){
                   
                    const response = await CreateAtsEmployeeService(fd)
                    if(response.status === 200){
                        setloader(false)
                        toast.success('Employee Data Created Successfully')
                        resetform() 
                    }
                }else{
                    if(employee_data === undefined){
                        fd.append('_id',state.data._id)
                        const response = await UpdateAtsEmployeeService(fd,state.data._id)
                        if(response.status === 200){
                            setloader(false)
                            toast.success('Employee Data Updated Successfully')
                            resetform() 
                        }
                    }else{
                      fd.append('_id',employee_data?._id)
                      const response = await UpdateAtsEmployeeService(fd,employee_data?._id)
                      if(response.status === 200){
                          setloader(false)
                          toast.success('Employee Data Updated Successfully')
                          resetform() 
                      }
                    }    
                }    
              
        }  
    
  }

  function resetform(){
        setsingleData({employee_name:'',mobile_no:'',email_id:'',current_location:'',skype_id:'',referred_by:'',user_photo:'',resume:'',notice_period:'',experience:'',preferred_location:'',current_ctc:'',expected_ctc:'',current_company:'',agency_comment:'',skills:'',designation:''})
        seterror({employee_name:'',mobile_no:'',email_id:'',current_location:'',skype_id:'',referred_by:'',user_photo:'',resume:'',notice_period:'',experience:'',preferred_location:'',current_ctc:'',expected_ctc:'',current_company:'',agency_comment:'',skills:'',designation:''})
        if(path === 'edit'){
            navigator(-1)
        }
        if(closemodal !== undefined){
          closemodal()
        }
       
  }

  function handlechange(e){
        setsingleData({...singleData,[e.target.name] : e.target.value})
        seterror({...error,[e.target.name] : ''})
  }

  async function searchClient(e){
     const response = await FilterAtsClientService({text:e,from_date:'',to_date:'',page:1})
     let arr = []
     let d = response?.data?.datas 
     d?.forEach((d1)=>{
        arr?.push({label:`[${d1?.company_name} : Client : ${d1?.client_name}]`,value:d1?._id})
     })
     setclients(arr)
  }


//   console.log("clients : ",clients)


  



  return (
    <div className={`flex min-h-screen max-h-screen  overflow-hidden`}>
   {(step !== 0 && step == undefined) &&  <AtsMenu />}
      <div className={`${step === 0 ? 'w-[100%]': 'w-[45%]'} mt-5 pl-5 min-h-screen max-h-screen h-screen overflow-y-scroll overflow-x-hidden`} >
      {(step !== 0 && step == undefined) &&  <GoBack />}
              

              <div className={`${step !== 0 && 'overflow-y-scroll'} pb-10`}>
              <div className='border-b pb-2'>
                  <h6 className='text-[13px] mb-1 font-[800]'>Add / Edit Employee Details</h6>
                  <h6 className='text-[12px] bg-slate-200 p-2'>Use the below form to create or update the employee details which will be assigned to client.</h6>
            </div>

              
              <div className='mt-2 flex'>
              
              <div className='mr-4 w-[45%]'>
              {/* <h6 className='text-[11px] font-[600] mb-1' >Search Client</h6>     */}

                  {/* <Select 
                    value={singleData?.ats_hr_client}
                    showSearch
                      bordered={false}
                      className='border border-slate-300 w-[100%]'
                      onSearch={searchClient}
                      options={clients}
                      onChange={(e)=>setsingleData({...singleData,ats_hr_client:clients?.find((v)=>v.value === e)})}
                      />
              */}

              <TextInput 
                mandatory={true}
                label={'Name'}  
                variant="standard"
                name="employee_name"
                type="text"
                value={singleData?.employee_name}
                error={error?.employee_name}
                handlechange={handlechange}
                placeholder="Enter employee name"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>

              <TextInput 
                mandatory={true}
                label={'Mobile'}  
                variant="standard"
                name="mobile_no"
                type="text"
                value={singleData?.mobile_no}
                error={error?.mobile_no}
                handlechange={handlechange}
                placeholder="Enter mobile no"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>

              <TextInput 
                mandatory={true}
                label={'Email'}  
                variant="standard"
                name="email_id"
                type="text"
                value={singleData?.email_id}
                error={error?.email_id}
                handlechange={handlechange}
                placeholder="Enter email id"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>

              <TextInput 
                mandatory={true}
                label={'Current Location'}  
                variant="standard"
                name="current_location"
                type="text"
                value={singleData?.current_location}
                error={error?.current_location}
                handlechange={handlechange}
                placeholder="Enter current location"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>


              <TextInput 
                mandatory={false}
                label={'Skype Id'}  
                variant="standard"
                name="skype_id"
                type="text"
                value={singleData?.skype_id}
                error={error?.skype_id}
                handlechange={handlechange}
                placeholder="Enter skype id"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>

              <TextInput 
                mandatory={false}
                label={'Referred By'}  
                variant="standard"
                name="referred_by"
                type="text"
                value={singleData?.referred_by}
                error={error?.referred_by}
                handlechange={handlechange}
                placeholder="Enter referred by"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>

              <TextInput 
                mandatory={false}
                label={'Designation'}  
                variant="standard"
                name="designation"
                type="text"
                value={singleData?.designation}
                error={error?.designation}
                handlechange={handlechange}
                placeholder="Enter designation"
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>

              <TextAreaInput1 
                label={'Skills'}  
                variant="standard"
                name="skills"
                type="text"
                value={singleData?.skills}
                error={error?.skills}
                handlechange={handlechange}
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>  

              <TextAreaInput1 
                label={'Agency Comment'}  
                variant="standard"
                name="agency_comment"
                type="text"
                value={singleData?.agency_comment}
                error={error?.agency_comment}
                handlechange={handlechange}
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>



              


              


            

              </div>
          
              

              <div className='w-[45%]  ml-4'>

              <TextInput
                mandatory={true}
                label={'Current CTC '}  
                variant="standard"
                name="current_ctc"
                type="text"
                value={singleData?.current_ctc}
                error={error?.current_ctc}
                handlechange={handlechange}
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>


              <TextInput
                mandatory={true}
                label={'Expected CTC '}  
                variant="standard"
                name="expected_ctc"
                type="text"
                value={singleData?.expected_ctc}
                error={error?.expected_ctc}
                handlechange={handlechange}
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>

              <TextInput
                mandatory={true}
                label={'Current Company '}  
                variant="standard"
                name="current_company"
                type="text"
                value={singleData?.current_company}
                error={error?.current_company}
                handlechange={handlechange}
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>  
              


              <div className='flex'>
                  <div className='mr-4'>
                  <TextInput 
                  mandatory={true}
                  label={'Notice Period'}  
                  variant="standard"
                  name="notice_period"
                  type="text"
                  value={singleData?.notice_period}
                  error={error?.notice_period}
                  handlechange={handlechange}
                  placeholder="Enter notice period"
                  InputLabelProps={{
                      style: { color: '#fff', }, 
                  }}/>
                  </div>
                  <div>
                  <TextInput 
                  mandatory={true}
                  label={'Experience'}  
                  variant="standard"
                  name="experience"
                  type="text"
                  value={singleData?.experience}
                  error={error?.experience}
                  handlechange={handlechange}
                  placeholder="Enter experience name"
                  InputLabelProps={{
                      style: { color: '#fff', }, 
                  }}/>  
                  </div>

              </div>  

              <TextInput
                mandatory={true}
                label={'Preferred Location '}  
                variant="standard"
                name="preferred_location"
                type="text"
                value={singleData?.preferred_location}
                error={error?.preferred_location}
                handlechange={handlechange}
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>  

              <TextInput
                mandatory={true}
                label={'Candidate Rating '}  
                variant="standard"
                name="ratings"
                type="number"
                min="0" max="5" 
                value={singleData?.ratings}
                error={error?.ratings}
                handlechange={handlechange}
                InputLabelProps={{
                    style: { color: '#fff', }, 
                }}/>    
              

            
              <h6 className='text-[11px] font-[600] mb-1 mt-2' >Employee Resume</h6>    
              <Uploader image={singleData?.resume} name="resume"  setimagefunc={(e,e1)=>{setsingleData({...singleData,[e1]:e});seterror({...error,resume:''})}}  removeimageuploadfunc = {()=>{setsingleData({...singleData,resume:''});seterror({...error,resume:''})}}/> 

              {error?.resume !== '' && error?.resume !== undefined &&
              <div className='flex items-center mt-1'>
              <BiErrorCircle className='text-red-500' size={14} />
              <span className='text-[10px] text-red-500 ml-1'>{error?.resume}</span>
              </div>}

              <h6 className='text-[11px] font-[600] mb-1 mt-2' >Employee Photo</h6>    
              <Uploader image={singleData?.user_photo} name="user_photo"  setimagefunc={(e,e1)=>{setsingleData({...singleData,[e1]:e});seterror({...error,user_photo:''})}}  removeimageuploadfunc = {()=>{setsingleData({...singleData,user_photo:''});seterror({...error,user_photo:''})}}/>    
              
              
              {error?.user_photo !== '' && error?.user_photo !== undefined &&
              <div className='flex items-center mt-1'>
              <BiErrorCircle className='text-red-500' size={14} />
              <span className='text-[10px] text-red-500 ml-1'>{error?.user_photo}</span>
              </div>}

            

              </div>
              </div>




              <div className='flex mt-2 border-t pt-2 w-full items-center '>
                  <div className='mr-2'>
                  <ButtonOutlinedAutoWidth btnName="Cancel" onClick={()=>step === 0 ? closemodal() : navigator(-1)} />
                  </div>
                  <ButtonFilledAutoWidth btnName="Save" onClick={submitform} />
              </div>
              </div>
      </div>  
    </div>
  )
}

export default AtsEmployeeManagmentCE