import { deleteRequest, get  } from "../../helpers/apihelpers";

export const GetFcaresReportService=async(page)=>{
    try {
        const result = await get(`api/fcares/contact_save/get?page=${page}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteFcaresReportService=async(id)=>{
    try {
        const result = await deleteRequest(`api/fcares/contact_save/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}