import React, { useState,useEffect } from 'react'
import FcaresMenu from './FcaresMenu'
import { GetFcaresDashboardService } from '../../services/Fcares/FCaresDashboardService'
import {RiBillLine,RiFilePaperLine} from 'react-icons/ri';
import { TbBuilding } from "react-icons/tb";
import { LuPackagePlus } from "react-icons/lu";
import { TbArrowsJoin } from "react-icons/tb";
import { AiOutlineUngroup } from "react-icons/ai";
import { TbBrandGoogleBigQuery } from "react-icons/tb";
import { MdOutlineFormatOverline } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import ReactApexChart from 'react-apexcharts';
import moment from 'moment';


function FcareDashboard() {

  const navigator = useNavigate()  
  const [homeData,sethomeData] = useState({})

  const monthArr = ['January','Febraury','March','April','May','June','July','August','Septemeber','October','November','December']

  const [series2,setseries2] = useState([])

  const [chartOptions1,setchartOptions1] = useState({
    series: [],

    chart: {
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded',
        dataLabels: {
          position: 'top', // top, center, bottom
        },
        
      },
    },
    dataLabels: {
      enabled: false
    },
    legend: {
      show: false
    },
    grid:{
      show: true,
      borderColor: '#fafafa',
    },
  
    stroke: {
      // show: true,
      width: 1,
      
      curve: 'smooth',
      colors: ['transparent']
    },
    xaxis: {
      categories:[],
    },
    yaxis: {
      title: {
        text: 'Amount'
      }
    },
    fill: {
      opacity: 1
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return  rupeeIndian?.format(val)
        }
      }
    },
    fill:['#027cd1']
  })

  useEffect(()=>{
    getdata()
  },[])  

  async function getdata(){
     const response = await GetFcaresDashboardService()
     let d = response?.data?.data?.maintenance
     let d1 = response?.data?.data?.maintenance1
     let d2 = response?.data?.data?.monthArr

    //  console.log("d1",d1)
    //  console.log("d2",d2)

     if(d?.length > 0){
       let data1 = Math.round(d[0]?.revenue / (d[0]?.revenue - d[0]?.fidelitus_maintenance)*100)
       let data2 = Math.round((d[0]?.revenue / (d[0]?.revenue - d[0]?.fidelitus_maintenance)*100) -100)

       setseries2([data1,data2])
     }else{
        setseries2([])
     }
     let categories = []
     let series = []

     if(d1?.length > 0){
       
        let data1 = []
        let data2 = []
        d2?.reverse()?.forEach((d3)=>{
          
          if(d3?.split('-')[1]?.slice(0,1) === '0'){
                categories?.push(monthArr[parseInt(d3?.split('-')[1]?.slice(1)) - 1]?.slice(0,3)  + '-' + d3?.split('-')[0])
          }else{
              categories?.push(monthArr[parseInt(d3?.split('-')[1]) - 1]?.slice(0,3) + '-' + d3?.split('-')[0])
          }
          if(d1?.find((f)=>f?._id?.month == d3)){
            data1?.push(d1?.find((f)=>f?._id?.month == d3)?.revenue)
            data2?.push(d1?.find((f)=>f?._id?.month == d3)?.fidelitus_maintenance)
          }else{
            data1?.push(0)
            data2?.push(0)
          }
          
        })

        series =  [{
            name:'Rent Collected',
            data:data1
          },
          {
            name:'Fcare Revenue',
            data:data2
        }]

     }else{
       
       series =  [{
        name:'Rent Collected',
        data:[]
      },
      {
        name:'Fcare Revenue',
        data:[]
      }]

     }

   
     setchartOptions1({
            ...chartOptions1,
            series:series,
            xaxis: {
              categories:categories,
            },})

     

     sethomeData(response?.data)
  } 

  let rupeeIndian = Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  });


  const chartOptions2 = {
    plotOptions: {
      color:['#000','#000'],
      size:'40%',
    },
   
    legend: {
      show: false
    },
    labels: ['Revenue Generated','Fcare Revenue'],
    fill:{
      colors: ['#4d65a3','#d44522'],
    }
  }


  return (
    <div lassName='h-screen max-h-screen box-border overflow-hidden'>
         <div className='block sm:flex'>
            <FcaresMenu />
            <div className=' m-5 w-[84%] min-h-screen max-h-screen h-screen overflow-hidden' >
              
            <div className='grid border-l border-r border-b grid-cols-6 items-center justify-evenly border-t border-b border-slate-100'>
                <div className='border-r border-b  border-slate-100 px-2 py-2 w-full' onClick={()=>navigator('/fcares/user_list')}>
                  <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><TbBuilding size={23} className='mr-2 bg-slate-200 p-1 text-slate-500' /> Total Landlord</h6>
                  <h6 className='text-[14px] mt-1'>{rupeeIndian?.format(homeData?.data?.landlord)?.split('.')[0].slice(1)}</h6>
                </div>

                <div className='border-r border-b  border-slate-100 px-2 py-2 w-full' onClick={()=>navigator('/fcares/user_list')}>
                  <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><LuPackagePlus size={23} className='mr-2 bg-slate-200 p-1 text-slate-500' /> Total Tenant</h6>
                  <h6 className='text-[14px] mt-1'>{rupeeIndian?.format(JSON.stringify(homeData?.data?.tenant))?.split('.')[0].slice(1)}</h6>
                </div>

                <div className='border-r border-b  border-slate-100 px-2 py-2 w-full' onClick={()=>navigator('/fcares/property_list')}>
                  <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><TbArrowsJoin size={23} className='mr-2 bg-slate-200 p-1 text-slate-500' /> Property Occupied</h6>
                  <h6 className='text-[14px] mt-1'>{rupeeIndian?.format(JSON.stringify(homeData?.data?.property_occupied))?.split('.')[0].slice(1)}</h6>
                </div>

                <div className='border-r border-b  border-slate-100 px-2 py-2 w-full' onClick={()=>navigator('/fcares/property_list')}>
                  <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><AiOutlineUngroup size={23} className='mr-2 bg-slate-200 p-1 text-slate-500' /> Property UnOccupied</h6>
                  <h6 className='text-[14px] mt-1'>{rupeeIndian?.format(JSON.stringify(homeData?.data?.property_unoccupied))?.split('.')[0].slice(1)}</h6>
                </div>

                <div className='border-r border-b  border-slate-100 px-2 py-2 w-full' onClick={()=>navigator('/fcares/ticket_list')}>
                  <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><RiBillLine size={23} className='mr-2 bg-slate-200 p-1 text-slate-500' />Total Tickets </h6>
                  <h6 className='text-[14px] mt-1'>{rupeeIndian?.format(JSON.stringify(homeData?.data?.ticket))?.split('.')[0].slice(1)}</h6>
                </div>

                <div className='px-2 border-b border-slate-100 py-2 w-full' onClick={()=>navigator('/fcares/query_list')}>
                  <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><TbBrandGoogleBigQuery size={23} className='mr-2 bg-slate-200 p-1 text-slate-500' />Query Pending </h6>
                  <h6 className='text-[14px] mt-1'>{rupeeIndian?.format(JSON.stringify(homeData?.data?.query_pending))?.split('.')[0].slice(1)}</h6>
                </div>
                <div className='px-2 border-r border-slate-100 py-2 w-full' onClick={()=>navigator('/fcares/query_list')}>
                  <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><MdOutlineFormatOverline size={23} className='mr-2 bg-slate-200 p-1 text-slate-500' />Query Completed </h6>
                  <h6 className='text-[14px] mt-1'>{rupeeIndian?.format(JSON.stringify(homeData?.data?.query_resolved))?.split('.')[0].slice(1)}</h6>
                </div>

                <div className='px-2 border-r border-slate-100 py-2 w-full' onClick={()=>navigator('/fcares/query_list')}>
                  <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><RiMoneyDollarCircleLine size={23} className='mr-2 bg-slate-200 p-1 text-slate-500' />Bill Amount </h6>
                  <h6 className='text-[14px] mt-1'>{rupeeIndian?.format(JSON.stringify(homeData?.data?.maintenance?.length > 0 ? homeData?.data?.maintenance[0]?.revenue : 0 ))?.split('.')[0]}</h6>
                </div>

                <div className='px-2 border-r border-slate-100 py-2 w-full' onClick={()=>navigator('/fcares/query_list')}>
                  <h6 className='text-[12px] text-slate-700 font-[800] flex items-center'><RiMoneyDollarCircleLine size={23} className='mr-2 bg-slate-200 p-1 text-slate-500' />Fcare Amount </h6>
                  <h6 className='text-[14px] mt-1'>{rupeeIndian?.format(JSON.stringify(homeData?.data?.maintenance?.length > 0 ? homeData?.data?.maintenance[0]?.fidelitus_maintenance : 0 ))?.split('.')[0]}</h6>
                </div>

               
            </div>

            <div className='flex h-[220px]'>
                <div className='max-h-[350px] w-[30%] relative border border-slate-100 p-2 my-2'>
                   <h6 className='pt-1 text-[13px] font-[700]'>Revenue Generated</h6> 

                    <div className='mt-2 -ml-8'>
                      <ReactApexChart options={chartOptions2} size={'10'} series={series2} type="donut" width={220} />
                      <div className='absolute pl-2 right-2 top-[35%]'>
                            <div className='mb-2'>
                                <h6 className='text-[11px]'>Rent Collected By Tenant</h6>
                                <h6 className='text-[13px] font-[800]'>{rupeeIndian?.format(JSON.stringify(homeData?.data?.maintenance?.length > 0 ? homeData?.data?.maintenance[0]?.revenue : 0 ))?.split('.')[0]}</h6>
                            </div>

                            <div>
                                <h6 className='text-[11px]'>Fcare Revenue By Landlord</h6>
                                <h6 className='text-[13px] font-[800]'>{rupeeIndian?.format(JSON.stringify(homeData?.data?.maintenance?.length > 0 ? homeData?.data?.maintenance[0]?.fidelitus_maintenance : 0 ))?.split('.')[0]}</h6>
                            </div>
                      </div> 
                    </div>

                </div>

                <div className='max-h-[350px] ml-[1%] w-[70%] border border-slate-100 p-2 my-2'>
                   <h6 className='pt-1 text-[13px] font-[700]'>Monthly Revenue Statistics</h6> 


                   <ReactApexChart options={chartOptions1} size={'10'} series={chartOptions1.series} type="bar" width={'100%'}  height={180} />

                  

                </div>
            </div>

            <div className='flex  justify-between mt-4'>
              <div className='border w-[30%] p-2 pt-3 border-slate-100'>
                <h6 className='text-[12px] font-[700]'>Today Report</h6>

                <div className='flex'>
                  <div className='border w-[50%] mt-2 border-blue-200'>
                  <h6 className='text-[10px] p-[4px]  font-[500]'>Today Total Query</h6>
                  <div className='flex w-full items-center bg-blue-100'>
                    <div className='w-[50%] border-r-[2px] border-blue-400'>
                     <h6 className='text-center text-[10px] py-[4px] font-[700] bg-blue-200'>Pending</h6>
                     <h6 className='text-center font-[800] text-[12px]'>{homeData?.data?.dataInfo?.queryListPending}</h6>
                    </div> 
                    <div className='w-[50%]'>
                     <h6 className='text-center text-[10px] py-[4px] font-[700] bg-blue-200'>Resolved</h6>
                     <h6 className='text-center font-[800] text-[12px]'>{homeData?.data?.dataInfo?.queryListResolved}</h6>
                    </div>
                  </div>
                  </div>

                  <div className='border w-[50%] ml-2 mt-2 border-green-200'>
                  <h6 className='text-[10px] p-[4px]  font-[500]'>Today Tickets</h6>
                  <div className='flex w-full items-center bg-green-100'>
                    <div className='w-[50%] w-[50%] border-r-[2px] border-green-400'> 
                     <h6 className='text-center text-[10px] py-[4px] font-[700] bg-green-200'>Pending</h6>
                     <h6 className='text-center font-[800] text-[12px]'>{homeData?.data?.dataInfo?.ticketListPending}</h6>
                    </div> 
                    <div className='w-[50%]'> 
                     <h6 className='text-center text-[10px] py-[4px] font-[700] bg-green-200'>Resolved</h6>
                     <h6 className='text-center font-[800] text-[12px]'>{homeData?.data?.dataInfo?.ticketListResolved}</h6>
                    </div> 
                  </div>
                  </div>
                </div>
              </div>
              
              <div className='border w-[34%] p-2 pt-3 border-slate-100'>
              <h6 className='text-[12px] font-[700]'>Active Tickets</h6>

              <div className='border h-[200px] overflow-y-scroll border-gray-300'>
                <div className='flex text-[12px] bg-slate-100'>
                  <h6 className='text-[11px] p-[2px] font-[600] border-r border-gray-300 w-[40%]'>Client Info</h6>
                  <h6 className='text-[11px] p-[3px] font-[600]  w-[60%]'>Remarks</h6>
                </div>
                {homeData?.data?.dataInfo?.ticketListPendingData?.map((d)=>(
                   <div onClick={()=>navigator('/fcares/query_list/edit',{state:d?._id})} className='flex cursor-pointer text-[11px] border-b border-gray-300'>
                   <h6 className='text-[11px] p-[2px] font-[400] border-r border-gray-300 w-[40%]'> {d?.ticket_created_by !== 'Tenant' ? `${d?.client?.name} / ${d?.client?.mobile}` : `${d?.tenant?.name} / ${d?.tenant?.mobile}`}</h6>
                   <h6 className='text-[11px] p-[3px] font-[400]  w-[60%]'>Remarks : <span className='font-[700]'>{d?.remarks}</span> <br></br> Property : <span className='font-[700]'>{d?.property?.building_name} </span> <br></br> Created At : <span className='font-[700]'>{moment(d?.createdAt)?.format('LLL')}</span></h6>
                 </div>))
                }
              </div>

              </div>

              <div className='border w-[34%] p-2 pt-3 border-slate-100'>
              <h6 className='text-[12px] font-[700]'>Active Query</h6>
              
              <div className='border h-[200px] overflow-y-scroll border-gray-300'>
                <div className='flex text-[12px] bg-slate-100'>
                  <h6 className='text-[11px] p-[2px] font-[600] border-r border-gray-300 w-[40%]'>Client Info</h6>
                  <h6 className='text-[11px] p-[3px] font-[600]  w-[60%]'>Remarks</h6>
                </div>
                {homeData?.data?.dataInfo?.queryListPendingData?.map((d)=>(
                   <div onClick={()=>navigator('/fcares/query_list/edit',{state:d?._id})} className='flex cursor-pointer text-[11px] border-b border-gray-300'>
                   <h6 className='text-[11px] p-[2px] font-[400] border-r border-gray-300 w-[40%]'> <span className='font-[700]'>{d?.user?.name}</span> / {d?.user?.mobile}</h6>
                   <h6 className='text-[11px] p-[3px] font-[400]  w-[60%]'>Remarks : <span className='font-[700]'>{d?.remarks}</span> <br></br> Service : <span className='font-[700]'>{d?.service?._id !== undefined ? d?.service?.name : d?.other}</span> <br></br> {d?.attachment !== '' && <a href={`${process.env.REACT_APP_BACKEND_IMAGE_URL}${d?.attachment}`} className='underline font-[700] text-red-600'>View Attachment</a>} <br></br> Created At : <span className='font-[700]'>{moment(d?.createdAt)?.format('LLL')}</span></h6>
                 </div>))
                }
              </div>
              </div>

            </div>

            </div>
        </div>    
    </div>
  )
}

export default FcareDashboard