import {   deleteRequest, get,post, put, } from "../../helpers/apihelpers";

export const CreateFinanceDataService =async(data)=>{
    try {
        const result = await post(`api/finance/create`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const updateFinanceDataService =async(id,data)=>{
    try {
        const result = await put(`api/finance/update/${id}`, data);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const GetFinanceDataService =async(department,page)=>{
    try {
        const result = await get(`api/finance/get?department=${department}&page=${page}`);
        return result;
    } catch (err) {
        return err.response;
    }
}

export const DeleteFinanceDataService =async(id)=>{
    try {
        const result = await deleteRequest(`api/finance/delete/${id}`);
        return result;
    } catch (err) {
        return err.response;
    }
}