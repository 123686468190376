import React, { useEffect, useState } from 'react'
import MdFollowUpMenu from './MdFollowUpMenu'
import moment from 'moment'
import {FiChevronRight,FiChevronLeft} from 'react-icons/fi';
import { IconButton } from '@material-ui/core';
import {DatePicker, Modal, Select} from 'antd'
import { ButtonFilledAutoWidth, ButtonOutlinedAutoWidth } from '../../components/button';
import { useLocation, useNavigate } from 'react-router-dom';
import {AiOutlineClose, AiOutlineDelete, AiOutlineEdit, AiOutlineFilter, AiOutlineReload} from 'react-icons/ai';  
import { DeleteMdFollowUpService, GetMdFollowUpService } from '../../services/MdFollowUpServices';
import toast from 'react-hot-toast';
import { IoFilterCircleOutline } from "react-icons/io5";
import { LuTimerReset } from "react-icons/lu";
import { TextAreaInput1, TextInput } from '../../components/input';
import { CreateMdFollowUpPropsoalService, DeleteMdFollowUpPropsoalService, UpdateMdFollowUpPropsoalService } from '../../services/MdFollowUpPropsalServices';

function MdFollowUpList() {

 const [data,setdata] = useState({datas:[],pagination:{}})

 const [page,setpage] = useState(1)
 const [singleData,setsingleData] = useState({})
 const [singleData1,setsingleData1] = useState({})
 const [modal,setmodal] = useState(false)
 const [modal1,setmodal1] = useState(false)
 const [modal2,setmodal2] = useState(false)
 const [modal3,setmodal3] = useState(false)
 const {pathname} = useLocation()
 const [search,setsearch] = useState({text:'',from_date:'',to_date:'',from_date1:'',to_date1:'',activate:false,type:'',stage:''})
 const path1 = pathname.split('/')[pathname.split('/').length - 2]

 const [proposal,setproposal] = useState({name:'',proposal_status:'',contact:'',email:'',status:'',remarks:''})
 const [error,seterror] = useState({name:'',proposal_status:'',contact:'',email:'',status:'',remarks:''})
 
 const navigator = useNavigate()


//  console.log("path1",path1)


 useEffect(()=>{
    if(path1 === 'land'){
        let type = typeArr?.find((f)=>f?.label === 'Land')
        setsearch({...search,type:type,status:''})
        getdata(type,'')
    }else if(path1 === 'warehouse'){
        let type = typeArr?.find((f)=>f?.label === 'WareHouse')
        setsearch({...search,type:type,status:''})
        getdata(type,'')
    }else if(path1 === 'office_space'){
        let type = typeArr?.find((f)=>f?.label === 'Commercial Office Space')
        setsearch({...search,type:type,status:''})
        getdata(type,'')
    }else if(path1 === 'investment'){
        let type = typeArr?.find((f)=>f?.label === 'Investment')
        setsearch({...search,type:type,})
        getdata(type,'')
    }else{
        setsearch({...search,type:'',status:''})
        getdata({label:'',value:''},{label:'',value:''})
    }
 },[pathname])




 async function getdata(type,status){
    const response = await GetMdFollowUpService(search?.text,search?.from_date1,search?.to_date1,page,type?.label === undefined ? search?.type : type?.value,status === '' ? '' : search?.status)
    setdata(response?.data)
 }
 
 async function gotocreate(){
    navigator('create')
 }

 async function gotoedit(d){
    navigator('edit',{state:d})
 }

 async function applyFilter(){
    const response = await GetMdFollowUpService(search?.text,search?.from_date1,search?.to_date1,1,search?.type?.value !== undefined ? search?.type?.value : search?.type,search?.status)
    setdata(response?.data)
 }


 async function resetfilter(){
    setpage(1)
    if(path1 !== 'lead'){
        setsearch({text:'',from_date:'',to_date:'',from_date1:'',to_date1:'',activate:false,status:'',type:''})
        const response = await GetMdFollowUpService('','','',1)
        setdata(response?.data)
    }else{
       setsearch({...search,text:'',from_date:'',to_date:'',from_date1:'',to_date1:'',activate:false,type:''})
       const response = await GetMdFollowUpService('','','',1,search?.type)
       setdata(response?.data)
    }
    
 }

 async function deleteData(){
    const response = await DeleteMdFollowUpService(singleData?._id)
    if(response?.status === 200){
        toast.success("Deleted Successfully")
        setmodal(false)
        getdata()
    }

 }

 const typeArr = [
    {label:'Land',value:'MD_LandStages'},
    {label:'WareHouse',value:'MD_WareHouseStages'},
    {label:'Commercial Office Space',value:'MD_CommercialOfficeSpaceStages'},
    {label:'Investment',value:'MD_InvestmentStages'},
  ]

  const statusArr = [
    {label:'Pending',value:'Pending'},
    {label:'Processing',value:'Processing'},
    {label:'Closed',value:'Closed'},
    {label:'Not Closed',value:'Not Closed'},
  ]

  const propsalStatus = [
    {label:'In Review',value:'In Review'},
    {label:'Not Intrested',value:'Not Intrested'},
    {label:'Intrested',value:'Intrested'},
  ]

  function handlechange(v,type){
    if(type === 'type'){
        setsearch({...search,type:typeArr?.find((f)=>f?.value === v)?.label})
    }
    if(type === 'status'){
        setsearch({...search,status:statusArr?.find((f)=>f?.label === v)?.label})
    }
    if(type === 'propsal_status'){
        setproposal({...proposal,status:propsalStatus?.find((f)=>f?.label === v)?.label})
    }

    

  }

  async function handlechange1(e){
    setproposal({...proposal,[e.target.name]:e.target.value})
    seterror({...error,[e.target.name]:''})
  }

//   console.log("search here",search)
//   const [type,settype]

  async function proposalcreate(){
    let sendData = {...proposal}
    sendData['md_followup']  = singleData?._id
    if(proposal?._id !== undefined){
        const response = await UpdateMdFollowUpPropsoalService(sendData,proposal?._id)
        if(response?.status === 200){
            toast.success("Proposal Updated")
            setmodal1(false)
            setmodal2(false)
            setproposal({name:'',proposal_status:'',contact:'',email:'',status:'',remarks:''})
            seterror({name:'',proposal_status:'',contact:'',email:'',status:'',remarks:''})
            setsingleData1({})
            getdata()
        }
    }else{
        const response = await CreateMdFollowUpPropsoalService(sendData)
        if(response?.status === 201){
            toast.success("Proposal Shared")
            setmodal1(false)
            setmodal2(false)
            setproposal({name:'',proposal_status:'',contact:'',email:'',status:'',remarks:''})
            seterror({name:'',proposal_status:'',contact:'',email:'',status:'',remarks:''})
            getdata()
        }
    }

  }

  async function deletepropsal(){
    const response = await DeleteMdFollowUpPropsoalService(singleData1?._id)
    if(response?.status === 200){
        toast.success("Propsal Deleted Successfully")
        getdata()
        setmodal3(false)
        setmodal2(false)
    }
  }

  return (
    <div className='w-[98%] h-screen overflow-hidden'>
          <Modal open={modal} width={260} footer={false} closable={false}>
            <h6 className='text-[14px] font-[700]'>Delete MD Lead Data</h6>  
            <h6 className='bg-slate-100 text-[10px] font-[500] p-1'>Are you sure want to delte the selected data once deleted will not be retrieved</h6> 

           
           <h6 className='text-[11px] font-[400] mt-2'>Project Name : <span className='font-[800]'>{singleData?.name}</span></h6>
           <h6 className='text-[11px] font-[400] '>Area / Acres : <span className='font-[800]'>{singleData?.area}</span></h6>


            <div className='flex border-t pt-2 mt-2 justify-end items-center'>
            <ButtonOutlinedAutoWidth btnName="Cancel" onClick={()=>setmodal(!modal)} />   
            <h6 className='w-[10px]'></h6>
            <ButtonFilledAutoWidth btnName="Save" onClick={()=>deleteData()} />   
            </div>
            </Modal>

            <Modal open={modal1} width={320} footer={false} closable={false}>
            <h6 className='text-[14px] font-[700]'>Proposal For This Lead</h6>  
            <h6 className='bg-slate-100 text-[10px] font-[500] p-1'>Use the below form to create proposal for this lead</h6> 

           
           <h6 className='text-[11px] font-[400] mt-2'>Project Name : <span className='font-[800]'>{singleData?.name}</span></h6>
           <h6 className='text-[11px] font-[400] '>Area / Acres : <span className='font-[800]'>{singleData?.area}</span></h6>
             

           <TextInput
            mandatory={true}
            label={'Name'}  
            variant="standard"
            name="name"
            type="text"
            value={proposal.name}
            error={error.name}
            handlechange={handlechange1}/>  

            <div className='flex'>
                <TextInput
                mandatory={true}
                label={'Contact'}  
                variant="standard"
                name="contact"
                type="text"
                value={proposal.contact}
                error={error.contact}
                handlechange={handlechange1}/>  
                <h6 className='w-5'></h6>

                <TextInput
                mandatory={true}
                label={'Email'}  
                variant="standard"
                name="email"
                type="text"
                value={proposal.email}
                error={error.email}
                handlechange={handlechange1}/> 

            </div>


            <h6 className='text-[11px] font-[600] mb-1 mt-2' >Status</h6>
            <div className='border border-slate-300  p-[2px]'>  
                <Select
                    bordered={false}
                    size='small'
                    defaultValue={proposal?.status === '' ? null : proposal?.status}
                    value={proposal?.status === '' ? null : proposal?.status}
                    className='w-[100%]'
                    placeholder=""
                    onChange={(v)=>handlechange(v,'propsal_status')}
                    options={propsalStatus}
                />
            </div>        

            <TextAreaInput1
                mandatory={true}
                label={'Remarks'}  
                variant="standard"
                name="remarks"
                type="text"
                value={proposal.remarks}
                error={error.remarks}
                handlechange={handlechange1}/> 


            <div className='flex border-t pt-2 mt-2 justify-end items-center'>
            <ButtonOutlinedAutoWidth btnName="Cancel" onClick={()=>{setmodal1(!modal1);setproposal({name:'',proposal_status:'',contact:'',email:'',status:'',remarks:''})}} />   
            <h6 className='w-[10px]'></h6>
            <ButtonFilledAutoWidth btnName="Save" onClick={()=>proposalcreate()} />   
            </div>
            </Modal>

            <Modal open={modal2} width={520} footer={false} closable={false}>

            <AiOutlineClose onClick={()=>setmodal2(false)} className='absolute right-2 top-2' />    
            <h6 className='text-[13px] font-[700]'>Proposal Shared ({singleData?.proposal?.length})</h6>  
            <h6 className='bg-slate-100 text-[10px] font-[500] p-1'>Use the below form to create proposal for this lead</h6> 

            <div className='text-[13px] border flex mt-4'>
                <h6 className='text-[11px] font-[600] p-[4px] border-r w-[10%]'>Sl No</h6>
                <h6 className='text-[11px] font-[600] p-[4px] border-r w-[20%]'>Name</h6>
                <h6 className='text-[11px] font-[600] p-[4px] border-r w-[20%]'>Contact</h6>
                <h6 className='text-[11px] font-[600] p-[4px] border-r w-[25%]'>Email</h6>
                <h6 className='text-[11px] font-[600] p-[4px] border-r w-[15%]'>Status</h6>
                <h6 className='text-[11px] font-[600] p-[4px] border-r w-[10%]'>Action</h6>
            </div>
            {singleData?.proposal?.map((d,i)=>(
                <div className='text-[13px] flex border-l border-b border-r'>
                <h6 className='text-[11px] font-[600] p-[4px] border-r w-[10%]'>{i+1}</h6>
                <h6 className='text-[11px] font-[600] p-[4px] border-r w-[20%]'>{d?.name}</h6>
                <h6 className='text-[11px] font-[600] p-[4px] border-r w-[20%]'>{d?.mobile}</h6>
                <h6 className='text-[11px] font-[600] p-[4px] border-r w-[25%]'>{d?.email}</h6>
                <h6 className='text-[11px] font-[600] p-[4px] border-r w-[15%]'>{d?.status}</h6>
                <h6 className='text-[11px] font-[600] p-[4px] border-r w-[10%]'>
                <h6 className='text-[11px] flex items-start justify-center mt-1'>
                    <AiOutlineEdit onClick={()=>{setsingleData1(d);setproposal(d);setmodal2(true)}} className='mr-2' />
                    <AiOutlineDelete onClick={()=>{setsingleData1(d);setmodal3(true)}} className='mr-2' />
                </h6>
                </h6>
            </div>
            ))}
           
           </Modal>

           <Modal open={modal3} width={260} footer={false} closable={false}>

           <h6 className='text-[14px] font-[700]'>Delete MD Propsal Data</h6>  
            <h6 className='bg-slate-100 text-[10px] font-[500] p-1'>Are you sure want to delte the selected data once deleted will not be retrieved</h6> 

           
           <h6 className='text-[11px] font-[400] mt-2'>Selected Property : <span className='font-[800]'>{singleData?.name}</span></h6>
           <h6 className='text-[11px] font-[400] mt-2'>Client Name : <span className='font-[800]'>{singleData1?.name}</span></h6>

            <div className='flex border-t pt-2 mt-2 justify-end items-center'>
            <ButtonOutlinedAutoWidth btnName="Cancel" onClick={()=>setmodal3(!modal3)} />   
            <h6 className='w-[10px]'></h6>
            <ButtonFilledAutoWidth btnName="Save" onClick={()=>deletepropsal()} />   
            </div>
        </Modal>
           

        <div className='flex'>
            <div className='min-w-44'>
            <MdFollowUpMenu />
            </div>
            <div className='w-[100%] pl-5 mt-4'>
            <div className='flex items-center -mt-1 border-b justify-between pb-1'>
                <h6 className='font-[800] text-[13px] capitalize'>Total {path1 !== 'lead' &&  path1?.replace('_',' ')}  ({data?.pagination?.total})</h6>

                <div className='flex items-center text-[12px]'>

                    <h6 className='mr-2 font-[600]'>{page == 1 ? data?.datas?.length > 0 ? 1 : 0 : (page - 1) * data?.pagination?.limit } - {data?.pagination?.limit} of {data?.pagination?.total}  </h6>

                    <div>
                    <IconButton onClick={()=>{page > 1 ? setpage(page-1) : console.log('')}}><FiChevronLeft className={`${page === 1 ? 'opacity-50' : 'opacity-100'}`}  size={16} /></IconButton>
                    <IconButton onClick={()=>{ page < data?.pagination?.totalPages  ? setpage(page+1) : console.log('')}}><FiChevronRight className={`${(data?.pagination?.totalPages === page || data?.datas?.length === 0) ? 'opacity-50 ' : 'opacity-100'}`}  size={16} /></IconButton>

                    </div>
                    <DatePicker  size='small' style={{fontSize:'11px'}} ampm={false} placeholder='From Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.from_date} onChange={(v,v1) => {console.log('v na',new Date(v).toLocaleDateString());setsearch({...search,from_date:v,from_date1:v1})}} /> 

                    <DatePicker size='small' style={{fontSize:'11px'}} ampm={false} placeholder='To Date' className='text-[11px] py-[2.6px]  w-28 border-slate-300 mr-2'  value={search?.to_date} onChange={(v,v1) => {console.log('v na',new Date(v).toLocaleDateString());setsearch({...search,to_date:v,to_date1:v1})}} /> 

                    
                    <div className='mr-2 flex'>
                        

                    <input  id="search_text" placeholder='Search text' type='text' value={search.text} onChange={(e)=>setsearch({...search,text:e.target.value})} className='border py-[3px] focus:ring-0 focus:outline-0 text-[14px]  w-[100px] px-1 rounded-md border-slate-300' />
                
                    {path1 === 'lead' &&
                    <div className='border border-slate-300 ml-2  rounded-md py-[2px]'>
                    
                    <Select
                        placeholder='Property Type'
                        bordered={false}
                        size='small'
                        defaultValue={search?.type === '' ? null : search?.type}
                        value={search?.type === '' ? null : search?.type}
                        style={{ width: 110 }}
                        onChange={(v)=>handlechange(v,'type')}
                        options={typeArr}
                    />
                </div>}

                <div className='border border-slate-300 ml-2  rounded-md py-[2px]'>
                    
                    <Select
                        placeholder='Status'
                        bordered={false}
                        size='small'
                        defaultValue={search?.status === '' ? null : search?.status}
                        value={search?.status === '' ? null : search?.status}
                        style={{ width: 110 }}
                        onChange={(v)=>handlechange(v,'status')}
                        options={statusArr}
                    />
                </div>

                    </div>  
                    <div className='mr-2'>
                        <AiOutlineFilter onClick={()=>applyFilter()} size={24} className='bg-slate-700 text-white rounded p-[5px] ' />
                    {/* <ButtonOutlinedAutoWidth btnName="Apply Filter" onClick={()=>applyFilter()} />  */}
                    </div>
                    <div className='mr-2'>
                    <AiOutlineReload onClick={()=>resetfilter()} size={24} className='bg-slate-100 text-black rounded p-[5px] ' />

                    {/* <ButtonOutlinedAutoWidth btnName="Reset Filter" onClick={()=>resetfilter()} />  */}
                    </div>
                  
                    <ButtonFilledAutoWidth btnName="Add New" onClick={()=>gotocreate()} />

                 
                   

                </div>
            </div>

          
           
            <div className='overflow-x-hidden h-[90vh] mb-[50px] overflow-y-scroll'>
            
            {data?.datas?.length === 0 &&
            <div className='grid place-items-center mt-20  items-center justify-center'>
                <img src={'https://fidecrmfiles.s3.amazonaws.com/NoDataFound1.png'} className='w-40 h-40 object-contain' /> 
                <h6 className='font-bold text-[14px] -mt-10'>No data found</h6>
                <h6 className='font-[500] w-[70%] text-center text-slate-700 text-[12.5px] -mt-2'>Oops we couldn't find any data based on your current stage please add the data to see list here.</h6>
            </div>}

            {data?.datas?.length > 0 &&
            <div className='border-t max-h-[84vh] overflow-y-scroll border-l border-r mt-2'>
            <div className='flex border-b sticky top-0 z-50 bg-white '>
                <h6 className='text-[12px] font-[700] min-w-[50px] max-w-[50px] p-1 border-r'>SL No</h6>
                <h6 className='text-[12px] font-[700] min-w-[12%] max-w-[12%] p-1 border-r'>Project Name /</h6>
                <h6 className='text-[12px] font-[700] min-w-[15%] max-w-[15%] p-1 border-r'>Details</h6>
                <h6 className='text-[12px] font-[700]  min-w-[12%] max-w-[12%] p-1 border-r'>Location</h6>
                <h6 className='text-[12px] font-[700]  min-w-[12%] max-w-[12%] p-1 border-r'>Area (Sqft)/(Acres)</h6>
                <h6 className='text-[12px] font-[700]  min-w-[10%] max-w-[10%] p-1 border-r'>Status</h6>
                <h6 className='text-[12px] font-[700]  min-w-[10%] max-w-[10%] p-1 border-r'>Stage</h6>
                <h6 className='text-[12px] font-[700]  min-w-[8%] max-w-[8%] p-1 border-r'>Created At</h6>
                <h6 className='text-[12px] font-[700]  min-w-[12%] max-w-[12%] p-1 border-r'>Proposal</h6>
                <h6 className='text-[12px] font-[700] p-1'>Action</h6>
            </div>  
             
             {data?.datas?.map((d,i)=>(
             <div className={`flex  border-b  cursor-pointer`}>
                <h6 className='text-[11px] min-w-[50px] max-w-[50px] p-1 text-center border-r'>{(page > 1 ? i+1+ (25 * (page - 1)) : i+1 )}</h6>
                <h6 className='text-[11px] min-w-[12%] font-[600] max-w-[12%] p-1 border-r'>{d?.name}</h6>
                <h6 className='text-[11px] min-w-[15%] max-w-[15%] p-1 border-r'>{d?.detail}</h6>
                <h6 className='text-[10px] min-w-[12%] max-w-[12%] p-1 border-r'>{d?.location}</h6>
                <h6 className='text-[11px] min-w-[12%] max-w-[12%] p-1 border-r'>{d?.area}</h6>
                <h6 className='text-[11px] min-w-[10%] max-w-[10%] p-1 border-r'>{d?.stage?.name}</h6>
                <h6 className='text-[11px] min-w-[10%] max-w-[10%] p-1 border-r'><span className={`p-[4px] ${d?.status === 'Pending' ? 'bg-blue-300' : d?.status === 'Processing' ? 'bg-yellow-300' : d?.status === 'Closed' ? 'bg-green-300' : 'bg-red-300'} font-[600] text-[10px]`}>{d?.status}</span></h6>
                <h6 className='text-[11px] min-w-[8%] max-w-[8%] p-1 border-r'>{moment(d?.createdAt)?.format('LL')}</h6>
                <h6 className='text-[11px] min-w-[12%] max-w-[12%] flex items-start justify-center p-1 border-r'><span className='font-bold flex items-center justify-center h-[20px] bg-gray-300  opacity-70 border -ml-4 p-[4px]'> {d?.proposal?.length}</span> <span onClick={()=>{setsingleData(d);setmodal2(true)}} className='bg-green-700 ml-[10px] text-white font-[700] text-[8px] rounded-[2px] p-[4px]'> Detail</span> <span className='bg-slate-100 border border-slate-400 ml-[10px] text-black font-[700] text-[8px] rounded-[2px] p-[4px]' onClick={()=>{setsingleData(d);setmodal1(true)}}> Propose</span></h6>
                <h6 className='text-[11px] flex items-start justify-center mt-1 p-1'>
                    <AiOutlineEdit onClick={()=>{gotoedit(d)}} className='mr-2' />
                    <AiOutlineDelete onClick={()=>{setsingleData(d);setmodal(true)}} className='mr-2' />
                </h6>
            </div>   
            ))} 
            </div>}
        </div>
        </div>
        </div>    
    </div>
  )
}

export default MdFollowUpList