import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import IlsMenu from './IlsMenu'
import { TextAreaInput1, TextInput } from '../../components/input'
import { ButtonFilled, ButtonOutlined } from '../../components/button'
import toast from 'react-hot-toast'
import { CreateILSLandRequirmentService, GetILSLandRequirmentDetailService, UpdateILSLandRequirmentService } from '../../services/IlsLandRequirmentServices'
import { GetStageService } from '../../services/StageServices'
import GoBack from '../../components/back/GoBack'
import { CreateLeadAttachmentFollowUpService } from '../../services/LeadServices'
import { DatePicker, TimePicker,Modal,Select } from 'antd';
import Uploader from '../../components/Uploader'
import {IoIosClose} from 'react-icons/io'
import moment from 'moment'
import DashboardMenu from '../dashboard/DashboardMenu'

function IlsLandRequirmentCE() {

  const {state,pathname} = useLocation()  
  const path = pathname.split('/')[2]

  const location = useLocation()  
  const navigate = useNavigate()

  const sectors = [{label:'Industrial/Manufacturing',value:"Industial/Manufacturing"},{label:'Logistics',value:"Logistics"},{label:'Medical/Pharama',value:"Medical/Pharama"},{label:'Developer',value:"Developer"},{label:'Individaul Investor',value:"Individaul Investor"}]
  
  const [loader,setloader] = useState(false)
  const [data,setdata] = useState({sector:'',company_name:'',location:'',micro_market:'',requirment:'',stage:'',point_of_contact_name:'',point_of_contact_mobile:'',point_of_contact_email:'',summary:''})
  const [error,seterror] = useState({sector:'',company_name:'',location:'',micro_market:'',requirment:'',stage:'',point_of_contact_name:'',point_of_contact_mobile:'',point_of_contact_email:'',summary:''})
  
  const [stages,setstages] = useState([])
  const [selectedstage,setselectedstage] = useState({})
  const [modal, setModal] = useState(false);
  const [stage,setstage] = useState({selected_stage:'',date:'',time:'',file:''})
  const [stageerr,setstageerr] = useState({selected_stage:'',date:'',time:'',file:''})

  async function getdata(){
    const response = await GetILSLandRequirmentDetailService(state?._id)
    let d = response?.data?.datas[0]
    let sendData = {...d}
    sendData['stage'] = {label:d?.stage?.name,value:d?.stage?._id}
    setdata(sendData)
  }

  useEffect(()=>{
      if(state?._id !== undefined){
        getdata()
      }
      getStages()
  },[])

  async function getStages(){
    const response = await GetStageService('ILS_Stages')
    let arr = []
    response.data.data.datas.forEach((d)=>{
        arr.push({value:d._id,label:d.name})
    })
    setstages(arr)
  }

  async function handlechange(e){
    setdata({...data,[e.target.name]:e.target.value})
    seterror({...error,[e.target.name]:''})
  }

  async function handleSelect(v,name){
    if(name === 'stage' && (stages?.find((f)=>f?.value === v)?.label === 'Inspection' || stages?.find((f)=>f?.value === v)?.label === 'Proposal' || stages?.find((f)=>f?.value === v)?.label === 'LOI/Agreement')){
      setModal(true)
      setselectedstage(stages?.find((f)=>f?.value === v))
    }else{
      if(name === 'stage'){
        setdata({...data,[name]:stages?.find((f)=>f?.value === v)})
        seterror({...error,[name]:''})
      }else{
        setdata({...data,[name]:v})
        seterror({...error,[name]:''})
      }
    }
  }

  async function submitform(){
    if(!data?.sector){
        seterror({...error,sector:'The Sector Field is required*'})
    }else if(!data?.company_name){
        seterror({...error,company_name:'The Company Name Field is required*'})
    }else if(!data?.point_of_contact_name){
        seterror({...error,point_of_contact_name:'The Contact Name Field is required*'})
    }else if(!data?.point_of_contact_mobile){
        seterror({...error,point_of_contact_mobile:'The Contact Mobile Field is required*'})
    }else{
        
        let sendData = {...data}
        sendData['stage'] = data?.stage?.value !== undefined ? data?.stage?.value : data?.stage
        

        if(state?._id === undefined){
            const response = await CreateILSLandRequirmentService(sendData)
            if(response?.status == 201){
                toast.success("ILS Land Requirment Created Successfully!")
                addFollowUp(response?.data?.data?._id)
            }
        }else{
            const response = await UpdateILSLandRequirmentService(state?._id,sendData)
            if(response?.status == 200){
                toast.success("ILS Land Requirment Updated Successfully!")
                addFollowUp()
                navigate(-1)
            }
        }
    }

  }

  async function resetform(){
    setdata({sector:'',company_name:'',location:'',micro_market:'',requirment:'',status:'',point_of_contact_name:'',point_of_contact_mobile:'',point_of_contact_email:'',summary:''})
    seterror({sector:'',company_name:'',location:'',micro_market:'',requirment:'',status:'',point_of_contact_name:'',point_of_contact_mobile:'',point_of_contact_email:'',summary:''})
  }

  async function addFollowUp(v){
  
      if(state?.stage?._id !== data?.stage?.value){
        let send_data = {}
        send_data['attachment_type'] = 'ILS'
        send_data['stage_type'] = data?.stage?.label
        send_data['stage'] = data?.stage?.value
        send_data['summary'] = ''
        if(state?._id === undefined){
            send_data['type_id'] = v
        }else{
            send_data['type_id'] = state?._id
        }

        if(stage?.date !== '' && stage?.date !== null && stage?.date !== ''){
          send_data['datetime'] = moment(stage?.date).format('YYYY-MM-DD') + ' ' + moment(stage?.time).format('hh:mm')
      }

      if(stage?.file !== '' && stage?.file !== undefined ){
        send_data['fileSize'] = stage?.file?.size
        send_data['file'] = stage?.file
      }  
      const response = await CreateLeadAttachmentFollowUpService(send_data)
      if(response?.status === 201){
        resetform()
      }
    }else{
      resetform()
    }
    // console.log("response?.data",response?.data)
  }

  return (
    <div className='h-screen max-h-screen overflow-hidden'>
       <Modal
        open={modal}
        closable={false}
        maskClosable={false}
        onClose={()=>setModal(false)}
        bodyStyle={{borderRadius:'0px !important'}}
        style={{maxWidth:'300px',borderRadius:'0px !important'}}
        footer={null}
      >
      <div style={{maxWidth:'300px',borderRadius:'0px !important'}} className="relative z-0 ">
        {/* <h6>{stage?.selected_stage?.label}</h6> */}
           <IoIosClose size={20} className="absolute -right-4 -top-4 z-10" onClick={()=>{setModal(false);setstage({selected_stage:'',datetime:'',file:''})}} />
           {(stages.find(f=>f?.value === selectedstage.value)?.label === 'Inspection') ?  
           <>
            <h6 className="font-bold text-[15px]  mb-2 w-full">Add a date and time for Inspection</h6>
            <div className='flex'>
              <div>
              <div className='border'>
              <DatePicker bordered={false} placeholder='' ampm={false} sx={{height:'40px'}} inputFormat="MM/dd/yyyy" value={stage.date} onChange={(v) => {setstage({...stage,date:v});setstageerr({...stageerr,date:''})}} />
              </div>
             {(stageerr?.date !== '' || stageerr?.time !== '')  && <h6 className='text-red-600 text-[10px]'>{stageerr?.date}</h6>}
              </div>
              <p style={{width:10}}></p>
              <div>
              <div className='border'>
              <TimePicker bordered={false} placeholder='' format="hh:mm" ampm={false} sx={{height:'40px'}} value={stage.time} onChange={(v) => {setstage({...stage,time:v});setstageerr({...stageerr,time:''})}} />
              </div>
              {(stageerr?.date !== '' || stageerr?.time !== '')  && <h6 className='text-red-600 text-[10px]'>{stageerr?.time}</h6>}
            </div>
            </div>
            {(stage?.date !== '' && stage?.time !== '') &&
          <div className='mt-2'>
          <ButtonFilled btnName={'Confirm'} onClick={()=>{setModal(false);setdata({...data,stage:selectedstage});seterror({...error,stage:''})}} />
          </div>}
           </>
          :
          <>
           

          <h6 className="font-bold text-[15px]  mb-2 w-full">Add a docs for {stages.find(f=>f?.value === data?.stage)?.label}</h6>
          <Uploader image={stage?.file}  setimagefunc={(e)=>{setstage({...stage,file:e});setstageerr({...stageerr,file:''})}}  removeimageuploadfunc = {()=>setstage({...stage,file:''})} />
          {(stage?.file !== '' && stage?.file !== undefined && stage?.file !== null) &&
          <div className='mt-2'>
          <ButtonFilled btnName={'Confirm'} onClick={()=>{setModal(false);setdata({...data,stage:selectedstage});seterror({...error,stage:''})}} />
          </div>
          }
          </>


          
          }  
      </div>
      </Modal>

    <div className='flex'>
       {path === 'land_requirment' ? <DashboardMenu /> : <IlsMenu />}
        <div className='w-[88%] px-4 mt-5'>
            <GoBack />
            <div className='w-[50%]'>
            <h6 className='font-[800] mb-1'>{location?.pathname?.split('/')[location?.pathname?.split('/').length -1] === 'edit' ? 'Edit' : 'Create'} {path}  ILS Requirment</h6> 
            <h6 className='text-[11px] leading-tight font-[500] p-2 bg-slate-100 '>Use the below form to create or edit the Ils requirment for your further purpose to maintain as you required.</h6> 
            
            <div className='flex justify-between'> 
             <div className='w-[46%]'>
               

             <TextInput 
                 mandatory={true}
                 label={'Company Name'}  
                 variant="standard"
                 name="company_name"
                 type="text"
                 value={data.company_name}
                 error={error.company_name}
                 handlechange={handlechange}
                 placeholder="Enter contact name"
                 InputLabelProps={{
                     style: { color: '#fff', }, 
                 }}
                />

                <h6 className='text-[11px] font-semibold mb-1 mt-1'>Sector</h6>
                <Select
                    value={data?.sector} 
                    error={error?.sector}
                    bordered={false}
                    placeholder="" 
                    onChange={(e)=>handleSelect(e,'sector')} 
                    options={sectors}
                    style={{borderRadius:'0px'}} 
                    className='border-l-4 border-l-slate-600 w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none'
                />

                <TextInput 
                 label={'Location'}  
                 variant="standard"
                 name="location"
                 type="text"
                 value={data.location}
                 error={error.location}
                 handlechange={handlechange}
                 placeholder="Enter contact name"
                 InputLabelProps={{
                     style: { color: '#fff', }, 
                 }}
                />

<TextInput 
                 label={'Micro Market'}  
                 variant="standard"
                 name="micro_market"
                 type="text"
                 value={data.micro_market}
                 error={error.micro_market}
                 handlechange={handlechange}
                 placeholder="Enter contact name"
                 InputLabelProps={{
                     style: { color: '#fff', }, 
                 }}
                />

                <TextAreaInput1 
                    label={'Requirment'}  
                    variant="standard"
                    name="requirment"
                    type="text"
                    value={data.requirment}
                    error={error.requirment}
                    handlechange={handlechange}
                    placeholder=""
                    InputLabelProps={{
                        style: { color: '#fff', }, 
                    }}
                />   

                 <h6 className='text-[11px] font-semibold mb-1 mt-1'>Stage</h6>

            <Select
                value={data?.stage} 
                error={error?.stage}
                bordered={false}
                placeholder="" 
                onChange={(e)=>handleSelect(e,'stage')} 
                options={stages}
                style={{borderRadius:'0px'}} 
                className='border-l-4 border-l-slate-600 w-full border  outline-0 focus:outline-0 focus:ring-0 focus:border-none focus:border-red-900 focus:border-transparent focus:ring-offset-0 focus:shadow-none'
            />       
             </div>

             <div className='w-[46%]'>
                <TextInput 
                 mandatory={true}
                 label={'Contact Name'}  
                 variant="standard"
                 name="point_of_contact_name"
                 type="text"
                 value={data.point_of_contact_name}
                 error={error.point_of_contact_name}
                 handlechange={handlechange}
                 placeholder="Enter contact name"
                 
                />

            <TextInput 
                 mandatory={true}
                 label={'Contact Mobile'}  
                 variant="standard"
                 name="point_of_contact_mobile"
                 type="text"
                 value={data.point_of_contact_mobile}
                 error={error.point_of_contact_mobile}
                 handlechange={handlechange}
                 placeholder="Enter contact name"
                 
                />

            <TextInput 
                 label={'Contact Email'}  
                 variant="standard"
                 name="point_of_contact_email"
                 type="text"
                 value={data.point_of_contact_email}
                 error={error.point_of_contact_email}
                 handlechange={handlechange}
                 placeholder="Enter contact name"
                 
                />

            <TextAreaInput1 
                label={'Summary'}  
                variant="standard"
                name="summary"
                type="text"
                value={data.summary}
                error={error.summary}
                handlechange={handlechange}
                placeholder=""
                />
             </div>   
            </div>   

             <div className='flex items-center mt-5 mb-10  border-t pt-5'>
                <div className='mr-2'>
                <ButtonOutlined btnName={'Back'} onClick={()=>navigate(-1)} />
                </div>
                <div>
                {loader ?
                <ButtonFilled btnName={loader ? 'Uploading' : 'Save'}  />
                :
                <ButtonFilled btnName={loader ? 'Uploading' : 'Save'} onClick={()=>submitform()} />}
                </div>
            </div>

            </div>
        </div>
    </div>          
    </div>
  )
}

export default IlsLandRequirmentCE